import React, { FC, useEffect, useState } from 'react'
import { getAllotedParkingSlots, getServiceRequestByData } from '../../../../constants/axios/apis'
import { VehicleRegistrationForm } from './VehicleRegistrationForm'
import completedStep from '../../../../assets/images/completed-step.png'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import { NoDataFoundWithMsg } from '../../../../constants/generics/customActios'

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

type Props = {
	contract?: any
	stepStatus?: any
	getStepStatus?: any
	setMessage?: any
}

const Step5: FC<Props> = ({ contract, stepStatus, getStepStatus, setMessage }: any) => {
	const [parkingSlots, setParkingSlots] = useState<any>([])
	const [selectedTab, setSelectedTab] = useState<any>(0)
	const [step5Data, setStep5Data] = useState<any>()
	const { t } = useTranslation();

	useEffect(() => {
		if (contract) {
			getAllotedParkingSlots(contract?.propertyID).then(({ data: slots }: any) => {
				if (slots) {
					setParkingSlots(slots)
				}
			})
		}
	}, [contract])

	useEffect(() => {
		if (parkingSlots.length) {
			setSelectedTab(parkingSlots[0].slotNumber)
		}
	}, [parkingSlots])

	useEffect(() => {
		getServiceRequestByData(9, '', contract?.propertyID).then((response) => {
			if (response.data) {
				setStep5Data(response.data[0])
			}
		})
	}, [contract, stepStatus])
	const VehicleRegistrationForms = parkingSlots?.map((slot: any) => (
		<VehicleRegistrationForm
			key={slot.id}
			slot={slot}
			property_id={contract?.propertyID}
			selectedTab={selectedTab}
			getStepStatus={getStepStatus}
			setMessage={setMessage}
		/>
	))
	if (contract?.isParkingAvailable === "N") { return <NoDataFoundWithMsg msg={'No Data'} /> }
	return (
		<div className='com-card-section w-100'>
			<div className='row form-add-design'>
				<div className='col-12'>
					<div className='card'>
						{stepStatus ? (
							<div className='row mta-app-setting-campaign'>
								<div className='col-12'>
									<div className='accordion accordion-flush move-out-accordion' id='accordionFlushExampleAccesscard'>
										<div className='accordion-item offers_details_accordian_div1 mb-0'>
											<h2 className='accordion-header position-relative' id='flush-headingAccesscard'>
												<button
													className='accordion-button collapsed accordian_bt'
													type='button'
													data-bs-toggle='collapse'
													data-bs-target='#flush-collapseAccesscard'
													aria-expanded='false'
													aria-controls='flush-collapseAccesscard'
												>
													<span className='completed-step ps-3 pe-3 d-inline-block'>
														<img src={completedStep} alt='complete-step' />
													</span>
													{t("Vehicle Registration")}
												</button>
											</h2>
											<div
												id='flush-collapseAccesscard'
												className='accordion-collapse collapse show'
												aria-labelledby='flush-headingAccesscard'
												data-bs-parent='#accordionFlushExampleAccesscard'
											>
												<div className='accordion-body py-5 bg-white'>
													<div className="row form-add-design">
														<div className="col-12">
															<div className="card shadow-none">
																<div className="card-body p-0">
																	<ul className="nav nav-tabs parking-slot-tab" id="ParkingSlotTab" role="tablist">
																		<li className="nav-item" role="presentation">
																			<button className="nav-link active" id="parking-slot-no-104-tab" data-bs-toggle="tab" data-bs-target="#parking-slot-no-104" type="button" role="tab" aria-controls="home" aria-selected="true">{t("Parking Slot Number")}<span className="ps-3">{step5Data?.details?.parking_slot_number}</span></button>
																		</li>
																	</ul>
																	<div className="tab-content" id="ParkingSlotTabContent">
																		<div className="tab-pane fade show active" id="parking-slot-no-104" role="tabpanel" aria-labelledby="parking-slot-no-104-tab">
																			<div className="my-10">
																				<div className="slot-details">
																					<h4 className="font-16">{t("Vehicle Details")}</h4>
																					<div className="row">
																						<div className="col-md-4 col-12">
																							<div className="mb-10">
																								<label className="form-label fw-bolder">{t("Brand")}</label>
																								<input type="text" className="form-control fw-bolder form-control-solid" value={step5Data?.details?.brand} readOnly />
																							</div>
																						</div>
																						<div className="col-md-4 col-12">
																							<div className="mb-10">
																								<label className="form-label fw-bolder">{t("Model")}</label>
																								<input type="text" className="form-control fw-bolder form-control-solid" value={step5Data?.details?.model} readOnly />
																							</div>
																						</div>
																						<div className="col-md-4 col-12">
																							<div className="mb-10">
																								<label className="form-label fw-bolder">{t("Color")}</label>
																								<input type="text" className="form-control fw-bolder form-control-solid" value={step5Data?.details?.color} readOnly />
																							</div>
																						</div>
																						<div className="col-md-4 col-12">
																							<div className="mb-0">
																								<label className="form-label fw-bolder">{t("Registered Plate Number")}</label>
																								<input type="text" className="form-control fw-bolder form-control-solid" value={step5Data?.details?.regno} readOnly />
																							</div>
																						</div>
																						<div className="col-md-4 col-12">
																							<div className="mb-0">
																								<label className="form-label fw-bolder">{t("Signature")}</label>
																								<img src={step5Data?.images?.signature} alt='preview-img' className='border border-secondary' />
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className='card-body p-0'>
								<ul className='nav nav-tabs parking-slot-tab' id='ParkingSlotTab' role='tablist'>
									{parkingSlots
										? parkingSlots.map((slot: any, index: number) => (
											<li
												className='nav-item'
												role='presentation'
												key={slot.id}
												onClick={() => setSelectedTab(slot.slotNumber)}
											>
												<button
													className={
														'nav-link ' + (selectedTab == slot.slotNumber ? 'active' : '')
													}
													id={'parking-slot-no-' + slot.slotNumber + '-tab'}
													data-bs-toggle='tab'
													data-bs-target={'#parking-slot-no-' + slot.slotNumber}
													type='button'
													role='tab'
													aria-controls='home'
													aria-selected='true'
												>
													{t("Parking Slot Number")}<span className='ps-3'>{slot.slotNumber}</span>
												</button>
											</li>
										))
										: ''}
								</ul>
								<div className='tab-content' id='ParkingSlotTabContent'>
									{parkingSlots ? VehicleRegistrationForms : ''}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export { Step5 }
