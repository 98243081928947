import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import TableDataResoled from './TableDataResoled'

export function ResoledIssues({ issues }: any) {
  const { t } = useTranslation();

  const columns = [
    {
      field: 'ticket',
      headerName: t("Request No."),
      width: 150
    },
    {
      field: 'issue',
      headerName: t("Issue/Request"),
      width: 250 
    },
    {
      field: 'replies',
      headerName: t("Issue/Request Type"),
      width: 250
    },
    {
      field: 'created',
      headerName: t("Date"),
      width: 150
    },
    {
      field: 'status',
      headerName: t("Status"),
      className: 'text-success',
      disableColumnMenu: true,
      width: 130,
    },
    // {
    //   field: 'action',
    //   headerName: t("Action"),
    //   sortable: false,
    //   disableColumnMenu: true,
    //   width: 130
    // },
  ]

  return (
    <div className='com-card-section'>
        <div className='card mb-3'>
          <div className='card-body px-8 py-6'>
            <div className='data_table'>
              <div className='row'>
                <div className='col-12'>
                  <div className='table-responsive'>
                    <div style={{ height: 400, width: '100%' }}>
                      <DataGrid
                        rows={TableDataResoled}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )

}
