import React, { useEffect } from 'react';
import CheckGreen from "../../../assets/images/Check_green_lg.png";
import { useTranslation } from 'react-i18next';
import {  useHistory  } from "react-router-dom";
import { useLocation } from 'react-router-dom'

const SuccessPopup = ({ show, onClose ,message }:any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation()
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        console.log(location.pathname)
        if (location.pathname == '/leads/add'){
          history.goBack(); 
        }else{
          window.location.reload();
        }
        // Redirect to home page
      }, 3000); // 5000ms = 5s

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [show, history]); // Run the effect when 'show' changes
  return (
    <div className={`modal fade ${show ? 'show d-block' : ''}`} style={{ backgroundColor: 'rgba(0,0,0,0.5)',marginTop:0 }} tabIndex={-1}>
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          {/* <div className="modal-header bg-white border-0">
            <button type="button" className="btn-close shadow" onClick={onClose} aria-label="Close"></button>
          </div> */}
          <div className="modal-body py-10">
            <div className="row g-5">
              <div className="col-md-12">
                <div className="text-center mx-md-12">
                  <div className="redeem-offer-QR">
                    <img src={CheckGreen} alt="tick-img" className="img-fluid mb-5" />
                    <h1 className="text-theme font-24 mb-2 fw-bold">{t("Done")}</h1>
                    <p className="mb-3 font-12 fw-bolder">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPopup;
