import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../components/layout/core'
import {MarketPlaceOffer} from './components/MarketPlaceOffer/MarketPlaceOffer'
import {MarketPlaceList} from './components/MarketPlaceList/MarketPlaceList'
import {All} from './components/All/All'
import {Active} from './components/Active/Active'
import {InActive} from './components/InActive/InActive'
import {Expired} from './components/Expired/Expired'
import {NearBy} from './components/NearBy/NearBy'
import {Dining} from './components/Dining/Dining'
import {Lifestyle} from './components/Lifestyle/Lifestyle'
import {Entertainment} from './components/Entertainment/Entertainment'
import {Redeemed} from './components/Redeemed/Redeemed'
import {MarketPlaceOfferDetails} from './components/MarketPlaceOfferDetails/MarketPlaceOfferDetails'
import {AddVoucher} from './components/AddVoucher/AddVoucher'
import {RedeemOfferPopup} from './components/RedeemOfferPopup/RedeemOfferPopup'
import {RedeemOfferSubmitPopup} from './components/RedeemOfferSubmitPopup/RedeemOfferSubmitPopup'
import {AddedSuccessfulPopup} from '../SuccessfulPopup/AddedSuccessfulPopup'
import {Redirect, Route, Switch} from 'react-router-dom'
import {getMarketPlaceList, getMarketPlaceTopOffers} from '../../constants/axios/apis'

let userEmail = ''
let userDetail = localStorage.getItem('userDetail')
if (userDetail) {
  userEmail = JSON.parse(userDetail).email
}

type Props = {
  marketPlaceItems?: any
  marketPlaceTopOffers?: any
}

const MarketPlace: FC<Props> = ({marketPlaceItems, marketPlaceTopOffers}: any) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
      <div className='col-xxl-12 mt-0'>
        {userEmail === 'commercialtenant@exalogic.co' ? (
          <Switch>
            <Route path='/market-place/all'>
              <MarketPlaceOffer />
              <MarketPlaceList />
              <All />
              <RedeemOfferPopup />
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/active'>
              <MarketPlaceOffer />
              <MarketPlaceList />
              <Active />
              <RedeemOfferPopup />
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/inactive'>
              <MarketPlaceOffer />
              <MarketPlaceList />
              <InActive />
              <RedeemOfferPopup />
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/expired'>
              <MarketPlaceOffer />
              <MarketPlaceList />
              <Expired />
              <RedeemOfferPopup />
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/redeemed'>
              <MarketPlaceOffer />
              <MarketPlaceList />
              <Redeemed />
              <RedeemOfferPopup />
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/marketplaceofferdetails'>
              <MarketPlaceOfferDetails />
              <RedeemOfferPopup />
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/add-voucher'>
              <AddVoucher />
              <AddedSuccessfulPopup />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path='/market-place/nearby'>
              <MarketPlaceOffer marketPlaceTopOffers={marketPlaceTopOffers} />
              <MarketPlaceList />
              <NearBy marketPlaceItems={marketPlaceItems} />
              {/* <RedeemOfferPopup redeemableOffer={redeemableOffer} /> */}
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/all'>
              <MarketPlaceOffer marketPlaceTopOffers={marketPlaceTopOffers} />
              <MarketPlaceList />
              <All
                marketPlaceItems={marketPlaceItems?.data?.filter(
                  (item: any) => item.is_redeemed === 0
                )}
              />
              {/* <RedeemOfferPopup redeemableOffer={redeemableOffer} /> */}
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/dining'>
              <MarketPlaceOffer marketPlaceTopOffers={marketPlaceTopOffers} />
              <MarketPlaceList />
              <Dining
                marketPlaceItems={marketPlaceItems?.data
                  ?.filter((item: any) => item.category === 20)
                  .filter((item: any) => item.is_redeemed === 0)}
              />
              {/* <RedeemOfferPopup redeemableOffer={redeemableOffer} /> */}
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/lifestyle'>
              <MarketPlaceOffer marketPlaceTopOffers={marketPlaceTopOffers} />
              <MarketPlaceList />
              <Lifestyle
                marketPlaceItems={marketPlaceItems?.data
                  ?.filter((item: any) => item.category === 21)
                  .filter((item: any) => item.is_redeemed === 0)}
              />
              {/* <RedeemOfferPopup redeemableOffer={redeemableOffer} /> */}
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/entertainment'>
              <MarketPlaceOffer marketPlaceTopOffers={marketPlaceTopOffers} />
              <MarketPlaceList />
              <Entertainment
                marketPlaceItems={marketPlaceItems?.data
                  ?.filter((item: any) => item.category === 22)
                  .filter((item: any) => item.is_redeemed === 0)}
              />
              {/* <RedeemOfferPopup redeemableOffer={redeemableOffer} /> */}
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/redeemed'>
              <MarketPlaceOffer marketPlaceTopOffers={marketPlaceTopOffers} />
              <MarketPlaceList />
              <Redeemed
                marketPlaceItems={marketPlaceItems?.data?.filter(
                  (item: any) => item.is_redeemed === 1
                )}
              />
              {/* <RedeemOfferPopup redeemableOffer={redeemableOffer} /> */}
              <RedeemOfferSubmitPopup />
            </Route>
            <Route path='/market-place/marketplaceofferdetails'>
              <MarketPlaceOfferDetails />
              {/* <RedeemOfferPopup redeemableOffer={redeemableOffer} /> */}
              <RedeemOfferSubmitPopup />
            </Route>
          </Switch>
        )}
      </div>
    </div>
  </>
)

const MarketPlaceWrapper: FC = () => {
  const [marketPlaceItems, setMarketPlaceItems] = useState<any>([])
  const [marketPlaceTopOffers, setMarketPlaceTopOffers] = useState<any>([])

  useEffect(() => {
    getMarketPlaceList().then((data) => {
      setMarketPlaceItems(data)
    })
    getMarketPlaceTopOffers().then((data) => {
      setMarketPlaceTopOffers(data)
    })
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Market Place</PageTitle>
      <MarketPlace
        marketPlaceItems={marketPlaceItems}
        marketPlaceTopOffers={marketPlaceTopOffers}
      />
    </>
  )
}

export {MarketPlaceWrapper}
