import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import bed from '../../../../assets/images/bed_icon.png'
import Bath from '../../../../assets/images/bath_icon.png'
import Place from '../../../../assets/images/place-icons.png'
import SQR from '../../../../assets/images/sqft-new-icon.svg'
import Yes from '../../../../assets/images/yes-new-icon.svg'
import Home from '../../../../assets/images/my-unit.png'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getReservedProperties } from '../../../../utils/makeRequest'
import { IState } from '../../../../utils/interface'
import { useTranslation } from 'react-i18next'
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios'
import { ssoUserDetailswithLocations } from '../../../../constants/generics/sso'
import { RootState } from '../../../../constants'
import { myLikes } from '../../../../constants/axios/apis'
import { Alert } from 'react-bootstrap-v5'
import { TeamSearch } from '../VisitsPage/Search/Search'

const ReservedPropertiesList: React.FC = () => {
  const location = useLocation()
  const [success, setSuccess] = useState('')
  const state: any = useSelector((state) => state)
  const [showSuccess, setShowSuccess] = useState(false)
  const userToken = state?.auth?.accessToken
  const { t } = useTranslation()
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
  const [loadings, setLoadings] = useState(false)
  const [errors, setErrors] = useState('')
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const [searchBroker, setSearchBroker] = useState('')
  const [searchProperty, setSearchProperty] = useState('')
  const [reservedProperties, setReservedProperties] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })

  let userEmail = ''
  let userId = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail)?.email
    userId = JSON.parse(userDetail)?.id
  }

  const { loading, response: resProperties, error } = reservedProperties
  useEffect(() => {
    getReservedProperties(setReservedProperties, userToken)
  }, [])

  const handleFind = (params: any) => {
    const pId = params?.id > 0 ? params?.id : params?.propertyId
    const Pname = params?.propertyName?.replace(/\s+/g, '-')?.toLowerCase()
    const location = `/properties/${Pname}/${pId}`
    window.location.assign(`${ssoUserDetailswithLocations(userDetail, accessToken, location)}`)
  }

  function onChange(e: any) {
    var postdata = {
      user_id: userId,
      property_id: e.target.value,
    }
    myLikes(postdata).then((data) => {
      if (data.code === 200 && data.errorCode != 1) {
        setSuccess(data?.description)
        setShowSuccess(true)
        setLoadings(false)
        setTimeout(() => {
          setShowSuccess(false)
        }, 2000)
      } else {
        setErrors(data?.errorDescription)
        setShow(true)
        setLoadings(false)
        setTimeout(() => {
          setShow(false)
        }, 2000)
      }
    })
  }
  const filteredresProperties = resProperties && Array.isArray(resProperties) ? resProperties
    ?.filter((items: any) => {
      return searchBroker === '' || (searchBroker !== '' && searchBroker === `${items?.broker_id}`)
    })
    .filter((items: any) => {
      const searchRegex = new RegExp(searchProperty, 'i')
      return (
        searchProperty === '' || (searchProperty !== '' && items?.propertyName?.match(searchRegex))
      )
    })
    : []
  const broker_array = [
    { brokerName: "Vishnu", mobile: "1234567890", name: "Bob" },
    { brokerName: "Amit", mobile: "0987654321", name: "Jane" },
    { brokerName: "Karan", mobile: "5678901234", name: "Eva" },
    { brokerName: "Vishnu", mobile: "2345678901", name: "Grace" },
    { brokerName: "Amit", mobile: "8901234567", name: "Charlie" },
    { brokerName: "Karan", mobile: "3456789012", name: "Hank" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Alice" },
    { brokerName: "Amit", mobile: "6789012345", name: "Frank" },
    { brokerName: "Karan", mobile: "7890123456", name: "David" },
    { brokerName: "Vishnu", mobile: "0123456789", name: "John" },
    { brokerName: "Amit", mobile: "9012345678", name: "Eva" },
    { brokerName: "Karan", mobile: "6789012345", name: "Bob" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Jane" },
    { brokerName: "Amit", mobile: "2345678901", name: "Charlie" },
    { brokerName: "Karan", mobile: "8901234567", name: "Hank" }
  ]
  return (
    <div className='row'>
      {show ? (
        <Alert className='font-14 fw-bold mb-8' variant='danger'>
          {error}!
        </Alert>
      ) : null}
      {showSuccess ? (
        <Alert className='font-14 fw-bold mb-8' variant='success'>
          {success}
        </Alert>
      ) : null}
      <div className='col-md-12 col-12'>
        <div className='row mb-7'>
          <div className='col-12 col-lg-4 col-md-4'>
            <h4 className='card-title all_card_title'>{t('Reserved Properties')}</h4>
          </div>
          <div className='col-12 col-md-8 col-lg-8'>
            <TeamSearch setSearchBroker={setSearchBroker} setSearchProperty={setSearchProperty} />
          </div>
        </div>
        <div className='reserved-main-div row'>
          {loading && <CustomLoader2 />}
          {Object.keys(filteredresProperties).length !== 0 &&
            filteredresProperties.map((reservedPropertie: any, index: number) => {
              return (
                <>
                  <div className='col-md-6' key={index + 1}>
                    <a className='offer_link'>
                      <div className='pm-offers-and-renewals mb-10 mt-1'>
                        <div className='card'>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='offer_main_img h-100'>
                                {reservedPropertie?.isOffered === '0' ? (
                                  <img
                                    src={
                                      reservedPropertie?.propertyCoverImage
                                        ? reservedPropertie?.propertyCoverImage
                                        : Home
                                    }
                                    className='img-fluid max-ht'
                                    onClick={() => handleFind(reservedPropertie)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      reservedPropertie?.propertyCoverImage
                                        ? reservedPropertie?.propertyCoverImage
                                        : Home
                                    }
                                    alt='Likes'
                                    className='img-fluid max-ht'
                                    title='This property is already offered.'
                                    style={{ cursor: 'pointer' }}
                                  />
                                )}
                                <span className='badge badge-pill badge-dark prop-type-badge'>
                                  {parseInt(reservedPropertie?.property_search_type) === 1
                                    ? t('Residential')
                                    : parseInt(reservedPropertie?.property_search_type) === 2
                                      ? t('Commercial')
                                      : reservedPropertie?.property_search_type}
                                </span>
                              </div>
                            </div>
                            <div className='col-12 align-self-center'>
                              <div className='offer_main_details p-3'>
                                <div className='col-12 pt-3'>
                                  <p className='text-black fw-bolder font-17 text-title my-offer-property-name mb-3'>
                                    {reservedPropertie?.propertyName
                                      ? reservedPropertie?.propertyName
                                      : '-'}
                                  </p>
                                  <h3 className='text-black fw-bolder mb-3 text-sub-title'>
                                    <small>{t('AED')}</small>{' '}
                                    <span>
                                      {' '}
                                      {reservedPropertie?.price ? reservedPropertie?.price : '-'}
                                    </span>{' '}
                                    / {t('Year')}
                                  </h3>
                                </div>
                                <div className='col-12 offer_main_details_div_3'>
                                  <p className='text-dark'>
                                    <img src={Place} className='img-fluid pe-2' />
                                    {reservedPropertie?.propertyCode?.substring(
                                      reservedPropertie?.propertyCode?.lastIndexOf('-') + 1,
                                      reservedPropertie?.propertyCode?.length
                                    )}{' '}
                                    {reservedPropertie?.buildingName &&
                                      ' | ' + reservedPropertie?.buildingName}{' '}
                                    {reservedPropertie?.Address &&
                                      ' | ' + reservedPropertie?.Address}{' '}
                                    {reservedPropertie?.location &&
                                      ' | ' + reservedPropertie?.location}
                                  </p>
                                </div>
                                <div className='col-12'>
                                  <div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
                                    <p className='p-3 me-2 mb-0'>
                                      <img src={bed} className='img-fluid me-3' />
                                      {reservedPropertie?.bedroom
                                        ? reservedPropertie?.bedroom
                                        : '-'}
                                    </p>
                                    <p className='p-3 me-2 mb-0'>
                                      <img src={Bath} className='img-fluid me-2' />
                                      {reservedPropertie?.bathrooms
                                        ? reservedPropertie?.bathrooms
                                        : '-'}
                                    </p>
                                    <p className='p-3 me-2 sqft-my-offer mb-0'>
                                      <img src={SQR} className='img-fluid me-2' />
                                      {reservedPropertie?.builtInArea
                                        ? reservedPropertie?.builtInArea
                                        : '-'}{' '}
                                      {t('Sqmt')}
                                    </p>
                                    <p className='p-3 mb-0'>
                                      <img src={Yes} className='img-fluid me-2' />
                                      {reservedPropertie?.parking == 'Y' ? t('Yes') : t('No')}
                                    </p>
                                  </div>
                                </div>
                                <div className='col-12'>
                                  <div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
                                    <p className='p-3 me-2'>
                                      Broker : {t(broker_array[index].brokerName)}
                                    </p>
                                    <p className='p-3'>
                                      User Name : {t(broker_array[index].name)}
                                    </p>
                                    <p className='p-3 me-2 sqft-my-offer'>
                                      User Mobile : {t(broker_array[index].mobile)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className='col-lg-3 col-xl-3 col-sm-3 col-12 align-self-center text-center'>
                              <div className='form-check pt-my-links ss'>
                                <label className='form-check-label' htmlFor='flexCheckDefault'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='flexCheckDefault'
                                    checked
                                    onChange={(e) => onChange(e)}
                                    value={reservedPropertie?.id}
                                  />
                                </label>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              )
            })}
          {Object.keys(filteredresProperties)?.length == 0 && !loading && <NoDataFound1 />}
        </div>
      </div>
    </div>
  )
}

export { ReservedPropertiesList }
