import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import SuccessIcon from "../../assets/images/tick-circle-green.svg";
import { useTranslation } from 'react-i18next';

export function Index() {
    let { status }: any = useParams();
    const history = useHistory();
    const [message, setMessage] = useState("");
    const [error, seError] = useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let txnId = params.get('t');
    let redirect_type:any = params.get('redirect_type');
    const { t } = useTranslation();

    useEffect(() => {
        const getData = async () => {
            try {
                const resp = await axios.get(`${process.env.REACT_APP_API_URL}/payment/return/${status}?t=${txnId}`)
                // console.log("🚀 ~ file: Index.tsx ~ line 16 ~ getData ~ resp", resp)
                if (resp.data.code === 200 && resp.data.errorCode != 1) {
                    seError(false)
                    setMessage(resp.data.description)
                    setTimeout(() => {
                        // window.open(
                        //     '/',
                        //     '_self'
                        // )
                        if (redirect_type == 2)
                            history.push('/my-services/openissues')
                        else if (redirect_type == 1)
                            history.push('/payments/all')
                        else
                            history.push('/pt-activity/my-offer')
                            // history.push('/pt-activity/my-offer/' + resp?.data?.data?.objectId)
                    }, 3000)
                } else {
                    seError(true)
                    setMessage(resp.data.errorDescription)
                    setTimeout(() => {
                        // window.open(
                        //     '/',
                        //     '_self'
                        // )
                        if (redirect_type == 2)
                            history.push('/my-services/openissues')
                        else if (redirect_type == 1)
                            history.push('/payments/all')
                        else
                            history.push('/pt-activity/my-offer')
                    }, 3000)
                }
            } catch (error) {
                setTimeout(() => {
                    history.push('/dashboard')
                }, 3000)
            }
        }
        getData()
    }, [])
    return (
        <div className='com-card-section h-100'>
            <div className='row h-100'>
                <div className='col-12'>
                    <div className='card mb-0 h-100'>
                        <div className='card-body px-8 py-6'>
                            <div className='row h-100 align-items-center'>
                                <div className='col-md-12 col-12'>
                                    {/* Payment Success */}
                                    {status == "success" && (<div className="text-center payment-success">
                                        <img
                                            src={SuccessIcon}
                                            alt="Payment Success"
                                            className='img-fluid'
                                        />
                                        <h3
                                            className="text-primary"
                                        >
                                           {t("Payment Successful!")}
                                        </h3>
                                        <p className="mb-0">
                                        {t("We received your purchase request. We'll be in touch shortly!")}
                                        </p>
                                    </div>)}
                                    {/* Link Expired */}
                                    {status == "error" && (<div className="text-center payment-success">
                                        <i className="bi bi-info-circle"></i>
                                        <h3
                                            className="text-primary"
                                        >
                                            {t("Error")}
                                        </h3>
                                        <p className="mb-0">
                                        {t("This link has been expired")}
                                        </p>
                                    </div>)}
                                    {/* Payment Canceled */}
                                    {status == "cancel" && (<div className="text-center payment-success">
                                        <i className="bi bi-x-circle"></i>
                                        <h3
                                            className="text-danger mb-0"
                                        >
                                            {t("Payment Cancelled.")}
                                        </h3>
                                    </div>)}
                                    {/* Payment Declined */}

                                    {status == "declined" && (<div className="text-center payment-success">
                                        <i className="bi bi-exclamation-circle"></i>
                                        <h3
                                            className="text-primary"
                                        >
                                            {t("Oops! Something went wrong.")}.
                                        </h3>
                                        <p className="mb-0">
                                           {t("While trying to reserve money from your account.")}
                                        
                                        </p>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
