import React from 'react'
import {useIntl} from 'react-intl'
//import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'


export function HelpPopup() {
    const intl = useIntl()
    const { t } = useTranslation();

    return (
        <>
        <div className='row'>
            <div className='col-lg-12 my-auto'>
                <button data-bs-target="#help-popup"
                    data-bs-toggle="modal" data-bs-dismiss="modal" type='submit'
                    className='btn btn-primary font-14 mb-6'
                > {t("Help ?")}
                </button>
                <div className="modal fade help-popup-modal custom-modal" aria-hidden='true' id="help-popup">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-10">
                            <div className="modal-header px-7 py-7 bg-primary border-0">
                                <h5 className="modal-title text-white">{t("Help me with this Service Request")}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                            </div>
                            <div className="modal-body px-2 pt-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="1">
                                                    <button className="accordion-button collapsed py-3" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        {t("The quality of material is not adequate")}
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="1" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p className="text-start">
                                                            {t("We are really sorry for this experience. You can try reaching out to our Facility Executive or us and we will try to resolve this as soon as possible.")}
                                                        </p>
                                                        <button type="button" className="btn btn-primary">{t("Chat With us")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="2">
                                                    <button className="accordion-button collapsed py-3" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        {t("Technician behaviour")}
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="2" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p className="text-start">
                                                            {t("We are really sorry for this experience. You can try reaching out to our Facility Executive or us and we will try to resolve this as soon as possible.")}
                                                        </p>
                                                        <button type="button" className="btn btn-primary">{t("Chat With us")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="3">
                                                    <button className="accordion-button collapsed py-3" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                        {t("Service Request Related")} #RealCube-1236542
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="3" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p className="text-start">
                                                            {t("We are really sorry for this experience. You can try reaching out to our Facility Executive or us and we will try to resolve this as soon as possible.")}
                                                        </p>
                                                        <button type="button" className="btn btn-primary">{t("Chat With us")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="four">
                                                    <button className="accordion-button collapsed py-3" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#four" aria-expanded="false" aria-controls="four">
                                                        {t("Payment, Refund, Bill Related Queries")}
                                                    </button>
                                                </h2>
                                                <div id="four" className="accordion-collapse collapse" aria-labelledby="four" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p className="text-start">
                                                            {t("We are really sorry for this experience. You can try reaching out to our Facility Executive or us and we will try to resolve this as soon as possible.")}
                                                        </p>
                                                        <button type="button" className="btn btn-primary">{t("Chat With us")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="five">
                                                    <button className="accordion-button collapsed py-3" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#five" aria-expanded="false" aria-controls="five">
                                                        {t("Others")}
                                                    </button>
                                                </h2>
                                                <div id="five" className="accordion-collapse collapse" aria-labelledby="five" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body">
                                                        <p className="text-start">
                                                            {t("We are really sorry for this experience. You can try reaching out to our Facility Executive or us and we will try to resolve this as soon as possible.")}
                                                        </p>
                                                        <button type="button" className="btn btn-primary">{t("Chat With us")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
