import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import Home from "../../../../../../assets/images/my-unit.png";
import { useTranslation } from 'react-i18next'
import { currencyFormater, CustomLoader2 } from '../../../../../../constants/generics/customActios';
import { formatDate } from '../../../../../../constants/generics/dateTimeFormater';

export const LeaseDetails: React.FC<any> = ({ contractDetailsState }) => {
    const { t } = useTranslation();
    // console.log(contractDetailsState,"contractDetailsStatecontractDetailsStatecontractDetailsState");

    // const [contractDetails, setContractDetails] = useState<any>({
    //     contractNo: '',
    //     contractStartDate: '',
    //     contractEndDate: '',
    //     documentCount: '',
    //     rent: '',
    //     SD: '',
    //     propertyImage: Home
    // });

    // useEffect(()=>{
    //     if(contractDetailsState.response.length>0){
    //         if(Object.keys(contractDetailsState.response).length !== 0){
    //             setContractDetails(contractDetailsState.response[0]);                
    //         }
    //     }
    // },[contractDetailsState]);

    return (
        <div className="com-card-section myunit-page comm-lease">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-9">
                        <div className="card-body ps-7 pe-5 py-5">
                            <div className="row">
                                <div className="col-md-4 col-lg-3 mb-lg-0 mb-4 text-md-start text-center">
                                    <img src={contractDetailsState?.response?.propertyImage ? contractDetailsState?.response?.propertyImage : Home} className="img-fluid" />
                                </div>
                                <div className="col-md-8 col-lg-9">
                                    <div className="card lease-card border-0 rounded-5">
                                        <div className="card-body px-5 pt-4 pb-2">
                                            <div className="row">
                                                {contractDetailsState?.loading && (
                                                    <CustomLoader2 />
                                                )}
                                                {!contractDetailsState?.loading && (
                                                    <>
                                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-3 mb-xl-6 ">
                                                            <h6 className="text-theme-gray mb-3 fw-bold font-15"> {t("Reference Number")} </h6>
                                                            <span className="text-theme fw-bolder font-14"> {contractDetailsState?.response?.lease_number ? contractDetailsState?.response?.lease_number : '-'} </span>
                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-3 mb-xl-6">
                                                            <div className="">
                                                                <h6 className="text-theme-gray mb-3 fw-bold font-15"> {t("Start Date")} </h6>
                                                                <span className="text-theme fw-bolder font-14"> {contractDetailsState?.response?.contract_start ? formatDate(contractDetailsState?.response?.contract_start) : '-'} </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-3 mb-xl-6">
                                                            <h6 className="text-theme-gray mb-3 fw-bold font-15"> {t("End Date")} </h6>
                                                            <span className="text-theme fw-bolder font-14"> {contractDetailsState?.response?.contract_end ? formatDate(contractDetailsState?.response?.contract_end) : '-'} </span>
                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-3 mb-xl-0">
                                                            <h6 className="text-theme-gray mb-3 fw-bold font-15"> {t("Documents")} </h6>
                                                            <span className="text-theme fw-bolder font-14"> {contractDetailsState?.response?.documentCount ? contractDetailsState?.response?.documentCount : '-'} </span>
                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-3 mb-xl-0">
                                                            <h6 className="text-theme-gray mb-3 fw-bold font-15"> {t("Rent Price")} </h6>
                                                            <span className="text-theme fw-bolder font-14"> {t('AED')} {contractDetailsState?.response?.annualRent ? currencyFormater(contractDetailsState?.response?.annualRent) : '-'} </span>
                                                        </div>
                                                        <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-3 mb-xl-0">
                                                            <h6 className="text-theme-gray mb-3 fw-bold font-15"> {t("Reservation Deposit")} </h6>
                                                            {/* <span className="text-theme fw-bolder font-14">{t('AED')} {contractDetailsState?.response?.security_deposit ? currencyFormater(contractDetailsState?.response?.security_deposit) : '-'} </span> */}
                                                            <span className="text-theme fw-bolder font-14">{t('AED')} 10,000 </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}