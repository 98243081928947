import React, {useState, useEffect, useRef} from 'react'
import detailsBanner from '../../../../assets/images/offer-details-banner.png'
import voxLogo from '../../../../assets/images/vox-logo-lg.png'
import Logo1 from '../../../../assets/images/vox-logo.svg'
import {MarketPlaceOfferDescription} from './MarketPlaceOfferDescription'
import {AvailableOffers} from './AvailableOffers/AvailableOffers'
import {Link, useLocation} from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../../../constants/generics/userCheck'

export function MarketPlaceOfferDetails() {
  // const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  // const inputRef = useRef<HTMLInputElement>(null)

  const state: any = useSelector(state => state);
  const user_role = getUserRole(state?.auth?.user?.data);

  const location = useLocation<any>()

  const [marketPlaceOffer, setMarketPlaceOffer] = useState<any>()
  const [backLink, setBackLink] = useState<any>(
    (user_role && ['PT', 'T'].includes(user_role)) 
    ? '/marketPlace/events/all' //TODO - dynamic on old location based
    : '/market-place/nearby'
  )

  useEffect(() => {
    if(user_role === 'PT' || user_role === 'T'){

      if(location?.state?.prevPath){
        const {prevPath} = location?.state
        setBackLink(prevPath)
      }

      if(location?.state?.marketplaceDetails){
        const {marketplaceDetails} = location?.state
        setMarketPlaceOffer(marketplaceDetails)
      }
      
    }
    else{
      setMarketPlaceOffer(location?.state)
    }
  }, [location])

  

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = () => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  // }

  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          {(user_role && ['PT', 'T'].includes(user_role)) ?
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={backLink} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              </Link>
              <span className='text-black'>Market Place</span>
            </h4>
            :
            <>
              {userEmail === 'commercialtenant@exalogic.co' ? (
                <h4 className='card-title font-18 text-black mb-6'>
                  <Link to={'/market-place/all'} className=''>
                    <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                    <span className='text-black'>VOX Cinemas</span>
                  </Link>
                </h4>
              ) : (
                <h4 className='card-title font-18 text-black mb-6'>
                  <Link to={'/market-place/nearby'} className=''>
                    <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                    <span className='text-black'>Market Place</span>
                  </Link>
                </h4>
              )}
            </>
          }
        </div>
      </div>
      {userEmail === 'commercialtenant@exalogic.co' ? (
        <div className='col-12'>
          <div className='card m-details-overview mb-3'>
            <div className='card-body px-5 py-5'>
              <div className='row'>
                <div className='col-md-4 col-lg-3 col-xl-3'>
                  <div className='card rounded market-place-logo-sec'>
                    <div className='card-body py-6 px-xxl-20 px-xl-15 px-md-15 px-12'>
                      <div className='row'>
                        <div className='col-12 text-center'>
                          <div className='bg-white rounded-10 px-4 py-4 mb-4'>
                            <img src={Logo1} alt='Logo' className='img-fluid' />
                          </div>
                          <div className='d-inline-flex mb-4 align-items-center w-100 justify-content-between'>
                            <span className='offer-count'>2 For 1</span>
                            <span className='offer-per'>25% OFF</span>
                          </div>
                          <h4 className='text-primary font-17 mb-0 fw-boldest'>VOX Cinemas</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-8 col-lg-9 col-xl-9'>
                  <div className='row'>
                    <div className='col-12'>
                      <h5 className='font-16 mb-5 fw-boldest text-black'>Details</h5>
                      <div className='row full-details'>
                        <div className='col-md-4'>
                          <div className='mb-7'>
                            <label className='d-block font-14 fw-bold mb-2'>Voucher Name</label>
                            <span className='font-14 fw-bold text-black'>
                              <span className='icon-bg'>
                                <i className='bi bi-telephone-fill'></i>
                              </span>
                              <span>CB87887</span>
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='mb-7'>
                            <label className='d-block font-14 fw-bold mb-2'>Category</label>
                            <span className='font-14 fw-bold text-black'>
                              <span className='icon-bg'>
                                <i className='bi bi-grid-fill'></i>
                              </span>
                              <span>Entertainment</span>
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='mb-7'>
                            <label className='d-block font-14 fw-bold mb-2'>Location</label>
                            <span className='font-14 fw-bold text-black'>
                              <span className='icon-bg'>
                                <i className='bi bi-geo-alt-fill'></i>
                              </span>
                              <span>Dubai</span>
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='mb-7'>
                            <label className='d-block font-14 fw-bold mb-2'>Contact</label>
                            <span className='font-14 fw-bold text-black'>
                              <span className='icon-bg'>
                                <i className='bi bi-telephone-fill'></i>
                              </span>
                              <span>800 5566</span>
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='mb-7'>
                            <label className='d-block font-14 fw-bold mb-2'>Start Date</label>
                            <span className='font-14 fw-bold text-black'>
                              <span className='icon-bg'>
                                <i className='bi bi-calendar-fill'></i>
                              </span>
                              <span>12-Nov-2021</span>
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='mb-7'>
                            <label className='d-block font-14 fw-bold mb-2'>Time</label>
                            <span className='font-14 fw-bold text-black'>
                              <span className='icon-bg'>
                                <i className='bi bi-clock-fill'></i>
                              </span>
                              <span>10:30 AM - 11:59PM</span>
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='mb-7'>
                            <label className='d-block font-14 fw-bold mb-2'>End Date</label>
                            <span className='font-14 fw-bold text-black'>
                              <span className='icon-bg'>
                                <i className='bi bi-calendar-fill'></i>
                              </span>
                              <span>12-Nov-2021</span>
                            </span>
                          </div>
                        </div>
                        <div className='col-md-8'>
                          <div className='mb-7'>
                            <label className='d-block font-14 fw-bold mb-2'>Web</label>
                            <span className='font-14 fw-bold text-black'>
                              <span className='icon-bg'>
                                <i className='bi bi-globe2'></i>
                              </span>
                              <span>https://uae.voxcinemas.com/</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-5 py-5 market-place-ht'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='off-details-banner position-relative'>
                    <img
                      src={marketPlaceOffer?.banner}
                      alt='offer-detail-banner'
                      className='img-fluid w-100 offer-detail-banner'
                    />
                    <div className='offer-details-section position-absolute text-white'>
                      <div className='row justify-content-between'>
                        <div className='col-sm-6'>
                          <div className='d-flex flex-column justify-content-around off-text-left'>
                          <div className="logo-img bg-white rounded-10">
                            <img
                                src={marketPlaceOffer?.logo}
                                alt='Offer Logo'
                                className='img-fluid offer-logo-img'
                                width='74'
                                height='70'
                              />
                          </div>
                            <h3>{marketPlaceOffer?.name}</h3>
                            <p className='fw-normal banner-text'>{marketPlaceOffer?.location}</p>
                            {/* <p className='fw-normal banner-text'>Entertainment</p> */}
                            <p className='available-time-offer banner-text'>
                              <small>{marketPlaceOffer?.open_hours}</small>
                            </p>
                          </div>
                        </div>
                        <div className='col-sm-6 text-end'>
                          <div className='d-flex flex-column justify-content-around h-100 text-white off-text-right'>
                            {/* <span className='offer'>2 for 1</span> */}
                            <span className='offer'>{marketPlaceOffer?.redeem_count ? marketPlaceOffer?.redeem_count : '0'} of {marketPlaceOffer?.redeem_limit ? marketPlaceOffer?.redeem_limit : '-'}</span>
                            <h2 className='text-white'>{marketPlaceOffer?.offer}% OFF</h2>
                            <h5 className='text-white fw-normal'>
                              Expires at
                              <span className='text-white offer-timer'>
                                {moment
                                  .utc(marketPlaceOffer?.expiry_date_time)
                                  .format('DD-MM-YYYY')}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MarketPlaceOfferDescription marketPlaceOffer={marketPlaceOffer} backLink={backLink}/>
      {userEmail === 'commercialtenant@exalogic.co' ? <AvailableOffers /> : null}
    </div>
  )
}
