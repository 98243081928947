import { Link } from "react-router-dom";


export function ChequesList() {


    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <h4 className="card-title mb-0"> My Cheques </h4>
                <div className="d-flex">
                    <Link to={'#'} className='btn btn-theme btn-sm font-16 me-3' data-bs-target="#replace-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                        Replace
                    </Link>
                    <Link to={'#'} className='btn btn-theme btn-sm font-16' data-bs-target="#postpone-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                        Postpone
                    </Link>
                </div>
            </div>
            <div className="row cheques">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card-table">
                                        <div className="table-responsive mb-3">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr className="rs-text-center">
                                                        <th className="text-left"> </th>
                                                        <th className="text-left"> Bank</th>
                                                        <th className="text-left"> Cheque Number</th>
                                                        <th data-breakpoints="xs" data-hide="phone,tablet" className="text-left"> Cheque Date</th>
                                                        <th data-breakpoints="xs" data-hide="phone,tablet" className="text-left"> Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check0" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Bank of Sharjah</td>
                                                        <td data-hide="phone,tablet" className="text-left">688</td>
                                                        <td data-hide="phone,tablet">Apr 20, 2022</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check1" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Bank of Baroda</td>
                                                        <td data-hide="phone,tablet" className="text-left">8678</td>
                                                        <td data-hide="phone,tablet">Apr 26, 2022</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check2" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Bank Saderat Iran</td>
                                                        <td data-hide="phone,tablet" className="text-left">68688</td>
                                                        <td data-hide="phone,tablet">Apr 27, 2022</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check3" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Bank of Sharjah</td>
                                                        <td data-hide="phone,tablet" className="text-left">78</td>
                                                        <td data-hide="phone,tablet">Apr 27, 2022</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check4" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Abu Dhabi Commercial Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">8688</td>
                                                        <td data-hide="phone,tablet">May 03, 2022</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check5" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Rafidain Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">7678</td>
                                                        <td data-hide="phone,tablet">May 04, 2022</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check6" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">The Royal Bank Of Scotland N.V.</td>
                                                        <td data-hide="phone,tablet" className="text-left">123456</td>
                                                        <td data-hide="phone,tablet">Feb 02, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check7" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">The Royal Bank Of Scotland N.V.</td>
                                                        <td data-hide="phone,tablet" className="text-left">123456</td>
                                                        <td data-hide="phone,tablet">Feb 02, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check8" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Abu Dhabi Commercial Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">123456</td>
                                                        <td data-hide="phone,tablet">Feb 22, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check9" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">The Royal Bank Of Scotland N.V.</td>
                                                        <td data-hide="phone,tablet" className="text-left">111111</td>
                                                        <td data-hide="phone,tablet">Feb 23, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check10" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Central Bank of UAE</td>
                                                        <td data-hide="phone,tablet" className="text-left">123456</td>
                                                        <td data-hide="phone,tablet">Mar 16, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check11" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Abu Dhabi Commercial Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">123435</td>
                                                        <td data-hide="phone,tablet">Mar 30, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check12" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Rafidain Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">123435</td>
                                                        <td data-hide="phone,tablet">Apr 07, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check13" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Rafidain Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">123435</td>
                                                        <td data-hide="phone,tablet">Apr 07, 2023</td>
                                                        <td data-hide="phone,tablet">Pending</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check14" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Rafidain Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">123435</td>
                                                        <td data-hide="phone,tablet">Apr 07, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check15" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check16" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check17" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check18" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Pending</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check19" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check20" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Pending</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check21" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check22" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check23" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check24" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Pending</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check25" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check26" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check27" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check28" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check29" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check30" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check31" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check32" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check33" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check34" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check35" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check36" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check37" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Pending</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check38" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check39" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Pending</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check40" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check41" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check42" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Pending</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check43" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check44" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">National Bank Of Fujairah</td>
                                                        <td data-hide="phone,tablet" className="text-left">435643</td>
                                                        <td data-hide="phone,tablet">Apr 08, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check45" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Rafidain Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">5686</td>
                                                        <td data-hide="phone,tablet">Apr 14, 2023</td>
                                                        <td data-hide="phone,tablet">Pending</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check46" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Al Ahli Bank Of Kuwait K.S.C.</td>
                                                        <td data-hide="phone,tablet" className="text-left">6896</td>
                                                        <td data-hide="phone,tablet">Apr 17, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check47" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Abu Dhabi Commercial Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">86</td>
                                                        <td data-hide="phone,tablet">Oct 14, 2023</td>
                                                        <td data-hide="phone,tablet">Approved</td>
                                                    </tr>
                                                    <tr className="rs-text-center">
                                                        <td data-hide="phone,tablet" className="text-left">
                                                            <div className="custom-input custom-input-inline checkbox ms-3">
                                                                <input type="checkbox" id="check48" className="invoiceCheckboxs-due" />
                                                                <label className="disable_check"></label>
                                                            </div>
                                                        </td>
                                                        <td data-hide="phone,tablet" className="text-left">Abu Dhabi Commercial Bank</td>
                                                        <td data-hide="phone,tablet" className="text-left">6887</td>
                                                        <td data-hide="phone,tablet">Apr 22, 2024</td>
                                                        <td data-hide="phone,tablet">Pending</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

