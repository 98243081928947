import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import uploadImg from '../../../../assets/images/file-upload.svg'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { accessCardRequest, generalServiceRequest } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

export function GeneralService({ genaralCategories, contract , setMessage}: any) {
    const { t } = useTranslation();

    let params: any = {};
    params = useLocation();
    const { quickCategory } = params.state;    

    const [pageTitle, setPageTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const history = useHistory()
    const closeRef = useRef<any>()
    const failRef = useRef<any>()
  
    // const handleUpload = () => {
    //   inputRef.current?.click()
    // }
    // const handleDisplayFileDetails = (e: any) => {
    //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
    //   // formik.setFieldTouched('photo')
    //   formik.setFieldValue('photo', e.currentTarget.files[0])
    // }

    
  
    const initialValues = {
        category_type_id: quickCategory ? quickCategory.id : '',
        // issue_type: '',
        desc: '',
        photo: '',
      }
  
    const newRequestSchema = Yup.object().shape({
        category_type_id: Yup.number().required(t('Please choose an issue category!')),
        desc: Yup.string()
            .required(t('Please add a comment'))
            .matches(/^[^\s].*/, t('Comment cannot start with a blankspace!'))
            .max(250, t("Maximum 250 characters")),
        // photo: Yup.mixed()
        //     .test('type', t('Only the following formats are acceptable: .jpeg, .jpg, .png'), (value) =>
        //         value ? ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type) : true )
        //     .test('fileSize', t('The file is too large!'), (value) => value ? value?.size <= 2000000 : true ),
    })
  
    const formik = useFormik({
      initialValues,
      validationSchema: newRequestSchema,
      onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
        setLoading(true)
        setTimeout(() => {
            generalServiceRequest(
                contract?.propertyID,
                quickCategory ? quickCategory.category_id : '',
                values.category_type_id,
                values.desc,
                values.photo
            )
            .then((response: any) => {
                setLoading(false)
                resetForm()
                setUploadedFileName(null)
                if (response.errorCode === 0) {
                    setMessage(t("Your Request has been Submitted!"))
                    closeRef.current.click()
                    history.push('/my-services/openissues')
                }else if (response.errorCode === 1) {
                    setMessage(response.errorDescription)
                    failRef.current.click()
                } else {
                    setMessage("Some error occured!")
                    failRef.current.click()
                } 
            })
            .catch((e) => {
                setLoading(false)
                setSubmitting(false)
                setStatus(t("Invalid Data Provided"))
            })
        }, 1000)
      },
    })

    useEffect(()=>{        
        const selectedCat = genaralCategories?.find((el:any)=> `${el.id}` === `${formik.values.category_type_id}`)
        setPageTitle(selectedCat?.name ? selectedCat.name : t('General'))        
        if(selectedCat === undefined)
            formik.values.category_type_id = ''
    },[formik.values.category_type_id, genaralCategories])

    const genaralCategoriesList = genaralCategories?.
        map((card: any) => (<option key={card.id} value={card.id}>{card.name} </option>) )

        const [uploading, setUploading] = useState(false)
        const handleUpload = () => {
          inputRef.current?.click()
        }
      
        const handleDisplayFileDetails = (e: any) => {
          setUploading(true)
          formik.setFieldError('photo', undefined)
          try {
            validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
              if (compressResponse?.success) {
                inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
                formik.setFieldTouched('photo')
                formik.setFieldValue('photo', compressResponse.file)
              } else {
                setUploadedFileName(null)
                formik.setFieldTouched('photo', true, false)
                formik.setFieldValue('photo', '', false)
                formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
              }
              setUploading(false)
            })
          } catch (error) {
            setUploadedFileName(null)
            formik.setFieldTouched('photo')
            formik.setFieldValue('photo', '', false)
            formik.setFieldError('photo', t('File upload error!'))
            setUploading(false)
          }
        }

        return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-md-12 col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                        <Link to={'/my-services/openissues'} className=''>
                            <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                        </Link>
                        {pageTitle}
                    </h4>
                </div>
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='col-md-12 col-12'>
                        <div className='card'>
                            <div className='card-body px-8 py-6'>
                                <div className='row form-add-design'>
                                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                        <div className='mb-10'>
                                            <label className='form-label fw-bolder'>{t("Service Category")}<span className='text-danger'> * </span></label>
                                            <select
                                                className='form-control form-select fw-bolder form-control-solid'
                                                data-control='select2'
                                                data-allow-clear='true'
                                                name='category_type_id'
                                                onChange={formik.handleChange}
                                                value={formik.values.category_type_id}
                                                onBlur={formik.handleBlur}
                                            >
                                                <option value='' disabled>{t("Please Select")}</option>
                                                {genaralCategories ? genaralCategoriesList : ''}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                        <div className='mb-10 upload-input'>
                                            <label className='form-label fw-bolder d-block'>
                                                {t("Photo")}
                                            </label>    
                                            <input
                                                ref={inputRef}
                                                onChange={(e) => handleDisplayFileDetails(e)}
                                                className='d-none'
                                                type='file'
                                                accept=".png,.jpg,.jpeg"
                                                // onBlur={formik.handleBlur}
                                            />
                                            <button
                                                type='button'
                                                onClick={handleUpload}
                                                disabled={uploading}
                                                className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                                                    }`}
                                            >
                                                {/* <span>
                                                {uploadedFileName ? (
                                                    uploadedFileName
                                                ) : (
                                            <span  className="txt">
                                                <img
                                                        alt="File"
                                                        src={uploadImg}
                                                className="position-relative"
                                                loading="lazy"/>
                                                    {t("Upload")}{' '}
                                                    </span>
                                                )}{' '}
                                                </span> */}
                                                <span>
                                                    {uploading ?
                                                    <span className='indicator-progress d-block'>
                                                        {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                    : uploadedFileName ? (
                                                        uploadedFileName
                                                    ) : (
                                                    <span  className="txt">
                                                        <img
                                                                alt="File"
                                                                src={uploadImg}
                                                        className="position-relative"
                                                        loading="lazy"/>{t('Upload')}{' '}
                                                        </span>
                                                    )}{' '}
                                                </span>
                                            </button>
                                            {formik.touched.photo && formik.errors.photo && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.photo}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-md-12 col-12'>
                                        <div className='mb-10'>
                                        <label className='form-label fw-bolder'>
                                            {t("Comments")}<span className='text-danger'>*</span>
                                        </label>
                                        <textarea
                                            // className='form-control fw-bolder form-control-solid'
                                            className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.desc && formik.errors.desc,
                                            },
                                            {
                                                'is-valid': formik.touched.desc && !formik.errors.desc,
                                            }
                                            )}
                                            data-kt-autosize='true'
                                            rows={3}
                                            placeholder={t("Type here...")}
                                            {...formik.getFieldProps('desc')}
                                        />
                                            {formik.touched.desc && formik.errors.desc && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.desc}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-md-12 text-end'>
                                        <div className='row justify-content-end'>
                                            <div className='col-md-12'>
                                                <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading || uploading}>
                                                    {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            {t("Please wait...")}{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <p
                ref={closeRef}
                data-bs-target='#req-submitted-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
            >
                {/*  */}
            </p>
            <p
                ref={failRef}
                data-bs-target='#req-failed-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
            >
            {/*  */}
            </p>
        </div>
    )
}
