// import {Link} from 'react-router-dom'

export default [
    {
        id: 1,
        no: "01",
        issue: "NOC for Unit Alteration",
        ticket: "REQ00125",
        description: "Architectural Issue",
        created: "25-07-2023",
        status: "In progress",
        replies: "Architectural Issue",
        // action: "-"
    },
    {
        id: 2,
        no: "01",
        issue: "NOC for Unit Sale",
        ticket: "REQ00126",
        description: "Signature Issue",
        created: "01-08-2023",
        status: "Open",
        replies: "Signature Issue",
        // action: "-"
    },
];
