import React, { FC, useEffect, useRef, useState } from 'react'
import { StepOne } from './Steps/StepOne/StepOne'
import { StepTwo } from './Steps/StepTwo/StepTwo'
import { StepThree } from './Steps/StepThree/StepThree'
import { StepSuccessful } from './Steps/StepSuccessful/StepSuccessful'
import { Spinner } from 'react-bootstrap-v5'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../constants'
import { createBrokerContract, createContract } from '../../../../../../constants/axios/apis';
import * as dashboard from '../../../../../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import * as PTOffer from '../../../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { CustomLoaderCompact, someThingWentWrong } from './../../../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next'
import { getUserRole } from '../../../../../../constants/generics/userCheck'

export function ExtendOfferStepper(props: any) {
    const dispatch = useDispatch()
    const ref = useRef<any>(0)
    let offerList: any
    offerList = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)

    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [firstStep, setFirstStep] = useState(false)
    const [secondStep, setSecondStep] = useState(false)
    const [thirdStep, setThirdStep] = useState(false)
    const [formErrors, setFormErrors] = useState({});
    const { t } = useTranslation();

    const [message, setMessage] = useState<any>()
    const [emiratesIdFront, setEmiratesIdFront] = useState<any>()
    const [emiratesIdBack, setEmiratesIdBack] = useState<any>()
    const [passport, setPassport] = useState<any>()
    const [passportBack, setPassportBack] = useState<any>()
    const [visa, setVisa] = useState<any>()
    const [letter, setLetter] = useState<any>()
    const [tenant, setTenant] = useState<any>({})
    const [contact, setContact] = useState<any>({})
    const [upload, setUpload] = useState<any>({})

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    const createContractDynamicApiCall = (user_role === 'BA' || user_role === 'BR') ? createBrokerContract : createContract  // For broker admin createBrokerContract

    const steps = [
        t("Tenant Details"),
        t("Emergency Contact Information"),
        t("Upload Documents")
    ];

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (activeStep === 0) {
            setFormErrors(validate(tenant, 1));
            if (firstStep) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
            }
        }
        else if (activeStep === 1) {
            setFormErrors(validate(contact, 2));
            if (secondStep) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setSkipped(newSkipped);
            }
        }
        else if (activeStep === 2 && thirdStep) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }
    };

    const somethingWrg = someThingWentWrong()
    const handleSubmit = () => {
        setLoading(true)
        var FormData = require('form-data');
        var createContractData = new FormData();
        createContractData.append('property_id', offerList?.propertyId);
        createContractData.append('offer_id', offerList?.offerId);
        createContractData.append('customer_name', JSON.parse(tenant)?.tName);
        createContractData.append('customer_last_name', JSON.parse(tenant)?.tLastName);
        createContractData.append('rent', offerList?.offeredPrice);
        createContractData.append('security_deposit', offerList?.securityDeposit);
        createContractData.append('installment', offerList?.noOfPayments);
        createContractData.append('contract_start', offerList?.leaseDate);
        createContractData.append('contract_end', offerList?.leaseEndDate);
        createContractData.append('customer_dob', JSON.parse(tenant)?.dob1);
        createContractData.append('emirates_id', JSON.parse(tenant)?.emiratesID);
        createContractData.append('emirates_id_exp_date', JSON.parse(tenant)?.emiratesIdExpiry1);
        createContractData.append('passport_no', JSON.parse(tenant)?.passport);
        createContractData.append('passport_expiry_date', JSON.parse(tenant)?.passportExpiry1);
        createContractData.append('emergency_contact_name', JSON.parse(contact)?.cName);
        createContractData.append('emergency_contact_no', JSON.parse(contact)?.contactNumber);
        createContractData.append('emergency_relation', JSON.parse(contact)?.relation);
        createContractData.append('emergency_nationality', JSON.parse(contact)?.nationality);
        emiratesIdFront != null && createContractData.append('emirates_front', emiratesIdFront);
        emiratesIdBack != null && createContractData.append('emirates_back', emiratesIdBack);
        passport != null && createContractData.append('passport', passport);
        passportBack != null && createContractData.append('passport_back', passportBack);
        visa != null && createContractData.append('visa', visa);
        if (offerList?.modeOfPayment === "PAY_BY_EMP") {
            createContractData.append('letter_of_intent', letter);
        }

        createContractDynamicApiCall(createContractData).then((data) => {
            setLoading(false)
            if (data?.status === 200 && data?.data?.errorCode === 0) {
                dispatch(dashboard.actions.offerDetails(offerList?.offerId))
                setMessage({
                    error: 0,
                    message: data?.data?.data?.msg,
                })
                ref.current.click();
                setFirstStep(false)
                setSecondStep(false)
                setThirdStep(false)
            }
            else if (data?.status > 200) {
                setMessage({
                    error: 1,
                    message: somethingWrg,
                })
                ref.current.click();
            } else if (data?.data?.errorCode === 1) {
                setMessage({
                    error: 1,
                    message: data?.data?.errorDescription,
                })
                ref.current.click();
            } else {
                setMessage({
                    error: 1,
                    message: data?.errorDescription,
                })
                ref.current.click();
            }
        })
    };

    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
        }
    }, [message])
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error(t("You can't skip a step that isn't optional."));
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const tenantDetails = (e: any) => {
        setTenant(e)
        e = JSON.parse(e)
        if (e.dob1.length > 0 && e.emiratesID.length > 0 && e.emiratesID.length === 15 && e.emiratesIdExpiry1.length > 0 && e.passport.length > 0 && e.passportExpiry1.length > 0 && e.tName.length > 0 && e.tLastName.length > 0 && e.checkEmiratesIdState === false) {
            setFirstStep(true)
        } else { setFirstStep(false) }

    }
    const contactInformation = (e: any) => {
        setContact(e)
        e = JSON.parse(e)
        if (e.cName.length > 0 && e.contactNumber.length > 0 && e.relation.length > 0 && e.nationality.length > 0) {
            setSecondStep(true)
        } else { setSecondStep(false) }

    }
    const uploadDocuments = (e: any) => {
        if (offerList?.modeOfPayment === "PAY_BY_EMP") {
            if (e.emiratesIdFront?.name?.length > 0 && e.emiratesIdBack?.name?.length > 0 && e.passport?.name?.length > 0 && e.passportBack?.name?.length > 0 && e.letter?.name?.length > 0) {
                setThirdStep(true)
                setEmiratesIdFront(e.emiratesIdFront)
                setEmiratesIdBack(e.emiratesIdBack)
                setPassport(e.passport)
                setPassportBack(e.passportBack)
                setVisa(e.visa)
                setLetter(e.letter)
            } else { setThirdStep(false) }
        } else if (e.emiratesIdFrontAlreadyExists && e.emiratesIdBackAlreadyExists && e.passportAlreadyExists && e.passportBackAlreadyExists) {
            // if (e.emiratesIdFront?.name?.length > 0 && e.emiratesIdBack?.name?.length > 0 && e.passport?.name?.length > 0 && e.passportBack?.name?.length > 0 && e.letter?.name?.length > 0) {
            setThirdStep(true)
            setEmiratesIdFront(e.emiratesIdFront)
            setEmiratesIdBack(e.emiratesIdBack)
            setPassport(e.passport)
            setPassportBack(e.passportBack)
            setVisa(e.visa)
            setLetter(e.letter)
            // } else { setThirdStep(false) }
        } else {
            if ((e.emiratesIdFrontAlreadyExists || e.emiratesIdFront?.name?.length > 0)
                && (e.emiratesIdBackAlreadyExists || e.emiratesIdBack?.name?.length > 0)
                && (e.passportAlreadyExists || e.passport?.name?.length > 0)
                && (e.passportBackAlreadyExists || e.passportBack?.name?.length > 0)) {
                    setThirdStep(true)
                    setEmiratesIdFront(e.emiratesIdFront)
                    setEmiratesIdBack(e.emiratesIdBack)
                    setPassport(e.passport)
                    setPassportBack(e.passportBack)
                    setVisa(e.visa)
                    setLetter(e.letter)
            } else { setThirdStep(false) }
        }

    }
    const validate = (values: any, type: any) => {
        values = JSON.parse(values)
        const errors: any = {};
        if (type === 1) {
            if (!values?.emiratesID) {
                errors.emiratesID = t("Emirates ID Is Requried.");
            } else {
                errors.emiratesID = "";
            }
            if (values?.emiratesID?.length < 15) {
                errors.emiratesID = t("Please enter a valid Emirates ID");
            } else {
                errors.emiratesID = "";
            }
            if (!values?.emiratesIdExpiry1) {
                errors.emiratesIdExpiry1 = t("Emirates ID Expiry Date Is Requried.");
            }
            if (!values?.passport) {
                errors.passport = t("Passport Number Is Requried.");
            } else {
                errors.passport = "";
            }
            if (!values?.passportExpiry1) {
                errors.passportExpiry1 = t("Passport ID Expiry Date  Is Requried.");
            }
            if (!values?.tName) {
                errors.tName = t("First Name Is Requried.");
            } else {
                errors.tName = "";
            }
            if (!values?.tLastName) {
                errors.tLastName = t("Last Name Is Requried.");
            } else {
                errors.tLastName = "";
            }
            if (!values?.dob1) {
                errors.dob1 = t("DOB Is Requried.");
            }
        }
        else if (type === 2) {
            if (!values?.cName) {
                errors.cName = t("Name Is Requried.");
            } else {
                errors.cName = "";
            }
            if (!values?.contactNumber) {
                errors.contactNumber = t("Contact Number is Requried.");
            } else {
                errors.contactNumber = "";
            }
            if (!values?.relation && values?.relation != t("Select Relation")) {
                errors.relation = t("Relation is Requried.");
            } else {
                errors.relation = "";
            }
            if (!values?.nationality && values?.nationality != t("Select Nationality")) {
                errors.nationality = t("Nationality is Requried.");
            } else {
                errors.nationality = "";
            }
        }
        return errors;

    }
    return (
        <><div className="row">
            <div className="col-md-12 ">
                <Stepper activeStep={activeStep} className="multiform-stepper">
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {}
                        const labelProps: {
                            optional?: React.ReactNode
                        } = {}
                        // if (isStepOptional(index)) {
                        //     labelProps.optional = (
                        //         <p>Optional</p>
                        //     );
                        // }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false
                        }
                        return (
                            <Step className={activeStep == index ? "active" : ""} key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                <React.Fragment>
                    <div>
                        {activeStep === 0 ?
                            <StepOne tenantDetails={tenantDetails} tenant={tenant} formErrors={formErrors} /> :
                            activeStep === 1 ?
                                <StepTwo contactInformation={contactInformation} contact={contact} formErrors={formErrors} /> :
                                activeStep === 2 ?
                                    <StepThree uploadDocuments={uploadDocuments} setUploading={setUploading}/> :
                                    <StepSuccessful
                                        onExtendOfferStepperTwo={props.onExtendOfferStepperTwo} />}
                    </div>
                    {/*<div>*/}
                    {/*{isStepOptional(activeStep) && (*/}
                    {/*<button onClick={handleSkip}>*/}
                    {/*Skip*/}
                    {/*</button>*/}
                    {/*)}*/}
                    {/*</div>*/}

                    {loading ? <CustomLoaderCompact /> : null}
                    <div className='row stepper-actions'>
                        <div className='row col-lg-12 text-end p-0'>
                            <div className='col-lg-6 text-end p-0 pe-md-3 mb-3 mb-md-0'>
                                {activeStep === 3 ?
                                    null
                                    :
                                    <>
                                        {activeStep === 0 ?
                                            null :
                                            <button
                                                onClick={handleBack}
                                                className='btn btn-outline-primary btn-lg px-12 py-3 me-3 btn-back d-none'
                                                style={{ display: activeStep === 0 ? 'none' : 'inline-block' }}
                                            >
                                                {activeStep === 0 ? t("Cancel") : t("Back")}
                                            </button>
                                        }
                                        {loading === false ? <button
                                            onClick={activeStep === 2 ? handleBack : handleBack}
                                            className='btn btn-primary fw-bold px-12 py-3'
                                            style={{ display: activeStep === 0 ? 'none' : 'inline-block' }}
                                        // disabled={firstStep ? false : true}
                                        >
                                            {activeStep === 2 ? t("Back") : t("Back")}
                                        </button> : <button
                                            onClick={activeStep === 2 ? handleBack : handleBack}
                                            className='btn btn-primary fw-bold px-12 py-3'
                                        >
                                            {activeStep === 2 ? t("Back") : t("Back")}
                                        </button>}
                                    </>}
                            </div>
                            <div className='col-lg-6 text-end p-0'>
                                {activeStep === 3 ?
                                    null
                                    :
                                    <>
                                        {activeStep === 0 ?
                                            null :
                                            <button
                                                onClick={handleBack}
                                                className='btn btn-outline-primary btn-lg px-12 py-3 me-3 btn-back d-none'
                                            >
                                                {activeStep === 0 ? t("Cancel") : t("Back")}
                                            </button>}
                                        {loading === false && uploading === false ? <button
                                            onClick={activeStep === 2 && thirdStep ? handleSubmit : handleNext}
                                            className='btn btn-primary fw-bold px-12 py-3'
                                        // disabled={firstStep ? false : true}
                                        >
                                            {activeStep === 2 ? t("Submit") : t("Next")}
                                        </button> : <button
                                            onClick={activeStep === 2 && thirdStep ? handleSubmit : handleNext}
                                            className='btn btn-primary fw-bold px-12 py-3'
                                            disabled={true}
                                        >
                                            {activeStep === 2 ? t("Submit") : t("Next")}
                                        </button>}
                                    </>}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </div><p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                {/*  */}
            </p></>
    );
}