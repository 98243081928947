import React, { useState, useEffect, useRef } from 'react'
import Place from '../../../../../assets/images/place.svg'
import SquareFt from '../../../../../assets/images/icon-sqft.svg'
import Bed from '../../../../../assets/images/icon-bed.svg'
import Bath from '../../../../../assets/images/icon-bath.svg'
import Cars from '../../../../../assets/images/icon-parking.svg'
import TickIcon from '../../../../../assets/images/Check_green.png'
import CancelIcon from '../../../../../assets/images/Close_red.png'
import { useLocation } from 'react-router-dom'
import { cancelScheduleVisit, myLikes } from '../../../../../constants/axios/apis'
import { Alert } from 'react-bootstrap-v5'
import * as dashboard from '../../../../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import * as PTOffer from '../../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import NoDataFoundImage from '../../../../../assets/images/no-data-found.svg'
import { MdOutlineClose } from "react-icons/md";
import { RootState } from '../../../../../constants'
import { ssoUserDetailswithLocations } from './../../../../../constants/generics/sso';
import { CustomLoader1, currencyFormater, validateCommercialBed } from '../../../../../constants/generics/customActios'
import { CustomViewPopup } from './../../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/ai";
import { FeedbackDrawer } from '../../../../../components/partials'
import { formatDate } from '../../../../../constants/generics/dateTimeFormater'
import { formatTimeOnly } from '../../../../../constants/generics/dateTimeFormater'
import { formatTimeMeridiem } from '../../../../../constants/generics/dateTimeFormater'
import { ReservedPropertyPopup } from '../../../../team-activity/components/VisitsPage/ReservedPropertyPopup/ReservedPropertyPopup'

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

export function UpcomingCard(props: any) {
	const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
	const ref = useRef<any>(0)
	const ref3 = useRef<any>(0)
	const dispatch = useDispatch()
	const [isDisplayed, setIsDisplayed] = useState(false);
	const [show, setShow] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')
	const [message, setMessage] = useState<any>()
	const [cancelId, setCancelId] = useState<any>()
	const location = useLocation()
	const [scheduleData, setScheduleData] = useState<any>('')
	const [viewImage, setViewImage] = useState<any>()
	const { data, offerId } = props
	const { t } = useTranslation();
	const [visitReviewData, setVisitReviewData] = useState<any>()

	let userEmail = ''
	let userId = ''
	let userDetail = localStorage.getItem('userDetail')
	if (userDetail) {
		userEmail = JSON.parse(userDetail).email
		userId = JSON.parse(userDetail).id
	}
	useEffect(() => {
		setInterval(() => {
			setIsDisplayed(true);
		}, 1000);
	}, []);
	const addScheduleData = (data: any) => {
		setScheduleData(data)
	}
	useEffect(() => {
		dispatch(dashboard.actions.scheduleData(scheduleData))
	}, [scheduleData])
	function onChange(e: any) {
		var postdata = {
			user_id: userId,
			property_id: e.target.value
		}
		myLikes(postdata).then((data) => {

			if (data.code === 200 && data.errorCode != 1) {
				dispatch(dashboard.actions.likes())
				setSuccess(data?.description)
				setShowSuccess(true)
				setLoading(false)
				setTimeout(() => {
					setShowSuccess(false)
				}, 2000)
			} else {
				setError(data?.errorDescription)
				setShow(true)
				setLoading(false)
				setTimeout(() => {
					setShow(false)
				}, 2000)
			}
		})
	}

	const onCancel = (id: any) => {
		cancelScheduleVisit(id).then((data) => {
			if (data.code === 200 && data.errorCode != 1) {
				dispatch(dashboard.actions.upcomingVisits())
				setMessage({
					error: 0,
					message: data.description,
				})
				ref.current.click();
			} else {
				setMessage({
					error: 1,
					message: data.errorDescription,
				})
				ref.current.click();
			}
		});
	}

	useEffect(() => {
		if (message?.message?.length > 0) {
			dispatch(PTOffer.actions.modalmessage(message))
		}
	}, [message])

	const handleFind = (params: any) => {
		const pId = params?.property_id > 0 ? params?.property_id : params?.propertyId
		const Pname = params?.propertyName?.replace(/\s+/g, "-")?.toLowerCase()
		const location = `/properties/${Pname}/${pId}`

		window.location.assign(`${ssoUserDetailswithLocations(userDetail, accessToken, location)}`);
	}
	const handlePreviewViewDoc = (data: any) => {
		setViewImage(data)
		ref3.current.click();
	}
	if (!isDisplayed) return <CustomLoader1 height={30} />;
const broker_array = [
    { brokerName: "Vishnu", mobile: "1234567890", name: "Bob" },
    { brokerName: "Amit", mobile: "0987654321", name: "Jane" },
    { brokerName: "Karan", mobile: "5678901234", name: "Eva" },
    { brokerName: "Vishnu", mobile: "2345678901", name: "Grace" },
    { brokerName: "Amit", mobile: "8901234567", name: "Charlie" },
    { brokerName: "Karan", mobile: "3456789012", name: "Hank" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Alice" },
    { brokerName: "Amit", mobile: "6789012345", name: "Frank" },
    { brokerName: "Karan", mobile: "7890123456", name: "David" },
    { brokerName: "Vishnu", mobile: "0123456789", name: "John" },
    { brokerName: "Amit", mobile: "9012345678", name: "Eva" },
    { brokerName: "Karan", mobile: "6789012345", name: "Bob" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Jane" },
    { brokerName: "Amit", mobile: "2345678901", name: "Charlie" },
    { brokerName: "Karan", mobile: "8901234567", name: "Hank" }
  ]
	//Filter visit data
	const cardData = data?.data && Array.isArray(data?.data) && data?.data?.length ?
		(location.pathname === '/pt-activity/my-visit/upcoming') ?
			data?.data.filter((item: any) => ([1, 3, 4].includes(item?.event_status) && item?.visit_date >= moment().format('YYYY-MM-DD'))) :
			(location.pathname === '/pt-activity/my-visit/visited') ?
				data?.data.filter((item: any) => (item?.event_status != 1)) :
				data?.data : []


	return (
		<><div className='row'>
			{isDisplayed && <>
				<div className='col-lg-12 col-12'>
					<div className="row">
						{show ? (
							<Alert className='font-14 fw-bold mb-8' variant='danger'>
								{error}!
							</Alert>
						) : null}
						{showSuccess ? (
							<Alert className='font-14 fw-bold mb-8' variant='success'>
								{success}
							</Alert>
						) : null}
						{/* reverse(). */}
						{data && data?.errorCode != 1 && data?.description != 'No data found.' && cardData?.length > 0
							? cardData.map((item: any, i: any) => {
								// if (location.pathname === '/pt-activity/my-visit/upcoming' ? item?.visit_date >= moment().format('YYYY-MM-DD') : true)
								// if (location.pathname === '/pt-activity/my-visit/upcoming' ? ([1,3,4].includes(item?.event_status) && item?.visit_date >= moment().format('YYYY-MM-DD')) : true)
								// if (location.pathname === '/pt-activity/my-visit/visited' ? item?.event_status != 1 : true)
								return (
									<div className="col-lg-6 col-12">
										<div className='pm-offers-and-renewals mb-10 mt-1' key={i}>
											<div className='card'>
												<div className='row'>
													<div className='col-sm-12 col-12 col-lg-12'>
														<div className='offer_main_img h-100 img-bg'>
															<div className="img-inner">
																{window.location.pathname === '/pt-activity/my-offer' ? (
																	<div>
																		<img
																			src={item?.property_cover_img}
																			alt='offers'
																			className='img-fluid maria'
																			onClick={() => offerId(item?.id)}
																			style={{ cursor: 'pointer' }} />
																	</div>

																) : window.location.pathname === '/pt-activity/my-shortlist' || window.location.pathname === '/pt-activity/my-visit/upcoming' || window.location.pathname === '/pt-activity/my-visit/visited' ? (
																	item?.isOffered === '0' ? (<img
																		src={item?.property_cover_img}
																		alt='Likes'
																		className='img-fluid'
																		onClick={() => handleFind(item)}
																		style={{ cursor: 'pointer' }} />) :
																		<>
																			<img
																				src={item?.property_cover_img}
																				alt='Likes'
																				className='img-fluid'
																				title='This property is already offered.'
																				style={{ cursor: 'not-allowed' }} />
																			<span className="badge badge-pill badge-dark prop-type-badge">{item?.property_search_type === 1 ? t("Residential") : item?.property_search_type === 2 ? t("Commercial") : item?.property_search_type}</span>
																		</>
																) : (
																	<img
																		src={item?.property_cover_img}
																		alt='offers'
																		className='img-fluid' />
																)}
															</div>
															<div className='property-overlay'>
																<div className="row align-items-center g-0">
																	<div className="col-6">
																		<h6>{item?.property_search_type === 1 ? t("Residential") : item?.property_search_type === 2 ? t("Commercial") : item?.property_search_type}</h6>
																	</div>
																	<div className="col-6 text-end">
																		<span className='price-amount d-block font-12'>
																			{t("AED")} <strong className="font-16">{item?.price?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</strong>  / {t("Year")}
																		</span>
																	</div>
																</div>
															</div>
															{window.location.pathname === '/pt-activity/my-shortlist' ? (
																<div className='text-center like-icon'>
																	<div className='form-check pt-my-links ss'>
																		<label className='form-check-label' htmlFor='flexCheckDefault'>
																			<input
																				className='form-check-input'
																				type='checkbox'
																				id='flexCheckDefault'
																				checked
																				onChange={(e) => onChange(e)}
																				value={item?.property_id} />
																		</label>
																	</div>
																</div>
															) : null}
															{/* <span className="badge badge-pill badge-dark prop-type-badge">{item?.property_search_type === 1 ? t("Residential") : item?.property_search_type === 2 ? t("Commercial") : item?.property_search_type}</span> */}
														</div>
													</div>
													<div className='col-lg-12 col-12 align-self-center'>
														<div className='offer_main_details p-3'>
															<div className='col-12 pt-3'>
																<p className='text-black fw-bolder font-17 text-title my-offer-property-name mb-3'>
																	{item?.property_name}
																</p>
																{/* <h2 className='text-black fw-bolder mb-3 text-sub-title'>
																	<small>{t("AED")}</small> <span className='fw-boldest'>{currencyFormater(item?.price)}</span> <small> / {t("Year")} </small>
																</h2> */}
															</div>
															<div className='col-12 offer_main_details_div_3 mb-3'>
																{window.location.pathname === '/pt-activity/my-offer' || window.location.pathname === '/pt-activity/my-contracts' ? (<span className="mb-0 mb-3 fw-bolder d-inline-block">{t("Offer No:")} {item?.offer_number}</span>) : ''}
																<p className='mb-3'>
																	<img src={Place} className='img-fluid pe-2' alt="place" />
																	{/* {item?.address} | {item?.location} */}
																	{item?.propertyCode?.substring(item?.propertyCode?.lastIndexOf("-") + 1, item?.propertyCode?.length)} {item?.buildingName && ' | ' + item?.buildingName}  {item?.address && ' | ' + item?.address}  {item?.location && ' | ' + item?.location}
																</p>
															</div>
															<div className='col-12'>
																<div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
																	{(!validateCommercialBed || item?.property_search_type != 2) &&
																		<>
																			{item?.bedrooms?.length > 0 && item?.bedrooms != 0 ? (
																				<p className='ps-0 pe-3 py-3 me-2 mb-0'>
																					<img src={Bed} className='img-fluid me-3' alt="bed" /> {item?.bedrooms}{' '} &nbsp; Bedrooms
																				</p>
																			) : (
																				<></>
																			)
																			}
																		</>
																	}
																	{item?.bathrooms?.length > 0 && item?.bathrooms != 0 ? (
																		<p className='ps-0 pe-3 py-3 me-2 mb-0'>
																			<img src={Bath} className='img-fluid me-3' alt="bath" /> {item?.bathrooms}{' '} &nbsp; Bathrooms
																		</p>
																	) : (
																		<></>
																	)
																	}
																	{item?.builtInArea > 0 && item?.builtInArea != 0 ? (
																		<p className='ps-0 pe-3 py-3 me-2 mb-0 SquareFt-my-offer'>
																			<img src={SquareFt} className='img-fluid me-2' alt="SquareFt" /> {item?.builtInArea}{' '}
																			{t("Sqmt")}
																		</p>
																	) : (
																		<></>
																	)
																	}
																	<p className='ps-0 pe-0 py-3 me-2 mb-0'>
																		<img src={Cars} className='img-fluid me-2' alt="yes" />{' '}
																		{item?.isParkingAvailable === 'Y' ? t("Yes") : t("No")}
																	</p>
																</div>
																<div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
																	<p className='p-3 me-2'>
																		Broker : {t(broker_array[i].brokerName)}
																	</p>
																	<p className='p-3'>
																		User Name : {t(broker_array[i].name)}
																	</p>
																	<p className='p-3 me-2 sqft-my-offer'>
																		User Mobile : {t(broker_array[i].mobile)}
																	</p>
																</div>
																{location.pathname !== '/pt-activity/my-visit/upcoming' ?
																<button
																	className='btn btn-primary font-13 fw-bold w-100 py-2 px-2'
																	data-bs-target='#reserved-popup'
																	data-bs-toggle='modal'
																	data-bs-dismiss='modal'
																>
																	{t("Reserve")}
																</button>
																: ''
																}

															</div>
														</div>
													</div>
													{location.pathname === '/pt-activity/my-visit/upcoming' ? (
														<div className='col-lg-12 col-12 align-self-center'>
															<div className='text-center p-3'>
																<div className='col-12'>
																	<div className='visit-confirmed-date-di rounded mb-7'>
																		<div className='row align-items-center'>
																			{/*<div className='col-6'>*/}
																			{/*<p className='text-black mb-0'> Date Of Visit </p>*/}
																			{/*</div>*/}
																			<div className="col-md-6 col-12">
																				<p className='text-black fw-bolder font-15 mb-0 text-start'>
																					{/* <i className='bi bi-check-circle-fill font-17 visit-confirmed me-3'></i>{' '}
																							{t("Visit Confirmed")} */}
																					<span className='bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'>{t('Visit Confirmed')}</span>
																				</p>
																			</div>
																			<div className='col-md-6 col-12 text-end'>
																				<p className='text-black text-md-end text-start fw-bold mb-0 mt-3 mt-md-0'>
																					<span className="me-3">{item?.visit_date ? formatDate(item?.visit_date) : '-'}</span>
																					{/* <span>{item?.visitTime}</span> */}
																					<span>{item?.visitTime ? formatTimeMeridiem(item?.visitTime) : "-"}</span>
																				</p>
																			</div>
																		</div>
																	</div>
																</div>
																{/*<div className='col-12'>*/}
																{/*<div className='visit-confirmed-date-div rounded p-3'>*/}
																{/*<div className='row'>*/}
																{/*<div className='col-6'>*/}
																{/*<p className='text-black mb-0'> Time Of Visit </p>*/}
																{/*</div>*/}
																{/*<div className='col-6'>*/}
																{/*<p className='text-black mb-0'> {item?.visitTime} </p>*/}
																{/*</div>*/}
																{/*</div>*/}
																{/*</div>*/}
																{/*</div>*/}
																<div className="col-12">
																	<div className="row g-3">
																		<div className="col-md-3 col-sm-4">
																			<button className='btn btn-outline-primary font-13 fw-bold w-100 py-2 px-2 border-1'
																				onClick={() => handlePreviewViewDoc(item?.qrCode)}>
																				{t("QR Code")}
																			</button>
																		</div>
																		<div className="col-md-3 col-sm-4">
																			<button className='btn btn-outline-primary font-13 fw-bold w-100 py-2 px-2 border-1'
																				data-bs-target='#rescheduleCancel-popup'
																				data-bs-toggle='modal'
																				data-bs-dismiss='modal'
																				onClick={() => setCancelId(item?.id)}>
																				{t("Cancel Visit")}
																			</button>
																			{/* <button
																					className='btn btn-primary font-13 fw-bold w-100 py-2 px-2'
																					data-bs-target='#rescheduleCancel-popup'
																					data-bs-toggle='modal'
																					data-bs-dismiss='modal'
																				>
																					Cancel
																				</button> */}
																		</div>
																		<div className="col-md-3 col-sm-4">
																			<button
																				className='btn btn-primary font-13 fw-bold w-100 py-2 px-2'
																				data-bs-target='#reschedule-popup'
																				data-bs-toggle='modal'
																				data-bs-dismiss='modal'
																				onClick={() => addScheduleData(item)}
																			>
																				{t("Reschedule")}
																			</button>
																		</div>
																		<div className="col-md-3 col-sm-4">
																			<button
																				className='btn btn-primary font-13 fw-bold w-100 py-2 px-2'
																				data-bs-target='#reserved-popup'
																				data-bs-toggle='modal'
																				data-bs-dismiss='modal'
																			>
																				{t("Reserve")}
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													) : location.pathname === '/pt-activity/my-visit/visited' ? (
														<div className='col-sm-12 col-12 align-self-center'>
															<div className='row'>
																<div className='col-12'>
																	<div className='p-4 d-flex align-items-center justify-content-between'>
																		<div className='text-left'>
																			<p className='text-black fw-bolder font-17 m-0'>
																				{/* {item?.event_status == 1 && <img src={TickIcon} className='me-2' alt="img" />}
																			{item?.event_status == 6 && <AiFillCloseCircle color='red' />}
																			{item?.event_status == 1 ? t("Visit Confirmed")
																				: item?.event_status == 2 ? t("Visit Completed")
																					: item?.event_status == 3 ? t("In progress")
																						: item?.event_status == 4 ? t("Reschedule")
																							: item?.event_status == 5 ? t("Visit Expired")
																								: item?.event_status == 6 ? t("Visit Cancelled")
																									: ""} */}
																				<span
																					className={
																						item?.event_status === 2
																							? 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
																							: item?.event_status === 4
																								? 'bg-info bg-opacity-10 text-info px-5 py-3 rounded fw-bolder'
																								: item?.event_status === 5
																									? 'bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
																									: item?.event_status === 1
																										? 'bg-success bg-opacity-10 text-primary px-5 py-3 rounded fw-bolder'
																										: item?.event_status === 3
																											? 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
																											: item?.event_status === 6
																												? 'bg-warning bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
																												: 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
																					}
																				>
																					{item?.event_status == 1
																						? t('Visit Confirmed')
																						: item?.event_status == 2
																							? t('Visit Completed')
																							: item?.event_status == 3
																								? t('In progress')
																								: item?.event_status == 4
																									? t('Reschedule')
																									: item?.event_status == 5
																										? t('Visit Expired')
																										: item?.event_status == 6
																											? t('Visit Cancelled')
																											: ''}
																				</span>
																			</p>
																			{item?.event_status == 2 && <button
																				className='btn btn-light font-14 fw-bold px-5 py-3'
																				data-bs-target={item?.rateUs ? '#feedback-data' : '#feedback-popup'}
																				data-bs-toggle='modal'
																				data-bs-dismiss='modal'
																				onClick={() => setVisitReviewData(item)}
																			>
																				{item?.rateUs ? t("Feedback") : t("Rate Us")}
																			</button>}
																		</div>
																		<div className="">
																			{item?.isOffered === '0' ?
																				<button
																					className='btn btn-primary font-13 fw-bold w-100 py-2 px-2'
																					type="button"
																					onClick={() => handleFind(item)}
																				>
																					{item?.property_search_type === 2 ? t("Submit Interest") : t("Make an Offer")}
																				</button>
																				:
																				<button
																					className='btn btn-primary font-13 fw-bold w-100 px-5 py-3'
																					type="button"
																					style={{ cursor: 'not-allowed' }}
																					title='This property is already offered.'
																				>
																					{t("Make an Offer")}
																				</button>
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													) : location.pathname === '/pt-activity/my-offer' ? (
														<div className='col-12 align-self-center'>
															<div className="card-status">
																<span className={item?.offer_status === '1' ? 'approved' : item?.offer_status === '2' || item?.offer_status === '4' ? 'rejected' : 'pending'}>
																	{item?.offer_status === '1' ? t("Approved") : item?.offer_status === '2' ? t("Rejected") : item?.offer_status === '4' ? t("Cancelled") : t("Pending")}
																</span>
															</div>
															<div className='text-center offer-price-sec d-flex align-items-center px-3 pb-3'>
																<p className='text-black fw-bolder font-14 mb-1 text-title me-2'>{t("Offer Price")}</p>
																<span> - </span>
																<h1 className='text-black fw-bolder font-14 mb-1 text-sub-title ms-3'>
																	<small>{t("AED")}</small>{' '}
																	<span className='fw-boldest'>{item?.rent_amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
																</h1>
															</div>
														</div>
													) : null}
												</div>
											</div>
										</div>
									</div>

								)
							})
							:
							<div className="col-12">
								<div className="card border-0 shadow-none rounded-0 no-data-found-cd">
									<div className="card-body border-0">
										<div className="row justify-content-center">
											<div className="col-12 text-center">
												<img src={NoDataFoundImage} alt="No Data Found" className="img-fluid" />
											</div>
											<div className="col-12 text-center">
												<span className='d-block text-center font-16 fw-bolder text-black'> {t("No Data Found")} </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						<FeedbackDrawer visitId={visitReviewData?.id} />
						<div className="modal fade feed-design" id="feedback-data">
							<div className="modal-dialog modal-dialog-centered">
								<div className="modal-content rounded-10">
									<div className="modal-header border-0">
										<h5 className="modal-title fw-bold">{t("Feedback")}</h5>
										<button type="button" className="btn-close text-black" data-bs-dismiss="modal"></button>
									</div>
									<div className="modal-body px-10 pb-0">
										<div className="row">
											<div className="col-12">
												<div className="satis-wdw">
													<label className="fw-normal text-black font-12 mb-1"> {t("Workmanship")} </label>
													<div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
														<input type="radio" className="btn-check" checked={visitReviewData?.rateUs?.workmanship === 1} disabled={true} />
														<label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis">
															<span className="very-satis-ioc comm-icon me-2"></span>
															{t("Very Satisfied")}
														</label>

														<input type="radio" className="btn-check" checked={visitReviewData?.rateUs?.workmanship === 2} disabled={true} />
														<label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="satis">
															<span className="satis-ioc comm-icon me-2"></span>
															{t("Satisfied")}
														</label>

														<input type="radio" className="btn-check" checked={visitReviewData?.rateUs?.workmanship === 3} disabled={true} />
														<label className="btn btn-outline-primary border-0 p-0 font-10 fw-bold text-black" htmlFor="notsatis">
															<span className="not-satis-ioc comm-icon me-2"></span>
															{t("Not Satisfied")}
														</label>
													</div>
													<label className="fw-normal text-black font-12 mb-1"> Punctuality </label>
													<div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
														<input type="radio" className="btn-check" checked={visitReviewData?.rateUs?.punctuality === 1} disabled={true} />
														<label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis-one">
															<span className="very-satis-ioc comm-icon me-2"></span>
															{t("Very Satisfied")}
														</label>

														<input type="radio" className="btn-check" checked={visitReviewData?.rateUs?.punctuality === 2} disabled={true} />
														<label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="satis-one">
															<span className="satis-ioc comm-icon me-2"></span>
															{t("Satisfied")}
														</label>

														<input type="radio" className="btn-check" checked={visitReviewData?.rateUs?.punctuality === 3} disabled={true} />
														<label className="btn btn-outline-primary border-0 p-0 font-10 fw-bold text-black" htmlFor="notsatis-one">
															<span className="not-satis-ioc comm-icon me-2"></span>
															{t("Not Satisfied")}
														</label>
													</div>
													<label className="fw-normal text-black font-12 mb-1"> {t("Quality of work")} </label>
													<div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
														<input type="radio" className="btn-check" checked={visitReviewData?.rateUs?.quality_of_work === 1} disabled={true} />
														<label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis-two">
															<span className="very-satis-ioc comm-icon me-2"></span>
															{t("Very Satisfied")}
														</label>

														<input type="radio" className="btn-check" checked={visitReviewData?.rateUs?.quality_of_work === 2} disabled={true} />
														<label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="satis-two">
															<span className="satis-ioc comm-icon me-2"></span>
															{t("Satisfied")}
														</label>

														<input type="radio" className="btn-check" checked={visitReviewData?.rateUs?.quality_of_work === 3} disabled={true} />
														<label className="btn btn-outline-primary border-0 p-0 font-10 fw-bold text-black" htmlFor="notsatis-three">
															<span className="not-satis-ioc comm-icon me-2"></span>
															{t("Not Satisfied")}
														</label>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className="mb-6">
													<textarea
														className='form-control fw-bold font-12 form-control-solid'
														data-kt-autosize='true'
														rows={1}
														value={visitReviewData?.rateUs?.additional_feedback}
														disabled={true}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>}
		</div>
		<ReservedPropertyPopup/>
			<div className='modal fade custom-modal' data-bs-backdrop="static" data-bs-keyboard="false" id='rescheduleCancel-popup'>
				<div className='modal-dialog modal-dialog-centered'>
					<div className='modal-content rounded-10'>
						<div className='modal-header border-0 py-4'>
							<h5 className='modal-title fw-bold'> {t("Cancel Visit")}</h5>
							<button
								type='button'
								className='btn-close'
								data-bs-dismiss='modal'
							>&times;</button>
						</div>
						<div className='modal-body px-10 pb-0'>
							<div className='row mt-6'>
								<div className='col-12'>
									<div className='mb-6'>
										<div className="text-center flex-css justify-content-center">
											<MdOutlineClose color='red' fontSize="3.5em" />
										</div>
										<h3 className='text-dark fw-bolder text-center flex-css justify-content-center mt-4 font-18'>{t("Are you sure?")}</h3>
									</div>
									<div className="text-center flex-css justify-content-center">
										<p className='font-14 text-black fw-normal'> {t("Do you really want to cancel this visit?")} <br /> {t("This process cannot be undone.")} </p>
									</div>
								</div>
							</div>
						</div>
						<div className='modal-footer border-0 flex-css justify-content-center'>
							<button
								type='button'
								className='btn btn-primary fw-bold px-10 py-3 me-5'
								data-bs-dismiss='modal'
								onClick={() => onCancel(cancelId)}
							>
								{t("Yes")}
							</button>
							<button
								type='button'
								className='btn btn-outline-primary fw-bold px-10 py-3'
								data-bs-dismiss='modal'
							>
								{t("No")}
							</button>
						</div>
					</div>
				</div>
			</div><p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
				{/*  */}
			</p><CustomViewPopup viewImage={viewImage} />
			<p ref={ref3} data-bs-target="#view-customPopUp" data-bs-toggle="modal" data-bs-dismiss="modal">
				{/*  */}
			</p></>
	)
}
