import React, {useState, useEffect, useRef} from 'react'
import MPSlide1 from '../../../../assets/images/relax-area-resort.png'
import MPOfferImg from '../../../../assets/images/relax-area-resort-short.png'
import RefreshIcon from '../../../../assets/images/refresh-icon.png'
import Image01 from '../../../../assets/images/marketplace-offers.svg'
import Image02 from '../../../../assets/images/marketplace-vouchers.svg'
import {Carousel} from 'bootstrap'
import {Link} from 'react-router-dom'
import { RedeemOfferPopup } from '../RedeemOfferPopup/RedeemOfferPopup'

type Props = {
  marketPlaceTopOffers?: any
}

const MarketPlaceOffer: React.FC<Props> = ({ marketPlaceTopOffers }) => {
  const [selectedOffer, setSelectedOffer] = useState([])
  const carouselRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const element = carouselRef.current
    if (!element) {
      return
    }
    const carousel = new Carousel(element)
    return function cleanUp() {
      carousel.dispose()
    }
  }, [])

  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }

  const content = marketPlaceTopOffers?.data?.map((item: any, index: any) => (
    <div key={item.id} className={`carousel-item ${index == 0 ? 'active' : ''}`}>
      <div className='row g-0'>
        <div className='col-sm-7'>
          <img src={item.banner} className='d-block w-100' alt='slider1' />
        </div>
        <div className='col-sm-5'>
          <div className='mp-carousel-text d-flex flex-column align-items-center justify-content-center p-5 h-100'>
            <h4 className='text-white font-24 position-relative'>{item.name}</h4>
            <h5 className='text-white font-34'>Get {item.offer}</h5>
            {/* <p className='text-white font-24'>+Off on pre EMI</p> */}
            <button
              className='mp-redeem-button'
              data-bs-toggle='modal'
              data-bs-target='#redeem-offer'
              onClick={() => setSelectedOffer(item)}
            >
              Redeem
            </button>
          </div>
        </div>
      </div>
    </div>
  ))

  return (
    <>
      <div className='com-card-section'>
        <div className='row align-items-center'>
          <div className='col-7'>
            <h4 className='card-title font-18 text-black mb-6'> Market Place </h4>
          </div>
          {userEmail === 'commercialtenant@exalogic.co' ? (
            <div className='col-5 text-end'>
              <Link
                to={'/market-place/add-voucher'}
                className='btn btn-primary btn-sm font-16 mb-6'
              >
                <i className='bi bi-plus-circle-fill align-middle'></i>
                Add
              </Link>
            </div>
          ) : null}
        </div>
        <div className='col-12'>
          {userEmail === 'commercialtenant@exalogic.co' ? (
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='total-parking-info'>
                  <div className='row'>
                    <div className='col-md-6 col-lg-4 col-12'>
                      <div className='card'>
                        <div className='card-body py-6 px-6'>
                          <div className='row align-items-center'>
                            <div className='col-4'>
                              <div className='icon-sh px-0 py-3 rounded-5 text-center bg-white'>
                                <img src={Image01} className='img-fluid' />
                              </div>
                            </div>
                            <div className='col-8'>
                              <h6 className='font-34 text-black fw-bolder mb-0'>
                                185 <span className='font-14 fw-normal'> Vouchers </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-4 col-12'>
                      <div className='card mt-4 mt-md-0'>
                        <div className='card-body py-6 px-6'>
                          <div className='row align-items-center'>
                            <div className='col-4'>
                              <div className='icon-sh px-0 py-3 rounded-5 text-center bg-white'>
                                <img src={Image02} className='img-fluid' />
                              </div>
                            </div>
                            <div className='col-8'>
                              <h6 className='font-34 text-black fw-bolder mb-0 mx-3'>
                                120 <span className='font-14 fw-normal'> Offers </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='row'>
                  <div className='col-12 col-md-12'>
                    <div className='row gx-10'>
                      <div className='col-sm-8 col-md-9'>
                        <div
                          id='carouselExampleCaptions'
                          className='carousel slide MPCarousel'
                          data-bs-ride='carousel'
                          ref={carouselRef}
                        >
                          <div className='carousel-indicators'>
                            <button
                              type='button'
                              data-bs-target='#carouselExampleCaptions'
                              data-bs-slide-to='0'
                              className='active'
                              aria-current='true'
                              aria-label='Slide 1'
                            ></button>
                            <button
                              type='button'
                              data-bs-target='#carouselExampleCaptions'
                              data-bs-slide-to='1'
                              aria-label='Slide 2'
                            ></button>
                            <button
                              type='button'
                              data-bs-target='#carouselExampleCaptions'
                              data-bs-slide-to='2'
                              aria-label='Slide 3'
                            ></button>
                            <button
                              type='button'
                              data-bs-target='#carouselExampleCaptions'
                              data-bs-slide-to='3'
                              aria-label='Slide 2'
                            ></button>
                            <button
                              type='button'
                              data-bs-target='#carouselExampleCaptions'
                              data-bs-slide-to='4'
                              aria-label='Slide 3'
                            ></button>
                            <button
                              type='button'
                              data-bs-target='#carouselExampleCaptions'
                              data-bs-slide-to='5'
                              aria-label='Slide 2'
                            ></button>
                          </div>
                          <div className='carousel-inner'>
                            {marketPlaceTopOffers ? content : ''}
                            {/* <div className='carousel-item active'>
                              <div className='row g-0'>
                                <div className='col-sm-7'>
                                  <img src={MPSlide1} className='d-block w-100' alt='slider1' />
                                </div>
                                <div className='col-sm-5'>
                                  <div className='mp-carousel-text d-flex flex-column align-items-center justify-content-center p-5 h-100'>
                                    <h4 className='text-white font-24 position-relative'>
                                      New Member
                                    </h4>
                                    <h5 className='text-white font-34'>Get 10% OFF</h5>
                                    <p className='text-white font-24'>+Off on pre EMI</p>
                                    <button
                                      className='mp-redeem-button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#redeem-offer'
                                    >
                                      Redeem
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='carousel-item'>
                              <div className='row g-0'>
                                <div className='col-sm-7'>
                                  <img src={MPSlide1} className='d-block w-100' alt='slider2' />
                                </div>
                                <div className='col-sm-5'>
                                  <div className='mp-carousel-text d-flex flex-column align-items-center justify-content-center p-5 h-100'>
                                    <h4 className='text-white font-24 position-relative'>
                                      New Member
                                    </h4>
                                    <h5 className='text-white font-34'>Get 10% OFF</h5>
                                    <p className='text-white font-24'>+Off on pre EMI</p>
                                    <button
                                      className='mp-redeem-button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#redeem-offer'
                                    >
                                      Redeem
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='carousel-item'>
                              <div className='row g-0'>
                                <div className='col-sm-7'>
                                  <img src={MPSlide1} className='d-block w-100' alt='slider3' />
                                </div>
                                <div className='col-sm-5'>
                                  <div className='mp-carousel-text d-flex flex-column align-items-center justify-content-center p-5 h-100'>
                                    <h4 className='text-white font-24 position-relative'>
                                      New Member
                                    </h4>
                                    <h5 className='text-white font-34'>Get 10% OFF</h5>
                                    <p className='text-white font-24'>+Off on pre EMI</p>
                                    <button
                                      className='mp-redeem-button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#redeem-offer'
                                    >
                                      Redeem
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='carousel-item'>
                              <div className='row g-0'>
                                <div className='col-sm-7'>
                                  <img src={MPSlide1} className='d-block w-100' alt='slider3' />
                                </div>
                                <div className='col-sm-5'>
                                  <div className='mp-carousel-text d-flex flex-column align-items-center justify-content-center p-5 h-100'>
                                    <h4 className='text-white font-24 position-relative'>
                                      New Member
                                    </h4>
                                    <h5 className='text-white font-34'>Get 10% OFF</h5>
                                    <p className='text-white font-24'>+Off on pre EMI</p>
                                    <button
                                      className='mp-redeem-button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#redeem-offer'
                                    >
                                      Redeem
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='carousel-item'>
                              <div className='row g-0'>
                                <div className='col-sm-7'>
                                  <img src={MPSlide1} className='d-block w-100' alt='slider3' />
                                </div>
                                <div className='col-sm-5'>
                                  <div className='mp-carousel-text d-flex flex-column align-items-center justify-content-center p-5 h-100'>
                                    <h4 className='text-white font-24 position-relative'>
                                      New Member
                                    </h4>
                                    <h5 className='text-white font-34'>Get 10% OFF</h5>
                                    <p className='text-white font-24'>+Off on pre EMI</p>
                                    <button
                                      className='mp-redeem-button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#redeem-offer'
                                    >
                                      Redeem
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='carousel-item'>
                              <div className='row g-0'>
                                <div className='col-sm-7'>
                                  <img src={MPSlide1} className='d-block w-100' alt='slider3' />
                                </div>
                                <div className='col-sm-5'>
                                  <div className='mp-carousel-text d-flex flex-column align-items-center justify-content-center p-5 h-100'>
                                    <h4 className='text-white font-24 position-relative'>
                                      New Member
                                    </h4>
                                    <h5 className='text-white font-34'>Get 10% OFF</h5>
                                    <p className='text-white font-24'>+Off on pre EMI</p>
                                    <button
                                      className='mp-redeem-button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#redeem-offer'
                                    >
                                      Redeem
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-4 col-md-3'>
                        <div className='position-relative'>
                          <img
                            src={MPOfferImg}
                            className='d-block w-100 position-relative offer-img'
                            alt='market-place-offer'
                          />
                          <div className='mp-image-overlay position-absolute'>
                            <div className='MPOffer-text position-absolute'>
                              {/* <h4 className='text-white font-14 ps-5'>Offer 15%</h4> */}
                              <h4 className='text-white font-14 ps-5'>
                                {marketPlaceTopOffers?.data?.slice(-1)[0].offer}
                              </h4>
                              <div className='offer-overlay rounded-5'>
                                <p className='text-white font-14 fw-bold'>
                                  Pay just 85 % and get no pre EMI till a Year
                                </p>
                              </div>
                              <div className='offer-btn d-flex flex-row justify-content-between px-5'>
                                <button
                                  className='btn-primary btn-redeem'
                                  data-bs-toggle='modal'
                                  data-bs-target='#redeem-offer'
                                >
                                  Redeem
                                </button>
                                <button className='btn-refresh'>
                                  <img
                                    src={RefreshIcon}
                                    alt='refresh-icon'
                                    className='img-fluid w-100'
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <RedeemOfferPopup redeemableOffer={selectedOffer} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export {MarketPlaceOffer}
