import BannerImg from '../../../../assets/images/banner-img.png'

const Banner = () => {
    let userName = ''
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
      userName = JSON.parse(userDetail).first_name + ' ' + JSON.parse(userDetail).last_name
    }
    return ( 
        <>
        <div className="banner mb-7">
            <div className="banner-inner"> 
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <div className="banner-title">
                            <h2>Hello,{userName}!</h2>
                            <p>Welcome to your Dashboard!</p>
                        </div>
                    </div>
                    <div className="col-md-4 text-end">
                        <img src={BannerImg} alt="banner-image" className="banner-img" />
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Banner;