import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { DocumentTabs } from './components/DocumentTabs/DocumentTabs'
import { InnerDocTabs } from './components/InnerDocTabs/InnerDocTabs'
import { InnerActionTabs } from './components/InnerActionTabs/InnerActionTabs'
import { EmiratesID } from './components/EmiratesID/EmiratesID'
import { Passport } from './components/Passport/Passport'
import { DrivingLicense } from './components/DrivingLicense/DrivingLicense'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../constants'
import * as documents from '../../constants/redux/Sagas/ProspectiveTenant/PTDocumentsSaga'
import { ViewPopup } from './components/ViewPopup/ViewPopup'
import { MarketingMaterials } from './components/MarketingMaterials/MarketingMaterials'

const Documents: FC = (props: any) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
      <div className='col-xxl-12 mt-0'>
        <div className='row'>
          <div className='col-md-12 col-12'>
          {/* <Switch>
                <Route path='/pt-documents/documents'>
                  <DocumentTabs />
                  <InnerDocTabs />
                </Route>
                <Route path='/pt-documents/need-action'>
                  <DocumentTabs />
                <InnerActionTabs />
                </Route>
                <Route path='/pt-documents/passport'>
                  <DocumentTabs />
                <InnerActionTabs />
                </Route>
              </Switch> */}
            <Switch>
              <Route path='/pt-documents/brn-orn-id'>
                <DocumentTabs />
                <EmiratesID />
              </Route>
              <Route path='/pt-documents/document'>
                <DocumentTabs />
                <Passport />
              </Route>
              <Route path='/pt-documents/marketing'>
                <DocumentTabs />
                <MarketingMaterials />
              </Route>
              <Route path='/pt-documents/driving-license'>
                <DocumentTabs />
                <DrivingLicense />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  </>
)

const PTDocumentsWrapper: FC = () => {
  const intl = useIntl()
  let myDocs: any = []
  // myDocs = useSelector<RootState>(({ PTdocuments }) => PTdocuments.mydocsuploaded)
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(documents.actions.mydocsuploaded())
  // }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}> My Documents </PageTitle>
      <Documents />
    </>
  )
}

export { PTDocumentsWrapper }
