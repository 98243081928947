import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'
import Logo1 from "../../../../assets/images/vox-logo.svg";
import { Link } from 'react-router-dom'
import { formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';
import moment from 'moment';

export function PromotionViewDetails({ marketPlaceDetails }: any) {
    const { t } = useTranslation();

    return (
        <>
            <div className='col-12'>
                <div className='card mb-3'>
                    <div className='card-body px-5 py-5 market-place-ht'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='off-details-banner position-relative'>
                                    <img
                                        src={marketPlaceDetails?.image?.length && Array.isArray(marketPlaceDetails?.image) ? marketPlaceDetails?.image[0] : ''}
                                        alt='offer-detail-banner'
                                        className='img-fluid w-100 offer-detail-banner'
                                    />
                                    <div className='offer-details-section position-absolute text-white'>

                                        <div className='text-end'>
                                            <div className='d-flex flex-column justify-content-around h-100 text-white off-text-right'>
                                                {marketPlaceDetails?.type && <span className='offer'>{marketPlaceDetails?.type}</span>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card m-details-overview mb-3">
                    <div className="card-body px-8 py-6">
                        <div className="card-status">
                            {marketPlaceDetails?.statusText === 'approved' ? <h4 className="approved">{t("Approved")}</h4>
                                : marketPlaceDetails?.statusText === 'pending' ? <h4 className="pending">{t("Pending")}</h4>
                                    : marketPlaceDetails?.statusText === 'rejected' ? <h4 className="rejected">{t("Rejected")}</h4>
                                        : ''

                            }
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-xl-3 mb-7 mb-xl-0 ">
                                <div className="card rounded market-place-logo-sec h-100">
                                    <div className="card-body py-6 px-xxl-20 px-xl-15 px-md-13 px-12">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <div className="bg-white rounded-10 px-4 py-4 mb-4">
                                                    <img src={marketPlaceDetails?.logo} alt="Logo" className="img-fluid" />
                                                </div>
                                                {(marketPlaceDetails?.type !== 'Event') &&
                                                    <div className="d-inline-flex mb-4 align-items-center w-100 justify-content-between">
                                                        <span className="offer-count">{marketPlaceDetails?.redeemCount} {t("out of")} {marketPlaceDetails?.redeemLimit}</span>
                                                        <span className="offer-per">{marketPlaceDetails?.offer ? marketPlaceDetails?.offer : '-'}% {t("OFF")} </span>
                                                    </div>
                                                }
                                                <h4 className="text-primary font-17 mb-0 fw-boldest">
                                                    {marketPlaceDetails?.name}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-9">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row full-details">
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-7">
                                                    <label className="d-block font-14 fw-bold mb-2">
                                                        {t("Building Name")}
                                                    </label>
                                                    <span className="font-14 fw-bold text-black">
                                                        {marketPlaceDetails?.building_name ? marketPlaceDetails?.building_name : '-'}
                                                    </span>
                                                </div>
                                            </div>
                                            {(marketPlaceDetails?.type !== 'Event') &&
                                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                    <div className="mb-7">
                                                        {marketPlaceDetails?.type === 'Offer' ?
                                                            <>
                                                                <label className="d-block font-14 fw-bold mb-2">{t("Offer Code")}</label>
                                                                <span className="font-14 fw-bold text-black">
                                                                    {marketPlaceDetails?.code ? marketPlaceDetails?.code : '-'}
                                                                </span>
                                                            </>
                                                            :
                                                            <>
                                                                <label className="d-block font-14 fw-bold mb-2">{t("Voucher Code")}</label>
                                                                <span className="font-14 fw-bold text-black">
                                                                    {marketPlaceDetails?.coupon_code ? marketPlaceDetails?.coupon_code : '-'}
                                                                </span>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-7">
                                                    <label className="d-block font-14 fw-bold mb-2">
                                                        {t("Category")}
                                                    </label>
                                                    <span className="font-14 fw-bold text-black">
                                                        {marketPlaceDetails?.category_name}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-7">
                                                    <label className="d-block font-14 fw-bold mb-2">
                                                        {t("Contact")}
                                                    </label>
                                                    <span className="font-14 fw-bold text-black">
                                                        {marketPlaceDetails?.contact}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-7">
                                                    <label className="d-block font-14 fw-bold mb-2">
                                                        {t("Start Date")}
                                                    </label>
                                                    <span className="font-14 fw-bold text-black">
                                                        {moment.utc(marketPlaceDetails?.start_date).format('DD-MM-YYYY')}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-7">
                                                    <label className="d-block font-14 fw-bold mb-2">
                                                        {t("Start Time")}
                                                    </label>
                                                    <span className="font-14 fw-bold text-black">
                                                        {formatTimeMeridiem(marketPlaceDetails?.start_time)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-7">
                                                    <label className="d-block font-14 fw-bold mb-2">
                                                        {t("End Date")}
                                                    </label>
                                                    <span className="font-14 fw-bold text-black">
                                                        {moment.utc(marketPlaceDetails?.end_date).format('DD-MM-YYYY')}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-7">
                                                    <label className="d-block font-14 fw-bold mb-2">
                                                        {t("End Time")}
                                                    </label>
                                                    <span className="font-14 fw-bold text-black">
                                                        {formatTimeMeridiem(marketPlaceDetails?.end_time)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                <div className="mb-md-0 mb-7">
                                                    <label className="d-block font-14 fw-bold mb-2">
                                                        {t("Web")}
                                                    </label>
                                                    <span className="font-14 fw-bold text-black">
                                                        {marketPlaceDetails?.web_link}
                                                        {/* <a href={marketPlaceDetails?.web_link} className="text-primary">{marketPlaceDetails?.web_link}</a> */}
                                                    </span>
                                                </div>
                                            </div>
                                            
                                            {(marketPlaceDetails?.type === 'Event') ?
                                                <>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-md-0 mb-7">
                                                            <label className="d-block font-14 fw-bold mb-2">
                                                                {t("Address Line 1")}
                                                            </label>
                                                            <span className="font-14 fw-bold text-black">
                                                                {marketPlaceDetails?.address1}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-md-0 mb-7">
                                                            <label className="d-block font-14 fw-bold mb-2">
                                                                {t("Address Line 2")}
                                                            </label>
                                                            <span className="font-14 fw-bold text-black">
                                                                {marketPlaceDetails?.address2}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="d-block font-14 fw-bold mb-2">
                                                                {t("Location")}
                                                            </label>
                                                            <span className="font-14 fw-bold text-black">
                                                                {marketPlaceDetails?.location}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </>
                                                :
                                                <>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-7">
                                                            <label className="d-block font-14 fw-bold mb-2">
                                                                {t("Location")}
                                                            </label>
                                                            <span className="font-14 fw-bold text-black">
                                                                {marketPlaceDetails?.location}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-md-0 mb-7">
                                                            <label className="d-block font-14 fw-bold mb-2">
                                                                {t("Redeem Limit")}
                                                            </label>
                                                            <span className="font-14 fw-bold text-black">
                                                                {marketPlaceDetails?.redeemLimit}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                                        <div className="mb-md-0 mb-7">
                                                            <label className="d-block font-14 fw-bold mb-2">
                                                                {t("Redeemed Count")}
                                                            </label>
                                                            <span className="font-14 fw-bold text-black">
                                                                {marketPlaceDetails?.redeemCount}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 offerDescription">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body px-8 py-6">
                                <div className="row mb-3">
                                    <div className="col-md-12 col-12">
                                        <h4 className="card-title font-17 fw-bolder">
                                            {t("Description")}
                                        </h4>
                                    </div>
                                </div>
                                <div className="row offer-desc">
                                    <div className="col-md-12 mb-5">
                                        <p className="font-14 fw-bold">
                                            {marketPlaceDetails?.description}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}