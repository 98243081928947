import React, { useState, useRef, FC } from 'react';
import PlusIcon from "../../../../../../assets/images/plus-circle.svg";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { fitOutDesignRequest } from '../../../../../../constants/axios/apis';
import { ViewPopup } from '../../../../../maintenance/ViewPopup';
import pdficon from '../../../../../../assets/images/pdf-file.png'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../../../constants/generics/fileUploadHandler';
import { Alert } from 'react-bootstrap-v5';

type Props = {
    contract?: any
    stepStatus?: any
    getStepStatus?: any
}

const DocumentsUpload: FC<Props> = ({ contract, getStepStatus, stepStatus }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState<any>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    const [inputBeingUploaded, setInputBeingUploaded] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [submitError, setSubmitError] = useState<string>('')
    const [viewImage, setViewImage] = useState<any>()
    const [uploadedFileName1, setUploadedFileName1] = useState<string | null>(null);
    const [uploadedFileName2, setUploadedFileName2] = useState<string | null>(null);
    const [uploadedFileName3, setUploadedFileName3] = useState<string | null>(null);
    const [uploadedFileName4, setUploadedFileName4] = useState<string | null>(null);
    const [uploadedFileName5, setUploadedFileName5] = useState<string | null>(null);
    const [uploadedFileName6, setUploadedFileName6] = useState<string | null>(null);
    const [uploadedFileName7, setUploadedFileName7] = useState<string | null>(null);
    const [uploadedFileName8, setUploadedFileName8] = useState<string | null>(null);
    const [uploadedFileName9, setUploadedFileName9] = useState<string | null>(null);

    const inputRef1 = useRef<HTMLInputElement>(null)
    const inputRef2 = useRef<HTMLInputElement>(null)
    const inputRef3 = useRef<HTMLInputElement>(null)
    const inputRef4 = useRef<HTMLInputElement>(null)
    const inputRef5 = useRef<HTMLInputElement>(null)
    const inputRef6 = useRef<HTMLInputElement>(null)
    const inputRef7 = useRef<HTMLInputElement>(null)
    const inputRef8 = useRef<HTMLInputElement>(null)
    const inputRef9 = useRef<HTMLInputElement>(null)

    const handleDisplayFileDetails = (reference: any, setUploadedFileName: any, fieldName: string) => {
        const inputFile = reference.current?.files?.[0]
        let isAllowedType: any = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(inputFile?.type)

        if (inputFile && isAllowedType) {
            if (inputFile.type === 'application/pdf') {
                if (inputFile.size > 5242880) {
                    setError(true)
                    setErrorMessage(t('The file is too large! Max allowed size is 5MB.'))
                    setTimeout(() => { setError(false) }, 3000)
                } else {
                    setUploadedFileName(inputFile.name)
                    formik.setFieldTouched(fieldName)
                    formik.setFieldValue(fieldName, inputFile)
                }
            } else {
                setUploading(true)
                setInputBeingUploaded(fieldName)
                setUploadedFileName()
                try {
                    validateAndCompressImage(inputFile).then((compressResponse) => {
                        if (compressResponse?.success) {
                            setUploadedFileName(inputFile.name)
                            formik.setFieldTouched(fieldName)
                            formik.setFieldValue(fieldName, inputFile)
                        } else {
                            setError(true)
                            setErrorMessage(t(compressResponse?.message || 'File compression error!'))
                            setTimeout(() => { setError(false) }, 3000)
                        }
                        setUploading(false)
                    })
                } catch (error) {
                    alert(t('File upload error!'))
                    setError(true)
                    setErrorMessage(t('File upload error!'))
                    setTimeout(() => { setError(false) }, 3000)
                    setUploading(false)
                }
            }
        } else {
            setError(true)
            setErrorMessage(t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'))
            setTimeout(() => {
                setError(false)
            }, 3000)
        }
    }

    const initialValues = {
        contract_id: contract?.id,
        property_id: contract?.propertyID,
        category_id: 29,
        mep_drawings: '',
        concept_design: '',
        light_layout: '',
        single_line_diagram: '',
        power_layout: '',
        cable_route: '',
        electrical_approval_letter: '',
        energy_meter_schedule: '',
        load_schedule: '',
    }

    const documentVerficationSchema = Yup.object().shape({
        mep_drawings: Yup.mixed()
            .required(t("MEP Drawings are required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        concept_design: Yup.mixed()
            .required(t("Concept Design is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        light_layout: Yup.mixed()
            .required(t("Light Layout is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        single_line_diagram: Yup.mixed()
            .required(t("Single Line Diagram is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        power_layout: Yup.mixed()
            .required(t("Power Layout is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        cable_route: Yup.mixed()
            .required(t("Cable Route is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        electrical_approval_letter: Yup.mixed()
            .required(t("Electirical Approval Letter is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        energy_meter_schedule: Yup.mixed()
            .required(t("Energy Meter Schedule is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        load_schedule: Yup.mixed()
            .required(t("Load Schedule is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
    })

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: documentVerficationSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setLoading(true)

            var FormData = require('form-data')
            var data = new FormData()
            data.append('contract_id', formik.values.contract_id)
            data.append('property_id', formik.values.property_id)
            data.append('category_id', formik.values.category_id)
            data.append('mep_drawing', formik.values.mep_drawings)
            data.append('concept_design', formik.values.concept_design)
            data.append('light_layout', formik.values.light_layout)
            data.append('single_line_diagram', formik.values.single_line_diagram)
            data.append('power_layout', formik.values.power_layout)
            data.append('cable_route', formik.values.cable_route)
            data.append('electric_approval_letter', formik.values.electrical_approval_letter)
            data.append('energy_meter_schedule', formik.values.energy_meter_schedule)
            data.append('load_schedule', formik.values.load_schedule)

            setTimeout(() => {
                fitOutDesignRequest(data)
                    .then((response: any) => {
                        setLoading(false)
                        // console.log(response)
                        if (response.errorCode === 0) {
                            getStepStatus()
                        }else{
                            setSubmitError(response.errorDescription)
                        }
                    })
                    .catch((e) => {
                        setSubmitting(false)
                        setSubmitError(t("Invalid Data Provided"))
                    })
            }, 1000)
        },
    })

    const handleView = (data: any) => {
        setViewImage(data)
    }

    return (
        <div className="com-card-section">
            <div className="row documents-card form-add-design pt-6">
                <div className="col-12">
                    <form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_login_signup_form'
                        onSubmit={formik.handleSubmit}
                    >
                        {error ? (
                            <Alert className='font-14 fw-bold mb-8' variant='danger'>
                                {errorMessage}!
                            </Alert>
                        ) : null}

                        {stepStatus.status ? (
                            <>
                                <div className="row mb-6">
                                    <div className="col-12">
                                        <p className="font-14 fw-normal mb-6">
                                            {t("Uploaded Documents")}
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("MEP Drawings")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.mep_drawing && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.mep_drawing)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.mep_drawing.substr(stepStatus?.data?.images?.mep_drawing.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.mep_drawing
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Concept Design")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.concept_design && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.concept_design)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.concept_design.substr(stepStatus?.data?.images?.concept_design.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.concept_design
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="font-14 fw-normal mb-6">
                                            {t("ADDC Documents to Upload")}
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Light Layout")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.light_layout && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.light_layout)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.light_layout.substr(stepStatus?.data?.images?.light_layout.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.light_layout
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Single Line Diagram")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.single_line_diagram && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.single_line_diagram)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.single_line_diagram.substr(stepStatus?.data?.images?.single_line_diagram.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.single_line_diagram
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Power Layout")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.power_layout && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.power_layout)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.power_layout.substr(stepStatus?.data?.images?.power_layout.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.power_layout
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Cable Route")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.cable_route && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.cable_route)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.cable_route.substr(stepStatus?.data?.images?.cable_route.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.cable_route
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Electrical Approval Letter")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.electric_approval_letter && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.electric_approval_letter)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.electric_approval_letter.substr(stepStatus?.data?.images?.electric_approval_letter.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.electric_approval_letter
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Energy Meter Schedule")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.energy_meter_schedule && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.energy_meter_schedule)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.energy_meter_schedule.substr(stepStatus?.data?.images?.energy_meter_schedule.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.energy_meter_schedule
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Load Schedule")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.load_schedule && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.load_schedule)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.load_schedule.substr(stepStatus?.data?.images?.load_schedule.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.load_schedule
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row mb-6">
                                    <div className="col-12">
                                        <p className="font-14 fw-normal mb-6">
                                            {t("Documents to Upload")}
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("MEP Drawings")} </label>
                                                    <input
                                                        name="mep_drawings"
                                                        ref={inputRef1}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef1, setUploadedFileName1, 'mep_drawings')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef1.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName1 ? "primary active" : "primary"}
                                                        `}
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'mep_drawings') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName1 || uploadedFileName1 ? (
                                                                    uploadedFileName1 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.mep_drawings && formik.errors.mep_drawings ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.mep_drawings}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Concept Design")} </label>
                                                    <input
                                                        name="concept_design"
                                                        ref={inputRef2}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef2, setUploadedFileName2, 'concept_design')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button' onClick={() => inputRef2.current?.click()} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName2 ? "primary active" : "primary"}`}>
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'concept_design') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName2 || uploadedFileName2 ? (
                                                                    uploadedFileName2 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.concept_design && formik.errors.concept_design ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.concept_design}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="font-14 fw-normal mb-6">
                                            {t("ADDC Documents to Upload")}
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Light Layout")} </label>
                                                    <input
                                                        name="light_layout"
                                                        ref={inputRef3}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef3, setUploadedFileName3, 'light_layout')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef3.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName3 ? "primary active" : "primary"
                                                            }`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'light_layout') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName3 || uploadedFileName3 ? (
                                                                    uploadedFileName3 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.light_layout && formik.errors.light_layout ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.light_layout}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Single Line Diagram")} </label>
                                                    <input
                                                        name="single_line_diagram"
                                                        ref={inputRef4}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef4, setUploadedFileName4, 'single_line_diagram')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef4.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName4 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'single_line_diagram') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName4 || uploadedFileName4 ? (
                                                                    uploadedFileName4 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.single_line_diagram && formik.errors.single_line_diagram ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.single_line_diagram}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Power Layout")} </label>
                                                    <input
                                                        name="power_layout"
                                                        ref={inputRef5}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef5, setUploadedFileName5, 'power_layout')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef5.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName5 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'power_layout') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName5 || uploadedFileName5 ? (
                                                                    uploadedFileName5 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.power_layout && formik.errors.power_layout ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.power_layout}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Cable Route")} </label>
                                                    <input
                                                        name="cable_route"
                                                        ref={inputRef6}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef6, setUploadedFileName6, 'cable_route')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef6.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName6 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'cable_route') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName6 || uploadedFileName6 ? (
                                                                    uploadedFileName6 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.cable_route && formik.errors.cable_route ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.cable_route}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Electrical Approval Letter")} </label>
                                                    <input
                                                        name="electrical_approval_letter"
                                                        ref={inputRef7}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef7, setUploadedFileName7, 'electrical_approval_letter')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef7.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName7 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'electrical_approval_letter') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName7 || uploadedFileName7 ? (
                                                                    uploadedFileName7 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.electrical_approval_letter && formik.errors.electrical_approval_letter ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.electrical_approval_letter}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Energy Meter Schedule")} </label>
                                                    <input
                                                        name="energy_meter_schedule"
                                                        ref={inputRef8}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef8, setUploadedFileName8, 'energy_meter_schedule')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef8.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName8 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'energy_meter_schedule') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName8 || uploadedFileName8 ? (
                                                                    uploadedFileName8 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.energy_meter_schedule && formik.errors.energy_meter_schedule ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.energy_meter_schedule}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Load Schedule")} </label>
                                                    <input
                                                        name="load_schedule"
                                                        ref={inputRef9}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef9, setUploadedFileName9, 'load_schedule')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef9.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName9 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'load_schedule') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName9 || uploadedFileName9 ? (
                                                                    uploadedFileName9 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.load_schedule && formik.errors.load_schedule ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.load_schedule}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='col-12 text-end'>
                                                <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading || uploading}>
                                                    {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            {t("Please wait...")}{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                            { submitError !== '' &&
                                                <div className="col-12 mt-3">
                                                    <div className='alert alert-danger w-100 text-center' role='alert'>
                                                        {submitError}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                    </form>
                </div>
            </div>
        </div>
    );
};

export { DocumentsUpload }
