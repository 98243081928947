import React, { useState, useEffect, useRef } from 'react'
import Propertie from '../../../../assets/images/latest-one.png'
import PlusIcon from '../../../../assets/images/plus-circle.svg'

export function ViewPopupAddPromo(props: any) {

  return (
    <div className='modal fade custom-modal' aria-hidden='true' id='view-popUp-add-promo'>
      <div className='modal-dialog modal-lg modal-dialog-centered'>
        <div className='modal-content rounded-10'>
          <div className='modal-header border-0 p-0'>
            <button
              type='button'
              className='btn-close shadow'
              data-bs-dismiss='modal'
              aria-label='Close'
            >&times;</button>
          </div>
          <div className='modal-body p-8'>
            <div className=''>
              <div className=''>
                <div className=''>
                  <div className='documents-card'>
                    <div className='row'>
                      <div className="col-12">
                        <label className='form-label text-black font-14 fw-bold d-block mb-5'>
                          Add more
                        </label>
                      </div>
                      <div className="col-12">
                        <input type='text' placeholder='Promo Name' className='form-control mb-5'></input>
                      </div>
                      <div className="col-12 text-center">

                        <input
                          // ref={inputRefBack}
                          // onChange={handleDisplayFileDetailsBack}
                          className='d-none'
                          type='file'
                          accept='.png,.jpeg,.jpg,application/pdf' />
                        <button
                          // onClick={handleUploadBack}
                          className={`w-100 py-3 mb-md-0 ellipsis mydoc_bt_file btn-outline-primary`}
                        // disabled={uploading2}
                        // className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileBack?.name || pIdBackImage ? 'primary active' : 'primary'}`}
                        // disabled={pIdBackImage ? true : false}
                        >
                          <span>
                            {/* {uploadedFileBack?.name || pIdBackImage ? (
															uploadedFileBack?.name || ''
														) : (
															<img src={PlusIcon} className='img-fluid va-baseline' />
														)} */}

                            <img src={PlusIcon} className='img-fluid va-baseline' />
                            <p className='m-0 upload-your-life'>Upload your file</p>


                          </span>
                        </button>
                      </div>
                      <div className="col-12 text-center mt-5">
                        <button className='btn btn-primary w-100' disabled>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='documents-card card'>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
