import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import Home from '../../../../assets/images/my-unit.png'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import {MyLease} from '../../../my-unit/MyLease'

export function ContractRenewal({contract}: any) {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  let userRole: number = 0
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }

  const [menu, setMenu] = useState('')

  return (
    <div className='com-card-section myunit-page'>
      <div className='row'>
        {userRole === 7 ? (
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/my-services/openissues'} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              </Link>
              Contract Renewal
            </h4>
          </div>
        ) : null}
        {userEmail === 'pm@exalogic.co' ? (
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/pm-unrenewed-leases'} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              </Link>
              Contract Renewal
            </h4>
          </div>
        ) : null}
        {menu === '/my-services/contract-renewal' ? (
          <div className='col-md-12 col-12'>
            {userEmail === 'commercialtenant@exalogic.co' ? (
              <div className='col-md-12 col-12'>
                <h4 className='card-title font-18 text-black mb-6'>
                  <Link to={'/my-services/renewal-notice'} className=''>
                    <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                  </Link>
                  Clearance Charges
                </h4>
              </div>
            ) : null}
          </div>
        ) : (
          <div className=''></div>
        )}

        <div className='col-12'>
          <MyLease contract={contract} />
          {/* <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-4 col-lg-3'>
                  <img src={Home} className='img-fluid' />
                </div>
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card bg-white rounded'>
                    <div className='card-body px-0 pt-0 pb-2'>
                      <div className='row align-items-top pb-7'>
                        <div className='col-md-6'>
                          <a
                                  href='#'
                                  className='text-decoration-none text-black fw-bolder view-all font-16'
                          >
                            <span> My Unit Details </span>
                          </a>
                        </div>
                        <div className='col-md-6 ps-lg-20 text-end'>
                          <a
                                  href='#'
                                  className='text-decoration-underline text-primary fw-bold view-all font-14'
                          >
                            <span> View Agreement </span>
                          </a>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-4 col-6 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Lease Number </h6>
                          <span className='text-black fw-bold'> 25895615651 </span>
                        </div>
                        <div className='col-md-4 col-6 mb-5'>
                          <div className=''>
                            <h6 className='text-theme-gray mb-3 fw-normal'> Lease Start Date </h6>
                            <span className='text-black fw-bold'> 21-Feb-2021 </span>
                          </div>
                        </div>
                        <div className='col-md-4 col-6 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Lease End Date </h6>
                          <span className='text-black fw-bold'> 20-Jan-2022 </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Documents </h6>
                          <span className='text-black fw-bold'> 4 </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Annual Rent </h6>
                          <span className='text-black fw-bold'> AED 12350 </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Security Deposit </h6>
                          <span className='text-black fw-bold'> AED 70000 </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
