import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import Home from "../../../../assets/images/my-unit.png";

export function Fitout() {
    return (
        <div className="com-card-section">
            <div className='row'>
                <div className='col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                        <Link to={'/my-services/openissues'} className=''>
                            <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                            <span className='text-black'>Fitout</span>
                        </Link>
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-8">
                            <div className="row mb-15">
                                <div className="col-md-4 col-lg-3">
                                    <div className="approval-move">
                                        <img src={Home} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-9 pt-0">
                                    <h4 className='text-black mb-5 font-15'>Lease Number : 25895615651</h4>
                                    <div className="card lease-card refund-block rounded">
                                        <div className="card-body px-5 py-5">
                                            <div className="row">
                                                <div className="col-md-4 col-6 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Lease Start Date </h6>
                                                    <span className="text-black fw-bold font-14"> 21-Feb-2021 </span>
                                                </div>
                                                <div className="col-md-4 col-6 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Lease End Date </h6>
                                                    <span className="text-black fw-bold font-14"> 20-Jan-2022 </span>
                                                </div>
                                                <div className="col-md-4 col-6 mb-7">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> No. of Payments </h6>
                                                    <span className="text-black fw-bold font-14"> 25895615651 </span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Documents </h6>
                                                    <span className="text-black fw-bold font-14"> 4 </span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Annual Rent </h6>
                                                    <span className="text-black fw-bold font-14"> AED 12350</span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> Security Deposit </h6>
                                                    <span className="text-black fw-bold font-14"> AED 70000 </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <div className="justify-content-end">
                                        <Link to={'#'} className='btn btn-primary fw-bold px-10 py-3 w-100'>
                                            New Fit-out Permit
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-3">
                                    <div className="justify-content-end">
                                        <Link to={'#'} className='btn btn-primary fw-bold px-10 py-3 w-100'>
                                            Modifcation
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-3">
                                    <div className="justify-content-end">
                                        <Link to={'#'} className='btn btn-primary fw-bold px-2 py-3 w-100 font-14'>
                                            Minor Renovation/Decoration
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-3">
                                    <div className="justify-content-end">
                                        <Link to={'#'} className='btn btn-primary fw-bold px-2 py-3 w-100 font-14'>
                                            Request Authorities NOC
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}