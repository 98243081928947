import { Spin } from 'antd';
import * as MyCustomeIcons from 'react-icons/md';
import NoDataFoundImage from '../../assets/images/no-data-found.svg';
import ComingSoonImage from '../../assets/images/coming-soon.svg';
import AvatarImg from '../../assets/images/avatar.jpg';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap-v5';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spiner from '../../assets/images/splash.gif';

export const CustomDdIcon = (props: any) => {
    const { name }: any = props;
    const FaIcon = MyCustomeIcons[name as keyof typeof MyCustomeIcons];

    if (!FaIcon) return <MyCustomeIcons.MdOutlineHourglassTop />;

    return <FaIcon />;
};

export const CustomDdIcon2 = (props: any) => {
    const { name, size }: any = props;
    const FaIcon = MyCustomeIcons[name as keyof typeof MyCustomeIcons];
    if (!FaIcon) return <MyCustomeIcons.MdOutlineHourglassTop />;

    return <FaIcon />;
};

export const CustomLoader = () => {
    const { t } = useTranslation();
    return <>
        <div className="text-center mb-7" style={{
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            {/* <Spin tip={t("Loading...")} size="large" style={{ color: '#49484c' }} /> */}
            <img src={Spiner} className="h-44px" width='100' height="100"/>
            <p className='text-dark'>{t("Loading...")}</p>
        </div>
    </>;
};

export const CustomLoader1 = (props: any) => {
    const { t } = useTranslation();

    return <>
        <div className="text-center mb-7" style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: `${props.height}vh`,
        }}>
            {/* <Spin tip={t("Loading...")} size="large" style={{ color: '#49484c' }} /> */}
            {/* <Spin tip={props.loadMessage ? t(props.loadMessage) : t("Loading...")} size="large" style={{ color: '#1C447B' }} /> */}
            <img src={Spiner} className="h-44px" width='100' height="100"/>
            <p className='text-dark'>{props.loadMessage ? t(props.loadMessage) : t("Loading...")}</p>
        </div>
    </>;
};

export const CustomLoader2 = (props: any) => {
    const { t } = useTranslation();
    return <>
        <div className="text-center mb-7" style={{
            // <div className="mb-7" style={{
            //display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: `${props.height}px`,
        }}>
            {/* <Spin tip={t("Loading...")} size="large" style={{ color: '#49484c' }} /> */}
            <img src={Spiner} className="h-44px" width='100' height="100"/>
            <p className='text-dark'>{props.loadMessage ? t(props.loadMessage) : t("Loading...")}</p>
        </div>
    </>;
};

export const CustomLoader3 = (props: any) => {
    const { t } = useTranslation();
    return <>
        <div className="text-center mb-7" style={{
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'center',
            height: `${props.height}px`,
        }}>
            {/* <Spin tip={t("Loading...")} size="large" style={{ color: '#1C447B' }} /> */}
            <img src={Spiner} className="h-44px" width='100' height="100"/>
            {/* <p className='text-dark text-center mt-n20'>{t("Loading...")}</p> */}
        </div>
    </>;
};

export const CustomLoaderDynamic = ({ height = '', size = 'large', showText = true, showCard = false }: any) => {
    const { t } = useTranslation();
    return (
        <div className={showCard ? "text-center mb-7" : "mb-7"} style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: `${height}`,
        }}>
            {/* <Spin tip={showText ? t("Loading...") : ''} size={size} style={{ color: '#49484c' }} /> */}
            <img src={Spiner} className="h-44px" width='100' height="100"/>
            <p className='text-dark'>{t("Loading...")}</p>
        </div>
    )
};
export const CustomLoaderWithMsg = (props: any) => {
    const { t } = useTranslation();
    return <>
        <div className="text-center mb-7" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: `${props.height}vh`,
        }}>
            {/* <Spin tip={props.msg} size="large" style={{ color: '#49484c' }} /> */}
            <img src={Spiner} className="h-44px" width='100' height="100"/>
            <p className='text-dark'>{t("Loading...")}</p>
        </div>
    </>;
};

export const CustomLoaderCompact = (props: any) => {
    const { t } = useTranslation();
    return <>
        <div className="text-center mb-7" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // height: props.height ? `${props.height}px` : '30px',
        }}>
            {/* <Spin size="default" style={{ color: '#49484c' }} className='pe-5' /> {t("Loading...")} */}
            <img src={Spiner} className="h-44px" width='100' height="100"/>
            <p className='text-dark'>{t("Loading...")}</p>
        </div>
    </>;
};

export const NoDataFound = () => {
    const { t } = useTranslation();
    return <>
        <div className="col-12">
            <div className="card border-0 shadow-none rounded-0 no-data-found-cd">
                <div className="card-body border-0">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <img src={NoDataFoundImage} alt="No Data Found" className="img-fluid" />
                        </div>
                        <div className="col-12 text-center">
                            <span className='d-block text-center font-16 fw-bolder text-black'>{t("No Data Found")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
export const NoDataFoundWithMsg = (props: any) => {
    const { t } = useTranslation();
    return <>
        <div className="col-12">
            <div className="card border-0 shadow-none rounded-0 no-data-found-cd">
                <div className="card-body border-0">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <img src={NoDataFoundImage} alt="No Data Found" className="img-fluid" />
                        </div>
                        <div className="col-12 text-center">
                            <span className='d-block text-center font-16 fw-bolder text-black'>{t(props?.msg)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
export const NoDataFound1 = (props: any) => {
    const location = useLocation()
    const { t } = useTranslation();
    return <>
        <div className="row justify-content-center no-data-found-cd">
            <div className="col-12 text-center">
                <img src={NoDataFoundImage} alt="No Data Found" className="img-fluid" style={{ height: `${props.height}px` }} />
                {/* <img src={Spiner} alt="No Data Found" className="h-44px" width='100' height="100"/> */}
            </div>
            <div className="col-12 text-center">
                {/* <span className='d-block text-center font-16 fw-bolder text-black'> {location.pathname.includes('marketPlace') ? t("Coming Soon") : t("No Data Found")} </span> */}
                <p className='d-block text-center font-16 fw-bolder text-black'>{location.pathname.includes('marketPlace') ? t("Coming Soon") : t("No Data Found")}</p>
            </div>
        </div>
    </>;
}

export const NoDataFoundMarketPlace = (props: any) => {
    const location = useLocation()
    const { t } = useTranslation();
    return <>
        <div className="col-12">
            <div className="card border-0 shadow-none rounded-0 no-data-found-cd">
                <div className="card-body border-0">
                    <div className="row justify-content-center align-items-center g-0">
                        <div className="col-auto text-center">
                            <img src={ComingSoonImage} alt="Coming Soon" className="img-fluid" style={{ height: `${props.height}px` }} />
                        </div>
                        <div className="col-auto text-center">
                            <span className='d-block text-center font-22 fw-boldest text-primary text-uppercase'> {t("Coming Soon")} </span>
                            <span className='d-block text-center font-14 fw-bold text-tune text-uppercase'> {t("Stay Tuned")} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export const DefaultAvatar = (props: any) => {
    return <>
        <img src={AvatarImg} alt={props?.alt} className={`${props?.classname}`} style={{ height: `${props?.height}px` }} />
    </>;
}

export const fileUploader = (
    value: any,
    setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
    fieldName = 'field'
) => {
    if (typeof value !== 'undefined') {
        if (
            value.type === 'image/png' ||
            value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf'
        ) {
            setErrorState((pre) => {
                return removeItem([...pre], `${fieldName} is not a png/jpeg/jpg/PDF file`)
            })
        } else {
            setErrorState((pre) => {
                return [...pre, `${fieldName} is not a png/jpeg/jpg/PDF file`]
            })
        }
        setErrorState((pre) => {
            return removeItem([...pre], `${fieldName} is not selected`)
        })
    }
    if (typeof value !== 'undefined') {
        if (value.size <= 5242880) {
            setErrorState((pre) => {
                return removeItem([...pre], `${fieldName} file size should not be more than 1MB`)
            })
        } else {
            setErrorState((pre) => {
                return [...pre, `${fieldName} file size should not be more than 5MB`]
            })
        }
        setErrorState((pre) => {
            return removeItem([...pre], `${fieldName} is not selected`)
        })
    }
}
export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
    var i = 0
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1)
        } else {
            ++i
        }
    }
    return arr
}
export const validateRequired = (
    value: any,
    setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
    fieldName = 'field'
) => {
    if (value === '' || (value.trim()).length === 0) {
        setErrorState((pre) => {
            return [...pre, `${fieldName} is required`]
        })
    } else {
        setErrorState((pre) => {
            return removeItem([...pre], `${fieldName} is required`)
        })
    }
}

export const validateRequiredNumbers = (
    value: any,
    setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
    fieldName = 'field'
) => {
    if (value === '' || (value.trim()).length === 0) {
        setErrorState((pre) => {
            return [...pre, `${fieldName} is required`]
        })
    } else {
        setErrorState((pre) => {
            return removeItem([...pre], `${fieldName} is required`)
        })
    }
    if (value.match(/[0-9+]/)) {
        setErrorState((pre) => {
            return removeItem([...pre], `${fieldName} is not valid`)
        })
    } else {
        setErrorState((pre) => {
            return [...pre, `${fieldName} is not valid`]
        })
    }
}
export const validateRequireChars = (
    value: any,
    setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
    fieldName = 'field'
) => {
    if (value === '' || (value.trim()).length === 0) {
        setErrorState((pre) => {
            return [...pre, `${fieldName} is required`]
        })
    } else {
        setErrorState((pre) => {
            return removeItem([...pre], `${fieldName} is required`)
        })
    }
    if (value.match(/[a-zA-Z ]/)) {
        setErrorState((pre) => {
            return removeItem([...pre], `${fieldName} is not valid`)
        })
    } else {
        setErrorState((pre) => {
            return [...pre, `${fieldName} is not valid`]
        })
    }
}
export function CustomViewPopup(props: any) {
    const [previewDoc, setPreviewDoc] = useState<any>('')
    useEffect(() => {
        setPreviewDoc('')
        setTimeout(() => {
            setPreviewDoc(props?.viewImage)
        }, (1000))
    }, [props])
    return (
        <div className='modal fade custom-modal' id='view-customPopUp'>
            <div className='modal-dialog modal-lg modal-dialog-centered'>
                <div className='modal-content rounded-10'>
                    <div className='modal-header p-0 border-0'>
                        <button
                            type='button'
                            className='btn-close shadow'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                        >&times;</button>
                    </div>
                    <div className='modal-body p-8'>
                        <div className=''>
                            <div className='row'>
                                <div className="col-12 text-center">
                                    {previewDoc?.includes('pdf') ? <iframe src={previewDoc} style={{ width: '600px', height: '500px' }} ></iframe> :
                                        <img src={previewDoc} className='img-fluid' />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function DynamicPopup() {
    const { t } = useTranslation();
    return (
        <div className="modal fade custom-modal" id="dynamic-popup1">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header bg-white border-0">
                        <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                    </div>
                    <div className="modal-body py-10">
                        <div className="row g-5">
                            <div className="col-md-12">
                                <div className="text-center mx-md-12">
                                    <h1>{t("Success")}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function CustomViewPopOver(props: any) {
    const [previewDoc, setPreviewDoc] = useState<any>('')
    useEffect(() => {
        setPreviewDoc('')
        setTimeout(() => {
            setPreviewDoc(props?.viewImage)
        }, (1000))
    }, [props])
    return (
        <>
            <div style={{
                display: 'block',
                width: 700,
                padding: 30
            }}>
                <OverlayTrigger
                    placement="top"
                    trigger="click"
                    overlay={(
                        <Popover id='popover-basic'>
                            <Popover.Title as="h3">

                            </Popover.Title>
                            <Popover.Content>
                                {previewDoc?.includes('pdf') ? <iframe src={previewDoc} style={{ width: '600px', height: '500px' }} ></iframe> :
                                    <img src={previewDoc} className='img-fluid va-baseline' />}
                            </Popover.Content>
                        </Popover>
                    )}>
                    <button className='border-0 bg-transparent'>
                        <i className='bi bi-eye text-black font-17'></i>
                    </button>
                </OverlayTrigger>
            </div>
        </>
    );
}

export function currencyFormater(amount: any) {
    if (amount && amount !== '' && !isNaN(amount)) {
        amount = parseFloat(amount)
        return amount.toLocaleString(undefined, { maximumFractionDigits: 2 })
    }
    return amount
}
export function someThingWentWrong() {
    return 'Something went wrong, please try again later.'
}

//False = Show bed count for both Residential/commercial property
//True = Show bed only for Residential property
export const validateCommercialBed = false