import React, { FC, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { SecurityDeposit } from './SecurityDeposit'
import { useTranslation } from 'react-i18next'

type Props = {
    props?: any
}

export const PaymentModalPreview: React.FC<Props> = ({props}: any) => {
    const { t } = useTranslation();

    return (
        <div className='modal fade notif-popup' id='payment-modal-preview'>
            <div className='modal-dialog modal-dialog-scrollable'>
                <div className='modal-content rounded-10'>
                    <div className='modal-body'>
                        <div className='row'>
                            <div className='col-12'>
                               <h6 className="font-16 text-normal fw-bold mb-4">{t("Your Contract Renewal Request Accept")}</h6>
                                <SecurityDeposit props={props} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
