import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { IState } from '../../../utils/interface';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

interface IProps {
    brokersList: IState["APIState"]
}


ChartJS.register(ArcElement, Tooltip, Legend);

/* export const data = {
    labels: ['40 Active', '10 Inactive'],
    datasets: [
        {
            datalabels: {
                color: "#ffffff",
                font: {
                    size: 16,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                },
            },
            data: [40, 10],
            backgroundColor: [
                '#147AD6',
                '#FF5F58',
            ],
            borderColor: [
                '#147AD6',
                '#FF5F58',
            ],
            borderWidth: 1,
        },
    ]
};
 */
export const options = {
    plugins: {
        cutout:80,
        legend: {
            display: true,
            position: 'right' as const,
            labels: {
                color: 'rgb(0, 44, 106)',
                lineDash: [12,12],
                boxWidth: 19,
                boxHeight: 18,
                padding: 30,
                usePointStyle: true,
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                }
            }
        },
    },

    maintainAspectRatio: true,
};

export const TotalBrokers: React.FC<IProps> = ({brokersList}) => {
    const {loading, response: brokers, error} = brokersList;
    const { t } = useTranslation();

    const [data,setData] = useState({
        labels: ['0 Active', '0 Inactive'],
    
        datasets: [
            {
                datalabels: {
                    color: "#ffffff",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                data: [0, 0],
                backgroundColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderWidth: 1,
            },
        ]
    });

  
    useEffect(()=>{
        if(Object.keys(brokers).length !== 0){
            setData({
                labels: [`${brokers.activeBrokers} ${t("Active")}`, `${brokers.inActiveBrokers} ${t("Inactive")}`],
                datasets: [
                    {
                        datalabels: {
                            color: "#ffffff",
                            font: {
                                size: 16,
                                family: "'Poppins', sans-serif",
                                lineHeight: 0.5,
                            },
                        },
                        data: [brokers.activeBrokers, brokers.inActiveBrokers],
                        backgroundColor: [
                            '#147AD6',
                            '#FF5F58',
                        ],
                        borderColor: [
                            '#147AD6',
                            '#FF5F58',
                        ],
                        borderWidth: 1,
                    },
                ]
            })
        } 
    },[brokersList]);

    return (
        <div className="">
            <div className="row">
                <div className="col-lg-12">
                    <h4 className="card-title font-17 mb-0 mt-5"> {t("Brokers")}</h4>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-12">
                    <div className="card brokers-count rounded-10">
                        <div className="card-body px-10 py-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="Doughnut-Chart text-center position-relative">
                                        <Chart type='doughnut'  data={data} options={options} width={200} height={200}/>
                                        <div className="inner-text">
                                            {Object.keys(brokers).length !== 0 ? (
                                                <h4 className="font-12 text-primary fw-boldest">
                                                    {brokers.teamList.length}<br/>
                                                    {t("Total")}<br/>
                                                    {t("brokers")}
                                                </h4>
                                            ):(
                                                <h4 className="font-12 text-primary fw-boldest">
                                                    0 <br/>
                                                    {t("Total")}<br/>
                                                    {t("brokers")}
                                                </h4>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}