import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { UpcomingCard } from '../MyVisit/UpcomingProperties/UpcomingCard'
import { RequestSuccessfulPopup } from '../../../SuccessfulPopup/RequestSuccessfulPopup'

export function MyApproval() {
	return (
		<div className='com-card-section'>
			<div className='row'>
				<div className='col-12'>
					<div className='card movein-moveout'>
						<div className='card-body px-8 py-6'>
							<div className='row'>
								<div className='col-md-12 col-12'>
                                    <UpcomingCard />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
