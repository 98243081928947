import React, { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CardPayment } from './CardPayment'
import { BankTransfer } from './BankTransfer'
import { PaybyCheque } from './PaybyCheque'
import { useTranslation } from 'react-i18next'

interface Props {
    tabKey: any
}

export const SecurityDeposit: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className=" ">
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-pills mb-3 security-deposit" id="pills-tab" role="tablist">
                        <li className="nav-item tab_nav_li" role="presentation">
                            <button className="nav-link active px-5 py-5 w-100 fw-bolder" id="pills-newpaycard-tab" data-bs-toggle="pill"
                                data-bs-target="#card-payment-newpay" type="button" role="tab" aria-controls="pills-newpaycard"
                                aria-selected="true">
                                <span className="icons mb-3"><i className="bi bi-credit-card font-17"></i></span>
                                {t("Card Payment")}
                            </button>
                        </li>
                        <li className="nav-item tab_nav_li" role="presentation">
                            <button className="nav-link px-5 py-5 w-100 fw-bolder" id="pills-newpaybank-tab" data-bs-toggle="pill"
                                data-bs-target="#bank-transfer-newpay" type="button" role="tab" aria-controls="pills-newpaybank"
                                aria-selected="false">
                                <span className="icons mb-3"><i className="bi bi-currency-dollar font-17"></i></span>
                                {t("Bank Transfer")}
                            </button>
                        </li>
                        <li className="nav-item tab_nav_li" role="presentation">
                            <button className="nav-link px-5 py-5 w-100 fw-bolder" id="pills-newpaycheque-tab" data-bs-toggle="pill"
                                data-bs-target="#pay-by-cheque-newpay" type="button" role="tab" aria-controls="pills-newpaycheque"
                                aria-selected="false">
                                <span className="icons mb-3"><i className="bi bi-cash font-17"></i></span>
                                {t("Pay by Cheque")}
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="card-payment-newpay" role="tabpanel" aria-labelledby="pills-newpaycard-tab">
                            <CardPayment />
                        </div>
                        <div className="tab-pane fade" id="bank-transfer-newpay" role="tabpanel" aria-labelledby="pills-newpaybank-tab">
                            <BankTransfer />
                        </div>
                        <div className="tab-pane fade" id="pay-by-cheque-newpay" role="tabpanel" aria-labelledby="pills-newpaycheque-tab">
                            <PaybyCheque />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
