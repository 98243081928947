import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ViewPopup } from '../../pt-documents/components/ViewPopup/ViewPopup'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { uploadMyDocs } from '../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../constants/generics/fileUploadHandler'
import NoDataFoundImage from '../../../assets/images/no-data-found.svg'


export function NeedAction({ needActionDocuments, getDocs }: any) {
  const [viewImage, setViewImage] = useState<any>()
  const [loading, setLoading] = useState<any>(false)
  const [uploadedEmiratesIdFrontName, setUploadedEmiratesIdFrontName] = useState<any>(null)
  const [uploadedEmiratesIdBackName, setUploadedEmiratesIdBackName] = useState<any>(null)
  const [uploadedPassportName, setUploadedPassportName] = useState<any>(null)
  const [uploadedPassportBackName, setUploadedPassportBackName] = useState<any>(null)
  const [uploadedVisaName, setUploadedVisaName] = useState<any>(null)
  const [uploadedLetterOfIntentName, setUploadedLetterOfIntentName] = useState<any>(null)
  const [uploadedAddcBillName, setUploadedAddcBillName] = useState<any>(null)

  const emiratesIdFrontInputRef = useRef<HTMLInputElement>(null)
  const emiratesIdBackInputRef = useRef<HTMLInputElement>(null)
  const passportInputRef = useRef<HTMLInputElement>(null)
  const passportBackInputRef = useRef<HTMLInputElement>(null)
  const visaInputRef = useRef<HTMLInputElement>(null)
  const letterOfIntentInputRef = useRef<HTMLInputElement>(null)
  const AddcBillInputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation();

  var emirates_front: any
  var emirates_back: any
  var passport: any
  var passport_back: any
  var visa: any
  var letter_of_intent: any
  var addc_bill: any

  // const handleEmiratesIdFrontUpload = () => {
  //   emiratesIdFrontInputRef.current?.click()
  // }

  // const handleEmiratesIdBackUpload = () => {
  //   emiratesIdBackInputRef.current?.click()
  // }

  // const handlePassportUpload = () => {
  //   passportInputRef.current?.click()
  // }
  // const handlePassporBacktUpload = () => {
  //   passportBackInputRef.current?.click()
  // }
  // const handleVisaUpload = () => {
  //   visaInputRef.current?.click()
  // }

  // const handleLetterOfIntentUpload = () => {
  //   letterOfIntentInputRef.current?.click()
  // }

  // const handleAddcBilltUpload = () => {
  //   AddcBillInputRef.current?.click()
  // }

  const initialValues = {
    emirates_front: '',
    emirates_back: '',
    passport: '',
    passport_back: '',
    visa: '',
    letter_of_intent: '',
    addc_bill: '',
  }

  const documentVerficationSchema = Yup.object().shape({
    emirates_front: Yup.mixed()
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .png .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    emirates_back: Yup.mixed()
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .png .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    passport: Yup.mixed()
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .png .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    passport_back: Yup.mixed()
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .png .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),

    visa: Yup.mixed()
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .png .pdf"), (value) => {
        if (value === 0) return true
        if (value) {
          return (
            value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf'
          )
        }
        return true
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    letter_of_intent: Yup.mixed()
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .png .pdf"), (value) => {
        if (value === 0) return true
        if (value) {
          return (
            value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf'
          )
        }
        return true
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    addc_bill: Yup.mixed()
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .png .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: documentVerficationSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)

      var FormData = require('form-data')
      var data = new FormData()
      if (formik.values.emirates_front != '0') {
        data.append('emirates_front', values.emirates_front)
      }
      if (formik.values.emirates_back != '0') {
        data.append('emirates_back', values.emirates_back)
      }
      if (formik.values.passport != '0') {
        data.append('passport', values.passport)
      }
      if (formik.values.passport_back != '0') {
        data.append('passport_back', values.passport_back)
      }
      if (formik.values.visa != '0') {
        data.append('visa', values.visa)
      }
      if (formik.values.letter_of_intent != '0') {
        data.append('letter_of_intent', values.letter_of_intent)
      }
      if (formik.values.addc_bill != '0') {
        data.append('addc_bill', values.addc_bill)
      }

      setTimeout(() => {
        uploadMyDocs(data)
          .then((response: any) => {
            setLoading(false)
            //   closeRef.current.click()
            formik.setTouched({})
            getDocs()
            console.log(response)
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  // const handleDisplayEmiratesIdFrontDetails = (e: any) => {
  //   formik.setFieldTouched('emirates_front')
  //   emiratesIdFrontInputRef.current?.files &&
  //     setUploadedEmiratesIdFrontName(emiratesIdFrontInputRef.current.files[0].name)
  //   formik.setFieldValue('emirates_front', e.currentTarget.files[0])
  // }

  // const handleDisplayEmiratesIdBackDetails = (e: any) => {
  //   formik.setFieldTouched('emirates_back')
  //   emiratesIdBackInputRef.current?.files &&
  //     setUploadedEmiratesIdBackName(emiratesIdBackInputRef.current.files[0].name)
  //   formik.setFieldValue('emirates_back', e.currentTarget.files[0])
  // }

  // const handleDisplayPassportDetails = (e: any) => {
  //   formik.setFieldTouched('passport')
  //   passportInputRef.current?.files &&
  //     setUploadedPassportName(passportInputRef.current.files[0].name)
  //   formik.setFieldValue('passport', e.currentTarget.files[0])
  // }
  // const handleDisplayPassportBackDetails = (e: any) => {
  //   formik.setFieldTouched('passport_back')
  //   passportBackInputRef.current?.files &&
  //     setUploadedPassportBackName(passportBackInputRef.current.files[0].name)
  //   formik.setFieldValue('passport_back', e.currentTarget.files[0])
  // }
  // const handleDisplayVisaDetails = (e: any) => {
  //   formik.setFieldTouched('visa')
  //   visaInputRef.current?.files && setUploadedVisaName(visaInputRef.current.files[0].name)
  //   formik.setFieldValue('visa', e.currentTarget.files[0])
  // }

  // const handleDisplayLetterOfIntentDetails = (e: any) => {
  //   formik.setFieldTouched('letter_of_intent')
  //   letterOfIntentInputRef.current?.files &&
  //     setUploadedLetterOfIntentName(letterOfIntentInputRef.current.files[0].name)
  //   formik.setFieldValue('letter_of_intent', e.currentTarget.files[0])
  // }

  // const handleDisplayAdccBillDetails = (e: any) => {
  //   formik.setFieldTouched('addc_bill')
  //   AddcBillInputRef.current?.files &&
  //     setUploadedAddcBillName(AddcBillInputRef.current.files[0].name)
  //   formik.setFieldValue('addc_bill', e.currentTarget.files[0])
  // }

  const handleView = (data: any) => {
    setViewImage(data)
  }

  const formatTitle = (string: string) => {
    return string.replaceAll('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())
  }

  useEffect(() => {
    formik.setFieldValue('emirates_front', 0)
    formik.setFieldValue('emirates_back', 0)
    formik.setFieldValue('passport', 0)
    formik.setFieldValue('passport_back', 0)
    formik.setFieldValue('visa', 0)
    formik.setFieldValue('letter_of_intent', 0)
    formik.setFieldValue('addc_bill', 0)
  }, [emirates_front, emirates_back, passport, passport_back, visa, letter_of_intent, addc_bill])

  // Emirates Front
  const [uploading, setUploading] = useState(false)
  const handleEmiratesIdFrontUpload = () => {
    emiratesIdFrontInputRef.current?.click()
  }

  const handleDisplayEmiratesIdFrontDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      emiratesIdFrontInputRef.current?.files && setUploadedEmiratesIdFrontName(emiratesIdFrontInputRef.current.files[0].name)
      formik.setFieldTouched('emirates_front')
      formik.setFieldValue('emirates_front', e.currentTarget.files[0])
    } else {
      setUploading(true)
      formik.setFieldError('emirates_front', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            emiratesIdFrontInputRef.current?.files && setUploadedEmiratesIdFrontName(compressResponse.file.name);
            formik.setFieldTouched('emirates_front', true, false)
            formik.setFieldValue('emirates_front', compressResponse.file, false)
          } else {
            setUploadedEmiratesIdFrontName(null)
            formik.setFieldTouched('emirates_front', true, false)
            formik.setFieldValue('emirates_front', '', false)
            formik.setFieldError('emirates_front', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedEmiratesIdFrontName(null)
        formik.setFieldTouched('emirates_front')
        formik.setFieldValue('emirates_front', '', false)
        formik.setFieldError('emirates_front', t('File upload error!'))
        setUploading(false)
      }
    }
  }

  // Emirates Back
  const [uploading1, setUploading1] = useState(false)
  const handleEmiratesIdBackUpload = () => {
    emiratesIdBackInputRef.current?.click()
  }

  const handleDisplayEmiratesIdBackDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      emiratesIdBackInputRef.current?.files && setUploadedEmiratesIdBackName(emiratesIdBackInputRef.current.files[0].name)
      formik.setFieldTouched('emirates_back')
      formik.setFieldValue('emirates_back', e.currentTarget.files[0])
    } else {
      setUploading1(true)
      formik.setFieldError('emirates_back', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            emiratesIdBackInputRef.current?.files && setUploadedEmiratesIdBackName(compressResponse.file.name);
            formik.setFieldTouched('emirates_back', true, false)
            formik.setFieldValue('emirates_back', compressResponse.file, false)
          } else {
            setUploadedEmiratesIdBackName(null)
            formik.setFieldTouched('emirates_back', true, false)
            formik.setFieldValue('emirates_back', '', false)
            formik.setFieldError('emirates_back', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading1(false)
        })
      } catch (error) {
        setUploadedEmiratesIdBackName(null)
        formik.setFieldTouched('emirates_back')
        formik.setFieldValue('emirates_back', '', false)
        formik.setFieldError('emirates_back', t('File upload error!'))
        setUploading1(false)
      }
    }
  }


  // Passport Front
  const [uploading2, setUploading2] = useState(false)
  const handlePassportUpload = () => {
    passportInputRef.current?.click()
  }

  const handleDisplayPassportDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      passportInputRef.current?.files && setUploadedPassportName(passportInputRef.current.files[0].name)
      formik.setFieldTouched('passport')
      formik.setFieldValue('passport', e.currentTarget.files[0])
    } else {
      setUploading2(true)
      formik.setFieldError('passport', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            passportInputRef.current?.files && setUploadedPassportName(compressResponse.file.name);
            formik.setFieldTouched('passport', true, false)
            formik.setFieldValue('passport', compressResponse.file, false)
          } else {
            setUploadedPassportName(null)
            formik.setFieldTouched('passport', true, false)
            formik.setFieldValue('passport', '', false)
            formik.setFieldError('passport', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading2(false)
        })
      } catch (error) {
        setUploadedPassportName(null)
        formik.setFieldTouched('passport')
        formik.setFieldValue('passport', '', false)
        formik.setFieldError('passport', t('File upload error!'))
        setUploading2(false)
      }
    }
  }

  // Passport Back
  const [uploading3, setUploading3] = useState(false)
  const handlePassporBacktUpload = () => {
    passportBackInputRef.current?.click()
  }

  const handleDisplayPassportBackDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      passportBackInputRef.current?.files && setUploadedPassportBackName(passportBackInputRef.current.files[0].name)
      formik.setFieldTouched('passport_back')
      formik.setFieldValue('passport_back', e.currentTarget.files[0])
    } else {
      setUploading3(true)
      formik.setFieldError('passport_back', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            passportBackInputRef.current?.files && setUploadedPassportBackName(compressResponse.file.name);
            formik.setFieldTouched('passport_back', true, false)
            formik.setFieldValue('passport_back', compressResponse.file, false)
          } else {
            setUploadedPassportBackName(null)
            formik.setFieldTouched('passport_back', true, false)
            formik.setFieldValue('passport_back', '', false)
            formik.setFieldError('passport_back', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading3(false)
        })
      } catch (error) {
        setUploadedPassportBackName(null)
        formik.setFieldTouched('passport_back')
        formik.setFieldValue('passport_back', '', false)
        formik.setFieldError('passport_back', t('File upload error!'))
        setUploading3(false)
      }
    }
  }

  // Visa
  const [uploading4, setUploading4] = useState(false)
  const handleVisaUpload = () => {
    visaInputRef.current?.click()
  }

  const handleDisplayVisaDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      visaInputRef.current?.files && setUploadedVisaName(visaInputRef.current.files[0].name)
      formik.setFieldTouched('visa')
      formik.setFieldValue('visa', e.currentTarget.files[0])
    } else {
      setUploading4(true)
      formik.setFieldError('visa', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            visaInputRef.current?.files && setUploadedVisaName(compressResponse.file.name);
            formik.setFieldTouched('visa', true, false)
            formik.setFieldValue('visa', compressResponse.file, false)
          } else {
            setUploadedVisaName(null)
            formik.setFieldTouched('visa', true, false)
            formik.setFieldValue('visa', '', false)
            formik.setFieldError('visa', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading4(false)
        })
      } catch (error) {
        setUploadedVisaName(null)
        formik.setFieldTouched('visa')
        formik.setFieldValue('visa', '', false)
        formik.setFieldError('visa', t('File upload error!'))
        setUploading4(false)
      }
    }
  }

  // Letter of Intend Details 
  const [uploading5, setUploading5] = useState(false)
  const handleLetterOfIntentUpload = () => {
    letterOfIntentInputRef.current?.click()
  }

  const handleDisplayLetterOfIntentDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      letterOfIntentInputRef.current?.files && setUploadedLetterOfIntentName(letterOfIntentInputRef.current.files[0].name)
      formik.setFieldTouched('letter_of_intent')
      formik.setFieldValue('letter_of_intent', e.currentTarget.files[0])
    } else {
      setUploading5(true)
      formik.setFieldError('letter_of_intent', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            letterOfIntentInputRef.current?.files && setUploadedLetterOfIntentName(compressResponse.file.name);
            formik.setFieldTouched('letter_of_intent', true, false)
            formik.setFieldValue('letter_of_intent', compressResponse.file, false)
          } else {
            setUploadedLetterOfIntentName(null)
            formik.setFieldTouched('letter_of_intent', true, false)
            formik.setFieldValue('letter_of_intent', '', false)
            formik.setFieldError('letter_of_intent', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading5(false)
        })
      } catch (error) {
        setUploadedLetterOfIntentName(null)
        formik.setFieldTouched('letter_of_intent')
        formik.setFieldValue('letter_of_intent', '', false)
        formik.setFieldError('letter_of_intent', t('File upload error!'))
        setUploading5(false)
      }
    }
  }

  // ADDC 
  const [uploading6, setUploading6] = useState(false)
  const handleAddcBilltUpload = () => {
    AddcBillInputRef.current?.click()
  }

  const handleDisplayAdccBillDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      AddcBillInputRef.current?.files && setUploadedAddcBillName(AddcBillInputRef.current.files[0].name)
      formik.setFieldTouched('addc_bill')
      formik.setFieldValue('addc_bill', e.currentTarget.files[0])
    } else {
      setUploading6(true)
      formik.setFieldError('addc_bill', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            AddcBillInputRef.current?.files && setUploadedAddcBillName(compressResponse.file.name);
            formik.setFieldTouched('addc_bill', true, false)
            formik.setFieldValue('addc_bill', compressResponse.file, false)
          } else {
            setUploadedAddcBillName(null)
            formik.setFieldTouched('addc_bill', true, false)
            formik.setFieldValue('addc_bill', '', false)
            formik.setFieldError('addc_bill', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading6(false)
        })
      } catch (error) {
        setUploadedAddcBillName(null)
        formik.setFieldTouched('addc_bill')
        formik.setFieldValue('addc_bill', '', false)
        formik.setFieldError('addc_bill', t('File upload error!'))
        setUploading6(false)
      }
    }
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='row'>
                  {needActionDocuments?.length
                    ? needActionDocuments?.map((document: any) => {
                      if (document.document_type == 'emirates_front') {
                        return (
                          <div className='col-xl-6 col-md-6 col-12 mb-6' key={document.id}>
                            <div className='gray-card'>
                              <div className='row'>
                                <div className='col-sm-8 col-12'>
                                  <h4 className='fw-bold font-16 text-black'>
                                    {formatTitle(document?.document_type)}
                                  </h4>
                                  <p className='text-normal font-12'>
                                    {t("Your document is Expiring soon")}
                                  </p>
                                </div>
                                <div className='col-sm-4 col-12 text-end'>
                                  <div>
                                    <button
                                      className='border-0 bg-transparent'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView(document)
                                      }}
                                    >
                                      <i className='text-black bi bi-eye font-13 me-3'></i>
                                    </button>
                                    <ViewPopup viewImage={viewImage} />

                                    <Link to={{ pathname: document?.filename }} target='_blank'>
                                      <i className='text-black bi bi-download font-13'></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-center'>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Date Added")} : {moment(document?.created).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Expiries On")} : {moment(document?.expiry_date).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <div className='d-flex'>
                                    {/* <Link to={'/'} className='btn btn-outline-primary bg-white border-0 font-15 px-3 py-2 me-3 w-100'>Cancel</Link> */}
                                    {/* <Link to={'/'} className='btn btn-primary font-15 px-3 py-2 w-100'> */}
                                    <input
                                      ref={emiratesIdFrontInputRef}
                                      onChange={(e) => handleDisplayEmiratesIdFrontDetails(e)}
                                      className='d-none'
                                      type='file'
                                    // accept='.jpeg,.jpg,.png,application/pdf'
                                    />
                                    <button
                                      type='button'
                                      onClick={handleEmiratesIdFrontUpload}
                                      // className={`w-100 py-3 ellipsis btn btn-outline-${uploadedEmiratesIdFrontName ? 'primary' : 'primary'
                                      //   }`}
                                      disabled={uploading}
                                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedEmiratesIdFrontName ? 'primary' : 'primary'
                                        }`}
                                    >
                                      {/* <span>
                                        {uploadedEmiratesIdFrontName ? (
                                          uploadedEmiratesIdFrontName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                      </span> */}
                                      {uploading ?
                                        <span className='indicator-progress d-block'>
                                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        : uploadedEmiratesIdFrontName ? (
                                          uploadedEmiratesIdFrontName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {formik.touched.emirates_front && formik.errors.emirates_front && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.emirates_front}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      }
                      if (document.document_type == 'emirates_back') {
                        return (
                          <div className='col-xl-6 col-md-6 col-12 mb-6' key={document.id}>
                            <div className='gray-card'>
                              <div className='row'>
                                <div className='col-sm-8 col-12'>
                                  <h4 className='fw-bold font-16 text-black'>
                                    {formatTitle(document?.document_type)}
                                  </h4>
                                  <p className='text-normal font-12'>
                                    {t("Your document is Expiring soon")}
                                  </p>
                                </div>
                                <div className='col-sm-4 col-12 text-end'>
                                  <div>
                                    <button
                                      className='border-0 bg-transparent'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView(document)
                                      }}
                                    >
                                      <i className='text-black bi bi-eye font-13 me-3'></i>
                                    </button>
                                    <ViewPopup viewImage={viewImage} />

                                    <Link to={{ pathname: document?.filename }} target='_blank'>
                                      <i className='text-black bi bi-download font-13'></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-center'>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Date Added")} : {moment(document?.created).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Expiries On")} : {moment(document?.expiry_date).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <div className='d-flex'>
                                    {/* <Link to={'/'} className='btn btn-outline-primary bg-white border-0 font-15 px-3 py-2 me-3 w-100'>Cancel</Link> */}
                                    {/* <Link to={'/'} className='btn btn-primary font-15 px-3 py-2 w-100'> */}
                                    <input
                                      ref={emiratesIdBackInputRef}
                                      onChange={(e) => handleDisplayEmiratesIdBackDetails(e)}
                                      className='d-none'
                                      type='file'
                                    // accept='.jpeg,.jpg,.png,application/pdf'
                                    />
                                    <button
                                      type='button'
                                      onClick={handleEmiratesIdBackUpload}
                                      // className={`w-100 py-3 ellipsis btn btn-outline-${uploadedEmiratesIdBackName ? 'primary' : 'primary'
                                      //   }`}
                                      disabled={uploading1}
                                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedEmiratesIdBackName ? 'primary' : 'primary'
                                        }`}
                                    >
                                      {/* <span>
                                        {uploadedEmiratesIdBackName ? (
                                          uploadedEmiratesIdBackName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                      </span> */}
                                      {uploading1 ?
                                        <span className='indicator-progress d-block'>
                                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        : uploadedEmiratesIdBackName ? (
                                          uploadedEmiratesIdBackName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {formik.touched.emirates_back && formik.errors.emirates_back && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.emirates_back}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      }
                      if (document.document_type == 'passport') {
                        return (
                          <div className='col-xl-6 col-md-6 col-12 mb-6' key={document.id}>
                            <div className='gray-card'>
                              <div className='row'>
                                <div className='col-sm-8 col-12'>
                                  <h4 className='fw-bold font-16 text-black'>
                                    {formatTitle(document?.document_type)}
                                  </h4>
                                  <p className='text-normal font-12'>
                                    {t("Your document is Expiring soon")}
                                  </p>
                                </div>
                                <div className='col-sm-4 col-12 text-end'>
                                  <div>
                                    <button
                                      className='border-0 bg-transparent'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView(document)
                                      }}
                                    >
                                      <i className='text-black bi bi-eye font-13 me-3'></i>
                                    </button>
                                    <ViewPopup viewImage={viewImage} />

                                    <Link to={{ pathname: document?.filename }} target='_blank'>
                                      <i className='text-black bi bi-download font-13'></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-center'>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Date Added")} : {moment(document?.created).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Expiries On")} : {moment(document?.expiry_date).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <div className='d-flex'>
                                    {/* <Link to={'/'} className='btn btn-outline-primary bg-white border-0 font-15 px-3 py-2 me-3 w-100'>Cancel</Link> */}
                                    {/* <Link to={'/'} className='btn btn-primary font-15 px-3 py-2 w-100'> */}
                                    <input
                                      ref={passportInputRef}
                                      onChange={(e) => handleDisplayPassportDetails(e)}
                                      className='d-none'
                                      type='file'
                                    // accept='.jpeg,.jpg,.png,application/pdf'
                                    />
                                    <button
                                      type='button'
                                      onClick={handlePassportUpload}
                                      // className={`w-100 py-3 ellipsis btn btn-outline-${uploadedPassportName ? 'primary' : 'primary'
                                      //   }`}
                                      disabled={uploading2}
                                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedPassportName ? 'primary' : 'primary'
                                        }`}
                                    >
                                      {/* <span>
                                        {uploadedPassportName ? (
                                          uploadedPassportName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                      </span> */}
                                      {uploading2 ?
                                        <span className='indicator-progress d-block'>
                                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        : uploadedPassportName ? (
                                          uploadedPassportName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {formik.touched.passport && formik.errors.passport && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.passport}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      }
                      if (document.document_type == 'passport_back') {
                        return (
                          <div className='col-xl-6 col-md-6 col-12 mb-6' key={document.id}>
                            <div className='gray-card'>
                              <div className='row'>
                                <div className='col-sm-8 col-12'>
                                  <h4 className='fw-bold font-16 text-black'>
                                    {formatTitle(document?.document_type)}
                                  </h4>
                                  <p className='text-normal font-12'>
                                    {t("Your document is Expiring soon")}
                                  </p>
                                </div>
                                <div className='col-sm-4 col-12 text-end'>
                                  <div>
                                    <button
                                      className='border-0 bg-transparent'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView(document)
                                      }}
                                    >
                                      <i className='text-black bi bi-eye font-13 me-3'></i>
                                    </button>
                                    <ViewPopup viewImage={viewImage} />

                                    <Link to={{ pathname: document?.filename }} target='_blank'>
                                      <i className='text-black bi bi-download font-13'></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-center'>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Date Added")} : {moment(document?.created).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Expiries On")} : {moment(document?.expiry_date).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <div className='d-flex'>
                                    {/* <Link to={'/'} className='btn btn-outline-primary bg-white border-0 font-15 px-3 py-2 me-3 w-100'>Cancel</Link> */}
                                    {/* <Link to={'/'} className='btn btn-primary font-15 px-3 py-2 w-100'> */}
                                    <input
                                      ref={passportBackInputRef}
                                      onChange={(e) => handleDisplayPassportBackDetails(e)}
                                      className='d-none'
                                      type='file'
                                    // accept='.jpeg,.jpg,.png,application/pdf'
                                    />
                                    <button
                                      type='button'
                                      onClick={handlePassporBacktUpload}
                                      // className={`w-100 py-3 ellipsis btn btn-outline-${uploadedPassportBackName ? 'primary' : 'primary'
                                      //   }`}
                                      disabled={uploading3}
                                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedPassportBackName ? 'primary' : 'primary'
                                        }`}
                                    >
                                      {/* <span>
                                        {uploadedPassportBackName ? (
                                          uploadedPassportBackName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                      </span> */}
                                      {uploading3 ?
                                        <span className='indicator-progress d-block'>
                                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        : uploadedPassportBackName ? (
                                          uploadedPassportBackName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {formik.touched.passport_back && formik.errors.passport_back && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.passport_back}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      }
                      if (document.document_type == 'visa') {
                        return (
                          <div className='col-xl-6 col-md-6 col-12 mb-6' key={document.id}>
                            <div className='gray-card'>
                              <div className='row'>
                                <div className='col-sm-8 col-12'>
                                  <h4 className='fw-bold font-16 text-black'>
                                    {formatTitle(document?.document_type)}
                                  </h4>
                                  <p className='text-normal font-12'>
                                    {t("Your document is Expiring soon")}
                                  </p>
                                </div>
                                <div className='col-sm-4 col-12 text-end'>
                                  <div>
                                    <button
                                      className='border-0 bg-transparent'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView(document)
                                      }}
                                    >
                                      <i className='text-black bi bi-eye font-13 me-3'></i>
                                    </button>
                                    <ViewPopup viewImage={viewImage} />

                                    <Link to={{ pathname: document?.filename }} target='_blank'>
                                      <i className='text-black bi bi-download font-13'></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-center'>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Date Added")} : {moment(document?.created).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Expiries On")} : {moment(document?.expiry_date).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <div className='d-flex'>
                                    {/* <Link to={'/'} className='btn btn-outline-primary bg-white border-0 font-15 px-3 py-2 me-3 w-100'>Cancel</Link> */}
                                    {/* <Link to={'/'} className='btn btn-primary font-15 px-3 py-2 w-100'> */}
                                    <input
                                      ref={visaInputRef}
                                      onChange={(e) => handleDisplayVisaDetails(e)}
                                      className='d-none'
                                      type='file'
                                    // accept='.jpeg,.jpg,.png,application/pdf'
                                    />
                                    <button
                                      type='button'
                                      onClick={handleVisaUpload}
                                      // className={`w-100 py-3 ellipsis btn btn-outline-${uploadedVisaName ? 'primary' : 'primary'
                                      //   }`}
                                      disabled={uploading4}
                                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedVisaName ? 'primary' : 'primary'
                                        }`}
                                    >
                                      {/* <span>
                                        {uploadedVisaName ? (
                                          uploadedVisaName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                      </span> */}
                                      {uploading4 ?
                                        <span className='indicator-progress d-block'>
                                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        : uploadedVisaName ? (
                                          uploadedVisaName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {formik.touched.visa && formik.errors.visa && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.visa}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      }
                      if (document.document_type == 'letter_of_intent') {
                        return (
                          <div className='col-xl-6 col-md-6 col-12 mb-6' key={document.id}>
                            <div className='gray-card'>
                              <div className='row'>
                                <div className='col-sm-8 col-12'>
                                  <h4 className='fw-bold font-16 text-black'>
                                    {formatTitle(document?.document_type)}
                                  </h4>
                                  <p className='text-normal font-12'>
                                    {t("Your document is Expiring soon")}
                                  </p>
                                </div>
                                <div className='col-sm-4 col-12 text-end'>
                                  <div>
                                    <button
                                      className='border-0 bg-transparent'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView(document)
                                      }}
                                    >
                                      <i className='text-black bi bi-eye font-13 me-3'></i>
                                    </button>
                                    <ViewPopup viewImage={viewImage} />

                                    <Link to={{ pathname: document?.filename }} target='_blank'>
                                      <i className='text-black bi bi-download font-13'></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-center'>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Date Added")} : {moment(document?.created).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Expiries On")} : {moment(document?.expiry_date).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <div className='d-flex'>
                                    {/* <Link to={'/'} className='btn btn-outline-primary bg-white border-0 font-15 px-3 py-2 me-3 w-100'>Cancel</Link> */}
                                    {/* <Link to={'/'} className='btn btn-primary font-15 px-3 py-2 w-100'> */}
                                    <input
                                      ref={letterOfIntentInputRef}
                                      onChange={(e) => handleDisplayLetterOfIntentDetails(e)}
                                      className='d-none'
                                      type='file'
                                    // accept='.jpeg,.jpg,.png,application/pdf'
                                    />
                                    <button
                                      type='button'
                                      onClick={handleLetterOfIntentUpload}
                                      // className={`w-100 py-3 ellipsis btn btn-outline-${uploadedLetterOfIntentName ? 'primary' : 'primary'
                                      //   }`}
                                      disabled={uploading5}
                                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedLetterOfIntentName ? 'primary' : 'primary'
                                        }`}
                                    >
                                      {/* <span>
                                        {uploadedLetterOfIntentName ? (
                                          uploadedLetterOfIntentName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                      </span> */}
                                      {uploading5 ?
                                        <span className='indicator-progress d-block'>
                                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        : uploadedLetterOfIntentName ? (
                                          uploadedLetterOfIntentName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {formik.touched.letter_of_intent && formik.errors.letter_of_intent && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.letter_of_intent}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      }
                      if (document.document_type == 'addc_bill') {
                        return (
                          <div className='col-xl-6 col-md-6 col-12 mb-6' key={document.id}>
                            <div className='gray-card'>
                              <div className='row'>
                                <div className='col-sm-8 col-12'>
                                  <h4 className='fw-bold font-16 text-black'>
                                    {formatTitle(document?.document_type)}
                                  </h4>
                                  <p className='text-normal font-12'>
                                    {t("Your document is Expiring soon")}
                                  </p>
                                </div>
                                <div className='col-sm-4 col-12 text-end'>
                                  <div>
                                    <button
                                      className='border-0 bg-transparent'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView(document)
                                      }}
                                    >
                                      <i className='text-black bi bi-eye font-13 me-3'></i>
                                    </button>
                                    <ViewPopup viewImage={viewImage} />

                                    <Link to={{ pathname: document?.filename }} target='_blank'>
                                      <i className='text-black bi bi-download font-13'></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-center'>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Date Added")} : {moment(document?.created).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <p className='text-normal font-12 fw-bold'>
                                    {t("Expiries On")} : {moment(document?.expiry_date).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                                <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                                  <div className='d-flex'>
                                    {/* <Link to={'/'} className='btn btn-outline-primary bg-white border-0 font-15 px-3 py-2 me-3 w-100'>Cancel</Link> */}
                                    {/* <Link to={'/'} className='btn btn-primary font-15 px-3 py-2 w-100'> */}
                                    <input
                                      ref={AddcBillInputRef}
                                      onChange={(e) => handleDisplayAdccBillDetails(e)}
                                      className='d-none'
                                      type='file'
                                    // accept='.jpeg,.jpg,.png,application/pdf'
                                    />
                                    <button
                                      type='button'
                                      onClick={handleAddcBilltUpload}
                                      // className={`w-100 py-3 ellipsis btn btn-outline-${uploadedAddcBillName ? 'primary' : 'primary'
                                      //   }`}
                                      disabled={uploading6}
                                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedAddcBillName ? 'primary' : 'primary'
                                        }`}
                                    >
                                      {/* <span>
                                        {uploadedAddcBillName ? (
                                          uploadedAddcBillName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                      </span> */}
                                      {uploading6 ?
                                        <span className='indicator-progress d-block'>
                                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        : uploadedAddcBillName ? (
                                          uploadedAddcBillName
                                        ) : (
                                          <span>
                                            {t("Upload")}{' '}
                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                          </span>
                                        )}{' '}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {formik.touched.addc_bill && formik.errors.addc_bill && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.addc_bill}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      }
                    })
                    : <>
                      <div className='row'>
                        <div className="col-12">
                          <div className="card border-0 shadow-none rounded-0 no-data-found-cd">
                            <div className="card-body border-0">
                              <div className="row justify-content-center">
                                <div className="col-12 text-center">
                                  <img src={NoDataFoundImage} alt="No action required" className="img-fluid" />
                                </div>
                                <div className="col-12 text-center">
                                  <span className='d-block text-center font-16 fw-bolder text-black'> {t("No action required")} </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {needActionDocuments?.length ? (
                    <button
                      type='submit'
                      className='btn btn-primary fw-bold px-10 py-3'
                      disabled={loading || !Object.keys(formik.touched).length || uploading || uploading1 || uploading2 || uploading3 || uploading4 || uploading5 || uploading6}
                    >
                      {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {t("Please wait...")}{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
