import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {StatementTabs} from './components/StatementTabs/StatementTabs'
import {ApprovedInvoices} from './components/StatementTabs/ApprovedInvoices/ApprovedInvoices'
import {InProgressInvoices} from './components/StatementTabs/InProgressInvoices/InProgressInvoices'
import {RejectedInvoices} from './components/StatementTabs/RejectedInvoices/RejectedInvoices'
import {AddCommission} from './components/Add/AddCommission/AddCommission'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RequestSuccessfulPopup} from '../SuccessfulPopup/RequestSuccessfulPopup'

import {useSelector} from 'react-redux'

import {getBrokerStatements} from '../../utils/makeRequest'
import {IState} from '../../utils/interface'

const StatementsPage: FC = () => {
  const state: any = useSelector((state) => state)
  const [searchItem, setSearchItem] = useState<any>('')
  const userToken = state?.auth?.accessToken

  if (userToken === 'undefined') {
    // token undefined
  }

  /* const [statementPending, setStatementPending] = useState([]);
    const [statementRejected, setStatementRejected] = useState([]);
    const [statementApproved, setStatementApproved] = useState([]); */

  const [brokerStatements, setBrokerStatements] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })

  const {loading, response: statements, error} = brokerStatements
  useEffect(() => {
    getBrokerStatements(setBrokerStatements, userToken)
  }, [])

  /* useEffect(()=>{
        let pending = statements.filter((statement: any) => statement.status === "Pending");
        let rejected = statements.filter((statement: any) => statement.status === "Rejected");
        let approved = statements.filter((statement: any) => statement.status === "Approved");

        setStatementPending(pending);
        setStatementRejected(rejected);
        setStatementApproved(approved);

    },[brokerStatements]); */

  return (
    <>
      <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
        <div className='col-xxl-12 mt-0'>
          <Switch>
            <Route path='/statements/approved-invoices'>
              <StatementTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <ApprovedInvoices searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/statements/inprogress-invoices'>
              <StatementTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <InProgressInvoices searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/statements/rejected-invoices'>
              <StatementTabs searchItem={searchItem} setSearchItem={setSearchItem} />
              <RejectedInvoices searchItem={searchItem} setSearchItem={setSearchItem} />
            </Route>
            <Route path='/statements/add-commission'>
              <AddCommission />
              <RequestSuccessfulPopup />
            </Route>
          </Switch>
          {/*  <Switch>
                    <Route path='/statements/approved-invoices'>
                        <StatementTabs />
                        <ApprovedInvoices 
                            loading={loading}
                            error={error}
                            statementApproved={statementApproved}
                        />
                    </Route>
                    <Route path='/statements/inprogress-invoices'>
                        <StatementTabs />
                        <InProgressInvoices 
                            loading={loading}
                            error={error}
                            statementPending={statementPending}
                        />
                    </Route>
                    <Route path='/statements/rejected-invoices'>
                        <StatementTabs />
                        <RejectedInvoices 
                            loading={loading}
                            error={error}
                            statementRejected={statementRejected}    
                        />
                    </Route>
                    <Route path='/statements/add-commission'>
                        <AddCommission />
                        <RequestSuccessfulPopup />
                    </Route>
                </Switch> */}
        </div>
      </div>
    </>
  )
}

const StatementsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}> Statements </PageTitle>
      <StatementsPage />
    </>
  )
}

export {StatementsWrapper}
