import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { getMyTeam } from '../../../../constants/axios/apis';

export const MyTeamList: React.FC<any> = ({ fcsMyTeamList }) => {
    const [myTeamList, setMyTeamList] = useState<any>([]);
    const { t } = useTranslation();

    useEffect(() => {
        getMyTeam().then(response => {
            if (response.code === 200 && response.errorCode === 0) {
                setMyTeamList(response.data.teamList)
            } else {
                setMyTeamList([])
            }
        })
    }, [])

    const columns = [
        {
            field: 'id',
            headerName: t("No"),
            sortable: false,
            disableColumnMenu: true,
            width: 100,
        },
        {
            field: 'name',
            headerName: t("Name"),
            width: 200,
        },
        {
            field: 'mobileNumber',
            headerName: t("Mobile Number"),
            width: 200,
        },
        {
            field: 'email',
            headerName: t("Email"),
            width: 250,
        },
        {
            field: 'designation',
            headerName: t("Designation"),
            width: 250,
        }
    ];

    return (
        <div className="card mt-9">
            <div className="card-body px-8 py-2">
                <div className="rec-payment-act-sec pb-5">
                    <div className="row">
                        <div className="col-12 pt-5">
                            <div className="rec-payment-act-sec">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="data_table">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className='table-responsive'>
                                                        <div style={{ height: 400, width: "100%" }}>
                                                            <DataGrid
                                                                rows={myTeamList}
                                                                getRowId={(row) => row.id}
                                                                columns={columns}
                                                                pageSize={5}
                                                                rowsPerPageOptions={[5]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}