import React, {useEffect, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import LeftArrow from '../../../assets/images/Left_Arrow.png'
import ProfileImage from '../../../assets/images/profile-av-lg.jpg'
import {useTranslation} from 'react-i18next'
import {getBrokerMyTeam} from '../../../constants/axios/apis'
import {CustomLoader2} from '../../../constants/generics/customActios'
import ProfileAvatar from '../../../assets/images/DefaultUser.png'

export function SingleBrokerDetails() {
  let params: any = {}
  params = useLocation()
  const history = useHistory()

  // if (!params?.state?.broker?.id) {
  //   window.location.replace('/broker-admin-dashboard/available-properties')
  // }

  let brokerID = ''
  let isNotifRedirect = false
  // const { broker } = params.state
  if (params?.state?.broker?.id) brokerID = params.state.broker.id
  if (params?.state?.broker?.isNotifRedirect) isNotifRedirect = params.state.broker.isNotifRedirect
  const {t} = useTranslation()
  const [loading, setLoading] = useState<any>(true)
  const [broker, setBrokerDetails] = useState<any>()

  useEffect(() => {
    setLoading(true)
    setBrokerDetails({})
    getBrokerMyTeam(`?id=${brokerID}`)
      .then((response: any) => {
        if (
          response?.data?.teamList &&
          Array.isArray(response?.data?.teamList) &&
          response?.data?.teamList?.[0]
        )
          setBrokerDetails(response?.data?.teamList[0])
        setLoading(false)
      })
      .catch((e) => {})
  }, [brokerID])

  return (
    <div className='row gy-5 g-xl-8 broker-dash-page dashboard-page'>
      <div className='col-xxl-12 mt-0'>
        <div className='com-card-section'>
          <div className='row'>
            <div className='col-md-12'>
              <h4 className='card-title font-18 text-black mb-6'>
                {isNotifRedirect ?
                    <Link to={'/notifications'} className=''>
                      <img src={LeftArrow} className='img-fluid me-5' />
                    </Link>
                  :
                  <span className='me-5 c-pointer' onClick={() => { history.goBack() }}>
                    <img src={LeftArrow} className='img-fluid me-5' />
                  </span>
                }
                {t('Details')}
              </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='card mb-3'>
                <div className='card-body px-8 py-6'>
                  {loading ? (
                    <CustomLoader2 />
                  ) : (
                    <>
                      <div className='row'>
                        <div className='col-lg-3 col-12'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='profile-img-card rounded-5 w-100 mb-3 mb-lg-0 py-5 px-4 py-xl-8 px-xl-8'>
                                <div className='img border w-100 text-center'>
                                  <img
                                    src={ broker?.profileImage ? broker?.profileImage : ProfileAvatar }
                                    className='img-fluid rounded-circle shadow mb-4'
                                  />
                                  <h4 className='text-black mb-3'>
                                    {broker?.name ? broker?.name : '-'}
                                  </h4>
                                  {(broker?.status === 1 && broker?.is_approved === 1) ?
                                    <p className='bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder' >{t("Active")}</p>
                                    : broker?.status === 0 ?
                                    <p className='bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder' >{t("Inactive")}</p>
                                    : 
                                    <p className='bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder' >{t("Approval Pending")}</p>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-9 prof-det col-12'>
                          <div className='row'>
                            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-4'>
                              <div className='border rounded-5 p-4 h-100'>
                                <label className='font-14 fw-bold d-block mb-2'>
                                  {t('First Name')}
                                </label>
                                <span className='text-black font-15 fw-bold'>
                                  {broker?.firstname ? broker?.firstname : '-'}
                                </span>
                              </div>
                            </div>
                            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-4'>
                              <div className='border rounded-5 p-4 h-100'>
                                <label className='font-14 fw-bold d-block mb-2'>
                                  {t('Last Name')}
                                </label>
                                <span className='text-black font-15 fw-bold'>
                                  {broker?.lastname ? broker?.lastname : '-'}
                                </span>
                              </div>
                            </div>
                            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-4'>
                              <div className='border rounded-5 p-4 h-100'>
                                <label className='font-14 fw-bold d-block mb-2'>
                                  {t('Emirates ID')}
                                </label>
                                <span className='text-black font-15 fw-bold'>
                                  {broker?.emiratesId ? broker?.emiratesId : '-'}
                                </span>
                              </div>
                            </div>
                            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-4'>
                              <div className='border rounded-5 p-4 h-100'>
                                <label className='font-14 fw-bold d-block mb-2'>
                                  {t('Mobile No')}
                                </label>
                                <span className='text-black font-15 fw-bold'>
                                  {broker?.mobileNumber ? broker?.mobileNumber : '-'}
                                </span>
                              </div>
                            </div>
                            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-4'>
                              <div className='border rounded-5 p-4 h-100'>
                                <label className='font-14 fw-bold d-block mb-2'>{t('Email')}</label>
                                <span className='text-black font-15 fw-bold'>
                                  {broker?.email ? broker?.email : '-'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
