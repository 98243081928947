import React, { FC, useState, useRef } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { moveOutApprovalRequest } from '../../../../constants/axios/apis'
import moment from 'moment'
import { ViewPopup } from '../../../maintenance/ViewPopup'
import pdfImage from '../../../../assets/images/documentImage.png'
import uploadImg from '../../../../assets/images/file-upload.svg'
import DatePicker from 'react-date-picker'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

const today = new Date()
today.setHours(0, 0, 0, 0)

type Props = {
  stepper?: any
  contract?: any
  stepStatus?: any
  getStepStatus?: any
  setMessage?: any
}

const Step4Add: FC<Props> = ({ contract, stepStatus, getStepStatus, setMessage }: any) => {
  const [loading, setLoading] = useState(false)
  const [viewImage, setViewImage] = useState<any>()
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const closeRef = useRef<any>()
  const { t } = useTranslation();

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldValue('photo', e.currentTarget.files[0])
  // }

  const initialValues = {
    category_id: 16,
    property_id: contract?.propertyID,
    date: today,
    comments: '',
    photo: '',
  }

  const moveOutApprovalRequestSchema = Yup.object().shape({
    date: Yup.date().min(today, t("Date cannot be in the past")).required(t("Please select a date")),
    comments: Yup.string().max(250, t("Maximum 250 characters")),
    // photo: Yup.mixed()
    //   .required(t("ADDC Clearance Letter is required"))
    //   .test('fileSize', t("The file is too large!"), (value) => {
    //     if (!value?.length) return true
    //     return value[0]?.size <= 2000000
    //   }),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: moveOutApprovalRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        moveOutApprovalRequest(
          values.category_id,
          values.property_id,
          moment(values.date).format('YYYY-MM-DD'),
          values.comments,
          values.photo
        )
          .then((response) => {
            setMessage(t("Your Move Out Approval Request has been Submitted!"))
            closeRef.current.click()
            resetForm()
            setUploadedFileName(null)
            setLoading(false)
            getStepStatus()
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const handleDate = (e: any) => {
    formik.setFieldValue('date', new Date(e))
  }

  const handleView = (data: any) => {
    setViewImage(data)
  }

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('photo', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('photo')
          formik.setFieldValue('photo', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('photo', true, false)
          formik.setFieldValue('photo', '', false)
          formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', '', false)
      formik.setFieldError('photo', t('File upload error!'))
      setUploading(false)
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-12 col-lg-12 mb-5 col-12'>
          <div className='card rounded-5'>
            <div className='card-body p-7'>
              {!stepStatus?.status ? (
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='kt_login_signup_form'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='row form-add-design'>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 date-picker'>
                        <label className='form-label fw-bolder'>{t("ADDC Clearance Date")}<span className='text-danger'>*</span></label>
                        <DatePicker
                          onChange={(e: any) => handleDate(e)}
                          value={formik.values.date}
                          minDate={new Date(new Date().toLocaleDateString())}
                          className='form-control fw-bolder form-control-solid'
                          format='dd-MM-y'
                          clearIcon={null}
                          maxDate={new Date('12/31/2999')}
                        />
                        {formik.touched.date && formik.errors.date && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.date}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 upload-input'>
                        <label className='form-label fw-bolder d-block'>
                          {t("ADDC Clearance Letter")}<span className='text-danger'>*</span>
                        </label>
                        <input
                          ref={inputRef}
                          onChange={(e) => handleDisplayFileDetails(e)}
                          className='d-none'
                          type='file'
                          accept=".png,.jpg,.jpeg"
                        />
                        <button
                          type='button'
                          onClick={handleUpload}
                          disabled={uploading}
                          className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                            }`}
                        >
                          {/* {uploadedFileName ? uploadedFileName : t("Upload")}
                          <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i> */}
                       <span>
                            {uploading ?
                              <span className='indicator-progress d-block'>
                                {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                              : uploadedFileName ? (
                                uploadedFileName
                              ) : (
                                <span className="txt">
                                  <img
                                          alt="File"
                                          src={uploadImg}
                                  className="position-relative"
                                  loading="lazy"/>
                                  {t('Upload')}{' '}
                                </span>
                              )}{' '}
                          </span>
                        </button>
                        {formik.touched.photo && formik.errors.photo && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.photo}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-8 col-12'>
                      <div className=''>
                        <label className='form-label fw-bolder'>{t("Comment")}</label>
                        <textarea
                          className='form-control fw-bolder form-control-solid'
                          data-kt-autosize='true'
                          name='comments'
                          rows={3}
                          placeholder={t("Type here...")}
                          value={formik.values.comments}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.comments && formik.errors.comments && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.comments}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 text-end'>
                      {contract?.isMoveInCompleted === "Y" ? <button
                        type='submit'
                        className='btn btn-primary fw-bold mt-10 px-10 py-3 w-100'
                        disabled={loading || uploading}
                      >
                        {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            {t("Submitting...")}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button> : <><button
                        type='submit'
                        className='btn btn-primary fw-bold mt-10 px-10 py-3 w-100'
                        disabled={true}
                      >
                        {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            {t("Submitting...")}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button><><p style={{ color: 'red', textAlign:'center' }}>Please complete the move in process</p></></>}
                    </div>
                  </div>
                </form>
              ) : (
                <div className='row form-add-design'>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("ADDC Clearance Date")}</label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        value={moment(stepStatus?.data?.details?.addc_clearance_date).format('DD-MM-YYYY')}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10 upload-input'>
                      <label className='form-label fw-bolder d-block'>
                        {t("ADDC Clearance Letter")}
                      </label>
                      <div className='d-flex service-req-img align-items-center'>
                        {stepStatus?.data?.images?.addc_clearance_letter && (
                          <>
                            <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                              data-bs-toggle='modal'
                              data-bs-dismiss='modal'
                              type='button'
                              onClick={() => {
                                handleView(stepStatus?.data?.images?.addc_clearance_letter)
                              }}>
                              <img
                                src={
                                  stepStatus?.data?.images?.addc_clearance_letter
                                    .substr(
                                      stepStatus?.data?.images?.addc_clearance_letter.lastIndexOf('.')
                                    ).includes('.pdf')
                                    ? pdfImage
                                    : stepStatus?.data?.images?.addc_clearance_letter
                                }
                                alt="document"
                                className='img-fluid me-5'
                              />
                            </button><ViewPopup viewImage={viewImage} /></>
                        )}
                      </div>
                    </div>
                  </div>
                  {stepStatus?.data?.details?.comments &&
                    <div className='col-md-8 col-12'>
                      <div className=''>
                        <label className='form-label fw-bolder'>{t("Comment")}</label>
                        <textarea
                          className='form-control fw-bolder form-control-solid'
                          data-kt-autosize='true'
                          rows={3}
                          value={stepStatus?.data?.details?.comments}
                          disabled={true}
                        />
                      </div>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
        <p
          ref={closeRef}
          data-bs-target='#req-submitted-popup'
          data-bs-toggle='modal'
          data-bs-dismiss='modal'
        >
          {/*  */}
        </p>
      </div>

      {/*<div className="modal fade custom-modal" id="request-success-modal">*/}
      {/*<div className="modal-dialog modal-sm modal-dialog-centered">*/}
      {/*<div className="modal-content">*/}
      {/*<div className="modal-header bg-white border-0">*/}
      {/*<button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>*/}
      {/*</div>*/}
      {/*<div className="modal-body py-10">*/}
      {/*<div className="row g-5">*/}
      {/*<div className="col-md-12">*/}
      {/*<div className="text-center mx-md-12">*/}
      {/*<div className="redeem-offer-QR">*/}
      {/*<img src={CheckGreen} alt="tick-img" className="img-fluid mb-5" />*/}
      {/*<h1 className="text-theme font-24 mb-2 fw-bold">Done</h1>*/}
      {/*<p className="mb-3 font-12 fw-bolder">*/}
      {/*Your Move out Request has been submitted successfully*/}
      {/*</p>*/}
      {/*<div className="col-12">*/}
      {/*<button data-bs-dismiss="modal" className="btn btn-primary btn-lg fw-bolder font-12 w-100">*/}
      {/*Request Refund*/}
      {/*</button>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*</div>*/}
    </>
  )
}

export { Step4Add }
