import React, { useState, useEffect, useRef } from 'react'
import Propertie from '../../../../assets/images/latest-one.png'

export function ViewPopupMarketing(props: any) {
  
  return (
    <div className='modal fade custom-modal' aria-hidden='true' id='view-popUp-marketing'>
      <div className='modal-dialog modal-lg modal-dialog-centered'>
        <div className='modal-content rounded-10'>
          <div className='modal-header border-0 p-0'>
            <button
              type='button'
              className='btn-close shadow'
              data-bs-dismiss='modal'
              aria-label='Close'
            >&times;</button>
          </div>
          <div className='modal-body p-8'>
            <div className=''>
              <div className='row'>
                <div className="col-12 text-center">
                    <img src={props.image} className='img-fluid va-baseline' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
