import React, { useState, FC, useEffect } from 'react';
import { useFormik } from 'formik';
import completedStep from '../../../../../../assets/images/completed-step.png'
import { useTranslation } from 'react-i18next'

type Props = {
    setSafetyPrecatuions?: any
    masterData?: any
    stepStatus?: any
    ref2?: any
    ref3?: any
}

const SafetyAndSecurityPrecautions: FC<Props> = ({ setSafetyPrecatuions, masterData, stepStatus, ref2, ref3 }) => {
    const [safetyOptions, setSafetyOptions] = useState<any>()
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const { t } = useTranslation();

    useEffect(() => {
        let precautions = masterData
            .filter((item: any) => item.id === 16)
            .flatMap((data: any) => data.master_data)
        setSafetyOptions(precautions)
    }, [masterData])

    const initialValues = {
        safety_prec: [],
    }

    const handelSubmit = () => {
        setSaved(false)
        setSafetyPrecatuions(null)
        formik.handleSubmit()
    }

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        onSubmit: (values) => {
            setSafetyPrecatuions({
                precautions_ids: values.safety_prec,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref3.current.click()
        },
    })

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setSafetyPrecatuions((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    return (
        <div className="row">
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
            >
                <div className="col-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSeven">
                            <button className="accordion-button collapsed" ref={ref2} type="button" data-bs-toggle="collapse" data-bs-target="#safety-security">
                                {t("Safety and Security Precautions")}
                                {(saved) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </h2>
                        <div id="safety-security" className="accordion-collapse collapse" data-bs-parent="#accordion-step-five">
                            {stepStatus?.permitToWorkRequestStatus ? (
                                <>
                                    <div className="accordion-body pb-5 pt-0">
                                        <div className="row form-add-design">
                                            {safetyOptions ? safetyOptions?.map((option: any) => (
                                                <div key={option.value} className="col-md-4 col-12">
                                                    <div className="form-check custom mb-8">
                                                        <input
                                                            className='form-check-input'
                                                            name='safety_prec'
                                                            value={option.value}
                                                            type='checkbox'
                                                            id={option.value}
                                                            disabled={true}
                                                            checked={stepStatus?.data?.permitToWork?.details?.safety_prec?.includes(String(option?.value))}
                                                        />
                                                        <label className="form-check-label font-14 text-black fw-bolder mx-2" htmlFor={option?.value}>
                                                            {option?.name}
                                                        </label>
                                                    </div>
                                                </div>
                                            )) : ''}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="accordion-body pb-5 pt-0">
                                        <div className="row form-add-design">
                                            {safetyOptions ? safetyOptions?.map((option: any) => (
                                                <div key={option.value} className="col-md-4 col-12">
                                                    <div className="form-check custom mb-8">
                                                        <input
                                                            className='form-check-input'
                                                            name='safety_prec'
                                                            value={option.value}
                                                            type='checkbox'
                                                            id={option.value}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <label className="form-check-label font-14 text-black fw-bolder mx-2" htmlFor={option?.value}>
                                                            {option?.name}
                                                        </label>
                                                    </div>
                                                </div>
                                            )) : ''}
                                            <div className="col-12 text-right">
                                                <div className="row justify-content-end">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end">
                                                            <button type='button' onClick={handelSubmit} disabled={!dirty} className='btn btn-primary fw-bold px-10 py-3'>
                                                                {saved ? t("Saved") : t("Save")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export { SafetyAndSecurityPrecautions }
