import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { IState } from '../../../utils/interface';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

interface IProps {
    brokersList: IState["APIState"]
}

const BrokerActivityTabs: React.FC<IProps> = ({brokersList}) => {
    const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className="row">
                    <div className="col-sm-12">
                        <div className='d-flex overflow-auto h-55px mb-2'>
                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                <li className='nav-item'>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                        (location.pathname === '/brokers/in-progress' && 'active')}
                                        to={{'pathname':'/brokers/in-progress', state: {'brokersList': brokersList}}} >
                                        {t("In Progress")}
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                        (location.pathname === '/brokers/approved' && 'active')}
                                        to={{'pathname':'/brokers/approved', state: {'brokersList': brokersList}}} >
                                        {t("Approved")}
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                        (location.pathname === '/brokers/rejected' && 'active')}
                                        to={{'pathname':'/brokers/rejected', state: {'brokersList': brokersList}}} >
                                        {t("Rejected")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {BrokerActivityTabs}
