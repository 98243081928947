import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const BrokerActivityTabs: React.FC<any> = ({broker}) => {
    const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className="row">
                    <div className="col-sm-12">
                        <div className='d-flex overflow-auto h-55px mb-1'>
                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                <li className='nav-item'>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                        (location.pathname === '/SingleBroker/in-progress' && 'active')}
                                        to={{'pathname':'/SingleBroker/in-progress', state: {'broker': broker}}} >
                                        {t("In Progress")}
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                        (location.pathname === '/SingleBroker/approved' && 'active')}
                                        to={{'pathname':'/SingleBroker/approved', state: {'broker': broker}}}>
                                        {t("Approved")}
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                        (location.pathname === '/SingleBroker/rejected' && 'active')}
                                        to={{'pathname':'/SingleBroker/rejected', state: {'broker': broker}}}>
                                        {t("Rejected")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {BrokerActivityTabs}
