import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
	getNationalities,
} from '../../../../constants/axios/apis'
import IntlTelInput from 'react-intl-tel-input'
import completedStep from '../../../../assets/images/completed-step.png'
import moment from 'moment'
import DatePicker from 'react-date-picker'
import { useTranslation } from 'react-i18next'

type Props = {
	contract?: any
	stepStatus?: any
	getStepStatus?: any
	setBasicDetails?: any
	issue?: any
	docRef?: any
}

const initialDOB = new Date('1/1/1980')
initialDOB.setHours(0, 0, 0, 0)

const healthClubRegistrationSchema = Yup.object().shape({
	full_name: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.matches(/^[A-Za-z ]+$/, 'Only alphabets allowed')
		.required('Full name is required'),
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	dob: Yup.string().required('Date of birth is required').nullable(),
	nationality: Yup.string().required('Nationality is required'),
	spouse_name: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.matches(/^[A-Za-z ]+$/, 'Only alphabets allowed'),
	child_name: Yup.array().of(Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.matches(/^[A-Za-z ]+$/, 'Only alphabets allowed'))
	,
	tel_number: Yup.string().matches(/^\+?[0-9]+$/, 'Invalid format'),
	mobile: Yup.string().matches(/^\+?[0-9]+$/, 'Invalid format'),
	company_name: Yup.string()
		.max(50, 'Maximum 50 symbols')
		.matches(/^[A-Za-z ]+$/, 'Only alphabets allowed'),
})

const BasicDetails: FC<Props> = ({ stepStatus, setBasicDetails, issue, docRef }: any) => {

	const [nationalities, setNationalities] = useState<any>()
	const [isSaved, setSaved] = useState<any>(false)
	const [dirty, setIsDirty] = useState<any>(false)
	const { t } = useTranslation();

	const initialValues = {
		full_name: '',
		dob: null,
		nationality: '',
		spouse_name: '',
		child_name: [''],
		po_box: '',
		tel_number: '',
		mobile: '',
		email: '',
		company_name: '',
	}

	useEffect(() => {
		getNationalities().then(({ data }) => {
			setNationalities(data)
		})
	}, [])

	const nationalityList = nationalities?.map((nationality: any) => (
		<option key={nationality.id_nationality} value={nationality.id_nationality}>
			{nationality.country}
		</option>
	))

	const [nationalitys, setNationalitys] = useState<any>()
	useEffect(() => {
		getNationalities().then(({ data }) => {
			const selectedNationality = data?.find((el: any) => el.id_nationality == issue?.details?.nationality)
			setNationalitys(selectedNationality?.country)
		})
	}, [issue])
	const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
		formik.setFieldValue('mobile', phoneNumber)
		formik.setFieldTouched('mobile')
	}
	const handelSubmit = () => {

		setSaved(false)
		setBasicDetails(null)
		formik.handleSubmit()
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnMount: true,
		validationSchema: healthClubRegistrationSchema,
		onSubmit: (values) => {
			setBasicDetails({
				full_name: values.full_name,
				dob: moment(values.dob).format('YYYY-MM-DD'),
				nationality: values.nationality,
				spouse_name: values.spouse_name,
				child_name: values.child_name,
				po_box: values.po_box,
				tel_number: values.tel_number,
				mobile: values.mobile,
				email: values.email,
				company_name: values.company_name,
				saved: true
			})
			setSaved(true)
			setIsDirty(false)
			docRef.current.click()
		},
	})

	const handleNewField = () => {
		formik.setFieldValue("child_name", [
			...formik.values.child_name,
			''
		]);
	};

	const handleRemoveField = (placing: any) => {
		formik.setFieldValue(
			"child_name",
			formik.values.child_name.filter((name, index) => index !== placing)
		);
	};

	const handleDate = (e: any) => {
		formik.setFieldValue('dob', new Date(e))
	}

	useEffect(() => {
		setIsDirty(true)
	}, [formik.values])

	useEffect(() => {
		if (dirty) {
			setSaved(false)
			setBasicDetails((currentState: any) => { return { ...currentState, saved: false } })
		}
	}, [dirty])

	return (
		<div className="accordion-item">
			<h2 className="accordion-header" id="headingOne">
				<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
					{(isSaved || stepStatus) &&
						<span className='completed-step ps-3 pe-3 d-inline-block'>
							<img src={completedStep} alt='complete-step' />
						</span>}
					{t("Basic Details")}
				</button>
			</h2>
			<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
				<div className="accordion-body bg-white">
					<div className='col-12'>
						{stepStatus ? (
							<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
								<div className="accordion-body bg-white">
									<div className="row form-add-design">
										<div className="col-md-4 col-12">
											<div className="mb-10">
												<label className="form-label fw-bolder d-block">{t("Full Name")}</label>
												<span className='text-black fw-bold font-14'>{issue?.details?.name}</span>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-10">
												<label className="form-label fw-bolder d-block">{t("Date of Birth")}</label>
												<span className='text-black fw-bold font-14'>{moment(issue?.details?.DOB).format('DD-MM-YYYY')}</span>
											</div>
										</div>
										<div className="col-md-4 col-12">
											<div className="mb-10">
												<label className="form-label fw-bolder d-block">{t("Nationality")}</label>
												<span className='text-black fw-bold font-14'>{nationalitys}</span>
											</div>
										</div>
										{issue?.details?.spouseName &&
											<div className="col-md-4 col-12">
												<div className="mb-10">
													<label className="form-label fw-bolder d-block">{t("Spouse Name")}</label>
													<span className='text-black fw-bold font-14'>{issue?.details?.spouseName}</span>
												</div>
											</div>
										}
										{issue?.details?.childName &&
											<div className="col-md-4 col-12">
												<div className="mb-10">
													<label className="form-label fw-bolder d-block">{t("Children Names")}</label>
													<span className='text-black fw-bold font-14'>{issue?.details?.childName?.split(',')?.map((child: any) => (
														<p>{child}</p>
													))}</span>
												</div>
											</div>
										}
										{issue?.details?.po_box &&
											<div className="col-md-4 col-12">
												<div className="mb-10">
													<label className="form-label fw-bolder d-block">{t("PO Box")}</label>
													<span className='text-black fw-bold font-14'>{issue?.details?.po_box}</span>
												</div>
											</div>
										}
										{issue?.details?.telNumber &&
											<div className="col-md-4 col-12">
												<div className="mb-10">
													<label className="form-label fw-bolder d-block">{t("Tel. Number")}</label>
													<span className='text-black fw-bold font-14'>{issue?.details?.telNumber}</span>
												</div>
											</div>
										}
										{issue?.details?.mobile &&
											<div className="col-md-4 col-12">
												<div className="mb-10">
													<label className="form-label fw-bolder d-block">{t("Mobile Number")}</label>
													<span className='text-black fw-bold font-14'>{issue?.details?.mobile}</span>
												</div>
											</div>
										}
										<div className="col-md-4 col-12">
											<div className="mb-10">
												<label className="form-label fw-bolder d-block">{t("Email Address")}</label>
												<span className='text-black fw-bold font-14'>{issue?.details?.email}</span>
											</div>
										</div>
										{issue?.details?.companyName &&
											<div className="col-md-4 col-12">
												<div className="mb-10">
													<label className="form-label fw-bolder d-block">{t("Company Name")}</label>
													<span className='text-black fw-bold font-14'>{issue?.details?.companyName}</span>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						) : (
							<div className='row form-add-design'>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7'>
										<label className='form-label fw-bolder'>
											{t("Full Name")}<span className='text-danger'>*</span>
										</label>
										<input
											type='text'
											{...formik.getFieldProps('full_name')}
											className='form-control fw-bolder form-control-solid'
											autoComplete='off'
										/>
										{formik.touched.full_name && formik.errors.full_name && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.full_name}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7 date-picker'>
										<label className='form-label fw-bolder'>
											{t("Date of Birth")}<span className='text-danger'>*</span>
										</label>
										<DatePicker
											onChange={(e: any) => handleDate(e)}
											value={formik.values.dob}
											maxDate={new Date(new Date().toLocaleDateString())}
											className={'form-control fw-bolder form-control-solid'}
											format='dd/MM/y'
											clearIcon={null}
										/>
										{formik.touched.dob && formik.errors.dob && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.dob}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7'>
										<label className='form-label fw-bolder'>
											{t("Nationality")}<span className='text-danger'>*</span>
										</label>
										<select
											className='form-control form-select fw-bolder form-control-solid'
											name='nationality'
											onChange={formik.handleChange}
											value={formik.values.nationality}
											onBlur={formik.handleBlur}
										>
											<option value='' disabled>
												Please Select
											</option>
											{nationalities ? nationalityList : ''}
										</select>
										{formik.touched.nationality && formik.errors.nationality && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.nationality}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7'>
										<label className='form-label fw-bolder'>{t("Spouse Name")}</label>
										<input
											type='text'
											{...formik.getFieldProps('spouse_name')}
											className='form-control fw-bolder form-control-solid'
											autoComplete='off'
										/>
										{formik.touched.spouse_name && formik.errors.spouse_name && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.spouse_name}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7'>
										<label className='form-label fw-bolder'>{t("Children Name")}</label>
										<button type="button" style={{ border: 0, background: 'transparent' }} onClick={handleNewField}>
											<i className="bi bi-plus-circle"></i>
										</button>
										{formik.values?.child_name.map((name, index) => (
											<div key={index}>
												<input
													type='text'
													{...formik.getFieldProps(`child_name[${index}]`)}
													className='form-control fw-bolder form-control-solid'
													autoComplete='off'
												/>
												{formik.values.child_name.length > 1 ?
													<button type="button" className='photo-view' onClick={() => handleRemoveField(index)}>
														<i className="bi bi-trash"></i>
													</button>
													:
													''
												}
											</div>
										))}
										{formik.touched.child_name && formik.errors.child_name && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.child_name}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7'>
										<label className='form-label fw-bolder'>{t("PO Box")}</label>
										<input
											type='text'
											{...formik.getFieldProps('po_box')}
											className='form-control fw-bolder form-control-solid'
											autoComplete='off'
										/>
										{formik.touched.po_box && formik.errors.po_box && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.po_box}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7'>
										<label className='form-label fw-bolder'>{t("Tel. Number")}</label>
										<input
											type='text'
											{...formik.getFieldProps('tel_number')}
											className='form-control fw-bolder form-control-solid'
											autoComplete='off'
										/>
										{formik.touched.tel_number && formik.errors.tel_number && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.tel_number}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7'>
										<label className='form-label fw-bolder'>{t("Mobile Number")}</label>
										<IntlTelInput
											preferredCountries={['ae', 'in', 'us']}
											containerClassName='intl-tel-input'
											separateDialCode={true}
											fieldName='mobile'
											value={formik.values.mobile}
											placeholder='Mobile Number'
											inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
											onPhoneNumberChange={handlePhoneNumber}
										/>
										{formik.touched.mobile && formik.errors.mobile && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.mobile}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7'>
										<label className='form-label fw-bolder'>
											{t("Email Address")}<span className='text-danger'>*</span>
										</label>
										<input
											type='text'
											{...formik.getFieldProps('email')}
											className='form-control fw-bolder form-control-solid'
											autoComplete='off'
										/>
										{formik.touched.email && formik.errors.email && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.email}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
									<div className='mb-7'>
										<label className='form-label fw-bolder'>{t("Company Name")}</label>
										<input
											type='text'
											{...formik.getFieldProps('company_name')}
											className='form-control fw-bolder form-control-solid'
											autoComplete='off'
										/>
										{formik.touched.company_name && formik.errors.company_name && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>
													<span role='alert' className='text-danger'>
														{formik.errors.company_name}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='col-md-12 text-end'>
									<button type='button' onClick={handelSubmit} disabled={isSaved} className='btn btn-primary fw-bold px-10 py-3 w-25'>
										<span className='indicator-label'>{isSaved ? t("Saved") : t("Save")}</span>
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
export { BasicDetails }