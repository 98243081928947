import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useLocation} from 'react-router-dom'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'

const PaymentMethodTabs: FC = () => {
  const location = useLocation()
  const {t} = useTranslation()

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/unit-portfolio/payments'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            {t('Pay Now')}
          </h4>
        </div>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-0'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/unit-portfolio/card-payment' && 'active')
                          }
                          to='/unit-portfolio/card-payment'
                        >
                          {t('Card Payment')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/unit-portfolio/bank-transfer' && 'active')
                          }
                          to='/unit-portfolio/bank-transfer'
                        >
                          {t('Bank Transfer')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/unit-portfolio/pay-by-cheque' && 'active')
                          }
                          to='/unit-portfolio/pay-by-cheque'
                        >
                          {t('Pay by Cheque')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {PaymentMethodTabs}
