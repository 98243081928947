import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials/layout/header-menus/HeaderUserMenu'
import { useLayout } from '../../core'
import { Link } from 'react-router-dom'
import avatarImg from '../../../../assets/images/avatar.jpg'
// import UaeFlagIcon from '../../../../assets/images/uae-flag.png'
// import UsaFlagIcon from '../../../../assets/images/en-flag.png'
import { RootState } from '../../../../constants'
import * as profile from '../../../../constants/redux/Sagas/ProfileSaga'
import { getUserNotifications } from '../../../../constants/axios/apis'
import * as General from '../../../../constants/redux/Sagas/GeneralSaga'
import { MenuComponent } from '../../../../setup/ts/components'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const { config } = useLayout()
  const { t } = useTranslation();

  let userName = 'Mohammad'
  let userImage = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    const lastName = JSON.parse(userDetail).last_name != null ? JSON.parse(userDetail).last_name : ''
    userName = JSON.parse(userDetail).first_name + ' ' + lastName
    userImage = JSON.parse(userDetail).profile_img
    userRole = JSON.parse(userDetail).id_role
  }

  let userDetails: any = {};
  userDetails = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  const lastNames = userDetails?.data?.last_name != null ? userDetails?.data?.last_name : '';
  let userNames = userDetails?.data?.first_name + ' ' + lastNames
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(profile.actions.profile())
  }, [])
  let profileData: any = []
  profileData = useSelector<RootState>(({ Profile }) => Profile.profile)

  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ];

  const currentLanguageCode: any = profileData?.data?.preferred_language === 1 ? 'en' : 'ar';
  const currentLanguage: any = languages.find((l) => l.code === currentLanguageCode);

  useEffect(()=>{
    getUserNotifications().then((response: any) => {
      if (response.data && Array.isArray(response.data)) {
        dispatch(General.actions.notif(response.unreadCount))
      }
    })
  },[])

  useEffect(() => {
    MenuComponent.reinitialization() // import from _metronic/assets/ts/components
  }, [])

  let notifCount: any
    notifCount = useSelector<RootState>(({ General }) => General.notif)
    notifCount = Number(notifCount)

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* CHAT */}
      {/* {userRole != 4 && userRole != 5 ? (
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <Link
            to={'/chat/all'}
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
          >
            <KTSVG path='' className='email-ioc' />
            <span className='bullet bullet-dot bg-warning h-6px w-6px position-absolute translate-middle top-5 start-70 animation-blink'></span>
          </Link>
        </div>
      ) : null} */}
      {/* Activities */}
      {/* <div className="d-flex align-items-stretch lang-sec me-1 me-lg-3">
        <div className="row">
          <div className="col-lg-12 my-auto">
            <div className="d-flex align-items-center h-100">
              <div className="switch me-2">
                <label className="switch-light">
                  <input type="checkbox" />
                  <span className="inner">
                    <span className="font-14">
                      <img width="18" height="16" src={UaeFlagIcon} alt="UAE Flag Icon" className='img-fluid me-2' />
                      <span className="color-hide">عربی</span>
                    </span>
                    <span className="font-14">
                      <img width="18" height="16" src={UsaFlagIcon} alt="USA Flag Icon" className='img-fluid me-2' />
                      <span className="color-hide">English</span>
                    </span>
                  </span>
                  <a className="btn btn-toggle-white"></a>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Drawer toggle */}
        <div
          className='position-relative'
        // data-bs-toggle='modal'
        // data-bs-target='#exampleModal'
        >

          {/* <a href='/notifications'></a>  */}
          <Link to='/notifications' className='menu-link px-3'>
            <KTSVG path='' className='item-icon doc-ioc' />
            <KTSVG path='' className='notif-ioc' />
            {notifCount !== undefined && notifCount !== '' && notifCount > 0 ?
              <span className='notify-icon'>
                <span className={'badge badge-light p-1'}>{notifCount > 999 ? '999+' : notifCount}</span>
              </span> : 
              <>
              <span className='notify-icon'>
                <span className={'badge badge-light p-1'}>0</span>
              </span>
              </>
            }
            {/* <span className='h-6px w-6px position-absolute translate-middle top-5 start-70 text-danger ps-1 font-12'>{notifCount}</span> */}
            {/* <span className='bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-5 start-70 animation-blink'></span> */}
          </Link>
        </div>
        {/* end::Drawer toggle */}
      </div>
      {/* Quick links */}

      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />
      </div>*/}

      {/* NOTIFICATIONS */}
      {/*   <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <HeaderNotificationsMenu />
      </div>*/}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center symbol ms-lg-7', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer position-relative d-contents', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <span className='text-blue-theme font-14 fw-bold user-name'><span className="fw-normal">Welcome,</span> {userNames} </span>
          <div className="topbar-poligon">
            <img src={userDetails?.data?.profile_img ? userDetails?.data?.profile_img : avatarImg} alt={userDetails?.data?.first_name} className='rounded-circle' />
          </div>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }
