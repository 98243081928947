import React, { FC, useEffect, useRef, useState } from 'react'
import {
	getServiceRequestByData,
	healthClubRegistrationRequest
} from '../../../../constants/axios/apis'
import { BasicDetails } from './BasicDetails'
import { OtherDetails } from './OtherDetails'
import { Documents } from './Documents'
import { useTranslation } from 'react-i18next'
import { NoDataFoundWithMsg } from '../../../../constants/generics/customActios'

type Props = {
	contract?: any
	stepStatus?: any
	getStepStatus?: any
	setMessage?: any
}

const today = new Date()
today.setHours(0, 0, 0, 0)

const Step4: FC<Props> = ({ contract, stepStatus, getStepStatus, setMessage }: any) => {
	const category_id = 8
	const [basicDetails, setBasicDetails] = useState<any>()
	const [documents, setDocuments] = useState<any>()
	const [property_id, setPropertyID] = useState<any>(contract?.propertyID)
	const [step4Data, setStep4Data] = useState<any>()
	const [isSubmitting, setSubmitting] = useState(false)
	const [submitError, setSubmitError] = useState('')
	const docRef = useRef<any>()
	const closeRef = useRef<any>()
	const [isSubmitable, setIsSubmitable] = useState<any>(false)
	const { t } = useTranslation();

	useEffect(() => {
		getServiceRequestByData(category_id, '', contract?.propertyID).then((response) => {
			if (response.data) {
				setStep4Data(response.data[0])
			}
		})
	}, [contract, stepStatus])

	const handelFinalSubmit = () => {
		if (basicDetails?.saved && documents?.saved) {
			setSubmitting(true)
			setSubmitError('')
			setTimeout(() => {
				healthClubRegistrationRequest(
					category_id,
					property_id,
					basicDetails.full_name,
					basicDetails.dob,
					basicDetails.nationality,
					basicDetails.spouse_name,
					basicDetails.child_name,
					basicDetails.po_box,
					basicDetails.tel_number,
					basicDetails.mobile,
					basicDetails.email,
					basicDetails.company_name,
					documents.emirates_id_front,
					documents.emirates_id_back,
					documents.passport_front,
					documents.passport_back,
					documents.photo,
					documents.spouse_photo,
					documents.child_photo,
				)
					.then((response: any) => {
						setSubmitting(false)
						if (response.errorCode === 1) {
							setSubmitError(response.errorDescription)
							setTimeout(() => {
								setSubmitError('')
							}, 5000)
						} else if (response.errorCode === 0) {
							setMessage(t("Your Health Club Registration Request has been Submitted!"))
							setSubmitting(false)
							getStepStatus()
							closeRef.current.click()
						} else {
							setSubmitError(t("Some error occured!"))
							console.log(response, 'response');
							setTimeout(() => {
								setSubmitError('')
							}, 5000)
						}
					})
					.catch((e) => {
						console.log(e, 'Error');
						setSubmitting(false)
						setSubmitError(t("Some error occured!"))
						setTimeout(() => {
							setSubmitError('')
						}, 5000)
					})
			}, 1000)

		} else {
			setSubmitError(t("Please fill all required fields"))
		}
	}

	useEffect(() => {
		if (basicDetails?.saved && documents?.saved) {
			setIsSubmitable(true)
		} else {
			setIsSubmitable(false)
		}
	}, [basicDetails, documents])


	if (contract?.isHealthClubAvailable === "N") { return <NoDataFoundWithMsg msg={'No Data'} /> }
	return (
		<>
			<div className='com-card-section w-100 mb-7 step3-page'>
				<div className='row form-add-design'>
					<div className='col-12'>
						<div className='card'>
							<div className='card-body p-0'>
								<div className='row'>
									<div className='col-12'>
										<div className="accordion move-out-accordion" id="accordionExample">
											<BasicDetails setBasicDetails={setBasicDetails} stepStatus={stepStatus} issue={step4Data} docRef={docRef} />
											{/* {stepStatus ? (<OtherDetails stepStatus={stepStatus}  issue={step4Data}/> ):''} */}
											<Documents setDocuments={setDocuments} stepStatus={stepStatus} issue={step4Data} docRef={docRef} />
										</div>
									</div>
									<div className='row'>
										{!stepStatus ? (
											<div className='col-md-12 text-end'>
												{isSubmitting ?
													<button
														type='button' className='btn btn-primary fw-bold px-15 py-3' disabled>
														<span className='indicator-progress' style={{ display: 'block' }}>
															{t("Please wait...")}{' '}
															<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
														</span>
													</button>
													:
													<button type='button' onClick={handelFinalSubmit} disabled={!isSubmitable} className='btn btn-primary fw-bold px-10 py-3'>
														<span className='indicator-label'>{t("Submit")}</span>
													</button>
												}
											</div>
										) : (
											''
										)}
										{submitError !== '' && (
											<div className="col-12 text-center">
												<div className="alert alert-danger w-100 mt-2" role="alert">
													{submitError}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p
				ref={closeRef}
				data-bs-target='#req-submitted-popup'
				data-bs-toggle='modal'
				data-bs-dismiss='modal'
			>
				{/*  */}
			</p>
		</>
	)
}

export { Step4 }
