import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const PaymentTabs: React.FC = () => {
    const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row access-cd-tabs">
                                <div className="col-md-12 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/payments/all' && 'active')}
                                                    to='/payments/all'>
                                                    {t("All")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/payments/rents' && 'active')}
                                                    to='/payments/rents'>
                                                    {t("Rents")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/payments/security-deposits' && 'active')}
                                                    to='/payments/security-deposits'>
                                                    {t("Security Deposits")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/payments/service-charges' && 'active')}
                                                    to='/payments/service-charges'>
                                                    {t("Service Charges")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/payments/other-charges' && 'active')}
                                                    to='/payments/other-charges'>
                                                    {t("Other Charges")}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {PaymentTabs}
