import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { UpcomingCard } from '../MyVisit/UpcomingProperties/UpcomingCard'
import { Details } from '../../../pm-offers-and-renewals/components/Details/Details'
import { MakeanOfferNotification } from '../../../../components/partials/layout/make-an-offer-notification/MakeanOfferNotification'
import { ExtendOfferPopup } from './ExtendOfferPopup/ExtendOfferPopup'
import { RequestSuccessfulPopup } from '../../../SuccessfulPopup/RequestSuccessfulPopup'

export function MyOffer(props: any) {
	const location = useLocation()
	// console.log("🚀 ~ file: MyOffer.tsx ~ line 11 ~ MyOffer ~ location", location.pathname)
	const fid: any = location.pathname.split("/").pop()
	// console.log("🚀 ~ file: MyOffer.tsx ~ line 13 ~ MyOffer ~ fid", fid)
	if (isNaN(fid)) {
		// console.log(fid + " is not a number " + isNaN(fid))
	} else {
		// console.log(fid + " is  a number " + isNaN(fid))
	}
	const { offer } = props
	const [openDetails, setOpenDetails] = useState(false)
	const [offerNo, setOfferNo] = useState('')
	const offerId = async (id: any) => {
		setOfferNo(id)
		setOpenDetails(true)
	}
	const offerIdShowHide = async (id: any) => {
		setOpenDetails(false)
	}
	if (!isNaN(fid)) {
		return (
			<div className='com-card-section'>
				<div className='row'>
					<div className='col-12'>
						<div className='card movein-moveout'>
							<div className='card-body px-8 py-6'>
								<div className='row'>
									<div className='col-md-12 col-12'>
										<Details offerIdNo={fid} offerIdShowHide={true} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>)
	}
	return (
		<div className='com-card-section'>
			<div className='row'>
				<div className='col-12'>
					<div className='card movein-moveout'>
						<div className='card-body px-8 py-6'>
							<div className='row'>
								<div className='col-md-12 col-12'>
									{!openDetails && (
										<div
										//   onClick={() => setOpenDetails(true)} style={{ cursor: 'pointer' }}
										>
											<UpcomingCard data={offer} offerId={offerId} />
										</div>
									)}
									{openDetails && <Details {...setOpenDetails} offerIdNo={offerNo} offerIdShowHide={offerIdShowHide} />}

									{/* <Details offerIdNo={251} offerIdShowHide={true} /> */}
									{/* <MakeanOfferNotification />
									<ExtendOfferPopup />
									<RequestSuccessfulPopup /> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
