import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import pic from '../../../../assets/images/Image_4.png'
import {HelpPopup} from './HelpPopup'
import {Activity} from './Activity'
import { useTranslation } from 'react-i18next'

export function ServiceRequests() {
    const { t } = useTranslation();

    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                        <Link to={'/my-services/openissues'} className=''>
                            <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                        </Link>
                        {t("Service Requests")}
                    </h4>
                </div>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className='card-body px-8 py-6'>
                            <div className='row align-items-center mb-6'>
                                <div className="col-sm-8 col-12">
                                    <h4 className='card-title font-18 text-black my-0'>
                                        {t("Request Details")} #RealCube-1236542
                                    </h4>
                                </div>
                                <div className="col-sm-4 col-12 text-end">
                                    <HelpPopup />
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-8'>
                                    <h6 className='text-theme-gray mb-3 fw-normal'> {t("Customer Name")} </h6>
                                    <span className='text-black fw-bold'> Muhhamed </span>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-8'>
                                    <h6 className='text-theme-gray mb-3 fw-normal'> {t("Renewal Period")} </h6>
                                    <span className='text-black fw-bold'> 30 {t("Days")} </span>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-8'>
                                    <h6 className='text-theme-gray mb-3 fw-normal'> {t("Renewal End Date")} </h6>
                                    <span className='text-black fw-bold'> 07.06.22 </span>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-8'>
                                    <h6 className='text-theme-gray mb-3 fw-normal'> {t("Annual Rent")} </h6>
                                    <span className='text-black fw-bold'> 125000 {t("AED")} </span>
                                </div>
                                <div className='col-md-12 col-12 mb-8'>
                                    <h6 className='text-theme-gray mb-3 fw-normal'> {t("Supporting Documents")} </h6>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6 col-xl-4 mb-md-0 mb-4">
                                            <div className="d-flex service-req-img align-items-center">
                                                <img src={pic} className='img-fluid me-5' />
                                                <span className='text-black fw-bold'> IMG012101.JPG </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4 mb-md-0 mb-4">
                                            <div className="d-flex service-req-img align-items-center">
                                                <img src={pic} className='img-fluid me-5' />
                                                <span className='text-black fw-bold'> IMG012101.JPG </span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-4 mb-md-0 mb-4">
                                            <div className="d-flex service-req-img align-items-center">
                                                <img src={pic} className='img-fluid me-5' />
                                                <span className='text-black fw-bold'> IMG012101.JPG </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-6">
                                <div className="col-12">
                                    <h4 className='card-title font-18 text-black mb-6'>
                                        {t("Activity")}
                                    </h4>
                                </div>
                                <div className="col-12">
                                    <Activity />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
