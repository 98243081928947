import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { postApprovalSubmit } from '../../../../../constants/axios/apis'

export function ApprovalReply({ approval_ID, setReload }: any) {
    const { t } = useTranslation()
    const approvalID = approval_ID && approval_ID !== '' ? approval_ID : ''
    
    const [submitStatus, setSubmitStatus] = useState<any>({
        loading: false,
        error: false,
        message: '',
    })

    const initialValues = {
        comment: '',
    }
    const validationSchema = Yup.object().shape({
        comment: Yup.string()
            .required(t('Comment is required!'))
            .matches(/^[^\s].*/, t('Comment cannot start with a blankspace!'))
            .max(250, t('Max length is 250!'))
            .test('len', t('Comment is required!'), (value) => {
                return value ? (value?.trim()).length > 0 : false
            }),
    })

    useEffect(() => {
        if (approvalID === '')
            setSubmitStatus((oStatus: any) => ({
                ...oStatus,
                loading: false,
                message: t('Incomplete approval data!'),
                error: true,
            }))
    }, [])

    const onSubmit = (values: any) => {
        if (approvalID !== '') {
            setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: true }))

            var formData = new FormData()
            formData.append('approval_id', approvalID)
            formData.append('comment', values.comment)

            setTimeout(() => {
                postApprovalSubmit(formData, 'reply')
                    .then((response: any) => {
                        if (response.errorCode === 1) {
                            setSubmitStatus((oStatus: any) => ({
                                ...oStatus,
                                loading: false,
                                message: response.errorDescription,
                                error: true,
                            }))
                        } else if (response.errorCode === 0) {
                            formik.resetForm()
                            setSubmitStatus((oStatus: any) => ({
                                ...oStatus,
                                loading: false,
                                message: response.description,
                            }))
                            setReload(true)
                        } else {
                            setSubmitStatus((oStatus: any) => ({
                                ...oStatus,
                                loading: false,
                                message: t('Some error occured!'),
                                error: true,
                            }))
                        }
                        setSubmitStatus((oStatus: any) => ({ ...oStatus, loading: false }))
                    })
                    .catch((e:any) => {
                        // console.log(e, 'Error');
                        setSubmitStatus((oStatus: any) => ({
                            ...oStatus,
                            loading: false,
                            message: t('Some error occured!'),
                            error: true,
                        }))
                    })
            }, 1000)
        }
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    })

    const handleSubmit = () => {
        formik.handleSubmit()
    }

    return (
        <>
            <div className='row align-items-end'>
                <div className='col-lg-12 col-sm-12 mb-5'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='font-14 fw-bold mb-3'>{t("Comment")}</label>
                            <textarea
                                className='form-control'
                                data-kt-autosize='true'
                                rows={3}
                                placeholder={t('Type here...')}
                                {...formik.getFieldProps('comment')}
                            />
                            {formik.touched.comment && formik.errors.comment ? (
                                <small className='text-danger'>{formik.errors.comment}</small>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className='col-12 text-end'>
                    {submitStatus.loading ? (
                        <button
                            type='button'
                            className='btn btn-primary fw-bold px-10 py-3'
                            disabled
                        >
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {t('Please wait...')}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        </button>
                    ) : (
                        <button
                            type='button'
                            className='btn btn-primary fw-bold px-12 py-3'
                            onClick={handleSubmit}
                            disabled={approvalID === ''}
                        >
                            {t('Submit')}
                        </button>
                    )}
                </div>

                {!submitStatus.loading && submitStatus.error && (
                    <div className='col-12 text-center'>
                        <div className='alert alert-danger w-100 mt-5 mb-0' role='alert'>
                            {submitStatus.message}
                        </div>
                    </div>
                )}
            </div>


        </>
    )
}
