import React, {FC, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import StarActive from '../../../../assets/images/Star_Active.png'
import StarInactive from '../../../../assets/images/Star_Inactive.png'
import ViewRightArrow from '../../../../assets/images/Right_arrow_white.png'
import {getUserNotifications} from '../../../../constants/axios/apis'
import moment from 'moment'

export function ActivityDrawer() {
  const history = useHistory()

  const [notifications, setNotifications] = useState<any>([])

  useEffect(() => {
    getUserNotifications().then((response: any) => {
      if (response.data) {
        setNotifications(response.data)
      }
    })
  }, [])

  const notificationList = notifications?.map((notification: any) => (
    <div key={notification.id} className='card mb-3'>
      <div className='card-body p-5'>
        <div className='row'>
          <div className='col-md-1 pe-md-0'>
            <img src={StarInactive} className='img-fluid' />
          </div>
          <div className='col-md-11'>
            <div className='row gx-10'>
              <div className='col-md-9'>
                <h4 className='notif-title font-17 fw-bold mb-3'>{notification.title}</h4>
              </div>
              <div className='col-md-3 text-center'>
                <span className='notif-time font-14 fw-bold'>
                  {moment(notification.created_at).fromNow()}
                </span>
              </div>
              <div className='col-12'>
                <h6 className='notif-desc fw-normal font-14'>{notification.description}</h6>
              </div>
               <div className='col-12'>
                <div className='btn-group mt-5 w-100 notif-btn-group'>
                  <div className='row w-100 gx-3'>
                    <div className='col-md-2'> </div>
                    <div className='col-md-4'>
                      <button className='btn btn-outline-primary w-100'>View Offer</button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        className='btn btn-primary w-100'
                        data-bs-target='#make-an-offer-notification'
                        data-bs-toggle='modal'
                        data-bs-dismiss='modal'
                      >
                        Proceed To Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))

  return (
    <div className='modal fade notif-popup' id='exampleModal'>
      <div className='modal-dialog modal-dialog-scrollable'>
        <div className='modal-content rounded-10'>
          <div className='modal-header border-0'>
            <h5 className='modal-title fw-bold'>Inbox</h5>
            <button type='button' className='btn-close text-black' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-12'>
                {notifications ? notificationList : ''}
                 {/*Your contract Extension Request Accept*/}
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>Your offer has been Approved</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Your offer has been Approved by the Owner/Agent
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                      onClick={() => history.push('/')}
                                      data-bs-dismiss='modal'
                                      className='btn btn-outline-primary w-100'
                                  >
                                    View Offer
                                  </button>
                                </div>
                                <div className='col-md-5'>
                                  <Link to={''} className='btn btn-primary w-100'
                                    data-bs-target='#pm-approved-notification'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                  >
                                    Proceed To Payment
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>Your Contract Extension Request Accept</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Pay due Amount click the pay now button.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                      onClick={() => history.push('/contract-extension-request-accept')}
                                      data-bs-dismiss='modal'
                                      className='btn btn-outline-primary w-100'
                                  >
                                    View Request
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <Link to={''} className='btn btn-primary w-100' data-bs-target='#payment-modal-new'
                                        data-bs-toggle='modal'
                                        data-bs-dismiss='modal'>
                                    Pay Now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Your offer has been Approved
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Your offer has been Approved by the Owner/Agent
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 gx-3'>
                                <div className='col-md-3'> </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-primary w-100'>
                                    View Offer
                                  </button>
                                </div>
                                <div className='col-md-5'>
                                  <button
                                    className='btn btn-primary w-100'
                                    data-bs-target='#make-an-offer-notification'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                  >
                                    Proceed To Payment
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Upcoming Service Request
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Upcoming Service Request
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary disabled w-100'>
                                    Arrived
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-danger disabled w-100'>
                                    Not Arrived
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-primary disabled w-100'>
                                    Arrived Late
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarInactive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              You AC Repair is done on 12-Jun-2021 at 11:29 AM
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>12:35am</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Lorem Ipsum és un text de farciment usat per la indústria de la
                              tipografia i la impremta.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary disabled w-100'>
                                    Resolved
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-secondary disabled w-100'>
                                    Not Resolved
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarInactive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Upcoming Service Request
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>1:35pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Lorem Ipsum és un text de farciment usat per la indústria de la
                              tipografia i la impremta. Lorem Ipsum ha estat el text estàndard de la
                              tres tipogràfiques.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary w-100'>Reschedule</button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-secondary w-100'>
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarInactive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              You AC Repair is done on 12-Jun-2021 at 11:29 AM
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>12:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Lorem Ipsum és un text de farciment usat per la indústria de la
                              tipografia i la impremta. Lorem Ipsum ha estat el text estàndard de la
                              tres tipogràfiques.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                    data-bs-target='#feedback-popup'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                    className='btn btn-outline-secondary w-100'
                                  >
                                    Rate Us
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarInactive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              The Contract is Generated
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>07:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Lorem Ipsum és un text de farciment usat per la indústria de la
                              tipografia i la impremta. Lorem Ipsum ha estat el text estàndard de la
                              tres tipogràfiques.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-outline-secondary w-100'>
                                    Download
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>Contract Expiry</h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Your contract has been expiring soon please renew it.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                    onClick={() => history.push('/my-services/extend-contract')}
                                    data-bs-dismiss='modal'
                                    className='btn btn-primary w-100'
                                  >
                                    Extend Contract
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <Link to={''} className='btn btn-outline-primary w-100'>
                                    Move-out
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Your Move-in has been Approved
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>4:15pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Move-in Request has been Approved
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary w-100'>View Report</button>
                                </div>
                                <div className='col-md-4'>
                                  <button className='btn btn-primary w-100'>Download Report</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body p-5'>
                    <div className='row'>
                      <div className='col-md-1 pe-md-0'>
                        <img src={StarActive} className='img-fluid' />
                      </div>
                      <div className='col-md-11'>
                        <div className='row gx-10'>
                          <div className='col-md-9'>
                            <h4 className='notif-title font-17 fw-bold mb-3'>
                              Your contract is expiring soon
                            </h4>
                          </div>
                          <div className='col-md-3 text-center'>
                            <span className='notif-time font-14 fw-bold'>8:25pm</span>
                          </div>
                          <div className='col-12'>
                            <h6 className='notif-desc fw-normal font-14'>
                              Your contract has been expiring soon please renew it.
                            </h6>
                          </div>
                          <div className='col-12'>
                            <div className='btn-group mt-5 w-100 notif-btn-group'>
                              <div className='row w-100 justify-content-end gx-3'>
                                <div className='col-md-4'>
                                  <button
                                    onClick={() => history.push('/my-services/contract-renewal')}
                                    data-bs-dismiss='modal'
                                    className='btn btn-primary w-100'
                                  >
                                    Renew Contract
                                  </button>
                                </div>
                                <div className='col-md-4'>
                                  <button
                                    onClick={() => history.push('/my-services/early-termination')}
                                    data-bs-dismiss='modal'
                                    className='btn btn-outline-danger w-100'
                                  >
                                    Terminate Contract
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer justify-content-center border-0' data-bs-dismiss='modal'>
            <Link className='fw-bold text-white font-19' to={'/notifications'}>
              <span>View all</span>
              <img src={ViewRightArrow} className='img-fluid ms-4 h-21px w-21px' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
