import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../constants';
import { CustomLoaderWithMsg } from '../../constants/generics/customActios';
import { Link, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import * as General from '../../constants/redux/Sagas/GeneralSaga'
import * as dashboard from '../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import * as dashboard1 from '../../constants/redux/Sagas/Tenant/TenantDashboardSaga'
import { getUserRole } from '../../constants/generics/userCheck';
const NotificationsPage: FC = () => (
    <>
        <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
            <div className='col-xxl-12 mt-0'>
            </div>
        </div>
    </>
);

const SSORedirectWrapper: FC = () => {
    const history = useHistory();
    const location = useLocation<any>()
    var notificationType = location?.pathname.split("/");
    var notificationType1 = location?.pathname.split("*");
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const contractList: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.allContract)

    const state: any = useSelector((state) => state)
    const user_data = state?.auth?.user?.data
    const user_role = getUserRole(user_data)

    const { otherData } = location.state || undefined

    useEffect(() => {
        setTimeout(() => {
            if (user_role !== 'BA') {
                if (notificationType[2] == 'visit_scheduled' || notificationType[2] == 'upcoming_visit' || notificationType[2] == 'reschedule_visit' || notificationType[2] == 'schedule_a_visit') {
                    // history.push('/pt-activity/my-visit/upcoming')
                    history.push({
                        pathname: '/pt-activity/my-visit/upcoming',
                        state: { id: notificationType[3] }
                    })
                }
                else if (notificationType[2] == 'cancel_visit' || notificationType[2] == 'visit_completed') {
                    history.push({
                        pathname: '/pt-activity/my-visit/visited',
                        state: { id: notificationType[3] }
                    })
                }
                else if (notificationType[2] == 'offer' || notificationType[2] == 'make_offer') {
                    dispatch(dashboard.actions.offerDetails(notificationType[3]))
                    sleep(2000)
                    history.push('/pt-activity/my-offer/' + notificationType[3])
                }
                else if (notificationType[2] == 'announcements') {
                    sleep(2000)
                    history.push({
                        pathname: '/announcements-details',
                        state: { announcementID: notificationType[3] }
                    })
                }
                else if (notificationType[2] == 'contract') {
                    dispatch(General.actions.contractDetails(contractList?.data?.find((contract: any) => contract.id == notificationType[3])))
                    // dispatch(dashboard1.actions.selectedContract(notificationType[3]))
                    sleep(2000)
                    history.push('/pt-activity/my-contracts-details/' + notificationType[3])

                }
                else if (notificationType[2] == 'cancel_visit') {
                    sleep(2000)
                    history.push({
                        pathname: '/pt-activity/my-visit/visited',
                        state: { id: notificationType[3] }
                    })

                } else if (notificationType[2] == 'service_access') {
                    sleep(2000)
                    const requiredData = {
                        id: notificationType[3],
                        category_id: location?.state?.otherData?.other_data?.category_id
                    }
                    history.push({
                        pathname: `/${notificationType1[1]}`,
                        state: requiredData
                    })
                } else if (notificationType[2] == 'payments') {
                    sleep(2000)
                    history.push({
                        pathname: `/${notificationType1[1]}`,
                    })
                }
                else if (notificationType[2] == 'edit_profile') {
                    history.push('/' + notificationType1[1])
                }
                else if (notificationType[2] == 'create_property_alert') {
                    history.push('/' + notificationType1[1])
                }
                else if (notificationType[2] == 'maintenance') {
                    sleep(2000)
                    history.push({
                        pathname: `/${notificationType1[1]}/${notificationType[3]}`
                    })
                }
                //CT-Market/Promotions
                else if (['event_approve', 'offer_approve', 'voucher_approve',
                            'event_reject', 'reject_offer', 'voucher_reject',            
                            'promotion_rmi_fromMA'].includes(notificationType[2])) {                    
                    history.push({
                        pathname: '/' + notificationType1[1],
                        state: { marketData: {id: otherData?.other_data?.promotion_id}, isNotifRedirect: true }
                    })
                }
                else if (['create_voucher', 'create_offer', 'create_events'].includes(notificationType[2])) {                    
                    history.push({
                        pathname: '/' + notificationType1[1],
                        state: { marketData: {id: notificationType[3]}, isNotifRedirect: true }
                    })
                }
            }
            // Broker admin start
            else {
                if (notificationType[2] == 'schedule_a_visit' || notificationType[2] == 'reschedule_visit' || notificationType[2] == 'upcoming_visit') {
                    history.push({
                        pathname: '/visits/upcoming',
                        state: { id: notificationType[3] }
                    })
                }
                else if (notificationType[2] == 'cancel_visit' || notificationType[2] == 'visit_completed') {
                    history.push({
                        pathname: '/visits/closed',
                        state: { id: notificationType[3] }
                    })
                }
                else if (notificationType[2] == 'make_offer' || notificationType[2] == 'extend_offer' || notificationType[2] == 'rent_payment_done' || notificationType[2] == 'sd_payment_done' || notificationType[2] == 'create_contract' || notificationType[2] == 'approve_contract' || notificationType[2] == 'offer_expired') {
                    dispatch(dashboard.actions.offerDetails(notificationType[3]))
                    sleep(2000)
                    history.push('/' + notificationType1[1])
                }
                else if (notificationType[2] == 'edit_profile') {
                    history.push('/' + notificationType1[1])
                }
                else if (notificationType[2] == 'submit_contract_sign') {
                    history.push('/' + notificationType1[1])
                }
                else if (notificationType[2] == 'create_property_alert') {
                    history.push('/' + notificationType1[1])
                }
                else if (notificationType[2] == 'add_invoice') {
                    history.push('/' + notificationType1[1])
                }
                else if (notificationType[2] == 'invoice_followup_approved') {
                    history.push('/' + notificationType1[1])
                }
                else if (notificationType[2] == 'reject_request') {
                    history.push('/' + notificationType1[1])
                }
                else if (notificationType[2] == 'teamMember_added' || notificationType[2] == 'approved_techinican') {
                    history.push({
                        pathname: '/SingleBroker/data/details',
                        state: { broker: {id: notificationType[3], isNotifRedirect: true} }
                    })
                }
            }
            // End broker admin
        }, 2000)
    }, [notificationType])

    async function sleep(ms: any) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    // useEffect(() => {
    //     if (notificationType[2] == 'visit_scheduled') {
    //         history.push({
    //             pathname: '/pt-activity/my-visit/upcoming',
    //             state: { id: notificationType[3] }
    //         })

    //     }
    // }, [notificationType])
    const intl = useIntl();
    return (
        <>
            <CustomLoaderWithMsg height={50} msg={t("Please wait while we fetch your request...")} />

        </>
    )
};

export { SSORedirectWrapper }
