export default [
    {
        id: 1,
        no: "01",
        paymentmode: "Cheque",
        amount: "3,947,000.00",
        duedate: "30-04-2024",
        payment_description: "Initial payment covering 50% of the total amount",
        status: "Upcoming",
    },
    {
        id: 2,
        no: "02",
        paymentmode: "Cheque",
        amount: "2,473,500.00",
        duedate: "15-12-2023",
        payment_description: "Final payment covering the remaining 50% of the total amount due",
        status: "Upcoming"
    },
    {
        id: 3,
        no: "03",
        paymentmode: "Bank Transfer",
        amount: "3,947,000.00",
        duedate: "30-07-2023",
        payment_description: "Reservation Deposit",
        status: "Paid",
    },
];