import React, { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../components/layout/core'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PromotionListWrapper } from './components/PromotionList/PromotionListWrapper';
import { PromotionViewWrapper } from './components/PromotionView/PromotionViewWrapper';
import { PromotionCreateWrapper } from './components/PromotionCreate/PromotionCreateWrapper';
import { CustomRequestSuccessfulPopup } from '../SuccessfulPopup/CustomRequestSuccessfulPopup';
import { enableMarketPlace } from '../mta-market-place/marketHandler/marketHandler';
import { NoDataFoundMarketPlace } from '../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

type Props  = {
    // categoryList:any
}

const PromotionsWrapper: FC<Props> = () => {
    const [popupSuccessMsg, setPopupSuccessMsg] = useState<any>('');

    return(
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/promotions/list'>
                            <PromotionListWrapper />
                        </Route>
                        <Route path='/promotions/view'>
                            <PromotionViewWrapper setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Route path='/promotions/add'>
                            <PromotionCreateWrapper setPopupSuccessMsg={setPopupSuccessMsg}/>
                        </Route>
                        <Redirect to='/error/403' />
                    </Switch>
                </div> 
            </div>
            <CustomRequestSuccessfulPopup 
                    successMessage={popupSuccessMsg}
                    showOkBtn={true}
                    customID={'-redirect'}
                />
        </>
    )
};

const PromotionsWrapperComingSoon: FC<Props> = () => (
    <>
        <div className='row gy-5 g-xl-8 dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <div className="col-md-12 mt-0">
                    <h4 className="card-title font-18 text-black mb-6">
                        {t("Promotions")}
                    </h4>
                </div>
                <div className="col-12 mt-0">
                    <div className='card h-100'>
                        <div className='card-body p-7'>
                            <div className="row">
                                <NoDataFoundMarketPlace />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </>
);


const MTAPromotionsWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Market Place</PageTitle>
            {/* <PromotionsWrapper /> */}
            { enableMarketPlace() ? <PromotionsWrapper /> : <PromotionsWrapperComingSoon /> }

        </>
    )
};

export { MTAPromotionsWrapper }
