import React, { useState, useRef, FC } from 'react';
import { RootState } from '../../../../../constants'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const BankDetails: FC = () => {
    let offerList: any = []
    offerList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.offerDetails)
    const { t } = useTranslation();

    return (
        <div className="row">
            {/* <div className="col-12 mb-4">
                <hr />
            </div> */}
            <div className="col-12">
                <div className="">
                    <h3 className="mb-7" id="flush-headingSeven">
                        {/* <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#bank-details">
                            
                        </button> */}
                        {t("Bank Details")}
                    </h3>
                    <div 
                        id="bank-details" 
                        className="" 
                        // data-bs-parent="#accordion-step-five"
                    >
                        <div className="pt-0">
                            <div className="row form-add-design">
                                <div className="col-md-4 col-12">
                                    <div className="mb-10 input-field-new">
                                        <label className="form-label fw-bolder">{t("Beneficiary Name")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">
                                                {offerList?.data?.payment_plan_details?.bank_details?.beneficiaryName}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10 input-field-new">
                                        <label className="form-label fw-bolder">{t("Beneficiary Account")} </label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">{offerList?.data?.payment_plan_details?.bank_details?.beneficiaryAccount}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10 input-field-new">
                                        <label className="form-label fw-bolder">{t("IBAN NO")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">{offerList?.data?.payment_plan_details?.bank_details?.ibanNo}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10 input-field-new">
                                        <label className="form-label fw-bolder">{t("Beneficiary Bank")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">{offerList?.data?.payment_plan_details?.bank_details?.beneficiaryBank}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10 input-field-new">
                                        <label className="form-label fw-bolder">{t("Swift Code")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">{offerList?.data?.payment_plan_details?.bank_details?.swiftCode}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="mb-10 input-field-new">
                                        <label className="form-label fw-bolder">{t("Branch Address")}</label>
                                        <div className="data-contain px-5 py-4 rounded-10">
                                            <h6 className="font-14 fw-bold d-block mb-0">{offerList?.data?.payment_plan_details?.bank_details?.branchAddress}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export { BankDetails }
