import React, { FC, useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import completedStep from '../../../../../../assets/images/completed-step.png'
import { createMoveInAccessCardsRequest, getAllotedAccessCards } from '../../../../../../constants/axios/apis';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsPopup } from '../../../../../terms-and-conditions/TermsAndConditionsPopup'

type Props = {
    contract?: any
    getStepStatus?: any
    stepStatus?: any
}

const AccessCardDetails: FC<Props> = ({ contract, getStepStatus, stepStatus }) => {
    const [loading, setLoading] = useState(false)
    const sigCanvas = useRef<any>();
    const { t } = useTranslation();

    const [accessCards, setAccessCards] = useState<any>([])
    useEffect(() => {
        if (contract) {
          getAllotedAccessCards(contract?.propertyID).then(({ data }) => {
            if (data) {
              setAccessCards(data)
            }
          })
        }
      }, [contract])


    const initialValues = {
        access_cards_signature: '',
        access_cards_terms: false,
        access_cards_received_by: '',
    }

    const handelSubmit = () => {
        formik.handleSubmit()
    }

    const accessCardRequestSchema = Yup.object().shape({
        access_cards_signature: Yup.string().required(t("Please add your signature")),
        access_cards_terms: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
        access_cards_received_by: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Name of receiver is required"))
            .test('filled', t("Name of receiver cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    })

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: accessCardRequestSchema,
        onSubmit: (values) => {
            setLoading(true)
            const data = new FormData()
            data.append('category_id', '7')
            data.append('property_id', contract?.propertyID)
            data.append('received_by', values.access_cards_received_by)
            data.append('signature', values.access_cards_signature)

            createMoveInAccessCardsRequest(data).then(() => {
                getStepStatus()
                setLoading(false)
            })
        },
    })

    const handleSignature = () => {
        const canvas = sigCanvas.current.getCanvas()
        canvas.toBlob((blob: string) => {
            formik.setFieldValue('access_cards_signature', blob)
        }, 'image/png')
    }

    function clearSignature() {
        sigCanvas.current.clear()
        formik.setFieldValue('access_cards_signature', '')
    }

    const cardsData = accessCards?.map((card: any, index: any) => (
        <tr key={card.id} >
            <td>
                {card?.cardType}
            </td>
            <td>
                {card?.total}
            </td>
        </tr>
    ))
    let total: number = 0
    accessCards?.forEach((card: any) => {
        total += card.total
    })

    return (
        <>
            <div className="row">
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className="col-12">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne">
                                    {t("Access Card Type")}
                                    {(stepStatus?.accessCardsStatus) &&
                                        <span className='completed-step ps-3 pe-3 d-inline-block'>
                                            <img src={completedStep} alt='complete-step' />
                                        </span>}
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordion-step-one">
                                <div className="accordion-body pb-5 pt-0">
                                    <div className="row form-add-design">
                                        <div className="col-12">
                                            <div className='table-responsive'>
                                                <table className='table align-middle'>
                                                    <thead>
                                                        <tr>
                                                            <th className=''> {t("Name of Access Cards")} </th>
                                                            <th className=''> {t("No of Access Cards")} </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {cardsData}
                                                        {/* <tr>
                                                            <td>
                                                                {t("Building Access Card")}
                                                            </td>
                                                            <td>
                                                                03
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t("Parking Access Card")}
                                                            </td>
                                                            <td>
                                                                02
                                                            </td>
                                                        </tr> */}
                                                        <tr>
                                                            <td>
                                                                {t("Total")}
                                                            </td>
                                                            <td>
                                                                {total}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* <div className="col-12 mb-5">
                                            <h5 className="text-black fw-boldest font-14 mb-5">
                                                {t("Building Access Regulations")}
                                            </h5>
                                            <ul className="text-black font-12 fw-bold ps-5">
                                                <li className="mb-4">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                                                </li>
                                                <li className="mb-4">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                                                </li>
                                                <li className="mb-4">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                                                </li>
                                                <li className="mb-4">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                                                </li>
                                            </ul>
                                        </div> */}
                                        <div className="col-12">
                                            <h5 className="text-black fw-bolder font-14 mb-7">
                                                {t("Received By")}
                                            </h5>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("Name")} </label>
                                                {stepStatus?.accessCardsStatus ?
                                                    <input
                                                        className='form-control fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.accessCards?.details?.received_by}
                                                        disabled={true}
                                                    />
                                                    :

                                                    <input
                                                        className='form-control fw-bolder form-control-solid bg-white'
                                                        {...formik.getFieldProps('access_cards_received_by')}
                                                        autoComplete="off"
                                                    />
                                                }
                                                {formik.touched.access_cards_received_by && formik.errors.access_cards_received_by && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>
                                                                {formik.errors.access_cards_received_by}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {!stepStatus?.accessCardsStatus &&
                                            <div className="col-12 text-right form-add-design">
                                                <div className="row justify-content-end mb-10">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end align-items-center">
                                                            <div className="form-check custom mx-5">
                                                                <input
                                                                    className='form-check-input'
                                                                    {...formik.getFieldProps('access_cards_terms')}
                                                                    checked={formik.values.access_cards_terms}
                                                                    type='checkbox'
                                                                    id='flexCheckDefault'
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                                <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefault">
                                                                    {t("Accept")} 	&nbsp;
                                                                    {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
                                                                        {t("Terms & Conditions")}
                                                                    </Link> */}
                                                                    <button
                                                                        data-bs-target="#terms-conditions-popup"
                                                                        data-bs-toggle='modal'
                                                                        className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                                                                        {t("Terms & Conditions")}
                                                                    </button>
                                                                    <span className='text-danger'>*</span>
                                                                </label>
                                                                {formik.touched.access_cards_terms && formik.errors.access_cards_terms && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>
                                                                            <span role='alert' className='text-danger'>
                                                                                {formik.errors.access_cards_terms}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <button type='button' data-bs-toggle="modal" data-bs-target="#signature-modal" className='btn btn-primary fw-bold px-10 py-3'>
                                                                    {t("Signature")}
                                                                </button>
                                                                {formik.touched.access_cards_signature && formik.errors.access_cards_signature && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>
                                                                            <span role='alert' className='text-danger'>
                                                                                {formik.errors.access_cards_signature}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="modal fade redeem-offer signature-modal" id="signature-modal">
                                            <div className="modal-dialog modal-xs modal-dialog-centered">
                                                <div className="modal-content rounded-10">
                                                    <div className="modal-header rounded-10 border-0">
                                                        <h5 className="modal-title font-18 fw-bold">{t("Add your Signature")}</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body p-8">
                                                        <SignatureCanvas
                                                            ref={sigCanvas}
                                                            penColor='black'
                                                            canvasProps={{ height: 180, width: 480, className: 'sigCanvas' }}
                                                        />
                                                    </div>
                                                    <div className="modal-footer p-0 m-0">
                                                        <div className="row w-100 g-0 m-0">
                                                            <div className="col-12">
                                                                <button type="button" className="btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0" onClick={clearSignature}>{t("Reset")}</button>
                                                            </div>
                                                            <div className="col-12">
                                                                <button onClick={handleSignature} type="button" className="btn btn-primary fw-bold px-10 py-5 w-100 d-block" data-bs-toggle='modal'
                                                                    data-bs-target='#ParkingSuccessModal' data-bs-dismiss="modal">{t("Submit")}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!stepStatus?.accessCardsStatus &&
                                            <div className="col-12 text-right">
                                                <div className="row justify-content-end">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end">
                                                            <button type='button' onClick={handelSubmit} disabled={loading} className='btn btn-primary fw-bold px-10 py-3'>
                                                                {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        {t("Please wait...")}{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <TermsAndConditionsPopup />
        </>
    )
};

export { AccessCardDetails }
