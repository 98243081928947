import { FC, useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector, connect, useDispatch, ConnectedProps } from 'react-redux'
import { LayoutSplashScreen } from '../../layout/core'
import * as auth from './AuthRedux'
import { getUserByToken, getUserByToken1 } from './AuthCRUD'
import { RootState } from '../../../constants'
import { useHistory } from 'react-router-dom';

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
	let history = useHistory()
	const didRequest = useRef(false)
	const dispatch = useDispatch()
	const [showSplashScreen, setShowSplashScreen] = useState(true)
	const [userDatas, setuserDatas] = useState<any>('')
	const [loadAgain, setloadAgain] = useState(false)
	const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
	const ssoUser: any = (document.cookie)
	const ssoUser1: any = document?.cookie?.split('; userDetail=')[1]
	const test = ssoUser1?.split(', ')[0]
	const tokenSSO = document?.cookie?.split('; userDetail=')[1] ? JSON.parse(test)?.access_token : 0
	let str = (ssoUser);

	str = str.split(', ');
	const result: any = {};
	for (let i in str) {
		const cur = str[i].split('=');
		result[cur[0]] = cur[1];
	}
	const search = window.location.search;
	const params = new URLSearchParams(search);
	// let txnId: any = params.get('t');
	let txnId: any = 0
	let Id: any = params.get('d');
	if (Id != null) { txnId = params.get('t'); }
	let Link: any = params.get('l');

	// We should request user by authToken before rendering the application JSON.parse(result.userDetail).id_role
	const ssoRoles = ['4', '5', '6', '7', '14', '15']
	useEffect(() => {
		const requestUser = async () => {
			try {
				if (!didRequest.current) {
					const { data: user } = await getUserByToken()
					dispatch(props.fulfillUser(user))
				}
			} catch (error) {
				if (!didRequest.current) {
					dispatch(props.logout())
				}
			} finally {
				setShowSplashScreen(false)
			}
			return () => (didRequest.current = true)
		}
		const ssoLogin = async () => {
			try {
				if (!didRequest.current) {
					const { data: user } = await getUserByToken1()
					const userInfo = {
						...user.data
					}
					localStorage.setItem('userDetail', JSON.stringify(userInfo))
					if (Link) {
						setTimeout(() => {
							// if (atob(Id) === '6')
							// 	history.push(`/broker-admin-dashboard/available-properties`)
							// else
								history.push(`/${atob(Link)}`)
						}, 1000)
					}
				}
			} catch (error) { }
			finally {
				setShowSplashScreen(false)
			}
			return () => (didRequest.current = true)
		}
		if (atob(Id) === '4' || atob(Id) === '5' || atob(Id) === '6' || atob(Id) === '7' || atob(Id) === '14' || atob(Id) === '15') {
			dispatch(props.logout())
			dispatch(auth.actions.login(atob(txnId)))
			ssoLogin()
		}
		else if (accessToken && !test) {
			requestUser()
		}
		else if (test) {
			setTimeout(() => {
				setloadAgain(false)
				dispatch(props.fulfillUser((test)))
				localStorage.setItem('userDetail', test)
				setShowSplashScreen(false)
			}, 1000)
		}
		else {
			dispatch(props.logout())
			// setShowSplashScreen(false)
			window.location.assign(process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_WEB_URL}` : `${process.env.REACT_APP_WEB_URL_LOCAL}`)
		}
	}, [, loadAgain])

	return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
