import AnnouncementsPage from '../pages/announcements/AnnouncementsPage'
import { AnnouncementsDetails } from '../pages/announcements/components/AnnouncementsDetails/AnnouncementsDetails'
import { BRStatementsWrapper } from '../pages/br-statements/BRStatementsWrapper'
import { BrokerAdminDashboardWrapper } from '../pages/broker-admin-dashboard/BrokerAdminDashboardWrapper'
import { BrokerAdminMyTeamWrapper } from '../pages/broker-admin-MyTeam/BrokerAdminMyTeamWrapper'
import { BrokersWrapper } from '../pages/brokers/BrokersWrapper'
import { CTMyTeamWrapper } from '../pages/ct-myteam/CTMyTeamWrapper'
import { FCDashboardWrapper } from '../pages/fc-dashboard/FCDashboardWrapper'
import { FCReportsWrapper } from '../pages/fc-reports/FCReportsWrapper'
import { SupervisorCreateAnnouncements } from '../pages/fc-supervisor-announcements/add/CreateAnnouncements/CreateAnnouncements'
import { FCSupervisorAnnouncementWrapper } from '../pages/fc-supervisor-announcements/FCSupervisorAnnouncements'
import { FCSupervisorCalenderWrapper } from '../pages/fc-supervisor-calender/FCSupervisorCalenderWrapper'
import { AddTechnicianPage } from '../pages/fc-supervisor-myteam/components/Add/AddTechnician'
import { FCSMyTeamDetailsWrapper } from '../pages/fc-supervisor-myteam/FCSMyTeamDetails/FCSMyTeamDetailsWrapper'
import { MaintenancePage } from '../pages/maintenance/MaintenancePage'
import { LeadsWrapper } from '../pages/leads/LeadsWrapper'
// import { enableStaticMarket } from '../pages/mta-market-place/marketHandler/marketHandler'
import { MTAMarketPlaceWrapper } from '../pages/mta-market-place/MTAMarketPlaceWrapper'
import { MTAPromotionsWrapper } from '../pages/mta-promotions/MTAPromotionsWrapper'
import { MyServicePage } from '../pages/my-services/MyServicePage'
import MyUnitWrapper from '../pages/my-unit/MyUnitWrapper'
import { UnitPortfolioWrapper } from '../pages/unit-portfolio/UnitPortfolioWrapper'
import { PaymentsPage } from '../pages/payments/PaymentsPage'
import { PTActivityWrapper } from '../pages/pt-activity/PTActivityWrapper'
import { CreateAlerts } from '../pages/pt-alerts/components/CreateAlerts/CreateAlerts'
import { PTAlertsWrapper } from '../pages/pt-alerts/PTAlertsWrapper'
import { PTDocumentsWrapper } from '../pages/pt-documents/PTDocumentsWrapper'
import { PTFaq } from '../pages/pt-faq/Faq'
import { PTMarketPlaceWrapper } from '../pages/pt-market-place/PTMarketPlaceWrapper'
import { PTSettings } from '../pages/pt-settings/PTSettings'
import { SingleBrokerDetails } from '../pages/SinglebrokersData/SingleBrokerDetails/SingleBrokerDetails'
import { SingleBrokersWrapper } from '../pages/SinglebrokersData/SingleBrokersWrapper'
import { StatementsWrapper } from '../pages/statements/StatementsWrapper'
import { ContractsWrapper } from '../pages/team-activity/components/Contracts/ContractsWrapper'
import { TeamActivityOfferWrapper } from '../pages/team-activity/components/Offers/OfferPageWrapper'
import { ReservedPropertiesWrapper } from '../pages/team-activity/components/ReservedProperties/ReservedPropertiesWrapper'
import { TeamsActivityWrapper } from '../pages/team-activity/TeamsActivityWrapper'
import { CommunityWallWrapper } from '../pages/community-wall/CommunityWallWrapper'
import { BrokerAdminChequesWrapper } from '../pages/broker-admin-cheques/BrokerAdminChequesWrapper'


export default [

	// {
	// 	// component: FCDashboardWrapper,
	// 	path: '/dashboard',
	// 	menu: {
	// 		menuPath: '/dashboard',
	// 		title: 'Dashboard',
	// 		iconClass: 'dash-icon-menu',
	// 		iconFile: '/media/icons/Dashboard_InActive.png',
	// 		dynaimcPaths:[],
	// 		supportedComponents:[],
	// 	},
	// 	exact: true,
	// 	permission: [
	// 		'FC', 'FCSS', 'FCS', 'FT', 'FMT', 'FMSM', 'MT'
	// 	],
	// },

	{ //** Broker Admin Dashboard
		component: BrokerAdminDashboardWrapper,
		path: '/broker-admin-dashboard',
		menuCode: 'Dashboard',//* API code/name
		menu: {
			menuPath: '/broker-admin-dashboard/available-properties',
			title: 'Dashboard',
			iconClass: 'dash-icon-menu',
			iconFile: '/media/icons/Dashboard_InActive.png',
			dynaimcPaths: [
				'/broker-admin-dashboard/available-properties',
				'/broker-admin-dashboard/viewall-upcoming-properties',
				'/broker-admin-dashboard/viewall-available-properties',
				'/broker-admin-dashboard/upcoming-properties'
			],
			supportedComponents: [],
		},
		exact: true,
		permission: [
			'BA'
		],
	},


	{//Documents
		component: PTDocumentsWrapper,	//* aside menu component
		path: '/pt-documents',//* aside menu path
		menuCode: 'My Documents',//* API code/name
		menu: {
			menuPath: '/pt-documents/brn-orn-id',//* default path
			title: 'My Documents',//* title
			iconClass: 'mydocument-icon-menu',//*icon class
			iconFile: '/media/icons/Dashboard_InActive.png',//*Icon name 
			supportedComponents: [],//**Inner pages */
			dynaimcPaths: [
				'/pt-documents/brn-orn-id',//* tab urls
				'/pt-documents/document'
			],
		},
		exact: true,
		permission: [
			'PT', 'BR', 'BA', 'T', 'CT', 'CPT'
		],
	},
	{//Activities
		component: PTActivityWrapper,
		path: '/pt-activity',
		menuCode: 'My Activity',
		menu: {
			menuPath: '/pt-activity/my-visit/upcoming',
			title: 'My Activity',
			iconClass: 'activity-icon-menu',
			iconFile: '/media/icons/myactivity-inactive.png',
			dynaimcPaths: [
				'/pt-activity/my-visit/upcoming',
				'/pt-activity/my-visit/visited',
				'/pt-activity/my-offer',
				'/pt-activity/my-shortlist',
				'/pt-activity/my-contracts',
				'/pt-activity/my-contracts-details',
				'/pt-activity/my-approvals'
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'PT', 'BR', 'T', 'CT', 'CPT',
		],
	},  { // FAQ
		component: LeadsWrapper,
		path: '/leads',
		menuCode: 'Leads',
		menu: {
			menuPath: '/leads/list',
			title: 'Leads',
			iconClass: 'team-act-icon-menu',
			iconFile: '/media/icons/team_activity_2.png',
			profileIconClass: 'item-icon quest-ioc',//*profile dropdown icon class
			dynaimcPaths: [
				'/leads/list',
				'/leads/add',
				'/leads/edit',
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'BR'
		],
	},
	{//PTAlertsWrapper
		component: PTAlertsWrapper,
		path: '/pt-alerts',
		menuCode: 'Alerts',
		menu: {
			menuPath: '/pt-alerts',
			title: 'My Alerts',
			iconClass: 'alert-icon-menu',
			iconFile: '/media/icons/alert-inactive.png',
			dynaimcPaths: [

			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'PT', 'BR', 'BA', 'T', 'CPT'
		],
	},
	//** Tenant */

	{//* My Unit
		component: MyUnitWrapper,
		path: '/my-unit',
		menuCode: 'My Unit',
		menu: {
			menuPath: '/my-unit/lease-details',
			title: 'My Unit',
			iconClass: 'unit-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/my-unit/payments',
				'/my-unit/documents/community-docx',
				'/my-unit/documents/personal-docx',
				'/my-unit/need-action',

			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'T', 'CT'
		],
		contractValidate: true,
		onlyRent : true,
	},

	{//* Unit Portfolio (Static)
		component: UnitPortfolioWrapper,
		path: '/unit-portfolio',
		menuCode: 'My Unit',
		menu: {
			menuPath: '/unit-portfolio/list',
			title: 'My Property',
			iconClass: 'unit-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/unit-portfolio/list',
				'/unit-portfolio/details',
				'/unit-portfolio/card-payment',
				'/unit-portfolio/bank-transfer',
				'/unit-portfolio/pay-by-cheque',
				'/unit-portfolio/owner-details',
				'/unit-portfolio/need-action',
				'/unit-portfolio/documents',
				'/unit-portfolio/payments'
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'T'
		],
		onlySale : true,
	},


	{//* maintenance
		component: MaintenancePage,
		path: '/maintenance',
		menuCode: 'maintenance',
		menu: {
			menuPath: '/maintenance/openissues',
			title: 'Maintenance',
			iconClass: 'maint-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/maintenance/openissues',
				'/maintenance/resolvedissues',
				'/maintenance/newrequest',
				'/maintenance/issues-view',
				'/maintenance/reschedule',
				'/my-services/amenity-booking',
				'/maintenance/resolvedissues',
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'T', 'CT'
		],
		contractValidate: true,
	},

	{//* services
		component: MyServicePage,
		path: '/my-services',
		menuCode: 'Service Requests',
		menu: {
			menuPath: '/my-services/openissues',
			title: 'My Services',
			iconClass: 'my-serv-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/my-services/openissues',
				'/my-services/resolvedissues',
				'/my-services/newrequest',
				'/my-services/issues-view',
				'/my-services/reschedule',
				'/my-services/amenity-booking',
				'/my-services/resolvedissues',
				'/my-services/1',
				'/my-services/2',
				'/my-services/3',
				'/my-services/4',
				'/my-services/14',
				'/my-services/service-requests',
				'/my-services/rent-reduction',
				'/my-services/renewal-notice',
				'/my-services/extend-contract',
				'/my-services/contract-renewal'
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'T', 'CT'
		],
		contractValidate: true,
	},
	{//* cheque (Static)
		component: BrokerAdminChequesWrapper,
		path: '/my-cheques',
		menuCode: 'My Unit',
		menu: {
			menuPath: '/my-cheques/list',
			title: 'My Cheques',
			iconClass: 'unit-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/my-cheques/list',
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'T'
		],
		onlySale : true,
	},
	{//* services
		component: CommunityWallWrapper,
		path: '/communitywall',
		menuCode: 'Announcements',
		menu: {
			menuPath: '/communitywall/page',
			title: 'Community Post',
			iconClass: 'announce-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/communitywall/page',
				'/communitywall/create-community-post'
				
				
			],
			
			
			supportedComponents: [
				{
					path: '/announcements-details',
					component: AnnouncementsDetails
				},
			],
		},
		exact: true,
		permission: [
			'T', 'CT'
		],
		contractValidate: true,
	},

	

	{//* Payments
		component: PaymentsPage,
		path: '/payments',
		menuCode: 'Payments',
		menu: {
			menuPath: '/payments/all',
			title: 'Payments',
			iconClass: 'payment-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/payments/all',
				'/payments/security-deposits',
				'/payments/service-charges',
				'/payments/other-charges',
				'/payments/statement',
				'/payments/card-payment',
				'/payments/bank-transfer',
				'/payments/pay-by-cheque',
				'/payments/cheque-delay-request',
				'/payments/cheque-replace-request',
				'/payments/rents'
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'T', 'CT'
		],
		contractValidate: true,
		onlyRent : true
	},

	{//* CT My team
		component: CTMyTeamWrapper,
		path: '/ct-my-team',
		menuCode: 'My team',
		menu: {
			menuPath: '/ct-my-team/day',
			title: 'My team',
			iconClass: 'myteam-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/ct-my-team/day',
				'/ct-my-team/month',
				'/ct-my-team/year'
			],
			supportedComponents: [
				{
					path: '/ct-my-team/add'
				},
			],
		},
		exact: true,
		permission: [
			'T'
		],
		onlyCT: true
	},

	// {//* Announcements
	// 	component: AnnouncementsPage,
	// 	path: '/announcements',
	// 	menuCode: 'Announcements',
	// 	menu: {
	// 		menuPath: '/announcements',
	// 		title: 'Announcement',
	// 		iconClass: 'announce-icon-menu',
	// 		iconFile: '/media/icons/My_Lease_InActive.png',
	// 		dynaimcPaths: [
	// 			'/announcements'
	// 		],
	// 		supportedComponents: [
	// 			{
	// 				path: '/announcements-details',
	// 				component: AnnouncementsDetails
	// 			},
	// 		],
	// 	},
	// 	exact: true,
	// 	permission: [
	// 		'T', 'CT'
	// 	],
	// 	contractValidate: true,
	// },
	{//MTA-Marketplace
		component: MTAMarketPlaceWrapper,
		path: '/market-place',
		menuCode: 'Market place',
		menu: {
			// menuPath: enableStaticMarket() ? '/market-place/list/offers/all' : '/market-place/list/events/all',
			menuPath: '/market-place/list/events/all',
			title: 'Market Place',
			iconClass: 'mp-icon-menu',
			iconFile: '/media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/market-place/list/events',
				'/market-place/list/events/all',
				'/market-place/list/events/dining',
				'/market-place/list/events/lifestyle',
				'/market-place/list/events/entertainment',
				'/market-place/list/events/active',
				'/market-place/list/events/inactive',
				'/market-place/list/events/expired',
				'/market-place/list/offers',
				'/market-place/list/offers/all',
				'/market-place/list/offers/dining',
				'/market-place/list/offers/lifestyle',
				'/market-place/list/offers/entertainment',
				'/market-place/list/offers/services',
				'/market-place/list/offers/active',
				'/market-place/list/offers/inactive',
				'/market-place/list/offers/expired',
				'/market-place/list/vouchers',
				'/market-place/list/vouchers/all',
				'/market-place/list/vouchers/dining',
				'/market-place/list/vouchers/lifestyle',
				'/market-place/list/vouchers/entertainment',
				'/market-place/list/vouchers/active',
				'/market-place/list/vouchers/inactive',
				'/market-place/list/vouchers/expired',
				'/market-place/list/redeemed',
				'/market-place/list/redeemed/all',
				'/market-place/list/redeemed/dining',
				'/market-place/list/redeemed/lifestyle',
				'/market-place/list/redeemed/entertainment',
				'/market-place/list/redeemed/active',
				'/market-place/list/redeemed/inactive',
				'/market-place/list/redeemed/expired',
			],
			supportedComponents: [
				// { path: '/market-place/add/event' },
				// { path: '/market-place/add/offer' },
				// { path: '/market-place/add/voucher' },
				{ path: '/market-place/view' }
			],
		},
		exact: true,
		permission: [
			'PT'
		],
		contractValidate: true,
	},
	{//MTA-Promotions
		component: MTAPromotionsWrapper,
		path: '/promotions',
		menuCode: 'Market place',
		menu: {
			menuPath: '/promotions/list/upcoming/events',
			title: 'Promotions',
			iconClass: 'campaign-icon-menu',
			iconFile: '/media/icons/Dashboard_InActive.png',
			dynaimcPaths: [
				'/promotions/list/upcoming/events',
				'/promotions/list/upcoming/offers',
				'/promotions/list/upcoming/vouchers',
				'/promotions/list/upcoming/redeemed',
				'/promotions/list/closed/events',
				'/promotions/list/closed/offers',
				'/promotions/list/closed/vouchers',
				'/promotions/list/closed/redeemed',
				'/promotions/list/pending/events',
				'/promotions/list/pending/offers',
				'/promotions/list/pending/vouchers',
			],
			supportedComponents: [
				{path: '/promotions/view'},
				{path: '/promotions/add/event'},
				{path: '/promotions/add/offer'},
				{path: '/promotions/add/voucher'},
			],
		},
		exact: true,
		permission: [
			'T'
		],
		onlyCT: true,
		contractValidate: true,
	},
	//** Tenant End */


	//** Broker Admin Start End */
	{ //** Broker Admin Team Activity
		component: TeamsActivityWrapper,
		path: '',
		menuCode: 'Team Activity',//* API code/name
		menu: {
			isSubMenu: true,
			// menuPath: '/visits/upcoming',
			title: 'Team Activity',
			iconClass: 'team-act-icon-menu',
			iconFile: '/media/icons/team_activity_2.png',
			dynaimcPaths: [],
			supportedComponents: [],
			menu_wrapper_id: 'team_activity',
			subMenu: [
                {
                    component: LeadsWrapper,
                    path: '/leads',
                    menuCode: 'Team Activity',//* API code/name
                    menu: {
                        menuPath: '/leads/list',
                        title: 'Leads',
                        dynaimcPaths: [
							'/leads/list',
							'/leads/add',
							'/leads/edit',
						],
                        supportedComponents: [
                            // {
                            //     path: '/leads/:id',
                            //     component: LeadsWrapper
                            // }
                        ],
                    },
                    // permission: ['BA'],
				},
				{
					component: TeamsActivityWrapper,
					path: '/visits',
					menuCode: 'Team Activity',//* API code/name
					menu: {
						menuPath: '/visits/upcoming',
						title: 'Team Visits',
						dynaimcPaths: [
							'/visits/upcoming',
							'/visits/visitconfirmed',
							'/visits/closed'
						],
						supportedComponents: [],
					},
					// permission: ['BA'],
				},
				{
					component: TeamActivityOfferWrapper,
					path: '/offers',
					menuCode: 'Team Activity',//* API code/name
					menu: {
						menuPath: '/offers',
						title: 'Offers',
						dynaimcPaths: [],
						supportedComponents: [
							{
								path: '/offers-details/:id',
								component: TeamActivityOfferWrapper
							}
						],
					},
					// permission: ['BA'],
				},
				{
					component: ReservedPropertiesWrapper,
					path: '/reservedproperties',
					menuCode: 'Team Activity',//* API code/name
					menu: {
						menuPath: '/reservedproperties',
						title: 'Reserved Properties',
						dynaimcPaths: [],
						supportedComponents: [],
					},
					// permission: ['BA'],
				},
				{
					component: ContractsWrapper,
					path: '/contracts',
					menuCode: 'Team Activity',//* API code/name
					menu: {
						menuPath: '/contracts/uninvoiced',
						title: 'Contracts',
						dynaimcPaths: [
							'/contracts/uninvoiced',
							'/contracts/invoiced',
							'/property-details'
						],
						supportedComponents: [],
					},
					// permission: ['BA'],
				},

			]
		},
		exact: true,
		permission: [
			'BA'
		],
	},
	{ //** Broker Admin My Team
		component: BrokerAdminMyTeamWrapper,
		path: '/broker-admin-MyTeam',
		menuCode: 'My Team',//* API code/name
		menu: {
			menuPath: '/broker-admin-MyTeam',
			title: 'My Team',
			iconClass: 'myteam-icon-menu',
			iconFile: '/media/icons/MyTeam_InActive.png',
			dynaimcPaths: [
				'/broker-admin-MyTeam',
				// '/SingleBroker/rejected',
				// '/SingleBroker/approved',
				// '/SingleBroker/in-progress',
				// '/brokers/rejected',
				// '/brokers/approved',
				// '/brokers/in-progress'

			],
			supportedComponents: [
				{
					path: '/SingleBroker/in-progress',
					component: SingleBrokersWrapper,
				},
				{
					path: '/brokers/rejected',
					component: BrokersWrapper,
				},
				{
					path: '/brokers/approved',
					component: BrokersWrapper,
				},
				{
					path: '/brokers/in-progress',
					component: BrokersWrapper,
				},
				{
					path: '/SingleBroker/rejected',
					component: SingleBrokersWrapper,
				},
				{
					path: '/SingleBroker/approved',
					component: SingleBrokersWrapper,
				},
				{
					path: '/SingleBroker/data/details',
					component: SingleBrokerDetails,
				}
			],
		},
		exact: true,
		permission: [
			'BA'
		],
	},
	{ //** Broker Admin Statments
		component: StatementsWrapper,
		path: '/statements',
		menuCode: 'Statements',//* API code/name
		menu: {
			menuPath: '/statements/inprogress-invoices',
			title: 'Statements',
			iconClass: 'statement-icon-menu',
			iconFile: '/media/icons/Dashboard_InActive.png',
			dynaimcPaths: [
				'/statements/approved-invoices',
				'/statements/rejected-invoices',
				'/statements/inprogress-invoices'

			],
			supportedComponents: [

			],
		},
		exact: true,
		permission: [
			'BA', 'BR'
		],
	},
	//** Broker Admin End */

	//** Broker  */
	// {//* Statments
	// 	component: BRStatementsWrapper,
	// 	path: '/br-statements',
	// 	menuCode: 'Statements',
	// 	menu: {
	// 		menuPath: '/br-statements/approved-invoices',
	// 		title: 'Statements',
	// 		iconClass: 'announce-icon-menu',
	// 		iconFile: '/media/icons/My_Lease_InActive.png',
	// 		dynaimcPaths: [
	// 			'/br-statements/approved-invoices',
	// 			'/br-statements/inprogress-invoices',
	// 			'/br-statements/rejected-invoices',
	// 			'/br-statements/add-commission'
	// 		],
	// 		supportedComponents: [
	// 		],
	// 	},
	// 	exact: true,
	// 	permission: [
	// 		'BR'
	// 	],
	// },
	//** Broker End */
	//** General Routes */
	{//PTMarketPlaceWrapper
		component: PTMarketPlaceWrapper,
		path: '/marketPlace/events/all',
		menuCode: 'Market place',
		menu: {
			menuPath: '/marketPlace/events/all',
			title: 'Market Place',
			iconClass: 'mp-icon-menu',
			iconFile: 'media/icons/My_Lease_InActive.png',
			dynaimcPaths: [
				'/marketPlace/events/all', '/marketPlace/events/dining',
				'/marketPlace/events/lifestyle', '/marketPlace/events/entertainment',
				'/marketPlace/offers/all', '/marketPlace/offers/dining', '/marketPlace/offers/lifestyle',
				'/marketPlace/offers/entertainment', '/marketPlace/vouchers/all', '/marketPlace/vouchers/dining',
				'/marketPlace/vouchers/lifestyle', '/marketPlace/vouchers/entertainment', '/marketPlace/redeemed/all',
				'/marketPlace/redeemed/dining', '/marketPlace/redeemed/lifestyle', '/marketPlace/redeemed/entertainment'
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			// 'PT', 'BR', 'BA', 'T', 'CT', 'CPT'
			'BR', 'BA',
		],
	},

	{//pt-faq
		component: PTFaq,
		path: '/pt-faq',
		menuCode: 'FAQ',
		menu: {
			menuPath: '/pt-faq',
			title: 'FAQ',
			iconClass: 'faq-icon-menu',
			iconFile: 'media/icons/faq-inactive.png',
			dynaimcPaths: [
				'/pt-faq'
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'PT', 'BR', 'BA', 'T', 'CPT'
		],
	},

	{//Settings
		component: PTSettings,
		path: '/pt-settings',
		menuCode: 'Settings',
		menu: {
			menuPath: '/pt-settings',
			title: 'Settings',
			iconClass: 'settings-icon-menu',
			iconFile: 'media/icons/settings-inactive.png',
			dynaimcPaths: [
				'/pt-settings'
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'PT', 'BR', 'BA', 'T', 'CPT'
		],
	},


	// {
	// 	component: FCSupervisorAnnouncementWrapper,//** Pvt routes  component
	// 	path: '/facility-maintenance',//* Pvt routes  path
	// 	menuCode: 'Maintenance',//* API title name
	// 	menu: {
	// 		menuPath: '/facility-maintenance/list/open',
	// 		title: 'Maintenance',
	// 		iconClass: 'maint-icon-menu',
	// 		iconFile: '/media/icons/announcement_InActive.png',
	// 		dynaimcPaths: [
	// 			'/facility-maintenance/list/open',
	// 			'/facility-maintenance/list/resolved'
	// 		],
	// 		supportedComponents: [
	// 			{ path: '/facility-maintenance/view' },
	// 			{ path: '/facility-maintenance/add' },
	// 		],
	// 	},
	// 	exact: true,
	// 	permission: [
	// 	],
	// },

]