import React from 'react'

export function ViewPopup(props: any) {
  return (
    <div className='modal fade custom-modal' aria-hidden='true' id='view-popUpreschedule'>
      <div className='modal-dialog modal-lg modal-dialog-centered'>
        <div className='modal-content rounded-10'>
          <div className='modal-header p-0 border-0'>
            <button
              type='button'
              className='btn-close shadow'
              data-bs-dismiss='modal'
              aria-label='Close'
            >&times;</button>
          </div>
          <div className='modal-body p-8'>
            <div className='row'>
              <div className="col-12 text-center">
                {/* {props?.viewImage.includes('pdf') ? <iframe src={props?.viewImage} style={{ width: '600px', height: '500px' }} ></iframe> :
                  <img src={props?.viewImage} className='img-fluid va-baseline' />} */}
                {props?.viewImage?.includes('pdf') ?
                  <iframe src={props?.viewImage} title="pdf" style={{ width: '600px', height: '500px' }} ></iframe>
                  :
                  <img src={props?.viewImage} alt="img" style={{ width: '100%', height: '100%' }} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
