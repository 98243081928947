import React, { useState, useEffect, useRef } from 'react'
import 'react-calendar/dist/Calendar.css'
import moment, { min } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { rescheduleVisit } from '../../../../../utils/makeRequest'
import { IState } from '../../../../../utils/interface'
import { useTranslation } from 'react-i18next'
import { MdOutlineClose } from 'react-icons/md'
import DatePicker from 'react-date-picker'
import { postRescheduleVisit } from '../../../../../constants/axios/apis'
import * as dashboard from './../../../../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import { CustomRequestSuccessfulPopup } from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { useHistory } from 'react-router-dom'

interface IProps {
  brokersList: IState['APIState']
}

export const ReschedulePopup: React.FC<any> = ({ scheduleData }) => {
  const ref = useRef<any>(0)
  const dispatch = useDispatch()
  const [message, setMessage] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [value, onChange] = useState(new Date())
  const [sDate, setSDate] = useState<any>()
  const [stime, setStime] = useState<any>()
  const [errorMessageTime, setErrorMessageTime] = useState<any>()
  const { t } = useTranslation()
  const buttonEl: any = useRef(null)
  const state: any = useSelector((state) => state)
  const [loadings, setLoadings] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [submitting, setSubmitting] = useState<any>('')
  const [status, setStatus] = useState<any>('')
  const history = useHistory()

  const handleDate = (e: any) => {
    onChange(new Date(e))
    setSDate(moment(e).format('YYYY-MM-DD'))
    setStime('')
  }

  let timeNow = moment().format('HH:mm')
  let dateNow = moment().format('YYYY-MM-DD')
  let timeData = [
    { id: 1, time: '09:00' },
    { id: 2, time: '09:30' },
    { id: 3, time: '10:00' },
    { id: 4, time: '10:30' },
    { id: 5, time: '11:00' },
    { id: 6, time: '11:30' },
    { id: 8, time: '12:00' },
    { id: 9, time: '12:30' },
    { id: 10, time: '13:00' },
    { id: 12, time: '13:30' },
    { id: 13, time: '14:00' },
    { id: 14, time: '14:30' },
    { id: 15, time: '15:00' },
    { id: 16, time: '15:30' },
    { id: 17, time: '16:00' },
    { id: 18, time: '16:30' },
    { id: 19, time: '17:00' },
    { id: 20, time: '17:30' },
    { id: 21, time: '18:00' },
    { id: 22, time: '18:30' },
    { id: 23, time: '19:00' },
  ]
  const onChangeTime = (time: any) => {
    setStime(time.target.value)
  }

  useEffect(() => {
    if (scheduleData?.visit_date) onChange(new Date(scheduleData?.visit_date))
  }, [scheduleData])

  const validateTime = (time: any) => {
    const old_date = moment(moment(value).format('YYYY-MM-DD') + ' ' + time + ':00')
    const dt = moment(
      moment(scheduleData?.visit_date).format('YYYY-MM-DD') + ' ' + scheduleData?.visitTime
    )
    if (old_date.diff(dt, 'days') === 0) {
      if (old_date.diff(dt, 'minutes') === 0) return false
    }
    return true
  }

  const onSubmit = () => {
    let datas: any
    if (stime?.length > 2) {
      setLoading(true)
      datas = {
        property_id: scheduleData?.property_id,
        visit_id: scheduleData?.id,
        start_time: moment(value).format('YYYY-MM-DD') + ' ' + stime + ':00',
        end_time:
          moment(value).format('YYYY-MM-DD') +
          ' ' +
          moment(stime, 'H:mm').add(0, 'seconds').add(30, 'minutes').format('HH:mm:ss'),
        visit_date: moment(value).format('YYYY-MM-DD'),
      }
      var config = {
        data: datas,
      }
      // setTimeout(() => {
      //   setLoadings(true)
      //   setSubmitError('')
      postRescheduleVisit(config)
        .then((response: any) => {
          setLoadings(false)
          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
          } else if (response.errorCode === 0) {
            setLoadings(false)
            setSuccessMsg(response?.data)
            console.log(response?.data,"response?.data");         
            var ev = new Event('click', { bubbles: true })
            buttonEl.current.dispatchEvent(ev)
            // setTimeout(() => {
            //   history.push('/visits/upcoming')
            // }, 1000)
          }
          else {
            setSubmitError(t('Some error occured. Please try again !'))
          }
        })
        .catch((e) => {
          setLoadings(false)
          setSubmitting(false)
          setStatus(t('Invalid Data Provided'))
        })
      // }, 1000)
      // postRescheduleVisit(config).then((data) => {        
      //   setLoading(false)
      //   if (data.code === 200 && data.errorCode != 1) {
      //     setMessage({
      //       error: 0,
      //       message: data.data,
      //     })
      //     // ref.current.click()
      //     setTimeout(() => {
      //       // window.location.reload()
      //       // ref.current.click()
      //       var ev = new Event('click', { bubbles: true })
      //       buttonEl.current.dispatchEvent(ev)
      //     }, 5000)
      //   } else {
      //     setMessage({
      //       error: 1,
      //       message: data.errorDescription,
      //     })
      //     // ref.current.click()
      //   }
      // })
      // } 
      // else {
      //   setErrorMessageTime(t('Please select the time'))
      //   setTimeout(() => {
      //     setErrorMessageTime('')
      //   }, 5000)
      // }
    }
  }

  return (
    <>
      <div className='row'>
        <div className='modal fade custom-modal' data-bs-backdrop="static" data-bs-keyboard="false" id='reschedule-popup'>
          <div className='modal-dialog modal-lg modal-dialog-centered'>
            <div className='modal-content rounded-10'>
              <div className='modal-header border-0 justify-content-start py-4'>
                <h5 className='modal-title fw-bold'>{t('Reschedule')}</h5>
                <button type='button' className='btn-close' data-bs-dismiss='modal'>
                  &times;
                </button>
              </div>
              <div className='modal-body px-10 pb-0'>
                <div className='row'>
                  <div className='col-12 col-sm-4'>
                    <div className='mb-6 date-picker'>
                      <label className='form-label fw-bolder mb-3'> {t('Visit Date')} </label>
                      <DatePicker
                        onChange={(e: any) => handleDate(e)}
                        value={value}
                        minDate={moment().toDate()}
                        format='dd-MM-y'
                        showLeadingZeros={true}
                        openCalendarOnFocus={true}
                        // defaultValue={new Date(scheduleData?.visit_date)}
                        className='form-control fw-bolder form-control-solid'
                        clearIcon={null}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-4'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder mb-3'> {t('Time')} </label>
                      <select className='form-control' onChange={onChangeTime} value={stime}>
                        <option value=''>{t('Select Time')}</option>
                        {timeData.map((item: any, index: any) => {
                          if (validateTime(item.time))
                            return (
                              <option
                                key={index}
                                value={item.time}
                                selected={scheduleData?.visitTime?.slice(0, -3)}
                                style={{
                                  display:
                                    timeNow >= item.time &&
                                      dateNow === moment(value).format('YYYY-MM-DD')
                                      ? 'none '
                                      : 'inline-block',
                                }}
                              >
                                {' '}
                                {item.time}
                              </option>
                            )
                        })}
                      </select>
                      <p style={{ color: 'red' }}> {errorMessageTime}</p>
                    </div>
                  </div>
                  <div className='col-12 col-sm-4'>
                    <div className='mb-6'>
                      <div className='row align-items-center visit-confirmed-date-res'>
                        <div className='col-md-12 col-12'>
                          <p className='text-black fw-bolder font-17 mb-5 text-md-end text-start'>
                            <i className='bi bi-check-circle-fill font-17 visit-confirmed me-3'></i>{' '}
                            {t('Visit Confirmed')}
                          </p>
                        </div>
                        <div className='col-md-12 col-12'>
                          <p className='text-black text-md-end text-start mt-3 mt-md-0 mb-0'>
                            <span className='me-3'>
                              {moment(scheduleData?.visit_date).format('DD-MM-YYYY')}
                            </span>
                            <span>{scheduleData?.visitTime}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-md-6'>
                  <div className='col-12 col-sm-4'>
                    <div className='mb-6'>
                      <label className='form-label mb-3'> {t('Name of the customer')}</label>
                      <input
                        disabled
                        type='text'
                        name='cname'
                        value={scheduleData?.customer_name}
                        // onChange={(e) => setCustomer(e?.target?.value)}
                        className='form-control form-control-solid'
                        placeholder={t('Enter')}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-4'>
                    <div className='mb-6'>
                      <label className='form-label mb-3'>{t('Email ID')}</label>
                      <input
                        disabled
                        type='email'
                        name='email'
                        value={scheduleData?.email}
                        className='form-control form-control-solid'
                        placeholder={t('Enter')}
                      />
                    </div>
                  </div>
                  {scheduleData?.comments && (
                    <div className='col-12 col-sm-12'>
                      <div className='mb-6'>
                        <label className='form-label mb-3'>{t('Comments(Optional)')}</label>
                        <textarea
                          className='form-control form-control-lg form-control-solid'
                          rows={3}
                          disabled
                          value={scheduleData?.comments}
                          placeholder={t('Type here...')}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='modal-footer border-0 pt-0 px-10'>
                <button
                  type='button'
                  className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                  data-bs-toggle='modal'
                  data-bs-dismiss='modal'
                >
                  {t('Cancel')}
                </button>
                <button
                  type='button'
                  className='btn btn-primary fw-bold px-10 py-3'
                  data-bs-dismiss='modal'
                  onClick={onSubmit}
                >
                  {t('Reschedule')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='modal fade custom-modal' id='rescheduleCancel-popup1'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content rounded-10'>
            <div className='modal-header border-0 py-4'>
              <h5 className='modal-title fw-bold'>{t('Cancel Visit')}</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal'>
                &times;
              </button>
            </div>
            <div className='modal-body px-10 pb-0'>
              <div className='row mt-6'>
                <div className='col-12'>
                  <div className='mb-6'>
                    <div className='text-center flex-css justify-content-center'>
                      <MdOutlineClose color='red' fontSize='3.5em' />
                    </div>
                    <h3 className='text-dark fw-bolder text-center flex-css justify-content-center mt-4 font-18'>
                      {t('Are you sure?')}
                    </h3>
                  </div>
                  <div className='text-center flex-css justify-content-center'>
                    <p className='font-14 text-black fw-normal'>
                      {t('Do you really want to cancel this visit?')} <br />{' '}
                      {t('This process cannot be undone.')}{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer border-0 flex-css justify-content-center'>
              <button
                type='button'
                className='btn btn-primary fw-bold px-10 py-3 me-5'
                data-bs-dismiss='modal'
                // onClick={() => onCancel()}
              >
                {t('Yes')}
              </button>
              <button
                type='button'
                className='btn btn-outline-primary fw-bold px-10 py-3'
                data-bs-dismiss='modal'
              >
                {t('No')}
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* <p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
     
      </p> */}
      {submitError && (
        <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
          {submitError}
        </div>
      )}
      <p
        ref={buttonEl}
        data-bs-target='#req-submitted-popup-broker-reschuled'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        className='invisible d-none btn btn-primary fw-bold px-10 py-3'
      />
      <CustomRequestSuccessfulPopup customID='-broker-reschuled' successMessage={successMsg} close={false} href={'/visits/upcoming'} />
    </>
  )
}
