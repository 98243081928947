import React, { FC, useEffect, useState } from 'react'
import { finalCompletionCertificate, finalCompletionCertificateQuestions } from '../../../../../constants/axios/apis'
import { LeaseDetails } from '../LeaseDetails/LeaseDetails'
import { DocumentsUpload } from './DocumentsUpload/DocumentsUpload'
import { FinalCompletionCertificateModal } from './FinalCompletionCertificateModal/FinalCompletionCertificateModal'
import { useTranslation } from 'react-i18next'

type Props = {
    contract?: any
    stepStatus?: any
    getStepStatus?: any
}

const StepSeven: FC<Props> = ({ contract, getStepStatus, stepStatus }) => {
    const [loading, setLoading] = useState<any>(false)
    const [questions, setQuestions] = useState<any>([])
    const [documents, setDocuments] = useState<any>()
    const [finalCertificate, setFinalCertificate] = useState<any>({
        questions_ids: [],
        answers: [],
    })
    const [isSubmitable, setIsSubmitable] = useState<any>(true)
    const { t } = useTranslation();

    // let questions = [
    //     {
    //         id: 1,
    //         question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     },
    //     {
    //         id: 2,
    //         question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     },
    //     {
    //         id: 3,
    //         question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     },
    //     // {
    //     //     id: 4,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 5,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 6,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 7,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 8,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 9,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 10,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 11,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 12,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 13,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 14,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 15,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 16,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 17,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 18,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 19,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // },
    //     // {
    //     //     id: 20,
    //     //     question: 'All relevant test/commissioning certificates for all electrical, fire an  mechanical systems',
    //     // }
    // ];

    useEffect(() => {
        finalCompletionCertificateQuestions().then((response: any) => {
            if (response.data) {
                setQuestions(response.data)
            }
        })
    }, [])

    useEffect(() => {
        setFinalCertificate((prevState: any) => {
            return {
                ...prevState,
                questions_ids: [
                    ...questions.map((question: any) => question.id)
                ]
            }
        })
    }, [questions])

    const handelFinalSubmit = () => {

        // if (finalCertificate?.questions_ids.length && finalCertificate?.answers.length, documents?.saved) {
        if (documents?.saved) { //TODO
            setLoading(true)
            setIsSubmitable(false)
            var FormData = require('form-data')
            var data = new FormData()
            data.append('property_id', contract?.propertyID)
            data.append('category_id', 34)
            data.append('ADCD_completion_certificate', documents.adcd_certificate)
            data.append('ADDC_inspection_certificate', documents.addc_certificate)
            finalCertificate.questions_ids.forEach((question_id: any, index: any) => {
                data.append(`question_no[${[index]}]`, question_id)
            });
            finalCertificate.answers.forEach((answer: any, index: any) => {
                data.append(`answer[${[index]}]`, answer)
            });

            setTimeout(() => {
                finalCompletionCertificate(data)
                    .then((response: any) => {
                        // setSubmitting(false)
                        if (response.errorCode === 1) {
                            // setSubmitError(response.errorDescription)
                            // setTimeout(() => {
                            // 	setSubmitError('')
                            // }, 5000)
                        } else if (response.errorCode === 0) {
                            // setSubmitting(false)
                            getStepStatus()
                        } else {
                            // setSubmitError("Some error occured")
                            console.log(response, 'response');
                            // setTimeout(() => {
                            // 	setSubmitError('')
                            // }, 5000)
                        }
                        setLoading(false)
                        setIsSubmitable(false)
                    })
                    .catch((e: any) => {
                        console.log(e, 'Error');
                        // setSubmitting(false)
                        // setSubmitError('Some error occured!')
                        // setTimeout(() => {
                        // 	setSubmitError('')
                        // }, 5000)
                    })
            }, 1000)

        } else {

        }
    }

    useEffect(() => {
        if (finalCertificate?.questions_ids.length > 0 && finalCertificate?.answers.length > 0 && documents?.saved) {
            setIsSubmitable(true)
        } else {
            setIsSubmitable(false)
        }
    }, [finalCertificate, documents])

    return (
        <div className="com-card-section w-100 p-7">
            <div className="row">
                <div className="col-md-12 mb-10">
                    <LeaseDetails contract={contract} />
                    <DocumentsUpload contract={contract} setDocuments={setDocuments} stepStatus={stepStatus} />
                    <FinalCompletionCertificateModal setFinalCertificate={setFinalCertificate} questions={questions} />

                    {!stepStatus?.status ? (
                        <>
                            <div className="col-12 text-right">
                                <div className="row justify-content-end">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-end">
                                            <button type='button' onClick={handelFinalSubmit} disabled={!isSubmitable} className='btn btn-primary fw-bold px-10 py-3'>
                                                {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        {t("Please wait...")}{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </div>
    )
};

export { StepSeven }
