import React, { FC } from 'react'
import completedStep from '../../../../../../assets/images/completed-step.png'
import { VehicleRegistrationForm } from './VehicleRegistrationForm';
import { useTranslation } from 'react-i18next'

type Props = {
    parkingSlots?: any
    contract?: any
    getStepStatus?: any
    stepStatus?: any
}

const VehicleRegistrationDetails: FC<Props> = ({ contract, parkingSlots, getStepStatus, stepStatus }) => {
    const VehicleRegistrationForms = parkingSlots?.map((slot: any) => (
        <VehicleRegistrationForm
            key={slot.id}
            slot={slot}
            property_id={contract?.propertyID}
            getStepStatus={getStepStatus}
            stepStatus={stepStatus}
        />
    ))
    const { t } = useTranslation();

    return (
        <div className="row">
            <div className="col-12">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree">
                            {t("Vehicle Registration(Optional)")}
                            {(stepStatus?.vehicleRegistrationStatus) &&
                                <span className='completed-step ps-3 pe-3 d-inline-block'>
                                    <img src={completedStep} alt='complete-step' />
                                </span>}
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordion-step-one">
                        <div className="accordion-body pb-5 pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="accordion move-out-inner-accordion" id="accordion-step-inner-one">
                                        {parkingSlots?.length ? VehicleRegistrationForms : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export { VehicleRegistrationDetails }
