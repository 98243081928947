import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import Home from "../../../../assets/images/my-unit.png";
import { useTranslation } from 'react-i18next'
import { ApplicantsInformation } from '../../../ct-move-in/MoveIn/components/StepSix/PermitToWork/ApplicantsInformation';
import { WorkInformation } from '../../../ct-move-in/MoveIn/components/StepSix/PermitToWork/WorkInformation';
import { SafetyAndSecurityPrecautions } from '../../../ct-move-in/MoveIn/components/StepSix/PermitToWork/SafetyAndSecurityPrecautions';
import { UploadRequiredDocuments } from '../../../ct-move-in/MoveIn/components/StepSix/PermitToWork/UploadRequiredDocuments';
import { generalPermitToWork, getMasterData } from '../../../../constants/axios/apis';

export function CTGeneralPTW({ contract, service }: any) {
    const { t } = useTranslation();
    const history = useHistory()
    const [masterData, setMasterData] = useState<any>([])
    const [loading, setLoading] = useState<any>(false)
    const [workInformation, setWorkInformation] = useState<any>()
    const [applicantInformation, setApplicantInformation] = useState<any>()
    const [safetyPrecatuions, setSafetyPrecatuions] = useState<any>()
    const [documents, setDocuments] = useState<any>()
    const [isSubmitable, setIsSubmitable] = useState<any>(false)

    useEffect(() => {
        getMasterData().then(({ data: masterData }) => {
            setMasterData(masterData)
        })
    }, [])

    const ref1 = useRef<any>()
    const ref2 = useRef<any>()
    const ref3 = useRef<any>()

    const handelFinalSubmit = () => {
        if (workInformation?.saved && applicantInformation?.saved && safetyPrecatuions?.saved && documents?.saved) {
            setLoading(true)
            setIsSubmitable(false)
            var FormData = require('form-data')
            var data = new FormData()
            data.append('contract_id', contract?.id)
            data.append('property_id', contract?.propertyID)
            data.append('category_id', 35)
            data.append('category_type_id', 49)
            data.append('building_name', applicantInformation.building_name)
            data.append('unit_number', applicantInformation.unit_number)
            data.append('applicant_name', applicantInformation.applicant_name)
            data.append('applicant_company_name', applicantInformation.applicant_company_name)
            data.append('applicant_contact_number', applicantInformation.applicant_contact_number)
            data.append('sub_contractor', applicantInformation.sub_contractor)
            data.append('start_date', workInformation.start_date)
            data.append('end_date', workInformation.end_date)
            data.append('start_time', workInformation.start_time)
            data.append('end_time', workInformation.end_time)
            data.append('description', workInformation.description)
            workInformation.permit_type_ids.forEach((permit_id: any, index: any) => {
                data.append(`permit_type_id[${[index]}]`, permit_id)
            });
            safetyPrecatuions.precautions_ids.forEach((precaution_id: any, index: any) => {
                data.append(`safety_prec[${[index]}]`, precaution_id)
            });
            data.append('undertaking_letter', documents.undertaking_letter)
            data.append('risk_assessment', documents.risk_assessment)
            data.append('local_authority_approval', documents.local_authority_approval)
            data.append('emirates_id', documents.emirates_id)
            data.append('trade_license', documents.trade_license)
            data.append('method_of_statement', documents.method_of_statement)
            data.append('crew_list', documents.crew_list)
            data.append('other_documents', documents.other_documents)
            data.append('permit_applicant_name', documents.permit_applicant)
            data.append('terms', documents.terms)
            data.append('signature', documents.signature)

            setTimeout(() => {
                generalPermitToWork(data)
                    .then((response: any) => {
                        // setSubmitting(false)
                        if (response.errorCode === 1) {
                            // error
                        } else if (response.errorCode === 0) {
                            history.push('/my-services/openissues')
                        } else {
                            console.log(response, 'response');
                        }
                        setLoading(false)
                        setIsSubmitable(false)
                    })
                    .catch((e: any) => {
                        console.log(e, 'Error');
                    })
            }, 1000)
        }
    }

    useEffect(() => {
        if (workInformation?.saved && applicantInformation?.saved && safetyPrecatuions?.saved && documents?.saved) {
            setIsSubmitable(true)
        } else {
            setIsSubmitable(false)
        }
    }, [workInformation, applicantInformation, safetyPrecatuions, documents])


    return (
        <div className="com-card-section">
            <div className='row'>
                <div className='col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                        <Link to={'/my-services/35'} className=''>
                            <img src={LeftArrow} alt='Back' className='img-fluid me-5' />
                            <span className='text-black'>{t(service?.name)}</span>
                        </Link>
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-8">
                            <div className="row mb-15 align-items-center">
                                <div className="col-md-4 col-lg-3">
                                    <div className="text-center">
                                        <img src={Home} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-9 pt-5">
                                    <div className="card lease-card refund-block rounded">
                                        <div className="card-body px-5 py-8">
                                            <div className="row">
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Tenant")} </h6>
                                                    <span className="text-black fw-bold font-14"> Mohammed </span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Building Name")} </h6>
                                                    <span className="text-black fw-bold font-14"> Nation Towers </span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Unit Number")} </h6>
                                                    <span className="text-black fw-bold font-14"> NT102 </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="accordion move-out-accordion" id="accordion-step-five">
                                        <ApplicantsInformation setApplicantInformation={setApplicantInformation} ref1={ref1} />
                                        <WorkInformation setWorkInformation={setWorkInformation} contract={contract} ref1={ref1} ref2={ref2} />
                                        <SafetyAndSecurityPrecautions setSafetyPrecatuions={setSafetyPrecatuions} masterData={masterData} ref2={ref2} ref3={ref3} />
                                        <UploadRequiredDocuments setDocuments={setDocuments} ref3={ref3} />

                                        <div className="col-12 text-right">
                                            <div className="row justify-content-end">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-end">
                                                        <button type='button' onClick={handelFinalSubmit} disabled={!isSubmitable} className='btn btn-primary fw-bold px-10 py-3'>
                                                            {!loading && <span className='indicator-label'>Submit</span>}
                                                            {loading && (
                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}