import React from 'react'
import { Link } from 'react-router-dom'
import Home from '../../../../assets/images/my-unit.png'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import moment from 'moment'

export function RentDeduction({ contract }: any) {
  return (
    <div className='com-card-section myunit-page'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/my-services/openissues'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            Rent Reduction
          </h4>
        </div>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>

                <div className='col-md-4 col-lg-3'>
                  <div className='approval-move'>
                    <img src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage} alt='Home' className='img-fluid' />
                  </div>
                </div>
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card bg-white rounded'>
                    <div className='card-body px-0 pt-0 pb-2'>
                      <div className='row'>
                        <div className='col-12 text-end mb-7'>
                          <a href={contract?.contractFile} className='text-decoration-none text-black fw-bold view-all' target="_blank">
                          <span className='download-agrement'> Download </span>
                        </a>
                      </div>
                        <div className='col-md-4 col-6 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Lease Number </h6>
                          <span className='text-black fw-bold'> {contract?.contractNo} </span>
                        </div>
                        <div className='col-md-4 col-6 mb-5'>
                          <div className=''>
                            <h6 className='text-theme-gray mb-3 fw-normal'> Lease Start Date </h6>
                            <span className='text-black fw-bold'>
                              {' '}
                              {moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4 col-6 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Lease End Date </h6>
                          <span className='text-black fw-bold'> {moment(contract?.contractEndDate).format('DD-MM-YYYY')} </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Status </h6>
                          <span className='text-black fw-bold'> {contract?.contractStatus} </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Annual Rent </h6>
                          <span className='text-black fw-bold'> AED {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> Security Deposit </h6>
                          <span className='text-black fw-bold'> AED {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
