import React, { useEffect, useRef, useState } from 'react'
import { sendCardPaymentRequest } from '../../../../constants/axios/apis'
import { useHistory } from 'react-router-dom'
import { RequestFailedPopup } from '../../../SuccessfulPopup/RequestFailedPopup'
import { useTranslation } from 'react-i18next'

export function CardPayment({ details, property_id, vatPercent }: any) {
  // console.log("🚀 ~ file: CardPayment.tsx ~ line 8 ~ CardPayment ~ details", details)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [objectId, setObjectId] = useState<any>('')
  const failRef = useRef<any>()
  const [message, setMessage] = useState<any>('')
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.pathname == '/payments/card-payment' && details?.object_id) {
      setObjectId(details.object_id)
    }
  }, [window.location])

  if (details === undefined) {
    // history.push('/payments/all')
    return <>Loading...</>
  }

  const amountVal = parseFloat(details?.amount)
  const extra = details?.extra_charges_amount ? parseFloat(details.extra_charges_amount) : 0
  const vatVal = ((amountVal + extra) * vatPercent.value) / 100
  const totalVal = amountVal + extra + vatVal

  const sendCardRequest = () => {
    setLoading(true)
    sendCardPaymentRequest(
      details?.offer_id,
      objectId,
      property_id,
      // details?.extra_charges_amount ? details?.amount + details?.extra_charges_amount : details?.amount,
      totalVal,
      details.payment_category,
      details.payment_for,
      details.payment_type,
      details.upcoming_payment_id,
      details.upcoming_payment_plan_id,
      details?.work_order_id ? details?.work_order_id : ''
    ).then((response) => {
      setLoading(false)
      if (response.errorCode === 1) {
        setMessage(response.errorDescription)
        failRef.current.click()
      }
      if (response.data.paymentUrl) {
        window.location.replace(response.data.paymentUrl)
      }
    })
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-5 px-md-15 py-12'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row justify-content-center h-100'>
                    <div className='col-lg-11 col-xl-8 col-12 mb-4'>
                      <div className='card-pay-bg h-100 rounded-10 p-5 p-md-14'>
                        <div className='row'>
                          <div className='col-12'>
                            <h5 className='text-black font-18 fw-bolder mb-8'>{t("Summary")}</h5>
                          </div>
                          <div className='col-12'>
                            <div className='row'>
                              <div className='col-md-8 col-8'>
                                <span className='text-black font-14 d-block mb-4 fw-bold'>
                                  {details?.type} {t("Amount")}
                                </span>
                              </div>
                              <div className='col-md-4 col-4'>
                                <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                  {t("AED")} {details?.amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </span>
                              </div>
                              {details?.extra_charges_title ? (
                                <>
                                  <div className='col-md-8 col-8'>
                                    <span className='text-black font-14 d-block mb-4 fw-bold'>
                                      {details?.extra_charges_title}
                                    </span>
                                  </div>
                                  <div className='col-md-4 col-4'>
                                    <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                      {t("AED")} {details?.extra_charges_amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                              <div className='col-md-8 col-8'>
                                <span className='text-black font-14 d-block mb-4 fw-bold'>
                                  {t("VAT")} ({vatPercent.value}%)
                                </span>
                              </div>
                              <div className='col-md-4 col-4'>
                                <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                  {t("AED")} {vatVal?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </span>
                              </div>
                            </div>
                            <hr className='my-1' />
                          </div>
                          <div className='col-12 my-4'>
                            <div className='row'>
                              <div className='col-md-8 col-8'>
                                <span className='text-black font-14 d-block fw-bold'>{t("Total")}</span>
                                <span className='d-block font-10 mb-2 fw-bold'>{t("(Inc. Taxes)")}</span>
                              </div>
                              <div className='col-md-4 my-auto col-4'>
                                <span className='text-black font-14 d-block mb-2 fw-boldest'>
                                  {/* {t("AED")} {details?.total?.toLocaleString(undefined, { maximumFractionDigits: 2 })} */}
                                  {t("AED")} {totalVal?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='col-12'>
                            <button
                              // data-bs-target='#pay-suc-popup'
                              // data-bs-toggle='modal'
                              type='button'
                              className='btn btn-primary w-100 fw-bold px-10 py-3'
                              onClick={() => sendCardRequest()}
                              disabled={loading || vatPercent.loading}
                            >
                              {loading || vatPercent.loading ? (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  {t("Please wait...")}{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>)
                                :
                                <span className='indicator-label'>{t("Confirm Payment")}</span>
                              }
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <RequestFailedPopup message={message} />
    </div>
  )
}
