import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from './setup/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from './components/layout/core'
import AuthInit from './components/auth/redux/AuthInit'
import { Routes } from './navigation/Routes'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from './constants'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
	const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
  let userDetail: any = {};
  userDetail = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  let lang: number = userDetail?.data?.preferred_language
  i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      lng: lang === 2 ? "ar" : "en",
      fallbackLng: "en",
      detection: {
        order: ["path", "localStorage", "htmlTag", "cookie"],
        caches: ["localStorage", "cookie"], // cache user language on
      },
      backend: {
        loadPath: '/assets/locales/{{lng}}/translation.json',
      },
    });
  const [loading, setLoading] = useState(true)

  let profileData: any = []
  profileData = useSelector<RootState>(({ Profile }) => Profile.profile)
  useEffect(() => {
    if (lang) {
      setLoading(false)
      document.body.dir = lang === 2 ? 'rtl' : 'ltr' //currentLanguage.dir || 'ltr';    

    }
  }, [, lang]);
  // if (accessToken == undefined) { window.location.assign(process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_WEB_URL}` : `${process.env.REACT_APP_WEB_URL_LOCAL}`) }
  // if (loading) { return <LayoutSplashScreen /> }
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
