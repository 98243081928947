/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../helpers'
import { useTranslation } from 'react-i18next'
import { LangSwitch } from './LangSwitch'

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const roles=[4,5,6,7,14,15]
export function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Wrong email format"))
      .min(3, t("Username should contain atleast 3 characters"))
      .max(50, t("Username should contain less than 50 characters"))
      .required(t("User name is required")),
    password: Yup.string()
      .min(3, t("Password should contain atleast 3 characters"))
      .max(20, t("Password should contain less than 20 characters"))
      .required(t("Password is required")),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setStatus()
      setTimeout(() => {
        login(values.email, values.password)
          .then(({data: userData}) => {
            setLoading(false)
            if (userData.errorCode === 1) {
              setLoading(false)
              setSubmitting(false)
              return setStatus(userData.errorDescription)
            }
            if (!roles?.includes(userData?.data?.userProfile?.id_role) ) {
              setLoading(false)
              setSubmitting(false)
              return setStatus(t("Wrong User Profile or user is not a member"))
            }
            dispatch(auth.actions.login(userData.data.access_token))

            const userInfo = {
              ...userData.data.userProfile,
              access_token: userData.data.access_token,
              expires_in: userData.data.expires_in,
              refresh_token: userData.data.refresh_token,
              token_type: userData.data.token_type,
            }

            localStorage.setItem('userDetail', JSON.stringify(userInfo))
            //window.location.reload()
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)
            let catchError = ''
            if (typeof err !== 'string') {
              catchError = t("Server error")
            } else {
              catchError = err
            }
            setStatus(catchError)
          })
      }, 1000)
    },
  })

  const [shown, setShown] = React.useState(false)

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text fw-bold'>{formik.status}</div>
        </div>
      ) : (
        <div></div>
      )}

      <div className="mb-5 d-flex justify-content-center">
        <LangSwitch />
      </div>

      <div className='text-left mb-10'>
        <h1 className='text-theme mb-3 fw-bold font-20'>{t("Sign In")}</h1>
        {/*<div className='text-gray-400 fw-bold fs-4'>*/}
        {/*New Here?{' '}*/}
        {/*<Link to='/auth/registration' className='link-primary fw-bolder'>*/}
        {/*Create an Account*/}
        {/*</Link>*/}
        {/*</div>*/}
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-5 login-main pb-2'>

        <input

          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          placeholder=' '
          type='email'
          name='email'
          autoComplete='off'
        />
        <label className="floating-label">User email</label>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>
              {formik.errors.email}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4 login-password login-main mt-5'>
        <input
          placeholder=' '
          type={shown ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        <label className="floating-label">Password</label>
        <button type='button' className='btn border-0' onClick={() => setShown(!shown)}>
          {shown ? (
            <i className='bi bi-eye font-17'></i>
          ) : (
            <i className='bi bi-eye-slash font-17'></i>
          )}
        </button>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='fv-row d-flex justify-content-end mb-4'>
        <div className='text-right'>
          {/* begin::Label */}
          {/*<label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>*/}
          {/* end::Label */}
          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='text-theme-black font-13 fw-bold'>
            {t("Forgot Password?")}
          </Link>
          {/* end::Link */}
        </div>
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5 font-16 fw-bold'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{t("Sign In")}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {t("Please wait...")}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        {/*<div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>*/}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
        {/*<img*/}
        {/*alt='Logo'*/}
        {/*src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}*/}
        {/*className='h-20px me-3'*/}
        {/*/>*/}
        {/*Continue with Google*/}
        {/*</a>*/}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
        {/*<img*/}
        {/*alt='Logo'*/}
        {/*src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}*/}
        {/*className='h-20px me-3'*/}
        {/*/>*/}
        {/*Continue with Facebook*/}
        {/*</a>*/}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>*/}
        {/*<img*/}
        {/*alt='Logo'*/}
        {/*src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}*/}
        {/*className='h-20px me-3'*/}
        {/*/>*/}
        {/*Continue with Apple*/}
        {/*</a>*/}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
