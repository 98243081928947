import React, { FC } from 'react'
import { LeaseDetails } from '../LeaseDetails/LeaseDetails'
import { DocumentsUpload } from './DocumentsUpload/DocumentsUpload'

type Props = {
    contract?: any
    stepStatus?: any
    getStepStatus?: any
}

const StepThree: FC<Props> = ({ contract, getStepStatus, stepStatus }) => {

    return (
        <div className="com-card-section w-100 p-7">
            <div className="row">
                <div className="col-md-12 mb-10">
                    <LeaseDetails contract={contract} />
                    <DocumentsUpload contract={contract} getStepStatus={getStepStatus} stepStatus={stepStatus} />
                </div>
            </div>
        </div>
    )
};

export { StepThree }
