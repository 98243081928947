export default [
    {
        id:"1",
        no: "01",
        unitcode: "REF-UN-1605604130",
        unitname: "GF0035",
        communityname: "Wilton Park Residencies",
        building: "Wilton Park Residencies 2",
        status: "Handover",
        action: "Handover"
    },
    {
        id:"2",
        no: "02",
        unitcode: "REF-UN-1605671718",
        unitname: "PF0111",
        communityname: "Belgravia Square",
        building: "Belgravia Square 3",
        status: "Under Construction",
        action: "Under Construction"
    },
    {
        id:"3",
        no: "03",
        unitcode: "REF-UN-1606119822",
        unitname: "GP0145",
        communityname: "The Sloan by Belgravia Heights",
        building: "Wilton Park Residencies 3",
        status: "Approval Pending",
        action: "Approval Pending"
    },
];