/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { PageTitle } from '../../components/layout/core'
import { MyServicesList } from './components/MyServicesList/MyServicesList'
import { IssuesList } from './components/IssuesList/IssuesList'
import { OpenIssues } from './components/OpenIssues/OpenIssues'
import { ResolvedIssues } from './components/ResolvedIssues/ResolvedIssues'
import { ContractRenewal } from './add/ContractRenewal/ContractRenewal'
import { ContractDetails } from './add/ContractDetails/ContractDetails'
import { SubmittedModal } from './add/SubmittedModal/SubmittedModal'
import { AccessCard } from './add/AccessCard/AccessCard'
import { ParkingIssue } from './add/ParkingIssue/ParkingIssue'
import { ExtendContract } from './add/ExtendContract/ExtendContract'
import { ExtendContractForm } from './add/ExtendContractForm/ExtendContractForm'
import { EarlyTermination } from './add/EarlyTermination/EarlyTermination'
import { EarlyTerminationForm } from './add/EarlyTerminationForm/EarlyTerminationForm'
import { UndertakingLetter } from './add/UndertakingLetter/UndertakingLetter'
import { RenewalNotice } from './add/RenewalNotice/RenewalNotice'
import { RenewalInformation } from './add/RenewalInformation/RenewalInformation'
import { RenewalNote } from './add/RenewalNote/RenewalNote'
import { RenewalNoticeVacat } from './add/RenewalNoticeVacat/RenewalNoticeVacat'
import { RentDeduction } from './add/RentDeduction/RentDeduction'
import { RentDeductionForm } from './add/RentDeductionForm/RentDeductionForm'
import { RequestSuccessfulPopup } from '../SuccessfulPopup/RequestSuccessfulPopup'
import { RenewalPopup } from '../SuccessfulPopup/RenewalPopup'
import { GeneralPTW } from './add/GeneralPTW/GeneralPTW'
import { GeneralPTWForm } from './add/GeneralPTW/GeneralPTWForm'
import {
  getServiceRequestDetails,
  getServiceRequestsCategories,
  getServicesIssues,
} from '../../constants/axios/apis'
import { useSelector } from 'react-redux'
import { RootState } from '../../constants'
import { MoveIn } from '../move-in/MoveIn'
import { MoveOut } from '../move-out/MoveOut'
import { ServiceRequests } from './components/ServiceRequests/ServiceRequests'
import { AmenityBooking } from './add/AmenityBooking/AmenityBooking'
import { UnitCard } from './preview/UnitCard'
import { ContractRenewalRequest } from './preview/ContractRenewalRequest'
import { AccessCardRequestDetail } from './preview/AccessCardRequestDetail'
import { ParkingIssueRequestDetail } from './preview/ParkingIssueRequestDetail'
import { EarlTerminationRequestDetail } from './preview/EarlTerminationRequestDetail'
import { UndertakingLetterRequestDetail } from './preview/UndertakingLetterRequestDetail'
import { GeneralPTWRequestDetail } from './preview/GeneralPTWRequestDetail'
import { ContractExtension } from './preview/ContractExtension'
import { AmenityBookingInner } from './preview/AmenityBookingInner'
import { ADDCWelcomeLetter } from './preview/ADDCWelcomeLetter'
import { VehicleRegistration } from './preview/VehicleRegistration'
import { RefundRequestDetail } from './preview/RefundRequestDetail'
import { HealthClubRegistration } from './preview/HealthClubRegistration/HealthClubRegistration'
import { AmenitySuccesfullyBooked } from '../SuccessfulPopup/AmenitySuccessfullyBooked'
import { ScheduleMeeting } from './add/ScheduleMeeting/ScheduleMeeting'
import { ScheduleMeetingPreview } from './preview/ScheduleMeetingPreview'
import { Vacate } from './preview/Vacate'
import { RentDeductionPreview } from './preview/RentDeductionPreview'
import { RequestFailedPopup } from '../SuccessfulPopup/RequestFailedPopup'
import { CTPermitToWork } from './components/CTPermitToWork/CTPermitToWork'
import { FireImpairment } from './components/CTPermitToWork/FireImpairment/FireImpairment'
import { RoofTopAccess } from './components/CTPermitToWork/RoofTopAccess/RoofTopAccess'
import { Fitout } from './components/Fitout/Fitout'
import { ChequeReplaceRequest } from './preview/ChequeReplaceRequest'
import { ChequeDelayRequest } from './preview/ChequeDelayRequest'
import { GeneralService } from './add/GeneralService/GeneralService'
import { GeenralService } from './preview/GeneralService'
import { CTGeneralPTW } from './add/CTGeneralPTW/CTGeneralPTWWrapper'
import { NocUnitAlteration } from './components/Owner/NocUnitAlteration'
import { NocSale } from './components/Owner/NocSale'
import { NocUtilities } from './components/Owner/NocUtilities'
import { NocFinalRegistration } from './components/Owner/NocFinalRegistration'
import { NocInitialRegistration } from './components/Owner/NocInitialRegistration'
import { NocTitleDeed } from './components/Owner/NocTitleDeed'
import { OwnerIssues } from './components/Owner/owner-issues/OwnerIssues'
import { ResoledIssues } from './components/Owner/owner-issues/ResoledIssues'

type Props = {
  services?: any
  permitToWorkServices?: any
  openIssues?: any
  resolvedIssues?: any
  contract?: any
  getIssueDetails?: any
  issue?: any
  message?: any
  setMessage?: any
}

const MyServices: FC<Props> = ({ services, permitToWorkServices, openIssues, resolvedIssues, contract, issue, message, setMessage }) =>{
  
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  let history=useHistory();
  const location = useLocation()
  const service_cat_url: any = location.pathname.substring(location.pathname.lastIndexOf("/") + 1, location.pathname.length)
  const whiteList_url = ['openissues', 'resolvedissues', 'preview']

  useEffect(() => {
    let serviceList :any=[]

    // storing services in array
    if(services && Array.isArray(services)){
      services.forEach((element:any) => {
        serviceList.push(`${element.id}`)
        if(element.categoryCode === 'RN'){
          serviceList.push('vacate')
          serviceList.push('contract-renewal')
          serviceList.push('rent-reduction')
          serviceList.push('extend-contract')
        }
        if(element.categoryCode === 'GS'){
          serviceList.push('general')
        }
      });
    }
    const isWhiteListUrl:boolean = whiteList_url.includes(service_cat_url)
    const isAllowedService:boolean = serviceList.includes(service_cat_url);

    if(currentContract?.contractStatus==='Terminated' && services && Array.isArray(services) && !isWhiteListUrl && !isAllowedService){
      history.push('/my-services/openissues')
    }
   }, [services])

  return(
  <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
      <div className='col-xxl-12 mt-0'>
        <Switch>
          <Route path='/my-services/openissues'>
            {/* {contract?.contractStatus != 'Terminated' && */}
              <MyServicesList services={services} />
            {/* } */}
            <IssuesList />
            { currentContract.is_sale === 1 ? 
              <OwnerIssues/>
            :
              <OpenIssues issues={openIssues} />
            }
          </Route>
          <Route path='/my-services/resolvedissues'>
            {/* {contract?.contractStatus != 'Terminated' && */}
              <MyServicesList services={services} />
            {/* } */}
            <IssuesList />
            { currentContract.is_sale === 1 ? 
              <ResoledIssues/>
            :
              <ResolvedIssues issues={resolvedIssues} />
            }
          </Route>
          <Route path='/my-services/contract-renewal'>
            <ContractRenewal contract={contract} />
            <ContractDetails contract={contract} />
            <SubmittedModal />
            <RenewalPopup />
          </Route>
          {/* <Route path='/my-services/access-card'> */}
          <Route path='/my-services/1'>
            <AccessCard
              accessCards={
                services?.find((service: any) => service.id === 1)?.service_category_types
              }
              contract={contract}
            />
            <SubmittedModal />
          </Route>
          {/* <Route path='/my-services/parking-issue'> */}
          <Route path='/my-services/2'>
            <ParkingIssue contract={contract} setMessage={setMessage} />
            <RequestSuccessfulPopup message={message} />
            <RequestFailedPopup message={message} />
          </Route>
          <Route path='/my-services/extend-contract'>
            <ExtendContract contract={contract} />
            <ExtendContractForm contract={contract} setMessage={setMessage} />
            <RequestSuccessfulPopup message={message} />
          </Route>
          {/* <Route path='/my-services/early-termination'> */}
          <Route path='/my-services/3'>
            <EarlyTermination contract={contract} />
            <EarlyTerminationForm contract={contract} setMessage={setMessage} />
            <RequestSuccessfulPopup message={message} />
          </Route>
          {/* <Route path='/my-services/undertaking-letter'> */}
          <Route path='/my-services/4'>
            <UndertakingLetter contract={contract} setMessage={setMessage} />
            <RequestSuccessfulPopup message={message} />
          </Route>
          <Route path='/my-services/5'>
            <MoveIn />
          </Route>
          <Route path='/my-services/6'>
            <MoveIn step={2} />
          </Route>
          <Route path='/my-services/7'>
            <MoveIn step={3} />
          </Route>
          <Route path='/my-services/8'>
            <MoveIn step={4} />
          </Route>
          <Route path='/my-services/9'>
            <MoveIn step={5} />
          </Route>
          {/* <Route path='/my-services/general-ptw'> */}
          <Route path='/my-services/14'>
            <GeneralPTW contract={contract} />
            <GeneralPTWForm contract={contract} setMessage={setMessage} />
            <RequestSuccessfulPopup message={message} />
            <RequestFailedPopup message={message} />
          </Route>
          <Route path='/my-services/15'>
            <MoveOut />
          </Route>
          <Route path='/my-services/16'>
            <MoveOut step={3} />
          </Route>
          <Route path='/my-services/17'>
            <MoveOut step={2} />
          </Route>
          <Route path='/my-services/19'>
            <MoveOut step={4} />
          </Route>
          <Route path='/my-services/18'>
            <RenewalNotice contract={contract} />
            <RenewalInformation contract={contract} />
            <RenewalNote contract={contract} />
            <RequestSuccessfulPopup />
          </Route>
          <Route path='/my-services/vacate'>
            <RenewalNotice contract={contract} />
            <RenewalNoticeVacat contract={contract} setMessage={setMessage} />
            <RequestSuccessfulPopup message={message} />
            <RequestFailedPopup message={message} />
          </Route>
          <Route path='/my-services/rent-reduction'>
            <RentDeduction contract={contract} />
            <RentDeductionForm contract={contract} setMessage={setMessage} />
            <RequestSuccessfulPopup message={message} />
          </Route>
          <Route path='/my-services/service-requests'>
            <ServiceRequests />
          </Route>
          {/* <Route path='/my-services/amenity-booking'> */}
          <Route path='/my-services/20'>
            <AmenityBooking contract={contract} />
            <AmenitySuccesfullyBooked />
          </Route>
          <Route path='/my-services/25'>
            <RenewalNotice contract={contract} />
            <RenewalNoticeVacat contract={contract} setMessage={setMessage} />
            <RequestSuccessfulPopup message={message} />
            <RequestFailedPopup message={message} />
          </Route>
          <Route path='/my-services/27'>
            <RentDeduction contract={contract} />
            <RentDeductionForm contract={contract} setMessage={setMessage} />
            <RequestSuccessfulPopup message={message} />
          </Route>
          <Route path='/my-services/35'>
            <CTPermitToWork services={permitToWorkServices} />
          </Route>
          {permitToWorkServices?.length && permitToWorkServices
            .filter((item: any) => item?.id !== 52 && item?.id !== 53)    // Filter out Fire Impairment & Access to Rooftop
            .map((item: any, index: number) => {
              return (
                <Route
                  key={index}
                  path={`/my-services/${item.id}`}
                >
                  <CTGeneralPTW contract={contract} service={item} />
                </Route>
              )
            })}
          <Route path='/my-services/52'>
            <RoofTopAccess contract={contract} />
          </Route>
          <Route path='/my-services/53'>
            <FireImpairment contract={contract} />
          </Route>
          <Route path='/my-services/general'>
            <GeneralService
              genaralCategories={
                services?.find((service: any) => service.categoryCode === "GS")?.service_category_types
              }//id-46
              contract={contract}
              setMessage={setMessage}
            />
            <RequestSuccessfulPopup message={message} />
            <RequestFailedPopup message={message} />
          </Route>
          <Route path='/my-services/fitout'>
            <Fitout />
          </Route>
          <Route path='/my-services/preview'>
            <UnitCard contract={contract} selectedIssue={issue} />
            <AccessCardRequestDetail selectedIssue={issue} />
            <ParkingIssueRequestDetail selectedIssue={issue} />
            <EarlTerminationRequestDetail contract={contract} selectedIssue={issue} />
            <UndertakingLetterRequestDetail selectedIssue={issue} />
            <GeneralPTWRequestDetail selectedIssue={issue} />
            <AmenityBookingInner selectedIssue={issue} />
            <ContractRenewalRequest selectedIssue={issue} contract={contract} />
            <ContractExtension selectedIssue={issue} contract={contract} />
            <ADDCWelcomeLetter selectedIssue={issue} contract={contract} />
            <VehicleRegistration selectedIssue={issue} contract={contract} />
            <HealthClubRegistration selectedIssue={issue} contract={contract} />
            <RefundRequestDetail selectedIssue={issue} contract={contract} />
            <ScheduleMeetingPreview selectedIssue={issue} />
            <Vacate selectedIssue={issue} contract={contract} />
            <RentDeductionPreview selectedIssue={issue} />
            <GeenralService selectedIssue={issue} />
            {/* <RenewalNoticeDetail selectedIssue={issue} contract={contract} /> */}
            {/* <RenewalNotices /> */}
            <ChequeReplaceRequest selectedIssue={issue} />
            <ChequeDelayRequest selectedIssue={issue} />
          </Route>
          {/* Owner Service Request*/}
          <Route path='/my-services/noc-unit-alteration'>
            <MyServicesList services={services} />
            <NocUnitAlteration />
          </Route>
          <Route path='/my-services/noc-sale'>
            <MyServicesList services={services} />
            <NocSale />
          </Route>
          <Route path='/my-services/noc-utilities'>
            <MyServicesList services={services} />
            <NocUtilities />
          </Route>
          <Route path='/my-services/noc-initial-registration'>
            <MyServicesList services={services} />
            <NocInitialRegistration />
          </Route> 
          <Route path='/my-services/noc-final-registration'>
            <MyServicesList services={services} />
            <NocFinalRegistration />
          </Route> 
          <Route path='/my-services/noc-title-deed'>
            <MyServicesList services={services} />
            <NocTitleDeed />
          </Route> 
        </Switch>
        <ScheduleMeeting contract={contract} />
      </div>
    </div>
  </>
)}

const MyServicePage: FC = () => {
  const location = useLocation()
  const [services, setServices] = useState<any>()
  const [permitToWorkServices, setPermitToWorkServices] = useState<any>([])
  const [openIssues, setOpenIssues] = useState<any>()
  const [resolvedIssues, setResolvedIssues] = useState<any>()
  const [selectedIssue, setSelectedIssue] = useState<any>(null)
  const [issue, setIssue] = useState<any>(null)
  const [message, setMessage] = useState<any>()
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)

  let userDetail = localStorage.getItem('userDetail')
  useEffect(() => {
    getServiceRequestsCategories(currentContract?.property_search_type, currentContract, userDetail && JSON.parse(userDetail)).then(response => {
      let categories = response.data?.filter((category: any) => category?.is_visible === 1 || category?.categoryCode === 'GS')
      setServices(categories)
    })
    getServicesIssues('open').then(({ data }) => {
      let sortedData = data
        .filter((service: any) => service?.property_id === currentContract?.propertyID)
        .sort((a: any, b: any) => {
          return a.id - b.id
        })
        .reverse()
      setOpenIssues(sortedData)
    })
    getServicesIssues('resolved').then(({ data }) => {
      let sortedData = data
        .filter((service: any) => service?.property_id === currentContract?.propertyID)
        .sort((a: any, b: any) => {
          return a.id - b.id
        })
        .reverse()
      setResolvedIssues(sortedData)
    })
  }, [location.key, currentContract])

  useEffect(() => {
    setIssue(null)
    if (selectedIssue) {
      getServiceRequestDetails(selectedIssue.id, selectedIssue.category_id).then((response) => {
        if (response.data) {
          setIssue(response.data[0])
        }
      })
    }
  }, [selectedIssue])

  useEffect(() => {
    if (Array.isArray(services) && services.length) {
      setPermitToWorkServices(services?.find((service: any) => service.id === 35)?.service_category_types)
    } else {
      setPermitToWorkServices([])
    }
  }, [services])

  useEffect(() => {
    setSelectedIssue(null)
    if (location.state) {
      setSelectedIssue(location.state)
    }
  }, [location.key])

  return (
    <>
      <PageTitle breadcrumbs={[]}> My Services </PageTitle>
      <MyServices
        services={services}
        permitToWorkServices={permitToWorkServices}
        openIssues={openIssues}
        resolvedIssues={resolvedIssues}
        contract={currentContract}
        issue={issue}
        message={message}
        setMessage={setMessage}
      />
    </>
  )
}

export { MyServicePage }
