import LeftArrow from '../../assets/images/Left_Arrow.png'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function OwnerDetails() {
  
  return (
    <div className='com-card-section'>
        <div className='row'>          
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body px-7 py-6'>
                  <div className='row'>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                          Name
                      </h6>
                      <span className='text-black fw-bold font-14'>
                          AL MAJED PROPERTY CO.LLC
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <div className=''>
                        <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                        Email
                        </h6>
                        <span className='text-black fw-bold font-14'>
                        nael.abdulaziz@almajid.com
                        </span>
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Phone
                      </h6>
                      <span className='text-black fw-bold font-14'>
                      971567559558
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Share %
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        60
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Emirates ID Number
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        787-1979-1234567-1
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Emirates ID Expiry
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        12-10-2028
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Passport Number
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        600-522-222
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Passport Expiry
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        06-04-2024
                      </span>
                    </div>
                  </div>
                  <h3 className="my-7">Joint Owner</h3>
                  <div className='row'>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                          Name
                      </h6>
                      <span className='text-black fw-bold font-14'>
                          Abdul Rafic
                      </span>
                    </div>
                    {/* <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <div className=''>
                        <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                        Email
                        </h6>
                        <span className='text-black fw-bold font-14'>
                          abdulaziz@almajid.com
                        </span>
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Phone
                      </h6>
                      <span className='text-black fw-bold font-14'>
                      971567559558
                      </span>
                    </div> */}
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Share %
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        40
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Emirates ID Number
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        787-1979-1234567-5
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Emirates ID Expiry
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        12-10-2028
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Passport Number
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        600-522-245
                      </span>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                      <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                      Passport Expiry
                      </h6>
                      <span className='text-black fw-bold font-14'>
                        06-04-2024
                      </span>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        </div>
    </div>
  )
}
