import React, { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import completedStep from '../../../../../../assets/images/completed-step.png'
import { useTranslation } from 'react-i18next'

type Props = {
    setPermitRequirements?: any
    masterData?: any
    stepStatus?: any
    ref1?: any
    ref2?: any
}

const SelectPermitRequirements: FC<Props> = ({ setPermitRequirements, masterData, stepStatus, ref1, ref2 }) => {
    const [requirementsOptions, setRequirementsOptions] = useState<any>()
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const { t } = useTranslation();

    useEffect(() => {
        let requirements = masterData
            .filter((item: any) => item.id === 35)
            .flatMap((data: any) => data.master_data)
        setRequirementsOptions(requirements)
    }, [masterData])

    const initialValues = {
        requirements_ids: [],
    }

    const handelSubmit = () => {
        setSaved(false)
        setPermitRequirements(null)
        formik.handleSubmit()
    }

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        onSubmit: (values) => {
            setPermitRequirements({
                requirements_ids: values.requirements_ids,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref2.current.click()
        },
    })

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setPermitRequirements((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    return (
        <div className="row">
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
            >
                <div className="col-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingEight">
                            <button className="accordion-button collapsed" ref={ref1} type="button" data-bs-toggle="collapse" data-bs-target="#permit-req">
                                {t("Select Permit Requirements")}
                                {(saved) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </h2>
                        <div id="permit-req" className="accordion-collapse collapse" data-bs-parent="#accordion-step-five">
                            {stepStatus?.helipadAccessRequestStatus ? (
                                <>
                                    <div className="accordion-body pb-5 pt-3">
                                        <div className="row form-add-design">
                                            {requirementsOptions ? requirementsOptions?.map((option: any) => (
                                                <div key={option.value} className="col-md-4 col-12">
                                                    <div className="form-check custom mb-8">
                                                        <input
                                                            className='form-check-input'
                                                            name='requirements_ids'
                                                            value={option.value}
                                                            type='checkbox'
                                                            id={option.value}
                                                            disabled={true}
                                                            checked={stepStatus?.data?.helipadAccess?.details?.hra_requirement?.includes(String(option?.value))}
                                                        />
                                                        <label className="form-check-label font-14 text-black fw-bolder mx-2" htmlFor={option?.value}>
                                                            {option?.name}
                                                        </label>
                                                    </div>
                                                </div>
                                            )) : ''}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="accordion-body pb-5 pt-3">
                                        <div className="row form-add-design">
                                            {requirementsOptions ? requirementsOptions?.map((option: any) => (
                                                <div key={option.value} className="col-md-4 col-12">
                                                    <div className="form-check custom mb-8">
                                                        <input
                                                            className='form-check-input'
                                                            name='requirements_ids'
                                                            value={option.value}
                                                            type='checkbox'
                                                            id={option.value}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <label className="form-check-label font-14 text-black fw-bolder mx-2" htmlFor={option?.value}>
                                                            {option?.name}
                                                        </label>
                                                    </div>
                                                </div>
                                            )) : ''}
                                            <div className="col-12 text-right">
                                                <div className="row justify-content-end">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end">
                                                            <button type='button' onClick={handelSubmit} disabled={!dirty} className='btn btn-primary fw-bold px-10 py-3'>
                                                                {saved ? t("Saved") : t("Save")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export { SelectPermitRequirements }
