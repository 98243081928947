import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '@mui/x-data-grid'
import TableData from './TableData'
import TableDataOccup from './TableDataOccup'
import { PaymentActionMenu } from './PaymentActionMenu'
import { PaymentClearedAction } from './PaymentClearedAction'

export function PaymentsNew() {
  const { t } = useTranslation()
  let unitDetail = localStorage.getItem('unitDetail')
  const columns = [
    // {
    //   field: 'no',
    //   headerName: t('No'),
    //   sortable: false,
    //   disableColumnMenu: true,
    //   flex:1,
    // },
    {
      field: 'percentageofcompletion',
      headerName: t('Completion (%)'),
      flex: 1,
    },
    // {
    //   field: 'paymentmode',
    //   headerName: t('Payment Mode'),
    //   width: 200,
    // },
    {
      field: 'amount',
      headerName: t('Amount (AED)'),
      flex: 1,
    },
    {
      field: 'duedate',
      headerName: t('Paid On/Due Date'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <span className={params.value === 'Paid' ? 'text-success' : params.value === 'Pending' ? 'text-danger' : ''} title={params.value}>{params.value}</span>
        )
      }
    },
    {
      field: 'action',
      headerName: t('Action'),
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status == 'Upcoming' || params.row.status == 'Pending' ? (
              <PaymentActionMenu />
            ) : (
              <PaymentClearedAction />
            )}
          </>
        )
      },
    },
  ]

  const columns2 = [
    // {
    //   field: 'no',
    //   headerName: t('No'),
    //   sortable: false,
    //   disableColumnMenu: true,
    //   flex:1,
    // },
    {
      field: 'paymentmode',
      headerName: t('Payment Mode'),

      width: 200,
    },
    {
      field: 'amount',
      headerName: t('Amount (AED)'),

      width: 200,
    },
    {
      field: 'duedate',
      headerName: t('Paid On/Due Date'),

      width: 200,
    },
    {
      field: 'payment_description',
      headerName: t('Payment Description'),

      width: 400,
    },
    {
      field: 'status',
      headerName: t('Status'),

      width: 200,
      renderCell: (params: any) => {
        return (
          <span className={params.value === 'Paid' ? 'text-success' : params.value === 'Upcoming' ? 'text-danger' : ''} title={params.value}>{params.value}</span>
        )
      }
    },
    {
      field: 'action',
      headerName: t('Action'),
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status == 'Upcoming' || params.row.status == 'Pending' ? (
              <PaymentActionMenu />
            ) : (
              <PaymentClearedAction />
            )}
          </>
        )
      },
    },
  ]

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <div className='data_table'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='table-responsive'>
                      <div style={{ height: 400, width: '100%' }}>
                        {unitDetail === 'Under Construction' ?
                          <DataGrid
                            rows={TableData}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                          />
                          :
                          <DataGrid
                            rows={TableDataOccup}
                            columns={columns2}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
