const notificationType = [
    {
        type: 'user_registration',
        button1: '',
        button2: '',
        link: '*userRegistration',
        notificationName: 'userRegistration'
    },
    {
        type: 'edit_profile',
        button1: 'View',
        button2: '',
        link: '*profile',
        notificationName: 'edit_profile'
    },
    {
        type: 'create_announcements',
        button1: 'View',
        button2: '',
        link: '*announcements',
        notificationName: 'announcements',
        permission: ['PT', 'T']
    },
    {
        type: 'rent_payment_done',
        button1: 'View',
        button2: '',
        link: '*pt-activity/my-offer',
        notificationName: 'offer',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'sd_payment_done',
        button1: 'View',
        button2: '',
        link: '*pt-activity/my-offer',
        notificationName: 'offer',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'make_offer',
        button1: 'View',
        button2: '',
        link: '*offers',
        notificationName: 'make_offer',
        permission: ['BR']
    },
    {
        type: 'accept_offer',
        button1: 'View Offer',
        button2: '',
        link: '*pt-activity/my-offer',
        notificationName: 'offer',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'offer_expired',
        button1: 'View Offer',
        button2: '',
        link: '*pt-activity/my-offer',
        notificationName: 'offer',
        permission: ['BR']
    },
    {
        type: 'approve_contract',
        button1: 'View Contract',
        button2: '',
        link: '*pt-activity/my-contracts-details',
        notificationName: 'contract',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'create_contract',
        button1: 'View Contract',
        button2: '',
        link: '*pt-activity/my-contracts-details',
        notificationName: 'contract',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'contract_sign',
        button1: 'Sign Contract',
        button2: '',
        link: '*pt-activity/my-contracts-details',
        notificationName: 'contract',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'submit_contract_sign',
        button1: '',
        button2: 'Download',
        link: '*pt-activity/my-contracts-details',
        notificationName: 'contract',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'contract_generated',
        button1: 'View Contract',
        button2: 'Download',
        link: '*pt-activity/my-contracts-details',
        notificationName: 'contract',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'contract_approved',
        button1: 'View Contract',
        button2: '',
        link: '*pt-activity/my-contracts-details',
        notificationName: 'contract',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'visit_scheduled',
        button1: 'View',
        button2: 'Reschedule',
        link: '*pt-activity/my-visit/upcoming',
        notificationName: 'visit_scheduled',
        permission: ['PT', 'T']
    },
    {
        type: 'upcoming_visit',
        button1: 'View',
        button2: '',
        link: '*pt-activity/my-visit/upcoming',
        notificationName: 'upcoming_visit',
        permission: ['BR']
    },
    // {
    //     type: 'visit_schedule',
    //     button1: 'View',
    //     button2: 'Reschedule',
    //     link: '*pt-activity/my-visit/upcoming',
    //     notificationName: 'visit_scheduled',
    //     permission: ['PT', 'T','BR']
    // },
    {
        type: 'schedule_a_visit',
        button1: 'View',
        button2: 'Reschedule',
        link: '*visits/upcoming',
        notificationName: 'schedule_a_visit',
        permission: ['BR']

    },
    {
        type: 'reschedule_visit',
        button1: 'View',
        button2: '',
        link: '*my-visit/upcoming',
        notificationName: 'visit_scheduled',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'cancel_visit',
        button1: 'View',
        button2: '',
        link: '*my-visit/visited',
        notificationName: 'cancel_visit',
        permission: ['PT', 'T', 'BR']
    },
    {
        type: 'extend_offer',
        button1: 'View',
        button2: '',
        link: '*pt-activity/my-offer',
        notificationName: 'extend_offer',
        permission: ['PT', 'T']
    },
    {
        type: 'make_offer',
        button1: 'View',
        button2: '',
        link: '*pt-activity/my-offer',
        notificationName: 'make_offer',
        permission: ['PT', 'T']
    },
    // *Service Requests
    {
        type: 'vacate',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'cheque_replace_submit',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'extend_contract_request',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'cheque_delay_requset',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'move_in_inspection_complete',
        button1: '',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'access_card_reg',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'meeting_schedule',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'rent_reduction_request',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'move_out_approval',
        button1: 'View',
        button2: '',
        link: '*my-services/16',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'both_clearnace_other_charge_payment_done',
        button1: 'View',
        button2: '',
        link: '*payments/service-charges',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'parking_issue',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'move_in_inspection_req',
        button1: '',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'move_out_inspection',
        button1: 'View',
        button2: '',
        link: '*my-services/15',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'lease_termination',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'welcome_letter',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'under_taking_letter_req',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'vehicle_reg',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'access_card_reqest_movein',
        button1: '',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'renew_contract_request',
        button1: 'View',
        button2: '',
        link: '*my-services/contract-renewal',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'refund_request',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'refund_request',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'extend_contract_approve',
        button1: 'Proceed to payment',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'extra_access_card_approved',
        button1: 'Proceed to payment',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },
    {
        type: 'extend_contract',
        button1: 'View',
        button2: '',
        link: '*my-services/preview',
        notificationName: 'service_access',
        permission: ['PT', 'T']
    },

    //* Payments
    {
        type: 'upcoming_payment_alert',
        button1: 'View',
        button2: '',
        link: '*payments/all',
        notificationName: 'payments',
        permission: ['PT', 'T']
    },

    // *Maintenance Requests
    {
        type: 'maintenance_request_feedback',
        button1: 'View',
        button2: '',
        link: '*maintenance/resolvedissues',
        notificationName: 'maintenance',
        permission: ['PT', 'T']
    },
    {
        type: 'maintenance_resolved',
        button1: 'View',
        button2: 'Rate-Us',
        link: '*maintenance/resolvedissues',
        notificationName: 'maintenance',
        permission: ['PT', 'T']
    },
    {
        type: 'maintenance_request',
        button1: 'View',
        button2: '',
        link: '*maintenance/issues-view',
        notificationName: 'maintenance',
        permission: ['PT', 'T']
    },
    {
        type: 'tenant_not_available',
        button1: 'View',
        button2: '',
        link: '*maintenance/issues-view',
        notificationName: 'maintenance',
        permission: ['PT', 'T']
    },
    {
        type: 'reschedule_maintenance_request_tenant',
        button1: 'View',
        button2: '',
        link: '*maintenance/issues-view',
        notificationName: 'maintenance',
        permission: ['PT', 'T']
    },

    // Broker Admin Visits
    {
        type: 'schedule_a_visit',
        button1: 'View',
        button2: 'Reschedule',
        link: '*visits/upcoming',
        notificationName: 'schedule_a_visit',
        permission: ['BA']

    },
    {
        type: 'upcoming_visit',
        button1: 'View',
        button2: '',
        link: '*visits/upcoming',
        notificationName: 'upcoming_visit',
        permission: ['BA']

    },
    {
        type: 'reschedule_visit',
        button1: 'View',
        button2: '',
        link: '*visits/upcoming',
        notificationName: 'reschedule_visit',
        permission: ['BA']
    },
    {
        type: 'cancel_visit',
        button1: 'View',
        button2: '',
        link: '*visits/closed',
        notificationName: 'cancel_visit',
        permission: ['BA']
    },
    {
        type: 'visit_completed',
        button1: 'View',
        button2: '',
        link: '*visits/closed',
        notificationName: 'visit_completed',
        permission: ['BA']
    },

    // Broker Admin Offers
    {
        type: 'make_offer',
        button1: 'View',
        button2: '',
        link: '*offers',
        notificationName: 'make_offer',
        permission: ['BA'] //Todo
    },
    {
        type: 'extend_offer',
        button1: 'View',
        button2: '',
        link: '*offers',
        notificationName: 'extend_offer',
        permission: ['BA'] // Todo
    },
    {
        type: 'offer_expired',
        button1: 'View',
        button2: '',
        link: '*offers',
        notificationName: 'offer_expired',
        permission: ['BA'] // Todo
    },
    {
        type: 'sd_payment_done',
        button1: 'View',
        button2: '',
        link: '*offers',
        notificationName: 'sd_payment_done',
        permission: ['BA'] // Todo
    },
    {
        type: 'rent_payment_done',
        button1: 'View',
        button2: '',
        link: '*offers',
        notificationName: 'rent_payment_done',
        permission: ['BA'] // Todo
    },
    {
        type: 'create_contract',
        button1: 'View',
        button2: '',
        link: '*offers',
        notificationName: 'create_contract',
        permission: ['BA'] // Todo
    },
    {
        type: 'approve_contract',
        button1: 'View',
        button2: '',
        link: '*offers',
        notificationName: 'approve_contract',
        permission: ['BA'] // Todo
    },
    // Contracts
    {
        type: 'submit_contract_sign',
        button1: 'Sign Contract',
        button2: '',
        link: '*contracts/uninvoiced',
        notificationName: 'submit_contract_sign',
        permission: ['BA']
    },

    // Alerts
    {
        type: 'create_property_alert',
        button1: 'View',
        button2: '',
        link: '*pt-alerts',
        notificationName: 'create_property_alert',
        permission: ['BA', 'BR']
    },

    // Team Member Broker Admin
    {
        type: 'teamMember_added',
        button1: 'View',
        button2: '',
        link: '*SingleBroker/data/details',
        notificationName: 'teamMember_added',
        permission: ['BA']
    },
    {
        type: 'approved_techinican',
        button1: 'View',
        button2: '',
        link: '*SingleBroker/data/details',
        notificationName: 'approved_techinican',
        permission: ['BA']
    },

    // Statements 
    {
        type: 'add_invoice',
        button1: 'View',
        button2: '',
        link: '*statements/inprogress-invoices',
        notificationName: 'add_invoice',
        permission: ['BA', 'BR']
    },
    {
        type: 'invoice_followup_approved',
        button1: 'View',
        button2: '',
        link: '*statements/approved-invoices',
        notificationName: 'invoice_followup_approved',
        permission: ['BA', 'BR']
    },
    {
        type: 'reject_request',
        button1: 'View',
        button2: '',
        link: '*statements/rejected-invoices',
        notificationName: 'reject_request',
        permission: ['BA', 'BR']
    },
    //CT notifs
    {
        // directRedirection: true,
        type: 'promotion_rmi_fromMA',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'promotion_rmi_fromMA',
        // stateName: 'marketData',
        // keyname: 'promotion_id',
        // linkPath: '/promotions/view',
        permission: ['T']
    },
    {
        type: 'event_approve',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'event_approve',
        permission: ['T']
    },
    {
        type: 'offer_approve',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'offer_approve',
        permission: ['T']
    },
    {
        type: 'voucher_approve',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'voucher_approve',
        permission: ['T']
    },
    {
        type: 'event_reject',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'event_reject',
        permission: ['T']
    },
    {
        type: 'reject_offer',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'reject_offer',
        permission: ['T']
    },
    {
        type: 'voucher_reject',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'voucher_reject',
        permission: ['T']
    },
    {
        type: 'create_voucher',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'create_voucher',
        permission: ['T']
    },
    {
        type: 'create_offer',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'create_offer',
        permission: ['T']
    },
    {
        type: 'create_events',
        button1: 'View',
        button2: '',
        link: '*promotions/view',
        notificationName: 'create_offer',
        permission: ['T']
    },
]

export const getNotificationListTypes = () => {
    return notificationType
}