import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {TenantsTab} from'../../Components/TenantTab'

export function StepOne() {
    return (
        <div className="">
            <TenantsTab />
        </div>
    );
}