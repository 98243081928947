import React, { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../components/layout/core'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PTMarketPlaceTab } from './PTMarketPlaceTab/PTMarketPlaceTab';
import { EventsWrapper } from './components/Events/EventsWrapper';
import { OffersWrapper } from './components/Offers/OffersWrapper';
import { VouchersWrapper } from './components/Vouchers/VouchersWrapper';
import { RedeemedWrapper } from './components/Redeemed/RedeemedWrapper';
import { getMasterData } from '../../constants/axios/apis';
import { RedeemOfferPopup } from '../market-place/components/RedeemOfferPopup/RedeemOfferPopup';
import { RedeemOfferSubmitPopup } from '../market-place/components/RedeemOfferSubmitPopup/RedeemOfferSubmitPopup';
import { RedeemMarketPlacePopup } from './components/RedeemMarketPlace/RedeemMarketPlacePopup';
import { RedeemMarketPlaceSubmitPopup } from './components/RedeemMarketPlace/RedeemMarketPlaceSubmitPopup';
import { MarketPlaceDetails } from './components/MarketPlaceDetails/MarketPlaceDetails';
import { NoDataFoundMarketPlace } from '../../constants/generics/customActios';
import { useTranslation } from 'react-i18next'
import { t } from 'i18next';

let userEmail = "";
let userDetail = localStorage.getItem("userDetail");
if (userDetail) {
    userEmail = JSON.parse(userDetail).email;
};

type Props  = {
    categoryList:any
}

const PTMarketPlace: FC<Props> = ({categoryList}) => (
    <>
        <div className='row gy-5 g-xl-8 dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                {/* <Switch>
                    <Route path='/marketPlace/events'>
                        <PTMarketPlaceTab />
                        <EventsWrapper categoryList={categoryList}/>
                    </Route>
                    <Route path='/marketPlace/offers'>
                        <PTMarketPlaceTab />
                        <OffersWrapper categoryList={categoryList}/>
                    </Route>
                    <Route path='/marketPlace/vouchers'>
                        <PTMarketPlaceTab />
                        <VouchersWrapper categoryList={categoryList}/>
                    </Route>
                    <Route path='/marketPlace/redeemed'>
                        <PTMarketPlaceTab />
                        <RedeemedWrapper categoryList={categoryList}/>
                    </Route>
                    <Route path='/marketPlace/marketPlaceDetailView'>
                        <MarketPlaceDetails /> */}
                        {/* <RedeemMarketPlacePopup /> */}
                        {/* <RedeemMarketPlaceSubmitPopup /> */}
                    {/* </Route>
                </Switch>*/}
            </div> 
            <div className="col-md-12 mt-0">
                <h4 className="card-title font-18 text-black mb-6">
                    {t("Market Place")}
                </h4>
            </div>
            <div className="col-12 mt-0">
                <div className='card h-100'>
                    <div className='card-body p-7'> 
                        <div className="row"> 
                            <NoDataFoundMarketPlace /> 
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </>
);


const PTMarketPlaceWrapper: FC = () => {
    const [categoryList, setCategoryList] = useState<any>([]);

    useEffect(()=>{
        getMasterData()
            .then(({data}) => {
            const dataList = data?.find((el: any) => el.category_name === 'market_place')
            const formatedList = {
                dining_id: dataList?.master_data?.find((el:any)=>el.name === "Dining")?.value,
                lifestyle_id: dataList?.master_data?.find((el:any)=>el.name === "Lifestyle")?.value,
                entertainment_id: dataList?.master_data?.find((el:any)=>el.name === "Entertainment")?.value
            }
            setCategoryList(formatedList)
        })
    },[])
    const { t } = useTranslation();

    return (
        <>
            <PageTitle breadcrumbs={[]}>Market Place</PageTitle>
            <PTMarketPlace categoryList={categoryList}/>
        </>
    )
};

export { PTMarketPlaceWrapper }
