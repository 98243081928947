import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '../../constants'
import * as profile from '../../constants/redux/Sagas/ProfileSaga'
import { useTranslation } from 'react-i18next'
import { CustomLoader1 } from '../../constants/generics/customActios'

const ProfileDetails = (props: any) => {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }
  let profileData: any = []
  profileData = useSelector<RootState>(({ Profile }) => Profile.profile)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(profile.actions.profile())
  }, [])

  const { t } = useTranslation();
  if (profileData == undefined) { return <CustomLoader1 height={50} /> }
  return (
    <>
      {/* <h3 className="general-title">General Details</h3> */}

      <div className='row profile-new-details'>
        <div className='col-12'><h3 className="general-title">General Details</h3></div>
        <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
          <div className=''>
            <label className=''>{t("Emirates Id")}</label>
            <span className=''>{profileData?.data?.emirates_id ? profileData?.data?.emirates_id : '-'}</span>
          </div>
        </div>
        <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
          <div className=''>
            <label className=''>{t("Alternate Contact No.")}</label>
            <span className=''>{profileData?.data?.alternate_no ? profileData?.data?.alternate_no : '-'}</span>
          </div>
        </div>
        <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
          <div className=''>
            <label className=''>{t("Preferred Language")}</label>
            <span className=''> {profileData?.data?.preferred_language === 1 ? 'English' : 'العربية'}</span>
          </div>
        </div>
        <div className='col-12 mt-5'><h3 className="general-title">Emergency Details</h3></div>
        <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
          <div className=''>
            <label className=''>{t("Contact Name")}</label>
            <span className=''>{profileData?.data?.emergency_contact_name ? profileData?.data?.emergency_contact_name : '-'}</span>
          </div>
        </div>
        <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
          <div className=''>
            <label className=''>{t("Contact Number")}</label>
            <span className=''>{profileData?.data?.emergency_contact_no ? profileData?.data?.emergency_contact_no : '-'}</span>
          </div>
        </div>

        {/* <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
              <label className=''>{t("Email Address")}</label>
              <span className=''>{userEmail}</span>
            </div>
          </div> */}

        {/* <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
              <label className=''>{t("Alternate Contact No.")}</label>
              <span className=''>
                {profileData?.data?.alternate_no}
              </span>
            </div>
          </div> */}

        {/* <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
              <label className=''>{t("Emergency Contact Name")}</label>
              <span className=''>
                {profileData?.data?.emergency_contact_name}
              </span>
            </div>
          </div> */}
        {/* <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
            <div className=''>
              <label className=''>{t("Emergency Contact Number")}</label>
              <span className=''>{profileData?.data?.emergency_contact_no}</span>
            </div>
          </div> */}
        <div className='col-12 mt-5'><h3 className="general-title">Subscription Details</h3></div>
        <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
          <div className=''>
            <label className=''>{t("Subscription for Offers")}</label>
            {/* <span className=''>{profileData?.data?.sms_subscription === 1 ? t("SMS") : ''}</span>
              <span className='text-black font-15 fw-bold ms-2'>{profileData?.data?.email_subscription === 1 ? t("Email") : ''}</span> */}
            <span className='text-black font-15 fw-bold ms-2'>{profileData?.data?.source ? profileData?.data?.source : '-'}</span>
          </div>
        </div>
        {userEmail == 'elio.broker@exalogic.co' ?
          <>
            <div className='col-12 mt-5'><h3 className="general-title">IBAN Details</h3></div>
            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
              <div className=''>
                <label className=''>{t("Country (bank account/country key)")}</label>
                <span className='text-black font-15 fw-bold ms-2'>United Arab Emirates</span>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
              <div className=''>
                <label className=''>{t("IBAN Number")}</label>
                <span className='text-black font-15 fw-bold ms-2'>AE</span>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
              <div className=''>
                <label className=''>{t("Bank Name")}</label>
                <span className='text-black font-15 fw-bold ms-2'>Central Bank Of UAE</span>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
              <div className=''>
                <label className=''>{t("Account Number")}</label>
                <span className='text-black font-15 fw-bold ms-2'>6039032718</span>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
              <div className=''>
                <label className=''>{t("Beneficiary Name")}</label>
                <span className='text-black font-15 fw-bold ms-2'>Arun</span>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
              <div className=''>
                <label className=''>{t("Home City")}</label>
                <span className='text-black font-15 fw-bold ms-2'>-</span>
              </div>
            </div>
            <div className='col-lg-4 col-sm-6 col-12 mb-lg-8 mb-5'>
              <div className=''>
                <label className=''>{t("Swift Code")}</label>
                <span className='text-black font-15 fw-bold ms-2'>-</span>
              </div>
            </div>
          </> : null
        }

      </div>
      <div className="row">
        <div className="col-12">
          {props.successMsg !== '' && (
            <div className='alert alert-success w-100 mt-2 text-center' role='alert'>
              {props.successMsg}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export { ProfileDetails }
