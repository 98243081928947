import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

export function MarketPlaceTabPrimary() {
    const { t } = useTranslation();

    const location = useLocation();
    let s = location.pathname
    let secondaryLink = s.substring(s.lastIndexOf("/") + 1, s.length)
    let primaryLink = s.substring(s.lastIndexOf("/list/") + 6, s.lastIndexOf("/"))

    const tabsList = [
        {
            title: t('Events'),
            value: 'events'
        },
        {
            title: t('Offers'),
            value: 'offers'
        },
        {
            title: t('Vouchers'),
            value: 'vouchers'
        },
        {
            title: t('Redeemed'),
            value: 'redeemed'
        }
    ]

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row access-cd-tabs">
                                <div className="col-md-12 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            {tabsList.map((item: any, index: number) => {
                                                return (
                                                    <li className='nav-item' key={index}>
                                                        <Link
                                                            className={`nav-link pb-1 position-relative text-active-primary me-14 ${primaryLink === item.value && 'active'}`}
                                                            to={`/market-place/list/${item.value}/all`}>
                                                            {/* to={`/market-place/list/${item.value}/${secondaryLink}`}> */}
                                                            {item.title}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};