import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../constants';
import { helipadRooftopAccessPermit } from '../../../../../../constants/axios/apis';
import { PermitToWorkDetails } from './PermitToWorkDetails';
import { SafetyPrecautions } from './SafetyPrecautions';
import { SelectPermitRequirements } from './SelectPermitRequirements';
import { ServicesAreaAffected } from './ServicesAreaAffected';
import { Signature } from './Signature';
import { useTranslation } from 'react-i18next'

type Props = {
    masterData?: any
    getStepStatus?: any
    stepStatus?: any
}

const HelipadRooftopAccess: FC<Props> = ({ masterData, getStepStatus, stepStatus }) => {
    const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
    const [loading, setLoading] = useState<any>(false)
    const [permitDetails, setPermitDetails] = useState<any>()
    const [permitRequirements, setPermitRequirements] = useState<any>()
    const [areasAffected, setAreasAffected] = useState<any>()
    const [safetyPrecatuions, setSafetyPrecatuions] = useState<any>()
    const [signatureData, setSignatureData] = useState<any>()
    const [isSubmitable, setIsSubmitable] = useState<any>(false)
    const { t } = useTranslation();

    const ref1 = useRef<any>()
    const ref2 = useRef<any>()
    const ref3 = useRef<any>()
    const ref4 = useRef<any>()

    const handelFinalSubmit = () => {
        if (permitDetails?.saved && permitRequirements?.saved && safetyPrecatuions?.saved && areasAffected?.saved && signatureData?.saved) {
            setLoading(true)
            setIsSubmitable(false)
            var FormData = require('form-data')
            var data = new FormData()
            data.append('contract_id', currentContract?.id)
            data.append('property_id', currentContract?.propertyID)
            data.append('category_id', 35)
            data.append('category_type_id', 52)
            data.append('hra_company_name', permitDetails.company_name)
            data.append('hra_contact_name', permitDetails.contact_name)
            data.append('hra_contact_number', permitDetails.contact_number)
            data.append('hra_applicant_designation', permitDetails.applicant_designation)
            data.append('hra_exact_work_location', permitDetails.exact_work_location)
            data.append('hra_no_workers', permitDetails.number_of_workers)
            data.append('hra_permit_date', permitDetails.permit_issue_date)
            data.append('hra_permit_time', permitDetails.permit_issue_time)
            data.append('hra_work_start_date', permitDetails.work_start_date)
            data.append('hra_work_end_date', permitDetails.work_end_date)
            data.append('hra_work_start_time', permitDetails.work_start_time)
            data.append('hra_work_end_time', permitDetails.work_end_time)
            permitRequirements.requirements_ids.forEach((requirement_id: any, index: any) => {
                data.append(`hra_requirement[${[index]}]`, requirement_id)
            });
            areasAffected.areas_ids.forEach((service_id: any, index: any) => {
                data.append(`hra_services[${[index]}]`, service_id)
            });
            safetyPrecatuions.precautions_ids.forEach((precaution_id: any, index: any) => {
                data.append(`hra_safety[${[index]}]`, precaution_id)
            });
            data.append('hrs_undertaking_letter', permitDetails.undertaking_letter)
            data.append('hrs_trade_license', permitDetails.trade_license)
            data.append('hrs_passport_copy', permitDetails.passport_copy)
            data.append('hrs_visa_copy', permitDetails.visa_copy)
            data.append('complete_description', permitDetails.description)
            data.append('hra_permit_applicant_name', signatureData.permit_applicant)
            data.append('hra_signature', signatureData.signature)

            setTimeout(() => {
                helipadRooftopAccessPermit(data)
                    .then((response: any) => {
                        // setSubmitting(false)
                        if (response.errorCode === 1) {
                            // setSubmitError(response.errorDescription)
                            // setTimeout(() => {
                            // 	setSubmitError('')
                            // }, 5000)
                        } else if (response.errorCode === 0) {
                            // setSubmitting(false)
                            getStepStatus()
                        } else {
                            // setSubmitError("Some error occured")
                            console.log(response, 'response');
                            // setTimeout(() => {
                            // 	setSubmitError('')
                            // }, 5000)
                        }
                        setLoading(false)
                        setIsSubmitable(false)
                    })
                    .catch((e) => {
                        console.log(e, 'Error');
                        // setSubmitting(false)
                        // setSubmitError('Some error occured!')
                        // setTimeout(() => {
                        // 	setSubmitError('')
                        // }, 5000)
                    })
            }, 1000)

        } else {
            // setSubmitError('Please fill all required fields')
        }
    }

    useEffect(() => {
        if (permitDetails?.saved && permitRequirements?.saved && areasAffected?.saved && safetyPrecatuions?.saved && signatureData?.saved) {
            setIsSubmitable(true)
        } else {
            setIsSubmitable(false)
        }
    }, [permitDetails, permitRequirements, areasAffected, safetyPrecatuions, signatureData])

    return (
        <div className="accordion move-out-accordion" id="accordion-step-five">
            <PermitToWorkDetails setPermitDetails={setPermitDetails} stepStatus={stepStatus} ref1={ref1} />
            <SelectPermitRequirements setPermitRequirements={setPermitRequirements} masterData={masterData} stepStatus={stepStatus} ref1={ref1} ref2={ref2} />
            <ServicesAreaAffected setAreasAffected={setAreasAffected} masterData={masterData} stepStatus={stepStatus} ref2={ref2} ref3={ref3} />
            <SafetyPrecautions setSafetyPrecatuions={setSafetyPrecatuions} masterData={masterData} stepStatus={stepStatus} ref3={ref3} ref4={ref4} />
            <Signature setSignatureData={setSignatureData} stepStatus={stepStatus} ref4={ref4} />

            {!stepStatus?.helipadAccessRequestStatus &&
                <div className="col-12 text-right">
                    <div className="row justify-content-end">
                        <div className="col-12">
                            <div className="d-flex justify-content-end">
                                <button type='button' onClick={handelFinalSubmit} disabled={!isSubmitable} className='btn btn-primary fw-bold px-10 py-3'>
                                    {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {t("Please wait...")}{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export { HelipadRooftopAccess }
