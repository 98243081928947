import React, { useState, useEffect } from 'react'
import { Col, Row } from 'antd'

export function AddComunitiesPost() {
    return (
        <>
            <div className='row align-items-center mb-5 community'>
                <div className="col-md-5">
                    <h5 className="theme-color font-22 d-inline-block float-left">
                        <a href="javascript:history.back()" className="back-hover text-decoration-none bg-white rounded me-4 px-2 rounded d-inline-block">
                            <i className="fa fa-angle-left font-20 font-weight-bold"></i>
                        </a>
                        Create Community Post
                    </h5>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className='row form-add-design'>
                      
                        <div className="col-md-4">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Category</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >
                                    <option >Please Select </option>
                                    <option>Offer </option>
                                    <option>Announcement</option>
                                    <option>Events</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Community</label>
                                <select className="form-control form-select fw-bolder form-control-solid"
                                >
                                    <option >Please Select </option>
                                    {/* <option>Offer </option>
                                    <option>Announcement</option>
                                    <option>Events</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Title</label>
                                <input type="text" className="form-control fw-bolder" placeholder="Title" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className='col-12'>
                            <label className='form-label fw-bolder text-uppercase'>Description</label>
                            <textarea
                                className='form-control'
                                data-kt-autosize='true'
                                rows={3}
                                placeholder={'Description'}
                            />
                        </div>
                    </div>                 
                    <div className="row mb-4 form-add-design">
                        <div className="col-md-4">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Video URL</label>
                                <input type="text" className="form-control fw-bolder" placeholder="Video URL" />
                            </div>
                        </div>
                    <div className="col-md-4">
                            <div className="mb-7">
                                <label className="form-label fw-bolder text-uppercase">Upload Image or PDF</label>                                                              
                                <input className="form-control" type="file" id="formFile" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}