import React, { useState, FC, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import moment from 'moment';
import completedStep from '../../../../../assets/images/completed-step.png'
import { useTranslation } from 'react-i18next'

type Props = {
    setGeneralInformation?: any
    ref1?: any
}

const today = new Date()
today.setHours(0, 0, 0, 0)

const generalInformationSchema = Yup.object().shape({
    permit_number: Yup.string().required('Permit Number is required'),
    location_of_work: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(30, 'Maximum 30 characters')
        .required('Location of work is required')
        .test('filled', 'Location of work cannot contain only blankspaces', (value) => {
            if (value) {
                return (
                    value.trim().length !== 0
                )
            }
            return true
        })
        .matches(/^[^\s].*/, 'Location of work cannot start with a blankspace'),
    date_of_impairmet: Yup.date()
        .min(today, 'Date of impairment cannot be in the past')
        .required('Please select a date of impairment'),
    time_of_impairment: Yup.string().required('Please select a time of impairment'),
    system_restore_date: Yup.date()
        .min(today, 'Date of system restore cannot be in the past')
        .required('Please select a date of system restore'),
    system_restore_time: Yup.string().required('Please select a date system restore'),
    reason: Yup.string().required('Please provide a description of work'),
})

const GeneralInformationofApplication: FC<Props> = ({ setGeneralInformation, ref1 }) => {
    const { t } = useTranslation();
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)

    const initialValues = {
        permit_number: '',
        location_of_work: '',
        date_of_impairmet: today,
        time_of_impairment: '',
        system_restore_date: today,
        system_restore_time: '',
        reason: '',
    }

    const handelSubmit = () => {
        setSaved(false)
        setGeneralInformation(null)
        formik.handleSubmit()
    }

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: generalInformationSchema,
        onSubmit: (values) => {
            setGeneralInformation({
                permit_number: values.permit_number,
                location_of_work: values.location_of_work,
                date_of_impairmet: moment(values.date_of_impairmet).format('YYYY-MM-DD'),
                time_of_impairment: values.time_of_impairment,
                system_restore_date: moment(values.system_restore_date).format('YYYY-MM-DD'),
                system_restore_time: values.system_restore_time,
                reason: values.reason,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref1.current.click()
        },
    })

    const handleImpairmentDate = (e: any) => {
        formik.setFieldTouched('date_of_impairmet')
        formik.setFieldValue('date_of_impairmet', new Date(e))
    }

    const handleSystemRestoreDate = (e: any) => {
        formik.setFieldTouched('system_restore_date')
        formik.setFieldValue('system_restore_date', new Date(e))
    }

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setGeneralInformation((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    return (
        <div className="row">
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
            >
                <div className="col-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSeven">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" aria-expanded="true" data-bs-target="#general-information-of-application">
                                {t("General Information of Application")}
                                {(saved) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </h2>
                        <div id="general-information-of-application" className="accordion-collapse collapse show" data-bs-parent="#accordion-step-five">
                            <div className="accordion-body pb-5 pt-0">
                                <div className="row form-add-design">
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder">{t("Permit No.")}</label>
                                            <input
                                                className='form-control bg-white fw-bolder form-control-solid'
                                                {...formik.getFieldProps('permit_number')}
                                            />
                                            {formik.touched.permit_number && formik.errors.permit_number && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.permit_number}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder">{t("Location")}</label>
                                            <input
                                                className='form-control bg-white fw-bolder form-control-solid'
                                                {...formik.getFieldProps('location_of_work')}
                                            />
                                            {formik.touched.location_of_work && formik.errors.location_of_work && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.location_of_work}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder">{t("Reason for Impairment")}</label>
                                            <textarea
                                                rows={4}
                                                className='form-control bg-white fw-bolder form-control-solid'
                                                {...formik.getFieldProps('reason')}
                                            ></textarea>
                                            {formik.touched.reason && formik.errors.reason && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.reason}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7 date-picker">
                                            <label className="form-label fw-bolder">{t("Requested Date")}</label>
                                            <DatePicker
                                                onChange={(e: any) => handleImpairmentDate(e)}
                                                value={formik.values.date_of_impairmet}
                                                minDate={new Date(new Date().toLocaleDateString())}
                                                className={'form-control fw-bolder form-control-solid'}
                                                format='dd-MM-y'
                                                clearIcon={null}
                                                maxDate={new Date('12/31/2999')}
                                            />
                                            {formik.touched.date_of_impairmet && formik.errors.date_of_impairmet && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.date_of_impairmet}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder">{t("Requested Time")}</label>
                                            <input
                                                type='time'
                                                className={'form-control bg-white fw-bolder form-control-solid'}
                                                {...formik.getFieldProps('time_of_impairment')}
                                                placeholder=''
                                            />
                                            {formik.touched.time_of_impairment && formik.errors.time_of_impairment && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.time_of_impairment}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7 date-picker">
                                            <label className="form-label fw-bolder">{t("Requested Date System Restored")}</label>
                                            <DatePicker
                                                onChange={(e: any) => handleSystemRestoreDate(e)}
                                                value={formik.values.system_restore_date}
                                                minDate={new Date(new Date().toLocaleDateString())}
                                                className={'form-control fw-bolder form-control-solid'}
                                                format='dd-MM-y'
                                                clearIcon={null}
                                                maxDate={new Date('12/31/2999')}
                                            />
                                            {formik.touched.system_restore_date && formik.errors.system_restore_date && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.system_restore_date}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder">{t("Requested Time System Restored")}</label>
                                            <input
                                                type='time'
                                                className={'form-control bg-white fw-bolder form-control-solid'}
                                                {...formik.getFieldProps('system_restore_time')}
                                                placeholder=''
                                            />
                                            {formik.touched.system_restore_time && formik.errors.system_restore_time && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.system_restore_time}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 text-right">
                                        <div className="row justify-content-end">
                                            <div className="col-12">
                                                <div className="d-flex justify-content-end">
                                                    <button type='button' onClick={handelSubmit} disabled={!dirty} className='btn btn-primary fw-bold px-10 py-3'>
                                                        {saved ? 'Saved' : t("Save")}
                                                    </button>
                                                </div>
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </form >
        </div >
    )
};

export { GeneralInformationofApplication }
