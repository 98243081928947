import React, { useState, useEffect } from 'react';
import OfferImg01 from "../../../../../assets/images/Image_1.png";
import OfferImg02 from "../../../../../assets/images/Image_2.png";
import OfferImg03 from "../../../../../assets/images/Image_3.png";
import OfferIcon01 from "../../../../../assets/images/Vox_logo_2.png";
import OfferIcon02 from "../../../../../assets/images/RO_logo.png";
import OfferIcon03 from "../../../../../assets/images/Puma_logo.png";
import {Link} from 'react-router-dom';

export function AvailableOffers() {
    return (
        <div className="com-card-section">
            <div className="row gx-8 gy-2">
                <div className="col-12">
                    <h4 className="text-black font-18 fw-bolder my-5">
                        Available Offers
                    </h4>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="card mb-3">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="offer-section">
                                        <Link to={'/market-place/marketplaceofferdetails'} className="text-decoration-none"> <img src={OfferImg01} alt="offer-img-01" className="d-block w-100 offer-img" /></Link>
                                        <div className="offer-icon">
                                            <img src={OfferIcon01} alt="Offer" className="img-fluid" />
                                        </div>
                                        <div className="offer-details p-4">
                                            <div className="d-flex flex-row justify-content-between mb-4">
                                                <span className="offer-price fw-bolder">AED 1300 OFF</span>
                                                <span className="offer-exclusive">Exclusive</span>
                                            </div>
                                            <div className="offer-desc">
                                                <Link to={'/market-place/marketplaceofferdetails'} className="text-decoration-none">
                                                    <p className="mb-0">Flat AED 800 OFF + AED 500 Cashback on </p>
                                                    <p className="mb-0">AED 50,000</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="card mb-3">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="offer-section">
                                        <Link to={'/market-place/marketplaceofferdetails'} className="text-decoration-none"><img src={OfferImg02} alt="offer-img-01" className="d-block w-100 offer-img" /></Link>
                                        <div className="offer-icon">
                                            <img src={OfferIcon02} alt="Offer" className="img-fluid" />
                                        </div>
                                        <div className="offer-details p-4">
                                            <div className="d-flex flex-row justify-content-between mb-4">
                                                <span className="offer-price fw-bolder">AED 1300 OFF</span>
                                                <span className="offer-exclusive">Exclusive</span>
                                            </div>
                                            <div className="offer-desc">
                                                <Link to={'/market-place/marketplaceofferdetails'} className="text-decoration-none">
                                                    <p className="mb-0">Flat AED 800 OFF + AED 500 Cashback on </p>
                                                    <p className="mb-0">AED 50,000</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="card mb-3">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="offer-section">
                                        <Link to={'/market-place/marketplaceofferdetails'} className="text-decoration-none">
                                            <img src={OfferImg03} alt="Offer" className="d-block w-100 offer-img" />
                                        </Link>
                                        <div className="offer-icon">
                                            <img src={OfferIcon03} alt="Offer" className="img-fluid" />
                                        </div>
                                        <div className="offer-details p-4">
                                            <div className="d-flex flex-row justify-content-between mb-4">
                                                <span className="offer-price fw-bolder">AED 1300 OFF</span>
                                                <span className="offer-exclusive">Exclusive</span>
                                            </div>
                                            <div className="offer-desc">
                                                <Link to={'/market-place/marketplaceofferdetails'} className="text-decoration-none">
                                                    <p className="mb-0">Flat AED 800 OFF + AED 500 Cashback on </p>
                                                    <p className="mb-0">AED 50,000</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}