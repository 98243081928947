import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import * as FCDashboard from '../../../../constants/redux/Sagas/FacilityContractor/FacilityContractorSaga'
// import { getFacilityBuildingList, getFmCommunityUser } from '../../../../constants/axios/apis'
import { Select } from 'antd'
import 'antd/dist/antd.css'
import { getUserBuildingCommunityList } from '../../../../utils/makeRequest'
const { Option } = Select

export function MenuInnerFacilityContactor() {
  const dispatch = useDispatch()
  const currentBuilding: any = useSelector<RootState>(
    ({ FCDashboard }) => FCDashboard.selectedBuilding
  )

  const [buildingList, setBuildingList] = useState<any>([])
  // const [propertyList, setPropertyList] = useState<any>(currentBuilding)
  const [selectedBuilding, setSelectedBuilding] = useState<any>(currentBuilding)
  const [isLoading, setIsLoading] = useState<any>({
    building: true,
    property: true,
  })

  const state: any = useSelector((state) => state)
  const userId = state?.auth?.user?.data?.id

  const onChange = (value: string) => {
    setSelectedBuilding(value)
  }

  const onSearch = (value: string) => { }

  useEffect(() => {
    getUserBuildingCommunityList(userId).then(({buildingListData, communityListData}) => {
      setBuildingList(buildingListData)
      dispatch(FCDashboard.actions.listBuilding(buildingListData))
      setIsLoading((oState: any) => ({ ...oState, building: false, property: false }));
    }).catch((e) => {
      setIsLoading((oState: any) => ({ ...oState, building: false, property: false }))
    })

    // getFmCommunityUser('?user_id=' + userId)
    //   .then(({data}) => {
    //     if (data && Array.isArray(data)) {

    //       const validBuilding = data.filter((el: any) => el.building_id && el.building_name)
    //       const buildingListData = validBuilding.map((item: any) => ({
    //         id: `${item.building_id}`,
    //         name: item.building_name,
    //         communityId: `${item.community_id}`,
    //       }))
    //       setBuildingList(buildingListData)
    //       // const communityList =  data.map((item: any) => ({ id: `${item.community_id}`, name: item.community }))
    //       // const filteredCommulityList = communityList?.filter((value, index, self) => index === self.findIndex((el) => ( el.id === value.id )))
    //       // setPropertyList(filteredCommulityList)

    //       dispatch(FCDashboard.actions.listBuilding(buildingListData))
    //     }
    //     setIsLoading((oState: any) => ({...oState, building: false, property: false}))
    //   })
    //   .catch((e) => {
    //     setIsLoading((oState: any) => ({...oState, building: false, property: false}))
    //   })

    // getFacilityBuildingList().then(({ data }) => {
    //     setBuildingUnitList((data && Array.isArray(data)) ? data.map((item: any) => ({ buildingId: `${item.id}`, buildingName: item.building })) : [])
    //     setIsLoading((oState: any) => ({ ...oState, building: false }))
    // }).catch((e) => { setIsLoading((oState: any) => ({ ...oState, building: false })) })
  }, [])

  useEffect(() => {
    if (currentBuilding === undefined && buildingList && buildingList[0]) {
      setSelectedBuilding(buildingList[0].id)
    }
  }, [currentBuilding, buildingList])

  useEffect(() => {
    dispatch(FCDashboard.actions.selectedBuilding(selectedBuilding))
  }, [selectedBuilding])

  const buildings = buildingList?.map((building: any) => (
    <Option key={building.id} value={building.id}>
      {building.name}
    </Option>
  ))

  const renderSelect = () => {
    if (!isLoading?.building) {
      return (
        <>
          <Select
            className='form-select form-select-lg form-select-solid p-1 fcss-header-select'
            showSearch
            placeholder='Select Building'
            optionFilterProp='children'
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              ((option!.children as unknown) as string).toLowerCase().includes(input.toLowerCase())
            }
            defaultValue={currentBuilding ? currentBuilding : selectedBuilding}
          >
            <Option value=''>All Buildings</Option>
            {!isLoading?.building ? buildings : ''}
          </Select>
        </>
      )
    } else {
    }
  }
  return (
    <>
      <div className='row'>
        <div className='col-lg-12 my-auto'>{renderSelect()}</div>
      </div>
    </>
  )
}
