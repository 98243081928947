import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import { createChequeDelayRequest, getMasterData } from '../../../../constants/axios/apis'

const chequeReplaceSchema = Yup.object().shape({
  reason: Yup.number().required('Please choose a reason for replacement'),
  desc: Yup.string().required('Please provide a description').max(250, 'Maximum 250 characters'),
})

export function ChequeDelayRequest() {
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const [loading, setLoading] = useState(false)
  const [reasons, setReasons] = useState<any>()
  const [details, setDetails] = useState<any>()
  const location = useLocation<any>()
  const history = useHistory()
  const closeRef = useRef<any>()

  useEffect(() => {
    if (location?.state?.details) {
      setDetails(location?.state?.details)
    }
  }, [location?.key])

  const initialValues = {
    offer_cheque_id: details ? details.offer_cheque_id : '',
    desc: '',
    reason: '',
    type: 2,
    property_id: currentContract?.propertyID,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: chequeReplaceSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        createChequeDelayRequest(
          values.offer_cheque_id,
          values.desc,
          values.reason,
          values.type,
          values.property_id
        )
          .then((response: any) => {
            setLoading(false)
            resetForm()
            closeRef.current.click()
            history.push('/payments/all')
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Invalid Data Provided')
          })
      }, 1000)
    },
  })

  useEffect(() => {
    getMasterData().then(({ data }) => {
      let items = data.filter((item: any) => item.id == 20).flatMap((data: any) => data.master_data)
      setReasons(items)
    })
  }, [])

  const reasonList = reasons?.map((reason: any) => (
    <option key={reason.value} value={reason.value}>
      {reason.name}
    </option>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-7'>
            <Link to={'/payments/all'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            Cheque Delay Request
          </h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='card'>
              <div className='card-body px-8 py-6'>
                <div className='row form-add-design'>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'>
                        {' '}
                        Reason for Delay<span className='text-danger'>*</span>{' '}
                      </label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid'
                        name='reason'
                        value={formik.values.reason}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          Select
                        </option>
                        {reasons ? reasonList : ''}
                      </select>
                      {formik.touched.reason && formik.errors.reason && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.reason}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-12 col-sm-12'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'>Description<span className='text-danger'>*</span></label>
                      <textarea
                        className='form-control form-control-lg form-control-solid'
                        rows={1}
                        {...formik.getFieldProps('desc')}
                      />
                      {formik.touched.desc && formik.errors.desc && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.desc}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-12 text-end'>
                    <button
                      type='submit'
                      className='btn btn-outline-primary font-14 fw-bold px-10 py-3 me-5'
                      onClick={() => {
                        formik.resetForm()
                        history.push('/payments/all')
                      }}
                    >
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading}>
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}
