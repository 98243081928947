import React from 'react'
import { useTranslation } from 'react-i18next'

export function AccessCardPopUp({ issue = '' }: any) {
    const { t } = useTranslation();

    return (
        <>
            <div className="modal fade attachment-popup-modal custom-modal" aria-hidden='true' id="access-card-popup">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content rounded-10">
                        <div className="modal-header p-6 border-0">
                            <h5 className="modal-title font-18">{t("Access Card Details")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                        </div>
                        <div className="modal-body p-2 ">
                            <div className="row ">
                                <div className="col-12">
                                    <table className="table">
                                        <tr>
                                            <td>{issue?.details?.category_type_name} X {issue?.details?.no_of_cards} </td>
                                            <td className="text-end">{t("AED")} {(issue?.details.no_of_cards * issue?.details.amount)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("VAT")}</td>
                                            <td className="text-end">{t("AED")} {issue?.details.no_of_cards * issue?.details.vat_amount}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("Total")}</td>
                                            <td className="text-end">{t("AED")} {
                                                (issue?.details.no_of_cards * issue?.details.amount
                                                    + (issue?.details.no_of_cards * issue?.details.vat_amount))
                                                    ?.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                            }</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
