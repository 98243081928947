import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import moment from 'moment'
import { getMarketPlaceDetails } from '../../../../constants/axios/apis'
import Loader from '../../../../components/Loader'
import { formatDateTimeMeridiem, formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater'
import { useTranslation } from 'react-i18next'
import { getUserRole } from '../../../../constants/generics/userCheck'
import { useSelector } from 'react-redux'
import { RedeemMarketPlacePopup } from './RedeemMarketPlace/RedeemMarketPlacePopup'
import { RedeemMarketPlaceSubmitPopup } from './RedeemMarketPlace/RedeemMarketPlaceSubmitPopup'
import { CustomLoaderCompact } from '../../../../constants/generics/customActios'

export function MarketPlaceViewWrapper() {
    const location = useLocation<any>()
    const history = useHistory()
    const { t } = useTranslation();
    const [loading, setLoading] = useState<any>(true);
    const [marketPlaceDetails, setMarketPlaceDetails] = useState<any>({})
    // const [allowRedeem, setAllowRedeem] = useState<any>(false)

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);

    const allowRedeem = user_role ? [''].includes(user_role) : false //TODO

    useEffect(() => {
        // if (location?.state?.redeem) {
        //     const { redeem } = location?.state
        //     setAllowRedeem(redeem)
        // }

        if (location?.state?.marketPlaceID) {
            const { marketPlaceID } = location?.state

            getMarketPlaceDetails(marketPlaceID).then(({ data }: any) => {
                if (data && data[0]) {
                    setMarketPlaceDetails(data[0])
                } else {
                    setMarketPlaceDetails({})
                }
                setLoading(false)
            })
        }
    }, [location])

    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-md-12 col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                        <span onClick={() => { history.goBack() }} className='c-pointer'>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </span>
                        {/* <Link to={backLink} className=''>
                            <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                        </Link> */}
                        <span className='text-black'>{t("Market Place")}</span>
                    </h4>
                </div>
            </div>
            {loading ?
                <CustomLoaderCompact />
                :
                <>
                    <div className="row">

                        <div className='col-12'>
                            <div className='card mb-3'>
                                <div className='card-body px-5 py-5 market-place-ht'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='off-details-banner position-relative'>
                                                <img
                                                    src={marketPlaceDetails?.image?.length && Array.isArray(marketPlaceDetails?.image) ? marketPlaceDetails?.image[0] : ''}
                                                    // src={detailsBanner}
                                                    alt='offer-detail-banner'
                                                    className='img-fluid w-100 offer-detail-banner'
                                                />
                                                <div className='offer-details-section position-absolute text-white'>
                                                    <div className='row justify-content-between'>
                                                        <div className='col-sm-6'>
                                                            <div className='d-flex flex-column justify-content-around off-text-left'>
                                                                {marketPlaceDetails?.logo &&
                                                                    <div className="logo-img bg-white rounded-10">
                                                                        <img
                                                                            src={marketPlaceDetails?.logo}
                                                                            alt='Offer Logo'
                                                                            className='img-fluid offer-logo-img'
                                                                            width='74'
                                                                            height='70'
                                                                        />
                                                                    </div>
                                                                }
                                                                <h3 className="">{marketPlaceDetails?.name}</h3>
                                                                {/* <p className='fw-normal banner-text'>{marketPlaceDetails?.location ? marketPlaceDetails?.location : 'Location --- '}</p> */}
                                                                <p className='fw-normal banner-text'>{marketPlaceDetails?.category_name}</p>
                                                                <p className='available-time-offer banner-text'>
                                                                    {/* <small>{marketPlaceDetails?.open_hours}</small> */}
                                                                    <small>{formatTimeMeridiem(marketPlaceDetails?.start_time) + ' - ' + formatTimeMeridiem(marketPlaceDetails?.end_time)}</small>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6 text-end'>
                                                            <div className='d-flex flex-column justify-content-around h-100 text-white off-text-right'>
                                                                {marketPlaceDetails?.type && <span className='offer'>{marketPlaceDetails?.type}</span>}
                                                                {marketPlaceDetails?.offer &&
                                                                    <h2 className='text-white'>{marketPlaceDetails?.offer}{t("% OFF")}</h2>
                                                                }
                                                                <h5 className='text-white fw-normal'>
                                                                    {t("Expires On")}
                                                                    <span className='text-white offer-timer'>
                                                                        {moment
                                                                            .utc(marketPlaceDetails?.end_date)
                                                                            .format('DD-MM-YYYY')}
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='mt-5 offerDescription'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card mb-3'>
                                    <div className='card-body px-8 py-6'>                                        
                                        <div className='row offer-desc'>                                            
                                            {marketPlaceDetails?.location &&
                                                <div className='col-lg-4 col-sm-6'>
                                                    <div className='mb-10'>
                                                        <label>{t("Location")}</label>
                                                        <br />
                                                        <strong>{marketPlaceDetails?.location}</strong>
                                                    </div>
                                                </div>
                                            }
                                            {marketPlaceDetails?.web_link &&
                                                <div className='col-lg-4 col-sm-6'>
                                                    <div className='mb-10'>
                                                        <label>{t("Web")}</label>
                                                        <br />
                                                        <strong>
                                                            <Link to={{ pathname: marketPlaceDetails?.web_link }} target='_blank' className="text-primary" >
                                                                {marketPlaceDetails?.web_link}
                                                            </Link>
                                                        </strong>
                                                    </div>
                                                </div>
                                            }
                                            {marketPlaceDetails?.offer_type &&
                                                <div className='col-lg-4 col-sm-6'>
                                                    <div className='mb-10'>
                                                        <label>{t("Type of offer")}</label>
                                                        <br />
                                                        <strong>{marketPlaceDetails?.offer_type}</strong>
                                                    </div>
                                                </div>
                                            }
                                            <div className='col-lg-4 col-sm-6'>
                                                <div className='mb-10'>
                                                    <label>{t("Start Date")}</label>
                                                    <br />
                                                    <strong>
                                                        {moment.utc(marketPlaceDetails?.start_date).format('DD-MM-YYYY')}
                                                    </strong>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-sm-6'>
                                                <div className='mb-10'>
                                                    <label>{t("End Date")}</label>
                                                    <br />
                                                    <strong>
                                                        {moment.utc(marketPlaceDetails?.end_date).format('DD-MM-YYYY')}
                                                    </strong>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-sm-6'>
                                                <div className='mb-10'>
                                                    <label>{t("Contact")}</label>
                                                    <br />
                                                    <strong>{marketPlaceDetails?.contact}</strong>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-sm-6'>
                                                <div className='mb-10'>
                                                    <label>{t("Time")}</label>
                                                    <br />
                                                    <strong>{formatTimeMeridiem(marketPlaceDetails?.start_time) + ' - ' + formatTimeMeridiem(marketPlaceDetails?.end_time)}</strong>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-md-12 col-12'>
                                                    <h4 className='card-title font-17 fw-bolder'>{t("Description")}</h4>
                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-5'>
                                                <p className='font-14 fw-bold'>{marketPlaceDetails?.description}</p>
                                            </div>
                                            {marketPlaceDetails?.ticket_no &&
                                                <div className='col-lg-4 col-sm-6'>
                                                    <div className='mb-10'>
                                                        <label>{t("Ticket No.")}</label>
                                                        <br />
                                                        <strong>{marketPlaceDetails?.ticket_no}</strong>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className='row'>
                                            <div className='col-12 text-end'>
                                                <div className='row justify-content-end'>
                                                    <div className='col-12'>
                                                        {/* <Link
                                                                    to={backLink}
                                                                    className='btn btn-outline-primary mx-4 fw-bold px-sm-14 px-8 py-3'
                                                                >
                                                                    Back
                                                                </Link> */}
                                                        <button
                                                            onClick={() => { history.goBack() }}
                                                            className='btn btn-outline-primary mx-4 fw-bold px-sm-14 px-8 py-3'
                                                        >
                                                            {t("Back")}
                                                        </button>
                                                        {allowRedeem ?
                                                            marketPlaceDetails.redeem_limit <= marketPlaceDetails.redeem_count ?
                                                                <button
                                                                    className='btn btn-primary fw-bold px-sm-10 px-8 py-3'
                                                                    style={{ cursor: 'not-allowed' }}
                                                                    title={'Redeem limit has been expired!'}
                                                                >
                                                                    {t("Redeemed limit reached")}
                                                                </button>
                                                                :
                                                                <button
                                                                    className='btn btn-primary fw-bold px-10 py-3'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-target='#redeem-offer'
                                                                >
                                                                    {t("Redeem")}
                                                                </button>
                                                            : ''
                                                        }


                                                        {/* {marketPlaceDetails?.is_redeemed == 1 ? (
                                <button
                                    className='btn btn-primary fw-bold px-sm-10 px-8 py-3'
                                    style={{ cursor: 'not-allowed' }}
                                    title={'Offer already redeemed!'}
                                >
                                    Already Redeemed
                                </button>
                                ) : (
                                <button
                                    className='btn btn-primary fw-bold px-10 py-3'
                                    data-bs-toggle='modal'
                                    data-bs-target='#redeem-offer'
                                >
                                    Redeem
                                </button>
                                )} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <RedeemMarketPlacePopup 
                                    redeemableOffer={marketPlaceDetails}
                                    // backLink={backLink}
                                />
                                <RedeemMarketPlaceSubmitPopup redeemData={marketPlaceDetails}/>
                            </div>
                        </div>
                    </div>
                    {/* <MarketPlaceDescription marketPlaceDetails={marketPlaceDetails} backLink={backLink} allowRedeem={allowRedeem}/> */}

                </>
            }
        </div>
    )
}
