import React, { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../components/layout/core'
import { MyLease } from './MyLease'
import { InnerDocxList } from "./Documents/InnerDocxList";
import { MyUnitlist } from './MyUnitList/MyUnitList'
import { Route, Switch } from 'react-router-dom'
import { getMyUnitDocuments, getPaymentHistory, getServiceRequestsCategories } from '../../constants/axios/apis'
import { RootState } from '../../constants'
import { useSelector } from 'react-redux'
import { MyServicesList } from '../my-services/components/MyServicesList/MyServicesList'
import { NeedAction } from './NeedAction/NeedAction'
import { PaymentsNew } from "./Payments/PaymentsNew";
import moment from 'moment';
import { CustomLoader } from '../../constants/generics/customActios';
import { MyUnitCard } from './MyUnitCard/MyUnitCard';


const MyUnitWrapper: FC = () => {
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)

  const [services, setServices] = useState<any>()
  const [payments, setPayments] = useState<any>(undefined)
  const [allDocuments, setAllDocuments] = useState<any>([])
  const [needActionDocuments, setNeedActionDocuments] = useState<any>([])

  let userDetail = localStorage.getItem('userDetail')
  useEffect(() => {
    if (currentContract) {
      getPaymentHistory(currentContract.propertyID, '', '', 100).then(({ data: paymentsData }) => {
        setPayments(paymentsData)
      })
    }
  }, [currentContract])

  useEffect(() => {
    getServiceRequestsCategories(currentContract?.property_search_type, currentContract, userDetail && JSON.parse(userDetail)).then(response => {
      let categories = response.data?.filter((category: any) => category?.is_visible === 1)
      setServices(categories)
    })
  }, [])

  const getDocs = () => {
    getMyUnitDocuments().then((response) => {
      // console.log(moment("2023-10-31 15:18:28").format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm'), moment("2023-10-31 15:18:28").format('YYYY-MM-DD HH:mm'), ' -', moment().format('YYYY-MM-DD HH:mm'))
      if (response.data) {
        // setAllDocuments(response.data?.all_documents?.filter((document: any) => document.contract_id == currentContract.id))
        setAllDocuments(response.data?.all_documents.filter((item: any) =>
          moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >= moment().format('YYYY-MM-DD HH:mm') && item.document_type == "emirates_front"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >= moment().format('YYYY-MM-DD HH:mm') && item.document_type == "emirates_back"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >= moment().format('YYYY-MM-DD HH:mm') && item.document_type == "passport"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >= moment().format('YYYY-MM-DD HH:mm') && item.document_type == "passport_back"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >= moment().format('YYYY-MM-DD HH:mm') && item?.filename?.length > 0 && item.document_type == "visa"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >= moment().format('YYYY-MM-DD HH:mm') && item?.filename?.length > 0 && item.document_type == "letter_of_intent"
        ))
        // setNeedActionDocuments(response.data?.need_action?.filter((document: any) => document.contract_id == currentContract.id))
        setNeedActionDocuments(response.data?.all_documents.filter((item: any) =>
          moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm') && item.document_type == "emirates_front"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm') && item.document_type == "emirates_back"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm') && item.document_type == "passport"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm') && item.document_type == "passport_back"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm') && item?.filename?.length > 0 && item.document_type == "visa"
          || moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm') && item?.filename?.length > 0 && item.document_type == "letter_of_intent"
        ))
      }
    })
  }

  useEffect(() => {
    if (currentContract) {
      getDocs()
    }
  }, [currentContract])
  if (currentContract === undefined) { return <CustomLoader /> }
  return (
    <div className='row gy-5 g-xl-8 myunit-page dashboard-page'>
      <div className='col-xxl-12 mt-0'>
        <PageTitle breadcrumbs={[]}>My Unit</PageTitle>
        <Switch>
          <Route path='/my-unit/payments'>
            <MyUnitCard contract={currentContract} />
            {/* <MyLease contract={currentContract} /> */}
            {currentContract?.contractStatus != 'Terminated' &&
              <MyServicesList services={services} />
            }
            <MyUnitlist allDocumentCount={allDocuments?.length} needActionDocumentCount={needActionDocuments?.length} />
            {/*<Payments payments={payments} />*/}
            <PaymentsNew payments={payments} />
          </Route>
          <Route path='/my-unit/documents'>
          <MyUnitCard contract={currentContract} />
            {/* <MyLease contract={currentContract} /> */}
            {currentContract?.contractStatus != 'Terminated' &&
              <MyServicesList services={services} />
            }
            <MyUnitlist allDocumentCount={allDocuments?.length} needActionDocumentCount={needActionDocuments.length} />
            <InnerDocxList allDocuments={allDocuments} />
            {/*<Documents documents={currentContract?.document} />*/}
          </Route>
          <Route path='/my-unit/need-action'>
          <MyUnitCard contract={currentContract} />
            {/* <MyLease contract={currentContract} /> */}
            {currentContract?.contractStatus != 'Terminated' &&
              <MyServicesList services={services} />
            }
            <MyUnitlist allDocumentCount={allDocuments?.length} needActionDocumentCount={needActionDocuments?.length} />
            <NeedAction needActionDocuments={needActionDocuments} getDocs={getDocs} />
          </Route>
          <Route path='/my-unit/lease-details'>
          <MyUnitCard contract={currentContract} />
            {currentContract?.contractStatus != 'Terminated' &&
              <MyServicesList services={services} />
            }
            <MyUnitlist allDocumentCount={allDocuments?.length} needActionDocumentCount={needActionDocuments?.length} />
            {/* <NeedAction needActionDocuments={needActionDocuments} getDocs={getDocs} /> */}
            <MyLease contract={currentContract} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default MyUnitWrapper
