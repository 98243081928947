import React, { useState, useEffect, useRef } from 'react';
import PlusIcon from "../../../../../../../../assets/images/plus-circle.svg";
import { Alert, Spinner } from 'react-bootstrap-v5'
import * as PTOffer from '../../../../../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { RootState } from '../../../../../../../../constants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { getMyDocs } from '../../../../../../../../constants/axios/apis';
import { ViewPopup } from './ViewPopup';
import { validateAndCompressImage } from '../../../../../../../../constants/generics/fileUploadHandler';

export function StepThree(props: any) {
    const { uploadDocuments } = props
    let offerList: any
    offerList = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)
    const [filesState, setFilesState] = useState<any>([])
    const [myDocs, setMyDocs] = useState<any>()
    const [viewImage, setViewImage] = useState<any>()
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [show, setShow] = useState(false)
    const [error, setError] = useState('')
    const [comments, setComments] = useState('')
    const [variants, setVriants] = useState('')

    const inputRefEmiratesIdFront = useRef<any>(0)
    const [emiratesIdFront, setEmiratesIdFront] = useState<any>()
    const [emiratesIdFrontAlreadyExists, setEmiratesIdFrontAlreadyExists] = useState(false)
    const inputRefEmiratesIdBack = useRef<any>(0)
    const [emiratesIdBack, setEmiratesIdBack] = useState<any>()
    const [emiratesIdBackAlreadyExists, setEmiratesIdBackAlreadyExists] = useState(false)

    const inputRefPassport = useRef<any>(0)
    const [passport, setPassport] = useState<any>()
    const [passportAlreadyExists, setPassportAlreadyExists] = useState(false)
    // console.log("🚀 ~ file: StepThree.tsx:35 ~ StepThree ~ passportAlreadyExists", passportAlreadyExists)
    const inputRefPassportBack = useRef<any>(0)
    const [passportBack, setPassportBack] = useState<any>()
    const [passportBackAlreadyExists, setPassportBackAlreadyExists] = useState(false)

    const inputRefVisa = useRef<any>(0)
    const [visa, setVisa] = useState<any>()

    const inputRefLetter = useRef<any>(0)
    const [letter, setLetter] = useState<any>()
    const [ErrorFile, setErrorFile] = useState<any>([])
    const { t } = useTranslation();

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const [uploadingEF, setUploadingEF] = useState(false)
    const handleUploadEmiratesIdFront = () => {
        inputRefEmiratesIdFront.current?.click()
    }
    const handleDisplayFileDetailsEmiratesIdFront = () => {
        const saveFile = checkFileDetails(inputRefEmiratesIdFront)
        // setEmiratesIdFront(saveFile)

        if (saveFile.type === 'application/pdf') {
            setEmiratesIdFront(saveFile)
        } else {
            setUploadingEF(true)
            try {
                validateAndCompressImage(saveFile).then((compressResponse) => {
                    if (compressResponse?.success) {
                        setEmiratesIdFront(compressResponse.file)
                    } else {
                        setShow(true)
                        setError(t(compressResponse?.message || 'File compression error!'))
                        setVriants('danger')
                        setTimeout(() => {
                            setShow(false)
                        }, 2000)
                    }
                    setUploadingEF(false)
                })
            } catch (error) {
                setShow(true)
                setError(t('File upload error!'))
                setVriants('danger')
                setTimeout(() => {
                    setShow(false)
                }, 2000)
                setUploadingEF(false)
            }
        }
    }
    //*

    var eIdFrontImage: any = ''
    var eIdBackImage: any = ''
    if (myDocs?.data?.length > 0) {
        eIdFrontImage = myDocs.data.find((obj: any) => obj.document_type === 'emirates_front')
        eIdBackImage = myDocs.data.find((obj: any) => obj.document_type === 'emirates_back')
    }
    const [uploadingEB, setUploadingEB] = useState(false)
    const handleUploadEmiratesIdBack = () => {
        inputRefEmiratesIdBack.current?.click()
    }
    const handleDisplayFileDetailsEmiratesIdBack = () => {
        const saveFile = checkFileDetails(inputRefEmiratesIdBack)
        // !filesState?.includes(saveFile?.name) ? setEmiratesIdBack(saveFile) : setErrorFile('F2')

        if(!filesState?.includes(saveFile?.name) && saveFile?.type){
            if(saveFile.type === 'application/pdf'){ 
                setEmiratesIdBack(saveFile)    
              }else{
                setUploadingEB(true)
                try {
                  validateAndCompressImage(saveFile).then((compressResponse) => {
                    if (compressResponse?.success) {
                        setEmiratesIdBack(compressResponse.file)
                    } else {
                        setShow(true)
                        setError(t(compressResponse?.message || 'File compression error!'))
                        setVriants('danger')
                        setTimeout(() => {
                            setShow(false)
                        }, 2000)
                    }
                    setUploadingEB(false)
                  })
                } catch (error) {
                    setShow(true)
                    setError(t('File upload error!'))
                    setVriants('danger')
                    setTimeout(() => {
                        setShow(false)
                    }, 2000)
                    setUploadingEB(false)
                }
              }
        }else{
            setErrorFile('F2')
        }
    }
    //*

    var pIdFrontImage: any = ''
    var pIdBackImage: any = ''
    if (myDocs?.data?.length > 0) {
        pIdFrontImage = myDocs.data.find((obj: any) => obj.document_type === 'passport')
        pIdBackImage = myDocs.data.find((obj: any) => obj.document_type === 'passport_back')
    }
    useEffect(() => {

        if (pIdFrontImage && pIdFrontImage?.filename) { setPassportAlreadyExists(true) } else { setPassportAlreadyExists(false) }
        if (pIdBackImage && pIdBackImage?.filename) { setPassportBackAlreadyExists(true) } else { setPassportBackAlreadyExists(false) }
        if (eIdFrontImage && eIdFrontImage?.filename) { setEmiratesIdFrontAlreadyExists(true) } else { setEmiratesIdFrontAlreadyExists(false) }
        if (eIdBackImage && eIdBackImage?.filename) { setEmiratesIdBackAlreadyExists(true) } else { setEmiratesIdBackAlreadyExists(false) }
    }, [pIdFrontImage, pIdBackImage, eIdFrontImage, eIdBackImage])
    
    const [uploadingPF, setUploadingPF] = useState(false)
    const [uploadingPB, setUploadingPB] = useState(false)
    const handleUploadPassport = () => {
        inputRefPassport.current?.click()
    }
    const handleUploadPassportBack = () => {
        inputRefPassportBack.current?.click()
    }
    const handleDisplayFileDetailsPassport = () => {
        const saveFile = checkFileDetails(inputRefPassport)
        // !filesState?.includes(saveFile?.name) ? setPassport(saveFile) : setErrorFile('F3')
        if(!filesState?.includes(saveFile?.name) && saveFile?.type){
            if(saveFile.type === 'application/pdf'){ 
                setPassport(saveFile)    
              }else{
                setUploadingPF(true)
                try {
                  validateAndCompressImage(saveFile).then((compressResponse) => {
                    if (compressResponse?.success) {
                        setPassport(compressResponse.file)
                    } else {
                        setShow(true)
                        setError(t(compressResponse?.message || 'File compression error!'))
                        setVriants('danger')
                        setTimeout(() => {
                            setShow(false)
                        }, 2000)
                    }
                    setUploadingPF(false)
                  })
                } catch (error) {
                    setShow(true)
                    setError(t('File upload error!'))
                    setVriants('danger')
                    setTimeout(() => {
                        setShow(false)
                    }, 2000)
                    setUploadingPF(false)
                }
              }
        }else{
            setErrorFile('F3')
        }
    }
    const handleDisplayFileDetailsPassportBack = () => {
        const saveFile = checkFileDetails(inputRefPassportBack)
        // !filesState?.includes(saveFile?.name) ? setPassportBack(saveFile) : setErrorFile('F4')
        
        if(!filesState?.includes(saveFile?.name) && saveFile?.type){
            if(saveFile.type === 'application/pdf'){ 
                setPassportBack(saveFile)    
              }else{
                setUploadingPB(true)
                try {
                  validateAndCompressImage(saveFile).then((compressResponse) => {
                    if (compressResponse?.success) {
                        setPassportBack(compressResponse.file)
                    } else {
                        setShow(true)
                        setError(t(compressResponse?.message || 'File compression error!'))
                        setVriants('danger')
                        setTimeout(() => {
                            setShow(false)
                        }, 2000)
                    }
                    setUploadingPB(false)
                  })
                } catch (error) {
                    setShow(true)
                    setError(t('File upload error!'))
                    setVriants('danger')
                    setTimeout(() => {
                        setShow(false)
                    }, 2000)
                    setUploadingPB(false)
                }
              }
        }else{
            setErrorFile('F4')
        }
    }
    //*
    const [uploadingV, setUploadingV] = useState(false)
    const handleUploadVisa = () => {
        inputRefVisa.current?.click()
    }
    const handleDisplayFileDetailsVisa = () => {        
        const saveFile = checkFileDetails(inputRefVisa)
        // !filesState?.includes(saveFile?.name) ? setVisa(saveFile) : setErrorFile('F5')

        if(!filesState?.includes(saveFile?.name) && saveFile?.type){
            if(saveFile.type === 'application/pdf'){ 
                setVisa(saveFile)    
              }else{
                setUploadingV(true)
                try {
                  validateAndCompressImage(saveFile).then((compressResponse) => {
                    if (compressResponse?.success) {
                        setVisa(compressResponse.file)
                    } else {
                        setShow(true)
                        setError(t(compressResponse?.message || 'File compression error!'))
                        setVriants('danger')
                        setTimeout(() => {
                            setShow(false)
                        }, 2000)
                    }
                    setUploadingV(false)
                  })
                } catch (error) {
                    setShow(true)
                    setError(t('File upload error!'))
                    setVriants('danger')
                    setTimeout(() => {
                        setShow(false)
                    }, 2000)
                    setUploadingV(false)
                }
              }
        }else{
            setErrorFile('F5')
        }
    }
    //*
    const handleUploadLetter = () => {
        inputRefLetter.current?.click()
    }
    const handleDisplayFileDetailsLetter = () => {
        const saveFile = checkFileDetails(inputRefLetter)
        // !filesState?.includes(saveFile?.name) ? setLetter(saveFile) : setErrorFile('F6')
        if(!filesState?.includes(saveFile?.name) && saveFile?.type){
            if(saveFile.type === 'application/pdf'){ 
                setLetter(saveFile)    
              }else{
                setLetter(true)
                try {
                  validateAndCompressImage(saveFile).then((compressResponse) => {
                    if (compressResponse?.success) {
                        setLetter(compressResponse.file)
                    } else {
                        setShow(true)
                        setError(t(compressResponse?.message || 'File compression error!'))
                        setVriants('danger')
                        setTimeout(() => {
                            setShow(false)
                        }, 2000)
                    }
                    setLetter(false)
                  })
                } catch (error) {
                    setShow(true)
                    setError(t('File upload error!'))
                    setVriants('danger')
                    setTimeout(() => {
                        setShow(false)
                    }, 2000)
                    setLetter(false)
                }
              }
        }else{
            setErrorFile('F6')
        }
    }

    useEffect(() => {
        getMyDocs().then((data) => {
            if (data.code === 200 && data.errorCode != 1) {
                setMyDocs(data)
            } else {

            }
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setErrorFile('')
        }, 2000)
    }, [ErrorFile])

    useEffect(() => {
        const filesUpdated = (
            [
                emiratesIdFront != undefined && emiratesIdFront?.name,
                emiratesIdBack != undefined && emiratesIdBack?.name,
                passport != undefined && passport?.name,
                passportBack != undefined && passportBack?.name,
                visa != undefined && visa?.name,
                letter != undefined && letter?.name
            ]
        )
        setFilesState(filesUpdated)

    }, [emiratesIdFront, emiratesIdBack, passport, passportBack, visa, letter])

    const checkFileDetails = (checkFile: any) => {
        let fileSize: any = checkFile?.current?.files[0]?.size <= 5242880
        // let fileSize: any = checkFile?.current?.files[0]?.size <= 2101546
        let isAllowedType: any =
            checkFile?.current?.files[0]?.type === 'image/png' ||
            checkFile?.current?.files[0]?.type === 'image/jpeg' ||
            checkFile?.current?.files[0]?.type === 'image/jpg' ||
            checkFile?.current?.files[0]?.type === 'application/pdf'
        if (!isAllowedType) {
            setShow(true)
            setError(t("File is not a png/jpeg/jpg/PDF file"))
            setVriants('danger')
            setTimeout(() => {
                setShow(false)
            }, 2000)
        }
        if (!fileSize) {
            setShow(true)
            setError(t("File size should be less than 5MB"))
            setVriants('danger')
            setTimeout(() => {
                setShow(false)
            }, 2000)
        }
        if (isAllowedType && fileSize) {
            // checkFile.current?.files && setUploadedFileInvoice(checkFile.current.files[0])
            return checkFile.current.files[0]
        }
        else if (!isAllowedType) {
            setShow(true)
            setError(t("Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf"))
            setVriants('danger')
            setTimeout(() => {
                setShow(false)
            }, 2000)
        }
        else if (!fileSize) {
            setShow(true)
            setError(t("The file is too large! Max allowed size is 5MB"))
            setVriants('danger')
            setTimeout(() => {
                setShow(false)
            }, 2000)
        }
            
    }
    useEffect(() => {
        props.setUploading(uploadingEF || uploadingEB || uploadingPF || uploadingPB || uploadingV)
    }, [uploadingEF, uploadingEB, uploadingPF, uploadingPB, uploadingV])
    uploadDocuments(
        {
            emiratesIdFront: emiratesIdFront?.name ? emiratesIdFront : null,
            emiratesIdFrontAlreadyExists,
            emiratesIdBack: emiratesIdBack?.name ? emiratesIdBack : null,
            emiratesIdBackAlreadyExists,
            passport: passport?.name ? passport : null,
            passportAlreadyExists,
            passportBack: passportBack?.name ? passportBack : null,
            passportBackAlreadyExists,
            visa: visa?.name ? visa : null,
            letter: letter?.name ? letter : null,
        }
    )
    const handleView = (data: any) => {
        setViewImage(data)
    }
    return (
        <div className="row">
            <div className="col-md-12">
                {/*<div className="col-12">*/}
                {/*<h3 className="upload-title">Documents to Upload</h3>*/}
                {/*</div>*/}
                {show ? (
                    <Alert className='font-14 fw-bold mb-8' variant={variants}>
                        {error}!
                    </Alert>
                ) : null}
                <div className="row em-row py-6">
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> {t("Emirates ID (Front)")} <span className="text-danger">*</span> </label>
                            {eIdFrontImage ? (
                                // <button
                                //     className='border-0 bg-transparent'
                                //     data-bs-target='#view-popUp-contract'
                                //     data-bs-toggle='modal'
                                //     // data-bs-dismiss='modal'
                                //     type='submit'
                                // onClick={() => {
                                //     handleView(eIdFrontImage)
                                // }}
                                // >
                                //     {/* <i className='bi bi-eye text-black font-17'></i> */}
                                // </button>
                                    <span className="text-black"> {<img src={eIdFrontImage?.filename} className="img-fluid va-baseline" />} </span>
                            ) : <>
                                    <input ref={inputRefEmiratesIdFront} onChange={handleDisplayFileDetailsEmiratesIdFront} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                                    <button onClick={handleUploadEmiratesIdFront} disabled={uploadingEF} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${emiratesIdFront?.name ? "primary active" : "primary"}`}>
                                        <span className="text-black"> {uploadingEF ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : emiratesIdFront?.name ? emiratesIdFront?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                    </button>
                                </>}
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> {t("Emirates ID (Back)")} <span className="text-danger">*</span> </label>
                            {eIdBackImage ? (
                                // <button
                                //     className='border-0 bg-transparent'
                                //     data-bs-target='#view-popUp-contract'
                                //     data-bs-toggle='modal'
                                //     // data-bs-dismiss='modal'
                                //     type='submit'
                                //     onClick={() => {
                                //         handleView(eIdBackImage)
                                //     }}
                                // >
                                //     {/* <i className='bi bi-eye text-black font-17'></i> */}
                                // </button>
                                    <span className="text-black"> {<img src={eIdBackImage?.filename} className="img-fluid va-baseline" />} </span>
                            ) : <>
                                    <input ref={inputRefEmiratesIdBack} onChange={handleDisplayFileDetailsEmiratesIdBack} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                                    <button onClick={handleUploadEmiratesIdBack} disabled={uploadingEB} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${emiratesIdBack?.name ? "primary active" : "primary"}`}>
                                        <span className="text-black"> {uploadingEB ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : emiratesIdBack?.name ? emiratesIdBack?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                    </button>
                                </>}
                        </div>
                        <p style={{ color: "red" }}> {ErrorFile?.includes('F2') ? t("You cannot upload the same file again") : ''}</p>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> {t("Passport (Front)")}  <span className="text-danger">*</span> </label>
                            {pIdFrontImage ? (
                                // <button
                                //     className='border-0 bg-transparent'
                                //     data-bs-target='#view-popUp-contract'
                                //     data-bs-toggle='modal'
                                //     // data-bs-dismiss='modal'
                                //     type='submit'
                                //     onClick={() => {
                                //         handleView(pIdFrontImage);
                                //     }}
                                // >
                                //     {/* <i className='bi bi-eye text-black font-17'></i> */}
                                // </button>
                                    <span className="text-black"> {<img src={pIdFrontImage?.filename} className="img-fluid va-baseline" />} </span>
                            ) : <>
                                    <input ref={inputRefPassport} onChange={handleDisplayFileDetailsPassport} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                                    <button onClick={handleUploadPassport} disabled={uploadingPF} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${passport?.name ? "primary active" : "primary"}`}>
                                        <span className="text-black"> {uploadingPF ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : passport?.name ? passport?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                    </button>
                                </>}
                        </div>
                        <p style={{ color: "red" }}>{ErrorFile?.includes('F3') ? t("You cannot upload the same file again") : ''}</p>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> {t("Passport (Back)")}  <span className="text-danger">*</span> </label>
                            {pIdBackImage ? (
                                // <button
                                //     className='border-0 bg-transparent'
                                //     data-bs-target='#view-popUp-contract'
                                //     data-bs-toggle='modal'
                                //     // data-bs-dismiss='modal'
                                //     type='submit'
                                //     onClick={() => {
                                //         handleView(pIdBackImage)
                                //     }}
                                // >
                                //     {/* <i className='bi bi-eye text-black font-17'></i> */}
                                // </button>
                                    <span className="text-black"> {<img src={pIdBackImage?.filename} className="img-fluid va-baseline" />} </span>
                            ) : <>
                                    <input ref={inputRefPassportBack} onChange={handleDisplayFileDetailsPassportBack} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                                    <button onClick={handleUploadPassportBack} disabled={uploadingPB} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${passportBack?.name ? "primary active" : "primary"}`}>
                                        <span className="text-black"> {uploadingPB ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : passportBack?.name ? passportBack?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                    </button>
                                </>}
                        </div>
                        <p style={{ color: "red" }}>{ErrorFile?.includes('F4') ? t("You cannot upload the same file again") : ''}</p>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-3 upload-input">
                            <label className="form-label text-black"> {t("Visa")}  </label>
                            <input ref={inputRefVisa} onChange={handleDisplayFileDetailsVisa} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                            <button onClick={handleUploadVisa} disabled={uploadingV} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${visa?.name ? "primary active" : "primary"}`}>
                                <span className="text-black"> {uploadingV ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : visa?.name ? visa?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                        <p style={{ color: "red" }}>{ErrorFile?.includes('F5') ? t("You cannot upload the same file again") : ''}</p>
                    </div>
                    {offerList?.modeOfPayment === "PAY_BY_EMP" ? (
                        <div className="col-sm-6 col-12">
                            <div className="mb-3 upload-input">
                                <label className="form-label text-black"> {t("Letter of Intent")} <span className="text-danger">*</span>  </label>
                                <input ref={inputRefLetter} onChange={handleDisplayFileDetailsLetter} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />
                                <button onClick={handleUploadLetter} className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${letter?.name ? "primary active" : "primary"}`}>
                                    <span className="text-black"> {letter?.name ? letter?.name : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                                </button>
                            </div>
                            <p style={{ color: "red" }}>{ErrorFile?.includes('F6') ? t("You cannot upload the same file again") : ''}</p>
                        </div>) : null}
                </div>
            </div>

            <ViewPopup viewImage={viewImage} />
        </div>
    );
}