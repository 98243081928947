import React from 'react'
import { Link } from 'react-router-dom'
import Home from '../../../../assets/images/my-unit.png'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


export function EarlyTermination({ contract }: any) {
  const { t } = useTranslation();

  return (
    <div className='com-card-section myunit-page'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/my-services/openissues'} className=''>
              <img src={LeftArrow} alt="Back" className='img-fluid me-5' />
            </Link>
            {t("Early Termination")}
          </h4>
        </div>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>

                <div className='col-md-4 col-lg-3 mb-md-0 mb-4'>
                  <div className='approval-move'>
                    <img src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage} alt='Home' className='img-fluid' />
                  </div>
                </div>
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card bg-white rounded'>
                    <div className='card-body px-0 pt-0 pb-2'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='row align-items-top pb-7'>
                            <div className='col-sm-6 col-6'>
                              <span className='fw-bold font-14'> {t("My Unit Details")} </span>
                            </div>
                            <div className='col-sm-6 col-6 ps-lg-20 text-end'>
                              <a
                                      href={contract?.contractFile}
                              className='text-decoration-underline text-primary fw-bold view-all font-14'
                              target='_blank'
                              rel='noreferrer'
                              >
                              <span> {t("View Agreement")} </span>
                            </a>
                          </div>
                        </div>
                      </div>
                        <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Lease Number")} </h6>
                          <span className='text-black fw-bold font-14'>
                            {' '}
                            {contract?.contractNo}{' '}
                          </span>
                        </div>
                        <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-6'>
                          <div className=''>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease Start Date")}{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            {t("Lease End Date")}{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'>
                            {' '}
                            {moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '}
                          </span>
                        </div>
                        <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("No of Installments")} </h6>
                          <span className='text-black fw-bold font-14'>
                            {' '}
                            {contract?.noOfPayments}{' '}
                          </span>
                        </div>
                        <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Annual Rent")} </h6>
                          <span className='text-black fw-bold font-14'> {t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                        </div>
                        <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                            {' '}
                            {t("Security Deposit")}{' '}
                          </h6>
                          <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
