import React, { useEffect } from 'react'
import { useState } from 'react'
import { PhotoViewPopup } from './photoViewPopup'
import { ServiceDataInfo } from './ServiceDataInfo'
import { useTranslation } from 'react-i18next'

export function ParkingIssueRequestDetail({ selectedIssue }: any) {
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])

  return (
    <div className='com-card-section'>
      {!loading && issue && issue?.code == 'PI' ? (
        <div className='row'>
          <div className='col-12'>
            <div className='accordion accordion-flush' id='two'>
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header position-relative' id='flush-headingOne'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapse2'
                    aria-expanded='false'
                    aria-controls='flush-collapseOne'
                  >
                    {t("Parking Issue Request Detail")}
                  </button>
                  <span className='services-badge'>
                    {issue?.status == 0 ? t("Open") : t("Closed")}
                  </span>
                </h2>
                <div
                  id='flush-collapse2'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingOne'
                  data-bs-parent='#two'
                >
                  <div className='accordion-body py-5'>
                    <div className='row form-add-design'>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Parking Issue")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={issue?.issue}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Photo")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={
                              issue?.images && Array.isArray(issue.images) && issue?.images?.length
                                ? issue.images.length
                                : 0
                            }
                          />
                          <>
                            {issue?.images &&
                              Array.isArray(issue.images) &&
                              issue?.images?.length && <PhotoViewPopup images={issue?.images} />}
                          </>
                        </div>
                      </div>
                      <div className='col-md-12 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Comments")}</label>
                          <textarea
                            className='form-control fw-bolder form-control-solid'
                            data-kt-autosize='true'
                            rows={3}
                            disabled
                            placeholder={issue?.details?.description}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <ServiceDataInfo issue={issue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
