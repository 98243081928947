import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import DatePicker from 'react-date-picker';
import IntlTelInput from 'react-intl-tel-input';
import { Link } from 'react-router-dom';  
import SuccessPopup from './SuccessPopup';
// import moment, { min } from 'moment'

export const AddLeadsForm: React.FC<any> = () => {
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation()
    const profileDetailsSchema = Yup.object().shape({
        // firstname: Yup.string().required('First Name is required'),
        // email: Yup.string()
        //     .email(t('Wrong email format'))
        //     .min(3, (t('Minimum 3 symbols')))
        //     .max(50, (t('Maximum 50 symbols')))
        //     .required(t('Email is required')),
        // alternate_email: Yup.string()
        //     .email(t('Wrong email format'))
        //     .min(3, (t('Minimum 3 symbols')))
        //     .max(50, (t('Maximum 50 symbols'))),
        // mobileNumberField: Yup.string()
        //     .min(4, t('Mobile No should be greater then 4 digits!'))
        //     .max(16, t('Mobile No should be smaller then 16 digits!'))
        //     .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
        //     .required(t('Mobile No is required!')),
        // dob: Yup.date().nullable().required('DOB is required'),
        // nationality: Yup.string().required('Nationality is required'),
        // emergency_contact_no: Yup.string()
        //     .min(4, t('Emergency Contact Number should be greater then 4 digits!'))
        //     .max(16, t('Emergency Contact Number should be smaller then 16 digits!'))
        //     .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!')),
        // emergency_contact_name: Yup.string()
        //     .matches(/^[^\s].*/, t('Contact Name cannot start with a blankspace!'))
        //     .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
        //     .min(3, t('Minimum 3 characters'))
        //     .max(25, t('Maximum 25 characters!')),
        // emergency_email: Yup.string()
        //     .email(t('Wrong email format'))
        //     .min(3, (t('Minimum 3 symbols')))
        //     .max(50, (t('Maximum 50 symbols')))
        //     .required(t('Email is required')),

        // amount: Yup.string().required('Amount is required'),
        // // broker_name: Yup.string().required('broker_name is required'),
        // discount: Yup.string().required('Discount is required'),
        // tax: Yup.string().required('Tax is required'),
        // charge_value: Yup.string().required('Charge Value is required'),

        // date_of_enquiry: Yup.date().nullable().required('To Date is required'),
        // due_date: Yup.date().nullable().required('Due Date is required'),
    })

    const initialValues = {
        firstname: '',
        email: '',
        mobileNumberField: "",
        dob: null,
        nationality: '',
        emergency_contact_no: '',
        emergency_contact_name: '',
        emergency_email: '',

        amount: '',
        broker_name: '',
        discount: '',
        tax: '',
        charge_value: '',
        available_properties: '',
        date_of_enquiry: null,
        due_date: null
    };


    const [mobileCode, setMobileCode] = useState<any>('')
    const [emgContactCode, setEmgContactCode] = useState<any>('')

    const formik = useFormik({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            // console.log(values);
            setShowModal(true);
        },
    })
    
    const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
        setMobileCode(country?.dialCode && !phoneNumber.includes('+') ? `+${country?.dialCode}` : '')
        formik.setFieldValue('mobileNumberField', phoneNumber)
        formik.setFieldTouched('mobileNumberField')
    }
    const handleEmergencyContactNumber = (status: any, phoneNumber: any, country: any) => {
        setEmgContactCode(country?.dialCode && !phoneNumber.includes('+') ? `+${country?.dialCode}` : '')
        formik.setFieldValue('emergency_contact_no', phoneNumber)
        formik.setFieldTouched('emergency_contact_no')
    }

    const [fromDate, setFromDate] = useState<any>()
    const [toDate, setToDate] = useState<any>()
    const [dueDate, setDueDate] = useState<any>()
    

    const handleFromDateChange = (date: any) => {
        setFromDate(date);
    };
    const handleToDateChange = (date: any) => {
        setToDate(date);
    };
    const handleDateChange = (date: any) => {
        setDueDate(date);
    };
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <div className='col-12 mt-0'>
                <div className='card mb-3'>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body px-8 py-6'>
                            <div className='row form-add-design'>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                    <div className="mb-8">
                                        <label className='form-label fw-bolder'>First Name <span className="text-danger">*</span></label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='First Name'
                                            {...formik.getFieldProps('firstname')}
                                        />
                                        {formik.touched.firstname && formik.errors.firstname && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block text-danger'>{formik.errors.firstname}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                    <div className="mb-8">
                                        <label className='form-label fw-bolder'>Last Name  </label>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Last Name'
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                    <div className="mb-8">
                                        <label className='form-label fw-bolder'>Email <span className="text-danger">*</span></label>
                                        <input
                                            type='email'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Email'
                                            {...formik.getFieldProps('email')}
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block text-danger'>{formik.errors.email}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-8">
                                        <label className="form-label fw-bolder">{t("Mobile Number")}
                                            <span className="text-danger"> * </span>
                                        </label>
                                        <IntlTelInput
                                            preferredCountries={['ae', 'in', 'us']}
                                            containerClassName='intl-tel-input w-100'
                                            separateDialCode={true}
                                            fieldName='mobile_no'
                                            value={formik.values.mobileNumberField}
                                            inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                            onPhoneNumberChange={handlePhoneNumber}
                                            onPhoneNumberBlur={handlePhoneNumber}
                                            placeholder={t('Mobile No.')}
                                        />
                                        {formik.touched.mobileNumberField && formik.errors.mobileNumberField ? (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.mobileNumberField}</small>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12 date-picker'>
                                    <div className="mb-8">
                                        <label className='form-label fw-bolder'>DOB <span className="text-danger"> * </span></label>
                                        <DatePicker
                                            onChange={(date: any) => formik.setFieldValue('dob', date)}
                                            value={formik.values.dob}
                                            className='form-control form-control-lg form-control-solid'
                                            clearIcon={null}
                                        />
                                        {formik.touched.dob && formik.errors.dob && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block text-danger'>{formik.errors.dob}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                    <div className="mb-8">
                                        <label className='form-label fw-bolder'>Nationality <span className="text-danger"> * </span></label>
                                        <select
                                            className="form-control form-control-lg form-control-solid form-select"
                                            {...formik.getFieldProps('nationality')}
                                        >
                                            <option value="" label="Select Nationality" />
                                            <option value="app1" label="United Arab Emirates" />
                                            <option value="app2" label="India" />
                                        </select>
                                        {formik.touched.nationality && formik.errors.nationality && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block text-danger'>{formik.errors.nationality}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-8">
                                        <label className="form-label fw-bolder">{t("Emergency Contact Name")} <span className="text-danger"> * </span></label>
                                        <input
                                            type="text"
                                            className="form-control fw-bolder form-control-solid"
                                            placeholder={t("Emergency Contact Name")}
                                            {...formik.getFieldProps('emergency_contact_name')}
                                        />
                                        {formik.touched.emergency_contact_name && formik.errors.emergency_contact_name && (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.emergency_contact_name}</small>
                                            </div>

                                        )}
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                    <div className="mb-8">
                                        <label className='form-label fw-bolder'>Emergency Contact Email <span className="text-danger">*</span></label>
                                        <input
                                            type='email'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Email'
                                            {...formik.getFieldProps('emergency_email')}
                                        />
                                        {formik.touched.emergency_email && formik.errors.emergency_email && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block text-danger'>{formik.errors.emergency_email}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-6 col-xl-4 col-12">
                                    <div className="mb-8">
                                        <label className="form-label fw-bolder">{t("Emergency Contact Number")}</label>
                                        <IntlTelInput
                                            preferredCountries={['ae', 'in', 'us']}
                                            containerClassName='intl-tel-input w-100'
                                            separateDialCode={true}
                                            fieldName='emergency_contact_no'
                                            value={formik.values.emergency_contact_no}
                                            inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                            onPhoneNumberChange={handleEmergencyContactNumber}
                                            onPhoneNumberBlur={handleEmergencyContactNumber}
                                            placeholder={t('Emergency Contact')}
                                        />
                                        {/* <PhoneInput
                                                country={'ae'}
                                                preferredCountries={['ae', 'in', 'us']}
                                                onlyCountries={['ae', 'in', 'us']}
                                                priority={{ ae: 0, in: 1, us: 2 }}
                                                value={formik.values.emergency_contact_no}
                                                onChange={handleEmergencyContactNumber}
                                                enableSearch={true}
                                                enableAreaCodeStretch={true}
                                                inputClass={"text-black"}
                                            /> */}
                                        {formik.touched.emergency_contact_no && formik.errors.emergency_contact_no && (
                                            <div className='d-flex flex-column text-danger'>
                                                <small className="text-danger">{formik.errors.emergency_contact_no}</small>
                                            </div>

                                        )}
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12 date-picker'>
                                    <div className="mb-8">
                                        <label className="form-label fw-bolder"> Date of Enquiry </label>
                                        <DatePicker
                                            onChange={(date: any) => formik.setFieldValue('date_of_enquiry', date)}
                                            value={formik.values.date_of_enquiry}
                                            // {...formik.getFieldProps('date_of_enquiry')}
                                            className='form-control form-control-lg form-control-solid'
                                            clearIcon={null}
                                        />
                                        {formik.touched.date_of_enquiry && formik.errors.date_of_enquiry && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block text-danger'>{formik.errors.date_of_enquiry}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                    <div className="mb-8">
                                        <label className="form-label fw-bolder"> Broker Name</label>
                                        <select
                                            className="form-control form-control-lg form-control-solid form-select"
                                            {...formik.getFieldProps('broker_name')}
                                        >
                                            <option value="" label="Select Broker" />
                                            <option value="app1" label="James Potter" />
                                            <option value="app2" label="Nisar   " />
                                            <option value="app3" label="Mohammed Ali" />
                                        </select>
                                        {formik.touched.broker_name && formik.errors.broker_name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block text-danger'>{formik.errors.broker_name}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                    <div className="mb-8">
                                        <label className="form-label fw-bolder"> Available Properties </label>
                                        <select
                                            className="form-control form-control-lg form-control-solid form-select"
                                            {...formik.getFieldProps('available_properties')}
                                        >
                                            <option value="" label="Select Available Properties" />
                                            <option value="USR-C88S-04-0405" label="USR-C88S-04-0405" />
                                            <option value="USR-C88O-18-1804" label="USR-C88O-18-1804" />
                                            <option value="USR-C88O-07-0704" label="USR-C88O-07-0702" />
                                            <option value="USR-C88O-06-0603" label="USR-C88O-06-0603" />
                                        </select>
                                        {formik.touched.available_properties && formik.errors.available_properties && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block text-danger'>{formik.errors.available_properties}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-md-12 col-sm-6 col-xl-12 col-12 date-picker'>
                                    <div className="mb-8">
                                        <label className="form-label fw-bolder"> Notes</label>
                                        <textarea className='form-control form-control-lg form-control-solid'
                                            rows={3}
                                            placeholder={t("Type here...")} />
                                    </div>
                                </div>
                                <div className="col-12 text-end mt-5">
                                    {/* <Link to={'/mta-myteam/list/pending'} className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'>
                                            {t("Cancel")}
                                        </Link> */}
                                    <Link to={'/leads/list'}
                                        className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'>
                                        {t("Cancel")}
                                    </Link>
                                    <button
                                        type='submit'
                                        className='btn btn-primary fw-bold px-10 py-3'
                                    >
                                        {/* {submitStatus.loading ? 
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    {t("Please wait...")}{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                                :
                                                
                                            } */}
                                        <span className='indicator-label'>{t("Submit")}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div >
            </div >
            <SuccessPopup show={showModal} onClose={handleCloseModal}  message="Added successfully"  />
        </>
    )
}
