import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getNationalities } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'

export function BasicDetails({ issue }: any) {
    const [nationality, setNationality] = useState<any>()
    const { t } = useTranslation();

    useEffect(() => {
        getNationalities().then(({ data }) => {
            const selectedNationality = data?.find((el: any) => el.id_nationality == issue?.details?.nationality)
            setNationality(selectedNationality.country)
        })
    }, [])

    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    {t("Basic Details")}
                </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body bg-white">
                    <div className="row form-add-design">
                        <div className="col-md-4 col-12">
                            <div className="mb-10">
                                <label className="form-label fw-bolder d-block">{t("Full Name")}</label>
                                <span className='text-black fw-bold font-14'>{issue?.details?.name}</span>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="mb-10">
                                <label className="form-label fw-bolder d-block">{t("Date of Birth")}</label>
                                <span className='text-black fw-bold font-14'>{moment(issue?.details?.DOB).format('DD-MM-YYYY')}</span>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="mb-10">
                                <label className="form-label fw-bolder d-block">{t("Nationality")}</label>
                                <span className='text-black fw-bold font-14'>{nationality}</span>
                            </div>
                        </div>
                        {issue?.details?.spouseName &&
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder d-block">{t("Spouse Name")}</label>
                                    <span className='text-black fw-bold font-14'>{issue?.details?.spouseName}</span>
                                </div>
                            </div>
                        }
                        {issue?.details?.childName &&
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder d-block">{t("Children Names")}</label>
                                    <span className='text-black fw-bold font-14'>{issue?.details?.childName?.split(',')?.map((child: any) => (
                                        <p>{child}</p>
                                    ))}</span>
                                </div>
                            </div>
                        }
                        {issue?.details?.po_box &&
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder d-block">{t("PO Box")}</label>
                                    <span className='text-black fw-bold font-14'>{issue?.details?.po_box}</span>
                                </div>
                            </div>
                        }
                        {issue?.details?.telNumber &&
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder d-block">{t("Tel. Number")}</label>
                                    <span className='text-black fw-bold font-14'>{issue?.details?.telNumber}</span>
                                </div>
                            </div>
                        }
                        {issue?.details?.mobile &&
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder d-block">{t("Mobile Number")}</label>
                                    <span className='text-black fw-bold font-14'>{issue?.details?.mobile}</span>
                                </div>
                            </div>
                        }
                        <div className="col-md-4 col-12">
                            <div className="mb-10">
                                <label className="form-label fw-bolder d-block">{t("Email Address")}</label>
                                <span className='text-black fw-bold font-14'>{issue?.details?.email}</span>
                            </div>
                        </div>
                        {issue?.details?.companyName &&
                            <div className="col-md-4 col-12">
                                <div className="mb-10">
                                    <label className="form-label fw-bolder d-block">{t("Company Name")}</label>
                                    <span className='text-black fw-bold font-14'>{issue?.details?.companyName}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}