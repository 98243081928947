import { Faq } from '../components/faq/Faq'
import AnnouncementsPage from '../pages/announcements/AnnouncementsPage'
import { AnnouncementsDetails } from '../pages/announcements/components/AnnouncementsDetails/AnnouncementsDetails'
import { DocumentsPage } from '../pages/documents/DocumentsPage'
import { OtherDocumentsPage } from '../pages/other-documents/OtherDocumentsPage'
import { FCDashboardWrapper } from '../pages/fc-dashboard/FCDashboardWrapper'
import { FCReportsWrapper } from '../pages/fc-reports/FCReportsWrapper'
import { SupervisorCreateAnnouncements } from '../pages/fc-supervisor-announcements/add/CreateAnnouncements/CreateAnnouncements'
import { FCSupervisorAnnouncementWrapper } from '../pages/fc-supervisor-announcements/FCSupervisorAnnouncements'
import { FCSupervisorCalenderWrapper } from '../pages/fc-supervisor-calender/FCSupervisorCalenderWrapper'
import { AddTechnicianPage } from '../pages/fc-supervisor-myteam/components/Add/AddTechnician'
import { FCSMyTeamDetailsWrapper } from '../pages/fc-supervisor-myteam/FCSMyTeamDetails/FCSMyTeamDetailsWrapper'
import { MaintenancePage } from '../pages/maintenance/MaintenancePage'
import { MyServicePage } from '../pages/my-services/MyServicePage'
import MyUnitWrapper from '../pages/my-unit/MyUnitWrapper'
import { PaymentsPage } from '../pages/payments/PaymentsPage'
import { PTActivityWrapper } from '../pages/pt-activity/PTActivityWrapper'
import { CreateAlerts } from '../pages/pt-alerts/components/CreateAlerts/CreateAlerts'
import { PTAlertsWrapper } from '../pages/pt-alerts/PTAlertsWrapper'
import { PTDocumentsWrapper } from '../pages/pt-documents/PTDocumentsWrapper'
import { PTFaq } from '../pages/pt-faq/Faq'
import { PTMarketPlaceWrapper } from '../pages/pt-market-place/PTMarketPlaceWrapper'
import { PTSettings } from '../pages/pt-settings/PTSettings'


export default [

    {//Documents
		component: DocumentsPage,	//* aside menu component
		path: '/documents',//* aside menu path
		menuCode: 'My Documents',//* API code/name
		menu: {
			menuPath: '/documents/emirates-id',//* default path
			title: 'My Documents',//* title
			iconClass: 'my-documents-icon-menu',//*icon class
			profileIconClass: 'item-icon doc-ioc',//*profile dropdown icon class
			iconFile: '/media/icons/Dashboard_InActive.png',//*Icon name 
			supportedComponents: [],//**Inner pages */
			dynaimcPaths: [
				'/documents/passport',//* tab urls
				'/documents/driving-license',
                '/documents/emirates-id'
			],
		},
		exact: true,
		permission: [
			'T','BA','CT'
		],
	},

	{//Documents
		component: OtherDocumentsPage,	//* aside menu component
		path: '/other-documents',//* aside menu path
		menuCode: 'My Documents',//* API code/name
		menu: {
			menuPath: '/other-documents',//* default path
			title: 'Other Documents',//* title
			iconClass: 'my-documents-icon-menu',//*icon class
			profileIconClass: 'item-icon doc-ioc',//*profile dropdown icon class
			iconFile: '/media/icons/Dashboard_InActive.png',//*Icon name 
			supportedComponents: [],//**Inner pages */
			dynaimcPaths: [
				'/other-documents'
			],
		},
		exact: true,
		permission: [
			'T','BA','CT'
		],
	},

	{//My Offers
		component: PTActivityWrapper,
		path: '/pt-activity',
		menuCode: 'My Offers',
		menu: {
			menuPath: '/pt-activity/my-offer',
			title: 'My Activity',
			iconClass: 'activity-icon-menu',
			iconFile: '/media/icons/myactivity-inactive.png',
            profileIconClass: 'item-icon my-activity-ioc',//*profile dropdown icon class
			dynaimcPaths: [
				'/pt-activity/my-visit/upcoming',
				'/pt-activity/my-visit/visited',
				'/pt-activity/my-offer',
				'/pt-activity/my-shortlist',
				'/pt-activity/my-contracts',
				'/pt-activity/my-contracts-details'
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'T','CT'
		],
	},
    
    {//My Alerts
		component: PTAlertsWrapper,
		path: '/pt-alerts',
		menuCode: 'My Alerts',
		menu: {
			menuPath: '/pt-alerts',
			title: 'My Alerts',
			iconClass: 'alert-icon-menu',
			iconFile: '/media/icons/alert-inactive.png',
            profileIconClass: 'item-icon my-alert-ioc',//*profile dropdown icon class
			dynaimcPaths: [
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'T','BA','CT'
		],
	},

	{ // FAQ
		component: PTFaq,
		path: '/pt-faq',
		menuCode: 'FAQ',
		menu: {
			menuPath: '/pt-faq',
			title: 'FAQ',
			iconClass: 'faq-icon-menu',
			iconFile: '/media/icons/faq-inactive.png',
            profileIconClass: 'item-icon quest-ioc',//*profile dropdown icon class
			dynaimcPaths: [
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'BA','CT'
		],
	},
	
	{ // Settings
		component: PTSettings,
		path: '/pt-settings',
		menuCode: 'Settings',
		menu: {
			menuPath: '/pt-settings',
			title: 'Settings',
			iconClass: 'settings-icon-menu',
			iconFile: '/media/icons/settings-inactive.png',
            profileIconClass: 'item-icon settings-ioc',//*profile dropdown icon class
			dynaimcPaths: [
			],
			supportedComponents: [
			],
		},
		exact: true,
		permission: [
			'BA','CT'
		],
	},
	
	
]