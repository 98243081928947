/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../components/layout/core'
import { PaymentsFilter } from './components/PaymentsFilter/PaymentsFilter'
import { PaymentTabs } from './components/PaymentTabs/PaymentTabs'
import { RentList } from './components/RentList/RentList'
import { SecurityDepositList } from './components/SecurityDepositList/SecurityDepositList'
import { ServiceChargesList } from './components/ServiceChargesList/ServiceChargesList'
import { OtherChargesList } from './components/OtherChargesList/OtherChargesList'
import { Statement } from './add/Statement/Statement'
import { PaymentMethodTabs } from './add/PaymentMethodTabs/PaymentMethodTabs'
import { CardPayment } from './add/CardPayment/CardPayment'
import { BankTransfer } from './add/BankTransfer/BankTransfer'
import { PayByCheque } from './add/PayByCheque/PayByCheque'
import { PaymentSuccessfulPopup } from '../SuccessfulPopup/PaymentSuccessfulPopup'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../constants'
import { getConfig, getOfferDetails, getPaymentHistory } from '../../constants/axios/apis'
import { ChequeDelayRequest } from './add/ChequeDelayRequest/ChequeDelayRequest'
import { ChequeReplaceRequest } from './add/ChequeReplaceRequest/ChequeReplaceRequest'
import { AllPaymentsList } from './components/AllPaymentsList/AllPaymentsList'

type Props = {
  contract?: any
  offerDetails?: any
  payments?: any
  details?: any
  filterPayments?: any
  setHasFilters?: any
  loading?: any
  setLoading?: any
  vatPercent?: any
}

const Payments: FC<Props> = ({ contract, offerDetails, payments, details, filterPayments, setHasFilters, loading, setLoading, vatPercent}: any) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
      <div className='col-xxl-12 mt-0'>
        <Switch>
          <Route path='/payments/all'>
            <PaymentsFilter contract={contract} filterPayments={filterPayments} setHasFilters={setHasFilters} setLoading={setLoading} />
            <PaymentTabs />
            <AllPaymentsList contract={contract} payments={payments} loading={loading} />
          </Route>
          <Route path='/payments/rents'>
            <PaymentsFilter contract={contract} filterPayments={filterPayments} setHasFilters={setHasFilters} setLoading={setLoading} />
            <PaymentTabs />
            <RentList contract={contract} payments={payments} loading={loading} />
          </Route>
          <Route path='/payments/security-deposits'>
            <PaymentsFilter contract={contract} filterPayments={filterPayments} setHasFilters={setHasFilters} setLoading={setLoading} />
            <PaymentTabs />
            <SecurityDepositList contract={contract} payments={payments} loading={loading} />
          </Route>
          <Route path='/payments/service-charges'>
            <PaymentsFilter contract={contract} filterPayments={filterPayments} setHasFilters={setHasFilters} setLoading={setLoading} />
            <PaymentTabs />
            <ServiceChargesList contract={contract} payments={payments} loading={loading} />
          </Route>
          <Route path='/payments/other-charges'>
            <PaymentsFilter contract={contract} filterPayments={filterPayments} setHasFilters={setHasFilters} setLoading={setLoading} />
            <PaymentTabs />
            <OtherChargesList contract={contract} payments={payments} loading={loading} />
          </Route>
          <Route path='/payments/statement'>
            <Statement property_id={contract?.propertyID} />
          </Route>
          <Route path='/payments/card-payment'>
            <PaymentMethodTabs details={details} />
            <CardPayment details={details} property_id={contract?.propertyID} vatPercent={vatPercent}/>
            <PaymentSuccessfulPopup />
          </Route>
          <Route path='/payments/bank-transfer'>
            <PaymentMethodTabs details={details} />
            <BankTransfer offerDetails={offerDetails} details={details} property_id={contract?.propertyID} vatPercent={vatPercent}/>
            <PaymentSuccessfulPopup />
          </Route>
          <Route path='/payments/pay-by-cheque'>
            <PaymentMethodTabs details={details} />
            <PayByCheque details={details} property_id={contract?.propertyID} vatPercent={vatPercent}/>
            <PaymentSuccessfulPopup />
          </Route>
          <Route path='/payments/cheque-delay-request'>
            <ChequeDelayRequest />
          </Route>
          <Route path='/payments/cheque-replace-request'>
            <ChequeReplaceRequest />
          </Route>
        </Switch>
      </div>
    </div>
  </>
)

const PaymentsPage: FC = () => {
  const contract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const [details, setDetails] = useState<any>()
  const [loading, setLoading] = useState<Boolean>(false)
  const [offerDetails, setOfferDetails] = useState<any>()
  const [vatPercent, setVatPercent] = useState<any>({loading: true, value : 0})

  const location = useLocation<any>()  //refresh list on load
  // if (!details && location?.state) {
  useEffect(()=>{
    if(location?.state){
        const {
          offer_id,
          object_id,
          property_id,
          amount,
          extra_charges_title,
          extra_charges_amount = 0,
          payment_category,
          payment_for,
          payment_type,
          upcoming_payment_id,
          upcoming_payment_plan_id,
          work_order_id,
          for: any,
        } = location?.state
        const total = amount + extra_charges_amount
        setDetails({
          offer_id,
          object_id,
          property_id,
          amount,
          total,
          extra_charges_title,
          extra_charges_amount,
          payment_category,
          payment_for,
          payment_type,
          upcoming_payment_id,
          upcoming_payment_plan_id,
          work_order_id,
          for: any,
        })
    }

  },[location])
  

  const [payments, setPayments] = useState<any>(undefined)
  const [hasFilters, setHasFilters] = useState<Boolean>(false)
  const getPayments = (start_date: any = '', end_date: any = '', pageSize = 100) => {
    setPayments([])
    getPaymentHistory(contract?.propertyID, start_date, end_date, pageSize).then(({ data }) => {
      if (start_date == '' && end_date == '') {
        setHasFilters(false)
      }
      if (!data) {
        setPayments([])
      } else {
        data
          .sort((a: any, b: any) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime()
          })
          .reverse()
        setPayments(data)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (contract && !hasFilters) {
      setLoading(true)
      getPayments()
    }
  }, [contract, location?.key])

  useEffect(() => {
    if (contract) {
      getOfferDetails(contract?.offer_id).then(response => {
        if (response.data) {
          setOfferDetails(response.data)
        }
      })
    }
    getConfig('').then(({data}: any) => {
      if (data && Array.isArray(data)) {
        const vat_per = data?.find((item: any) => item.key == 'vat_percentage').value
        setVatPercent(vat_per ? {loading: false, value: parseFloat(vat_per)} : {loading: false, value: 0})
      }
    })
  }, [contract])

  return (
    <>
      <PageTitle breadcrumbs={[]}> Maintenance </PageTitle>
      <Payments
        contract={contract}
        offerDetails={offerDetails}
        payments={payments}
        details={details}
        filterPayments={getPayments}
        setHasFilters={setHasFilters}
        loading={loading}
        setLoading={setLoading}
        vatPercent={vatPercent}
      />
    </>
  )
}

export { PaymentsPage }
