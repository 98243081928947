import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import doc from '../../../../assets/images/doc-icon.png'
const DocumentTabs: React.FC = () => {
    const location = useLocation()

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                            <Link
                                                className={`nav-link position-relative text-active-primary  ` +
                                                        (location.pathname === '/pt-documents/brn-orn-id' ?
                                                    'active' :
                                                    '')}
                                                    to='/pt-documents/brn-orn-id'>
                                                    <img src={doc} alt="icon" className="me-2" width="12" height="auto" /> BRN/ORN
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                        (location.pathname === '/pt-documents/document' && 'active')}
                                                    to='/pt-documents/document'>
                                                    <img src={doc} alt="icon" className="me-2" width="12" height="auto" /> Emirate/Passport/Visa
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                        (location.pathname === '/pt-documents/marketing' && 'active')}
                                                    to='/pt-documents/marketing'>
                                                    <img src={doc} alt="icon" className="me-2" width="12" height="auto" /> Marketing Materials
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {DocumentTabs}
