import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import RightArrow from "../../../../assets/images/Right_Arrow.png";
import { useSelector } from 'react-redux';
import { getBrokers } from '../../../../utils/makeRequest';
import { IState } from '../../../../utils/interface';
import { useTranslation } from 'react-i18next'
import { CustomLoader2 } from '../../../../constants/generics/customActios';
import ProfileAvatar from '../../../../assets/images/DefaultUser.png'

export function BrokersList() {
    const state: any = useSelector(state => state);
    const userToken = state?.auth?.accessToken;
    const [brokersList, setBrokersList] = useState<IState['APIState']>({
        loading: false,
        response: {},
        error: ''
    });
    const { loading, response: brokers, error } = brokersList;
    
    useEffect(() => {
        getBrokers(setBrokersList, userToken);
    }, []);

    const { t } = useTranslation();

    return (
        <div className="">
            <div className="row">
                <div className="col-12 pt-6">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-7 col-6">
                            {Object.keys(brokers).length !== 0 ? (
                                <h4 className="card-title font-17 mb-0"> {t("Brokers")} ({brokers?.teamList?.length}) </h4>
                            ) : (
                                <h4 className="card-title font-17 mb-0">{t("Broker")}</h4>
                            )}
                        </div>
                        <div className="col-lg-5 col-md-5 col-6 text-end ">
                            <Link to={'/broker-admin-MyTeam'} className="text-decoration-none view-all">
                                <span> {t("View All")} </span>
                                <img src={RightArrow} className="img-fluid" />
                            </Link>
                        </div>
                    </div>
                    <div className="row pt-8">
                        <div className="col-12">
                            <div className="card brokers-list rounded-10">
                                <div className="card-body p-5">
                                    {loading && (
                                        <CustomLoader2 />
                                    )}
                                    {Object.keys(brokers).length !== 0 &&

                                        brokers?.teamList?.slice(0,4)?.map((broker: any, index: number) => {
                                            return (
                                                <div className="mb-5 box" key={index + 1}>
                                                    <Link to={{ 'pathname': broker?.status === 1 && broker?.is_approved === 1 ? '/SingleBroker/in-progress' : '/SingleBroker/data/details', state: { 'broker': broker } }} >
                                                        <div className="row align-items-center">
                                                            <div className="col-md-3 col-3">
                                                                <div className="broker-av">
                                                                    <img src={broker?.profileImage ? broker?.profileImage : ProfileAvatar} className="img-fluid rounded-circle" style={{width: '54px'}}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-9 col-9">
                                                                <div className="broker-name">
                                                                    <h4 className="fw-normal font-14 ps-3">
                                                                    <span className={broker?.status === 1 && broker?.is_approved === 1 ? 'member-status active' : 'member-status inactive'}></span> {broker?.name ? broker?.name : '-'}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}