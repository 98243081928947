import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {TeamSearch} from '../Search/Search'
import {useTranslation} from 'react-i18next'

const VisitsPage: React.FC<any> = ({setSearchBroker, setSearchProperty}) => {
  const location = useLocation()
  const {t} = useTranslation()

  return (
    <div className='row'>
      <div className='col-md-12 col-12'>
        <div className='row'>
          <div className='col-12'>
            <h4 className='card-title mb-5'> {t('Team Visits')}</h4>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link pb-1 position-relative text-active-primary me-12 ` +
                      (location.pathname === '/visits/upcoming' && 'active')
                    }
                    to='/visits/upcoming'
                  >
                    {t('Upcoming')}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link pb-1 position-relative text-active-primary me-6 ` +
                      (location.pathname === '/visits/closed' && 'active')
                    }
                    to='/visits/closed'
                  >
                    {t('Visited Properties')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-sm-6'>
            <TeamSearch setSearchBroker={setSearchBroker} setSearchProperty={setSearchProperty} />
          </div>
        </div>
      </div>
    </div>
  )
}

export {VisitsPage}
