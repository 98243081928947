import React, {useState, useEffect} from 'react'
import Bath from '../../../../../assets/images/bath.svg'
import Sqft from '../../../../../assets/images/sqft-new-icon.svg'
import Bed from '../../../../../assets/images/bed.svg'
import Upcoming02 from '../../../../../assets/images/loc-theme.png'
import {useSelector} from 'react-redux'
import {getClosedVisits} from '../../../../../utils/makeRequest'
import {IState} from '../../../../../utils/interface'
import {useTranslation} from 'react-i18next'
import {CustomLoader2, NoDataFound1} from '../../../../../constants/generics/customActios'
import moment from 'moment'
import YesIcon from '../../../../../assets/images/yes-new-icon.svg'
import TickIcon from '../../../../../assets/images/Check_green.png'
import {AiFillCloseCircle} from 'react-icons/ai'

export const ClosedList: React.FC<any> = ({searchBroker, searchProperty}) => {
  const state: any = useSelector((state) => state)
  const userToken = state?.auth?.accessToken
  const {t} = useTranslation()
  const [closedVisits, setClosedVisits] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })
  const {loading, response: visits, error} = closedVisits

  useEffect(() => {
    getClosedVisits(setClosedVisits, userToken)
  }, [])

  const filteredVisits = visits
    .filter((items: any) => {
      return searchBroker === '' || (searchBroker !== '' && searchBroker === `${items?.broker_id}`)
    })
    .filter((items: any) => {
      const searchRegex = new RegExp(searchProperty, 'i')
      return (
        searchProperty === '' || (searchProperty !== '' && items?.propertyName?.match(searchRegex))
      )
    })

  if (!loading && (filteredVisits === undefined || filteredVisits.length === 0)) {
    return <NoDataFound1 />
  }
  const broker_array = [
    { brokerName: "Vishnu", mobile: "1234567890", name: "Bob" },
    { brokerName: "Amit", mobile: "0987654321", name: "Jane" },
    { brokerName: "Karan", mobile: "5678901234", name: "Eva" },
    { brokerName: "Vishnu", mobile: "2345678901", name: "Grace" },
    { brokerName: "Amit", mobile: "8901234567", name: "Charlie" },
    { brokerName: "Karan", mobile: "3456789012", name: "Hank" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Alice" },
    { brokerName: "Amit", mobile: "6789012345", name: "Frank" },
    { brokerName: "Karan", mobile: "7890123456", name: "David" },
    { brokerName: "Vishnu", mobile: "0123456789", name: "John" },
    { brokerName: "Amit", mobile: "9012345678", name: "Eva" },
    { brokerName: "Karan", mobile: "6789012345", name: "Bob" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Jane" },
    { brokerName: "Amit", mobile: "2345678901", name: "Charlie" },
    { brokerName: "Karan", mobile: "8901234567", name: "Hank" }
  ]
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12 pt-6 pb-6'>
          {loading && <CustomLoader2 />}
          {filteredVisits.map((visit: any, index: number) => {
            return (
              <div className='pm-offers-and-renewals mb-10 mt-5' key={index}>
                <div className='card'>
                  <div className='row'>
                    <div className='col-sm-3 col-12 col-lg-3 col-xl-3'>
                      <div className='upcoming_img'>
                        <img
                          src={visit?.property_cover_img ? visit?.property_cover_img : 'No Image'}
                          className='img-fluid'
                        />
                        <span className='badge badge-pill badge-dark prop-type-badge'>
                          {parseInt(visit?.property_search_type) === 1
                            ? t('Residential')
                            : parseInt(visit?.property_search_type) === 2
                            ? t('Commercial')
                            : visit?.property_search_type}
                        </span>
                      </div>
                    </div>
                    <div className='col-lg-5 col-xl-5 col-sm-5 col-12 align-self-center'>
                      <div className='offer_main_details'>
                        <div className='col-12 pt-3'>
                          <p className='text-black fw-bolder font-17 text-title my-offer-property-name mb-3'>
                            {visit?.property_name ? visit?.property_name : '-'}
                          </p>

                          <h2 className='text-black fw-bolder mb-3 text-sub-title'>
                            <small>{t('AED')}</small>{' '}
                            <span className='fw-boldest'>
                              {visit?.price?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </span>{' '}
                            <small> / {t('Year')} </small>
                          </h2>
                        </div>
                        <div className='col-12 offer_main_details_div_3 mb-3'>
                          <p>
                            <span>
                              <img src={Upcoming02} className='img-fluid map-icon' />{' '}
                            </span>
                            {visit?.propertyCode?.substring(
                              visit?.propertyCode?.lastIndexOf('-') + 1,
                              visit?.propertyCode?.length
                            ) + ' | '}{' '}
                            {visit?.buildingName && visit?.buildingName + ' | '}{' '}
                            {visit?.address && visit?.address + ' | '}{' '}
                            {visit?.location && visit?.location}
                          </p>
                        </div>
                        <div className='col-12'>
                          <div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
                            {parseInt(visit?.property_search_type) !== 2 &&
                              <p className='p-3 me-2 mb-0'>
                                <img src={Bed} className='img-fluid me-3' alt='bed' />
                                {visit?.bedRooms ? visit?.bedRooms : '0'}{' '}
                              </p>
                            }
                            <p className='p-3 me-2 mb-0'>
                              <img src={Bath} className='img-fluid me-3' alt='bath' />
                              {visit?.bathRooms ? visit?.bathRooms : '0'}
                            </p>
                            <p className='p-3 me-2 sqft-my-offer mb-0'>
                              <img src={Sqft} className='img-fluid me-2' alt='sqft' />
                              {visit?.builtInArea ? visit?.builtInArea : '-'}
                              {t('Sqmt')}
                            </p>
                            <p className='p-3 mb-0'>
                              <img src={YesIcon} className='img-fluid me-2' alt='yes' />{' '}
                              {visit?.isParkingAvailable === 'Y' ? t('Yes') : t('No')}
                            </p>
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
                            <p className='p-3 me-2'>
                              Broker : {t(broker_array[index].brokerName)}
                            </p>
                            <p className='p-3'>
                              User Name : {t(broker_array[index].name)}
                            </p>
                            <p className='p-3 me-2 sqft-my-offer'>
                              User Mobile : {t(broker_array[index].mobile)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-xl-4 col-sm-4 col-12 align-self-center'>
                      <div className='text-center pe-md-6 px-6 pb-6 px-md-0 pb-md-0'>
                        <div className='col-12'>
                          <div className='visit-confirmed-date-di rounded mb-5'>
                            <div className='row align-items-center'>
                              <div className='col-sm-12 col-12 align-self-center'>
                                <div className='text-center'>
                                  <p className='text-black fw-bolder font-17 mb-10'>
                                    <span
                                      className={
                                        visit?.event_status === 2
                                          ? 'bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'
                                          : visit?.event_status === 4
                                          ? 'bg-info bg-opacity-10 text-info px-5 py-3 rounded fw-bolder'
                                          : visit?.event_status === 5
                                          ? 'bg-danger bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
                                          : visit?.event_status === 1
                                          ? 'bg-success bg-opacity-10 text-primary px-5 py-3 rounded fw-bolder'
                                          : visit?.event_status === 3
                                          ? 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
                                          : visit?.event_status === 6
                                          ? 'bg-warning bg-opacity-10 text-danger px-5 py-3 rounded fw-bolder'
                                          : 'bg-warning bg-opacity-10 text-warning px-5 py-3 rounded fw-bolder'
                                      }
                                    >
                                      {visit?.event_status == 1
                                        ? t('Visit Confirmed')
                                        : visit?.event_status == 2
                                        ? t('Visit Completed')
                                        : visit?.event_status == 3
                                        ? t('In progress')
                                        : visit?.event_status == 4
                                        ? t('Reschedule')
                                        : visit?.event_status == 5
                                        ? t('Visit Expired')
                                        : visit?.event_status == 6
                                        ? t('Visit Cancelled')
                                        : ''}
                                    </span>
                                  </p>
                                  {visit?.event_status == 2 && (
                                    <button
                                      className='btn btn-light font-14 fw-bold w-75 py-3'
                                      data-bs-target='#feedback-popup'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                    >
                                      {t('Write Review')}
                                    </button>
                                  )}
                                </div>
                              </div>
                              {/* <div className='col-md-12 col-6 py-md-3'>
                                                                <p className='text-black text-end fw-bold mb-0'>
                                                                    <span className='me-3'>
                                                                        {moment(visit?.visit_date).format('DD-MM-YYYY')}
                                                                    </span>
                                                                    <span>{visit?.visitTime ? visit?.visitTime : '-'}</span>
                                                                </p>
                                                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
