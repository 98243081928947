import React, {FC, useState, useRef, useEffect } from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {bankTransfer} from '../../../../constants/axios/apis'
import {myOffersDetails} from '../../../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

export function BankTransfer({props}: any) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [offerDetails, setOfferDetails] = useState<any>()
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const closeRef = useRef<any>()
  const { t } = useTranslation();

  const initialValues = {
    offer_id: props?.contract?.offer_id,
    property_id: props?.contract?.propertyID,
    payment_category: props?.payment_category,
    payment_for: 1,
    beneficiary_name: offerDetails?.payment_plan_details?.bank_details?.beneficiaryName,
    beneficiary_account: offerDetails?.payment_plan_details?.bank_details?.beneficiaryAccount,
    iban_no: offerDetails?.payment_plan_details?.bank_details?.ibanNo,
    beneficiary_bank: offerDetails?.payment_plan_details?.bank_details?.beneficiaryBank,
    swift_code: offerDetails?.payment_plan_details?.bank_details?.swiftCode,
    branch_address: offerDetails?.payment_plan_details?.bank_details?.branchAddress,
    receipt_img: '',
  }

  useEffect(() => {
    if (props?.contract) {
      myOffersDetails(props?.contract?.offer_id).then(({data}) => {
        if (data) {
          setOfferDetails(data.data)
        }
      })
    }
  }, [props?.contract])

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldValue('receipt_img', e.currentTarget.files[0])
  // }

  const bankTransferRequestSchema = Yup.object().shape({
    receipt_img: Yup.mixed()
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .pdf"), (value) => {
        if (!value) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'image/png' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t('The file is too large! Max allowed size is 5MB.'), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: bankTransferRequestSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      var FormData = require('form-data')
      var bankTransferDetails = new FormData()
      bankTransferDetails.append('offer_id', values.offer_id)
      bankTransferDetails.append('payment_category', values.payment_category)
      bankTransferDetails.append('payment_for', values.payment_for)
      bankTransferDetails.append('property_id', values.property_id)
      bankTransferDetails.append('beneficiary_name', values.beneficiary_name)
      bankTransferDetails.append('beneficiary_account', values.beneficiary_account)
      bankTransferDetails.append('iban_no', values.iban_no)
      bankTransferDetails.append('beneficiary_bank', values.beneficiary_bank)
      bankTransferDetails.append('swift_code', values.swift_code)
      bankTransferDetails.append('branch_address', values.branch_address)
      bankTransferDetails.append('receipt_img', values.receipt_img)

      setTimeout(() => {
        bankTransfer(bankTransferDetails)
          .then((response: any) => {
            setLoading(false)
            // console.log(response)
            if (response.data.code === 200 && response.data.errorCode != 1) {
              props.contractActivityUpdated()
              closeRef.current.click()
            } else {
              setError(response.data.errorDescription)
              setTimeout(() => { setError('') }, 5000);
            }
          })
          .catch((e) => {
            setSubmitting(false)
            setLoading(false)
            setStatus(t("Invalid Data Provided"))
            setError(t("Some error occured. Please try again!"))
            setTimeout(() => { setError('') }, 5000);
          })
      }, 1000)
    },
  })
  
  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {    
    if(e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf'){      
      inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)        
      formik.setFieldTouched('receipt_img')
      formik.setFieldValue('receipt_img', e.currentTarget.files[0])
    }else{
      setUploading(true)
      formik.setFieldError('receipt_img', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
            formik.setFieldTouched('receipt_img', true, false)
            formik.setFieldValue('receipt_img', compressResponse.file, false)
          } else {
            setUploadedFileName(null)
            formik.setFieldTouched('receipt_img', true, false)
            formik.setFieldValue('receipt_img', '', false)
            formik.setFieldError('receipt_img', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedFileName(null)
        formik.setFieldTouched('receipt_img')
        formik.setFieldValue('receipt_img', '', false)
        formik.setFieldError('receipt_img', t('File upload error!'))
        setUploading(false)
      }
    }
  }

  return (
    <div className=''>
      <div className='row'>
        <div className='col-12'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='row form-add-design'>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("Beneficiary Name")}</label>
                  <input
                    {...formik.getFieldProps('beneficiary_name')}
                    type='text'
                    className='form-control fw-bolder  bg-white'
                    value={formik.values.beneficiary_name}
                    maxLength={50}
                    disabled
                  />
                  {formik.touched.beneficiary_name && formik.errors.beneficiary_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.beneficiary_name}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='mb-5 upload-input'>
                <label className='form-label fw-bolder d-block mb-3'>{t("Bank Receipt")}</label>
                <input
                  ref={inputRef}
                  onChange={(e) => handleDisplayFileDetails(e)}
                  className='d-none'
                  type='file'
                  // onBlur={formik.handleBlur}
                />
                <button
                  type='button'
                  onClick={handleUpload}
                  disabled={uploading}
                  className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                  }`}
                >
                  {/* <span>
                    {uploadedFileName ? (
                      uploadedFileName
                    ) : (
                      <span>
                        {t("Upload")}{' '}
                        <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                      </span>
                    )}{' '}
                  </span> */}
                  {uploading ?
                    <span className='indicator-progress d-block'>
                      {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    : uploadedFileName ? (
                      uploadedFileName
                    ) : (
                      <span>
                        {t("Upload")}{' '}
                        <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                      </span>
                    )}{' '}
                </button>
                {formik.touched.receipt_img && formik.errors.receipt_img && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.receipt_img}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("Beneficiary Account Number")}</label>
                  <input
                    {...formik.getFieldProps('beneficiary_account')}
                    type='text'
                    className='form-control fw-bolder  bg-white'
                    value={formik.values.beneficiary_account}
                    maxLength={50}
                    disabled
                  />
                  {formik.touched.beneficiary_account && formik.errors.beneficiary_account && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.beneficiary_account}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("IBAN Number")}</label>
                  <input
                    {...formik.getFieldProps('iban_no')}
                    type='text'
                    className='form-control fw-bolder  bg-white'
                    value={formik.values.iban_no}
                    maxLength={50}
                    disabled
                  />
                  {formik.touched.iban_no && formik.errors.iban_no && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.iban_no}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("Beneficiary Bank Name")}</label>
                  <input
                    {...formik.getFieldProps('beneficiary_bank')}
                    type='text'
                    className='form-control fw-bolder  bg-white'
                    value={formik.values.beneficiary_bank}
                    maxLength={50}
                    disabled
                  />
                  {formik.touched.beneficiary_bank && formik.errors.beneficiary_bank && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.beneficiary_bank}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("Swift Code")}</label>
                  <input
                    {...formik.getFieldProps('swift_code')}
                    type='text'
                    className='form-control fw-bolder  bg-white'
                    value={formik.values.swift_code}
                    maxLength={50}
                    disabled
                  />
                  {formik.touched.swift_code && formik.errors.swift_code && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.swift_code}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              { error !== '' && 
                <div className="col-12">
                  <div className='alert alert-danger w-100 text-center mt-3' role='alert'>
                    {error}
                  </div>
                </div>
              }
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary fw-bold px-10 py-3 w-100'
                  disabled={loading || uploading}
                >
                  {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {t("Please wait...")}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#success-modal-preview'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}
