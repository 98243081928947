import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { MainCard } from './MainCard/MainCard'
import { PaymentPlanDetails } from './PaymentPlanDetails/PaymentPlanDetails'
import { OfferDetails } from './OfferDetails/OfferDetails'
import { ExtendOffer } from './ExtendOffer/ExtendOffer'
import { BankDetails } from './BankDetails/BankDetails'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import * as dashboard from '../../../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import { MakeanOfferNotification } from '../../../../components/partials/layout/make-an-offer-notification/MakeanOfferNotification'
import { ExtendOfferPopup } from '../../../pt-activity/Components/MyOffer/ExtendOfferPopup/ExtendOfferPopup'
import { RequestSuccessfulPopup } from '../../../SuccessfulPopup/RequestSuccessfulPopup'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { AcceptOfferPopUp } from './AcceptOfferPopUp/AcceptOfferPopUp'
import moment from 'moment'
import { TenantsDetails } from './TenantsDetails/TenantsDetails'
import { useTranslation } from 'react-i18next'
import { CustomLoader1 } from '../../../../constants/generics/customActios'
import { formatTimeOnly } from '../../../../constants/generics/dateTimeFormater'

interface Props {
	offerIdNo: any
	offerIdShowHide: any
}
const Details: React.FC<Props> = ({ offerIdNo, offerIdShowHide }) => {
	const resultRef = useRef<any>(0)
	const [selectedOfferData, setSelectedOfferData] = useState<any>('')
	const [isDisplayed, setIsDisplayed] = useState(false);
	const dispatch = useDispatch()
	const { t } = useTranslation();
	let offerList: any = []
	offerList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.offerDetails)
	useEffect(() => {
		dispatch(dashboard.actions.offerDetails(offerIdNo))
		return () => {
			dispatch(dashboard.actions.resetOfferDetails())
		}
	}, [dispatch, offerIdNo])

	useEffect(() => {
		var tabMode
		if (offerList?.data?.offer_details?.offerSDPaymentStatus != 1) { tabMode = 2 }
		else if (offerList?.data?.offer_details?.offerPaymentStatus != 1) { tabMode = 1 }
		else { tabMode = 3 }
		setSelectedOfferData({
			offerId: offerList?.data?.offer_details?.offerId,
			amount: offerList?.data?.payment_plan_details?.rent[0]?.totalAmount,
			tabMode: tabMode,
			leaseDate: offerList?.data?.offer_details?.startDate,
			leaseEndDate: offerList?.data?.offer_details?.endDate,
			noOfPayments: offerList?.data?.offer_details?.noOfInstallments,
			customerName: offerList?.data?.offer_details?.tenant_name,
			customerLastName: offerList?.data?.offer_details?.tenant_last_name,
			offeredPrice: offerList?.data?.offer_details?.annualRent,
			securityDeposit: offerList?.data?.payment_plan_details?.sd?.totalAmount,
			propertyId: offerList?.data?.property_details?.propertyID,
			modeOfPayment: offerList?.data?.property_details?.modeOfPayment,
			bank_details: offerList?.data?.payment_plan_details?.bank_details,
			rentChequeDate: moment(offerList?.data?.payment_plan_details?.rent[0]?.checkDate).format('YYYY-MM-DD'),
			sdChequeDate: moment(offerList?.data?.payment_plan_details?.sd?.checkDate).format('YYYY-MM-DD'),
			docs: offerList?.data?.document_details,
			rentModeOfPayment: offerList?.data?.offer_details?.RentModeOfPayment,
			sdModeOfPayment: offerList?.data?.offer_details?.SDModeOfPayment,
		})
	}, [offerList])
	useEffect(() => {
		dispatch(PTOffer.actions.offerdetailsdata(selectedOfferData))
	}, [selectedOfferData])
	const location = useLocation()
	const intl = useIntl()
	const history = useHistory()
	let userRole: number = 0
	let userEmail = ''
	let userDetail = localStorage.getItem('userDetail')
	if (userDetail) {
		userEmail = JSON.parse(userDetail).email
		userRole = JSON.parse(userDetail).id_role
	}

	useEffect(() => {
		setInterval(() => {
			setIsDisplayed(true);
		}, 1000);
	}, []);
	const onSubmit = (e: any) => {
		e.preventDefault();
		// resultRef.current.scrollIntoView({ behavior: "smooth" });
		window.scrollTo({ left: 0, top: 900, behavior: "smooth" })
	};
	if (!isDisplayed) return <div><h2>{t("Loading...")}</h2></div>;
	if (offerList == undefined) { return <CustomLoader1 height={50} /> }

	const today = new Date()
	const validity = offerList?.data?.offer_details?.validity
	const expiryDays = moment(validity).startOf('day').diff(moment(today).startOf('day'), 'days')
	const expiry = moment(validity).diff(moment(today), 'seconds')

	return (
		<div className='com-card-section'>
			{isDisplayed && <>	<div className='row'>
				<div className='col-12'>
					<div className='row align-items-center mb-7'>
						<div className='col-lg-6 col-sm-6 col-12'>
							<div className='d-flex'>
								<h4 className='card-title font-18 text-black mb-0'>
									{userRole === 4 || userRole === 5 || userRole === 7 ? (
										<Link to={'/pt-activity/my-offer'} className=''>
											<img src={LeftArrow} className='img-fluid me-5 left-arrow-back' onClick={() => offerIdShowHide()} />
										</Link>
									) : userRole === 6 ? (
										<Link to={'/offers'} className=''>
											<img src={LeftArrow} className='img-fluid me-5 left-arrow-back' onClick={() => offerIdShowHide()} />
										</Link>
									) : (
										<Link to={'/pm-offers-and-renewals/offer'} className=''>
											<img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
										</Link>
									)}
									{t("Offer No")} : {offerList?.data?.offer_details?.offerNumber}
								</h4>
								{offerList?.data?.offer_details?.offerSDPaymentStatus == 0 ?
									expiry > 0 ?
										<span className='d-inline-block bg-warning bg-opacity-10 text-warning fw-bolder rounded px-3 py-2 ms-5'>
											<i className="bi bi-exclamation-circle text-warning"></i>
											{' '}{(expiryDays > 0) ? `${t("Offer will expire in")} ${expiryDays} ${expiryDays > 1 ? t("days") : t("day")}` : `${t("Offer will expire today at")} ${formatTimeOnly(validity)}`}
										</span>
										:
										<span className='d-inline-block bg-danger bg-opacity-10 text-danger fw-bolder rounded px-3 py-2 ms-5'><i className="bi bi-exclamation-triangle text-danger"></i> {t("Offer expired!")}</span>
									: ''
								}
							</div>
						</div>
						{userRole === 4 || userRole === 5 || userRole === 6 || userRole === 14 || userRole === 7 ? (
							<div className='col-lg-6 col-sm-6 col-12 text-end'>
								{offerList?.data?.offer_details?.offerPaymentStatus != 1 || offerList?.data?.offer_details?.offerSDPaymentStatus != 1 ? (
									<>
										{userRole === 14 ? (
											<>
												<button
													className='btn btn-outline-primary fw-bold px-4 py-3 me-4 cpt-btn'
													data-bs-toggle='modal'
													data-bs-target='#accept-offer-popup'
												>
													{t("Accept Offer")}
												</button>
												<AcceptOfferPopUp />
												<button
													className='btn btn-outline-primary fw-bold px-4 py-3 me-4 cpt-btn'
													onClick={(e) => history.push('/')}
												>
													{t("Make an Offer")}
												</button>
												<button
													className='btn btn-outline-primary fw-bold px-4 py-3 me-4 cpt-btn'
													data-bs-toggle='modal'
													data-bs-target='#tenant-details-popup'
												>
													{t("CPT")}
												</button>
												<TenantsDetails />
											</>) : null}

										{parseInt(offerList?.data?.offer_details?.offerStatus) === 1 && offerList?.data?.offer_details?.offerPaymentStatus != 1 && offerList?.data?.offer_details?.offerSDPaymentStatus != 1 ? (
											offerList?.data?.offer_details?.is_offer_extended === 1 ?
												<button
													className='btn btn-outline-primary fw-bold px-4 py-3 me-4 cpt-btn'
													data-bs-toggle='modal'
													data-bs-target='#extend-offer-popUp'
													disabled={parseInt(offerList?.data?.offer_details?.offerStatus) === 1 ? false : true}
												>
													{t("Extend Offer")}
												</button> : <button
													className='btn btn-outline-primary fw-bold px-4 py-3 me-4 cpt-btn'
													data-bs-toggle='modal'
													data-bs-target='#extend-offer-popUp'
													disabled={parseInt(offerList?.data?.offer_details?.offerStatus) === 1 ? false : true}
												>
													{t("Extend Offer")}
												</button>) : null}
										<button
											className='btn btn-primary fw-bold px-4 py-3 me-2 cpt-btn'
											data-bs-target='#make-an-offer-notification'
											data-bs-toggle='modal'
											data-bs-dismiss='modal'
											disabled={parseInt(offerList?.data?.offer_details?.offerStatus) === 1 ? false : true}
										>
											{parseInt(offerList?.data?.offer_details?.offerStatus) === 1 ? offerList?.data?.offer_details?.offerSDPaymentStatus != 1 ? t("Pay Security Deposit") : offerList?.data?.offer_details?.offerPaymentStatus != 1 ? t("Pay Rent Amount") : t("Pay now") : t("Waiting for approval")}
										</button>
										{" "}
										{/* <AddingCheque /> */}
									</>
								) : null}
								{offerList?.data?.offer_details?.contractCancelled === "Y" ? <div className="card-status"><span className={'rejected'}>{t("Contract Rejected")}</span></div>
									:
									// { offerList?.data?.offer_details?.offerPaymentStatus != 0 && offerList?.data?.offer_details?.offerSDPaymentStatus != 0 && offerList?.data?.offer_details?.contractGenerated === 'N' && offerList?.data?.offer_details?.proceedContractGeneration === 'Y' ? (
									offerList?.data?.offer_details?.offerPaymentStatus != 0 && offerList?.data?.offer_details?.offerSDPaymentStatus != 0 && offerList?.data?.offer_details?.contractGenerated === 'N' && offerList?.data?.offer_details?.proceedContractGeneration === 'Y' ? (
										<button
											className='btn btn-primary fw-bold px-4 py-3 ms-3 cpt-btn'
											data-bs-target='#extend-offer-popup'
											data-bs-toggle='modal'
											data-bs-dismiss='modal'
											disabled={offerList?.data?.offer_details?.contractSubmitted === 'Y' ? true : false}
											title={offerList?.data?.offer_details?.contractSubmitted === 'Y' ? t("Waiting for approval") : ''}
										>
											{offerList?.data?.offer_details?.contractSubmitted === 'Y' ? t("Contract already submitted") : t("Continue With Contract Generation")}
										</button>
									) : offerList?.data?.offer_details?.proceedContractGeneration === 'N' && parseInt(offerList?.data?.offer_details?.offerStatus) === 1 && offerList?.data?.offer_details?.offerSDPaymentStatus != 0 && offerList?.data?.offer_details?.offerPaymentStatus != 0 ?
										(<button
											className='btn btn-primary fw-bold px-4 py-3 ms-3 cpt-btn'
											onClick={onSubmit}
										>
											{t("Upload PDC")}
										</button>)
										: null}
							</div>
						) : (
							<div className='col-lg-6 col-sm-12 text-end'>
								<button
									className='btn btn-primary fw-bold px-4 py-3 me-4 cpt-btn'
									onClick={(e) => history.push('/pm-offers-and-renewals/edit-offer')}
								>
									<i className='bi bi-pencil-fill me-1'></i> {t("Edit Offer")}
								</button>
								<button
									className='btn btn-primary fw-bold px-4 py-3 cpt-btn'
									data-bs-target='#pm-create-contract-popup'
									data-bs-toggle='modal'
								>
									{t("Create Contract")}
								</button>
							</div>
						)}
					</div>
				</div>
				<div className='col-12'>
					<MainCard />
				</div>
				<div className='col-12'>
					{userRole === 4 || userRole === 5 || userRole === 6 || userRole === 7 ? (
						<div className='accordion check-date-details' id='accordion-step-five'>
							<OfferDetails />
							<PaymentPlanDetails ref={resultRef} />
							<BankDetails />
						</div>
					) : (
						<div className='accordion check-date-details' id='accordion-step-five'>
							<OfferDetails />
							<PaymentPlanDetails />
						</div>
					)}
				</div>
			</div></>}
			<ExtendOffer />
			<MakeanOfferNotification />
			<ExtendOfferPopup />
			<RequestSuccessfulPopup />
		</div>
	)
}

export { Details }
