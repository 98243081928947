import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Facilities } from '../AvailableProperties/Facilities/Facilities'
import LocationIcon from '../../../../../assets/images/loc-theme.png'
import { getLatestProperties } from '../../../../../utils/makeRequest'
import { IState } from '../../../../../utils/interface'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../constants'
import { ssoUserDetailswithLocations } from '../../../../../constants/generics/sso'
import { currencyFormater, CustomLoader2, NoDataFound1 } from '../../../../../constants/generics/customActios'
import Home from '../../../../../assets/images/my-unit.png'

export function LatestProperties() {
  const location = useLocation()
  const { t } = useTranslation()
  const [upcomingProperties, setUpcomingProperties] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })

  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
  let userEmail = ''
  let userId = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userId = JSON.parse(userDetail).id
  }

  useEffect(() => {
    getLatestProperties(setUpcomingProperties)
  }, [])

  const handleFind = (params: any) => {
    const pId = params?.id > 0 ? params?.id : params?.propertyId
    const Pname = params?.propretyName?.replace(/\s+/g, '-')?.toLowerCase()
    const location = `/properties/${Pname}/${pId}`
    window.location.assign(`${ssoUserDetailswithLocations(userDetail, accessToken, location)}`)
  }

  return (
    <div className=''>
      <div className='row'>
        {location.pathname === '/broker-admin-dashboard/available-properties' ||
          location.pathname === '/broker-admin-dashboard/upcoming-properties' ||
          location.pathname === '/broker-admin-dashboard/latest-properties' ||
          location.pathname === '/broker-admin-dashboard/trending-properties' ? (
          <div className='col-12 pt-6'>
            <div className='row'>
              {upcomingProperties?.loading ? (
                <CustomLoader2 />
              ) : (
                <>
                  {upcomingProperties?.response &&
                    Array.isArray(upcomingProperties?.response) &&
                    upcomingProperties?.response.length ? (
                    upcomingProperties?.response
                      ?.slice(0, 2)
                      .map((property: any, index: number) => {
                        return (
                          <div className='col-md-6 col-12'>
                            <div className='card product-card rounded-10 mb-7' key={index + 1}>
                              <img
                                src={
                                  property?.property_images[0]?.propertyImage
                                    ? property.property_images[0].propertyImage
                                    : Home
                                }
                                className='card-img-top'
                                alt='Property'
                                onClick={() => handleFind(property)}
                                style={{ cursor: 'pointer' }}
                              />
                              <span className='badge badge-pill badge-dark prop-type-badge'>
                                {parseInt(property?.propertySearchType) === 1
                                  ? t('Residential')
                                  : parseInt(property?.propertySearchType) === 2
                                    ? t('Commercial')
                                    : property?.propertySearchType}
                              </span>
                              <div className='card-body px-4 py-4'>
                                <div className='row'>
                                  <div className='col-12'>
                                    <div className='content_wrapper'>
                                      <p className='fw-bold price text-black'>
                                        {t('AED')}{' '}
                                        <span className='fw-boldest'>
                                          {property?.price ? currencyFormater(property.price) : '-'}
                                        </span>{' '}
                                        / {t('Year')}
                                      </p>
                                      <h5
                                        onClick={() => handleFind(property)}
                                        style={{ cursor: 'pointer' }}
                                        className='fw-boldest text-black card-title'
                                      >
                                        {property?.propretyName ? property.propretyName : '-'}
                                      </h5>
                                      <Facilities property={property} />
                                      <p className='facility-location text-black mb-0'>
                                        <span>
                                          <img
                                            src={LocationIcon}
                                            className='img-fluid'
                                            alt='Location'
                                          />
                                        </span>
                                        {property?.propertyCode?.substring(
                                          property?.propertyCode?.lastIndexOf('-') + 1,
                                          property?.propertyCode?.length
                                        ) + ' | '}{' '}
                                        {property?.buildingType && property?.buildingType + ' | '}{' '}
                                        {property?.Address && property?.Address + ' | '}{' '}
                                        {property?.location && property?.location}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                  ) : (
                    <>
                      <NoDataFound1 />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : location.pathname === '/broker-admin-dashboard/viewall-available-properties' ||
          location.pathname === '/broker-admin-dashboard/viewall-upcoming-properties' ||
          location.pathname === '/broker-admin-dashboard/viewall-latest-properties' ||
          location.pathname === '/broker-admin-dashboard/viewall-trending-properties' ? (
          <div className='col-12 pt-6'>
            <div className='row'>
              {upcomingProperties?.loading && <CustomLoader2 />}
              {(upcomingProperties?.error && upcomingProperties?.error !== '') && (
                <div className='alert alert-danger w-100 mt-2' role='alert'>
                  {upcomingProperties?.error}
                </div>
              )}
              {upcomingProperties?.response.map((property: any, index: number) => {
                return (
                  <div className='col-md-4 col-12'>
                    <div className='card product-card rounded-10 mb-7' key={index + 1}>
                      <img
                        onClick={() => handleFind(property)}
                        style={{ cursor: 'pointer' }}
                        src={
                          property?.property_images[0]?.propertyImage
                            ? property.property_images[0].propertyImage
                            : Home
                        }
                        className='card-img-top'
                        alt='Property'
                      />
                      <span className='badge badge-pill badge-dark prop-type-badge'>
                        {parseInt(property?.propertySearchType
                        ) === 1
                          ? t('Residential')
                          : parseInt(property?.propertySearchType
                          ) === 2
                            ? t('Commercial')
                            : property?.propertySearchType
                        }
                      </span>
                      <div className='card-body px-4 py-4'>
                        <div className='row'>
                          <div className='col-12'>
                            <div className='content_wrapper'>
                              <p className='fw-bold price text-black'>
                                {t('AED')}{' '}
                                <span className='fw-boldest'>
                                  {property?.price ? currencyFormater(property.price) : '-'}
                                </span>{' '}
                                / {t('Year')}
                              </p>
                              <h5
                                onClick={() => handleFind(property)}
                                style={{ cursor: 'pointer' }}
                                className='fw-boldest text-black card-title'
                              >
                                {property?.propretyName ? property.propretyName : '-'}
                              </h5>
                              <Facilities property={property} />
                              <p className='facility-location text-black mb-0'>
                                <span>
                                  <img src={LocationIcon} className='img-fluid' alt='Location' />
                                </span>
                                {property?.propertyCode?.substring(
                                  property?.propertyCode?.lastIndexOf('-') + 1,
                                  property?.propertyCode?.length
                                ) + ' | '}{' '}
                                {property?.buildingType && property?.buildingType + ' | '}{' '}
                                {property?.Address && property?.Address + ' | '}{' '}
                                {property?.location && property?.location}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            {/* <div className="col-12 text-center">
                            <a href={PROPERTY_LIST_PAGE_URL} target="_blank">
                                <div className="btn btn-primary w-50 mx-auto text-center my-5" role="alert">
                                    {t("More properties...")}
                                </div>
                            </a>
                        </div> */}
          </div>
        ) : null}
      </div>
    </div>
  )
}
