import React, { FC } from 'react'
import moment from 'moment';
import Home from "../../../../../assets/images/my-unit.png";
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../../../constants/generics/dateTimeFormater';
import { currencyFormater } from '../../../../../constants/generics/customActios';

type Props = {
    contract?: any
}

const LeaseDetails: FC<Props> = ({ contract }: any) => {
    const { t } = useTranslation();

    return (
        <div className="row align-items-center">
            <div className="col-md-4 col-lg-3">
                <div className="text-center">
                    <img src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/shopping-mall/shopping-mall.png" className="img-fluid" />
                </div>
            </div>
            <div className="col-md-8 col-lg-9 pt-5">
                <div className="col-md-12 mb-5">
                    <h6 className="d-inline text-black fw-bold font-15"> {t("Lease Number")} : </h6>
                    <span className="text-black fw-bold font-14"> {contract?.leaseNumber} </span>
                </div>
                {/* <div className="card lease-card bg-white rounded-5">
                    <div className="card-body p-0"> */}
                        <div className="row">
                            <div className="col-md-4 col-6 mb-5">
                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease Start Date")} </h6>
                                <span className="text-black fw-bold font-14"> {formatDate(contract?.contractStartDate)} </span>
                            </div>
                            <div className="col-md-4 col-6 mb-5">
                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Lease End Date")} </h6>
                                <span className="text-black fw-bold font-14"> {formatDate(contract?.contractEndDate)} </span>
                            </div>
                            <div className="col-md-4 col-6 mb-5">
                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("No. of Installments")} </h6>
                                <span className="text-black fw-bold font-14"> {contract?.noOfPayments} </span>
                            </div>
                            <div className="col-md-4 col-6 mb-5">
                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Status")} </h6>
                                <span className="text-black fw-bold font-14"> {contract?.contractStatus} </span>
                            </div>
                            <div className="col-md-4 col-6 mb-5">
                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Annual Rent")} </h6>
                                <span className="text-black fw-bold font-14"> {t("AED")} {currencyFormater(contract?.rent)} </span>
                            </div>
                            <div className="col-md-4 col-6 mb-5">
                                <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Security Deposit")} </h6>
                                <span className="text-black fw-bold font-14"> {t("AED")} {currencyFormater(contract?.SD)} </span>
                            </div>
                        </div>
                    {/* </div>
                </div> */}
            </div>
        </div>
    )
};

export { LeaseDetails }
