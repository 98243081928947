import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../constants';
import { fireProtectionImpairmentPermit } from '../../../../../../constants/axios/apis';
import { ApplicantUndertaking } from './ApplicantUndertaking';
import { GeneralInformationofApplication } from './GeneralInformationofApplication';
import { RequiredImpairmentChecklist } from './RequiredImpairmentChecklist';
import { SelectPermitReq } from './SelectPermitReq';
import { Signature } from './Signature';
import { useTranslation } from 'react-i18next'

type Props = {
    masterData?: any
    getStepStatus?: any
    stepStatus?: any
}

const FireProtectionImpairment: FC<Props> = ({ masterData, getStepStatus, stepStatus }) => {
    const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
    const [loading, setLoading] = useState<any>(false)
    const [generalInformation, setGeneralInformation] = useState<any>()
    const [permitRequirements, setPermitRequirements] = useState<any>()
    const [impairmentChecklist, setImpairmentChecklist] = useState<any>()
    const [applicantUndertaking, setApplicantUndertaking] = useState<any>()
    const [signatureData, setSignatureData] = useState<any>()
    const [isSubmitable, setIsSubmitable] = useState<any>(false)
    const { t } = useTranslation();

    const ref1 = useRef<any>()
    const ref2 = useRef<any>()
    const ref3 = useRef<any>()
    const ref4 = useRef<any>()

    const handelFinalSubmit = () => {
        if (generalInformation?.saved && permitRequirements?.saved && impairmentChecklist?.saved && applicantUndertaking?.saved && signatureData?.saved) {
            setLoading(true)
            setIsSubmitable(false)
            var FormData = require('form-data')
            var data = new FormData()
            data.append('contract_id', currentContract?.id)
            data.append('property_id', currentContract?.propertyID)
            data.append('category_id', 35)
            data.append('category_type_id', 53)
            data.append('fpi_permit_no', generalInformation.permit_number)
            data.append('fpi_applicant_name', generalInformation.applicant_name)
            data.append('fpi_contact_number', generalInformation.contact_number)
            data.append('fpi_company_name', generalInformation.applicant_company_name)
            data.append('fpi_company_contact_number', generalInformation.company_contact_number)
            data.append('fpi_building_name', generalInformation.building_name)
            data.append('fpi_location_of_work', generalInformation.location_of_work)
            data.append('fpi_requested_date', generalInformation.date_of_impairmet)
            data.append('fpi_requested_time', generalInformation.time_of_impairment)
            data.append('fpi_system_restored_date', generalInformation.system_restore_date)
            data.append('fpi_system_restored_time', generalInformation.system_restore_time)
            data.append('fpi_reason', generalInformation.reason)
            permitRequirements.requirements_ids.forEach((requirement_id: any, index: any) => {
                data.append(`fpi_permitRequirement[${[index]}]`, requirement_id)
            });
            impairmentChecklist.checklist_ids.forEach((checklist_id: any, index: any) => {
                data.append(`fpi_checklist[${[index]}]`, checklist_id)
            });
            data.append('fpi_applicant_undertaking_name', applicantUndertaking.applicant_name)
            data.append('fpi_applicant_undertaking_signature', applicantUndertaking.signature)
            data.append('fpi_permit_applicant_name', signatureData.permit_applicant)
            data.append('fpi_permit_applicant_signature', signatureData.signature)

            setTimeout(() => {
                fireProtectionImpairmentPermit(data)
                    .then((response: any) => {
                        if (response.errorCode === 1) {
                        } else if (response.errorCode === 0) {
                            getStepStatus()
                        } else {
                        }
                        setLoading(false)
                        setIsSubmitable(false)
                    })
                    .catch((e) => {
                        console.log(e, 'Error');
                    })
            }, 1000)

        }
    }

    useEffect(() => {
        if (generalInformation?.saved && permitRequirements?.saved && impairmentChecklist?.saved && applicantUndertaking?.saved && signatureData?.saved) {
            setIsSubmitable(true)
        } else {
            setIsSubmitable(false)
        }
    }, [generalInformation, permitRequirements, impairmentChecklist, applicantUndertaking, signatureData])

    return (
        <div className="accordion move-out-accordion" id="accordion-step-five">
            <GeneralInformationofApplication setGeneralInformation={setGeneralInformation} stepStatus={stepStatus} ref1={ref1} />
            <SelectPermitReq setPermitRequirements={setPermitRequirements} masterData={masterData} stepStatus={stepStatus} ref1={ref1} ref2={ref2} />
            <RequiredImpairmentChecklist setImpairmentChecklist={setImpairmentChecklist} masterData={masterData} stepStatus={stepStatus} ref2={ref2} ref3={ref3} />
            <ApplicantUndertaking setApplicantUndertaking={setApplicantUndertaking} stepStatus={stepStatus} ref3={ref3} ref4={ref4} />
            <Signature setSignatureData={setSignatureData} stepStatus={stepStatus} ref4={ref4} />

            {!stepStatus?.fireProtectionRequestStatus &&
                <div className="col-12 text-right">
                    <div className="row justify-content-end">
                        <div className="col-12">
                            <div className="d-flex justify-content-end">
                                <button type='button' onClick={handelFinalSubmit} disabled={!isSubmitable} className='btn btn-primary fw-bold px-10 py-3'>
                                    {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {t("Please wait...")}{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export { FireProtectionImpairment }
