import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ReactSignatureCanvas from 'react-signature-canvas'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getRenewalNotice, renewalNoteRequest } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'
        import uploadImg from '../../../../assets/images/file-upload.svg'

export function RenewalNote({ contract }: any) {
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [noticeSubmitted, setNoticeSubmitted] = useState<boolean>(true)
  let history = useHistory()
  const { t } = useTranslation();

  useEffect(() => {
    getRenewalNotice().then(({ data: notice }) => {
      if (notice.length > 0) {
        setNoticeSubmitted(notice[0])
      }
    })
  }, [])

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldValue('notice', e.currentTarget.files[0])
  // }

  const initialValues = {
    property_id: contract?.propertyID,
    category_id: 12,
    category_type_id: 0,
    signature: '',
    notice: '',
    terms: false,
  }

  const sigCanvas = useRef<any>()
  function clear() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }
  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      console.log(blob)
      formik.setFieldValue('signature', blob)
    })
  }

  const handleSubmit = (e: any, url: string) => {
    formik.handleSubmit()
    if (formik.isValid && Object.keys(formik.touched).length > 0) {
      history.push(url)
    }
  }

  const renewalNoteRequestSchema = Yup.object().shape({
    terms: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
    signature: Yup.string().required(t("Please add your signature")),
    // notice: Yup.mixed()
    //   .test('fileSize', t("The file is too large!"), (value) => {
    //     if (!value?.length) return true
    //     return value[0]?.size <= 2000000
    //   })
    //   .required(t("Please upload your renewal notice")),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: renewalNoteRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
        setTimeout(() => {
        renewalNoteRequest(
          values.property_id,
          values.category_id,
          values.category_type_id,
          values.signature,
          values.notice
        )
          .then((response: any) => {
            resetForm()
            setUploadedFileName(null)
            formik.setFieldValue('terms', false)
            formik.setFieldValue('notice', null)
            sigCanvas.current.clear()
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('notice', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('notice')
          formik.setFieldValue('notice', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('notice', true, false)
          formik.setFieldValue('notice', '', false)
          formik.setFieldError('notice', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('notice')
      formik.setFieldValue('notice', '', false)
      formik.setFieldError('notice', t('File upload error!'))
      setUploading(false)
    }
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              {/* <div className='row'>
                <div className='col-md-12 col-12'>
                  <h4 className='card-title mb-5'>Note:</h4>
                  <p className='text-black fw-bold font-14 mb-5'>
                    Lorem Ipsum és un text de farciment usat per la indústria de la tipografiai la
                    impremta. Lorem Ipsum ha estat el text estàndard de la indústria des de l'any
                    1500, quan un impressor desconegut va fer servir una galerada de text i la va
                    mesclar per crear un llibre de mostres tipogràfiques.
                  </p>
                </div>
              </div> */}
              {!noticeSubmitted ? (
                <div className=''>
                  <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework row form-add-design mt-2'
                    noValidate
                    id='noticeForm'
                    onSubmit={formik.handleSubmit}
                  >
                    <div className='col-md-6 col-lg-4 col-12'>
                      <div className='mb-10 upload-input'>
                        <label className='form-label fw-bolder d-block'>{t("Attach Notice")}</label>
                        <input
                          ref={inputRef}
                          onChange={(e) => handleDisplayFileDetails(e)}
                          className='d-none'
                          type='file'
                          accept=".png,.jpg,.jpeg"
                        />
                        <button
                          type='button'
                          onClick={handleUpload}
                          disabled={uploading}
                          className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                            }`}
                        >
                          {/* <span>
                            {uploadedFileName ? (
                              uploadedFileName
                            ) : (
                        <span  className="txt">
                          <img
                                  alt="File"
                                  src={uploadImg}
                          className="position-relative"
                          loading="lazy"/>

                                {t("Upload")}{' '}

                              </span>
                            )}{' '}
                          </span> */}
                          <span>
                            {uploading ?
                              <span className='indicator-progress d-block'>
                                {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                              : uploadedFileName ? (
                                uploadedFileName
                              ) : (
                            <span  className="txt">
                              <img
                                      alt="File"
                                      src={uploadImg}
                              className="position-relative"
                              loading="lazy"/>
                                  {t('Upload')}{' '}
                                </span>
                              )}{' '}
                          </span>
                        </button>
                      </div>
                      {formik.touched.notice && formik.errors.notice && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.notice}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6 col-lg-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Signature")}</label>
                        <div className='border border-2'>
                          <ReactSignatureCanvas
                            ref={sigCanvas}
                            penColor='black'
                            canvasProps={{ height: 180, width: 372, className: 'sigCanvas' }}
                            onEnd={handleSignature}
                          />
                        </div>
                        {formik.touched.signature && formik.errors.signature && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.signature}
                              </span>
                            </div>
                          </div>
                        )}
                        <button
                          type='button'
                          className='btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0'
                          onClick={clear}
                        >
                          {t("Reset")}
                        </button>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-4 col-12 mt-8'>
                      <div className='mb-4'>
                        <div className='form-check custom'>
                          <input
                            className='form-check-input'
                            {...formik.getFieldProps('terms')}
                            checked={formik.values.terms}
                            type='checkbox'
                            id='terms-check'
                            onBlur={formik.handleBlur}
                          />
                          <label
                            className='form-check-label font-14 text-black fw-bolder'
                            htmlFor='terms-check'
                          >
                            {t("Accept the Terms & Conditions")}
                          </label>
                        </div>
                        {formik.touched.terms && formik.errors.terms && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.terms}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-12 text-md-end section-w-res'>
                      {/* <Link
                      to={'/my-services/vacate'}
                      className='btn btn-outline-danger vacat-btn fw-bolder px-10 py-3 me-5'
                    >
                      Vacate
                    </Link> */}
                      <button
                        type='button'
                        onClick={(e) => handleSubmit(e, '/my-services/vacate')}
                        form='noticeForm'
                        className='btn btn-outline-danger vacat-btn fw-bolder px-md-10 py-3 px-5 me-md-5 me-3'
                        disabled={uploading}
                      >
                        {t("Vacate")}
                      </button>
                      <button
                        type='button'
                        onClick={(e) => handleSubmit(e, '/thankyou-service')}
                        form='noticeForm'
                        disabled={uploading}
                        className='btn btn-outline-primary ns-btn fw-bolder px-md-10 py-3 me-md-5 px-5 me-3'
                      >
                        {t("Not Sure")}
                      </button>
                      <button
                        type='button'
                        onClick={(e) => handleSubmit(e, '/my-services/contract-renewal')}
                        form='noticeForm'
                        disabled={uploading}
                        className='btn btn-primary fw-bold px-md-10 px-3 py-3'
                      >
                        {t("Renew Contract")}
                      </button>
                      {/* <Link
                    to={'/thankyou-service'}
                    className='btn btn-outline-primary ns-btn fw-bolder px-10 py-3 me-5'
                  >
                    Not Sure
                  </Link> */}
                      {/* <Link
                      to={'/my-services/contract-renewal'}
                      className='btn btn-primary fw-bold px-10 py-3'
                    >
                      {t("Renew Contract")}
                    </Link> */}
                    </div>
                  </form>
                </div>
              ) : (
                <div className='col-12 text-md-end section-w-res'>
                  <Link
                    to={'/my-services/vacate'}
                    className='btn btn-outline-danger vacat-btn fw-bolder px-md-10 py-3 px-5 me-md-5 me-3'
                  >
                    {t("Vacate")}
                  </Link>
                  <Link
                    to={'/thankyou-service'}
                    className='btn btn-outline-primary ns-btn fw-bolder px-md-10 py-3 me-md-5 px-5 me-3'
                  >
                    {t("Not Sure")}
                  </Link>
                  <Link
                    to={'/my-services/contract-renewal'}
                    className='btn btn-primary fw-bold px-md-10 px-3 py-3'
                  >
                    {t("Renew Contract")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
