import {FC} from 'react'
import {Link} from 'react-router-dom'
import PayNow from '../../../../assets/images/debit-card.png'
import ChequeReplace from '../../../../assets/images/cheque_replace.png'
import ChequeDelay from '../../../../assets/images/cheque_delay.png'
import ActionDotsIoc from '../../../../assets/images/Action_dots.png'
import {useTranslation} from 'react-i18next'

const PaymentActionMenu: FC = () => {
  const {t} = useTranslation()

  return (
    <div>
      <div className='dropdown'>
        <button
          className='btn dropdown-toggle border-0'
          type='button'
          id='dropdownMenuButton1'
          data-bs-toggle='dropdown'
        >
          <img src={ActionDotsIoc} className='img-fluid' />
        </button>

        <ul className='dropdown-menu actionmenu-tenant py-2 px-4'>
          <li className='p-2'>
            <Link
              to={{
                pathname: '/my-cheques/list',
              }}
              className='menu-link text-black fs-7'
            >
              <img src={ChequeReplace} className='img-fluid me-3' />
              {t('Cheque Replace')}
            </Link>
          </li>
          <li className='p-2'>
            <Link
              to={{
                pathname: '/my-cheques/list',
              }}
              className='menu-link text-black fs-7'
            >
              <img src={ChequeDelay} className='img-fluid me-3' />
              {t('Cheque Postpone')}
            </Link>
          </li>
          <li className='p-2'>
            <Link
              to={{
                pathname: '/my-cheques/list',
              }}
              className='menu-link text-black fs-7'
            >
              <img src={PayNow} className='img-fluid me-3' />
              {t('Stop payment of cheque')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export {PaymentActionMenu}
