import { getUserRole } from "../../../constants/generics/userCheck"

export const checkMarketAdmin = (user_state) => {
    try {
        const user_role = getUserRole(user_state)
        const isAdminUser = user_role && ['MA', 'MT'].includes(user_role) ? true : false
        return isAdminUser
    } catch (error) {
        return false
    }
}

export const enableMarketPlace = () => {
    // console.log(process.env.REACT_APP_MARKETPLACE_ENABLE, 'process.env.REACT_APP_MARKETPLACE_ENABLE');
    const enableMarket = ((process.env.REACT_APP_MARKETPLACE_ENABLE !== undefined) && (process.env.REACT_APP_MARKETPLACE_ENABLE === 'true') ? true : false)
    return enableMarket
}