import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { PhotoViewPopup } from './photoViewPopup'
import { ServiceDataInfo } from './ServiceDataInfo'
import { getMasterData } from '../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'

export function EarlTerminationRequestDetail({ contract, selectedIssue }: any) {
  const [terminationReason, setTerminationReason] = useState<any>()
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])

  useEffect(() => {
    if (issue && issue?.code == 'ET') {
      getMasterData().then(({ data }: any) => {
        setTerminationReason(
          data
            .find((item: any) => item.id === 11)
            ?.master_data.find(
              (item: any) => item.value == issue?.details?.early_termination_reason
            )
        )
      })
    }
  }, [issue])

  return (
    <div className='com-card-section'>
      {!loading && issue && issue?.code == 'ET' ? (
        <div className='row'>
          <div className='col-12'>
            <div className='accordion accordion-flush' id='three'>
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header position-relative' id='flush-headingOne'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapse3'
                    aria-expanded='false'
                    aria-controls='flush-collapseOne'
                  >
                    {t("Early Termination Request Detail")}
                  </button>
                  <span className='services-badge'>
                    {issue?.status == 0 ? t("Open") : t("Closed")}
                  </span>
                </h2>
                <div
                  id='flush-collapse3'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingOne'
                  data-bs-parent='#three'
                >
                  <div className='accordion-body py-5'>
                    <div className='row form-add-design'>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Lease Start Date")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(contract?.contractStartDate).format('D-MM-YYYY')}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Lease End Date")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(contract?.contractEndDate).format('D-MM-YYYY')}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Annual Rent")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={parseInt(contract?.rent)?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' AED'}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Early Termination Date")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.early_termination_date).format(
                              'D-MM-YYYY'
                            )}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Early Termination Reason")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={terminationReason?.name}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Document")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={issue?.images?.supp_document?.length == 1 ? issue?.images?.supp_document?.length + ' file' : issue?.images?.supp_document?.length + ' files'}
                          />
                          <>
                            {issue?.images?.supp_document?.length &&
                              <PhotoViewPopup images={issue?.images?.supp_document} />
                            }
                          </>
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>
                            {t("Early Termination Fee")} <small>{t("(*2 Months of Rent)")}</small>
                          </label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={parseInt(issue?.details?.early_termination_fees)?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' AED'}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <ServiceDataInfo issue={issue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
