import React, {useState, useEffect} from 'react'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import ArrowRightBlue from '../../../../assets/images/arrow-right-blue.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Link} from 'react-router-dom'
import {getAnnouncements} from '../../../../constants/axios/apis'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {NoDataFound1} from '../../../../constants/generics/customActios'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../constants'
import AnnounceBackupImg from '../../../../assets/images/banner-img.png'
import {formatDate, formatTimeMeridiem} from '../../../../constants/generics/dateTimeFormater'

export function Announcements() {
  const currentContract: any = useSelector<RootState>(({Tdashboard}) => Tdashboard.selectedContract)

  const [announcements, setAnnouncements] = useState<any>([])
  const {t} = useTranslation()
  const [sliderLoading, setSliderLoading] = useState(true)

  useEffect(() => {
    if (currentContract?.buildingID && currentContract.buildingID !== '') {
      const payload = '?building_id=' + currentContract.buildingID + '&id_sort=1&date_sort=1'
      setAnnouncements([])
      getAnnouncements(payload).then((response: any) => {
        // console.log(response);
        if (response?.errorCode === 0 && response?.data && response.data !== '') {
          let announcementsList = response.data
          announcementsList = announcementsList?.filter((announcement: any) => {
            const annpuncementEndTime = new Date(
              announcement.endDate + ' ' + announcement.end_time
            ).getTime()
            let currentTime = new Date().getTime()
            return currentTime <= annpuncementEndTime
          })
          setAnnouncements(announcementsList)
        }
      })
    } else {
      setAnnouncements([])
    }
  }, [currentContract])

  useEffect(() => {
    setTimeout(() => {
      setSliderLoading(false)
    }, 500)
  }, [])

  // useEffect(() => {
  //   getAnnouncements().then((data: any) => {
  //     setAnnouncements(data)
  //   })
  // }, [])

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  }

  // if (announcements === undefined || announcements?.length === 0) {
  //   return <div className='com-card-section'>
  //     <div className='row'>
  //       <div className='col-12'>
  //         <div className='card mb-7'>
  //           <div className='card-body p-7'>
  //             <div className='row align-items-center'>
  //               <div className='col-md-6 col-7'>
  //                 <h4 className='card-title'> Community Post </h4>
  //               </div>
  //               <div className="offset-sm-3 col-md-3 col-5 text-end">
  //                 <Link to={'/communitywall/page'} className="text-decoration-underline view-all text-black font-12 ">
  //                   <span> {t("View All")} </span>
  //                   <img src={RightArrow} alt="view-all" className="img-fluid" />
  //                 </Link>
  //               </div>
  //             </div>
  //             <div className='announcement-sec'>
  //               <div className='row'>
  //                 <div className='col-md-12 col-12 col-lg-12'>
  //                   <NoDataFound1 height={70} />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // }
  // let content = announcements?.map((item: any) => (
  //   <div key={item.id}>
  //     <Link to={{ pathname: "/communitywall/page", state: { announcementID: item.id } }}>
  //       <div className='card mt-4'>
  //         <div className='card-body'>
  //           <div className='row'>
  //             <div className='col-md-4 col-lg-3 my-auto col-12'>
  //               <img src={item?.cover_image && (typeof item?.cover_image === 'string') ? item?.cover_image : AnnounceBackupImg} alt="cover-img" className='img-fluid announce-img' />
  //             </div>
  //             <div className='col-md-6 col-lg-7 my-auto col-12'>
  //               <h4 className='card-title mb-2'> {item.title} </h4>
  //               <h6 className="sch-date">
  //                 {item?.building_name}
  //               </h6>
  //               <h6 className="sch-time mb-0">
  //                 <span className='text-black me-3'>{formatDate(item.date)}</span>
  //                 {formatTimeMeridiem(item.start_time)} - {formatTimeMeridiem(item.end_time)}
  //               </h6>
  //             </div>
  //             <div className='col-md-2 col-lg-2 my-auto text-end col-12'>
  //               <img src={ArrowRightBlue} alt='go' className='img-fluid d-inline' />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </Link>
  //   </div>
  // ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row align-items-center'>
                <div className='col-md-6 col-7'>
                  <h4 className='card-title'> Community Post </h4>
                </div>
                <div className='offset-sm-3 col-md-3 col-5 text-end'>
                  <Link
                    to={'/communitywall/page'}
                    className='text-decoration-underline view-all text-black font-12 '
                  >
                    <span> {t('View All')} </span>
                    <img src={RightArrow} alt='view-all' className='img-fluid' />
                  </Link>
                </div>
              </div>
              <div className='announcement-sec'>
                <div className='row'>
                  <div className='col-md-12 col-12 col-lg-12'>
                    <div className='row'>
                      <div className='col-12'>
                        {sliderLoading ? null : (
                          <Slider {...settings}>
                            <div>
                              <Link
                                to={{
                                  pathname: '/communitywall/page',
                                }}
                              >
                                <div className='card mt-4'>
                                  <div className='card-body'>
                                    <div className='row'>
                                      <div className='col-md-4 col-lg-3 my-auto col-12'>
                                        <img
                                          src={AnnounceBackupImg}
                                          alt='cover-img'
                                          className='img-fluid announce-img'
                                        />
                                      </div>
                                      <div className='col-md-6 col-lg-7 my-auto col-12'>
                                        <h4 className='card-title mb-2'> Realcube </h4>
                                        <h6 className='sch-date'>Polo Residence 1</h6>
                                        <h6 className='sch-time mb-0'>
                                          <span className='text-black me-3'>16-08-2023</span>
                                          10:00 AM - 06:00 PM
                                        </h6>
                                      </div>
                                      {/* <div className='col-md-2 col-lg-2 my-auto text-end col-12'>
                                        <img
                                          src={ArrowRightBlue}
                                          alt='go'
                                          className='img-fluid d-inline'
                                        />
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link
                                to={{
                                  pathname: '/communitywall/page',
                                }}
                              >
                                <div className='card mt-4'>
                                  <div className='card-body'>
                                    <div className='row'>
                                      <div className='col-md-4 col-lg-3 my-auto col-12'>
                                        <img
                                          src={AnnounceBackupImg}
                                          alt='cover-img'
                                          className='img-fluid announce-img'
                                        />
                                      </div>
                                      <div className='col-md-6 col-lg-7 my-auto col-12'>
                                        <h4 className='card-title mb-2'> Holiday</h4>
                                        <h6 className='sch-date'>Polo Residence 1</h6>
                                        <h6 className='sch-time mb-0'>
                                          <span className='text-black me-3'>21-08-2023</span>
                                          10:00 AM - 06:00 PM
                                        </h6>
                                      </div>
                                      {/* <div className='col-md-2 col-lg-2 my-auto text-end col-12'>
                                        <img
                                          src={ArrowRightBlue}
                                          alt='go'
                                          className='img-fluid d-inline'
                                        />
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </Slider>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
