import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import avatarImg from '../../../../assets/images/avatar.jpg'

const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1920,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: true
            }
        }
    ]
};

export function MoveInMoveOut({ actionCards=true }: any) {
    const { t } = useTranslation();

        let userName = ''
        let userImage = ''
        let userDetail = localStorage.getItem('userDetail')
        if (userDetail) {
        userName = JSON.parse(userDetail).first_name + ' ' + JSON.parse(userDetail).last_name
        userImage = JSON.parse(userDetail).profile_img
        }

    console.log("img", userDetail);
    return (
        <div className="com-card-section">
            <div>
                <div className="row">
                    <div className="col-12">
                        <Slider {...settings}>
                            <div>
                                <div className="card m-0 ct-dash-card">
                                    <div className="card-body p-7">
                                        <div className='row h-100 gx-0'>
                                            {actionCards != undefined && actionCards != false ?
                                                <>
                                                    <div className='col-sm-12'>
                                                        <div className='move-in-main-div d-flex align-items-center'>
                                                            <img src={userImage ? userImage : avatarImg} alt="images" />
                                                            <div className="ms-5">
                                                                <h6 className='text-gray-02 font-16 text-capitalize fw-700 mb-xl-2 mb-3'>
                                                                    {t("Welcome")}, <span className='fw-700'> {userName} </span>
                                                                </h6>
                                                                <h6 className='fs-5 text-capitalize text-gray-01 fw-bolder'>
                                                                    {' '}
                                                                    {t("Action Required")}{' '}
                                                                </h6>
                                                                <h2 className='mb-0 fw-bolder font-14 text-gray-02'>{t("Request For Fit-out")}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12  mt-10'>
                                                        <Link to={'/ct-move-in'} className='btn btn-lg btn-primary w-100 rounded move-in-link d-block'>
                                                            {t("New Fit-Out")}
                                                        </Link>
                                                    </div>

                                                </>
                                                :
                                                <div className='col-xl-12 col-12'>
                                                    <div className='move-in-main-div d-flex align-items-center'>
                                                        <img src={userImage ? userImage : avatarImg} alt="images" className="rounded-circle"/>
                                                        <div className="ms-5">
                                                            <h4 className='text-gray-02 font-18 text-capitalize fw-700 mb-xl-2 mb-5'>
                                                                {t("Welcome")}, <span className='fw-700'>{userName}</span>
                                                            </h4>
                                                            <h6 className='fs-5 text-capitalize text-gray-01 fw-bolder'>
                                                                {' '}No Action Required
                                                                {' '}
                                                            </h6>
                                                            <h2 className='mb-0 fw-bolder font-17 text-gray-02'>All Good!</h2>
                                                        </div>
                                                    </div>
                                                    <div className="text-right mt-10">
                                                        <Link to={'/move-out'} className='btn btn-lg btn-light rounded w-100'>
                                                            Move Out
                                                        </Link>
                                                    </div>
                                                </div>

                                        }
                                        </div>
                                        {/*<div className="row p-4 p-xl-0">
                                            {actionCards != undefined && actionCards != false ?
                                                <>
                                                    <div className="col-lg-8">
                                                        <div className="my-6 move-in-main-div">
                                                            <h6 className="fs-6 text-capitalize text-white fw-bold mb-xl-10 mb-6">
                                                                {t("Welcome")}, <span className="fw-700"> {userName} </span>
                                                            </h6>
                                                            <h6 className="fs-5 text-capitalize text-gray-400 fw-bolder"> {t("Action Required")} </h6>
                                                            <h2 className="text-white mb-0 fw-bold">{t("Request For Fit-out")}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-12 text-right mt-auto px-lg-0">
                                                        <Link to={'/ct-move-in'} className='btn btn-lg btn-light rounded move-in-link d-block mb-7'>
                                                            {t("New Fit-Out")}
                                                        </Link>
                                                    </div>
                                                </>
                                                :
                                                <div className='col-xl-12 col-12'>
                                                    <div className='my-6 move-in-main-div'>
                                                        <h4 className='fs-6 text-capitalize text-white fw-bold mb-xl-10 mb-6'>
                                                            {t("Welcome")}, <span className='fw-700'>{userName}</span>
                                                        </h4>
                                                        <h6 className='fs-5 text-capitalize text-gray-400 fw-bolder'>
                                                            {' '}No Action Required
                                                            {' '}
                                                        </h6>
                                                        <h2 className='text-white mb-0 fw-bolder font-17'>All Good!</h2>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 text-right mt-5 px-lg-0">
                                                    <Link to={'/move-out'} className='btn btn-lg btn-light rounded w-100'>
                                                        Move Out
                                                    </Link>
                                                </div>
                                            }
                                        </div>*/}
                                    </div>
                                </div>

                            </div>
                            {/* <div>
                                <div className="card m-0">
                                    <div className="card-body px-8 py-1 bg-gradient-primary-card first-card">
                                        <div className="row pb-4">
                                            <div className="col-lg-8">
                                                <div className="my-6">
                                                    <h6 className="fs-6 text-capitalize text-white fw-bold">
                                                        Welcome, <span className="fw-700"> {userName} </span>
                                                    </h6>
                                                    <h6 className="fs-5 text-capitalize text-gray-400 fw-bolder"> Action Required </h6>
                                                    <h1 className="text-white mb-0 fw-bold">Request For Move-in</h1>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 text-right my-auto px-lg-0">
                                                <Link to={'/move-out'} className='btn btn-lg btn-light rounded w-100'>
                                                    Move Out
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}