import React, { useState, FC, useEffect } from 'react';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next'
import completedStep from '../../../../../assets/images/completed-step.png'
import { Link } from 'react-router-dom';
import { TermsAndConditionsPopup } from '../../../../terms-and-conditions/TermsAndConditionsPopup'

type Props = {
    setPermitRequirements?: any
    masterData?: any
    ref1?: any
    ref2?: any
}

const PermitRequirements: FC<Props> = ({ setPermitRequirements, masterData, ref1, ref2 }) => {
    const [requirementsOptions, setRequirementsOptions] = useState<any>()
    const [safetyOptions, setSafetyOptions] = useState<any>()
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const { t } = useTranslation();

    useEffect(() => {
        let requirements = masterData
            .filter((item: any) => item.id === 35)
            .flatMap((data: any) => data.master_data)
        setRequirementsOptions(requirements)
        let precautions = masterData
            .filter((item: any) => item.id === 37)
            .flatMap((data: any) => data.master_data)
        setSafetyOptions(precautions)
    }, [masterData])

    const initialValues = {
        requirements_ids: [],
        precautions_ids: [],
        status: 0,
    }

    const handelSubmit = () => {
        setSaved(false)
        setPermitRequirements(null)
        formik.handleSubmit()
    }

    const permitRequirementsSchema = Yup.object().shape({
        status: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
    })

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: permitRequirementsSchema,
        onSubmit: (values) => {
            setPermitRequirements({
                requirements_ids: values.requirements_ids,
                precautions_ids: values.precautions_ids,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref2.current.click()
        },
    })

    const handleTerms = (e: any) => {
        if (e.target.checked) {
            return formik.setFieldValue('status', 1)
        }
        return formik.setFieldValue('status', 0)
    }

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setPermitRequirements((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    return (
        <>
            <div className="row">
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                >
                    <div className="col-12">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-permit-heading">
                                <button className="accordion-button collapsed" ref={ref1} type="button" data-bs-toggle="collapse" data-bs-target="#permit-req">
                                    {t("Permit Requirements")}
                                    {(saved) &&
                                        <span className='completed-step ps-3 pe-3 d-inline-block'>
                                            <img src={completedStep} alt='complete-step' />
                                        </span>}
                                </button>
                            </h2>
                            <div id="permit-req" className="accordion-collapse collapse" data-bs-parent="#accordion-rooftop-access">
                                <div className="accordion-body pb-5 pt-0">
                                    <div className="row form-add-design">
                                        <div className="col-12 mb-3">
                                            <label className="form-label fw-bolder"> {t("Select Permit Requirements")} </label>
                                        </div>
                                        {requirementsOptions ? requirementsOptions?.map((option: any) => (
                                            <div key={option.value} className="col-md-4 col-12">
                                                <div className="form-check custom mb-8">
                                                    <input
                                                        className='form-check-input'
                                                        name='requirements_ids'
                                                        value={option.value}
                                                        type='checkbox'
                                                        id={option.value}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <label className="form-check-label font-14 text-black fw-bolder mx-2" htmlFor={option?.value}>
                                                        {option?.name}
                                                    </label>
                                                </div>
                                            </div>
                                        )) : ''}
                                        <div className="col-md-12 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">{t("Comments (if any)")}</label>
                                                <textarea
                                                    rows={2}
                                                    className='form-control bg-white fw-bolder form-control-solid'
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label className="form-label fw-bolder"> {t("Safety Precaution to be taken by personnel")} </label>
                                        </div>
                                        {safetyOptions ? safetyOptions?.map((option: any) => (
                                            <div key={option.value} className="col-md-4 col-12">
                                                <div className="form-check custom mb-8">
                                                    <input
                                                        className='form-check-input'
                                                        name='safety_prec'
                                                        value={option.value}
                                                        type='checkbox'
                                                        id={option.value}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <label className="form-check-label font-14 text-black fw-bolder mx-2" htmlFor={option?.value}>
                                                        {option?.name}
                                                    </label>
                                                </div>
                                            </div>
                                        )) : ''}
                                        <div className="col-md-12 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">{t("Comments (if any)")}</label>
                                                <textarea
                                                    rows={2}
                                                    className='form-control bg-white fw-bolder form-control-solid'
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder d-block">{t("Are you using a contractor to carry out work?")}</label>
                                                <div className='form-check form-check-inline me-10'>
                                                    <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        name='is_contractor'
                                                        id='inlineRadio1'
                                                    />
                                                    <label className='form-check-label' htmlFor='inlineRadio1'>
                                                        {t("Yes")}
                                                    </label>
                                                </div>
                                                <div className='form-check form-check-inline'>
                                                    <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        name='is_contractor'
                                                        id='inlineRadio2'
                                                    />
                                                    <label className='form-check-label' htmlFor='inlineRadio2'>
                                                        {t("No")}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-7">
                                                <div className="form-check custom">
                                                    <input
                                                        className='form-check-input'
                                                        checked={formik.values.status ? true : false}
                                                        {...formik.getFieldProps('status')}
                                                        onChange={(e) => handleTerms(e)}
                                                        type='checkbox'
                                                        id='flexCheckDefault'
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    <label
                                                        className='form-check-label font-12 fw-normal'
                                                        htmlFor='flexCheckDefault'
                                                    >
                                                        {t("Accept")} 	&nbsp;
                                                        {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
                                                            {t("Terms & Conditions")}
                                                        </Link> */}
                                                        <button
                                                            data-bs-target="#terms-conditions-popup"
                                                            data-bs-toggle='modal'
                                                            className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                                                            {t("Terms & Conditions")}
                                                        </button>
                                                        <span className='text-danger'>*</span>
                                                    </label>
                                                </div>
                                                {formik.touched.status && formik.errors.status && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>
                                                                {formik.errors.status}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12 text-right">
                                            <div className="row justify-content-end">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-end">
                                                        <button type='button' onClick={handelSubmit} disabled={!dirty} className='btn btn-primary fw-bold px-10 py-3'>
                                                            {saved ? t("Saved") : t("Save")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <TermsAndConditionsPopup />
        </>
    )
};

export { PermitRequirements }
