import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    currencyFormater,
    CustomLoader2,
    NoDataFound1,
} from '../../../../constants/generics/customActios'
import { getInvoiceList } from '../../../../constants/axios/apis'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination'

export function RecentInvoices({ broker }: any) {
    const brokerAdminMyTeamId = broker?.id
    const { t } = useTranslation()
    const pageSize = 5
    const [brokerList, setbrokerList] = useState<any>()
    const [loading, setLoading] = useState<any>(true)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [totalbrokerList, setTotalbrokerList] = useState<any>(0)
    const [page, setPage] = React.useState(1)
    setTimeout(() => {
        setLoading(false)
    }, 1500)

    const handleClick = (newCurrentPage: any) => {
        setCurrentPage(newCurrentPage)
    }

    useEffect(() => {
        let page = currentPage
        const newPayload =
            '?currentPage=' +
            page +
            '&pageSize=' +
            pageSize +
            '&status=pending' +
            '&id_sort=1' +
            (brokerAdminMyTeamId ? '&user_id=' + brokerAdminMyTeamId : '&user_id=')
        getInvoiceList(newPayload).then((response: any) => {
            if (Array.isArray(response?.data) && response?.data?.length > 0) {
                setbrokerList(response?.data)
                setTotalbrokerList(response?.dataCount)
            } else {
                setbrokerList([])
                setTotalbrokerList(0)
            }
        })
    }, [currentPage])

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        handleClick(value)
    }

    if (!loading && (brokerList === undefined || brokerList?.length === 0)) {
        return <NoDataFound1 />
    }

    const brokerData = brokerList?.map((data: any, index: any) => (
        <tr key={data?.id}>
            <td>{(currentPage - 1) * pageSize + index + 1}</td>
            <td>{data?.InvoiceNo ? data?.InvoiceNo : '-'}</td>
            <td>{data?.unitNumber ? data?.unitNumber : '-'}</td>
            <td>{data?.propertyName ? data?.propertyName : '-'}</td>
            <td>{data?.leaseNumber ? data?.leaseNumber : '-'}</td>
            <td>
            {t('AED')} {data?.invoiceAmount ? currencyFormater(data?.invoiceAmount) : '-'}
            </td>
            <td className={data?.status === 'Pending' ? 'text-warning' : 'text-warning'}>
                <i className='bi bi-check-circle-fill text-warning me-2'></i>{data?.status ? data?.status : '-'}</td>
        </tr>
    ))

    return (
        <div className='rec-payment-act-sec'>
            <div className='row'>
                <div className='col-12'>
                    <div className='table-responsive'>
                        <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                            <thead>
                                <tr>
                                    <th className=''> {t('No.')} </th>
                                    <th className=''> {t('Invoice Number')} </th>
                                    <th className=''> {t('Unit Number')} </th>
                                    <th className=''> {t('Property Name')} </th>
                                    <th className=''> {t('Lease Number')} </th>
                                    <th className=''> {t('Price')}</th>
                                    <th className=''> {t('Status')} </th>
                                </tr>
                            </thead>
                            {loading ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={7}>
                                            <CustomLoader2 />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>{brokerList ? brokerData : ''}</tbody>
                            )}
                        </table>
                        <CompactPagination
                            pageSize={pageSize}
                            totalCount={parseInt(totalbrokerList)}
                            defaultPage={currentPage}
                            onPageChange={setCurrentPage}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
