import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { useFormik } from 'formik'
import { generateStatementRequest } from '../../../../constants/axios/apis'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { useDispatch } from 'react-redux'


export function Statement({ property_id }: any) {
  const [loading, setLoading] = useState<any>(false)
  const [message, setMessage] = useState<any>()
  const dispatch = useDispatch()
  const ref = useRef<any>(0)
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const initialValues = {
    property_id: property_id,
    from_date: today,
    to_date: today,
    category: 0,
    send_email: false,
  }
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        generateStatementRequest(
          values.property_id,
          moment(values.from_date).format('YYYY-MM-DD'),
          moment(values.to_date).format('YYYY-MM-DD'),
          values.category,
          values.send_email
        )
          .then((response: any) => {
            setLoading(false)
            // closeRef.current.click()
            if (response.errorCode != 1 && response?.data?.length > 0) {
              resetForm()
              window.open(response.data)
            } else {
              setMessage({
                error: 1,
                message: 'No data found',
              })
              ref.current.click()
              // alert(t("Something went wrong"))
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const handleStartDate = (e: any) => {
    formik.setFieldTouched('from_date')
    formik.setFieldValue('from_date', new Date(e))
  }

  const handleEndDate = (e: any) => {
    formik.setFieldTouched('to_date')
    formik.setFieldValue('to_date', new Date(e))
  }

  useEffect(() => {
    if (message?.message?.length > 0) {
      dispatch(PTOffer.actions.modalmessage(message))
    }
  }, [message])
  return (
    <><div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/payments/all'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            {t("Statement")}
          </h4>
        </div>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='col-12'>
            <div className='card mb-6'>
              <div className='card-body px-8 py-6'>
                <div className='row form-add-design'>
                  <div className='col-md-4 col-12'>
                    <div className='position-relative date-picker'>
                      <label className='form-label fw-bolder'>{t("Start Date")}</label>
                      <DatePicker
                        onChange={(e: any) => handleStartDate(e)}
                        value={formik.values.from_date}
                        className='form-control fw-bolder form-control-solid'
                        format='dd-MM-y'
                        clearIcon={null} />
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='position-relative date-picker'>
                      <label className='form-label fw-bolder'>{t("End Date")}</label>
                      <DatePicker
                        onChange={(e: any) => handleEndDate(e)}
                        value={formik.values.to_date}
                        className='form-control fw-bolder form-control-solid'
                        format='dd-MM-y'
                        clearIcon={null} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <div className='row form-add-design mt-2'>
                  <div className='col-md-12 col-12'>
                    <div className='mb-7'>
                      <label className='form-label fw-bolder'>{t("Select Category")}</label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid'
                        name='category'
                        onChange={formik.handleChange}
                        value={formik.values.category}
                        onBlur={formik.handleBlur}
                      >
                        <option value='0' selected>
                          {t("All")}
                        </option>
                        <option value='1'>{t("Rents")}</option>
                        <option value='2'>{t("Security Deposits")}</option>
                        <option value='3'>{t("Service Charges")}</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className=''>
                      <div className='form-check custom'>
                        <input
                          className='form-check-input'
                          {...formik.getFieldProps('send_email')}
                          checked={formik.values.send_email}
                          onBlur={formik.handleBlur}
                          type='checkbox'
                          id='flexCheckDefault' />
                        <label
                          className='form-check-label font-14 text-black fw-bolder'
                          htmlFor='flexCheckDefault'
                        >
                          {t("Send email copy")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 text-end mt-5'>
            <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading}>
              {!loading && <span className='indicator-label'>{t("Generate Report")}</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {t("Please wait...")}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div><p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
        {/*  */}
      </p></>
  )
}
