import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'react-router-dom'
import { getMaintenanceRequestsCategories } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function MaintenanceList() {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }

  const [categories, setCategories] = useState<any>()

  useEffect(() => {
    getMaintenanceRequestsCategories().then(({ data: categoriesData }) => {
      setCategories(categoriesData)
    })
  }, [])

  let content = categories?.map((category: any) =>
    category?.service_category_types.map((subcategory: any) => (
      <div key={subcategory.id}>
        <Link
          to={{ pathname: 'newrequest', state: { quickLinkCategory: subcategory } }}
          className='text-decoration-none'
        >
          <div className='card'>
            <div className='card-body py-3 px-5'>
              <div className='row g-0'>
                <div className='col-12 text-center'>
                  <img src={subcategory.icon} className='img-fluid' />
                </div>
                <div className='col-12'>
                  <h6 className='card-title mb-0'>{t(subcategory.name)}</h6>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    ))
  )

  return (
    <div className='com-card-section'>
      <div className='row mb-6 flex-stack'>
        <div className='col-sm-6 col-6'>
          <h4 className='card-title font-18 text-black'>{t("Maintenance")}</h4>
        </div>
        <div className='col-sm-6 col-6 text-end'>
          <Link to={'/maintenance/newrequest'} className='btn btn-theme btn-sm font-16'>
            {t("New Request")}
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <h4 className='card-title'> {t("New Request")} </h4>
                </div>
              </div>
              <div className='quick-link-sec maintainence'>
                <div>
                  <div className='row'>
                    <div className='col-12'>
                      <Slider {...settings}>
                        {categories ? content : ''}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
