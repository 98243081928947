import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { UploadDocuments } from './UploadDocuments/UploadDocuments';
import { useTranslation } from 'react-i18next'

export function DocumentUpload({type, contractActivity, contractActivityUpdated}: any) {
  const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row align-items-center">
                                <div className="col-lg-9 col-md-8 col-12">
                                    <h4 className="font-16 fw-bold text-normal">{t("Contract")} {type} {t("Is Paid")}</h4>
                                    <p className="mb-0 text-normal font-12">{t("Upload documents needed to continue the process")} </p>
                                </div>
                                <div className="col-lg-3 col-md-4 col-12">
                                    <Link to={''} className='btn btn-primary w-100' data-bs-target='#upload-documents-preview'
                                          data-bs-toggle='modal'
                                          data-bs-dismiss='modal'>{t("Upload")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UploadDocuments contractActivity={contractActivity} contractActivityUpdated={contractActivityUpdated} />
        </div>
    );
}



