import React, { useState, useRef, FC } from 'react';
import {Link} from 'react-router-dom'
import PlusIcon from "../../../../../../../assets/images/plus-circle.svg";

export function StepTwo() {
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="row form-add-design">
                  <div className='col-12'>
                        <div className='mb-5'>
                            <h5 className='className="modal-title fw-bold font-18 cpt-headding'>
                              Contact Information
                            </h5>
                        </div>
                    </div> 
                    {/* <div className='col-12'>
                        <div className='row'>
                            <div className='col-6 ps-0'>
                                <div className='mb-6'>
                                    <input
                                        value="Muhammad"
                                        type='text'
                                        className='form-control fw-bolder bg-white'
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className='col-6 pe-0'>
                                <div className='mb-6'>
                                    <input
                                        value="Ashif"
                                        type='text'
                                        className='form-control fw-bolder bg-white'
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">First Name</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">Last Name</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">Contact Number</label>
                      <input type="tel" 
                        className="form-control fw-bolder bg-secon mb-1" 
                        placeholder="" 
                      />
                        <div className="form-check form-switch w-100">
                            <label className="form-check-label w-75" htmlFor="flexSwitchCheckChecked">If any Emergency contact this person</label>
                            <span className="w-25 text-end ten-checkbox">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked />
                            </span>
                        </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">Email ID</label>
                      <input type="email" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">Department</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">Designation</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-5 upload-input">
                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> Upload Emirates ID </label>
                            <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                            <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="mb-5 upload-input">
                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> Upload Emirates ID </label>
                            <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                            <button onClick={handleUpload} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                <span> {uploadedFileName ? uploadedFileName : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                            </button>
                        </div>
                    </div>
                    {/* <div className="col-12">
                        <div className='mb-3'>
                            <button className='btn bg-secondary border-0 fw-bold px-10 py-3 w-100'>
                                Add Person
                            </button>  
                        </div>                    
                    </div> */}
                </div>
            </div>
        </div>
    );
}