import LocationImg from '../../../../assets/images/icon_place.svg'
import SquareFt from '../../../../assets/images/icon-sqft.svg'
import Bed from '../../../../assets/images/icon-bed.svg'
import Bath from '../../../../assets/images/icon-bath.svg'
import Cars from '../../../../assets/images/icon-parking.svg'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'react-router-dom'
import NoDataFoundImage from '../../../../assets/images/no-data-found.svg'
import { currencyFormater } from '../../../../constants/generics/customActios'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function MyOffers(props: any) {
  const { offer } = props

  const { t } = useTranslation();

  return (
    <div className='com-card-section mb-7'>
      <div className='row align-items-center'>
        <div className='col-md-6 col-8'>
          <h4 className='card-title'> {t("My Offers")} </h4>
        </div>
        {offer?.errorCode != 1 && offer?.description != 'No data found.' && offer?.data?.length > 0 ?
          <div className='col-md-6 col-4 text-end'>
            <Link to='/pt-activity/my-offer' className='text-decoration-none view-all'>
              <span> {t("View All")} </span>
              {/*<img src={RightArrow} className="img-fluid" />*/}
            </Link>
          </div>
          : <></>
        }
      </div>
      <div className='upcoming-work-order-sec my-visits-sec'>
        <div className='row'>
          <div className='col-md-12 col-12 col-lg-12'>
            <div className='row'>
              {offer?.errorCode != 1 && offer?.description != 'No data found.' && offer?.data?.length > 0 && offer
                ? offer?.data?.slice(-2)?.map((item: any, i: any) => {
                  return (
                    <div className='col-md-6 col-12'>
                      <Link to={'/pt-activity/my-offer'}>
                        <div className='card mt-4 lease-card'>
                          <div className='card-body p-0'>
                            <div className='row'>
                              <div className='col-12'>
                                <div className='img-bg'>
                                  <img
                                    src={item?.property_cover_img}
                                    className='w-100 img-fluid position-relative'
                                    alt="img"
                                  />
                                  <div className='property-overlay'>
                                    <div className="row align-items-center g-0">
                                      <div className="col-6">
                                        <h6>{parseInt(item?.property_search_type) === 1 ? t('Residential') : parseInt(item?.property_search_type) === 2 ? t('Commercial') : item?.property_search_type}
                                        </h6>
                                      </div>
                                      <div className="col-6 text-end">
                                        <span className='price-amount d-block'>
                                          {t("AED")} <strong>{item?.price?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</strong> / {t("Year")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='p-4'>
                                  <h4 className='card-title mb-2'>
                                    {item?.property_name}
                                  </h4>
                                  {/* <span className='price-amount d-block'>
                                                {t("AED")} <strong>{item?.price?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</strong> / {t("Year")}
                                              </span> */}
                                  <span className='location-color'>
                                    <img src={LocationImg} width="12" height="17" className='me-2' alt="img" />
                                    {item?.propertyCode?.substring(item?.propertyCode?.lastIndexOf("-") + 1, item?.propertyCode?.length) + ' | '} {item?.buildingName && item?.buildingName + ' | '}  {item?.address && item?.address + ' | '}  {item?.location && item?.location}
                                  </span>
                                  <div className='flex-wrap d-flex mt-4 text-black'>
                                    {item?.bedrooms?.length > 0 && item?.bedrooms != 0 ? (
                                      <div className='me-3 mb-2'>
                                        <span className='square-feet'>
                                          <img src={Bed} /> {item?.bedrooms} Bedrooms
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )
                                    }
                                    {item?.bathrooms?.length > 0 && item?.bathrooms != 0 ? (
                                      <div className='me-3 mb-2'>
                                        <span className='square-feet'>
                                          <img src={Bath} /> {item?.bathrooms} Bathrooms
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )
                                    }
                                    {item?.builtInArea > 0 && item?.builtInArea != 0 ? (
                                      <div className='me-3 mb-2'>
                                        <span className='square-feet'>
                                          <img src={SquareFt} alt="img" /> {item?.builtInArea}{' '}
                                          {t("Sqmt")}
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )
                                    }
                                    <div className='mb-2'>
                                      <span className='square-feet'>
                                        <img src={Cars} alt="img" /> {item?.isParkingAvailable === 'Y' ? t("Yes") : t("No")}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='offer-price'>
                                    <span>{t("Offer Price")}</span>
                                    <span> - </span>
                                    <span className='m-0 font-13'>
                                      <strong> {t("AED")} {currencyFormater(item?.rent_amount)}</strong>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
                : <div className='col-12 mt-3'>
                <div className='card'>
                <div className="card-body">
                  <div className="col-12">
                    <div className="row justify-content-center no-data-found-cd">
                      <div className="col-12 text-center">
                        <img src={NoDataFoundImage} alt="No Data Found" className="img-fluid" />
                      </div>
                      <div className="col-12 text-center">
                        <span className='d-block text-center font-16 fw-bolder text-black'> {t("No Data Found")} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
