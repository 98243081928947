import React, {useState, useEffect, FC} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import RightArrow from '../../../../assets/images/Right_Arrow.png'
import {useTranslation} from 'react-i18next'

const PropertiesList: React.FC<any> = ({propertyFilter}) => {
  const location = useLocation()
  const {t} = useTranslation()

  return (
    <div className='row'>
      {location.pathname === '/broker-admin-dashboard/available-properties' ||
      location.pathname === '/broker-admin-dashboard/upcoming-properties' ||
      location.pathname === '/broker-admin-dashboard/latest-properties' ||
      location.pathname === '/broker-admin-dashboard/trending-properties' ? (
        <div className='col-md-12 col-12'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='row align-items-center'>
                <div className='col-md-12 col-lg-9 col-sm-6 col-12'>
                  <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                      {propertyFilter === 'Filter' ? (
                        <>
                          <li className='nav-item'>
                            <Link
                              className={
                                `nav-link pb-1 position-relative text-active-primary me-12 ` +
                                (location.pathname ===
                                  '/broker-admin-dashboard/available-properties' && 'active')
                              }
                              to='/broker-admin-dashboard/available-properties'
                            >
                              {t('Available Properties')}
                            </Link>
                          </li>
                          <li className='nav-item'>
                            <Link
                              className={
                                `nav-link pb-1 position-relative text-active-primary me-6 ` +
                                (location.pathname ===
                                  '/broker-admin-dashboard/upcoming-properties' && 'active')
                              }
                              to='/broker-admin-dashboard/upcoming-properties'
                            >
                              {t('Upcoming Properties')}
                            </Link>
                          </li>
                        </>
                      ) : (
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-12 ` +
                              (location.pathname ===
                                `/broker-admin-dashboard/${propertyFilter.toLowerCase()}-properties` &&
                                'active')
                            }
                            to={`/broker-admin-dashboard/${propertyFilter.toLowerCase()}-properties`}
                          >
                            {propertyFilter}
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className='col-md-12 col-lg-3 col-sm-6 col-12 text-end'>
                  <Link
                  to={
                    // location.pathname === '/broker-admin-dashboard/available-properties' ? '/broker-admin-dashboard/viewall-available-properties' :
                    location.pathname === '/broker-admin-dashboard/upcoming-properties' ? '/broker-admin-dashboard/viewall-upcoming-properties' :
                    location.pathname === '/broker-admin-dashboard/latest-properties' ? '/broker-admin-dashboard/viewall-latest-properties' :
                    location.pathname === '/broker-admin-dashboard/trending-properties' ? '/broker-admin-dashboard/viewall-trending-properties' :
                    '/broker-admin-dashboard/viewall-available-properties'
                  }
                    // to={'/broker-admin-dashboard/viewall-available-properties'}
                    className='text-decoration-none view-all d-block mt-2 text-end'
                  >
                    <span> {t('View All')} </span>
                    <img src={RightArrow} className='img-fluid' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : location.pathname === '/broker-admin-dashboard/viewall-available-properties' ||
        location.pathname === '/broker-admin-dashboard/viewall-upcoming-properties' ||
        location.pathname === '/broker-admin-dashboard/viewall-latest-properties' ||
        location.pathname === '/broker-admin-dashboard/viewall-trending-properties' ? (
        <div className='col-md-12 col-12'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='row align-items-center'>
                <div className='col-md-12'>
                  <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-12 ` +
                            (location.pathname ===
                              '/broker-admin-dashboard/viewall-available-properties' && 'active')
                          }
                          to='/broker-admin-dashboard/viewall-available-properties'
                        >
                          {t('Available Properties')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-6 ` +
                            (location.pathname ===
                              '/broker-admin-dashboard/viewall-upcoming-properties' && 'active')
                          }
                          to='/broker-admin-dashboard/viewall-upcoming-properties'
                        >
                          {t('Upcoming Properties')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-6 ` +
                            (location.pathname ===
                              '/broker-admin-dashboard/viewall-latest-properties' && 'active')
                          }
                          to='/broker-admin-dashboard/viewall-latest-properties'
                        >
                          {t('Latest Properties')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-6 ` +
                            (location.pathname ===
                              '/broker-admin-dashboard/viewall-trending-properties' && 'active')
                          }
                          to='/broker-admin-dashboard/viewall-trending-properties'
                        >
                          {t('Trending Properties')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export {PropertiesList}
