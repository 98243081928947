import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import DatePicker from 'react-date-picker';
// import moment, { min } from 'moment'
import SuccessPopup from '../../../../leads/components/SuccessPopup';




export const ReservedPropertyPopup: React.FC<any> = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation()
  const currentDate = new Date();
  const tenDaysFromNow = new Date(currentDate.setDate(currentDate.getDate() + 10));
  const [date, setDate] = useState(tenDaysFromNow);
  const profileDetailsSchema = Yup.object().shape({
    // description: Yup.string().required('Description is required'),
    // application: Yup.string().required('Application is required'),
    // amount: Yup.string().required('Amount is required'),
    // disc_type: Yup.string().required('Disc_Type is required'),
    // discount: Yup.string().required('Discount is required'),
    // tax: Yup.string().required('Tax is required'),
    // charge_value: Yup.string().required('Charge Value is required'),
    // from_date: Yup.date().nullable().required('From Date is required'),
    // to_date: Yup.date().nullable().required('To Date is required'),
    // due_date: Yup.date().nullable().required('Due Date is required'),
  })
  
  const initialValues = {
    description: 'Blocking Fee', 
    application : '',
    amount : '9,500,000',
    disc_type : '',
    discount : '0',
    tax : '0',
    charge_value: '1,42,500',
    from_date: new Date('7/24/2024'),
    to_date : new Date('7/24/2025'),
    due_date: date,
    checque_num: '0',
    cheque_date: null

  };

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      console.log(values);
      setShowModal(true);
    },
  })

  const [fromDate, setFromDate] = useState<any>()
  const [toDate, setToDate] = useState<any>()
  const [dueDate, setDueDate] = useState<any>()

  const handleFromDateChange = (date:any) => {
    setFromDate(date);
  };
  const handleToDateChange = (date:any) => {
    setToDate(date);
  };
  const handleDateChange = (date:any) => {
    setDueDate(date);
  };
  const handleCloseModal = () => setShowModal(false);
  return (
    <>
      <div className='row'>
        <div className='modal fade custom-modal' data-bs-backdrop="static" data-bs-keyboard="false" id='reserved-popup'>
          <div className='modal-dialog modal-lg modal-dialog-centered'>
            <div className='modal-content rounded-10'>
              <div className='modal-header border-0 justify-content-start py-4'>
                <h5 className='modal-title fw-bold'>{t('Reserve Property')}</h5>
                <button type='button' className='btn-close' data-bs-dismiss='modal'>
                  &times;
                </button>
              </div>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='modal-body px-10 pb-0'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className='col-form-label fw-bold'>Description </label>
                      <input
                        type='text'
                        disabled
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Description'
                        {...formik.getFieldProps('description')}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.description}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6'>
                      <label className='col-form-label fw-bold'>Applicant  </label>
                      <select disabled
                        className="form-control form-control-lg form-control-solid"
                        {...formik.getFieldProps('application')}
                      >
                        {/* <option value="" label="Select application" /> */}
                        <option value="bob" label="Bob" selected/>
                        <option value="Jane" label="Jane"/>
                        <option value="Eva" label="Eva" />
                        <option value="Grace" label="Grace" />
                      </select>
                      {formik.touched.application  && formik.errors.application  && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.application }</div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6'>
                      <label className='col-form-label fw-bold'>Amount</label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Amount '
                        {...formik.getFieldProps('amount')}
                      />
                      {formik.touched.amount  && formik.errors.amount  && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.amount }</div>
                        </div>
                      )}
                    </div>
                    {/* <div className='col-md-6'>
                      <label className='col-form-label fw-bold'> Disc.Type</label>
                      <select
                        className="form-control form-control-lg form-control-solid"
                        {...formik.getFieldProps('disc_type')}
                      >
                        <option value="" label="Select disc_type" />
                        <option value="app1" label="Type 1" />
                        <option value="app2" label="Type 2" />
                        <option value="app3" label="Type 3" />
                      </select>
                      {formik.touched.disc_type  && formik.errors.disc_type  && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.disc_type }</div>
                        </div>
                      )}
                    </div> */}
                    <div className='col-md-6'>
                      <label className='col-form-label fw-bold'>Discount</label>
                      <input
                        type='text'
                        disabled
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Discount'
                        {...formik.getFieldProps('discount')}
                      />
                      {formik.touched. discount  && formik.errors.discount  && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.discount }</div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6'>
                      <label className='col-form-label fw-bold'>Tax </label>
                      <input
                        type='text'
                        disabled
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Tax'
                        {...formik.getFieldProps('tax')}
                      />
                      {formik.touched. tax  && formik.errors.tax  && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.tax }</div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6'>
                      <label className='col-form-label fw-bold'>To be collected Amount                       </label>
                      <input
                        type='text'
                        disabled
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Charge Value'
                        {...formik.getFieldProps('charge_value')}
                      />
                      {formik.touched. charge_value  && formik.errors.charge_value  && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.charge_value }</div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6 date-picker'>
                      <label className='col-form-label fw-bold'>From Date</label>
                      {/* <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='From Date'
                        {...formik.getFieldProps('from_date')}
                      /> */}
                      <DatePicker
                          onChange={(date:any) => formik.setFieldValue('from_date', date)}
                          value={formik.values.from_date}
                          className='form-control form-control-lg form-control-solid'
                          clearIcon={null}
                        />
                      {formik.touched. from_date  && formik.errors.from_date  && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.from_date }</div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6 date-picker'>
                      <label className='col-form-label fw-bold'>To Date</label>
                      {/* <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='To Date'
                        {...formik.getFieldProps('to_date')}
                      /> */}
                      <DatePicker
                         onChange={(date:any) => formik.setFieldValue('to_date', date)}
                         value={formik.values.to_date}
                        // {...formik.getFieldProps('to_date')}
                        className='form-control form-control-lg form-control-solid'
                        clearIcon={null}
                      />
                      {formik.touched.to_date  && formik.errors.to_date  && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.to_date }</div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6 date-picker'>
                      <label className='col-form-label fw-bold'>Due Date</label>
                      {/* <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Due Date'
                        {...formik.getFieldProps('due_date')}
                      /> */}
                      <DatePicker
                        onChange={(date:any) => formik.setFieldValue('due_date', date)}
                        value={formik.values.due_date}
                        className='form-control form-control-lg form-control-solid'
                        clearIcon={null}
                      />
                      {formik.touched.due_date  && formik.errors.due_date  && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.due_date }</div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6'>
                      <label className='col-form-label fw-bold'>Cheque Number</label>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Cheque Number'
                        {...formik.getFieldProps('checque_num')}
                      />
                      {formik.touched.charge_value && formik.errors.charge_value && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.charge_value}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-6 date-picker'>
                      <label className='col-form-label fw-bold'>Cheque Date</label>
                      {/* <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='To Date'
                        {...formik.getFieldProps('to_date')}
                      /> */}
                      <DatePicker
                        onChange={(date: any) => formik.setFieldValue('cheque_date', date)}
                        value={formik.values.cheque_date}
                        // {...formik.getFieldProps('to_date')}
                        className='form-control form-control-lg form-control-solid'
                        clearIcon={null}
                      />
                      {formik.touched.cheque_date && formik.errors.cheque_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>{formik.errors.cheque_date}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='modal-footer border-0 pt-0 px-10 mt-3'>
                  <button
                    type='button'
                    className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                    data-bs-toggle='modal'
                    data-bs-dismiss='modal'
                  >
                    {t('Cancel')}
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary fw-bold px-10 py-3'
                    data-bs-dismiss='modal'
                  >
                    {t('Reserve')}
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      <SuccessPopup show={showModal} onClose={handleCloseModal} message="Reserved successfully" />

    </>
  )
}
