import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import LeftArrow from '../../../../src/assets/images/Left_Arrow.png'
import { AddLeadsForm } from './AddLeadForm'
export const ViewLead: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className='com-card-section'>
            <div className='row gy-5 g-xl-8'>
                <div className='col-md-12 col-12'>
                    <div className='row align-items-center mb-6'>
                        <div className='col-md-6 col-12'>
                            <h4 className='card-title font-18 text-black mb-0'>
                                <Link to={'/leads/list'} className=''>
                                    <img src={LeftArrow} alt='back' className='img-fluid me-5' />
                                </Link>
                                {t("Lead Details")}
                            </h4>
                        </div>
                        {/* <div className='col-md-6 col-12 text-end'>
              <button className='btn btn-theme py-2 font-14 fw-normal mt-3 mt-md-0'>
                {t("Emergency?")}
              </button>
            </div> */}
                    </div>
                </div>
 
                    <div className="row">
                        <div className="col-12 mt-0">
                            <div className="card mb-7">
                                <div className="card-body px-7 py-6">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Name</h6>
                                            <span className="text-black fw-bold font-14">Shaik Mohammad</span>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <div className="">
                                                <h6 className="text-theme-gray mb-3 fw-normal font-13">Email</h6>
                                                <span className="text-black fw-bold font-14">shaikmohammad@gmail.com</span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Mobile Number</h6>
                                            <span className="text-black fw-bold font-14">+971 554056310</span>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">DOB</h6>
                                            <span className="text-black fw-bold font-14">06-09-2024</span>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Nationality</h6>
                                            <span className="text-black fw-bold font-14">United Arab Emirates</span>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Emergency Contact Name </h6>
                                            <span className="text-black fw-bold font-14">Nisar</span>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Emergency Contact Email</h6>
                                            <span className="text-black fw-bold font-14">nisar@gmail.com</span>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Emergency Contact Number</h6>
                                            <span className="text-black fw-bold font-14">+971 544056310</span>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Date of Enquiry</h6>
                                            <span className="text-black fw-bold font-14">12-07-2024</span>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Broker Name</h6>
                                            <span className="text-black fw-bold font-14">James Potter</span>
                                        </div>
                                        <div className="col-12 mb-5">
                                            <h6 className="text-theme-gray mb-3 fw-normal font-13">Notes</h6>
                                            <span className="text-black fw-bold font-14">Create Lead</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 
        </div>
    )
}