import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'
import {DocxTab} from "./DocxTab/DocxTab";
import {CommunityDocx} from "./CommunityDocx/CommunityDocx";

type Props = {
    allDocuments?: any
}

const InnerDocxList: React.FC<Props> = ({allDocuments}: any) => {
    return (

        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3 movein-moveout">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <Switch>
                                        {/* <Route path='/my-unit/documents/community-docx'>
                                            <DocxTab />
                                            <CommunityDocx />
                                        </Route> */}
                                        <Route path='/my-unit/documents/personal-docx'>
                                            {/* <DocxTab /> */}
                                            <CommunityDocx allDocuments={allDocuments} />
                                        </Route>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {InnerDocxList}