import React, { useEffect, useState } from 'react'
import { AttachmentPopup } from './AttachmentPopup'
import { PhotoViewPopup } from './photoViewPopup'
import { useTranslation } from 'react-i18next'

export function Vacate({ selectedIssue, contract }: any) {
    const [issue, setIssue] = useState<any>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (selectedIssue) {
            setIssue(selectedIssue)
            setLoading(false)
        }
    }, [selectedIssue])
    const { t } = useTranslation();

    return (
        <>
            {!loading && issue && issue?.code == 'VAC' ? (
                <div className='row'>
                    <div className='col-12'>
                        <div className='accordion accordion-flush' id='accordionFlushExampleVacateCard'>
                            <div className='accordion-item offers_details_accordian_div1'>
                                <h2 className='accordion-header position-relative' id='flush-headingVacateCard'>
                                    <button
                                        className='accordion-button collapsed accordian_bt'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#flush-collapseVacateCard'
                                        aria-expanded='false'
                                        aria-controls='flush-collapseVacateCard'
                                    >
                                        {t("Vacate")}
                                    </button>
                                    <span className='services-badge'>
                                        {issue?.status == 0 ? t("Open") : t("Closed")}
                                    </span>
                                </h2>
                                <div
                                    id='flush-collapseVacateCard'
                                    className='accordion-collapse collapse show'
                                    aria-labelledby='flush-headingVacateCard'
                                    data-bs-parent='#accordionFlushExampleVacateCard'
                                >
                                    <div className='accordion-body py-5'>
                                        <div className='row form-add-design'>
                                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                <div className='mb-10'>
                                                    <label className='form-label fw-bolder'>{t("Vacating Reason")}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control fw-bolder form-control-solid'
                                                        disabled
                                                        value={issue?.details?.reason}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-12'>
                                                <div className='mb-10'>
                                                    <label className='form-label fw-bolder'>{t("Termination Letter")}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control fw-bolder form-control-solid'
                                                        disabled
                                                        // value={issue?.images?.supp_document.length}
                                                        value={Array.isArray(issue?.images?.termination_letter)
                                                            ? issue?.images?.termination_letter.length
                                                            : issue?.images?.termination_letter
                                                                ? 1
                                                                : 0}
                                                    />
                                                    <>
                                                        {issue?.images?.termination_letter &&
                                                            Array.isArray(issue?.images?.termination_letter) &&
                                                            issue?.images?.termination_letter.length ? (
                                                            <PhotoViewPopup images={issue?.images?.termination_letter} />
                                                        ) : ''}
                                                    </>
                                                    <>
                                                        {issue?.images?.termination_letter &&
                                                            !Array.isArray(issue?.images?.termination_letter) &&
                                                            issue?.images?.termination_letter.length ? (
                                                            <PhotoViewPopup images={[issue?.images?.termination_letter]} />
                                                        ) : ''}
                                                    </>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                <div className='mb-10'>
                                    <label className='form-label fw-bolder'>Renewal Start Date</label>
                                    <input
                                        type='text'
                                        className='form-control fw-bolder form-control-solid'
                                        disabled
                                        value='21-Feb-2021'
                                    />
                                    <img src={Dateicon} className="img-fluid cal-ioc" />
                                </div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                <div className='mb-10 position-relative'>
                                    <label className='form-label fw-bolder'>Renewal End Date</label>
                                    <input
                                        type='text'
                                        className='form-control fw-bolder form-control-solid'
                                        disabled
                                        value='20-Jan-2022'
                                    />
                                    <img src={Dateicon} className="img-fluid cal-ioc" />
                                </div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                <div className='mb-10 position-relative'>
                                    <label className='form-label fw-bolder'>Renewal Annual Amount</label>
                                    <input
                                        type='text'
                                        className='form-control fw-bolder form-control-solid'
                                        value='AED 12350'
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                <div className='mb-10 position-relative'>
                                    <label className='form-label fw-bolder'>Supporting Documents</label>
                                    <div className='d-flex fdrr-rtl'>
                                        <input
                                            type='text'
                                            name=''
                                            className='form-control expected-rent-input'
                                            placeholder=''
                                            value='3 Attachments'
                                            disabled
                                        />
                                        <button
                                            type='submit'
                                            className='bg-white cancel-bt px-7'
                                            data-bs-target='#attachment-popup'
                                            data-bs-toggle='modal'
                                        >
                                            <i className='bi bi-eye font-18 text-black'></i>
                                        </button>
                                    </div>
                                </div>
                                <AttachmentPopup />
                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    )
}
