import React, {useEffect} from 'react'
import {useState} from 'react'
import {PhotoViewPopup} from './photoViewPopup'
import {ServiceDataInfo} from './ServiceDataInfo'
import { useTranslation } from 'react-i18next'

export function RefundRequestDetail({selectedIssue}: any) {
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      {!loading && issue && issue?.code == 'SDRR' ? (
        <div className='row'>
          <div className='col-12'>
            <div className='accordion accordion-flush' id='two'>
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header position-relative' id='flush-headingOne'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapse2'
                    aria-expanded='false'
                    aria-controls='flush-collapseOne'
                  >
                    {t("Refund Request Detail")}
                  </button>
                  <span className='services-badge'>
                    {issue?.status == 0 ? t("Open") : t("Closed")}
                  </span>
                </h2>
                <div
                  id='flush-collapse2'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingOne'
                  data-bs-parent='#two'
                >
                  <div className='accordion-body py-5'>
                    <div className='row form-add-design'>
                        <div className='col-md-4 col-12'>
                            <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Refund to")}</label>
                            <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                disabled
                                value={issue?.details?.refund_to === "1"? 'Me': issue?.details?.refund_to === "2" ? 'Other' : ''}
                            />
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Customer Name")}</label>
                            <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                disabled
                                value={issue?.details?.name}
                            />
                            </div>
                        </div>
                        
                        <div className='col-md-4 col-12'>
                            <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Bank Name")}</label>
                            <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                disabled
                                value={issue?.details?.bank}
                            />
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Account Number")}</label>
                            <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                disabled
                                value={issue?.details?.account_number}
                            />
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("IBAN Number")}</label>
                            <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                disabled
                                value={issue?.details?.iban_number}
                            />
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Swift Code")}</label>
                            <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                disabled
                                value={issue?.details?.swift_code}
                            />
                            </div>
                        </div>
                      
                    </div>
                    <hr />
                    <ServiceDataInfo issue={issue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
