import React, { useState, useEffect, useRef } from 'react';
import SuccessCard from "../../../../assets/images/Succesfull_card.png";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

export function SubmittedModal() {
    const { t } = useTranslation();

    return (
        <div className="modal fade custom-modal redeem-QR" id="submitted-popup">
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header bg-white border-0">
                        <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body py-10">
                        <div className="row g-5">
                            <div className="col-md-12">
                                <div className="text-center mx-md-12">
                                    <div className="redeem-offer-QR">
                                        <img src={SuccessCard} alt="tick-img" className="img-fluid mb-5" />
                                        <h1 className="text-theme font-24 mb-2 fw-bold">{t("Successful")}</h1>
                                        <p className="mb-3 font-12 fw-bolder mx-md-10">
                                            {t("Your Request has been Submitted!")}
                                        </p>
                                        <button data-bs-dismiss="modal" className="btn btn-primary btn-lg fw-bolder font-12 w-100">
                                            {t("Continue")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}