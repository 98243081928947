import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Redirect, Route, Switch} from 'react-router-dom'
import {UpcomingCard} from '../MyVisit/UpcomingProperties/UpcomingCard'

export function MyLikes(props: any) {
  const location = useLocation()
  const {likes} = props
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3 movein-moveout'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <UpcomingCard data={likes} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
