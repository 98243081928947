import React, { useState, useEffect, useRef } from 'react'
import { SafetySecurityPrecautions } from './SafetySecurityPrecautions'
import { WorkInformation } from './WorkInformation'
import completedStep from '../../../../assets/images/completed-step.png'
import { generalPTWRequest } from '../../../../constants/axios/apis'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function GeneralPTWForm({ contract, setMessage }: any) {
  const [loading, setLoading] = useState<any>(false)
  const [workInformation, setWorkInformation] = useState<any>()
  const [safetyPrecatuions, setSafetyPrecatuions] = useState<any>()
  const [isSubmitable, setIsSubmitable] = useState<any>(false)
  const history = useHistory()
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const nextRef = useRef<any>()
  const { t } = useTranslation();

  const handelFinalSubmit = () => {
    if (workInformation?.saved && safetyPrecatuions?.saved) {
      setLoading(true)
      setIsSubmitable(false)
      var FormData = require('form-data')
      var data = new FormData()
      data.append('is_contractor', workInformation.is_contractor)
      data.append('contractor_name', workInformation.contractor_name)
      data.append('contractor_phone', workInformation.contractor_phone)
      data.append('contractor_company', workInformation.contractor_company)
      data.append('category_id', workInformation.category_id)
      data.append('start_date', moment(workInformation.start_date).format('YYYY-MM-DD'))
      data.append('end_date', moment(workInformation.end_date).format('YYYY-MM-DD'))
      data.append('start_time', workInformation.start_time)
      data.append('end_time', workInformation.end_time)
      data.append('description', workInformation.description)
      data.append('property_id', workInformation.property_id)
      data.append('photo[]', workInformation.photo)
      data.append('trading_licence', workInformation.trading_licence)
      data.append('contractor_id', workInformation.contractor_id)
      safetyPrecatuions.precautions_ids.forEach((precaution_id: any, index: any) => {
        data.append(`safety_prec[${[index]}]`, precaution_id)
      });
      data.append('signature', safetyPrecatuions.signature)

      setTimeout(() => {
        generalPTWRequest(data)
          .then((response: any) => {
            if (response.errorCode === 1) {
              setMessage(response.errorDescription)
              failRef.current.click()
            } else if (response.errorCode === 0) {
              setMessage(t("Your General PTW Request has been Submitted!"))
              successRef.current.click()
              history.push('/my-services/openissues')
            } else {
              console.log(response, 'response');
            }
            setLoading(false)
            setIsSubmitable(false)
          })
          .catch((e: any) => {
            // console.log(e, 'Error');
          })
      }, 1000)

    } else {

    }
  }

  useEffect(() => {
    if (workInformation?.saved && safetyPrecatuions?.saved) {
      setIsSubmitable(true)
    } else {
      setIsSubmitable(false)
    }
  }, [workInformation, safetyPrecatuions])

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion accordion-flush' id='accordionFlushExample'>
            <div className='accordion-item offers_details_accordian_div1'>
              <h2 className='accordion-header' id='flush-headingOne'>
                <button
                  className='accordion-button collapsed accordian_bt'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapseOne'
                  aria-expanded='false'
                  aria-controls='flush-collapseOne'
                >
                  {t("Work Information")}
                  {(workInformation?.saved) &&
                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                      <img src={completedStep} alt='complete-step' />
                    </span>}
                </button>
              </h2>
              <div
                id='flush-collapseOne'
                className='accordion-collapse collapse show'
                aria-labelledby='flush-headingOne'
                data-bs-parent='#accordionFlushExample'
              >
                <div className='accordion-body pb-5 pt-0'>
                  <div className='row form-add-design'>
                    <WorkInformation setWorkInformation={setWorkInformation} contract={contract} nextRef={nextRef} />
                  </div>
                </div>
              </div>
            </div>
            <div className='accordion-item offers_details_accordian_div1 payment-plan-details'>
              <h2 className='accordion-header' id='flush-headingTwo'>
                <button
                  className='accordion-button collapsed accordian_bt'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapseTwo'
                  aria-expanded='false'
                  aria-controls='flush-collapseTwo'
                  ref={nextRef}
                >
                  {t("Safety and Security Precautions")}
                  {(safetyPrecatuions?.saved) &&
                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                      <img src={completedStep} alt='complete-step' />
                    </span>}
                </button>
              </h2>
              <div
                id='flush-collapseTwo'
                className='accordion-collapse collapse'
                aria-labelledby='flush-headingTwo'
                data-bs-parent='#accordionFlushExample'
              >
                <div className='accordion-body py-5'>
                  <div className='row form-add-design'>
                    <SafetySecurityPrecautions setSafetyPrecatuions={setSafetyPrecatuions} nextRef={nextRef} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 text-end'>
          <div className='mt-10'>
            <button type='button'
              onClick={handelFinalSubmit}
              disabled={!isSubmitable}
              className='btn btn-primary fw-bold px-10 py-3 w-25'
            >
              {!loading && <span className='indicator-label'>{t("Submit")}</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {t("Please wait...")}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        <p
          ref={successRef}
          data-bs-target='#req-submitted-popup'
          data-bs-toggle='modal'
          data-bs-dismiss='modal'
        >
          {/*  */}
        </p>
        <p
          ref={failRef}
          data-bs-target='#req-failed-popup'
          data-bs-toggle='modal'
          data-bs-dismiss='modal'
        >
          {/*  */}
        </p>
      </div>
    </div>
  )
}
