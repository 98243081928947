export default [
    {
        id: 1,
        no: "01",
        percentageofcompletion: "25",
        // paymentmode: "Bank Transfer",
        amount: "2,473,500.00",
        duedate: "15-04-2023",
        status: "Paid"
    },
    {
        id: 2,
        no: "02",
        percentageofcompletion: "50",
        // paymentmode: "Bank Transfer",
        amount: "3,947,000.00",
        duedate: "30-08-2024",
        status: "Pending",
        action: ""
    },
];