import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import Place from '../../../../../assets/images/place.svg'
import Sqft from '../../../../../assets/images/icon-sqft.svg'
import Bed from '../../../../../assets/images/icon-bed.svg'
import Bath from '../../../../../assets/images/icon-bath.svg'
import YesIcon from '../../../../../assets/images/icon-parking.svg'
import { RootState } from '../../../../../constants'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

export function MainCard() {
	let offerList: any = []
	offerList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.offerDetails)
	const location = useLocation()
	const intl = useIntl()
	const history = useHistory()
	let userRole: number = 0
	let userEmail = ''
	let userDetail = localStorage.getItem('userDetail')
	if (userDetail) {
		userEmail = JSON.parse(userDetail).email
		userRole = JSON.parse(userDetail).id_role
	}
	const { t } = useTranslation();

	return (
		<div className='mb-5'>
			<div className=''>
				<div className=''>
					<div className='row align-items-center'>
						<div className='col-md-7 col-12'>
							<div className='pm-offers-and-renewals-details-card'>
								<div className='card'>
									<div className='row'>
										<div className='col-sm-12 col-12'>
											<div className='offer_main_img h-100'>
												<img
													src={offerList?.data?.property_details?.coverImage}
													alt='Work Orders'
													className='img-fluid'
												/>												
												<div className="card-status">
													<span className='badge badge-pill badge-dark prop-type-badge'>
														{parseInt(offerList?.data?.property_details?.propertySearchType) === 1
															? t('Residential')
															: parseInt(offerList?.data?.property_details?.propertySearchType) === 2
																? t('Commercial')
																: offerList?.data?.property_details?.propertySearchType}
													</span>
												</div>
											</div>
										</div>
										<div className='col-sm-12 col-12 align-self-center'>
											<div className='offer_main_details pb-2 px-5 pt-5'>
												<div className='col-12'>
													<p className='text-black fw-bolder font-17 text-short mb-3' title={offerList?.data?.property_details?.propertyName}>
														{offerList?.data?.property_details?.propertyName}
													</p>
													<h1 className='text-black fw-bolder mb-3'>
														<small>{t("AED")}</small>{' '}
														<span className='fw-boldest'>
															{offerList?.data?.property_details?.price?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
														</span>
														<small> / {t("Year")} </small>
													</h1>
												</div>
												<div className='col-12 offer_main_details_div_3 mb-0'>
													<p className='mb-1'>
														<img src={Place} className='img-fluid me-2' />
														{/* {offerList?.data?.property_details?.location} */}
														{offerList?.data?.offer_details?.unitNo?.substring(offerList?.data?.offer_details?.unitNo?.lastIndexOf("-") + 1, offerList?.data?.offer_details?.unitNo?.length) + ' | '} {offerList?.data?.offer_details?.building && offerList?.data?.offer_details?.building + ' | '}  {offerList?.data?.property_details?.address && offerList?.data?.property_details?.address + ' | '}  {offerList?.data?.property_details?.location && offerList?.data?.property_details?.location}
													</p>
												</div>
												<div className='col-12'>
													<div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
														{parseInt(offerList?.data?.property_details?.propertySearchType) !== 2 &&
															<p className='py-2 pr-3 pl-0 me-2 my-0'>
																<img src={Bed} className='img-fluid me-3' alt='bed' />
																{offerList?.data?.property_details?.bedrooms ? offerList?.data?.property_details?.bedrooms : '-'} &nbsp; Bedrooms
															</p>
														}
														{parseInt(offerList?.data?.property_details?.propertySearchType) !== 2 &&
															<>
																{offerList?.data?.property_details?.bathRooms?.length > 0 && offerList?.data?.property_details?.bathRooms != 0 ? (
																	<p className='py-2 pr-3 pl-0 me-2 my-0'>
																		<img src={Bath} className='img-fluid me-3' alt='bath' />
																		{offerList?.data?.property_details?.bathRooms ? offerList?.data?.property_details?.bathRooms : '-'} &nbsp; Bathrooms
																	</p>
																) : (
																	<></>
																)
																}
															</>
														}
														{offerList?.data?.property_details?.area > 0 && offerList?.data?.property_details?.area != 0 ? (
															<p className='py-2 pr-3 pl-0 me-2 my-0 sqft-my-offer'>
																<img src={Sqft} className='img-fluid me-2' alt='sqft' />
																{offerList?.data?.property_details?.area ? offerList?.data?.property_details?.area : '-'} {t('Sqmt')}
															</p>
														) : (
															<></>
														)
														}
														<p className='py-2 pr-3 pl-0 me-2 my-0'>
															<img src={YesIcon} className='img-fluid me-2' alt='yes' />{' '}
															{offerList?.data?.property_details?.parking === 'Y' ? t('Yes') : t('No')}
														</p>
													</div>
												</div>
												{/* <div className='col-12'>
													<div className='mainCard_d_etails_div_2 d-flex  flex-wrap'>
														{offerList?.data?.property_details?.propertySearchType !== 2 &&
															<>
																{offerList?.data?.property_details?.bedrooms &&
																	<p className='p-3 me-2'>
																		<img src={Bed} className='img-fluid me-2' />{' '}
																		{offerList?.data?.property_details?.bedrooms}
																	</p>
																}
																{offerList?.data?.property_details?.bathRooms && <p className='p-3 me-2'>
																	<img src={Bath} className='img-fluid me-2' />{' '}
																	{offerList?.data?.property_details?.bathRooms}
																</p>}
															</>}
														<p className='p-3 me-2 sqft-my-offer'>
															<img src={Sqft} className='img-fluid me-2' />{' '}
															{offerList?.data?.property_details?.area} {t("Sqmt")}
														</p>
														<p className='p-3'>
															<img src={YesIcon} className='img-fluid' />{' '}
															{offerList?.data?.property_details?.parking === 'Y' ? t("Yes") : t("No")}
														</p>
													</div>
												</div> */}
											</div>
										</div>
										<div className='col-sm-12 col-12 align-self-center'>
											{userRole === 4 ? (
												<div className='pb-5 px-5 pt-0 d-flex align-items-center offer-price-sec'>
													<p className='text-black fw-bolder font-14 my-0 me-3'>{t("Offer Price")} -</p>
													<h1 className='d-flex align-items-center text-black fw-bolder m-0'>
														<small className='font-12 fw-normal my-0 me-3'>{t("AED")}</small>{' '}
														<span className='fw-boldest font-16'>
															{' '}
															{offerList?.data?.offer_details?.offerPrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
														</span>
														<small className='font-12 fw-normal my-0 me-3'>/Year</small>{' '}
													</h1>
													{offerList?.data?.offer_details?.offerPaymentStatus != 0 &&
														offerList?.data?.offer_details?.contractGenerated != 'Y' ? (
														<>
															{/* <p className='text-black fw-bolder mb-0'>
																<i className='bi bi-check-circle-fill text-success me-2 font-17'></i>{' '}
																Payment Successful
															</p>
															<p className='text-black fw-bolder mb-0'>
																<i className='bi bi-exclamation-circle-fill text-danger me-2 font-17'></i>{' '}
																Payment Failed
															</p> */}
														</>
													) : null}
												</div>
											) : (
												<div className='offer-price-detail text-center offer-price-sec d-flex align-items-center px-3 pb-3'>
													<p className='text-black fw-bolder font-14 mb-1 text-title me-2'>{t("Offer Price")}</p>
													<h1 className='text-black fw-bolder font-14 mb-1 text-sub-title ms-3'>
														<small>{t("AED")}</small>{' '}
														<span className='fw-boldest'>
															{' '}
															{offerList?.data?.offer_details?.offerPrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
														</span>
													</h1>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-5 col-12'>
							<div className='row form-add-design'>
								<div className='col-12'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Date")} : </label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>
												{moment(offerList?.data?.offer_details?.date).format('DD-MM-YYYY')}
											</h6>
										</div>
									</div>
								</div>
								<div className='col-12'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Tenant")} :</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>{offerList?.data?.offer_details?.tenant_name}</h6>
										</div>
									</div>
								</div>
								<div className='col-12'>
									<div className='input-field-new'>
										<label className='form-label fw-bolder'>{t("Validity")} : </label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>
												{moment(offerList?.data?.offer_details?.validity).format('DD-MM-YYYY')}
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
