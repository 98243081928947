import {useTranslation} from 'react-i18next'

export function InspectionDocument() {
  const {t} = useTranslation()

  return (
    <>
      <div className='com-card-section'>
        <div className='row form-add-design'>
          <div className='col-12'>
            <div className='card documents-card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-xl-6 col-md-6 col-12 mb-3'>
                    <div className='gray-card py-7'>
                      <div className='row'>
                        <div className='col-sm-8 col-12'>
                          <i className='text-theme-pdf bi bi-file-earmark-pdf font-17 pe-3'></i>
                          <h4 className='fw-bold font-16 text-black mb-0 d-inline-block'>
                            {t("Rules & Regulations")}
                          </h4>
                        </div>
                        <div className='col-sm-4 col-12 text-end'>
                          <div>
                            <a href='https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/pdf/RulesRegulation.pdf'  target='_blank'>
                              <i className='text-black bi bi-download font-17'></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-6 col-md-6 col-12 mb-3'>
                    <div className='gray-card py-7'>
                      <div className='row'>
                        <div className='col-sm-8 col-12'>
                          <i className='text-theme-pdf bi bi-file-earmark-pdf font-17 pe-3'></i>
                          <h4 className='fw-bold font-16 text-black mb-0 d-inline-block'>
                            {t("Inspection Report")}
                          </h4>
                        </div>
                        <div className='col-sm-4 col-12 text-end'>
                          <div>
                            <a href='https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/pdf/RealcubeMoveIN.pdf'  target='_blank'>
                              <i className='text-black bi bi-download font-17'></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-6 col-md-6 col-12 mb-3'>
                    <div className='gray-card py-7'>
                      <div className='row'>
                        <div className='col-sm-8 col-12'>
                          <i className='text-theme-pdf bi bi-file-earmark-pdf font-17 pe-3'></i>
                          <h4 className='fw-bold font-16 text-black mb-0 d-inline-block'>
                            {t("Marina Residences - Title Deed")}
                          </h4>
                        </div>
                        <div className='col-sm-4 col-12 text-end'>
                          <div>
                            <a href='https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/pdf/RealcubeMoveIN.pdf'  target='_blank'>
                              <i className='text-black bi bi-download font-17'></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-6 col-md-6 col-12 mb-3'>
                    <div className='gray-card py-7'>
                      <div className='row'>
                        <div className='col-sm-8 col-12'>
                          <i className='text-theme-pdf bi bi-file-earmark-pdf font-17 pe-3'></i>
                          <h4 className='fw-bold font-16 text-black mb-0 d-inline-block'>
                            {t("Al Ghozlan - Title Deed")}
                          </h4>
                        </div>
                        <div className='col-sm-4 col-12 text-end'>
                          <div>
                            <a href='https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/pdf/RealcubeMoveIN.pdf' target='_blank'>
                              <i className='text-black bi bi-download font-17'></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
