import React from 'react';
import CheckGreen from "../../../../assets/images/Check_green_md.svg";
import RedeemQR from "../../../../assets/images/Icon_metro-qrcode.png";

export function RedeemOfferSubmitPopup() {
    return (
        <div className="modal fade custom-modal redeem-QR" id="redeem-QR">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content rounded-10">
                    <div className="modal-header">
                        <h5 className="modal-title">Thank You for Submitting your Interest!</h5>
                        <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="row g-5 mb-3">
                            <div className="col-md-6">
                                <div className="text-center">
                                    <img src={RedeemQR} alt="redeem-qr" className="img-fluid qr-code-img"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-center">
                                    <div className="redeem-offer-QR">
                                        <img src={CheckGreen} alt="redeem-offer-img" className="img-fluid mb-4 redeem-offer-img" />
                                        <h1 className="text-primary font-14 fw-boldest mb-4"> Thank You </h1>
                                        <p className="mb-4 font-12 fw-normal">
                                            In order to Redeem the Voucher successfully, you should Download the Mobile application.
                                        </p>
                                        <button className="btn btn-primary btn-lg d-block py-2 px-lg-16 fw-normal mx-auto" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}