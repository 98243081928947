import { Link } from "react-router-dom"
import BannerImg from "../../../assets/images/banner-img.png"
import { ScheduleMeeting } from "../../my-services/add/ScheduleMeeting/ScheduleMeeting"
import { Announcements } from "../components/Announcements/Announcements"
import { MyActivity } from "./MyActivity"
import NoImage from "../../../assets/images/House.png";
const OwnerDashboard = () => {
    let userName = ''
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userName = JSON.parse(userDetail).first_name + ' ' + JSON.parse(userDetail).last_name
    }

    return (
        <>
            <div className="banner mb-7">
                <div className="banner-inner">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className="banner-title">
                                <h2>Hello, {userName}!</h2>
                                <p>Welcome to your Dashboard!</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-end">
                            <img src={BannerImg} alt="banner-image" className="banner-img" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-7">
                <div className='col-12 col-md-12 col-xl-6 mb-xl-0 mb-7'>
                    <div className="card h-100">
                        <div className="card-body p-7">
                            <h4 className="mb-5">Under Construction</h4>
                            <div className='row'>
                                <div className='col-lg-4 col-xl-4 col-md-6 col-12'>
                                    <div className='lease-banner text-center'>
                                        <img
                                            src={'https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/property/prop-4.jpg'}
                                            alt='Banner'
                                            className='img-fluid rounded'
                                        />
                                    </div>
                                </div>
                                <div className='col-xl-8 col-lg-8 col-md-6 col-12'>
                                    <div className='card lease-card h-100 justify-content-between'>
                                        <div className='row'>
                                            <div className='col-12 mb-4'>
                                                <h6 className='text-theme-gray mb-1 fw-bold fs-7'> Unit Code  </h6>
                                                <span className='text-black fw-bolder fs-7'> KHV-P011-VL00-GF-0035 </span>
                                            </div>
                                            <div className='col-12 mb-4'>
                                                <h6 className='text-theme-gray mb-1 fw-bold fs-7'> Address</h6>
                                                <span className='text-black fw-bolder fs-7'>
                                                        Marina Residences 1 | Al Falah Street | Abu Dhabi
                                                </span>
                                            </div>
                                            <div className='col-12 mb-0'>
                                                <h6 className='text-theme-gray mb-1 fw-bold fs-7'> Totle Amount</h6>
                                                <span className='text-black fw-bolder fs-7'>
                                                 AED 9,750,000
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-12 col-xl-6'>
                    <div className="card h-100">
                        <div className="card-body p-7">
                            <div className="row mb-3">
                                <div className="col-12 col-md-8">
                                    <h4>Upcoming Payments</h4>
                                </div>
                                <div className="col-12 col-md-4 text-end">
                                    <Link to={'/unit-portfolio/details'} className="text-decoration-underline view-all text-black font-12 ">View All</Link>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-12">
                                    <table className="table dash-table">
                                        <tr>
                                            <th>Completion (%)</th>
                                            <th>Due Date</th>
                                            <th>Amount (AED)</th>
                                        </tr>
                                        <tr>
                                            <td>25</td>
                                            <td>05-08-2023</td>
                                            <td>2,473,500</td>
                                        </tr>
                                        <tr>
                                            <td>50</td>
                                            <td>20-04-2024</td>
                                            <td>3,947,000</td>
                                        </tr>
                                        <tr>
                                            <td>75</td>
                                            <td>15-07-2024</td>
                                            <td>3,329,500</td>
                                        </tr>
                                        <tr>
                                            <td>100</td>
                                            <td>25-10-2024</td>
                                            <td>9,750,000</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <MyActivity /> */}

        </>
    );
}
export default OwnerDashboard;