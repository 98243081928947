import { useTranslation } from 'react-i18next';

export function CardPayment() {
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-5 px-md-15 py-12'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row justify-content-center h-100'>
                    <div className='col-lg-11 col-xl-8 col-12 mb-4'>
                      <div className='card-pay-bg h-100 rounded-10 p-5 p-md-14'>
                        <div className='row'>
                          <div className='col-12'>
                            <h5 className='text-black font-18 fw-bolder mb-8'>{t("Summary")}</h5>
                          </div>
                          <div className='col-12'>
                            <div className='row'>
                              <div className='col-md-8 col-8'>
                                <span className='text-black font-14 d-block mb-4 fw-bold'>
                                  {t("Amount")} 
                                </span>
                              </div>
                              <div className='col-md-4 col-4'>
                                <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                  {t("AED")} 3,947,000.00
                                </span>
                              </div>
                              <div className='col-md-8 col-8'>
                                <span className='text-black font-14 d-block mb-4 fw-bold'>
                                  {t("VAT")} (5%)
                                </span>
                              </div>
                              <div className='col-md-4 col-4'>
                                <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                  {t("AED")} 243,750
                                </span>
                              </div>
                            </div>
                            <hr className='my-1' />
                          </div>
                          <div className='col-12 my-4'>
                            <div className='row'>
                              <div className='col-md-8 col-8'>
                                <span className='text-black font-14 d-block fw-bold'>{t("Total")}</span>
                                <span className='d-block font-10 mb-2 fw-bold'>{t("(Inc. Taxes)")}</span>
                              </div>
                              <div className='col-md-4 my-auto col-4'>
                                <span className='text-black font-14 d-block mb-2 fw-boldest'>
                                  {t("AED")} 4,190,750
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='col-12'>
                            <button
                              data-bs-target='#pay-suc-popup'
                              data-bs-toggle='modal'
                              type='button'
                              className='btn btn-primary w-100 fw-bold px-10 py-3'
                            >
                              {t("Confirm Payment")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
