import { useState } from "react";

export function NocTitleDeed() {

    const [documentsCount, setDocumentsCount] = useState<any>(0);
    return (
        <>
            <div className="row noc">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-7">NOC for Title Deed</h4>
                            <form className="" method="post">
                                <div className="row form-add-design">
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Buyer First Name </label>
                                            <input type="text" className="form-control" placeholder="Buyer First Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Buyer Last Name </label>
                                            <input type="text" className="form-control" placeholder="Buyer Last Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Passport Number </label>
                                            <input type="text" className="form-control" placeholder="Passport Number" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Passport Expiry</label>
                                            <input type="date" className="form-control" placeholder="Passport Expiry" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Project Name </label>
                                            <input type="text" className="form-control" placeholder="Project Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Property Name </label>
                                            <input type="text" className="form-control" placeholder="Property Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Unit Name</label>
                                            <input type="text" className="form-control" placeholder="Unit Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Total Price of Unit </label>
                                            <input type="text" className="form-control" placeholder="Total Price of Unit" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Amount Paid </label>
                                            <input type="text" className="form-control" placeholder="Amount Paid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="card-title mb-7">Joint Owner Details</h4>
                                    <button type="button" className="btn btn-primary" onClick={() => { setDocumentsCount((oState: any) => oState + 1) }}>Add</button>
                                </div>
                                <div className="row form-add-design">
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> First Name </label>
                                            <input type="text" className="form-control" placeholder="First Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Last Name </label>
                                            <input type="text" className="form-control" placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Share % </label>
                                            <input type="text" className="form-control" placeholder="Share %" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Emirates ID Number </label>
                                            <input type="text" className="form-control" placeholder="Emirates ID Number" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Emirates ID Expiry </label>
                                            <input type="text" className="form-control" placeholder="Emirates ID Expiry" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Passport Number </label>
                                            <input type="text" className="form-control" placeholder="Passport Number" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label"> Passport Expiry </label>
                                            <input type="text" className="form-control" placeholder="Passport Expiry" />
                                        </div>
                                    </div>
                                </div>

                                {Array(documentsCount).fill(1).map((data, index) => {
                                    return (
                                        <>
                                        <div className="dev-border">
                                            <button className="btn btn-danger" onClick={() => { setDocumentsCount((oState: any) => oState - 1) }}>X</button>
                                        </div>
                                        <div className="row form-add-design">
                                            <div className="col-md-6 col-lg-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label"> First Name </label>
                                                    <input type="text" className="form-control" placeholder="First Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label"> Last Name </label>
                                                    <input type="text" className="form-control" placeholder="Last Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label"> Share % </label>
                                                    <input type="text" className="form-control" placeholder="Share %" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label"> Emirates ID Number </label>
                                                    <input type="text" className="form-control" placeholder="Emirates ID Number" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label"> Emirates ID Expiry </label>
                                                    <input type="text" className="form-control" placeholder="Emirates ID Expiry" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label"> Passport Number </label>
                                                    <input type="text" className="form-control" placeholder="Passport Number" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label"> Passport Expiry </label>
                                                    <input type="text" className="form-control" placeholder="Passport Expiry" />
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    )
                                })}
                                <div className="row">
                                    <div className="col-12">
                                        <h3>Terms and Conditions</h3>
                                    </div>
                                    <div className="col-12">
                                        <div className="bg-light rounded my-3">
                                            <ol type="a" className="py-3 m-0">
                                                <li> Pay 210.00 AED as NOC for Title Deed fees (Inclusive VAT) </li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-check">
                                            <input type="checkbox" name="type" value="1" id="tnc" className="form-check-input" />
                                            <label htmlFor="tnc" className="form-check-label">I hereby confirm that I have understood the terms and conditions mentioned herein and I agree to abide by the Community Rules.</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12 text-end">
                                        <button type="submit" value="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}