import React, {FC} from 'react'
import ChatIoc from "../../../../assets/images/Chat.png";
import {Link} from 'react-router-dom'
import {ChatBot} from '../ChatBot/ChatBot'

const Toggle: FC = () => (
  // <button
  //   className='btn btn-sm btn-white btn-active-primary shadow-sm'
  // >
  //   <span id='kt_explore_toggle_label'>Explores</span>
  // </button>
    <div>
        {/*<Link to={'/chat/all'} className=''>*/}
            {/*<img*/}
                {/*title='Chat'*/}
            {/*data-bs-target="#feedback-popup" data-bs-toggle="modal" data-bs-dismiss="modal"*/}
              {/*src={ChatIoc} className="img-fluid position-fixed zindex-2 c-pointer top-80 mt-10 end-0"*/}
            {/*/>*/}
        {/*</Link>*/}
        <ChatBot />
    </div>
)

export {Toggle}
