import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { FeedbackDrawer } from '../../../../components/partials'
import { formatDateTimeMeridiem } from '../../../../constants/generics/dateTimeFormater'

export function ResolvedIssues({ issues }: any) {
  const [issueData, setIssueData] = useState<any>([])
  const [mr, setMr] = useState<any>()
  const { t } = useTranslation();

  const columns = [
    // {
    //   field: 'id',
    //   headerName: 'No',
    //   sortable: false,
    //   disableColumnMenu: true,
    //   width: 100,
    // },
    {
      field: 'ticket',
      headerName: t("Ticket No"),
      width: 200,
    },
    {
      field: 'categoryType',
      headerName: t("Issue"),
      width: 300,
    },
    {
      field: 'description',
      headerName: t("Description"),
      width: 200,
      renderCell: (params: any) => {
        return params.row?.description?.description &&
          params.row?.description?.description.length > 25
          ? params.row?.description?.description.slice(0, 25).split(' ').slice(0, -1).join(' ') +
          '...'
          : params.row?.description?.description
      },
    },
    {
      field: 'time',
      headerName: t("Date & Time"),
      width: 180,
      renderCell: (params: any) => {
        return formatDateTimeMeridiem(params.row.time)
        // return moment(params.row.created).format('DD-MM-YYYY hh:mm')
      },
    },
    {
      field: 'status',
      headerName: t("Status"),
      className: 'text-danger',
      disableColumnMenu: true,
      width: 150,
      //   renderCell: () => {
      //     return <span className='new-theme'>New</span>
      //   },
    },
    // {
    //   field: 'replies',
    //   headerName: 'Replies',
    //   sortable: false,
    //   disableColumnMenu: true,
    //   width: 100,
    // },
    {
      field: 'action',
      headerName: t("Action"),
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (params: any) => {
        return (
          <button
            data-bs-target={params.row.is_feedback == 'Y' ? '#feedback-data' : '#feedback-popup'}
            data-bs-toggle='modal'
            data-bs-dismiss='modal'
            className='btn border-0 text-primary'
            onClick={() => setMr(params.row)}
          >
            <span className='view-option text-theme fw-bolder'>{params.row.is_feedback == 'Y' ? 'Feedback' : t("Rate Us")}</span>
          </button>
        )
      },
    },
  ]

  useEffect(() => {
    if (issues) {
      setIssueData(
        issues?.map((issue: any) => {
          return { ...issue, status: t("Resolved"), time: issue.appointmentDate + ' ' + issue.time }
        })
      )
    }
  }, [issues])

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <div className='data_table'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='table-responsive'>
                          <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                              // rows={TableData}
                              rows={issueData}
                              columns={columns}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeedbackDrawer mrId={mr?.id} />

      <div className="modal fade feed-design" id="feedback-data">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-10">
            <div className="modal-header border-0">
              <h5 className="modal-title fw-bold">{t("Feedback")}</h5>
              <button type="button" className="btn-close text-black" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body px-10 pb-0">
              <div className="row">
                <div className="col-12">
                  <div className="satis-wdw">
                    <label className="fw-normal text-black font-12 mb-1"> Workmanship </label>
                    <div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
                      <input type="radio" className="btn-check" checked={mr?.workmanship === 1} disabled={true} />
                      <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis">
                        <span className="very-satis-ioc comm-icon me-2"></span>
                        {t("Very Satisfied")}
                      </label>

                      <input type="radio" className="btn-check" checked={mr?.workmanship === 2} disabled={true} />
                      <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="satis">
                        <span className="satis-ioc comm-icon me-2"></span>
                        {t("Satisfied")}
                      </label>

                      <input type="radio" className="btn-check" checked={mr?.workmanship === 3} disabled={true} />
                      <label className="btn btn-outline-primary border-0 p-0 font-10 fw-bold text-black" htmlFor="notsatis">
                        <span className="not-satis-ioc comm-icon me-2"></span>
                        {t("Not Satisfied")}
                      </label>
                    </div>
                    <label className="fw-normal text-black font-12 mb-1"> Punctuality </label>
                    <div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
                      <input type="radio" className="btn-check" checked={mr?.punctuality === 1} disabled={true} />
                      <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis-one">
                        <span className="very-satis-ioc comm-icon me-2"></span>
                        {t("Very Satisfied")}
                      </label>

                      <input type="radio" className="btn-check" checked={mr?.punctuality === 2} disabled={true} />
                      <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="satis-one">
                        <span className="satis-ioc comm-icon me-2"></span>
                        {t("Satisfied")}
                      </label>

                      <input type="radio" className="btn-check" checked={mr?.punctuality === 3} disabled={true} />
                      <label className="btn btn-outline-primary border-0 p-0 font-10 fw-bold text-black" htmlFor="notsatis-one">
                        <span className="not-satis-ioc comm-icon me-2"></span>
                        {t("Not Satisfied")}
                      </label>
                    </div>
                    <label className="fw-normal text-black font-12 mb-1"> Quality of work </label>
                    <div className="btn-group w-100 rounded-10 mb-6 px-10 py-5">
                      <input type="radio" className="btn-check" checked={mr?.qualityOfWork === 1} disabled={true} />
                      <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="verysatis-two">
                        <span className="very-satis-ioc comm-icon me-2"></span>
                        {t("Very Satisfied")}
                      </label>

                      <input type="radio" className="btn-check" checked={mr?.qualityOfWork === 2} disabled={true} />
                      <label className="btn btn-outline-primary border-0 me-8 p-0 font-10 fw-bold text-black" htmlFor="satis-two">
                        <span className="satis-ioc comm-icon me-2"></span>
                        {t("Satisfied")}
                      </label>

                      <input type="radio" className="btn-check" checked={mr?.qualityOfWork === 3} disabled={true} />
                      <label className="btn btn-outline-primary border-0 p-0 font-10 fw-bold text-black" htmlFor="notsatis-three">
                        <span className="not-satis-ioc comm-icon me-2"></span>
                        {t("Not Satisfied")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-6">
                    <textarea
                      className='form-control fw-bold font-12 form-control-solid'
                      data-kt-autosize='true'
                      rows={1}
                      value={mr?.additionalFeedback}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
