import React, { useState, useEffect, useRef } from 'react';
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import Call from "../../../../assets/images/emer-call.png";
import {Link} from 'react-router-dom'

export function AddVoucher() {

    const [file, setFile] = useState([] as any);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    function uploadSingleFile(e:any) {
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
        console.log("file", file);
    }

    function upload(e:any) {
        e.preventDefault();
        console.log(file);
    }

    function deleteFile(e:any) {
        const s = file.filter((item:any, index:any) => index !== e);
        setFile(s);
        console.log(s);
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <Link to={'/market-place/all'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            <span className="text-black">
                                Add New
                            </span>
                        </Link>
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-8">
                            <div className="row form-add-design">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Select Type </label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option> All </option>
                                            <option> Active </option>
                                            <option> Inactive </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Voucher Name </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Category </label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option> All </option>
                                            <option> Active </option>
                                            <option> Inactive </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Location </label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option> Dubai </option>
                                            <option> Abhu Dhabi </option>
                                            <option> Sharjah </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Contact </label>
                                        <select className="form-control form-select fw-bolder form-control-solid" data-control="select2" data-allow-clear="true">
                                            <option selected disabled>Please Select</option>
                                            <option> Person1 </option>
                                            <option> Person2 </option>
                                            <option> Person3 </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="mb-5 mb-md-10">
                                        <label className="form-label fw-bolder">Offer Period</label>
                                        <input type="date" className="form-control fw-bolder form-control-solid" placeholder="From" />
                                        {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                    </div>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="mb-5 mb-md-10">
                                        <label className="form-label fw-bolder d-none d-md-block">&nbsp;</label>
                                        <input type="date" className="form-control fw-bolder form-control-solid" placeholder="To" />
                                        {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="">
                                        <label className="form-label fw-bolder d-block"> Discount </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <div className="input-group mb-7">
                                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="To" />
                                                <span className="input-group-text bg-transparent font-14 fw-normal" id="basic-addon2">%</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="input-group mb-7">
                                                <input type="text" className="form-control fw-bolder form-control-solid" placeholder="To" />
                                                <span className="input-group-text bg-transparent text-uppercase font-14 fw-normal" id="basic-addon2">AED</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label fw-bolder"> Web </label>
                                        <input type="text" className="form-control fw-bolder form-control-solid" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="upload-input">
                                        <label className="form-label fw-bolder d-block"> Upload Image </label>
                                        <input ref={inputRef}  onChange={uploadSingleFile} type="file" className="d-none" multiple/>
                                        <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                            <span> Upload Image  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    {file.length > 0 &&
                                    <div className="mb-7 position-relative">
                                        <label className="form-label fw-bolder d-block">Attach Images/Video</label>
                                        {file.map((item:any, index:any) => {
                                            return (
                                                <div className="upload-image" key={item}>
                                                    <img src={item} alt="" />
                                                    <div className="close-icon" onClick={() => deleteFile(index)}>
                                                        &times;
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    }
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="mb-0">
                                        <label className="form-label fw-bolder">Description</label>
                                        <textarea
                                            className='form-control fw-bolder form-control-solid'
                                            data-kt-autosize='true'
                                            rows={3}
                                            placeholder='Type here...'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-end mt-5">
                    <Link to={'/market-place/all'} className='btn btn-outline-primary bg-white fw-bold px-10 py-3 me-5'>
                        Cancel
                    </Link>
                    <button data-bs-target="#added-success-modal" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                        Create Voucher
                    </button>
                </div>
            </div>
        </div>
    );
}