import React, { useState, FC, useRef } from 'react';
import PlusIcon from "../../../../../assets/images/plus-circle.svg";
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../../constants/generics/fileUploadHandler';
import { Alert } from 'react-bootstrap-v5';

type Props = {
    setDocuments?: any
    ref2?: any
}

const RequiredDocuments: FC<Props> = ({ setDocuments, ref2 }) => {
    const { t } = useTranslation();
    const [uploading, setUploading] = useState<boolean>(false)
    const [inputBeingUploaded, setInputBeingUploaded] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [uploadedFileName1, setUploadedFileName1] = useState<string | null>(null);
    const [uploadedFileName2, setUploadedFileName2] = useState<string | null>(null);
    const [uploadedFileName3, setUploadedFileName3] = useState<string | null>(null);
    const [uploadedFileName4, setUploadedFileName4] = useState<string | null>(null);
    const [uploadedFileName5, setUploadedFileName5] = useState<string | null>(null);
    const [uploadedFileName6, setUploadedFileName6] = useState<string | null>(null);
    const [uploadedFileName7, setUploadedFileName7] = useState<string | null>(null);
    const [uploadedFileName8, setUploadedFileName8] = useState<string | null>(null);

    const inputRef1 = useRef<HTMLInputElement>(null)
    const inputRef2 = useRef<HTMLInputElement>(null)
    const inputRef3 = useRef<HTMLInputElement>(null)
    const inputRef4 = useRef<HTMLInputElement>(null)
    const inputRef5 = useRef<HTMLInputElement>(null)
    const inputRef6 = useRef<HTMLInputElement>(null)
    const inputRef7 = useRef<HTMLInputElement>(null)
    const inputRef8 = useRef<HTMLInputElement>(null)

    const handleDisplayFileDetails = (reference: any, setUploadedFileName: any, fieldName: string) => {
        const inputFile = reference.current?.files?.[0]
        let isAllowedType: any = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(inputFile?.type)

        if (inputFile && isAllowedType) {
            if (inputFile.type === 'application/pdf') {
                if (inputFile.size > 5242880) {
                    setError(true)
                    setErrorMessage(t('The file is too large! Max allowed size is 5MB.'))
                    setTimeout(() => { setError(false) }, 3000)
                } else {
                    setUploadedFileName(inputFile.name)
                    // formik.setFieldTouched(fieldName)
                    // formik.setFieldValue(fieldName, inputFile)
                }
            } else {
                setUploading(true)
                setInputBeingUploaded(fieldName)
                setUploadedFileName()
                try {
                    validateAndCompressImage(inputFile).then((compressResponse) => {
                        if (compressResponse?.success) {
                            setUploadedFileName(inputFile.name)
                            // formik.setFieldTouched(fieldName)
                            // formik.setFieldValue(fieldName, inputFile)
                        } else {
                            setError(true)
                            setErrorMessage(t(compressResponse?.message || 'File compression error!'))
                            setTimeout(() => { setError(false) }, 3000)
                        }
                        setUploading(false)
                    })
                } catch (error) {
                    alert(t('File upload error!'))
                    setError(true)
                    setErrorMessage(t('File upload error!'))
                    setTimeout(() => { setError(false) }, 3000)
                    setUploading(false)
                }
            }
        } else {
            setError(true)
            setErrorMessage(t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'))
            setTimeout(() => {
                setError(false)
            }, 3000)
        }
    }

    const sigCanvas = useRef<any>();

    const handleSignature = () => {
        const canvas = sigCanvas.current.getCanvas()
        canvas.toBlob((blob: string) => {
        }, 'image/png')
    }

    function clearSignature() {
        sigCanvas.current.clear()
    }

    return (
        <div className="row">
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
            >
                <div className="col-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-permit-heading">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#required-doc">
                                {t("Required Documents")}
                            </button>
                        </h2>

                        {error ? (
                            <Alert className='font-14 fw-bold mb-8' variant='danger'>
                                {errorMessage}!
                            </Alert>
                        ) : null}

                        <div id="required-doc" className="accordion-collapse collapse" data-bs-parent="#accordion-rooftop-access">
                            <div className="accordion-body pb-5 pt-0">
                                <div className="row form-add-design">
                                    <div className="col-md-4 col-12 documents-card">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder"> {t("Undertaking Letter")} </label>
                                            <input
                                                name="undertaking_letter"
                                                className="d-none"
                                                type="file"
                                                ref={inputRef1}
                                                onChange={() => handleDisplayFileDetails(inputRef1, setUploadedFileName1, 'undertaking_letter')}
                                                accept='.jpeg,.jpg,application/pdf'
                                            />
                                            <button type='button'
                                                onClick={() => inputRef1.current?.click()}
                                                className={
                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName1 ? "primary active" : "primary"}`
                                                }
                                            >
                                                <span className="pt-5">
                                                    {(uploading && inputBeingUploaded === 'undertaking_letter') ?
                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                        : uploadedFileName1 || uploadedFileName1 ? (
                                                            uploadedFileName1 || ''
                                                        ) : (
                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                        )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 documents-card">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder"> {t("Risk Assessment")} </label>
                                            <input
                                                name="risk_assessment"
                                                className="d-none"
                                                type="file"
                                                ref={inputRef2}
                                                onChange={() => handleDisplayFileDetails(inputRef2, setUploadedFileName2, 'risk_assessment')}
                                                accept='.jpeg,.jpg,application/pdf'
                                            />
                                            <button type='button'
                                                onClick={() => inputRef2.current?.click()}
                                                className={
                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName2 ? "primary active" : "primary"}`
                                                }
                                            >
                                                <span className="pt-5">
                                                    {(uploading && inputBeingUploaded === 'risk_assessment') ?
                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                        : uploadedFileName2 || uploadedFileName2 ? (
                                                            uploadedFileName2 || ''
                                                        ) : (
                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                        )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 documents-card">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder"> {t("Passport")} </label>
                                            <input
                                                name="passport"
                                                className="d-none"
                                                type="file"
                                                ref={inputRef3}
                                                onChange={() => handleDisplayFileDetails(inputRef3, setUploadedFileName3, 'passport')}
                                                accept='.jpeg,.jpg,application/pdf'
                                            />
                                            <button type='button'
                                                onClick={() => inputRef3.current?.click()}
                                                className={
                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName3 ? "primary active" : "primary"}`
                                                }
                                            >
                                                <span className="pt-5">
                                                    {(uploading && inputBeingUploaded === 'passport') ?
                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                        : uploadedFileName3 || uploadedFileName3 ? (
                                                            uploadedFileName3 || ''
                                                        ) : (
                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                        )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 documents-card">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder"> {t("Visa")} </label>
                                            <input
                                                name="visa"
                                                className="d-none"
                                                type="file"
                                                ref={inputRef4}
                                                onChange={() => handleDisplayFileDetails(inputRef4, setUploadedFileName4, 'visa')}
                                                accept='.jpeg,.jpg,application/pdf'
                                            />
                                            <button type='button'
                                                onClick={() => inputRef4.current?.click()}
                                                className={
                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName4 ? "primary active" : "primary"}`
                                                }
                                            >
                                                <span className="pt-5">
                                                    {(uploading && inputBeingUploaded === 'visa') ?
                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                        : uploadedFileName4 || uploadedFileName4 ? (
                                                            uploadedFileName4 || ''
                                                        ) : (
                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                        )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 documents-card">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder"> {t("Trade License")} </label>
                                            <input
                                                name="trade_license"
                                                className="d-none"
                                                type="file"
                                                ref={inputRef5}
                                                onChange={() => handleDisplayFileDetails(inputRef5, setUploadedFileName5, 'trade_license')}
                                                accept='.jpeg,.jpg,application/pdf'
                                            />
                                            <button type='button'
                                                onClick={() => inputRef5.current?.click()}
                                                className={
                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName5 ? "primary active" : "primary"}`
                                                }
                                            >
                                                <span className="pt-5">
                                                    {(uploading && inputBeingUploaded === 'trade_license') ?
                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                        : uploadedFileName5 || uploadedFileName5 ? (
                                                            uploadedFileName5 || ''
                                                        ) : (
                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                        )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 documents-card">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder"> {t("Method of Statement")} </label>
                                            <input
                                                name="method_of_statement"
                                                className="d-none"
                                                type="file"
                                                ref={inputRef6}
                                                onChange={() => handleDisplayFileDetails(inputRef6, setUploadedFileName6, 'method_of_statement')}
                                                accept='.jpeg,.jpg,application/pdf'
                                            />
                                            <button type='button'
                                                onClick={() => inputRef6.current?.click()}
                                                className={
                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName6 ? "primary active" : "primary"}`
                                                }
                                            >
                                                <span className="pt-5">
                                                    {(uploading && inputBeingUploaded === 'method_of_statement') ?
                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                        : uploadedFileName6 || uploadedFileName6 ? (
                                                            uploadedFileName6 || ''
                                                        ) : (
                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                        )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 documents-card">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder"> {t("Workers/Crew name List")} </label>
                                            <input
                                                name="crew_list"
                                                className="d-none"
                                                type="file"
                                                ref={inputRef7}
                                                onChange={() => handleDisplayFileDetails(inputRef7, setUploadedFileName7, 'crew_list')}
                                                accept='.jpeg,.jpg,application/pdf'
                                            />
                                            <button type='button'
                                                onClick={() => inputRef7.current?.click()}
                                                className={
                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName7 ? "primary active" : "primary"}`
                                                }
                                            >
                                                <span className="pt-5">
                                                    {(uploading && inputBeingUploaded === 'crew_list') ?
                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                        : uploadedFileName7 || uploadedFileName7 ? (
                                                            uploadedFileName7 || ''
                                                        ) : (
                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                        )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 documents-card">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder"> {t("Others (if any)")} </label>
                                            <input
                                                name="others"
                                                className="d-none"
                                                type="file"
                                                ref={inputRef8}
                                                onChange={() => handleDisplayFileDetails(inputRef8, setUploadedFileName8, 'others')}
                                                accept='.jpeg,.jpg,application/pdf'
                                            />
                                            <button type='button'
                                                onClick={() => inputRef8.current?.click()}
                                                className={
                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName8 ? "primary active" : "primary"}`
                                                }
                                            >
                                                <span className="pt-5">
                                                    {(uploading && inputBeingUploaded === 'others') ?
                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                        : uploadedFileName8 || uploadedFileName8 ? (
                                                            uploadedFileName8 || ''
                                                        ) : (
                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                        )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder">{t("Comments (if any)")}</label>
                                            <textarea
                                                rows={2}
                                                className='form-control bg-white fw-bolder form-control-solid'
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12 text-right">
                                        <div className="row justify-content-end mb-10">
                                            <div className="col-12">
                                                <button type='button' data-bs-toggle="modal" data-bs-target="#signature-modal" className='btn btn-primary fw-bold px-10 py-3'>
                                                    {t("Applicant's Signature")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade redeem-offer signature-modal" id="signature-modal">
                                        <div className="modal-dialog modal-xs modal-dialog-centered">
                                            <div className="modal-content rounded-10">
                                                <div className="modal-header rounded-10 border-0">
                                                    <h5 className="modal-title font-18 fw-bold">{t("Add your Signature")}</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body p-8">
                                                    <SignatureCanvas
                                                        ref={sigCanvas}
                                                        penColor='black'
                                                        canvasProps={{ height: 180, width: 480, className: 'sigCanvas' }}
                                                    />
                                                </div>
                                                <div className="modal-footer p-0 m-0">
                                                    <div className="row w-100 g-0 m-0">
                                                        <div className="col-12">
                                                            <button type="button" className="btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0" onClick={clearSignature}>
                                                                {t("Reset")}
                                                            </button>
                                                        </div>
                                                        <div className="col-12">
                                                            <button onClick={handleSignature} type="button" className="btn btn-primary fw-bold px-10 py-5 w-100 d-block" data-bs-toggle='modal'
                                                                data-bs-target='#ParkingSuccessModal' data-bs-dismiss="modal">{t("Submit")}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-right">
                                        <div className="row justify-content-end">
                                            <div className="col-12">
                                                <div className="d-flex justify-content-end">
                                                    <button type='button' disabled={uploading} className='btn btn-primary fw-bold px-10 py-3'>
                                                        {t("Save and Submit")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export { RequiredDocuments }
