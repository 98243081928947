/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { WelcomeBanner } from './components/WelcomeBanner/WelcomeBanner'
import { MarketPlace } from './components/MarketPlace/MarketPlace'
import { Announcements } from './components/Announcements/Announcements'
import { useSelector } from 'react-redux'
import { RootState } from '../../constants'
import { ScheduleMeeting } from '../my-services/add/ScheduleMeeting/ScheduleMeeting'
import { MyServicesList } from '../my-services/components/MyServicesList/MyServicesList'
import { getServiceRequestsCategories } from '../../constants/axios/apis'
import { NoDataFoundWithMsg } from '../../constants/generics/customActios'
import { useTranslation } from 'react-i18next'
import { WelcomeBanner as WelcomeBannerCT } from '../ct-dashboard/components/WelcomeBanner/WelcomeBanner'
import OwnerDashboard from './ownerDashboard/ownerDashboard'

interface Props {
  contractData: any
  services?: any
}
const DashboardPage: FC<Props> = ({ contractData, services }) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
      { contractData?.is_sale === 1 ? 
        <div className='col-xxl-12 mt-0'>
          <OwnerDashboard/>
          {(
            
          (services?.length > 0)) &&
            <MyServicesList services={services} contractData={contractData} />
          }
          <Announcements />
        </div>
      :
      <div className='col-xxl-12 mt-0'>
        {contractData?.property_search_type == 2 ?
          <WelcomeBannerCT contract={contractData} />
          :
          <WelcomeBanner contract={contractData} />
        }
          {(
          // contractData?.contractStatus != 'Terminated' && 
         (services?.length > 0)) &&
          <MyServicesList services={services} contractData={contractData} />
        }
        <ScheduleMeeting contract={contractData} />
        <Announcements />
        {/* <MarketPlace /> */}
      </div>
      }
    </div>
  </>
)

const DashboardError = () =>{
  const { t } = useTranslation();
  return <>
    <div className="overflow-hidden rounded-3">
      <NoDataFoundWithMsg msg={t("No contract data found")} />
    </div>
  </>
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const [services, setServices] = useState<any>([])

  let userDetail = localStorage.getItem('userDetail')
  useEffect(() => {
    getServiceRequestsCategories(currentContract?.property_search_type, currentContract, userDetail && JSON.parse(userDetail)).then(response => {
      // console.log("🚀 ~ file: DashboardWrapper.tsx:43 ~ getServiceRequestsCategories ~ response", response)
      let categories =  response?.data && Array.isArray(response?.data) ? response?.data?.filter((category: any) => category?.is_visible === 1 || category?.categoryCode === 'GS') :[]
      setServices(categories)
    })
  }, [currentContract])  

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      {currentContract ?
        <DashboardPage contractData={currentContract} services={services} />
        :
        <DashboardError />
      }
    </>
  )
}

export { DashboardWrapper }
