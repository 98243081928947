import React, { Suspense } from "react"
import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import * as _redux from './constants'
import store, { persistor } from './constants/redux/Store'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { App } from './App'
import { ICTi18nProvider } from './setup/i18n/ICTi18n'
import './styles/sass/style.scss'
import './styles/sass/style.react.scss'
import { LayoutSplashScreen } from "./components/layout/core"

const { PUBLIC_URL } = process.env

_redux.setupAxios(axios, store)

Chart.register(...registerables)

ReactDOM.render(
	<ICTi18nProvider>
		<Provider store={store}>
			{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
			<PersistGate persistor={persistor}>
				<Suspense fallback={<LayoutSplashScreen />}>
					<App basename={PUBLIC_URL} />
				</Suspense>
			</PersistGate>
		</Provider>
	</ICTi18nProvider>,
	document.getElementById('root')
)
