import React, { useState, useEffect, useRef } from 'react'
import Propertie from '../../../../assets/images/latest-one.png'

export function ViewPopup1(props: any) {
  const [previewDoc, setPreviewDoc] = useState<any>('')
  useEffect(() => {
    setPreviewDoc('')
    setTimeout(() => {
      setPreviewDoc(props?.viewImage?.filename)
    }, (1000))
  }, [props])
  return (
    <div className='modal fade custom-modal' aria-hidden='true' id='view-popUp1'>
      <div className='modal-dialog modal-lg modal-dialog-centered'>
        <div className='modal-content rounded-10'>
          <div className='modal-header p-0 border-0'>
            <button
              type='button'
              className='btn-close shadow'
              data-bs-dismiss='modal'
              aria-label='Close'
            >&times;</button>
          </div>
          <div className='modal-body p-8'>
            <div className='row'>
              <div className="col-12 text-center">
                {previewDoc?.includes('pdf') ? <iframe src={previewDoc} style={{ width: '600px', height: '500px' }} ></iframe> :
                  <img src={previewDoc} className='img-fluid' />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
