import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Spinner } from 'react-bootstrap-v5'
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { useTranslation } from 'react-i18next'

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export function PayByCheque() {
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation();
  const [value, onChange] = useState<Value>(new Date());

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
  }

  return (
    <><div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-md-8 px-lg-15 px-5 py-15'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              >
                <div className='row'>
                  <div className='col-md-6 col-12 mb-md-0 mb-4'>
                    <div className='row form-add-design'>
                      <div className='col-md-12 col-12'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='mb-6 upload-input'>
                              <label className='form-label fw-bolder d-block mb-3'>{t("Cheque Image")}</label>
                              <input
                                multiple
                                ref={inputRef}
                                onChange={(e) => handleDisplayFileDetails(e)}
                                className='d-none'
                                type='file'
                                accept=".png,.jpg,.jpeg"
                                />
                              <button
                                type='button'
                                onClick={handleUpload}
                                disabled={uploading}
                                className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                                 }`}
                            >
                                {/* <span>
                                  {uploadedFileName ? (
                                    uploadedFileName
                                  ) : (
                                    <span>
                                      {t("Upload")}{' '}
                                      <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                    </span>
                                  )}{' '}
                                </span> */}
                                <span>
                                  {uploading ?
                                    <span className='indicator-progress d-block'>
                                      {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                    : uploadedFileName ? (
                                      uploadedFileName
                                    ) : (
                                      <span>
                                        {t('Upload')}{' '}
                                        <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                      </span>
                                    )}{' '}
                                </span>
                              </button>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-6'>
                              <label className='form-label fw-bolder'>{t("Cheque Number")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                              />
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-6 date-picker'>
                              <label className='form-label fw-bolder'>{t("Cheque Date")}</label>
                              <DatePicker
                                onChange={onChange} 
                                value={value}
                                minDate={new Date(new Date().toLocaleDateString())}
                                maxDate={new Date('12/31/2999')}
                                className='form-control fw-bolder form-control-solid'
                                format='dd-MM-y'
                                clearIcon={null}
                              />
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-6'>
                              <label className='form-label fw-bolder'>{t("Account Number")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                              />
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-6'>
                              <label className='form-label fw-bolder'>{t("Account Holder Name")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                              />
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-0'>
                              <label className='form-label fw-bolder'>{t("Bank Name")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-12'>
                    <div className='row justify-content-center h-100'>
                      <div className='col-lg-12 col-12'>
                        <div className='card-pay-bg h-100 rounded-10 p-5 p-md-15'>
                          <div className='row'>
                            <div className='col-12'>
                              <h5 className='text-black font-18 fw-bolder mb-8'>{t("Summary")}</h5>
                            </div>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-md-8 col-8'>
                                  <span className='text-black font-14 d-block mb-4 fw-bold'>
                                    {t("Amount")}
                                  </span>
                                </div>
                                <div className='col-md-4 col-4'>
                                  <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                    {t("AED")} 3,947,000.00
                                  </span>
                                </div>
                                <div className='col-md-8 col-8'>
                                  <span className='text-black font-14 d-block mb-4 fw-bold'>
                                    {t("VAT")} (5%)
                                  </span>
                                </div>
                                <div className='col-md-4 col-4'>
                                  <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                    {t("AED")} 243,750
                                  </span>
                                </div>
                              </div>
                              <hr className='my-1' />
                            </div>
                            <div className='col-12 my-4'>
                              <div className='row'>
                                <div className='col-md-8 col-8'>
                                  <span className='text-black font-14 d-block fw-bold'>{t("Total")}</span>
                                  <span className='d-block font-10 mb-2 fw-bold'>{t("(Inc. Taxes)")}</span>
                                </div>
                                <div className='col-md-4 col-4 my-auto'>
                                  <span className='text-black font-14 d-block mb-2 fw-boldest'>
                                    {t("AED")} 4,190,750
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className='col-12'>
                              <button
                                data-bs-target='#pay-suc-popup'
                                data-bs-toggle='modal'
                                type='button'
                                className='btn btn-primary w-100 fw-bold px-10 py-3'
                              >
                                {t("Confirm Payment")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
