import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import {
  getServiceRequestsCategories,
  undertakingLetterRequest,
} from '../../../../constants/axios/apis'
import ReactSignatureCanvas from 'react-signature-canvas'
import { useTranslation } from 'react-i18next'
import uploadImg from '../../../../assets/images/file-upload.png'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'
import { RootState } from '../../../../constants'

export function UndertakingLetter({ contract, setMessage }: any) {
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const [services, setServices] = useState<any>()

  let userDetail = localStorage.getItem('userDetail')
  const [loading, setLoading] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [category, setCategory] = useState<any>()
  const history = useHistory()
  const closeRef = useRef<any>()
  const { t } = useTranslation();

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldTouched('photo')
  //   formik.setFieldValue('photo', e.currentTarget.files[0])
  // }

  useEffect(() => {
    getServiceRequestsCategories(currentContract?.property_search_type, currentContract, userDetail && JSON.parse(userDetail)).then(({ data }: any) => {
      setCategory(data.find((category: any) => category.id === 4))
    })
  }, [])

  const undertakingLetterTypes = category?.service_category_types?.map((option: any) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))

  const initialValues = {
    property_id: contract?.propertyID,
    category_id: 4,
    category_type_id: '',
    desc: '',
    signature: '',
    photo: '',
  }

  const sigCanvas = useRef<any>()
  function clear() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }
  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    })
  }

  const undertakingLetterRequestSchema = Yup.object().shape({
    desc: Yup.string().max(50, t("Maximum 50 characters")),
    category_type_id: Yup.number().required(t("Choose the undertaking letter type")),
    signature: Yup.string().required(t("Please add your signature")),
    photo: Yup.mixed()
      .required(t("Document is required"))
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf"), (value) => {
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'image/png' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        return value?.size <= 5242880
      }),
  })

  const formik = useFormik({
    initialValues,
    isInitialValid: false,
    validationSchema: undertakingLetterRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        undertakingLetterRequest(
          values.property_id,
          values.category_id,
          values.category_type_id,
          values.desc,
          values.signature,
          values.photo
        )
          .then((response: any) => {
            setLoading(false)
            setMessage(t("Your Undertaking Letter has been Submitted!"))
            resetForm()
            setUploadedFileName(null)
            formik.setFieldValue('photo', null)
            sigCanvas.current.clear()
            closeRef.current.click()
            history.push('/my-services/openissues')
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', e.currentTarget.files[0])
    } else {
      setUploading(true)
      formik.setFieldError('photo', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
            formik.setFieldTouched('photo', true, false)
            formik.setFieldValue('photo', compressResponse.file, false)
          } else {
            setUploadedFileName(null)
            formik.setFieldTouched('photo', true, false)
            formik.setFieldValue('photo', '', false)
            formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedFileName(null)
        formik.setFieldTouched('photo')
        formik.setFieldValue('photo', '', false)
        formik.setFieldError('photo', t('File upload error!'))
        setUploading(false)
      }
    }
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/my-services/openissues'} className=''>
              <img src={LeftArrow} alt='Back' className='img-fluid me-5' />
            </Link>
            {t("Undertaking Letter")}
          </h4>
        </div>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='col-md-12 col-12'>
            <div className='card'>
              <div className='card-body px-8 py-6'>
                <div className='row form-add-design'>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Undertaking Letter")}<span className='text-danger'>*</span>
                      </label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid'
                        name='category_type_id'
                        onChange={formik.handleChange}
                        value={formik.values.category_type_id}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          {t("Select")}
                        </option>
                        {category ? undertakingLetterTypes : ''}
                      </select>
                      {formik.touched.category_type_id && formik.errors.category_type_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.category_type_id}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10 upload-input'>
                      <label className='form-label fw-bolder d-block'>
                        {t("Supporting Document")}<span className='text-danger'>*</span>
                      </label>
                      <input
                        ref={inputRef}
                        onChange={(e) => handleDisplayFileDetails(e)}
                        className='d-none'
                        type='file'
                      />
                      <button
                        type='button'
                        onClick={handleUpload}
                        disabled={uploading}
                        className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                          }`}
                      >
                        {/* <span>
                          {uploadedFileName ? (
                            uploadedFileName
                          ) : (
                            <span className="txt">
                              {t("Upload")}{' '}<img
                                    alt="File"
                                    src={uploadImg}
                              className="position-relative"
                              loading="lazy"/>
                            </span>
                          )}{' '}
                        </span> */}
                        {uploading ?
                          <span className='indicator-progress d-block'>
                            {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                          : uploadedFileName ? (
                            uploadedFileName
                          ) : (
                            <span className="txt">
                              <img
                                    alt="File"
                                    src={uploadImg}
                              className="position-relative"
                              loading="lazy"/>
                              {t("Upload")}{' '}
                            </span>
                          )}{' '}
                      </button>
                      {formik.touched.photo && formik.errors.photo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.photo}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-8 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Comments")}</label>
                      <textarea
                        className='form-control fw-bolder form-control-solid'
                        data-kt-autosize='true'
                        name='desc'
                        rows={8}
                        placeholder={t("Type here...")}
                        value={formik.values.desc}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.desc && formik.errors.desc && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.desc}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-6 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Signature")}<span className='text-danger'>*</span>
                      </label>
                      {/* <textarea
                        className={clsx(
                          'form-control fw-bolder form-control-solid',
                          {
                            'is-invalid': formik.touched.signature && formik.errors.signature,
                          },
                          {
                            'is-valid': formik.touched.signature && !formik.errors.signature,
                          }
                        )}
                        data-kt-autosize='true'
                        name='signature'
                        rows={3}
                        placeholder='Your Signature'
                        value={formik.values.signature}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.signature && formik.errors.signature && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.signature}
                            </span>
                          </div>
                        </div>
                      )} */}
                      <div className='border border-2 overflow-hidden'>
                        <ReactSignatureCanvas
                          ref={sigCanvas}
                          penColor='black'
                          canvasProps={{ height: 180, width: 372, className: 'sigCanvas' }}
                          onEnd={handleSignature}
                        />
                      </div>
                      {formik.touched.signature && formik.errors.signature && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.signature}
                            </span>
                          </div>
                        </div>
                      )}
                      <button
                        type='button'
                        className='btn btn-default bg-transparent text-primary fw-bold px-10 py-4 d-block border-0 ml-auto'
                        onClick={clear}
                      >
                        {t("Reset")}
                      </button>
                    </div>
                  </div>
                  <div className='col-md-12 text-end'>
                    <div className='row justify-content-end'>
                      <div className='col-md-12'>
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading || uploading}>
                          {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              {t("Please wait...")}{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}
