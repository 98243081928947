import React, { useState } from 'react'
import ChatIoc from "../../../../assets/images/Chat.png";
import ChatRobo from "../../../../assets/icons/chat-robo.png";
import Send from "../../../../assets/icons/send-message.svg";
import { ChatInner } from './ChatInner';

export function ChatBot() {
    const [refetchFlag, setRefetchFlag] = useState<any>(false)

    return (
        <>
            {/* <button type="button" className="border-0 shadow-none" onClick={() => setRefetchFlag(true)} data-bs-toggle="modal" data-bs-target="#chat-bot-popup">
                <img alt='Chat' src={ChatIoc}
                    className="img-fluid position-fixed zindex-2 c-pointer top-80 mt-10 end-0"
                />
            </button> */}
            <div className="modal fade custom-modal chat-bot-popup" id="chat-bot-popup">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-10">
                        <div className="modal-header border-0 bg-primary py-2 justify-content-start">
                            <h5 className="modal-title fw-bold text-white">
                                <img alt="chat" src={ChatRobo} className="me-5" />
                                RealCube
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body px-7 pb-0 chat-main-page">
                            <div className="row" id="kt_chat_messenger">
                                <div className="col-12" id='kt_chat_messenger'>
                                    <ChatInner refetchFlag={refetchFlag} setRefetchFlag={setRefetchFlag} />
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer border-0 pt-0 px-7">
                            <input type="text" className="form-control rounded-10" placeholder="Message"
                                aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <button type="button" className="btn bg-white border-0 rounded-circle position-absolute py-3 px-3 me-2"
                                id="basic-addon2">
                                <img alt="chat" src={Send} className="" />
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}