import { useState, useEffect, useRef } from 'react'
import { shallowEqual, useSelector, connect, useDispatch, ConnectedProps } from 'react-redux'
import * as auth from '../../components/auth/redux/AuthRedux'
import { updateProfileDetails, getProfileDetails } from '../../utils/makeRequest'
import { getMasterData } from '../../constants/axios/apis'
import { IState } from '../../utils/interface'
import { RootState } from '../../constants'
import { getUserByToken } from '../auth/redux/AuthCRUD'
import * as profile from '../../constants/redux/Sagas/ProfileSaga'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import { useTranslation } from 'react-i18next'
import { CustomLoader1 } from '../../constants/generics/customActios'
import { useHistory } from 'react-router-dom';
import * as PTOffer from '../../constants/redux/Sagas/ProspectiveTenant/PTOffer'


const ProfileEdit = (props: any) => {
	let history = useHistory()
	const ref = useRef<any>(0)
	const [message, setMessage] = useState<any>()


	let userDetail: any = {};
	userDetail = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
	let lang: number = userDetail?.data?.preferred_language
	const state: any = useSelector((state) => state)
	const userToken = state?.auth?.accessToken
	const dispatch = useDispatch()
	const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken)
	let profileData: any = []
	profileData = useSelector<RootState>(({ Profile }) => Profile.profile)

	if (userToken === 'undefined') {
		// token undefined
	}

	const [profileUpdated, setProfileUpdated] = useState(false)

	const [profileDetailsState, setProfileDetailsState] = useState<IState['APIState']>({
		loading: false,
		response: [],
		error: '',
	})

	const uploadEl = props.uploadEl
	const profilePicUploadError = props.profilePicUploadError
	const setProfilePicUploadError = props.setProfilePicUploadError

	const [languageList, setLanguageList] = useState<any>()
	const [loading, setLoading] = useState<any>(true)

	const initialValues = {
		first_name: '',
		last_name: '',
		preferred_language: '',
		email: '',
		phone: '',
		alternate_no: '',
		emergency_contact_no: '',
		emergency_contact_name: '',
		subscription: '',
		emirates_id: '',
		/* profile_img: "",  */
	}

	const [updateProfileDetailsState, setUpdateProfileDetailsState] = useState<IState['APIState']>({
		loading: false,
		response: [],
		error: '',
	})

	const { t } = useTranslation();

	const onSubmit = (values: any) => {
		const formData = new FormData()

		formData.append('first_name', values.first_name)
		values.last_name?.length > 0 &&
			formData.append('last_name', values.last_name?.length > 0 ? values.last_name : ' ')

		if (values.preferred_language == 1) {
			formData.append('preferred_language', '1')
		}
		if (values.preferred_language == 2) {
			formData.append('preferred_language', '2')
		}
		// formData.append('preferred_language', values.preferred_language)
		formData.append('email', values.email)
		formData.append('phone', values.phone)
		formData.append('alternate_no', values.alternate_no)
		formData.append('emergency_contact_no', values.emergency_contact_no)
		formData.append('emergency_contact_name', values.emergency_contact_name)
		formData.append('emirates_id', values.emirates_id)

		if (values.subscription.includes('sms')) {
			formData.append('sms_subscription', '1')
		} else {
			formData.append('sms_subscription', '0')
		}

		if (values.subscription.includes('email')) {
			formData.append('email_subscription', '1')
		} else {
			formData.append('email_subscription', '0')
		}

		uploadEl.current?.files[0] && formData.append('profile_img', uploadEl.current?.files[0])

		const pairs = Array.from(formData.entries())
		for (let pair of pairs) {
		}
		updateProfileDetails(setUpdateProfileDetailsState, userToken, formData)
	}

	const validationSchema = Yup.object({
		// first_name: Yup.string()
		// 	.required(t("First Name is required"))
		// 	.min(3, t("Minimum 3 characters"))
		// 	.max(30, t("Maximum 30 characters"))
		// 	.matches(/^[^\s].*/, t("First Name cannot start with a blankspace"))
		// 	.nullable()
		// 	.matches(/^([A-Za-z ]*)$/g, t("First Name can only contain letters.")),
		last_name: Yup.string()
			.required(t("Last Name is required"))
			.matches(/^[^\s].*/, t("Last Name cannot start with a blankspace"))
			.matches(/^[A-Za-z ]*$/, t("Only alphabets allowed"))
			.nullable()
			.max(30, t("Maximum 30 characters")),
		// preferred_language: Yup.string().required('Preferred Language is required'),
		// email: Yup.string()
		// 	.email(t("Field should contain a valid e-mail"))
		// 	.required(t("Email ID is required")),
		// .matches(/^[^\s].*/, 'Email Id cannot start with a blankspace'),
		emirates_id: Yup.string()
			.matches(/^[^\s].*/, t("Emirates Id cannot start with a blankspace"))
			.required(t("Emirates Id is required"))
			.min(15, t("Min length is 15"))
			.nullable()
			.max(25, t("Maximum 25 characters"))
			.matches(/^([A-Za-z0-9\-]*)$/g, 'Emirates Id can only contain letters , "-" and numbers'),
		phone: Yup.string()
			.min(4, t("Mobile No. should be greater then 4 digits"))
			.max(15, t("Mobile No. should be smaller then 15 digits"))
			.matches(/^\+?[0-9]+$/, t("Invalid format, only + and numbers allowed"))
			.matches(/^[^\s].*/, t("Mobile No. cannot start with a blankspace"))
			.required(t("Mobile No. is required")),
		alternate_no: Yup.string()
			.min(4, t("Mobile No. should be greater then 4 digits"))
			.max(15, t("Mobile No. should be smaller then 15 digits"))
			.matches(/^\+?[0-9]+$/, t("Invalid format, only + and numbers allowed"))
			.matches(/^[^\s].*/, t("Mobile No. cannot start with a blankspace")),
		emergency_contact_no: Yup.string()
			// .required('Emergency Contact Number is required')
			.min(4, t("Mobile No. should be greater then 4 digits"))
			.max(15, t("Mobile No. should be smaller then 15 digits"))
			.matches(/^\+?[0-9]+$/, t("Invalid format, only + and numbers allowed"))
			.matches(/^[^\s].*/, t("Mobile No. cannot start with a blankspace")),
		emergency_contact_name: Yup.string()
			// .required('Emergency Contact Name is required')
			.min(3, t("Minimum 3 characters"))
			.max(30, t("Maximum 30 characters"))
			.matches(/^[^\s].*/, t("Cannot start with a blankspace"))
			.matches(/^[A-Za-z ]*$/, t("Only alphabets allowed")),
		/* profile_img: Yup.mixed()
				.test('type', 'Only the following formats are acceptable: .jpeg, .jpg, .png', (value) => {
					return (
						value &&
						(value === "" || value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png')
					)
				})
				.test('fileSize', 'The file is too large', (value) => {
					return value?.size <= 2000000
				}), */
	})

	const validate = (values: any) => {
		let errors: any = {}

		if (profilePicUploadError[0]) {
			errors.profile_pic = 'Profile pic error'
		}

		return errors
	}


	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
		validate,
	})

	useEffect(() => {
		getProfileDetails(setProfileDetailsState, userToken)
		const requestUser = async () => {
			const { data: user } = await getUserByToken()
			dispatch(auth.actions.fulfillUser(user))
			dispatch(profile.actions.profile())

		}
		requestUser()
	}, [updateProfileDetailsState])

	useEffect(() => {
		if (Object.keys(profileDetailsState.response).length !== 0) {
			formik.setFieldValue('profile_img', uploadEl.current?.files[0])

			formik.setFieldValue('first_name', profileDetailsState.response.first_name)
			formik.setFieldValue('last_name', profileDetailsState.response.last_name)
			formik.setFieldValue('phone', profileDetailsState.response.phone)
			formik.setFieldValue('email', profileDetailsState.response.email)
			formik.setFieldValue('emirates_id', profileDetailsState.response.emirates_id)

			formik.setFieldValue('alternate_no', profileDetailsState.response.alternate_no ? profileDetailsState.response.alternate_no : '')
			formik.setFieldValue(
				'emergency_contact_no',
				profileDetailsState.response.emergency_contact_no ? profileDetailsState.response.emergency_contact_no : ''
			)
			formik.setFieldValue(
				'emergency_contact_name',
				profileDetailsState.response.emergency_contact_name ? profileDetailsState.response.emergency_contact_name : ''
			)

			if (profileDetailsState.response.preferred_language === 1) {
				formik.setFieldValue('preferred_language', 1)
			}
			if (profileDetailsState.response.preferred_language === 2) {
				formik.setFieldValue('preferred_language', 2)
			}
			let subArray = []

			if (profileDetailsState.response.email_subscription === 1) {
				subArray.push('email')
			}
			if (profileDetailsState.response.sms_subscription === 1) {
				subArray.push('sms')
			}

			if (subArray.length > 0) {
				formik.setFieldValue('subscription', subArray)
			}
		}
	}, [profileUpdated, profileDetailsState])

	// useEffect(() => {
	// 	if (updateProfileDetailsState.response === 1) {
	// 		setProfileUpdated(!profileUpdated)
	// 		setMessage({
	// 			error: 0,
	// 			message: t("Profile updated successfully"),
	// 		})
	// 		ref.current.click()
	// 		setTimeout(() => {
	// 			ref.current.click()
	// 		}, 3000);
	// 		// history.push(`/`)
	// 	}
	// 	if (updateProfileDetailsState.error) {
	// 		setProfileUpdated(!profileUpdated)
	// 		setMessage({
	// 			error: 1,
	// 			message: updateProfileDetailsState.error,
	// 		})
	// 		ref.current.click()
	// 		setTimeout(() => {
	// 			ref.current.click()
	// 		}, 3000);
	// 		// history.push(`/`)
	// 	}
	// }, [updateProfileDetailsState])

	useEffect(() => {
		if (updateProfileDetailsState.response === 1) {
			setProfileUpdated(!profileUpdated)
			props.onDetail()
			props.setSuccessMsg(t("Profile updated successfully"))
			// window.location.replace('/profile')
		}
	}, [updateProfileDetailsState])

	const handlePhoneNumber = (phoneNumber: any) => {
		formik.setFieldValue('phone', phoneNumber !== '' ? '+' + phoneNumber : phoneNumber)
		// formik.setFieldValue('phone', phoneNumber)
		formik.setFieldTouched('phone')
	}

	const handleEmergencyContactNumber = (emergenceContactNo: any) => {
		formik.setFieldValue('emergency_contact_no', emergenceContactNo !== '' ? '+' + emergenceContactNo : emergenceContactNo)
		formik.setFieldTouched('emergency_contact_no')
	}

	const handleAlternateNumber = (alternateNo: any) => {
		formik.setFieldValue('alternate_no', alternateNo !== '' ? '+' + alternateNo : alternateNo)
		formik.setFieldTouched('alternate_no')
	}

	useEffect(() => {
		setLoading(true)
		setLanguageList([])
		getMasterData()
			.then(({ data }) => {
				const languageList = data.find((el: any) => el.category_name === 'Language')
				setLanguageList(languageList?.master_data ? languageList?.master_data : [])
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
			})
	}, [])

	const languageOption = languageList?.map((languageItem: any) => (
		<option key={languageItem.value} value={languageItem.value} className='text-capitalize'>
			{languageItem.name}
		</option>
	))
	useEffect(() => {
		if (message?.message?.length > 0) {
			dispatch(PTOffer.actions.modalmessage(message))
		}
	}, [message])
	return (
		<>
			{profileDetailsState.loading && (
				<>
					<div className='row form-add-design'>
						<div className='col-lg-6 col-12 w-100 h-20'>
							<div
								className='mb-10 h-20 d-flex justify-content-center align-items-center'
								style={{ height: '500px' }}
							>
								<CustomLoader1 height={30} />
							</div>
						</div>
					</div>
				</>
			)}
			{!profileDetailsState.loading && (
				<>
					<form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
						<div className='row form-add-design'>
							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'>{t("First Name")} <small className='text-danger'>* </small>{' '}</label>
									{/* <input
                type='text'
                className='form-control fw-bolder form-control-solid'
                placeholder={t("First Name")}
                {...formik.getFieldProps('first_name')}
                disabled={profileDetailsState.response.first_name?.length >= 4 ? true : false}
            /> */}
									<div className="form-control bg-light">
										{profileDetailsState.response.first_name}
									</div>
									<div className='d-flex flex-column text-danger'>
										{formik.touched.first_name && formik.errors.first_name ? (
											<div className='d-flex flex-column text-danger'>
												<small className='text-danger'>{formik.errors.first_name}</small>
											</div>
										) : null}
									</div>
								</div>
							</div>
							{/* <div className='col-lg-6 col-12'>
<div className='mb-10'>
  <label className='form-label fw-bolder'> {t("Middle Name")}</label>
  <input
    type='text'
    className='form-control fw-bolder form-control-solid'
    placeholder={t("Middle Name")}
    {...formik.getFieldProps('middle_name')}
    disabled={true}
  />
  <div className='d-flex flex-column text-danger'>
    {formik.touched.middle_name && formik.errors.middle_name ? (
      <div className='d-flex flex-column text-danger'>
        <small className='text-danger'>{formik.errors.middle_name}</small>
      </div>
    ) : null}
  </div>
</div>
</div> */}
							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'> {t("Last Name")} <small className='text-danger'>* </small>{' '}</label>
									{/* <input
                type='text'
                className='form-control fw-bolder form-control-solid'
                placeholder={t("Last Name")}
                {...formik.getFieldProps('last_name')}
                disabled={profileDetailsState.response.last_name?.length >= 4 ? true : false}
            /> */}
									{profileDetailsState.response.last_name?.length >= 4 ?
										<div className="form-control bg-light">
											{profileDetailsState.response.last_name}
										</div>
										:
										<input
											type='text'
											className='form-control fw-bolder form-control-solid'
											placeholder={t("Last Name")}
											{...formik.getFieldProps('last_name')} />}
									<div className='d-flex flex-column text-danger'>
										{formik.touched.last_name && formik.errors.last_name ? (
											<div className='d-flex flex-column text-danger'>
												<small className='text-danger'>{formik.errors.last_name}</small>
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'> {t("Emirates Id")} <small className='text-danger'>* </small>{' '}</label>
									{profileDetailsState.response.emirates_id?.length >= 15 ?
										<div className="form-control bg-light">
											{profileDetailsState.response.emirates_id}
										</div>
										:
										<input
											type='text'
											className='form-control fw-bolder form-control-solid'
											placeholder={t("Emirates Id")}
											{...formik.getFieldProps('emirates_id')} />}


									<div className='d-flex flex-column text-danger'>
										{formik.touched.emirates_id && formik.errors.emirates_id ? (
											<div className='d-flex flex-column text-danger'>
												<small className='text-danger'>{formik.errors.emirates_id}</small>
											</div>
										) : null}
									</div>
									{/*<div className='d-flex flex-column text-danger'>*/}
									{/*{formik.touched.last_name && formik.errors.last_name ? (*/}
									{/*<div className='d-flex flex-column text-danger'>*/}
									{/*<small className="text-danger">{formik.errors.last_name}</small>*/}
									{/*</div>*/}
									{/*) : null}*/}
									{/*</div>*/}
								</div>
							</div>
							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'> {t("Mobile Number")} <small className='text-danger'>* </small>{' '}</label>
									<div className='d-block'>
										<PhoneInput
											country={'ae'}
											preferredCountries={['ae']}
											// onlyCountries={['ae']}
											priority={{ ae: 0, in: 1, us: 2 }}
											value={formik.values.phone}
											onChange={handlePhoneNumber}
											enableSearch={true}
											// enableAreaCodeStretch={false}
											// disableCountryCode={true}
											countryCodeEditable={false}
											inputClass={'text-black' + (profileDetailsState.response.phone?.length >= 6 ? ' bg-light' : '')}
											placeholder={t("Mobile Number")}
											disabled={profileDetailsState.response.phone?.length >= 6 ? true : false}
										// isValid={(value: any, country: any) => {
										// 	if (value.match(/123456/)) {
										// 		return 'Invalid value: ' + value + ', ' + country.name;
										// 	} else if (value.length > 12 && country.name === 'United Arab Emirates') {
										// 		return false;
										// 	} else if (value.match(/00/)) {
										// 		return false;
										// 	} else {
										// 		return true;
										// 	}
										// }}
										/>
									</div>
									<div className='d-flex flex-column text-danger'>
										{formik.touched.phone && formik.errors.phone ? (
											<div className='d-flex flex-column text-danger'>
												<small className='text-danger'>{formik.errors.phone}</small>
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'> {t("Email Address")} <small className='text-danger'>* </small>{' '}</label>
									<div className="form-control bg-light">
										{profileDetailsState.response.email}
									</div>
									{/* <input
                type='email'
                className='form-control fw-bolder form-control-solid'
                placeholder={t("Email Address")}
                {...formik.getFieldProps('email')}
            /> */}
									<div className='d-flex flex-column text-danger'>
										{formik.touched.email && formik.errors.email ? (
											<div className='d-flex flex-column text-danger'>
												<small className='text-danger'>{formik.errors.email}</small>
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'> {t("Alternate Contact Number")}</label>
									<PhoneInput
										// country={'ae'}
										preferredCountries={['ae']}
										// onlyCountries={['ae']}
										priority={{ ae: 0 }}
										value={formik.values.alternate_no}
										onChange={handleAlternateNumber}
										enableSearch={true}
										enableAreaCodeStretch={true}
										inputClass={'text-black'}
										placeholder={t("Alternate Contact Number")}
									// isValid={(value: any, country: any) => {
									// 	if (value.match(/123456/)) {
									// 		return 'Invalid value: ' + value + ', ' + country.name;
									// 	} else if (value.length > 12 && country.name === 'United Arab Emirates') {
									// 		return false;
									// 	} else if (value.match(/00/)) {
									// 		return false;
									// 	} else {
									// 		return true;
									// 	}
									// }} 
									/>
									<div className='d-flex flex-column text-danger'>
										{formik.touched.alternate_no && formik.errors.alternate_no ? (
											<div className='d-flex flex-column text-danger'>
												<small className='text-danger'>{formik.errors.alternate_no}</small>
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'>{t("Preferred Language")}</label>
									<select
										// name='preferred_language'
										className='form-control form-select fw-bolder form-control-solid'
										{...formik.getFieldProps('preferred_language')}
									>
										{/* <option value=''>Select</option> */}
										{/* {languageList ? languageOption : ''} */}
										<option value={1}>English</option>
										<option value={2}>العربية</option>
										{/* {languages.map(({ name,value }) => (
                <option key={value} value={value}>
                    {name}
                </option>
            ))} */}
									</select>
									<div className='d-flex flex-column text-danger'>
										{formik.touched.preferred_language && formik.errors.preferred_language ? (
											<div className='d-flex flex-column text-danger'>
												<small className='text-danger'>{formik.errors.preferred_language}</small>
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'>{t("Emergency Contact Name")}</label>
									<input
										type='text'
										className='form-control fw-bolder form-control-solid'
										placeholder={t("Emergency Contact Name")}
										{...formik.getFieldProps('emergency_contact_name')} />
									<div className='d-flex flex-column text-danger'>
										{formik.touched.emergency_contact_name &&
											formik.errors.emergency_contact_name ? (
											<div className='d-flex flex-column text-danger'>
												<small className='text-danger'>
													{formik.errors.emergency_contact_name}
												</small>
											</div>
										) : null}
									</div>
								</div>
							</div>
							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'>{t("Emergency Contact Number")}</label>
									<PhoneInput
										// country={'ae'}
										preferredCountries={['ae']}
										// onlyCountries={['ae']}
										priority={{ ae: 0, in: 1, us: 2 }}
										value={formik.values.emergency_contact_no}
										onChange={handleEmergencyContactNumber}
										enableSearch={true}
										enableAreaCodeStretch={true}
										inputClass={'text-black'}
										placeholder={t("Emergency Contact Number")}
									// isValid={(value: any, country: any) => {
									// 	if (value.match(/123456/)) {
									// 		return 'Invalid value: ' + value + ', ' + country.name;
									// 	} else if (value.length > 12 && country.name === 'United Arab Emirates') {
									// 		return false;
									// 	} else if (value.match(/00/)) {
									// 		return false;
									// 	} else {
									// 		return true;
									// 	}
									// }}
									/>
									<div className='d-flex flex-column text-danger'>
										{formik.touched.emergency_contact_no && formik.errors.emergency_contact_no ? (
											<div className='d-flex flex-column text-danger'>
												<small className='text-danger'>{formik.errors.emergency_contact_no}</small>
											</div>
										) : null}
									</div>
								</div>
							</div>

							<div className='col-lg-6 col-12'>
								<div className='mb-10'>
									<label className='form-label fw-bolder'>{t("Subscription for Offers")}</label>
									<div className='mt-2 d-flex'>
										<div className=''>
											<label className='form-check-label text-black font-14 fw-normal'> {t("SMS")} </label>
											<div className='form-check form-switch d-inline'>
												<input
													className='form-check-input'
													type='checkbox'
													id='sms'
													{...formik.getFieldProps('subscription')}
													value='sms'
													checked={formik.values.subscription.includes('sms')} />
												<label className='form-check-label' htmlFor='sms'></label>
											</div>
										</div>
										<div className='ms-20 ms-20-ar'>
											<label className='form-check-label text-black font-14 fw-normal'>
												{t("Email")}
											</label>
											<div className='form-check form-switch d-inline'>
												<input
													className='form-check-input'
													type='checkbox'
													id='email'
													{...formik.getFieldProps('subscription')}
													value='email'
													checked={formik.values.subscription.includes('email')} />
												<label className='form-check-label' htmlFor='email'></label>
											</div>
										</div>
									</div>
								</div>
							</div>
							{props.userEmail == 'elio.broker@exalogic.co' ? <>
								<div className='col-lg-6 col-12'>
									<div className='mb-10'>
										<label className='form-label fw-bolder'>{t("Country (bank account/country key)")}</label>
										<select className='form-control fw-bolder form-control-solid'>
											<option>United Arab Emirates</option>
										</select>
									</div>
								</div>
								<div className='col-lg-6 col-12'>
									<div className='mb-10'>
										<label className='form-label fw-bolder'>{t("IBAN Number")}</label>
										<input
											type='text'
											className='form-control fw-bolder form-control-solid'
											placeholder={t("IBAN Number")} />
									</div>
								</div>
								<div className='col-lg-6 col-12'>
									<div className='mb-10'>
										<label className='form-label fw-bolder'>{t("Bank Name")}</label>
										<select className='form-control fw-bolder form-control-solid'>
											<option>Central Bank of UAE</option>
										</select>
									</div>
								</div>
								<div className='col-lg-6 col-12'>
									<div className='mb-10'>
										<label className='form-label fw-bolder'>{t("Bank Account Number")}</label>
										<input
											type='text'
											className='form-control fw-bolder form-control-solid'
											placeholder={t("Bank Account Number")} />
									</div>
								</div>
								<div className='col-lg-6 col-12'>
									<div className='mb-10'>
										<label className='form-label fw-bolder'>{t("Beneficiary Name")}</label>
										<input
											type='text'
											className='form-control fw-bolder form-control-solid'
											placeholder={t("Account Holder Name")} />
									</div>
								</div>
								<div className='col-lg-6 col-12'>
									<div className='mb-10'>
										<label className='form-label fw-bolder'>{t("Home City")}</label>
										<input
											type='text'
											className='form-control fw-bolder form-control-solid'
											placeholder={t("Home City")} />
									</div>
								</div>
								<div className='col-lg-6 col-12'>
									<div className='mb-10'>
										<label className='form-label fw-bolder'>{t("Swift Code")}</label>
										<input
											type='text'
											className='form-control fw-bolder form-control-solid'
											placeholder={t("Swift Code")} />
									</div>
								</div>
								<div className='col-12 text-end'>
									{updateProfileDetailsState.loading && <CustomLoader1 height={50} />}
									{!updateProfileDetailsState.loading && (
										<>
											{/* <button type="submit" onClick={props.onDetail} className='btn btn-outline-primary fw-bold px-10 py-2 me-5'>
                    {t("Cancel")}
                </button> */}
											<button
												onClick={formik.submitForm}
												type='button'
												className='btn btn-primary fw-bold px-10 py-2'
											>
												{t("Submit")}
											</button>
										</>
									)}
								</div>
							</> : null}

							{/* {updateProfileDetailsState.error && (
								<div className='alert alert-danger w-100 mt-2' role='alert'>
									{updateProfileDetailsState.error}
								</div>
							)}
							{updateProfileDetailsState.response === 1 && (
								<div className='alert alert-success w-100 mt-2' role='alert'>
									{t("Profile updated successfully")}
								</div>
							)} */}
						</div>
					</form>
				</>
			)}
			{/* <p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
			</p> */}
		</>
	)
}

export default ProfileEdit
