import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import Home from "../../../../../assets/images/my-unit.png";
import { PermitDetails } from './PermitDetails';
import { PermitRequirements } from './PermitRequirements';
import { RequiredDocuments } from './RequiredDocuments';
import { useTranslation } from 'react-i18next'
import { getMasterData } from '../../../../../constants/axios/apis';

export function RoofTopAccess({ contract }: any) {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<any>(false)
    const [masterData, setMasterData] = useState<any>([])
    const [permitDetails, setPermitDetails] = useState<any>()
    const [permitRequirements, setPermitRequirements] = useState<any>()
    const [documents, setDocuments] = useState<any>()
    const [isSubmitable, setIsSubmitable] = useState<any>(false)

    const ref1 = useRef<any>()
    const ref2 = useRef<any>()

    useEffect(() => {
        getMasterData().then(({ data: masterData }) => {
            setMasterData(masterData)
        })
    }, [])

    // const handelFinalSubmit = () => {
    //     if (generalInformation?.saved && impairmentDetails?.saved) {
    //         setLoading(true)
    //         setIsSubmitable(false)
    //         var FormData = require('form-data')
    //         var data = new FormData()
    //         data.append('contract_id', contract?.id)
    //         data.append('property_id', contract?.propertyID)
    //         data.append('category_id', 35)
    //         data.append('category_type_id', 53)
    //         data.append('fpi_permit_no', generalInformation.permit_number)
    //         data.append('fpi_location_of_work', generalInformation.location_of_work)
    //         data.append('fpi_requested_date', generalInformation.date_of_impairmet)
    //         data.append('fpi_requested_time', generalInformation.time_of_impairment)
    //         data.append('fpi_system_restored_date', generalInformation.system_restore_date)
    //         data.append('fpi_system_restored_time', generalInformation.system_restore_time)
    //         data.append('fpi_reason', generalInformation.reason)
    //         impairmentDetails.requirements_ids.forEach((requirement_id: any, index: any) => {
    //             data.append(`fpi_permitRequirement[${[index]}]`, requirement_id)
    //         });
    //         impairmentDetails.checklist_ids.forEach((checklist_id: any, index: any) => {
    //             data.append(`fpi_checklist[${[index]}]`, checklist_id)
    //         });
    //         data.append('fpi_permit_applicant_name', impairmentDetails.permit_applicant)
    //         data.append('fpi_permit_applicant_signature', impairmentDetails.signature)

    //         setTimeout(() => {
    //             fireProtectionImpairmentPermit(data)
    //                 .then((response: any) => {
    //                     console.log(response)
    //                     if (response.errorCode === 1) {
    //                     } else if (response.errorCode === 0) {

    //                     } else {
    //                     }
    //                     setLoading(false)
    //                     setIsSubmitable(false)
    //                 })
    //                 .catch((e) => {
    //                     console.log(e, 'Error');
    //                 })
    //         }, 1000)

    //     }
    // }

    // useEffect(() => {
    //     if (generalInformation?.saved && impairmentDetails?.saved) {
    //         setIsSubmitable(true)
    //     } else {
    //         setIsSubmitable(false)
    //     }
    // }, [generalInformation, impairmentDetails])

    return (
        <div className="com-card-section">
            <div className='row'>
                <div className='col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                        <Link to={'/my-services/35'} className=''>
                            <img src={LeftArrow} alt="Back" className='img-fluid me-5' />
                            <span className='text-black'>{t("Access to Rooftop")}</span>
                        </Link>
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-8">
                            <div className="row mb-15 align-items-center">
                                <div className="col-md-4 col-lg-3">
                                    <div className="text-center">
                                        <img src={Home} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-lg-9 pt-5">
                                    <div className="card lease-card refund-block rounded">
                                        <div className="card-body px-5 py-8">
                                            <div className="row">
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Tenant")} </h6>
                                                    <span className="text-black fw-bold font-14"> Mohammed </span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Building Name")} </h6>
                                                    <span className="text-black fw-bold font-14"> Nation Towers </span>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <h6 className="text-theme-gray mb-3 fw-normal font-13"> {t("Unit Number")} </h6>
                                                    <span className="text-black fw-bold font-14"> NT102 </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="accordion move-out-accordion" id="accordion-rooftop-access">
                                        <PermitDetails setPermitDetails={setPermitDetails} ref1={ref1} />
                                        <PermitRequirements setPermitRequirements={setPermitRequirements} masterData={masterData} ref1={ref1} ref2={ref2} />
                                        <RequiredDocuments setDocuments={setDocuments} ref2={ref2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}