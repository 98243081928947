import { Link } from "react-router-dom";

export function NocSale() {
    return (
        <>
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body form-add-design">
                        <h4 className="card-title mb-7">NOC for Unit Sale Request</h4>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="mb-7">
                                    <label className="form-label"> Community <span>*</span>
                                    </label>
                                    <select name="id_community" className="form-control form-select" required >
                                        <option value="">Select Community</option>
                                        <option value="6">Al Raha Gardens</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="mb-7">
                                    <label className="form-label"> Precinct <span>*</span>
                                    </label>
                                    <select name="id_precinct" className="form-control role-select form-select" required>
                                        <option value="">Select Precinct</option>
                                        <option value="1">ARG Residential</option>
                                    </select>
                                </div>
                            </div>              
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="mb-7">
                                    <label className="form-label"> Unit Number <span>*</span></label>
                                    <select required name="id_unit" id="srv_id_unit" className="form-control form-select">
                                        <option value="">Select Unit Number</option>
                                        <option value="7640">456</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <form className="form-register" method="post">
                            <div className="row mb-4">
                                <div className="col-8">
                                    <h4 className="card-title mb-2"> Upload Documents </h4>
                                    <p>Note: Please ensure your signature matches your Emirates ID signature</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-7">
                                            <label className="form-label"> Buyer name </label>
                                            <input type="text" required className="form-control" name="buyer_name" id="buyer_name" placeholder="Buyer Name"/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-7">
                                                <label className="form-label">
                                                    Buyer contact number 
                                                    <span>*</span>
                                                </label>
                                                <input type="text" required className="form-control" placeholder="Buyer contact number" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-7">
                                            <label className="form-label">
                                                Buyer email address 
                                                <span>*</span>
                                            </label>
                                            <input type="email" required className="form-control" id="buyer_email" name="buyer_email" placeholder="Buyer Email Address"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 comm_p addc-div">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="addc-cert"> ADDC certificate (Owner account clearance) <a href={'https://provis-images.s3.us-east-2.amazonaws.com/ADDC+sample+document.pdf'} target="_blank">View Sample</a></label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="addc_certificate_filename[]" className="form-control" id="addc-cert" required/>
                                                <small className="mb-0">Note: Please check the sample, other documents will not be accepted and may delay the process</small>
                                            </div>
                                            
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 comm_p undertaking-div">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="transfer-ut"> Undertaking to transfer utilities from buyer</label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="transfer_utilities_filename[]" className="form-control" id="transfer-ut" required/>
                                            </div>
                                            
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="buyer-pass"> Buyer Front side Passport/EID copy </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="buyer_passport_filename[]" className="form-control" id="buyer-pass" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="buyer-pass-back"> Buyer Back side Passport/EID copy </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="buyer_passport_filename_back[]" className="form-control" id="buyer-pass-back" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="seller-pass"> Seller Front side Passport/EID copy </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="seller_passport_filename[]" className="form-control" id="seller-pass" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="seller-pass-back"> Seller Back side Passport/EID copy </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="seller_passport_filename_back[]" className="form-control" id="seller-pass-back" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="title-deed"> Title Deed </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="title_deed_filename[]" className="form-control" id="title-deed" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="mou-doc"> MOU </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="mou_filename[]" className="form-control" id="mou-doc" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="buyer-info"> Buyer information sheet </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="buyer_info_sheet_filename[]" className="form-control" required />
                                                <small> (Please ensure document is signed by the buyer)</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="assign-service"> Assignment of service charge seller </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="sc_seller_assignment_filename[]" className="form-control" id="assign-service" required/>
                                                <small> (Please ensure document is signed by both parties)</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 comm_p">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="district-col"> District cooling clearance (Owner account clearance) </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="district_cooling_clearence_filename[]" className="form-control" id="district-col" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 comm_p">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="gas-clearance"> Gas clearance </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="gas_clearence_filename[]" className="form-control" id="gas-clearance" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12" id="sc_forms">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="ser-form">Undertaking to Pay Service Charges (to be signed by the buyer)</label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="pay_sc_form_filename[]" className="form-control" id="ser-form" required/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h3>Terms and Conditions</h3>
                                </div>
                                <div className="col-12">
                                    <div className="bg-light rounded my-3">
                                        <ol type="a" className="py-3 m-0">
                                            <li> Pay 210.00 AED as NOC for Unit Sale fees (Inclusive VAT) </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-check">
                                        <input type="checkbox" name="type" value="1" id="tnc" className="form-check-input" />
                                        <label htmlFor="tnc" className="form-check-label">I hereby confirm that I have understood the terms and conditions mentioned herein and I agree to abide by the Community Rules.</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 text-end">
                                    <button type="submit" name="submit" value="submit" className="btn btn-primary">Submit &amp; Pay</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}