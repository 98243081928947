import { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Place from '../../../../assets/images/place.svg'
import Sqft from '../../../../assets/images/icon-sqft.svg'
import Bed from '../../../../assets/images/icon-bed.svg'
import Bath from '../../../../assets/images/icon-bath.svg'
import YesIcon from '../../../../assets/images/icon-parking.svg'
import SignatureCanvas from 'react-signature-canvas'
import NoDataFoundImage from '../../../../assets/images/no-data-found.svg'
import { addSignature } from './../../../../constants/axios/apis';
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap-v5'
import { CustomLoader1, NoDataFound } from '../../../../constants/generics/customActios'
import * as General from './../../../../constants/redux/Sagas/GeneralSaga'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import { RootState } from '../../../../constants'
import { Buffer } from 'buffer'
import * as dashboard from '../../../../constants/redux/Sagas/Tenant/TenantDashboardSaga'
import * as auth from '../../../../components/auth/redux/AuthRedux'


const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function MyContracts(props: any) {
  let contractDetails: any
  contractDetails = useSelector<RootState>(({ General }) => General.contractDetails)
  const dispatch = useDispatch()
  const { contract } = props
  const history = useHistory();
  const [fieldValue, setFieldValue] = useState<any>()
  const [contractId, setcontractId] = useState<any>()

  const ref = useRef<any>(0)
  const ref2 = useRef<any>(0)
  const [errormessage, seterrorMessage] = useState<any>()
  const [message, setMessage] = useState<any>()
  const [loading, setLoading] = useState(false)
  const sigCanvas = useRef<any>()
  const { t } = useTranslation();

  const state: any = useSelector(state => state);
  let token = state?.auth?.accessToken;
  let idRole = state?.auth?.user?.data?.id_role + '';

  function clearSignature() {
    sigCanvas.current.clear()
    setFieldValue('')
  }


  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      setFieldValue(blob)
    }, 'image/png')
  }
  const addSign = () => {
    if (fieldValue !== undefined && fieldValue !== '') {
      setLoading(true)
      var data = new FormData();
      data.append('contract_id', contractId);
      data.append('signature', fieldValue, 'signature.png');

      addSignature(data).then((response) => {
        setFieldValue('')
        ref2.current.click();
        setLoading(false)
        if (response?.code === 200 && response?.errorCode != 1) {

          dispatch(General.actions.contractDetails(contractDetails))
          clearSignature()
          setMessage({
            error: 0,
            message: response?.data?.msg,
            waiting: true,
            // message: response?.data?.msg + ". Please login again to continue!",
          })
          ref.current.click();
          // setTimeout(() => {
          //   window.location.replace("/pt-activity/my-contracts");
          // }, 3000)
          handleRedirect(contractId)
        } else {
          setMessage({
            error: 1,
            // message: response?.errorDescription,
            message: response?.errorDescription ? response?.errorDescription : t("Some error occured!"),
          })
          ref.current.click();
        }
      }).catch((e: any) => {
        setMessage({
          error: 1,
          message: t("Some error occured!"),
        })
        ref.current.click();
      }
      )
    } else {
      seterrorMessage('Signature is required');
      setTimeout(() => {
        seterrorMessage('')
      }, 2000)
    }
  }

  useEffect(() => {
    if (message?.message?.length > 0) {
      dispatch(PTOffer.actions.modalmessage(message))
    }
  }, [message])
  const getCOontractDetails = (item: any) => {
    dispatch(General.actions.contractDetails(item))
    history.push(`/pt-activity/my-contracts-details/${item?.id}`)
  }
  const broker_array = [
    { brokerName: "Vishnu", mobile: "1234567890", name: "Bob" },
    { brokerName: "Amit", mobile: "0987654321", name: "Jane" },
    { brokerName: "Karan", mobile: "5678901234", name: "Eva" },
    { brokerName: "Vishnu", mobile: "2345678901", name: "Grace" },
    { brokerName: "Amit", mobile: "8901234567", name: "Charlie" },
    { brokerName: "Karan", mobile: "3456789012", name: "Hank" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Alice" },
    { brokerName: "Amit", mobile: "6789012345", name: "Frank" },
    { brokerName: "Karan", mobile: "7890123456", name: "David" },
    { brokerName: "Vishnu", mobile: "0123456789", name: "John" },
    { brokerName: "Amit", mobile: "9012345678", name: "Eva" },
    { brokerName: "Karan", mobile: "6789012345", name: "Bob" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Jane" },
    { brokerName: "Amit", mobile: "2345678901", name: "Charlie" },
    { brokerName: "Karan", mobile: "8901234567", name: "Hank" }
  ]
  //Auto logout & login using sso after contract signing
  const handleRedirect = (contractId: any) => {
    try {
      if (token && idRole) {
        token = Buffer?.from(token)?.toString('base64')
        idRole = Buffer?.from(idRole)?.toString('base64')
        dispatch(dashboard.actions.selectedContractId(contractId))
        setTimeout(() => {
          dispatch(auth.actions.logout())
        }, 4000);
        setTimeout(() => {
          window.location.replace(`/?t=${token}&d=${idRole}`);
        }, 5000);
      } else {
        window.location.replace("/pt-activity/my-contracts");
      }
    } catch (error: any) {
      window.location.replace("/pt-activity/my-contracts");
    }
  }

  if (contract === undefined) return <CustomLoader1 height={30} />;
  return (
    <><div className='com-card-section'>
      <div className='card'>
        <div className='card-body px-8 pb-1 pt-7'>
          <div className='row'>
            {contract && contract?.errorCode != 1 && contract?.description != 'No data found.' && contract?.data?.length > 0
              ? contract?.data.map((item: any, i: any) => {
                return (
                  <div className='col-lg-6 col-12' key={i}>
                    <div className='pm-offers-and-renewals mb-6'>
                      <div className='card c-pointer'>
                        <div className='row'>
                          <div className='col-12' onClick={(e) => getCOontractDetails(item)}>
                            <div className='offer_main_img h-100 img-bg'>
                              <div className='img-inner'>
                                <img
                                  src={item?.propertyImage}
                                  alt='Work Orders'
                                  className='img-fluid w-100'
                                />
                              </div>
                              <div className='property-overlay'>
                                <div className="row align-items-center g-0">
                                  <div className="col-6">
                                    <h6>{item?.property_search_type === 1 ? t("Residential") : item?.property_search_type === 2 ? t("Commercial") : item?.property_search_type}</h6>
                                  </div>
                                  <div className="col-6 text-end">
                                    <span className='price-amount d-block font-12'>
                                      {t("AED")} <strong className="font-16">{item?.price?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</strong>  / {t("Year")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* <span className="badge badge-pill badge-dark prop-type-badge">{item?.property_search_type === 1 ? t("Residential") : item?.property_search_type === 2 ? t("Commercial") : item?.property_search_type}</span> */}
                            </div>
                          </div>
                          <div className='col-12 align-self-center' onClick={(e) => getCOontractDetails(item)}>
                            <div className='offer_main_details p-3'>
                              <div className='col-12 pt-3'>
                                <p className='text-black fw-bolder font-17 my-offer-property-name mb-3'>
                                  {item?.property_name}
                                </p>
                                {/* <h1 className='text-black fw-bolder mb-3'>
                                  <small>{t("AED")}</small>{' '}
                                  <span className='fw-boldest'>{parseInt(item?.rent)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span> <small> / {t("Year")} </small>
                                </h1> */}
                              </div>
                              <div className='col-12 offer_main_details_div_3 mb-0'>
                                <span className="mb-0 fw-bolder d-inline-block mb-3">{t("Contract No:")} {item?.contractNo}</span>
                                <p className='mb-3'>
                                  <img src={Place} className='img-fluid pe-2' />
                                  {/* {item?.location} */}
                                  {item?.propertyCode?.substring(item?.propertyCode?.lastIndexOf("-") + 1, item?.propertyCode?.length) + ' | '} {item?.buildingName && item?.buildingName + ' | '}  {item?.address && item?.address + ' | '}  {item?.location && item?.location}
                                </p>
                              </div>
                              <div className='col-12'>
                                <div className='mainCard_d_etails_div_2 d-flex'>
                                  {item?.bedRooms?.length > 0 && item?.bedRooms != 0 ? (
                                    <p className='p-0 me-2 mb-0'>
                                      <img src={Bed} className='img-fluid me-3' alt="bed" /> {item?.bedRooms} Bedrooms
                                    </p>
                                  ) : (
                                    <></>
                                  )
                                  }
                                  {item?.bathRooms?.length > 0 && item?.bathRooms != 0 ? (
                                    <p className='p-0 me-2 mb-0'>
                                      <img src={Bath} className='img-fluid me-3' alt="bath" /> {item?.bathRooms} Batrooms
                                    </p>
                                  ) : (
                                    <></>
                                  )
                                  }
                                  {item?.builtInArea > 0 && item?.builtInArea != 0 ? (
                                    <p className='p-0 me-2 mb-0 sqft-my-offer'>
                                      <img src={Sqft} className='img-fluid me-3 ' />
                                      {item?.builtInArea} {t("Sqmt")}
                                    </p>
                                  ) : (
                                    <></>
                                  )
                                  }
                                  <p className='p-0 mb-0'>
                                    <img src={YesIcon} className='img-fluid me-3' />
                                    {item?.parking === 'Y' ? t("Yes") : t("No")}
                                  </p>
                                </div>
                              </div>
                              <div className='col-12'>
                                <div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
                                  <p className='p-3 me-2'>
                                    Broker : {t(broker_array[i].brokerName)}
                                  </p>
                                  <p className='p-3'>
                                    User Name : {t(broker_array[i].name)}
                                  </p>
                                  <p className='p-3 me-2 sqft-my-offer'>
                                    User Mobile : {t(broker_array[i].mobile)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 align-self-center'>
                            <div className='p-5 pt-0'>
                              {<div className='card-status'>
                                <p
                                  className={item?.status === 'Approved'
                                    ? 'approved'
                                    : 'pending'}
                                >
                                  {item?.status}
                                </p>
                              </div>}
                              {item?.contractFile != null ? (
                                <div className='text-end'>
                                  <a href={item?.contractFile} target='_blank'>
                                    <button className='btn btn-primary rounded py-2 w-100 mb-5'>
                                      {t("Download")}
                                    </button>
                                  </a>
                                </div>
                              ) : null}
                              {item?.isSigned != 1 ? (
                                <div className='text-end'>
                                  <button
                                    data-bs-target='#sign-submitted-popup1'
                                    data-bs-toggle='modal'
                                    className='btn btn-outline-primary rounded w-100 py-2 mb-0'
                                    onClick={() => setcontractId(item?.id)}
                                  >
                                    {t("Contract Sign")}
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              <NoDataFound />}
          </div>
        </div>
      </div>
      <div className="modal fade add-broker-modal" id="sign-submitted-popup1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-8">
            <div className="modal-header p-0">
              <h5 className="modal-title">{t("Tenant Signature")}</h5>{loading ? <Spinner animation='border' variant='primary' /> : null}
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body px-0 pb-0 pt-5">
              <div className="mb-5">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-5 position-relative">
                      <SignatureCanvas
                        ref={sigCanvas}
                        penColor='black'
                        canvasProps={{ height: 80, width: 320, className: 'sigCanvas' }}
                        onEnd={handleSignature} />
                    </div>
                    <p style={{ color: 'red' }}>{errormessage}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer px-0 py-0">
              <div className="d-flex flex-wrap justify-content-end">
                <button
                  className='btn btn-primary fw-bold px-10 py-3 me-4'
                  type='button'
                  data-bs-toggle='modal'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  {t("Cancel")}
                </button>{/*  */}
                <button className='btn btn-primary fw-bold px-10 py-3 me-4 mb-3' onClick={clearSignature}>
                  {t("Clear")}
                </button>{/*  */}
                <button className='btn btn-primary fw-bold px-10 py-3'
                  onClick={addSign}>
                  {t("Submit")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
        {/*  */}
      </p>
      <p ref={ref2} data-bs-target="#sign-submitted-popup1" data-bs-toggle="modal" data-bs-dismiss="modal">
        {/*  */}
      </p></>
  )
}
