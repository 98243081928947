import React, { useState, useEffect, useRef } from 'react'
import { getMasterData, getServiceRequestByData, vacateRequest } from '../../../../constants/axios/apis'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { PhotoViewPopup } from '../../preview/photoViewPopup'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'
        import uploadImg from '../../../../assets/images/file-upload.png'

export function RenewalNoticeVacat({ contract, setMessage }: any) {
  const [loading, setLoading] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [vacatingReasons, setVacatingReasons] = useState<any>()
  const [requestStatus, setRequestStatus] = useState<any>({
    data: {},
    status: false
  })
  const closeRef = useRef<any>()
  const failRef = useRef<any>()
  let history = useHistory()
  const { t } = useTranslation();

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldTouched('termination_letter')
  //   formik.setFieldValue('termination_letter', e.currentTarget.files[0])
  // }

  useEffect(() => {
    getMasterData().then(({ data }: any) => {
      setVacatingReasons(data?.find((item: any) => item.id === 13)?.master_data)
    })
  }, [])

  useEffect(() => {
    getServiceRequestByData(25, '', contract?.propertyID).then((response) => {
      if (response.data) {
        setRequestStatus({
          data: response.data[0],
          status: true
        })
      } else {
        setRequestStatus({ data: {}, status: false })
      }
    })
  }, [contract])

  const vacatingOptions = vacatingReasons?.map((option: any) => (
    <option key={option?.value} value={option?.value}>
      {option?.name}
    </option>
  ))

  const initialValues = {
    contract_id: contract?.id,
    vacate_reason: '',
    termination_letter: '',
    cateogry_id: 25,
  }

  const vacateRequestSchema = Yup.object().shape({
    vacate_reason: Yup.date().required(t("Please select a reason for vacating the property")),
    termination_letter: Yup.mixed()
      .required(t("Termination Letter is required"))
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf"), (value) => {
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'image/png' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t('The file is too large! Max allowed size is 5MB.'), (value) => {
        return value?.size <= 5242880
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: vacateRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        const data = new FormData()
        data.append('contract_id', values.contract_id)
        data.append('vacate_reason', values.vacate_reason)
        data.append('termination_letter', values.termination_letter)
        data.append('category_id', '25')

        vacateRequest(data)
          .then((response: any) => {
            setLoading(false)
            if (response.errorCode === 1) {
              setMessage(response.errorDescription)
              failRef.current.click()
            } else if (response.errorCode === 0) {
              setMessage(t("Your Vacate Request has been Submitted!"))
              closeRef.current.click()
              history.push('/my-services/openissues')
            } else {
              setMessage(t("Some error occured, please try again"))
              failRef.current.click()
            }
            resetForm()
            setUploadedFileName(null)
            formik.setFieldValue('termination_letter', null)
          })
          .catch((e) => {
            // setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {    
    if(e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf'){      
      inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)        
      formik.setFieldTouched('termination_letter')
      formik.setFieldValue('termination_letter', e.currentTarget.files[0])
    }else{
      setUploading(true)
      formik.setFieldError('termination_letter', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
            formik.setFieldTouched('termination_letter', true, false)
            formik.setFieldValue('termination_letter', compressResponse.file, false)
          } else {
            setUploadedFileName(null)
            formik.setFieldTouched('termination_letter', true, false)
            formik.setFieldValue('termination_letter', '', false)
            formik.setFieldError('termination_letter', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedFileName(null)
        formik.setFieldTouched('termination_letter')
        formik.setFieldValue('termination_letter', '', false)
        formik.setFieldError('termination_letter', t('File upload error!'))
        setUploading(false)
      }
    }
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <h4 className='card-title'>{t("Vacate")}</h4>
                </div>
              </div>
              {requestStatus?.status ? (
                <>
                  <div className='row form-add-design mt-10'>
                    <div className='col-12'>
                      <div className='row'>
                        <div className='col-md-6 col-lg-4 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Vacating Reason")}</label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              disabled
                              value={requestStatus?.data?.details?.reason}
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-lg-4 col-12'>
                          <div className='mb-10 upload-input'>
                            <label className='form-label fw-bolder d-block'>{t("Termination Letter")}</label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              disabled
                              value={Array.isArray(requestStatus?.data?.images?.termination_letter)
                                ? requestStatus?.data?.images?.termination_letter.length
                                : requestStatus?.data?.images?.termination_letter
                                  ? 1
                                  : 0}
                            />
                            <>
                              {requestStatus?.data?.images?.termination_letter &&
                                Array.isArray(requestStatus?.data?.images?.termination_letter) &&
                                requestStatus?.data?.images?.termination_letter.length ? (
                                <PhotoViewPopup images={requestStatus?.data?.images?.termination_letter} />
                              ) : ''}
                            </>
                            <>
                              {requestStatus?.data?.images?.termination_letter &&
                                !Array.isArray(requestStatus?.data?.images?.termination_letter) &&
                                requestStatus?.data?.images?.termination_letter.length ? (
                                <PhotoViewPopup images={[requestStatus?.data?.images?.termination_letter]} />
                              ) : ''}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='row form-add-design mt-10'>
                    <form
                      className='form fv-plugins-bootstrap5 fv-plugins-framework'
                      noValidate
                      id='kt_login_signup_form'
                      onSubmit={formik.handleSubmit}
                    >
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-md-6 col-lg-4 col-12'>
                            <div className='mb-10'>
                              <label className='form-label fw-bolder'>{t("Vacating Reason")}<span className='text-danger'>*</span></label>
                              <select
                                className='form-control form-select fw-bolder form-control-solid'
                                name='vacate_reason'
                                onChange={formik.handleChange}
                                value={formik.values.vacate_reason}
                                onBlur={formik.handleBlur}
                              >
                                <option value='' disabled>
                                  {t("Select")}
                                </option>
                                {vacatingReasons ? vacatingOptions : ''}
                              </select>
                              {formik.touched.vacate_reason && formik.errors.vacate_reason && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.vacate_reason}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-md-6 col-lg-4 col-12'>
                            <div className='mb-10 upload-input'>
                              <label className='form-label fw-bolder d-block'>{t("Termination Letter")}<span className='text-danger'>*</span></label>
                              <input
                                ref={inputRef}
                                onChange={(e) => handleDisplayFileDetails(e)}
                                className='d-none'
                                type='file'
                                // onBlur={formik.handleBlur}
                              />
                              <button
                                onClick={handleUpload}
                                type='button'
                                disabled={uploading}
                                className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                                  }`}
                              >
                                {/* <span>
                                  {uploadedFileName ? (
                                    uploadedFileName
                                  ) : (
                              <span  className="txt">
                                <img
                                        alt="File"
                                        src={uploadImg}
                                className="position-relative"
                                loading="lazy"/>
                                      {t("Upload")}{' '}
                                    </span>
                                  )}{' '}
                                </span> */}
                                {uploading ?
                            <span className='indicator-progress d-block'>
                              {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            : uploadedFileName ? (
                              uploadedFileName
                            ) : (
                              <span  className="txt">
                                <img
                                        alt="File"
                                        src={uploadImg}
                                className="position-relative"
                                loading="lazy"/>
                                {t("Upload")}{' '}
                              </span>
                            )}{' '}
                              </button>
                              {formik.touched.termination_letter && formik.errors.termination_letter && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.termination_letter}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-12 text-end'>
                            <button
                              type='submit'
                              className='btn btn-primary fw-bold px-10 py-3'
                              disabled={loading || uploading}
                            >
                              {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                              {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  {t("Please wait...")}{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}
