import React, { useState, useRef, FC, useEffect } from 'react';
import PlusIcon from "../../../../../../assets/images/plus-circle.svg";
import * as Yup from 'yup'
import { useFormik } from 'formik';
import completedStep from '../../../../../../assets/images/completed-step.png'
import moment from 'moment';
import { ViewPopup } from '../../../../../maintenance/ViewPopup';
import pdficon from '../../../../../../assets/images/pdf-file.png'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../../../constants/generics/fileUploadHandler';
import { Alert } from 'react-bootstrap-v5';

type Props = {
    setPermitDetails?: any
    stepStatus?: any
    ref1?: any
}

const today = new Date()
today.setHours(0, 0, 0, 0)

const PermitToWorkDetails: FC<Props> = ({ setPermitDetails, stepStatus, ref1 }) => {
    const { t } = useTranslation();
    const [uploading, setUploading] = useState<boolean>(false)
    const [inputBeingUploaded, setInputBeingUploaded] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const [viewImage, setViewImage] = useState<any>()

    const [uploadedFileName1, setUploadedFileName1] = useState<string | null>(null);
    const [uploadedFileName2, setUploadedFileName2] = useState<string | null>(null);
    const [uploadedFileName3, setUploadedFileName3] = useState<string | null>(null);
    const [uploadedFileName4, setUploadedFileName4] = useState<string | null>(null);

    const inputRef1 = useRef<HTMLInputElement>(null)
    const inputRef2 = useRef<HTMLInputElement>(null)
    const inputRef3 = useRef<HTMLInputElement>(null)
    const inputRef4 = useRef<HTMLInputElement>(null)
    const permitIssueRef = useRef<any>()
    const workStartRef = useRef<any>()
    const workEndRef = useRef<any>()

    const handleDisplayFileDetails = (reference: any, setUploadedFileName: any, fieldName: string) => {
        const inputFile = reference.current?.files?.[0]
        let isAllowedType: any = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(inputFile?.type)

        if (inputFile && isAllowedType) {
            if (inputFile.type === 'application/pdf') {
                if (inputFile.size > 5242880) {
                    setError(true)
                    setErrorMessage(t('The file is too large! Max allowed size is 5MB.'))
                    setTimeout(() => { setError(false) }, 3000)
                } else {
                    setUploadedFileName(inputFile.name)
                    formik.setFieldTouched(fieldName)
                    formik.setFieldValue(fieldName, inputFile)
                }
            } else {
                setUploading(true)
                setInputBeingUploaded(fieldName)
                setUploadedFileName()
                try {
                    validateAndCompressImage(inputFile).then((compressResponse) => {
                        if (compressResponse?.success) {
                            setUploadedFileName(inputFile.name)
                            formik.setFieldTouched(fieldName)
                            formik.setFieldValue(fieldName, inputFile)
                        } else {
                            setError(true)
                            setErrorMessage(t(compressResponse?.message || 'File compression error!'))
                            setTimeout(() => { setError(false) }, 3000)
                        }
                        setUploading(false)
                    })
                } catch (error) {
                    alert(t('File upload error!'))
                    setError(true)
                    setErrorMessage(t('File upload error!'))
                    setTimeout(() => { setError(false) }, 3000)
                    setUploading(false)
                }
            }
        } else {
            setError(true)
            setErrorMessage(t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'))
            setTimeout(() => {
                setError(false)
            }, 3000)
        }
    }

    const initialValues = {
        company_name: '',
        contact_name: '',
        contact_number: '',
        applicant_designation: '',
        exact_work_location: '',
        number_of_workers: '',
        permit_issue_date_time: '',
        work_start_date_time: '',
        work_end_date_time: '',
        description: '',
        undertaking_letter: '',
        trade_license: '',
        passport_copy: '',
        visa_copy: '',
    }

    const handelSubmit = () => {
        setSaved(false)
        setPermitDetails(null)
        formik.handleSubmit()
    }

    const permitDetailsSchema = Yup.object().shape({
        company_name: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Company name is required"))
            .test('filled', t("Company name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Company Name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        contact_name: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Contact name is required"))
            .test('filled', t("Contact name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Contact Name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        contact_number: Yup.string(),
        applicant_designation: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Applicant designation is required"))
            .test('filled', t("Applicant designation cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Applicant designation cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        exact_work_location: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Exact work location is required"))
            .test('filled', t("Exact work location cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Exact work location cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        number_of_workers: Yup.string().required(t("Number of workers is required")),
        permit_issue_date_time: Yup.date()
            .min(today, t("Permit Issue date cannot be in the past"))
            .required(t("Permit Issue date & time are required"))
            .nullable(),
        work_start_date_time: Yup.date()
            .min(today, t("Work start date cannot be in the past"))
            .required(t("Work start date & time are required"))
            .nullable(),
        work_end_date_time: Yup.date()
            .min(Yup.ref('work_start_date_time'), t("Work ending date cannot be before starting date"))
            .required(t("Work end date & time are required"))
            .nullable(),
        description: Yup.string().required(t("Please provide a description of work")),
        undertaking_letter: Yup.mixed()
            .required(t("Undertaking Letter is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        trade_license: Yup.mixed()
            .required(t("Trade License is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        passport_copy: Yup.mixed()
            .required(t("Passport Copy is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        visa_copy: Yup.mixed()
            .required(t("Visa Copy is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
    })

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: permitDetailsSchema,
        onSubmit: (values) => {
            setPermitDetails({
                company_name: values.company_name,
                contact_name: values.contact_name,
                contact_number: values.contact_number,
                applicant_designation: values.applicant_designation,
                exact_work_location: values.exact_work_location,
                number_of_workers: values.number_of_workers,
                permit_issue_date: moment(values.permit_issue_date_time).format('YYYY-MM-DD'),
                permit_issue_time: moment(values.permit_issue_date_time).format('HH:mm'),
                work_start_date: moment(values.work_start_date_time).format('YYYY-MM-DD'),
                work_start_time: moment(values.work_start_date_time).format('HH:mm'),
                work_end_date: moment(values.work_end_date_time).format('YYYY-MM-DD'),
                work_end_time: moment(values.work_end_date_time).format('HH:mm'),
                description: values.description,
                undertaking_letter: values.undertaking_letter,
                trade_license: values.trade_license,
                passport_copy: values.passport_copy,
                visa_copy: values.visa_copy,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref1.current.click()
        },
    })

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setPermitDetails((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    const handlePermitIssueDateTime = (e: any) => {
        formik.setFieldTouched('permit_issue_date_time')
        formik.setFieldValue('permit_issue_date_time', e.target.value)
    }

    const handleWorkStartDateTime = (e: any) => {
        formik.setFieldTouched('work_start_date_time')
        formik.setFieldValue('work_start_date_time', e.target.value)
    }

    const handleWorkEndDateTime = (e: any) => {
        formik.setFieldTouched('work_end_date_time')
        formik.setFieldValue('work_end_date_time', e.target.value)
    }

    const handleView = (data: any) => {
        setViewImage(data)
    }

    return (
        <div className="row">
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
            >
                <div className="col-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#permit-collapseone">
                                {t("Permit to Work Details")}
                                {(saved) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </h2>

                        <div id="permit-collapseone" className="accordion-collapse collapse" data-bs-parent="#accordion-step-five">
                            {stepStatus?.helipadAccessRequestStatus ? (
                                <>
                                    <div className="accordion-body pb-5 pt-0">
                                        <div className="row form-add-design">
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Company Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.helipadAccess?.details?.hra_company_name}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Contact Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.helipadAccess?.details?.hra_contact_name}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Contact Number")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.helipadAccess?.details?.hra_contact_number}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Applicant Designation")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.helipadAccess?.details?.hra_applicant_designation}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Exact Work Location")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.helipadAccess?.details?.hra_exact_work_location}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("No. of Workers")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.helipadAccess?.details?.hra_no_workers}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Permit Issue Date and Time")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={
                                                            moment(stepStatus?.data?.helipadAccess?.details?.hra_permit_date).format('DD-MM-YYYY')
                                                            + ' ' +
                                                            moment(stepStatus?.data?.helipadAccess?.details?.hra_permit_time, 'HH:mm').format('HH:mm a')
                                                        }
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Work Start Date and Time")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={
                                                            moment(stepStatus?.data?.helipadAccess?.details?.hra_work_start_date).format('DD-MM-YYYY')
                                                            + ' ' +
                                                            moment(stepStatus?.data?.helipadAccess?.details?.hra_work_start_time, 'HH:mm').format('HH:mm a')
                                                        }
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Work End Date and Time")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={
                                                            moment(stepStatus?.data?.helipadAccess?.details?.hra_work_end_date).format('DD-MM-YYYY')
                                                            + ' ' +
                                                            moment(stepStatus?.data?.helipadAccess?.details?.hra_work_end_time, 'HH:mm').format('HH:mm a')
                                                        }
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="mb-8">
                                                    <label className="form-label fw-bolder">{t("Complete Description of Work")}</label>
                                                    <textarea
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        data-kt-autosize='true'
                                                        rows={2}
                                                        value={stepStatus?.data?.helipadAccess?.details?.complete_description}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row documents-card">
                                                    <div className="col-lg-4 col-12">
                                                        <div className="mb-5 upload-input">
                                                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Undertaking Letter")} </label>
                                                            <div className='d-flex service-req-img align-items-center'>
                                                                {stepStatus?.data?.helipadAccess?.details?.images?.undertaking_letter && (
                                                                    <>
                                                                        <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                            data-bs-toggle='modal'
                                                                            data-bs-dismiss='modal'
                                                                            type='button'
                                                                            onClick={() => {
                                                                                handleView(stepStatus?.data?.helipadAccess?.details?.images?.undertaking_letter)
                                                                            }}>
                                                                            <img
                                                                                src={
                                                                                    stepStatus?.data?.helipadAccess?.details?.images?.undertaking_letter
                                                                                        .substr(
                                                                                            stepStatus?.data?.helipadAccess?.details?.images?.undertaking_letter.lastIndexOf('.')
                                                                                        ).includes('.pdf')
                                                                                        ? pdficon
                                                                                        : stepStatus?.data?.helipadAccess?.details?.images?.undertaking_letter
                                                                                }
                                                                                alt="document"
                                                                                className='img-fluid me-5'
                                                                            />
                                                                        </button><ViewPopup viewImage={viewImage} /></>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="mb-5 upload-input">
                                                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Trade License")} </label>
                                                            <div className='d-flex service-req-img align-items-center'>
                                                                {stepStatus?.data?.helipadAccess?.details?.images?.trade_license && (
                                                                    <>
                                                                        <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                            data-bs-toggle='modal'
                                                                            data-bs-dismiss='modal'
                                                                            type='button'
                                                                            onClick={() => {
                                                                                handleView(stepStatus?.data?.helipadAccess?.details?.images?.trade_license)
                                                                            }}>
                                                                            <img
                                                                                src={
                                                                                    stepStatus?.data?.helipadAccess?.details?.images?.trade_license
                                                                                        .substr(
                                                                                            stepStatus?.data?.helipadAccess?.details?.images?.trade_license.lastIndexOf('.')
                                                                                        ).includes('.pdf')
                                                                                        ? pdficon
                                                                                        : stepStatus?.data?.helipadAccess?.details?.images?.trade_license
                                                                                }
                                                                                alt="document"
                                                                                className='img-fluid me-5'
                                                                            />
                                                                        </button><ViewPopup viewImage={viewImage} /></>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="mb-5 upload-input">
                                                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Passport Copy")} </label>
                                                            <div className='d-flex service-req-img align-items-center'>
                                                                {stepStatus?.data?.helipadAccess?.details?.images?.passport_copy && (
                                                                    <>
                                                                        <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                            data-bs-toggle='modal'
                                                                            data-bs-dismiss='modal'
                                                                            type='button'
                                                                            onClick={() => {
                                                                                handleView(stepStatus?.data?.helipadAccess?.details?.images?.passport_copy)
                                                                            }}>
                                                                            <img
                                                                                src={
                                                                                    stepStatus?.data?.helipadAccess?.details?.images?.passport_copy
                                                                                        .substr(
                                                                                            stepStatus?.data?.helipadAccess?.details?.images?.passport_copy.lastIndexOf('.')
                                                                                        ).includes('.pdf')
                                                                                        ? pdficon
                                                                                        : stepStatus?.data?.helipadAccess?.details?.images?.passport_copy
                                                                                }
                                                                                alt="document"
                                                                                className='img-fluid me-5'
                                                                            />
                                                                        </button><ViewPopup viewImage={viewImage} /></>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="mb-4 upload-input">
                                                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Visa Copy")} </label>
                                                            <div className='d-flex service-req-img align-items-center'>
                                                                {stepStatus?.data?.helipadAccess?.details?.images?.visa_copy && (
                                                                    <>
                                                                        <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                            data-bs-toggle='modal'
                                                                            data-bs-dismiss='modal'
                                                                            type='button'
                                                                            onClick={() => {
                                                                                handleView(stepStatus?.data?.helipadAccess?.details?.images?.visa_copy)
                                                                            }}>
                                                                            <img
                                                                                src={
                                                                                    stepStatus?.data?.helipadAccess?.details?.images?.visa_copy
                                                                                        .substr(
                                                                                            stepStatus?.data?.helipadAccess?.details?.images?.visa_copy.lastIndexOf('.')
                                                                                        ).includes('.pdf')
                                                                                        ? pdficon
                                                                                        : stepStatus?.data?.helipadAccess?.details?.images?.visa_copy
                                                                                }
                                                                                alt="document"
                                                                                className='img-fluid me-5'
                                                                            />
                                                                        </button><ViewPopup viewImage={viewImage} /></>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="accordion-body pb-5 pt-0">
                                        <div className="row form-add-design">
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Company Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('company_name')}
                                                    />
                                                    {formik.touched.company_name && formik.errors.company_name && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.company_name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Contact Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('contact_name')}
                                                    />
                                                    {formik.touched.contact_name && formik.errors.contact_name && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.contact_name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Contact Number")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('contact_number')}
                                                    />
                                                    {formik.touched.contact_number && formik.errors.contact_number && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.contact_number}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Applicant Designation")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('applicant_designation')}
                                                    />
                                                    {formik.touched.applicant_designation && formik.errors.applicant_designation && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.applicant_designation}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Exact Work Location")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('exact_work_location')}
                                                    />
                                                    {formik.touched.exact_work_location && formik.errors.exact_work_location && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.exact_work_location}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("No. of Workers")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('number_of_workers')}
                                                    />
                                                    {formik.touched.number_of_workers && formik.errors.number_of_workers && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.number_of_workers}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Permit Issue Date and Time")} </label>
                                                    <input
                                                        ref={permitIssueRef}
                                                        onChange={(e) => handlePermitIssueDateTime(e)}
                                                        type="datetime-local"
                                                        className="form-control bg-white fw-bolder form-control-solid"
                                                        placeholder=""
                                                    />
                                                    {formik.touched.permit_issue_date_time && formik.errors.permit_issue_date_time && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.permit_issue_date_time}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Work Start Date and Time")} </label>
                                                    <input
                                                        ref={workStartRef}
                                                        onChange={(e) => handleWorkStartDateTime(e)}
                                                        type="datetime-local"
                                                        className="form-control bg-white fw-bolder form-control-solid"
                                                        placeholder=""
                                                    />
                                                    {formik.touched.work_start_date_time && formik.errors.work_start_date_time && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.work_start_date_time}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Work End Date and Time")} </label>
                                                    <input
                                                        ref={workEndRef}
                                                        onChange={(e) => handleWorkEndDateTime(e)}
                                                        type="datetime-local"
                                                        className="form-control bg-white fw-bolder form-control-solid"
                                                        placeholder=""
                                                    />
                                                    {formik.touched.work_end_date_time && formik.errors.work_end_date_time && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.work_end_date_time}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="mb-8">
                                                    <label className="form-label fw-bolder">{t("Complete Description of Work")}</label>
                                                    <textarea
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        data-kt-autosize='true'
                                                        name='description'
                                                        rows={2}
                                                        placeholder={t("Type here...")}
                                                        value={formik.values.description}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.description && formik.errors.description && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.description}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row documents-card">
                                                    {error ? (
                                                        <Alert className='font-14 fw-bold mb-8' variant='danger'>
                                                            {errorMessage}!
                                                        </Alert>
                                                    ) : null}
                                                    <div className="col-lg-4 col-12">
                                                        <div className="mb-5 upload-input">
                                                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Undertaking Letter")} </label>
                                                            <input
                                                                name="undertaking_letter"
                                                                ref={inputRef1}
                                                                className="d-none"
                                                                type="file"
                                                                onChange={() => handleDisplayFileDetails(inputRef1, setUploadedFileName1, 'undertaking_letter')}
                                                                onBlur={formik.handleBlur}
                                                                accept='.jpeg,.jpg,application/pdf'
                                                            />
                                                            <button type='button'
                                                                onClick={() => inputRef1.current?.click()}
                                                                className={
                                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName1 ? "primary active" : "primary"}`
                                                                }
                                                            >
                                                                <span className="pt-5">
                                                                    {(uploading && inputBeingUploaded === 'undertaking_letter') ?
                                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                        : uploadedFileName1 || uploadedFileName1 ? (
                                                                            uploadedFileName1 || ''
                                                                        ) : (
                                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                        )}
                                                                </span>
                                                            </button>
                                                            {formik.touched.undertaking_letter && formik.errors.undertaking_letter ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.undertaking_letter}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="mb-5 upload-input">
                                                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Trade License")} </label>
                                                            <input
                                                                name="trade_license"
                                                                ref={inputRef2}
                                                                className="d-none"
                                                                type="file"
                                                                onChange={() => handleDisplayFileDetails(inputRef2, setUploadedFileName2, 'trade_license')}
                                                                onBlur={formik.handleBlur}
                                                                accept='.jpeg,.jpg,application/pdf'
                                                            />
                                                            <button type='button'
                                                                onClick={() => inputRef2.current?.click()}
                                                                className={
                                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName2 ? "primary active" : "primary"}`
                                                                }
                                                            >
                                                                <span className="pt-5">
                                                                    {(uploading && inputBeingUploaded === 'trade_license') ?
                                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                        : uploadedFileName2 || uploadedFileName2 ? (
                                                                            uploadedFileName2 || ''
                                                                        ) : (
                                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                        )}
                                                                </span>
                                                            </button>
                                                            {formik.touched.trade_license && formik.errors.trade_license ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.trade_license}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="mb-5 upload-input">
                                                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Passport Copy")} </label>
                                                            <input
                                                                name="passport_copy"
                                                                ref={inputRef3}
                                                                className="d-none"
                                                                type="file"
                                                                onChange={() => handleDisplayFileDetails(inputRef3, setUploadedFileName3, 'passport_copy')}
                                                                onBlur={formik.handleBlur}
                                                                accept='.jpeg,.jpg,application/pdf'
                                                            />
                                                            <button type='button'
                                                                onClick={() => inputRef3.current?.click()}
                                                                className={
                                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName3 ? "primary active" : "primary"}`
                                                                }
                                                            >
                                                                <span className="pt-5">
                                                                    {(uploading && inputBeingUploaded === 'passport_copy') ?
                                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                        : uploadedFileName3 || uploadedFileName3 ? (
                                                                            uploadedFileName3 || ''
                                                                        ) : (
                                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                        )}
                                                                </span>
                                                            </button>
                                                            {formik.touched.passport_copy && formik.errors.passport_copy ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.passport_copy}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-12">
                                                        <div className="mb-4 upload-input">
                                                            <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("Visa Copy")} </label>
                                                            <input
                                                                name="visa_copy"
                                                                ref={inputRef4}
                                                                className="d-none"
                                                                type="file"
                                                                onChange={() => handleDisplayFileDetails(inputRef4, setUploadedFileName4, 'visa_copy')}
                                                                onBlur={formik.handleBlur}
                                                                accept='.jpeg,.jpg,application/pdf'
                                                            />
                                                            <button type='button'
                                                                onClick={() => inputRef4.current?.click()}
                                                                className={
                                                                    `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName4 ? "primary active" : "primary"}`
                                                                }
                                                            >
                                                                <span className="pt-5">
                                                                    {(uploading && inputBeingUploaded === 'visa_copy') ?
                                                                        <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                        : uploadedFileName4 || uploadedFileName4 ? (
                                                                            uploadedFileName4 || ''
                                                                        ) : (
                                                                            <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                        )}
                                                                </span>
                                                            </button>
                                                            {formik.touched.visa_copy && formik.errors.visa_copy ? (
                                                                <div className='d-flex flex-column text-danger'>
                                                                    <small className="text-danger">{formik.errors.visa_copy}</small>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 text-right">
                                                <div className="row justify-content-end">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end">
                                                            <button type='button' onClick={handelSubmit} disabled={!dirty || uploading} className='btn btn-primary fw-bold px-10 py-3'>
                                                                {saved ? t("Saved") : t("Save")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export { PermitToWorkDetails }
