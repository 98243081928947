import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import LeftArrow from '../../../assets/images/Left_Arrow.png'
import Home from '../../assets/images/my-unit.png'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import { getAllotedParkingSlots } from '../../../constants/axios/apis'
import Map from "./Property/Map";
import FloorPlant from '../../../assets/images/floor-plan.png';

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function MyProperty({ contract }: any) {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  let userRole: number = 0
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }
  const location = useLocation()
  const [parkingSlotsCount, setParkingSlotsCount] = useState<any>()
  const { t } = useTranslation();
  const [mapFloor, setMapFloor] = useState<any>('location')

  let unitDetail = localStorage.getItem('unitDetail')

  useEffect(() => {
    if (contract) {
      getAllotedParkingSlots(contract?.propertyID).then(({ data: slots }: any) => {
        if (slots) {
          setParkingSlotsCount(slots.length)
        } else {
          setParkingSlotsCount(0)
        }
      })
    }
  }, [contract])

  return (
    <div className='com-card-section'>
      {userRole === 7 && location.pathname === '/my-services/contract-renewal' ? (
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='row'>
                  <div className='col-md-4 col-lg-3'>
                    <div className='approval-move'>
                      {/* <img src={Home} alt='Home' className='img-fluid' /> */}
                      <img src={contract?.communityLogo} alt='Home' className='img-fluid' />
                    </div>
                  </div>
                  <div className='col-md-8 col-lg-9'>
                    <div className='card lease-card bg-white rounded shadow-none'>
                      <div className='card-body px-0 pt-0 pb-2'>

                        <div className='row align-items-top mb-7'>
                          <div className='col-md-6'>
                            <h4> {t("Lease Number")} : {contract?.contractNo} </h4>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <div className=''>
                              <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                {' '}
                                {t("Lease Start Date")}
                              </h6>
                              <span className='text-black fw-bold font-14'>
                                {' '}
                                {moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
                              </span>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease End Date")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {t("No of Installments")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {contract?.noOfPayments}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Status")} </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {t(contract?.contractStatus)}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Annual Rent")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Security Deposit")}
                            </h6>
                            <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : userRole === 15 && location.pathname === '/my-services/contract-renewal' ? (
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/my-services/openissues'} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              </Link>
              {t("Contract Renewal")}
            </h4>
          </div>
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='row'>
                  <div className='col-md-4 col-lg-3 MB-4'>
                    <div className='approval-move'>
                      <img src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage} alt='Home' className='img-fluid' />
                    </div>
                  </div>
                  <div className='col-md-8 col-lg-9'>
                    <div className='card lease-card bg-white rounded shadow-none'>
                      <div className='card-body px-0 pt-0 pb-2'>
                        <div className='row align-items-baseline'>
                          <div className='col-md-12'>
                            <a href={contract?.contractFile}
                            className='text-decoration-none text-primary fw-bold view-all font-14 text-black'
                            target='_blank'
                            rel='noreferrer'
                            >
                            <span> {t("View Agreement")} </span>
                          </a>
                        </div>
                      </div>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease Number")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {contract?.contractNo}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <div className=''>
                              <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                {' '}
                                {t("Lease Start Date")}
                              </h6>
                              <span className='text-black fw-bold font-14'>
                                {' '}
                                {moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
                              </span>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease End Date")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Documents")} </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {contract?.documentCount}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Annual Rent")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Security Deposit")}
                            </h6>
                            <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='row'>          
          <div className='col-7'>
            <div className='card mb-5'>
              <div className='card-body p-6'>
                <div className="row">
                  {/* <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                      <h6 className="text-theme-gray mb-3 fw-normal font-13">Unit Number</h6>
                      <span className="text-black fw-bold font-14"> 00330 </span>
                  </div> */}
                  {/* <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-5">
                      <h6 className="text-theme-gray mb-3 fw-normal font-13">Percentage</h6>
                      <span className="text-black fw-bold font-14">20%</span>
                  </div> */}
                  <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                      <h6 className="text-theme-gray mb-3 fw-normal font-13"> No. of Bedrooms</h6>
                      <span className="text-black fw-bold font-14"> 3 </span>
                  </div>
                  {unitDetail !== 'Under Construction' ?
                   <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                      <h6 className="text-theme-gray mb-3 fw-normal font-13"> No. of Bathrooms</h6>
                      <span className="text-black fw-bold font-14"> 4 </span>
                  </div> 
                  : '' }
                  <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                      <h6 className="text-theme-gray mb-3 fw-normal font-13"> Plot Area (sqmt)</h6>
                      <span className="text-black fw-bold font-14"> 325 </span>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                      <h6 className="text-theme-gray mb-3 fw-normal font-13">No. of Parking </h6>
                      <span className="text-black fw-bold font-14"> 1 </span>
                  </div> 
                  <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                      <h6 className="text-theme-gray mb-3 fw-normal font-13">Furniture Status </h6>
                      <span className="text-black fw-bold font-14"> Semi Furnished </span>
                  </div> 
                  {unitDetail === 'Under Construction' ?
                  <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                      <h6 className="text-theme-gray mb-3 fw-normal font-13">Estimated Completion Date</h6>
                      <span className="text-black fw-bold font-14">25-10-2024 </span>
                  </div>
                  : '' }
                  <div className="col-md-4 col-sm-6 col-xl-4 col-12 mb-7">
                      <h6 className="text-theme-gray mb-3 fw-normal font-13">
                        {unitDetail === 'Under Construction' ? 'Estimated Total Amount' : 'Total Amount' }</h6>
                      <span className="text-black fw-bold font-14"> AED 9,750,000 </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='card mb-5'>
              <div className='card-body p-6'>
                  <h5 className='mb-5'>Amenities</h5>
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-4">
                      {/* <h6 className="text-theme-gray mb-3 fw-normal font-13">Gym </h6> */}
                      <span className="text-black fw-bold font-13"> Gym </span>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-4">
                      {/* <h6 className="text-theme-gray mb-3 fw-normal font-13"> </h6> */}
                      <span className="text-black fw-bold font-13"> Swimming Pool </span>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-4">
                      {/* <h6 className="text-theme-gray mb-3 fw-normal font-13"></h6> */}
                      <span className="text-black fw-bold font-13"> Concierge </span>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-4">
                      {/* <h6 className="text-theme-gray mb-3 fw-normal font-13"></h6> */}
                      <span className="text-black fw-bold font-13"> Private Parking  </span>
                  </div> 
                  <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-4">
                      {/* <h6 className="text-theme-gray mb-3 fw-normal font-13"> </h6> */}
                      <span className="text-black fw-bold font-13"> Pets Allowed </span>
                  </div> 
                  <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-4">
                      {/* <h6 className="text-theme-gray mb-3 fw-normal font-13"></h6> */}
                      <span className="text-black fw-bold font-13"> Central AC  </span>
                  </div> 
                  <div className="col-md-4 col-sm-6 col-xl-3 col-12 mb-4">
                      {/* <h6 className="text-theme-gray mb-3 fw-normal font-13"></h6> */}
                      <span className="text-black fw-bold font-13">Built in Wardrobes </span>
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className='col-5'>
            <div className='card mb-5'>
              <div className='card-body p-5'>
                <div className="access-cd-tabs mb-4">
                  <div className='d-flex'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                        <li className='nav-item'>
                          <Link to className={`me-0 nav-link position-relative text-active-primary ${mapFloor === 'location' ? 'active' : ''}`} onClick={() => setMapFloor('location') }> {t('Near by Location')} </Link>
                        </li>
                        <li className='nav-item'>
                          <Link to className={`me-0 nav-link position-relative text-active-primary ${mapFloor === 'floor' ? 'active' : ''}`} onClick={() => setMapFloor('floor') }> {t('Floor Plan')} </Link>
                        </li>                                  
                    </ul>
                  </div>
                </div>
                { mapFloor === 'location' ?
                  <div className="near-by-loaction mb-30px">
                    <div className="near-by-location-main-div">
                      <Map />
                    </div>
                </div>
                : 
                <div className=''>
                  <img src={FloorPlant} alt='floorplant' className='w-100'/>
                </div>
                }
              </div>
            </div>
          </div>
          { unitDetail === 'Under Construction' ?
          <div className='col-12'>
              <div className="card">
                <div className='card-body'>
                  <div className='table-responsive'>
                    <table className='w-100' cellPadding="5">
                      <tr>
                        <th>No</th>
                        <th>Completion (%)</th>
                        <th>Due Date</th>
                        <th>Actual Date</th>
                        <th>Amount (AED)</th>
                        <th>Status</th>
                        <th>Documents</th>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>25</td>
                        <td>05-04-2023</td>
                        <td>12-04-2023</td>
                        <td>2,473,500</td>
                        <td className='text-success'>Completed</td>
                        <td style={{'width':'auto'}}>
                          <Link target="_blank" className="text-decoration-none" to="https://api.realcube.estate/files/myDocument/visa/16830243833310.jpg">Download</Link>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>50</td>
                        <td>20-07-2023</td>
                        <td>28-07-2023</td>
                        <td>3,947,000</td>
                        <td className='text-success'>Completed</td>
                        <td style={{'width':'auto'}}>
                          <Link target="_blank" className="text-decoration-none" to="https://api.realcube.estate/files/myDocument/visa/16830243833310.jpg">Download</Link>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>75</td>
                        <td>15-05-2024</td>
                        <td>-</td>
                        <td>3,329,500</td>
                        <td className='text-warning'>In Progress</td>
                        <td style={{'width':'auto'}}>
                          <Link target="_blank" className="text-decoration-none" to="https://api.realcube.estate/files/myDocument/visa/16830243833310.jpg">Download</Link>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>100</td>
                        <td>25-10-2024</td>
                        <td>-</td>
                        <td>9,750,000</td>
                        <td>-</td>
                        <td style={{'width':'auto'}}>
                          <Link target="_blank" className="text-decoration-none" to="https://api.realcube.estate/files/myDocument/visa/16830243833310.jpg">Download</Link>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
          </div>
          : '' }

        </div>
      )}
    </div>
  )
}
