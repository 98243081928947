import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import CheckGreen from "../../assets/images/Check_green_lg.png";
import closeRed from "../../assets/images/error.png";
import { RootState } from '../../constants';
import { CustomLoader1 } from '../../constants/generics/customActios';
import { useTranslation } from 'react-i18next';

export const DynamicPopup: React.FC<any> = ({ message }) => {
    const { t } = useTranslation()
    let datalist: any
    datalist = useSelector<RootState>(({ PTOffer }) => PTOffer.modalmessage)
    return (
        <div className="modal fade custom-modal" id="dynamic-popup">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header bg-white border-0">
                        <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                    </div>
                    <div className="modal-body py-10">
                        <div className="row g-5">
                            <div className="col-md-12">
                                <div className="text-center mx-md-12">
                                    <div className="redeem-offer-QR">
                                        {datalist?.error === 0 ? <><img src={CheckGreen} alt="tick-img" className="img-fluid mb-5" />
                                            <h1 className="text-theme font-24 mb-2 fw-bold">{datalist?.message}</h1></> :
                                            <> <img src={closeRed} alt="tick-img" className="img-fluid mb-5" />
                                                <h1 className="text-theme font-24 mb-2 fw-bold">{datalist?.message}</h1></>}
                                        {datalist?.waiting ?
                                            <div>
                                                <span className='indicator-progress d-block'>
                                                    {t('Please wait...')}{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}