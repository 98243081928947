import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

export function Activity() {
    const { t } = useTranslation();

    return (
        <div className='service-request-activity rounded p-5'>
            <div className="wrapper-progressBar">
                <ul className="progressBar">
                    <li className="text-theme-gray">{t("Request")} <br/> {t("Submitted")}</li>
                    <li className="active">{t("Assigned")} <br/> {t("Technician")}</li>
                    <li className="text-theme-gray">{t("Job")} <br/> {t("Scheduled")}</li>
                    <li className="text-theme-gray">{t("Job")} <br/> {t("Started")}</li>
                    <li className="text-theme-gray">{t("Job")} <br/> {t("On Hold")}</li>
                    <li className="text-theme-gray">{t("Assigned")} <br/> {t("Completed")}</li>
                </ul>
            </div>
        </div>
    )
}
