import React, { useState, FC, useEffect } from 'react';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import completedStep from '../../../../../../assets/images/completed-step.png'
import { useTranslation } from 'react-i18next'

type Props = {
    setApplicantInformation?: any
    stepStatus?: any
    ref1?: any
}

const ApplicantsInformation: FC<Props> = ({ setApplicantInformation, stepStatus, ref1 }) => {

    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const { t } = useTranslation();

    const initialValues = {
        building_name: '',
        unit_number: '',
        applicant_name: '',
        applicant_company_name: '',
        applicant_contact_number: '',
        sub_contractor: '',
    }

    const handelSubmit = () => {
        setSaved(false)
        setApplicantInformation(null)
        formik.handleSubmit()
    }

    const applicantInformationSchema = Yup.object().shape({
        building_name: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Building name is required"))
            .test('filled', t("Building name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Building Name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        unit_number: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required('Unit Number is required')
            .test('filled', t("Unit Number of receiver cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Unit Number cannot start with a blankspace"))
            .matches(/^[a-zA-Z0-9]*$/, t("Only alphabets & numbers allowed")),
        applicant_name: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Applicant Name is required"))
            .test('filled', t("Applicant Name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Applicant Name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        applicant_company_name: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Applicant Company name is required"))
            .test('filled', t("Applicant Company name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Applicant Company name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    
        applicant_contact_number: Yup.string(),
    
        sub_contractor: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .test('filled', t("Sub-Contractor Name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Sub-Contractor Name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    })

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: applicantInformationSchema,
        onSubmit: (values) => {
            setApplicantInformation({
                building_name: values.building_name,
                unit_number: values.unit_number,
                applicant_name: values.applicant_name,
                applicant_company_name: values.applicant_company_name,
                applicant_contact_number: values.applicant_contact_number,
                sub_contractor: values.sub_contractor,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref1.current.click()
        },
    })

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setApplicantInformation((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    return (
        <div className="row">
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
            >
                <div className="col-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#applicants-information">
                                {t("Applicant's Information")}
                                {(saved) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </h2>
                        <div id="applicants-information" className="accordion-collapse collapse" data-bs-parent="#accordion-step-five">
                            {stepStatus?.permitToWorkRequestStatus ? (
                                <>
                                    <div className="accordion-body pb-5 pt-0">
                                        <div className="row form-add-design">
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder">  {t("Building Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.permitToWork?.details?.building_name}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Unit Number")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.permitToWork?.details?.unit_number}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Applicant Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.permitToWork?.details?.applicant_name}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Applicant's Company Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.permitToWork?.details?.applicant_company_name}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Applicant's Contact Number")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.permitToWork?.details?.applicant_contact_number}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder">{t("Sub-Contractor (if any)")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.permitToWork?.details?.sub_contractor}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="accordion-body pb-5 pt-0">
                                        <div className="row form-add-design">
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder">  {t("Building Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('building_name')}
                                                    />
                                                    {formik.touched.building_name && formik.errors.building_name && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.building_name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Unit Number")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('unit_number')}
                                                    />
                                                    {formik.touched.unit_number && formik.errors.unit_number && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.unit_number}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Applicant Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('applicant_name')}
                                                    />
                                                    {formik.touched.applicant_name && formik.errors.applicant_name && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.applicant_name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Applicant's Company Name")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('applicant_company_name')}
                                                    />
                                                    {formik.touched.applicant_company_name && formik.errors.applicant_company_name && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.applicant_company_name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Applicant's Contact Number")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('applicant_contact_number')}
                                                    />
                                                    {formik.touched.applicant_contact_number && formik.errors.applicant_contact_number && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.applicant_contact_number}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder">{t("Sub-Contractor (if any)")} </label>
                                                    <input
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        {...formik.getFieldProps('sub_contractor')}
                                                    />
                                                    {formik.touched.sub_contractor && formik.errors.sub_contractor && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.sub_contractor}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 text-right">
                                                <div className="row justify-content-end">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end">
                                                            <button type='button' onClick={handelSubmit} disabled={!dirty} className='btn btn-primary fw-bold px-10 py-3'>
                                                                {saved ? t("Saved") : t("Save")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export { ApplicantsInformation }
