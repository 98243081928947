import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {TDetails} from './TDetails'
import {AddressDetails} from'./AddressDetails';

export function TenantsTab() {
    return (
        <div className="">
            <div className="row">
                <div className='col-12'>
                    <div className='mb-5'>
                        <h5 className='className="modal-title fw-bold font-18 cpt-headding'>
                            Tenant Details
                        </h5>
                    </div>
                </div> 
                <div className="col-12 cpt-tenant-det">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item tab_nav_li" role="presentation">
                            <button className="nav-link active px-5 py-5 w-100 fw-bolder" id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#card-payment" type="button" role="tab" aria-controls="pills-home"
                                aria-selected="true">                                
                                Tenant Details
                            </button>
                        </li>
                        <li className="nav-item tab_nav_li" role="presentation">
                            <button className="nav-link px-5 py-5 w-100 fw-bolder" id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#bank-transfer" type="button" role="tab" aria-controls="pills-profile"
                                aria-selected="false">
                                Address Details
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="card-payment" role="tabpanel" aria-labelledby="pills-home-tab">
                           <TDetails />
                        </div>
                        <div className="tab-pane fade" id="bank-transfer" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <AddressDetails />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}