import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { getConfig } from '../../../../constants/axios/apis'

export function RenewalInformation({ contract }: any) {
  const [renewalAmount, setRenewalAmount] = useState<any>()
  const [renewalPercentage, setRenewalPercentage] = useState<any>()

  let startingDate: any = new Date(contract?.contractStartDate)
  startingDate.setFullYear(startingDate.getFullYear() + 1)
  const start_date = moment(startingDate).format('DD-MM-YYYY')

  let endingDate: any = new Date(moment(start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'))
  endingDate.setFullYear(endingDate.getFullYear() + 1)
  endingDate.setDate(endingDate.getDate() - 1)
  const end_date = moment(endingDate).format('DD-MM-YYYY')

  useEffect(() => {
    getConfig().then(({ data }: any) => {
      setRenewalPercentage(
        data?.find((item: any) => item.key == 'renewal_percentage_increase').value
      )
    })
  }, [])

  useEffect(() => {
    if (renewalPercentage > 0) {
      let amount = (renewalPercentage / 100) * contract?.rent + contract?.rent
      setRenewalAmount(amount)
    }
  }, [renewalPercentage, contract])

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-8'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <h4 className='card-title mb-7'>Renewal Information</h4>
                </div>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-md-4 col-lg-3 col-12 mb-4 mb-md-0'>
                      <div className=''>
                        <h6 className='text-theme-gray mb-2 fw-bold'> Lease Expire </h6>
                        <span className='fw-bolder'> {moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '} </span>
                      </div>
                    </div>
                    <div className='col-md-4 col-lg-3 col-12 mb-4 mb-md-0'>
                      <h6 className='text-theme-gray mb-2 fw-bold'> Renewal Period </h6>
                      <span className='fw-bolder'>
                        {' '}
                        {start_date} to {end_date}{' '}
                      </span>
                    </div>
                    <div className='col-md-4 col-lg-3 col-12 mb-4 mb-md-0'>
                      <h6 className='text-theme-gray mb-2 fw-bold'> No of Installments </h6>
                      <span className='fw-bolder'> {contract?.noOfPayments} </span>
                    </div>
                    <div className='col-md-4 col-lg-3 col-12'>
                      <h6 className='text-theme-gray mb-2 fw-bold'> New Annual Amount </h6>
                      <span className='fw-bolder'> AED {renewalAmount?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
