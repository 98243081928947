import React, { useState, useRef, FC, useEffect } from 'react';
import PlusIcon from "../../../../../../assets/images/plus-circle.svg";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ViewPopup } from '../../../../../maintenance/ViewPopup';
import pdficon from '../../../../../../assets/images/pdf-file.png'
import completedStep from '../../../../../../assets/images/completed-step.png'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../../../constants/generics/fileUploadHandler';
import { Alert } from 'react-bootstrap-v5';

type Props = {
    contract?: any
    setDocuments?: any
    stepStatus?: any
    getStepStatus?: any
}

const DocumentsUpload: FC<Props> = ({ contract, setDocuments, stepStatus }) => {
    const { t } = useTranslation();
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    const [inputBeingUploaded, setInputBeingUploaded] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [viewImage, setViewImage] = useState<any>()
    const [uploadedFileName1, setUploadedFileName1] = useState<string | null>(null);
    const [uploadedFileName2, setUploadedFileName2] = useState<string | null>(null);

    const inputRef1 = useRef<HTMLInputElement>(null)
    const inputRef2 = useRef<HTMLInputElement>(null)

    const handleDisplayFileDetails = (reference: any, setUploadedFileName: any, fieldName: string) => {
        const inputFile = reference.current?.files?.[0]
        let isAllowedType: any = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(inputFile?.type)

        if (inputFile && isAllowedType) {
            if (inputFile.type === 'application/pdf') {
                if (inputFile.size > 5242880) {
                    setError(true)
                    setErrorMessage(t('The file is too large! Max allowed size is 5MB.'))
                    setTimeout(() => { setError(false) }, 3000)
                } else {
                    setUploadedFileName(inputFile.name)
                    formik.setFieldTouched(fieldName)
                    formik.setFieldValue(fieldName, inputFile)
                }
            } else {
                setUploading(true)
                setInputBeingUploaded(fieldName)
                setUploadedFileName()
                try {
                    validateAndCompressImage(inputFile).then((compressResponse) => {
                        if (compressResponse?.success) {
                            setUploadedFileName(inputFile.name)
                            formik.setFieldTouched(fieldName)
                            formik.setFieldValue(fieldName, inputFile)
                        } else {
                            setError(true)
                            setErrorMessage(t(compressResponse?.message || 'File compression error!'))
                            setTimeout(() => { setError(false) }, 3000)
                        }
                        setUploading(false)
                    })
                } catch (error) {
                    alert(t('File upload error!'))
                    setError(true)
                    setErrorMessage(t('File upload error!'))
                    setTimeout(() => { setError(false) }, 3000)
                    setUploading(false)
                }
            }
        } else {
            setError(true)
            setErrorMessage(t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'))
            setTimeout(() => {
                setError(false)
            }, 3000)
        }
    }

    const initialValues = {
        contract_id: contract?.id,
        property_id: contract?.propertyID,
        category_id: '',
        adcd_certificate: '',
        addc_certificate: '',
    }

    const handelSubmit = () => {
        setSaved(false)
        setDocuments(null)
        formik.handleSubmit()
    }

    const documentVerficationSchema = Yup.object().shape({
        adcd_certificate: Yup.mixed()
            .required(t("ADCD Completion Certificate is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        addc_certificate: Yup.mixed()
            .required(t("ADDC Inspection Certificate"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
    })

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: documentVerficationSchema,
        onSubmit: (values) => {
            setDocuments({
                adcd_certificate: values.adcd_certificate,
                addc_certificate: values.addc_certificate,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
        },
    })

    const handleView = (data: any) => {
        setViewImage(data)
    }

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setDocuments((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    return (
        <div className="com-card-section">
            <div className="row documents-card form-add-design pt-6">
                <div className="col-12">
                    <form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_login_signup_form'
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="row mb-6">
                            <div className="col-12">
                                <p className="font-14 fw-normal mb-6">
                                    {t("Documents to Upload")}
                                    {(saved) &&
                                        <span className='completed-step ps-3 pe-3 d-inline-block'>
                                            <img src={completedStep} alt='complete-step' />
                                        </span>}
                                </p>
                            </div>

                            {error ? (
                                <Alert className='font-14 fw-bold mb-8' variant='danger'>
                                    {errorMessage}!
                                </Alert>
                            ) : null}

                            {stepStatus?.status ? (
                                <>
                                    <div className="col-md-12 mb-5">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADCD Completion Certificate")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.permitToWork?.images?.adcd_completion && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.permitToWork?.images?.adcd_completion)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.permitToWork?.images?.adcd_completion
                                                                                .substr(
                                                                                    stepStatus?.data?.permitToWork?.images?.adcd_completion.lastIndexOf('.')
                                                                                ).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.permitToWork?.images?.adcd_completion
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADDC Inspection Certificate")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.permitToWork?.images?.addc_inspection && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.permitToWork?.images?.addc_inspection)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.permitToWork?.images?.addc_inspection
                                                                                .substr(
                                                                                    stepStatus?.data?.permitToWork?.images?.addc_inspection.lastIndexOf('.')
                                                                                ).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.permitToWork?.images?.addc_inspection
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button><ViewPopup viewImage={viewImage} /></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-md-12 mb-5">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADCD Completion Certificate")} </label>
                                                    <input
                                                        name="adcd_certificate"
                                                        ref={inputRef1}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef1, setUploadedFileName1, 'adcd_certificate')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef1.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName1 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'adcd_certificate') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName1 || uploadedFileName1 ? (
                                                                    uploadedFileName1 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.adcd_certificate && formik.errors.adcd_certificate ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.adcd_certificate}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADDC Inspection Certificate")} </label>
                                                    <input
                                                        name="addc_certificate"
                                                        ref={inputRef2}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef2, setUploadedFileName2, 'addc_certificate')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef2.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName2 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'addc_certificate') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName2 || uploadedFileName2 ? (
                                                                    uploadedFileName2 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.addc_certificate && formik.errors.addc_certificate ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.addc_certificate}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-right">
                                            <div className="row justify-content-end">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-end">
                                                        <button type='button' onClick={handelSubmit} disabled={!dirty || uploading} className='btn btn-primary fw-bold px-10 py-3'>
                                                            {saved ? t("Saved") : t("Save")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="card permit-work-card rounded-10 border-0" data-bs-target="#final-certificate-modal" data-bs-toggle="modal">
                                            <div className="card-body py-3">
                                                <div className="row h-100 align-items-center">
                                                    <div className="col-lg-10 col-10">
                                                        <h5 className="text-black font-14 fw-bolder mb-0">
                                                            {t("Final Completion Certificate")}
                                                        </h5>
                                                    </div>
                                                    <div className="col-lg-2 col-2 text-end">
                                                        <div className="">
                                                            <i className="bi bi-chevron-right text-black font-15 fw-boldest"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export { DocumentsUpload }
