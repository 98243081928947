import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../components/layout/core'
import {ContractsPage} from './Components/ContractsPage'
import {UninvoicesList} from './Components/Uninvoiced/Uninvoiced'
import {InvoicesList} from './Components/Invoiced/Invoiced'
import {PropertyDetails} from './Components/PropertyDetails/PropertyDetails'
import {RequestSuccessfulPopup} from '../../../SuccessfulPopup/RequestSuccessfulPopup'

import {Redirect, Route, Switch} from 'react-router-dom'

const Contracts: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
        <div className='col-xxl-12 mt-0'>
            <div className="com-card-section">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body p-7">
                                <div className="row">
                                    <div className="col-12">
                                        <Switch>
                                            <Route path='/contracts/uninvoiced'>
                                                <ContractsPage />
                                                <UninvoicesList />
                                            </Route>
                                        </Switch>
                                        <Switch>
                                            <Route path='/contracts/invoiced'>
                                                <ContractsPage />
                                                <InvoicesList />
                                            </Route>
                                        </Switch>
                                        <Switch>
                                            <Route path='/property-details'>
                                                <PropertyDetails />
                                                <RequestSuccessfulPopup />
                                            </Route>
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
)

const ContractsWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <Contracts />
        </>
    )
}

export {ContractsWrapper}
