import React, { FC } from 'react'
import { PageTitle } from '../../components/layout/core'
import { MyTeamList } from './components/MyTeamList/MyTeamList';
import { RequestSuccessfulPopup } from '../SuccessfulPopup/RequestSuccessfulPopup';
import { Route, Switch } from 'react-router-dom'
import { AddTeam } from './add/AddMember/AddTeam';
import { MonthReport } from './components/Reports/MonthReport';
import { DayReport } from './components/Reports/DayReport';
import { YearReport } from './components/Reports/YearReport';
import { ReportsList } from './components/ReportsList/ReportsList';

const MyTeamPage: FC = () => {

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                        <Route path='/ct-my-team/day'>
                            <ReportsList />
                            {/* <DayReport /> */}
                            <MyTeamList />
                        </Route>
                        <Route path='/ct-my-team/month'>
                            <ReportsList />
                            {/* <MonthReport /> */}
                            <MyTeamList />
                        </Route>
                        <Route path='/ct-my-team/year'>
                            <ReportsList />
                            {/* <YearReport /> */}
                            <MyTeamList />
                        </Route>
                        <Route path='/ct-my-team/add'>
                            <AddTeam />
                            <RequestSuccessfulPopup />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    )
}

const CTMyTeamWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}> Announcements </PageTitle>
            <MyTeamPage />
        </>
    )
};

export { CTMyTeamWrapper }

