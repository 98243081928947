import React, { FC, useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { Button, Dropdown, Menu, Space } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Redirect, Route, Switch } from 'react-router-dom'
import TableData from './TableData';

const Leads = () => {
    const { t } = useTranslation()
    const history = useHistory();
    const menu = (
        <Menu className='custom-antdropdown-menu'>
            <Menu.Item key="1">
                <Link to='/leads/edit'>Edit</Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Link to='/leads/view'>View</Link>
            </Menu.Item>
        </Menu>
    );
    const columns = [
        {
            field: 'sno',
            headerName: t('S.No'),
            width: 50,
        },
        {
            field: 'referenceid',
            headerName: t('Reference ID'),
            width: 160,
        },
        {
            field: 'customername',
            headerName: t('Customer Name'),
            width: 150,
        },
        {
            field: 'brokername',
            headerName: t('Broker Name'),
            width: 150,
        },
        {
            field: 'mobileno',
            headerName: t('Mobile No'),
            width: 150,
        },
        {
            field: 'enquirydate',
            headerName: t('Enquiry Date'),
            width: 150,
        },
        {
            field: 'status',
            headerName: t('Status'),
            width: 130,
            renderCell: (params: any) => {
                return (
                    <span className={params.value === 'Active' ? 'text-success' : params.value === 'InActive' ? 'text-danger' : params.value === 'active' ? 'text-success' : 'text-warning'}>{params.value}</span>
                )
            },
        },
        {
            field: 'substatus',
            headerName: t('Sub Status'),
            width: 130,
            renderCell: (params: any) => {
                return (
                    <span className={params.value === 'Contacted' ? 'text-primary2' : params.value === 'Qualified' ? 'text-warning' : params.value === 'active' ? 'text-primary2' : 'text-warning'}>{params.value}</span>
                )
            },
        },
        {
            field: 'action',
            headerName: t('Action'),
            sortable: false,
            width: 100,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                return (
                    <>
                        {/* <span className='view-option text-theme fw-bolder'>{t("View")}</span> */}
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
                            <MoreOutlined className='dot-increase-20' />
                        </Dropdown>
                    </>
                )
            },
        },
    ]
    return (
        <div className='com-card-section dashboard-page'>
            <div className='row gy-5 g-xl-8 mb-6 align-items-center'>
                <div className='col mt-0'>
                    <h4 className='card-title font-18 text-black mb-0'>{t('Leads')}</h4>
                </div>
                <div className='col-auto mt-0'>
                    <Link className="btn btn-primary fw-bold px-10 py-3" to="/leads/add">
                        <i className="bi bi-plus-circle-fill align-middle"></i>  Add Lead</Link>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='card mb-3'>
                        <div className='card-body px-8 py-6'>
                            <div className='rec-payment-act-sec'>
                                <div className='data_table'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='table-responsive'>
                                                <div style={{ height: 400, width: '100%' }}>
                                                    <DataGrid
                                                        rows={TableData}
                                                        columns={columns}
                                                        pageSize={10}
                                                        rowsPerPageOptions={[10]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Leads;