import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { PaymentActionMenu } from '../PaymentActionMenu/PaymentActionMenu'
import { PaymentClearedAction } from '../PaymentActionMenu/PaymentClearedAction'
import moment from 'moment'
import { Spinner } from 'react-bootstrap-v5'
import { useTranslation } from 'react-i18next'
import { CustomLoader1 } from '../../../../constants/generics/customActios'



export function ServiceChargesList({ payments, contract, loading }: any) {
  const [paymentsData, setPaymentsData] = useState<any>([])
  const { t } = useTranslation();

  useEffect(() => {
    if (payments) {
      setPaymentsData(payments?.filter((item: any) => item.title !== 'Rent' && item.title !== 'Security Deposit' && item.title !== 'Access Card'))
    } else {
      setPaymentsData([])
    }
  }, [payments])

  const columns = [
    {
      field: 'id',
      headerName: t("No"),
      sortable: false,
      disableColumnMenu: true,
      width: 60,
    },
    {
      field: 'title',
      headerName: t("Type of Payment"),
      width: 200,
      renderCell: (params: any) => {
        return <span title={params.row?.title}>
          {params.row?.title
            ? params.row?.title.length > 25
              ? params.row?.title.slice(0, 25).split(' ').slice(0, -1).join(' ') +
              '...'
              : params.row?.title
            : params.row?.title}
        </span>
      }
    },
    {
      field: 'amount',
      headerName: t("Amount"),
      width: 150,
      renderCell: (params: any) => {
        return params.row.amount?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' AED'
      },
    },
    {
      field: 'unitNumber',
      headerName: t("Unit"),
      width: 200,
      renderCell: (params: any) => {
        return contract?.UnitNumber
      }
    },
    {
      field: 'statusText',
      headerName: t("Status"),
      width: 150,
      renderCell: (params: any) => {
        return (
          <p
            className={
              params?.row?.statusText === 'Upcoming' ? 'upcoming-theme mb-0' : 'cleared-theme mb-0'
            }
          >
            {`${params?.row?.statusText}`}
          </p>
        )
      },
    },
    {
      field: 'paidDate',
      headerName: t("Paid On/Due Date"),
      width: 200,
      renderCell: (params: any) => {
        return params.row.paidDate ?
          moment(params.row.paidDate).format('DD-MM-YYYY')
          : params.row.dueDate
            ? moment(params.row.dueDate).format('DD-MM-YYYY')
            : '--' //If not a valid date
      },
    },
    {
      field: 'action',
      headerName: t("Action"),
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (params: any) => {
        return (
          <>
            {params?.row?.status == 0 ? (
              <PaymentActionMenu details={params?.row} />
            ) : (
              <PaymentClearedAction details={params?.row} />
            )}
          </>
        )
      },
    },
  ]
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <h4 className='card-title mb-6'>{t("Recent Payment Activity")}</h4>
                </div>
                <div className='col-md-12 col-12'>
                  <div className='data_table'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='table-responsive'>
                          {loading ? (
                            <>
                              <div>
                                {/* <CustomLoader1 height={50} /> */}
                                <CustomLoader1 loadMessage='Please wait a minute, this might take a while…' height={50} />
                              </div>
                            </>
                          )
                            : (
                              <>
                                <div style={{ height: 400 }}>
                                  <DataGrid
                                    rows={paymentsData}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                  />
                                </div>
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
