import React,{useState, useEffect} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {Redirect, Route, Switch} from 'react-router-dom'
export const options = {
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart - Stacked',
        },
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
            grid: {
                display: false,
                drawBorder: false,
                zeroLineColor: 'transparent'
            },
            ticks: {
                color: 'rgb(0, 0, 0)',
                font: {
                    size: 12,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                    weight: '500',
                }
            },
        },
        y: {
            stacked: true,
            ticks: {
                color: 'rgb(0, 0, 0)',
                font: {
                    size: 12,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                    weight: '500',
                }
            },
        },
    },
    tooltips: false,
};

const labels = ["January","February","March","April","May","June","July","August","September","October","November","December"];

/* export const data = {
    labels,
    datasets: [
        {
            datalabels: {
                color: "#EAEFF5",
                font: {
                    size: 16,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                },
            },
            label: 'In-Progress',
            data: [40, 60,40, 60, 40, 60],
            backgroundColor: '#f6a41c',
            barThickness: 45,
            maxBarThickness: 45,
            borderRadius: {
                bottomRight: 5,
                bottomLeft: 5,
                topRight: 5,
                topLeft: 5,
            }
        },
        {
            datalabels: {
                color: "#EAEFF5",
                font: {
                    size: 16,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                },
            },
            label: 'Whole',
            data: [100, 80, 100, 80, 100, 80],
            backgroundColor: '#EAEFF5',
            barThickness: 45,
            maxBarThickness: 45,
            borderRadius: {
                bottomRight: 0,
                bottomLeft: 0,
                topRight: 5,
                topLeft: 5,
            }
        },
    ],
}; */
export const BarChartForBroker:React.FC<any> = ({invoice}) => {


    const [data, setData] = useState({
        labels,
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'In-Progress',
                data: [0,0,0,0,0,0,0,0,0,0,0,0],
                backgroundColor: '#f6a41c',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }/* ,
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'Whole',
                data: [invoice.January,invoice.February,invoice.March,invoice.April,invoice.May,invoice.June,invoice.July,invoice.August,invoice.September,invoice.October,invoice.November,invoice.December],
                backgroundColor: '#EAEFF5',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 0,
                    bottomLeft: 0,
                    topRight: 5,
                    topLeft: 5,
                }
            }, */
        ],
    });

    useEffect(()=>{
        setData({
            labels,
            datasets: [
                {
                    datalabels: {
                        color: "#EAEFF5",
                        font: {
                            size: 16,
                            family: "'Poppins', sans-serif",
                            lineHeight: 0.5,
                        },
                    },
                    label: 'In-Progress',
                    data: [invoice?.January,invoice?.February,invoice?.March,invoice?.April,invoice?.May,invoice?.June,invoice?.July,invoice?.August,invoice?.September,invoice?.October,invoice?.November,invoice?.December],
                    backgroundColor: '#f6a41c',
                    barThickness: 45,
                    maxBarThickness: 45,
                    borderRadius: {
                        bottomRight: 5,
                        bottomLeft: 5,
                        topRight: 5,
                        topLeft: 5,
                    }
                }
            ],
        });
    },[invoice]);
    return (
        <>
             <Bar options={options} data={data} height={"50%"} width={"100%"} />
        </>
);
}