import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { getContractActivity } from '../../../constants/axios/apis'
import { DocumentUpload } from './DocumentUpload'
import { PaymentModalPreview } from './make-an-offer-notification/PaymentModal'
import { RequestAccept } from './RequestAccept'
import { ServiceDataInfo } from './ServiceDataInfo'
import { SignatureModal } from './SignatureModal/SignatureModal'
import { SignedContract } from './SignedContract'
import { SignSuccessful } from './SignSuccessful/SignSuccessful'
import { SuccessfulPopup } from './SuccessfulPopup/SuccessfulPopup'
import { useTranslation } from 'react-i18next'

export function ContractExtension({ selectedIssue, contract }: any) {
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [contractActivity, setContractActivity] = useState<any>()

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])

  useEffect(() => {
    if (issue && issue?.code == 'CE') {
      getContractActivity(issue?.details?.contract_id, 1).then((response) => {
        if (response.data) {
          setContractActivity(response.data[0])
        }
      })
    }
  }, [issue])

  const contractActivityUpdated = () => {
    getContractActivity(issue?.details?.contract_id, 1).then((response) => {
      if (response.data) {
        setContractActivity(response.data[0])
      }
    })
  }

  const formatTitle = (string: string) => {
    return string.toLocaleLowerCase().replaceAll('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())
  }
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      {!loading && issue && issue?.code == 'CE' ? (
        <div className='row mta-app-setting-campaign'>
          {contractActivity?.activity == 10 && <RequestAccept type={'Extension'} />}
          {contractActivity?.activity == 12 && (
            <DocumentUpload
              type={'Extension'}
              contractActivity={contractActivity}
              contractActivityUpdated={contractActivityUpdated}
            />
          )}
          {contractActivity?.activity == 14 && <SignedContract type={'Extension'} />}
          <div className='col-12'>
            <div className='accordion accordion-flush' id='accordionFlushExampleExtension'>
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header position-relative' id='flush-headingExtension'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapseExtension'
                    aria-expanded='false'
                    aria-controls='flush-collapseExtension'
                  >
                    {t("Contract Extension Request Detail")}
                  </button>
                  <span className='services-badge'>
                    {issue?.status == 0 ? t("Open") : t("Closed")}
                  </span>
                </h2>

                <div
                  id='flush-collapseExtension'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingExtension'
                  data-bs-parent='#accordionFlushExampleExtension'
                >
                  <div className='accordion-body py-5'>
                    <div className='row form-add-design'>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Customer Name")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={issue?.details?.customer_name}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Renewal Period")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={issue?.details?.renewal_period}
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>{t("Renewal End Date")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.details?.renewal_end_date).format('DD-MM-YYYY')}
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>{t("Renewal Annual Amount")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={parseInt(issue?.details?.renewal_amount)?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' AED'}
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>{t("Number of Installments")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={issue?.details?.installments}
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>{t("Method of Payment")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={formatTitle(issue?.details?.mode_payment)}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <ServiceDataInfo issue={issue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PaymentModalPreview
            props={{
              amount: issue?.details.renewal_amount,
              payment_for: 1,
              payment_category: 4,
              property_id: issue?.details.property_id,
              contractActivityUpdated: contractActivityUpdated,
              contract: contract,
            }}
          />
          <SignatureModal />
          <SignSuccessful />
          <SuccessfulPopup />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
