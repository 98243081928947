import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from 'react-i18next'
import NocUnitAlteration from '../../../../assets/images/noc-unit-alteration-ioc.png'
import NocUnitSale from '../../../../assets/images/noc-unit-sale-ioc.png'
import NocUtilities from '../../../../assets/images/noc-utilities-ioc.png'
import NocTitleDeed from '../../../../assets/images/noc-title-deed-ioc.png'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../constants'

export function MyServicesList({ contractData, services }: any) {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }
  const { t } = useTranslation();
  const [isAmenityAvailable, setIsAmenityAvailable] = useState(false);
  const [isMoveInCompleted, setIsMoveInCompleted] = useState(false);
  const [isParkingAvailable, setIsParkingAvailable] = useState(false);
  const [sliderLoading, setSliderLoading] = useState(true);

  useEffect(() => {
    setIsAmenityAvailable(contractData?.isAmenityAvailable === "Y" ? false : true)
    setIsMoveInCompleted(contractData?.isMoveInCompleted === "Y" ? false : true)
    setIsParkingAvailable(contractData?.isParkingAvailable === "Y" ? false : true)
  }, [contractData]);

  useEffect(() => {
    setTimeout(() => {
      setSliderLoading(false);
    }, 500);
  }, []);

  // console.log("🚀 ~ file: MyServicesList.tsx ~ line 27 ~ MyServicesList ~ isAmenityAvailable", isAmenityAvailable) category.id != 15 && isMoveInCompleted ||
  // let updatedServicesList = services?.filter( 
  //   (el: any) => el.id != 21 && el.id != 22 && el.id != 23 && el.id != 24 && el.id != 5 && el.id != 6 && el.id != 7 && el.id != 8 && el.id != 9 && el.id != 42 && el.id != 43
  // )

  let categories = services?.filter((category: any) => category?.is_visible === 1 && (category.id != 20 && isAmenityAvailable || category.id != 2 && isParkingAvailable))

  let finalCat = categories?.length > 0 ? categories : services
  
  let content = finalCat?.map((service: any) => {
    return (
      service.id == 26 ? (
        <div key={service.id}>
          <Link
            to={'#schedule-meeting'}
            className='text-decoration-none'
            data-bs-toggle='modal'
          >
            <div className='card'>
              <div className='card-body py-4 px-3'>
                <div className='row g-0 align-items-center'>
                  <div className='col-4'>
                    <img src={service.icon} className='img-fluid' />
                  </div>
                  <div className='col-8'>
                    <h6 className='card-title mb-0'>
                      {' '}
                      {t("Meeting Request")}
                       {/* {t("Request")}{' '} */}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ) : (
        <div key={service.id}>
          <Link to={{ pathname: ['FODN', 'FOCA', 'PTW', 'AA'].includes(service?.categoryCode) ? '/ct-move-in' : '/my-services/' + service.id }}>
            <div className='card'>
              <div className='card-body py-4 px-3'>
                <div className='row g-0 align-items-center'>
                  <div className='col-4'>
                    <img src={service.icon} className='img-fluid' />
                  </div>
                  <div className='col-8'>
                    <h6 className='card-title mb-0'>{service.categoryName}</h6>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))
  }

  )

  const genaralCategory = services?.find((category:any)=> category.categoryCode === 'GS' )
  let generalCat = []
  if(genaralCategory?.service_category_types && Array.isArray(genaralCategory.service_category_types) && genaralCategory.service_category_types.length){
    generalCat = genaralCategory.service_category_types.map((cat:any)=>(
      <div key={cat.id}>
        <Link to={{ pathname: '/my-services/general' , state : {quickCategory:cat}}}>
          <div className='card'>
            <div className='card-body py-4 px-3'>
              <div className='row g-0 align-items-center'>
                <div className='col-4'>
                  <img src={cat.icon} className='img-fluid' />
                </div>
                <div className='col-8'>
                  <h6 className='card-title mb-0'>{cat.name}</h6>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    ))    
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-6 col-6'>
                  <h4 className='card-title'> {window.location.pathname == '/dashboard' ? t("Quick Links") : t("My Services")} </h4>
                </div>
                {/*<div className="col-md-6 col-6 text-right">*/}
                {/*<a href="#" className="text-decoration-none view-all">*/}
                {/*<span> View All </span>*/}
                {/*<img src={RightArrow} className="img-fluid" />*/}
                {/*</a>*/}
                {/*</div>*/}
              </div>
              <div className='quick-link-sec'>
                <div>
                  <div className='row'>
                    <div className='col-12'>
                      { currentContract.is_sale === 1 ? (
                        <>
                          <Slider {...settings}>
                            <div>
                              <Link to={'/my-services/noc-unit-alteration'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-auto'>
                                        <img src={NocUnitAlteration} className='img-fluid' />
                                      </div>
                                      <div className='col'>
                                        <h6 className='card-title mb-0'>{t("NOC for Unit Alteration")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/noc-sale'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-auto'>
                                        <img src={NocUnitSale} className='img-fluid' />
                                      </div>
                                      <div className='col'>
                                        <h6 className='card-title mb-0'>{t("NOC for Unit sale")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/noc-utilities'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-auto'>
                                        <img src={NocUtilities} className='img-fluid' />
                                      </div>
                                      <div className='col'>
                                        <h6 className='card-title mb-0'>{t("NOC for Utilities")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/noc-title-deed'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-auto'>
                                        <img src={NocTitleDeed} className='img-fluid' />
                                      </div>
                                      <div className='col'>
                                        <h6 className='card-title mb-0'>{t("NOC for Title Deed")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            {/* <div>
                              <Link to={'/my-services/noc-initial-registration'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-auto'>
                                        <img src={NOC} className='img-fluid' />
                                      </div>
                                      <div className='col'>
                                        <h6 className='card-title mb-0'>{t("NOC for Initial Registration")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div> */}
                            {/* <div>
                              <Link to={'/ct-move-in'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={PermitWork} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Permit to Work")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/contract-renewal'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={RenewContract} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Renew Contract")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/1'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={AccessCard} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Access Cards")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/parking-issue'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={ParkingIssue} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Parking Issue")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/openissues'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={Disturbance} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Disturbance")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div> */}
                            {/* <div>
                              <Link to={'/my-services/early-termination'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={EarlyTerminations} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Early Termination")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/extend-contract'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={ExtendContract} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Extend Contract")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/undertaking-letter'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={UndertakingLetter} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Undertaking Letter")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div> */}
                            {/* <div>
                              <Link to={'/my-services/renewal-notice'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={ClearanceCharges} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Clearance Charges")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                            <div>
                              <Link to={'/my-services/rent-reduction'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-4'>
                                        <img src={RentDeduction} className='img-fluid' />
                                      </div>
                                      <div className='col-8'>
                                        <h6 className='card-title mb-0'>{t("Rent Reduction")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div> */}
                            {/* <div>
                              <Link to={'/my-services/amenity-booking'}>
                                <div className='card'>
                                  <div className='card-body py-4 px-3'>
                                    <div className='row g-0 align-items-center'>
                                      <div className='col-auto'>
                                        <img src={RentDeduction} className='img-fluid' />
                                      </div>
                                      <div className='col'>
                                        <h6 className='card-title mb-0'>{t("Amenity Booking")}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div> */}
                          </Slider>
                        </>
                      ) : (
                        <>
                          {sliderLoading ? null : 
                            <Slider {...settings}>
                              {services ? content : null}
                              {generalCat?.length ? generalCat : null}
                            </Slider>
                          }
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
