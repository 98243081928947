import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { BasicDetails } from './BasicDetails';
import { Documents } from './Documents';
import { OtherDetails } from './OtherDetails';
import { useTranslation } from 'react-i18next'

export function HealthClubRegistration({selectedIssue, contract}: any) {
    const [issue, setIssue] = useState<any>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      if (selectedIssue) {
        setIssue(selectedIssue)
        setLoading(false)
      }
    }, [selectedIssue])

    // console.log(selectedIssue, 'selectedIssue');
    const { t } = useTranslation();

    return (
        <div className='com-card-section'>
            {!loading && issue && issue?.code == 'HCR' ? (   //TODO: change code as per required
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-body py-4 px-8'>
                            <div className='col-12'>
                                <h4 className="card-title font-18 text-black mb-6">{t("Health club registration")}</h4>
                            </div>
                            <div className='col-12'>
                                <div className="accordion move-out-accordion" id="accordionExample">
                                    <BasicDetails issue={issue} />
                                    {/* <OtherDetails issue={issue} /> */}
                                    <Documents issue={issue} />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
              )}
        </div>
    );
}