import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {TotalBrokers} from "./TotalBrokers/TotalBrokers"
import {TotalBrokersActivity} from "./TotalBrokersActivity/TotalBrokersActivity"
import {RecentInvoices} from '../broker-admin-dashboard/components/RecentInvoices/RecentInvoices';
import RightArrow from "../../assets/images/Right_Arrow.png";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

const BrokersPage: FC = () => {
    let params: any = {};
    params = useLocation(); 
    const { brokersList } = params.state;
    const { t } = useTranslation();

    return (
        <>
        <div className='row gy-5 g-xl-8 broker-dash-page dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <div className="com-card-section">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-6">
                                <div className="card-body px-8 pb-10">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="card-title"> {t("My Team")} </h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-5 col-12">
                                            <TotalBrokers brokersList={brokersList}/>
                                        </div>
                                        <div className="col-lg-7 col-12">
                                            <TotalBrokersActivity brokersList={brokersList}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-body px-8">
                                    <div className="row">
                                        <div className="col-md-6 col-7">
                                            <h4 className="card-title"> {t("Recent Invoices")} </h4>
                                        </div>
                                        <div className="col-md-6 col-5 text-end">
                                            <Link to={'/statements/approved-invoices'} className="text-decoration-none view-all d-block mt-2 text-end">
                                                <span> {t("View All")} </span>
                                                <img src={RightArrow} className="img-fluid" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <RecentInvoices />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

const BrokersWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <BrokersPage />
        </>
    )
};

export {BrokersWrapper}
