import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {createWorker} from 'tesseract.js'
import {Alert, Spinner} from 'react-bootstrap-v5'
import {RootState} from '../../../../constants'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import * as dashboard from '../../../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import {payByCheque} from '../../../../constants/axios/apis'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import {SuccessfulPopup} from '../SuccessfulPopup/SuccessfulPopup'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

const today = new Date()
today.setHours(0, 0, 0, 0)

export function PaybyCheque({props}: any) {
  const [loading, setLoading] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const closeRef = useRef<any>()

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldTouched('cheque_img')
  //   formik.setFieldValue('cheque_img', e.currentTarget.files[0])
  //   formik.handleChange(e)
  // }
  const { t } = useTranslation();

  const initialValues = {
    offer_id: props?.contract?.offer_id,
    payment_for: props?.payment_for,
    cheque_number: '',
    payment_date: '',
    acc_holder_name: '',
    acc_number: '',
    amount: props?.amount,
    bank: '',
    cheque_img: '',
  }

  const chequeRequestSchema = Yup.object().shape({
    cheque_number: Yup.string()
      .required(t("Cheque Number is required"))
      .matches(/^[0-9]*$/, t("Only numbers allowed")),
    payment_date: Yup.date()
      .min(today, t("Payment date cannot be in the past"))
      .required(t("Please enter the payment date")),
    acc_holder_name: Yup.string()
      .required(t("Account Holder Name is required"))
      .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    acc_number: Yup.string()
      .required(t("Account Number is required"))
      .matches(/^[0-9]*$/, t("Only numbers allowed")),
    bank: Yup.string()
      .required(t("Bank Name is required"))
      .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    // cheque_img: Yup.mixed()
    //   .required(t("Image is required"))
    //   .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png"), (value) => {
    //     return (
    //       value &&
    //       (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png')
    //     )
    //   })
    //   .test('fileSize', t("The file is too large!"), (value) => {
    //     return value?.size <= 2000000
    //   }),
  })

  const formik = useFormik({
    initialValues,
    isInitialValid: false,
    validationSchema: chequeRequestSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      var FormData = require('form-data')
      var chequePaymentDetails = new FormData()
      chequePaymentDetails.append('offer_id', values.offer_id)
      chequePaymentDetails.append('property_id', props?.contract?.propertyID)
      chequePaymentDetails.append('payment_for', values.payment_for)
      chequePaymentDetails.append('cheque_number', values.cheque_number)
      chequePaymentDetails.append('payment_date', values.payment_date)
      chequePaymentDetails.append('acc_holder_name', values.acc_holder_name)
      chequePaymentDetails.append('acc_number', values.acc_number)
      chequePaymentDetails.append('amount', values.amount)
      chequePaymentDetails.append('bank', values.bank)
      chequePaymentDetails.append('cheque_img', values.cheque_img)

      setTimeout(() => {
        payByCheque(chequePaymentDetails)
          .then((response: any) => {
            setLoading(false)
            console.log(response)
            props.contractActivityUpdated()
            closeRef.current.click()
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('cheque_img', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('cheque_img')
          formik.setFieldValue('cheque_img', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('cheque_img', true, false)
          formik.setFieldValue('cheque_img', '', false)
          formik.setFieldError('cheque_img', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('cheque_img')
      formik.setFieldValue('cheque_img', '', false)
      formik.setFieldError('cheque_img', t('File upload error!'))
      setUploading(false)
    }
  }

  return (
    <>
      <div className=' '>
        <div className='row'>
          <div className='col-12'>
            <div className='row form-add-design'>
              <div className='col-12'>
                <div className='mb-5 upload-input'>
                  <label className='form-label fw-bolder d-block mb-3'>{t("Photo")}</label>
                  <input
                    multiple
                    ref={inputRef}
                    onChange={(e) => handleDisplayFileDetails(e)}
                    className='d-none'
                    type='file'
                    accept=".png,.jpg,.jpeg"
                  />
                  <button
                    onClick={handleUpload}
                    disabled={uploading}
                    className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                    }`}
                  >
                    <span>
                      {uploading ?
                        <span className='indicator-progress d-block'>
                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        : uploadedFileName ? (
                          uploadedFileName
                        ) : (
                          <span>
                            {t('Upload')}{' '}
                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                          </span>
                        )}{' '}
                    </span>
                    {/* <span>
                      {uploadedFileName ? (
                        uploadedFileName
                      ) : (
                        <span>
                          {t("Upload")}{' '}
                          <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                        </span>
                      )}{' '}
                    </span> */}
                  </button>
                  {formik.touched.cheque_img && formik.errors.cheque_img && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.cheque_img}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("Cheque Number")}</label>
                  <input
                    type='text'
                    className='form-control fw-bolder  bg-white'
                    {...formik.getFieldProps('cheque_number')}
                    maxLength={50}
                  />
                  {formik.touched.cheque_number && formik.errors.cheque_number && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.cheque_number}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("Cheque Date")}</label>
                  <input
                    type='date'
                    className='form-control fw-bolder  bg-white'
                    {...formik.getFieldProps('payment_date')}
                  />
                  {formik.touched.payment_date && formik.errors.payment_date && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.payment_date}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("Account Number")}</label>
                  <input
                    type='text'
                    className='form-control fw-bolder  bg-white'
                    {...formik.getFieldProps('acc_number')}
                    maxLength={50}
                  />
                  {formik.touched.acc_number && formik.errors.acc_number && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.acc_number}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("Account Holder Name")}</label>
                  <input
                    type='text'
                    className='form-control fw-bolder  bg-white'
                    {...formik.getFieldProps('acc_holder_name')}
                    maxLength={50}
                  />
                  {formik.touched.acc_holder_name && formik.errors.acc_holder_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.acc_holder_name}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative'>
                  <label className='font-14 fw-bold mb-3 d-block'>{t("Bank Name")}</label>
                  <input
                    type='text'
                    className='form-control fw-bolder  bg-white'
                    {...formik.getFieldProps('bank')}
                    maxLength={50}
                  />
                  {formik.touched.bank && formik.errors.bank && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.bank}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary fw-bold px-10 py-3 w-100'
                  disabled={loading || uploading}
                >
                  {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {t("Please wait...")}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <p
          ref={closeRef}
          data-bs-target='#success-modal-preview'
          data-bs-toggle='modal'
          data-bs-dismiss='modal'
        >
          {/*  */}
        </p>
      </div>
    </>
  )
}
