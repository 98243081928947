import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import offerImg01 from '../../../../../../assets/images/offer_img.png'
import Home from '../../../../../../assets/images/my-unit.png'
import Place from '../../../../../../assets/images/place-icons.png'
import UserIcon from '../../../../../../assets/images/user-ioc-active.png'
import Time from '../../../../../../assets/images/time-icon.png'
import Date from '../../../../../../assets/images/Scheduled_Active.png'
import { useSelector } from 'react-redux'
import { getUninvoicedContractsList } from '../../../../../../utils/makeRequest'
import { IState } from '../../../../../../utils/interface'
import { useTranslation } from 'react-i18next'
import { CustomLoader2, NoDataFound1 } from '../../../../../../constants/generics/customActios'
import { formatDate, formatTimeOnly } from '../../../../../../constants/generics/dateTimeFormater'
import { TeamSearch } from '../../../VisitsPage/Search/Search'

export function UninvoicesList() {
  const state: any = useSelector((state) => state)
  const userToken = state?.auth?.accessToken
  const { t } = useTranslation()
  const [searchProperty, setSearchProperty] = useState('')
  const [searchBroker, setSearchBroker] = useState('')
  const [uninvoicedContractList, setUninvoicedContractList] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })
  const { loading, response: uninvoicedContacts, error } = uninvoicedContractList

  useEffect(() => {
    getUninvoicedContractsList(setUninvoicedContractList, userToken)
  }, [])

  const filteredUninvoicedContacts = uninvoicedContacts
    .filter((items: any) => {
      return searchBroker === '' || (searchBroker !== '' && searchBroker === `${items?.broker_id}`)
    })
    .filter((items: any) => {
      const searchRegex = new RegExp(searchProperty, 'i')
      return (
        searchProperty === '' || (searchProperty !== '' && items?.propertyName?.match(searchRegex))
      )
    })

  return (
    <div className='row'>
      <div className='col-md-12 col-lg-6 ms-auto col-12 mt-md-3 mt-lg-0 mb-5'>
        <TeamSearch setSearchBroker={setSearchBroker} setSearchProperty={setSearchProperty} />
      </div>
      <div className='col-md-12 col-12'>
        <div className='reserved-main-div row'>
          {loading && <CustomLoader2 />}
          {Object.keys(filteredUninvoicedContacts).length !== 0 &&
            filteredUninvoicedContacts.map((contract: any, index: number) => {
              return (
                <div className='col-sm-12' key={index + 1}>
                  <a className='offer_link'>
                    <div className='pm-offers-and-renewals mb-5'>
                      <div className='card'>
                        <div className='row'>
                          <div className='col-sm-3 col-12 col-lg-3 col-xl-3'>
                            <div className='offer_main_img h-100'>
                              <img
                                src={
                                  contract?.propertyCoverImage
                                    ? contract?.propertyCoverImage
                                    : Home
                                }
                                className='img-fluid'
                              />
                              <span className='badge badge-pill badge-dark prop-type-badge'>
                                {parseInt(contract?.property_search_type) === 1
                                  ? t('Residential')
                                  : parseInt(contract?.property_search_type) === 2
                                    ? t('Commercial')
                                    : contract?.property_search_type}
                              </span>
                            </div>
                          </div>
                          <div className='col-lg-6 col-xl-6 col-sm-6 col-12 align-self-center'>
                            <div className='offer_main_details'>
                              <div className='col-12'>
                                <p className='text-black fw-bolder font-17 text-title my-offer-property-name mb-3'>
                                  {contract?.propertyName ? contract?.propertyName : '-'}
                                </p>
                              </div>
                              <div className='col-12 offer_main_details_div_3'>
                                <p className='text-dark'>
                                  <img src={Place} className='img-fluid pe-2' />
                                  {contract?.propertyCode?.substring(
                                    contract?.propertyCode?.lastIndexOf('-') + 1,
                                    contract?.propertyCode?.length
                                  )}{' '}
                                  {contract?.buildingName && ' | ' + contract?.buildingName}{' '}
                                  {contract?.Address && ' | ' + contract?.Address}{' '}
                                  {contract?.location && ' | ' + contract?.location}
                                </p>
                              </div>
                              <div className='col-12'>
                                <div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
                                  <p className='p-3 me-2'>
                                    <img src={Date} className='img-fluid me-3' />
                                    {contract?.contractGeneratedDate
                                      ? formatDate(contract.contractGeneratedDate)
                                      : '-'}
                                  </p>
                                  <p className='p-3 me-2'>
                                    <img src={Time} className='img-fluid me-2' />
                                    {contract?.contractGeneratedDate
                                      ? formatTimeOnly(contract.contractGeneratedDate)
                                      : '-'}
                                  </p>
                                  <p className='p-3 me-2 sqft-my-offer'>
                                    <img src={UserIcon} className='img-fluid me-2' />
                                    {contract?.brokerName ? contract?.brokerName : '-'}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {contract?.isSigned === 1 && (
                            <div className='col-lg-3 col-xl-3 col-sm-3 col-12 align-self-center text-center'>
                              <Link
                                //to={'/statements/add-commission'} 
                                to={{ 'pathname': '/statements/add-commission', state: { contract : contract?.id} }}
                                className=''>
                                <button className='btn btn-outline-primary fw-bold px-10 py-3'>
                                  {t('Claim Commission')}
                                </button>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              )
            })}
          {Object.keys(filteredUninvoicedContacts)?.length == 0 && !loading && <NoDataFound1 />}
        </div>
      </div>
    </div>
  )
}
