import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import DatePicker from 'react-date-picker'
import { createChequeReplacementRequest, getMasterData } from '../../../../constants/axios/apis'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import moment from 'moment'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'
import { useTranslation } from 'react-i18next'

const today = new Date()
today.setHours(0, 0, 0, 0)

export function ChequeReplaceRequest() {
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const [loading, setLoading] = useState(false)
  const [reasons, setReasons] = useState<any>()
  const [file, setFile] = useState([] as any)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [details, setDetails] = useState<any>()
  const history = useHistory()
  const location = useLocation<any>()
  const inputRef = useRef<HTMLInputElement>(null)
  const closeRef = useRef<any>()
  const { t } = useTranslation()

  useEffect(() => {
    if (location?.state?.details) {
      setDetails(location?.state?.details)
    }
  }, [location?.key])

  const chequeReplaceSchema = Yup.object().shape({
    reason: Yup.number().required('Please choose a reason for replacement'),
    date: Yup.date()
      .min(today, 'Selected date cannot be in the past')
      .required('Please select a date'),
    acc_holder_name: Yup.string()
      .required('Account Holder Name is required')
      .matches(/^[A-Za-z ]+$/, 'Only alphabets allowed'),
    cheque_number: Yup.string()
      .required('Cheque Number is required')
      .matches(/^[0-9]*$/, 'Only numbers allowed'),
    acc_number: Yup.string()
      .required('Account Number is required')
      .matches(/^[0-9]*$/, 'Only numbers allowed'),
    bank: Yup.string()
      .required('Bank Name is required')
      .matches(/^[A-Za-z ]+$/, 'Only alphabets allowed'),
    // amount: Yup.string()
    //   .required('Amount is required')
    //   .matches(/^[0-9]*$/, 'Only numbers allowed'),
    // cheque_img: Yup.mixed()
    //   .required('Image is required')
    //   .test('required', 'Cheque image is required', (value) => {
    //     if (value) {
    //       return value?.length !== 0
    //     }
    //     return false
    //   })
    //   .test('type', 'Only the following formats are accepted: .jpeg, .jpg, .png', (value) => {
    //     if (value?.length > 1) {
    //       let validated = 0
    //       value.forEach((element: any) => {
    //         if (element.type === 'image/jpeg' || element.type === 'image/jpg' || element.type === 'image/png') {
    //           validated++
    //         }
    //       })
    //       return validated === value.length
    //     }
    //     return value?.length > 0 && (value[0].type === 'image/jpeg' || value[0].type === 'image/jpg' || value[0].type === 'image/png')
    //   })
    //   .test('fileSize', 'The file is too large', (value) => {
    //     return value && value[0]?.size <= 2000000
    //   }),
    cheque_img: Yup.mixed()
      .test('fileRequried', (t('Please select a file!')), (value) => {
        return value?.size ? true : false
      })
      .test('type', (t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!')), (value) => {
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'image/png' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', (t('The file is too large! Max allowed size is 5MB.')), (value) => {
        return value?.size <= 5242880
      }),
    desc: Yup.string().max(250, 'Maximum 250 characters'),
  })

  const initialValues = {
    offer_cheque_id: details ? details.offer_cheque_id : '',
    date: today,
    cheque_img: '',
    cheque_number: '',
    acc_number: '',
    acc_holder_name: '',
    bank: '',
    amount: details?.amount,
    desc: '',
    reason: '',
    type: 1,
    property_id: currentContract?.propertyID,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: chequeReplaceSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        createChequeReplacementRequest(
          values.offer_cheque_id,
          moment(values.date).format('YYYY-MM-DD'),
          values.cheque_number,
          values.acc_holder_name,
          values.acc_number,
          values.amount,
          values.bank,
          values.desc,
          values.reason,
          values.type,
          values.cheque_img,
          values.property_id
        )
          .then((response: any) => {
            setLoading(false)
            resetForm()
            closeRef.current.click()
            history.push('/payments/all')
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Invalid Data Provided')
          })
      }, 1000)
    },
  })

  useEffect(() => {
    getMasterData().then(({ data }) => {
      let items = data.filter((item: any) => item.id == 21).flatMap((data: any) => data.master_data)
      setReasons(items)
    })
  }, [])

  useEffect(() => {
    if (uploadedFiles.length) {
      formik.setFieldTouched('cheque_img')
      formik.setFieldValue('cheque_img', uploadedFiles)
    } else {
      setUploadedFileName(null)
      formik.setFieldValue('cheque_img', '')
    }
  }, [uploadedFiles])

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }

  // function uploadSingleFile(e: any) {
  //   setUploadedFiles((oldList: any) => [...oldList, e.target.files[0]])
  //   setFile([URL.createObjectURL(e.target.files[0])])
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  // }

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const uploadSingleFile = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
      formik.setFieldTouched('cheque_img')
      formik.setFieldValue('cheque_img', e.currentTarget.files[0])
    } else {
      setUploading(true)
      formik.setFieldError('cheque_img', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
            formik.setFieldTouched('cheque_img', true, false)
            formik.setFieldValue('cheque_img', compressResponse.file, false)
          } else {
            setUploadedFileName(null)
            formik.setFieldTouched('cheque_img', true, false)
            formik.setFieldValue('cheque_img', '', false)
            formik.setFieldError('cheque_img', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedFileName(null)
        formik.setFieldTouched('cheque_img')
        formik.setFieldValue('cheque_img', '', false)
        formik.setFieldError('cheque_img', t('File upload error!'))
        setUploading(false)
      }
    }
  }


  function deleteFile(mediaIndex: any, media: any) {
    const filteredFiles = file.filter((item: any, index: any) => index !== mediaIndex)
    setFile(filteredFiles)
    setUploadedFiles(uploadedFiles.filter((item: any, index: any) => index !== mediaIndex))
    inputRef.current!.value = ''
  }

  const handleDate = (e: any) => {
    formik.setFieldValue('date', new Date(e))
  }

  const reasonList = reasons?.map((reason: any) => (
    <option key={reason.value} value={reason.value}>
      {reason.name}
    </option>
  ))

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-7'>
            <Link to={'/payments/all'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            Cheque Replace Request
          </h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='card'>
              <div className='card-body px-8 py-6'>
                <div className='row form-add-design'>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'>
                        {' '}
                        Reason for Cheque Replace<span className='text-danger'>*</span>{' '}
                      </label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid'
                        name='reason'
                        value={formik.values.reason}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          Select
                        </option>
                        {reasons ? reasonList : ''}
                      </select>
                      {formik.touched.reason && formik.errors.reason && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.reason}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'>
                        {' '}
                        Cheque Number<span className='text-danger'>*</span>{' '}
                      </label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        {...formik.getFieldProps('cheque_number')}
                      />
                      {formik.touched.cheque_number && formik.errors.cheque_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.cheque_number}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6 date-picker'>
                      <label className='form-label fw-bolder text-black mb-3'> Cheque Date<span className='text-danger'>*</span> </label>
                      <DatePicker
                        onChange={(e: any) => handleDate(e)}
                        value={formik.values.date}
                        minDate={new Date(new Date().toLocaleDateString())}
                        className='form-control fw-bolder form-control-solid'
                        format='dd-MM-y'
                        clearIcon={null}
                      />
                      {formik.touched.date && formik.errors.date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.date}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'>
                        {' '}
                        Account Number<span className='text-danger'>*</span>{' '}
                      </label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        {...formik.getFieldProps('acc_number')}
                      />
                      {formik.touched.acc_number && formik.errors.acc_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.acc_number}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'>
                        {' '}
                        Account Holder Name<span className='text-danger'>*</span>{' '}
                      </label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        {...formik.getFieldProps('acc_holder_name')}
                      />
                      {formik.touched.acc_holder_name && formik.errors.acc_holder_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.acc_holder_name}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'> Bank Name<span className='text-danger'>*</span> </label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        {...formik.getFieldProps('bank')}
                      />
                      {formik.touched.bank && formik.errors.bank && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.bank}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'> Amount </label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        value={'AED ' + formik.values.amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        name='amount'
                        disabled={true}
                      />
                      {formik.touched.amount && formik.errors.amount && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.amount}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6 upload-input'>
                      <label className='form-label fw-bolder text-dark'> Cheque Image<span className='text-danger'>*</span> </label>
                      <input
                        ref={inputRef}
                        // onChange={uploadSingleFile}
                        onChange={(e) => uploadSingleFile(e)}
                        className='d-none'
                        type='file'
                        name='cheque_img'
                        accept=".png,.jpg,.jpeg"
                      />
                      <button
                        type='button'
                        onClick={handleUpload}
                        disabled={uploading}
                        className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                          }`}
                      >
                        <span>
                          {uploading ?
                            <span className='indicator-progress d-block'>
                              {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            : uploadedFileName ? (
                              uploadedFileName
                            ) : (
                              <span>
                                {t('Upload')}{' '}
                                <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                              </span>
                            )}{' '}
                        </span>
                        {/* <span>
                          {uploadedFileName ? (
                            uploadedFileName
                          ) : (
                            <span>
                              Upload{' '}
                              <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                            </span>
                          )}{' '}
                        </span> */}
                      </button>
                      {formik.touched.cheque_img && formik.errors.cheque_img && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.cheque_img}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-6 upload-input'>
                      {/*<label className="form-label fw-bolder d-block">Attach Images/Video</label>*/}
                      <div className='d-flex'>
                        {/* {visible && (
                          <div className='position-relative'>
                            <div className='upload-image'>
                              <img src={src} alt={alt} className='form-img__img-preview' />
                              <div className='close-icon' onClick={removeImg}>
                                &times;
                              </div>
                            </div>
                          </div>
                        )} */}
                        {file.length > 0 && (
                          <div className='position-relative d-flex'>
                            {file.map((item: any, index: any) => {
                              return (
                                <div className='upload-image' key={item}>
                                  <img src={item} alt='' />
                                  <div
                                    className='close-icon'
                                    onClick={() => deleteFile(index, item)}
                                  >
                                    &times;
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder text-black mb-3'>Description</label>
                      <textarea
                        className='form-control form-control-lg form-control-solid'
                        rows={3}
                        placeholder='Type here...'
                        {...formik.getFieldProps('desc')}
                      />
                      {formik.touched.desc && formik.errors.desc && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.desc}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-12 text-end'>
                    <button
                      type='submit'
                      className='btn btn-outline-primary font-14 fw-bold px-10 py-3 me-5'
                      onClick={() => {
                        formik.resetForm()
                        history.push('/payments/all')
                      }}
                    >
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading || uploading}>
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}
