import React, {FC} from 'react'
import {MenuInner} from './MenuInner'
import {MenuInnerSearch} from './MenuInnerSearch'
import {PMDropDown} from './PMDropdown'
import {MenuInnerFacilityContactor} from './MenuInnerFacilityContaractor'

const Header: FC = () => {
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userRole = JSON.parse(userDetail).id_role
  }
  return (
    <div
      className='header-menu align-items-stretch mobile-header-menu'
    >
      {userRole != 4 && userRole != 5 && userRole === 7 ? (
        <div className='menu menu-lg-rounded menu-column menu-lg-row my-0 my-lg-0 align-items-stretch mobile-menu-p me-6'>
          {<MenuInner />}
          {/* comment tower drop down */}
        </div>
      ) : null}
      {userRole === 18 || userRole === 17 || userRole === 11 ? (
        <div className='menu menu-lg-rounded menu-column menu-lg-row my-0 my-lg-0 align-items-stretch mobile-menu-p me-6'>
          {<PMDropDown />}
          {/* comment tower drop down */}
        </div>
      ) : null}

      {/* All Fc User FCS,FCSS, FC  */}
      {userRole === 8 ||
      userRole === 9 ||
      userRole === 10 ||
      userRole === 12 ||
      userRole === 13  ? (
        <div className='menu menu-lg-rounded menu-column menu-lg-row my-0 my-lg-0 align-items-stretch mobile-menu-p me-6'>
          <MenuInnerFacilityContactor />
        </div>
      ) : null}

      <div className='menu menu-lg-rounded menu-column menu-lg-row my-0 my-lg-0 align-items-stretch'>
        <MenuInnerSearch />
      </div>
    </div>
  )
}

export {Header}
