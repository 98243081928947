import React, { useState } from 'react'
import { ViewPopup } from '../../../pt-documents/components/ViewPopup/ViewPopup'
import pdfImage from '../../../../assets/images/documentImage.png'
import { useTranslation } from 'react-i18next'

export function Documents({ issue }: any) {
  const [viewImage, setViewImage] = useState<any>()
  const { t } = useTranslation();

  const handleView = (data: any) => {
    setViewImage(data)
  }

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
          {t("Documents")}
        </button>
      </h2>
      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
        <div className="accordion-body bg-white">
          <div className='col-12'>
            <div className='row mb-5'>
              <div className='col-md-6 col-12'>
                <div className='row documents-card form-add-design'>
                  <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                      {t("Emirates ID")}
                    </h4>
                  </div>
                  <div className="col-12">
                    <div className='row'>
                      <div className='col-md-6 col-sm-6 col-xl-6 col-12'>
                        <div className='mb-3 upload-input'>
                          <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Front")} </label>
                          <div className="position-relative">
                            {issue?.images?.emiratesID_front ?
                              <>
                                <div className="text-center">
                                  <button
                                    className='border-0 bg-transparent upload-image mt-0 w-100'
                                    data-bs-target='#view-popUp'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                    type='button'
                                    onClick={() => {
                                      handleView({
                                        filename: Array.isArray(issue?.images?.emiratesID_front)
                                          ?
                                          issue?.images?.emiratesID_front[0]
                                          :
                                          issue?.images?.emiratesID_front
                                      })
                                    }}
                                  >
                                    <img src={issue?.images.emiratesID_front
                                      .substr(
                                        issue?.images.emiratesID_front.lastIndexOf('.')
                                      ).includes('.pdf')
                                      ? pdfImage
                                      : issue?.images.emiratesID_front
                                    } alt="Preview" className="form-img__img-preview w-100" />
                                  </button>
                                </div>
                              </>
                              :
                              t("No image")
                            }
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-6 col-xl-6 col-12'>
                        <div className='mb-3 upload-input'>
                          <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Back")} </label>
                          <div className="position-relative">
                            {issue?.images?.emiratesID_back ?
                              <>
                                <div className="text-center">
                                  <button
                                    className='border-0 bg-transparent upload-image mt-0 w-100'
                                    data-bs-target='#view-popUp'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                    type='button'
                                    onClick={() => {
                                      handleView({
                                        filename: Array.isArray(issue?.images?.emiratesID_back)
                                          ?
                                          issue?.images?.emiratesID_back[0]
                                          :
                                          issue?.images?.emiratesID_back
                                      })
                                    }}
                                  >
                                    <img src={issue?.images.emiratesID_back
                                      .substr(
                                        issue?.images.emiratesID_back.lastIndexOf('.')
                                      ).includes('.pdf')
                                      ? pdfImage
                                      : issue?.images.emiratesID_back
                                    } alt="Preview" className="form-img__img-preview w-100" />
                                  </button>
                                </div>
                              </>
                              :
                              t("No image")
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className='col-md-6 col-12'>
                <div className='row documents-card form-add-design'>
                  <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                      {t("Passport")}
                    </h4>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="mb-3 upload-input">
                      <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Front")} </label>
                      <div className="position-relative">
                        {issue?.images?.passport_front ?
                          <>
                            <div className="text-center">
                              <button
                                className='border-0 bg-transparent upload-image mt-0 w-100'
                                data-bs-target='#view-popUp'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                type='button'
                                onClick={() => {
                                  handleView({ filename: Array.isArray(issue?.images?.passport_front) ? issue?.images?.passport_front[0] : issue?.images?.passport_front })
                                }}
                              >
                                <img src={issue?.images.passport_front
                                  .substr(
                                    issue?.images.passport_front.lastIndexOf('.')
                                  ).includes('.pdf')
                                  ? pdfImage
                                  : issue?.images.passport_front
                                } alt="Preview" className="form-img__img-preview w-100" />
                              </button>
                            </div>
                          </>
                          :
                          t("No image")
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="mb-3 upload-input">
                      <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Back")} </label>
                      <div className="position-relative">
                        {issue?.images?.passport_back ?
                          <>
                            <div className="text-center">
                              <button
                                className='border-0 bg-transparent upload-image mt-0 w-100'
                                data-bs-target='#view-popUp'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                type='button'
                                onClick={() => {
                                  handleView({ filename: Array.isArray(issue?.images?.passport_back) ? issue?.images?.passport_back[0] : issue?.images?.passport_back })
                                }}
                              >
                                <img src={issue?.images.passport_back
                                  .substr(
                                    issue?.images.passport_back.lastIndexOf('.')
                                  ).includes('.pdf')
                                  ? pdfImage
                                  : issue?.images.passport_back
                                } alt="Preview" className="form-img__img-preview w-100" />
                              </button>
                            </div>
                          </>
                          :
                          t("No image")
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className='row documents-card form-add-design'>
                  <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                      {t("Photo")}
                    </h4>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="mb-3 upload-input">
                      <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Photo")} </label>
                      <div className="position-relative">
                        {issue?.images?.photo ?
                          <>
                            <div className="text-center">
                              <button
                                className='border-0 bg-transparent upload-image mt-0 w-100'
                                data-bs-target='#view-popUp'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                type='button'
                                onClick={() => {
                                  handleView({ filename: issue?.images?.photo })
                                }}
                              >
                                <img src={issue?.images.photo
                                  .substr(
                                    issue?.images.photo.lastIndexOf('.')
                                  ).includes('.pdf')
                                  ? pdfImage
                                  : issue?.images.photo
                                } alt="Preview" className="form-img__img-preview w-100" />
                              </button>
                            </div>
                          </>
                          :
                          t("No image")
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ViewPopup viewImage={viewImage} />
          </div>
        </div>
      </div>
    </div>
  );
}