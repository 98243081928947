export function NocUnitAlteration() {
    return (
        <>
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <form method="POST" className="form-label-left">
                        <div className="card-body form-add-design">
                            <h4 className="card-title mb-7">NOC for Unit Alteration</h4>
                            <div className="row">
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label"> Community <span>*</span>
                                        </label>
                                        <select name="id_community" className="form-control form-select" required >
                                            <option value="">Select Community</option>
                                            <option value="6">Al Raha Gardens</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label"> Precinct <span>*</span>
                                        </label>
                                        <select name="id_precinct" className="form-control role-select form-select" required>
                                            <option value="">Select Precinct</option>
                                            <option value="1">ARG Residential</option>
                                        </select>
                                    </div>
                                </div>                   
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label"> Unit Number <span>*</span></label>
                                        <select required name="id_unit" id="srv_id_unit" className="form-control form-select">
                                            <option value="">Select Unit Number</option>
                                            <option value="7640">456</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label"> Type of Alterations <span>*</span> </label>
                                        <input type="text" name="type_of_alterations" value="" className="form-control selectpicker" id="type_of_alterations" required placeholder="Enter Type of the Alteration" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label" htmlFor="cName"> Name of Contractor <span>*</span></label>
                                        <input type="text" required className="form-control" id="cName" name="alteration_name_contractor" value="" placeholder="Name of Contractor" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label" htmlFor=""> Contact Person Name <span>*</span></label>
                                        <input type="text" required className="form-control" value="" placeholder="Contact Person Name" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label" htmlFor=""> Contact Person NUmber <span>*</span></label>
                                        <input type="text" required className="form-control" value="" placeholder="Contact Person Number" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label" htmlFor="">Email Address <span>*</span></label>
                                        <input type="email" required className="form-control" value="" placeholder="Contact Person Email" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-7">
                                        <label className="form-label" htmlFor=""> Consultant Name <span>*</span> </label>
                                        <input type="text" required className="form-control" value="" placeholder="Consultant Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label" htmlFor="attachment"> Proposed Architectural Lay-out for the Unit  </label>
                                        <input type="file" required accept=".pdf,.png,.jpeg,.jpg,.gif" name="attachment" id="attachment" className="form-control" value="" placeholder="File" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-12">
                                    <div className="mb-7">
                                        <label className="form-label" htmlFor="attachment_2">Trade License of the Appointed Contractor / Consultant</label>
                                        <input type="file" required accept=".pdf,.png,.jpeg,.jpg,.gif" name="attachment_2" id="attachment_2" className="form-control" value="" placeholder="File"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-7 textarea-responsive">
                                        <label className="form-label" htmlFor="ckeditor">
                                        Additional Notes <small>(Please Enter Notes)</small>
                                        <span>*</span>
                                        </label>
                                        <textarea className="form-control" id="ckeditor" name="comment" rows={4} placeholder="Write additional information here"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h3>Terms and Conditions</h3>
                                </div>
                                <div className="col-12">
                                    <div className="bg-light rounded my-3">
                                        <ol type="a" className="py-3 m-0">
                                            <li> Pay 210.00 AED as NOC for Unit Alteration fees (Inclusive VAT) </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-check">
                                        <input type="checkbox" name="type" value="1" id="tnc" className="form-check-input" />
                                        <label htmlFor="tnc" className="form-check-label">I hereby confirm that I have understood the terms and conditions mentioned herein and I agree to abide by the Community Rules.</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 text-end">
                                    <button type="submit" name="submit" value="submit" className="btn btn-primary">Submit</button> 
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
}