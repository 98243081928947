import React, { useState, useEffect } from 'react';
import { getMasterData } from '../../../constants/axios/apis';
import pdfImage from '../../../assets/images/documentImage.png'
import { ViewPopup } from '../../maintenance/ViewPopup';
import { useTranslation } from 'react-i18next'

export function ChequeReplaceRequest({ selectedIssue }: any) {
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [reason, setReason] = useState<any>()
  const [viewImage, setViewImage] = useState<any>()

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])

  useEffect(() => {
    if (issue) {
      getMasterData().then(({ data }) => {
        let reason = data.filter((item: any) => item.id == 21)
          .flatMap((data: any) => data.master_data)
          .find((item: any) => item.value == issue?.details?.reason)
          ?.name
        setReason(reason)
      })
    }
  }, [issue])

  const handleView = (data: any) => {
    setViewImage(data)
  }
  const { t } = useTranslation();

  return (
    <>
      <div className="com-card-section">
        {!loading && issue && issue?.code == 'CQR' ? (
          <div className="row">
            <div className="col-12">
              <div className="card mb-3">
                <div className="card-body px-8 py-6">
                  <div className="row form-add-design">
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Reason for Cheque Replace")}</label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          disabled
                          value={reason}
                        />
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Cheque Number")}</label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          disabled
                          value={issue?.details?.cheque_number}
                        />
                      </div>
                    </div>

                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Cheque Date")}</label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          disabled
                          value={issue?.details?.cheque_number}
                        />
                      </div>
                    </div>

                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Account Number")}</label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          disabled
                          value={issue?.details?.acc_number}
                        />
                      </div>
                    </div>

                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Account Holder Name")}</label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          disabled
                          value={issue?.details?.acc_holder_name}
                        />
                      </div>
                    </div>

                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Bank Name")}</label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          disabled
                          value={issue?.details?.bank}
                        />
                      </div>
                    </div>

                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Cheque Image")}</label>
                        <div className='d-flex service-req-img align-items-center'>

                          <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                            data-bs-toggle='modal'
                            data-bs-dismiss='modal'
                            type='button'
                            onClick={() => {
                              handleView(issue?.images?.cheque_img)
                            }}>
                            <img
                              src={
                                issue?.images?.cheque_img
                                  .substr(
                                    issue?.images?.cheque_img.lastIndexOf('.')
                                  ).includes('.pdf')
                                  ? pdfImage
                                  : issue?.images?.cheque_img
                              }
                              alt="document"
                              className='img-fluid me-5'
                              style={{ height: 'auto' }}
                            />
                          </button>
                          <ViewPopup viewImage={viewImage} />
                        </div>
                      </div>
                    </div>

                    {issue?.details?.description && <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Description")}</label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          disabled
                          value={issue?.details?.description}
                        />
                      </div>
                    </div>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}