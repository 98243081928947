import React, { useEffect } from 'react'
import { useState } from 'react'
import { ServiceDataInfo } from './ServiceDataInfo'
import { AttachmentPopup } from './AttachmentPopup'
import { getContractActivity } from '../../../constants/axios/apis'
import moment from 'moment'
import { SuccessfulPopup } from './SuccessfulPopup/SuccessfulPopup'
import { SignSuccessful } from './SignSuccessful/SignSuccessful'
import { SignatureModal } from './SignatureModal/SignatureModal'
import { RequestAccept } from './RequestAccept'
import { SignedContract } from './SignedContract'
import { PaymentModalPreview } from './make-an-offer-notification/PaymentModal'
import { DocumentUpload } from './DocumentUpload'
import { useTranslation } from 'react-i18next'
import { PaymentPlanDetails } from './PaymentPlanDetails/PaymentPlanDetails'

export function ContractRenewalRequest({ selectedIssue, contract }: any) {
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [contractActivity, setContractActivity] = useState<any>()
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])

  useEffect(() => {
    if (issue && issue?.code == 'CR') {
      getContractActivity(issue?.details?.contract_id, 0).then((response) => {
        if (response.data) {
          setContractActivity(response.data[0])
        }
      })
    }
  }, [issue])

  const contractActivityUpdated = () => {
    getContractActivity(issue?.details?.contract_id, 0).then((response) => {
      if (response.data) {
        setContractActivity(response.data[0])
      }
    })
  }

  return (
    <div className='com-card-section'>
      {!loading && issue && issue?.code == 'CR' ? (
        <div className='row mta-app-setting-campaign'>
          {contractActivity?.activity == 1 && <RequestAccept type={'Renewal'} />}
          {contractActivity?.activity == 3 && issue?.postDatedCheques == 'N' && issue?.payment_plan_details?.length > 0 && (
            <div className="com-card-section">
              <div className="row">
                <div className="col-12">
                  <div className="card mb-3">
                    <div className="card-body px-8 py-6">
                      <div className="row align-items-center">
                        <div className="col-lg-9 col-md-8 col-12">
                          <h4 className="font-16 fw-bold text-normal">Contract Renewal Is Paid</h4>
                          <p className="mb-0 text-normal font-12">Upload post dated cheques for upcoming payments </p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                          <button
                            className='btn btn-primary w-100'
                            onClick={() => window.scrollTo({ left: 0, top: 900, behavior: "smooth" })}
                          >
                            Upload PDC
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {contractActivity?.activity == 3 && issue?.postDatedCheques == 'Y' && (
            <DocumentUpload
              type={'Renewal'}
              contractActivity={contractActivity}
              contractActivityUpdated={contractActivityUpdated}
            />
          )}
          {contractActivity?.activity == 5 && <SignedContract type={'Renewal'} />}
          <div className='col-12'>
            <div className='accordion accordion-flush' id='accordionFlushExample'>
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header position-relative' id='flush-headingOne'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapseOne'
                    aria-expanded='false'
                    aria-controls='flush-collapseOne'
                  >
                    {t("Contract Details")}
                  </button>
                  <span className='services-badge'>
                    {issue?.status == 0 ? t("Open") : t("Closed")}
                  </span>
                </h2>

                <div
                  id='flush-collapseOne'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingOne'
                  data-bs-parent='#accordionFlushExample'
                >
                  <div className='accordion-body py-5'>
                    <div className='row form-add-design'>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Customer Name")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={issue?.details?.customer_name}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Renewal Start Date")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.details?.renewal_start_date).format('DD-MM-YYYY')}
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>{t("Renewal End Date")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.details?.renewal_end_date).format('DD-MM-YYYY')}
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>{t("Renewal Annual Amount")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={'AED ' + issue?.details?.renewal_amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            disabled
                          />
                        </div>
                      </div>
                      {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>Supporting Documents</label>
                          <div className='d-flex fdrr-rtl'>
                            <input
                              type='text'
                              name=''
                              className='form-control expected-rent-input'
                              placeholder=''
                              value='3 Attachments'
                              disabled
                            />
                            <button
                              type='submit'
                              className='bg-white cancel-bt px-7'
                              data-bs-target='#attachment-popup'
                              data-bs-toggle='modal'
                            >
                              <i className='bi bi-eye font-18 text-black'></i>
                            </button>
                          </div>
                        </div>
                        <AttachmentPopup />
                      </div> */}
                    </div>
                    <div className='col-12'>
                      <div className='accordion check-date-details' id='accordion-step-five'>
                        <PaymentPlanDetails renewDetails={issue} contract={contract} setIssue={setIssue} />
                      </div>
                    </div>
                    <hr />
                    <ServiceDataInfo issue={issue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PaymentModalPreview
            props={{
              amount: issue?.details.renewal_amount,
              payment_for: 1,
              payment_category: 5,
              property_id: issue?.details.property_id,
              contractActivityUpdated: contractActivityUpdated,
              contract: contract,
            }}
          />
          <SignatureModal />
          <SignSuccessful />
          <SuccessfulPopup />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
