import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ActivityTabs } from './Components/ActivityTabs/ActivityTabs'
import { MyVisit } from './Components/MyVisit/MyVisit'
import { MyOffer } from './Components/MyOffer/MyOffer'
import { MyLikes } from './Components/Mylikes/MyLikes'
import { MyContracts } from './Components/MyContracts/MyContracts'
import { MyActivity } from '../pt-dashboard/components/MyActivity/MyActivity'
import { MakeanOfferNotification } from '../../components/partials/layout/make-an-offer-notification/MakeanOfferNotification'
import { ExtendOfferPopup } from './Components/MyOffer/ExtendOfferPopup/ExtendOfferPopup'
import { RequestSuccessfulPopup } from '../SuccessfulPopup/RequestSuccessfulPopup'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../constants'
import * as dashboard from '../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import {MyContractsDetails} from './Components/MyContracts/MyContractsDetails'
import { MyApproval } from './Components/MyApproval/MyApproval'

interface Props {
  visitsData: any
  upcomingVisitsData: any
  likesData: any
  offerData: any
  contractData: any
}
const PTActivityPage: FC<Props> = ({
  visitsData,
  upcomingVisitsData,
  likesData,
  offerData,
  contractData,
}) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
      <div className='col-xxl-12 mt-0'>
        <Switch>
          <Route path='/pt-activity/my-visit'>
            <MyActivity
              visits={upcomingVisitsData}
              likes={likesData}
              offer={offerData}
              contract={contractData}
            />
            <ActivityTabs />
            <MyVisit visits={visitsData} upcoming={upcomingVisitsData} />
          </Route>
          <Route path='/pt-activity/my-offer'>
            <MyActivity
              visits={upcomingVisitsData}
              likes={likesData}
              offer={offerData}
              contract={contractData}
            />
            <ActivityTabs />
            <MyOffer offer={offerData} />
            {/* <MakeanOfferNotification />
            <ExtendOfferPopup />
            <RequestSuccessfulPopup /> */}
          </Route>
          <Route path='/pt-activity/my-approvals'>
            <MyActivity
              visits={upcomingVisitsData}
              likes={likesData}
              offer={offerData}
              contract={contractData}
            />
            <ActivityTabs />
            <MyApproval />
          </Route>
          <Route path='/pt-activity/my-shortlist'>
            <MyActivity
              visits={upcomingVisitsData}
              likes={likesData}
              offer={offerData}
              contract={contractData}
            />
            <ActivityTabs />
            <MyLikes likes={likesData} />
          </Route>
          <Route path='/pt-activity/my-contracts'>
            <MyActivity
              visits={upcomingVisitsData}
              likes={likesData}
              offer={offerData}
              contract={contractData}
            />
            <ActivityTabs />
            <MyContracts contract={contractData} />
          </Route>
          <Route path='/pt-activity/my-contracts-details'>
            <MyActivity
                visits={upcomingVisitsData}
                likes={likesData}
                offer={offerData}
                contract={contractData}
            />
            <ActivityTabs />
            <MyContractsDetails contract={contractData} />
          </Route>
        </Switch>
      </div>
    </div>
  </>
)

const PTActivityWrapper: FC = () => {
  let vistisList: any = []
  let upcomingVistisList: any = []
  let likesList: any = []
  let offerList: any = []
  let contractList: any = []
  vistisList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.vistis)
  upcomingVistisList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.upcomingVisits)
  likesList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.likes)
  offerList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.offerList)
  contractList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.allContract)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(dashboard.actions.vistis())
    dispatch(dashboard.actions.upcomingVisits())
    dispatch(dashboard.actions.likes())
    dispatch(dashboard.actions.offerList())
    dispatch(dashboard.actions.allContract())
  }, [])
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <PTActivityPage
        visitsData={vistisList}
        upcomingVisitsData={upcomingVistisList}
        likesData={likesList}
        offerData={offerList}
        contractData={contractList}
      />
    </>
  )
}

export { PTActivityWrapper }
