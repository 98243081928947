import { FC } from 'react'
import { Link } from 'react-router-dom'
import PayNow from '../../../../assets/images/debit-card.png'
import ChequeReplace from '../../../../assets/images/cheque_replace.png'
import ChequeDelay from '../../../../assets/images/cheque_delay.png'
import ActionDotsIoc from '../../../../assets/images/Action_dots.png'
import { useTranslation } from 'react-i18next'

type Props = {
  details?: any
}

const PaymentActionMenu: FC<Props> = ({ details }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='dropdown'>
        <button
          className='btn dropdown-toggle border-0'
          type='button'
          id='dropdownMenuButton1'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          <img src={ActionDotsIoc} className='img-fluid' />
        </button>

        <ul className='dropdown-menu actionmenu-tenant' aria-labelledby='dropdownMenuButton1'>
          <li className='p-2'>
            <Link
              to={{
                pathname: '/payments/card-payment',
                state: {
                  amount: details.amount,
                  offer_id: details.offer_id,
                  payment_type: details?.status != 0 ? details.payment_type : 2,
                  payment_for: details.payment_for,
                  payment_category: details.payment_category,
                  property_id: details.property_id,
                  upcoming_payment_id:
                    details?.status != 0 ? details.upcoming_payment_id : details.id,
                  upcoming_payment_plan_id: details?.payment_category == 1 && details?.details_json?.payment_plan_id ? details.details_json.payment_plan_id : '',
                },
              }}
              className='menu-link text-black fs-7'
            >
              <img src={PayNow} className='img-fluid me-3' />
              {t("Pay Now")}
            </Link>
          </li>
          {(details.paymentType == 2 || details.paymentType == null) && !details.is_cheque_delay_exists ? (
            <>
              {details.is_cheque_replace_exists != 1 && <li className='p-2'>
                <Link
                  to={{
                    pathname: '/payments/cheque-replace-request',
                    state: {
                      details,
                    },
                  }}
                  className='menu-link text-black fs-7'
                >
                  <img src={ChequeReplace} className='img-fluid me-3' />
                  {t("Cheque Replace Request")}
                </Link>
              </li>}
              {details.is_cheque_replace_exists != 1 && <li className='p-2'>
                <Link
                  to={{
                    pathname: '/payments/cheque-delay-request',
                    state: {
                      details,
                    },
                  }}
                  className='menu-link text-black fs-7'
                >
                  <img src={ChequeDelay} className='img-fluid me-3' />
                  {t("Cheque Delay Request")}
                </Link>
              </li>}
            </>
          ) : (
            ''
          )}
        </ul>
      </div>
    </div>
  )
}

export { PaymentActionMenu }
