import React, { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getAvailableTimings, moveOutInspectionRequest } from '../../../../constants/axios/apis'
import moment from 'moment'
import DatePicker from 'react-date-picker'
import { useTranslation } from 'react-i18next'
import { Time } from './Time'

const today = new Date()
today.setHours(0, 0, 0, 0)

type Props = {
  contract?: any
  stepStatus?: any
  getStepStatus?: any
  setMessage?: any
}

const Step1: FC<Props> = ({ contract, stepStatus, getStepStatus, setMessage }: any) => {
  const [loading, setLoading] = useState<any>(false)
  const closeRef = useRef<any>()
  const { t } = useTranslation();
  const [timeSlots, setTimeSlots] = useState<any>()

  const initialValues = {
    category_id: 15,
    category_type_id: 0,
    property_id: contract?.propertyID,
    date: today,
    time_slot: '',
    comments: '',
  }

  const moveOutRequestSchema = Yup.object().shape({
    date: Yup.date().min(today, t("Date cannot be in the past")).required(t("Please select a date")),
    time_slot: Yup.string().required(t("Please select a time slot")),
    comments: Yup.string().max(250, t("Maximum 250 characters"))
  })
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: moveOutRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        moveOutInspectionRequest(
          values.category_id,
          values.category_type_id,
          values.property_id,
          moment(values.date).format('YYYY-MM-DD'),
          values.time_slot,
          values.comments
        )
          .then((response: any) => {
            setMessage(t("Your Move Out Inspection Request has been Submitted!"))
            closeRef.current.click()
            resetForm()
            getStepStatus()
            setLoading(false)
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const handleDate = (e: any) => {
    formik.setFieldValue('date', new Date(e))
  }

  useEffect(() => {
    if (contract) {
      getAvailableTimings(
        contract.propertyID,
        moment(formik.values.date).format('YYYY-MM-DD'),
        2
      ).then(({ data }) => {
        setTimeSlots(data)
      })
    }
  }, [contract, formik.values.date])
  return (
    <div className='com-card-section w-100 p-7'>
      <div className='row'>
        <div className='col-md-12'>
          {!stepStatus.status ? (
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='row form-add-design'>
                <div className='col-md-5 col-xl-5 col-12'>
                  <div className='mb-10 date-picker'>
                    <label className='form-label fw-bolder'>{t("Pick a Date")}</label>
                    <DatePicker
                      onChange={(e: any) => handleDate(e)}
                      value={formik.values.date}
                      minDate={new Date(new Date().toLocaleDateString())}
                      className='form-control fw-bolder form-control-solid'
                      format='dd-MM-y'
                      clearIcon={null}
                      maxDate={new Date('12/31/2999')}
                    />
                    {formik.touched.date && formik.errors.date && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.date}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='mb-10'>
                    {/* <label className='form-label fw-bolder d-block mb-3'>{t("Choose Time Slot")}</label> */}

                    <div className='col-md-12 col-12'>
                      <Time timeSlots={timeSlots} formik={formik} />
                    </div>
                    {formik.touched.time_slot && formik.errors.time_slot && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.time_slot}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12'>
                  <div className=''>
                    <label className='form-label fw-bolder'>{t("Comment")}</label>
                    <textarea
                      className='form-control fw-bolder form-control-solid'
                      data-kt-autosize='true'
                      rows={3}
                      placeholder={t("Type here...")}
                      {...formik.getFieldProps('comments')}
                    />
                    {formik.touched.comments && formik.errors.comments && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.comments}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="col-12"> */}
                  {/* <div className="row"> */}
                    <div className='col-12 text-end'>
                      {contract?.isMoveInCompleted === "Y" ? <button type='submit' className='btn btn-primary fw-bold mt-10 px-10 py-3' disabled={loading}>
                        {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            {t("Please wait...")}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button> : <><button type='submit' className='btn btn-primary fw-bold mt-10 px-10 w-sm-25 w-100 py-3 ' disabled={true}>
                        {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            {t("Please wait...")}{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button><><p style={{ color: 'red', paddingRight:'6px' }}>Please complete the move in process</p></></>}
                    </div>

                  {/* </div> */}
                {/* </div> */}
              </div>
            </form>
          ) : (
            <>
              <div className='row form-add-design'>
                <div className='col-md-4 col-xl-4 col-12'>
                  <div className='mb-10'>
                    <label className='form-label fw-bolder'>{t("Date")}</label>
                    <input
                      type='text'
                      className='form-control fw-bolder form-control-solid'
                      value={moment(stepStatus?.data?.details?.date).format('DD-MM-YYYY')}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-12'>
                  <div className='mb-10'>
                    <label className='form-label fw-bolder d-block mb-3'>{t("Time Slot")}</label>
                    <div className='btn-group flex-wrap'>
                      <input
                        type='radio'
                        className='btn-check'
                        checked={true}
                      />
                      <label className='btn btn-outline-primary me-5 mb-xl-0 mb-5' htmlFor='btnradio1'>
                        {' '}
                        {stepStatus?.data?.details?.time_slot}{' '}
                      </label>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className=''>
                    <label className='form-label fw-bolder'>{t("Comment")}</label>
                    <textarea
                      className='form-control fw-bolder form-control-solid'
                      data-kt-autosize='true'
                      rows={3}
                      value={stepStatus?.data?.details?.comments}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}

export { Step1 }
