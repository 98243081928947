import React,{ useState,useEffect } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {BrokerActivityTabs} from './BrokerActivityTabs';
import {BarChartForBroker} from './BarChartForBroker'
import { useSelector } from 'react-redux';
import { getInvoiceList } from '../../../utils/makeRequest';
import { IState } from '../../../utils/interface';
import Loader from '../../../components/Loader';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

interface IProps {
    brokersList: IState["APIState"]
}


export const TotalBrokersActivity: React.FC<IProps> = ({brokersList}) => {
    const state:any = useSelector(state => state);

    const userToken = state?.auth?.accessToken;
    const { t } = useTranslation();

    if(userToken === "undefined"){
        // token undefined
    }

    const [invoiceList, setInvoiceList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });




    const {loading, response: invoices, error} = invoiceList;
    useEffect(()=>{
        getInvoiceList(setInvoiceList,userToken);
    },[]);
    function yearMonths(){
        return ["January","February","March","April","May","June","July","August","September","October","November","December"];
    }
    function getInvoiceMonths(invoices: any[],status: string){
        const months = yearMonths();

        let invoiceMonth = new Set(
            invoices.filter(invoice=>invoice.status === status).map(invoice => {
                let date = new Date(invoice.invoiceDate);
                return date.getMonth()
            }).sort().map(d=>{
                return months[d];
            })
        );
        let invoiceMonths: any[] = [];
        
        invoiceMonth.forEach(function(month: any) {
            invoiceMonths.push(month);
        });

        return invoiceMonths;
    }

    function getTotalInvoiceByMonth(invoices: any[], status: string, invoiceMonths: any[]){
        const months = yearMonths();
        const returnArray: any[] = [];

        let invoice = invoices.filter(invoice=>invoice.status === status)

        invoiceMonths.forEach (function(month: any) {
            let totalInvoice = invoice.filter(inv=>{
                let d = new Date(inv.invoiceDate);

                return months[d.getMonth()] === month;
                //let invoiceMonth = d.getMonth();
            });
            returnArray.push(totalInvoice.length);
        })
        return returnArray;
    }


    const [chartDataPending, setChartDataPending] = useState({
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'In-Progress',
                data: [],
                backgroundColor: '#002C6A',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }
        ],
    });
    const [chartDataApproved, setChartDataApproved] = useState({
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'In-Progress',
                data: [],
                backgroundColor: '#002C6A',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }
        ],
    });
    const [chartDataRejected, setChartDataRejected] = useState({
        labels: [],
        datasets: [
            {
                datalabels: {
                    color: "#EAEFF5",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                label: 'In-Progress',
                data: [],
                backgroundColor: '#002C6A',
                barThickness: 45,
                maxBarThickness: 45,
                borderRadius: {
                    bottomRight: 5,
                    bottomLeft: 5,
                    topRight: 5,
                    topLeft: 5,
                }
            }
        ],
    });


    function setChartData(setState: any,label: any,data: any): void{
        setState({
            labels: label,
            datasets: [
                {
                    datalabels: {
                        color: "#EAEFF5",
                        font: {
                            size: 16,
                            family: "'Poppins', sans-serif",
                            lineHeight: 0.5,
                        },
                    },
                    label: 'In-Progress',
                    data: data,
                    backgroundColor: '#002C6A',
                    barThickness: 45,
                    maxBarThickness: 45,
                    borderRadius: {
                        bottomRight: 5,
                        bottomLeft: 5,
                        topRight: 5,
                        topLeft: 5,
                    }
                }
            ],
        })
    }

    useEffect(()=>{
        if(Array.isArray(invoices)){
            if(invoices.length>0){
                let pendingInvoiceMonth = getInvoiceMonths(invoices, "Pending");
                let totalPendingInvoiceByMonth = getTotalInvoiceByMonth(invoices, "Pending", pendingInvoiceMonth);
                setChartData(setChartDataPending, pendingInvoiceMonth, totalPendingInvoiceByMonth);
                
                let approvedInvoiceMonth = getInvoiceMonths(invoices, "Approved");
                let totalApprovedInvoiceByMonth = getTotalInvoiceByMonth(invoices, "Approved", approvedInvoiceMonth);
                setChartData(setChartDataApproved, approvedInvoiceMonth, totalApprovedInvoiceByMonth);

                let rejectedInvoiceMonth = getInvoiceMonths(invoices, "Rejected");
                let totalRejectedInvoiceByMonth = getTotalInvoiceByMonth(invoices, "Rejected", rejectedInvoiceMonth);
                setChartData(setChartDataRejected, rejectedInvoiceMonth, totalRejectedInvoiceByMonth);
                
           /*      console.log('--------------------------');
                console.log('pendingInvoiceMonth',pendingInvoiceMonth);
                console.log('totalPendingInvoiceByMonth',totalPendingInvoiceByMonth);
                console.log('--------------------------');
                console.log('approvedInvoiceMonth',approvedInvoiceMonth);
                console.log('totalApprovedInvoiceByMonth',totalApprovedInvoiceByMonth);
                console.log('--------------------------');
                console.log('rejectedInvoiceMonth',rejectedInvoiceMonth);
                console.log('totalRejectedInvoiceByMonth',totalRejectedInvoiceByMonth);
 */

                /* console.log('pendingInvoice',pendingInvoice);
                console.log('rejectedInvoice',rejectedInvoice); */
            }
        }
    },[invoices]);

   
    return (
        <div className="">
            <div className="row">
                <div className="col-lg-12">
                    <h4 className="card-title font-17 mb-0 mt-5"> {t("Activity Progress")}</h4>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-12">
                    <div className="card brokers-count rounded-10">
                        <div className="card-body px-10 py-0">
                            <div className="row">
                                <div className="col-12">
                                    <Switch>
                                        <Route path='/brokers/in-progress'>
                                            <BrokerActivityTabs brokersList={brokersList}/>
                                            <BarChartForBroker data={chartDataPending}/>
                                        </Route>
                                        <Route path='/brokers/approved'>
                                            <BrokerActivityTabs brokersList={brokersList}/>
                                            <BarChartForBroker data={chartDataApproved}/>
                                        </Route>
                                        <Route path='/brokers/rejected'>
                                            <BrokerActivityTabs brokersList={brokersList}/>
                                            <BarChartForBroker data={chartDataRejected}/>
                                        </Route>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}