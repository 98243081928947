import React, { useState, FC, useEffect } from 'react'
import { LeaseDetails } from '../LeaseDetails/LeaseDetails'
import { PermitToWork } from './PermitToWork/PermitToWork';
import { HelipadRooftopAccess } from './HelipadRooftopAccess/HelipadRooftopAccess';
import { FireProtectionImpairment } from './FireProtectionImpairment/FireProtectionImpairment';
import { getMasterData } from '../../../../../constants/axios/apis';
import completedStep from '../../../../../assets/images/completed-step.png'
import { useTranslation } from 'react-i18next'

type Props = {
    contract?: any
    getStepStatus?: any
    stepStatus?: any
}

const StepSix: FC<Props> = ({ contract, getStepStatus, stepStatus }) => {
    const [activeButton, setActiveButton] = useState(0);
    const [masterData, setMasterData] = useState<any>([])
    const { t } = useTranslation();

    useEffect(() => {
        getMasterData().then(({ data: masterData }) => {
            setMasterData(masterData)
        })
    }, [])

    return (
        <div className="com-card-section w-100 p-7">
            <div className="row">
                <div className="col-md-12 mb-10">
                    <LeaseDetails contract={contract} />
                </div>

                <div className="col-md-12">
                    <div className="row mb-5">
                        <div className="col-4">
                            <button
                                className={`btn ${activeButton === 0 ? "btn-primary" : "btn-outline-secondary"} fw-bold px-10 py-3 w-100`}
                                onClick={() => setActiveButton(0)}
                            >
                                {t("Permit to Work")}
                                {(stepStatus?.permitToWorkRequestStatus) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </div>
                        <div className="col-4">
                            <button
                                className={`btn ${activeButton === 1 ? "btn-primary" : "btn-outline-secondary"} fw-bold px-10 py-3 w-100`}
                                onClick={() => setActiveButton(1)}
                            >
                                {t("Helipad and Rooftop Access")}
                                {(stepStatus?.helipadAccessRequestStatus) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </div>
                        <div className="col-4">
                            <button
                                className={`btn ${activeButton === 2 ? "btn-primary" : "btn-outline-secondary"} fw-bold px-10 py-3 w-100`}
                                onClick={() => setActiveButton(2)}
                            >
                                {t("Fire Protection Impairment")}
                                {(stepStatus?.fireProtectionRequestStatus) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </div>
                    </div>

                    <div className="row">

                        {activeButton === 0 ?
                            <PermitToWork masterData={masterData} getStepStatus={getStepStatus} stepStatus={stepStatus} />
                            : activeButton === 1 ?
                                <HelipadRooftopAccess masterData={masterData} getStepStatus={getStepStatus} stepStatus={stepStatus} /> :
                                <FireProtectionImpairment masterData={masterData} getStepStatus={getStepStatus} stepStatus={stepStatus} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export { StepSix }
