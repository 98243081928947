import React, { useState, useEffect, useRef } from 'react';
import PlusIcon from "../../../../../assets/images/plus-circle.svg";
import { useTranslation } from 'react-i18next'

export function AcceptOfferPopUp(props: any) {
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };

    return (
        <div className="modal fade custom-modal create-campaign-popup tenant-info-popup" id="accept-offer-popup">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content rounded pt-5">
                    <div className="modal-header p-0 justify-content-start rounded-0 mb-2">
                        <h5 className="modal-title fw-bold font-14">
                            {t("Accept Offer")}
                        </h5>
                        <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                    </div>
                    <div className="modal-body text-start">
                        <div className="row">
                            <div className="col-12">
                                <h5 className="modal-title fw-bold font-18 mb-6 accept-offer">
                                    {t("Signed Documents to Upload")}
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="mb-3 upload-input">
                                    <label className="form-label font-14 fw-normal d-block mb-5 accept-offer">{t("Valid Trading License")}</label>
                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                    <button onClick={handleUpload}
                                        className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                        <span> {uploadedFileName ? uploadedFileName :
                                        <img src={PlusIcon} className="img-fluid va-baseline"/>} </span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="mb-3 upload-input">
                                    <label className="form-label font-14 fw-normal d-block mb-5 accept-offer">{t("Chamber of commerce Certificate")}</label>
                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                    <button onClick={handleUpload}
                                            className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                        <span> {uploadedFileName ? uploadedFileName :
                                            <img src={PlusIcon} className="img-fluid va-baseline"/>} </span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="mb-3 upload-input">
                                    <label className="form-label font-14 fw-normal d-block mb-5 accept-offer">{t("Power of Attorney")}</label>
                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                    <button onClick={handleUpload}
                                            className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                        <span> {uploadedFileName ? uploadedFileName :
                                            <img src={PlusIcon} className="img-fluid va-baseline"/>} </span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="mb-3 upload-input">
                                    <label className="form-label font-14 fw-normal d-block mb-5 accept-offer">{t("Authorization Letter")}</label>
                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                    <button onClick={handleUpload}
                                            className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                        <span> {uploadedFileName ? uploadedFileName :
                                            <img src={PlusIcon} className="img-fluid va-baseline"/>} </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h5 className="modal-title fw-bold font-18 my-6 accept-offer">
                                    {t("Other Documents to Upload")}
                                </h5>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-6 col-12">
                                <div className="mb-3 upload-input">
                                    <label className="form-label font-14 fw-normal d-block mb-5 accept-offer">{t("Passport Copy")}</label>
                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                    <button onClick={handleUpload}
                                            className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                        <span> {uploadedFileName ? uploadedFileName :
                                            <img src={PlusIcon} className="img-fluid va-baseline"/>} </span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="mb-3 upload-input">
                                    <label className="form-label font-14 fw-normal d-block mb-5 accept-offer">{t("Residence Visa")}</label>
                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                    <button onClick={handleUpload}
                                            className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                        <span> {uploadedFileName ? uploadedFileName :
                                            <img src={PlusIcon} className="img-fluid va-baseline"/>} </span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="mb-3 upload-input">
                                    <label className="form-label font-14 fw-normal d-block mb-5 accept-offer">{t("Emirates ID")}</label>
                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                    <button onClick={handleUpload}
                                            className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                        <span> {uploadedFileName ? uploadedFileName :
                                            <img src={PlusIcon} className="img-fluid va-baseline"/>} </span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="mb-3 upload-input">
                                    <label className="form-label font-14 fw-normal d-block mb-5 accept-offer">{t("TRN Letter")}</label>
                                    <input ref={inputRef} onChange={handleDisplayFileDetails} className="d-none" type="file"/>
                                    <button onClick={handleUpload}
                                            className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileName ? "primary active" : "primary"}`}>
                                        <span> {uploadedFileName ? uploadedFileName :
                                            <img src={PlusIcon} className="img-fluid va-baseline"/>} </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary fw-bold px-10 py-3 w-100">{t("Submit")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}