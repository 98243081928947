import LocationImg from '../../../../assets/images/icon_place.svg'
import TickIcon from '../../../../assets/images/Check_green.png'
import SquareFt from '../../../../assets/images/icon-sqft.svg'
import Bed from '../../../../assets/images/icon-bed.svg'
import Bath from '../../../../assets/images/icon-bath.svg'
import Cars from '../../../../assets/images/icon-parking.svg'
import NoDataFoundImage from '../../../../assets/images/no-data-found.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import moment from 'moment'
import { NoDataFound1, currencyFormater, validateCommercialBed } from '../../../../constants/generics/customActios'

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

export function MyVisits(props: any) {
	const { visits } = props
	const { t } = useTranslation();

	const filterVisits = visits?.data && Array.isArray(visits?.data) ? visits?.data?.filter((item: any, i: any) => ([1, 3, 4].includes(item?.event_status) && item?.visit_date >= moment().format('YYYY-MM-DD'))) : []

	return (
		<div className='com-card-section pt-my-upcoming-visits mb-7'>
			<div className='row align-items-center'>
				<div className='col-md-6 col-8'>
					<h4 className='card-title'> {t("My Upcoming Visits")} </h4>
				</div>
				{visits?.errorCode != 1 && visits && filterVisits?.length ?
					<div className='col-md-6 col-4 text-end'>
						<a href='/pt-activity/my-visit/upcoming' className='text-decoration-none view-all'>
							<span> {t("View All")} </span>
							{/*<img src={RightArrow} className="img-fluid" />*/}
						</a>
					</div>
					: <></>
				}
			</div>
			<div className='upcoming-work-order-sec my-visits-sec'>
				<div className='row'>
					<div className='col-12'>
						<div className='row'>
							{visits?.errorCode != 1 && visits && filterVisits?.length
								? filterVisits?.slice(0, 2)?.map((item: any, i: any) => {
									// if( item?.visit_date >= moment().format('YYYY-MM-DD'))
									// if (item?.event_status === 3 || item?.event_status === 4)
									{
										return (
											<div className='col-md-6 col-12'>
												<Link to={'/pt-activity/my-visit/upcoming'}>
													<div className='card mt-4'>
														<div className='card-body p-0'>
															<div className='row'>
																<div className='col-12'>
																	<div className='img-bg'>
																		<img
																			src={item?.property_cover_img}
																			className='w-100 img-fluid position-relative'
																		/>
																	</div>
																</div>
																<div className='col-12'>
																	<div className='p-4'>
																		<h4 className='card-title mb-2'>
																			{item?.property_name}
																		</h4>
																		{/*<span className='price-amount d-block'>
																			{t("AED")} <strong>{currencyFormater(item?.price)}</strong> / {t("Year")}
																		</span>*/}
																		<span className='location-color py-1'>
																			<img src={LocationImg} width="12" height="17" className='me-2' />
																			{item?.propertyCode?.substring(item?.propertyCode?.lastIndexOf("-") + 1, item?.propertyCode?.length) + ' | '} {item?.buildingName && item?.buildingName + ' | '}  {item?.address && item?.address + ' | '}  {item?.location && item?.location}
																		</span>

																		<div className='d-flex flex-wrap mt-4'>
																			{(!validateCommercialBed || item?.property_search_type != 2) &&
																				item?.bedrooms?.length > 0 && item?.bedrooms != 0 ? (
																				<div className='me-3 mb-2'>
																					<span className='square-feet'>
																						<img src={Bed} /> {item?.bedrooms} Bedrooms
																					</span>
																				</div>
																			) : (
																				<></>
																			)
																			}
																			{item?.bathrooms?.length > 0 && item?.bathrooms != 0 ? (
																				<div className='me-3 mb-2'>
																					<span className='square-feet'>
																						<img src={Bath} /> {item?.bathrooms} Bothrooms
																					</span>
																				</div>
																			) : (
																				<></>
																			)
																			}
																			{item?.builtInArea > 0 && item?.builtInArea != 0 ? (
																				<div className='me-3 mb-2'>
																					<span className='square-feet'>
																						<img src={SquareFt} /> {item?.built_in_area != null ? item?.built_in_area : item?.builtInArea}{' '}
																						{t("Sqmt")}
																					</span>
																				</div>
																			) : (
																				<></>
																			)
																			}
																			<div className='mb-2'>
																				<span className='square-feet'>
																					<img src={Cars} /> {item?.isParkingAvailable === 'Y' ? t("Yes") : t("No")}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</Link>
											</div>
										)
									}
									// else{
									// 	if(i ===0)
									// 	return <NoDataFound1/>
									// }
								})
								: <div className='col-12 mt-3'>
									<div className='card'>
										<div className='card-body'>
											<NoDataFound1 />
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
