import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useSelector } from 'react-redux';
import {useIntl} from 'react-intl'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import anounceImg from '../../../../assets/images/bg_1.jpg'
import { formatDate, formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater';
import { getUserRole } from '../../../../constants/generics/userCheck';
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import { getAnnouncements } from '../../../../constants/axios/apis';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import AnnounceBackupImg from '../../../../assets/images/announcement-img.png'

export function AnnouncementsDetails() {
    const intl = useIntl()
    const location = useLocation<any>()
    const [menu, setMenu] = useState('')
    const [announcement, setAnnouncement] = useState<any>({})

    const state: any = useSelector(state => state);
    const user_role = getUserRole(state?.auth?.user?.data);
    // let announcementID = ''
    // if(location?.state?.announcementID)
    //     announcementID = location?.state?.announcementID
    const { t } = useTranslation();
    
    // useEffect(() => {
    //     setAnnouncement(location?.state?.item)
    // }, [location.key])
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        if(location?.state?.announcementID && location?.state?.announcementID !== ''){
            setLoading(true)
            setAnnouncement({})
            getAnnouncements(`?id=${location?.state?.announcementID}`).then((response: any) => {
                if(response?.errorCode === 0 && response?.data && Array.isArray(response.data) && response.data?.[0]){
                    setAnnouncement(response.data[0])
                }
                setLoading(false)
            })
        }
    }, [location?.state?.announcementID]);

    let userEmail = ''
    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
        userEmail = JSON.parse(userDetail).email
        userRole = JSON.parse(userDetail).id_role
    }

    useEffect(() => {
        if (window.location.pathname != menu) {
            setMenu(window.location.pathname)
        }
    }, [])

    const backLink = (user_role === 'T') ? '/announcements'
        : (user_role === 'FC' || user_role ==='FCS' || user_role === 'FMT') ? '/su-announcement'
        : (user_role === 'FCSS' || user_role === 'FMSM') ? '/fc-ss-announcements/list' : '/';
    
    // const banner_img = announcement?.cover_image ? announcement?.cover_image : announcement?.categoryIcon
    const banner_img = announcement?.cover_image && (typeof announcement?.cover_image === 'string') ? announcement?.cover_image : AnnounceBackupImg

    return (
        <div className="col-12">
            { (user_role && ['T', 'FC', 'FCS', 'FCSS', 'FMT', 'FMSM'].includes(user_role)) ? (
                <div className="row mt-n5">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <h4 className="card-title font-18 text-black mb-7">
                                    <Link to={'/communitywall/page'} className=''>
                                        <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                        <span className="text-black"> {t("Announcement")} </span>
                                    </Link>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body px-8 py-8">
                                {
                                    loading ? <CustomLoader2 />:
                                        announcement && Object.keys(announcement).length ?
                                        <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="announcement-main-images mb-10">
                                                        <img src={banner_img} className="" alt='announcement-img'/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row mb-3">
                                                        <div className="col-12">
                                                            <h4 className="card-title font-20 text-black mb-7">
                                                                {announcement?.title}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <label className="text-theme-gray mb-4 fw-normal font-14"> {t("Building")} : </label>
                                                            <span className="text-black font-15"> {announcement?.building_name} </span>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="text-theme-gray mb-4 fw-normal font-14"> {t("Date")} : </label>
                                                            {announcement?.endDate && (moment(announcement?.date).diff(moment(announcement?.endDate), 'days') !== 0)? 
                                                                <span className="text-black font-15"> {t("From")} <span className="fw-bolder">{formatDate(announcement?.date)}</span> {t("To")} <span className="fw-bolder">{formatDate(announcement?.endDate)}</span> </span>
                                                                :
                                                                <span className="text-black font-15"> {t("From")} <span className="fw-bolder">{formatDate(announcement?.date)}</span> {t("Onward")}</span>
                                                            }
                                                            {/* <span className="text-black fw-bold font-15"> {formatDate(announcement?.date)}</span> */}
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="text-theme-gray mb-4 fw-normal font-14"> {t("Time")} : </label>
                                                            <span className="text-black font-15"> {t("From")} <span className="fw-bolder">{formatTimeMeridiem(announcement?.start_time)}</span> {t("To")} <span className="fw-bolder">{formatTimeMeridiem(announcement?.end_time)}</span> </span>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="text-theme-gray mb-0 fw-normal font-14"> {t("Description")} : </label>
                                                            <span className="text-black font-15"> {announcement?.announcement}</span>
                                                        </div>

                                                        {/* <div className="col-sm-6 col-md-3 col-12">
                                                            <div className="">
                                                                <h6 className="text-theme-gray mb-4 fw-normal font-14"> {t("Category")} </h6>
                                                                <span className="text-black fw-bold font-15"> {announcement?.category_value} </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-3 col-12">
                                                            <div className="">
                                                                <h6 className="text-theme-gray mb-4 fw-normal font-14"> {t("Building")} </h6>
                                                                <span className="text-black fw-bold font-15"> {announcement?.building_name} </span>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-sm-4 col-12">
                                                            <div className="">
                                                                <h6 className="text-theme-gray mb-4 fw-normal font-14"> Attachments </h6>
                                                                <div className='d-flex'>
                                                                    <div className='bg-light rounded-pill me-3 text-center py-1 px-5'>
                                                                        <p className='mb-0'>
                                                                            <i className='bi bi-image fonts-1 me-3'></i>
                                                                            IMG13145
                                                                        </p>
                                                                    </div>
                                                                    <div className='bg-light rounded-pill me-3 text-center py-1 px-5'>
                                                                        <p className='mb-0'>
                                                                            <i className='bi bi-image fonts-1 me-3'></i>
                                                                            IMG13145
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <NoDataFound1 />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ) : userRole === 15 ?(
                <div className="row mt-n5">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <h4 className="card-title font-18 text-black mb-7">
                                    <Link to={'/'} className=''>
                                        <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                        <span className="text-black">
                                            {t("Announcements (CT)")}
                                        </span>
                                    </Link>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body px-8 py-8">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="announcement-main-images mb-10">
                                            <img src={anounceImg} className="" alt='announcement-img'/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row mb-10">
                                            <div className="col-12">
                                                <h4 className="card-title font-18 text-black mb-7">
                                                    {t("Description")}
                                                </h4>
                                                <p>Lorem Ipsum és un text de farciment usat per la indústria de la ti
                                                    pografiai la impremta. Lorem Ipsum ha estat el text estàndard de la indústria des de l'any 1500,
                                                    quan un impressor desconegut va fer servir una g
                                                    alerada de text i la va mesclar per crear un llibre de mostres tipogràfiques.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-sm-4 col-12">
                                                <div className="">
                                                    <h6 className="text-theme-gray mb-4 fw-normal font-14"> {t("Date")} </h6>
                                                    <span className="text-black fw-bold font-15"> Marina Mall </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-12">
                                                <div className="">
                                                    <h6 className="text-theme-gray mb-4 fw-normal font-14"> {t("Time")} </h6>
                                                    <span className="text-black fw-bold font-15"> 10:30AM - 12:45PM </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-12">
                                                <div className="">
                                                    <h6 className="text-theme-gray mb-4 fw-normal font-14"> {t("Attachments")} </h6>
                                                    <div className='d-flex'>
                                                        <div className='bg-light rounded-pill me-3 text-center py-1 px-5'>
                                                            <p className='mb-0'>
                                                                <i className='bi bi-image fonts-1 me-3'></i>
                                                                IMG13145
                                                            </p>
                                                        </div>
                                                        <div className='bg-light rounded-pill me-3 text-center py-1 px-5'>
                                                            <p className='mb-0'>
                                                                <i className='bi bi-image fonts-1 me-3'></i>
                                                                IMG13145
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}