import React, { useState, useEffect, useRef } from 'react';
import CheckGreen from "../../assets/images/Check_green_lg.png";
import { useTranslation } from 'react-i18next'

export const CustomRequestSuccessfulPopup:React.FC<any> = ({successMessage, href='', customID='', close=true}) => {
    const { t } = useTranslation();

    return (
        <div className="modal fade custom-modal" id={"req-submitted-popup"+customID}>
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                    { close &&
                        <div className="modal-header bg-white border-0">
                            <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                        </div>
                    }
                    <div className="modal-body py-10">
                        <div className="row g-5">
                            <div className="col-md-12">
                                <div className="text-center mx-md-12">
                                    <div className="redeem-offer-QR">
                                        <img src={CheckGreen} alt="tick-img" className="img-fluid mb-5" />
                                        <h1 className="text-theme font-24 mb-2 fw-bold">{t("Successful")}</h1>
                                        <p className="mb-3 font-12 fw-bolder">
                                            {successMessage}
                                        </p>
                                        {href !== '' &&
                                            <button 
                                                onClick={()=>{
                                                    setTimeout(()=>{
                                                        // window.location.replace(href?href:"/workorders/open/corrective");
                                                        window.location.replace(href);
                                                    },300)
                                                }}
                                                data-bs-dismiss="modal" className="btn btn-primary btn-lg fw-bolder font-12 w-100">
                                                {t("Continue")}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}