import React, { FC, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { MyteamBrokersList } from './components/MyteamBrokersList/MyteamBrokersList'
/* import {RecentInvoices} from './components/RecentInvoices/RecentInvoices' */
import { RecentInvoices } from '../broker-admin-dashboard/components/RecentInvoices/RecentInvoices'
import { TotalBrokerChart } from './components/TotalBrokerChart/TotalBrokerChart'
import RightArrow from "../../assets/images/Right_Arrow.png";
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { getBrokers } from '../../utils/makeRequest';
import { IState } from '../../utils/interface';
import { useTranslation } from 'react-i18next'
import { AddTeamPopup } from './components/AddTeamPopup/AddTeamPopup';

const BrokerAdminMyTeamPage: FC = () => {

    const state: any = useSelector(state => state);
    const userToken = state?.auth?.accessToken;
    const [brokersList, setBrokersList] = useState<IState['APIState']>({
        loading: false,
        response: {},
        error: ''
    });

    const { loading, response: brokers, error } = brokersList;

    useEffect(() => {
        getBrokers(setBrokersList, userToken);
    }, []);

    const { t } = useTranslation();

    return (
        <>
            <div className='row gy-5 g-xl-8 broker-team-page dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <div className="com-card-section">
                        <div className="row">
                            <div className="col-12">
                                <div className="card mb-6">
                                    <div className="card-body p-7">
                                        <div className="row mb-4">
                                            <div className="col-lg-6 col-md-6 col">
                                                <h4 className="card-title all_card_title"> {t("My Team")} </h4>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-auto text-end">
                                                <Link to={""} data-bs-toggle="modal" data-bs-target="#addBroker" className="btn btn-primary btn-sm font-16">
                                                    <i className="bi bi-plus-circle-fill align-middle"></i> {t("Add Broker")}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-7 col-12">
                                                <MyteamBrokersList />
                                            </div>
                                            <div className="col-lg-5 col-12">
                                                <TotalBrokerChart brokersList={brokersList} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-body p-7">
                                        <div className="row mb-4">
                                            <div className="col-md-6 col-7">
                                                <h4 className="card-title"> {t("Recent Invoices")} </h4>
                                            </div>
                                            <div className="col-md-6 col-5 text-end">
                                                <Link to={'/statements/inprogress-invoices'} className="text-decoration-none view-all d-block mt-2 text-end">
                                                    <span> {t("View All")} </span>
                                                    <img src={RightArrow} className="img-fluid" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <RecentInvoices />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddTeamPopup />
        </>
    )
};

const BrokerAdminMyTeamWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <BrokerAdminMyTeamPage />
        </>
    )
};

export { BrokerAdminMyTeamWrapper }
