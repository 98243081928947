import React, {useState, useEffect, FC} from 'react'
import {useIntl} from 'react-intl'
import { ChequesList } from './components/ChequesList'
import { ReplacePopup } from './components/ReplaceModal'
import { PopstponePopup } from './components/PostponeModal'

const BrokerAdminChequesWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <ChequesList/>
      <ReplacePopup/>
      <PopstponePopup/>
    </>
      
  )
}

export {BrokerAdminChequesWrapper}
