import React, { useState, useRef, FC, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import PlusIcon from "../../../../../../assets/images/plus-circle.svg";
import * as Yup from 'yup'
import { useFormik } from 'formik';
import completedStep from '../../../../../../assets/images/completed-step.png'
import { ViewPopup } from '../../../../../maintenance/ViewPopup';
import pdficon from '../../../../../../assets/images/pdf-file.png'
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsPopup } from '../../../../../terms-and-conditions/TermsAndConditionsPopup'
import { validateAndCompressImage } from '../../../../../../constants/generics/fileUploadHandler';
import { Alert } from 'react-bootstrap-v5';

type Props = {
    setDocuments?: any
    stepStatus?: any
    ref3?: any
}

const UploadRequiredDocuments: FC<Props> = ({ setDocuments, stepStatus, ref3 }) => {
    const { t } = useTranslation();
    const sigCanvas = useRef<any>();
    const [uploading, setUploading] = useState<boolean>(false)
    const [inputBeingUploaded, setInputBeingUploaded] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const [viewImage, setViewImage] = useState<any>()
    const [uploadedFileName1, setUploadedFileName1] = useState<string | null>(null);
    const [uploadedFileName2, setUploadedFileName2] = useState<string | null>(null);
    const [uploadedFileName3, setUploadedFileName3] = useState<string | null>(null);
    const [uploadedFileName4, setUploadedFileName4] = useState<string | null>(null);
    const [uploadedFileName5, setUploadedFileName5] = useState<string | null>(null);
    const [uploadedFileName6, setUploadedFileName6] = useState<string | null>(null);
    const [uploadedFileName7, setUploadedFileName7] = useState<string | null>(null);
    const [uploadedFileName8, setUploadedFileName8] = useState<string | null>(null);

    const inputRef1 = useRef<HTMLInputElement>(null)
    const inputRef2 = useRef<HTMLInputElement>(null)
    const inputRef3 = useRef<HTMLInputElement>(null)
    const inputRef4 = useRef<HTMLInputElement>(null)
    const inputRef5 = useRef<HTMLInputElement>(null)
    const inputRef6 = useRef<HTMLInputElement>(null)
    const inputRef7 = useRef<HTMLInputElement>(null)
    const inputRef8 = useRef<HTMLInputElement>(null)

    const handleDisplayFileDetails = (reference: any, setUploadedFileName: any, fieldName: string) => {
        const inputFile = reference.current?.files?.[0]
        let isAllowedType: any = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(inputFile?.type)

        if (inputFile && isAllowedType) {
            if (inputFile.type === 'application/pdf') {
                if (inputFile.size > 5242880) {
                    setError(true)
                    setErrorMessage(t('The file is too large! Max allowed size is 5MB.'))
                    setTimeout(() => { setError(false) }, 3000)
                } else {
                    setUploadedFileName(inputFile.name)
                    formik.setFieldTouched(fieldName)
                    formik.setFieldValue(fieldName, inputFile)
                }
            } else {
                setUploading(true)
                setInputBeingUploaded(fieldName)
                setUploadedFileName()
                try {
                    validateAndCompressImage(inputFile).then((compressResponse) => {
                        if (compressResponse?.success) {
                            setUploadedFileName(inputFile.name)
                            formik.setFieldTouched(fieldName)
                            formik.setFieldValue(fieldName, inputFile)
                        } else {
                            setError(true)
                            setErrorMessage(t(compressResponse?.message || 'File compression error!'))
                            setTimeout(() => { setError(false) }, 3000)
                        }
                        setUploading(false)
                    })
                } catch (error) {
                    alert(t('File upload error!'))
                    setError(true)
                    setErrorMessage(t('File upload error!'))
                    setTimeout(() => { setError(false) }, 3000)
                    setUploading(false)
                }
            }
        } else {
            setError(true)
            setErrorMessage(t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'))
            setTimeout(() => {
                setError(false)
            }, 3000)
        }
    }

    const initialValues = {
        undertaking_letter: '',
        risk_assessment: '',
        local_authority_approval: '',
        emirates_id: '',
        trade_license: '',
        method_of_statement: '',
        crew_list: '',
        other_documents: '',
        permit_applicant: '',
        terms: false,
        signature: '',
    }

    const handelSubmit = () => {
        setSaved(false)
        setDocuments(null)
        formik.handleSubmit()
    }

    const documentUploadSchema = Yup.object().shape({
        undertaking_letter: Yup.mixed()
            .required(t("Undertaking Letter is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        risk_assessment: Yup.mixed()
            .required(t("Risk Assessment is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        local_authority_approval: Yup.mixed()
            .required(t("Local Authority Approval is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        emirates_id: Yup.mixed()
            .required(t("Emirates ID is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        trade_license: Yup.mixed()
            .required(t("Trading License is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        method_of_statement: Yup.mixed()
            .required(t("Method Of Statement is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        crew_list: Yup.mixed()
            .required(t("Crew List Letter is required"))
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        other_documents: Yup.mixed()
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
                if (!value) return true
                return (
                    value &&
                    (value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            })
            .nullable(),
        permit_applicant: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Permit Applicant name is required"))
            .test('filled', t("Permit Applicant name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Permit Applicant name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        terms: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
        signature: Yup.string().required(t("Please add your signature")),
    })

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: documentUploadSchema,
        onSubmit: (values) => {
            setDocuments({
                undertaking_letter: values.undertaking_letter,
                risk_assessment: values.risk_assessment,
                local_authority_approval: values.local_authority_approval,
                emirates_id: values.emirates_id,
                trade_license: values.trade_license,
                method_of_statement: values.method_of_statement,
                crew_list: values.crew_list,
                other_documents: values.other_documents,
                permit_applicant: values.permit_applicant,
                terms: values.terms,
                signature: values.signature,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref3.current.click()
        },
    })

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setDocuments((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    const handleSignature = () => {
        const canvas = sigCanvas.current.getCanvas()
        canvas.toBlob((blob: string) => {
            formik.setFieldValue('signature', blob)
        }, 'image/png')
    }

    function clearSignature() {
        sigCanvas.current.clear()
        formik.setFieldValue('signature', '')
    }

    const handleView = (data: any) => {
        setViewImage(data)
    }

    return (
        <>
            <div className="row">
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className="col-12">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingSeven">
                                <button className="accordion-button collapsed" ref={ref3} type="button" data-bs-toggle="collapse" data-bs-target="#upload-required">
                                    {t("Upload Required Documents")}
                                    {(saved) &&
                                        <span className='completed-step ps-3 pe-3 d-inline-block'>
                                            <img src={completedStep} alt='complete-step' />
                                        </span>}
                                </button>
                            </h2>

                            {error ? (
                                <Alert className='font-14 fw-bold mb-8' variant='danger'>
                                    {errorMessage}!
                                </Alert>
                            ) : null}

                            <div id="upload-required" className="accordion-collapse collapse" data-bs-parent="#accordion-step-five">
                                {stepStatus?.permitToWorkRequestStatus ? (
                                    <>
                                        <div className="accordion-body pb-5 pt-0">
                                            <div className="row documents-card form-add-design">
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Undertaking Letter")}</label>
                                                        <div className='d-flex service-req-img align-items-center'>
                                                            {stepStatus?.data?.permitToWork?.images?.undertaking_letter && (
                                                                <>
                                                                    <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-dismiss='modal'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            handleView(stepStatus?.data?.permitToWork?.images?.undertaking_letter)
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                stepStatus?.data?.permitToWork?.images?.undertaking_letter
                                                                                    .substr(
                                                                                        stepStatus?.data?.permitToWork?.images?.undertaking_letter.lastIndexOf('.')
                                                                                    ).includes('.pdf')
                                                                                    ? pdficon
                                                                                    : stepStatus?.data?.permitToWork?.images?.undertaking_letter
                                                                            }
                                                                            alt="document"
                                                                            className='img-fluid me-5'
                                                                        />
                                                                    </button><ViewPopup viewImage={viewImage} /></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Risk Assessment")}</label>
                                                        <div className='d-flex service-req-img align-items-center'>
                                                            {stepStatus?.data?.permitToWork?.images?.risk_assessment && (
                                                                <>
                                                                    <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-dismiss='modal'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            handleView(stepStatus?.data?.permitToWork?.images?.risk_assessment)
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                stepStatus?.data?.permitToWork?.images?.risk_assessment
                                                                                    .substr(
                                                                                        stepStatus?.data?.permitToWork?.images?.risk_assessment.lastIndexOf('.')
                                                                                    ).includes('.pdf')
                                                                                    ? pdficon
                                                                                    : stepStatus?.data?.permitToWork?.images?.risk_assessment
                                                                            }
                                                                            alt="document"
                                                                            className='img-fluid me-5'
                                                                        />
                                                                    </button><ViewPopup viewImage={viewImage} /></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Local Authority Approval")}</label>
                                                        <div className='d-flex service-req-img align-items-center'>
                                                            {stepStatus?.data?.permitToWork?.images?.local_authority_approval && (
                                                                <>
                                                                    <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-dismiss='modal'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            handleView(stepStatus?.data?.permitToWork?.images?.local_authority_approval)
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                stepStatus?.data?.permitToWork?.images?.local_authority_approval
                                                                                    .substr(
                                                                                        stepStatus?.data?.permitToWork?.images?.local_authority_approval.lastIndexOf('.')
                                                                                    ).includes('.pdf')
                                                                                    ? pdficon
                                                                                    : stepStatus?.data?.permitToWork?.images?.local_authority_approval
                                                                            }
                                                                            alt="document"
                                                                            className='img-fluid me-5'
                                                                        />
                                                                    </button><ViewPopup viewImage={viewImage} /></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Emirates ID")}</label>
                                                        <div className='d-flex service-req-img align-items-center'>
                                                            {stepStatus?.data?.permitToWork?.images?.emirates_id && (
                                                                <>
                                                                    <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-dismiss='modal'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            handleView(stepStatus?.data?.permitToWork?.images?.emirates_id)
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                stepStatus?.data?.permitToWork?.images?.emirates_id
                                                                                    .substr(
                                                                                        stepStatus?.data?.permitToWork?.images?.emirates_id.lastIndexOf('.')
                                                                                    ).includes('.pdf')
                                                                                    ? pdficon
                                                                                    : stepStatus?.data?.permitToWork?.images?.emirates_id
                                                                            }
                                                                            alt="document"
                                                                            className='img-fluid me-5'
                                                                        />
                                                                    </button><ViewPopup viewImage={viewImage} /></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Trade License")}</label>
                                                        <div className='d-flex service-req-img align-items-center'>
                                                            {stepStatus?.data?.permitToWork?.images?.trade_license && (
                                                                <>
                                                                    <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-dismiss='modal'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            handleView(stepStatus?.data?.permitToWork?.images?.trade_license)
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                stepStatus?.data?.permitToWork?.images?.trade_license
                                                                                    .substr(
                                                                                        stepStatus?.data?.permitToWork?.images?.trade_license.lastIndexOf('.')
                                                                                    ).includes('.pdf')
                                                                                    ? pdficon
                                                                                    : stepStatus?.data?.permitToWork?.images?.trade_license
                                                                            }
                                                                            alt="document"
                                                                            className='img-fluid me-5'
                                                                        />
                                                                    </button><ViewPopup viewImage={viewImage} /></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Method of Statement")}</label>
                                                        <div className='d-flex service-req-img align-items-center'>
                                                            {stepStatus?.data?.permitToWork?.images?.method_of_statement && (
                                                                <>
                                                                    <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-dismiss='modal'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            handleView(stepStatus?.data?.permitToWork?.images?.method_of_statement)
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                stepStatus?.data?.permitToWork?.images?.method_of_statement
                                                                                    .substr(
                                                                                        stepStatus?.data?.permitToWork?.images?.method_of_statement.lastIndexOf('.')
                                                                                    ).includes('.pdf')
                                                                                    ? pdficon
                                                                                    : stepStatus?.data?.permitToWork?.images?.method_of_statement
                                                                            }
                                                                            alt="document"
                                                                            className='img-fluid me-5'
                                                                        />
                                                                    </button><ViewPopup viewImage={viewImage} /></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Workers' / Crew List")}</label>
                                                        <div className='d-flex service-req-img align-items-center'>
                                                            {stepStatus?.data?.permitToWork?.images?.crew_list && (
                                                                <>
                                                                    <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-dismiss='modal'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            handleView(stepStatus?.data?.permitToWork?.images?.crew_list)
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                stepStatus?.data?.permitToWork?.images?.crew_list
                                                                                    .substr(
                                                                                        stepStatus?.data?.permitToWork?.images?.crew_list.lastIndexOf('.')
                                                                                    ).includes('.pdf')
                                                                                    ? pdficon
                                                                                    : stepStatus?.data?.permitToWork?.images?.crew_list
                                                                            }
                                                                            alt="document"
                                                                            className='img-fluid me-5'
                                                                        />
                                                                    </button><ViewPopup viewImage={viewImage} /></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Other(s) (if any)")}</label>
                                                        <div className='d-flex service-req-img align-items-center'>
                                                            {stepStatus?.data?.permitToWork?.images?.other_documents && (
                                                                <>
                                                                    <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-dismiss='modal'
                                                                        type='button'
                                                                        onClick={() => {
                                                                            handleView(stepStatus?.data?.permitToWork?.images?.other_documents)
                                                                        }}>
                                                                        <img
                                                                            src={
                                                                                stepStatus?.data?.permitToWork?.images?.other_documents
                                                                                    .substr(
                                                                                        stepStatus?.data?.permitToWork?.images?.other_documents.lastIndexOf('.')
                                                                                    ).includes('.pdf')
                                                                                    ? pdficon
                                                                                    : stepStatus?.data?.permitToWork?.images?.other_documents
                                                                            }
                                                                            alt="document"
                                                                            className='img-fluid me-5'
                                                                        />
                                                                    </button><ViewPopup viewImage={viewImage} /></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10">

                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <h5 className="text-black fw-bolder font-14 mb-10">
                                                        {t("Permit Applicant")}
                                                    </h5>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="mb-10">
                                                        <label className="form-label fw-bolder"> {t("Name")} </label>
                                                        <input
                                                            className='form-control bg-white fw-bolder form-control-solid'
                                                            value={stepStatus?.data?.permitToWork?.details?.permit_applicant_name}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="accordion-body pb-5 pt-0">
                                            <div className="row documents-card form-add-design">
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Undertaking Letter")}</label>
                                                        <input
                                                            name="undertaking_letter"
                                                            ref={inputRef1}
                                                            className="d-none"
                                                            type="file"
                                                            onChange={() => handleDisplayFileDetails(inputRef1, setUploadedFileName1, 'undertaking_letter')}
                                                            onBlur={formik.handleBlur}
                                                            accept='.jpeg,.jpg,application/pdf'
                                                        />
                                                        <button type='button'
                                                            onClick={() => inputRef1.current?.click()}
                                                            className={
                                                                `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName1 ? "primary active" : "primary"}`
                                                            }
                                                        >
                                                            <span className="pt-5">
                                                                {(uploading && inputBeingUploaded === 'undertaking_letter') ?
                                                                    <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                    : uploadedFileName1 || uploadedFileName1 ? (
                                                                        uploadedFileName1 || ''
                                                                    ) : (
                                                                        <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                    )}
                                                            </span>
                                                        </button>
                                                        {formik.touched.undertaking_letter && formik.errors.undertaking_letter ? (
                                                            <div className='d-flex flex-column text-danger'>
                                                                <small className="text-danger">{formik.errors.undertaking_letter}</small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Risk Assessment")}</label>
                                                        <input
                                                            name="risk_assessment"
                                                            ref={inputRef2}
                                                            className="d-none"
                                                            type="file"
                                                            onChange={() => handleDisplayFileDetails(inputRef2, setUploadedFileName2, 'risk_assessment')}
                                                            onBlur={formik.handleBlur}
                                                            accept='.jpeg,.jpg,application/pdf'
                                                        />
                                                        <button type='button'
                                                            onClick={() => inputRef2.current?.click()}
                                                            className={
                                                                `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName2 ? "primary active" : "primary"}`
                                                            }
                                                        >
                                                            <span className="pt-5">
                                                                {(uploading && inputBeingUploaded === 'risk_assessment') ?
                                                                    <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                    : uploadedFileName2 || uploadedFileName2 ? (
                                                                        uploadedFileName2 || ''
                                                                    ) : (
                                                                        <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                    )}
                                                            </span>
                                                        </button>
                                                        {formik.touched.risk_assessment && formik.errors.risk_assessment ? (
                                                            <div className='d-flex flex-column text-danger'>
                                                                <small className="text-danger">{formik.errors.risk_assessment}</small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Local Authority Approval")}</label>
                                                        <input
                                                            name="local_authority_approval"
                                                            ref={inputRef3}
                                                            className="d-none"
                                                            type="file"
                                                            onChange={() => handleDisplayFileDetails(inputRef3, setUploadedFileName3, 'local_authority_approval')}
                                                            onBlur={formik.handleBlur}
                                                            accept='.jpeg,.jpg,application/pdf'
                                                        />
                                                        <button type='button'
                                                            onClick={() => inputRef3.current?.click()}
                                                            className={
                                                                `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName3 ? "primary active" : "primary"}`
                                                            }
                                                        >
                                                            <span className="pt-5">
                                                                {(uploading && inputBeingUploaded === 'local_authority_approval') ?
                                                                    <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                    : uploadedFileName3 || uploadedFileName3 ? (
                                                                        uploadedFileName3 || ''
                                                                    ) : (
                                                                        <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                    )}
                                                            </span>
                                                        </button>
                                                        {formik.touched.local_authority_approval && formik.errors.local_authority_approval ? (
                                                            <div className='d-flex flex-column text-danger'>
                                                                <small className="text-danger">{formik.errors.local_authority_approval}</small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Emirates ID")}</label>
                                                        <input
                                                            name="emirates_id"
                                                            ref={inputRef4}
                                                            className="d-none"
                                                            type="file"
                                                            onChange={() => handleDisplayFileDetails(inputRef4, setUploadedFileName4, 'emirates_id')}
                                                            onBlur={formik.handleBlur}
                                                            accept='.jpeg,.jpg,application/pdf'
                                                        />
                                                        <button type='button'
                                                            onClick={() => inputRef4.current?.click()}
                                                            className={
                                                                `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName4 ? "primary active" : "primary"}`
                                                            }
                                                        >
                                                            <span className="pt-5">
                                                                {(uploading && inputBeingUploaded === 'emirates_id') ?
                                                                    <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                    : uploadedFileName4 || uploadedFileName4 ? (
                                                                        uploadedFileName4 || ''
                                                                    ) : (
                                                                        <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                    )}
                                                            </span>
                                                        </button>
                                                        {formik.touched.emirates_id && formik.errors.emirates_id ? (
                                                            <div className='d-flex flex-column text-danger'>
                                                                <small className="text-danger">{formik.errors.emirates_id}</small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Trade License")}</label>
                                                        <input
                                                            name="trade_license"
                                                            ref={inputRef5}
                                                            className="d-none"
                                                            type="file"
                                                            onChange={() => handleDisplayFileDetails(inputRef5, setUploadedFileName5, 'trade_license')}
                                                            onBlur={formik.handleBlur}
                                                            accept='.jpeg,.jpg,application/pdf'
                                                        />
                                                        <button type='button'
                                                            onClick={() => inputRef5.current?.click()}
                                                            className={
                                                                `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName5 ? "primary active" : "primary"}`
                                                            }
                                                        >
                                                            <span className="pt-5">
                                                                {(uploading && inputBeingUploaded === 'trade_license') ?
                                                                    <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                    : uploadedFileName5 || uploadedFileName5 ? (
                                                                        uploadedFileName5 || ''
                                                                    ) : (
                                                                        <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                    )}
                                                            </span>
                                                        </button>
                                                        {formik.touched.trade_license && formik.errors.trade_license ? (
                                                            <div className='d-flex flex-column text-danger'>
                                                                <small className="text-danger">{formik.errors.trade_license}</small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Method of Statement")}</label>
                                                        <input
                                                            name="method_of_statement"
                                                            ref={inputRef6}
                                                            className="d-none"
                                                            type="file"
                                                            onChange={() => handleDisplayFileDetails(inputRef6, setUploadedFileName6, 'method_of_statement')}
                                                            onBlur={formik.handleBlur}
                                                            accept='.jpeg,.jpg,application/pdf'
                                                        />
                                                        <button type='button'
                                                            onClick={() => inputRef6.current?.click()}
                                                            className={
                                                                `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName6 ? "primary active" : "primary"}`
                                                            }
                                                        >
                                                            <span className="pt-5">
                                                                {(uploading && inputBeingUploaded === 'method_of_statement') ?
                                                                    <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                    : uploadedFileName6 || uploadedFileName6 ? (
                                                                        uploadedFileName6 || ''
                                                                    ) : (
                                                                        <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                    )}
                                                            </span>
                                                        </button>
                                                        {formik.touched.method_of_statement && formik.errors.method_of_statement ? (
                                                            <div className='d-flex flex-column text-danger'>
                                                                <small className="text-danger">{formik.errors.method_of_statement}</small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Workers' / Crew List")}</label>
                                                        <input
                                                            name="crew_list"
                                                            ref={inputRef7}
                                                            className="d-none"
                                                            type="file"
                                                            onChange={() => handleDisplayFileDetails(inputRef7, setUploadedFileName7, 'crew_list')}
                                                            onBlur={formik.handleBlur}
                                                            accept='.jpeg,.jpg,application/pdf'
                                                        />
                                                        <button type='button'
                                                            onClick={() => inputRef7.current?.click()}
                                                            className={
                                                                `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName7 ? "primary active" : "primary"}`
                                                            }
                                                        >
                                                            <span className="pt-5">
                                                                {(uploading && inputBeingUploaded === 'crew_list') ?
                                                                    <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                    : uploadedFileName7 || uploadedFileName7 ? (
                                                                        uploadedFileName7 || ''
                                                                    ) : (
                                                                        <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                    )}
                                                            </span>
                                                        </button>
                                                        {formik.touched.crew_list && formik.errors.crew_list ? (
                                                            <div className='d-flex flex-column text-danger'>
                                                                <small className="text-danger">{formik.errors.crew_list}</small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10 upload-input">
                                                        <label className="form-label text-black font-14 fw-bold d-block mb-5">{t("Other(s) (if any)")}</label>
                                                        <input
                                                            name="other_documents"
                                                            ref={inputRef8}
                                                            className="d-none"
                                                            type="file"
                                                            onChange={() => handleDisplayFileDetails(inputRef8, setUploadedFileName8, 'other_documents')}
                                                            onBlur={formik.handleBlur}
                                                            accept='.jpeg,.jpg,application/pdf'
                                                        />
                                                        <button type='button'
                                                            onClick={() => inputRef8.current?.click()}
                                                            className={
                                                                `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName8 ? "primary active" : "primary"}`
                                                            }
                                                        >
                                                            <span className="pt-5">
                                                                {(uploading && inputBeingUploaded === 'other_documents') ?
                                                                    <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                    : uploadedFileName8 || uploadedFileName8 ? (
                                                                        uploadedFileName8 || ''
                                                                    ) : (
                                                                        <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                    )}
                                                            </span>
                                                        </button>
                                                        {formik.touched.other_documents && formik.errors.other_documents ? (
                                                            <div className='d-flex flex-column text-danger'>
                                                                <small className="text-danger">{formik.errors.other_documents}</small>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12">
                                                    <div className="mb-10">

                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <h5 className="text-black fw-bolder font-14 mb-10">
                                                        {t("Permit Applicant")}
                                                    </h5>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="mb-10">
                                                        <label className="form-label fw-bolder"> {t("Name")} </label>
                                                        <input
                                                            className='form-control bg-white fw-bolder form-control-solid'
                                                            {...formik.getFieldProps('permit_applicant')}
                                                            autoComplete="off"
                                                        />
                                                        {formik.touched.permit_applicant && formik.errors.permit_applicant && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>
                                                                        {formik.errors.permit_applicant}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-8 text-right">
                                                    <div className="row justify-content-end mb-10">
                                                        <div className="col-12">
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <div className="form-check custom mx-5">
                                                                    <input
                                                                        className='form-check-input'
                                                                        {...formik.getFieldProps('terms')}
                                                                        checked={formik.values.terms}
                                                                        type='checkbox'
                                                                        id='signature-check'
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <label className="form-check-label font-14 text-black fw-bolder" htmlFor="signature-check">
                                                                        {t("Accept")} 	&nbsp;
                                                                        {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
                                                                            {t("Terms & Conditions")}
                                                                        </Link> */}
                                                                        <button
                                                                            data-bs-target="#terms-conditions-popup"
                                                                            data-bs-toggle='modal'
                                                                            className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                                                                            {t("Terms & Conditions")}
                                                                        </button>
                                                                    </label>
                                                                    {formik.touched.terms && formik.errors.terms && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block'>
                                                                                <span role='alert' className='text-danger'>
                                                                                    {formik.errors.terms}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <button type='button' data-bs-toggle="modal" data-bs-target="#signature-modal" className='btn btn-primary fw-bold px-10 py-3'>
                                                                        {t("Signature")}
                                                                    </button>
                                                                    {formik.touched.signature && formik.errors.signature && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block'>
                                                                                <span role='alert' className='text-danger'>
                                                                                    {formik.errors.signature}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal fade redeem-offer signature-modal" id="signature-modal">
                                                    <div className="modal-dialog modal-xs modal-dialog-centered">
                                                        <div className="modal-content rounded-10">
                                                            <div className="modal-header rounded-10 border-0">
                                                                <h5 className="modal-title font-18 fw-bold">{t("Add your Signature")}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body p-8">
                                                                <SignatureCanvas
                                                                    ref={sigCanvas}
                                                                    penColor='black'
                                                                    canvasProps={{ height: 180, width: 480, className: 'sigCanvas' }}
                                                                />
                                                            </div>
                                                            <div className="modal-footer p-0 m-0">
                                                                <div className="row w-100 g-0 m-0">
                                                                    <div className="col-12">
                                                                        <button type="button" className="btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0" onClick={clearSignature}>{t("Reset")}</button>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <button onClick={handleSignature} type="button" className="btn btn-primary fw-bold px-10 py-5 w-100 d-block" data-bs-toggle='modal'
                                                                            data-bs-target='#ParkingSuccessModal' data-bs-dismiss="modal">{t("Submit")}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 text-right">
                                                    <div className="row justify-content-end">
                                                        <div className="col-12">
                                                            <div className="d-flex justify-content-end">
                                                                <button type='button' onClick={handelSubmit} disabled={!dirty || uploading} className='btn btn-primary fw-bold px-10 py-3'>
                                                                    {saved ? t("Saved") : t("Save")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <TermsAndConditionsPopup />
        </>
    )
};

export { UploadRequiredDocuments }
