import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { Link } from 'react-router-dom'
import { AlertName01 } from './components/AlertName01/AlertName01'
import { AlertName02 } from './components/AlertName02/AlertName02'
import { CreateAlerts } from './components/CreateAlerts/CreateAlerts'
import { useTranslation } from 'react-i18next'
import i18next, { t } from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

interface Props {
    setReload: any
    reload: any
    t: any
}

const PTAlertsPage: FC<Props> = ({ setReload, reload }) => (
    <>
        <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
            <div className='col-xxl-12 mt-0'>
                <div className="row">
                    <div className="col-12">
                        <div className="row align-items-center mb-6">
                            <div className="col-sm-6 col">
                                <h4 className="card-title text-black mb-0 font-18 fw-bolder">
                                    {t("My Alerts")}
                                </h4>
                            </div>
                            <div className="col-sm-6 col-auto text-end">
                                <CreateAlerts setReload={setReload} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="accordion move-out-accordion" id="accordion-step-one">
                            {/* <AlertName01 /> */}
                            <AlertName02 reload={reload} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);

const PTAlertsWrapper: FC = () => {
    const intl = useIntl();
    const [reload, setReload] = React.useState(false)
    const reloadPage = (e: any) => {
        if (e.target.value) {
            setReload(true)
        } else {
            setReload(false)
        }
    }
    const { t } = useTranslation();

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <PTAlertsPage setReload={setReload} reload={reload} t={undefined} />
        </>
    )
};

export { PTAlertsWrapper }
