import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { disputeChargesRequest } from '../../../../../constants/axios/apis';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import * as PTOffer from '../../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'

export function DisputePopup({ contract, workOrderId, setMessage }: any) {
    const [loading, setLoading] = useState<any>(false)
    const ref = useRef<any>(0)
    const dispatch = useDispatch()
    const successRef = useRef<any>()
    const dismissRef = useRef<any>()
    const inputRef = useRef<HTMLInputElement>(null);
    const [messages, setMessages] = useState<any>()
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const { t } = useTranslation();

    const initialValues = {
        work_order_id: workOrderId,
        property_id: contract?.propertyID,
        category_id: '',
        comments: '',
        document: '',
    }

    const disputeRequestSchema = Yup.object().shape({
        category_id: Yup.number().required(t("Please choose a category")),
        document: Yup.mixed()
            .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf"), (value) => {
                if (value) {
                    return (
                        value.type === 'image/jpeg'
                        || value.type === 'image/jpg'
                        || value.type === 'image/png'
                        || value.type === 'image/pdf'
                    )
                }
                return true
            })
            .test('fileSize', t("The file is too large!"), (value) => {
                if (value) {
                    return value?.size <= 2000000
                }
                return true
            }),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        isInitialValid: false,
        validationSchema: disputeRequestSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setLoading(true)
            var FormData = require('form-data')
            var data = new FormData()
            data.append('work_order_id', formik.values.work_order_id)
            data.append('category_id', formik.values.category_id)
            data.append('property_id', formik.values.property_id)
            data.append('comments', formik.values.comments)
            data.append('document', formik.values.document)
            setTimeout(() => {
                disputeChargesRequest(data)
                    .then((response: any) => {
                        console.log("🚀 ~ file: DisputePopup.tsx:67 ~ .then ~ response", response)
                        if (response != 500) {
                            setMessage(t("Your dispute request has been Submitted!"))
                            setLoading(false)
                            resetForm()
                            dismissRef.current.click()
                            successRef.current.click()
                        } else {
                            setMessages({
                                error: 1,
                                message: 'Something went wrong!',
                            })
                            dismissRef.current.click()
                            ref.current.click()
                        }
                    })
                    .catch((e) => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus(t("Invalid Data Provided"))
                    })
            }, 1000)
        },
    })

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = (e: any) => {
        inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
        formik.setFieldTouched('document')
        formik.setFieldValue('document', e.currentTarget.files[0])
    };

    useEffect(() => {
        if (messages?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(messages))
        }
    }, [messages])
    return (
        <div>
            <button type='submit' className='btn fw-bold p-0 border-0 text-primary text-decoration-underline font-12'
                data-bs-toggle="modal"
                data-bs-target="#dispute-popup"
            >
                {t("Dispute")}
            </button>

            <div className="modal fade custom-modal" id="dispute-popup">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-white border-0">
                            <button type="button" className="btn-close shadow" onClick={() => formik.resetForm()} data-bs-dismiss="modal" aria-label="Close">&times;</button>
                        </div>
                        <div className="modal-body px-10 pt-0">
                            <form
                                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                                noValidate
                                id='kt_login_signup_form'
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="row form-add-design g-5 text-start">
                                    <div className='col-12'>
                                        <div className=''>
                                            <label className='form-label fw-bolder'>{t("Select Type")}<span className='text-danger'>*</span></label>
                                            <select
                                                data-control='select'
                                                data-hide-search='true'
                                                data-allow-clear='true'
                                                className='form-control form-select fw-bolder form-control-solid'
                                                name='category_id'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                <option selected disabled>{t("Please Select")}</option>
                                                <option value='42'>{t("Clearance Charges")}</option>
                                                <option value='43'>{t("Other Charges")}</option>
                                            </select>
                                            {formik.touched.category_id && formik.errors.category_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.category_id}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className=''>
                                            <label className='form-label fw-bolder'>{t("Comments")}</label>
                                            <textarea
                                                className='form-control fw-bolder form-control-solid'
                                                data-kt-autosize='true'
                                                rows={2}
                                                placeholder={t("Type here...")}
                                                {...formik.getFieldProps('comments')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="upload-input">
                                            <label className="form-label fw-bolder d-block">{t("Upload Document")}</label>
                                            <input
                                                ref={inputRef}
                                                onChange={(e) => handleDisplayFileDetails(e)}
                                                className='d-none'
                                                type='file'
                                                onBlur={formik.handleBlur}
                                            />
                                            <button
                                                type='button'
                                                onClick={handleUpload}
                                                className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                                <span>
                                                    {uploadedFileName ? (
                                                        uploadedFileName
                                                    ) : (
                                                        <span>
                                                            {t("Upload")}{' '}
                                                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                                        </span>
                                                    )}{' '}
                                                </span>
                                            </button>
                                            {formik.touched.document && formik.errors.document && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.document}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className=''>
                                            <button type='submit' className='btn btn-primary fw-bold px-10 py-3 w-100' disabled={loading}>
                                                {!loading && <span className='indicator-label'>{t("Send Request")}</span>}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        {t("Please wait...")}{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <p
                ref={dismissRef}
                data-bs-target='#dispute-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
            >
                {/*  */}
            </p>

            <p
                ref={successRef}
                data-bs-target='#req-submitted-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
            >
                {/*  */}
            </p>
            <p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
                {/*  */}
            </p>
        </div>

    );
}