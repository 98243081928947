import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import LeftArrow from '../../assets/images/Left_Arrow.png'
import Home from '../../assets/images/my-unit.png'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getAllotedParkingSlots } from '../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function MyLease({ contract }: any) {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  let userRole: number = 0
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }
  const location = useLocation()
  const [parkingSlotsCount, setParkingSlotsCount] = useState<any>()
  const { t } = useTranslation();

  useEffect(() => {
    if (contract) {
      getAllotedParkingSlots(contract?.propertyID).then(({ data: slots }: any) => {
        if (slots) {
          setParkingSlotsCount(slots.length)
        } else {
          setParkingSlotsCount(0)
        }
      })
    }
  }, [contract])

  return (
    <div className='com-card-section'>
      {userRole === 7 && location.pathname === '/my-services/contract-renewal' ? (
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='row'>
                  <div className='col-md-4 col-lg-3'>
                    <div className='approval-move'>
                      {/* <img src={Home} alt='Home' className='img-fluid' /> */}
                      <img src={contract?.communityLogo} alt='Home' className='img-fluid' />
                    </div>
                  </div>
                  <div className='col-md-8 col-lg-9'>
                    <div className='card lease-card bg-white rounded shadow-none'>
                      <div className='card-body px-0 pt-0 pb-2'>

                        <div className='row align-items-top mb-7'>
                          <div className='col-md-6'>
                            <h4> {t("Lease Number")} : {contract?.contractNo} </h4>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <div className=''>
                              <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                {' '}
                                {t("Lease Start Date")}
                              </h6>
                              <span className='text-black fw-bold font-14'>
                                {' '}
                                {moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
                              </span>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease End Date")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {t("No of Installments")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {contract?.noOfPayments}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Status")} </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {t(contract?.contractStatus)}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Annual Rent")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Security Deposit")}
                            </h6>
                            <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : userRole === 15 && location.pathname === '/my-services/contract-renewal' ? (
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/my-services/openissues'} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              </Link>
              {t("Contract Renewal")}
            </h4>
          </div>
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='row'>
                  <div className='col-md-4 col-lg-3 MB-4'>
                    <div className='approval-move'>
                      <img src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage} alt='Home' className='img-fluid' />
                    </div>
                  </div>
                  <div className='col-md-8 col-lg-9'>
                    <div className='card lease-card bg-white rounded shadow-none'>
                      <div className='card-body px-0 pt-0 pb-2'>
                        <div className='row align-items-baseline'>
                          <div className='col-md-12'>
                            <a href={contract?.contractFile}
                            className='text-decoration-none text-primary fw-bold view-all font-14 text-black'
                            target='_blank'
                            rel='noreferrer'
                            >
                            <span> {t("View Agreement")} </span>
                          </a>
                        </div>
                      </div>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease Number")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {contract?.contractNo}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <div className=''>
                              <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                {' '}
                                {t("Lease Start Date")}
                              </h6>
                              <span className='text-black fw-bold font-14'>
                                {' '}
                                {moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
                              </span>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease End Date")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Documents")} </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {contract?.documentCount}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Annual Rent")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Security Deposit")}
                            </h6>
                            <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='row'>          
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body p-7 pb-6 pt-6'>
                <div className='row mb-5'>
                  <div className=''>
                    {/* <div className='row align-items-baseline'>
                      <div className='col-sm-6 col'>
                        <a
                          href='#'
                          className='text-decoration-none text-black fw-bolder view-all font-16'
                        >
                          <span> {t("My Unit Details")} </span>
                        </a>
                      </div>
                      <div className='col-sm-6 ps-lg-20 col-auto text-end mt-3 mt-sm-0'>
                        <a
                          href={contract?.contractFile}
                          className='text-decoration-underline text-primary fw-bolder view-all font-14'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <span className='me-0'> {t("View Agreement")} </span>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className='col-md-4 col-lg-3 mb-4'>
                    <div className='approval-move'>
                      <img src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage} alt={Home} className='img-fluid rounded' />
                    </div>
                  </div> */}
                  <div className='col-md-12 col-lg-12'>
                    <div className='card lease-card bg-white rounded shadow-none'>
                      <div className='card-body px-0 pt-0 pb-2'>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease Number")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {contract?.contractNo}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                            <div className=''>
                              <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                {' '}
                                {t("Lease Start Date")}
                              </h6>
                              <span className='text-black fw-bold font-14'>
                                {' '}
                                {moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
                              </span>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease End Date")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '}
                            </span>
                          </div>
                          {/*<div className='col-md-4 col-sm-6 col-xl-3 col-12'>*/}
                          {/*<h6 className='text-theme-gray mb-3 fw-normal font-13'> Status </h6>*/}
                          {/*<span className='text-black fw-bold font-14'>*/}
                          {/*{' '}*/}
                          {/*{contract?.contractStatus}{' '}*/}
                          {/*</span>*/}
                          {/*</div>*/}
                          <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Status")} </h6>
                            <span className='text-black fw-bold font-14'> {t(contract?.contractStatus)} </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Annual Rent")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Security Deposit")}
                            </h6>
                            <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Allotted Parking Slots")} </h6>
                            <span className='text-black fw-bold font-14'> {parkingSlotsCount} </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-3 col-12 mb-0'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("No of Installments")} </h6>
                            <span className='text-black fw-bold font-14'> {contract?.noOfPayments} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      )}
    </div>
  )
}
