import React from 'react';
import CheckGreen from "../../assets/images/Check_green_lg.png";
import { useTranslation } from 'react-i18next'
import { IoMdClose } from "react-icons/io";

export const ReplacePopup: React.FC<any> = ({ visitDetails, message = '' }) => {
    const { t } = useTranslation();

    return (
        <div className="modal fade custom-modal replace" id="replace-popup">
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header bg-white border-0">
                        <h4 className="modal-title" id="modalTitle">Cheque Replacement</h4>
                        <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close"><IoMdClose />
                        </button>
                    </div>
                    <div className="modal-body py-10">
                        <div className="row g-5">
                            <p>Please select one or more valid cheques.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}