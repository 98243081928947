import MultipleFloorPlan from "./MultipleFloorPlan";
import MultipleImageUpload from "./MultipleImageUpload";
import MultipleMediaUpload from "./MultipleMediaUpload";

export function Media({chooseMessage}:any) {
    return (
        <>
        <h3>Sale Details</h3>
        <div className="card form-add-design">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="form-label fw-bolder"> Floor Plan </label>
                            </div>
                        </div>
                        <MultipleFloorPlan/>
                        {/* <div className="border-dashed-black px-4 pb-4 pt-3 mt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="w-100">
                                        <div className="upload-block d-inline-block">
                                            <div className="upload-btn-wrapper bg-white px-2 py-1">
                                                <span className="material-icons font-44">+</span>
                                                <input type="file" accept="image/*" multiple className="community_images" name="photos[]" id="photos"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="form-label fw-bolder"> Other Media </label>
                            </div>
                        </div>
                        <MultipleMediaUpload/>
                        {/* <div className="border-dashed-black px-4 pb-4 pt-3 mt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="w-100">
                                        <div className="upload-block d-inline-block">
                                            <div className="upload-btn-wrapper bg-white px-2 py-1">
                                                <span className="material-icons font-44">+</span>
                                                <input type="file" accept="image/*" multiple className="community_images" name="photos[]" id="photos"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="form-label fw-bolder"> Photos </label>
                            </div>
                        </div>
                        <MultipleImageUpload/>
                        {/* <div className="border-dashed-black px-4 pb-4 pt-3 mt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="w-100">
                                        <div className="upload-block d-inline-block">
                                            <div className="upload-btn-wrapper bg-white px-2 py-1">
                                                <span className="material-icons font-44">+</span>
                                                <input type="file" accept="image/*" multiple className="community_images" name="photos[]" id="photos"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-12 col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="form-label fw-bolder"> Virtuval Video </label>
                                <textarea className="form-control" rows={4}></textarea>
                            </div>
                        </div>
                        {/* <div className="border-dashed-black px-4 pb-4 pt-3 mt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="w-100">
                                        <div className="upload-block d-inline-block">
                                            <div className="upload-btn-wrapper bg-white px-2 py-1">
                                                <span className="material-icons font-44">+</span>
                                                <input type="file" accept="image/*" multiple className="community_images" name="photos[]" id="photos"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
         <div className="text-end my-6">
            <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('3')}>Previous</button>
            <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('5')}>Next</button>
        </div>
        </>
    )
}