import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import CheckMark from "../../../../assets/images/Check_green_lg.png";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux';
import { addNewBroker } from '../../../../utils/makeRequest';
import {
    validateRequired,
    validateAlphabets,
    validateNumbers,
    validateMinLength,
    validateLength,
    validateEmail
} from '../../../../utils/validtionFunction';
import { IState } from '../../../../utils/interface';
import Loader from '../../../../components/Loader';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

interface IProps {
    brokersList: IState["APIState"]
}

// import ChartDataLabels from 'chartjs-plugin-datalabels'
// Chart.register(ChartDataLabels);

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    cutout: "80%",
    plugins: {
        legend: {
            display: true,
            position: 'bottom' as const,
            labels: {
                color: 'rgb(0, 44, 106)',
                lineDash: [12, 12],
                boxWidth: 19,
                boxHeight: 18,
                padding: 30,
                usePointStyle: true,
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                }
            }
        },
    },

    maintainAspectRatio: true,
};
export const TotalBrokerChart: React.FC<IProps> = ({ brokersList }) => {
    const buttonEl: any = useRef(null);

    const state: any = useSelector(state => state);

    const userToken = state?.auth?.accessToken;
    const userId = state?.auth?.user?.data?.id;
    const { t } = useTranslation();

    if (userToken === "undefined" || userId === "undefined") {
        // token undefined && // user id
    }


    const { loading, response: brokers, error } = brokersList;

    const [data, setData] = useState({
        labels: ['0 Active', '0 Inactive'],

        datasets: [
            {
                datalabels: {
                    color: "#ffffff",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                data: [0, 0],
                backgroundColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderWidth: 1,
            },
        ]
    });


    useEffect(() => {
        if (Object.keys(brokers).length !== 0) {
            setData({
                labels: [` ${t('Active')} (${brokers?.activeBrokers}) `, ` ${t('Inactive')} (${brokers?.inActiveBrokers}) `],
                datasets: [
                    {
                        datalabels: {
                            color: "#ffffff",
                            font: {
                                size: 16,
                                family: "'Poppins', sans-serif",
                                lineHeight: 0.5,
                            },
                        },
                        data: [brokers.activeBrokers, brokers.inActiveBrokers],
                        backgroundColor: [
                            '#147AD6',
                            '#FF5F58',
                        ],
                        borderColor: [
                            '#147AD6',
                            '#FF5F58',
                        ],
                        borderWidth: 1,
                    },
                ]
            })
        }
    }, [brokersList]);


    return (
        <div className="">
            <div className="row">
                <div className="col-12">
                    <div className="card brokers-count broker-admin rounded-10">
                        <div className="card-body">
                            <div className="Doughnut-Chart text-center position-relative">
                                <Chart type='doughnut' data={data} options={options} width={150} height={150} />
                                <div className="inner-text res-767">
                                    {Object.keys(brokers).length !== 0 ? (
                                        <h4 className="font-12 text-primary fw-boldest">
                                            {brokers.teamList.length}<br /> {t("Total")}<br /> {t("brokers")}
                                        </h4>
                                    ) : (
                                        <h4 className="font-12 text-primary fw-boldest">
                                            0 <br />
                                            {t("Total")}<br />
                                            {t("brokers")}
                                        </h4>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}