import React from 'react'
import { Link } from 'react-router-dom'
import Home from '../../../../assets/images/my-unit.png'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function ExtendContract({ contract }: any) {
  const { t } = useTranslation();

  return (
    <div className='com-card-section myunit-page'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/my-services/openissues'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            {t("Contract Extension")}
          </h4>
        </div>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='offset-md-3 col-md-9 col-12'>
                  <div className='row align-items-baseline'>
                    <div className='col-md-6'>
                      <span> {t("My Unit Details")} </span>
                    </div>
                    <div className='col-md-6 ps-lg-20 text-end'>
                      <a
                        href={contract?.contractFile}
                        className='text-decoration-underline text-primary fw-bolder view-all font-14'
                        target="_blank"                      >
                        <span> {t("View Agreement")} </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 col-lg-3'>
                  <div className='approval-move'>
                    <img src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage} alt='Home' className='img-fluid' />
                  </div>
                </div>
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card bg-white rounded'>
                    <div className='card-body px-0 pt-4 pb-2'>
                      <div className='row'>
                        <div className='col-md-4 col-6 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> {t("Lease Number")} </h6>
                          <span className='text-black fw-bold'> {contract?.contractNo} </span>
                        </div>
                        <div className='col-md-4 col-6 mb-5'>
                          <div className=''>
                            <h6 className='text-theme-gray mb-3 fw-normal'> {t("Lease Start Date")} </h6>
                            <span className='text-black fw-bold'>
                              {' '}
                              {contract?.contractStartDate}{' '}
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4 col-6 mb-5'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> {t("Lease End Date")} </h6>
                          <span className='text-black fw-bold'> {contract?.contractEndDate} </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> {t("Status")} </h6>
                          <span className='text-black fw-bold'> {contract?.contractStatus} </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> {t("Annual Rent")} </h6>
                          <span className='text-black fw-bold'> {t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                        </div>
                        <div className='col-md-4 col-6'>
                          <h6 className='text-theme-gray mb-3 fw-normal'> {t("Security Deposit")} </h6>
                          <span className='text-black fw-bold'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
