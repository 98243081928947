import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// import bed from '../../../../../assets/images/bath-icon.png'
// import Bath from '../../../../../assets/images/bathing-icon.png'
// import Place from '../../../../../assets/images/place-icons.png'
// import SQR from '../../../../../assets/images/full-screen.png'
// import Yes from '../../../../../assets/images/parking-icon.png'
import YesIcon from '../../../../../assets/images/yes-new-icon.svg'
import Place from '../../../../../assets/images/place.svg'
import Sqft from '../../../../../assets/images/sqft-new-icon.svg'
import Bed from '../../../../../assets/images/bed.svg'
import Bath from '../../../../../assets/images/bath.svg'

import { useSelector } from 'react-redux'
import { getOfferList } from '../../../../../utils/makeRequest'
import { IState } from '../../../../../utils/interface'
import { TeamSearch } from '../../VisitsPage/Search/Search'
import { useTranslation } from 'react-i18next'
import { CustomLoader2, NoDataFound1 } from '../../../../../constants/generics/customActios'

export function OffersList({ offerId }: any) {
  const location = useLocation()
  const state: any = useSelector((state) => state)
  const userToken = state?.auth?.accessToken
  const [offerList, setOfferList] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })
  const { loading, response: offers, error } = offerList
  useEffect(() => {
    getOfferList(setOfferList, userToken)
  }, [])
  const [searchProperty, setSearchProperty] = useState('')
  const [searchBroker, setSearchBroker] = useState('')
  const { t } = useTranslation()

  const filteredOffers = offers
    .filter((items: any) => {
      return searchBroker === '' || (searchBroker !== '' && searchBroker === `${items?.broker_id}`)
    })
    .filter((items: any) => {
      const searchRegex = new RegExp(searchProperty, 'i')
      return (
        searchProperty === '' || (searchProperty !== '' && items?.propertyName?.match(searchRegex))
      )
    })

  return (
    <div className='row'>
      <div className='col-md-12 col-12'>
        <div className='row align-items-center mb-7'>
          <div className='col-sm-6'>
            <h4 className='card-title all_card_title'>{t('Offers')}</h4>
          </div>
          <div className='col-sm-6'>
            <TeamSearch setSearchBroker={setSearchBroker} setSearchProperty={setSearchProperty} />
          </div>
        </div>
        <div className='row'>
          
            {loading && <CustomLoader2 />}
            {filteredOffers.map((offer: any, index: number) => {
              return (
                <div className='col-md-6'>
                <div className='pm-offers-and-renewals mb-10 mt-1'>
                  <div className='card c-pointer' key={index + 1} onClick={() => offerId(offer?.id)}>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='offer_main_img h-100 img-bg'>
                          <img
                            src={offer?.property_cover_img ? offer?.property_cover_img : 'No Image'}
                            className='img-fluid max-ht'
                          />
                          <span className='badge badge-pill badge-dark prop-type-badge'>
                            {parseInt(offer?.property_search_type) === 1
                              ? t('Residential')
                              : parseInt(offer?.property_search_type) === 2
                                ? t('Commercial')
                                : offer?.property_search_type}
                          </span>
                        </div>
                      </div>
                      <div className='col-12 align-self-center'>
                        <div className='offer_main_details p-3'>
                          <div className='col-12 pt-3'>
                            <p className='text-black fw-bolder font-17 text-title my-offer-property-name mb-3'>
                              {offer?.property_name ? offer?.property_name : '-'}
                            </p>

                            <h2 className='text-black fw-bolder mb-3 text-sub-title'>
                              <small>{t('AED')}</small>{' '}
                              <span className='fw-boldest'>
                                {offer?.price?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                })}
                              </span>{' '}
                              <small> / {t('Year')} </small>
                            </h2>
                          </div>
                          <div className='text-black fw-bolder col-12 offer_main_details_div_3 mb-3'>
                            <p>
                              {' '}
                              {t('Offer No: ')}
                              {offer?.offer_number ? offer?.offer_number : '-'}
                            </p>
                          </div>
                          <div className='col-12 offer_main_details_div_3 mb-3'>
                            <p>
                              <span>
                                <img src={Place} className='img-fluid map-icon' />{' '}
                              </span>
                              {offer?.propertyCode?.substring(
                                offer?.propertyCode?.lastIndexOf('-') + 1,
                                offer?.propertyCode?.length
                              ) + ' | '}{' '}
                              {offer?.buildingName && offer?.buildingName + ' | '}{' '}
                              {offer?.address && offer?.address + ' | '}{' '}
                              {offer?.location && offer?.location}
                            </p>
                          </div>

                          {/* <div className='bg-theme d-inline-block'>
                          <div className='facility-box'>
                            <ul>
                              <li>
                                <img
                                  src={bed}
                                  alt='Bedrooms'
                                  loading='lazy'
                                  width='15'
                                  height='15'
                                />
                                <span>{offer?.bedrooms ? offer?.bedrooms : '-'}</span>
                              </li>
                              <li>
                                <img
                                  src={Bath}
                                  alt='Bathrooms'
                                  loading='lazy'
                                  width='15'
                                  height='15'
                                />
                                <span>{offer?.bathrooms ? offer?.bathrooms : '-'}</span>
                              </li>
                              <li>
                                <img src={SQR} alt='Sqmt' loading='lazy' width='15' height='15' />
                                <span>
                                  {offer?.built_in_area ? offer?.built_in_area : '-'}
                                  {t('Sqmt')}
                                </span>
                              </li>
                              <li title='Yes'>
                                <img
                                  src={Yes}
                                  alt='Parking'
                                  loading='lazy'
                                  width='15'
                                  height='15'
                                />
                                <span>
                                  {offer?.isParkingAvailable === 'Y' ? t('Yes') : t('No')}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                          <div className='col-12'>
                            <div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
                              {parseInt(offer?.property_search_type) !== 2 &&
                                <p className='p-3 me-2'>
                                  <img src={Bed} className='img-fluid me-3' alt='bed' />
                                  {offer?.bedrooms ? offer?.bedrooms : '-'}
                                </p>
                              }
                              <p className='p-3 me-2'>
                                <img src={Bath} className='img-fluid me-3' alt='bath' />
                                {offer?.bathrooms ? offer?.bathrooms : '-'}
                              </p>
                              <p className='p-3 me-2 sqft-my-offer'>
                                <img src={Sqft} className='img-fluid me-2' alt='sqft' />
                                {offer?.built_in_area ? offer?.built_in_area : '-'}
                                {t('Sqmt')}
                              </p>
                              <p className='p-3'>
                                <img src={YesIcon} className='img-fluid me-2' alt='yes' />{' '}
                                {offer?.isParkingAvailable === 'Y' ? t('Yes') : t('No')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-12 align-self-center'>
                        <div className='card-status'>
                          <span
                            className={
                              offer?.offer_status === '1'
                                ? 'approved'
                                : offer?.offer_status === '2'
                                  ? 'rejected'
                                  : 'pending'
                            }
                          >
                            {offer?.offer_status === '1'
                              ? t('Approved')
                              : offer?.offer_status === '2'
                                ? t('Rejected')
                                : t('Pending')}
                          </span>
                        </div>
                        <div className='text-center offer-price-sec d-flex align-items-center px-3 pb-3'>
                         
                                    <p className='text-black fw-bolder font-14 mb-1 text-title me-2'>
                                      {t('Offer Price')}
                                    </p>
                                    <h1 className='text-black fw-bolder font-14 mb-1 text-sub-title ms-3'>
                                      <span>{t('AED')}</span>{' '}
                                      <span className='fw-boldest'>
                                        {offer?.rent_amount?.toLocaleString(undefined, {
                                          maximumFractionDigits: 2,
                                        })}
                                      </span>{' '}
                                    </h1>
                                 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              )
            })}
            {filteredOffers?.length == 0 && !loading && <NoDataFound1 />}
          </div>
      </div>
    </div>
  )
}
