import {useIntl} from 'react-intl'
import React, {useState, useEffect, useRef} from 'react'
import BackIcon from '../../../../assets/images/Left_Arrow.png'
import Nouislider from 'nouislider-react'
import 'nouislider/dist/nouislider.css'
import {
  getPropertyFilterData,
  getConfig,
  getMasterData,
  addAlerts,
  getAlerts,
} from '../../../../constants/axios/apis'
import {useDispatch} from 'react-redux'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import {DynamicPopup} from '../../../SuccessfulPopup/DynamicPopup'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'

const languages = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
  },
]

export function CreateAlerts(props: any) {
  const dispatch = useDispatch()
  const ref = useRef<any>(0)
  const intl = useIntl()
  const [propertyFilterData, setPropertyFilterData] = useState<any>()
  const [masterData, setMasterData] = useState<any>()
  const [configData, setConfigData] = useState<any>()
  const [minSizeValue, setMinSizeValue] = useState<any>(10)
  const [maxSizeValue, setMaxSizeValue] = useState<any>(10000)
  const [minValues, setMinValues] = useState<any>()
  const [maxValues, setMaxValues] = useState<any>()
  const [minSizeValues, setMinSizeValues] = useState<any>()
  const [maxSizeValues, setMaxSizeValues] = useState<any>()
  // Budget
  const [minBudgetValue, setMinBudgetValue] = useState<any>(5000)
  const [maxBudgetValue, setMaxBudgetValue] = useState<any>(1000000)

  const [message, setMessage] = useState<any>()
  const [show, setShow] = useState<any>(false)

  const [buildingSelected, setBuildingSelected] = useState<any>([])
  const [propertyTypeSelected, setPropertyTypeSelected] = useState<any>([])
  const [movieInSelected, setMovieInSelected] = useState<any>()
  const [bedroomSelected, setBedroomSelected] = useState<any>([])
  const {t} = useTranslation()

  function onSizeChange(value: any) {
    setMinSizeValue(value[0])
  }
  function onSizeSlide(value: any) {
    setMaxSizeValue(value[1])
  }
  function onChange(value: any) {
    setMinSizeValue(Math.round(value[0]))
  }
  function onSlide(value: any) {
    setMinSizeValue(Math.round(value[0]))
    setMaxSizeValue(Math.round(value[1]))
  }

  function onSizeChanges(value: any) {
    setMinSizeValues(value[0])
  }
  function onSizeSlides(value: any) {
    setMaxSizeValues(value[1])
  }
  function onChanges(value: any) {
    setMinBudgetValue(Math.round(value[0]))
  }
  function onSlides(value: any) {
    setMinBudgetValue(Math.round(value[0]))
    setMaxBudgetValue(Math.round(value[1]))
  }
  useEffect(() => {
    getPropertyFilterData().then((data) => {
      setPropertyFilterData(data.data)
    })

    getConfig().then(({data}: any) => {
      setMinValues(data?.find((item: any) => item.key == 'min_budget_range').value)
      setMaxValues(data?.find((item: any) => item.key == 'max_budget_range').value)
      setMinSizeValues(data?.find((item: any) => item.key == 'min_sqft_range').value)
      setMaxSizeValues(data?.find((item: any) => item.key == 'max_sqft_range').value)
    })
  }, [])
  useEffect(() => {
    getMasterData().then(({data: masterData}) => {
      let moveInWithIn = masterData
        .filter((item: any) => item.id === 17)
        .flatMap((data: any) => data.master_data)
      setMasterData(moveInWithIn)
    })
  }, [])
  const handleSubmit = () => {
    setShow(true)
    var Alertdata: any
    Alertdata = {
      // "property_type_id": propertyTypeSelected,
      // "building_type_id": buildingSelected,
      // "bedroom_id": bedroomSelected,
      built_in_area_from: minSizeValue,
      built_in_area_to: maxSizeValue,
      budget_from: minBudgetValue,
      budget_to: maxBudgetValue,
      // "move_in": movieInSelected
    }
    if (buildingSelected.length > 0) Alertdata['building_type_id'] = buildingSelected.join(',')
    if (propertyTypeSelected.length > 0)
      Alertdata['property_type_id'] = propertyTypeSelected.join(',')
    if (bedroomSelected.length > 0) Alertdata['bedroom_id'] = bedroomSelected.join(',')
    // if(bedroomSelected !== '')
    //     Alertdata["bedroom_id"] = bedroomSelected
    if (movieInSelected) Alertdata['move_in'] = movieInSelected

    // console.log(Alertdata, 'Alertdata');

    addAlerts(Alertdata).then((data) => {
      if (data.code === 200 && data.errorCode != 1) {
        setMessage({
          error: 0,
          message: 'Alert ' + data?.description,
        })
        ref.current.click()
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } else {
        setShow(false)
        setMessage({
          error: 1,
          message: data?.errorDescription,
        })
        ref.current.click()
      }
    })
  }

  useEffect(() => {
    if (message?.message?.length > 0) {
      dispatch(PTOffer.actions.modalmessage(message))
    }
  }, [message])

  const handelBuildingSelect = (e: any) => {
    let buildingIDList = [...buildingSelected]
    if (e?.target?.checked) {
      //add
      buildingIDList.push(e?.target?.value)
    } else {
      //remove
      var index = buildingIDList.indexOf(e?.target?.value)
      if (index !== -1) buildingIDList.splice(index, 1)
    }
    setBuildingSelected(buildingIDList)
  }

  const handelPropertyTypeSelect = (e: any) => {
    let propertyTypeIDList = [...propertyTypeSelected]
    if (e?.target?.checked) {
      //add
      propertyTypeIDList.push(e?.target?.value)
    } else {
      //remove
      var index = propertyTypeIDList.indexOf(e?.target?.value)
      if (index !== -1) propertyTypeIDList.splice(index, 1)
    }
    setPropertyTypeSelected(propertyTypeIDList)
  }

  const handelBedroomSelect = (e: any) => {
    let bedroomList = [...bedroomSelected]
    if (e?.target?.checked) {
      //add
      bedroomList.push(e?.target?.value)
    } else {
      //remove
      var index = bedroomList.indexOf(e?.target?.value)
      if (index !== -1) bedroomList.splice(index, 1)
    }
    setBedroomSelected(bedroomList)
  }

  return (
    <>
      <>
        <div className='row'>
          <div className='col-lg-12 my-auto'>
            <button
              data-bs-target='#create-alerts-popup'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
              type='submit'
              className='btn btn-primary fw-bold px-md-10 py-md-3 py-2'
            >
              {t('Create Alert')}
            </button>
            <div
              className='modal fade add-broker-modal'
              data-bs-backdrop='static'
              data-bs-keyboard='false'
              aria-hidden='true'
              id='create-alerts-popup'
            >
              <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                  <div className='modal-header px-10 pt-10'>
                    <h5 className='modal-title' data-bs-dismiss='modal'>
                      <img src={BackIcon} className='img-fluid pe-2 c-pointer' />
                      {t('Create an Alert')}
                    </h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      {' '}
                    </button>
                  </div>
                  <div className='modal-body overflow-x-hidden px-10 pt-0'>
                    <ul
                      className='nav nav-pills mb-3 create-offer-tabs'
                      id='pills-tab'
                    >
                      <li className='nav-item tab_nav_li'>
                        <button
                          className='nav-link active px-5 py-5 w-100 fw-bolder'
                          data-bs-toggle='pill'
                          data-bs-target='#buy'
                          type='button'
                        >
                          {t('Buy')}
                        </button>
                      </li>
                      <li className='nav-item tab_nav_li'>
                        <button
                          className='nav-link px-5 py-5 w-100 fw-bolder'
                          data-bs-toggle='pill'
                          data-bs-target='#sale'
                          type='button'
                        >
                          {t('Sale')}
                        </button>
                      </li>
                    </ul>
                    <div className='tab-content'>
                      <div
                        className='tab-pane fade show active'
                        id='buy'
                      >
                        <div className='min-h-502px pt-5'>
                          <div className='row'>
                            <div className='col-12 mb-10'>
                              <h5 className='mb-7 text-start'>{t('Buildings')}</h5>
                              <div className='filterWrap'>
                                <div className='row inner-sec'>
                                  {propertyFilterData &&
                                    propertyFilterData?.building?.map(
                                      (building: any, index: number) => {
                                        return (
                                          <div className='col-auto filterBtnGroup mb-3' key={index}>
                                            <input
                                              type='checkbox'
                                              className='btn-check filter_btn'
                                              name={'building' + index}
                                              value={building?.value}
                                              id={`custom-checkbox-${index}`}
                                              onChange={handelBuildingSelect}
                                              autoComplete='off'
                                            />
                                            <label
                                              className='btn btn-outline-primary px-5'
                                              htmlFor={`custom-checkbox-${index}`}
                                              id='filter_btn'
                                            >
                                              {/* <img src={building.icon} className="img-fluid mx-auto mb-3" /> */}
                                              <span>{building.name}</span>
                                            </label>
                                          </div>
                                        )
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className='col-12 mb-10'>
                              <h5 className='mb-7 text-start'>{t('Property Type')}</h5>
                              <div className='filterWrap'>
                                <div className='row inner-sec'>
                                  {propertyFilterData &&
                                    propertyFilterData?.propertyType?.map(
                                      (propertytype: any, index: number) => {
                                        return (
                                          <div
                                            className='col-auto filterBtnGroup property-type mb-3'
                                            key={index}
                                          >
                                            <input
                                              type='checkbox'
                                              className='btn-check filter_btn'
                                              name={'propertyType' + index}
                                              id={'pt-' + propertytype?.value}
                                              value={propertytype?.value}
                                              onChange={handelPropertyTypeSelect}
                                              autoComplete='off'
                                            />
                                            <label
                                              className='btn btn-outline-primary px-5'
                                              htmlFor={'pt-' + propertytype?.value}
                                              id='filter_btn'
                                            >
                                              {/* <img src={propertytype?.icon} className="img-fluid pe-2 mx-auto mb-3" /> */}
                                              <span>{propertytype?.name}</span>
                                            </label>
                                          </div>
                                        )
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className='col-12 mb-10'>
                              <h5 className='mb-7 text-start'>{t('Bedrooms')}</h5>
                              <div className='filterWrap'>
                                <div className='row inner-sec'>
                                  {propertyFilterData &&
                                    propertyFilterData?.bedroom?.map(
                                      (broom: any, index: number) => {
                                        return (
                                          <div className='col-2 filterBtnGroup mb-3' key={index}>
                                            <input
                                              type='checkbox'
                                              className='btn-check filter_btn'
                                              name={'broom' + index}
                                              id={'br-' + broom?.value}
                                              value={broom?.value}
                                              onChange={handelBedroomSelect}
                                              autoComplete='off'
                                            />
                                            <label
                                              className='btn btn-outline-primary bedrooms'
                                              htmlFor={'br-' + broom?.value}
                                              id='filter_btn'
                                            >
                                              <span>{broom?.name}</span>
                                            </label>
                                          </div>
                                        )
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className='col-12 mb-2'>
                              <div className='row pe-0'>
                                <div className='col-sm-6'>
                                  <div className='row budget'>
                                    <h5 className='text-start'>{t('Budget in AED')}</h5>
                                  </div>
                                </div>
                              </div>
                              <div className='row pe-0'>
                                <div className='col d-flex budget_min_max'>
                                  <label htmlFor='inputtext' className='col-form-label pe-3'>
                                    {t('Min')}
                                  </label>
                                  <input
                                    type='text'
                                    id='price-min'
                                    name='minBudgetValue'
                                    value={parseInt(minBudgetValue)?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                    className='form-control'
                                    onKeyDown={(event) => {
                                      event.preventDefault()
                                    }}
                                  />
                                </div>
                                <div className='col d-flex budget_min_max pe-4'>
                                  <input
                                    type='text'
                                    id='price-max'
                                    name='maxBudgetValue'
                                    value={parseInt(maxBudgetValue)?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                    className='form-control'
                                    onKeyDown={(event) => {
                                      event.preventDefault()
                                    }}
                                  />
                                  <label htmlFor='inputtext' className='col-form-label ps-3'>
                                    {t('Max')}
                                  </label>
                                </div>
                              </div>
                              <div className='row mt-10 mb-5'>
                                <div className='price-range'>
                                  <Nouislider
                                    range={{min: 1000, max: 1000000}}
                                    start={[
                                      parseInt(minValues?.length > 0 ? minValues : 0),
                                      parseInt(maxValues?.length > 0 ? maxValues : 0),
                                    ]}
                                    connect
                                    onSlide={onSlides}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-12 mb-10'>
                              <h5 className='mb-7 text-start'>{t('Move in')}</h5>
                              <div className='filterWrap'>
                                <div className='row inner-sec g-5'>
                                  {masterData &&
                                    masterData?.map((movein: any, index: number) => {
                                      return (
                                        <div className='col-auto filterBtnGroup mb-3'>
                                          <input
                                            type='radio'
                                            className='btn-check filter_btn'
                                            name='options'
                                            id={'mov-' + movein?.value}
                                            value={movein?.value}
                                            onChange={(e) => setMovieInSelected(e?.target?.value)}
                                            autoComplete='off'
                                          />
                                          <label
                                            className='btn btn-outline-primary move-in d-block px-5'
                                            htmlFor={'mov-' + movein?.value}
                                            id='filter_btn'
                                          >
                                            <span>{movein?.name}</span>
                                          </label>
                                        </div>
                                      )
                                    })}
                                </div>
                              </div>
                            </div>
                            <div className='col-12'>
                              <div className='row pe-0'>
                                <div className='col-sm-6'>
                                  <div className='row budget'>
                                    <h5 className='text-start'>{t('Property Size in Sqmt')}</h5>
                                  </div>
                                </div>
                              </div>
                              <div className='row pe-0'>
                                <div className='col d-flex budget_min_max'>
                                  <label className='col-form-label pe-3'>{t('Min')}</label>
                                  <input
                                    type='text'
                                    id='price-min'
                                    name='minSizeValue'
                                    value={parseInt(minSizeValue)?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                    className='form-control'
                                    onKeyDown={(event) => {
                                      event.preventDefault()
                                    }}
                                  />
                                </div>
                                <div className='col d-flex budget_min_max pe-4'>
                                  <input
                                    type='text'
                                    id='price-max'
                                    name='maxSizeValue'
                                    value={parseInt(maxSizeValue)?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                    className='form-control'
                                    onKeyDown={(event) => {
                                      event.preventDefault()
                                    }}
                                  />
                                  <label className='col-form-label ps-3'>{t('Max')}</label>
                                </div>
                              </div>
                              <div className='row mt-10 mb-5'>
                                <div className='price-range'>
                                  <Nouislider
                                    range={{min: 100, max: 10000}}
                                    start={[
                                      parseInt(minSizeValues?.length > 0 ? minSizeValues : 0),
                                      parseInt(maxSizeValues?.length > 0 ? maxSizeValues : 0),
                                    ]}
                                    connect
                                    onSlide={onSlide}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='sale'
                      >
                        <div className='min-h-502px pt-5'>
                          <div className='row'>
                            <div className='col-12 mb-10'>
                              <h5 className='mb-7 text-start'>{t('Buildings')}</h5>
                              <div className='filterWrap'>
                                <div className='row inner-sec'>
                                  {propertyFilterData &&
                                    propertyFilterData?.building?.map(
                                      (building: any, index: number) => {
                                        return (
                                          <div className='col-auto filterBtnGroup mb-3' key={index}>
                                            <input
                                              type='checkbox'
                                              className='btn-check filter_btn'
                                              name={'building' + index}
                                              value={building?.value}
                                              id={`custom-checkbox-${index}`}
                                              onChange={handelBuildingSelect}
                                              autoComplete='off'
                                            />
                                            <label
                                              className='btn btn-outline-primary px-5'
                                              htmlFor={`custom-checkbox-${index}`}
                                              id='filter_btn'
                                            >
                                              {/* <img src={building.icon} className="img-fluid mx-auto mb-3" /> */}
                                              <span>{building.name}</span>
                                            </label>
                                          </div>
                                        )
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className='col-12 mb-10'>
                              <h5 className='mb-7 text-start'>{t('Property Type')}</h5>
                              <div className='filterWrap'>
                                <div className='row inner-sec'>
                                  {propertyFilterData &&
                                    propertyFilterData?.propertyType?.map(
                                      (propertytype: any, index: number) => {
                                        return (
                                          <div
                                            className='col-auto filterBtnGroup property-type mb-3'
                                            key={index}
                                          >
                                            <input
                                              type='checkbox'
                                              className='btn-check filter_btn'
                                              name={'propertyType' + index}
                                              id={'pt-' + propertytype?.value}
                                              value={propertytype?.value}
                                              onChange={handelPropertyTypeSelect}
                                              autoComplete='off'
                                            />
                                            <label
                                              className='btn btn-outline-primary px-5'
                                              htmlFor={'pt-' + propertytype?.value}
                                              id='filter_btn'
                                            >
                                              {/* <img src={propertytype?.icon} className="img-fluid pe-2 mx-auto mb-3" /> */}
                                              <span>{propertytype?.name}</span>
                                            </label>
                                          </div>
                                        )
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className='col-12 mb-10'>
                              <h5 className='mb-7 text-start'>{t('Bedrooms')}</h5>
                              <div className='filterWrap'>
                                <div className='row inner-sec'>
                                  {propertyFilterData &&
                                    propertyFilterData?.bedroom?.map(
                                      (broom: any, index: number) => {
                                        return (
                                          <div className='col-2 filterBtnGroup mb-3' key={index}>
                                            <input
                                              type='checkbox'
                                              className='btn-check filter_btn'
                                              name={'broom' + index}
                                              id={'br-' + broom?.value}
                                              value={broom?.value}
                                              onChange={handelBedroomSelect}
                                              autoComplete='off'
                                            />
                                            <label
                                              className='btn btn-outline-primary bedrooms'
                                              htmlFor={'br-' + broom?.value}
                                              id='filter_btn'
                                            >
                                              <span>{broom?.name}</span>
                                            </label>
                                          </div>
                                        )
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className='col-12 mb-2'>
                              <div className='row pe-0'>
                                <div className='col-sm-6'>
                                  <div className='row budget'>
                                    <h5 className='text-start'>{t('Budget in AED')}</h5>
                                  </div>
                                </div>
                              </div>
                              <div className='row pe-0'>
                                <div className='col d-flex budget_min_max'>
                                  <label htmlFor='inputtext' className='col-form-label pe-3'>
                                    {t('Min')}
                                  </label>
                                  <input
                                    type='text'
                                    id='price-min'
                                    name='minBudgetValue'
                                    value={parseInt(minBudgetValue)?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                    className='form-control'
                                    onKeyDown={(event) => {
                                      event.preventDefault()
                                    }}
                                  />
                                </div>
                                <div className='col d-flex budget_min_max pe-4'>
                                  <input
                                    type='text'
                                    id='price-max'
                                    name='maxBudgetValue'
                                    value={parseInt(maxBudgetValue)?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                    className='form-control'
                                    onKeyDown={(event) => {
                                      event.preventDefault()
                                    }}
                                  />
                                  <label htmlFor='inputtext' className='col-form-label ps-3'>
                                    {t('Max')}
                                  </label>
                                </div>
                              </div>
                              <div className='row mt-10 mb-5'>
                                <div className='price-range'>
                                  <Nouislider
                                    range={{min: 1000, max: 1000000}}
                                    start={[
                                      parseInt(minValues?.length > 0 ? minValues : 0),
                                      parseInt(maxValues?.length > 0 ? maxValues : 0),
                                    ]}
                                    connect
                                    onSlide={onSlides}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='col-12 mb-10'>
                              <h5 className='mb-7 text-start'>{t('Move in')}</h5>
                              <div className='filterWrap'>
                                <div className='row inner-sec g-5'>
                                  {masterData &&
                                    masterData?.map((movein: any, index: number) => {
                                      return (
                                        <div className='col-auto filterBtnGroup mb-3'>
                                          <input
                                            type='radio'
                                            className='btn-check filter_btn'
                                            name='options'
                                            id={'mov-' + movein?.value}
                                            value={movein?.value}
                                            onChange={(e) => setMovieInSelected(e?.target?.value)}
                                            autoComplete='off'
                                          />
                                          <label
                                            className='btn btn-outline-primary move-in d-block px-5'
                                            htmlFor={'mov-' + movein?.value}
                                            id='filter_btn'
                                          >
                                            <span>{movein?.name}</span>
                                          </label>
                                        </div>
                                      )
                                    })}
                                </div>
                              </div>
                            </div>
                            <div className='col-12'>
                              <div className='row pe-0'>
                                <div className='col-sm-6'>
                                  <div className='row budget'>
                                    <h5 className='text-start'>{t('Property Size in Sqmt')}</h5>
                                  </div>
                                </div>
                              </div>
                              <div className='row pe-0'>
                                <div className='col d-flex budget_min_max'>
                                  <label className='col-form-label pe-3'>{t('Min')}</label>
                                  <input
                                    type='text'
                                    id='price-min'
                                    name='minSizeValue'
                                    value={parseInt(minSizeValue)?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                    className='form-control'
                                    onKeyDown={(event) => {
                                      event.preventDefault()
                                    }}
                                  />
                                </div>
                                <div className='col d-flex budget_min_max pe-4'>
                                  <input
                                    type='text'
                                    id='price-max'
                                    name='maxSizeValue'
                                    value={parseInt(maxSizeValue)?.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}
                                    className='form-control'
                                    onKeyDown={(event) => {
                                      event.preventDefault()
                                    }}
                                  />
                                  <label className='col-form-label ps-3'>{t('Max')}</label>
                                </div>
                              </div>
                              <div className='row mt-10 mb-5'>
                                <div className='price-range'>
                                  <Nouislider
                                    range={{min: 100, max: 10000}}
                                    start={[
                                      parseInt(minSizeValues?.length > 0 ? minSizeValues : 0),
                                      parseInt(maxSizeValues?.length > 0 ? maxSizeValues : 0),
                                    ]}
                                    connect
                                    onSlide={onSlide}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-primary w-100'
                      onClick={handleSubmit}
                      // data-bs-toggle='modal'
                      // data-bs-dismiss='modal'
                      disabled={
                        buildingSelected?.length > 0 &&
                        minSizeValue > 0 &&
                        maxSizeValue > 0 &&
                        maxBudgetValue > 0 &&
                        show === false
                          ? false
                          : true
                      }
                    >
                      {t('Create an Alert')}
                    </button>
                    <p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
                      {/*  */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      
      
    </>
  )
}
