import React, { useState, useRef, FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../constants'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

const OfferDetails: FC = () => {
	let offerList: any = []
	offerList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.offerDetails)
	const { t } = useTranslation();

	return (
		<div className='row'>
			<div className="col-12 mb-4">
				<hr />
			</div>
			<div className='col-12'>
				<div className=''>
					<h3 className='mb-7' id='flush-headingSeven'>
						{/* <button
							className='accordion-button collapsed'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#offer-details'
						>
							
						</button> */}
						{t("Offer Details")}
					</h3>
					<div
						id='offer-details'
						className=''
					>
						<div className='pt-0'>
							<div className='row form-add-design'>
								<div className='col-md-4'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Tenant Name")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'> {offerList?.data?.offer_details?.tenant_name}</h6>
										</div>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Area(in sqmt)")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>{offerList?.data?.property_details?.area}</h6>
										</div>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Unit Number")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>{offerList?.data?.offer_details?.unitNo}</h6>
										</div>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Location")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>{offerList?.data?.property_details?.location}</h6>
										</div>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Lease Start Date")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>{moment(offerList?.data?.offer_details?.startDate).format('DD-MM-YYYY')}</h6>
										</div>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Lease End Date")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>{moment(offerList?.data?.offer_details?.endDate).format('DD-MM-YYYY')}</h6>
										</div>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Annual Rent")} </label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>{t("AED")} {offerList?.data?.offer_details?.offerPrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h6>
										</div>
									</div>
								</div>
								<div className='col-md-4'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Security Deposit")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>{t("AED")} {offerList?.data?.offer_details?.securityDeposit?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h6>
										</div>
									</div>
								</div>
								{/* <div className="col-12 text-end">
                                    <button className='btn btn-outline-primary fw-bold px-10 py-3 me-5'>
                                        Cancel Offer
                                    </button>
                                    <button className='btn btn-primary fw-bold px-10 py-3'>
                                        Extend Offer
                                    </button>
                                </div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-12 mb-4">
				<hr className="mt-0" />
			</div>
		</div>
	)
}

export { OfferDetails }
