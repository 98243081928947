import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { vehicleRegistrationRequest } from '../../../../constants/axios/apis'
import ReactSignatureCanvas from 'react-signature-canvas'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsPopup } from '../../../terms-and-conditions/TermsAndConditionsPopup'

export function VehicleRegistrationForm({ slot, property_id, selectedTab, getStepStatus, setMessage }: any) {
  const [loading, setLoading] = useState<any>(false)
  const closeRef = useRef<any>()
  const { t } = useTranslation();

  const initialValues = {
    category_id: 9,
    property_id: property_id,
    parking_slot: slot.id,
    brand: '',
    model: '',
    color: '',
    regno: '',
    status: 0,
    signature: '',
  }

  const sigCanvas = useRef<any>()
  function clear() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }
  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    })
  }

  const handleTerms = (e: any) => {
    if (e.target.checked) {
      return formik.setFieldValue('status', 1)
    }
    return formik.setFieldValue('status', 0)
  }

  const vehicleRegistrationRequestSchema = Yup.object().shape({
    brand: Yup.string()
      .required(t("Please enter your vehicle's brand"))
      .matches(/^[^\s].*/, (t('Brand Name cannot start with a blankspace!')))
      .max(20, (t('Brand should be smaller then 20 characters!'))),
    model: Yup.string()
      .required(t("Please enter your vehicle's model"))
      .matches(/^[^\s].*/, (t('Model Name cannot start with a blankspace!')))
      .max(20, 'Model should be smaller then 20 characters!'),
    color: Yup.string()
      .required(t("Please enter your vehicle's color"))
      .max(20, (t('Color should be smaller then 20 characters!')))
      .matches(/^[^\s].*/, (t('Color Name cannot start with a blankspace!')))
      .matches(/^[A-Za-z ]+$/, (t('Only alphabets allowed!'))),
    regno: Yup.string()
      .required(t("Please enter your vehicle's registration number"))
      .matches(/^[^\s].*/, (t('Registration number cannot start with a blankspace!')))
      .max(20, (t('Registration number should be smaller then 20 digits!'))),
    status: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
    signature: Yup.string().required(t("Please add your signature")),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleRegistrationRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        vehicleRegistrationRequest(
          values.property_id,
          values.category_id,
          values.parking_slot,
          values.brand,
          values.model,
          values.color,
          values.regno,
          values.signature
        )
          .then((response: any) => {
            setLoading(false)
            setMessage(t("Your Vehicle Registration Request has been Submitted!"))
            resetForm()
            sigCanvas.current.clear()
            getStepStatus()
            closeRef.current.click()
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  return (
    <>
      <div key={slot.id} className='tab-content' id='ParkingSlotTabContent'>
        <form
          className={
            'form w-100 fv-plugins-bootstrap5 fv-plugins-framework tab-pane fade ' +
            (selectedTab == slot.slotNumber ? 'show active' : '')
          }
          noValidate
          onSubmit={formik.handleSubmit}
          id={'parking-slot-no-' + slot.slotNumber}
          role='tabpanel'
          aria-labelledby={'parking-slot-no-' + slot.slotNumber + '-tab'}
          key={slot.id}
        >
          <div className={'tab-pane fade ' + (selectedTab == slot.slotNumber ? 'show active' : '')}>
            <div className='my-10'>
              <div className='slot-details'>
                <h4 className='font-16'>{t("Vehicle Details")}</h4>
                <div className='row'>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Brand")}<span className='text-danger'>*</span></label>
                      <input
                        type='text'
                        {...formik.getFieldProps('brand')}
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t("Brand")}
                        autoComplete='off'
                      />
                      {formik.touched.brand && formik.errors.brand && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.brand}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Model")}<span className='text-danger'>*</span></label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        {...formik.getFieldProps('model')}
                        placeholder={t("Model")}
                        autoComplete='off'
                      />
                      {formik.touched.model && formik.errors.model && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.model}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Color")}<span className='text-danger'>*</span></label>
                      <input
                        type='text'
                        {...formik.getFieldProps('color')}
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t("Color")}
                        autoComplete='off'
                      />
                      {formik.touched.color && formik.errors.color && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.color}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-0'>
                      <label className='form-label fw-bolder'>{t("Registered Plate Number")}<span className='text-danger'>*</span></label>
                      <input
                        type='text'
                        {...formik.getFieldProps('regno')}
                        className='form-control fw-bolder form-control-solid'
                        placeholder={t("Registered Plate Number")}
                        autoComplete='off'
                      />
                      {formik.touched.regno && formik.errors.regno && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.regno}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className='col-md-8 col-12'>
                        <div className='mt-7'>
                          <label className='form-label fw-bolder'>{t("Signature")}<span className='text-danger'>*</span></label>
                          <div className='border border-2 c-pointer'>
                            <ReactSignatureCanvas
                              ref={sigCanvas}
                              penColor='black'
                              canvasProps={{
                                height: 130,
                                width: 500,
                                className: 'sigCanvas',
                              }}
                              onEnd={handleSignature}
                            />
                            <button
                              type='button'
                              className='btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0'
                              onClick={clear}
                            >
                              {t("Reset")}
                            </button>
                          </div>
                          {formik.touched.signature && formik.errors.signature && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.signature}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row align-items-center mt-10">
              <div className='col'>
                <div className=''>
                  {/* <label className='form-label fw-bolder'>&nbsp;</label> */}
                  <div className='form-check form-check-sm custom'>
                    <input
                      className='form-check-input'
                      checked={formik.values.status ? true : false}
                      {...formik.getFieldProps('status')}
                      onChange={(e) => handleTerms(e)}
                      type='checkbox'
                      id='flexCheckDefault'
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className='form-check-label font-12 fw-normal'
                      htmlFor='flexCheckDefault'
                    >
                      {t("Accept")} 	&nbsp;
                      {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
                                {t("Terms & Conditions")}
                              </Link> */}
                      <button
                        data-bs-target="#terms-conditions-popup"
                        data-bs-toggle='modal'
                        className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                        {t("Terms & Conditions")}
                      </button>
                      <span className='text-danger'>*</span>
                    </label>
                  </div>
                  {formik.touched.status && formik.errors.status && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.status}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-auto text-end'>
                <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading}>
                  {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {t("Please wait...")}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div >
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <TermsAndConditionsPopup />
    </>

  )
}
