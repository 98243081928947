import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export function ServiceDataInfo({issue = ''}: any) {
  const { t } = useTranslation();

  return (
    <div className='row form-add-design px-0 py-4'>
      <div className='col-md-4 col-sm-6 col-12'>
        <div className='mb-4 mb-md-0'>
          <h6 className='text-theme-gray mb-3 fw-normal'> {t("Service Requested Date & Time")} </h6>
          <span className='text-black fw-bold'>
            {' '}
            {moment(issue?.created).format('D-MM-YYYY')} |{' '}
            {moment(issue?.created).format('h:mm A')}{' '}
          </span>
        </div>
      </div>
      <div className='col-md-4 col-sm-6 col-12'>
        <div className='mb-4 mb-md-0'>
          {/* <h6 className='text-theme-gray mb-3 fw-normal'> Payment type </h6> */}
          {/* <span className='text-black fw-bold'> Credit Card </span> */}
        </div>
      </div>
      <div className='col-md-4 col-sm-6 col-12'>
        <div className='mb-4 mb-md-0'>
          <h6 className='text-theme-gray mb-3 fw-normal'> {t("Confirmation")} </h6>
          <span className='text-black fw-bold'>{issue?.SRNumber}</span>
        </div>
      </div>
    </div>
  )
}
