import React from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {
  DrawerMessenger,
  ExploreMain,
  ActivityDrawer,
  FeedbackDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import {FeedbackSuccessfulPopup} from '../../pages/SuccessfulPopup/FeedbackSuccessfulPopup'
import {MakeanOfferNotification} from '../partials/layout/make-an-offer-notification/MakeanOfferNotification'
// import {CreateAdCampaign} from '../partials/layout/make-an-offer-notification/CreateContract/CreateAdCampaign'
import {ExtendOffer} from '../../pages/pm-offers-and-renewals/components/Details/ExtendOffer/ExtendOffer'
import {PMApprovedNotification} from '../partials/layout/pm-approved-notification/PMApprovedNotification'
import {CreateContractPopup} from "../partials/layout/pm-approved-notification/CreateContract/CreateContractPopup";

import {PaymentModal} from "../../pages/ContractExtensionRequestAccept/components/make-an-offer-notification/PaymentModal";

const MasterLayout: React.FC = ({children}) => {

  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userRole = JSON.parse(userDetail).id_role
  }

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
      <HeaderWrapper />
          {userRole === 4 || userRole === 14 || userRole === 5 || userRole === 6 || userRole === 16 ? 
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>{children}</Content>
              </div>
            </div>
            :
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid pt-18 pt-md-8 pt-lg-0'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>{children}</Content>
              </div>
            </div>
          }
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
        <ActivityDrawer />
        <MakeanOfferNotification />
        <ExtendOffer />
        <PMApprovedNotification />
        <CreateContractPopup />
        <PaymentModal />
        {/*<CreateAdCampaign />*/}
        <FeedbackDrawer />
        <FeedbackSuccessfulPopup />
        <ExploreMain />
        <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
