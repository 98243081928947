import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom'
import LeftArrow from "../../../../../../assets/images/Left_Arrow.png";
//import Home from "../../../../../../../assets/images/home.png";
import UploadIcon from '../../../../../../../../portal/src/assets/images/upload-ioc.png'
import {LeaseDetails} from '../../../../../statements/components/Add/AddCommission/LeaseDetails/LeaseDetails'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

export function PropertyDetails() {
    const [file, setFile,] = useState([] as any);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    function uploadSingleFile(e:any) {
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
        console.log("file", file);
    }
    const { t } = useTranslation();

    function upload(e:any) {
        e.preventDefault();
        console.log(file);
    }
    function deleteFile(e:any) {
        const s = file.filter((item:any, index:any) => index !== e);
        setFile(s);
        console.log(s);
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
        setUploadedFileName(inputRef.current.files[0].name);
    };
    return (
        <div className="">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="card-title font-18 text-black mb-7">
                                <Link to={'/contracts/uninvoiced'} className=''>
                                    <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                                </Link>
                                {t("Property Details")}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className="row">
                        <div className="col-12">
                            <LeaseDetails />
                        </div>
                    </div>
                    <div className="row mt-10">
                        {/*<form>*/}
                            <div className="row form-add-design">
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-10 upload-input">
                                        <h4>{t("Add Invoices")}</h4>
                                        <label className="form-label fw-bolder d-block mb-3">{t("Lease Agreement")}</label>
                                        <input ref={inputRef} onChange={uploadSingleFile} id="upload_1" className="d-none" type="file" multiple/>
                                        <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                            <span>{uploadedFileName ? uploadedFileName : <span>{t("Upload Invoice")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                        </button>
                                    </div>
                                    <div className="mb-7 upload-input">
                                        {file.length > 0 &&
                                        <div className="position-relative">
                                            <label className="form-label fw-bolder d-block">{t("Attach Images/Video")} </label>
                                            {file.map((item:any, index:any) => {
                                                return (
                                                    <div className="upload-image" key={item}>
                                                        <img src={item} alt="" />
                                                        <div className="close-icon" onClick={() => deleteFile(index)}>
                                                            &times;
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-12">
                                    <div className="mb-10 upload-input">
                                        <h4>Add Documents</h4>
                                        <label className="form-label fw-bolder d-block mb-3">{t("Lease Agreement")}</label>
                                        <input ref={inputRef} onChange={uploadSingleFile} id="upload_2" className="d-none" type="file" multiple/>
                                        <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                            <span>{uploadedFileName ? uploadedFileName : <span>{t("Upload Invoice")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                        </button>
                                    </div>
                                    <div className="mb-7 upload-input">
                                        {file.length > 0 &&
                                        <div className="position-relative">
                                            <label className="form-label fw-bolder d-block">{t("Attach Images/Video")}</label>
                                            {file.map((item:any, index:any) => {
                                                return (
                                                    <div className="upload-image" key={item}>
                                                        <img src={item} alt="" />
                                                        <div className="close-icon" onClick={() => deleteFile(index)}>
                                                            &times;
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-12 mt-auto">
                                    <div className="mb-10 upload-input">
                                        <label className="form-label fw-bolder d-block mb-3">{t("Trade License")}</label>
                                        <input ref={inputRef} onChange={uploadSingleFile} id="upload_3" className="d-none" type="file" multiple/>
                                        <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                                            <span>{uploadedFileName ? uploadedFileName : <span>{t("Upload Docs")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                        </button>
                                    </div>
                                    <div className="mb-7 upload-input">
                                        {file.length > 0 &&
                                        <div className="position-relative">
                                            <label className="form-label fw-bolder d-block">{t("Attach Images/Video")}</label>
                                            {file.map((item:any, index:any) => {
                                                return (
                                                    <div className="upload-image" key={item}>
                                                        <img src={item} alt="" />
                                                        <div className="close-icon" onClick={() => deleteFile(index)}>
                                                            &times;
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-8 col-12">
                                    <div className="mb-3">
                                        <label className="form-label fw-bolder">{t("Comments")}</label>
                                        <textarea
                                            className='form-control fw-bolder form-control-solid'
                                            data-kt-autosize='true'
                                            rows={3}
                                            placeholder={t("Type here...")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 text-end">
                                    <button data-bs-target="#req-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal" type='submit' className='btn btn-primary fw-bold px-10 py-3'>
                                        {t("Extend Offer")}
                                    </button>
                                </div>
                            </div>
                        {/*</form>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}