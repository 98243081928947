import React, { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import {
	createMoveInInspectionIssue,
	deleteInspectionIssue,
	getPreciseLocations,
	getServiceRequestByData,
	moveInInspectionRequest,
} from '../../../../constants/axios/apis'
import { InspectionIssue } from './InspectionIssue'
import { Form } from 'react-bootstrap-v5'
import FormData from 'form-data'
import { Link } from 'react-router-dom'
import ReactSignatureCanvas from 'react-signature-canvas'
import { useTranslation } from 'react-i18next'
import uploadImg from '../../../../assets/images/file-upload.svg'
import { TermsAndConditionsPopup } from '../../../terms-and-conditions/TermsAndConditionsPopup'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

type Props = {
	contract?: any
	stepStatus?: any
	getStepStatus?: any
	setMessage?: any
}

const Step1: FC<Props> = ({ contract, stepStatus, getStepStatus, setMessage }: any) => {
	const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
	const [preciseLocations, setPreciseLocations] = useState<any>()
	const [inspectionIssues, setInspectionIssues] = useState<any>([])
	const [loading, setLoading] = useState(false)
	const [isDamaged, setIsDamaged] = useState(false)
	const [confirmed, setIsConfirmed] = useState(false)
	const [confirmedSaveAndSubmit, setIsConfirmedSaveAndSubmit] = useState(false)
	const [confirmRemove, setConfirmRemove] = useState(false)
	const [issueToBeRemoved, setIssueToBeRemoved] = useState<any>(null)
	const [saveAndSubmit, setSaveAndSubmit] = useState<any>(false)
	const [inspectionData, setInspectionData] = useState<any>()
	const { t } = useTranslation();

	const inputRef = useRef<HTMLInputElement>(null)
	const closeRef = useRef<any>()
	const confirmRef = useRef<any>()
	const confirmSaveAndSubmitRef = useRef<any>()
	const removeRef = useRef<any>()
	const sigCanvas = useRef<any>()
	const damagedRef = useRef<any>()

	const IssueRequestSchema = Yup.object().shape({
		precise_location: Yup.string().required(t("Please select a location")),
		description: Yup.string().required(t("Please provide a description of the problem")),
		// photo: Yup.mixed()
		// 	.required(t("An image of the issue is required"))
		// 	.test('type', t("Only the following formats are accepted: .jpeg, .jpg, .png"), (value) => {
		// 		return value && (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png')
		// 	})
		// 	.test('fileSize', t("The file is too large!"), (value) => {
		// 		return value?.size <= 2000000
		// 	}),
		status: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
	})

	const InspectionRequestSchema = Yup.object().shape({
		termsAndConditions: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
		signature: Yup.string().required(t("Please add your signature")),
	})

	const initialValues = {
		category_id: 5,
		precise_location: '',
		property_id: contract?.propertyID,
		description: '',
		photo: '',
		status: 0,
	}

	const inspectionInitialValues = {
		signature: '',
		termsAndConditions: 0,
	}

	// const handleUpload = () => {
	// 	inputRef.current?.click()
	// }

	// const handleDisplayFileDetails = (e: any) => {
	// 	inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
	// 	formik.setFieldValue('photo', e.currentTarget.files[0])
	// }

	const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('photo', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('photo')
          formik.setFieldValue('photo', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('photo', true, false)
          formik.setFieldValue('photo', '', false)
          formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', '', false)
      formik.setFieldError('photo', t('File upload error!'))
      setUploading(false)
    }
 }

	const handleTerms = (e: any) => {
		if (e.target.checked) {
			return formik.setFieldValue('status', 1)
		}
		return formik.setFieldValue('status', 0)
	}

	const handleTermsAndConditions = (e: any) => {
		if (e.target.checked) {
			return inspectionFormik.setFieldValue('termsAndConditions', 1)
		}
		return inspectionFormik.setFieldValue('termsAndConditions', 0)
	}

	const fetchInspectionIssues = async () => {
		await getServiceRequestByData(5, '', contract?.propertyID).then((response: any) => {
			if (response.data) {
				let issues = response.data.filter((issue: any) => issue.status != 1)
				if (issues.length) {
					setInspectionIssues(issues)
				} else {
					setInspectionIssues([])
					setInspectionData(response.data[0])
				}
			} else {
				setInspectionIssues([])
			}
		})
	}

	useEffect(() => {
		fetchInspectionIssues()
	}, [])

	useEffect(() => {
		if (contract) {
			getPreciseLocations(contract?.propertyID).then(({ data: preciseLocationList }) => {
				setPreciseLocations(preciseLocationList['preciseLocationList'])
			})
		}
	}, [contract])

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema: IssueRequestSchema,
		onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
			if (saveAndSubmit) {
				confirmSaveAndSubmitRef.current.click()
			} else {
				setLoading(true)
				setTimeout(() => {
					const data = new FormData()
					data.append('category_id', values.category_id)
					data.append('property_id', values.property_id)
					data.append('precise_location', values.precise_location)
					data.append('desc', values.description)
					data.append('status', 0)
					data.append('photo[]', values.photo)

					createMoveInInspectionIssue(data)
						.then((response: any) => {
							setMessage(t("Your Move-in Inspection issue has been saved!"))
							closeRef.current.click()
							setLoading(false)
							resetForm()
							setUploadedFileName(null)
							formik.setFieldValue('status', false)
							formik.setFieldValue('photo', '')
							inputRef.current!.value = ''
							fetchInspectionIssues()
						})
						.catch((e) => {
							setLoading(false)
							setSubmitting(false)
							setStatus(t("Invalid Data Provided"))
						})
				}, 1000)

			}

		},
	})

	const inspectionFormik = useFormik({
		initialValues: inspectionInitialValues,
		enableReinitialize: true,
		validationSchema: InspectionRequestSchema,
		onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
			confirmRef.current.click()
		},
	})

	const removeIssue = (issue: any) => {
		setIssueToBeRemoved(issue)
		removeRef.current.click()
	}

	const submitMoveInInspection = () => {
		setMessage(t("Your Move-in Inspection Request has been Submitted!"))
		let issue_ids = inspectionIssues?.map((issue: any) => {
			if (issue.property_id === contract?.propertyID) {
				return issue.id
			}
		}).filter(Boolean)
		moveInInspectionRequest({ request_id: issue_ids.join() }).then((response: any) => {
			getStepStatus()
		})
		// confirmRef.current.click()
		setIsConfirmed(false)
		closeRef.current.click()
	}

	const submitMoveInInspectionWithoutIssues = () => {
		setMessage(t("Your Move-in Inspection Request has been Submitted!"))
		const data = new FormData()
		data.append('no_issues', '1')
		data.append('property_id', contract?.propertyID)
		data.append('signature', inspectionFormik.values.signature, 'signature.png')
		data.append('termsAndConditions', inspectionFormik.values.termsAndConditions)

		moveInInspectionRequest(data).then((response: any) => {
			getStepStatus()
			fetchInspectionIssues()
		})
		closeRef.current.click()
		setIsConfirmed(false)
		// confirmRef.current.click()
	}

	const saveIssues = () => {
		// confirmSaveAndSubmitRef.current.click()
		setLoading(true)
		const data = new FormData()
		data.append('category_id', formik.values.category_id)
		data.append('property_id', formik.values.property_id)
		data.append('precise_location', formik.values.precise_location)
		data.append('desc', formik.values.description)
		data.append('status', 0)
		data.append('photo[]', formik.values.photo)

		createMoveInInspectionIssue(data)
			.then((response: any) => {
				formik.resetForm()
				setUploadedFileName(null)
				formik.setFieldValue('status', false)
				formik.setFieldValue('photo', '')
				inputRef.current!.value = ''
				fetchInspectionIssues().then(response => {
					setSaveAndSubmit(false)
					setLoading(false)
					setIsConfirmed(true)
				})
			})
			.catch((e) => {
				setLoading(false)
				formik.setSubmitting(false)
				formik.setStatus(t("Invalid Data Provided"))
			})
	}

	useEffect(() => {
		if (confirmed) {
			let issue_ids = inspectionIssues?.map((issue: any) => {
				if (issue.property_id === contract?.propertyID) {
					return issue.id
				}
			}).filter(Boolean)
			if (issue_ids.length > 0) {
				submitMoveInInspection()
			} else {
				submitMoveInInspectionWithoutIssues()
			}
		}
	}, [confirmed])

	useEffect(() => {
		if (confirmedSaveAndSubmit) {
			saveIssues()
		}
	}, [confirmedSaveAndSubmit])

	useEffect(() => {
		if (confirmRemove && issueToBeRemoved) {
			setInspectionIssues((prevState: any) => prevState.filter((prevItem: any) => prevItem !== issueToBeRemoved))
			deleteInspectionIssue(issueToBeRemoved.id).then((response: any) => { })
			removeRef.current.click()
			setConfirmRemove(false)
			setIssueToBeRemoved(null)
		}
	}, [confirmRemove, issueToBeRemoved])

	const preciseLocationOptions = preciseLocations?.map((location: any, index: any) => (
		<option key={index} value={location?.id}>
			{location?.name}
		</option>
	))

	const issues = inspectionIssues?.map((issue: any) => (
		<InspectionIssue
			key={issue.id}
			issue={issue}
			property_id={contract?.propertyID}
			preciseLocationOptions={preciseLocationOptions}
			triggerRemoveIssue={removeIssue}
			triggerFetchIssues={fetchInspectionIssues}
			stepStatus={stepStatus}
		/>
	))
	const handleChange = (e: any) => {
		setIsDamaged(e.target.checked)
		formik.resetForm()
		setUploadedFileName(null)
		formik.setFieldValue('status', false)
		formik.setFieldValue('photo', '')
		if(inputRef.current)
			inputRef.current!.value = ''
	}

	const clear = () => {
		sigCanvas.current.clear()
		inspectionFormik.setFieldValue('signature', '')
	}

	const handleSignature = () => {
		const canvas = sigCanvas.current.getCanvas()
		canvas.toBlob((blob: string) => {
			inspectionFormik.setFieldValue('signature', blob)
		})
	}

	return (
		<>
			<div className='com-card-section w-100'>
				<div className='row'>
					{!stepStatus &&
						<Form style={{ paddingBottom: '35px' }}>
							<Form.Check
								type="switch"
								id="custom-switch"
								label={"Report an issue"}
								defaultChecked={inspectionIssues?.length === 0 ? false : true}
								onChange={handleChange}
								ref={damagedRef}
							/>
						</Form>
					}
					<div className='col-md-12'>
						{inspectionIssues ? issues : ''}
						{!stepStatus ? (
							<>
								{isDamaged ? (
									<form
										className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
										noValidate
										id='inspectionForm'
										onSubmit={formik.handleSubmit}
										key={'form1'}
									>
										<div className='row form-add-design'>
											<div className='col-md-12 mb-15'>
											</div>
											<div>
												<button
													type='button'
													className='btn btn-secondary float-end border-0 rounded-rounded p-2'
													onClick={() => damagedRef.current.click()}
												>
													<i className="bi bi-x-lg p-0"></i>
												</button>
											</div>
											<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
												<div className='mb-10 upload-input'>
													<label className='form-label fw-bolder d-block'>{t("Upload Image")}<span className='text-danger'>*</span></label>
													<input
														ref={inputRef}
														onChange={(e) => handleDisplayFileDetails(e)}
														className='d-none'
														type='file'
														accept=".png,.jpg,.jpeg"
													/>
													<button
														onClick={handleUpload}
														type='button'
														disabled={uploading}
														className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
															}`}
													>
														{/* <span>
															{uploadedFileName ? (
																uploadedFileName
															) : (
																<span  className="txt">
																	<img
																			alt="File"
																			src={uploadImg}
																	className="position-relative"
																	loading="lazy"/>
																	{t("Upload")}
																</span>
															)}{' '}
														</span> */}
														<span>
															{uploading ?
																<span className='indicator-progress d-block'>
																	{t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
																</span>
																: uploadedFileName ? (
																	uploadedFileName
																) : (
																	<span className="txt">
																		<img
																			alt="File"
																			src={uploadImg}
																		className="position-relative"
																		loading="lazy"/>
																		{t('Upload')}{' '}
																	</span>
																)}{' '}
														</span>
													</button>
													{formik.touched.photo && formik.errors.photo && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block'>
																<span role='alert' className='text-danger'>
																	{formik.errors.photo}
																</span>
															</div>
														</div>
													)}
												</div>
											</div>
											<div className='col-md-8 col-sm-8 col-xl-8 col-12'>
												<div className='mb-10'>
													<label className='form-label fw-bolder'>{t("Select Room")}<span className='text-danger'>*</span></label>
													<select
														className='form-control form-select fw-bolder form-control-solid'
														name='precise_location'
														onChange={formik.handleChange}
														value={formik.values.precise_location}
														onBlur={formik.handleBlur}
													>
														<option value='' disabled>
															{t("Please Select")}
														</option>
														{preciseLocations ? preciseLocationOptions : ''}
													</select>
													{formik.touched.precise_location && formik.errors.precise_location && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block'>
																<span role='alert' className='text-danger'>
																	{formik.errors.precise_location}
																</span>
															</div>
														</div>
													)}
												</div>
											</div>
											<div className='col-md-12 col-sm-12 col-xl-12 col-12'>
												<div className='mb-10'>
													<label className='form-label fw-bolder'>{t("Description")}<span className='text-danger'>*</span></label>
													<textarea
														className={clsx(
															'form-control mb-0 typing-area me-5 px-2 form-control-solid',
															{
																'is-invalid': formik.touched.description && formik.errors.description,
															},
															{
																'is-valid': formik.touched.description && !formik.errors.description,
															}
														)}
														data-kt-element='input'
														rows={3}
														name='description'
														placeholder={t("Type here...")}
														onBlur={formik.handleBlur}
														autoComplete='off'
														value={formik.values.description}
														onChange={formik.handleChange}
													></textarea>
													{formik.touched.description && formik.errors.description && (
														<div className='fv-plugins-message-container'>
															<div className='fv-help-block'>
																<span role='alert' className='text-danger'>
																	{formik.errors.description}
																</span>
															</div>
														</div>
													)}
												</div>
											</div>
											<div className='col-md-6 col-12 mb-0'>
												<div className='form-check form-check-sm custom'>
													<input
														className='form-check-input'
														checked={formik.values.status ? true : false}
														{...formik.getFieldProps('status')}
														onChange={(e) => handleTerms(e)}
														type='checkbox'
														id='flexCheckDefault'
														onBlur={formik.handleBlur}
													/>
													<label
														className='form-check-label font-12 fw-normal'
														htmlFor='flexCheckDefault'
													>
														{t("Accept")} 	&nbsp;
														{/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
															{t("Terms & Conditions")}
														</Link> */}
														<button
															type='button'
															data-bs-target="#terms-conditions-popup"
															data-bs-toggle='modal'
															className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
															{t("Terms & Conditions")}
														</button>
														<span className='text-danger'>*</span>
													</label>
												</div>
												{formik.touched.status && formik.errors.status && (
													<div className='fv-plugins-message-container'>
														<div className='fv-help-block'>
															<span role='alert' className='text-danger'>
																{formik.errors.status}
															</span>
														</div>
													</div>
												)}
											</div>
										</div>

										<div className='col-md-12 text-end'>
											<button
												type='submit'
												className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
												onClick={() => setSaveAndSubmit(false)}
												disabled={loading || uploading}
											>
												{!loading && <span className='indicator-label'>{t("Save & Add More")}</span>}
												{loading && (
													<span className='indicator-progress' style={{ display: 'block' }}>
														{t("Please wait...")}{' '}
														<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
													</span>
												)}
											</button>
											<button
												type='submit'
												className='btn btn-primary fw-bold px-10 py-3'
												onClick={() => setSaveAndSubmit(true)}
												disabled={loading || uploading}
											>
												<span className='indicator-label'>{t("Submit Move In Inspection")}</span>
											</button>
										</div>
									</form>
								) : (
									<>
										{!inspectionIssues.length ? (
											<form
												className='form w-100 fv-plugins-bootstrap5 form-add-design fv-plugins-framework'
												noValidate
												id='inspectionForm'
												onSubmit={inspectionFormik.handleSubmit}
												key={'form2'}
											>
												<div className='col-12'>
													<div className='row mb-7'>
														<div className='col-md-8 col-12'>
															<div className='mt-0'>
																<label className='form-label fw-bolder'>{t("Signature")}<span className='text-danger'>*</span></label>
																<div className='border border-2'>
																	<ReactSignatureCanvas
																		ref={sigCanvas}
																		penColor='black'
																		canvasProps={{
																			height: 130,
																			width: 500,
																			className: 'sigCanvas',
																		}}
																		onEnd={handleSignature}
																	/>
																	<button
																		type='button'
																		className='btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0'
																		onClick={clear}
																	>
																		{t("Reset")}
																	</button>
																</div>
																{inspectionFormik.touched.signature && inspectionFormik.errors.signature && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>
																			<span role='alert' className='text-danger'>
																				{inspectionFormik.errors.signature}
																			</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
													</div>
													<div className='row align-items-center'>
														<div className='col-md-7 col-12 mb-0'>
															<div className='form-check form-check-sm custom'>
																<input
																	className='form-check-input'
																	checked={inspectionFormik.values.termsAndConditions ? true : false}
																	{...inspectionFormik.getFieldProps('termsAndConditions')}
																	onChange={(e) => handleTermsAndConditions(e)}
																	type='checkbox'
																	id='flexCheckDefault'
																	onBlur={inspectionFormik.handleBlur}
																/>
																<label
																	className='form-check-label font-12 fw-normal'
																	htmlFor='flexCheckDefault'
																>
																	{t("Accept")} 	&nbsp;
																	{/*<Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">*/}
																		{/*{t("Terms & Conditions")}*/}
																	{/*</Link>*/}
                                                                    <button
																		type='button'
                                                                        data-bs-target="#terms-conditions-popup"
                                                                        data-bs-toggle='modal'
                                                                        className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                                                                        {t("Terms & Conditions")}
                                                                    </button>
																	<span className='text-danger'>*</span>
																</label>
																{inspectionFormik.touched.termsAndConditions && inspectionFormik.errors.termsAndConditions && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>
																			<span role='alert' className='text-danger'>
																				{inspectionFormik.errors.termsAndConditions}
																			</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className='col-md-5 col-12 mb-0 text-end'>
															<button
																type='submit'
																className='btn btn-primary fw-bold px-10 py-3'
															>
																<span className='indicator-label'>{t("Submit Move In Inspection")}</span>
															</button>
														</div>
													</div>
												</div>
											</form>

										) : (
											<div className='col-md-12 text-end'>
												<button
													type='submit'
													className='btn btn-primary fw-bold px-10 py-3'
													onClick={() => confirmRef.current.click()}
												>
													<span className='indicator-label'>{t("Submit Move In Inspection")}</span>
												</button>
											</div>
										)
										}
									</>
								)}
							</>
						) : (
							<>
								{stepStatus && !inspectionIssues.length && (
									<>
										<p>{t("Move In Inspection submitted")}</p>
										<div>
											<label className='form-label fw-bolder'>{t("Signature")}</label>
											<img
												src={inspectionData?.images?.signature}
												alt="document"
												className='img-fluid me-5'
												style={{ height: 'auto' }}
											/>
										</div>
									</>
								)}
							</>
						)}
					</div>
				</div>

				<div className='modal fade custom-modal logout-popup' id='confirm-inspection-modal'>
					<div className='modal-dialog'>
						<div className='modal-content rounded-10'>
							<div className='modal-header border-0'>
								<h5 className="modal-title">{t("Confirm")}</h5>
								<button type='button' className='btn-close' data-bs-dismiss='modal'>
									&times;
								</button>
							</div>
							<div className='modal-body px-5 py-8'>
								<div className="row">
									<div className="col-md-12 text-center">
										<h1 className="text-black fw-bold font-20 mb-4">
											{t("Are you sure you want to submit the move-in inspection?")}
										</h1>
									</div>
									<div className="col-md-12 text-center py-1">
										<button type='button' data-bs-dismiss="modal" className='btn btn-outline-primary font-14 fw-bold px-10 py-3 mx-4'>
											{t("Cancel")}
										</button>
										<button type='button' data-bs-dismiss="modal" onClick={() => setIsConfirmed(true)} className='btn btn-primary fw-bold px-10 py-3 font-14'>
											{t("Submit")}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='modal fade custom-modal logout-popup' id='confirm-save-submit-inspection-modal'>
					<div className='modal-dialog'>
						<div className='modal-content rounded-10'>
							<div className='modal-header border-0'>
								<h5 className="modal-title">{t("Confirm")}</h5>
								<button type='button' className='btn-close' data-bs-dismiss='modal'>
									&times;
								</button>
							</div>
							<div className='modal-body px-5 py-8'>
								<div className="row">
									<div className="col-md-12 text-center">
										<h1 className="text-black fw-bold font-20 mb-4">
											{t("Are you sure you want to save & submit the move-in inspection?")}
										</h1>
									</div>
									<div className="col-md-12 text-center py-1">
										<button data-bs-dismiss="modal" className='btn btn-outline-primary font-14 fw-bold px-10 py-3 mx-4'>
											{t("Cancel")}
										</button>
										<button data-bs-dismiss="modal" onClick={() => setIsConfirmedSaveAndSubmit(true)} className='btn btn-primary fw-bold px-10 py-3 font-14'>
											{t("Submit")}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='modal fade custom-modal logout-popup' id='remove-issue-modal'>
					<div className='modal-dialog'>
						<div className='modal-content rounded-10'>
							<div className='modal-header border-0'>
								<h5 className="modal-title">{t("Confirm")}</h5>
								<button type='button' className='btn-close' data-bs-dismiss='modal'>
									&times;
								</button>
							</div>
							<div className='modal-body px-5 py-8'>
								<div className="row">
									<div className="col-md-12 text-center">
										<h1 className="text-black fw-bold font-20 mb-4">
											{t("Are you sure you want to remove this issue?")}
										</h1>
									</div>
									<div className="col-md-12 text-center py-1">
										<button type="button" data-bs-dismiss="modal" className='btn btn-outline-primary font-14 fw-bold px-10 py-3 mx-4'>
											{t("Cancel")}
										</button>
										<button type="button" data-bs-dismiss="modal" onClick={() => setConfirmRemove(true)} className='btn btn-primary fw-bold px-10 py-3 font-14'>
											{t("Confirm")}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p
				ref={closeRef}
				data-bs-target='#req-submitted-popup'
				data-bs-toggle='modal'
				data-bs-dismiss='modal'
			>
				{/*  */}
			</p>

			<p
				ref={confirmRef}
				data-bs-target='#confirm-inspection-modal'
				data-bs-toggle='modal'
				data-bs-dismiss='modal'
			>
				{/*  */}
			</p>

			<p
				ref={confirmSaveAndSubmitRef}
				data-bs-target='#confirm-save-submit-inspection-modal'
				data-bs-toggle='modal'
				data-bs-dismiss='modal'
			>
				{/*  */}
			</p>

			<p
				ref={removeRef}
				data-bs-target='#remove-issue-modal'
				data-bs-toggle='modal'
				data-bs-dismiss='modal'
			>
				{/*  */}
			</p>
			<TermsAndConditionsPopup />
		</>
	)
}

export { Step1 }
