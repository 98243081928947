import React, { useState, useRef, FC } from 'react';
import PlusIcon from "../../../../../../assets/images/plus-circle.svg";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { moveInAuthorityApproval } from '../../../../../../constants/axios/apis';
import { ViewPopup } from '../../../../../maintenance/ViewPopup';
import pdficon from '../../../../../../assets/images/pdf-file.png'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../../../constants/generics/fileUploadHandler';
import { Alert } from 'react-bootstrap-v5';

type Props = {
    contract?: any
    stepStatus?: any
    getStepStatus?: any
}

const DocumentsUpload: FC<Props> = ({ contract, getStepStatus, stepStatus }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<any>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    const [inputBeingUploaded, setInputBeingUploaded] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [viewImage, setViewImage] = useState<any>()
    const [uploadedFileName1, setUploadedFileName1] = useState<string | null>(null);
    const [uploadedFileName2, setUploadedFileName2] = useState<string | null>(null);
    const [uploadedFileName3, setUploadedFileName3] = useState<string | null>(null);
    const [uploadedFileName4, setUploadedFileName4] = useState<string | null>(null);
    const [submitError, setSubmitError] = useState<string>('')

    const inputRef1 = useRef<HTMLInputElement>(null)
    const inputRef2 = useRef<HTMLInputElement>(null)
    const inputRef3 = useRef<HTMLInputElement>(null)
    const inputRef4 = useRef<HTMLInputElement>(null)

    const handleDisplayFileDetails = (reference: any, setUploadedFileName: any, fieldName: string) => {
        const inputFile = reference.current?.files?.[0]
        let isAllowedType: any = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(inputFile?.type)

        if (inputFile && isAllowedType) {
            if (inputFile.type === 'application/pdf') {
                if (inputFile.size > 5242880) {
                    setError(true)
                    setErrorMessage(t('The file is too large! Max allowed size is 5MB.'))
                    setTimeout(() => { setError(false) }, 3000)
                } else {
                    setUploadedFileName(inputFile.name)
                    formik.setFieldTouched(fieldName)
                    formik.setFieldValue(fieldName, inputFile)
                }
            } else {
                setUploading(true)
                setInputBeingUploaded(fieldName)
                setUploadedFileName()
                try {
                    validateAndCompressImage(inputFile).then((compressResponse) => {
                        if (compressResponse?.success) {
                            setUploadedFileName(inputFile.name)
                            formik.setFieldTouched(fieldName)
                            formik.setFieldValue(fieldName, inputFile)
                        } else {
                            setError(true)
                            setErrorMessage(t(compressResponse?.message || 'File compression error!'))
                            setTimeout(() => { setError(false) }, 3000)
                        }
                        setUploading(false)
                    })
                } catch (error) {
                    alert(t('File upload error!'))
                    setError(true)
                    setErrorMessage(t('File upload error!'))
                    setTimeout(() => { setError(false) }, 3000)
                    setUploading(false)
                }
            }
        } else {
            setError(true)
            setErrorMessage(t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'))
            setTimeout(() => {
                setError(false)
            }, 3000)
        }
    }

    const initialValues = {
        contract_id: contract?.id,
        property_id: contract?.propertyID,
        category_id: 36,
        adcd: '',
        addc: '',
        adm: '',
        site_map: '',
    }

    const documentVerficationSchema = Yup.object().shape({
        adcd: Yup.mixed()
            .required(t("ADCD is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        addc: Yup.mixed()
            .required(t("ADDC is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        adm: Yup.mixed()
            .required(t("ADM is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            }),
        site_map: Yup.mixed()
            // .required(t("Site Map is required"))
            .test('type', t("Only the following formats are acceptable: .png, .jpeg, .jpg .pdf"), (value) => {
                if (!value) return true
                return (
                    value &&
                    (value.type === 'image/png' || value.type === 'image/jpeg' ||
                        value.type === 'image/jpg' ||
                        value.type === 'application/pdf')
                )
            })
            .nullable(),
    })

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: documentVerficationSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setLoading(true)

            var FormData = require('form-data')
            var data = new FormData()
            data.append('contract_id', formik.values.contract_id)
            data.append('property_id', formik.values.property_id)
            data.append('category_id', formik.values.category_id)
            data.append('adcd_approval', formik.values.adcd)
            data.append('addc_approval', formik.values.addc)
            data.append('adm_approval', formik.values.adm)
            data.append('site_MAP', formik.values.site_map)

            setTimeout(() => {
                moveInAuthorityApproval(data)
                    .then((response: any) => {
                        setLoading(false)
                        console.log(response)
                        if (response.errorCode === 0) {
                            getStepStatus()
                        }else{
                            setSubmitError(response.errorDescription)
                        }
                    })
                    .catch((e) => {
                        setSubmitting(false)
                        setSubmitError(t("Invalid Data Provided"))
                    })
            }, 1000)
        },
    })

    const handleView = (data: any) => {
        setViewImage(data)
    }

    return (
        <div className="com-card-section">
            <div className="row documents-card form-add-design pt-6">
                <div className="col-12">
                    <form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_login_signup_form'
                        onSubmit={formik.handleSubmit}
                    >

                        {error ? (
                            <Alert className='font-14 fw-bold mb-8' variant='danger'>
                                {errorMessage}!
                            </Alert>
                        ) : null}

                        {stepStatus.status ? (
                            <>
                                <div className="row mb-6">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADCD")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.adcd_approval && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.adcd_approval)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.adcd_approval.substr(stepStatus?.data?.images?.adcd_approval.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.adcd_approval
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button>
                                                                <ViewPopup viewImage={viewImage} />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADDC")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.addc_approval && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.addc_approval)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.addc_approval.substr(stepStatus?.data?.images?.addc_approval.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.addc_approval
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button>
                                                                <ViewPopup viewImage={viewImage} />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADM")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.adm_approval && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.adm_approval)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.adm_approval.substr(stepStatus?.data?.images?.adm_approval.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.adm_approval
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button>
                                                                <ViewPopup viewImage={viewImage} />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black text-uppercase font-14 fw-bold d-block mb-5"> {t("Site Map")} </label>
                                                    <div className='d-flex service-req-img align-items-center'>
                                                        {stepStatus?.data?.images?.site_map && (
                                                            <>
                                                                <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-dismiss='modal'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        handleView(stepStatus?.data?.images?.site_map)
                                                                    }}>
                                                                    <img
                                                                        src={
                                                                            stepStatus?.data?.images?.site_map.substr(stepStatus?.data?.images?.site_map.lastIndexOf('.')).includes('.pdf')
                                                                                ? pdficon
                                                                                : stepStatus?.data?.images?.site_map
                                                                        }
                                                                        alt="document"
                                                                        className='img-fluid me-5'
                                                                    />
                                                                </button>
                                                                <ViewPopup viewImage={viewImage} />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row mb-6">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADCD")} </label>
                                                    <input
                                                        name="adcd"
                                                        ref={inputRef1}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef1, setUploadedFileName1, 'adcd')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef1.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName1 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'adcd') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName1 || uploadedFileName1 ? (
                                                                    uploadedFileName1 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.adcd && formik.errors.adcd ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.adcd}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADDC")} </label>
                                                    <input
                                                        name="addc"
                                                        ref={inputRef2}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef2, setUploadedFileName2, 'addc')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef2.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName2 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'addc') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName2 || uploadedFileName2 ? (
                                                                    uploadedFileName2 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.addc && formik.errors.addc ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.addc}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-5 upload-input">
                                                    <label className="form-label text-black font-14 fw-bold d-block mb-5"> {t("ADM")} </label>
                                                    <input
                                                        name="adm"
                                                        ref={inputRef3}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef3, setUploadedFileName3, 'adm')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef3.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName3 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'adm') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName3 || uploadedFileName3 ? (
                                                                    uploadedFileName3 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.adm && formik.errors.adm ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.adm}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="mb-3 upload-input">
                                                    <label className="form-label text-black text-uppercase font-14 fw-bold d-block mb-5"> {t("Site Map")} </label>
                                                    <input
                                                        name="site_map"
                                                        ref={inputRef4}
                                                        className="d-none"
                                                        type="file"
                                                        onChange={() => handleDisplayFileDetails(inputRef4, setUploadedFileName4, 'site_map')}
                                                        onBlur={formik.handleBlur}
                                                        accept='.png,.jpeg,.jpg,application/pdf'
                                                    />
                                                    <button type='button'
                                                        onClick={() => inputRef4.current?.click()}
                                                        className={
                                                            `w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName4 ? "primary active" : "primary"}`
                                                        }
                                                    >
                                                        <span className="pt-5">
                                                            {(uploading && inputBeingUploaded === 'site_map') ?
                                                                <span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
                                                                : uploadedFileName4 || uploadedFileName4 ? (
                                                                    uploadedFileName4 || ''
                                                                ) : (
                                                                    <img src={PlusIcon} alt="Add File" className='img-fluid va-baseline' />
                                                                )}
                                                        </span>
                                                    </button>
                                                    {formik.touched.site_map && formik.errors.site_map ? (
                                                        <div className='d-flex flex-column text-danger'>
                                                            <small className="text-danger">{formik.errors.site_map}</small>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='col-12 text-end'>
                                                <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading || uploading}>
                                                    {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            {t("Please wait...")}{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                            { submitError !== '' &&
                                                <div className="col-12 mt-3">
                                                    <div className='alert alert-danger w-100 text-center' role='alert'>
                                                        {submitError}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export { DocumentsUpload }
