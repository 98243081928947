import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {TeamSearch} from './Search/Search';
import { useTranslation } from 'react-i18next'

const ContractsPage: React.FC = () => {
    const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="row">
            <div className="col-md-12 col-12">
                <div className="row">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="card-title all_card_title">{t("Contracts")}</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className='d-flex overflow-auto h-55px'>
                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                <li className='nav-item'>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-12 ` +
                                        (location.pathname === '/contracts/uninvoiced' && 'active')}
                                        to='/contracts/uninvoiced'>
                                        {t("Uninvoiced")}
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link
                                        className={`nav-link pb-1 position-relative text-active-primary me-6 ` +
                                        (location.pathname === '/contracts/invoiced' && 'active')}
                                        to='/contracts/invoiced'>
                                        {t("Invoiced")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {ContractsPage}
