import React, { useEffect, useState } from 'react'
import { ServiceDataInfo } from './ServiceDataInfo'
import { AccessCardPopUp } from './AccessCardPopUp'
import { useTranslation } from 'react-i18next'

export function AccessCardRequestDetail({ selectedIssue }: any) {
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])

  return (
    <>
      <div className='com-card-section'>
        {!loading && issue && issue?.code == 'AC' ? (
          <div className='row mta-app-setting-campaign'>
            <div className='col-12'>
              <div className='accordion accordion-flush' id='accordionFlushExampleAccesscard'>
                <div className='accordion-item offers_details_accordian_div1'>
                  <h2 className='accordion-header position-relative' id='flush-headingAccesscard'>
                    <button
                      className='accordion-button collapsed accordian_bt'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseAccesscard'
                      aria-expanded='false'
                      aria-controls='flush-collapseAccesscard'
                    >
                      {t("Access Card Request Detail")}
                    </button>
                    <span className='services-badge'>
                      {issue?.status == 0 ? t("Open") : t("Closed")}
                    </span>
                  </h2>
                  <div
                    id='flush-collapseAccesscard'
                    className='accordion-collapse collapse show'
                    aria-labelledby='flush-headingAccesscard'
                    data-bs-parent='#accordionFlushExampleAccesscard'
                  >
                    <div className='accordion-body py-5'>
                      <div className='row form-add-design'>
                        <div className='col-md-4 col-12'>
                          <div className='mb-10 position-relative'>
                            <label className='form-label fw-bolder'>{t("Access Cards")}</label>
                            <div className='d-flex fdrr-rtl'>
                              <input
                                type='text'
                                name=''
                                className='form-control expected-rent-input'
                                placeholder=''
                                value={'Access Cards (' + issue?.details.no_of_cards + ')'}
                                disabled
                              />
                              <button
                                type='submit'
                                className='bg-white cancel-bt px-7'
                                data-bs-target='#access-card-popup'
                                data-bs-toggle='modal'
                              >
                                <i className='bi bi-eye font-18 text-black'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Price")}</label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              disabled
                              value={
                                (issue?.details.no_of_cards * issue?.details.amount
                                  + (issue?.details.no_of_cards * issue?.details.vat_amount))
                                  ?.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                + ' AED'
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <ServiceDataInfo issue={issue} />
                      <AccessCardPopUp issue={issue} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
