import {Action} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
// ** Define API endPoints **/
export const GET_MYVISITS = `${API_URL}/myVisits/all`
export const GET_MYUPCOMINGVISITS = `${API_URL}/myVisits/upcoming`
export const GET_MYLIKES = `${API_URL}/getAllUserLikes`
export const GET_MYOFFERLIST = `${API_URL}/offerList`
export const GET_MYOFFERDETAILS = `${API_URL}/offerDetail`
export const GET_MYCONTRACT = `${API_URL}/getAllContract`

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

// ** Define Actions "ALL ACTIONS NAMES SHOULD BE UNIQUE!!!!" **
export const actionTypes = {
  Visits: '[Visits] Action',
  UpcomingVisits: '[UpcomingVisits] Action',
  Likes: '[Likes] Action',
  OfferList: '[OfferList] Action',
  OfferDetails: '[OfferDetails] Action',
  ResetOfferDetails: '[ResetOfferDetails] Action',
  AllContract: '[AllContract] Action',
  ScheduleData: '[ScheduleData] Action',

  VisitsLoaded: '[Load Visits] Load data',
  UpcomingVisitsLoaded: '[Upcoming Load Visits] Load data',
  LikesLoaded: '[Load Likes] Load data',
  OfferLoaded: '[Load Offer] Load data',
  OfferDeatilsLoaded: '[Load Offer Deatils] Load data',
  AllContractLoaded: '[Load AllContract] Load data',
}

// ** Define initialDashboardState **
const initialDashboardState: IDashboardState = {
  vistis: undefined,
  upcomingVisits: undefined,
  likes: undefined,
  offerList: undefined,
  offerDetails: undefined,
  resetOfferDetails: undefined,
  allContract: undefined,
  accessToken: undefined,
  scheduleData: undefined,
}
export interface IDashboardState {
  vistis?: ''
  upcomingVisits?: ''
  likes?: ''
  offerList?: ''
  offerDetails?: ''
  resetOfferDetails?: ''
  allContract?: ''
  scheduleData?: ''
  accessToken?: string
}

// ** Add Reducers **
// ** Once done make sure to import and add to rootReducer in RootReducer.ts file
export const reducer = persistReducer(
  {
    storage,
    key: 'PTdashboard',
    blacklist: [
      'vistis',
      'likes',
      'offerList',
      'allContract',
      'upcomingVisits',
      'offerDetails',
      'scheduleData',
    ],
  },
  (state: IDashboardState = initialDashboardState, action: ActionWithPayload<IDashboardState>) => {
    switch (action.type) {
      //   case actionTypes.Visits: {
      //     const accessToken = action.payload?.accessToken
      //     return {accessToken, vistis: undefined}
      //   }

      //   case actionTypes.Likes: {
      //     const accessToken = action.payload?.accessToken
      //     return {accessToken, likes: undefined}
      //   }

      //   case actionTypes.OfferList: {
      //     const accessToken = action.payload?.accessToken
      //     return {accessToken, offerList: undefined}
      //   }

      //   case actionTypes.AllContract: {
      //     return {...state, allContract: undefined}
      //   }
      // ** Reducer for Visits data load **
      case actionTypes.VisitsLoaded: {
        const vistis = action.payload?.vistis
        return {...state, vistis}
      }
      // ** Reducer for Upcoming Visits data load **
      case actionTypes.UpcomingVisitsLoaded: {
        const upcomingVisits = action.payload?.upcomingVisits
        return {...state, upcomingVisits}
      }
      // ** Reducer for Likes data load **
      case actionTypes.LikesLoaded: {
        const likes = action.payload?.likes
        return {...state, likes}
      }
      // ** Reducer for Offers data load **
      case actionTypes.OfferLoaded: {
        const offerList = action.payload?.offerList
        return {...state, offerList}
      }
      // ** Reducer for Offers Deatils data load **
      case actionTypes.OfferDeatilsLoaded: {
        const offerDetails = action.payload?.offerDetails
        return {...state, offerDetails}
      }
      // ** Reducer to Reset Offers Deatils **
      case actionTypes.ResetOfferDetails: {
        const resetOfferDetails = action.payload?.resetOfferDetails
        return {...state, resetOfferDetails}
        // return (resetOfferDetails)
        // const offerDetailset = {}
        // return {...state, offerDetailset}
      }
      // ** Reducer for AllContracts data load **
      case actionTypes.AllContractLoaded: {
        const allContract = action.payload?.allContract
        return {...state, allContract}
      }
      // ** Reducer for scheduleData data store **
      case actionTypes.ScheduleData: {
        const scheduleData = action.payload?.scheduleData
        return {...state, scheduleData: scheduleData}
      }
      default:
        return state
    }
  }
)
export function myVisits() {
  // ** Authorization head should be fulfilled in interceptor.
  // ** Check common redux folder => setupAxios
  return axios.get(GET_MYVISITS)
}
export function myUpcomingVisits() {
  return axios.get(GET_MYUPCOMINGVISITS)
}
export function myLikes() {
  return axios.get(GET_MYLIKES)
}
export function myOffers() {
  return axios.get(GET_MYOFFERLIST)
}
export function myOffersDetails(id: any) {
  return axios.get(GET_MYOFFERDETAILS + '/' + id)
}
export function myAllContracts() {
  return axios.get(GET_MYCONTRACT)
}
export const actions = {
  // ** Get My Visits **
  vistis: () => ({type: actionTypes.Visits}),
  fulfillMyVisits: (vistis: '') => ({type: actionTypes.VisitsLoaded, payload: {vistis}}),
  // ** Get My Upcoming Visits **
  upcomingVisits: () => ({type: actionTypes.UpcomingVisits}),
  fulfillMyUpcomingVisits: (upcomingVisits: '') => ({
    type: actionTypes.UpcomingVisitsLoaded,
    payload: {upcomingVisits},
  }),
  // ** Get My Likes **
  likes: () => ({type: actionTypes.Likes}),
  fulfillMyLikes: (likes: '') => ({type: actionTypes.LikesLoaded, payload: {likes}}),
  // ** Get My Offer List **
  offerList: () => ({type: actionTypes.OfferList}),
  fulfillMyOffers: (offerList: '') => ({type: actionTypes.OfferLoaded, payload: {offerList}}),
  // ** Get My Offer Details List **

  offerDetails: (id: any) => ({type: actionTypes.OfferDetails, payload: {id}}),
  fulfillMyOffersDetails: (offerDetails: '') => ({
    type: actionTypes.OfferDeatilsLoaded,
    payload: {offerDetails},
  }),
  resetOfferDetails: () => ({type: actionTypes.ResetOfferDetails}),
  // ** Get My AllContract List **
  allContract: () => ({type: actionTypes.AllContract}),
  fulfillMyAllContracts: (allContract: '') => ({
    type: actionTypes.AllContractLoaded,
    payload: {allContract},
  }),
  scheduleData: (scheduleData: any) => ({
    type: actionTypes.ScheduleData,
    payload: {scheduleData},
  }),
}
// ** Define Saga [Make sure to maintain unique names]
// ** Once done make sure to import and add to rootSaga in RootReducer.ts file
export function* PTDashboardsaga() {
  // ** Get My Visits Dispatch**
  yield takeLatest(actionTypes.Visits, function* userRequested() {
    const {data: visits} = yield myVisits()
    yield put(actions.fulfillMyVisits(visits))
  })
  // ** Get My Upcoming Visits Dispatch**
  yield takeLatest(actionTypes.UpcomingVisits, function* userRequested() {
    const {data: upcomingVisits} = yield myUpcomingVisits()
    yield put(actions.fulfillMyUpcomingVisits(upcomingVisits))
  })
  // ** Get My Likes Dispatch**
  yield takeLatest(actionTypes.Likes, function* userRequested() {
    const {data: likes} = yield myLikes()
    yield put(actions.fulfillMyLikes(likes))
  })
  // ** Get My Offers Dispatch**
  yield takeLatest(actionTypes.OfferList, function* userRequested() {
    const {data: offerList} = yield myOffers()
    yield put(actions.fulfillMyOffers(offerList))
  })
  // ** Get My Offers Details Dispatch**
  yield takeLatest(actionTypes.OfferDetails, function* userRequested(id: any) {
    const {data: offerDetails} = yield myOffersDetails(id.payload.id)
    yield put(actions.fulfillMyOffersDetails(offerDetails))
  })
  // ** Get My AllContracts Dispatch**
  yield takeLatest(actionTypes.AllContract, function* userRequested() {
    const {data: allContract} = yield myAllContracts()
    yield put(actions.fulfillMyAllContracts(allContract))
  })
}
