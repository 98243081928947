import React, { FC, useEffect, useRef, useState } from 'react'
import { Step1 } from './components/steps/Step1'
import { Step2 } from './components/steps/Step2'
import { Step3 } from './components/steps/Step3'
import { Step4 } from './components/steps/Step4'
import { Step5 } from './components/steps/Step5'
import { StepperComponent } from '../../setup/ts/components'
import CurrentStep from '../../assets/images/current-step.png'
import completedStep from '../../assets/images/completed-step.png'
import { useSelector } from 'react-redux'
import { RootState } from '../../constants'
import { getStepsStatus } from '../../constants/axios/apis'
import { RequestSuccessfulPopup } from '../SuccessfulPopup/RequestSuccessfulPopup'
import { RequestFailedPopup } from '../SuccessfulPopup/RequestFailedPopup'
import { useTranslation } from 'react-i18next'
import { CustomLoader } from '../../constants/generics/customActios'
import { useHistory } from 'react-router-dom'

type Props = {
  step?: any
}

const MoveIn: FC<Props> = ({ step }) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const contract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const [message, setMessage] = useState<any>()
  const [step1Status, setStep1Status] = useState<Boolean>(false)
  const [step2Status, setStep2Status] = useState<Boolean>(false)
  const [step3Status, setStep3Status] = useState<Boolean>(false)
  const [step4Status, setStep4Status] = useState<Boolean>(false)
  const [step5Status, setStep5Status] = useState<Boolean>(false)
  const { t } = useTranslation();
  const history = useHistory();

  if(contract?.property_search_type == 2){
    history.push("/")
  }

  const getMoveInStepsStatus = () => {
    getStepsStatus(contract?.propertyID, contract?.id, 0).then((response) => {
      response.data?.map((value: any) => {
        if (value.id == 5) {
          setStep1Status(value.status)
        }

        if (value.id == 6) {
          setStep2Status(value.status)
        }

        if (value.id == 7) {
          setStep3Status(value.status)
        }

        if (value.id == 8) {
          setStep4Status(value.status)
        } else {
          setStep4Status(true)
        }

        if (value.id == 9) {
          setStep5Status(value.status)
        }
      })
    })
  }

  useEffect(() => {
    if (contract) {
      getMoveInStepsStatus()
    }
  }, [contract])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    if (step) {
      return stepper.current?.goto(step)
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  if (contract === undefined) { return (<CustomLoader />) }
  return (
    <div className='com-card-section movein-page'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'> {t("Move-in Process")} </h4>
        </div>
      </div>
      <div className='col-12'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav'>
            <div
              className='stepper-item current'
              style={{ cursor: 'pointer' }}
              data-kt-stepper-element='nav'
              onClick={() => stepper.current?.goto(1)}
            >
              <h3 className='stepper-title'>
                {t("Move-in Inspection")}
                {step1Status ? (
                  <span className='completed-step ps-3 d-inline-block'>
                    <img src={completedStep} alt='complete-step' />
                  </span>
                ) : (
                  <span className='current-step ps-3'>
                    <img src={CurrentStep} alt='current-step' />
                  </span>
                )}
              </h3>
            </div>

            <div
              className='stepper-item'
              style={{ cursor: 'pointer' }}
              data-kt-stepper-element='nav'
              onClick={() => stepper.current?.goto(2)}
            >
              <h3 className='stepper-title'>
                {t("Upload ADDC Welcome Letter")}
                {step2Status ? (
                  <span className='completed-step ps-3 d-inline-block'>
                    <img src={completedStep} alt='complete-step' />
                  </span>
                ) : (
                  <span className='current-step ps-3'>
                    <img src={CurrentStep} alt='current-step' />
                  </span>
                )}
              </h3>
            </div>

            <div
              className='stepper-item'
              style={{ cursor: 'pointer' }}
              data-kt-stepper-element='nav'
              onClick={() => stepper.current?.goto(3)}
            >
              <h3 className='stepper-title'>
                {t("Access Cards")}
                {step3Status ? (
                  <span className='completed-step ps-3 d-inline-block'>
                    <img src={completedStep} alt='complete-step' />
                  </span>
                ) : (
                  <span className='current-step ps-3'>
                    <img src={CurrentStep} alt='current-step' />
                  </span>
                )}
              </h3>
            </div>
            <div
              className='stepper-item'
              style={{ cursor: contract?.isHealthClubAvailable === "Y" ? 'pointer' : 'not-allowed', display: contract?.isHealthClubAvailable === "Y" ? 'block' : 'none' }}
              data-kt-stepper-element='nav'
              onClick={() => contract?.isHealthClubAvailable === "Y" ? stepper.current?.goto(4) : null}
            >
              <h3 className='stepper-title'>
                {t("Health club registration (Optional)")}
                {step4Status ? (
                  <span className='completed-step ps-3 d-inline-block'>
                    <img src={completedStep} alt='complete-step' />
                  </span>
                ) : (
                  <span className='current-step ps-3'>
                    <img src={CurrentStep} alt='current-step' />
                  </span>
                )}
              </h3>
            </div>

            <div
              className='stepper-item'
              style={{ cursor: contract?.isParkingAvailable === "Y" ? 'pointer' : 'not-allowed', display: contract?.isParkingAvailable === "Y" ? 'block' : 'none' }}
              data-kt-stepper-element='nav'
              onClick={() => contract?.isParkingAvailable === "Y" ? stepper.current?.goto(5) : null}
            >
              <h3 className='stepper-title'>
                {t("Vehicle Registration (Optional)")}
                {step5Status ? (
                  <span className='completed-step ps-3 d-inline-block'>
                    <img src={completedStep} alt='complete-step' />
                  </span>
                ) : (
                  <span className='current-step ps-3'>
                    <img src={CurrentStep} alt='current-step' />
                  </span>
                )}
              </h3>
            </div>
          </div>

          {/* <Formik initialValues={initValues} onSubmit={submitStep}>
            {() => ( */}
          <div className='movein-form' id='kt_create_account_form'>
            <div className='current' data-kt-stepper-element='content'>
              <Step1 getStepStatus={getMoveInStepsStatus} stepStatus={step1Status} contract={contract} setMessage={setMessage} />
            </div>

            <div data-kt-stepper-element='content'>
              <Step2 getStepStatus={getMoveInStepsStatus} stepStatus={step2Status} contract={contract} setMessage={setMessage} />
            </div>

            <div data-kt-stepper-element='content' className='flex-column'>
              <Step3 getStepStatus={getMoveInStepsStatus} stepStatus={step3Status} contract={contract} setMessage={setMessage} />
            </div>

            <div data-kt-stepper-element='content' style={{ cursor: 'pointer', display: contract?.isHealthClubAvailable === "Y" ? 'block' : 'none' }}>
              <Step4 getStepStatus={getMoveInStepsStatus} stepStatus={step4Status} contract={contract} setMessage={setMessage} />
            </div>

            <div data-kt-stepper-element='content' >
              <Step5 getStepStatus={getMoveInStepsStatus} stepStatus={step5Status} contract={contract} setMessage={setMessage} />
            </div>
            <RequestSuccessfulPopup message={message} />
            <RequestFailedPopup message={message} />

            <div className=' '>
              <div className='mr-2 col-2'>
                {/* <button
                  onClick={prevStep}
                  type='button'
                  className='btn btn-outline-secondary btn-lg px-10 py-3 w-100'
                  data-kt-stepper-action='previous'
                >
                  Back
                </button> */}
                {/*&nbsp;*/}
              </div>
              <div className='col-8'>&nbsp;</div>

              <div className='col-2'>
                {/*<button type='submit' className='btn btn-primary fw-bold px-10 py-3 w-100'>
                        <span className='indicator-label'>
                          {!isSubmitButton && 'Next'}
                          {isSubmitButton && 'Submit'}
                        </span>
                  </button>*/}
              </div>
            </div>
          </div>
          {/* )} */}
          {/* </Formik> */}
        </div>
      </div>
    </div>
  )
}

export { MoveIn }
