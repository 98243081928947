import { Link } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import pdficon from '../../../../assets/images/pdf-file.png'
import previewImg from '../../../../assets/images/home.png'
import eye from '../../../../assets/images/preview-eye.png'
import cameraIcon from '../../../../assets/images/camera-icon.png'
import moment from 'moment'
import { ViewPopup } from '../../../pt-documents/components/ViewPopup/ViewPopup'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

type Props = {
  allDocuments?: any
}

const CommunityDocx: React.FC<Props> = ({ allDocuments }: any) => {
  const [viewImage, setViewImage] = useState<any>()
  const { t } = useTranslation();

  const handleView = (data: any) => {
    setViewImage(data)
  }

  const formatTitle = (string: string) => {
    if (string != 'passport') {
      return string.replaceAll('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())
    } else {
      return string.replaceAll('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ' Front'
    }
  }

  return (
    <div className='row mt-4'>
      {allDocuments?.map((document: any) => (
        <div className='col-md-6 col-12 mb-6' key={document.id}>
          <div className='com-docx-sec'>
            <div className='row align-items-center'>
              <div className='col-lg-7'>
                <div className='row'>
                  <div className='col-12'>
                    <div className="position-relative image-preview-box">
                      <img src={document?.filename ? document?.filename : previewImg} alt="images" className="img-fluid rounded img-preview" />
                      <div className="image-preview">
                        <button
                          className='btn border-0'
                          data-bs-target='#view-popUp'
                          data-bs-toggle='modal'
                          data-bs-dismiss='modal'
                          onClick={() => {
                            handleView(document)
                          }}
                        >
                          <img src={eye} alt="images" width="29" height="15" className="img-fluid" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-5'>
                <div className='row'>
                  <div className='col-12 mb-3'>
                    <p className='text-normal font-12 mb-0'>{t("Created On")}:</p>
                    <p className='mb-0 text-black font-12 fw-normal'>
                      {moment(document?.created).format('DD-MM-YYYY')}
                    </p>
                  </div>
                  <div className='col-12 mb-3'>
                    <p className='text-normal font-12 mb-0'>{t("Expiries On")}:</p>
                    <p className='mb-0 text-black font-12 fw-normal'>
                      {moment(document?.expiry_date).format('DD-MM-YYYY')}
                    </p>
                  </div>
                  <div className='col-12 mb-2'>
                    <p className='text-normal font-12 mb-0'>{t("File Name")}:</p>
                    <p className='mb-0 text-black font-12 fw-normal'>
                      <span className='docx-title'>{formatTitle(document.document_type)}</span>
                    </p>
                  </div>
                  <div className='col-12 col-md-12'>
                    <Link
                      to={{ pathname: document?.filename }}
                      target='_blank'
                      className='btn btn-primary text-decoration-none py-2 px-6 w-100'
                    >
                      {t("Download")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <ViewPopup viewImage={viewImage} />
          </div>
        </div>
      ))}
      {/* <div className="col-md-6 col-12 mb-6">
                <div className='com-docx-sec'>
                    <div className='row align-items-center'>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className='col-12 mb-4'>
                                    <span><img src={pdficon} alt="docx-icon" className='docx-icon me-3' /></span>
                                    <span className="docx-title">Rent Receipt</span>
                                </div>
                                <div className='col-12 col-sm-6 col-md-6 col-xl-6 mb-3 mb-xl-0'>
                                    <p className='text-normal font-12 mb-1'>Create On:</p>
                                    <p className='mb-0 text-black font-12 fw-normal'>10/12/2022</p>
                                </div>
                                <div className='col-12 col-sm-6 col-md-6 col-xl-6 mb-3 mb-xl-0'>
                                    <p className='text-normal font-12 mb-1'>Created On:</p>
                                    <p className='mb-0 text-black font-12 fw-normal'>Property Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className='col-12 col-md-12 mb-3'>
                                    <Link to={'/'} className="btn btn-outline-primary text-decoration-none py-2 px-6 w-100">Preview</Link>
                                </div>
                                <div className='col-12 col-md-12'>
                                    <Link to={'/'} className="btn btn-primary text-decoration-none py-2 px-6 w-100">Download</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-12 mb-6">
                <div className='com-docx-sec'>
                    <div className='row align-items-center'>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className='col-12 mb-4'>
                                    <span><img src={pdficon} alt="docx-icon" className='docx-icon me-3' /></span>
                                    <span className="docx-title">Rent Receipt</span>
                                </div>
                                <div className='col-12 col-sm-6 col-md-6 col-xl-6 mb-3 mb-xl-0'>
                                    <p className='text-normal font-12 mb-1'>Create On:</p>
                                    <p className='mb-0 text-black font-12 fw-normal'>10/12/2022</p>
                                </div>
                                <div className='col-12 col-sm-6 col-md-6 col-xl-6 mb-3 mb-xl-0'>
                                    <p className='text-normal font-12 mb-1'>Created On:</p>
                                    <p className='mb-0 text-black font-12 fw-normal'>Property Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className='col-12 col-md-12 mb-3'>
                                    <Link to={'/'} className="btn btn-outline-primary text-decoration-none py-2 px-6 w-100">Preview</Link>
                                </div>
                                <div className='col-12 col-md-12'>
                                    <Link to={'/'} className="btn btn-primary text-decoration-none py-2 px-6 w-100">Download</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-12 mb-6">
                <div className='com-docx-sec'>
                    <div className='row align-items-center'>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className='col-12 mb-4'>
                                    <span><img src={pdficon} alt="docx-icon" className='docx-icon me-3' /></span>
                                    <span className="docx-title">Rent Receipt</span>
                                </div>
                                <div className='col-12 col-sm-6 col-md-6 col-xl-6 mb-3 mb-xl-0'>
                                    <p className='text-normal font-12 mb-1'>Create On:</p>
                                    <p className='mb-0 text-black font-12 fw-normal'>10/12/2022</p>
                                </div>
                                <div className='col-12 col-sm-6 col-md-6 col-xl-6 mb-3 mb-xl-0'>
                                    <p className='text-normal font-12 mb-1'>Created On:</p>
                                    <p className='mb-0 text-black font-12 fw-normal'>Property Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className='col-12 col-md-12 mb-3'>
                                    <Link to={'/'} className="btn btn-outline-primary text-decoration-none py-2 px-6 w-100">Preview</Link>
                                </div>
                                <div className='col-12 col-md-12'>
                                    <Link to={'/'} className="btn btn-primary text-decoration-none py-2 px-6 w-100">Download</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  )
}
export { CommunityDocx }
