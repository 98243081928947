import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { bankTransfer } from '../../../../constants/axios/apis'
import { useHistory } from 'react-router-dom'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { Spinner } from 'react-bootstrap-v5'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

export function BankTransfer({ offerDetails, details, property_id, vatPercent }: any) {
	const dispatch = useDispatch()
	const ref = useRef<any>(0)
	const inputRef = useRef<any>(null)
	const history = useHistory()
	const [message, setMessage] = useState<any>()
	const [loading, setLoading] = useState(false)
	const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
	const { t } = useTranslation();

	if (details === undefined) {
		history.push('/payments/all')
	}

	const amountVal = parseFloat(details?.amount)
	const extra = details?.extra_charges_amount ? parseFloat(details.extra_charges_amount) : 0
	const vatVal = ((amountVal + extra) * vatPercent.value) / 100
	const totalVal = amountVal + extra + vatVal

	// const handleUpload = () => {
	// 	inputRef.current?.click()
	// }
	// const handleDisplayFileDetails = (e: any) => {
	// 	inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
	// 	formik.setFieldValue('receipt_img', e.currentTarget.files[0])
	// }

	const initialValues = {
		offer_id: details?.offer_id,
		payment_for: details?.payment_for,
		payment_category: details?.payment_category ? details?.payment_category : '',
		// amount: details?.extra_charges_amount ? details?.amount + details?.extra_charges_amount : details?.amount,
		amount: totalVal,
		beneficiary_name: offerDetails?.payment_plan_details?.bank_details?.beneficiaryName,
		beneficiary_account: offerDetails?.payment_plan_details?.bank_details?.beneficiaryAccount,
		iban_no: offerDetails?.payment_plan_details?.bank_details?.ibanNo,
		beneficiary_bank: offerDetails?.payment_plan_details?.bank_details?.beneficiaryBank,
		swift_code: offerDetails?.payment_plan_details?.bank_details?.swiftCode,
		// branch_address: offerDetails?.payment_plan_details?.bank_details?.branchAddress,
		branch_address: '',
		payment_type: '',
		upcoming_payment_id: '',
		receipt_img: '',
	}

	const bankTransferRequestSchema = Yup.object().shape({
		beneficiary_name: Yup.string()
			.min(3, t("Minimum 3 characters"))
			// .max(30, 'Maximum 100 characters')
			.required(t("Beneficiary Name is required"))
			.test('filled', t("Beneficiary Name cannot contain only blankspaces"), (value) => {
				if (value) {
					return (
						value.trim().length !== 0
					)
				}
				return true
			})
			.matches(/^[^\s].*/, t("Beneficiary Name cannot start with a blankspace"))
		// .matches(/^[A-Za-z ]+$/, 'Only alphabets allowed')
		,
		beneficiary_account: Yup.string()
			// .max(13, 'Maximum 13 characters')
			.required(t("Beneficiary Account is required"))
		// .matches(/^[0-9]*$/, 'Only numbers allowed'),
		// .matches(/^[a-zA-Z0-9]*$/, 'Only alphabets & numbers allowed')
		,
		iban_no: Yup.string()
			.max(23, t("Maximum 23 characters"))
			.required(t("IBAN Number is required"))
			.matches(/^[a-zA-Z0-9]*$/, t("Only alphabets & numbers allowed")),
		beneficiary_bank: Yup.string()
			.min(3, t("Minimum 3 characters"))
			.max(30, t("Maximum 30 characters"))
			.required(t("Beneficiary Bank is required"))
			.test('filled', t("Bank Name cannot contain only blankspaces"), (value) => {
				if (value) {
					return (
						value.trim().length !== 0
					)
				}
				return true
			})
			.matches(/^[^\s].*/, t("Bank Name cannot start with a blankspace"))
			.matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
		swift_code: Yup.string()
			.min(8, t("Minimum 8 characters"))
			.max(11, t("Maximum 11 characters"))
			.required(t("Swift Code is required"))
			.matches(/^[a-zA-Z0-9]*$/, t("Only alphabets & numbers allowed")),

		receipt_img: Yup.mixed()
			.required(t("Receipt copy is required"))
			.test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf"), (value) => {
				if (!value) return true
				return (
					value &&
					(value.type === 'image/jpeg' ||
						value.type === 'image/jpg' ||
						value.type === 'image/png' ||
						value.type === 'application/pdf')
				)
			})
			.test('fileSize', t('The file is too large! Max allowed size is 5MB.'), (value) => {
				if (!value) return true
				return value?.size <= 5242880
			}),
		// branch_address: Yup.string().required('Beneficiary Address is required'),
	})

	const formik = useFormik({
		initialValues,
		isInitialValid: false,
		validationSchema: bankTransferRequestSchema,
		onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
			setLoading(true)
			var FormData = require('form-data')
			var bankTransferDetails = new FormData()
			bankTransferDetails.append('offer_id', values.offer_id)
			bankTransferDetails.append('payment_for', values.payment_for)
			if (values?.amount) {
				bankTransferDetails.append('amount', values.amount)
			}
			bankTransferDetails.append('property_id', property_id)
			bankTransferDetails.append('beneficiary_name', values.beneficiary_name)
			bankTransferDetails.append('beneficiary_account', values.beneficiary_account)
			bankTransferDetails.append('iban_no', values.iban_no)
			bankTransferDetails.append('beneficiary_bank', values.beneficiary_bank)
			bankTransferDetails.append('swift_code', values.swift_code)
			bankTransferDetails.append('branch_address', values.branch_address)
			bankTransferDetails.append('receipt_img', values.receipt_img)
			bankTransferDetails.append('payment_category', details.payment_category)
			bankTransferDetails.append('payment_type', details.payment_type)
			bankTransferDetails.append('upcoming_payment_id', details.upcoming_payment_id)
			bankTransferDetails.append('upcoming_payment_plan_id', details.upcoming_payment_plan_id)
			bankTransferDetails.append('work_order_id', details?.work_order_id ? details?.work_order_id : '')
			setTimeout(() => {
				bankTransfer(bankTransferDetails)
					.then((response: any) => {
						setLoading(false)
						if (response.data.code === 200 && response.data.errorCode != 1) {
							setMessage({
								error: 0,
								message: t("Payment Successful"),
							})
							resetForm()
							ref.current.click()
						} else {
							setMessage({
								error: 1,
								message: response.data.errorDescription,
							})
							ref.current.click()
						}
						setTimeout(() => {
							ref.current.click()
							history.push('/payments/all')
						}, 2000)
					})
					.catch((e) => {
						setSubmitting(false)
						setStatus(t("Invalid Data Provided"))
					})
			}, 1000)
		},
	})

	useLayoutEffect(() => {
		if (loading) {
			document.body.style.overflow = 'hidden'
			document.body.style.height = '100%'
			document.body.style.pointerEvents = 'none'
		}
		if (!loading) {
			document.body.style.overflow = 'auto'
			document.body.style.height = 'auto'
			document.body.style.pointerEvents = 'all'
		}
	}, [loading])

	useEffect(() => {
		if (message?.message?.length > 0) {
			dispatch(PTOffer.actions.modalmessage(message))
		}
	}, [message])

	const [uploading, setUploading] = useState(false)
	const handleUpload = () => {
		inputRef.current?.click()
	}

	const handleDisplayFileDetails = (e: any) => {
		if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
			inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
			formik.setFieldTouched('receipt_img')
			formik.setFieldValue('receipt_img', e.currentTarget.files[0])
		} else {
			setUploading(true)
			formik.setFieldError('receipt_img', undefined)
			try {
				validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
					if (compressResponse?.success) {
						inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
						formik.setFieldTouched('receipt_img', true, false)
						formik.setFieldValue('receipt_img', compressResponse.file, false)
					} else {
						setUploadedFileName(null)
						formik.setFieldTouched('receipt_img', true, false)
						formik.setFieldValue('receipt_img', '', false)
						formik.setFieldError('receipt_img', t(compressResponse?.message || 'File compression error!'))
					}
					setUploading(false)
				})
			} catch (error) {
				setUploadedFileName(null)
				formik.setFieldTouched('receipt_img')
				formik.setFieldValue('receipt_img', '', false)
				formik.setFieldError('receipt_img', t('File upload error!'))
				setUploading(false)
			}
		}
	}

	return (
		<>
			{' '}
			<div className='com-card-section'>
				<div className='row'>
					<div className='col-12'>
						<div className='card mb-3'>
							<div className='card-body px-md-8 px-lg-15 px-5 py-15'>
								<form
									className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
									noValidate
									id='kt_login_signup_form'
									onSubmit={formik.handleSubmit}
								>
									<div className='row'>
										<div className='col-md-6 col-12 mb-md-0 mb-4'>
											<div className='row form-add-design'>
												<div className='col-md-12 col-12'>
													<div className='row'>
														<div className='col-md-12'>
															<div className='mb-6'>
																<label className='form-label fw-bolder'>{t("Beneficiary Name")}</label>
																<input
																	type='text'
																	className='form-control fw-bolder form-control-solid'
																	autoComplete='off'
																	{...formik.getFieldProps('beneficiary_name')}
																/>
																{formik.touched.beneficiary_name && formik.errors.beneficiary_name && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>
																			<span role='alert' className='text-danger'>
																				{formik.errors.beneficiary_name}
																			</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className='col-md-12'>
															<div className='mb-6'>
																<label className='form-label fw-bolder'>
																	{t("Beneficiary Account Number")}
																</label>
																<input
																	type='text'
																	className='form-control fw-bolder form-control-solid'
																	autoComplete='off'
																	{...formik.getFieldProps('beneficiary_account')}
																/>
																{formik.touched.beneficiary_account &&
																	formik.errors.beneficiary_account && (
																		<div className='fv-plugins-message-container'>
																			<div className='fv-help-block'>
																				<span role='alert' className='text-danger'>
																					{formik.errors.beneficiary_account}
																				</span>
																			</div>
																		</div>
																	)}
															</div>
														</div>
														<div className='col-md-12'>
															<div className='mb-6'>
																<label className='form-label fw-bolder'>{t("IBAN Number")}</label>
																<input
																	type='text'
																	className='form-control fw-bolder form-control-solid'
																	autoComplete='off'
																	{...formik.getFieldProps('iban_no')}
																/>
																{formik.touched.iban_no && formik.errors.iban_no && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>
																			<span role='alert' className='text-danger'>
																				{formik.errors.iban_no}
																			</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className='col-md-12'>
															<div className='mb-6'>
																<label className='form-label fw-bolder'>
																	{t("Beneficiary Bank Name")}
																</label>
																<input
																	type='text'
																	className='form-control fw-bolder form-control-solid'
																	autoComplete='off'
																	{...formik.getFieldProps('beneficiary_bank')}
																/>
																{formik.touched.beneficiary_bank && formik.errors.beneficiary_bank && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>
																			<span role='alert' className='text-danger'>
																				{formik.errors.beneficiary_bank}
																			</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className='col-md-12'>
															<div className='mb-6'>
																<label className='form-label fw-bolder'>{t("Swift Code")}</label>
																<input
																	type='text'
																	className='form-control fw-bolder form-control-solid'
																	autoComplete='off'
																	{...formik.getFieldProps('swift_code')}
																/>
																{formik.touched.swift_code && formik.errors.swift_code && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>
																			<span role='alert' className='text-danger'>
																				{formik.errors.swift_code}
																			</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className='col-md-12'>
															<div className='mb-6'>
																<label className='form-label fw-bolder'>{t("Bank Receipt")}</label>
																<input
																	ref={inputRef}
																	onChange={(e) => handleDisplayFileDetails(e)}
																	className='d-none'
																	type='file'
																// onBlur={formik.handleBlur}
																/>
																<button
																	type='button'
																	onClick={handleUpload}
																	disabled={uploading}
																	className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
																		}`}
																>
																	{/* <span>
																		{uploadedFileName ? (
																			uploadedFileName
																		) : (
																			<span>
																				{t("Upload")}{' '}
																				<i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
																			</span>
																		)}{' '}
																	</span> */}
																	{uploading ?
																		<span className='indicator-progress d-block'>
																			{t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
																		</span>
																		: uploadedFileName ? (
																			uploadedFileName
																		) : (
																			<span>
																				{t("Upload")}{' '}
																				<i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
																			</span>
																		)}{' '}
																</button>
																{/* <input
																	type='text'
																	className='form-control fw-bolder form-control-solid'
																	autoComplete='off'
																	{...formik.getFieldProps('beneficiary_name')}
																/> */}
																{formik.touched.receipt_img && formik.errors.receipt_img && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>
																			<span role='alert' className='text-danger'>
																				{formik.errors.receipt_img}
																			</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-md-6 col-12'>
											<div className='row justify-content-center h-100'>
												<div className='col-lg-12 col-12'>
													<div className='card-pay-bg h-100 rounded-10 p-5 p-md-15'>
														<div className='row'>
															<div className='col-12'>
																<h5 className='text-black font-18 fw-bolder mb-8'>{t("Summary")}</h5>
															</div>
															<div className='col-12'>
																<div className='row'>
																	<div className='col-md-8 col-8'>
																		<span className='text-black font-14 d-block mb-4 fw-bold'>
																			{details?.type} {t("Amount")}
																		</span>
																	</div>
																	<div className='col-md-4 col-4'>
																		<span className='text-black font-14 d-block mb-4 fw-boldest'>
																			{t("AED")} {details?.amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
																		</span>
																	</div>
																	{details?.extra_charges_title ? (
																		<>
																			<div className='col-md-8 col-8'>
																				<span className='text-black font-14 d-block mb-4 fw-bold'>
																					{details?.extra_charges_title}
																				</span>
																			</div>
																			<div className='col-md-4 col-4'>
																				<span className='text-black font-14 d-block mb-4 fw-boldest'>
																					{t("AED")} {details?.extra_charges_amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
																				</span>
																			</div>
																		</>
																	) : (
																		''
																	)}
																	<div className='col-md-8 col-8'>
																		<span className='text-black font-14 d-block mb-4 fw-bold'>
																			{t("VAT")} ({vatPercent.value}%)
																		</span>
																	</div>
																	<div className='col-md-4 col-4'>
																		<span className='text-black font-14 d-block mb-4 fw-boldest'>
																			{t("AED")} {vatVal?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
																		</span>
																	</div>
																</div>
																<hr className='my-1' />
															</div>
															<div className='col-12 my-4'>
																<div className='row'>
																	<div className='col-md-8 col-8'>
																		<span className='text-black font-14 d-block fw-bold'>
																			{t("Total")}
																		</span>
																		<span className='d-block font-10 mb-2 fw-bold'>
																			{t("(Inc. Taxes)")}
																		</span>
																	</div>
																	<div className='col-md-4 col-4 my-auto'>
																		<span className='text-black font-14 d-block mb-2 fw-boldest'>
																			{/* {t("AED")} {details?.total?.toLocaleString(undefined, { maximumFractionDigits: 2 })} */}
																			{t("AED")} {totalVal?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
																		</span>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<button
																	// data-bs-target='#pay-suc-popup'
																	data-bs-toggle={!formik.isValid ? '' : 'modal'}
																	type='submit'
																	className='btn btn-primary w-100 fw-bold px-10 py-3'
																	disabled={uploading || vatPercent.loading}
																>
																	{loading && (
																		<Spinner
																			as='span'
																			animation='grow'
																			size='sm'
																			role='status'
																			aria-hidden='true'
																		/>
																	)}
																	{loading || vatPercent.loading ? t("Loading...") : t("Confirm Payment")}
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
				{/*  */}
			</p>
		</>
	)
}
