import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from 'antd';
import { RootState } from '../../../../constants'
import * as dashboard from '../../../../constants/redux/Sagas/Tenant/TenantDashboardSaga'
import { useHistory } from 'react-router-dom'

export function MenuInner() {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const contractList: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.allContract)
  const contractLoading: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.contractLoading)
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const currentContractId: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContractId)
  const { Option } = Select;

  const [selectedContract, setSelectedContract] = useState<any>(currentContractId)
  // console.log(contractList)
  // console.log(selectedContract)
  // console.log(currentContract,'currentContract')

  useEffect(() => {
    dispatch(dashboard.actions.allContract())
  }, [])

  // useEffect(() => {
  //   if (!currentContract) {
  //     setSelectedContract(contractList?.data[0])
  //     // setSelectedContract(
  //     //   contractList?.data?.find((contract: any) => contract.id == currentContractId)
  //     // )
  //     dispatch(dashboard.actions.selectedContractId(contractList?.data[0].id))
  //   }
  //   // else {

  //   //   setSelectedContract(contractList?.data?.find((contract: any) => contract.id == currentContractId))
  //   // }
  // }, [contractList, currentContract])

  useEffect(() => {
    if (!currentContract) {
      if (currentContractId) {
        const newContract = contractList?.data?.find((contract: any) => contract.id == currentContractId)
        if (newContract) {
          setSelectedContract(contractList?.data?.find((contract: any) => contract.id == currentContractId))
        } else {
          const newContract = contractList?.data[0]
          setSelectedContract(newContract)
          dispatch(dashboard.actions.selectedContractId(newContract?.id))
        }
      }
      else {
        setSelectedContract(contractList?.data[0])
        dispatch(dashboard.actions.selectedContractId(contractList?.data[0]?.id))
      }

      // setSelectedContract(
      //   contractList?.data?.find((contract: any) => contract.id == currentContractId)
      // )
    }
    // else {

    //   setSelectedContract(contractList?.data?.find((contract: any) => contract.id == currentContractId))
    // }
  }, [contractList, currentContract])

  useEffect(() => {
    dispatch(dashboard.actions.selectedContract(selectedContract))
  }, [selectedContract, dispatch])

  useEffect(() => {
    if (contractList && contractList?.code === 200 && contractList?.errorCode === 0) {
      dispatch(dashboard.actions.contractLoading(false))
    }
  }, [contractList])

  // useEffect(() => {
  //   if (selectedContract === undefined) {
  //     setSelectedContract(contractList?.data[0]?.id)

  //   }
  // }, [])
  if (contractList === undefined || contractList?.errorCode === 1) {
    return null
    // setTimeout(() => {
    //   history.push('/pt-activity/my-offer')
    // }, 2000)
  }
  const selectedContractsID = (id: any) => {
    dispatch(dashboard.actions.selectedContractId(id))
    setSelectedContract(
      contractList?.data?.find((contract: any) => contract.id == id)
    )
  }
  const contracts = contractList?.data?.map((contract: any) => {
    // if (contract?.signature != null)
    // if (contract?.contractStatus === 'Active')
    if (contract?.contractStatus)
      return (
        // <option className='tanent-select p-2' key={contract.id} value={contract.id}>
        //   {contract.buildingName + ' - ' + contract.UnitNumber}
        // </option>
        <Option key={contract.id} value={contract.id}>
          {contract.buildingName + ' - ' + contract.UnitNumber}
          </Option>
        )
  })


  return (
    <>
      <div className='row'>
        <div className='col-lg-12 my-auto'>
          {/* <select
            className='form-select form-select-lg form-select-solid tenant-search'
            data-control='select2'
            data-placeholder='Select Community'
            // onChange={(e) =>
            //   setSelectedContract(
            //     contractList?.data?.find((contract: any) => contract.id == e.target.value)
            //   )
            // }
            onChange={(e) => selectedContractsID(e.target.value)}
            // defaultValue={currentContract?.id}
            defaultValue={currentContractId}
          >
            {contractList ? contracts : ''}
          </select> */}
          <Select
            placeholder='Select a Unit'
            onChange={(e) => selectedContractsID(e.target.value)}
            defaultValue={currentContractId}
            className='topbar-tenant-search'
            >
              {contractList ? contracts : ''}
            </Select>
        </div>
      </div>
    </>
  )
}
