import React, { useState, useEffect, useRef } from 'react'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  createMaintenanceRequest,
  getAvailableTimings,
  getMaintenanceIssues,
  getMaintenanceRequestsCategories,
  getPreciseLocations,
} from '../../../../constants/axios/apis'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { Time } from './Time'
import DatePicker from 'react-date-picker'
import Call from '../../../../assets/images/emer-call.png'
import moment from 'moment'
import { RequestFailedPopup } from '../../../SuccessfulPopup/RequestFailedPopup'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'
import uploadImg from '../../../../assets/images/file-upload.svg'

const today = new Date()
today.setHours(0, 0, 0, 0)

export function NewRequest() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const successRef = useRef<any>()
  const failRef = useRef<any>()
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [categories, setCategories] = useState<any>()
  const [subCategories, setSubCategories] = useState<any>()
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>()
  const [precise_locations, setPreciseLocations] = useState<any>()
  const [timeSlots, setTimeSlots] = useState<any>([])
  const [message, setMessage] = useState<any>('')
  const [resolvedIssues, setResolvedIssues] = useState<any>([])
  const [recurringIssue, setRecurringIssue] = useState<any>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const location: any = useLocation()
  const quickLinkCategory = location?.state?.quickLinkCategory || undefined

  const initialValues = {
    category_id: quickLinkCategory ? quickLinkCategory.category_id : '',
    category_type_id: quickLinkCategory ? quickLinkCategory.id : '',
    issue_type: '',
    property_id: currentContract?.propertyID,
    date: today,
    time: '',
    description: '',
    precise_location: '',
    photo: '',
    is_recurring: false as any,
  }

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldTouched('photo')
  //   formik.setFieldValue('photo', e.currentTarget.files[0])
  // }

  useEffect(() => {
    getMaintenanceRequestsCategories().then(({ data: categoriesData }) => {
      setCategories(categoriesData)
    })
  }, [])

  useEffect(() => {
    getMaintenanceIssues('resolved', currentContract?.propertyID).then((response) => {
      if (response.data) {
        response.data
          .sort((a: any, b: any) => {
            return new Date(a.created).getTime() - new Date(b.created).getTime()
          })
          .reverse()
        setResolvedIssues(response.data)
      } else {
        setResolvedIssues([])
      }
    })
  }, [currentContract])

  useEffect(() => {
    if (currentContract) {
      getPreciseLocations(currentContract.propertyID).then(({ data: preciseLocationList }) => {
        setPreciseLocations(preciseLocationList['preciseLocationList'])
      })
    }
  }, [currentContract])

  useEffect(() => {
    if (quickLinkCategory) {
      setSelectedSubCategory(subCategories?.find((item: any) => item.id == quickLinkCategory.id))
    }
  }, [subCategories])

  useEffect(() => {
    setSubCategories(
      categories
        ?.map((cat: any) => {
          return cat?.service_category_types
        })
        .flat()
    )
  }, [categories])

  const handleSelect = (e: any) => {
    formik.handleChange(e)
    setSelectedSubCategory(subCategories.find((item: any) => item.id == e.target.value))
    formik.setFieldValue(
      'category_id',
      subCategories.find((item: any) => item.id == e.target.value).category_id
    )
    formik.setFieldValue('issue_type', '')
    formik.setFieldTouched('issue_type', false)
  }

  const handleDate = (e: any) => {
    formik.setFieldValue('date', new Date(e))
    formik.setFieldValue('time', '')
    formik.setFieldTouched('time', false)
  }

  const newRequestSchema = Yup.object().shape({
    category_type_id: Yup.number().required(t("Please choose an issue category")),
    issue_type: Yup.number().required(t("Please choose an issue type")),
    date: Yup.date()
      .min(today, t("Selected date cannot be in the past"))
      .required(t("Please select a date")),
    time: Yup.string().required(t("Please select a time slot")),
    description: Yup.string().required(t("Please provide a description of the problem"))
      .matches(/^[^\s].*/, t('Description cannot start with a blankspace!'))
      .max(250, t("Maximum 250 characters"))
      .test('len', 'Invalid format', (value) => {
        if (value) {
          const words = value.split(' ')
          let isValid = true
          words.every(word => {
            if (word.length > 100) {
              isValid = false
              return false
            }
            return true
          });
          return isValid
        }
        return true
      }),
    precise_location: Yup.string().required(t("Please select a location")),
    // photo: Yup.mixed()
    //   .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png"), (value) => {
    //     if (value) {
    //       return (
    //         value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png'
    //       )
    //     }
    //     return true
    //   })
    //   .test('fileSize', t("The file is too large!"), (value) => {
    //     if (value) {
    //       return value?.size <= 2000000
    //     }
    //     return true
    //   }),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: newRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {

        const data = new FormData()
        data.append('property_id', values.property_id)
        data.append('category_id', values.category_id)
        data.append('category_type_id', values.category_type_id)
        data.append('issue_type', values.issue_type)
        data.append('date', moment(values.date).format('YYYY-MM-DD'))
        data.append('time', values.time)
        data.append('description', values.description)
        data.append('precise_location', values.precise_location)
        data.append('is_recurring', values.is_recurring)
        data.append('photo[]', values.photo)

        createMaintenanceRequest(data)
          .then((response: any) => {
            setLoading(false)
            if (response.errorCode === 1) {
              setMessage(response.errorDescription)
              failRef.current.click()
            } else {
              resetForm()
              successRef.current.click()
              history.push('/maintenance/openissues')
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  useEffect(() => {
    if (currentContract) {
      getAvailableTimings(
        currentContract.propertyID,
        moment(formik.values.date).format('YYYY-MM-DD'),
        0
      ).then(({ data }) => {
        setTimeSlots(data)
      })
    }
  }, [currentContract, formik.values.date])

  useEffect(() => {
    if (formik.values.category_type_id && formik.values.issue_type && formik.values.precise_location) {
      let recurringIssueFound = resolvedIssues.find((issue: any) => {
        return issue.categoryTypeId === parseInt(formik.values.category_type_id)
          && issue.issueTypeId === parseInt(formik.values.issue_type)
          && parseInt(issue.description.precise_location) === parseInt(formik.values.precise_location)
      }
      )
      if (recurringIssueFound) {
        setRecurringIssue(recurringIssueFound)
      } else {
        setRecurringIssue(null)
      }
    }
  }, [formik.values.category_type_id, formik.values.issue_type, formik.values.precise_location, resolvedIssues])

  const handleDescription = (e: any) => {
    // if (e.target.value.length === 250) {
    //   return
    // }
    formik.handleChange(e)
  }

  const handleIsRecurring = (e: any) => {
    if (e.target.checked) {
      return formik.setFieldValue('is_recurring', 1)
    }
    return formik.setFieldValue('is_recurring', 0)
  }

  const subCategoryOptions = subCategories?.map((subCategory: any) => (
    <option key={subCategory.id} value={subCategory.id} onSelect={handleSelect}>
      {subCategory.name}
    </option>
  ))

  const issueTypes = selectedSubCategory?.service_issue_types?.map((issueType: any) => (
    <option key={issueType.id} value={issueType.id}>
      {issueType.issue}
    </option>
  ))

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('photo', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('photo')
          formik.setFieldValue('photo', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('photo', true, false)
          formik.setFieldValue('photo', '', false)
          formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', '', false)
      formik.setFieldError('photo', t('File upload error!'))
      setUploading(false)
    }
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <div className='row align-items-center mb-6'>
            <div className='col-md-6 col-12'>
              <h4 className='card-title font-18 text-black mb-0'>
                <Link to={'/maintenance/openissues'} className=''>
                  <img src={LeftArrow} alt='back' className='img-fluid me-5' />
                </Link>
                {t("New Maintenance Request")}
              </h4>
            </div>
            <div className='col-md-6 col-12 text-end'>
              <button className='btn btn-theme py-2 font-14 fw-normal mt-3 mt-md-0'>
                {t("Emergency?")}
              </button>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='card mb-3'>
              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}
              <div className='card-body px-8 py-6'>
                <div className='row form-add-design'>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Issue Category")} <span className='text-danger'>*</span></label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid'
                        data-control='select2'
                        data-allow-clear='true'
                        name='category_type_id'
                        onChange={(e) => handleSelect(e)}
                        value={formik.values.category_type_id}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          {t("Please Select")}
                        </option>
                        {categories ? subCategoryOptions : ''}
                      </select>
                      {formik.touched.category_type_id && formik.errors.category_type_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.category_type_id}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Issue Type")} <span className='text-danger'>*</span></label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid'
                        data-control='select2'
                        data-allow-clear='true'
                        name='issue_type'
                        onChange={formik.handleChange}
                        value={formik.values.issue_type}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          {t("Please Select")}
                        </option>
                        {subCategories ? issueTypes : ''}
                      </select>
                      {formik.touched.issue_type && formik.errors.issue_type && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.issue_type}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10 position-relative date-picker'>
                      <label className='form-label fw-bolder'>{t("Appointment Date")} <span className='text-danger'>*</span></label>
                      <DatePicker
                        onChange={(e: any) => handleDate(e)}
                        value={formik.values.date}
                        minDate={new Date(new Date().toLocaleDateString())}
                        className={clsx(
                          'form-control fw-bolder form-control-solid',
                          {
                            'is-invalid': formik.touched.date && formik.errors.date,
                          },
                          {
                            'is-valid': formik.touched.date && !formik.errors.date,
                          }
                        )}
                        format='dd-MM-y'
                        clearIcon={null}
                        maxDate={new Date('12/31/2999')}
                      />
                      {formik.touched.date && formik.errors.date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.date}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 col-12'>
                    <Time timeSlots={timeSlots} formik={formik} />
                  </div>
                  <div className='col-md-12 col-12'>
                    <div className='mb-5 w-100 overflow-hidden'>
                      <label className='form-label fw-bolder d-block mb-3'>{t("Precise Location")} <span className='text-danger'>*</span></label>
                      <div className='overflow-scroll pb-3'>
                        <div className='btn-group locat-group maintanance_time_bt'>
                          {precise_locations?.map((location: any) => (
                            <div key={location.id}>
                              <input
                                type='radio'
                                className='btn-check'
                                name='precise_location'
                                value={location.id}
                                onChange={formik.handleChange}
                                id={`btnradio_${location.id}`}
                                autoComplete='off'
                                onBlur={formik.handleBlur}
                                checked={formik.values.precise_location == location.id}
                              />
                              <label
                                className='btn btn-outline-primary me-4'
                                htmlFor={`btnradio_${location.id}`}
                              >
                                <img src={location.icon} className='img-fluid me-4 d-block' />
                                {location.name}
                              </label>
                            </div>
                          ))}
                        </div>
                        {formik.touched.precise_location && formik.errors.precise_location && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.precise_location}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={'col-md-12 col-12 fade ' + (recurringIssue ? 'show' : '')}>
                    <div className={'w-100 overflow-hidden ' + (recurringIssue ? 'mb-5' : '')}>
                      <div className='form-check custom'>
                        <input
                          className='form-check-input'
                          {...formik.getFieldProps('is_recurring')}
                          checked={formik.values.is_recurring}
                          type='checkbox'
                          id='flexCheckDefault'
                          onChange={(e) => handleIsRecurring(e)}
                          onBlur={formik.handleBlur}
                        />
                        <label
                          className='form-check-label font-14 text-black fw-bolder'
                          htmlFor='flexCheckDefault'
                        >
                          {t("Is this a recurring issue?")}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-4 col-12'>
                    <div className='mb-10 upload-input'>
                      <label className='form-label fw-bolder d-block mb-3'>{t("Upload Image")}</label>
                      <input
                        ref={inputRef}
                        onChange={(e) => handleDisplayFileDetails(e)}
                        className='d-none'
                        type='file'
                        accept=".png,.jpg,.jpeg"
                      // onBlur={formik.handleBlur}
                      />
                      <button
                        type='button'
                        onClick={handleUpload}
                        disabled={uploading}
                        className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                          }`}
                      >
                        <span>
                          {uploading ?
                            <span className='indicator-progress d-block'>
                              {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            : uploadedFileName ? (
                              uploadedFileName
                            ) : (
                              <span className="txt">

                                <img
                                        alt="File"
                                        src={uploadImg}
                                className="position-relative"
                                loading="lazy"/>
                                {t('Upload')}{' '}
                              </span>
                            )}{' '}
                        </span>
                        {/* <span>
                          {uploadedFileName ? (
                            uploadedFileName
                          ) : (
                            <span className="txt">
                              <img
                                      alt="File"
                                      src={uploadImg}
                              className="position-relative"
                              loading="lazy"/>
                              {t("Upload")}{' '}
                            </span>
                          )}{' '}
                        </span> */}
                      </button>
                      {formik.touched.photo && formik.errors.photo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.photo}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Description")} <span className='text-danger'>*</span></label>
                      <textarea
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.description && formik.errors.description,
                          },
                          {
                            'is-valid': formik.touched.description && !formik.errors.description,
                          }
                        )}
                        {...formik.getFieldProps('description')}
                        // name='description'
                        // value={formik.values.description}
                        // onChange={(e) => handleDescription(e)}
                        data-kt-autosize='true'
                        rows={3}
                        placeholder={t("Type here...")}
                        // onBlur={formik.handleBlur}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.description}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                      <h3 className='mb-4'>Terms and Conditions</h3>
                  </div>
                  {/* <div className="col-12">
                      <div className="bg-light rounded my-3">
                          <ol type="a" className="py-3 m-0">
                              <li> Pay 210.00 AED as NOC for Utilities fees (Inclusive VAT) </li>
                          </ol>
                      </div>
                  </div> */}
                  <div className="col-12">
                      <div className="form-check">
                          <input type="checkbox" name="type" value="1" id="tnc" className="form-check-input" />
                          <label htmlFor="tnc" className="form-check-label">I hereby confirm that I have understood the terms and conditions mentioned herein and I agree to abide by the Maintenance Rules.</label>
                      </div>
                  </div>
                </div>   
                <div className='row mt-7'>
                  <div className='col-12 text-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                      onClick={() => {
                        formik.resetForm()
                        history.push('/maintenance/openissues')
                      }}
                    >
                      {t("Cancel")}
                    </button>
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading || uploading}>
                      {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {t("Please wait...")}{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p
        ref={successRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <RequestFailedPopup message={message} />
    </div>
  )
}
