import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../../../../components/Loader';
import { useSelector } from 'react-redux';
import { getUninvoicedContractsList } from '../../../../../utils/makeRequest';
import { IState } from '../../../../../utils/interface';
import { useTranslation } from 'react-i18next'

export const ContractFilter: React.FC<any> = ({ handleContractFilters, contractState }) => {
    const state: any = useSelector(state => state);
    const userToken = state?.auth?.accessToken;
    const { t } = useTranslation();
    

    let params: any = {}
    params = useLocation()
    let contractId: any = ''
    if (params?.state && params?.state?.contract) {
        contractId = params?.state?.contract
    }
    const [uninvoicedContractList, setUninvoicedContractList] = useState<IState['APIState']>({
        loading: false,
        response: [],
        error: ''
    });
    const { loading, response: uninvoicedContacts, error } = uninvoicedContractList;
    useEffect(() => {
        getUninvoicedContractsList(setUninvoicedContractList, userToken);
    }, []);
    const [selectedContract, setSelectedContract] = useState<string>(contractId)
    
// console.log(uninvoicedContractList?.response[0]?.brokerID,"uninvoicedContractListuninvoicedContractList");

    useEffect(() => {
        if(selectedContract !== '' && uninvoicedContacts){
            const selectedContractOption = uninvoicedContacts?.find((item:any)=>`${item?.id}` === `${selectedContract}`)
            handleContractFilters(selectedContractOption?.propertyID)
        }
    }, [selectedContract, uninvoicedContacts])

    return (
        <div className='row mb-3'>
            <div className='col-lg-12 text-end my-auto'>
                <select
                    className='form-select-lg form-select-solid filter-select'
                    data-placeholder={t("Filter")}
                    onChange={(e)=>{setSelectedContract(e.target.value)}}
                    value={selectedContract}
                >
                    <option value="" disabled className='bg-secondary text-muted'>{t("Select Contract")}</option>
                    {uninvoicedContacts.map((contract: any, index: number) => {
                        return (
                            <option value={contract?.id} key={index}>{contract?.propertyName}</option>
                            // <option value={JSON.stringify(contract)}>{contract?.propertyName}</option>

                        )
                    })}
                </select>
            </div>
        </div>
    );
}