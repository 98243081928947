export function NocUtilities() {
    return (
        <>
        <div className="row">
            <div className="col-md-12">
                <div className="card">   
                    <div className="card-body form-add-design">
                        <h4 className="card-title mb-7">NOC for Utilities</h4>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="mb-7">
                                    <label className="form-label"> Community <span>*</span>
                                    </label>
                                    <select name="id_community" className="form-control form-select" required >
                                        <option value="">Select Community</option>
                                        <option value="6">Al Raha Gardens</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="mb-7">
                                    <label className="form-label"> Precinct <span>*</span>
                                    </label>
                                    <select name="id_precinct" className="form-control role-select form-select" required>
                                        <option value="">Select Precinct</option>
                                        <option value="1">ARG Residential</option>
                                    </select>
                                </div>
                            </div>                 
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="mb-7">
                                    <label className="form-label"> Unit Number <span>*</span></label>
                                    <select required name="id_unit" id="srv_id_unit" className="form-control form-select">
                                        <option value="">Select Unit Number</option>
                                        <option value="7640">456</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <form className="form-register" method="post">
                            <div className="row">
                                <div className="col-8">
                                    <h4 className="card-title mb-3"> Upload Documents </h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="title_deed"> Title Deed </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="title_deed_filename[]" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="addc_filename"> ADDC Clearance (or latest ADDC Bill if rented) </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="addc_filename[]" className="form-control" required />
                                                {/* <small className="mb-0 text-danger font-10">Note: Please check the sample, other documents will not be accepted and may delay the process</small> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="owner_eid"> Owner EID/Passport front side copy </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="owner_eid[]" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="mb-7">
                                                <label className="form-label" htmlFor="owner_eid_back"> Owner EID/Passport back side copy </label>
                                                <input type="file" accept=".png,.jpeg,.jpg,.gif,.pdf" name="owner_eid_back[]" className="form-control" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h3>Terms and Conditions</h3>
                                </div>
                                <div className="col-12">
                                    <div className="bg-light rounded my-3">
                                        <ol type="a" className="py-3 m-0">
                                            <li> Pay 210.00 AED as NOC for Utilities fees (Inclusive VAT) </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-check">
                                        <input type="checkbox" name="type" value="1" id="tnc" className="form-check-input" />
                                        <label htmlFor="tnc" className="form-check-label">I hereby confirm that I have understood the terms and conditions mentioned herein and I agree to abide by the Community Rules.</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 text-end">
                                <button type="submit" name="submit" id="btnsubmit" value="submit" className="btn btn-primary">Submit &amp; Pay</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}