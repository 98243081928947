import React, { useState, useEffect } from 'react';
import { AddedSuccessfulPopup } from '../../../../../SuccessfulPopup/AddedSuccessfulPopup'
import { useTranslation } from 'react-i18next'

export function FinalCompletionCertificateModal({ setFinalCertificate, questions }: any) {
    const [answers, setAnswers] = useState<any>([])
    const [currentAnswer, setCurrentAnswer] = useState<any>()
    const [isCompleted, setIsCompleted] = useState<any>(false)


    const [activeQuestion, setActiveQuestion] = useState(1);
    const { t } = useTranslation();

    const handleNext = (id: any) => {
        if (currentAnswer) {
            setAnswers((prevAnswer: any) => [...prevAnswer, currentAnswer])
        }
        setActiveQuestion(id);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCurrentAnswer(null)

    };

    const handleSubmit = () => {
        if (currentAnswer) {
            setAnswers((prevAnswer: any) => [...prevAnswer, currentAnswer])
        }
        setCurrentAnswer(null)
        setIsCompleted(true)

        // alert('Form submitted');
    };

    useEffect(() => {
        setFinalCertificate((prevState: any) => { return { ...prevState, answers: [...prevState.answers, ...answers] } })
    }, [isCompleted])

    return (
        <div className="modal fade redeem-offer completion-modal" id="final-certificate-modal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-10">
                    <div className="modal-header border-0 rounded-10">
                        <h5 className="modal-title font-18 fw-bolder">
                            {t("Final Completion Certificate")}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-20">
                        <div className="row form-sec">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <div className="total-que-count mb-6">
                                                    <h2 className="fw-normal font-30">
                                                        <span className="active fw-boldest">
                                                            {activeQuestion}/
                                                        </span>
                                                        <span>
                                                            {questions.length}
                                                        </span>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mx-lg-8 mx-md-5 mx-3">
                                                    <div className="progress">
                                                        <div
                                                            style={{ width: Math.round((activeQuestion / questions.length) * 100) + '%' }}
                                                            className="progress-bar"
                                                            role="progressbar">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {questions.map((val: any, index: any) => {
                                            if (activeQuestion === val.id) {
                                                return (
                                                    <div key={index} className="row">
                                                        <div className="col-12 pb-10 pt-8">
                                                            <div className="mb-0">
                                                                <label
                                                                    className="form-label text-black font-15 fw-bold d-block mb-10">
                                                                    {index + 1}. {val.questions}
                                                                </label>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="mx-lg-8 mx-md-5 mx-3">
                                                                            <div
                                                                                className="btn-group w-100 question-options"
                                                                                id="question-options">
                                                                                <input type="radio"
                                                                                    className="btn-check"
                                                                                    name="btnradio" id="option-1"
                                                                                    // onClick={(e: any) => console.log(activeQuestion, e.target.value)}
                                                                                    // onClick={(e: any) => handleAnswer(activeQuestion, e.target.value)}
                                                                                    onClick={(e: any) => setCurrentAnswer(e.target.value)}
                                                                                    value={1}
                                                                                    // checked={answers.some((answer: any, answer_index: any) => answer_index == index && answer == 1)}
                                                                                    autoComplete="off" />
                                                                                <label
                                                                                    className="btn btn-outline-primary yes me-0"
                                                                                    htmlFor="option-1"> {t("Yes")} </label>

                                                                                <input type="radio"
                                                                                    className="btn-check"
                                                                                    name="btnradio" id="option-2"
                                                                                    onClick={(e: any) => setCurrentAnswer(e.target.value)}
                                                                                    value={2}
                                                                                    // checked={answers.some((answer: any, answer_index: any) => answer_index == index && answer == 2)}
                                                                                    autoComplete="off" />
                                                                                <label
                                                                                    className="btn btn-outline-primary no me-0"
                                                                                    htmlFor="option-2"> {t("No")} </label>

                                                                                <input type="radio"
                                                                                    className="btn-check"
                                                                                    onClick={(e: any) => setCurrentAnswer(e.target.value)}
                                                                                    value={3}
                                                                                    // checked={answers.some((answer: any, answer_index: any) => answer_index == index && answer == 3)}
                                                                                    name="btnradio" id="option-3"
                                                                                    autoComplete="off" />
                                                                                <label
                                                                                    className="btn btn-outline-primary notapplicable me-0"
                                                                                    htmlFor="option-3"> {t("N/A")} </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="mx-lg-8 mx-md-5 mx-3 pb-6">
                                                                {/* <button
                                                                    className="btn btn-default bg-transparent text-primary fw-normal mb-3 px-10 py-3 w-100 d-block border-0">
                                                                    Save
                                                                </button> */}
                                                                {
                                                                    activeQuestion !== 1 &&
                                                                    <button
                                                                        onClick={() => handleNext(val.id - 1)}
                                                                        className='btn btn-outline-secondary fw-bold btn-lg px-10 py-3 w-100 btn-back mb-3'
                                                                    >
                                                                        {t("Back")}
                                                                    </button>
                                                                }
                                                                <button
                                                                    onClick={() => questions.length === index + 1 ? handleSubmit() : handleNext(val.id + 1)}
                                                                    className='btn btn-primary fw-bold px-10 py-3 w-100'
                                                                    data-bs-toggle={questions.length === index + 1 ? "modal" : ""}
                                                                    data-bs-target={questions.length === index + 1 ? "#added-success-modal" : ""}
                                                                >
                                                                    {questions.length === index + 1 ? t("Submit") : t("Next")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddedSuccessfulPopup />
        </div>
    );
}