import React, { FC, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import completedStep from '../../../../../../assets/images/completed-step.png'
import { keyTypeDetails } from '../../../../../../constants/axios/apis';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsPopup } from '../../../../../terms-and-conditions/TermsAndConditionsPopup'

type Props = {
    contract?: any
    getStepStatus?: any
    stepStatus?: any
}

const KeyTypeDetails: FC<Props> = ({ contract, getStepStatus, stepStatus }) => {
    const [loading, setLoading] = useState(false)
    const sigCanvas = useRef<any>();
    const { t } = useTranslation();

    const initialValues = {
        keys_signature: '',
        keys_terms: false,
        keys_received_by: '',
    }

    const handelSubmit = () => {
        formik.handleSubmit()
    }

    const keyTypeDetailsSchema = Yup.object().shape({
        keys_signature: Yup.string().required(t("Please add your signature")),
        keys_terms: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
        keys_received_by: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Name of receiver is required"))
            .test('filled', t("Name of receiver cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    })
    
    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: keyTypeDetailsSchema,
        onSubmit: (values) => {
            setLoading(true)
            const data = new FormData()
            data.append('category_id', '37')
            data.append('property_id', contract?.propertyID)
            data.append('received_by', values.keys_received_by)
            data.append('signature', values.keys_signature)

            keyTypeDetails(data).then(() => {
                getStepStatus()
                setLoading(false)
            })
        },
    })

    const handleSignature = () => {
        const canvas = sigCanvas.current.getCanvas()
        canvas.toBlob((blob: string) => {
            formik.setFieldValue('keys_signature', blob)
        }, 'image/png')
    }

    function clearSignature() {
        sigCanvas.current.clear()
        formik.setFieldValue('keys_signature', '')
    }

    return (
        <>
            <div className="row">
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className="col-12">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo">
                                    {t("Key Type")}
                                    {(stepStatus?.keyCardsStatus) &&
                                        <span className='completed-step ps-3 pe-3 d-inline-block'>
                                            <img src={completedStep} alt='complete-step' />
                                        </span>}
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordion-step-one">
                                <div className="accordion-body pb-5 pt-0">
                                    <div className="row form-add-design">
                                        <div className="col-12">
                                            <h5 className="text-black fw-bolder font-14 mb-7">
                                                {t("Key Details")}
                                            </h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className='table-responsive'>
                                                    <table className='table align-middle'>
                                                        <thead>
                                                            <tr>
                                                                <th className=''> {t("Type of Key")} </th>
                                                                <th className=''> {t("No of Keys")} </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {t("Main Door Key")}
                                                                </td>
                                                                <td>
                                                                    03
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {t("Total")}
                                                                </td>
                                                                <td>
                                                                    03
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 mb-5">
                                                <h5 className="text-black fw-boldest font-14 mb-5">
                                                    {t("Building Access Regulations")}
                                                </h5>
                                                <ul className="text-black font-12 fw-bold ps-5">
                                                    <li className="mb-4">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                                                        the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                                                    </li>
                                                    <li className="mb-4">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                                                        the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                                                    </li>
                                                    <li className="mb-4">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                                                        the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                                                    </li>
                                                    <li className="mb-4">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                                                        the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        <div className="col-12">
                                            <h5 className="text-black fw-bolder font-14 mb-7">
                                                {t("Recieved By")}
                                            </h5>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder"> {t("Name")} </label>
                                                {stepStatus?.keyCardsStatus ?
                                                    <input
                                                        className='form-control fw-bolder form-control-solid'
                                                        value={stepStatus?.data?.keyCards?.details?.received_by}
                                                        disabled={true}
                                                    />
                                                    :
                                                    <input
                                                        className='form-control fw-bolder form-control-solid bg-white'
                                                        {...formik.getFieldProps('keys_received_by')}
                                                        autoComplete="off"
                                                    />
                                                }
                                                {formik.touched.keys_received_by && formik.errors.keys_received_by && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>
                                                                {formik.errors.keys_received_by}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {!stepStatus?.keyCardsStatus &&
                                            <div className="col-12 text-right form-add-design">
                                                <div className="row justify-content-end mb-10">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end align-items-center">
                                                            <div className="form-check custom mx-5">
                                                                <input
                                                                    className='form-check-input'
                                                                    {...formik.getFieldProps('keys_terms')}
                                                                    checked={formik.values.keys_terms}
                                                                    type='checkbox'
                                                                    id='flexCheckDefault'
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                                <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefaultOne">
                                                                    {t("Accept")} 	&nbsp;
                                                                    {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
                                                                        {t("Terms & Conditions")}
                                                                    </Link> */}
                                                                    <button
                                                                        data-bs-target="#terms-conditions-popup"
                                                                        data-bs-toggle='modal'
                                                                        className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                                                                        {t("Terms & Conditions")}
                                                                    </button>
                                                                    <span className='text-danger'>*</span>
                                                                </label>
                                                                {formik.touched.keys_terms && formik.errors.keys_terms && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>
                                                                            <span role='alert' className='text-danger'>
                                                                                {formik.errors.keys_terms}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <button type='button' data-bs-toggle="modal" data-bs-target="#signature-modal1" className='btn btn-primary fw-bold px-10 py-3'>
                                                                    {t("Signature")}
                                                                </button>
                                                                {formik.touched.keys_signature && formik.errors.keys_signature && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block'>
                                                                            <span role='alert' className='text-danger'>
                                                                                {formik.errors.keys_signature}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="modal fade redeem-offer signature-modal" id="signature-modal1">
                                            <div className="modal-dialog modal-xs modal-dialog-centered">
                                                <div className="modal-content rounded-10">
                                                    <div className="modal-header rounded-10 border-0">
                                                        <h5 className="modal-title font-18 fw-bold">{t("Add your Signature")}</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body p-8">
                                                        <SignatureCanvas
                                                            ref={sigCanvas}
                                                            penColor='black'
                                                            canvasProps={{ height: 180, width: 480, className: 'sigCanvas' }}
                                                        />
                                                    </div>
                                                    <div className="modal-footer p-0 m-0">
                                                        <div className="row w-100 g-0 m-0">
                                                            <div className="col-12">
                                                                <button type="button" className="btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0" onClick={clearSignature}>{t("Reset")}</button>
                                                            </div>
                                                            <div className="col-12">
                                                                <button onClick={handleSignature} type="button" className="btn btn-primary fw-bold px-10 py-5 w-100 d-block" data-bs-toggle='modal'
                                                                    data-bs-target='#ParkingSuccessModal' data-bs-dismiss="modal">{t("Submit")}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!stepStatus?.keyCardsStatus &&
                                            <div className="col-12 text-right">
                                                <div className="row justify-content-end">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end">
                                                            <button type='button' onClick={handelSubmit} disabled={loading} className='btn btn-primary fw-bold px-10 py-3'>
                                                                {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        {t("Please wait...")}{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <TermsAndConditionsPopup />
        </>
    )
};

export { KeyTypeDetails }
