import React, { FC, useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { RootState } from '../../../../constants';
import { Alert, Spinner } from 'react-bootstrap-v5'
import { DynamicPopup } from '../../../../pages/SuccessfulPopup/DynamicPopup';
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import * as dashboard from '../../../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import { bankTransfer } from '../../../../constants/axios/apis';
import { currencyFormater, someThingWentWrong } from '../../../../constants/generics/customActios';
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler';

export function BankTransfer() {
    const dispatch = useDispatch()
    const history = useHistory();
    const somethingWrg = someThingWentWrong()
    const ref = useRef<any>(0)
    const refBank = useRef<any>(0)
    const inputRef = useRef<HTMLInputElement>(null);
    let offerList: any
    offerList = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)
    const inputRefCheque = useRef<any>(0)
    const [beneficiaryName, setBeneficiaryName] = useState('')
    const [beneficiaryAccount, setBeneficiaryAccount] = useState('')
    const [beneficiaryIBAN, setBeneficiaryIBAN] = useState('')
    const [beneficiaryBankName, setBeneficiaryBankName] = useState('')
    const [beneficiarySwiftCode, setBeneficiarySwiftCode] = useState('')
    const [message, setMessage] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [error, setError] = useState('')
    const [variants, setVriants] = useState('')
    const [uploadedFileCheque, setUploadedFileCheque] = useState<any>()
    const { t } = useTranslation();

    const [uploading, setUploading] = useState(false)
    const handleUploadCheque = () => {
        inputRefCheque.current?.click()
    }
    const handleDisplayFileDetailsCheque = () => {
        if (inputRefCheque?.current?.files[0] && inputRefCheque?.current?.files[0].type === 'application/pdf') {
            let fileSize: any = inputRefCheque?.current?.files[0]?.size <= 5242880
            if (!fileSize) {
                setError(t("File size should be less than 5MB"))
                setVriants('danger')
                setShow(true)
                setTimeout(() => { setShow(false) }, 2000)
            }
            if (fileSize) {
                inputRefCheque.current?.files && setUploadedFileCheque(inputRefCheque.current.files[0])
            }
        } else {
            setUploading(true)
            try {
                validateAndCompressImage(inputRefCheque?.current?.files[0]).then((compressResponse) => {
                    if (compressResponse?.success) {
                        compressResponse.file && setUploadedFileCheque(compressResponse.file)
                    } else {
                        setError(t(compressResponse?.message || 'File compression error!'))
                        setVriants('danger')
                        setShow(true)
                        setTimeout(() => { setShow(false) }, 2000)
                    }
                    setUploading(false)
                })
            } catch (error) {
                setError(t('File upload error!'))
                setVriants('danger')
                setShow(true)
                setTimeout(() => { setShow(false) }, 2000)
                setUploading(false)
            }
        }

        // let fileSize: any = inputRefCheque?.current?.files[0]?.size <= 2101546
        // let isAllowedType: any =
        //     inputRefCheque?.current?.files[0]?.type === 'image/png' ||
        //     inputRefCheque?.current?.files[0]?.type === 'image/jpeg' ||
        //     inputRefCheque?.current?.files[0]?.type === 'image/jpg' ||
        //     inputRefCheque?.current?.files[0]?.type === 'application/pdf'
        // if (!isAllowedType) {
        //     setShow(true)
        //     setError(t("File is not a png/jpeg/jpg/PDF file"))
        //     setVriants('danger')
        //     setTimeout(() => {
        //         setShow(false)
        //     }, 2000)
        // }
        // if (!fileSize) {
        //     setShow(true)
        //     setError(t("File size should be less than 2MB"))
        //     setVriants('danger')
        //     setTimeout(() => {
        //         setShow(false)
        //     }, 2000)
        // }
        // if (isAllowedType && fileSize) {
        //     inputRefCheque.current?.files && setUploadedFileCheque(inputRefCheque.current.files[0])
        // }
    }
    const handleSubmit = () => {
        setLoading(true)
        var FormData = require('form-data')
        var bankTransferDetails = new FormData()
        bankTransferDetails.append('offer_id', offerList?.offerId);
        bankTransferDetails.append('payment_category', '1');
        bankTransferDetails.append('payment_for', offerList?.tabMode);
        bankTransferDetails.append('property_id', offerList?.propertyId);
        bankTransferDetails.append('beneficiary_name', offerList?.bank_details?.beneficiaryName);
        bankTransferDetails.append('beneficiary_account', offerList?.bank_details?.beneficiaryAccount);
        bankTransferDetails.append('iban_no', offerList?.bank_details?.ibanNo);
        bankTransferDetails.append('beneficiary_bank', offerList?.bank_details?.beneficiaryBank);
        bankTransferDetails.append('swift_code', offerList?.bank_details?.swiftCode);
        bankTransferDetails.append('branch_address', offerList?.bank_details?.branchAddress);
        bankTransferDetails.append('receipt_img', uploadedFileCheque);
        bankTransferDetails.append('amount', offerList?.tabMode === 2 ? offerList?.securityDeposit : offerList?.amount);
        bankTransfer(bankTransferDetails).then((data) => {
            setLoading(false)
            if (data?.status === 200 && data?.data?.errorCode === 0) {
                setBeneficiaryName('')
                setBeneficiaryAccount('')
                setBeneficiaryIBAN('')
                setBeneficiaryBankName('')
                setBeneficiarySwiftCode('')
                setUploadedFileCheque('')
                setMessage({
                    error: 0,
                    message: t("Payment Successful"),
                })                
                dispatch(dashboard.actions.offerDetails(offerList?.offerId))
            } else if (data?.status > 200) {
                setMessage({
                    error: 1,
                    message: somethingWrg,
                })
            } else if (data?.data?.errorCode === 1) {
                setMessage({
                    error: 1,
                    message: data?.data?.errorDescription,
                })
            } else {
                setMessage({
                    error: 1,
                    message: somethingWrg,
                })
            }
        })
    }

    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
            refBank.current.click();
        }
    }, [message])
    return (
        <><>
            <div className=" ">
                <div className="row">
                    <div className="col-12">
                        <div className="row form-add-design">
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">{t("Amount (AED)")}</label>
                                    <input type="text" className="form-control fw-bolder  bg-white"
                                        value={currencyFormater(offerList?.tabMode === 2 ? offerList?.securityDeposit : offerList?.amount)}
                                        disabled={true} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">{t("Beneficiary Name")}</label>
                                    <input type="text" className="form-control fw-bolder  bg-white" value={offerList?.bank_details?.beneficiaryName} maxLength={50} onChange={(e) => setBeneficiaryName(e?.target?.value)} onKeyPress={(event) => {
                                        if (!/[a-zA-Z ]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                        disabled />
                                </div>
                            </div>
                            {show ? (
                                <Alert className='font-14 fw-bold mb-8' variant={variants}>
                                    {error}!
                                </Alert>
                            ) : null}
                            <div className="mb-5 upload-input">
                                <label className="form-label fw-bolder d-block mb-3">{t("Bank Receipt")}</label>
                                <input
                                    ref={inputRefCheque}
                                    onChange={handleDisplayFileDetailsCheque}
                                    className='d-none'
                                    type='file'
                                    accept='.png,.jpeg,.jpg,application/pdf' />
                                <button onClick={handleUploadCheque} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileCheque?.name ? "primary" : "primary"}`} disabled={uploading}>
                                    <span>{uploading ?
                                        <span className='indicator-progress d-block'>
                                            {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        : uploadedFileCheque?.name ? uploadedFileCheque?.name : <span>{t("Upload")}  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>} </span>
                                </button>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">{t("Beneficiary Account Number")}</label>
                                    {loading ? <Spinner animation='border' variant='primary' /> : null}
                                    <input type="text" className="form-control fw-bolder  bg-white" value={offerList?.bank_details?.beneficiaryAccount} maxLength={50} onChange={(e) => setBeneficiaryAccount(e?.target?.value)} onKeyPress={(event) => {
                                        if (!/[0-9+]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                        disabled />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">{t("IBAN Number")}</label>
                                    <input type="text" className="form-control fw-bolder  bg-white" value={offerList?.bank_details?.ibanNo} maxLength={50} onChange={(e) => setBeneficiaryIBAN(e?.target?.value)} onKeyPress={(event) => {
                                        if (!/[0-9+a-zA-Z]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                        disabled />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">{t("Beneficiary Bank Name")}</label>
                                    <input type="text" className="form-control fw-bolder  bg-white" value={offerList?.bank_details?.beneficiaryBank} maxLength={50} onChange={(e) => setBeneficiaryBankName(e?.target?.value)} onKeyPress={(event) => {
                                        if (!/[a-zA-Z ]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                        disabled />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-5 position-relative">
                                    <label className="font-14 fw-bold mb-3 d-block">{t("Swift Code")}</label>
                                    <input type="text" className="form-control fw-bolder  bg-white" value={offerList?.bank_details?.swiftCode} maxLength={50} onChange={(e) => setBeneficiarySwiftCode(e?.target?.value)} onKeyPress={(event) => {
                                        if (!/[0-9+a-zA-Z ]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                {loading === false && uploading === false ? <button type='submit' className='btn btn-primary fw-bold px-10 py-3 w-100' onClick={handleSubmit} disabled={
                                    uploadedFileCheque?.name?.length > 0 ? false : true &&
                                        loading === false
                                        ? true : false}>
                                    {t("Submit")}
                                </button> : <button type='submit' className='btn btn-primary fw-bold px-10 py-3 w-100' disabled={true}>
                                    {t("Submit")}
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </><p ref={refBank} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                {/*  */}
            </p>
            </>
    );
}
