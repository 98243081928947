import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next'

ChartJS.register(ArcElement, Tooltip, Legend);

/* export const data = {
    labels: ['40 Active', '10 Inactive'],
    datasets: [
        {
            datalabels: {
                color: "#ffffff",
                font: {
                    size: 16,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                },
            },
            label: '50 Total Brokers',
            data: [40, 10],
            backgroundColor: [
                '#147AD6',
                '#FF5F58',
            ],
            borderColor: [
                '#147AD6',
                '#FF5F58',
            ],
            borderWidth: 1,
        },
    ]
}; */

export const options = {
    cutout: "80%",
    plugins: {
        legend: {
            display: true,
            position: 'right' as const,
            labels: {
                color: 'rgb(0, 44, 106)',
                lineDash: [12, 12],
                boxWidth: 19,
                boxHeight: 18,
                padding: 30,
                usePointStyle: true,
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    lineHeight: 0.5,
                }
            },
            layout: {
                padding: 20,
            }
        },
    },

    maintainAspectRatio: false,
};

export const TotalInvoices: React.FC<any> = ({ broker }) => {
    const { t } = useTranslation();

    const [data, setData] = useState({
        labels: ['0 Active', '0 Inactive'],

        datasets: [
            {
                datalabels: {
                    color: "#ffffff",
                    font: {
                        size: 16,
                        family: "'Poppins', sans-serif",
                        lineHeight: 0.5,
                    },
                },
                data: [0, 0],
                backgroundColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderColor: [
                    '#147AD6',
                    '#FF5F58',
                ],
                borderWidth: 1,
            },
        ]
    });
    useEffect(() => {
        if (Object.keys(broker).length !== 0) {
            setData({
                labels: [`${broker?.paidInvoices} ${t("Paid")}`, `${broker?.nonPaidInvoices} ${t("Non Paid")}`],
                datasets: [
                    {
                        datalabels: {
                            color: "#ffffff",
                            font: {
                                size: 16,
                                family: "'Poppins', sans-serif",
                                lineHeight: 0.5,
                            },
                        },
                        data: [broker?.paidInvoices, broker?.nonPaidInvoices],
                        backgroundColor: [
                            '#147AD6',
                            '#FF5F58',
                        ],
                        borderColor: [
                            '#147AD6',
                            '#FF5F58',
                        ],
                        borderWidth: 1,
                    },
                ]
            })
        }
    }, [broker]);
    return (
        <>
            {/* <div className="row pt-5">
                <div className="col-12"> */}
                    <div className="card mt-5 brokers-count rounded-10">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4 className="card-title font-17 mb-0">{t("Invoices")} </h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mt-lg-10 mt-md-5">
                                    <div className="Doughnut-Chart text-center position-relative">
                                        <Chart type='doughnut' data={data} options={options} height={"300%"} width={"90%"} />
                                        <div className="inner-text">
                                            <h4 className="font-12 text-primary fw-boldest">
                                                <span className='font-14'>{broker?.paidInvoices + broker?.nonPaidInvoices}</span><br />
                                                {t("Total")}<br />
                                                {t("Invoices")}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}
        </>
    );
}