import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { BrokerActivityTabs } from './BrokerActivityTabs'
import { BarChartForBroker } from './BarChartForBroker'
import { useTranslation } from 'react-i18next'

export const SingleBrokersActivity: React.FC<any> = ({ broker }) => {
  const { t } = useTranslation()

  return (
    <>
      {/* <div className='row pt-5'>
        <div className='col-12'> */}
          <div className='card mt-5 brokers-count rounded-10'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-12'>
                  <h4 className='card-title font-17 mb-0'> {t('Activity Progress')}</h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Switch>
                    <Route path='/SingleBroker/in-progress'>
                      <BrokerActivityTabs broker={broker} />
                      <BarChartForBroker invoice={broker?.Count?.inprogress} />
                    </Route>
                    <Route path='/SingleBroker/approved'>
                      <BrokerActivityTabs broker={broker} />
                      <BarChartForBroker invoice={broker?.Count?.approved} />
                    </Route>
                    <Route path='/SingleBroker/rejected'>
                      <BrokerActivityTabs broker={broker} />
                      <BarChartForBroker invoice={broker?.Count?.rejected} />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
    </>
  )
}
