import React, { useState, useRef, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import cheque from '../../../../assets/images/bank-check-inactive.svg'
import cash from '../../../../assets/images/cash-active.svg'
import emp from '../../../../assets/images/payment-inactive.svg'
import { useFormik } from 'formik'
import { getConfig, getContractActivity, renewContractRequest } from '../../../../constants/axios/apis'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsPopup } from '../../../terms-and-conditions/TermsAndConditionsPopup'
import { useDispatch } from 'react-redux'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'

export function ContractDetails({ contract }: any) {
  const [loading, setLoading] = useState(false)
  const [serviceStatus, setServiceStatus] = useState<Boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const closeRef = useRef<any>()
  const ref = useRef<any>(0)
  const [renewalAmount, setRenewalAmount] = useState<any>()
  const [renewalPercentage, setRenewalPercentage] = useState<any>()
  const [message, setMessage] = useState<any>()
  const { t } = useTranslation();

  useEffect(() => {
    getConfig().then(({ data }: any) => {
      setRenewalPercentage(
        data?.find((item: any) => item.key == 'renewal_percentage_increase').value
      )
    })
  }, [])

  useEffect(() => {
    if (renewalPercentage > 0) {
      let amount = (renewalPercentage / 100) * parseInt(contract?.rent) + parseInt(contract?.rent)
      setRenewalAmount(amount)
    }
  }, [renewalPercentage, contract])

  let userName = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userName = JSON.parse(userDetail).first_name + ' ' + JSON.parse(userDetail).last_name
  }

  useEffect(() => {
    getContractActivity(contract?.id, 0).then((response) => {
      if (response.data.length) {
        setServiceStatus(true)
      } else {
        setServiceStatus(false)
      }
    })
  }, [contract])

  let startingDate: any = new Date(contract?.contractStartDate)
  startingDate.setFullYear(startingDate.getFullYear() + 1)
  const start_date = startingDate.toLocaleString().split(',')[0]

  let endingDate: any = new Date(start_date)
  endingDate.setFullYear(endingDate.getFullYear() + 1)
  endingDate.setDate(endingDate.getDate() - 1)
  const end_date = endingDate.toLocaleString().split(',')[0]

  const initialValues = {
    contract_id: contract?.id,
    mode_payment: '',
    no_payments: '',
    customer_name: userName,
    terms: false,
  }

  const contractRenewalSchema = Yup.object().shape({
    mode_payment: Yup.string().required(t("Please choose method of payment")),
    no_payments: Yup.string().required(t("Please choose number of installments")),
    terms: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
  })

  const formik = useFormik({
    initialValues,
    isInitialValid: false,
    validationSchema: contractRenewalSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        renewContractRequest(
          values.contract_id,
          values.mode_payment,
          values.customer_name,
          values.no_payments
        )
          .then((response: any) => {
            setLoading(false)
            if (response != 500) {
              resetForm()
              closeRef.current.click()
              history.push('/my-services/openissues')
            }
            else {
              setMessage({
                error: 1,
                message: 'Something went wrong!',
              })
              ref.current.click()
            }
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  useEffect(() => {
    if (message?.message?.length > 0) {
      dispatch(PTOffer.actions.modalmessage(message))
    }
  }, [message])
  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-6'>
                <div className='row'>
                  <div className='col-md-12 col-12'>
                    <h4 className='card-title'>{t("Contract Details")}</h4>
                    {serviceStatus ? (
                      <p className='text-success'>
                        {t("A contract renewal request is already in progress.")}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='kt_login_signup_form'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='row form-add-design mt-10'>
                    <div className='col-md-3 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Customer Name")}</label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          value={contract?.occupantFirstName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className='col-md-3 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t("Renewal Start Date")}</label>
                        <input
                          className='form-control fw-bolder form-control-solid'
                          type='text'
                          value={moment(start_date).format('DD-MM-YYYY')}
                          disabled
                        />
                        {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                      </div>
                    </div>
                    <div className='col-md-3 col-12'>
                      <div className='mb-10 position-relative'>
                        <label className='form-label fw-bolder'>{t("Renewal End Date")}</label>
                        <input
                          className='form-control fw-bolder form-control-solid'
                          type='text'
                          value={moment(end_date).format('DD-MM-YYYY')}
                          disabled
                        />
                        {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                      </div>
                    </div>
                    <div className='col-md-3 col-12'>
                      <div className='mb-10 position-relative'>
                        <label className='form-label fw-bolder'>{t("Annual Rent")}</label>
                        <input
                          className='form-control fw-bolder form-control-solid'
                          type='tel'
                          value={renewalAmount?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' AED'}
                          disabled
                        />
                      </div>
                    </div>
                    {/* <div className='col-md-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>Method of Payment</label>
                        <select
                          className='form-control form-select fw-bolder form-control-solid'
                          data-control='select2'
                          data-allow-clear='true'
                          name='mode_payment'
                          onChange={formik.handleChange}
                          value={formik.values.mode_payment}
                          onBlur={formik.handleBlur}
                          style={{cursor: serviceStatus ? 'not-allowed' : ''}}
                          disabled={serviceStatus ? true : false}
                        >
                          <option value='' disabled>
                            Please Select
                          </option>
                          <option value='CREDIT_CARD'>Credit Card</option>
                          <option value='CHEQUE'>Cheque</option>
                          <option value='PAYMENT_BY_EMPLOYER'>Payment By Employer</option>
                        </select>
                        {formik.touched.mode_payment && formik.errors.mode_payment && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.mode_payment}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div> */}
                    {/* <div className='col-md-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>Number of Payments</label>
                        <select
                          className='form-control form-select fw-bolder form-control-solid'
                          data-control='select2'
                          data-allow-clear='true'
                          name='no_payments'
                          onChange={formik.handleChange}
                          value={formik.values.no_payments}
                          onBlur={formik.handleBlur}
                          style={{cursor: serviceStatus ? 'not-allowed' : ''}}
                          disabled={serviceStatus ? true : false}
                        >
                          <option value='' disabled>
                            Please Select
                          </option>
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                        </select>
                        {formik.touched.no_payments && formik.errors.no_payments && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.no_payments}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div> */}
                    {serviceStatus ? (
                      ''
                    ) : (
                      <>
                        <div className='col-12'>
                          <div className='col-md-12 col-12'>
                            <h4 className='mb-5'>{t("Method of Payment")}</h4>
                          </div>
                          <div className='mb-10 make-an-offer-wdw'>
                            <div className='row'>
                              <div className='col-md-4 col-sm-6 col-12'>
                                <div className='btn payment-group w-100 form-check form-check-inline col-auto mb-4 me-10'>
                                  <input
                                          className='form-check-input'
                                type='radio'
                                name='mode_payment'
                                id='pay-one'
                                value='CREDIT_CARD'
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <label className='form-check-label text-black font-12 d-inline-flex' htmlFor='pay-one'>

                                {t("Credit Card")}
                                <span className='cash-ioc'><img src={cash} alt="cheqe"/></span>
                              </label>
                                </div>
                                </div>
                                  <div className='col-md-4 col-sm-6 col-12'>
                                    <div className='btn payment-group w-100 form-check form-check-inline col-auto mb-4 me-10'>
                                      <input
                                              className='form-check-input'
                                type='radio'
                                name='mode_payment'
                                id='pay-two'
                                value='CHEQUE'
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <label className='form-check-label text-black font-12 d-inline-flex' htmlFor='pay-two'>
                                {t("Cheque")}<span className='cheque-ioc'><img src={cheque} alt="cheqe"/></span>

                              </label>
                                    </div>
                                  </div>
                              <div className='col-md-4 col-sm-6 col-12'>
                                <div className='btn payment-group w-100 form-check form-check-inline col-auto mb-4 me-10'>
                                  <input
                                          className='form-check-input'
                                type='radio'
                                name='mode_payment'
                                id='pay-three'
                                value='PAYMENT_BY_EMP'
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <label className='form-check-label text-black font-12 d-inline-flex' htmlFor='pay-three'>
                                {t("Payment By Employer")}<span className='emp-pay-ioc'><img src={emp} alt="cheqe"/></span>

                              </label>
                            </div>
                            </div>
                            </div>
                            {formik.touched.mode_payment && formik.errors.mode_payment && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.mode_payment}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='mb-10 make-an-offer-wdw installment'>
                            <label className='form-label font-14 fw-bolder text-black d-block mb-5'>
                              {t("Choose the number of installments")}
                            </label>
                            <div className="form-check form-check-inline col-auto mb-4 me-10">
                              <input
                                      className='form-check-input'
                                type='radio'
                                name='no_payments'
                                id='no-pay-one'
                                value='1'
                                autoComplete='off'
                                // checked={formik.values.installment == '1'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <label className='form-check-label text-black font-12' htmlFor='no-pay-one'>
                                {t("1 Installment")}
                              </label>
                            </div>
                              <div className="form-check form-check-inline col-auto mb-4 me-10">
                              <input
                                      className='form-check-input'
                                type='radio'
                                name='no_payments'
                                id='no-pay-two'
                                value='2'
                                autoComplete='off'
                                // checked={formik.values.installment == '2'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <label className='form-check-label text-black font-12' htmlFor='no-pay-two'>
                                {t("2 Installments")}
                              </label>
                              </div>
                            <div className="form-check form-check-inline col-auto mb-4 me-10">
                              <input
                                      className='form-check-input'
                                type='radio'
                                name='no_payments'
                                id='no-pay-three'
                                value='3'
                                autoComplete='off'
                                // checked={formik.values.installment == '3'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <label className='form-check-label text-black font-12' htmlFor='no-pay-three'>
                                {t("3 Installments")}
                              </label>
                            </div>
                            <div className="form-check form-check-inline col-auto mb-4 me-10">
                              <input
                                      className='form-check-input'
                                type='radio'
                                name='no_payments'
                                id='no-pay-four'
                                value='4'
                                autoComplete='off'
                                // checked={formik.values.installment == '4'}
                                onChange={formik.handleChange}
                                // onChange={(e) => handleInstallmentChange(e)}
                                onBlur={formik.handleBlur}
                              />
                              <label className='form-check-label text-black font-12' htmlFor='no-pay-four'>
                                {t("4 Installments")}
                              </label>
                            </div>
                            {formik.touched.no_payments && formik.errors.no_payments && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.no_payments}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-md-4 col-12 my-auto'>
                          <div className='mb-4'>
                            <div className='form-check custom'>
                              <input
                                className='form-check-input'
                                {...formik.getFieldProps('terms')}
                                checked={formik.values.terms}
                                type='checkbox'
                                id='flexCheckDefault'
                                onBlur={formik.handleBlur}
                              />
                              <label
                                className='form-check-label font-14 text-black fw-bolder'
                                htmlFor='flexCheckDefault'
                              >
                                {t("Accept")} 	&nbsp;
                                {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary pl-5 fw-500">
                                  {t("Terms & Conditions")}
                                </Link> */}
                                <button
                                  data-bs-target="#terms-conditions-popup"
                                  data-bs-toggle='modal'
                                  className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                                  {t("Terms & Conditions")}
                                </button>
                                <span className='text-danger'>*</span>
                              </label>
                            </div>
                            {formik.touched.terms && formik.errors.terms && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.terms}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div className='col-12 text-end'>
                      {serviceStatus ? (
                        <div
                          className='btn btn-primary fw-bold px-10 py-3'
                          style={{ cursor: 'not-allowed' }}
                          title={'Contract renewal was already submitted!'}
                        >
                          {t("Submit")}
                        </div>
                      ) : (
                        <button
                          type='submit'
                          className='btn btn-primary fw-bold px-10 py-3'
                          disabled={loading}
                        >
                          {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              {t("Please wait...")}{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <p
          ref={closeRef}
          data-bs-target='#renew-popup'
          data-bs-toggle='modal'
          data-bs-dismiss='modal'
        >
          {/*  */}
        </p>
      </div><p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
        {/*  */}
      </p>
      <TermsAndConditionsPopup />
    </>
  )
}
