import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

export function VehicleRegistration({ selectedIssue, contract }: any) {
    const [issue, setIssue] = useState<any>()
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedIssue) {
            setIssue(selectedIssue)
            setLoading(false)
        }
    }, [selectedIssue])

    return (
        <div className='com-card-section'>
            {!loading && issue && issue?.code == 'VR' ? (   //TODO: change code as per required
                <div className='row mta-app-setting-campaign'>
                    <div className='col-12'>
                        <div className='accordion accordion-flush' id='accordionFlushExampleAccesscard'>
                            <div className='accordion-item offers_details_accordian_div1'>
                                <h2 className='accordion-header position-relative' id='flush-headingAccesscard'>
                                    <button
                                        className='accordion-button collapsed accordian_bt'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#flush-collapseAccesscard'
                                        aria-expanded='false'
                                        aria-controls='flush-collapseAccesscard'
                                    >
                                        {t("Vehicle Registration")}
                                    </button>
                                </h2>
                                <div
                                    id='flush-collapseAccesscard'
                                    className='accordion-collapse collapse show'
                                    aria-labelledby='flush-headingAccesscard'
                                    data-bs-parent='#accordionFlushExampleAccesscard'
                                >
                                    <div className='accordion-body py-5'>
                                        <div className="row form-add-design">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body p-0">
                                                        <ul className="nav nav-tabs parking-slot-tab" id="ParkingSlotTab" role="tablist">
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active" id="parking-slot-no-104-tab" data-bs-toggle="tab" data-bs-target="#parking-slot-no-104" type="button" role="tab" aria-controls="home" aria-selected="true">{t("Parking Slot Number")}<span className="ps-3">{issue?.details?.parking_slot_number}</span></button>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content" id="ParkingSlotTabContent">
                                                            <div className="tab-pane fade show active" id="parking-slot-no-104" role="tabpanel" aria-labelledby="parking-slot-no-104-tab">
                                                                <div className="my-10">
                                                                    <div className="slot-details">
                                                                        <h4 className="font-16">{t("Vehicle Details")}</h4>
                                                                        <div className="row">
                                                                            <div className="col-md-4 col-12">
                                                                                <div className="mb-10">
                                                                                    <label className="form-label fw-bolder">{t("Brand")}</label>
                                                                                    <input type="text" className="form-control fw-bolder form-control-solid" value={issue?.details?.brand} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-12">
                                                                                <div className="mb-10">
                                                                                    <label className="form-label fw-bolder">{t("Model")}</label>
                                                                                    <input type="text" className="form-control fw-bolder form-control-solid" value={issue?.details?.model} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-12">
                                                                                <div className="mb-10">
                                                                                    <label className="form-label fw-bolder">{t("Color")}</label>
                                                                                    <input type="text" className="form-control fw-bolder form-control-solid" value={issue?.details?.color} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-12">
                                                                                <div className="mb-0">
                                                                                    <label className="form-label fw-bolder">{t("Registered Plate Number")}</label>
                                                                                    <input type="text" className="form-control fw-bolder form-control-solid" value={issue?.details?.regno} readOnly />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-12">
                                                                                <div className="mb-0">
                                                                                    <label className="form-label fw-bolder">{t("Signature")}</label>
                                                                                    <img src={issue?.images?.signature} alt='preview-img' className='border border-secondary' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}