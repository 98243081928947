import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import {MoveIn} from './MoveIn/MoveIn'

const CTMoveInPage: FC = () => (
    <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
        <div className='col-xxl-12 mt-0'>
            <MoveIn />
        </div>
    </div>
    </>
);

const CTMoveInWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
        <PageTitle breadcrumbs={[]}>Move In</PageTitle>
        <CTMoveInPage />
        </>
    )
};

export {CTMoveInWrapper}
