import {DataGrid} from '@mui/x-data-grid'
import {Link} from 'react-router-dom'
import { useHistory  } from "react-router-dom";
import {useTranslation} from 'react-i18next'
import TableData from './TableData'

export function UnitsList() {
  const {t} = useTranslation()
  const history = useHistory();

  const handDetailsView = (url:any) => {
    localStorage.setItem('unitDetail', url);
    history.push('/unit-portfolio/owner-details');
  }

  const columns = [
    {
      field: 'unitcode',
      headerName: t('Unit Code'),
      flex: 1
    },
    {
      field: 'unitname',
      headerName: t('Unit Name'),
      flex: 1
    },
    {
      field: 'communityname',
      headerName: t('Community'),
      flex: 1
    },
    {
      field: 'building',
      headerName: t('Building'),
      flex: 1
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <span className={params.value === 'Handover' ? 'text-success' : params.value === 'Under Construction' ? 'text-primary2': params.value === 'Approved' ? 'text-success' : 'text-warning' }>{params.value}</span>
        )
      },
    },
    {
      field: 'action',
      headerName: t('Action'),
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <Link to onClick={() => handDetailsView(params.value)}>
              <span className='view-option text-theme fw-bolder'>{t("View")}</span>
            </Link>
          </>
        )
      },
    },
  ]

  return (
    <div className='com-card-section'>
      <div className='row mb-6 align-items-center'>
        <div className='col mt-0'>
          <h4 className='card-title font-18 text-black mb-0'>{t('Unit Portfolio')}</h4>
        </div>
        {/* <div className='col-auto mt-0'>
          <Link className="btn btn-primary fw-bold px-10 py-3" to="/unit-portfolio/create-unit">Add Unit</Link>
        </div> */}
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <div className='data_table'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='table-responsive'>
                      <div style={{height: 400, width: '100%'}}>
                        <DataGrid
                          rows={TableData}
                          columns={columns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
