import React, { FC, useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import TableData from './components/TableData';
import { Button, Dropdown, Menu, Space } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Redirect, Route, Switch } from 'react-router-dom'
import Leads from './components/leads'; 
import { NewRequest } from '../maintenance/add/NewRequest/NewRequest';
import { NewRequestList } from '../fc-supervisor-services_bckup/Maintenance/components/NewRequest/NewRequest';
import { AddLead } from './components/AddLead';
import { EditLead } from './components/EditLead';
import { ViewLead } from './components/ViewLead';

const LeadsPage: FC = () => {
    return (
        <>
            <Switch>
                <Route path='/leads/list'>
                    <Leads />
                </Route>
                <Route path='/leads/add'>
                    <AddLead />
                </Route>
                <Route path='/leads/edit'>
                    <EditLead />
                </Route>
                <Route path='/leads/view'>
                    <ViewLead />
                </Route>
            </Switch>
        </>
    )
}
const LeadsWrapper: FC = () => {
    return (
        <>
            <LeadsPage />
        </>
    )
}
export { LeadsWrapper }