import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

const MarketPlaceList: React.FC = () => {
  const location = useLocation()

  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        {userEmail === 'commercialtenant@exalogic.co' ? (
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-0'>
                <div className='row access-cd-tabs'>
                  <div className='col-md-12 col-12'>
                    <div className='d-flex overflow-auto h-55px'>
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ` +
                              (location.pathname === '/market-place/all' && 'active')
                            }
                            to='/market-place/all'
                          >
                            All
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ps-0 ` +
                              (location.pathname === '/market-place/active' && 'active')
                            }
                            to='/market-place/active'
                          >
                            Active
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ` +
                              (location.pathname === '/market-place/inactive' && 'active')
                            }
                            to='/market-place/inactive'
                          >
                            Inactive
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ` +
                              (location.pathname === '/market-place/expired' && 'active')
                            }
                            to='/market-place/expired'
                          >
                            Expired
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ` +
                              (location.pathname === '/market-place/redeemed' && 'active')
                            }
                            to='/market-place/redeemed'
                          >
                            Redeemed
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-0'>
                <div className='row access-cd-tabs'>
                  <div className='col-md-12 col-12'>
                    <div className='d-flex overflow-auto h-55px'>
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ps-0 ` +
                              (location.pathname === '/market-place/nearby' && 'active')
                            }
                            to='/market-place/nearby'
                          >
                            Nearby
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ` +
                              (location.pathname === '/market-place/all' && 'active')
                            }
                            to='/market-place/all'
                          >
                            All
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ` +
                              (location.pathname === '/market-place/dining' && 'active')
                            }
                            to='/market-place/dining'
                          >
                            Dining
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ` +
                              (location.pathname === '/market-place/lifestyle' && 'active')
                            }
                            to='/market-place/lifestyle'
                          >
                            Lifestyle
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ` +
                              (location.pathname === '/market-place/entertainment' && 'active')
                            }
                            to='/market-place/entertainment'
                          >
                            Entertainment
                          </Link>
                        </li>
                        <li className='nav-item'>
                          <Link
                            className={
                              `nav-link pb-1 position-relative text-active-primary me-6 ` +
                              (location.pathname === '/market-place/redeemed' && 'active')
                            }
                            to='/market-place/redeemed'
                          >
                            Redeemed
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {MarketPlaceList}
