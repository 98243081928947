import React, { FC, useState, useEffect, useRef } from 'react'
import { getAlerts, removeAlerts, unSubscribeAlerts, subscribeAlerts } from '../../../../constants/axios/apis'
import { useDispatch } from 'react-redux';
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { DynamicPopup } from '../../../SuccessfulPopup/DynamicPopup';
import { CustomLoaderDynamic, NoDataFound1 } from '../../../../constants/generics/customActios';
import { getMasterData } from './../../../../constants/axios/apis';
import { MdOutlineClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import MyAlertImg from '../../../../assets/images/my-alert-image.png'
import CancelImg from '../../../../assets/images/cancel.png'
const languages = [
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl'
    },
];

const AlertName02: FC<any> = ({ reload }) => {
    const dispatch = useDispatch()
    const ref = useRef<any>(0)

    const [myAlertsList, setMyAlertsList] = useState<any>()
    const [message, setMessage] = useState<any>()
    const [loading, setLoading] = useState<any>(false)
    const [masterData, setMasterData] = useState<any>();
    const [cancelId, setCancelId] = useState<any>()
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true)
        getAlerts().then((data) => {
            let allAlert = data?.data?.reverse().map((value: any) => { return value });
            setMyAlertsList(allAlert);
            setLoading(false)
        })
        getMasterData().then(({ data: masterData }) => {
            let moveInWithIn = masterData
                .filter((item: any) => item.id === 17)
                .flatMap((data: any) => data.master_data)
            setMasterData(moveInWithIn)
        })
    }, [, message, reload])
    const handleSubmit = (id: any) => {
        var AlertId = ({
            "id_alert": id
        });

        removeAlerts(AlertId).then((data) => {
            if (data.code === 200 && data.errorCode != 1) {
                setMessage({
                    error: 0,
                    message: data?.description,
                })
                ref.current.click();
            } else {
                setMessage({
                    error: 1,
                    message: data?.errorDescription,
                })
                ref.current.click();
            }
        })
    }
    const handleUnsubscribe = (e: any) => {
        var AlertId = ({
            "id_alert": e?.target?.value
        });

        unSubscribeAlerts(AlertId).then((data) => {
            if (data.code === 200 && data.errorCode != 1) {
                setMessage({
                    error: 0,
                    message: data?.description,
                })
                ref.current.click();
            } else {
                setMessage({
                    error: 1,
                    message: data?.errorDescription,
                })
                ref.current.click();
            }
        })
    }
    const handleSubscribe = (e: any) => {
        var AlertId = ({
            "id_alert": e?.target?.value
        });

        subscribeAlerts(AlertId).then((data) => {
            if (data.code === 200 && data.errorCode != 1) {
                setMessage({
                    error: 0,
                    message: data?.description,
                })
                ref.current.click();
            } else {
                setMessage({
                    error: 1,
                    message: data?.errorDescription,
                })
                ref.current.click();
            }
        })
    }

    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
        }
    }, [message])

    if (loading) { return (<div><CustomLoaderDynamic height={'425px'} showCard={true} /></div>) }

    return (
        <>
            {myAlertsList && myAlertsList?.length > 0 && myAlertsList?.map((alerts: any, index: number) => {

                const buildingsList = alerts?.location_name && alerts.location_name.length ?
                    Array.isArray(alerts.location_name) ? (alerts.location_name).join(', ') : alerts.location_name : null
                const propertyTypeList = alerts?.property_type_name && alerts.property_type_name.length ?
                    Array.isArray(alerts.property_type_name) ? (alerts.property_type_name).join(', ') : alerts.property_type_name : null

                return (
                    <div className="card mb-7">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <div className="my-alert-image">
                                        <img src={MyAlertImg} alt="my-alert-image" />
                                    </div>
                                </div>
                                <div className="col-md-9 col-12">
                                    <div className='py-7 pe-7 ps-md-0 ps-7'>
                                        <div className='row alert-text'>
                                            {buildingsList &&
                                            <div className="col-md-6 col-12">
                                                <div className="d-flex mb-4">
                                                    <h6>Building </h6>
                                                    <p>{buildingsList}</p>
                                                </div>
                                            </div>
                                            }
                                            {propertyTypeList &&
                                            <div className="col-md-6 col-12">
                                                <div className="d-flex mb-4">
                                                    <h6>Property Type </h6>
                                                    <p>{propertyTypeList}</p>
                                                </div>
                                            </div>
                                            }
                                            {alerts?.no_of_bedrooms && alerts?.no_of_bedrooms != "null" &&
                                                <div className="col-md-6 col-12">
                                                    <div className="d-flex mb-4">
                                                        <h6>Bedrooms</h6>
                                                        <p>{alerts?.no_of_bedrooms}</p>
                                                    </div>
                                                </div>
                                            }
                                            {(alerts?.budget_to && alerts?.budget_to !== '0') ?
                                            <div className="col-md-6 col-12">
                                                <div className="d-flex mb-4">
                                                    <h6>Budget in AED</h6>
                                                    <p>{parseInt(alerts?.budget_from)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}  {t("AED")} - {parseInt(alerts?.budget_to)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}  {t("AED")}</p>
                                                </div>
                                            </div>
                                             : ''
                                            }
                                            {masterData && masterData?.map((movein: any, index: number) => {
                                                if (alerts?.move_in == movein?.value)
                                                    return (
                                                        <div className="col-md-6 col-12">
                                                            <div className="d-flex mb-4">
                                                                <h6>Movein</h6>
                                                                <p className="font-14 fw-bold d-block mb-0 alert-txt">{alerts?.move_in == movein?.value ? movein?.name : ''}</p>
                                                            </div>
                                                        </div>
                                                    );
                                            })}
                                            {(alerts?.built_in_area_to && alerts?.built_in_area_to !== 0) ?
                                            <div className="col-md-6 col-12">
                                                <div className="d-flex mb-4">
                                                    <h6>Property size in Sqmt</h6>
                                                    <p>{parseInt(alerts?.built_in_area_from)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}  {t("Sqmt")} - {parseInt(alerts?.built_in_area_to)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}  {t("Sqmt")}</p>
                                                </div>
                                            </div>
                                             : ''
                                            }
                                        </div>
                                        <div className="row justify-content-end">
                                            <div className="col-lg-3 col-md-6 col-6">
                                                <button className="btn btn-outline-primary btn-block fw-bold px-md-7 px-sm-10 py-3"
                                                    type='button'
                                                    data-bs-target='#alertCancel-popup1'
                                                    data-bs-toggle='modal'
                                                    onClick={() => setCancelId(alerts?.id_alert)}>{t("Remove")}</button>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-6">
                                                <button className="btn btn-primary fw-bold btn-block px-md-7 px-sm-10 py-3" value={alerts?.id_alert} onClick={alerts?.status != 2 ? handleUnsubscribe : handleSubscribe}>{alerts?.status != 2 ? t("Unsubscribe") : t("Subscribe")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <p>{!loading && !myAlertsList?.length ? <div className='card h-100'><div className='card-body p-7 '> <div className="row"> <NoDataFound1 /> </div> </div></div> : ''}</p>

            {/* <div className="row">
                <div className="col-12">
                    {myAlertsList && myAlertsList?.length > 0 && myAlertsList?.map((alerts: any, index: number) => {

                        const buildingsList = alerts?.location_name && alerts.location_name.length ?
                            Array.isArray(alerts.location_name) ? (alerts.location_name).join(', ') : alerts.location_name : null
                        const propertyTypeList = alerts?.property_type_name && alerts.property_type_name.length ?
                            Array.isArray(alerts.property_type_name) ? (alerts.property_type_name).join(', ') : alerts.property_type_name : null

                        return (
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#alert-' + alerts?.id_alert}>
                                        <span className='text-ellipsis w-95'> {buildingsList ? buildingsList : <i>Building name error</i>} </span>
                                    </button>
                                </h2>
                                <div id={'alert-' + alerts?.id_alert} className="accordion-collapse collapse " data-bs-parent="#accordion-step-one">
                                    <div className="accordion-body pb-5 pt-0">
                                        <div className="row">
                                            {buildingsList &&
                                                <div className="col-xl-4 col-lg-6 col-sm-6 col-12 mb-6">
                                                    <div className="data-contain2 px-5 py-4 rounded-10 text-center h-auto">
                                                        <h6 className="font-14 fw-bold d-block mb-0 alert-txt">{buildingsList} </h6>
                                                    </div>
                                                </div>
                                            }
                                            {propertyTypeList &&
                                                <div className="col-xl-4 col-lg-6 col-sm-6 col-12 mb-6">
                                                    <div className="data-contain2 px-5 py-4 rounded-10 text-center h-auto">
                                                        <h6 className="font-14 fw-bold d-block mb-0 alert-txt">{propertyTypeList} </h6>
                                                    </div>
                                                </div>
                                            }
                                            {alerts?.no_of_bedrooms && alerts?.no_of_bedrooms != "null" &&
                                                <div className="col-xl-4 col-lg-6 col-sm-6 col-12 mb-6">
                                                    <div className="data-contain2 px-5 py-4 rounded-10 text-center h-auto">
                                                        <h6 className="font-14 fw-bold d-block mb-0 alert-txt">{t("Bedroom")} - {alerts?.no_of_bedrooms}</h6>
                                                    </div>
                                                </div>
                                            }
                                            {(alerts?.budget_to && alerts?.budget_to !== '0') ?
                                                <div className="col-xl-4 col-lg-6 col-sm-6 col-12 mb-6">
                                                    <div className="data-contain2 px-5 py-4 rounded-10 text-center h-auto">
                                                        <h6 className="font-14 fw-bold d-block mb-0 alert-txt">{parseInt(alerts?.budget_from)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}  {t("AED")} - {parseInt(alerts?.budget_to)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}  {t("AED")} </h6>
                                                    </div>
                                                </div>
                                                : ''
                                            }
                                            {masterData && masterData?.map((movein: any, index: number) => {
                                                if (alerts?.move_in == movein?.value)
                                                    return (
                                                        <div className="col-xl-4 col-lg-6 col-sm-6 col-12 mb-6">
                                                            <div className="data-contain2 px-5 py-4 rounded-10 text-center h-auto">
                                                                <h6 className="font-14 fw-bold d-block mb-0 alert-txt">{alerts?.move_in == movein?.value ? movein?.name : ''}</h6>
                                                            </div>
                                                        </div>);
                                            })}
                                            {(alerts?.built_in_area_to && alerts?.built_in_area_to !== 0) ?
                                                <div className="col-xl-4 col-lg-6 col-sm-6 col-12 mb-6">
                                                    <div className="data-contain2 px-5 py-4 rounded-10 text-center h-auto">
                                                        <h6 className="font-14 fw-bold d-block mb-0 alert-txt">{parseInt(alerts?.built_in_area_from)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}  {t("Sqmt")} - {parseInt(alerts?.built_in_area_to)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}  {t("Sqmt")} </h6>
                                                    </div>
                                                </div>
                                                : ''
                                            }
                                            <div className="col-12 text-end">
                                                <div className="row justify-content-end">
                                                    <div className="col-lg-2 col-md-3 col-6">
                                                        <button className="btn btn-outline-primary btn-block fw-bold px-md-7 px-sm-10 py-3"
                                                            type='button'
                                                            data-bs-target='#alertCancel-popup1'
                                                            data-bs-toggle='modal'
                                                            onClick={() => setCancelId(alerts?.id_alert)}>{t("Remove")}</button>
                                                    </div>
                                                    <div className="col-lg-2 col-md-3 col-6">
                                                        <button className="btn btn-primary fw-bold btn-block px-md-7 px-sm-10 py-3" value={alerts?.id_alert} onClick={alerts?.status != 2 ? handleUnsubscribe : handleSubscribe}>{alerts?.status != 2 ? t("Unsubscribe") : t("Subscribe")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    <p>{!loading && !myAlertsList?.length ? <div className='card h-100'><div className='card-body p-7 '> <div className="row"> <NoDataFound1 /> </div> </div></div> : ''}</p>
                </div>
            </div> */}
            <div className='modal fade custom-modal' id='alertCancel-popup1'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content rounded-10'>
                        <div className='modal-header border-0 py-4'>
                            <h5 className='modal-title fw-bold'>{t("Remove Alert")}</h5>
                            <button
                                type='button'
                                className='btn-close'
                                data-bs-dismiss='modal'
                            >&times;</button>
                        </div>
                        <div className='modal-body px-10 pb-0'>
                            <div className='row mt-6'>
                                <div className='col-12'>
                                    <div className='mb-6'>
                                        <div className="text-center flex-css justify-content-center redeem-offer-QR">
                                            {/* <MdOutlineClose color='red' fontSize="3.5em" /> */}
                                            <img src={CancelImg} alt='cancel-img'/>
                                        </div>
                                        <h3 className='text-dark fw-bolder text-center flex-css justify-content-center mt-4 font-18'>{t("Are you sure?")}</h3>
                                    </div>
                                    <div className="text-center flex-css justify-content-center">
                                        <p className='font-14 text-black fw-normal'>
                                            {t("Do you really want to remove this alert? This process cannot be undone.")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer border-0 flex-css justify-content-center'>                            
                            <button
                                type='button'
                                className='btn btn-outline-primary fw-bold px-20 py-3 me-5'
                                data-bs-dismiss='modal'
                            >
                                {t("No")}
                            </button>
                            <button
                                type='button'
                                className='btn btn-primary fw-bold px-20 py-3'
                                data-bs-dismiss='modal'
                                onClick={() => handleSubmit(cancelId)}
                            >
                                {t("Yes")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <>
                <p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                    {/*  */}
                </p>
                <DynamicPopup />
            </>
        </>
    )
};

export { AlertName02 }
