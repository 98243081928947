import React, {FC, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../constants'
import * as profile from '../../constants/redux/Sagas/ProfileSaga'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import { NoDataFound1 } from '../../constants/generics/customActios'

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

const PTFaq: FC = () => {

  let faqs: any = []
  faqs = useSelector<RootState>(({Profile}) => Profile.faq)
  // console.log('🚀 ~ file: Faq.tsx ~ line 8 ~ faqs', faqs)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(profile.actions.faq())
  }, [])
  const { t } = useTranslation();

  return (
    <>
      <div className='row gy-5 g-xl-8 dashboard-page'>
        <div className='col-xxl-12 mt-0'>
          <div className='row'>
            <div className='col-md-12 col-12'>
              <h4 className='card-title font-18 text-black mb-6 fw-bolder'>
                {t("Frequently Asked Questions")}
              </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 col-12'>
              <div className=''>
                <div className=''>
                  <div className='accordion faq-accordian' id='accordionExample'>
                    {faqs
                      ? faqs?.data.map((item: any, i: any) => {
                          return (
                            <div className='accordion-item'>
                              <h2 className='accordion-header' id={item.id}>
                                <button
                                  className='accordion-button'
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target={'#collapse' + item.id} //'#collapseOne'
                                  aria-expanded='false'
                                  aria-controls={'collapse' + item.id}
                                >
                                  {item.question}
                                </button>
                              </h2>
                              <div
                                id={'collapse' + item.id}
                                className='accordion-collapse collapse border-top'
                                aria-labelledby={item.id}
                                data-bs-parent='#accordionExample'
                              >
                                <div className='accordion-body p-7' dangerouslySetInnerHTML={{__html: item.answer}}></div>
                              </div>
                            </div>
                          )
                        })
                      : <>
                        <div className='card'>
                          <div className='card-body'>
                            <div className='row'>
                              <div className='col-12'>
                                <NoDataFound1 />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>                      
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export {PTFaq}
