import {Action} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
// ** Define API endPoints **/

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

// ** Define Actions "ALL ACTIONS NAMES SHOULD BE UNIQUE!!!!" **

export const actionTypes = {
  OffersRenewlaData: '[OffersRenewlaData] Action',
  ContractsApprovalData: '[ContractsApprovalData] Action',
  PmMakeAnOfferData: '[PmMakeAnOfferData] Action',
  PmMakeAnOfferPopUpData: '[PmMakeAnOfferPopUpData] Action',
  Building: '[Building] Action',
  Pid: '[Pid] Action',
  Notif: '[Notif] Action',
  ContractDetails: '[ContractDetails] Action',
  Permissions: '[Permissions] Action',
}
// ** Define initialGeneralDatas **
const initialGeneralDatasState: IGeneralDatasState = {
  offersRenewlaData: undefined,
  contractsApprovalData: undefined,
  pmMakeAnOfferData: undefined,
  pmMakeAnOfferPopUpData: undefined,
  building: undefined,
  pid: undefined,
  notif: undefined,
  contractDetails: undefined,
  permissions: undefined,
}
export interface IGeneralDatasState {
  offersRenewlaData?: ''
  contractsApprovalData?: ''
  pmMakeAnOfferData?: ''
  pmMakeAnOfferPopUpData?: ''
  building?: ''
  pid?: ''
  notif?: ''
  contractDetails?: ''
  permissions?: ''
}
export const reducer = persistReducer(
  {storage, key: 'General', whitelist: ['building','contractDetails','premissions', 'notif']},

  (
    state: IGeneralDatasState = initialGeneralDatasState,
    action: ActionWithPayload<IGeneralDatasState>
  ) => {
    switch (action.type) {
      case actionTypes.OffersRenewlaData: {
        const offersRenewlaData = action.payload?.offersRenewlaData
        return {...state, offersRenewlaData: offersRenewlaData}
      }
      case actionTypes.ContractsApprovalData: {
        const contractsApprovalData = action.payload?.contractsApprovalData
        return {...state, contractsApprovalData: contractsApprovalData}
      }
      case actionTypes.PmMakeAnOfferData: {
        const pmMakeAnOfferData = action.payload?.pmMakeAnOfferData
        return {...state, pmMakeAnOfferData: pmMakeAnOfferData}
      }
      case actionTypes.PmMakeAnOfferPopUpData: {
        const pmMakeAnOfferPopUpData = action.payload?.pmMakeAnOfferPopUpData
        return {...state, pmMakeAnOfferPopUpData: pmMakeAnOfferPopUpData}
      }
      case actionTypes.Building: {
        const building = action.payload?.building
        return {...state, building: building}
      }
      case actionTypes.Pid: {
        const pid = action.payload?.pid
        return {...state, pid: pid}
      }
      case actionTypes.Notif: {
        const notif = action.payload?.notif
        return {...state, notif: notif}
      }
      case actionTypes.ContractDetails: {
        const contractDetails = action.payload?.contractDetails
        return {...state, contractDetails: contractDetails}
      }
      case actionTypes.Permissions: {
        const permissions = action.payload?.permissions
        return {...state, permissions: permissions}
      }
      default:
        return state
    }
  }
)

export const actions = {
  offersRenewlaData: (offersRenewlaData: any) => ({
    type: actionTypes.OffersRenewlaData,
    payload: {offersRenewlaData},
  }),
  contractsApprovalData: (contractsApprovalData: any) => ({
    type: actionTypes.ContractsApprovalData,
    payload: {contractsApprovalData},
  }),
  pmMakeAnOfferData: (pmMakeAnOfferData: any) => ({
    type: actionTypes.PmMakeAnOfferData,
    payload: {pmMakeAnOfferData},
  }),
  pmMakeAnOfferPopUpData: (pmMakeAnOfferPopUpData: any) => ({
    type: actionTypes.PmMakeAnOfferPopUpData,
    payload: {pmMakeAnOfferPopUpData},
  }),
  building: (building: any) => ({
    type: actionTypes.Building,
    payload: {building},
  }),
  pid: (pid: any) => ({
    type: actionTypes.Pid,
    payload: {pid},
  }),
  notif: (notif: any) => ({
    type: actionTypes.Notif,
    payload: {notif},
  }),
  contractDetails: (contractDetails: any) => ({
    type: actionTypes.ContractDetails,
    payload: {contractDetails},
  }),
  permissions: (permissions: any) => ({
    type: actionTypes.Permissions,
    payload: {permissions},
  }),
}

export function* GeneralSaga() {
}
