import React, { useEffect, useRef, useState } from 'react'
import CheckGreen from '../../../../assets/images/Check_green_lg.png'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getServiceRequestByData, scheduleMeetingRequest } from '../../../../constants/axios/apis'
import moment from 'moment'
import { ClickAwayListener } from '@mui/material'
import DatePicker from 'react-date-picker'
import { useTranslation } from 'react-i18next'

const today = new Date()
const startOfDay = new Date()
startOfDay.setHours(0, 0, 0, 0)

export function ScheduleMeeting({ contract }: any) {
  const [loading, setLoading] = useState<any>(false)
  const successRef = useRef<any>()
  const dismissRef = useRef<any>()
  const timeRef = useRef<any>()
  const location = useLocation()
  const { t } = useTranslation();
  // const [serviceStatus, setServiceStatus] = useState<any>(false)
  let userDetail = JSON.parse(localStorage.getItem('userDetail')!)
  let history = useHistory()

  const initialValues = {
    property_id: contract?.propertyID,
    user_unique_id: userDetail?.id,
    start_time: '',
    end_time: '',
    visit_date: today,
    title: '',
    customer_type: 1,
    customer_name: userDetail?.first_name + userDetail?.last_name,
    email: userDetail?.email,
    mobile: '',
    organization_name: '',
    schedule_type: 2,
    comments: '',
    category_id: 26,
  }

  const scheduleMeetingSchema = Yup.object().shape({
    start_time: Yup.date()
      .when('visit_date', {
        is: (val: any) => (moment(val).isSame(moment(), 'day')),
        then: Yup.date().min(Yup.ref('visit_date'), t("Start time cannot be in the past"))
      })
      .nullable()
      .required(t("Please select a visit time")),
    visit_date: Yup.date()
      .min(startOfDay, t("Visit date cannot be in the past"))
      .required(t("Please select a visit date")),
    customer_type: Yup.string().required(t("Please specify the customer type")),
    customer_name: Yup.string()
      .min(3, t("Minimum 3 symbols"))
      .max(50, t("Maximum 50 symbols"))
      .required(t("Name is required"))
      .test('filled', t("Name cannot contain only blankspaces"), (value) => {
        if (value) {
          return (
            value.trim().length !== 0
          )
        }
        return true
      })
      .matches(/^[^\s].*/, t("Name cannot start with a blankspace"))
      .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    email: Yup.string()
      .min(3, t("Minimum 3 symbols"))
      .max(50, t("Maximum 50 symbols"))
      .required(t("Email is required"))
      .test('filled', t("Email cannot contain only blankspaces"), (value) => {
        if (value) {
          return (
            value.trim().length !== 0
          )
        }
        return true
      })
      .matches(/^[^\s].*/, t("Email cannot start with a blankspace"))
      .email(t("Wrong email format")),
    comments: Yup.string().max(250, t("Maximum 250 characters")),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema: scheduleMeetingSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        scheduleMeetingRequest(
          values.property_id,
          values.user_unique_id,
          moment(values.start_time).format('YYYY-MM-DD HH:mm:ss'),
          values.end_time,
          moment(values.visit_date).format('YYYY-MM-DD'),
          values.title,
          values.customer_type,
          values.customer_name,
          values.email,
          values.mobile,
          values.organization_name,
          values.schedule_type,
          values.comments,
          values.category_id,
        )
          .then((response: any) => {
            setLoading(false)
            // refreshStatus()
            resetForm()
            dismissRef.current.click()
            successRef.current.click()
            if (location?.pathname == '/my-services/openissues') {
              history.push('/my-services/openissues')
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  // useEffect(() => {
  //   refreshStatus()
  // }, [contract])

  // const refreshStatus = () => {
  //   getServiceRequestByData(26, '', contract?.propertyID).then((response) => {
  //     if (response.data) {
  //       let data = response.data.sort((a: any, b: any) => {
  //         return b.id - a.id
  //       })
  //       if (data[0]?.status === 0) {
  //         setServiceStatus(true)
  //         formik.resetForm()
  //       } else {
  //         setServiceStatus(false)
  //       }
  //     } else {
  //       setServiceStatus(false)
  //     }
  //   })
  // }

  const handleVisitDate = (e: any) => {
    let visit_date = moment(e).set({
      'hour': moment().get('hour'),
      'minute': moment().get('minute'),
    })
      .toDate()
    formik.setFieldTouched('visit_date')
    formik.setFieldValue('visit_date', visit_date)
    timeRef.current.value = '';
    formik.setFieldValue('start_time', '')
    formik.setFieldValue('end_time', '')
  }

  const handleStartTime = (e: any) => {
    let time = moment(e.target.value, 'HH:mm')
    let startTime = moment(formik.values.visit_date).set({
      'hour': time.get('hour'),
      'minute': time.get('minute'),
    }).format('YYYY-MM-DD HH:mm:ss')

    let endTime =
      moment(startTime).format('YYYY-MM-DD') +
      ' ' +
      moment(startTime).add(0, 'seconds').add(30, 'minutes').format('HH:mm:ss')

    formik.setFieldValue('start_time', moment(startTime).toDate())
    formik.setFieldValue('end_time', endTime)
  }

  const handleClickAway = () => {
    formik.resetForm()
    timeRef.current.value = '';
  }

  return (
    <div className=''>
      {location?.pathname == '/thankyou-service' ? (
        <button
          type='button'
          className='btn btn-light w-300px font-14'
          data-bs-toggle='modal'
          data-bs-target='#schedule-meeting'
        >
          {t("Schedule Meeting")}
        </button>
      ) : (
        ''
      )}

      <ClickAwayListener onClickAway={handleClickAway}>
        <div className='modal fade custom-modal' id='schedule-meeting'>
          <div className='modal-dialog modal-lg'>
            <div className='modal-content rounded-10'>
              <div className='modal-header p-5 border-0'>
                <h4 className='modal-title'>
                  {t("Meeting Request")}
                </h4>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >&times;</button>
              </div>
              <div className='modal-body'>
                <div className='col-12'>
                  <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                  >
                    <div className='row form-add-design text-start'>
                      {/* {serviceStatus ? (
                        <p className='text-success'>A Meeting Request is already scheduled.</p>
                      ) : (
                        ''
                      )} */}
                      <div className='col-md-6 col-12'>
                        <div className='mb-10 date-picker'>
                          <label className='form-label'>
                            {t("Visit Date")}<span className='text-danger'>*</span>
                          </label>
                          <DatePicker
                            onChange={(e: any) => handleVisitDate(e)}
                            value={formik.values.visit_date}
                            minDate={new Date(new Date().toLocaleDateString())}
                            className={clsx(
                              'form-control fw-bolder form-control-solid',
                              {
                                'is-invalid': formik.touched.visit_date && formik.errors.visit_date,
                              },
                              {
                                'is-valid': formik.touched.visit_date && !formik.errors.visit_date,
                              }
                            )}
                            format='dd-MM-y'
                            clearIcon={null}
                            maxDate={new Date('12/31/2999')}
                          // disabled={serviceStatus}
                          />
                          {formik.touched.visit_date && formik.errors.visit_date && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.visit_date}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-md-6 col-12'>
                        <div className='mb-10'>
                          <label className='form-label'>
                            {t("Visit Time")}<span className='text-danger'>*</span>
                          </label>
                          <input
                            ref={timeRef}
                            type='time'
                            className={clsx(
                              'form-control fw-bolder form-control-solid',
                              {
                                'is-invalid': formik.touched.start_time && formik.errors.start_time,
                              },
                              {
                                'is-valid': formik.touched.start_time && !formik.errors.start_time,
                              }
                            )}
                            name='start_time'
                            onChange={(e) => handleStartTime(e)}
                            onBlur={formik.handleBlur}
                            placeholder=''
                          // disabled={serviceStatus}
                          />
                          {formik.touched.start_time && formik.errors.start_time && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.start_time}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-md-12 col-12'>
                        <div className='mb-10 position-relative'>
                          <h4
                            className='card-title font-18 mb-0 fw-boldest'
                            style={{ color: '#3D3D3D' }}
                          >
                            {t("Plan Visit")}
                          </h4>
                        </div>
                      </div>
                      <div className='col-md-6 col-12'>
                        <div className='mb-10'>
                          <label className='form-label'>{t("Customer Type")}<span className='text-danger'>*</span></label>
                          <select
                            data-control='select'
                            data-hide-search='true'
                            data-allow-clear='true'
                            className='form-select'
                            name='customer_type'
                            defaultValue={formik.values.customer_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          // disabled={serviceStatus}
                          >
                            <option value='1'>{t("Individual Customer")}</option>
                            <option value='2'>{t("Company")}</option>
                          </select>
                          {formik.touched.customer_type && formik.errors.customer_type && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.customer_type}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-md-6 col-12'>
                        <div className='mb-10'>
                          <label className='form-label'>
                            {t("Name of the customer")}<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='text'
                            className={clsx(
                              'form-control fw-bolder form-control-solid',
                              {
                                'is-invalid':
                                  formik.touched.customer_name && formik.errors.customer_name,
                              },
                              {
                                'is-valid':
                                  formik.touched.customer_name && !formik.errors.customer_name,
                              }
                            )}
                            name='customer_name'
                            value={formik.values.customer_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete='off'
                          // disabled={serviceStatus}
                          />
                          {formik.touched.customer_name && formik.errors.customer_name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.customer_name}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-md-6 col-12'>
                        <div className='mb-10'>
                          <label className='form-label'>
                            {t("Email ID")}<span className='text-danger'>*</span>
                          </label>
                          <input
                            placeholder='Email'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('email')}
                            className={clsx(
                              'form-control fw-bolder form-control-solid',
                              { 'is-invalid': formik.touched.email && formik.errors.email },
                              {
                                'is-valid': formik.touched.email && !formik.errors.email,
                              }
                            )}
                          // disabled={serviceStatus}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.email}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-md-12 col-12'>
                        <div className='mb-10'>
                          <label className='form-label'>{t("Comments(Optional)")}</label>
                          <textarea
                            className='form-control fw-bolder form-control-solid'
                            data-kt-autosize='true'
                            rows={4}
                            name='comments'
                            value={formik.values.comments}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          // disabled={serviceStatus}
                          />
                          {formik.touched.comments && formik.errors.comments && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.comments}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-md-12 col-12 text-end'>
                        <button
                          type='button'
                          className='btn btn-outline-primary fw-bold px-10 py-3 me-4'
                          data-bs-dismiss='modal'
                          onClick={() => formik.resetForm()}
                        >
                          {t("Cancel")}
                        </button>
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading}>
                          {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              {t("Please wait...")}{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ClickAwayListener>

      <p
        ref={dismissRef}
        data-bs-target='#schedule-meeting'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        className="m-0"
      >
        {/*  */}
      </p>

      <p
        className="m-0"
        ref={successRef}
        data-bs-target='#added-success-modal'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>

      <div className='modal fade custom-modal' id='added-success-modal'>
        <div className='modal-dialog modal-sm modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header bg-white border-0'>
              <button
                type='button'
                className='btn-close shadow'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                &times;
              </button>
            </div>
            <div className='modal-body py-10'>
              <div className='row g-5'>
                <div className='col-md-12'>
                  <div className='text-center mx-md-12'>
                    <div className='redeem-offer-QR'>
                      <img src={CheckGreen} alt='tick-img' className='img-fluid mb-5' />
                      <h1 className='text-theme font-24 mb-2 fw-bold'>{t("Done")}</h1>
                      <p className='mb-3 font-12 fw-bolder'>{t("Meeting request added successfully.")}</p>
                      <button
                        data-bs-dismiss='modal'
                        className='btn btn-primary btn-lg fw-bolder font-12 w-100'
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
