import moment from 'moment'
import { currencyFormater } from '../../../../constants/generics/customActios'
import { useTranslation } from 'react-i18next'

const languages = [
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl'
    },
];

export function ContractDetails(props: any) {
    const { contracts } = props
    const { t } = useTranslation();

    return (
        <div className='row'>
            <div className='col-12'>
                <div className='accordion-item'>
                    <h2 className='accordion-header' id='flush-headingSeven'>
                        <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#offer-details'
                        >
                            {t("Contract Details")}
                        </button>
                    </h2>
                    <div
                        id='offer-details'
                        className='accordion-collapse collapse show'
                        data-bs-parent='#accordion-step-five'
                    >
                        <div className='accordion-body pb-5 pt-0'>
                            <div className='row form-add-design'>
                                {/* <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>Contract generation Date</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {moment(contracts?.contractStart).format('DD-MM-YYYY')}
                                            </h6>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>{t("Contract Start Date")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {moment(contracts?.contractStart).format('DD-MM-YYYY')}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>{t("Contract End Date")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {moment(contracts?.contractEnd).format('DD-MM-YYYY')}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>{t("Contract No")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {contracts?.contractNo}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>{t("Occupant First Name")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {contracts?.tenantName}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>{t("Occupant Last Name")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {contracts?.tenantLastName}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>{t("Building Name")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {contracts?.buildingName}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>{t("Unit No")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {contracts?.propertyCode}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>{t("Unit Size")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {contracts?.builtInArea} {t("Sqmt")}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>Building Area</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {contracts?.builtInArea} Sqmt
                                            </h6>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>{t("Annual Rent")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {t("AED")} {currencyFormater(contracts?.annualRent)}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-md-4'>
                                    <div className='mb-10'>
                                        <label className='form-label fw-bolder'>Contract Value</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                AED {contracts?.contractValue}
                                            </h6>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='col-md-4'>
                                    <div className='mb-0'>
                                        <label className='form-label fw-bolder'>{t("Security Deposit")}</label>
                                        <div className='data-contain px-5 py-4 rounded-10'>
                                            <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                {t("AED")}  {currencyFormater(contracts?.SD)}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

