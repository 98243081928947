import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import ProfileImage from '../../assets/images/profile-av-lg.jpg'
import { OutOfOfficePopUp } from './OutOfOfficePopUp'
import { RootState } from '../../constants'
import * as profile from '../../constants/redux/Sagas/ProfileSaga'
import avatarImg from '../../assets/images//avatar.jpg'

import {
	validateMediaRequired,
    validateMediaSize,
    validateImageType,
} from '../../utils/validtionFunction';


const ProfileHeader = (props: any) => {
	const location = useLocation()
	let userName = ''
	let userNumber = ''
	let userEmail = ''
	let userDetail = localStorage.getItem('userDetail')
	if (userDetail) {
		userName = JSON.parse(userDetail).first_name + ' ' + JSON.parse(userDetail).last_name
		// userNumber = JSON.parse(userDetail).phone_code + ' ' + JSON.parse(userDetail).phone
		userNumber = JSON.parse(userDetail).phone
		userEmail = JSON.parse(userDetail).email
	}
	let profileData: any = []
	profileData = useSelector<RootState>(({ Profile }) => Profile.profile)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(profile.actions.profile())
	}, [])

	const uploadEl = props.uploadEl;
	const [profilePicDisplay, setProfilePicDisplay] = useState<any>();

	const onClickUploadProfile = () => {
        uploadEl?.current?.click();
		console.log("clicked");
    }

	/* const [profilePicUploadError, setProfilePicUploadError] = useState<string[]>(['']); */

	const profilePicUploadError = props.profilePicUploadError;
	const setProfilePicUploadError = props.setProfilePicUploadError;

	useEffect(()=>{
		setProfilePicDisplay(profileData?.data?.profile_img ? profileData?.data?.profile_img : avatarImg);
	},[profileData]);

	const onChangeProfilePic = (e:any) => {
		console.log('File on change: ',uploadEl.current?.files[0]);

		if(uploadEl.current?.files){
            setProfilePicUploadError([]);
            validateMediaSize(uploadEl.current?.files[0], setProfilePicUploadError, "Profile Picture");
            validateImageType(uploadEl.current?.files[0], setProfilePicUploadError, "Profile Picture");
            /* validateMediaRequired(uploadEl.current?.files[0], setProfilePicUploadError, "Profile Picture"); */
        }


		var oFReader = new FileReader();
		if(uploadEl.current?.files[0]) {
			if(
				uploadEl.current?.files[0]?.type === 'image/png' ||
				uploadEl.current?.files[0]?.type === 'image/jpeg' ||
				uploadEl.current?.files[0]?.type === 'image/jpg'
			){
				oFReader.readAsDataURL(uploadEl.current.files[0]);
				oFReader.onload = function (oFREvent) {
					setProfilePicDisplay(oFREvent?.target?.result);
				}
			}else{
				// console.log("Val error: ",uploadEl.current?.files);
			}
		}
		else{
			setProfilePicDisplay(profileData?.data?.profile_img ? profileData?.data?.profile_img : avatarImg);
		}
	}

	return (
		<div className='row align-items-center h-100'>
			<div className='col-md-12'>
				<div className='profile-img-card'>

					<div className=' w-100 text-center'>
						{/* <img src={profileData?.data?.profile_img ? profileData?.data?.profile_img : avatarImg} className='img-fluid rounded-circle shadow' /> */}
						<img src={profilePicDisplay} className='img-fluid rounded-circle' />
					</div>
					{!props.isEdit ? (
								<div className='mx-1 mb-4'>
								<h5 className='text-black font-16 fw-bolder mb-3 text-center mt-3'>{userName}</h5>
								<span className='font-13 text-center d-block mb-2 text-black fw-normal'>{userNumber}</span>
								<span className='font-13 text-center d-block mb-2 text-black fw-normal'>{userEmail}</span>
								{/*<select className='form-select  bg-transparent form-select-lg form-select-solid p-0 border-0' data-control='select2' data-placeholder='Select Community'>*/}
								{/*<option>Nation Towers - 21</option>*/}
								{/*<option>Nation Towers - 22</option>*/}
								{/*<option>Nation Towers - 23</option>*/}
								{/*<option>Nation Towers - 24</option>*/}
								{/*<option>Nation Towers - 25</option>*/}
								{/*</select>*/}
								<div className='mb-4'>
									{/* <div className='form-check form-switch mx-13'>
										<input className='form-check-input' type='checkbox' id='profile-check' checked />
										<label className='form-check-label text-theme fw-bolder' htmlFor='profile-check'>
											Active
										</label>
									</div> */}
								</div>


								<OutOfOfficePopUp/>
							</div>
							):
							<input
								type="file"
								className="invisible"
								ref={uploadEl}
								onChange={onChangeProfilePic}
							/>
					}

					<div className='main-profile'>
					<div className="d-flex justify-content-center">
							{/* <button
                className='border-0 btn btn-primary w-100'
                data-bs-target='#out-of-offer-popUp'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              >
                Mark Out of Office
              </button> */}
							{props.isEdit && (
								<button className='btn btn-primary my-5' onClick={onClickUploadProfile}>Upload Picture
								</button>
							)}
							
						</div>
						{!props.isEdit ? (
							<button onClick={props.onEdit} className='border-0'>
								{/* <i className='bi bi-pencil-square fs-1 text-theme text-bolder'></i> */}
								Edit Profile
							</button>
						) : (
							<button onClick={props.onDetail} className='border-0'>
								View Details
								 {/* <i className='bi bi-eye fs-1 text-theme text-bolder'></i> */}
							</button>
						)}
					</div>
				</div>
				<div className="d-flex flex-column">
					{profilePicUploadError.map((error: any, index: number)=>{
						return (
							<small className="text-danger mt-2">{error}</small>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default ProfileHeader
