import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { sendChequePaymentRequest } from '../../../../constants/axios/apis'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { Spinner } from 'react-bootstrap-v5'
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler';

const today = new Date()
today.setHours(0, 0, 0, 0)

export function PayByCheque({ details, property_id, vatPercent }: any) {
  const dispatch = useDispatch()
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const ref = useRef<any>(0)
  const history = useHistory()
  const [message, setMessage] = useState<any>()
  const [loading, setLoading] = useState(false)
  if (details === undefined) {
    history.push('/payments/all')
  }
  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldTouched('cheque_img')
  //   formik.setFieldValue('cheque_img', e.currentTarget.files[0])
  //   formik.handleChange(e)
  // }
  const { t } = useTranslation();

  const amountVal = parseFloat(details?.amount)
  const extra = details?.extra_charges_amount ? parseFloat(details.extra_charges_amount) : 0
  const vatVal = ((amountVal + extra) * vatPercent.value) / 100
  const totalVal = amountVal + extra + vatVal

  const initialValues = {
    offer_id: 0,
    property_id: '',
    payment_for: details?.payment_for,
    payment_category: details?.payment_category ? details?.payment_category : '',
    cheque_number: '',
    payment_date: today,
    acc_holder_name: '',
    acc_number: '',
    // amount: details?.extra_charges_amount ? details?.amount + details?.extra_charges_amount : details?.amount,
    amount: totalVal,
    bank: '',
    cheque_img: '',
    payment_type: '',
    upcoming_payment_id: ''
  }

  const chequeRequestSchema = Yup.object().shape({
    cheque_number: Yup.string()
      .max(23, t("Maximum 23 characters"))
      .required(t("Cheque Number is required"))
      .matches(/^[0-9]*$/, t("Only numbers allowed")),
    payment_date: Yup.date()
      .min(today, t("Payment date cannot be in the past"))
      .required(t("Please enter the payment date")),
    acc_holder_name: Yup.string()
      .max(30, t("Maximum 30 characters"))
      .required(t("Account Holder Name is required"))
      .test('filled', t("Account holder name cannot contain only blankspaces"), (value) => {
        if (value) {
          return (
            value.trim().length !== 0
          )
        }
        return true
      })
      .matches(/^[^\s].*/, t("Account holder name cannot start with a blankspace"))
      .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    acc_number: Yup.string()
      .max(13, t("Maximum 13 characters"))
      .required(t("Account Number is required"))
      .matches(/^[0-9]*$/, t("Only numbers allowed")),
    bank: Yup.string()
      .required(t("Bank Name is required"))
      .test('filled', t("Bank Name cannot contain only blankspaces"), (value) => {
        if (value) {
          return (
            value.trim().length !== 0
          )
        }
        return true
      })
      .matches(/^[^\s].*/, t("Bank Name cannot start with a blankspace"))
      .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    // cheque_img: Yup.mixed()
    //   .required(t("Image is required"))
    //   .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png"), (value) => {
    //     return (
    //       value &&
    //       (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png')
    //     )
    //   })
    //   .test('fileSize', t("The file is too large!"), (value) => {
    //     return value?.size <= 2000000
    //   }),
  })

  const formik = useFormik({
    initialValues,
    isInitialValid: false,
    validationSchema: chequeRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        sendChequePaymentRequest(
          details.offer_id,
          values.payment_for,
          values.payment_category,
          property_id,
          values.cheque_number,
          moment(values.payment_date).format('YYYY-MM-DD'),
          values.acc_holder_name,
          values.acc_number,
          values.amount,
          values.bank,
          values.cheque_img,
          details.payment_type,
          details.upcoming_payment_id,
          details.upcoming_payment_plan_id,
        )
          .then((response: any) => {
            setLoading(false)
            resetForm()
            if (response.code === 200 && response.errorCode != 1) {
              setMessage({
                error: 0,
                message: t("Payment Successful"),
              })
              ref.current.click();
            } else {
              setMessage({
                error: 1,
                message: response.errorDescription,
              })
              ref.current.click();
            }
            setTimeout(() => {
              ref.current.click();
              history.push('/payments/all')
            }, 2000)
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  useLayoutEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
      document.body.style.pointerEvents = "none";
    }
    if (!loading) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
      document.body.style.pointerEvents = "all";
    }
  }, [loading]);

  useEffect(() => {
    if (message?.message?.length > 0) {
      dispatch(PTOffer.actions.modalmessage(message))
    }
  }, [message])

  const handleDate = (e: any) => {
    formik.setFieldValue('payment_date', new Date(e))
  }

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('cheque_img', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('cheque_img')
          formik.setFieldValue('cheque_img', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('cheque_img', true, false)
          formik.setFieldValue('cheque_img', '', false)
          formik.setFieldError('cheque_img', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('cheque_img')
      formik.setFieldValue('cheque_img', '', false)
      formik.setFieldError('cheque_img', t('File upload error!'))
      setUploading(false)
    }
  }

  return (
    <><div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-md-8 px-lg-15 px-5 py-15'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='row'>
                  <div className='col-md-6 col-12 mb-md-0 mb-4'>
                    <div className='row form-add-design'>
                      <div className='col-md-12 col-12'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='mb-6 upload-input'>
                              <label className='form-label fw-bolder d-block mb-3'>{t("Cheque Image")}</label>
                              <input
                                multiple
                                ref={inputRef}
                                onChange={(e) => handleDisplayFileDetails(e)}
                                className='d-none'
                                type='file'
                                accept=".png,.jpg,.jpeg"
                                />
                              <button
                                type='button'
                                onClick={handleUpload}
                                disabled={uploading}
                                className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                                 }`}
                            >
                                {/* <span>
                                  {uploadedFileName ? (
                                    uploadedFileName
                                  ) : (
                                    <span>
                                      {t("Upload")}{' '}
                                      <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                    </span>
                                  )}{' '}
                                </span> */}
                                <span>
                                  {uploading ?
                                    <span className='indicator-progress d-block'>
                                      {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                    : uploadedFileName ? (
                                      uploadedFileName
                                    ) : (
                                      <span>
                                        {t('Upload')}{' '}
                                        <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                      </span>
                                    )}{' '}
                                </span>
                              </button>
                              {formik.touched.cheque_img && formik.errors.cheque_img && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.cheque_img}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            {/*<div className="image-input image-input-empty" data-kt-image-input="true">*/}
                            {/*<div className="image-input-wrapper w-125px h-125px"></div>*/}
                            {/*<label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"*/}
                            {/*data-kt-image-input-action="change"*/}
                            {/*data-bs-toggle="tooltip"*/}
                            {/*data-bs-dismiss="click"*/}
                            {/*title="Change avatar">*/}
                            {/*<i className="bi bi-pencil-fill fs-7"></i>*/}
                            {/*<input type="file" name="avatar" accept="image/*" />*/}
                            {/*<input type="hidden" name="avatar_remove" />*/}
                            {/*<span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"*/}
                            {/*data-kt-image-input-action="cancel"*/}
                            {/*data-bs-toggle="tooltip"*/}
                            {/*data-bs-dismiss="click"*/}
                            {/*title="Cancel avatar">*/}
                            {/*<i className="bi bi-x fs-2"></i>*/}
                            {/*</span>*/}
                            {/*<span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"*/}
                            {/*data-kt-image-input-action="remove"*/}
                            {/*data-bs-toggle="tooltip"*/}
                            {/*data-bs-dismiss="click"*/}
                            {/*title="Remove avatar">*/}
                            {/*<i className="bi bi-x fs-2"></i>*/}
                            {/*</span>*/}
                            {/*</div>*/}
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-6'>
                              <label className='form-label fw-bolder'>{t("Cheque Number")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                {...formik.getFieldProps('cheque_number')} />
                              {formik.touched.cheque_number && formik.errors.cheque_number && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.cheque_number}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-6 date-picker'>
                              <label className='form-label fw-bolder'>{t("Cheque Date")}</label>
                              <DatePicker
                                onChange={(e: any) => handleDate(e)}
                                value={formik.values.payment_date}
                                minDate={new Date(new Date().toLocaleDateString())}
                                maxDate={new Date('12/31/2999')}
                                className='form-control fw-bolder form-control-solid'
                                format='dd-MM-y'
                                clearIcon={null}
                              />
                              {formik.touched.payment_date && formik.errors.payment_date && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.payment_date}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-6'>
                              <label className='form-label fw-bolder'>{t("Account Number")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                {...formik.getFieldProps('acc_number')} />
                              {formik.touched.acc_number && formik.errors.acc_number && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.acc_number}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-6'>
                              <label className='form-label fw-bolder'>{t("Account Holder Name")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                {...formik.getFieldProps('acc_holder_name')} />
                              {formik.touched.acc_holder_name && formik.errors.acc_holder_name && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.acc_holder_name}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='mb-0'>
                              <label className='form-label fw-bolder'>{t("Bank Name")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                {...formik.getFieldProps('bank')} />
                              {formik.touched.bank && formik.errors.bank && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.bank}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-12'>
                    <div className='row justify-content-center h-100'>
                      <div className='col-lg-12 col-12'>
                        <div className='card-pay-bg h-100 rounded-10 p-5 p-md-15'>
                          <div className='row'>
                            <div className='col-12'>
                              <h5 className='text-black font-18 fw-bolder mb-8'>{t("Summary")}</h5>
                            </div>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-md-8 col-8'>
                                  <span className='text-black font-14 d-block mb-4 fw-bold'>
                                    {details?.type} {t("Amount")}
                                  </span>
                                </div>
                                <div className='col-md-4 col-4'>
                                  <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                    {t("AED")} {details?.amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                  </span>
                                </div>
                                {details?.extra_charges_title ? (
                                  <>
                                    <div className='col-md-8 col-8'>
                                      <span className='text-black font-14 d-block mb-4 fw-bold'>
                                        {details?.extra_charges_title}
                                      </span>
                                    </div>
                                    <div className='col-md-4 col-4'>
                                      <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                        {t("AED")} {details?.extra_charges_amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  ''
                                )}
                                <div className='col-md-8 col-8'>
                                  <span className='text-black font-14 d-block mb-4 fw-bold'>
                                    {t("VAT")} ({vatPercent.value}%)
                                  </span>
                                </div>
                                <div className='col-md-4 col-4'>
                                  <span className='text-black font-14 d-block mb-4 fw-boldest'>
                                    {t("AED")} {vatVal?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                  </span>
                                </div>
                              </div>
                              <hr className='my-1' />
                            </div>
                            <div className='col-12 my-4'>
                              <div className='row'>
                                <div className='col-md-8 col-8'>
                                  <span className='text-black font-14 d-block fw-bold'>{t("Total")}</span>
                                  <span className='d-block font-10 mb-2 fw-bold'>{t("(Inc. Taxes)")}</span>
                                </div>
                                <div className='col-md-4 col-4 my-auto'>
                                  <span className='text-black font-14 d-block mb-2 fw-boldest'>
                                    {/* {t("AED")} {details?.total?.toLocaleString(undefined, { maximumFractionDigits: 2 })} */}
                                    {t("AED")} {totalVal?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className='col-12'>
                              <button
                                // data-bs-target='#pay-suc-popup'
                                data-bs-toggle={!formik.isValid ? '' : 'modal'}
                                type='submit'
                                disabled={uploading || vatPercent.loading}
                                className='btn btn-primary w-100 fw-bold px-10 py-3'
                              >{loading && <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true" />}
                                {loading || vatPercent.loading ? t("Loading...") : t("Confirm Payment")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div><p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
        {/*  */}
      </p></>
  )
}
