import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  earlyTerminationRequest,
  getMasterData,
  getServiceRequestByData,
} from '../../../../constants/axios/apis'
import moment from 'moment'
import DatePicker from 'react-date-picker'
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsPopup } from '../../../terms-and-conditions/TermsAndConditionsPopup'
import { useDispatch } from 'react-redux'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'
import uploadImg from '../../../../assets/images/file-upload.svg'

const today = new Date()
today.setHours(0, 0, 0, 0)

export function EarlyTerminationForm({ contract, setMessage }: any) {
  const [loading, setLoading] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [terminationReasons, setTerminationReasons] = useState<any>()
  const [serviceStatus, setServiceStatus] = useState<Boolean>(false)
  const [messages, setMessages] = useState<any>()
  const history = useHistory()
  const dispatch = useDispatch()
  const closeRef = useRef<any>()
  const ref = useRef<any>(0)
  const { t } = useTranslation();

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldValue('photo', e.currentTarget.files[0])
  // }

  useEffect(() => {
    getMasterData().then(({ data }: any) => {
      setTerminationReasons(data.find((item: any) => item.id === 11)?.master_data)
    })
  }, [])

  useEffect(() => {
    getServiceRequestByData(3, '', contract?.propertyID).then((response) => {
      if (response.data) {
        setServiceStatus(true)
      } else {
        setServiceStatus(false)
      }
    })
  }, [contract])

  const terminationOptions = terminationReasons?.map((option: any) => (
    <option key={option.value} value={option.value}>
      {option.name}
    </option>
  ))

  const initialValues = {
    contract_id: contract?.id,
    property_id: contract?.propertyID,
    category_id: 3,
    contract_start_date: contract?.contractStartDate,
    contract_end_date: contract?.contractEndDate,
    annual_rent: contract?.rent,
    early_termination_date: today,
    early_termination_reason: '',
    early_termination_fees: (contract?.rent / 12) * 2,
    terms: false,
    photo: '',
  }

  const earlyTerminationRequestSchema = Yup.object().shape({
    early_termination_date: Yup.date()
      .min(today, t("Termination Date cannot be in the past"))
      .required(t("Please pick your early termination date")),
    early_termination_reason: Yup.number().required(t("Choose an early termination reason")),
    terms: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
    photo: Yup.mixed()
      .required(t("Supporting document is required"))
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf"), (value) => {
        if (!value) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'image/png' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
  })

  const formik = useFormik({
    initialValues,
    isInitialValid: false,
    validationSchema: earlyTerminationRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        earlyTerminationRequest(
          values.contract_id,
          values.property_id,
          values.category_id,
          values.contract_start_date,
          values.contract_end_date,
          values.annual_rent,
          moment(values.early_termination_date).format('YYYY-MM-DD'),
          values.early_termination_reason,
          values.early_termination_fees,
          values.terms,
          values.photo
        )
          .then((response: any) => {
            if (response != 500) {
              setMessage(t("Your Early Termination Request has been Submitted!"))
              setLoading(false)
              resetForm()
              setUploadedFileName(null)
              formik.setFieldValue('terms', false)
              formik.setFieldValue('photo', null)
              closeRef.current.click()
              history.push('/my-services/openissues')
            } else {
              setMessages({
                error: 1,
                message: 'Something went wrong!',
              })
              ref.current.click()
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const handleTerminationDate = (e: any) => {
    formik.setFieldTouched('early_termination_date')
    formik.setFieldValue('early_termination_date', new Date(e))
  }
  useEffect(() => {
    if (messages?.message?.length > 0) {
      dispatch(PTOffer.actions.modalmessage(messages))
    }
  }, [messages])

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {    
    if(e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf'){      
      inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)        
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', e.currentTarget.files[0])
    }else{
      setUploading(true)
      formik.setFieldError('photo', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
            formik.setFieldTouched('photo', true, false)
            formik.setFieldValue('photo', compressResponse.file, false)
          } else {
            setUploadedFileName(null)
            formik.setFieldTouched('photo', true, false)
            formik.setFieldValue('photo', '', false)
            formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedFileName(null)
        formik.setFieldTouched('photo')
        formik.setFieldValue('photo', '', false)
        formik.setFieldError('photo', t('File upload error!'))
        setUploading(false)
      }
    }
  }

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-5'>
              <div className='card-body px-8 py-6'>
                <div className='row'>
                  <div className='col-md-12 col-12'>
                    <h4 className='card-title mb-3'>{t("Contract Details")}</h4>
                    {serviceStatus ? (
                      <p className='text-success'>{t("Early Termination Letter was already submitted!")}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='kt_login_signup_form'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='row form-add-design mt-10'>
                    {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 position-relative'>
                        <label className='form-label fw-bolder'>Lease Start Date</label>
                        <input
                          className='form-control fw-bolder form-control-solid'
                          type='text'
                          value={moment(contract?.contractStartDate).format('DD-MM-YYYY')}
                          disabled
                        />
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 position-relative'>
                        <label className='form-label fw-bolder'>Lease End Date</label>
                        <input
                          className='form-control fw-bolder form-control-solid'
                          type='text'
                          value={moment(contract?.contractEndDate).format('DD-MM-YYYY')}
                          disabled
                        />
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 position-relative'>
                        <label className='form-label fw-bolder'>Annual Rent</label>
                        <input
                          className='form-control fw-bolder form-control-solid'
                          type='tel'
                          value={contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          disabled
                        />
                      </div>
                    </div> */}
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 position-relative date-picker'>
                        <label className='form-label fw-bolder'>{t("Early Termination Date")}<span className='text-danger'>*</span></label>
                        {/* <input
                          className={clsx(
                            'form-control fw-bolder form-control-solid',
                            {
                              'is-invalid':
                                formik.touched.early_termination_date &&
                                formik.errors.early_termination_date,
                            },
                            {
                              'is-valid':
                                formik.touched.early_termination_date &&
                                !formik.errors.early_termination_date,
                            }
                          )}
                          type='date'
                          max='9999-12-31'
                          {...formik.getFieldProps('early_termination_date')}
                          placeholder=''
                          style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                          disabled={serviceStatus ? true : false}
                        /> */}
                        <DatePicker
                          onChange={(e: any) => handleTerminationDate(e)}
                          value={formik.values.early_termination_date}
                          className='form-control fw-bolder form-control-solid'
                          format='dd-MM-y'
                          clearIcon={null}
                          minDate={moment(contract?.contractStartDate).toDate()}
                          maxDate={moment(contract?.contractEndDate).toDate()}
                          disabled={serviceStatus ? true : false}
                        />
                        {formik.touched.early_termination_date &&
                          formik.errors.early_termination_date && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.early_termination_date}
                                </span>
                              </div>
                            </div>
                          )}
                        {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 position-relative'>
                        <label className='form-label fw-bolder'>{t("Early Termination Reason")}<span className='text-danger'>*</span></label>
                        <select
                          className='form-control form-select fw-bolder form-control-solid'
                          name='early_termination_reason'
                          onChange={formik.handleChange}
                          value={formik.values.early_termination_reason}
                          onBlur={formik.handleBlur}
                          style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                          disabled={serviceStatus ? true : false}
                        >
                          <option value='' disabled>
                            {t("Select")}
                          </option>
                          {terminationReasons ? terminationOptions : ''}
                        </select>
                        {formik.touched.early_termination_reason &&
                          formik.errors.early_termination_reason && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.early_termination_reason}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-4 col-12'>
                      <div className='mb-10 upload-input'>
                        <label className='form-label fw-bolder d-block'>{t("Supporting Document")}<span className='text-danger'>*</span></label>
                        <input
                          ref={inputRef}
                          onChange={(e) => handleDisplayFileDetails(e)}
                          className='d-none'
                          type='file'
                          // onBlur={formik.handleBlur}
                          style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                          disabled={serviceStatus || uploading  ? true : false}
                        />
                        <button
                          type='button'
                          onClick={handleUpload}
                          className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                            }`}
                          style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                          disabled={serviceStatus ? true : false}
                        >
                          {/* <span>
                            {uploadedFileName ? (
                              uploadedFileName
                            ) : (
                              <span className="txt">
                                <img
                                        alt="File"
                                        src={uploadImg}
                                className="position-relative"
                                loading="lazy"/>
                                {t("Upload")}{' '}
                              </span>
                            )}{' '}
                          </span> */}
                          {uploading ?
                            <span className='indicator-progress d-block'>
                              {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            : uploadedFileName ? (
                              uploadedFileName
                            ) : (
                              <span className="txt">
                                <img
                                        alt="File"
                                        src={uploadImg}
                                className="position-relative"
                                loading="lazy"/>
                                {t("Upload")}{' '}
                              </span>
                            )}{' '}
                        </button>
                        {formik.touched.photo && formik.errors.photo && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.photo}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 position-relative'>
                        <label className='form-label fw-bolder'>
                          {t("Early Termination Fee (*2 Months of Rent)")}
                        </label>
                        <input
                          type='text'
                          className='form-control fw-bolder form-control-solid'
                          value={initialValues.early_termination_fees?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' AED'}
                          placeholder=''
                          disabled
                        />
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12 my-auto'>
                      <div className='mb-4'>
                        <div className='form-check custom'>
                          <input
                            className='form-check-input'
                            {...formik.getFieldProps('terms')}
                            checked={formik.values.terms}
                            type='checkbox'
                            id='flexCheckDefault'
                            onBlur={formik.handleBlur}
                            style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                            disabled={serviceStatus ? true : false}
                          />
                          <label
                            className='form-check-label font-14 text-black fw-bolder'
                            htmlFor='flexCheckDefault'
                          >
                            {t("Accept")} 	&nbsp;
                            {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
                              {t("Terms & Conditions")}
                            </Link> */}
                            <button
                              data-bs-target="#terms-conditions-popup"
                              data-bs-toggle='modal'
                              className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                              {t("Terms & Conditions")}
                            </button>
                            <span className='text-danger'>*</span>
                          </label>
                        </div>
                        {formik.touched.terms && formik.errors.terms && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.terms}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-12 text-end'>
                      {serviceStatus ? (
                        <div
                          className='btn btn-secondary fw-bold px-10 py-3'
                          style={{ cursor: 'not-allowed' }}
                          title={'Early Termination Letter was already submitted!'}
                        >
                          {t("Submit")}
                        </div>
                      ) : (
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading || uploading}>
                          {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              {t("Please wait...")}{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <p
          ref={closeRef}
          data-bs-target='#req-submitted-popup'
          data-bs-toggle='modal'
          data-bs-dismiss='modal'
        >
          {/*  */}
        </p>
      </div>
      <TermsAndConditionsPopup /><p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
        {/*  */}
      </p>
    </>
  )
}
