import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {Redirect, Route, Switch} from 'react-router-dom'
import {MyVisitTabs} from './MyVisitTabs/MyVisitTabs'
import {UpcomingCard} from './UpcomingProperties/UpcomingCard'
import {ReschedulePopup} from './ReschedulePopup/ReschedulePopup'

export function MyVisit(props: any) {
  const location = useLocation()
  const {visits, upcoming} = props

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3 movein-moveout'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <Switch>
                    <Route path='/pt-activity/my-visit/upcoming'>
                      <MyVisitTabs />
                      <UpcomingCard data={upcoming} />
                      <ReschedulePopup />
                    </Route>
                    <Route path='/pt-activity/my-visit/visited'>
                      <MyVisitTabs />
                      <UpcomingCard data={visits} />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
