import React, { FC, useEffect, useRef, useState } from 'react';
import { ApplicantsInformation } from './ApplicantsInformation';
import { SafetyAndSecurityPrecautions } from './SafetyAndSecurityPrecautions';
import { UploadRequiredDocuments } from './UploadRequiredDocuments';
import { WorkInformation } from './WorkInformation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../constants';
import { generalPermitToWork } from '../../../../../../constants/axios/apis';
import { useTranslation } from 'react-i18next'

type Props = {
    masterData?: any
    getStepStatus?: any
    stepStatus?: any
}

const PermitToWork: FC<Props> = ({ masterData, getStepStatus, stepStatus }) => {
    const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
    const [loading, setLoading] = useState<any>(false)
    const [workInformation, setWorkInformation] = useState<any>()
    const [applicantInformation, setApplicantInformation] = useState<any>()
    const [safetyPrecatuions, setSafetyPrecatuions] = useState<any>()
    const [documents, setDocuments] = useState<any>()
    const [isSubmitable, setIsSubmitable] = useState<any>(false)
    const { t } = useTranslation();

    const ref1 = useRef<any>()
    const ref2 = useRef<any>()
    const ref3 = useRef<any>()

    const handelFinalSubmit = () => {

        if (workInformation?.saved && applicantInformation?.saved && safetyPrecatuions?.saved && documents?.saved) {
            setLoading(true)
            setIsSubmitable(false)
            var FormData = require('form-data')
            var data = new FormData()
            data.append('contract_id', currentContract?.id)
            data.append('property_id', currentContract?.propertyID)
            data.append('category_id', 35)
            data.append('category_type_id', 49)
            data.append('building_name', applicantInformation.building_name)
            data.append('unit_number', applicantInformation.unit_number)
            data.append('applicant_name', applicantInformation.applicant_name)
            data.append('applicant_company_name', applicantInformation.applicant_company_name)
            data.append('applicant_contact_number', applicantInformation.applicant_contact_number)
            data.append('sub_contractor', applicantInformation.sub_contractor)
            data.append('start_date', workInformation.start_date)
            data.append('end_date', workInformation.end_date)
            data.append('start_time', workInformation.start_time)
            data.append('end_time', workInformation.end_time)
            data.append('description', workInformation.description)
            workInformation.permit_type_ids.forEach((permit_id: any, index: any) => {
                data.append(`permit_type_id[${[index]}]`, permit_id)
            });
            safetyPrecatuions.precautions_ids.forEach((precaution_id: any, index: any) => {
                data.append(`safety_prec[${[index]}]`, precaution_id)
            });
            data.append('undertaking_letter', documents.undertaking_letter)
            data.append('risk_assessment', documents.risk_assessment)
            data.append('local_authority_approval', documents.local_authority_approval)
            data.append('emirates_id', documents.emirates_id)
            data.append('trade_license', documents.trade_license)
            data.append('method_of_statement', documents.method_of_statement)
            data.append('crew_list', documents.crew_list)
            data.append('other_documents', documents.other_documents)
            data.append('permit_applicant_name', documents.permit_applicant)
            data.append('terms', documents.terms)
            data.append('signature', documents.signature)

            setTimeout(() => {
                generalPermitToWork(data)
                    .then((response: any) => {
                        // setSubmitting(false)
                        if (response.errorCode === 1) {
                            // setSubmitError(response.errorDescription)
                            // setTimeout(() => {
                            // 	setSubmitError('')
                            // }, 5000)
                        } else if (response.errorCode === 0) {
                            // setSubmitting(false)
                            getStepStatus()
                        } else {
                            // setSubmitError("Some error occured")
                            console.log(response, 'response');
                            // setTimeout(() => {
                            // 	setSubmitError('')
                            // }, 5000)
                        }
                        setLoading(false)
                        setIsSubmitable(false)
                    })
                    .catch((e: any) => {
                        console.log(e, 'Error');
                        // setSubmitting(false)
                        // setSubmitError('Some error occured!')
                        // setTimeout(() => {
                        // 	setSubmitError('')
                        // }, 5000)
                    })
            }, 1000)

        } else {

        }
    }

    useEffect(() => {
        if (workInformation?.saved && applicantInformation?.saved && safetyPrecatuions?.saved && documents?.saved) {
            setIsSubmitable(true)
        } else {
            setIsSubmitable(false)
        }
    }, [workInformation, applicantInformation, safetyPrecatuions, documents])

    return (
        <div className="accordion move-out-accordion" id="accordion-step-five">
            <ApplicantsInformation setApplicantInformation={setApplicantInformation} stepStatus={stepStatus} ref1={ref1} />
            <WorkInformation setWorkInformation={setWorkInformation} contract={currentContract} stepStatus={stepStatus} ref1={ref1} ref2={ref2} />
            <SafetyAndSecurityPrecautions setSafetyPrecatuions={setSafetyPrecatuions} masterData={masterData} stepStatus={stepStatus} ref2={ref2} ref3={ref3} />
            <UploadRequiredDocuments setDocuments={setDocuments} stepStatus={stepStatus} ref3={ref3} />

            {!stepStatus?.permitToWorkRequestStatus &&
                <div className="col-12 text-right">
                    <div className="row justify-content-end">
                        <div className="col-12">
                            <div className="d-flex justify-content-end">
                                <button type='button' onClick={handelFinalSubmit} disabled={!isSubmitable} className='btn btn-primary fw-bold px-10 py-3'>
                                    {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {t("Please wait...")}{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export { PermitToWork }
