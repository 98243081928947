import React, { useState, FC, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import completedStep from '../../../../../../assets/images/completed-step.png'
import { getServiceRequestsCategories } from '../../../../../../constants/axios/apis';
import moment from 'moment';
import { useTranslation } from 'react-i18next'

type Props = {
    setWorkInformation?: any
    contract?: any
    stepStatus?: any
    ref1?: any
    ref2?: any
}

const today = new Date()
today.setHours(0, 0, 0, 0)

const WorkInformation: FC<Props> = ({ setWorkInformation, contract, stepStatus, ref1, ref2 }) => {
    const [permitTypes, setPermitTypes] = useState<any>([])
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const { t } = useTranslation();
    let userDetail = localStorage.getItem('userDetail')

    useEffect(() => {
        getServiceRequestsCategories(2, contract, userDetail).then((response) => {
            let permit_types = response.data
                .filter((item: any) => item.id === 35)      // Get only Permit To Work sub services
                .flatMap((data: any) => data.service_category_types)
                .filter((item: any) => item.id !== 52 && item.id !== 53)    // Filter out Fire Impairment & Access to Rooftop services
            setPermitTypes(permit_types)
        })
    }, [])

    const initialValues = {
        start_date: today,
        end_date: today,
        start_time: '',
        end_time: '',
        description: '',
        permit_type_ids: [],
    }

    const handelSubmit = () => {
        setSaved(false)
        setWorkInformation(null)
        formik.handleSubmit()
    }

    const workInformationSchema = Yup.object().shape({
        permit_type_ids: Yup.array().test('array', t("Must choose at least one type of permit"), (value: any) => {
            return (
                value &&
                value.length > 0
            )
        }),
        start_date: Yup.date()
            .min(today, t("Starting date cannot be in the past"))
            .required(t("Please select a starting date")),
        end_date: Yup.date()
            .min(Yup.ref('start_date'), t("Ending date cannot be before starting date"))
            .required(t("Please select an ending date")),
        start_time: Yup.string().required(t("Please select a starting time")),
        end_time: Yup.string().required(t("Please select an ending time"))
            .when('start_time', {
                is: (val: string) => (val && val.length > 0 ? true : false),
                then: Yup.string().notOneOf([Yup.ref('start_time')], t("Start time and end time cannot be the same")),
            }),
        description: Yup.string().required(t("Please provide a description of work")),
    })

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: workInformationSchema,
        onSubmit: (values) => {
            setWorkInformation({
                start_date: moment(values.start_date).format('YYYY-MM-DD'),
                end_date: moment(values.end_date).format('YYYY-MM-DD'),
                start_time: values.start_time,
                end_time: values.end_time,
                description: values.description,
                permit_type_ids: values.permit_type_ids,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref2.current.click()
        },
    })

    const handleStartDate = (e: any) => {
        formik.setFieldTouched('start_date')
        formik.setFieldValue('start_date', new Date(e))
    }

    const handleEndDate = (e: any) => {
        formik.setFieldTouched('end_date')
        formik.setFieldValue('end_date', new Date(e))
    }

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setWorkInformation((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    return (
        <div className="row">
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
            >
                <div className="col-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSeven">
                            <button className="accordion-button collapsed" ref={ref1} type="button" data-bs-toggle="collapse" data-bs-target="#work-information">
                                {t("Work Information")}
                                {(saved) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </h2>
                        <div id="work-information" className="accordion-collapse collapse" data-bs-parent="#accordion-step-five">
                            {stepStatus?.permitToWorkRequestStatus ? (
                                <>
                                    <div className="accordion-body pb-5 pt-0">
                                        <div className="row form-add-design">
                                            {permitTypes ? permitTypes?.map((type: any) => (
                                                <div className="col-md-4 col-12" key={type?.id}>
                                                    <div className="form-check custom mb-8">
                                                        <input
                                                            className='form-check-input'
                                                            name='permit_type_ids'
                                                            value={type?.id}
                                                            type='checkbox'
                                                            id={type?.id}
                                                            disabled={true}
                                                            checked={stepStatus?.data?.permitToWork?.details?.permit_type_id?.includes(String(type?.id))}
                                                        />
                                                        <label className="form-check-label font-14 text-black fw-bolder mx-2" htmlFor={type?.id}>
                                                            {type?.name}
                                                        </label>
                                                    </div>
                                                </div>
                                            )) : (
                                                ''
                                            )}
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">

                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7 date-picker">
                                                    <label className="form-label fw-bolder"> {t("Work Start Date")} </label>
                                                    <input
                                                        type='text'
                                                        className={'form-control bg-white fw-bolder form-control-solid'}
                                                        value={moment(stepStatus?.data?.permitToWork?.details?.start_date).format('DD-MM-YYYY')}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7 date-picker">
                                                    <label className="form-label fw-bolder"> {t("Work End Date")} </label>
                                                    <input
                                                        type='text'
                                                        className={'form-control bg-white fw-bolder form-control-solid'}
                                                        value={moment(stepStatus?.data?.permitToWork?.details?.end_date).format('DD-MM-YYYY')}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Work Start Time")} </label>
                                                    <input
                                                        type='text'
                                                        className={'form-control bg-white fw-bolder form-control-solid'}
                                                        value={moment(stepStatus?.data?.permitToWork?.details?.start_time, 'HH:mm').format('HH:mm a')}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Work End Time")} </label>
                                                    <input
                                                        type='text'
                                                        className={'form-control bg-white fw-bolder form-control-solid'}
                                                        value={moment(stepStatus?.data?.permitToWork?.details?.end_time, 'HH:mm').format('HH:mm a')}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12">
                                                <div className="mb-7">

                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Complete Description of Work")} </label>
                                                    <textarea
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        data-kt-autosize='true'
                                                        name='description'
                                                        rows={2}
                                                        value={stepStatus?.data?.permitToWork?.details?.description}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="accordion-body pb-5 pt-0">
                                        <div className="row form-add-design">
                                            {permitTypes ? permitTypes?.map((type: any) => (
                                                <div className="col-md-4 col-12" key={type?.id}>
                                                    <div className="form-check custom mb-8">
                                                        <input
                                                            className='form-check-input'
                                                            name='permit_type_ids'
                                                            value={type?.id}
                                                            type='checkbox'
                                                            id={type?.id}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <label className="form-check-label font-14 text-black fw-bolder mx-2" htmlFor={type?.id}>
                                                            {type?.name}
                                                        </label>
                                                    </div>
                                                </div>
                                            )) : (
                                                ''
                                            )}
                                            {formik.touched.permit_type_ids && formik.errors.permit_type_ids && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.permit_type_ids}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">

                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7 date-picker">
                                                    <label className="form-label fw-bolder"> {t("Work Start Date")} </label>
                                                    <DatePicker
                                                        onChange={(e: any) => handleStartDate(e)}
                                                        value={formik.values.start_date}
                                                        minDate={new Date(new Date().toLocaleDateString())}
                                                        className={'form-control fw-bolder form-control-solid'}
                                                        format='dd-MM-y'
                                                        clearIcon={null}
                                                        maxDate={new Date('12/31/2999')}
                                                    />
                                                    {formik.touched.start_date && formik.errors.start_date && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.start_date}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7 date-picker">
                                                    <label className="form-label fw-bolder"> {t("Work End Date")} </label>
                                                    <DatePicker
                                                        onChange={(e: any) => handleEndDate(e)}
                                                        value={formik.values.end_date}
                                                        minDate={new Date(new Date().toLocaleDateString())}
                                                        className={'form-control fw-bolder form-control-solid'}
                                                        format='dd-MM-y'
                                                        clearIcon={null}
                                                        maxDate={new Date('12/31/2999')}
                                                    />
                                                    {formik.touched.end_date && formik.errors.end_date && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.end_date}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Work Start Time")} </label>
                                                    <input
                                                        type='time'
                                                        className={'form-control bg-white fw-bolder form-control-solid'}
                                                        {...formik.getFieldProps('start_time')}
                                                        placeholder=''
                                                    />
                                                    {formik.touched.start_time && formik.errors.start_time && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.start_time}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Work End Time")} </label>
                                                    <input
                                                        type='time'
                                                        className={'form-control bg-white fw-bolder form-control-solid'}
                                                        {...formik.getFieldProps('end_time')}
                                                        placeholder=''
                                                    />
                                                    {formik.touched.end_time && formik.errors.end_time && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.end_time}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-12">
                                                <div className="mb-7">

                                                </div>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <div className="mb-7">
                                                    <label className="form-label fw-bolder"> {t("Complete Description of Work")} </label>
                                                    <textarea
                                                        className='form-control bg-white fw-bolder form-control-solid'
                                                        data-kt-autosize='true'
                                                        name='description'
                                                        rows={2}
                                                        placeholder='Type here...'
                                                        value={formik.values.description}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.description && formik.errors.description && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                <span role='alert' className='text-danger'>
                                                                    {formik.errors.description}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 text-right">
                                                <div className="row justify-content-end">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end">
                                                            <button type='button' onClick={handelSubmit} disabled={!dirty} className='btn btn-primary fw-bold px-10 py-3'>
                                                                {saved ? t("Saved") : t("Save")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export { WorkInformation }
