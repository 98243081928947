// import {Link} from 'react-router-dom'

export default [
    {
        id: 1,
        no: "01",
        issue: "NOC for Utilities",
        ticket: "REQ00100",
        description: "ADDC Bill Issue",
        created: "15-07-2023",
        status: "Closed",
        replies: "ADDC Bill Issue",
        // action: "-"
    },
    {
        id: 2,
        no: "01",
        issue: "NOC for Title Deed",
        ticket: "REQ00112",
        description: "Emirates ID Expired",
        created: "21-07-2023",
        status: "Closed",
        replies: "Emirates ID Expired",
        // action: "-"
    },
];
