import React, { useState, useEffect, useRef } from 'react'
import PlusIcon from '../../../../assets/images/plus-circle.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getMyDocs } from '../../../../constants/redux/Sagas/ProspectiveTenant/PTDocumentsSaga'
import { Link } from 'react-router-dom'
import { updateContractActivity } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

export function UploadDocuments({ contractActivity, contractActivityUpdated }: any) {
  const [loading, setLoading] = useState<any>(false)
  const [uploadedEmiratesIdFrontName, setUploadedEmiratesIdFrontName] = useState<any>(null)
  const [uploadedEmiratesIdBackName, setUploadedEmiratesIdBackName] = useState<any>(null)
  const [uploadedPassportName, setUploadedPassportName] = useState<any>(null)
  const [uploadedVisaName, setUploadedVisaName] = useState<any>(null)
  const [uploadedLetterOfIntentName, setUploadedLetterOfIntentName] = useState<any>(null)
  const [uploadedAddcBillName, setUploadedAddcBillName] = useState<any>(null)

  const emiratesIdFrontInputRef = useRef<HTMLInputElement>(null)
  const emiratesIdBackInputRef = useRef<HTMLInputElement>(null)
  const passportInputRef = useRef<HTMLInputElement>(null)
  const visaInputRef = useRef<HTMLInputElement>(null)
  const letterOfIntentInputRef = useRef<HTMLInputElement>(null)
  const AddcBillInputRef = useRef<HTMLInputElement>(null)

  const [myDocs, setMyDocs] = useState<any>()
  const closeRef = useRef<any>()
  const { t } = useTranslation();

  var emirates_front: any
  var emirates_back: any
  var passport: any
  var visa: any
  var letter_of_intent: any
  var addc_bill: any

  useEffect(() => {
    getMyDocs().then(({ data }: any) => {
      if (data.code === 200 && data.errorCode != 1) {
        setMyDocs(data.data)
      }
    })
  }, [])

  if (myDocs?.length > 0) {
    passport = myDocs.find((obj: any) => obj.document_type === 'passport')
    emirates_front = myDocs.find((obj: any) => obj.document_type === 'emirates_front')
    emirates_back = myDocs.find((obj: any) => obj.document_type === 'emirates_back')
    visa = myDocs.find((obj: any) => obj.document_type === 'visa')
    letter_of_intent = myDocs.find((obj: any) => obj.document_type === 'letter_of_intent')
    addc_bill = myDocs.find((obj: any) => obj.document_type === 'addc_bill')
  }

  // const handleEmiratesIdFrontUpload = () => {
  //   emiratesIdFrontInputRef.current?.click()
  // }
  // const handleEmiratesIdBackUpload = () => {
  //   emiratesIdBackInputRef.current?.click()
  // }

  // const handlePassportUpload = () => {
  //   passportInputRef.current?.click()
  // }

  // const handleVisaUpload = () => {
  //   visaInputRef.current?.click()
  // }

  // const handleLetterOfIntentUpload = () => {
  //   letterOfIntentInputRef.current?.click()
  // }

  // const handleAddcBilltUpload = () => {
  //   AddcBillInputRef.current?.click()
  // }

  const initialValues = {
    emirates_front: '',
    emirates_back: '',
    passport: '',
    visa: '',
    letter_of_intent: '',
    addc_bill: '',
  }

  const documentVerficationSchema = Yup.object().shape({
    emirates_front: Yup.mixed()
      .required(t("Front image of your Emirates ID is required"))
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    emirates_back: Yup.mixed()
      .required(t("Back image of your Emirates ID is required"))
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    passport: Yup.mixed()
      .required(t("Your Passport image is required"))
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    visa: Yup.mixed()
      .required(t("Your Visa image is required"))
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
        if (value === 0) return true
        if (value) {
          return (
            value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf'
          )
        }
        return true
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    letter_of_intent: Yup.mixed()
      .required(t("Please upload the Letter of Intent"))
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
        if (value === 0) return true
        if (value) {
          return (
            value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf'
          )
        }
        return true
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
    addc_bill: Yup.mixed()
      .required(t("ADDC Bill is required"))
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: documentVerficationSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)

      var FormData = require('form-data')
      var data = new FormData()
      if (formik.values.emirates_front != '0') {
        data.append('emirates_front', values.emirates_front)
      }
      if (formik.values.emirates_back != '0') {
        data.append('emirates_back', values.emirates_back)
      }
      if (formik.values.passport != '0') {
        data.append('passport', values.passport)
      }
      if (formik.values.visa != '0') {
        data.append('visa', values.visa)
      }
      if (formik.values.letter_of_intent != '0') {
        data.append('letter_of_intent', values.letter_of_intent)
      }
      if (formik.values.addc_bill != '0') {
        data.append('addc_bill', values.addc_bill)
      }

      data.append('contract_activity_id', contractActivity.id)
      data.append('status', contractActivity.type == 0 ? 4 : 13)

      setTimeout(() => {
        updateContractActivity(data)
          .then((response: any) => {
            setLoading(false)
            contractActivityUpdated()
            closeRef.current.click()
            console.log(response)
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  // const handleDisplayEmiratesIdFrontDetails = (e: any) => {
  //   formik.setFieldTouched('emirates_front')
  //   emiratesIdFrontInputRef.current?.files &&
  //     setUploadedEmiratesIdFrontName(emiratesIdFrontInputRef.current.files[0].name)
  //   formik.setFieldValue('emirates_front', e.currentTarget.files[0])
  // }

  // const handleDisplayEmiratesIdBackDetails = (e: any) => {
  //   formik.setFieldTouched('emirates_back')
  //   emiratesIdBackInputRef.current?.files &&
  //     setUploadedEmiratesIdBackName(emiratesIdBackInputRef.current.files[0].name)
  //   formik.setFieldValue('emirates_back', e.currentTarget.files[0])
  // }

  // const handleDisplayPassportDetails = (e: any) => {
  //   formik.setFieldTouched('passport')
  //   passportInputRef.current?.files &&
  //     setUploadedPassportName(passportInputRef.current.files[0].name)
  //   formik.setFieldValue('passport', e.currentTarget.files[0])
  // }

  // const handleDisplayVisaDetails = (e: any) => {
  //   formik.setFieldTouched('visa')
  //   visaInputRef.current?.files && setUploadedVisaName(visaInputRef.current.files[0].name)
  //   formik.setFieldValue('visa', e.currentTarget.files[0])
  // }

  // const handleDisplayLetterOfIntentDetails = (e: any) => {
  //   formik.setFieldTouched('letter_of_intent')
  //   letterOfIntentInputRef.current?.files &&
  //     setUploadedLetterOfIntentName(letterOfIntentInputRef.current.files[0].name)
  //   formik.setFieldValue('letter_of_intent', e.currentTarget.files[0])
  // }

  // const handleDisplayAdccBillDetails = (e: any) => {
  //   formik.setFieldTouched('addc_bill')
  //   AddcBillInputRef.current?.files &&
  //     setUploadedAddcBillName(AddcBillInputRef.current.files[0].name)
  //   formik.setFieldValue('addc_bill', e.currentTarget.files[0])
  // }

  useEffect(() => {
    if (emirates_front) {
      formik.setFieldValue('emirates_front', 0)
    }
    if (emirates_back) {
      formik.setFieldValue('emirates_back', 0)
    }
    if (passport) {
      formik.setFieldValue('passport', 0)
    }
    if (visa) {
      formik.setFieldValue('visa', 0)
    }
    if (letter_of_intent) {
      formik.setFieldValue('letter_of_intent', 0)
    }
    if (addc_bill) {
      formik.setFieldValue('addc_bill', 0)
    }
  }, [emirates_front, emirates_back, passport, visa, letter_of_intent, addc_bill])

  // Emirates Front
  const [uploading, setUploading] = useState(false)
  const handleEmiratesIdFrontUpload = () => {
    emiratesIdFrontInputRef.current?.click()
  }

  const handleDisplayEmiratesIdFrontDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      emiratesIdFrontInputRef.current?.files && setUploadedEmiratesIdFrontName(emiratesIdFrontInputRef.current.files[0].name)
      formik.setFieldTouched('emirates_front')
      formik.setFieldValue('emirates_front', e.currentTarget.files[0])
    } else {
      setUploading(true)
      formik.setFieldError('emirates_front', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            emiratesIdFrontInputRef.current?.files && setUploadedEmiratesIdFrontName(compressResponse.file.name);
            formik.setFieldTouched('emirates_front', true, false)
            formik.setFieldValue('emirates_front', compressResponse.file, false)
          } else {
            setUploadedEmiratesIdFrontName(null)
            formik.setFieldTouched('emirates_front', true, false)
            formik.setFieldValue('emirates_front', '', false)
            formik.setFieldError('emirates_front', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedEmiratesIdFrontName(null)
        formik.setFieldTouched('emirates_front')
        formik.setFieldValue('emirates_front', '', false)
        formik.setFieldError('emirates_front', t('File upload error!'))
        setUploading(false)
      }
    }
  }
  // Emirates Back
  const [uploading1, setUploading1] = useState(false)
  const handleEmiratesIdBackUpload = () => {
    emiratesIdBackInputRef.current?.click()
  }

  const handleDisplayEmiratesIdBackDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      emiratesIdBackInputRef.current?.files && setUploadedEmiratesIdBackName(emiratesIdBackInputRef.current.files[0].name)
      formik.setFieldTouched('emirates_back')
      formik.setFieldValue('emirates_back', e.currentTarget.files[0])
    } else {
      setUploading1(true)
      formik.setFieldError('emirates_back', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            emiratesIdBackInputRef.current?.files && setUploadedEmiratesIdBackName(compressResponse.file.name);
            formik.setFieldTouched('emirates_back', true, false)
            formik.setFieldValue('emirates_back', compressResponse.file, false)
          } else {
            setUploadedEmiratesIdBackName(null)
            formik.setFieldTouched('emirates_back', true, false)
            formik.setFieldValue('emirates_back', '', false)
            formik.setFieldError('emirates_back', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading1(false)
        })
      } catch (error) {
        setUploadedEmiratesIdBackName(null)
        formik.setFieldTouched('emirates_back')
        formik.setFieldValue('emirates_back', '', false)
        formik.setFieldError('emirates_back', t('File upload error!'))
        setUploading1(false)
      }
    }
  }


  // Passport Front
  const [uploading2, setUploading2] = useState(false)
  const handlePassportUpload = () => {
    passportInputRef.current?.click()
  }

  const handleDisplayPassportDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      passportInputRef.current?.files && setUploadedPassportName(passportInputRef.current.files[0].name)
      formik.setFieldTouched('passport')
      formik.setFieldValue('passport', e.currentTarget.files[0])
    } else {
      setUploading2(true)
      formik.setFieldError('passport', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            passportInputRef.current?.files && setUploadedPassportName(compressResponse.file.name);
            formik.setFieldTouched('passport', true, false)
            formik.setFieldValue('passport', compressResponse.file, false)
          } else {
            setUploadedPassportName(null)
            formik.setFieldTouched('passport', true, false)
            formik.setFieldValue('passport', '', false)
            formik.setFieldError('passport', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading2(false)
        })
      } catch (error) {
        setUploadedPassportName(null)
        formik.setFieldTouched('passport')
        formik.setFieldValue('passport', '', false)
        formik.setFieldError('passport', t('File upload error!'))
        setUploading2(false)
      }
    }
  }

  // Visa
  const [uploading4, setUploading4] = useState(false)
  const handleVisaUpload = () => {
    visaInputRef.current?.click()
  }

  const handleDisplayVisaDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      visaInputRef.current?.files && setUploadedVisaName(visaInputRef.current.files[0].name)
      formik.setFieldTouched('visa')
      formik.setFieldValue('visa', e.currentTarget.files[0])
    } else {
      setUploading4(true)
      formik.setFieldError('visa', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            visaInputRef.current?.files && setUploadedVisaName(compressResponse.file.name);
            formik.setFieldTouched('visa', true, false)
            formik.setFieldValue('visa', compressResponse.file, false)
          } else {
            setUploadedVisaName(null)
            formik.setFieldTouched('visa', true, false)
            formik.setFieldValue('visa', '', false)
            formik.setFieldError('visa', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading4(false)
        })
      } catch (error) {
        setUploadedVisaName(null)
        formik.setFieldTouched('visa')
        formik.setFieldValue('visa', '', false)
        formik.setFieldError('visa', t('File upload error!'))
        setUploading4(false)
      }
    }
  }

  // Letter of Intend Details 
  const [uploading5, setUploading5] = useState(false)
  const handleLetterOfIntentUpload = () => {
    letterOfIntentInputRef.current?.click()
  }

  const handleDisplayLetterOfIntentDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      letterOfIntentInputRef.current?.files && setUploadedLetterOfIntentName(letterOfIntentInputRef.current.files[0].name)
      formik.setFieldTouched('letter_of_intent')
      formik.setFieldValue('letter_of_intent', e.currentTarget.files[0])
    } else {
      setUploading5(true)
      formik.setFieldError('letter_of_intent', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            letterOfIntentInputRef.current?.files && setUploadedLetterOfIntentName(compressResponse.file.name);
            formik.setFieldTouched('letter_of_intent', true, false)
            formik.setFieldValue('letter_of_intent', compressResponse.file, false)
          } else {
            setUploadedLetterOfIntentName(null)
            formik.setFieldTouched('letter_of_intent', true, false)
            formik.setFieldValue('letter_of_intent', '', false)
            formik.setFieldError('letter_of_intent', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading5(false)
        })
      } catch (error) {
        setUploadedLetterOfIntentName(null)
        formik.setFieldTouched('letter_of_intent')
        formik.setFieldValue('letter_of_intent', '', false)
        formik.setFieldError('letter_of_intent', t('File upload error!'))
        setUploading5(false)
      }
    }
  }

  // ADDC 
  const [uploading6, setUploading6] = useState(false)
  const handleAddcBilltUpload = () => {
    AddcBillInputRef.current?.click()
  }

  const handleDisplayAdccBillDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      AddcBillInputRef.current?.files && setUploadedAddcBillName(AddcBillInputRef.current.files[0].name)
      formik.setFieldTouched('addc_bill')
      formik.setFieldValue('addc_bill', e.currentTarget.files[0])
    } else {
      setUploading6(true)
      formik.setFieldError('addc_bill', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            AddcBillInputRef.current?.files && setUploadedAddcBillName(compressResponse.file.name);
            formik.setFieldTouched('addc_bill', true, false)
            formik.setFieldValue('addc_bill', compressResponse.file, false)
          } else {
            setUploadedAddcBillName(null)
            formik.setFieldTouched('addc_bill', true, false)
            formik.setFieldValue('addc_bill', '', false)
            formik.setFieldError('addc_bill', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading6(false)
        })
      } catch (error) {
        setUploadedAddcBillName(null)
        formik.setFieldTouched('addc_bill')
        formik.setFieldValue('addc_bill', '', false)
        formik.setFieldError('addc_bill', t('File upload error!'))
        setUploading6(false)
      }
    }
  }

  return (
    <div className='modal fade custom-modal' id='upload-documents-preview'>
      <div className='modal-dialog modal-dialog-scrollable'>
        <div className='modal-content rounded-10'>
          <div className='modal-body'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='row'>
                <div className='col-12'>
                  <h6 className='font-14 text-black fw-bold mb-3'>{t("Upload Documents")}</h6>
                  <p className='font-15 text-normal fw-bold mb-6'>{t("Documents to Upload")}</p>
                </div>
                <div className='col-6'>
                  <div className='mb-5 upload-input bg-white'>
                    <label className='form-label text-black font-14 fw-bold d-block mb-3'>
                      {' '}
                      {t("Emirates ID (Front)")}{' '}
                    </label>
                    <input
                      ref={emiratesIdFrontInputRef}
                      onChange={(e) => handleDisplayEmiratesIdFrontDetails(e)}
                      className='d-none'
                      type='file'
                    />
                    <button
                      onClick={handleEmiratesIdFrontUpload}
                      // className={`w-100 py-3 mydoc_bt_file bg-white ellipsis btn-outline-${
                      //   uploadedEmiratesIdFrontName ? 'primary active' : 'primary'
                      // }`}
                      disabled={uploading}
                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedEmiratesIdFrontName ? 'primary' : 'primary'
                        }`}
                      type='button'
                    >
                      {/* <span>
                        {' '}
                        {uploadedEmiratesIdFrontName ? (
                          uploadedEmiratesIdFrontName
                        ) : (
                          <img src={PlusIcon} className='img-fluid va-baseline' />
                        )}{' '}
                      </span> */}
                      {uploading ?
                        <span className='indicator-progress d-block'>
                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        : uploadedEmiratesIdFrontName ? (
                          uploadedEmiratesIdFrontName
                        ) : (
                          <span>
                            {t("Upload")}{' '}
                            <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                          </span>
                        )}{' '}
                    </button>
                    <div className='col-12'>
                      <div className='doc-view-bt mt-n9'>
                        <div className='row'>
                          <div className='col-4 text-center'>
                            {emirates_front && emirates_front instanceof Object ? (
                              <Link
                                to={{ pathname: emirates_front.filename }}
                                target='_blank'
                                className='border-0 bg-transparent'
                              >
                                <i className='bi bi-eye text-black font-17'></i>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {formik.touched.emirates_front && formik.errors.emirates_front && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.emirates_front}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-6'>
                  <div className='mb-5 upload-input bg-white'>
                    <label className='form-label text-black font-14 fw-bold d-block mb-3'>
                      {' '}
                      {t("Emirates ID (Back)")}{' '}
                    </label>
                    <input
                      ref={emiratesIdBackInputRef}
                      onChange={(e) => handleDisplayEmiratesIdBackDetails(e)}
                      className='d-none'
                      type='file'
                    />
                    <button
                      onClick={handleEmiratesIdBackUpload}
                      // className={`w-100 py-3 mydoc_bt_file bg-white ellipsis btn-outline-${
                      //   uploadedEmiratesIdBackName ? 'primary active' : 'primary'
                      // }`}
                      disabled={uploading1}
                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedEmiratesIdBackName ? 'primary' : 'primary'
                        }`}
                      type='button'
                    >
                      {/* <span>
                        {' '}
                        {uploadedEmiratesIdBackName ? (
                          uploadedEmiratesIdBackName
                        ) : (
                          <img src={PlusIcon} className='img-fluid va-baseline' />
                        )}{' '}
                      </span> */}
                      {uploading1 ?
                        <span className='indicator-progress d-block'>
                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        : uploadedEmiratesIdBackName ? (
                          uploadedEmiratesIdBackName
                        ) : (
                          <span>
                            <img src={PlusIcon} className='img-fluid va-baseline' />
                          </span>
                        )}{' '}
                    </button>
                    <div className='col-12'>
                      <div className='doc-view-bt mt-n9'>
                        <div className='row'>
                          <div className='col-4 text-center'>
                            {emirates_back && emirates_back instanceof Object ? (
                              <Link
                                to={{ pathname: emirates_back.filename }}
                                target='_blank'
                                className='border-0 bg-transparent'
                              >
                                <i className='bi bi-eye text-black font-17'></i>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {formik.touched.emirates_back && formik.errors.emirates_back && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.emirates_back}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-6'>
                  <div className='mb-5 upload-input bg-white'>
                    <label className='form-label text-black font-14 fw-bold d-block mb-3'>
                      {' '}
                      {t("Passport")}{' '}
                    </label>
                    <input
                      ref={passportInputRef}
                      onChange={(e) => handleDisplayPassportDetails(e)}
                      className='d-none'
                      type='file'
                    />
                    <button
                      onClick={handlePassportUpload}
                      // className={`w-100 py-3 mydoc_bt_file bg-white ellipsis btn-outline-${
                      //   uploadedPassportName ? 'primary active' : 'primary'
                      // }`}
                      disabled={uploading2}
                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedPassportName ? 'primary' : 'primary'
                        }`}
                      type='button'
                    >
                      {/* <span>
                        {' '}
                        {uploadedPassportName ? (
                          uploadedPassportName
                        ) : (
                          <img src={PlusIcon} className='img-fluid va-baseline' />
                        )}{' '}
                      </span> */}
                      {uploading2 ?
                        <span className='indicator-progress d-block'>
                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        : uploadedPassportName ? (
                          uploadedPassportName
                        ) : (
                          <span>
                            <img src={PlusIcon} className='img-fluid va-baseline' />
                          </span>
                        )}{' '}
                    </button>
                    <div className='col-12'>
                      <div className='doc-view-bt mt-n9'>
                        <div className='row'>
                          <div className='col-4 text-center'>
                            {passport && passport instanceof Object ? (
                              <Link
                                to={{ pathname: passport.filename }}
                                target='_blank'
                                className='border-0 bg-transparent'
                              >
                                <i className='bi bi-eye text-black font-17'></i>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formik.touched.passport && formik.errors.passport && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.passport}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-6'>
                  <div className='mb-5 upload-input bg-white'>
                    <label className='form-label text-black font-14 fw-bold d-block mb-3'>
                      {' '}
                      {t("Visa")}{' '}
                    </label>
                    <input
                      ref={visaInputRef}
                      onChange={(e) => handleDisplayVisaDetails(e)}
                      className='d-none'
                      type='file'
                    />
                    <button
                      onClick={handleVisaUpload}
                      // className={`w-100 py-3 mydoc_bt_file bg-white ellipsis btn-outline-${uploadedVisaName ? 'primary active' : 'primary'
                      //   }`}
                      disabled={uploading4}
                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedVisaName ? 'primary' : 'primary'
                        }`}
                      type='button'
                    >
                      {/* <span>
                        {' '}
                        {uploadedVisaName ? (
                          uploadedVisaName
                        ) : (
                          <img src={PlusIcon} className='img-fluid va-baseline' />
                        )}{' '}
                      </span> */}
                      {uploading4 ?
                        <span className='indicator-progress d-block'>
                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        : uploadedVisaName ? (
                          uploadedVisaName
                        ) : (
                          <span>
                            <img src={PlusIcon} className='img-fluid va-baseline' /></span>
                        )}{' '}
                    </button>
                    <div className='col-12'>
                      <div className='doc-view-bt mt-n9'>
                        <div className='row'>
                          <div className='col-4 text-center'>
                            {visa && visa instanceof Object ? (
                              <Link
                                to={{ pathname: visa.filename }}
                                target='_blank'
                                className='border-0 bg-transparent'
                              >
                                <i className='bi bi-eye text-black font-17'></i>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formik.touched.visa && formik.errors.visa && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.visa}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-6'>
                  <div className='mb-7 upload-input bg-white'>
                    <label className='form-label text-black font-14 fw-bold d-block mb-3'>
                      {' '}
                      {t("Letter of Intent")}{' '}
                    </label>
                    <input
                      ref={letterOfIntentInputRef}
                      onChange={(e) => handleDisplayLetterOfIntentDetails(e)}
                      className='d-none'
                      type='file'
                    />
                    <button
                      onClick={handleLetterOfIntentUpload}
                      // className={`w-100 py-3 mydoc_bt_file bg-white ellipsis btn-outline-${uploadedLetterOfIntentName ? 'primary active' : 'primary'
                      //   }`}
                      type='button'
                      disabled={uploading5}
                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedLetterOfIntentName ? 'primary' : 'primary'
                        }`}
                    >
                      {/* <span>
                        {' '}
                        {uploadedLetterOfIntentName ? (
                          uploadedLetterOfIntentName
                        ) : (
                          <img src={PlusIcon} className='img-fluid va-baseline' />
                        )}{' '}
                      </span> */}
                      {uploading5 ?
                        <span className='indicator-progress d-block'>
                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        : uploadedLetterOfIntentName ? (
                          uploadedLetterOfIntentName
                        ) : (
                          <span>
                            <img src={PlusIcon} className='img-fluid va-baseline' />
                          </span>
                        )}{' '}
                    </button>
                    <div className='col-12'>
                      <div className='doc-view-bt mt-n9'>
                        <div className='row'>
                          <div className='col-4 text-center'>
                            {letter_of_intent && letter_of_intent instanceof Object ? (
                              <Link
                                to={{ pathname: letter_of_intent.filename }}
                                target='_blank'
                                className='border-0 bg-transparent'
                              >
                                <i className='bi bi-eye text-black font-17'></i>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {formik.touched.letter_of_intent && formik.errors.letter_of_intent && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.letter_of_intent}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-6'>
                  <div className='mb-7 upload-input bg-white'>
                    <label className='form-label text-black font-14 fw-bold d-block mb-3'>
                      {' '}
                      {t("ADDC Bill")}{' '}
                    </label>
                    <input
                      ref={AddcBillInputRef}
                      onChange={(e) => handleDisplayAdccBillDetails(e)}
                      className='d-none'
                      type='file'
                    />
                    <button
                      onClick={handleAddcBilltUpload}
                      // className={`w-100 py-3 mydoc_bt_file bg-white ellipsis btn-outline-${uploadedAddcBillName ? 'primary active' : 'primary'
                      //   }`}
                      disabled={uploading6}
                      className={`w-100 py-3 ellipsis btn btn-outline-${uploadedAddcBillName ? 'primary' : 'primary'
                        }`}
                      type='button'
                    >
                      {/* <span>
                        {' '}
                        {uploadedAddcBillName ? (
                          uploadedAddcBillName
                        ) : (
                          <img src={PlusIcon} className='img-fluid va-baseline' />
                        )}{' '}
                      </span> */}
                      {uploading6 ?
                        <span className='indicator-progress d-block'>
                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        : uploadedAddcBillName ? (
                          uploadedAddcBillName
                        ) : (
                          <span>
                            <img src={PlusIcon} className='img-fluid va-baseline' />
                          </span>
                        )}{' '}
                    </button>
                    <div className='col-12'>
                      <div className='doc-view-bt mt-n9'>
                        <div className='row'>
                          <div className='col-4 text-center'>
                            {addc_bill && addc_bill instanceof Object ? (
                              <Link
                                to={{ pathname: addc_bill.filename }}
                                target='_blank'
                                className='border-0 bg-transparent'
                              >
                                <i className='bi bi-eye text-black font-17'></i>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {formik.touched.addc_bill && formik.errors.addc_bill && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.addc_bill}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12'>
                  <button
                    type='submit'
                    className='btn btn-primary fw-bold px-10 py-3 w-100'
                    disabled={loading || uploading || uploading1 || uploading2 || uploading4 || uploading5 || uploading6}
                  >
                    {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {t("Please wait...")}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#sign-successful-preview'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}
