import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import SignatureCanvas from 'react-signature-canvas';
import { vehicleRegistrationRequest } from '../../../../../../constants/axios/apis'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsPopup } from '../../../../../terms-and-conditions/TermsAndConditionsPopup'

export function VehicleRegistrationForm({ slot, property_id, getStepStatus, stepStatus }: any) {
  const [loading, setLoading] = useState(false)
  const sigCanvas = useRef<any>();
  const handelSubmit = () => {
    formik.handleSubmit()
  }
  const initialValues = {
    category_id: 9,
    property_id: property_id,
    parking_slot: slot.id,
    brand: '',
    model: '',
    color: '',
    regno: '',
    status: false,
    signature: '',
  }
  const { t } = useTranslation();

  const vehicleRegistrationRequestSchema = Yup.object().shape({
    brand: Yup.string().required(t("Please enter your vehicle's brand")),
    model: Yup.string().required(t("Please enter your vehicle's model")),
    color: Yup.string().required(t("Please enter your vehicle's color")),
    regno: Yup.string().required(t("Please enter your vehicle's registration number")),
    status: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
    signature: Yup.string().required(t("Please add your signature")),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleRegistrationRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        vehicleRegistrationRequest(
          values.property_id,
          values.category_id,
          values.parking_slot,
          values.brand,
          values.model,
          values.color,
          values.regno,
          values.signature
        )
          .then((response: any) => {
            // setSubmitting(false)
            if (response.errorCode === 1) {
              // setSubmitError(response.errorDescription)
              // setTimeout(() => {
              // 	setSubmitError('')
              // }, 5000)
            } else if (response.errorCode === 0) {
              // setSubmitting(false)
              getStepStatus()
            } else {
              // setSubmitError("Some error occured")
              console.log(response, 'response');
              // setTimeout(() => {
              // 	setSubmitError('')
              // }, 5000)
            }
            setLoading(false)
          })
          .catch((e: any) => {
            console.log(e, 'Error');
            // setSubmitting(false)
            // setSubmitError('Some error occured!')
            // setTimeout(() => {
            // 	setSubmitError('')
            // }, 5000)
          })
      }, 1000)
    },
  })

  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    }, 'image/png')
  }

  function clearSignature() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }

  return (
    <>
      <div className="accordion-item" key={slot?.id}>
        <h2 className="accordion-header" id="flush-headingFour">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour">
            <span className="active"></span>
            {slot?.slotNumber}
          </button>
        </h2>
        {/* <div id="flush-collapseFour" className={`accordion-collapse collapse ${slots?.length === 1 ? 'show' : ''}`} data-bs-parent="#accordion-step-inner-one"> */}
        <div id="flush-collapseFour" className={`accordion-collapse collapse ''`} data-bs-parent="#accordion-step-inner-one">
          <div className="accordion-body pb-5 pt-0">
            <div className="row form-add-design">
              <div className="col-12">
                <h5 className="text-black fw-bolder font-14 mb-7">
                {t(" Vehicle Details")}
                </h5>
              </div>
              {stepStatus.vehicleRegistrationStatus ? (
                <>
                  <div className="col-md-4 col-12">
                    <div className="mb-5">
                      <label className="form-label fw-bolder"> {t("Brand")} </label>
                      <input
                        type='text'
                        value={stepStatus?.data?.vehicleDetails?.details?.brand}
                        className='form-control fw-bolder form-control-solid'
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="mb-5">
                      <label className="form-label fw-bolder"> {t("Model")} </label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        value={stepStatus?.data?.vehicleDetails?.details?.model}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="mb-5">
                      <label className="form-label fw-bolder"> {t("Color")} </label>
                      <input
                        type='text'
                        value={stepStatus?.data?.vehicleDetails?.details?.color}
                        className='form-control fw-bolder form-control-solid'
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="mb-5">
                      <label className="form-label fw-bolder"> {t("Registered Plate Number")} </label>
                      <input
                        type='text'
                        value={stepStatus?.data?.vehicleDetails?.details?.regno}
                        className='form-control fw-bolder form-control-solid'
                        disabled={true}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-4 col-12">
                    <div className="mb-5">
                      <label className="form-label fw-bolder"> {t("Brand")} </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('brand')}
                        className='form-control fw-bolder form-control-solid'
                        placeholder=''
                        disabled={true}
                      />
                      {formik.touched.brand && formik.errors.brand && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.brand}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="mb-5">
                      <label className="form-label fw-bolder"> {t("Model")} </label>
                      <input
                        type='text'
                        className='form-control fw-bolder form-control-solid'
                        {...formik.getFieldProps('model')}
                        placeholder=''
                        disabled={true}
                      />
                      {formik.touched.model && formik.errors.model && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.model}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="mb-5">
                      <label className="form-label fw-bolder"> {t("Color")} </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('color')}
                        className='form-control fw-bolder form-control-solid'
                        disabled={true}
                      />
                      {formik.touched.color && formik.errors.color && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.color}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="mb-5">
                      <label className="form-label fw-bolder"> {t("Registered Plate Number")} </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('regno')}
                        className='form-control fw-bolder form-control-solid'
                        disabled={true}
                      />
                      {formik.touched.regno && formik.errors.regno && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.regno}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 text-right my-auto">
                    <div className="row justify-content-end">
                      <div className="col-12">
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="form-check custom mx-5">
                            {/* <input className="form-check-input" type="checkbox" id="flexCheckDefaultTwo" /> */}
                            <input
                              className='form-check-input'
                              {...formik.getFieldProps('status')}
                              checked={formik.values.status}
                              type='checkbox'
                              id='flexCheckDefaultTwo'
                              onBlur={formik.handleBlur}
                            />
                            <label className="form-check-label font-14 text-black fw-bolder" htmlFor="flexCheckDefaultTwo">
                              {t("Accept")} 	&nbsp;
                              {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
                                {t("Terms & Conditions")}
                              </Link> */}
                              <button
                                  data-bs-target="#terms-conditions-popup"
                                  data-bs-toggle='modal'
                                  className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                                  {t("Terms & Conditions")}
                              </button>
                              <span className='text-danger'>*</span>
                            </label>
                            {formik.touched.status && formik.errors.status && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.status}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            <button data-bs-toggle="modal" data-bs-target="#signature-modal2" className='btn btn-primary fw-bold px-10 py-3'>
                              {t("Signature")}
                            </button>
                            {formik.touched.signature && formik.errors.signature && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.signature}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal fade redeem-offer signature-modal" id="signature-modal2">
                    <div className="modal-dialog modal-xs modal-dialog-centered">
                      <div className="modal-content rounded-10">
                        <div className="modal-header rounded-10 border-0">
                          <h5 className="modal-title font-18 fw-bold">{t("Add your Signature")}</h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-8">
                          <SignatureCanvas
                            ref={sigCanvas}
                            penColor='black'
                            canvasProps={{ height: 180, width: 480, className: 'sigCanvas' }}
                          />
                        </div>
                        <div className="modal-footer p-0 m-0">
                          <div className="row w-100 g-0 m-0">
                            <div className="col-12">
                              <button type="button" className="btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0" onClick={clearSignature}>{t("Reset")}</button>
                            </div>
                            <div className="col-12">
                              <button type="button" onClick={handleSignature} className="btn btn-primary fw-bold px-10 py-5 w-100 d-block" data-bs-toggle='modal'
                                data-bs-target='#ParkingSuccessModal' data-bs-dismiss="modal">{t("Submit")}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right mt-3">
                    <div className="row justify-content-end">
                      <div className="col-12">
                        <div className="d-flex justify-content-end">
                          <button type='button' onClick={handelSubmit} disabled={loading} className='btn btn-primary fw-bold px-10 py-3'>
                            {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                            {loading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                {t("Please wait...")}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <TermsAndConditionsPopup />
    </>
  )
}
