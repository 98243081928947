import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import DatePicker from 'react-date-picker'
import moment from 'moment'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

export function PaymentsFilter({ contract, filterPayments, setHasFilters, setLoading }: any) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const { t } = useTranslation();

  const initialValues = {
    start_date: moment(contract?.contractCreatedAt).startOf('day').toDate(),
    end_date: today,
  }

  const statementDatesSchema = Yup.object().shape({
    start_date: Yup.date()
      .required(t("Please select a starting date")),
    end_date: Yup.date()
      .min(Yup.ref('start_date'), t("Ending date cannot be before starting date"))
      .required(t("Please select an ending date")),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: statementDatesSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        filterPayments(moment(values.start_date, 'YYYY-MM-DD').format('YYYY-MM-DD'), moment(values.end_date).format('YYYY-MM-DD'))
        setHasFilters(true)
      }, 1000)
    },
  })

  const resetFilter = () => {
    filterPayments()
    formik.setFieldValue('start_date', contract ? moment(contract?.contractCreatedAt).startOf('day').toDate() : today)
    formik.setFieldValue('end_date', today)
  }

  const handleStartDate = (e: any) => {
    formik.setFieldTouched('end_date')
    formik.setFieldValue('start_date', new Date(e))
  }

  const handleEndDate = (e: any) => {
    formik.setFieldTouched('end_date')
    formik.setFieldValue('end_date', new Date(e))
  }

  useEffect(() => {
    if (formik.isValid && contract && (formik.touched.start_date || formik.touched.end_date)) {
      formik.submitForm()
    }
  }, [formik.isValid, formik.values.start_date, formik.values.end_date, contract])

  return (
    <div className='com-card-section'>
      <div className='row align-items-center mb-6'>
        <div className='col-md-6 col-6'>
          <h4 className='card-title mb-0'>{t("Payments")}</h4>
        </div>
        <div className='col-md-6 col-6 text-end'>
          <Link
                  to={'/payments/statement'}
          type='submit'
          className='btn btn-theme fw-bold px-8 py-3 font-14 '
          >
          {t("Statement")}
        </Link>
      </div>
    </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body px-8 py-6'>

              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='inspectionForm'
                onSubmit={formik.handleSubmit}
              >
                <div className='row form-add-design mt-5'>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-md-0 mb-4 position-relative date-picker'>
                      <label className='form-label fw-bolder'>{t("Start Date")}</label>
                      <DatePicker
                        onChange={(e: any) => handleStartDate(e)}
                        value={formik.values.start_date}
                        className='form-control fw-bolder form-control-solid'
                        format='dd-MM-y'
                        clearIcon={null}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-md-0 mb-4 position-relative date-picker'>
                      <label className='form-label fw-bolder'>{t("End Date")}</label>
                      <DatePicker
                        onChange={(e: any) => handleEndDate(e)}
                        value={formik.values.end_date}
                        className='form-control fw-bolder form-control-solid'
                        format='dd-MM-y'
                        clearIcon={null}
                      />
                      {formik.touched.end_date && formik.errors.end_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.end_date}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12 text-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary fw-bold px-10 py-3 mt-8 reset-btn'
                      onClick={() => resetFilter()}
                    >
                      <span>{t("Reset")}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
