import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {SignatureModal} from "./SignatureModal/SignatureModal";
import { useTranslation } from 'react-i18next'

export function SignedContract({type}: any) {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className="row align-items-center">
                                <div className="col-lg-9 col-md-8 col-12">
                                    <h4 className="font-16 fw-bold text-normal">{t("Contract")} {type} {t("Request Accept")}</h4>
                                    <p className="mb-0 text-normal font-12">{t("The contract signature is pending click sign contact button")} </p>
                                </div>
                                <div className="col-lg-3 col-md-4 col-12">
                                    <Link to={''} className='btn btn-primary w-100' data-bs-target='#signature-modal-preview'
                                          data-bs-toggle='modal'
                                          data-bs-dismiss='modal'>{t("Sign Contract")}</Link>
                                </div>
                                <SignatureModal />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



