import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import { formatDateTimeMeridiem } from '../../../../constants/generics/dateTimeFormater'

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function OpenIssues({ issues }: any) {
  const [issueData, setIssueData] = useState<any>([])
  const { t } = useTranslation();

  const columns = [
    // {
    //   field: 'id',
    //   headerName: 'No',
    //   sortable: false,
    //   disableColumnMenu: true,
    //   width: 100,
    // },
    {
      field: 'ticket',
      headerName: t("Ticket No"),
      width: 200,
      renderCell: (params: any) => {
        return (
          <Link to={{ pathname: '/maintenance/issues-view/' + params.row.id }}>
            <span className='view-option text-theme fw-bolder'>{params.row.ticket}</span>
          </Link>
        )
      },
    },
    {
      field: 'categoryType',
      headerName: t("Issue"),
      width: 300,
    },
    {
      field: 'description',
      headerName: t("Description"),
      width: 200,
      renderCell: (params: any) => {
        return params.row?.description?.description &&
          params.row?.description?.description.length > 25
          ? params.row?.description?.description.slice(0, 25).split(' ').slice(0, -1).join(' ') +
          '...'
          : params.row?.description?.description
      },
    },
    {
      field: 'time',
      headerName: t("Date & Time"),
      width: 180,
      renderCell: (params: any) => {
        return formatDateTimeMeridiem(params.row.time)
        // return moment(params.row.created).format('DD-MM-YYYY hh:mm')
      },
    },
    {
      field: 'status',
      headerName: t("Status"),
      className: 'text-danger',
      disableColumnMenu: true,
      width: 150,
      //   renderCell: () => {
      //     return <span className='new-theme'>New</span>
      //   },
    },
    // {
    //   field: 'replies',
    //   headerName: 'Replies',
    //   sortable: false,
    //   disableColumnMenu: true,
    //   width: 100,
    // },
    {
      field: 'action',
      headerName: t("Action"),
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (params: any) => {
        return (
          <Link to={{ pathname: '/maintenance/issues-view/' + params.row.id }}>
            <span className='view-option text-theme fw-bolder'>{t("View")}</span>
          </Link>
        )
      },
    },
  ]

  useEffect(() => {
    if (issues) {
      setIssueData(
        issues?.map((issue: any) => {
          return { ...issue, status: t("Open"), time: issue.appointmentDate + ' ' + issue.time }
        })
      )
    }
  }, [issues])

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <div className='data_table'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='table-responsive'>
                          <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                              //   rows={TableData}
                              rows={issueData}
                              getRowId={(row) => row.ticket}
                              columns={columns}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
