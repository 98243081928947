import React, { useState, useEffect } from 'react';
import {TeamUpcomingComponent} from './UpcomingComponent/UpcomingComponent'

export const UpcomingList: React.FC<any> = ({searchBroker,searchProperty}) => {
    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12 pt-6">
                    <div className="upcoming_main_div">
                       <TeamUpcomingComponent searchBroker={searchBroker} searchProperty={searchProperty}/>
                    </div>
                </div>
            </div>
        </div>
    );
}