import React, { useState, useEffect, useRef } from 'react'
import PlusIcon from '../../../../assets/images/plus-circle.svg'
import { Alert, Spinner } from 'react-bootstrap-v5'
import { ViewPopup } from '../ViewPopup/ViewPopup'
import { getMyDocs, uploadMyDocs, deleteMyDocs } from '../../../../constants/axios/apis'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'
import eyeIcon from '../../../../assets/images/eye-icon.svg';
import checkBoxIcon from '../../../../assets/images/check-box-icon.svg';
import uploadImg from '../../../../assets/images/Image_login_1.png';

export function EmiratesID() {
	const location = useLocation()
	const [myDocs, setMyDocs] = useState<any>()
	const [uploadedFileFrnt, setUploadedFileFrnt] = useState<any>()
	const [uploadedFileBack, setUploadedFileBack] = useState<any>()
	const [viewImage, setViewImage] = useState<any>()
	const [show, setShow] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const inputRefFrnt = useRef<any>(0)
	const inputRefBack = useRef<any>(0)
	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')
	var eIdFrontImage : any = ''
	var eIdBackImage : any = ''
	const { t } = useTranslation();

	const [uploading1, setUploading1] = useState(false)
	const [uploading2, setUploading2] = useState(false)
	const [editing1, setEditing1] = useState(false)
	const [editing2, setEditing2] = useState(false)

	// ** Get uploaded docs - getMyDocs()
	useEffect(() => {
		setLoading(true)
		getMyDocs().then((data) => {
			if (data.code === 200 && data.errorCode != 1) {
				setLoading(false)
				setMyDocs(data)
			} else {
				setLoading(false)
			}
		})
	}, [])
	if (myDocs?.data?.length > 0) {
		eIdFrontImage = myDocs.data.find((obj: any) => obj.document_type === 'emirates_front')
		eIdBackImage = myDocs.data.find((obj: any) => obj.document_type === 'emirates_back')
	}

	const handleUploadFrnt = () => {
		inputRefFrnt.current?.click()
	}
	const handleUploadBack = () => {
		inputRefBack.current?.click()
	}
	const handleReset = () => {
		setUploadedFileFrnt('')
		setUploadedFileBack('')
	}
	// ** Upload new doc(s) - uploadMyDocs()
	const handleSubmit = () => {
		setLoading(true)
		var FormData = require('form-data')
		var data = new FormData()
		if (uploadedFileFrnt?.name != '' && uploadedFileFrnt != null) {
			data.append('emirates_front', uploadedFileFrnt)
		}
		if (uploadedFileBack?.name != '' && uploadedFileBack != null) {
			data.append('emirates_back', uploadedFileBack)
		}
		uploadMyDocs(data).then((data) => {
			if (data.code === 200 && data.errorCode != 1) {
				setSuccess(data?.description)
				setShowSuccess(true)
				setUploadedFileFrnt('')
				setUploadedFileBack('')
				setLoading(false)
				setTimeout(() => {
					setShowSuccess(false)
					getMyDocs().then((data) => {
						if (data.code === 200 && data.errorCode != 1) {
							setMyDocs(data)
						} else {
							setMyDocs(null)
						}
					})
				}, 2000)
			} else {
				setError(data?.errorDescription)
				setShow(true)
				setLoading(false)
				setTimeout(() => {
					setShow(false)
				}, 2000)
			}
		})
	}
	// ** Verify the file size and extension/type
	const handleDisplayFileDetailsFrnt = () => {
		const inputFile = inputRefFrnt?.current?.files?.[0]
		let isAllowedType: any = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(inputFile?.type)
		if (inputFile && isAllowedType) {
			if (inputFile.type === 'application/pdf') {
				if (inputFile.size > 5242880) {
					setShow(true); setError(t('The file is too large! Max allowed size is 5MB.'))
					setTimeout(() => { setShow(false) }, 3000)
				} else {
					setUploadedFileFrnt(inputFile)
				}
			} else {
				setUploading1(true)
				try {
					validateAndCompressImage(inputFile).then((compressResponse) => {
						if (compressResponse?.success) {
							setUploadedFileFrnt(compressResponse.file)
						} else {
							setShow(true); setError(t(compressResponse?.message || 'File compression error!'))
							setTimeout(() => { setShow(false) }, 3000)
						}
						setUploading1(false)
					})
				} catch (error) {
					setShow(true); setError(t('File upload error!'))
					setTimeout(() => { setShow(false) }, 3000)
					setUploading1(false)
				}
			}
		} else {
			setShow(true)
			setError(t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'))
			setTimeout(() => {
				setShow(false)
			}, 3000)
		}
		// let fileSize: any = inputRefFrnt?.current?.files[0]?.size <= 2101546
		// let isAllowedType: any =
		// 	inputRefFrnt?.current?.files[0]?.type === 'image/png' ||
		// 	inputRefFrnt?.current?.files[0]?.type === 'image/jpeg' ||
		// 	inputRefFrnt?.current?.files[0]?.type === 'image/jpg' ||
		// 	inputRefFrnt?.current?.files[0]?.type === 'application/pdf'
		// if (!isAllowedType) {
		// 	setShow(true)
		// 	setError('File is not a png/jpeg/jpg/PDF  file')
		// 	setTimeout(() => {
		// 		setShow(false)
		// 	}, 2000)
		// }
		// if (!fileSize) {
		// 	setShow(true)
		// 	setError('File size should be less than 2MB')
		// 	setTimeout(() => {
		// 		setShow(false)
		// 	}, 2000)
		// }
		// if (isAllowedType && fileSize) {
		// 	inputRefFrnt.current?.files && setUploadedFileFrnt(inputRefFrnt.current.files[0])
		// }
	}
	const handleDisplayFileDetailsBack = () => {
		const inputFile = inputRefBack?.current?.files?.[0]
		let isAllowedType: any = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(inputFile?.type)
		if (inputFile && isAllowedType) {
			if (inputFile.type === 'application/pdf') {
				if (inputFile.size > 5242880) {
					setShow(true); setError(t('The file is too large! Max allowed size is 5MB.'))
					setTimeout(() => { setShow(false) }, 3000)
				} else {
					setUploadedFileBack(inputFile)
				}
			} else {
				setUploading2(true)
				try {
					validateAndCompressImage(inputFile).then((compressResponse) => {
						if (compressResponse?.success) {
							setUploadedFileBack(compressResponse.file)
						} else {
							setShow(true); setError(t(compressResponse?.message || 'File compression error!'))
							setTimeout(() => { setShow(false) }, 3000)
						}
						setUploading2(false)
					})
				} catch (error) {
					setShow(true); setError(t('File upload error!'))
					setTimeout(() => { setShow(false) }, 3000)
					setUploading2(false)
				}
			}
		} else {
			setShow(true)
			setError(t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'))
			setTimeout(() => {
				setShow(false)
			}, 3000)
		}
		// let fileSize: any = inputRefBack?.current?.files[0]?.size <= 2101546
		// let isAllowedType: any =
		// 	inputRefBack?.current?.files[0]?.type === 'image/png' ||
		// 	inputRefBack?.current?.files[0]?.type === 'image/jpeg' ||
		// 	inputRefBack?.current?.files[0]?.type === 'image/jpg' ||
		// 	inputRefBack?.current?.files[0]?.type === 'application/pdf'
		// if (!isAllowedType) {
		// 	setShow(true)
		// 	setError('File is not a png/jpeg/jpg/PDF  file')
		// 	setTimeout(() => {
		// 		setShow(false)
		// 	}, 2000)
		// }
		// if (!fileSize) {
		// 	setShow(true)
		// 	setError('File size should be less than 2MB')
		// 	setTimeout(() => {
		// 		setShow(false)
		// 	}, 2000)
		// }
		// if (isAllowedType && fileSize) {
		// 	inputRefBack.current?.files && setUploadedFileBack(inputRefBack.current.files[0])
		// }
	}
	const handleEdit1 = () => {
		setEditing1(true)
		handleUploadFrnt()
	  }
	  const handleEdit2 = () => {
		setEditing2(true)
		handleUploadBack()
	  }
	// ** Delete uploaded docs by id - deleteMyDocs(#id)
	const handleDelete = (id: any) => {
		setLoading(true)
		deleteMyDocs(id?.id).then((data) => {
			if (data.code === 200 && data.errorCode != 1) {
				setLoading(false)
				setSuccess(data?.description)
				setShowSuccess(true)
				setLoading(false)
				setTimeout(() => {
					setShowSuccess(false)
				}, 2000)
				getMyDocs().then((data) => {
					if (data.code === 200 && data.errorCode != 1) {
						setMyDocs(data)
					} else {
						setMyDocs(null)
					}
				})
			} else {
				setError(data?.errorDescription)
				setShow(true)
				setLoading(false)
				setTimeout(() => {
					setShow(false)
				}, 2000)
			}
		})
	}
	const handleView = (data: any) => {
		setViewImage(data)
	}
	return (
		<>
			<div className='com-card-section'>
				<div className='row form-add-design'>
					<div className='col-12'>
						<div className='documents-card card'>
							<div className='card-body px-10'>
								<div className='row'>
									{/* <div className='col-12'>
										<h3 className='font-16 fw-bold text-black mb-3 need-txt'>{t("Emirates ID")}</h3>
										{location.pathname === '/pt-documents/documents/id-proof' ? (
											<p className='font-14 fw-bold mb-8'>
												{t("Click on the eye icon to view the image")}
												{loading ? <Spinner animation='border' variant='primary' /> : null}
											</p>
										) : (
											<p className='font-14 fw-bold mb-8 need-sec-txt'>
												{t("Your Emirates ID is expired please update valid document")}
											</p>
										)
										}
									</div> */}
									{show ? (
										<Alert className='font-14 fw-bold mb-8' variant='danger'>
											{error}!
										</Alert>
									) : null}
									{showSuccess ? (
										<Alert className='font-14 fw-bold mb-8' variant='success'>
											{success}
										</Alert>
									) : null}
								</div>
								<div className='row'>
									<div className='col-md-12'>
										<div className='row'>
											<div className='col-sm-6 col-lg-4 col-12'>
												<div className='mb-3 upload-input'>
													<label className='form-label text-black font-14 fw-bold d-block mb-5'>
														{t("Front")}
													</label>
													<input
														ref={inputRefFrnt}
														onChange={handleDisplayFileDetailsFrnt}
														className='d-none'
														type='file'
														accept='.png,.jpeg,.jpg,application/pdf'
													/>
													<button
														onClick={handleUploadFrnt}
														// className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileFrnt?.name || eIdFrontImage ? 'primary active' : 'primary'
														// 	}`}
														// disabled={eIdFrontImage ? true : false}
														className={`w-100 py-3 ellipsis mydoc_bt_file mb-md-0 btn-outline-${!uploading1 && (uploadedFileFrnt?.name || eIdFrontImage) ? 'primary active' : 'primary'
															}`}
														disabled={uploading1}
													>
														<span>
															{uploading1 ?
																<span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
																: uploadedFileFrnt?.name || eIdFrontImage ? (
																	uploadedFileFrnt?.name || ''
																) : (
																	<>
																		<img src={PlusIcon} className='img-fluid va-baseline' />
																		<p className='m-0 upload-your-life'>Upload your file</p>
																	</>
																)}
														</span>
														{/* <span>
															{uploadedFileFrnt?.name || eIdFrontImage ? (
																uploadedFileFrnt?.name || ''
															) : (
																<img src={PlusIcon} className='img-fluid va-baseline' />
															)}
														</span> */}
													</button>
													<div className='col-12 position-relative'>
														{eIdFrontImage ? (
															<>
																<div className='doc-view-bt w-100'>
																	<div className='row'>
																		<div className='col-12 text-center'>
																			<button
																				className='border-0 bg-transparent mx-auto'
																				data-bs-target='#view-popUp'
																				data-bs-toggle='modal'
																				data-bs-dismiss='modal'
																				type='submit'
																				onClick={() => {
																					handleView(eIdFrontImage)
																				}}
																			>
																				<img src={eyeIcon} className='img-fluid' />
																			</button>
																			<div className='d-flex py-2 px-4 upload-name align-items-center justify-content-between rounded-bottom'>
																				<p className='m-0'>Passport Front</p>
																				<img src={checkBoxIcon} className='img-fluid' />
																			</div>
																			<div className='upload-img'>
																				<img src={eIdFrontImage?.filename} className='img-fluid' />
																			</div>
																		</div>
																		{/* <div className='col-6 text-center'>
																	{eIdFrontImage ? (
																		<button
																			className='border-0 bg-transparent '
																			onClick={() => {
																				handleDelete(eIdFrontImage)
																			}}
																		>
																			<i className='bi bi-trash text-black font-17'></i>
																		</button>
																	) : null}
																</div> */}
																	</div>
																</div>
															</>
														) : null}
													</div>
												</div>
											</div>
											<div className='col-sm-6 col-lg-4 col-12'>
												<div className='mb-3 upload-input'>
													<label className='form-label text-black font-14 fw-bold d-block mb-5'>
														{t("Back")}
													</label>
													<input
														ref={inputRefBack}
														onChange={handleDisplayFileDetailsBack}
														className='d-none'
														type='file'
														accept='.png,.jpeg,.jpg,application/pdf'

													/>
													<button
														onClick={handleUploadBack}
														className={`w-100 py-3 mb-md-0 ellipsis mydoc_bt_file btn-outline-${!uploading2 && (uploadedFileBack?.name || eIdBackImage) ? 'primary active' : 'primary'
															}`}
														disabled={uploading2}
													// className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileBack?.name || eIdBackImage ? 'primary active' : 'primary'
													// 	}`}
													// disabled={eIdBackImage ? true : false}
													>
														<span>
															{uploading2 ?
																<span className='spinner-border spinner-border text-primary align-middle ms-2'></span>
																: uploadedFileBack?.name || eIdBackImage ? (
																	uploadedFileBack?.name || ''
																) : (
																	<>
																		<img src={PlusIcon} className='img-fluid va-baseline' />
																		<p className='m-0 upload-your-life'>Upload your file</p>
																	</>
																)}
															{/* {uploadedFileBack?.name || eIdBackImage ? (
																uploadedFileBack?.name || ''
															) : (
																<img src={PlusIcon} className='img-fluid va-baseline' />
															)} */}
														</span>
													</button>
													<div className='col-12 position-relative'>
														{eIdBackImage ? (
															<>
																<div className='doc-view-bt w-100'>
																	<div className='row'>
																		<div className='col-12 text-center'>
																			<button
																				className='border-0 bg-transparent mx-auto'
																				data-bs-target='#view-popUp'
																				data-bs-toggle='modal'
																				data-bs-dismiss='modal'
																				type='submit'
																				onClick={() => {
																					handleView(eIdBackImage)
																				}}
																			>
																				<img src={eyeIcon} className='img-fluid' />
																			</button>
																			<div className='d-flex py-2 px-4 upload-name align-items-center justify-content-between rounded-bottom'>
																				<p className='m-0'>Passport Back</p>
																				<img src={checkBoxIcon} className='img-fluid' />
																			</div>
																			<div className='upload-img'>
																				<img src={eIdBackImage?.filename} className='img-fluid' />
																			</div>
																		</div>
																		{/* <div className='col-6 text-center'>
																	{eIdBackImage ? (
																		<button
																			className='border-0 bg-transparent '
																			onClick={() => {
																				handleDelete(eIdBackImage)
																			}}
																		>
																			<i className='bi bi-trash text-black font-17'></i>
																		</button>
																	) : null}
																</div> */}
																	</div>
																</div>
																<ViewPopup viewImage={viewImage} />
															</>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{eIdFrontImage === undefined || eIdBackImage === undefined || editing1 || editing2 || eIdFrontImage === '' || eIdBackImage === '' ?	
									<div className='row'>
										<div className='col-12'>
											{/* {location.pathname === '/pt-documents/need-action'
												|| location.pathname === '/pt-documents/documents/id-proof' ? ( */}
															{location.pathname === '/pt-documents/passport'
																|| location.pathname === '/pt-documents/emirates-id' ? (
																<div className='col-12 text-end'>
																	{/* <button className='btn btn-outline-primary fw-bold px-10 py-2 me-5' onClick={handleReset}>
														{t("Cancel")}
													</button> */}
													<button
														type='submit'
														className='btn btn-primary fw-bold px-20 py-2'
														onClick={handleSubmit}
														disabled={loading || uploading1 || uploading2}
													>
														{t("Save")}
													</button>
												</div>
											)
												:
												null
											}
										</div>
									</div>
									:''
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
