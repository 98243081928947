import React, { FC, useEffect, useState } from 'react'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import { StepOne } from './components/StepOne/StepOne'
import { StepTwo } from './components/StepTwo/StepTwo'
import { StepThree } from './components/StepThree/StepThree'
import { StepFour } from './components/StepFour/StepFour'
import { StepFive } from './components/StepFive/StepFive'
import { StepSix } from './components/StepSix/StepSix'
import { StepSeven } from './components/StepSeven/StepSeven';
import { useSelector } from 'react-redux';
import { RootState } from '../../../constants';
import { getServiceRequestByData } from '../../../constants/axios/apis';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const MoveIn: FC = () => {

    const { t } = useTranslation()
    const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
    const [activeStep, setActiveStep] = React.useState(0);
    const history = useHistory();

    if(currentContract?.property_search_type == 1){
        history.push("/")
    }
    // const [skipped, setSkipped] = React.useState(new Set<number>());
    const [step1Status, setStep1Status] = useState<any>({
        status: false,
        accessCardsStatus: false,
        keyCardsStatus: false,
        vehicleRegistrationStatus: false,
        data: {
            accessCards: {},
            keyCards: {},
            vehicleDetails: {},
        }
    })
    const [step2Status, setStep2Status] = useState<any>({
        status: false,
        data: {}
    })
    const [step3Status, setStep3Status] = useState<any>({
        status: false,
        data: {}
    })
    const [step4Status, setStep4Status] = useState<any>({
        status: false,
        data: {}
    })
    const [step5Status, setStep5Status] = useState<any>({
        status: false,
        permitToWorkRequestStatus: false,
        helipadAccessRequestStatus: false,
        fireProtectionRequestStatus: false,
        data: {
            permitToWork: {},
            helipadAccess: {},
            fireProtection: {}
        }
    })
    const [step6Status, setStep6Status] = useState<any>({
        status: false,
        data: {}
    })

    const steps = [
        // 'Move-in Inspection',
        { label: 'Handover', completed: step1Status.status },
        { label: 'Fit-out Design NOC', completed: step2Status.status },
        { label: 'Authorities NOC', completed: step3Status.status },
        { label: 'Fit-out Commencement Approval', completed: step4Status.status },
        { label: 'Permit to Work', completed: step5Status.status },
        { label: 'Permission to Start Operations', completed: step6Status.status },
    ];

    const getStep1Status = () => {
        getServiceRequestByData(7, '', currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep1Status((prevState: any) => {
                    return {
                        ...prevState,
                        accessCardsStatus: true,
                        data: {
                            ...prevState.data,
                            accessCards: response.data[0]
                        }
                    }
                })
            } else {
                setStep1Status((prevState: any) => {
                    return {
                        ...prevState,
                        accessCardsStatus: false,
                        data: {
                            ...prevState.data,
                            accessCards: {}
                        }
                    }
                })
            }
        })

        getServiceRequestByData(37, '', currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep1Status((prevState: any) => {
                    return {
                        ...prevState,
                        keyCardsStatus: true,
                        data: {
                            ...prevState.data,
                            keyCards: response.data[0]
                        }
                    }
                })
            } else {
                setStep1Status((prevState: any) => {
                    return {
                        ...prevState,
                        keyCardsStatus: false,
                        data: {
                            ...prevState.data,
                            keyCards: {}
                        }
                    }
                })
            }
        })

        getServiceRequestByData(9, '', currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep1Status((prevState: any) => {
                    return {
                        ...prevState,
                        vehicleRegistrationStatus: true,
                        data: {
                            ...prevState.data,
                            vehicleDetails: response.data[0]
                        }
                    }
                })
            } else {
                setStep1Status((prevState: any) => {
                    return {
                        ...prevState,
                        vehicleRegistrationStatus: false,
                        data: {
                            ...prevState.data,
                            vehicleDetails: {}
                        }
                    }
                })
            }
        })
    }

    const getStep2Status = () => {
        getServiceRequestByData(29, '', currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep2Status({ status: true, data: response.data[0] })
            } else {
                setStep2Status({ status: false, data: {} })
            }
        })
    }

    const getStep3Status = () => {
        getServiceRequestByData(36, '', currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep3Status({ status: true, data: response.data[0] })
            } else {
                setStep3Status({ status: false, data: {} })
            }
        })
    }

    const getStep4Status = () => {
        getServiceRequestByData(30, '', currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep4Status({ status: true, data: response.data[0] })
            } else {
                setStep4Status({ status: false, data: {} })
            }
        })
    }

    const getStep5Status = () => {
        getServiceRequestByData(35, 49, currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep5Status((prevState: any) => {
                    return {
                        ...prevState,
                        permitToWorkRequestStatus: true,
                        data: {
                            ...prevState.data,
                            permitToWork: response.data[0]
                        }
                    }
                })
            } else {
                setStep5Status((prevState: any) => {
                    return {
                        ...prevState,
                        permitToWorkRequestStatus: false,
                        data: {
                            ...prevState.data,
                            permitToWork: {}
                        }
                    }
                })
            }
        })

        getServiceRequestByData(35, 52, currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep5Status((prevState: any) => {
                    return {
                        ...prevState,
                        helipadAccessRequestStatus: true,
                        data: {
                            ...prevState.data,
                            helipadAccess: response.data[0]
                        }
                    }
                })
            } else {
                setStep5Status((prevState: any) => {
                    return {
                        ...prevState,
                        helipadAccessRequestStatus: false,
                        data: {
                            ...prevState.data,
                            helipadAccess: {}
                        }
                    }
                })
            }
        })

        getServiceRequestByData(35, 53, currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep5Status((prevState: any) => {
                    return {
                        ...prevState,
                        fireProtectionRequestStatus: true,
                        data: {
                            ...prevState.data,
                            fireProtection: response.data[0]
                        }
                    }
                })
            } else {
                setStep5Status((prevState: any) => {
                    return {
                        ...prevState,
                        fireProtectionRequestStatus: false,
                        data: {
                            ...prevState.data,
                            fireProtection: {}
                        }
                    }
                })
            }
        })
    }

    const getStep6Status = () => {
        getServiceRequestByData(34, '', currentContract?.propertyID).then((response) => {
            if (response.data) {
                setStep6Status({ status: true, data: response.data[0] })
            } else {
                setStep6Status({ status: false, data: {} })
            }
        })
    }

    useEffect(() => {
        if (step1Status.accessCardsStatus == true && step1Status.keyCardsStatus == true) {
            setStep1Status((prevState: any) => {
                return {
                    ...prevState,
                    status: true
                }
            })
        } else {
            setStep1Status((prevState: any) => {
                return {
                    ...prevState,
                    status: false
                }
            })
        }
    }, [step1Status.accessCardsStatus, step1Status.keyCardsStatus])

    useEffect(() => {
        if (step5Status.permitToWorkRequestStatus == true) {
            setStep5Status((prevState: any) => {
                return {
                    ...prevState,
                    status: true
                }
            })
        } else {
            setStep5Status((prevState: any) => {
                return {
                    ...prevState,
                    status: false
                }
            })
        }
    }, [step5Status.permitToWorkRequestStatus])

    useEffect(() => {
        getStep1Status()
        getStep2Status()
        getStep3Status()
        getStep4Status()
        getStep5Status()
        getStep6Status()
    }, [currentContract])

    // const isStepOptional = (step: number) => {
    //     return step === 1;
    // };

    // const isStepSkipped = (step: number) => {
    //     return skipped.has(step);
    // };

    // const handleNext = () => {
    //     let newSkipped = skipped;
    //     if (isStepSkipped(activeStep)) {
    //         newSkipped = new Set(newSkipped.values());
    //         newSkipped.delete(activeStep);
    //     }

    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped(newSkipped);
    // };

    // const handleSubmit = () => {

    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleSkip = () => {
    //     if (!isStepOptional(activeStep)) {
    //         // You probably want to guard against something like this,
    //         // it should never occur unless someone's actively trying to break something.
    //         throw new Error("You can't skip a step that isn't optional.");
    //     }

    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped((prevSkipped) => {
    //         const newSkipped = new Set(prevSkipped.values());
    //         newSkipped.add(activeStep);
    //         return newSkipped;
    //     });
    // };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    return (
        <div className="com-card-section movein-page">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6"> {t('New Fit-out Permit')} </h4>
                </div>
            </div>
            <div className="col-12">
                <div className="card w-100">
                    <div className="card-body p-0">
                        <Stepper activeStep={activeStep} className="multiform-stepper" id="new-fit-out-permit">
                            {steps.map((step, index) => {
                                // const stepProps: { completed?: boolean } = {};
                                // const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                // if (isStepOptional(index)) {
                                //     labelProps.optional = (
                                //         <p>Optional</p>
                                //     );
                                // }

                                // if (isStepSkipped(index)) {
                                // if (index == 1) {
                                //     // console.log(step2Status)
                                //     step.completed = step2Status;     //control step status
                                // } else {
                                //     step.completed = false;
                                // }
                                // }
                                return (
                                    <Step className={activeStep == index ? "active" : ""} key={step.label} {...step}>
                                        <StepLabel
                                            {...labelProps}
                                            style={{ cursor: 'pointer' }} onClick={() => setActiveStep(index)}
                                        >{t(step.label)}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <React.Fragment>
                            <div>
                                {
                                    // activeStep === 0 ?
                                    // <StepOne contract={currentContract} /> :
                                    activeStep === 0 ?
                                        <StepTwo contract={currentContract} getStepStatus={getStep1Status} stepStatus={step1Status} /> :
                                        activeStep === 1 ?
                                            <StepThree contract={currentContract} getStepStatus={getStep2Status} stepStatus={step2Status} /> :
                                            activeStep === 2 ?
                                                <StepFour contract={currentContract} getStepStatus={getStep3Status} stepStatus={step3Status} /> :
                                                activeStep === 3 ?
                                                    <StepFive contract={currentContract} getStepStatus={getStep4Status} stepStatus={step4Status} /> :
                                                    activeStep === 4 ?
                                                        <StepSix contract={currentContract} getStepStatus={getStep5Status} stepStatus={step5Status} /> :
                                                        <StepSeven contract={currentContract} getStepStatus={getStep6Status} stepStatus={step6Status} />
                                }
                            </div>
                            {/*<div>*/}
                            {/*{isStepOptional(activeStep) && (*/}
                            {/*<button onClick={handleSkip}>*/}
                            {/*Skip*/}
                            {/*</button>*/}
                            {/*)}*/}
                            {/*</div>*/}
                            <div className='d-flex flex-stack bg-white action-btns px-7 stepper-actions'>
                                {/* <div className='mr-2 col-2'>
                                    <button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className='btn btn-outline-secondary btn-lg px-10 py-3 w-100 btn-back'
                                    >
                                        Back
                                    </button>
                                </div> */}
                                <div className="col-8">
                                    &nbsp;
                                </div>

                                {/* <div className="col-2">
                                    <button
                                        onClick={activeStep === 5 ? handleSubmit : handleNext}
                                        className='btn btn-primary fw-bold px-10 py-3 w-100'
                                    >
                                        {activeStep === 5 ? 'Submit' : 'Next'}
                                    </button>
                                </div> */}
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        </div>
    )
};

export { MoveIn };
