import React, { FC, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { Communitywall } from './communityWall'
import { AddComunitiesPost } from './createCommunityPost'

const CommunityWall: FC = () => {
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <Switch>
                       <Route path='/communitywall/page'>
                            <Communitywall />
                        </Route>
                        <Route path='/communitywall/create-community-post'>
                            <AddComunitiesPost />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}

const CommunityWallWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <CommunityWall />
        </>
    )
};

export { CommunityWallWrapper }

