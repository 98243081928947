import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CompactPagination } from '../../../../../components/partials/content/CompactPagination/CompactPagination';
import { getInvoiceList } from '../../../../../constants/axios/apis';
import { currencyFormater, CustomLoader2, NoDataFound1 } from '../../../../../constants/generics/customActios';


export function InProgressInvoices({ searchItem, setSearchItem }: any) {
    const { t } = useTranslation()
    const pageSize = 10
    const [brokerList, setbrokerList] = useState<any>()
    const [loading, setLoading] = useState<any>(true)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [totalbrokerList, setTotalbrokerList] = useState<any>(0)
    const [page, setPage] = React.useState(1)
    setTimeout(() => {
        setLoading(false)
    }, 1500)

    const handleClick = (newCurrentPage: any) => {
        setCurrentPage(newCurrentPage)
    }
    useEffect(() => { setCurrentPage(1) }, [searchItem])
    useEffect(() => {
        let page = currentPage
        const newPayload =
            '?currentPage=' +
            page +
            '&pageSize=' +
            pageSize +
            '&user_id=' +
            '&id_sort=1' +
            '&search_key=' + searchItem +
            '&status=pending'
        getInvoiceList(newPayload).then((response: any) => {
            if (Array.isArray(response?.data) && response?.data?.length > 0) {
                setbrokerList(response?.data)
                setTotalbrokerList(response?.dataCount)
            } else {
                setbrokerList([])
                setTotalbrokerList(0)
            }
        })
    }, [currentPage, searchItem])

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        handleClick(value)
    }

    if (!loading && (brokerList === undefined || brokerList?.length === 0)) {
        return <div className='card'><NoDataFound1 /></div>
    }

    let userRole: number = 0
    let userDetail = localStorage.getItem('userDetail')
    if (userDetail) {
      userRole = JSON.parse(userDetail).id_role
    }

    const brokerData = brokerList?.map((data: any, index: any) => (
        <tr key={data?.id}>
            <td>{(currentPage - 1) * pageSize + index + 1}</td>
            <td>{data?.InvoiceNo ? data?.InvoiceNo : '-'}</td>
            <td>{data?.unitNumber ? data?.unitNumber : '-'}</td>
            <td>{data?.propertyName ? data?.propertyName : '-'}</td>
            <td>{data?.leaseNumber ? data?.leaseNumber : '-'}</td>
            <td>
                {t('AED')} {data?.invoiceAmount ? currencyFormater(data?.invoiceAmount) : '-'}
            </td>
            { userRole === 5 ?
            <td className='text-warning'>
                <i className='bi bi-check-circle-fill text-warning me-2'></i>{data?.status ? data?.status : '-'}
            </td>
            :
            <td>
                   
                <div className="dropdown">
                    <button className="btn btn-outline-primary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        Change Status
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item" data-bs-toggle="modal" href="#status_update">Pending</a></li>
                        <li><a className="dropdown-item" data-bs-toggle="modal" href="#status_update">Aprovel</a></li>
                        <li><a className="dropdown-item" data-bs-toggle="modal" href="#status_update">Rejecte</a></li>
                    </ul>
                </div>
            </td> 
            }
        </tr>
    ))

    return (
        <>
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-12'>
                    <div className="card mb-3">
                        <div className="card-body p-7">
                            <div className='rec-payment-act-sec'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='table-responsive'>
                                            <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th className=''> {t('No.')} </th>
                                                        <th className=''> {t('Invoice Number')} </th>
                                                        <th className=''> {t('Unit Number')} </th>
                                                        <th className=''> {t('Property Name')} </th>
                                                        <th className=''> {t('Reference Number')} </th>
                                                        <th className=''> {t('Price')}</th>
                                                        <th className=''> {t('Status')} </th>
                                                    </tr>
                                                </thead>
                                                {loading ? (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <CustomLoader2 />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) : (
                                                    <tbody>{brokerList ? brokerData : ''}</tbody>
                                                )}
                                            </table>
                                            <CompactPagination
                                                pageSize={pageSize}
                                                totalCount={parseInt(totalbrokerList)}
                                                defaultPage={currentPage}
                                                onPageChange={setCurrentPage}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="status_update">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Status Changes</h5>
                </div> */}
                <div className="modal-body">
                    <button type="button" className="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                    <p className='font-18 fw-bold'>Are you sure change the approvel status?</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                    <button type="button" className="btn btn-primary">Yes</button>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}
