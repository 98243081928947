import { getFmCommunityUser } from '../constants/axios/apis';
import { IState as Props } from './interface';

const API_URL = process.env.REACT_APP_API_URL || 'api';
const PROFILE_DETAILS_UPDATE_API_URL = `${API_URL}/profile`;
const UPDATE_PROFILE_DETAILS_UPDATE_API_URL = `${API_URL}/updateProfile`;
const PROPERTY_FILTER_API_URL = `${API_URL}/propertyFilter`;
const BROKER_MY_TEAM_API_URL = `${API_URL}/broker/myTeam`;
const INVOICE_LIST_API_URL = `${API_URL}/broker/invoiceList`;
const RESERVED_PROPERTTIES_API_URL = `${API_URL}/property/reservedProperties`;
const ADD_NEW_BROKER_API_URL = `${API_URL}/user/create`;
const GET_BROKER_INVOICE_API_URL = `${API_URL}/broker/invoiceList`;
const GET_OFFER_LIST_API_URL = `${API_URL}/offerList`;
const GET_OFFER_DETAILS_API_URL = `${API_URL}/offerDetail/`;

const GET_UPCOMINC_VISITS_API_URL = `${API_URL}/myVisits/upcoming`;
const GET_VISITED_API_URL = `${API_URL}/myVisits/visited`;
/* const GET_VISITED_API_URL = `${API_URL}/myVisits/upcoming/`; */
const RESCHEDULE_VISIT_API_URL = `${API_URL}/visit/reschedule`;
const UNINVOICED_CONTRACTS_API_URL = `${API_URL}/broker/contracts?invoice_type=0`;
const INVOICED_CONTRACTS_API_URL = `${API_URL}/broker/contracts?invoice_type=1`;
const CONTRACTS_DETAILS_API_URL = `${API_URL}/getAllContract`;

const ELIGIBLE_COMMMISSION_API_URL = `${API_URL}/broker/eligibleCommission`;
const ADD_INVOICE_API_URL = `${API_URL}/broker/invoice/add`;

const FCS_MY_TEAM_API_URL = `${API_URL}/myTeam`;
const FCS_TECHNICIANS_LIST_API_URL = `${API_URL}/myTeam?user_role=FC`;
// const FCS_MY_TEAM_TECHNICIAN_API_URL = `${API_URL}/facility/user/company`;
/* const FCS_MY_TEAM_API_URL = `${API_URL}/facility/myTeam`; */
const FCS_WORK_ORDERS_TEAM_API_URL = `${API_URL}/facility/work/orders`;
const FCS_ASSIGN_WORK_ORDER_API_URL = `${API_URL}/facility/work/assign`;

const FCS_ADD_TECHNICIAN_API_URL = `${API_URL}/user/create`;

const FCS_GET_ANNOUNCEMENTS_API_URL = `${API_URL}/announcements/list`;
const FCS_ADD_NEW_ANNOUNCEMENT_API_URL = `${API_URL}/announcements/create`;

const FCS_GET_MAINTENANCE_REQUESTS = `${API_URL}/facility/mr/issues`;

const FCS_GEN_REPORT_URL = `${API_URL}/facility/count/reports`;
const FC_GEN_REPORT_URL = `${API_URL}/facility/reports`;
const FCS_ATTENDANCE_LIST_URL = `${API_URL}/user/attendance/list`;

const BUILDING_LIST_URL = `${API_URL}/facility/building/list`;
const MASTER_DATA_URL = `${API_URL}/getMasterData`;

const FCSS_SCHEDULED_VISITS_API_URL = `${API_URL}/facility/visitor/list`;
const FCSS_MOVEDIN_API_URL = `${API_URL}/approval/list`;

// FC, FCS, FCSS, FM, FMSM - return building & community(property) formated list 
export const getUserBuildingCommunityList = async (userId:any) =>{
    let buildingListData = new Array()
    let communityListData = new Array()
    await getFmCommunityUser('?user_id=' + userId).then(({ data }) => {
        let allCommulityList = new Array()
        if (data && Array.isArray(data)) {

            data.forEach((community: any) => {
                allCommulityList.push({
                    id: `${community.community_id}`,
                    name: community.community
                })

                community?.buildings?.forEach((building: any) => {
                    if (building.id && building.building_name) {
                        buildingListData.push({
                            id: `${building.id}`,
                            name: building.building_name,
                            communityId: `${community.community_id}`,
                        })
                    }
                })
            })
            
            // setBuildingList(data.map((item: any) => ({ id: `${item.building_id}`, name: item.building_name, communityId: `${item.community_id}` })))

            // const communityList = data.map((item: any) => ({ id: `${item.community_id}`, name: item.community }))
            // setPropertyList(filteredCommulityList)
            communityListData = allCommulityList?.filter((value, index, self) => index === self.findIndex((el) => (el.id === value.id)))
        }
    }).catch((e) => {  })

    return {buildingListData, communityListData}
}


export const getBuildingList = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', BUILDING_LIST_URL, setState, '', userToken);
}


export const generateAttendanceListFCS = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string, date: String) => {
    makeRequest('GET', FCS_ATTENDANCE_LIST_URL+`?date=${date}`, setState, '', userToken);
}


export const generateReportFCS = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string, params: string) => {
    makeRequest('GET', FCS_GEN_REPORT_URL+params, setState, '', userToken);
}
export const generateReportFC = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string, params: string) => {
    makeRequest('GET', FC_GEN_REPORT_URL+params, setState, '', userToken);
}

export const getMaintenanceRequestsOpen = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', FCS_GET_MAINTENANCE_REQUESTS+'?status=open', setState, '', userToken);
}
export const getMaintenanceRequestsResolved = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', FCS_GET_MAINTENANCE_REQUESTS+'?status=resolved', setState, '', userToken);
}



export const addNewAnnouncement = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string, params: any, callback: Function = () => {}) => {
    makeRequest('POST', FCS_ADD_NEW_ANNOUNCEMENT_API_URL, setState, params, userToken, true, callback);
}



export const getAnnouncementsLists = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', FCS_GET_ANNOUNCEMENTS_API_URL, setState, '', userToken);
}

export const getProfileDetails = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', PROFILE_DETAILS_UPDATE_API_URL, setState, '', userToken);
}

export const updateProfileDetails = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string, params: any, callback: Function = () => {}) => {
    makeRequest('POST', UPDATE_PROFILE_DETAILS_UPDATE_API_URL, setState, params, userToken, true, callback);
}

export const addNewTechnician = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, requestParams: any, userToken: string, callback: Function = () => {}) => {
    makeRequest(
        'POST',
        FCS_ADD_TECHNICIAN_API_URL,
        setState, 
        requestParams, 
        userToken,
        false,
        callback
    );
}

export const assignWorkOrders = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, requestParams: any, userToken: string, callback: Function = () => {}) => {
    makeRequest(
        'POST',
        FCS_ASSIGN_WORK_ORDER_API_URL,
        setState, 
        requestParams, 
        userToken,
        false,
        callback
    );
}


export const getFcsWorkOrders = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', FCS_WORK_ORDERS_TEAM_API_URL, setState, '', userToken);
}

export const getFcsMyTeamList = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', FCS_MY_TEAM_API_URL, setState, '', userToken);
}
export const getTechnicianList = (setState: React.Dispatch<React.SetStateAction<{  //Only FC users under current FCS
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', FCS_TECHNICIANS_LIST_API_URL, setState, '', userToken);
}

export const getFcssScheduledVisits = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', FCSS_SCHEDULED_VISITS_API_URL, setState, '', userToken);
}

export const getFcssApprovals = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', FCSS_MOVEDIN_API_URL, setState, '', userToken);
}

// export const getFcsMyTeamTechnicianInfo = (setState: React.Dispatch<React.SetStateAction<{
//     loading: boolean;
//     response: any;
//     error: string;
// }>>, userToken: string) => {
//     makeRequest('GET', FCS_MY_TEAM_TECHNICIAN_API_URL, setState, '', userToken);
// }

export const addInvoiceBroker = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string, params: any, callback: Function = () => {}) => {
    makeRequest('POST', ADD_INVOICE_API_URL, setState, params, userToken, true, callback);
}

export const getEligibleCommission = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string, propertyID: number) => {
    makeRequest('GET', ELIGIBLE_COMMMISSION_API_URL+`?property_id=${propertyID}`, setState, '', userToken);
}

export const getContractsDetails = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string, propertyID: number) => {
    makeRequest('GET', CONTRACTS_DETAILS_API_URL+`?property_id=${propertyID}`, setState, '', userToken);
}


export const getUninvoicedContractsList = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', UNINVOICED_CONTRACTS_API_URL, setState, '', userToken);
}

export const getInvoicedContractsList = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', INVOICED_CONTRACTS_API_URL, setState, '', userToken);
}

export const rescheduleVisit = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, requestParams: any, userToken: string, callback: Function = () => {}) => {
    makeRequest(
        'POST',
        RESCHEDULE_VISIT_API_URL,
        setState, 
        requestParams, 
        userToken,
        false,
        callback
    );
}


export const getUpcomingVisits = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', GET_UPCOMINC_VISITS_API_URL, setState, '', userToken);
}

export const getClosedVisits = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', GET_VISITED_API_URL, setState, '', userToken);
}


export const getOfferDetails = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, id: any, userToken: string) => {

    const URL  = GET_OFFER_DETAILS_API_URL+ id;


    makeRequest('GET', URL, setState, '', userToken);
}

export const getOfferList = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', GET_OFFER_LIST_API_URL, setState, '', userToken);
}

export const getBrokerStatements = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', GET_BROKER_INVOICE_API_URL, setState, '', userToken);
}

export const addNewBroker = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, requestParams: any, userToken: string, callback: Function = () => {}) => {
    makeRequest(
        'POST',
        ADD_NEW_BROKER_API_URL,
        setState, 
        requestParams, 
        userToken,
        false,
        callback
    );
}

export const getReservedProperties = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', RESERVED_PROPERTTIES_API_URL, setState, '', userToken);
}

export const getInvoiceList = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', INVOICE_LIST_API_URL, setState, '', userToken);
}

export const getBrokers = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {
    makeRequest('GET', BROKER_MY_TEAM_API_URL+`?id_sort=1`, setState, '', userToken);
}

export const getActiveBroker = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, userToken: string) => {       
    makeRequest('GET', BROKER_MY_TEAM_API_URL+`?id_sort=1&status=active&approvedStatus=1`, setState, '', userToken);
}

export const getTrendingProperties = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>) => {

    //const token = userInfo.access_token;

    const paramsRequest = {
        search_property_type: "",
        exclusive: "trending",
        property_type_id: "",
        building_id: "",
        location:"",
        community_id: "",
        budget_min: "",
        budget_max: "",
        bedroom: "",
        built_in_area_min: "",
        built_in_area_max: "",
        amenity_type_id: "",
        sub_community_id: "",
        sub_locality_id: "",
        emirates_id: "",
        is_offer: "",
        keyword_search: "",
        currentPage : "",
        pageSize : ""
    };

    makeRequest('POST', PROPERTY_FILTER_API_URL, setState, paramsRequest);
}
export const getLatestProperties = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>) => {

    //const token = userInfo.access_token;

    const paramsRequest = {
        search_property_type: "",
        exclusive: "latest",
        property_type_id: "",
        building_id: "",
        location:"",
        community_id: "",
        budget_min: "",
        budget_max: "",
        bedroom: "",
        built_in_area_min: "",
        built_in_area_max: "",
        amenity_type_id: "",
        sub_community_id: "",
        sub_locality_id: "",
        emirates_id: "",
        is_offer: "",
        keyword_search: "",
        currentPage : "",
        pageSize : ""
    };

    makeRequest('POST', PROPERTY_FILTER_API_URL, setState, paramsRequest);
}
export const getAvailableProperties = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>) => {

    //const token = userInfo.access_token;

    const paramsRequest = {
        search_property_type: "",
        exclusive: "available",
        property_type_id: "",
        building_id: "",
        location:"",
        community_id: "",
        budget_min: "",
        budget_max: "",
        bedroom: "",
        built_in_area_min: "",
        built_in_area_max: "",
        amenity_type_id: "",
        sub_community_id: "",
        sub_locality_id: "",
        emirates_id: "",
        is_offer: "",
        keyword_search: "",
        currentPage : "",
        pageSize : ""
    };

    makeRequest('POST', PROPERTY_FILTER_API_URL, setState, paramsRequest);
}


export const getUpcomingProperties = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>) => {
    const paramsRequest = {
        search_property_type: "",
        exclusive: "upcoming",
        property_type_id: "",
        building_id: "",
        location:"",
        community_id: "",
        budget_min: "",
        budget_max: "",
        bedroom: "",
        built_in_area_min: "",
        built_in_area_max: "",
        amenity_type_id: "",
        sub_community_id: "",
        sub_locality_id: "",
        emirates_id: "",
        is_offer: "",
        keyword_search: "",
        currentPage : "",
        pageSize : ""
    };

    makeRequest('POST', PROPERTY_FILTER_API_URL, setState, paramsRequest);
}

export const getMasterData = (setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>) => {
    makeRequest('GET', MASTER_DATA_URL, setState);
}

const makeRequest = async (method = 'GET', URL: string, setState: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    response: any;
    error: string;
}>>, requestParams: any = '', userToken: string = '', formData: boolean = false, callback: Function = () => {}) => {

    try{
        setState({
            loading: true,
            response: [],
            error: ''
        });

        let headers:any = {}

        let body = requestParams;

        if (!formData) {
            headers = {
                'Content-Type': 'application/json',
            }
            body = JSON.stringify(requestParams);
        }

        if (userToken !== "undefined" && userToken !== "") {

            headers = {
                ...headers,
                'Authorization': `Bearer ${userToken}`
            }
        }

        let init = method == "POST" ? {
            method: method,
            headers: headers,
            body: body
        } : {
            method: method,
            headers: headers
        };

        const response = await fetch(URL, init);

        const responseObj = await response.json();
        if(responseObj.code === 200 && responseObj.errorCode == 0){
            setState({
                loading: false,
                response: responseObj.data,
                error: ''
            });
            callback();
        }else{
            if (responseObj.errorDescription !== null && responseObj.errorDescription !== '') {
                throw responseObj.errorDescription;
            } else {
                throw "Server error";
            }
        }
    }catch(err){
        let catchError = "";
        if (typeof err !== "string") {
            catchError = "Server error";
        } else {
            catchError = err;
        }
        //setExtendOfferState({loading: false, error: catchError});
        setState({
            loading: false,
            response: [],
            error: catchError
        });
    }
}

// export const PROPERTY_DETAILS_PAGE_URL = 'https://property.realcube.estate/properties';
// export const PROPERTY_LIST_PAGE_URL = 'https://property.realcube.estate/properties-listing';