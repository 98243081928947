import React from 'react';
import { useTranslation } from 'react-i18next'
import MarketPalace1 from '../../../../../assets/images/market_1.svg';
import MarketPalace2 from '../../../../../assets/images/market_2.svg';
import MarketPalace3 from '../../../../../assets/images/market_3.svg';
import MarketPalace4 from '../../../../../assets/images/market_4.svg';
import { Link } from 'react-router-dom'
import { MarketPlaceCreatePopup } from '../../MarketPlaceCreate/MarketPlaceCreatePopup';
import Loader from '../../../../../components/Loader';
import { MarketPlaceStats } from '../MarketPlaceStats/MarketPlaceStats';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../../../constants/generics/userCheck';
import { checkMarketAdmin } from '../../../marketHandler/marketHandler';

const MarketPlaceHeader = ({ dataCount }: any) => {
    // console.log(dataCount, 'MarketPlaceOffer');
    const { t } = useTranslation();

    const state: any = useSelector(state => state)
    const isAdminUser = checkMarketAdmin(state?.auth?.user?.data)

    return (
        <>
            <div className="com-card-section">
                <div className="row align-items-center mb-6">
                    <div className="col-sm-4">
                        <h4 className="card-title font-18 text-black mb-0">{t("Market Place")}</h4>
                    </div>
                    <div className="col-sm-8 text-end">
                        {/* <MarketPlaceCreatePopup /> */}
                    </div>
                </div>
                {isAdminUser && 
                    <MarketPlaceStats dataCount={dataCount} />
                }
            </div>
        </>
    );
};
export { MarketPlaceHeader };