import React, {useState, useEffect} from 'react'
import BedIcon from "../../../../../../assets/images/bed.svg";
import BathIcon from "../../../../../../assets/images/bath.svg";
import SqftIcon from "../../../../../../assets/images/sqft-new-icon.svg";
import ParkingIcon from "../../../../../../assets/images/yes-new-icon.svg";
import {useTranslation} from 'react-i18next'

export function Facilities({property}: any) {
  const {t} = useTranslation()
  
  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='facility-box'>
            <ul>
              <li>
                <img src={BedIcon} alt='bed-theme' />
                <span className='text-black'> {property?.bedrooms ? property.bedrooms : '-'} </span>
              </li>
              <li>
                <img src={BathIcon} alt='bath-theme' />
                <span className='text-black'>
                  {' '}
                  {property?.bathrooms ? property.bathrooms : '-'}{' '}
                </span>
              </li>
              <li>
                <img src={SqftIcon} alt='sqft-theme' />
                <span className='text-black'>
                  {' '}
                  {property?.builtInArea ? property.builtInArea : '-'} {t('Sqmt')}{' '}
                </span>
              </li>
              <li>
                <img src={ParkingIcon} alt='parking-theme' />
                <span className='text-black'>
                  {' '}
                  {property?.isParkingAvailable === 'Y' ? t('Yes') : t('No')}{' '}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
