import {Link} from 'react-router-dom'
import banner_img from '../../assets/images/banner-img.png'
import NoDataFound from '../../assets/images/no-data-found.svg'
import ArrowRightBlue from '../../assets/images/arrow-right-blue.png'

export function Communitywall() {
  return (
    <>
      <div className='com-card-section'>
        <div className='row mb-6 align-items-center'>
          <div className='col mt-0'>
            <h4 className='card-title font-18 text-black mb-0'> Community Post </h4>
          </div>
          <div className='col-auto mt-0'>
            <Link className='btn btn-primary fw-bold px-10 py-3' to={'create-community-post'}>
              Create Community Post
            </Link>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <div className='card mb-3'>
              <div className='card-body px-8 py-2'>
                <div className='col-12 cpt-tenant-det1'>
                  <ul className='nav nav-pills' id='pills-tab' role='tablist'>
                    <li className='nav-item tab_nav_li' role='presentation'>
                      <button
                        className='nav-link pb-1 position-relative active me-6'
                        id='pills-profile-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#bank-transfer'
                        type='button'
                        role='tab'
                        aria-controls='pills-profile'
                        aria-selected='false'
                      >
                        Events
                      </button>
                    </li>
                    <li className='nav-item tab_nav_li' role='presentation'>
                      <button
                        className='nav-link pb-1 position-relative me-6'
                        id='pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#card-payment'
                        type='button'
                        role='tab'
                        aria-controls='pills-home'
                        aria-selected='true'
                      >
                        Announcements
                      </button>
                    </li>
                    <li className='nav-item tab_nav_li' role='presentation'>
                      <button
                        className='nav-link pb-1 position-relative me-6'
                        id='pills-profile1-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#bank-transfer1'
                        type='button'
                        role='tab'
                        aria-controls='pills-profile1'
                        aria-selected='false'
                      >
                        Offers
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='tab-content' id='pills-tabContent'>
              <div
                className='tab-pane fade show active'
                id='bank-transfer'
                role='tabpanel'
                aria-labelledby='pills-profile-tab'
              >
                <div className='card mb-7 announce-list'>
                  <div className='card-body'>
                    <div className='row mb-4'>
                      <div className='col-12 col-lg-12'>
                        <Link to>
                          <div className='announcements-card c-pointer card mb-3 px-0'>
                            <div className='announcements-card-img p-5 pe-0'>
                              <img
                                src={banner_img}
                                className='img-fluid rounded-0 announce-banner'
                              />
                            </div>
                            <div
                              className='announcements-card-content px-5 d-flex align-items-center'
                              style={{width: '100%'}}
                            >
                              <div className='row' style={{width: '100%'}}>
                                <div className='col-12'>
                                  <div className='row mt-3'>
                                    <div className='col-md-12'>
                                      <h5 className='announcements-title text-black font-16 fw-bolder text-ellipsis mb-1'>
                                        Holiday{' '}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-12'>
                                  <p className='mb-0 fw-bolder font-12'>Polo Residence 1</p>
                                </div>
                                <div className='col-12'>
                                  <p className='fw-normal mt-3 mb-4 font-12 text-desc text-ellipsis'>
                                    Realcube Community Announcement
                                  </p>
                                </div>
                                <div className='col-12'>
                                  <div className='row mb-3 align-items-center '>
                                    <div className='col-12'>
                                      <div className='d-inline rounded-5 py-2 px-0'>
                                        <span className='text-black fw-bolder font-12 pe-1'>
                                          {' '}
                                          21-08-2023{' '}
                                        </span>
                                        <span className='fw-bolder font-12'>
                                          <p className='p-0 m-0 d-inline'> 10:00 AM - 06:00 PM </p>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className='my-auto text-end pe-5'>
                              <img
                                src={ArrowRightBlue}
                                className='img-fluid d-inline left-arrow-back'
                                alt='go'
                              />
                            </div> */}
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 col-lg-12'>
                        <Link to>
                          <div className='announcements-card c-pointer card mb-3 px-0'>
                            <div className='announcements-card-img p-5 pe-0'>
                              <img
                                src={banner_img}
                                className='img-fluid rounded-0 announce-banner'
                              />
                            </div>
                            <div
                              className='announcements-card-content px-5 d-flex align-items-center'
                              style={{width: '100%'}}
                            >
                              <div className='row' style={{width: '100%'}}>
                                <div className='col-12'>
                                  <div className='row mt-3'>
                                    <div className='col-md-12'>
                                      <h5 className='announcements-title text-black font-16 fw-bolder text-ellipsis mb-1'>
                                        Test Video{' '}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-12'>
                                  <p className='mb-0 fw-bolder font-12'>Polo Residence 1</p>
                                </div>
                                <div className='col-12'>
                                  <p className='fw-normal mt-3 mb-4 font-12 text-desc text-ellipsis'>
                                    Realcube Community Announcement
                                  </p>
                                </div>
                                <div className='col-12'>
                                  <div className='row mb-3 align-items-center '>
                                    <div className='col-12'>
                                      <div className='d-inline rounded-5 py-2 px-0'>
                                        <span className='text-black fw-bolder font-12 pe-1'>
                                          {' '}
                                          16-06-2023{' '}
                                        </span>
                                        <span className='fw-bolder font-12'>
                                          <p className='p-0 m-0 d-inline'> 08:00 PM to 11:00 PM </p>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className='my-auto text-end pe-5'>
                              <img
                                src={ArrowRightBlue}
                                className='img-fluid d-inline left-arrow-back'
                                alt='go'
                              />
                            </div> */}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='card-payment'
                role='tabpanel'
                aria-labelledby='pills-home-tab'
              >
                <div className='card mb-7 announce-list'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-12 col-lg-12'>
                        <Link to={{pathname: '/announcements-details'}}>
                          <div className='announcements-card c-pointer card mb-3 px-0'>
                            <div className='announcements-card-img p-5 pe-0'>
                              <img
                                src={banner_img}
                                className='img-fluid rounded-0 announce-banner'
                              />
                            </div>
                            <div
                              className='announcements-card-content px-5 d-flex align-items-center'
                              style={{width: '100%'}}
                            >
                              <div className='row' style={{width: '100%'}}>
                                <div className='col-12'>
                                  <div className='row mt-3'>
                                    <div className='col-md-12'>
                                      <h5 className='announcements-title text-black font-16 fw-bolder text-ellipsis mb-1'>
                                        Realcube
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-12'>
                                  <p className='mb-0 fw-bolder font-12'>Polo Residence 1</p>
                                </div>
                                <div className='col-12'>
                                  <p className='fw-normal mt-3 mb-4 font-12 text-desc text-ellipsis'>
                                    Realcube Community Announcement
                                  </p>
                                </div>
                                <div className='col-12'>
                                  <div className='row mb-3 align-items-center '>
                                    <div className='col-12'>
                                      <div className='d-inline rounded-5 py-2 px-0'>
                                        <span className='text-black fw-bolder font-12 pe-1'>
                                          {' '}
                                          16-08-2023{' '}
                                        </span>
                                        <span className='fw-bolder font-12'>
                                          <p className='p-0 m-0 d-inline'> 10:00 AM to 06:00 PM </p>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className='my-auto text-end pe-5'>
                              <img
                                src={ArrowRightBlue}
                                className='img-fluid d-inline left-arrow-back'
                                alt='go'
                              />
                            </div> */}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='bank-transfer1'
                role='tabpanel'
                aria-labelledby='pills-profile1-tab'
              >
                <div className='card mb-7 announce-list'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-12 col-lg-12'>
                        <Link to={{pathname: '/announcements-details'}}>
                          <div className='announcements-card c-pointer card mb-3 px-0'>
                            <div className='announcements-card-img p-5 pe-0'>
                              <img
                                src={banner_img}
                                className='img-fluid rounded-0 announce-banner'
                              />
                            </div>
                            <div
                              className='announcements-card-content px-5 d-flex align-items-center'
                              style={{width: '100%'}}
                            >
                              <div className='row' style={{width: '100%'}}>
                                <div className='col-12'>
                                  <div className='row mt-3'>
                                    <div className='col-md-12'>
                                      <h5 className='announcements-title text-black font-16 fw-bolder text-ellipsis mb-1'>
                                        Realcube
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-12'>
                                  <p className='mb-0 fw-bolder font-12'>Polo Residence 1</p>
                                </div>
                                <div className='col-12'>
                                  <p className='fw-normal mt-3 mb-4 font-12 text-desc text-ellipsis'>
                                    Realcube Community Announcement
                                  </p>
                                </div>
                                <div className='col-12'>
                                  <div className='row mb-3 align-items-center '>
                                    <div className='col-12'>
                                      <div className='d-inline rounded-5 py-2 px-0'>
                                        <span className='text-black fw-bolder font-12 pe-1'>
                                          {' '}
                                          16-08-2023{' '}
                                        </span>
                                        <span className='fw-bolder font-12'>
                                          <p className='p-0 m-0 d-inline'> 10:00 AM to 06:00 PM </p>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className='my-auto text-end pe-5'>
                              <img
                                src={ArrowRightBlue}
                                className='img-fluid d-inline left-arrow-back'
                                alt='go'
                              />
                            </div> */}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
