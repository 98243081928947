import React, { useEffect } from 'react'
import { useState } from 'react'
import { PhotoViewPopup } from './photoViewPopup'
import { ServiceDataInfo } from './ServiceDataInfo'
import { useTranslation } from 'react-i18next'

export function UndertakingLetterRequestDetail({ selectedIssue }: any) {
  const [images, setImages] = useState([])
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])

  useEffect(() => {
    if (issue && issue?.code == 'UL') {
      setImages(Object.values(issue.images))
    }
  }, [issue])

  return (
    <div className='com-card-section'>
      {!loading && issue && issue?.code == 'UL' ? (
        <div className='row'>
          <div className='col-12'>
            <div className='accordion accordion-flush' id='four'>
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header position-relative' id='flush-headingOne'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapse4'
                    aria-expanded='false'
                    aria-controls='flush-collapseOne'
                  >
                    {t("Undertaking Letter Request Detail")}
                  </button>
                  <span className='services-badge'>
                    {issue?.status == 0 ? t("Open") : t("Closed")}
                  </span>
                </h2>
                <div
                  id='flush-collapse4'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingOne'
                  data-bs-parent='#four'
                >
                  <div className='accordion-body py-5'>
                    <div className='row form-add-design'>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Undertaking Letter")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={issue?.details?.category_type_name}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Supporting Document")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={issue?.images?.photo ? '1' : '0'}
                          />
                          <>
                            <PhotoViewPopup images={[issue?.images?.photo]} />
                          </>
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Signature")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={issue?.images?.signature ? '1' : '0'} />
                          <>
                            <div className="">
                              <button type="button" className="photo-view" data-bs-toggle="modal" data-bs-target="#sign1">
                                <i className="bi bi-eye font-18"></i>
                              </button>
                              <div className="modal fade custom-modal" id="sign1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-sm">
                                  <div className="modal-content rounded-10">
                                    <div className="modal-header p-0 border-0">
                                      <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                      <img src={issue?.images?.signature} className="w-100"></img>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </>
                        </div>
                      </div>
                      {issue?.details?.description &&
                        <div className='col-md-12 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Comments")}</label>
                            <textarea
                              className='form-control fw-bolder form-control-solid'
                              data-kt-autosize='true'
                              rows={3}
                              disabled
                              placeholder={issue?.details?.description}
                            />
                          </div>
                        </div>
                      }
                    </div>
                    <hr />
                    <ServiceDataInfo issue={issue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
