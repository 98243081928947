/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../components/layout/core'
import { MaintenanceList } from './components/MaintenanceList/MaintenanceList'
import { IssuesList } from './components/IssuesList/IssuesList'
import { OpenIssues } from './components/OpenIssues/OpenIssues'
import { ResolvedIssues } from './components/ResolvedIssues/ResolvedIssues'
import { NewRequest } from './add/NewRequest/NewRequest'
import { RequestSuccessfulPopup } from '../SuccessfulPopup/RequestSuccessfulPopup'
import { Route, Switch, useLocation } from 'react-router-dom'
import { getMaintenanceIssues } from '../../constants/axios/apis'
import { IssuesView } from './components/OpenIssues/IssuesView'
import { Reschedule } from './add/Reschedule/Reschedule'
import { useSelector } from 'react-redux'
import { RootState } from '../../constants'

type Props = {
  openIssues?: any
  resolvedIssues?: any
  contract?: any
}

const Maintenance: FC<Props> = ({ contract, openIssues, resolvedIssues }) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
      <div className='col-xxl-12 mt-0'>
        <Switch>
          <Route path='/maintenance/openissues'>
            {contract?.contractStatus != 'Terminated' &&
              <MaintenanceList />
            }
            <IssuesList />
            <OpenIssues issues={openIssues} />
          </Route>
          <Route path='/maintenance/resolvedissues'>
            {contract?.contractStatus != 'Terminated' &&
              <MaintenanceList />
            }
            <IssuesList />
            <ResolvedIssues issues={resolvedIssues} />
          </Route>
          <Route path='/maintenance/newrequest'>
            <NewRequest />
            <RequestSuccessfulPopup />
          </Route>
          <Route path='/maintenance/issues-view/:id'>
            <IssuesView />
          </Route>
          <Route path='/maintenance/reschedule'>
            <Reschedule />
            <RequestSuccessfulPopup />
          </Route>
        </Switch>
      </div>
    </div>
  </>
)

const MaintenancePage: FC = () => {
  const location = useLocation()
  const [openIssues, setOpenIssues] = useState<any>(null)
  const [resolvedIssues, setResolvedIssues] = useState<any>(null)
  const contract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)

  useEffect(() => {
    getMaintenanceIssues('open', contract?.propertyID).then((response) => {
      if (response.data) {
        response.data
          .sort((a: any, b: any) => {
            return new Date(a.created).getTime() - new Date(b.created).getTime()
          })
          .reverse()
        setOpenIssues(response.data)
      } else {
        setOpenIssues([])
      }
    })

    getMaintenanceIssues('resolved', contract?.propertyID).then((response) => {
      if (response.data) {
        response.data
          .sort((a: any, b: any) => {
            return new Date(a.created).getTime() - new Date(b.created).getTime()
          })
          .reverse()
        setResolvedIssues(response.data)
      } else {
        setResolvedIssues([])
      }
    })
  }, [contract, location.key])

  return (
    <>
      <PageTitle breadcrumbs={[]}> Maintenance </PageTitle>
      <Maintenance contract={contract} openIssues={openIssues} resolvedIssues={resolvedIssues} />
    </>
  )
}

export { MaintenancePage }
