import {FC} from 'react'
import { useTranslation } from 'react-i18next';

const Error404: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>{t("Page Not Found")}</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        {t("The page you looked not found!")} 
        {/* <br /> Plan your blog post by choosing a topic */}
      </div>
    </>
  )
}

export {Error404}
