import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import { getUserRole } from '../../../../constants/generics/userCheck';

export function PromotionListing({ marketPlaceList, loading, totalCount, currentPage, setCurrentPage, redeem = false }: any) {
    const { t } = useTranslation();
    const pageSize = 9
    const location = useLocation();
    let activeLink = location.pathname
    let subLink = activeLink.substring(activeLink.lastIndexOf("/") + 1, activeLink.length)

    // const state: any = useSelector(state => state);
    // const user_role = getUserRole(state?.auth?.user?.data);
    // const isAdminUser = user_role ? ['MA', 'MT'].includes(user_role) : false

    // const location = useLocation();

    // console.log(marketPlaceList, 'marketPlaceList');
    let currentTime = new Date().getTime();

    return (
        <div className="com-card-section">
            <div className="row gx-8 gy-2 pt-6">
                {loading ?
                    <CustomLoader2 height={'166'} />
                    :
                    marketPlaceList?.length ?
                        <>
                            {
                                marketPlaceList?.map((item: any, index: number) => {
                                    const mpDate = new Date(item.end_date + " " + item.end_time).getTime();
                                    // console.log(mpDate, currentTime, currentTime <= mpDate);

                                    const isExpired = item.end_date ? currentTime > mpDate : true
                                    return (
                                        <div className="col-lg-4 col-sm-6" key={index}>
                                            <div className="card mb-6 shadow-sm">
                                                <div className="card-body p-0">
                                                    <div className="row">
                                                        <div className="col-md-12 col-12">
                                                            <div className="offer-section">
                                                                <Link
                                                                    to={{
                                                                        pathname: '/promotions/view',
                                                                        state: { 'marketData': { 'id': item.id } }
                                                                    }}
                                                                >
                                                                    <img src={item?.image?.length && Array.isArray(item?.image) ? item?.image[0] : ''}
                                                                        alt="offer-img-01" className="d-block w-100 offer-img" />
                                                                </Link>
                                                                {item.logo &&
                                                                    <div className="offer-icon">
                                                                        <img src={item.logo} alt="Offer" className="img-fluid" />
                                                                    </div>
                                                                }
                                                                <div className="offer-details p-4">
                                                                    <div className="d-flex flex-row justify-content-between mb-4">
                                                                        <div>
                                                                            {item.type !== 'Event' &&
                                                                                <span className="offer-price fw-bolder">{item.offer ? item.offer + ' % OFF' : ''}</span>
                                                                            }
                                                                        </div>
                                                                        <div className='text-end'>
                                                                            {/* {item.statusText !== 'approved' &&  */}
                                                                                <span className={`offer-exclusive ${item.statusText === 'approved' ? 'bg-success' : item.statusText === 'pending' ? 'bg-warning' : 'bg-danger'} me-2`}>{item.statusText ? item.statusText : '-'}</span>
                                                                            {/* } */}
                                                                            {/* <span className={`offer-exclusive ${item.statusText === 'approved' ? 'bg-success' : 'bg-warning'} me-2`}>{item.statusText ? item.statusText : '-'}</span> */}
                                                                            {isExpired &&
                                                                                <span className="offer-exclusive bg-danger me-2">{t("Expired")}</span>
                                                                            }
                                                                            { subLink==='redeemed' && 
                                                                                <span className="offer-exclusive me-2">{item.type ? item.type : '-'}</span>
                                                                            }
                                                                            <span className="offer-exclusive">{item.category_name ? item.category_name : '-'}</span>
                                                                        </div>


                                                                    </div>
                                                                    <div className="offer-desc">
                                                                        <Link
                                                                            // to={'/market-place/marketplaceofferdetails'}
                                                                            to={{
                                                                                pathname: '/promotions/view',
                                                                                state: { 'marketData': { 'id': item.id } }
                                                                            }}
                                                                            className="text-decoration-none">
                                                                            <p className="mb-0">{item.name ? item.name : '-'}</p>
                                                                            {/* <p className="mb-0">{'-'}</p> */}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <CompactPagination
                                pageSize={pageSize}
                                totalCount={parseInt(totalCount)}
                                defaultPage={currentPage}
                                onPageChange={setCurrentPage}
                                loading={loading}
                            />
                        </>
                        :
                        <NoDataFound1 height={'110'} />
                }
            </div>
        </div>
    );
}