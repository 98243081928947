import React from 'react';
import { Link } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { useTranslation } from 'react-i18next'

export function CTPermitToWork({ services }: any) {
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className='row'>
                <div className='col-12'>
                    <h4 className='card-title font-18 text-black mb-6'>
                        <Link to={'/my-services/openissues'} className=''>
                            <img src={LeftArrow} alt='back' className='img-fluid me-5' />
                            <span className='text-black'>{t("Permit To Work")}</span>
                        </Link>
                    </h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-6">
                            <div className='row'>
                                <div className='col-12 mb-7'>
                                    <h4 className='card-title font-14'>{t("Permit Type")}</h4>
                                </div>
                            </div>
                            <div className="row">
                                {services?.map((service: any) => (
                                    <div className='col-sm-3 col-6' key={service.id}>
                                        <Link to={{ pathname: '/my-services/' + service.id }}>
                                            <div className='card mb-5'>
                                                <div className='card-body py-5 px-5 border rounded'>
                                                    <div className='row g-0 align-items-center'>
                                                        <div className='col-4'>
                                                            <img src={service.icon} alt='service-img' className='img-fluid' />
                                                        </div>
                                                        <div className='col-8'>
                                                            <h6 className='mb-0 text-gray fw-normal font-13'>{service.name}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}