import React, { useState, useRef, useEffect } from 'react'
import PlusIcon from '../../../../assets/images/plus-circle.svg'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import DatePicker from 'react-date-picker'
import PhoneInput from 'react-phone-input-2'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import uploadImg from '../../../../assets/images/file-upload.svg'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

const today = new Date()
today.setHours(0, 0, 0, 0)

export function WorkInformation({ contract, setWorkInformation, nextRef }: any) {
  const [saved, setSaved] = useState<any>(false)
  const [dirty, setIsDirty] = useState<any>(false)
  const [isContractor, setIsContractor] = useState(0)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [uploadedLicenseFileName, setUploadedLicenseFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const inputLicenceRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation();

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleLicenseUpload = () => {
  //   inputLicenceRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   formik.setFieldValue('photo', e.currentTarget.files[0])
  // }

  // const handleDisplayLicenseFileDetails = (e: any) => {
  //   inputLicenceRef.current?.files &&
  //     setUploadedLicenseFileName(inputLicenceRef.current.files[0].name)
  //   formik.setFieldValue('trading_licence', e.currentTarget.files[0])
  // }

  const initialValues = {
    is_contractor: isContractor,
    contractor_name: '',
    contractor_phone: '',
    contractor_company: '',
    category_id: 14,
    start_date: today,
    end_date: today,
    start_time: '',
    end_time: '',
    description: '',
    property_id: contract?.propertyID,
    photo: '',
    trading_licence: '',
    contractor_id: 1,
  }

  const handleStartDate = (e: any) => {
    formik.setFieldTouched('start_date')
    formik.setFieldValue('start_date', new Date(e))
  }

  const handleEndDate = (e: any) => {
    formik.setFieldTouched('end_date')
    formik.setFieldValue('end_date', new Date(e))
  }

  const handelSubmit = () => {
    setSaved(false)
    setWorkInformation(null)
    formik.handleSubmit()
  }

  const generalPTWRequestSchema = Yup.object().shape({
    description: Yup.string().required(t("Please provide a description of work")).max(250, t("Maximum 250 characters")),
    start_date: Yup.date()
      .min(today, t("Starting date cannot be in the past"))
      .required(t("Please select a starting date")),
    end_date: Yup.date()
      .min(Yup.ref('start_date'), t("Ending date cannot be before starting date"))
      .required(t("Please select an ending date")),
    start_time: Yup.string().required(t("Please select a starting time")),
    end_time: Yup.string().required(t("Please select an ending time"))
      .when('start_time', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf([Yup.ref('start_time')], t("Start time and end time cannot be the same")),
      }),
    contractor_name: Yup.string().when('is_contractor', {
      is: '1',
      then: Yup.string()
        .min(3, t("Minimum 3 characters"))
        .max(30, t("Maximum 30 characters"))
        .required(t("Contractor name is required"))
        .test('filled', t("Contractor name cannot contain only blankspaces"), (value) => {
          if (value) {
            return (
              value.trim().length !== 0
            )
          }
          return true
        })
        .matches(/^[^\s].*/, t("Name cannot start with a blankspace"))
        .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    }),
    contractor_phone: Yup.string().when('is_contractor', {
      is: '1',
      then: Yup.string().required(t("Contractor phone is required")).matches(/^\+?[0-9]+$/, t("Invalid format"))
    }),
    contractor_company: Yup.string().when('is_contractor', {
      is: '1',
      then: Yup.string()
        .min(3, t("Minimum 3 characters"))
        .max(30, t("Maximum 30 characters"))
        .required(t("Company name is required"))
        .test('filled', t("Company name cannot contain only blankspaces"), (value) => {
          if (value) {
            return (
              value.trim().length !== 0
            )
          }
          return true
        })
        .matches(/^[^\s].*/, t("Company name cannot start with a blankspace"))
        .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    }),
    trading_licence: Yup.mixed().when('is_contractor', {
      is: '1',
      then: Yup.mixed()
        .required(t("Trading license is required"))
        .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf"), (value) => {
          return (
            value &&
            (value.type === 'image/jpeg' ||
              value.type === 'image/jpg' ||
              value.type === 'image/png' ||
              value.type === 'application/pdf')
          )
        })
        .test('fileSize', t('The file is too large! Max allowed size is 5MB.'), (value) => {
          if (!value?.length) return true
          return value[0]?.size <= 5242880
        }),
      otherwise: Yup.string(),
    }),
    // photo: Yup.mixed().test('fileSize', t("The file is too large!"), (value) => {
    //   if (!value?.length) return true
    //   return value[0]?.size <= 2000000
    // }),
    status: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
  })

  const formik = useFormik({
    initialValues,
    isInitialValid: false,
    validationSchema: generalPTWRequestSchema,
    onSubmit: (values) => {
      setWorkInformation({
        is_contractor: values.is_contractor,
        contractor_name: values.contractor_name,
        contractor_phone: values.contractor_phone,
        contractor_company: values.contractor_company,
        category_id: values.category_id,
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        end_date: moment(values.end_date).format('YYYY-MM-DD'),
        start_time: values.start_time,
        end_time: values.end_time,
        description: values.description,
        property_id: values.property_id,
        photo: values.photo,
        trading_licence: values.trading_licence,
        contractor_id: values.contractor_id,
        saved: true
      })
      setSaved(true)
      setIsDirty(false)
      nextRef.current.click()
    },
  })

  const handleContractorChange = (e: any) => {
    setIsContractor(e.target.value)
    formik.handleChange(e)
  }

  const handlePhoneNumber = (phoneNumber: any) => {
    formik.setFieldValue('contractor_phone', phoneNumber)
    formik.setFieldTouched('contractor_phone')
  }

  useEffect(() => {
    if (formik.values.start_date.getTime() === today.getTime()) {
      if (moment(formik.values.start_time, 'HH:mm').isBefore(moment())) {
        formik.setFieldError('start_time', t("Start time cannot be in the past"))
      } else {
        formik.setFieldError('start_time', '')
      }
    }
  }, [formik.values.start_date, formik.values.start_time])

  useEffect(() => {
    if (moment(formik.values.end_time, 'HH:mm').isBefore(moment(formik.values.start_time, 'HH:mm'))) {
      formik.setFieldError('end_time', t("End time cannot be before start time"))
    } else {
      formik.setFieldError('end_time', '')
    }
  }, [formik.values.start_time, formik.values.end_time])

  const handleStartTimeChange = (e: any) => {
    formik.setFieldTouched('start_time', true, false);
    formik.setFieldValue('start_time', e.target.value, false);
  }

  const handleEndTimeChange = (e: any) => {
    formik.setFieldTouched('end_time', true, false);
    formik.setFieldValue('end_time', e.target.value, false);
  }

  useEffect(() => {
    setIsDirty(true)
  }, [formik.values])

  useEffect(() => {
    if (dirty) {
      setSaved(false)
      setWorkInformation((currentState: any) => { return { ...currentState, saved: false } })
    }
  }, [dirty])

  const [uploading, setUploading] = useState(false)
  const handleLicenseUpload = () => {
    inputLicenceRef.current?.click()
  }

  const handleDisplayLicenseFileDetails = (e: any) => {    
    if(e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf'){      
      inputLicenceRef.current?.files && setUploadedLicenseFileName(inputLicenceRef.current.files[0].name)        
      formik.setFieldTouched('trading_licence')
      formik.setFieldValue('trading_licence', e.currentTarget.files[0])
    }else{
      setUploading(true)
      formik.setFieldError('trading_licence', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputLicenceRef.current?.files && setUploadedLicenseFileName(compressResponse.file.name);
            formik.setFieldTouched('trading_licence', true, false)
            formik.setFieldValue('trading_licence', compressResponse.file, false)
          } else {
            setUploadedLicenseFileName(null)
            formik.setFieldTouched('trading_licence', true, false)
            formik.setFieldValue('trading_licence', '', false)
            formik.setFieldError('trading_licence', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedLicenseFileName(null)
        formik.setFieldTouched('trading_licence')
        formik.setFieldValue('trading_licence', '', false)
        formik.setFieldError('trading_licence', t('File upload error!'))
        setUploading(false)
      }
    }
   }

   const [uploading1, setUploading1] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading1(true)
    formik.setFieldError('photo', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('photo')
          formik.setFieldValue('photo', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('photo', true, false)
          formik.setFieldValue('photo', '', false)
          formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading1(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', '', false)
      formik.setFieldError('photo', t('File upload error!'))
      setUploading1(false)
    }
  }

  return (
    <div className='col-12'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='row documents-card'>
          <div className='col-md-12 col-12'>
            <div className='mb-10' id='tenant-work-Information'>
              <div className='row form-add-design'>
                <div className='col-12'>
                  <label className='form-label fw-bolder mb-5'>
                    {t("Are you using a contractor to carry out work?")}
                  </label>
                </div>
                <div className='col-12'>
                  <div className='form-check form-check-inline me-10'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='is_contractor'
                      id='inlineRadio1'
                      value='1'
                      checked={isContractor == 1}
                      autoComplete='off'
                      onChange={(e) => handleContractorChange(e)}
                      onBlur={formik.handleBlur}
                    />
                    <label className='form-check-label' htmlFor='inlineRadio1'>
                      {t("Yes")}
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='is_contractor'
                      id='inlineRadio2'
                      value='0'
                      checked={isContractor == 0}
                      autoComplete='off'
                      onChange={(e) => handleContractorChange(e)}
                      onBlur={formik.handleBlur}
                    />
                    <label className='form-check-label' htmlFor='inlineRadio2'>
                      {t("No")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-sm-4 col-xl-3 col-12'>
            <div className='mb-10 date-picker'>
              <label className='form-label fw-bolder'>{t("Work Start Date")}<span className='text-danger'>*</span></label>
              <DatePicker
                onChange={(e: any) => handleStartDate(e)}
                value={formik.values.start_date}
                minDate={new Date(new Date().toLocaleDateString())}
                className={clsx(
                  'form-control fw-bolder form-control-solid',
                  {
                    'is-invalid': formik.touched.start_date && formik.errors.start_date,
                  },
                  {
                    'is-valid': formik.touched.start_date && !formik.errors.start_date,
                  }
                )}
                format='dd-MM-y'
                clearIcon={null}
                maxDate={new Date('12/31/2999')}
              />
              {formik.touched.start_date && formik.errors.start_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.start_date}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-md-3 col-sm-4 col-xl-3 col-12'>
            <div className='mb-10 date-picker'>
              <label className='form-label fw-bolder'>{t("Work End Date")}<span className='text-danger'>*</span></label>
              <DatePicker
                onChange={(e: any) => handleEndDate(e)}
                value={formik.values.end_date}
                minDate={new Date(new Date().toLocaleDateString())}
                className={clsx(
                  'form-control fw-bolder form-control-solid',
                  {
                    'is-invalid': formik.touched.end_date && formik.errors.end_date,
                  },
                  {
                    'is-valid': formik.touched.end_date && !formik.errors.end_date,
                  }
                )}
                format='dd-MM-y'
                clearIcon={null}
                maxDate={new Date('12/31/2999')}
              />
              {formik.touched.end_date && formik.errors.end_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.end_date}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-md-3 col-sm-4 col-xl-3 col-12'>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>{t("Work Start Time")}<span className='text-danger'>*</span></label>
              <input
                type='time'
                className={clsx(
                  'form-control fw-bolder form-control-solid',
                  {
                    'is-invalid': formik.touched.start_time && formik.errors.start_time,
                  },
                  {
                    'is-valid': formik.touched.start_time && !formik.errors.start_time,
                  }
                )}
                name="start_time"
                value={formik.values.start_time}
                onChange={(e) => handleStartTimeChange(e)}
                placeholder=''
              />
              {formik.touched.start_time && formik.errors.start_time && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.start_time}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-md-3 col-sm-4 col-xl-3 col-12'>
            <div className='mb-0'>
              <label className='form-label fw-bolder'>{t("Work End Time")}<span className='text-danger'>*</span></label>
              <input
                type='time'
                className={clsx(
                  'form-control fw-bolder form-control-solid',
                  {
                    'is-invalid': formik.touched.end_time && formik.errors.end_time,
                  },
                  {
                    'is-valid': formik.touched.end_time && !formik.errors.end_time,
                  }
                )}
                name="end_time"
                value={formik.values.end_time}
                onChange={(e) => handleEndTimeChange(e)}
                placeholder=''
              />
              {formik.touched.end_time && formik.errors.end_time && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.end_time}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-12'>
            <div className='mb-10'>
              <label className='form-label fw-bolder mb-5'>{t("Complete Description of Work")}<span className='text-danger'>*</span></label>
              <textarea
                className='form-control fw-bolder form-control-solid'
                data-kt-autosize='true'
                name='description'
                rows={3}
                placeholder={t("Type here...")}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.description}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isContractor == 1 ? (
            <><div className='col-md-4 col-sm-6 col-xl-4 col-12'>
              <div className='mb-10'>
                <label className='form-label fw-bolder'>{t("Contractor Name")}<span className='text-danger'>*</span></label>
                <input
                  className='form-control fw-bolder form-control-solid'
                  name='contractor_name'
                  autoComplete='off'
                  value={formik.values.contractor_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
                {formik.touched.contractor_name && formik.errors.contractor_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.contractor_name}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div><div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>{t("Contractor Phone")}<span className='text-danger'>*</span></label>
                  <PhoneInput
                    country={'ae'}
                    preferredCountries={['ae']}
                    // onlyCountries={['ae']}
                    priority={{ ae: 0, in: 1, us: 2 }}
                    value={formik.values.contractor_phone}
                    onChange={handlePhoneNumber}
                    enableSearch={true}
                    enableAreaCodeStretch={true}
                    inputClass={'text-black'}
                  />
                  {formik.touched.contractor_phone && formik.errors.contractor_phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.contractor_phone}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>{t("Contractor Company")}<span className='text-danger'>*</span></label>
                  <input
                    className='form-control fw-bolder form-control-solid'
                    name='contractor_company'
                    autoComplete='off'
                    value={formik.values.contractor_company}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                  {formik.touched.contractor_company && formik.errors.contractor_company && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.contractor_company}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div></>
          ) : ''}
          {isContractor == 0 ? (
            <div className='col-lg-4 col-12'>
              <div className='mb-3 upload-input'>
                <label className='form-label font-14 fw-bold d-block mb-5'>
                  {' '}
                  {t("Upload Document (if any)")}{' '}
                </label>
                <input
                  ref={inputRef}
                  onChange={(e) => handleDisplayFileDetails(e)}
                  className='d-none'
                  type='file'
                  accept=".png,.jpg,.jpeg"
                />
                <button
                  type='button'
                  onClick={handleUpload}
                  disabled={uploading1}
                  className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                  }`}
                >
                  <span>
                    {uploading1 ?
                      <span className='indicator-progress d-block'>
                        {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                      : uploadedFileName ? (
                        uploadedFileName
                      ) : (
                        <img src={PlusIcon} className='img-fluid va-baseline' />
                      )}{' '}
                  </span>
                  {/* <span>
                    {' '}
                    {uploadedFileName ? (
                      uploadedFileName
                    ) : (
                      <img src={PlusIcon} className='img-fluid va-baseline' />
                    )}{' '}
                  </span> */}
                </button>
              </div>
              {formik.touched.photo && formik.errors.photo && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.photo}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className='col-lg-4 col-12'>
                <div className='mb-3 upload-input'>
                  <label className='form-label font-14 fw-bold d-block mb-5'>
                    {' '}
                    {t("Upload Document (if any)")}{' '}
                  </label>
                  <input
                    ref={inputRef}
                    onChange={(e) => handleDisplayFileDetails(e)}
                    className='d-none'
                    type='file'
                    accept=".png,.jpg,.jpeg"
                  />
                  <button
                    type='button'
                    onClick={handleUpload}
                    disabled={uploading1}
                    className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                    }`}
                  >
                    {/* <span>
                      {' '}
                      {uploadedFileName ? (
                        uploadedFileName
                      ) : (
                        <img src={PlusIcon} className='img-fluid va-baseline' />
                      )}
                    </span> */}
                    <span>
                    {uploading1 ?
                      <span className='indicator-progress d-block'>
                        {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                      : uploadedFileName ? (
                        uploadedFileName
                      ) : (
                        <img src={PlusIcon} className='img-fluid va-baseline' />
                      )}{' '}
                  </span>
                  </button>
                  {formik.touched.photo && formik.errors.photo && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.photo}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className='mb-0 upload-input'>
                  <label className='form-label font-14 fw-bold d-block mb-5'>
                    {t("Supplier/Contractor Trade license")}<span className='text-danger'>*</span>
                  </label>
                  <input
                    ref={inputLicenceRef}
                    onChange={(e) => handleDisplayLicenseFileDetails(e)}
                    className='d-none'
                    type='file'
                  />
                  <button
                    type='button'
                    onClick={handleLicenseUpload}
                    disabled={uploading}
                    className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                    }`}
                  >
                    {/* <span>
                      {' '}
                      {uploadedLicenseFileName ? (
                        uploadedLicenseFileName
                      ) : (
                        <img src={PlusIcon} className='img-fluid va-baseline' />
                      )}
                    </span> */}
                      {uploading ?
                        <span className='indicator-progress d-block'>
                          {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        : uploadedLicenseFileName ? (
                          uploadedLicenseFileName
                        ) : (
                          <span className="txt">
                            <img
                                    alt="File"
                                    src={uploadImg}
                            className="position-relative"
                            loading="lazy"/>
                            {t("Upload")}{' '}
                          </span>
                        )}{' '}
                  </button>
                  {formik.touched.trading_licence && formik.errors.trading_licence && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.trading_licence}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="col-lg-4 col-12 text-end mt-12">
            <button
              type='button'
              onClick={handelSubmit}
              disabled={!dirty || uploading || uploading1}
              className='btn btn-primary fw-bold px-10 py-3'
              style={{ marginTop: '35px' }}
            >
              {saved ? t("Saved") : t("Save")}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
