import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'

export function AddressDetails() {
    return (
        <div className="row">
            <div className="col-12">
                <div className="row form-add-design">
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">Address</label>
                      <textarea
                        className='form-control resize-none '
                        rows={2}
                        placeholder='Address'
                      ></textarea>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">Office Number</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">P.O Box</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">CIty</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='mb-5'>
                      <label className="font-14 fw-bold mb-3 text-black d-block">Country</label>
                      <input type="text" 
                        className="form-control fw-bolder bg-secon" 
                        placeholder="" 
                      />
                    </div>
                  </div>
                  {/* <div className="col-12 text-end">
                      <button className='btn btn-outline-primary fw-bold px-10 py-3 me-5'>
                          Cancel Offer
                      </button>
                      <button className='btn btn-primary fw-bold px-10 py-3'>
                          Submit
                      </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
}