import moment from 'moment'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { PhotoViewPopup } from './photoViewPopup'
import { ServiceDataInfo } from './ServiceDataInfo'
import { useTranslation } from 'react-i18next'

export function GeneralPTWRequestDetail({ selectedIssue }: any) {
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      {!loading && issue && issue?.code == 'GPTW' ? (
        <div className='row'>
          <div className='col-12'>
            <div className='accordion accordion-flush' id='five'>
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header position-relative' id='flush-headingOne'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapse5'
                    aria-expanded='false'
                    aria-controls='flush-collapseOne'
                  >
                    {t("General PTW Request Detail")}
                  </button>
                  <span className='services-badge'>
                    {issue?.status == 0 ? 'Open' : 'Closed'}
                  </span>
                </h2>
                <div
                  id='flush-collapse5'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingOne'
                  data-bs-parent='#five'
                >
                  <div className='accordion-body py-5'>
                    <div className='row form-add-design'>
                      <div className='col-12'>
                        <div className='mb-10'>
                          <h5 className='card-title font-18 text-black mb-5'>{t("Information")} </h5>
                          <div className='form-check custom d-flex ps-0'>
                            <label
                              className='form-check-label font-14 text-black fw-bolder'
                              htmlFor='req-one'
                            >
                              {t("Are you using a contractor to carry out work?")}
                            </label>
                            <input
                              className='form-check-input ms-5'
                              checked={true}
                              type='checkbox'
                              id='req-one'
                            />
                            <b className='px-2'>{issue?.details?.is_contractor == 1 ? 'Yes' : 'No'}</b>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Work Start Date")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.details?.start_date).format('DD-MM-YYYY')}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Work End Date")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.details?.end_date).format('DD-MM-YYYY')}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Work Start Time")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.details?.start_time, 'HH:mm  A').format('HH:mm')}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Work End Time")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.details?.end_time, 'HH:mm  A').format('HH:mm')}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Supporting Documents")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            // value={issue?.images?.supp_document.length}
                            value={Array.isArray(issue?.images?.supp_document)
                              ? issue?.images?.supp_document.length
                              : issue?.images?.supp_document
                                ? 1
                                : 0}
                          />
                          <>
                            {issue?.images?.supp_document &&
                              Array.isArray(issue?.images?.supp_document) &&
                              issue?.images?.supp_document.length ? (
                              <PhotoViewPopup images={issue?.images?.supp_document} />
                            ) : ''}
                          </>
                          <>
                            {issue?.images?.supp_document &&
                              !Array.isArray(issue?.images?.supp_document) &&
                              issue?.images?.supp_document.length ? (
                              <PhotoViewPopup images={[issue?.images?.supp_document]} />
                            ) : ''}
                          </>
                        </div>
                      </div>
                      {issue?.details?.is_contractor == 1 ? (
                        <div className='col-md-4 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>
                              {t("Supplier/Contractor Trade license")}
                            </label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              disabled
                              value={
                                Array.isArray(issue?.images?.trade_license)
                                  ? issue?.images?.trade_license.length
                                  : issue?.images?.trade_license
                                    ? 1
                                    : 0
                              }
                            />
                            <>
                              {issue?.images?.trade_license &&
                                issue?.images?.trade_license.length ? (
                                <PhotoViewPopup images={[issue?.images?.trade_license]} />
                              ) : ''}
                            </>
                          </div>
                        </div>
                      ) : ''}
                      {issue?.details?.is_contractor == 1 ? (
                        <>
                          <div className='col-md-4 col-12'>
                            <div className='mb-10'>
                              <label className='form-label fw-bolder'>{t("Contractor Name")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                disabled
                                value={issue?.details?.contractor_name}
                              />
                            </div>
                          </div>
                          <div className='col-md-4 col-12'>
                            <div className='mb-10'>
                              <label className='form-label fw-bolder'>{t("Contractor Phone")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                disabled
                                value={issue?.details?.contractor_phone}
                              />
                            </div>
                          </div>
                          <div className='col-md-4 col-12'>
                            <div className='mb-10'>
                              <label className='form-label fw-bolder'>{t("Contractor Company")}</label>
                              <input
                                type='text'
                                className='form-control fw-bolder form-control-solid'
                                disabled
                                value={issue?.details?.contractor_company}
                              />
                            </div>
                          </div>
                        </>
                      ) : ''}
                      <div className='col-md-12 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Work Description")}</label>
                          <textarea
                            className='form-control fw-bolder form-control-solid'
                            data-kt-autosize='true'
                            rows={3}
                            disabled
                            placeholder={issue?.details?.description}
                          />
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='mb-10'>
                          <h5 className='card-title font-18 text-black'>
                            {t("Safety and Security Precautions")}
                          </h5>
                        </div>
                      </div>
                      {issue?.details?.safety_prec_name?.map((item: any, index: any) => (
                        <div className='col-12 col-md-4' key={index}>
                          <div className='mb-10'>
                            <div className='form-check custom '>
                              <label
                                className='form-check-label font-14 text-black fw-bolder mx-2'
                                htmlFor='req-2'
                              >
                                {item}
                              </label>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='req-2'
                                checked
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <hr />
                    <ServiceDataInfo issue={issue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
