import React, { useState, useRef, useEffect } from 'react';
import PlusIcon from "../../../../assets/images/plus-circle.svg";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import completedStep from '../../../../assets/images/completed-step.png'
import { ViewPopup } from '../../../pt-documents/components/ViewPopup/ViewPopup';
import pdfImage from '../../../../assets/images/documentImage.png'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

const healthClubRegistrationSchema = Yup.object().shape({
  emirates_id_front: Yup.mixed()
    .required('Emirates ID is required')
    .test('type', 'Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf', (value) => {
      return (
        value &&
        (value.type === 'image/jpeg' ||
          value.type === 'image/jpg' ||
          value.type === 'image/png' ||
          value.type === 'application/pdf')
      )
    })
    .test('fileSize', 'The file is too large, must be less than 2 mb', (value) => {
      if (!value) return true
      return value?.size <= 2000000
    }),
  emirates_id_back: Yup.mixed()
    .required('Emirates ID is required')
    .test('type', 'Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf', (value) => {
      return (
        value &&
        (value.type === 'image/jpeg' ||
          value.type === 'image/jpg' ||
          value.type === 'image/png' ||
          value.type === 'application/pdf')
      )
    })
    .test('fileSize', 'The file is too large, must be less than 2 mb', (value) => {
      if (!value) return true
      return value?.size <= 2000000
    }),
  passport_front: Yup.mixed()
    .required('Passport copy is required')
    .test('type', 'Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf', (value) => {
      return (
        value &&
        (value.type === 'image/jpeg' ||
          value.type === 'image/jpg' ||
          value.type === 'image/png' ||
          value.type === 'application/pdf')
      )
    })
    .test('fileSize', 'The file is too large, must be less than 2 mb', (value) => {
      if (!value) return true
      return value?.size <= 2000000
    }),
  passport_back: Yup.mixed()
    .required('Passport copy is required')
    .test('type', 'Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf', (value) => {
      return (
        value &&
        (value.type === 'image/jpeg' ||
          value.type === 'image/jpg' ||
          value.type === 'image/png' ||
          value.type === 'application/pdf')
      )
    })
    .test('fileSize', 'The file is too large, must be less than 2 mb', (value) => {
      if (!value) return true
      return value?.size <= 2000000
    }),
  photo: Yup.mixed()
    .required('Photo is required')
    .test('type', 'Only the following formats are acceptable: .jpeg, .jpg, .png, .pdf', (value) => {
      return (
        value &&
        (value.type === 'image/jpeg' ||
          value.type === 'image/jpg' ||
          value.type === 'image/png' ||
          value.type === 'application/pdf')
      )
    })
    .test('fileSize', 'The file is too large, must be less than 2 mb', (value) => {
      if (!value) return true
      return value?.size <= 2000000
    }),

})

export function Documents({ stepStatus, setDocuments, issue, docRef }: any) {

  const [isSaved, setSaved] = useState<any>(false)
  const [dirty, setIsDirty] = useState<any>(false)
  const { t } = useTranslation();

  const initialValues = {
    emirates_id_front: '',
    emirates_id_back: '',
    passport_front: '',
    passport_back: '',
    photo: '',
    spouse_photo: '',
    child_photo: '',
  }
  const handelSubmit = () => {
    setSaved(false)
    setDocuments(null)
    formik.handleSubmit()
  }


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: healthClubRegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setDocuments({
        emirates_id_front: values.emirates_id_front,
        emirates_id_back: values.emirates_id_back,
        passport_front: values.passport_front,
        passport_back: values.passport_back,
        photo: values.photo,
        spouse_photo: values.spouse_photo,
        child_photo: values.child_photo,
        saved: true,
      })
      setSaved(true)
      setIsDirty(false)
      docRef.current.click()
    },
  })

  const [uploadedFileName1, setUploadedFileName1] = useState<string | null>(null);
  const [uploadedFileName2, setUploadedFileName2] = useState<string | null>(null);
  const [uploadedFileName3, setUploadedFileName3] = useState<string | null>(null);
  const [uploadedFileName4, setUploadedFileName4] = useState<string | null>(null);
  const [uploadedFileName5, setUploadedFileName5] = useState<string | null>(null);
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const inputRef5 = useRef<HTMLInputElement>(null);
  const [viewImage, setViewImage] = useState<any>()

  const handleDisplayFileDetails1 = (e: any) => {
    inputRef1.current?.files &&
      setUploadedFileName1(inputRef1.current.files[0].name)

    formik.setFieldTouched('emirates_id_front')
    formik.setFieldValue('emirates_id_front', e.currentTarget.files[0])
  }
  const handleDisplayFileDetails2 = (e: any) => {
    inputRef2.current?.files &&
      setUploadedFileName2(inputRef2.current.files[0].name)
    formik.setFieldTouched('emirates_id_back')
    formik.setFieldValue('emirates_id_back', e.currentTarget.files[0])
  }
  const handleDisplayFileDetails3 = (e: any) => {
    inputRef3.current?.files &&
      setUploadedFileName3(inputRef3.current.files[0].name)
    formik.setFieldTouched('passport_front')
    formik.setFieldValue('passport_front', e.currentTarget.files[0])
  }
  const handleDisplayFileDetails4 = (e: any) => {
    inputRef4.current?.files &&
      setUploadedFileName4(inputRef4.current.files[0].name)
    formik.setFieldTouched('passport_back')
    formik.setFieldValue('passport_back', e.currentTarget.files[0])
  }
  const handleDisplayFileDetails5 = (e: any) => {
    inputRef5.current?.files &&
      setUploadedFileName5(inputRef5.current.files[0].name)
    formik.setFieldTouched('photo')
    formik.setFieldValue('photo', e.currentTarget.files[0])
  }

  const handleView = (data: any) => {
    setViewImage(data)
  }

  useEffect(() => {
    setIsDirty(true)
  }, [formik.values])

  useEffect(() => {
    if (dirty) {
      setSaved(false)
      setDocuments((currentState: any) => { return { ...currentState, saved: false } })
    }
  }, [dirty])

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button className="accordion-button collapsed" ref={docRef} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          {(isSaved || stepStatus) &&
            <span className='completed-step ps-3 pe-3 d-inline-block'>
              <img src={completedStep} alt='complete-step' />
            </span>}
          {t("Documents")}
        </button>
      </h2>
      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
        <div className="accordion-body bg-white">
          <div className='col-12'>
            {stepStatus ? (
              <div className='col-12'>
                <div className='row mb-5'>
                  <div className='col-md-6 col-12'>
                    <div className='row documents-card form-add-design'>
                      <div className="col-md-12 col-12">
                        <h4 className="card-title font-18 text-black mb-6">
                          {t("Emirates ID")}
                        </h4>
                      </div>
                      <div className="col-12">
                        <div className='row'>
                          <div className='col-md-6 col-sm-6 col-xl-6 col-12'>
                            <div className='mb-3 upload-input'>
                              <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Front")} </label>
                              <div className="position-relative">
                                <div className="">
                                  {issue?.images?.emiratesID_front ?
                                    <>
                                      <div className="text-center">
                                        <button
                                          className='border-0 bg-transparent upload-image mt-0 w-100'
                                          data-bs-target='#view-popUp'
                                          data-bs-toggle='modal'
                                          data-bs-dismiss='modal'
                                          type='button'
                                          onClick={() => {
                                            handleView({
                                              filename: Array.isArray(issue?.images?.emiratesID_front)
                                                ?
                                                issue?.images?.emiratesID_front[0]
                                                :
                                                issue?.images?.emiratesID_front
                                            })
                                          }}
                                        >
                                          <img src={issue?.images.emiratesID_front
                                            .substr(
                                              issue?.images.emiratesID_front.lastIndexOf('.')
                                            ).includes('.pdf')
                                            ? pdfImage
                                            : issue?.images.emiratesID_front
                                          } alt="Preview" className="form-img__img-preview w-100" />
                                        </button>
                                      </div>
                                    </>
                                    :
                                    t("No image")
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 col-sm-6 col-xl-6 col-12'>
                            <div className='mb-3 upload-input'>
                              <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Back")} </label>
                              <div className="position-relative">
                                <div className="">
                                  {issue?.images?.emiratesID_back ?
                                    <>
                                      <div className="text-center">
                                        <button
                                          className='border-0 bg-transparent upload-image mt-0 w-100'
                                          data-bs-target='#view-popUp'
                                          data-bs-toggle='modal'
                                          data-bs-dismiss='modal'
                                          type='button'
                                          onClick={() => {
                                            handleView({
                                              filename: Array.isArray(issue?.images?.emiratesID_back)
                                                ?
                                                issue?.images?.emiratesID_back[0]
                                                :
                                                issue?.images?.emiratesID_back
                                            })
                                          }}
                                        >
                                          <img src={issue?.images.emiratesID_back
                                            .substr(
                                              issue?.images.emiratesID_back.lastIndexOf('.')
                                            ).includes('.pdf')
                                            ? pdfImage
                                            : issue?.images.emiratesID_back
                                          } alt="Preview" className="form-img__img-preview w-100" />
                                        </button>
                                      </div>
                                    </>
                                    :
                                    t("No image")
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className='col-md-6 col-12'>
                    <div className='row documents-card form-add-design'>
                      <div className="col-md-12 col-12">
                        <h4 className="card-title font-18 text-black mb-6">
                          {t("Passport")}
                        </h4>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3 upload-input">
                          <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Front")} </label>
                          <div className="position-relative">
                            <div className="">
                              {issue?.images?.passport_front ?
                                <>
                                  <div className="text-center">
                                    <button
                                      className='border-0 bg-transparent upload-image mt-0 w-100'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView({ filename: Array.isArray(issue?.images?.passport_front) ? issue?.images?.passport_front[0] : issue?.images?.passport_front })
                                      }}
                                    >
                                      <img src={issue?.images.passport_front
                                        .substr(
                                          issue?.images.passport_front.lastIndexOf('.')
                                        ).includes('.pdf')
                                        ? pdfImage
                                        : issue?.images.passport_front
                                      } alt="Preview" className="form-img__img-preview w-100" />
                                    </button>
                                  </div>
                                </>
                                :
                                t("No image")
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3 upload-input">
                          <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Back")} </label>
                          <div className="position-relative">
                            <div className="">
                              {issue?.images?.passport_back ?
                                <>
                                  <div className="text-center">
                                    <button
                                      className='border-0 bg-transparent upload-image mt-0 w-100'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView({ filename: Array.isArray(issue?.images?.passport_back) ? issue?.images?.passport_back[0] : issue?.images?.passport_back })
                                      }}
                                    >
                                      <img src={issue?.images.passport_back
                                        .substr(
                                          issue?.images.passport_back.lastIndexOf('.')
                                        ).includes('.pdf')
                                        ? pdfImage
                                        : issue?.images.passport_back
                                      } alt="Preview" className="form-img__img-preview w-100" />
                                    </button>
                                  </div>
                                </>
                                :
                                t("No image")
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-12'>
                    <div className='row documents-card form-add-design'>
                      <div className="col-md-12 col-12">
                        <h4 className="card-title font-18 text-black mb-6">
                          {t("Photo")}
                        </h4>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3 upload-input">
                          <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Photo")} </label>
                          <div className="position-relative">
                            <div className="">
                              {issue?.images?.photo ?
                                <>
                                  <div className="text-center">
                                    <button
                                      className='border-0 bg-transparent upload-image mt-0 w-100'
                                      data-bs-target='#view-popUp'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'
                                      type='button'
                                      onClick={() => {
                                        handleView({ filename: issue?.images?.photo })
                                      }}
                                    >
                                      <img src={issue?.images.photo
                                        .substr(
                                          issue?.images.photo.lastIndexOf('.')
                                        ).includes('.pdf')
                                        ? pdfImage
                                        : issue?.images.photo
                                      } alt="Preview" className="form-img__img-preview w-100" />
                                    </button>
                                  </div>
                                </>
                                :
                                t("No image")
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ViewPopup viewImage={viewImage} />
              </div>
            ) : (<>
              <div className='row mb-5'>
                <div className='col-md-6 col-12'>
                  <div className='row documents-card form-add-design'>
                    <div className="col-md-12 col-12">
                      <h4 className="card-title font-18 text-black mb-6">
                        {t("Emirates ID")}
                      </h4>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mb-3 upload-input">
                        <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Front")} </label>
                        <input
                          ref={inputRef1}
                          className="d-none"
                          type="file"
                          onChange={handleDisplayFileDetails1}
                          onBlur={formik.handleBlur} />
                        <button onClick={() => inputRef1.current?.click()} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName1 ? "primary active" : "primary"}`}>
                          <span> {uploadedFileName1 ? uploadedFileName1 : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                        </button>
                      </div>
                      {formik.touched.emirates_id_front && formik.errors.emirates_id_front ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className="text-danger">{formik.errors.emirates_id_front}</small>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mb-3 upload-input">
                        <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Back")} </label>
                        <input
                          ref={inputRef2}
                          className="d-none"
                          type="file"
                          onChange={handleDisplayFileDetails2}
                          onBlur={formik.handleBlur} />
                        <button onClick={() => inputRef2.current?.click()} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName2 ? "primary active" : "primary"}`}>
                          <span> {uploadedFileName2 ? uploadedFileName2 : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                        </button>
                      </div>
                      {formik.touched.emirates_id_back && formik.errors.emirates_id_back ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className="text-danger">{formik.errors.emirates_id_back}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-12'>
                  <div className='row documents-card form-add-design'>
                    <div className="col-md-12 col-12">
                      <h4 className="card-title font-18 text-black mb-6">
                        {t("Passport")}
                      </h4>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mb-3 upload-input">
                        <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Front")} </label>
                        <input
                          ref={inputRef3}
                          className="d-none"
                          type="file"
                          onChange={handleDisplayFileDetails3}
                          onBlur={formik.handleBlur} />
                        <button onClick={() => inputRef3.current?.click()} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName3 ? "primary active" : "primary"}`}>
                          <span> {uploadedFileName3 ? uploadedFileName3 : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                        </button>
                      </div>
                      {formik.touched.passport_front && formik.errors.passport_front ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className="text-danger">{formik.errors.passport_front}</small>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mb-3 upload-input">
                        <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Back")} </label>
                        <input
                          ref={inputRef4}
                          className="d-none"
                          type="file"
                          onChange={handleDisplayFileDetails4}
                          onBlur={formik.handleBlur} />
                        <button onClick={() => inputRef4.current?.click()} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName4 ? "primary active" : "primary"}`}>
                          <span> {uploadedFileName4 ? uploadedFileName4 : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                        </button>
                      </div>
                      {formik.touched.passport_back && formik.errors.passport_back ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className="text-danger">{formik.errors.passport_back}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 col-12'>
                  <div className='row documents-card form-add-design'>
                    <div className="col-md-12 col-12">
                      <h4 className="card-title font-18 text-black mb-6">
                        {t("Photo")}
                      </h4>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mb-3 upload-input">
                        <label className="form-label text-primary font-14 fw-bold d-block mb-5"> {t("Photo")} </label>
                        <input
                          ref={inputRef5}
                          className="d-none"
                          type="file"
                          onChange={handleDisplayFileDetails5}
                          onBlur={formik.handleBlur} />
                        <button onClick={() => inputRef5.current?.click()} className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName5 ? "primary active" : "primary"}`}>
                          <span> {uploadedFileName5 ? uploadedFileName5 : <img src={PlusIcon} className="img-fluid va-baseline" />} </span>
                        </button>
                      </div>
                      {formik.touched.photo && formik.errors.photo ? (
                        <div className='d-flex flex-column text-danger'>
                          <small className="text-danger">{formik.errors.photo}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 text-end'>
                  <button type='button' onClick={handelSubmit} disabled={isSaved} className='btn btn-primary fw-bold px-10 py-3 w-25'>
                    <span className='indicator-label'>{isSaved ? t("Saved") : t("Save")}</span>
                  </button>
                </div>
              </div>
            </>)}
          </div>
        </div>
      </div>
    </div>
  );
}