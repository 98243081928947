import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl'
    },
];
type Props = {
    details?: any
}

// const PaymentMethodTabs: React.FC = () => {
const PaymentMethodTabs: FC<Props> = ({ details }: any) => {
    console.log("🚀 ~ file: PaymentMethodTabs.tsx:26 ~ details", details)
    const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <Link to={'/payments/all'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </Link>
                        {t("Pay Now")}
                    </h4>
                </div>
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-8 py-0">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/payments/card-payment' && 'active')}
                                                    to='/payments/card-payment'>
                                                    {t("Card Payment")}
                                                </Link>
                                            </li>
                                            {details?.payment_for != 3 ?
                                                <li className='nav-item'>
                                                    <Link
                                                        className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                            (location.pathname === '/payments/bank-transfer' && 'active')}
                                                        to='/payments/bank-transfer'>
                                                        {t("Bank Transfer")}
                                                    </Link>
                                                </li> : null}
                                            {details?.payment_for != 3 ? <li className='nav-item'>
                                                <Link
                                                    className={`nav-link pb-1 position-relative text-active-primary me-14 ` +
                                                        (location.pathname === '/payments/pay-by-cheque' && 'active')}
                                                    to='/payments/pay-by-cheque'>
                                                    {t("Pay by Cheque")}
                                                </Link>
                                            </li> : null}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { PaymentMethodTabs }
