import React, { useState, useEffect, useRef } from 'react'
import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import { useHistory } from 'react-router-dom'
import { LeaseDetails } from './LeaseDetails/LeaseDetails'
import { ContractFilter } from './ContractFilter'
import { useSelector } from 'react-redux'
import {
  getUninvoicedContractsList,
  getContractsDetails,
  getEligibleCommission,
  addInvoiceBroker,
} from '../../../../../utils/makeRequest'
import { IState } from '../../../../../utils/interface'
import { useTranslation } from 'react-i18next'
import { CustomLoader2 } from '../../../../../constants/generics/customActios'
import { CustomRequestSuccessfulPopup } from '../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import { validateAndCompressImage } from '../../../../../constants/generics/fileUploadHandler'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addBrokerInvoice } from '../../../../../constants/axios/apis'

export function AddCommission() {
  const buttonEl: any = useRef(null)
  const { t } = useTranslation()
  const history = useHistory()
  const state: any = useSelector((state) => state)
  const userToken = state?.auth?.accessToken
  const inputRef = useRef<HTMLInputElement>(null)
  const inputRef1 = useRef<HTMLInputElement>(null)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [uploadedDocumentFileName, setUploadedDocumentFileName] = useState<string | null>(null)
  const [contractState, setContractState] = useState<any>('')
  const [noContract, setNoContract] = useState(true)
  const [contractDetails, setContractDetails] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })
  const { loading: loadingDetails, response: Details, error: errorLoadig } = contractDetails
  const [eligibleCommission, setEligibleCommission] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })
  const {
    loading: eligibleCommissionLoading,
    response: eligibleCommissionResponse,
    error: errorEligibleCommission,
  } = eligibleCommission

  const [submitStatus, setSubmitStatus] = useState<any>({
    loading: false,
    error: false,
    isSubmitted: false,
    message: '',
  })
  const [submitError, setSubmitError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (contractState !== '') {
      const property_id = contractState
      getContractsDetails(setContractDetails, userToken, property_id)
      getEligibleCommission(setEligibleCommission, userToken, property_id)
      setNoContract(false)
    } else {
      setNoContract(true)
    }
  }, [contractState])

  const handleContractFilters = (econtractPropertyID: any) => {
    setContractState(econtractPropertyID)
  }

  const initialValues = {
    property_id: contractState,
    contract_id: eligibleCommissionResponse?.contractId,
    broker_id: eligibleCommissionResponse?.brokerId,
    invoice_file: '',
    supporting_document: '',
    comment: '',
  }
 

  const newRequestSchema = Yup.object().shape({
    comment: Yup.string()
      // .required(t('Please provide a description of the problem!'))
      .matches(/^[^\s].*/, t('Description cannot start with a blankspace!'))
      .max(250, t('Description should be 250 characters!')),
    invoice_file: Yup.mixed()
      .test('fileRequried', t('Please select a file!'), (value) => {
        return value?.size ? true : false
      })
      .test(
        'type',
        t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'),
        (value) => {
          return (
            value &&
            (value.type === 'image/jpeg' ||
              value.type === 'image/jpg' ||
              value.type === 'image/png' ||
              value.type === 'application/pdf')
          )
        }
      )
      .test('fileSize', t('The file is too large! Max allowed size is 5MB.'), (value) => {
        return value?.size <= 5242880
      }),
    supporting_document: Yup.mixed()
      .test('fileRequried', t('Please select a document!'), (value) => {
        return value?.size ? true : false
      })
      .test(
        'type',
        t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!'),
        (value) => {
          return (
            value &&
            (value.type === 'image/jpeg' ||
              value.type === 'image/jpg' ||
              value.type === 'image/png' ||
              value.type === 'application/pdf')
          )
        }
      )
      .test('fileSize', t('The file is too large! Max allowed size is 5MB.'), (value) => {
        return value?.size <= 5242880
      }),
  })

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
      formik.setFieldTouched('invoice_file')
      formik.setFieldValue('invoice_file', e.currentTarget.files[0])
    } else {
      setUploading(true)
      formik.setFieldError('invoice_file', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputRef.current?.files && setUploadedFileName(compressResponse.file.name)
            formik.setFieldTouched('invoice_file', true, false)
            formik.setFieldValue('invoice_file', compressResponse.file, false)
          } else {
            setUploadedFileName(null)
            formik.setFieldTouched('invoice_file', true, false)
            formik.setFieldValue('invoice_file', '', false)
            formik.setFieldError(
              'invoice_file',
              t(compressResponse?.message || 'File compression error!')
            )
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedFileName(null)
        formik.setFieldTouched('invoice_file')
        formik.setFieldValue('invoice_file', '', false)
        formik.setFieldError('invoice_file', t('File upload error!'))
        setUploading(false)
      }
    }
  }

  const [uploadingDocument, setUploadingDocument] = useState(false)
  const handleUploadDocument = () => {
    inputRef1.current?.click()
  }

  const handleDisplayDocumentDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      inputRef1.current?.files && setUploadedDocumentFileName(inputRef1.current.files[0].name)
      formik.setFieldTouched('supporting_document')
      formik.setFieldValue('supporting_document', e.currentTarget.files[0])
    } else {
      setUploadingDocument(true)
      formik.setFieldError('supporting_document', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputRef1.current?.files && setUploadedDocumentFileName(compressResponse.file.name)
            formik.setFieldTouched('supporting_document', true, false)
            formik.setFieldValue('supporting_document', compressResponse.file, false)
          } else {
            setUploadedDocumentFileName(null)
            formik.setFieldTouched('supporting_document', true, false)
            formik.setFieldValue('supporting_document', '', false)
            formik.setFieldError(
              'supporting_document',
              t(compressResponse?.message || 'File compression error!')
            )
          }
          setUploadingDocument(false)
        })
      } catch (error) {
        setUploadedDocumentFileName(null)
        formik.setFieldTouched('supporting_document')
        formik.setFieldValue('supporting_document', '', false)
        formik.setFieldError('supporting_document', t('File upload error!'))
        setUploadingDocument(false)
      }
    }
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: newRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setSubmitError('')
      setTimeout(() => {
        addBrokerInvoice(
          values.property_id,
          values.contract_id,
          values.broker_id,
          values.invoice_file,
          values.supporting_document,
          values.comment
        )
          .then((response: any) => {
            setLoading(false)
            if (response.errorCode === 1) setSubmitError(response.errorDescription)
            else if (response.errorCode === 0) {
              formik.resetForm()
              setLoading(false)
              var ev = new Event('click', { bubbles: true })
              buttonEl.current.dispatchEvent(ev)
            } else setSubmitError(t('Some error occured. Please try again !'))
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t('Invalid Data Provided'))
          })
      }, 1000)
    },
  })

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-9 col-9'>
                  <h4 className='card-title font-18 text-black mb-8'>
                    <span
                      className='me-5 c-pointer'
                      onClick={() => {
                        history.goBack()
                      }}
                    >
                      <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                      <span className='text-black fw-bolder'>{t('Add Invoice')}</span>
                    </span>
                  </h4>
                </div>
                <div className='col-md-3 '>
                  <ContractFilter
                    handleContractFilters={handleContractFilters}
                    contractState={contractState}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <LeaseDetails contractDetailsState={eligibleCommission} />
                </div>
              </div>
              <div>
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='row form-add-design'>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'> {t('Tenant Name')} </label>
                        <input
                          className='form-control fw-bolder form-control-solid bg-light'
                          disabled
                          value={
                            eligibleCommissionResponse?.tenantName
                              ? eligibleCommissionResponse?.tenantName
                              : '-'
                          }
                        />
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10'>
                        <label className='form-label fw-bolder'>{t('Eligible Commission')}</label>
                        <input
                          className='form-control fw-bolder form-control-solid bg-light'
                          disabled
                          value={
                            eligibleCommissionResponse?.eligibleCommision
                              ? eligibleCommissionResponse?.eligibleCommision
                              : '-'
                          }
                        />
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 upload-input'>
                        <label className='form-label fw-bolder'>
                          {' '}
                          {t('Invoice')} <span className='text-danger'>*</span>
                        </label>
                        <input
                          ref={inputRef}
                          onChange={(e) => handleDisplayFileDetails(e)}
                          className='d-none'
                          type='file'
                          accept='.png,.jpg,.jpeg'
                        />
                        <button
                          type='button'
                          onClick={handleUpload}
                          disabled={uploading}
                          className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                            }`}
                        >
                          <span>
                            {uploading ? (
                              <span className='indicator-progress d-block'>
                                {t('Please wait...')}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            ) : uploadedFileName ? (
                              uploadedFileName
                            ) : (
                              <span>
                                {t('Upload')}{' '}
                                <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                              </span>
                            )}{' '}
                          </span>
                        </button>
                        {formik.touched.invoice_file && formik.errors.invoice_file && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.invoice_file}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                      <div className='mb-10 upload-input'>
                        <label className='form-label fw-bolder d-block mb-3'>
                          {' '}
                          {t('Supporting Documents')} <span className='text-danger'>*</span>
                        </label>
                        <input
                          ref={inputRef1}
                          onChange={(e) => handleDisplayDocumentDetails(e)}
                          className='d-none'
                          type='file'
                          accept='.png,.jpg,.jpeg'
                        />
                        <button
                          type='button'
                          onClick={handleUploadDocument}
                          disabled={uploadingDocument}
                          className={`w-100 py-3 ellipsis btn btn-outline-${uploadedDocumentFileName ? 'primary' : 'primary'
                            }`}
                        >
                          {uploadingDocument ? (
                            <span className='indicator-progress d-block'>
                              {t('Please wait...')}{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : uploadedDocumentFileName ? (
                            uploadedDocumentFileName
                          ) : (
                            <span>
                              {t('Upload')}{' '}
                              <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                            </span>
                          )}{' '}
                        </button>
                        {formik.touched.supporting_document && formik.errors.supporting_document && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert' className='text-danger'>
                                {formik.errors.supporting_document}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-8 col-12'>
                      <div className='mb-3'>
                        <label className='form-label fw-bolder'>{t('Comments')}</label>
                        <textarea
                          className='form-control fw-bolder form-control-solid'
                          data-kt-autosize='true'
                          rows={3}
                          placeholder={t('Type here...')}
                          {...formik.getFieldProps('comment')}
                        />
                        {formik.touched.comment && formik.errors.comment ? (
                          <small className='text-danger'>{formik.errors.comment}</small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </form>

                <div className='col-12 text-end'>
                  <button
                    type='button'
                    className='btn btn-outline-primary fw-bold px-10 py-3 me-5'
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    {t('Cancel')}
                  </button>
                  <button
                    type='submit'
                    onClick={formik.submitForm}
                    className='btn btn-primary fw-bold px-10 py-3'
                    disabled={loading || uploading || uploadingDocument || noContract}
                  >
                    {!loading && <span className='indicator-label'>{t('Submit')}</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {t('Please wait...')}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  {submitError && (
                    <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
                      {submitError}
                    </div>
                  )}
                  <span
                    ref={buttonEl}
                    data-bs-target='#req-submitted-popup'
                    data-bs-toggle='modal'
                    data-bs-dismiss='modal'
                    className='invisible d-none btn btn-primary fw-bold px-10 py-3'
                  />
                  <CustomRequestSuccessfulPopup
                    successMessage={t('Invoice Added Successfully')}
                    href={'/statements/inprogress-invoices'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
