import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  LogoutToken: '[LogoutToken] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}
const ssoUser: any = document.cookie
const ssoUser1: any = document?.cookie?.split('; userDetail=')[1]
const test = ssoUser1?.split(', ')[0]
let str = ssoUser

str = str.split(', ')
const result: any = {}
for (let i in str) {
  const cur = str[i].split('=')
  result[cur[0]] = cur[1]
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: document?.cookie?.split('; userDetail=')[1]
    ? JSON.parse(test)?.access_token
    : undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
}

export const reducer = persistReducer(
  {storage, key: 'auth', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Logout: {
        localStorage.clear()
        document.cookie = 'userDetail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        // window.open(
        //   process.env.NODE_ENV !== 'production'
        //     ? process.env.REACT_APP_WEB_URL_LOCAL
        //     : process.env.REACT_APP_WEB_URL,
        //   '_self'
        // )
        return {accessToken: undefined, user: undefined}
      }
      case actionTypes.LogoutToken: {
        localStorage.clear()
        return {accessToken: undefined, user: undefined}
      }
      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string) => ({type: actionTypes.Login, payload: {accessToken}}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  logoutToken: () => ({type: actionTypes.LogoutToken}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: user} = yield getUserByToken()
    yield put(actions.fulfillUser(user))
  })
}
