import React, { useState, useRef } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import moment from 'moment'
import { updateMoveInInspectionIssue } from '../../../../constants/axios/apis'
import FormData from 'form-data'
import { useTranslation } from 'react-i18next'

export function InspectionIssue({
  issue,
  property_id,
  preciseLocationOptions,
  triggerRemoveIssue,
  triggerFetchIssues,
  stepStatus,
}: any) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation();

  const initialValues = {
    id: issue?.id,
    category_id: 5,
    category_type_id: 0,
    precise_location: issue?.details?.precise_location,
    property_id: property_id,
    description: issue?.details?.description,
    photo: '',
  }

  const moveInInspectionRequestSchema = Yup.object().shape({
    precise_location: Yup.string().required(t("Please select a location")),
    description: Yup.string().required(t("Please provide a description of the problem"))
      .max(250, t("Maximum 250 characters")),
    photo: Yup.mixed()
      .test('type', t("Only the following formats are accepted: .jpeg, .jpg, .png"), (value) => {
        if (!value) {
          return true
        }
        return value && (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png')
      })
      .test('fileSize', t("The file is too large!"), (value) => {
        if (!value) {
          return true
        }
        return value?.size <= 2000000
      }),
  })

  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
    formik.setFieldTouched('photo')
    formik.setFieldValue('photo', e.currentTarget.files[0])
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: moveInInspectionRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        const data = new FormData()
        data.append('id', values.id)
        data.append('category_id', values.category_id)
        data.append('property_id', values.property_id)
        data.append('precise_location', values.precise_location)
        data.append('desc', values.description)
        data.append('photo[]', values.photo)

        updateMoveInInspectionIssue(data)
          .then((response: any) => {
            triggerFetchIssues()
            setLoading(false)
            setIsEditing(false)
            resetForm()
            setUploadedFileName(null)
            formik.setFieldValue('photo', '')
            inputRef.current!.value = ''
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const cancelEditing = () => {
    setIsEditing(false)
    formik.resetForm()
  }
  if (issue?.property_id != property_id) { return null }
  return (
    <div className='row mb-3 g-0' key={issue.id}>
      <div className='col-2 col-sm-1'>
        <div className='image-input-wrapper h-100 move-in-left-img'>
          <img src={issue.images[0]} alt='preview-img' className='preview-img' />
        </div>
      </div>
      <div className='col-10 col-sm-11 ps-md-0 bg-light-gray-solid'>
        <div className='row px-5 py-4'>
          <div className="col-10">
            <div className='image-desc'>
              <h5 className='image-name mb-2 text-short'>{issue.details.description}</h5>
              <p className='image-info mb-0 fw-bolder text-theme-gray'>
                {moment(issue.created).format('DD-MM-YYYY')}
              </p>
            </div>
          </div>
          <div className="col-2 text-end my-auto">
            {stepStatus ? (
              ''
            ) : (
              <div className='img-actions d-block'>
                <span onClick={() => setIsEditing(true)}>
                  <i className='fas fa-pencil-alt'></i>
                </span>
                <span onClick={() => triggerRemoveIssue(issue)}>
                  <i className='fas fa-trash'></i>
                </span>
                {/* <span className='me-0'>
                <i className='fas fa-plus-circle'></i>
              </span> */}
              </div>
            )}
          </div>
        </div>
        {isEditing ? (
          <div>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <div className='col-12'>
                <div className='row form-add-design mx-4 mb-7'>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10 upload-input'>
                      <label className='form-label fw-bolder d-block'>{t("Upload New Images")}</label>
                      <input
                        ref={inputRef}
                        onChange={(e) => handleDisplayFileDetails(e)}
                        className='d-none'
                        type='file'
                      />
                      <button
                        onClick={handleUpload}
                        type='button'
                        className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                          }`}
                      >
                        <span>
                          {uploadedFileName ? (
                            uploadedFileName
                          ) : (
                            <span>
                              {t("Upload")}
                              <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                            </span>
                          )}{' '}
                        </span>
                      </button>
                      {formik.touched.photo && formik.errors.photo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.photo}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Change Room")}</label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid bg-white'
                        name='precise_location'
                        onChange={formik.handleChange}
                        value={formik.values.precise_location}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          {t("Please Select")}
                        </option>
                        {preciseLocationOptions ? preciseLocationOptions : ''}
                      </select>
                    </div>
                    {formik.touched.precise_location && formik.errors.precise_location && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.precise_location}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Description")}</label>
                      <input
                        type='text'
                        className={clsx(
                          'form-control fw-bolder form-control-solid bg-white',
                          {
                            'is-invalid': formik.touched.description && formik.errors.description,
                          },
                          {
                            'is-valid': formik.touched.description && !formik.errors.description,
                          }
                        )}
                        name='description'
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        placeholder={t("Type here...")}
                        onBlur={formik.handleBlur}
                        autoComplete='off'
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.description}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 text-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'
                      onClick={() => cancelEditing()}
                    >
                      <span className='indicator-label'>{t("Cancel")}</span>
                    </button>
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading}>
                      {!loading && <span className='indicator-label'>{t("Save Changes")}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {t("Please wait...")}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
