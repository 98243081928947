import {FC} from 'react'
import {Link} from 'react-router-dom'
import DownloadIcon from '../../../../assets/images/download-ioc.png'
import ActionDotsIoc from "../../../../assets/images/Action_dots.png";
import { useTranslation } from 'react-i18next'

const PaymentClearedAction: FC = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="dropdown">
                <button 
                    className="btn dropdown-toggle border-0"
                    type="button" 
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                >
                    <img src={ActionDotsIoc} className="img-fluid" />
                </button>

                <div className="dropdown-menu action-dropdown px-4 py-2">
                    <Link to={''} target="_blank" className='menu-link text-black fs-7 d-flex justify-content-center align-items-center flex-wrap py-1'>
                        <img src={DownloadIcon} className="img-fluid me-3" />
                        {t("Download Receipt")}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export {PaymentClearedAction}
