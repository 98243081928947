import moment from 'moment'
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { RedeemOfferPopup } from '../RedeemOfferPopup/RedeemOfferPopup'

export function MarketPlaceOfferDescription({ marketPlaceOffer, backLink }: any) {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }

  return (
    <>
      <div className='mt-5 offerDescription'>
        <div className='row'>
          {userEmail === 'commercialtenant@exalogic.co' ? (
            <div className='col-12'>
              <div className='card mb-3'>
                <div className='card-body px-8 py-6'>
                  <div className='row mb-3'>
                    <div className='col-md-12 col-12'>
                      <h4 className='card-title font-17 fw-bolder'>Description</h4>
                    </div>
                  </div>
                  <div className='row offer-desc'>
                    <div className='col-md-12 mb-5'>
                      <p className='font-14 fw-bold'>
                        Lorem Ipsum és un text de farciment usat per la indústria de la tipografiai
                        la impremta. Lorem Ipsum ha estat el text estàndard de la indústria des de
                        l'any 1500, quan un impressor desconegut va fer servir una galerada de text
                        i la va mesclar per crear un llibre de mostres tipogràfiques.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 text-end'>
                      <div className='row justify-content-end'>
                        <div className='col-12'>
                          <button
                            className='btn btn-primary fw-bold px-10 py-3'
                            data-bs-toggle='modal'
                            data-bs-target='#redeem-offer'
                          >
                            Redeem
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='col-12'>
              <div className='card mb-3'>
                <div className='card-body px-8 py-6'>
                  <div className='row mb-3'>
                    <div className='col-md-12 col-12'>
                      <h4 className='card-title font-17 fw-bolder'>Description</h4>
                    </div>
                  </div>
                  <div className='row offer-desc'>
                    <div className='col-md-12 mb-5'>
                      <p className='font-14 fw-bold'>{marketPlaceOffer?.description}</p>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='mb-10'>
                        <label>Location</label>
                        <br />
                        <strong>{marketPlaceOffer?.location}</strong>
                      </div>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='mb-10'>
                        <label>Web</label>
                        <br />
                        <strong>
                          <Link to={{ pathname: marketPlaceOffer?.web_link }} target='_blank' className="text-primary" >
                            {marketPlaceOffer?.web_link}
                          </Link>
                        </strong>
                      </div>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='mb-10'>
                        <label>Expiry Date</label>
                        <br />
                        <strong>
                          {moment.utc(marketPlaceOffer?.expiry_date_time).format('DD-MM-YYYY')}
                        </strong>
                      </div>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='mb-10'>
                        <label>Contact</label>
                        <br />
                        <strong>{marketPlaceOffer?.contact}</strong>
                      </div>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='mb-10'>
                        <label>Time</label>
                        <br />
                        {/* <strong>{marketPlaceOffer?.open_hours}</strong> */}
                        <strong>{moment(marketPlaceOffer?.start_time, 'HH:mm:ss').format('HH:mm a')}</strong>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 text-end'>
                      <div className='row justify-content-end'>
                        <div className='col-12'>
                          <Link
                            to={backLink}
                            className='btn btn-outline-primary mx-4 fw-bold px-sm-14 px-8 py-3'
                          >
                            Back
                          </Link>
                          {marketPlaceOffer?.is_redeemed == 1 ? (
                            <button
                              className='btn btn-primary fw-bold px-sm-10 px-8 py-3'
                              style={{ cursor: 'not-allowed' }}
                              title={'Offer already redeemed!'}
                            >
                              Already Redeemed
                            </button>
                          ) : (
                            <button
                              className='btn btn-primary fw-bold px-10 py-3'
                              data-bs-toggle='modal'
                              data-bs-target='#redeem-offer'
                            >
                              Redeem
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <RedeemOfferPopup redeemableOffer={marketPlaceOffer} backLink={backLink} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
