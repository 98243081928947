import React, { useState, useEffect, useRef } from 'react'
import { getMasterData } from '../../../../constants/axios/apis'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import SignatureCanvas from 'react-signature-canvas'
import { useTranslation } from 'react-i18next'

export function SafetySecurityPrecautions({ setSafetyPrecatuions, nextRef }: any) {
  const [saved, setSaved] = useState<any>(false)
  const [dirty, setIsDirty] = useState<any>(false)
  const [safetyPrecautions, setSafetyPrecautions] = useState<any>()
  const sigCanvas = useRef<any>()
  const { t } = useTranslation();

  useEffect(() => {
    getMasterData().then(({ data: masterData }) => {
      let precautions = masterData
        .filter((item: any) => item.id === 16)
        .flatMap((data: any) => data.master_data)
      setSafetyPrecautions(precautions)
    })
  }, [])

  const initialValues = {
    safety_prec: [],
    signature: '',
  }

  const handelSubmit = () => {
    setSaved(false)
    setSafetyPrecatuions(null)
    formik.handleSubmit()
  }

  const safetyPrecautionsRequestSchema = Yup.object().shape({
    signature: Yup.string().required(t("Please add your signature")),
  })

  const formik = useFormik({
    initialValues,
    isInitialValid: false,
    validationSchema: safetyPrecautionsRequestSchema,
    onSubmit: (values) => {
      setSafetyPrecatuions({
        precautions_ids: values.safety_prec,
        signature: values.signature,
        saved: true
      })
      setSaved(true)
      setIsDirty(false)
      nextRef.current.click()
    },
  })

  useEffect(() => {
    setIsDirty(true)
  }, [formik.values])

  useEffect(() => {
    if (dirty) {
      setSaved(false)
      setSafetyPrecatuions((currentState: any) => { return { ...currentState, saved: false } })
    }
  }, [dirty])

  function clear() {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }
  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    })
  }

  return (
    <div className='col-12'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='row'>
          {safetyPrecautions
            ? safetyPrecautions?.map((option: any) => (
              <div key={option.value} className='col-md-4 col-sm-6 col-xl-4 col-12'>
                <div className='form-check custom mb-8'>
                  <input
                    className='form-check-input'
                    name='safety_prec'
                    value={option.value}
                    type='checkbox'
                    id={option.value}
                    onChange={formik.handleChange}
                  />
                  <label
                    className='form-check-label font-14 text-black fw-bolder mx-2'
                    htmlFor={option.value}
                  >
                    {option.name}
                  </label>
                </div>
              </div>
            ))
            : ''}
          {formik.touched.safety_prec && formik.errors.safety_prec && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.safety_prec}
                </span>
              </div>
            </div>
          )}
          <div className="col-md-4 col-12">
            <div className="mb-0">
              <label className="form-label fw-bolder"> {t("Customer Signature")} </label>
              <div className="w-100 form-control">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor='black'
                  canvasProps={{ height: 80, width: 320, className: 'sigCanvas' }}
                  onEnd={handleSignature}
                />
                <button
                  type='button'
                  className='btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0'
                  onClick={clear}
                >
                  {t("Reset")}
                </button>
                {formik.touched.signature && formik.errors.signature && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.signature}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 text-end">
            <button
              type='button'
              onClick={handelSubmit}
              disabled={!dirty}
              className='btn btn-primary fw-bold px-10 py-3'
              style={{ marginTop: '35px' }}
            >
              {saved ? t("Saved") : t("Save")}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
