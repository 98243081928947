import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

const MyVisitTabs: React.FC = () => {

    const location = useLocation();

    let userEmail = "";
    let userDetail = localStorage.getItem("userDetail");
    if(userDetail){
        userEmail = JSON.parse(userDetail).email;
    };

    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="row access-cd-tabs subtabs">
                        <div className="col-md-12 col-12">
                            <div className='d-flex overflow-auto h-55px'>
                                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                    <li className='nav-item'>
                                        <Link
                                            className={`nav-link position-relative text-active-primary ` +
                                            (location.pathname === '/pt-activity/my-visit/upcoming' && 'actives')}
                                            to='/pt-activity/my-visit/upcoming'>
                                            {t("Upcoming")}
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link
                                            className={`nav-link position-relative text-active-primary ` +
                                            (location.pathname === '/pt-activity/my-visit/visited' && 'actives')}
                                            to='/pt-activity/my-visit/visited'>
                                            {t("Visited Properties")}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {MyVisitTabs}
