import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export function ScheduleMeetingPreview({ selectedIssue }: any) {
	const [issue, setIssue] = useState<any>()
	const [loading, setLoading] = useState(true)
    const { t } = useTranslation();

	useEffect(() => {
		if (selectedIssue) {
			setIssue(selectedIssue)
			setLoading(false)
		}
	}, [selectedIssue])

	return (
		<>
			{!loading && issue && issue?.code == 'SM' ? (
				<div className='row'>
					<div className='col-12'>
						<div className='accordion accordion-flush' id='accordionFlushExampleScheduleMeetingCard'>
							<div className='accordion-item offers_details_accordian_div1'>
								<h2 className='accordion-header position-relative' id='flush-headingScheduleMeetingCard'>
									<button
										className='accordion-button collapsed accordian_bt'
										type='button'
										data-bs-toggle='collapse'
										data-bs-target='#flush-collapseScheduleMeetingCard'
										aria-expanded='false'
										aria-controls='flush-collapseScheduleMeetingCard'
									>
										{t("Schedule Meeting")}
									</button>
									<span className='services-badge'>
										{issue?.status == 0 ? t("Open") : t("Closed")}
									</span>
								</h2>
								<div
									id='flush-collapseScheduleMeetingCard'
									className='accordion-collapse collapse show'
									aria-labelledby='flush-headingScheduleMeetingCard'
									data-bs-parent='#accordionFlushExampleScheduleMeetingCard'
								>
									<div className='accordion-body py-5'>
										<div className='row form-add-design'>
											<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
												<div className='mb-10'>
													<label className='form-label fw-bolder'>{t("Visit Date")}</label>
													<input
														type='text'
														className='form-control fw-bolder form-control-solid'
														disabled
														value={moment(issue?.details?.visit_date).format('DD-MM-YYYY')}
													/>
												</div>
											</div>
											<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
												<div className='mb-10'>
													<label className='form-label fw-bolder'>{t("Visit Time")}</label>
													<input
														type='text'
														className='form-control fw-bolder form-control-solid'
														disabled
														value={moment(issue?.details?.start_time).format('h:mm A')}
													/>
												</div>
											</div>
											<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
												<div className='mb-10 position-relative'>
													<label className='form-label fw-bolder'>{t("Customer Type")}</label>
													<input
														type='text'
														className='form-control fw-bolder form-control-solid'
														disabled
														value={issue?.details?.customer_type == 1 ? t("Individual") : t("Organization")}
													/>
												</div>
											</div>
											<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
												<div className='mb-10 position-relative'>
													<label className='form-label fw-bolder'>{t("Name of the customer")}</label>
													<input
														type='text'
														className='form-control fw-bolder form-control-solid'
														value={issue?.details?.customer_name}
														disabled
													/>
												</div>
											</div>
											<div className='col-md-4 col-sm-6 col-xl-4 col-12'>
												<div className='mb-10 position-relative'>
													<label className='form-label fw-bolder'>{t("Email ID")}</label>
													<input
														type='text'
														className='form-control fw-bolder form-control-solid'
														value={issue?.details?.email}
														disabled
													/>
												</div>
											</div>
											<div className='col-md-12 col-sm-12 col-xl-12 col-12'>
												<div className='mb-10 position-relative'>
													<label className='form-label fw-bolder'>{t("Comments")}</label>
													<textarea className='form-control fw-bolder form-control-solid' rows={4} disabled>
														{issue?.details?.comments}
													</textarea>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</>
	)
  return (
    <>
      {!loading && issue && issue?.code == 'SM' ? (
        <div className='card'>
          <div className='card-body p-7'>
            <div className='row form-add-design'>
              <div className='col-12 mb-4 mt-2'>
                <h3 className='text-black font-15 fw-bolder'>Schedule Meeting</h3>
              </div>
              <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>Visit Date</label>
                  <input
                    type='text'
                    className='form-control fw-bolder form-control-solid'
                    disabled
                    value={moment(issue?.details?.visit_date).format('DD-MM-YYYY')}
                  />
                </div>
              </div>
              <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>Visit Time</label>
                  <input
                    type='text'
                    className='form-control fw-bolder form-control-solid'
                    disabled
                    value={moment(issue?.details?.start_time).format('h:mm A')}
                  />
                </div>
              </div>
              <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                <div className='mb-10 position-relative'>
                  <label className='form-label fw-bolder'>Customer Type</label>
                  <input
                    type='text'
                    className='form-control fw-bolder form-control-solid'
                    disabled
                    value={issue?.details?.customer_type == 1 ? 'Individual' : 'Organization'}
                  />
                </div>
              </div>
              <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                <div className='mb-10 position-relative'>
                  <label className='form-label fw-bolder'>Name of the customer</label>
                  <input
                    type='text'
                    className='form-control fw-bolder form-control-solid'
                    value={issue?.details?.customer_name}
                    disabled
                  />
                </div>
              </div>
              <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                <div className='mb-10 position-relative'>
                  <label className='form-label fw-bolder'>Email ID</label>
                  <input
                    type='text'
                    className='form-control fw-bolder form-control-solid'
                    value={issue?.details?.email}
                    disabled
                  />
                </div>
              </div>
              <div className='col-md-12 col-sm-12 col-xl-12 col-12'>
                <div className='mb-0 position-relative'>
                  <label className='form-label fw-bolder'>Comments</label>
                  <textarea className='form-control fw-bolder form-control-solid' rows={4} disabled>
                    {issue?.details?.comments}
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
