import MyUnit from "../../../../assets/images/myunit-banner.png";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import { Link } from "react-router-dom";
import LeftArrow from '../../../../assets/images/Left_Arrow.png'

export function MyUnitCard({ contract }: any) {
    const { t } = useTranslation();
    let unitDetail = localStorage.getItem('unitDetail')
    return (
        <>
            <div className="row">
                <div className='col-md-12 col-12'>
                    <h4 className="card-title font-18 text-black mb-6">
                        <Link to={'/unit-portfolio/list'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                        </Link>
                        Unit Details
                    </h4>
                </div>
            </div>
            <div className="card mb-7">
                <div className="card-body p-7">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-12">
                            <img src={contract?.propertyImage ? contract?.propertyImage : MyUnit} alt="my-unit-image" className="img-fluid w-100 rounded my-unit-image" />
                        </div>
                        <div className="col-md-6 col-lg-8 col-12">
                            <div className="d-flex flex-column justify-content-between h-100">
                                <div className="my-unit-address-owner">
                                    <h6>{contract?.occupantFirstName + " " + contract?.occupantLastName} </h6>
                                    <p> Address : {contract?.buildingName && contract?.buildingName + ' | '}{' '}
                                        {contract?.address && contract?.address + ' | '}{' '}
                                        {contract?.location && contract?.location} </p>
                                    <p>Unit : {contract?.UnitNumber}</p>
                                    <p className=""> Status : <span className={unitDetail === 'Handover' ? 'text-success' : unitDetail === 'Approved' ? 'text-success' : unitDetail === 'Under Construction' ? 'text-primary2' : 'text-warning' }> {unitDetail} </span> </p>
                                </div>
                                {/* <div>
                                    <a
                                        href={contract?.contractFile}
                                        className='text-decoration-underline text-primary fw-bolder view-all font-14'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <button className="btn btn-primary w-100">View Contract</button>
                                    </a>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
