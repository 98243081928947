export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  var i = 0
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1)
    } else {
      ++i
    }
  }
  return arr
}

export const validateRequired = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field'
) => {
  if (value === '' || (value.trim()).length === 0) {
    setErrorState((pre) => {
      return [...pre, `${fieldName} is required`]
    })
  } else {
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} is required`)
    })
  }
}

export const validateAlphabets = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field'
) => {
  if (!/[^a-zA-Z ]/.test(value)) {
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} should only contain alphabets only`)
    })
  } else {
    setErrorState((pre) => {
      return [...pre, `${fieldName} should only contain alphabets only`]
    })
  }
}

export const validateNumbers = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field'
) => {
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

  /* if (!/^[0-9]$/.test(value)){ */
  if (value.match(phoneno)) {
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} is not valid`)
    })
  } else {
    setErrorState((pre) => {
      return [...pre, `${fieldName} is not valid`]
    })
  }
}

export const validateAlphaNumeric = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field'
) => {
  if (!/[^a-zA-Z0-9 ]/.test(value)) {
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} should contain alphabets or numbers only`)
    })
  } else {
    setErrorState((pre) => {
      return [...pre, `${fieldName} should contain alphabets or numbers only`]
    })
  }
}

export const validateLength = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field',
  length = 15
) => {
  if (value.length > length) {
    setErrorState((pre) => {
      return [...pre, `${fieldName} should contain less than ${length} characters`]
    })
  } else {
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} should contain less than ${length} characters`)
    })
  }
}

export const validateMinLength = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field',
  length = 15
) => {
  if (value.length < length) {
    setErrorState((pre) => {
      return [...pre, `${fieldName} does't contain less than ${length} characters`]
    })
  } else {
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} does't contain less than ${length} characters`)
    })
  }
}

export const validateEmail = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'email'
) => {
  var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  if (value.match(mailformat)) {
    setErrorState((pre) => {
      return removeItem([...pre], `Enter a valid ${fieldName}`)
    })
  } else {
    setErrorState((pre) => {
      return [...pre, `Enter a valid ${fieldName}`]
    })
  }
}

export const validateMediaRequired = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field'
) => {
  if (typeof value !== 'undefined') {
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} is not selected`)
    })
  } else {
    setErrorState((pre) => {
      return [...pre, `${fieldName} is not selected`]
    })
  }
}

export const validateMediaSize = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field'
) => {
  if (typeof value !== 'undefined') {
    if (value.size <= 5242880) {
      setErrorState((pre) => {
        return removeItem([...pre], `${fieldName} The file is too large! Max allowed size is 5MB.`)
      })
    } 
    else {
      setErrorState((pre) => {
        return [...pre, `${fieldName} file size should not be more than 5MB`]
      })
    }
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} is not selected`)
    })
  }
}

export const validateMediaType = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field'
) => {
  if (typeof value !== 'undefined') {
    if (
      value.type === 'image/png' ||
      value.type === 'image/jpeg' ||
      value.type === 'image/jpg' ||
      value.type === 'application/pdf'
    ) {
      setErrorState((pre) => {
        return removeItem([...pre], `${fieldName} is not a png/jpeg/jpg/PDF file`)
      })
    } else {
      setErrorState((pre) => {
        return [...pre, `${fieldName} is not a png/jpeg/jpg/PDF file`]
      })
    }
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} is not selected`)
    })
  }
}
export const validateImageType = (
  value: any,
  setErrorState: React.Dispatch<React.SetStateAction<string[]>>,
  fieldName = 'field'
) => {
  if (typeof value !== 'undefined') {
    if (
      value.type === 'image/png' ||
      value.type === 'image/jpeg' ||
      value.type === 'image/jpg' 
    ) {
      setErrorState((pre) => {
        return removeItem([...pre], `${fieldName} is not a png/jpeg/jpg file`)
      })
    } else {
      setErrorState((pre) => {
        return [...pre, `${fieldName} is not a png/jpeg/jpg file`]
      })
    }
    setErrorState((pre) => {
      return removeItem([...pre], `${fieldName} is not selected`)
    })
  }
}

export const validateContractSelected = (
  contractState: any,
  setErrorState: React.Dispatch<React.SetStateAction<string>>,
  fieldName = 'field'
) => {
  if (Object.keys(contractState).length !== 0) {
    const property_id = contractState.propertyID
    setErrorState('')
  } else {
    setErrorState('Please select contract')
  }
}
