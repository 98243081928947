/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { getUserRole } from '../../constants/generics/userCheck'

const RedirectHandler: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    const state: any = useSelector(state => state);
    const userRoleCode = getUserRole(state?.auth?.user?.data);    

    let redirectPath = '/'
    if (location.pathname === '/' || location.pathname === '')
        redirectPath = (userRoleCode === 'BA' ? '/broker-admin-dashboard/available-properties' :
            userRoleCode && ['T', 'CPT', 'PT', 'BR', 'CT'].includes(userRoleCode) ? '/dashboard' :
                '/auth/login')
    else if (location.pathname === '/dashboard' && userRoleCode === 'BA')
        redirectPath = '/broker-admin-dashboard/available-properties'
    else
        redirectPath = '/error/403'        

    history.push(redirectPath)

    return (
        <></>
    )
}

export { RedirectHandler }
