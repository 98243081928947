import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import NoDataFoundImage from '../../../assets/images/no-data-found.svg'

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function PaymentsNew({ payments }: any) {
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      {payments &&
        payments
          ?.filter((payment: any) => payment?.payment_for == 1 || payment?.payment_for == 2)
          ?.map((payment: any) =>
            payment?.status == 0 ? (
              <div className='row' key={payment.id}>
                <div className='col-12'>
                  <div className='card mb-3'>
                    <div className='card-body px-8 py-6'>
                      <div className='gray-card overflow-hidden'>
                        <div className='row'>
                          <div className='col-12'>
                            <p className='font-12 text-normal mb-2'>{t("Mode of Payment")}</p>
                            <h4 className='text-black font-15 mb-7'>{payment?.paymentTypeText}</h4>
                          </div>
                          <div className='col-12 col-sm-3 my-unit-madeofpay'>
                            <p className='font-12 text-normal mb-2'>{t("Type")}</p>
                            <h4 className='text-black font-15 mb-0'>{payment?.title}</h4>
                          </div>
                          <div className='col-12 col-sm-3 my-unit-madeofpay'>
                            <p className='font-12 text-normal mb-2'>{t("Amount")}</p>
                            <h4 className='text-black font-15 mb-0'>{t("AED")} {payment?.amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h4>
                          </div>
                          <div className='col-12 col-sm-3 my-unit-madeofpay'>
                            <p className='font-12 text-normal mb-2'>{t("Lease Period")}</p>
                            <h4 className='text-black font-15 mb-0'>
                              {moment(payment?.contractStartDate).format('DD-MM-YYYY') +
                                ' - ' +
                                moment(payment?.contractEndDate).format('DD-MM-YYYY')}
                            </h4>
                          </div>
                          <div className='col-12 col-sm-3 my-unit-madeofpay'>
                            <p className='font-12 text-normal mb-2'>{t("Due Date")}</p>
                            <h4 className='text-black font-15 mb-0'>
                              {moment(payment?.dueDate).format('DD-MM-YYYY')}
                            </h4>
                          </div>
                          {/* <div className='col-6 text-end'>
                            <div className='d-flex flex-wrap justify-content-between'>
                              <Link
                                to={{
                                  pathname: '/payments/card-payment',
                                  state: {
                                    offer_id: payment?.objectID,
                                    amount: payment?.amount,
                                    type: payment?.title,
                                    extra_charges_title: '',
                                    extra_charges_amount: 0,
                                    payment_category: payment?.payment_category,
                                    payment_for: payment?.payment_for,
                                    payment_type: payment?.paymentType,
                                    upcoming_payment_id: payment?.id,
                                  },
                                }}
                                className='btn btn-primary px-3 font-14 py-2'
                              >
                                Pay Now
                              </Link>
                              {payment?.paymentType == 2 || payment?.paymentTypeText == 'PDC' && (
                                <>
                                  <Link
                                    to={{
                                      pathname: '/payments/cheque-replace-request',
                                      state: {
                                        details: payment,
                                      },
                                    }}
                                    className='btn btn-outline-primary px-3 font-14 py-2 bg-white'
                                  >
                                    Cheque Replace Request
                                  </Link>
                                  <Link
                                    to={{
                                      pathname: '/payments/cheque-delay-request',
                                      state: {
                                        details: payment,
                                      },
                                    }}
                                    className='btn btn-outline-primary px-3 font-14 py-2 bg-white'
                                  >
                                    Cheque Delay Request
                                  </Link>
                                </>
                              )}
                            </div>
                          </div> */}
                        </div>
                        <div className='card-status'>
                          <h4 className='pending'>{t("Upcoming Payment")}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='row' key={payment.id}>
                <div className='col-12'>
                  <div className='card mb-3'>
                    <div className='card-body px-8 py-6'>
                      <div className='gray-card overflow-hidden'>
                        <div className='row'>
                          <div className='col-12'>
                            <p className='font-12 text-normal mb-2'>{t("Mode of Payment")}</p>
                            <h4 className='text-black font-15 mb-7'>{payment?.paymentTypeText}</h4>
                          </div>
                          <div className='col-12 col-sm-3 my-unit-madeofpay'>
                            <p className='font-12 text-normal mb-2'>{t("Type")}</p>
                            <h4 className='text-black font-15 mb-0'>{payment?.title}</h4>
                          </div>
                          <div className='col-12 col-sm-3 my-unit-madeofpay'>
                            <p className='font-12 text-normal mb-2'>{t("Amount")}</p>
                            <h4 className='text-black font-15 mb-0'>{t("AED")} {payment?.amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</h4>
                          </div>
                          <div className='col-12 col-sm-3 my-unit-madeofpay'>
                            <p className='font-12 text-normal mb-2'>{t("Lease Period")}</p>
                            <h4 className='text-black font-15 mb-0'>
                              {moment(payment?.contractStartDate).format('DD-MM-YYYY') +
                                ' - ' +
                                moment(payment?.contractEndDate).format('DD-MM-YYYY')}
                            </h4>
                          </div>
                          <div className='col-12 col-sm-3 my-unit-madeofpay'>
                            <p className='font-12 text-normal mb-2'>{t("Paid Date")}</p>
                            <h4 className='text-black font-15 mb-0'>
                              {moment(payment?.paidDate).format('DD-MM-YYYY')}
                            </h4>
                          </div>
                        </div>
                        <div className='card-status'>
                          <h4 className='approved'>{t("Cleared")}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
      {!payments ? (
        <div className='row'>
          <div className="col-12">
            <div className="card border-0 shadow-none rounded-0 no-data-found-cd">
              <div className="card-body border-0">
                <div className="row justify-content-center">
                  <div className="col-12 text-center">
                    <img src={NoDataFoundImage} alt="No Upcoming Payments" className="img-fluid" />
                  </div>
                  <div className="col-12 text-center">
                    <span className='d-block text-center font-16 fw-bolder text-black'> {t("No Upcoming Payments")} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
