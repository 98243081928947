import React, { useState, FC, useRef, useEffect } from 'react';
import PlusIcon from "../../../../../assets/images/plus-circle.svg";
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import moment from 'moment';
import completedStep from '../../../../../assets/images/completed-step.png'
import { validateAndCompressImage } from '../../../../../constants/generics/fileUploadHandler';

type Props = {
    setPermitDetails?: any
    ref1?: any
}

const today = new Date()
today.setHours(0, 0, 0, 0)

const PermitDetails: FC<Props> = ({ setPermitDetails, ref1 }) => {
    const [uploadedFileName1, setUploadedFileName1] = useState<string | null>(null);
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const inputRef1 = useRef<HTMLInputElement>(null)
    const workStartRef = useRef<any>()
    const workEndRef = useRef<any>()
    const { t } = useTranslation();

    // const handleDisplayFileDetails1 = (e: any) => {
    //     inputRef1.current?.files &&
    //         setUploadedFileName1(inputRef1.current.files[0].name);
    // };

    const initialValues = {
        exact_work_location: '',
        number_of_workers: '',
        description: '',
        work_start_date_time: '',
        work_end_date_time: ''
    }

    const handelSubmit = () => {
        setSaved(false)
        setPermitDetails(null)
        formik.handleSubmit()
    }

    const permitDetailsSchema = Yup.object().shape({
        exact_work_location: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Exact work location is required"))
            .test('filled', t("Exact work location cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Exact work location cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        number_of_workers: Yup.string()
            .required(t("Number of workers is required"))
            .matches(/^[0-9]*$/, 'Only numbers allowed'),
        work_start_date_time: Yup.date()
            .min(today, t("Work start date cannot be in the past"))
            .required(t("Work start date & time are required"))
            .nullable(),
        work_end_date_time: Yup.date()
            .min(Yup.ref('work_start_date_time'), t("Work ending date cannot be before starting date"))
            .required(t("Work end date & time are required"))
            .nullable(),
        description: Yup.string().required(t("Please provide a description of work")),
        photo: Yup.mixed()
        .test('type', (t('Only the following formats are accepted: .jpeg, .jpg .pdf .png!')), (value) => {
          return (
            value &&
            (value.type === 'image/jpeg' ||
              value.type === 'image/jpg' ||
              value.type === 'image/png' ||
              value.type === 'application/pdf')
          )
        })
        .test('fileSize', (t('The file is too large! Max allowed size is 5MB.')), (value) => {
          return value?.size <= 5242880
        }),
    })

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: permitDetailsSchema,
        onSubmit: (values) => {
            setPermitDetails({
                exact_work_location: values.exact_work_location,
                number_of_workers: values.number_of_workers,
                work_start_date: moment(values.work_start_date_time).format('YYYY-MM-DD'),
                work_start_time: moment(values.work_start_date_time).format('HH:mm'),
                work_end_date: moment(values.work_end_date_time).format('YYYY-MM-DD'),
                work_end_time: moment(values.work_end_date_time).format('HH:mm'),
                description: values.description,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref1.current.click()
        },
    })

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setPermitDetails((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    const handleWorkStartDateTime = (e: any) => {
        formik.setFieldTouched('work_start_date_time')
        formik.setFieldValue('work_start_date_time', e.target.value)
    }

    const handleWorkEndDateTime = (e: any) => {
        formik.setFieldTouched('work_end_date_time')
        formik.setFieldValue('work_end_date_time', e.target.value)
    }

    const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef1.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {    
    if(e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf'){      
      inputRef1.current?.files && setUploadedFileName1(inputRef1.current.files[0].name)        
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', e.currentTarget.files[0])
    }else{
      setUploading(true)
      formik.setFieldError('photo', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            inputRef1.current?.files && setUploadedFileName1(compressResponse.file.name);
            formik.setFieldTouched('photo', true, false)
            formik.setFieldValue('photo', compressResponse.file, false)
          } else {
            setUploadedFileName1(null)
            formik.setFieldTouched('photo', true, false)
            formik.setFieldValue('photo', '', false)
            formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedFileName1(null)
        formik.setFieldTouched('photo')
        formik.setFieldValue('photo', '', false)
        formik.setFieldError('photo', t('File upload error!'))
        setUploading(false)
      }
    }
 }

    return (
        <div className="row">
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
            >
                <div className="col-12">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-permit-heading">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#permit-details">
                                {t("Permit to Work Details")}
                                {(saved) &&
                                    <span className='completed-step ps-3 pe-3 d-inline-block'>
                                        <img src={completedStep} alt='complete-step' />
                                    </span>}
                            </button>
                        </h2>
                        <div id="permit-details" className="accordion-collapse collapse" data-bs-parent="#accordion-rooftop-access">
                            <div className="accordion-body pb-5 pt-0">
                                <div className="row form-add-design">
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder">{t("Location of Activity")}</label>
                                            <input
                                                className='form-control bg-white fw-bolder form-control-solid'
                                                {...formik.getFieldProps('exact_work_location')}

                                            />
                                            {formik.touched.exact_work_location && formik.errors.exact_work_location && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.exact_work_location}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder"> {t("No. of Workers")}</label>
                                            <input
                                                className='form-control bg-white fw-bolder form-control-solid'
                                                {...formik.getFieldProps('number_of_workers')}
                                            />
                                            {formik.touched.number_of_workers && formik.errors.number_of_workers && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.number_of_workers}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder">{t("Complete Description of Activity")}</label>
                                            <textarea
                                                className='form-control bg-white fw-bolder form-control-solid'
                                                data-kt-autosize='true'
                                                name='description'
                                                rows={2}
                                                placeholder={t("Type here...")}
                                                value={formik.values.description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.description && formik.errors.description && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.description}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 documents-card">
                                        <div className="mb-7 upload-input">
                                            <label className="form-label fw-bolder"> {t("Upload Document ( if any )")} </label>
                                            <input
                                                name="undertaking_letter"
                                                className="d-none"
                                                type="file"
                                                ref={inputRef1}
                                                onChange={handleDisplayFileDetails}
                                            // accept='.jpeg,.jpg,application/pdf'
                                            />
                                            <button type='button'
                                                onClick={handleUpload}
                                                disabled={uploading}
                                                className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName1 ? 'primary' : 'primary'
                                                    }`}
                                            //  onClick={() => inputRef1.current?.click()}
                                            //   className={`w-100 py-3 mydoc_bt_file ellipsis btn-outline-${uploadedFileName1 ? "primary active" : "primary"}`}
                                            >
                                                {uploading ?
                                                    <span className='indicator-progress d-block'>
                                                        {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                    : uploadedFileName1 ? (
                                                        uploadedFileName1
                                                    ) : (
                                                        <span>
                                                            <img src={PlusIcon} className="img-fluid va-baseline" /></span>
                                                    )}{' '}
                                                {/* <span>
                                                    {uploadedFileName1 ? uploadedFileName1 : <img src={PlusIcon} className="img-fluid va-baseline" />}
                                                </span> */}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder"> {t("Work Start Date and Time")} </label>
                                            <input
                                                ref={workStartRef}
                                                onChange={(e) => handleWorkStartDateTime(e)}
                                                type="datetime-local"
                                                className="form-control bg-white fw-bolder form-control-solid"
                                                placeholder=""
                                            />
                                            {formik.touched.work_start_date_time && formik.errors.work_start_date_time && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.work_start_date_time}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-7">
                                            <label className="form-label fw-bolder"> {t("Work End Date and Time")} </label>
                                            <input
                                                ref={workEndRef}
                                                onChange={(e) => handleWorkEndDateTime(e)}
                                                type="datetime-local"
                                                className="form-control bg-white fw-bolder form-control-solid"
                                                placeholder=""
                                            />
                                            {formik.touched.work_end_date_time && formik.errors.work_end_date_time && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.work_end_date_time}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 text-right">
                                        <div className="row justify-content-end">
                                            <div className="col-12">
                                                <div className="d-flex justify-content-end">
                                                    <button type='button' onClick={handelSubmit} disabled={!dirty || uploading} className='btn btn-primary fw-bold px-10 py-3'>
                                                        {saved ? t("Saved") : t("Save")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
};

export { PermitDetails }
