import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../components/layout/core'
import {Route, Switch} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next, { t } from 'i18next'
import { InspectionDocument } from './components/InspectionDocument'

const OtherDocuments: FC = () => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page maint-page'>
      <div className='col-xxl-12 mt-0'>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <h4 className='card-title font-18 text-black mb-6 fw-bolder'> {t("Other Documents")} </h4>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-12'>
            <InspectionDocument />
          </div>
        </div>
      </div>
    </div>
  </>
)

const OtherDocumentsPage: FC = () => {
  const intl = useIntl()
  const { t } = useTranslation();
  return (
    <>
      <PageTitle breadcrumbs={[]}> Other Documents </PageTitle>
      <OtherDocuments />
    </>
  )
}

export {OtherDocumentsPage}
