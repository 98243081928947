import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../components/layout/core'
import { OffersList } from '../Offers/Components/OffersComponents'
import { OfferDetails } from '../Offers/Components/OfferDetails'
import { Redirect, Route, Switch } from 'react-router-dom';
import { TeamSearch } from '../VisitsPage/Search/Search'
import { Details } from '../../../pm-offers-and-renewals/components/Details/Details'


const OfferPage: FC = () => {
    const [searchProperty, setSearchProperty] = useState("");
    const [searchBroker, setSearchBroker] = useState("");
    const [openDetails, setOpenDetails] = useState(false)
    const [offerNo, setOfferNo] = useState('')

    const offerId = async (id: any) => {
        setOfferNo(id)
        setOpenDetails(true)
    }
    const offerIdShowHide = async (id: any) => {
        setOpenDetails(false)
    }

    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <div className="com-card-section">
                        <div className="row">
                            <div className="col-12">
                                <div className="card mb-3">
                                    <div className="card-body px-8">
                                        <div className="row">
                                            <div className="col-12">
                                                {!openDetails && (
                                                    <OffersList offerId={offerId} />
                                                )}
                                                {openDetails && <Details {...setOpenDetails} offerIdNo={offerNo} offerIdShowHide={offerIdShowHide} />}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const TeamActivityOfferWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <OfferPage />
        </>
    )
}

export { TeamActivityOfferWrapper }
