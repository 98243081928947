import React, { FC, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import CheckMark from "../../../../assets/images/Check_green_lg.png";

import { useTranslation } from 'react-i18next';
import Loader from '../../../../components/Loader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addNewBroker } from '../../../../utils/makeRequest';
import { IState } from '../../../../utils/interface';
import IntlTelInput from 'react-intl-tel-input'
import { CustomLoader2 } from '../../../../constants/generics/customActios';

export const AddTeamPopup = () => {
    const buttonEl: any = useRef(null);

    const state: any = useSelector(state => state);

    const userToken = state?.auth?.accessToken;
    const userId = state?.auth?.user?.data?.id;
    const { t } = useTranslation();

    const [selectedMobileCode, setMelectedMobileCode] = useState<any>('')
    const [isSubmitting, setSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState('')
    const [addBrokersList, setAddBrokersList] = useState<IState['APIState']>({
        loading: false,
        response: {},
        error: ''
    });

    const {
        loading: loadingAddBrokerResponce,
        response: addBrokerResponce,
        error: errorAddBrokerResponce
    } = addBrokersList;

    const initialValues = {
        firstNameField: "",
        lastNameField: "",
        emailIDField: "",
        emirateIDField: "",
        mobileNumberField: "",
        designationField: "",
    }

    const validationSchema = Yup.object({
        firstNameField: Yup.string()
            .required(t('First Name is required!'))
            .matches(/^[^\s].*/, t('First Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
        lastNameField: Yup.string()
            .required(t('Last Name is required!'))
            .matches(/^[^\s].*/, t('Last Name cannot start with a blankspace!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .min(3, t("Minimum 3 characters!"))
            .max(25, t('Maximum 25 characters!')),
        emirateIDField: Yup.string()
            .required(t('Emirates ID is required!'))
            .matches(/^[^\s].*/, t('Emirates ID cannot start with a blankspace!'))
            .matches(/^(\d+-?)+\d+$/, t("Emirates Id contains only numbers and hypen!"))
            .min(15, t('Minimum length is 15!'))
            .max(25, t('Maximum 25 characters!')),
        mobileNumberField: Yup.string()
            .min(4, t('Mobile No should be greater then 4 digits!'))
            .max(16, t('Mobile No should be smaller then 16 digits!'))
            .matches(/^\+?[0-9]+$/, t('Invalid format, only + and numbers allowed!'))
            .required(t('Mobile No is required!')),
        emailIDField: Yup.string()
            .required(t('Email ID is required!'))
            .email(t('Enter a valid email!'))
            .matches(/^[^\s].*/, t('Email Id cannot start with a blankspace!')),
        designationField: Yup.string()
            .required(t('Designation is required!'))
            .matches(/^[^\s].*/, t('Designation cannot start with a blankspace!'))
            .matches(/^[a-z0-9]+$/i, t("Special characters and blankspace are not allowed for Designation!"))
            .min(4, t('Minimum length is 4!'))
            .matches(/^[A-Za-z ]*$/, t('Only alphabets allowed!'))
            .max(25, t('Maximum 25 characters!')),
    });

    const onSubmit = (values: any) => {
        // console.log(values, 'values');

        setSubmitting(true)
        setSubmitError('')

        const newBrokerAdmin = {
            first_name: values.firstNameField,
            last_name: values.lastNameField,
            email: values.emailIDField,
            emirates_id: values.emirateIDField,
            phone: selectedMobileCode + values.mobileNumberField,
            designation: values.designationField,
            user_type: "",
            id_role: "BROKER",
            created_by: userId
        };

        addNewBroker(setAddBrokersList, newBrokerAdmin, userToken, successNewBroker);


        // setTimeout(() => {
        //     createContractorUser(formData)
        //         .then((response: any) => {
        //             setSubmitting(false)
        //             if (response.errorCode === 1)
        //                 setSubmitError(response.errorDescription)
        //             else if (response.errorCode === 0) {
        //                 formik.resetForm();
        //                 setSubmitting(false)
        //                 var ev = new Event('click', { bubbles: true });
        //                 buttonEl.current.dispatchEvent(ev);
        //             } else
        //                 setSubmitError("Some error occured. Please try again !")
        //         })
        //         .catch((e) => {
        //             console.log(e, 'Error');
        //             setSubmitting(false)
        //             setSubmitError('Some error occured. Please try again !')
        //         })
        // }, 1000)
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    function successNewBroker() {
        var ev = new Event('click', { bubbles: true });
        buttonEl.current.dispatchEvent(ev);
    }

    const handlePhoneNumber = (status: any, phoneNumber: any, country: any) => {
        setMelectedMobileCode(country?.dialCode && !phoneNumber.includes('+') ? `+${country?.dialCode}` : '')
        formik.setFieldValue('mobileNumberField', phoneNumber)
        formik.setFieldTouched('mobileNumberField')
    }

    const handleSubmit = () => {

        // console.log('handleSubmit', formik.errors);

        // formik.handleSubmit
        formik.handleSubmit()
    }

    return (
        <>
            {/*add broker modal*/}
            <div className="modal fade custom-modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden='true' id="addBroker">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-10">
                        <div className="modal-header py-5 text-white border-0">
                            <h5 className="modal-title">{t("Add Team")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form className="form-add-design needs-validation" id="addUserFormId">
                                <div className="mb-5">
                                    <label htmlFor="f-name" className="form-label fw-bolder mb-3">
                                        {t("First Name")} <small className="text-danger">* </small>
                                    </label>
                                    <input
                                        {...formik.getFieldProps('firstNameField')}
                                        type="text"
                                        className="form-control fw-bolder form-control-solid"
                                        placeholder={t("First Name")}
                                    />
                                    {formik.touched.firstNameField && formik.errors.firstNameField ? (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.firstNameField}</small>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="l-name" className="form-label fw-bolder mb-3">
                                        {t("Last Name")} <small className="text-danger">* </small></label>
                                    <input
                                        {...formik.getFieldProps('lastNameField')}
                                        type="text"
                                        className="form-control fw-bolder form-control-solid"
                                        placeholder={t("Last Name")}
                                    />
                                    {formik.touched.lastNameField && formik.errors.lastNameField ? (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.lastNameField}</small>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="emirated-id" className="form-label fw-bolder mb-3">
                                        {t("Emirates Id")} <small className="text-danger">* </small></label>
                                    <input
                                        {...formik.getFieldProps('emirateIDField')}
                                        type="text"
                                        className="form-control fw-bolder form-control-solid"
                                        placeholder={t("Emirates Id")}
                                    />
                                    {formik.touched.emirateIDField && formik.errors.emirateIDField ? (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.emirateIDField}</small>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="mobile-no" className="form-label fw-bolder mb-3">
                                        <small className="text-danger"></small>{t("Mobile Number")} <small className="text-danger">* </small></label>
                                    <IntlTelInput
                                        preferredCountries={['ae', 'in', 'us']}
                                        containerClassName='intl-tel-input w-100'
                                        separateDialCode={true}
                                        fieldName='mobile_no'
                                        value={formik.values.mobileNumberField}
                                        inputClassName='form-control form-control-lg mb-4 form-control-solid w-100'
                                        onPhoneNumberChange={handlePhoneNumber}
                                        onPhoneNumberBlur={handlePhoneNumber}
                                        placeholder={t('Mobile No.')}
                                    />
                                    {formik.touched.mobileNumberField && formik.errors.mobileNumberField ? (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.mobileNumberField}</small>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="mail-id" className="form-label fw-bolder mb-3">
                                        {t("Email Id")} <small className="text-danger">* </small></label>
                                    <input
                                        {...formik.getFieldProps('emailIDField')}
                                        type="text"
                                        className="form-control fw-bolder form-control-solid"
                                        placeholder={t("Email Id")}
                                    />
                                    {formik.touched.emailIDField && formik.errors.emailIDField ? (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.emailIDField}</small>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="designation" className="form-label fw-bolder mb-3">
                                        {t("Designation")} <small className="text-danger">* </small></label>
                                    <input
                                        {...formik.getFieldProps('designationField')}
                                        type="text"
                                        className="form-control fw-bolder form-control-solid"
                                        placeholder={t("Designation")}
                                    />
                                    {formik.touched.designationField && formik.errors.designationField ? (
                                        <div className='d-flex flex-column text-danger'>
                                            <small className="text-danger">{formik.errors.designationField}</small>
                                        </div>
                                    ) : null}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            {loadingAddBrokerResponce && (
                                <Loader />
                            )}

                            {!loadingAddBrokerResponce && (
                                <>
                                    <button form="addUserFormId" type="button" onClick={handleSubmit} className="btn btn-primary text-uppercase w-100">{t("ADD")}</button>
                                </>
                            )}

                            {errorAddBrokerResponce && (
                                <div className="alert alert-danger w-100 mt-2" role="alert">
                                    {errorAddBrokerResponce}
                                </div>
                            )}
                            <button ref={buttonEl} type="button" className="d-none invisible btn btn-primary text-uppercase w-0 h-0" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#verificationModal">{t("ADD modal")}</button>

                        </div>
                    </div>
                </div>
            </div>
            {/*verification modal*/}
            <div className="modal fade verification-modal" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden='true' id="verificationModal">
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-center verification-box">
                                <img src={CheckMark} alt="check-mark" />
                                <h3 className="text-primary mb-3">{t("Done")}</h3>
                                <p className="font-12 mb-8">
                                    {t("The Profile is under verification.")}
                                </p>
                                <p className="font-12 mb-8">
                                    {t("We are currently validating the profile. You will receive an Email once its approved.")}
                                </p>
                                <a href='/broker-admin-MyTeam'>
                                <button type="button" className="btn btn-primary text-uppercase w-100" data-bs-dismiss="modal">
                                    {t("Continue")}
                                </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};