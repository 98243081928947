import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import DatePicker from 'react-date-picker'
import { useFormik } from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import Bedroom from '../../../../assets/images/Bed_1.png'
import moment from 'moment'
import { Time } from './Time'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../constants'
import { getAvailableTimings, rescheduleMaintenanceRequest } from '../../../../constants/axios/apis'
import { ViewPopup } from '../../ViewPopup'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

const today = new Date()
today.setHours(0, 0, 0, 0)

const Reschedule: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [viewImage, setViewImage] = useState<any>()
  const closeRef = useRef<any>()
  const currentContract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const [timeSlots, setTimeSlots] = useState<any>([])
  const location = useLocation<any>()
  const [issue, setIssue] = useState<any>()
  const [date, setDate] = useState<any>(new Date())
  const { t } = useTranslation();

  useEffect(() => {
    setIssue(location?.state?.issue)
  }, [])

  useEffect(() => {
    if (issue) {
      setDate(new Date(issue?.appointmentDate))
    }
  }, [issue])
  

  const initialValues = {
    date: date,
    // time: moment(issue?.time, 'HH:mm').format('HH:mm'),
    time:'',
  }
  const [dateValue, setDateValue] = useState<any>()

  const handleDate = (e: any) => {
    setDateValue(e);
    formik.setFieldValue('date', new Date(e))
    // formik.setFieldValue('time', '')
    formik.setFieldValue('time', '')
    formik.setFieldTouched('time', false)
  }

  const rescheduleRequestSchema = Yup.object().shape({
    date: Yup.date()
      .min(today, t("Selected date cannot be in the past"))
      .required(t("Please select a date")),
    time: Yup.string().required(t("Please select a time slot")),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: rescheduleRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      const data = new FormData()
      data.append('id', issue.id)
      data.append('date', moment(values.date).format('YYYY-MM-DD'))
      data.append('time', values.time)

      setTimeout(() => {
        // rescheduleMaintenanceRequest(
        //   moment(values.date).format('YYYY-MM-DD'),
        //   values.time,
        //   issue.id
        // )
        rescheduleMaintenanceRequest(data)
          .then((response: any) => {
            setLoading(false)
            resetForm()
            closeRef.current.click()
            history.push('/maintenance/issues-view/' + response.data.id)
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const handleView = (data: any) => {
    setViewImage(data)
  }

  useEffect(() => {
    if (currentContract) {
      getAvailableTimings(
        currentContract.propertyID,
        moment(formik.values.date).format('YYYY-MM-DD'),
        0
      ).then(({ data }) => {
        setTimeSlots(data)
      })
    }
  }, [formik.values.date])

  useEffect(() => {
    if (currentContract) {
      if (formik.values.date && formik.values.date !== '') {
        getAvailableTimings(
          currentContract.propertyID,
          moment(formik.values.date).format('YYYY-MM-DD'),
          0
        ).then(({data}) => {
          //Validate/Filter and display only future date & time slots.
          function validateTime(inputTime: any) {
            const dt = moment(
              moment(formik.values.date).format('YYYY-MM-DD') + ' ' + inputTime + ':00'
            )
            if (moment().diff(dt, 'minutes') > 0) return false
            return true
          }
          const validTimes = data.filter((element: any) => validateTime(element))
          setTimeSlots(validTimes)
        })
      } else {
        setTimeSlots([])
      }
    }
  }, [formik.values.date])


  // useEffect(() => {
  //   if (moment(date, 'YYYY-mm-dd').isSame(moment(formik.values.date, 'YYYY-MM-DD'), 'day')) {
  //     formik.setFieldValue('time', moment(issue?.time, 'HH:mm').format('HH:mm'))
  //   } else {
  //     formik.setFieldValue('time', '')
  //     formik.setFieldTouched('time', false)
  //   }
  // }, [formik.values.date])

  return (
    <div className='com-card-section'>
      <div className='row mb-6'>
        <div className='col-12 col-md-12'>
          <h4 className='card-title font-18 text-black mb-0'>
            <Link to={'/maintenance/issues-view/' + issue?.id} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              <span className='text-black'>{t("Reschedule")}</span>
            </Link>
          </h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='card mb-3'>
              <div className='card-body px-8 py-8 pt-6'>
                <div className='row form-add-design'>
                  <div className='col-md-4 col-12'>
                    <div className='mb-7'>
                      <label className='form-label fw-bolder'> {t("Issue Category")} </label>
                      <div className='form-control fw-bolder form-control-solid bg-light'>
                        <h6 className='font-14 fw-bold d-block mb-0 py-1 work-order-input'>
                          {issue?.issueCategory}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-7'>
                      <label className='form-label fw-bolder'> {t("Issue Type")} </label>
                      <div className='form-control fw-bolder form-control-solid bg-light'>
                        <h6 className='font-14 fw-bold d-block mb-0 py-1 work-order-input'>
                          {issue?.issueType}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-4'>
                    <div className='mb-7 date-picker'>
                      <label className='form-label fw-bolder'> {t("Date")}<span className='text-danger'>*</span> </label>
                      <DatePicker
                        onChange={(e: any) => handleDate(e)}
                        value={formik.values.date}
                        minDate={new Date()}
                        className={clsx(
                          'form-control fw-bolder form-control-solid',
                          {
                            'is-invalid': formik.touched.date && formik.errors.date,
                          },
                          {
                            'is-valid': formik.touched.date && !formik.errors.date,
                          }
                        )}
                        clearIcon={null}
                        format='dd-MM-y'
                      />
                      {formik.touched.date && formik.errors.date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.date}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 col-12'>
                    {/* <span className='text-danger'>*</span>  */}
                    <Time timeSlots={timeSlots} formik={formik} />
                  </div>
                  <div className='col-12 col-sm-4'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'>
                        {t("Precise Location")}
                      </label>
                      <div className='form-control fw-bolder form-control-solid bg-light'>
                        <h6 className='font-14 fw-bold d-block mb-0 py-1'>
                          <img src={Bedroom} className='img-fluid me-5' width='18px' />{' '}
                          {issue?.location}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {issue?.photo[0] &&
                    <div className='col-12 col-sm-4'>
                      <div className='mb-6'>
                        <label className='form-label fw-bolder text-black mb-3'>{t("Image")}</label>
                        <div className='d-flex service-req-img align-items-center'>
                          <img src={issue?.photo[0]} className='img-fluid me-5' />
                          {/* <span className='text-black fw-bold me-8'> IMG012101.JPG </span> */}
                          <button className='border-0 text-primary font-12 btn'
                            data-bs-target='#view-popUpreschedule'
                            data-bs-toggle='modal'
                            data-bs-dismiss='modal'
                            type='button'
                            onClick={() => {
                              handleView(issue?.photo[0])
                            }}>
                            {t("View")}
                          </button>
                          <ViewPopup viewImage={viewImage} />
                        </div>
                      </div>
                    </div>
                  }
                  <div className='col-12 col-sm-12'>
                    <div className='mb-6'>
                      <label className='form-label fw-bolder text-black mb-3'>{t("Description")}</label>
                      <textarea
                        className='form-control form-control-lg form-control-solid bg-light'
                        value={issue?.description}
                        rows={1}
                        disabled
                      />
                    </div>
                  </div>
                  <div className='col-12 text-end mt-5'>
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading}>
                      {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {t("Please wait...")}{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}

export { Reschedule }
