import React, { FC, useEffect, useRef, useState } from 'react'
import { Step1 } from './components/steps/Step1'
import { Step3 } from './components/steps/Step3'
import { Step4 } from './components/steps/Step4'
import { Step5 } from './components/steps/Step5'
import { StepperComponent } from '../../setup/ts/components'
import { FormikValues } from 'formik'
import { createAccountSchemas, ICreateAccount, inits } from './components/CreateAccountWizardHelper'
import CurrentStep from '../../assets/images/current-step.png'
import completedStep from '../../assets/images/completed-step.png'
import { useSelector } from 'react-redux'
import { RootState } from '../../constants'
import { getConfig, getServiceRequestByData, getStepsStatus } from '../../constants/axios/apis'
import { RequestSuccessfulPopup } from '../SuccessfulPopup/RequestSuccessfulPopup'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import LeftArrow from '../../assets/images/Left_Arrow.png'
import { useHistory } from "react-router-dom";

type Props = {
  step?: any
}

const MoveOut: FC<Props> = ({ step }) => {
  let history = useHistory();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const contract: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.selectedContract)
  const [message, setMessage] = useState<any>()
  const [clearanceCharges, setclearanceCharges] = useState<any>([])
  const [otherCharges, setOtherCharges] = useState<any>([])
  const [totalClearanceCharges, setTotalClearanceCharges] = useState<any>(0)
  const [totalOtherCharges, setTotalOtherCharges] = useState<any>(0)
  const [vatPercent, setVatPercent] = useState<any>(0)
  const { t } = useTranslation();

  const [step1Status, setStep1Status] = useState<any>({
    status: false,
    data: {}
  })
  const [step3Status, setStep3Status] = useState<Boolean>(false)
  const [step4Status, setStep4Status] = useState<any>({
    status: false,
    data: {}
  })
  const [step5Status, setStep5Status] = useState<any>({
    status: false,
    data: {}
  })

  const getStep1Status = () => {
    getServiceRequestByData(15, '', contract?.propertyID).then((response) => {
      if (response.data) {
        setStep1Status({
          status: true,
          data: response.data[0]
        })
      }
    })
  }
  // const getStep3Status = () => {
  //   getServiceRequestByData(21, '', contract?.propertyID).then((response) => {
  //     if (response.data) {
  //       setStep3Status(true)
  //     }
  //   })
  // }
  const getStep4Status = () => {
    getServiceRequestByData(16, '', contract?.propertyID).then((response) => {
      if (response.data) {
        setStep4Status({
          status: true,
          data: response.data[0]
        })
      }
    })
  }
  const getStep5Status = () => {
    getServiceRequestByData(19, '', contract?.propertyID).then((response) => {
      if (response.data) {
        setStep5Status({
          status: true,
          data: response.data[0]
        })
      }
    })
  }

  useEffect(() => {
    getStep1Status()
    getStep4Status()
    getStep5Status()
  }, [contract])

  const getMoveOutStepsStatus = (property_id: any, contract_id: any) => {
    getStepsStatus(property_id, contract_id, 1).then((response) => {
      // console.log(response)
      response.data?.map((value: any) => {
        if (value.id == 15) {
          setStep1Status((prevState: any) => {
            return {
              ...prevState,
              status: value.status
            }
          })
        }

        if (value.id == 21) {
          setStep3Status(value.status)
        }

        if (value.id == 16) {
          setStep4Status((prevState: any) => {
            return {
              ...prevState,
              status: value.status
            }
          })
        }

        if (value.id == 19) {
          setStep5Status((prevState: any) => {
            return {
              ...prevState,
              status: value.status
            }
          })
        }
      })
    })
  }

  useEffect(() => {
    if (contract) {
      getMoveOutStepsStatus(contract?.propertyID, contract?.id)
    }
    getConfig('').then(({data}: any) => {
      if (data && Array.isArray(data)) {
        const vat_per = data?.find((item: any) => item.key == 'vat_percentage').value
        setVatPercent(vat_per ? parseFloat(vat_per) : 0)
      }
    })
  }, [contract])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    if (step) {
      return stepper.current?.goto(step)
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    getServiceRequestByData(21, '', contract?.propertyID).then((response) => {
      if (response.data) {
        setclearanceCharges(response.data)
      } else {
        setclearanceCharges([])
      }
    })

    getServiceRequestByData(24, '', contract?.propertyID).then((response) => {
      if (response.data) {
        setOtherCharges(response.data)
      } else {
        setOtherCharges([])
      }
    })
  }, [contract])

  useEffect(() => {

    if (!clearanceCharges.length) {
      setTotalClearanceCharges(0)
    } else if (clearanceCharges && totalClearanceCharges == 0) {
      clearanceCharges.map((charge: any) => {
        setTotalClearanceCharges((prevState: any) => prevState + (charge.details.amount * charge.details.quantity))
      })
    }

    if (!otherCharges.length) {
      setTotalOtherCharges(0)
    } else if (otherCharges && totalOtherCharges == 0) {
      otherCharges.map((charge: any) => {
        setTotalOtherCharges((prevState: any) => prevState + (charge.details.amount * charge.details.quantity))
      })
    }
  }, [clearanceCharges, otherCharges])

  return (
    <div className='com-card-section movein-page'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <p onClick={history.goBack} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
              <span className='text-black'>{t("Move Out Process")}</span>
            </p>
          </h4>
        </div>
      </div>
      <div className='col-12'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav'>
            <div
              className='stepper-item current'
              style={{ cursor: 'pointer' }}
              data-kt-stepper-element='nav'
              onClick={() => stepper.current?.goto(1)}
            >
              <h3 className='stepper-title'>
                {t("Move Out Inspection")}
                {step1Status.status ? (
                  <span className='completed-step ps-3 d-inline-block'>
                    <img src={completedStep} alt='complete-step' />
                  </span>
                ) : (
                  <span className='current-step ps-3'>
                    <img src={CurrentStep} alt='current-step' />
                  </span>
                )}
              </h3>
            </div>

            <div
              className='stepper-item'
              style={{ cursor: 'pointer' }}
              data-kt-stepper-element='nav'
              onClick={() => stepper.current?.goto(2)}
            >
              <h3 className='stepper-title'>
                {t("Clearance Charges")}
                {step3Status ? (
                  <span className='completed-step ps-3 d-inline-block'>
                    <img src={completedStep} alt='complete-step' />
                  </span>
                ) : (
                  <span className='current-step ps-3'>
                    <img src={CurrentStep} alt='current-step' />
                  </span>
                )}
              </h3>
            </div>

            <div
              className='stepper-item'
              style={{ cursor: 'pointer' }}
              data-kt-stepper-element='nav'
              onClick={() => stepper.current?.goto(3)}
            >
              <h3 className='stepper-title'>
                {t("Move Out Approval")}
                {step4Status.status ? (
                  <span className='completed-step ps-3 d-inline-block'>
                    <img src={completedStep} alt='complete-step' />
                  </span>
                ) : (
                  <span className='current-step ps-3'>
                    <img src={CurrentStep} alt='current-step' />
                  </span>
                )}
              </h3>
            </div>

            <div
              className='stepper-item'
              style={{ cursor: 'pointer' }}
              data-kt-stepper-element='nav'
              onClick={() => stepper.current?.goto(4)}
            >
              <h3 className='stepper-title'>
                {t("Request Refund")}
                {step5Status.status ? (
                  <span className='completed-step ps-3 d-inline-block'>
                    <img src={completedStep} alt='complete-step' />
                  </span>
                ) : (
                  <span className='current-step ps-3'>
                    <img src={CurrentStep} alt='current-step' />
                  </span>
                )}
              </h3>
            </div>
          </div>

          <div className='movein-form' id='kt_create_account_form'>
            <div className='current' data-kt-stepper-element='content'>
              <Step1 getStepStatus={getStep1Status} stepStatus={step1Status} contract={contract} setMessage={setMessage} />
            </div>

            <div data-kt-stepper-element='content' className='flex-column'>
              <Step3
                contract={contract}
                stepStatus={step3Status}
                clearanceCharges={clearanceCharges}
                otherCharges={otherCharges}
                totalClearanceCharges={totalClearanceCharges}
                totalOtherCharges={totalOtherCharges}
                vatPercent={vatPercent}
                setMessage={setMessage}
              />
            </div>

            <div data-kt-stepper-element='content'>
              <Step4 getStepStatus={getStep4Status} stepStatus={step4Status} contract={contract} setMessage={setMessage} />
            </div>

            <div data-kt-stepper-element='content'>
              <Step5
                contract={contract}
                getStepStatus={getStep5Status}
                stepStatus={step5Status}
                setMessage={setMessage}
                totalClearanceCharges={totalClearanceCharges}
                totalOtherCharges={totalOtherCharges}
                vatPercent={vatPercent}
              />
            </div>
          </div>
        </div>
      </div>
      <RequestSuccessfulPopup message={message} />
    </div>
  )
}

export { MoveOut }
