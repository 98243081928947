import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

type Props = {
    allDocumentCount?: any
    needActionDocumentCount?: any
}

const MyUnitlist: React.FC<Props> = ({allDocumentCount, needActionDocumentCount}) => {
    const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-body px-7 py-0">
                            <div className="row access-cd-tabs">
                                <div className="col-md-12 col-12">
                                    <div className='d-flex overflow-auto h-55px'>
                                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/my-unit/lease-details' && 'active')}
                                                    to='/my-unit/lease-details'>
                                                    {t("Lease Details")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/my-unit/payments' && 'active')}
                                                    to='/my-unit/payments'>
                                                    {t("Payments")}
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/my-unit/documents/community-docx' ?
                                                        'active' :
                                                        location.pathname === '/my-unit/documents/personal-docx' ?
                                                            'active' :
                                                            '')}
                                                    to='/my-unit/documents/personal-docx'>
                                                    {t("Documents")} ({allDocumentCount})
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link
                                                    className={`nav-link position-relative text-active-primary ` +
                                                    (location.pathname === '/my-unit/need-action' && 'active')}
                                                    to='/my-unit/need-action'>
                                                    {t("Need Action")} ({needActionDocumentCount})
                                                </Link>
                                            </li>                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {MyUnitlist}
