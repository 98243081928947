import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AttachmentPopup } from './AttachmentPopup';
import { useTranslation } from 'react-i18next'

export function RentDeductionPreview({ selectedIssue }: any) {
    const [issue, setIssue] = useState<any>()
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedIssue) {
            setIssue(selectedIssue)
            setLoading(false)
        }
    }, [selectedIssue])

    return (
        <>
            {!loading && issue && issue?.code == 'RR' ? (
                <div className='row'>
                    <div className='col-12'>
                        <div className='accordion accordion-flush' id='accordionFlushExampleRentReductionCard'>
                            <div className='accordion-item offers_details_accordian_div1'>
                                <h2 className='accordion-header position-relative' id='flush-headingRentReductionCard'>
                                    <button
                                        className='accordion-button collapsed accordian_bt'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#flush-collapseRentReductionCard'
                                        aria-expanded='false'
                                        aria-controls='flush-collapseRentReductionCard'
                                    >
                                        {t("Rent Reduction")}
                                    </button>
                                    <span className='services-badge'>
                                        {issue?.status == 0 ? t("Open") : t("Closed")}
                                    </span>
                                </h2>
                                <div
                                    id='flush-collapseRentReductionCard'
                                    className='accordion-collapse collapse show'
                                    aria-labelledby='flush-headingRentReductionCard'
                                    data-bs-parent='#accordionFlushExampleRentReductionCard'
                                >
                                    <div className='accordion-body py-5'>
                                        <div className='row form-add-design'>
                                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                <div className='mb-10'>
                                                    <label className='form-label fw-bolder'>{t("Customer Name")}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control fw-bolder form-control-solid'
                                                        disabled
                                                        value={issue?.details?.customer_name}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                <div className='mb-10'>
                                                    <label className='form-label fw-bolder'>{t("Renewal Start Date")}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control fw-bolder form-control-solid'
                                                        disabled
                                                        value={moment(issue?.details?.renewal_start_date).format('DD-MM-YYYY')}
                                                    />
                                                    {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                <div className='mb-10 position-relative'>
                                                    <label className='form-label fw-bolder'>{t("Renewal End Date")}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control fw-bolder form-control-solid'
                                                        disabled
                                                        value={moment(issue?.details?.renewal_end_date).format('DD-MM-YYYY')}
                                                    />
                                                    {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                <div className='mb-10 position-relative'>
                                                    <label className='form-label fw-bolder'>{t("Renewal Annual Amount")}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control fw-bolder form-control-solid'
                                                        value={issue?.details?.renewal_amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                <div className='mb-10 position-relative'>
                                                    <label className='form-label fw-bolder'>{t("Number of Installments")} </label>
                                                    <input
                                                        type='text'
                                                        className='form-control fw-bolder form-control-solid'
                                                        value={issue?.details?.installments}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                                                <div className='mb-10 position-relative'>
                                                    <label className='form-label fw-bolder'>{t("Quoted Amount")}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control fw-bolder form-control-solid'
                                                        value={parseFloat(issue?.details?.offer_request_price)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    )
}