import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import LeftArrow from '../../../../../assets/images/Left_Arrow.png'
import LeftArrow from '../../../../src/assets/images/Left_Arrow.png'
import { AddLeadsForm } from './AddLeadForm'
export const AddLead: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='com-card-section'>
      <div className='row gy-5 g-xl-8'>
        <div className='col-md-12 col-12 mt-0'>
          <div className='row align-items-center mb-6'>
            <div className='col-md-6 col-12'>
              <h4 className='card-title font-18 text-black mb-0'>
                <Link to={'/leads/list'} className=''>
                  <img src={LeftArrow} alt='back' className='img-fluid me-5' />
                </Link>
                {t("Add Lead")}
              </h4>
            </div>
            {/* <div className='col-md-6 col-12 text-end'>
              <button className='btn btn-theme py-2 font-14 fw-normal mt-3 mt-md-0'>
                {t("Emergency?")}
              </button>
            </div> */}
          </div>
        </div>
        <AddLeadsForm />
      </div>
    </div>
  )
}