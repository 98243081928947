import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import Bath from '../../../../../../assets/images/bath.svg'
import Sqft from '../../../../../../assets/images/sqft-new-icon.svg'
import Bed from '../../../../../../assets/images/bed.svg'
import Upcoming02 from '../../../../../../assets/images/loc-theme.png'
import { useDispatch, useSelector } from 'react-redux'
import { getUpcomingVisits } from '../../../../../../utils/makeRequest'
import { IState } from '../../../../../../utils/interface'
import { useTranslation } from 'react-i18next'
import {
  CustomLoader2,
  CustomViewPopup,
  NoDataFound1,
} from '../../../../../../constants/generics/customActios'
import moment from 'moment'
import { MdOutlineClose } from 'react-icons/md'
import { cancelScheduleVisit } from '../../../../../../constants/axios/apis'
import { CustomRequestSuccessfulPopup } from '../../../../../SuccessfulPopup/CustomRequestSuccessfulPopup'
import YesIcon from '../../../../../../assets/images/yes-new-icon.svg'
import { ReschedulePopup } from '../../ReschedulePopup/ReschedulePopup'
import { ReservedPropertyPopup } from '../../ReservedPropertyPopup/ReservedPropertyPopup'

export function TeamUpcomingComponent({ searchBroker, searchProperty }: any) {
  const [viewImage, setViewImage] = useState<any>()
  const state: any = useSelector((state) => state)
  const ref3 = useRef<any>(0)
  const userToken = state?.auth?.accessToken
  const ref = useRef<any>(0)
  const [cancelId, setCancelId] = useState<any>()
  const buttonEl: any = useRef(null)
  const [submitError, setSubmitError] = useState('')
  const [loadings, setLoadings] = useState(false)
  const [status, setStatus] = useState<any>('')
  const [submitting, setSubmitting] = useState<any>('')
  const [successMsg, setSuccessMsg] = useState('')
  const history = useHistory()
  const [scheduleData, setScheduleData] = useState<any>('')
  const { t } = useTranslation()
  const [upcomingVisits, setUpcomingVisits] = useState<IState['APIState']>({
    loading: false,
    response: [],
    error: '',
  })
  const { loading, response: visits, error } = upcomingVisits
  useEffect(() => {
    getUpcomingVisits(setUpcomingVisits, userToken)
  }, [])

  const filteredVisits = visits
    .filter((items: any) => {
      return searchBroker === '' || (searchBroker !== '' && searchBroker === `${items?.broker_id}`)
    })
    .filter((items: any) => {
      const searchRegex = new RegExp(searchProperty, 'i')
      return (
        searchProperty === '' || (searchProperty !== '' && items?.propertyName?.match(searchRegex))
      )
    })

  if (!loading && (filteredVisits === undefined || filteredVisits.length === 0)) {
    return <NoDataFound1 />
  }

  const addScheduleData = (visit: any) => {
    setScheduleData(visit)
  }
  const handlePreviewViewDoc = (visit: any) => {
    setViewImage(visit)
    ref3.current.click()
  }
  const onCancel = (id: any) => {
    setTimeout(() => {
      setLoadings(true)
      setSubmitError('')
      cancelScheduleVisit(id)
        .then((response: any) => {
          setLoadings(false)
          if (response.errorCode === 1) {
            setSubmitError(response.errorDescription)
          } else if (response.errorCode === 0) {
            setSuccessMsg(response?.description)
            setLoadings(false)
            var ev = new Event('click', { bubbles: true })
            buttonEl.current.dispatchEvent(ev)
          } else {
            setSubmitError(t('Some error occured. Please try again !'))
          }
        })
        .catch((e) => {
          setLoadings(false)
          setSubmitting(false)
          setStatus(t('Invalid Data Provided'))
        })
    }, 1000)
  }
  const broker_array = [
    { brokerName: "Vishnu", mobile: "1234567890", name: "Bob" },
    { brokerName: "Amit", mobile: "0987654321", name: "Jane" },
    { brokerName: "Karan", mobile: "5678901234", name: "Eva" },
    { brokerName: "Vishnu", mobile: "2345678901", name: "Grace" },
    { brokerName: "Amit", mobile: "8901234567", name: "Charlie" },
    { brokerName: "Karan", mobile: "3456789012", name: "Hank" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Alice" },
    { brokerName: "Amit", mobile: "6789012345", name: "Frank" },
    { brokerName: "Karan", mobile: "7890123456", name: "David" },
    { brokerName: "Vishnu", mobile: "0123456789", name: "John" },
    { brokerName: "Amit", mobile: "9012345678", name: "Eva" },
    { brokerName: "Karan", mobile: "6789012345", name: "Bob" },
    { brokerName: "Vishnu", mobile: "4567890123", name: "Jane" },
    { brokerName: "Amit", mobile: "2345678901", name: "Charlie" },
    { brokerName: "Karan", mobile: "8901234567", name: "Hank" }
  ]


  return (
    <>
      <div className='row'>
        <div className='col-lg-6'>
          {loading && <CustomLoader2 />}
          {filteredVisits.map((visit: any, index: number) => {
            return (
              <div className='pm-offers-and-renewals  mb-10 mt-5' key={index}>
                <div className='card'>
                  <div className='row align-items-center'>
                    <div className='col-sm-12 col-12 col-lg-12'>
                      <div className='upcoming_img'>
                        <img
                          src={visit?.property_cover_img ? visit?.property_cover_img : 'No Image'}
                          className='img-fluid max-ht'
                        />
                        <span className='badge badge-pill badge-dark prop-type-badge'>
                          {parseInt(visit?.property_search_type) === 1
                            ? t('Residential')
                            : parseInt(visit?.property_search_type) === 2
                              ? t('Commercial')
                              : visit?.property_search_type}
                        </span>
                      </div>
                    </div>
                    <div className='col-lg-12 col-12 align-self-center'>
                      <div className='offer_main_details p-3'>
                        <div className='col-12 pt-3'>
                          <p className='text-black fw-bolder font-17 text-title my-offer-property-name mb-3'>
                            {visit?.property_name ? visit?.property_name : '-'}
                          </p>

                          <h2 className='text-black fw-bolder mb-3 text-sub-title'>
                            <small>{t('AED')}</small>{' '}
                            <span className='fw-boldest'>
                              {visit?.price?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })}
                            </span>{' '}
                            <small> / {t('Year')} </small>
                          </h2>
                        </div>
                        <div className='col-12'>
                          <p>
                            <span>
                              <img src={Upcoming02} className='img-fluid map-icon' />{' '}
                            </span>
                            {visit?.propertyCode?.substring(
                              visit?.propertyCode?.lastIndexOf('-') + 1,
                              visit?.propertyCode?.length
                            ) + ' | '}{' '}
                            {visit?.buildingName && visit?.buildingName + ' | '}{' '}
                            {visit?.address && visit?.address + ' | '}{' '}
                            {visit?.location && visit?.location}
                          </p>
                        </div>
                        <div className='col-12'>
                          <div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
                            {parseInt(visit?.property_search_type) !== 2 &&
                              <p className='p-3 me-2 mb-0'>
                                <img src={Bed} className='img-fluid me-3' alt='bed' />
                                {visit?.bedRooms ? visit?.bedRooms : '0'}{' '}
                              </p>
                            }
                            <p className='p-3 me-2 mb-0'>
                              <img src={Bath} className='img-fluid me-3' alt='bath' />{' '}
                              {visit?.bathRooms ? visit?.bathRooms : '0'}{' '}
                            </p>
                            <p className='p-3 me-2 sqft-my-offer mb-0'>
                              <img src={Sqft} className='img-fluid me-2' alt='sqft' />{' '}
                              {visit?.builtInArea ? visit?.builtInArea : '-'}
                              {t('Sqmt')}
                            </p>
                            <p className='p-3 mb-0'>
                              <img src={YesIcon} className='img-fluid me-2' alt='yes' />{' '}
                              {visit?.isParkingAvailable === 'Y' ? t('Yes') : t('No')}
                            </p>
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='mainCard_d_etails_div_2 d-flex flex-wrap'>
                            <p className='p-3 me-2'>
                              Broker : {t(broker_array[index].brokerName)}
                            </p>
                            <p className='p-3'>
                              User Name : {t(broker_array[index].name)}
                            </p>
                            <p className='p-3 me-2 sqft-my-offer'>
                              User Mobile : {t(broker_array[index].mobile)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <div className='text-center p-3'>
                        <div className='col-12'>
                          <div className='visit-confirmed-date-di rounded mb-5'>
                            <div className='row align-items-center'>
                              <div className='col-md-6 col-12'>
                                <p className='text-black fw-bolder font-15 mb-0 text-start'>
                                  <span className='bg-success bg-opacity-10 text-success px-5 py-3 rounded fw-bolder'>{t('Visit Confirmed')}</span>
                                </p>
                              </div>
                              <div className='col-md-6 col-12 text-end'>
                                <p className='text-black text-md-end text-start fw-bold mb-0 mt-3 mt-md-0'>
                                  <span className='me-3'>
                                    {moment(visit?.visit_date).format('DD-MM-YYYY')}
                                  </span>
                                  <span>{visit?.visitTime ? visit?.visitTime : '-'}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='row g-3'>
                            <div className='col-sm-3'>
                              <button
                                className='btn btn-outline-primary font-13 fw-bold w-100 py-2 px-2 border-1'
                                onClick={() => handlePreviewViewDoc(visit?.qrCode)}
                              >
                                {t('QR Code')}
                              </button>
                            </div>
                            <div className='col-sm-3'>
                              <button
                                className='btn btn-outline-primary font-13 fw-bold w-100 py-2 px-2 border-1'
                                data-bs-target='#rescheduleCancel-popup'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                onClick={() => setCancelId(visit?.id)}
                              >
                                {t('Cancel Visit')}
                              </button>
                            </div>
                            <div className='col-sm-3'>
                              <button
                                className='btn btn-primary font-13 fw-bold w-100 py-2 px-2'
                                data-bs-target='#reschedule-popup'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                onClick={() => addScheduleData(visit)}
                              >
                                {t('Reschedule')}
                              </button>
                            </div>
                            <div className='col-sm-3'>
                              <button
                                className='btn btn-primary font-13 fw-bold w-100 py-2 px-2'
                                data-bs-target='#reserved-popup'
                                data-bs-toggle='modal'
                                data-bs-dismiss='modal'
                                // onClick={() => addScheduleData(visit)}
                              >
                                {t('Reserve')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='modal fade custom-modal' data-bs-backdrop="static" data-bs-keyboard="false" id='rescheduleCancel-popup'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content rounded-10'>
            <div className='modal-header border-0 py-4'>
              <h5 className='modal-title fw-bold'> {t('Cancel Visit')}</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal'>
                &times;
              </button>
            </div>
            <div className='modal-body px-10 pb-0'>
              <div className='row mt-6'>
                <div className='col-12'>
                  <div className='mb-6'>
                    <div className='text-center flex-css justify-content-center'>
                      <MdOutlineClose color='red' fontSize='3.5em' />
                    </div>
                    <h3 className='text-dark fw-bolder text-center flex-css justify-content-center mt-4 font-18'>
                      {t('Are you sure?')}
                    </h3>
                  </div>
                  <div className='text-center flex-css justify-content-center'>
                    <p className='font-14 text-black fw-normal'>
                      {' '}
                      {t('Do you really want to cancel this visit?')} <br />{' '}
                      {t('This process cannot be undone.')}{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer border-0 flex-css justify-content-center'>
              <button
                type='button'
                className='btn btn-primary fw-bold px-10 py-3 me-5'
                data-bs-dismiss='modal'
                onClick={() => onCancel(cancelId)}
              >
                {t('Yes')}
              </button>
              <button
                type='button'
                className='btn btn-outline-primary fw-bold px-10 py-3'
                data-bs-dismiss='modal'
              >
                {t('No')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {submitError && (
        <div className='alert alert-danger w-100 text-center mt-5' role='alert'>
          {submitError}
        </div>
      )}
      <p
        ref={buttonEl}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
        className='invisible d-none btn btn-primary fw-bold px-10 py-3'
      />
      <CustomRequestSuccessfulPopup
        successMessage={successMsg}
        close={false}
        href={'/visits/closed'}
      />
      <p
        ref={ref}
        data-bs-target='#dynamic-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      ></p>
      <CustomViewPopup viewImage={viewImage} />
      <p
        ref={ref3}
        data-bs-target='#view-customPopUp'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      ></p>
      <ReschedulePopup scheduleData={scheduleData} />
      {/* <p
        ref={ref3}
        data-bs-target='#reserved-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      ></p> */}
      <ReservedPropertyPopup />
    </>
  )
}
