import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const DashboardFilter:React.FC<any> = ({handlePropertyFilters}) => {
    const { t } = useTranslation();

    return (
        <div className='row'>
            <div className='col-lg-12 text-end my-auto'>
                <select
                    className='form-select-lg form-select-solid filter-select'
                    data-placeholder={t("Filter")}
                    onChange={handlePropertyFilters}
                >
                    <option>{t("Filter")}</option>
                    <option>{t("Available")}</option>
                    <option>{t("Upcoming")}</option>
                    <option>{t("Latest")}</option>
                    <option>{t("Trending")}</option>
                </select>
            </div>
        </div>
    );
}