import React, { useState, useEffect, useRef } from 'react'
import 'nouislider/dist/nouislider.css'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { extendContractRequest, getContractActivity } from '../../../../constants/axios/apis'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import { useDispatch } from 'react-redux'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function ExtendContractForm({ contract, setMessage }: any) {
  const [loading, setLoading] = useState(false)
  const [serviceStatus, setServiceStatus] = useState<Boolean>(false)
  const [renewalAmount, setRenewalAmount] = useState<any>()
  const [renewPeriod, setRenewPeriod] = useState<any>(null)
  const [renewalEndDate, setRenewalEndDate] = useState<any>(null)
  const [messages, setMessages] = useState<any>()
  const history = useHistory()
  const ref = useRef<any>(0)
  const dispatch = useDispatch()
  const { t } = useTranslation();

  useEffect(() => {
    getContractActivity(contract?.id, 1).then((response) => {
      if (response.data.length) {
        setServiceStatus(true)
        setRenewalAmount(0)
        setRenewPeriod(null)
        formik.resetForm()
      } else {
        setServiceStatus(false)
      }
    })
  }, [contract])

  let date: any = new Date(contract?.contractStartDate)
  let startingDate = date.setFullYear(date.getFullYear() + 1)
  const start_date = date.toLocaleString().split(',')[0]
  const closeRef = useRef<any>()

  const initialValues = {
    contract_id: contract?.id,
    mode_payment: '',
    installment: '',
    extend_period: '',
    rent_amount: renewalAmount,
  }

  useEffect(() => {
    if (renewPeriod && serviceStatus === false) {
      let amount = (contract?.rent / 12) * (renewPeriod / 30)
      setRenewalAmount(amount)
      formik.values.rent_amount = amount
    }
  }, [renewPeriod, contract])

  useEffect(() => {
    if (renewPeriod && serviceStatus === false) {
      let new_date = new Date(startingDate)
      let end_date = new Date(new_date.setDate(new_date.getDate() + parseInt(renewPeriod)))
        .toLocaleString()
        .split(',')[0]
      setRenewalEndDate(end_date)
    } else {
      setRenewalEndDate(new Date(contract?.contractEndDate).toLocaleString().split(',')[0])
    }
  }, [renewPeriod])

  const extendContractSchema = Yup.object().shape({
    extend_period: Yup.number().required(t("Please choose a renewal period")),
    mode_payment: Yup.string().required(t("Please choose a payment method")),
    installment: Yup.number().required(t("Please choose number of installments")),
  })

  const formik = useFormik({
    initialValues,
    isInitialValid: false,
    validationSchema: extendContractSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        extendContractRequest(
          values.contract_id,
          values.mode_payment,
          values.installment,
          values.extend_period,
          values.rent_amount
        )
          .then((response: any) => {
            setLoading(false)
            if (response != 500) {
              setMessage(t("Your Extend Contract Request has been Submitted!"))
              resetForm()
              closeRef.current.click()
              history.push('/my-services/openissues')
            } else {
              setMessages({
                error: 1,
                message: 'Something went wrong!',
              })
              ref.current.click()
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const handleChange = (e: any) => {
    setRenewPeriod(e.target.value)
    formik.handleChange(e)
  }

  useEffect(() => {
    if (messages?.message?.length > 0) {
      dispatch(PTOffer.actions.modalmessage(messages))
    }
  }, [messages])
  let userEmail = ''
  let userRole: number = 0
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
    userRole = JSON.parse(userDetail).id_role
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='accordion accordion-flush' id='accordionFlushExample'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header' id='flush-headingOne'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapseOne'
                    aria-expanded='false'
                    aria-controls='flush-collapseOne'
                  >
                    {t("Contract Details")}
                  </button>
                </h2>
                <div
                  id='flush-collapseOne'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingOne'
                  data-bs-parent='#accordionFlushExample'
                >
                  <div className='accordion-body py-5'>
                    {serviceStatus ? (
                      <p className='text-success'>
                        {t("A contract extension request is already in progress.")}
                      </p>
                    ) : (
                      ''
                    )}
                    <div className='row form-add-design'>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Customer Name")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={contract?.customerName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder d-block mb-3'>
                            {t("Renewal Period")}
                          </label>
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='extend_period'
                              id='inlineRadio1'
                              value='30'
                              autoComplete='off'
                              onChange={(e) => handleChange(e)}
                              onBlur={formik.handleBlur}
                              style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                              disabled={serviceStatus ? true : false}
                            />
                            <label
                              className='form-check-label text-black fw-bolder font-14'
                              htmlFor='inlineRadio1'
                            >
                              {t("30 Days")}
                            </label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='extend_period'
                              id='inlineRadio2'
                              value='90'
                              autoComplete='off'
                              onChange={(e) => handleChange(e)}
                              onBlur={formik.handleBlur}
                              style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                              disabled={serviceStatus ? true : false}
                            />
                            <label
                              className='form-check-label text-black fw-bolder font-14'
                              htmlFor='inlineRadio2'
                            >
                              {t("90 Days")}
                            </label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='extend_period'
                              id='inlineRadio3'
                              value='120'
                              autoComplete='off'
                              onChange={(e) => handleChange(e)}
                              onBlur={formik.handleBlur}
                              style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                              disabled={serviceStatus ? true : false}
                            />
                            <label
                              className='form-check-label text-black fw-bolder font-14'
                              htmlFor='inlineRadio3'
                            >
                              {t("120 Days")}
                            </label>
                          </div>
                          {formik.touched.extend_period && formik.errors.extend_period && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors.extend_period}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Renewal Start Date")}</label>
                          <input
                            className='form-control fw-bolder form-control-solid'
                            type='text'
                            value={moment(start_date).format('DD-MM-YYYY')}
                            disabled
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>{t("Renewal End Date")}</label>
                          <input
                            className='form-control fw-bolder form-control-solid'
                            type='text'
                            value={moment(renewalEndDate).format('DD-MM-YYYY')}
                            disabled
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>{t("Renewal Amount")}</label>
                          <input
                            className='form-control fw-bolder form-control-solid'
                            type='tel'
                            value={renewalAmount != null ? renewalAmount?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' AED' : ''}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='accordion-item offers_details_accordian_div1 payment-plan-details'>
                <h2 className='accordion-header' id='flush-headingTwo'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapseTwo'
                    aria-expanded='false'
                    aria-controls='flush-collapseTwo'
                    style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                    disabled={serviceStatus ? true : false}
                  >
                    {t("Method of Payments")}
                  </button>
                </h2>
                <div
                  id='flush-collapseTwo'
                  className='accordion-collapse collapse'
                  aria-labelledby='flush-headingTwo'
                  data-bs-parent='#accordionFlushExample'
                >
                  <div className='accordion-body py-5'>
                    <div className='row justify-content-center'>
                      {userEmail === 'commercialtenant@demo.com' ? (
                        <div className='col-md-12 col-12 col-lg-12'>
                          <div className='make-an-offer-wdw'>
                            <div className='row form-add-design'>
                              <div className='col-4'>
                                <div className='mb-10 position-relative'>
                                  <h4 className='form-label fw-bolder font-16 mb-6'>
                                    {t("Method of Payments")}
                                  </h4>
                                  <select
                                    className='form-control form-select'
                                    data-control='select2'
                                    data-allow-clear='true'
                                  >
                                    <option selected disabled>
                                      {t("Please Select")}
                                    </option>
                                    <option>{t("Cash")}</option>
                                    <option>{t("Cheque")}</option>
                                    <option>{t("Payment By Employer")}</option>
                                  </select>
                                </div>
                              </div>
                              <div className='col-8'>
                                <div className='mb-10 ps-10 position-relative'>
                                  <h4 className='form-label fw-bolder d-block font-16 mb-9'>
                                    {t("Choose the number of payments")}
                                  </h4>
                                  <div className='form-check form-check-inline col-2'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='period'
                                      id='inlineRadio1'
                                    />
                                    <label
                                      className='form-check-label text-black fw-bolder font-14'
                                      htmlFor='inlineRadio1'
                                    >
                                      {t("1 Payment")}
                                    </label>
                                  </div>
                                  <div className='form-check form-check-inline col-3'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='period'
                                      id='inlineRadio2'
                                    />
                                    <label
                                      className='form-check-label text-black fw-bolder font-14'
                                      htmlFor='inlineRadio2'
                                    >
                                      {t("3 Payment")}
                                    </label>
                                  </div>
                                  <div className='form-check form-check-inline col-3'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='period'
                                      id='inlineRadio3'
                                    />
                                    <label
                                      className='form-check-label text-black fw-bolder font-14'
                                      htmlFor='inlineRadio3'
                                    >
                                      {t("2 Payment")}
                                    </label>
                                  </div>
                                  <div className='form-check form-check-inline col-3'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='period'
                                      id='inlineRadio4'
                                    />
                                    <label
                                      className='form-check-label text-black fw-bolder font-14'
                                      htmlFor='inlineRadio4'
                                    >
                                      {t("4 Payment")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className='col-12 text-end'>
                                <button
                                  data-bs-target='#req-submitted-popup'
                                  data-bs-toggle='modal'
                                  type='submit'
                                  className='btn btn-primary fw-bold px-10 py-3'
                                >
                                  {t("Submit")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : userRole == 7 ? (
                        <div className='col-md-12 col-12 col-lg-8'>
                          <div className='make-an-offer-wdw'>
                            <div className='row'>
                              <div className='col-12 mb-12'>
                                <div className='btn-group payment-group w-100'>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='mode_payment'
                                    id='pay-one'
                                    value='CASH'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                                    disabled={serviceStatus ? true : false}
                                  />
                                  <label className='btn btn-outline-primary me-md-8 me-3' htmlFor='pay-one'>
                                    <span className='mb-3 cash-ioc'></span>
                                    {t("Cash")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='mode_payment'
                                    id='pay-two'
                                    value='CHEQUE'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                                    disabled={serviceStatus ? true : false}
                                  />
                                  <label className='btn btn-outline-primary me-md-8 me-3' htmlFor='pay-two'>
                                    <span className='mb-3 cheque-ioc'></span>
                                    {t("Cheque")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='mode_payment'
                                    id='pay-three'
                                    value='PAYMENT_BY_EMPLOYER'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                                    disabled={serviceStatus ? true : false}
                                  />
                                  <label className='btn btn-outline-primary' htmlFor='pay-three'>
                                    <span className='mb-3 emp-pay-ioc'></span>
                                    {t("Payment By Employer")}
                                  </label>
                                </div>
                                {formik.touched.mode_payment && formik.errors.mode_payment && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert' className='text-danger'>
                                        {formik.errors.mode_payment}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className='col-12 mb-7'>
                                <label className='form-label font-14 fw-bolder text-black d-block text-center mb-5'>
                                  {t("Choose the number of installments")}
                                </label>
                                {formik.touched.installment && formik.errors.installment && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      <span role='alert' className='text-danger'>
                                        {formik.errors.installment}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div className='btn-group num-pay-group w-100 mb-10'>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='installment'
                                    id='no-pay-one'
                                    value='1'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                                    disabled={serviceStatus ? true : false}
                                  />
                                  <label className='btn btn-outline-primary' htmlFor='no-pay-one'>
                                    {t("1 Installment")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='installment'
                                    id='no-pay-two'
                                    value='2'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                                    disabled={serviceStatus ? true : false}
                                  />
                                  <label className='btn btn-outline-primary' htmlFor='no-pay-two'>
                                    {t("2 Installments")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='installment'
                                    id='no-pay-three'
                                    value='3'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                                    disabled={serviceStatus ? true : false}
                                  />
                                  <label className='btn btn-outline-primary' htmlFor='no-pay-three'>
                                    {t("3 Installments")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='installment'
                                    id='no-pay-four'
                                    value='4'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    style={{ cursor: serviceStatus ? 'not-allowed' : '' }}
                                    disabled={serviceStatus ? true : false}
                                  />
                                  <label className='btn btn-outline-primary' htmlFor='no-pay-four'>
                                    {t("4 Installments")}
                                  </label>
                                </div>

                                <div className='col-12'>
                                  {serviceStatus ? (
                                    <div
                                      className='btn btn-secondary fw-bold px-10 py-3'
                                      style={{ cursor: 'not-allowed' }}
                                      title={'Early Termination Letter was already submitted!'}
                                    >
                                      {t("Submit")}
                                    </div>
                                  ) : (
                                    <button
                                      type='submit'
                                      className='btn btn-primary w-100 fw-bold px-10 py-3'
                                      disabled={loading}
                                    >
                                      {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                                      {loading && (
                                        <span
                                          className='indicator-progress'
                                          style={{ display: 'block' }}
                                        >
                                          {t("Submitting...")}{' '}
                                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                      )}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='col-md-12 col-12 col-lg-8'>
                          <div className='make-an-offer-wdw'>
                            <div className='row'>
                              <div className='col-12 mb-12'>
                                <div className='btn-group payment-group w-100'>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='mode_payment'
                                    id='pay-one'
                                    value='CASH'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <label className='btn btn-outline-primary me-md-8 me-3' htmlFor='pay-one'>
                                    <span className='mb-3 cash-ioc'></span>
                                    {t("Cash")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='mode_payment'
                                    id='pay-two'
                                    value='CHEQUE'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <label className='btn btn-outline-primary me-md-8 me-3' htmlFor='pay-two'>
                                    <span className='mb-3 cheque-ioc'></span>
                                    {t("Cheque")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='mode_payment'
                                    id='pay-three'
                                    value='PAYMENT_BY_EMPLOYER'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <label
                                    className='btn btn-outline-primary me-8'
                                    htmlFor='pay-three'
                                  >
                                    <span className='mb-3 emp-pay-ioc'></span>
                                    {t("Payment By Employer")}
                                  </label>
                                </div>
                              </div>
                              <div className='col-12 mb-7'>
                                <label className='form-label font-14 fw-bolder text-black d-block text-center mb-5'>
                                  {t("Choose the number of installments")}
                                </label>
                                <div className='btn-group num-pay-group w-100 mb-10'>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='installment'
                                    id='no-pay-one'
                                    value='1'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <label className='btn btn-outline-primary' htmlFor='no-pay-one'>
                                    {t("1 Installment")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='installment'
                                    id='no-pay-two'
                                    value='2'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <label className='btn btn-outline-primary' htmlFor='no-pay-two'>
                                    {t("2 Installments")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='installment'
                                    id='no-pay-three'
                                    value='3'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <label className='btn btn-outline-primary' htmlFor='no-pay-three'>
                                    {t("3 Installments")}
                                  </label>
                                  <input
                                    className='btn-check'
                                    type='radio'
                                    name='installment'
                                    id='no-pay-four'
                                    value='4'
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  <label className='btn btn-outline-primary' htmlFor='no-pay-four'>
                                    {t("4 Installments")}
                                  </label>
                                </div>

                                <div className='col-12'>
                                  <button
                                    // data-bs-target='#req-submitted-popup'
                                    // data-bs-toggle={!formik.isValid ? '' : 'modal'}
                                    type='submit'
                                    className='btn btn-primary w-100 fw-bold px-10 py-3'
                                  >
                                    {t("Submit")}
                                  </button>
                                </div>
                              </div>
                              {/* <div className='col-12 text-center mb-5'>
                                <h2 className='text-black fw-boldest font-34 mb-5'>
                                  {offerAmount} AED
                                  <span className='fw-bold font-20'>/ payment</span>
                                </h2>
                                <h6 className='font-13 fw-normal'>
                                  You pay{' '}
                                  <span className='text-black font-14 fw-boldest'>
                                    {' '}
                                    {contract?.rent} AED{' '}
                                  </span>{' '}
                                  per year
                                </h6>
                              </div>

                              <div className='col-12 text-center mb-5'>
                                <div className='bg-white shadow-sm py-2 px-5 rounded-5 d-inline'>
                                  <span
                                    className='text-black fw-boldest font-12 c-pointer'
                                    data-bs-container='body'
                                    data-bs-toggle='popover'
                                    data-bs-placement='right'
                                    data-bs-content='Right popover'
                                  >
                                    How renting works
                                    <img src={WorksIcon} className='img-fluid ms-2' />
                                  </span>
                                </div>
                              </div>
                              <div className='col-12 mb-1 text-center'>
                                <p className='text-black font-13 fw-bold'>
                                  If you don't think a price is fair, make your own offer for the
                                  landlord to consider.
                                </p>
                              </div>
                              <div className='col-12 mb-5'>
                                <div className='bg-white pt-5 pb-8 px-10 shadow-sm rounded-5'>
                                  <div className=''>
                                    <label
                                      htmlFor='customRange1'
                                      className='form-label mb-5 text-center text-black fw-bolder font-18 d-block'
                                    >
                                      Make an Offer
                                    </label>
                                    {offerAmount && (
                                      <Nouislider
                                        start={offerAmount}
                                        range={{
                                          min: 10000,
                                          // max: {offerAmount},
                                          max: 69000,
                                        }}
                                        step={1000}
                                        onSlide={(data) => setOfferAmount(data[0])}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='col-12'>
                                <button
                                  data-bs-target='#req-submitted-popup'
                                  data-bs-toggle='modal'
                                  type='submit'
                                  className='btn btn-primary w-100 fw-bold px-10 py-3'
                                >
                                  Make On Offer
                                </button> 
                              </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p><p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
        {/*  */}
      </p>
    </div>
  )
}
