import React, { useState, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import {SuccessfulPopup} from "../SuccessfulPopup/SuccessfulPopup";
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function SignatureModal() {
    const sigCanvas = useRef<any>();
    function clear() {
        sigCanvas.current.clear();
    }
    const { t } = useTranslation();

    return (
        <>
        <div className="modal fade redeem-offer signature-modal" id="signature-modal-preview">
            <div className="modal-dialog modal-xs modal-dialog-centered">
                <div className="modal-content rounded-10">
                    <div className="modal-header rounded-10 border-0">
                        <h5 className="modal-title font-18 fw-bold">{t("Add your Signature")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-8">
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor='black'
                            canvasProps={{height: 180, width: 480, className: 'sigCanvas'}}
                        />
                    </div>
                    <div className="modal-footer p-0 m-0">
                        <div className="row w-100 g-0 m-0">
                            <div className="col-12">
                                <button className="btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0" onClick={clear}>{t("Reset")}</button>
                            </div>
                            <div className="col-12">
                                <Link to={''} className='btn btn-primary fw-bold px-10 py-3 w-100' data-bs-target='#success-modal-new'
                                      data-bs-toggle='modal'
                                      data-bs-dismiss='modal'>
                                    {t("Submit")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SuccessfulPopup />
        </>
    );
}