import React, { useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import LeftArrow from "../../../../assets/images/Left_Arrow.png";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { createTeamMember } from '../../../../constants/axios/apis';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { RequestFailedPopup } from '../../../SuccessfulPopup/RequestFailedPopup';

export function AddTeam() {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<any>('')
    const history = useHistory()
    const successRef = useRef<any>()
    const failRef = useRef<any>()
    const { t } = useTranslation();

    const teamMemberSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("First Name is required"))
            .test('filled', t("First name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("First name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        last_name: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Last name is required"))
            .test('filled', t("Last name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Last name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        emirates_id: Yup.string()
            .required(t("Emirates ID is required"))
            .matches(/^[a-zA-Z0-9]*$/, t("Only alphabets & numbers allowed")),
        email: Yup.string()
            .email(t("Wrong email format"))
            .min(3, t("Minimum 3 symbols"))
            .max(50, t("Maximum 50 symbols"))
            .required(t("Email is required")),
        mobile: Yup.string()
            .min(4, t("Mobile No. should be greater then 4 digits"))
            .max(15, t("Mobile No. should be smaller then 15 digits"))
            .matches(/^\+?[0-9]+$/, t("Invalid format, only + and numbers allowed"))
            .matches(/^[^\s].*/, t("Mobile No. cannot start with a blankspace"))
            .required(t("Mobile No. is required")),
        department: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Department name is required"))
            .test('filled', t("Department name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Department name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        designation: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Designation name is required"))
            .test('filled', t("Designation name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Designation name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed"))
    })

    const initialValues = {
        first_name: '',
        last_name: '',
        emirates_id: '',
        mobile: '',
        email: '',
        // department: '',
        designation: '',
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: teamMemberSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setLoading(true)
            setTimeout(() => {
                var FormData = require('form-data')
                var data = new FormData()
                data.append('first_name', values.first_name)
                data.append('last_name', values.last_name)
                data.append('email', values.email)
                data.append('emirates_id', values.emirates_id)
                data.append('phone', values.mobile)
                // data.append('department', values.department)
                data.append('designation', values.designation)
                data.append('id_role', 'CTU')

                createTeamMember(data)
                    .then((response: any) => {
                        setLoading(false)
                        if (response === 500) {
                            setMessage('Server error!')
                            failRef.current.click()
                        } else if (response.errorCode === 1) {
                            // setMessage(response.errorDescription)
                            // failRef.current.click()
                        } else {
                            successRef.current.click()
                            resetForm()
                            history.push('/ct-my-team/day')
                        }
                    })
                    .catch((e) => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus(t("Invalid Data Provided"))
                    })
            }, 1000)
        },
    })

    const handlePhoneNumber = (phoneNumber: any) => {
        formik.setFieldValue('mobile', phoneNumber)
        formik.setFieldTouched('mobile')
    }

    return (
        <div className="com-card-section">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="card-title font-18 text-black mb-6">
                        <Link to={'/ct-my-team/day'} className=''>
                            <img src={LeftArrow} className="img-fluid me-5 left-arrow-back" />
                            <span className="text-black">
                                {t("Add Member")}
                            </span>
                        </Link>
                    </h4>
                </div>
            </div>
            <div className="row">
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body px-8 py-6">
                                <div className="row form-add-design">
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("First Name")} <span className="text-danger"> * </span></label>
                                            <input
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder=""
                                                {...formik.getFieldProps('first_name')}
                                                autoComplete="off"
                                            />
                                            {formik.touched.first_name && formik.errors.first_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.first_name}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Last Name")} <span className="text-danger"> * </span></label>
                                            <input
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder=""
                                                {...formik.getFieldProps('last_name')}
                                                autoComplete="off"
                                            />
                                            {formik.touched.last_name && formik.errors.last_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.last_name}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Emirates ID")} <span className="text-danger"> * </span></label>
                                            <input
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder=""
                                                {...formik.getFieldProps('emirates_id')}
                                                autoComplete="off"
                                            />
                                            {formik.touched.emirates_id && formik.errors.emirates_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.emirates_id}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Mobile Number")} <span className="text-danger"> * </span></label>
                                            <div className='d-block'>
                                                <PhoneInput
                                                    country={'ae'}
                                                    preferredCountries={['ae']}
                                                    priority={{ ae: 0, in: 1, us: 2 }}
                                                    value={formik.values.mobile}
                                                    onChange={handlePhoneNumber}
                                                    enableSearch={true}
                                                    countryCodeEditable={false}
                                                    inputClass={'text-black'}
                                                    placeholder={t("Mobile Number")}
                                                />
                                                {formik.touched.mobile && formik.errors.mobile && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>
                                                                {formik.errors.mobile}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="mb-10">
                                            <label className="form-label fw-bolder">{t("Email ID")} <span className="text-danger"> * </span></label>
                                            <input
                                                type="email"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder=""
                                                {...formik.getFieldProps('email')}
                                                autoComplete="off"
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.email}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4 col-12">
                                        <div className="mb-2">
                                            <label className="form-label fw-bolder">{t("Department")} <span className="text-danger"> * </span></label>
                                            <input
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder=""
                                                {...formik.getFieldProps('department')}
                                            />
                                            {formik.touched.department && formik.errors.department && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.department}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className="col-md-4 col-12">
                                        <div className="mb-2">
                                            <label className="form-label fw-bolder">{t("Designation")} <span className="text-danger"> * </span></label>
                                            <input
                                                type="text"
                                                className="form-control fw-bolder form-control-solid"
                                                placeholder=""
                                                {...formik.getFieldProps('designation')}
                                            />
                                            {formik.touched.designation && formik.errors.designation && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.designation}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-end mt-5">
                        <Link to={'/ct-my-team/day'} className='btn btn-outline-primary fw-bold px-10 py-3 me-5 bg-white'>
                            {t("Cancel")}
                        </Link>
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading}>
                            {!loading && <span className='indicator-label'>{t("Add")}</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {t("Please wait...")}{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
            <p
                ref={successRef}
                data-bs-target='#req-submitted-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
            >
                {/*  */}
            </p>

            <p
                ref={failRef}
                data-bs-target='#req-failed-popup'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
            >
                {/*  */}
            </p>

            <RequestFailedPopup message={message} />
        </div>
    );
}