import React, { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import completedStep from '../../../../../../assets/images/completed-step.png'
import SignatureCanvas from 'react-signature-canvas';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsPopup } from '../../../../../terms-and-conditions/TermsAndConditionsPopup'

type Props = {
    setSignatureData?: any
    stepStatus?: any
    ref4?: any
}

const Signature: FC<Props> = ({ setSignatureData, stepStatus, ref4 }) => {
    const sigCanvas = useRef<any>();
    const [saved, setSaved] = useState<any>(false)
    const [dirty, setIsDirty] = useState<any>(false)
    const { t } = useTranslation();

    const initialValues = {
        permit_applicant: '',
        terms: false,
        signature: '',
    }

    const handelSubmit = () => {
        setSaved(false)
        setSignatureData(null)
        formik.handleSubmit()
    }

    const workInformationSchema = Yup.object().shape({
        permit_applicant: Yup.string()
            .min(3, t("Minimum 3 characters"))
            .max(30, t("Maximum 30 characters"))
            .required(t("Permit Applicant name is required"))
            .test('filled', t("Permit Applicant name cannot contain only blankspaces"), (value) => {
                if (value) {
                    return (
                        value.trim().length !== 0
                    )
                }
                return true
            })
            .matches(/^[^\s].*/, t("Permit Applicant name cannot start with a blankspace"))
            .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
        terms: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
        signature: Yup.string().required(t("Please add your signature")),
    })

    const formik = useFormik({
        initialValues,
        isInitialValid: false,
        validationSchema: workInformationSchema,
        onSubmit: (values) => {
            setSignatureData({
                permit_applicant: values.permit_applicant,
                terms: values.terms,
                signature: values.signature,
                saved: true
            })
            setSaved(true)
            setIsDirty(false)
            ref4.current.click()
        },
    })

    useEffect(() => {
        setIsDirty(true)
    }, [formik.values])

    useEffect(() => {
        if (dirty) {
            setSaved(false)
            setSignatureData((currentState: any) => { return { ...currentState, saved: false } })
        }
    }, [dirty])

    const handleSignature = () => {
        const canvas = sigCanvas.current.getCanvas()
        canvas.toBlob((blob: string) => {
            formik.setFieldValue('signature', blob)
        }, 'image/png')
    }

    function clearSignature() {
        sigCanvas.current.clear()
        formik.setFieldValue('signature', '')
    }

    return (
        <>
            <div className="row">
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className="col-12">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingEleven">
                                <button className="accordion-button collapsed" ref={ref4} type="button" data-bs-toggle="collapse" data-bs-target="#signature-box">
                                    {t("Signature")}
                                    {(saved) &&
                                        <span className='completed-step ps-3 pe-3 d-inline-block'>
                                            <img src={completedStep} alt='complete-step' />
                                        </span>}
                                </button>
                            </h2>
                            <div id="signature-box" className="accordion-collapse collapse" data-bs-parent="#accordion-step-five">
                                {stepStatus?.helipadAccessRequestStatus ? (
                                    <>
                                        <div className="accordion-body pb-5 pt-0">
                                            <div className="row form-add-design">
                                                <div className="col-12">
                                                    <h5 className="text-black fw-bolder font-14 mb-5">
                                                        {t("Permit Applicant")}
                                                    </h5>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="mb-7">
                                                        <label className="form-label fw-bolder"> {t("Name")} </label>
                                                        <input
                                                            className='form-control bg-white fw-bolder form-control-solid'
                                                            value={stepStatus?.data?.helipadAccess?.details?.hra_permit_applicant_name}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="accordion-body pb-5 pt-0">
                                            <div className="row form-add-design">
                                                <div className="col-12">
                                                    <h5 className="text-black fw-bolder font-14 mb-5">
                                                        {t("Permit Applicant")}
                                                    </h5>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="mb-7">
                                                        <label className="form-label fw-bolder"> {t("Name")} </label>
                                                        <input
                                                            className='form-control bg-white fw-bolder form-control-solid'
                                                            {...formik.getFieldProps('permit_applicant')}
                                                            autoComplete="off"
                                                        />
                                                        {formik.touched.permit_applicant && formik.errors.permit_applicant && (
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'>
                                                                    <span role='alert' className='text-danger'>
                                                                        {formik.errors.permit_applicant}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 text-right">
                                                    <div className="row justify-content-end mb-10">
                                                        <div className="col-12">
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <div className="form-check custom mx-5">
                                                                    <input
                                                                        className='form-check-input'
                                                                        {...formik.getFieldProps('terms')}
                                                                        checked={formik.values.terms}
                                                                        type='checkbox'
                                                                        id='signature-check'
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <label className="form-check-label font-14 text-black fw-bolder" htmlFor="signature-check">
                                                                        {t("Accept")} 	&nbsp;
                                                                        {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
                                                                            {t("Terms & Conditions")}
                                                                        </Link> */}
                                                                        <button
                                                                            data-bs-target="#terms-conditions-popup"
                                                                            data-bs-toggle='modal'
                                                                            className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                                                                            {t("Terms & Conditions")}
                                                                        </button>
                                                                        <span className='text-danger'>*</span>
                                                                    </label>
                                                                    {formik.touched.terms && formik.errors.terms && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block'>
                                                                                <span role='alert' className='text-danger'>
                                                                                    {formik.errors.terms}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <button type='button' data-bs-toggle="modal" data-bs-target="#signature-modal" className='btn btn-primary fw-bold px-10 py-3'>
                                                                        {t("Signature")}
                                                                    </button>
                                                                    {formik.touched.signature && formik.errors.signature && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block'>
                                                                                <span role='alert' className='text-danger'>
                                                                                    {formik.errors.signature}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal fade redeem-offer signature-modal" id="signature-modal">
                                                    <div className="modal-dialog modal-xs modal-dialog-centered">
                                                        <div className="modal-content rounded-10">
                                                            <div className="modal-header rounded-10 border-0">
                                                                <h5 className="modal-title font-18 fw-bold">{t("Add your Signature")}</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body p-8">
                                                                <SignatureCanvas
                                                                    ref={sigCanvas}
                                                                    penColor='black'
                                                                    canvasProps={{ height: 180, width: 480, className: 'sigCanvas' }}
                                                                />
                                                            </div>
                                                            <div className="modal-footer p-0 m-0">
                                                                <div className="row w-100 g-0 m-0">
                                                                    <div className="col-12">
                                                                        <button type="button" className="btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0" onClick={clearSignature}>{t("Reset")}</button>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <button onClick={handleSignature} type="button" className="btn btn-primary fw-bold px-10 py-5 w-100 d-block" data-bs-toggle='modal'
                                                                            data-bs-target='#ParkingSuccessModal' data-bs-dismiss="modal">{t("Submit")}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 text-right">
                                                <div className="row justify-content-end">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-end">
                                                            <button type='button' onClick={handelSubmit} disabled={!dirty} className='btn btn-primary fw-bold px-10 py-3'>
                                                                {saved ? t("Saved") : t("Save")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <TermsAndConditionsPopup />
        </>
    )
};

export { Signature }
