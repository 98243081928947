import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getPropertyFilterData } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function ResolvedIssues({ issues }: any) {
  const [issueData, setIssueData] = useState<any>([])
  const [amenityTypes, setAmenityTypes] = useState<any>([])
  const { t } = useTranslation();

  useEffect(() => {
    getPropertyFilterData().then(({ data }) => {
      setAmenityTypes(data.amenity)
    })
  }, [])

  const getAmenityType = (amenity_type_id: any) => {
    if (amenityTypes.length) {
      return amenityTypes?.find((item: any) => item.value == amenity_type_id)?.name
    }
    return '--'
  }

  const columns = [
    {
      field: 'ticket',
      headerName: t("Request No."),
      width: 150,
      renderCell: (params: any) => {
        return (
          <Link to={{ pathname: '/my-services/preview', state: params.row }}>
            <span className='view-option text-theme fw-bolder'>{params.row.ticket}</span>
          </Link>
        )
      },
    },
    {
      field: 'issue',
      headerName: t("Issue/Request"),
      width: 250,
      renderCell: (params: any) => {
        return <span title={params.row.category_id !== 20
          ? params.row.issue
          : getAmenityType(params.row?.description?.amenity_type_id)}
        >
          {params.row.category_id !== 20
            ? params.row.issue
            : getAmenityType(params.row?.description?.amenity_type_id).length > 25
              ? getAmenityType(params.row?.description?.amenity_type_id).slice(0, 25).split(' ').slice(0, -1).join(' ') +
              '...'
              : getAmenityType(params.row?.description?.amenity_type_id)}
        </span>
      },
    },
    {
      field: 'category_id',
      headerName: t("Issue/Request Type"),
      width: 250,
      renderCell: (params: any) => {
        return params.row.category_id !== 20 ? t("Service Request") : t("Amenity Booking")
      },
    },
    // {
    //   field: 'description',
    //   headerName: 'Description',
    //   width: 200,
    //   renderCell: (params: any) => {
    //     return params.row?.description?.description
    //       ? params.row?.description?.description.length > 25
    //         ? params.row?.description?.description.slice(0, 25).split(' ').slice(0, -1).join(' ') +
    //         '...'
    //         : params.row?.description?.description
    //       : params.row?.description?.comments
    //         ? params.row?.description?.comments.length > 25
    //           ? params.row?.description?.comments.slice(0, 25).split(' ').slice(0, -1).join(' ') +
    //           '...'
    //           : params.row?.description?.comments
    //         : '--'
    //   },
    // },
    {
      field: 'created',
      headerName: t("Date"),
      width: 150,
      renderCell: (params: any) => {
        return moment(params.row.created).format('DD-MM-YYYY')
      },
    },
    {
      field: 'status',
      headerName: t("Status"),
      className: 'new-theme',
      disableColumnMenu: true,
      width: 130,
      renderCell: () => {
        return t("Closed")
      },
    },
    {
      field: 'action',
      headerName: t("Action"),
      sortable: false,
      disableColumnMenu: true,
      width: 130,
      renderCell: (params: any) => {
        return (
          <Link to={{ pathname: '/my-services/preview', state: params.row }}>
            <span className='view-option text-theme fw-bolder'>{t("View")}</span>
          </Link>
        )
      },
    },
  ]

  useEffect(() => {
    if (issues) {
      setIssueData(
        issues?.map((issue: any) => {
          return { ...issue, status: 'Resolved' }
        })
      )
    }
  }, [issues])

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-md-12 col-12'>
                  <div className='data_table'>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='table-responsive'>
                          <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                              rows={issueData}
                              columns={columns}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
