import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ApprovalComments } from './ApprovalComments'
import { ApprovalReply } from './ApprovalReply'
import { getApprovalCommentList } from '../../../../../constants/axios/apis'

export function CommentWrapper({ approvalID, approvalStatus }: any) {
    const { t } = useTranslation()

    const [reload, setReload] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [commentList, setCommentList] = useState<any>([]);

    useEffect(() => {
        if (reload && approvalID) {
            setLoading(true)
            setError('')
            getApprovalCommentList('?approval_id=' + approvalID)
                .then((response: any) => {
                    if (response.errorCode === 0) {
                        setCommentList(response?.data)
                    } else if (response.errorCode === 1) {
                        setCommentList([])
                        if (response.errorDescription !== 'No data found.')
                            setError(response.errorDescription)
                    } else {
                        setCommentList([])
                        setError(t("Some error occured!"))
                    }
                    setLoading(false)
                }).catch((e: any) => {
                    setLoading(false)
                    setCommentList([])
                    setError(t("Some error occured"))
                })
            setReload(false)
        }
    }, [approvalID, reload]);


    if (error !== '' || commentList.length > 0)
        return (
            <div className="row mt-3">
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body px-8 py-6'>
                            <ApprovalComments commentList={commentList} loading={loading} error={error} />
                            {approvalStatus === 'pending' && commentList.length > 0 ?
                                <ApprovalReply approval_ID={approvalID} setReload={setReload} /> : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    else return <></>
}
