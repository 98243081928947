import React, { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getPaymentHistoryByParams, refundRequest } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import { currencyFormater } from '../../../../constants/generics/customActios'

type Props = {
  contract?: any
  stepStatus?: any
  getStepStatus?: any
  setMessage?: any
  totalClearanceCharges?: any
  totalOtherCharges?: any
  vatPercent?: any
}

const Step5: FC<Props> = ({ contract, stepStatus, getStepStatus, setMessage, totalClearanceCharges, totalOtherCharges, vatPercent }: any) => {
  const [loading, setLoading] = useState(false)
  const closeRef = useRef<any>()
  const [clearanceStatus, setClearanceStatus] = useState<boolean>(false)
  const { t } = useTranslation();

  const initialValues = {
    category_id: 19,
    property_id: contract?.propertyID,
    refund_to: 1,
    name: contract?.customerName,
    bank: '',
    account_number: '',
    iban_number: '',
    confirm_iban_number: '',
    swift_code: '',
  }

  const moveOutRequestSchema = Yup.object().shape({
    name: Yup.string()
      .when('refund_to', {
        is: '2',
        then: Yup.string().required(t("Customer Name is required"))
          .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed"))
      }),
    bank: Yup.string()
      .required(t("Bank Name is required"))
      .matches(/^[A-Za-z ]+$/, t("Only alphabets allowed")),
    account_number: Yup.string()
      .required(t("Account Number is required"))
      .matches(/^[0-9]*$/, t("Only numbers allowed")),
    iban_number: Yup.string()
      .required(t("IBAN Number is required"))
      .matches(/^[a-zA-Z0-9]*$/, t("Only alphabets & numbers allowed")),
    confirm_iban_number: Yup.string()
      .required(t("IBAN Number confirmation is required"))
      .matches(/^[a-zA-Z0-9]*$/, t("Only alphabets & numbers allowed"))
      .when('iban_number', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('iban_number')], t("IBAN and Confirm IBAN did not match")),
      }),
    swift_code: Yup.string()
      .required(t("Swift Code is required"))
      .matches(/^[a-zA-Z0-9]*$/, t("Only alphabets & numbers allowed")),
    refund_to: Yup.string().required(t("Please select refund type")),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: moveOutRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        refundRequest(
          values.category_id,
          values.property_id,
          values.refund_to,
          values.name,
          values.bank,
          values.account_number,
          values.iban_number,
          values.confirm_iban_number,
          values.swift_code
        )
          .then((response: any) => {
            setMessage(t("Your Refund Request has been Submitted!"))
            closeRef.current.click()
            resetForm()
            setLoading(false)
            getStepStatus()
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const onRefundTypeChange = (e: any) => {
    if (e.target.value == 1) {
      formik.setFieldValue('name', contract?.customerName)
    } else {
      formik.setFieldValue('name', '')
    }

    formik.handleChange(e)
  }

  const checkClearanceChargesPaymentStatus = () => {
    getPaymentHistoryByParams(contract?.propertyID, '10', '7', '1').then(response => {
      if (response.data) {
        setClearanceStatus(true)
      } else {
        setClearanceStatus(false)
      }
    })
  }

  useEffect(() => {
    if (contract) {
      checkClearanceChargesPaymentStatus()
    }
  }, [contract])

  const baseAmount = totalClearanceCharges + totalOtherCharges
  const vatAmount = (vatPercent * baseAmount) / 100
  const totalAmount = vatAmount + baseAmount
  const refundAmount = contract?.SD - (totalAmount)

  return (
    <div className='com-card-section w-100 p-7'>
      <div className='row'>
        <div className='col-md-12'>
          {!stepStatus?.status ? (
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='row form-add-design'>
                <div className='col-xl-4 col-12 d-md-none d-block mb-6'>
                  <label className='form-label fw-bolder text-black d-block mb-5 font-17'>
                    {t("Refund Amount Details")}
                  </label>
                  <div className='request-refund-bg p-5 rounded'>
                    <div className='row mb-7'>
                      <div className='col-7'>
                        <h6 className='text-black fw-bold font-14'> {t("Available Security Deposit")} </h6>
                      </div>
                      <div className='col-5 text-end'>
                        <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <div className='col-7'>
                        <h6 className='text-black fw-bold font-14'> {t("Total Charges")} </h6>
                      </div>
                      <div className='col-5 text-end'>
                        <span className={`text-black fw-bold font-14 ${clearanceStatus ? 'text-decoration-line-through' : ''}`}>
                          {t("AED")} {(totalAmount)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </span>
                      </div>
                    </div>
                    <div className='row refund-block py-5 ps-5 rounded'>
                      <div className='col-7'>
                        <h6 className='text-black fw-bold font-14'> {t("Refund")} </h6>
                      </div>
                      <div className='col-5 text-end'>
                        <span className='text-black fw-bold font-14'>
                          {t("AED")} {(clearanceStatus ? contract?.SD : (refundAmount))?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-8 col-12'>
                  <div className='row'>
                    <div className='col-md-12 col-12 mb-10'>
                      <label className='form-label fw-bolder text-black d-block mb-5 font-17'>
                        {' '}
                        {t("Select Type")}{' '}
                      </label>
                      <div className='form-check form-check-inline custom-radio mb-sm-0 mb-4'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='refund_to'
                          id='inlineRadio1'
                          value={1}
                          autoComplete='off'
                          onChange={(e: any) => onRefundTypeChange(e)}
                          onBlur={formik.handleBlur}
                          defaultChecked
                        />
                        <label
                          className='form-check-label text-black fw-bolder '
                          htmlFor='inlineRadio1'
                        >
                          {t("Refund to me")}
                        </label>
                      </div>
                      <div className='form-check form-check-inline custom-radio mb-sm-0 mb-4'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='refund_to'
                          id='inlineRadio2'
                          value={2}
                          autoComplete='off'
                          onChange={(e: any) => onRefundTypeChange(e)}
                          onBlur={formik.handleBlur}
                        />
                        <label
                          className='form-check-label text-black fw-bolder'
                          htmlFor='inlineRadio2'
                        >
                          {t("Refund to others")}
                        </label>
                      </div>
                      {formik.touched.refund_to && formik.errors.refund_to && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.refund_to}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-md-12 col-12 mb-10'>
                      <div className='row'>
                        <label className='form-label fw-bolder text-black d-block mb-5 font-17'>
                          {t("Bank Details")}
                        </label>
                        <div className='col-sm-6 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Customer Name")}</label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              {...formik.getFieldProps('name')}
                              disabled={formik.values.refund_to == 1}
                              placeholder={t("Customer Name")}
                              autoComplete='off'
                              maxLength={25}
                              onKeyPress={(event) => {
                                if (!/[a-zA-Z ]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.name && formik.errors.name && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.name}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-sm-6 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Bank Name")}</label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              {...formik.getFieldProps('bank')}
                              placeholder={t("Enter Bank Name")}
                              autoComplete='off'
                              maxLength={25}
                              onKeyPress={(event) => {
                                if (!/[a-zA-Z ]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.bank && formik.errors.bank && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.bank}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-sm-6 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Account Number")}</label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              {...formik.getFieldProps('account_number')}
                              placeholder={t("Account Number")}
                              autoComplete='off'
                              maxLength={15}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.account_number && formik.errors.account_number && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.account_number}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-sm-6 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("IBAN Number")}</label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              {...formik.getFieldProps('iban_number')}
                              placeholder={t("Enter IBAN Number")}
                              autoComplete='off'
                              maxLength={15}
                              onKeyPress={(event) => {
                                if (!/[a-zA-Z0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.iban_number && formik.errors.iban_number && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.iban_number}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-sm-6 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Confirm IBAN Number")}</label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              {...formik.getFieldProps('confirm_iban_number')}
                              placeholder={t("Retype IBAN Number")}
                              autoComplete='off'
                              maxLength={15}
                              onKeyPress={(event) => {
                                if (!/[a-zA-Z0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.confirm_iban_number &&
                              formik.errors.confirm_iban_number && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.confirm_iban_number}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className='col-sm-6 col-12'>
                          <div className='mb-10'>
                            <label className='form-label fw-bolder'>{t("Swift Code")}</label>
                            <input
                              type='text'
                              className='form-control fw-bolder form-control-solid'
                              {...formik.getFieldProps('swift_code')}
                              placeholder={t("Enter Swift Code")}
                              autoComplete='off'
                              maxLength={15}
                              onKeyPress={(event) => {
                                if (!/[a-zA-Z0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {formik.touched.swift_code && formik.errors.swift_code && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert' className='text-danger'>
                                    {formik.errors.swift_code}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-sm-6 col-12 text-end'>
                          {contract?.isMoveInCompleted === "Y" ? <button
                            type='submit'
                            className='btn btn-primary fw-bold px-10 py-3 w-100'
                            disabled={loading}
                          >
                            {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                            {loading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                {t("Submitting...")}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button> : <><button
                            type='submit'
                            className='btn btn-primary fw-bold mt-10 px-10 py-3 w-100'
                            disabled={true}
                          >
                            {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                            {loading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                {t("Submitting...")}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button><><p style={{ color: 'red',  textAlign:'center' }}>Please complete the move in process</p></></>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 col-12 d-none d-md-block'>
                  <label className='form-label fw-bolder text-black d-block mb-5 font-17'>
                    {t("Refund Amount Details")}
                  </label>
                  <div className='request-refund-bg p-5 rounded'>
                    <div className='row mb-7'>
                      <div className='col-6'>
                        <h6 className='text-black fw-bold font-14'> {t("Available Security Deposit")} </h6>
                      </div>
                      <div className='col-6 text-end'>
                        <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                      </div>
                    </div>
                    <div className='row mb-7'>
                      <div className='col-6'>
                        <h6 className='text-black fw-bold font-14'> {t("Total Charges")} </h6>
                      </div>
                      <div className='col-6 text-end'>
                        <span className={`text-black fw-bold font-14 ${clearanceStatus ? 'text-decoration-line-through' : ''}`}>
                          {t("AED")} {(totalAmount)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </span>
                      </div>
                    </div>
                    <div className='row refund-block py-5 ps-5 rounded'>
                      <div className='col-6'>
                        <h6 className='text-black fw-bold font-14'> {t("Refund")} </h6>
                      </div>
                      <div className='col-6 text-end'>
                        <span className='text-black fw-bold font-14'>
                          {t("AED")} {(clearanceStatus ? contract?.SD : (refundAmount))?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className='row form-add-design'>
              <div className='col-xl-8 col-12'>
                <div className='row'>
                  <div className='col-md-12 col-12 mb-10'>
                    <label className='form-label fw-bolder text-black d-block mb-5 font-17'>
                      {' '}
                      {t("Refund Type")}{' '}
                    </label>
                    <div className='form-check form-check-inline custom-radio mb-sm-0 mb-4'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id='inlineRadio1'
                        autoComplete='off'
                        checked={true}
                        disabled={true}
                      />
                      <label
                        className='form-check-label text-black fw-bolder '
                        htmlFor='inlineRadio1'
                      >
                        {stepStatus?.data?.details?.refund_to == 1 ? t("Refund to me") : t("Refund to others")}
                      </label>
                    </div>
                  </div>
                  <div className='col-md-12 col-12 mb-10'>
                    <div className='row'>
                      <label className='form-label fw-bolder text-black d-block mb-5 font-17'>
                        {t("Bank Details")}
                      </label>
                      <div className='col-sm-6 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Customer Name")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={stepStatus?.data?.details?.name}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Bank Name")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={stepStatus?.data?.details?.bank}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Account Number")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={stepStatus?.data?.details?.account_number}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("IBAN Number")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={stepStatus?.data?.details?.iban_number}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='col-sm-6 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Swift Code")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={stepStatus?.data?.details?.swift_code}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-12'>
                <label className='form-label fw-bolder text-black d-block mb-5 font-17'>
                  {t("Refund Amount Details")}
                </label>
                <div className='request-refund-bg p-5 rounded'>
                  <div className='row mb-7'>
                    <div className='col-7'>
                      <h6 className='text-black fw-bold font-14'> {t("Available Security Deposit")} </h6>
                    </div>
                    <div className='col-5 text-end'>
                      <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <div className='col-7'>
                      <h6 className='text-black fw-bold font-14'> {t("Total Charges")} </h6>
                    </div>
                    <div className='col-5 text-end'>
                      <span className={`text-black fw-bold font-14 ${clearanceStatus ? 'text-decoration-line-through' : ''}`}>
                        {t("AED")} {(totalAmount)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </span>
                    </div>
                  </div>
                  <div className='row refund-block py-5 ps-5 rounded'>
                    <div className='col-7'>
                      <h6 className='text-black fw-bold font-14'> {t("Refund")} </h6>
                    </div>
                    <div className='col-5 text-end'>
                      <span className='text-black fw-bold font-14'>
                        {t("AED")} {(clearanceStatus ? contract?.SD : (refundAmount))?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}

export { Step5 }
