import React, { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CardPayment } from './CardPayment'
import { BankTransfer } from './BankTransfer'
import { PaybyCheque } from './PaybyCheque'
import { useTranslation } from 'react-i18next'
import { getUserRole } from '../../../../constants/generics/userCheck'
import { useSelector } from 'react-redux'

interface Props {
    tabKey: any
}

export const SecurityDeposit: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const state: any = useSelector((state) => state)
    const user_data = state?.auth?.user?.data
    const user_role = getUserRole(user_data)
  
    return (
        <div className=" ">
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-pills mb-3 security-deposit" id="pills-tab" role="tablist">
                        {(user_role !== 'BA' && user_role !== 'BR') &&
                            <li className="nav-item tab_nav_li" role="presentation">
                                <button className="nav-link active px-5 py-5 w-100 fw-bolder" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#card-payment" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">
                                    <span className="icons mb-3"><i className="bi bi-credit-card font-17"></i></span>
                                    {t("Card Payment")}
                                </button>
                            </li>
                        }
                        <li className="nav-item tab_nav_li" role="presentation">
                            <button className="nav-link px-5 py-5 w-100 fw-bolder" id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#bank-transfer" type="button" role="tab" aria-controls="pills-profile"
                                aria-selected="false">
                                <span className="icons mb-3"><i className="bi bi-currency-dollar font-17"></i></span>
                                {t("Bank Transfer")}
                            </button>
                        </li>
                        <li className="nav-item tab_nav_li" role="presentation">
                            <button className="nav-link px-5 py-5 w-100 fw-bolder" id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#pay-by-cheque" type="button" role="tab" aria-controls="pills-profile"
                                aria-selected="false">
                                <span className="icons mb-3"><i className="bi bi-cash font-17"></i></span>
                                {t("Pay by Cheque")}
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        {(user_role !== 'BA' && user_role !== 'BR') &&
                            <div className="tab-pane fade show active" id="card-payment" role="tabpanel" aria-labelledby="pills-home-tab">
                                <CardPayment />
                            </div>
                        }
                        <div className="tab-pane fade" id="bank-transfer" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <BankTransfer />
                        </div>
                        <div className="tab-pane fade" id="pay-by-cheque" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <PaybyCheque />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
