import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { MyActivity } from './components/MyActivity/MyActivity'
import { MyVisits } from './components/MyVisits/MyVisits'
import { MyOffers } from './components/MyOffers/MyOffers'
import { MyContracts } from './components/MyContracts/MyContracts'
import { MyLikes } from './components/MyLikes/MyLikes'
import { Search } from './components/Search/Search'
import SearchForm from './components/Search/Searchs'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../constants'
import * as dashboard from '../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import Banner from './components/Banner/Banner'
interface Props {
  visitsData: any
  likesData: any
  offerData: any
  contractData: any
}
const PTDashboardPage: FC<Props> = ({ visitsData, likesData, offerData, contractData }) => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page fc-ss-dashboard-page'>
      <div className='col-xxl-12 mt-0'>
        {/* <Search /> */}
        {/*<SearchForm />*/}
        <Banner />
        <MyActivity
          visits={visitsData}
          likes={likesData}
          offer={offerData}
          contract={contractData}
        />
        <div className='row'>
          <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
            <MyVisits visits={visitsData} />
          </div>
          <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
            <MyOffers offer={offerData} />
          </div>
          <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
            <MyContracts contract={contractData} />
          </div>
          <div className='col-12 col-md-12 col-lg-12 col-xl-12'>
            <MyLikes likes={likesData} />
          </div>
        </div>
      </div>
    </div>
  </>
)

const PTDashboardWrapper: FC = () => {
  let vistisList: any = []
  let likesList: any = []
  let offerList: any = []
  let contractList: any = []
  vistisList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.upcomingVisits)
  likesList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.likes)
  offerList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.offerList)
  contractList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.allContract)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(dashboard.actions.upcomingVisits())
    dispatch(dashboard.actions.likes())
    dispatch(dashboard.actions.offerList())
    dispatch(dashboard.actions.allContract())
  }, [])
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <PTDashboardPage
        visitsData={vistisList}
        likesData={likesList}
        offerData={offerList}
        contractData={contractList}
      />
    </>
  )
}

export { PTDashboardWrapper }
