export default [
    {
        id:"1",
        no: "01",
        sno: "1",
        referenceid: "REF-L-1720440760",
        customername: "Shaik Mohammad",
        brokername: "James Potter",
        mobileno: "+971 554056310",
        enquirydate: "Jul 01, 2024",
        status: "Active",
        substatus: "Contacted",
        action: "Handover"
    } ,
     {
        id:"2",
        no: "02",
        sno: "2",
        referenceid: "REF-L-1720779065",
        customername: "Asarudeen",
        brokername: "Nisar",
        mobileno: "+971 554056312",
        enquirydate: "Jul 04, 2024",
        status: "InActive",
        substatus: "Qualified",
        action: "Handover"
    } ,
    {
        id:"3",
        no: "03",
        sno: "3",
        referenceid: "REF-L-1720440760",
        customername: "Asife",
        brokername: "Mohammed Ali",
        mobileno: "+971 553056310",
        enquirydate: "Jul 10, 2024",
        status: "Active",
        substatus: "Contacted",
        action: "Handover"
    } 
];