import React, { FC } from 'react'
import moment from 'moment'
import Home from '../../../../assets/images/my-unit.png'
import { Step4Add } from '../steps/Step4-addon'
import { useTranslation } from 'react-i18next'

type Props = {
  stepper?: any
  contract?: any
  stepStatus?: any
  getStepStatus?: any
  setMessage?: any
}

const Step4: FC<Props> = ({ contract, stepStatus, getStepStatus, setMessage }: any) => {
  const { t } = useTranslation();

  return (
    <div className='w-100'>
      <div className='com-card-section w-100 mb-7 step4-page p-7'>
        <div className='row'>
          <div className='col-12'>
            <div className='card shadow-none'>
              <div className='card-body p-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-3'>
                    <div className=' step-3'>
                      <div className='approval-move'>
                        <img src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage} alt='Home' className='img-fluid' />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-8 col-lg-9'>
                    <div className='card lease-card bg-white rounded-5 shadow-none'>
                      <div className='card-body px-0 pb-5'>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-10'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease Number")}{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {contract?.contractNo}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-10'>
                            <div className=''>
                              <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                {' '}
                                {t("Lease Start Date")}{' '}
                              </h6>
                              <span className='text-black fw-bold font-14'>
                                {' '}
                                {moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
                              </span>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-10'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease End Date")}{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '}
                            </span>
                          </div>
                          {/* <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-md-0 mb-10'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> Documents </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {contract?.documentCount}{' '}
                            </span>
                          </div> */}
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-md-0 mb-10'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Annual Rent")}{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {' '}
                              {t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}{' '}
                            </span>
                          </div>
                          <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-md-0 mb-10'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Security Deposit")}{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Step4Add stepStatus={stepStatus} getStepStatus={getStepStatus} contract={contract} setMessage={setMessage} />
    </div>
  )
}

export { Step4 }
