import React, { FC, useEffect, useRef, useState } from 'react'
import {
  createMoveInAccessCardsRequest,
  getAllotedAccessCards,
} from '../../../../constants/axios/apis'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReactSignatureCanvas from 'react-signature-canvas'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";
import { TermsAndConditionsPopup } from '../../../terms-and-conditions/TermsAndConditionsPopup'

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

type Props = {
  contract?: any
  stepStatus?: any
  getStepStatus?: any
  setMessage?: any
}

const Step3Add: FC<Props> = ({ contract, stepStatus, getStepStatus, setMessage }) => {
  const [loading, setLoading] = useState(false)
  const [isShowMore, setIsShowMore] = useState(true)
  const [isShow, setIsShow] = useState(true)
  const [accessCards, setAccessCards] = useState<any>([])
  const ref = useRef<any>()
  const sigCanvas = useRef<any>()
  const { t } = useTranslation();

  const toggleReadMore = () => {
    setIsShowMore(!isShowMore)
    setIsShow(!isShowMore)
  }

  useEffect(() => {
    if (contract) {
      getAllotedAccessCards(contract?.propertyID).then(({ data }) => {
        if (data) {
          setAccessCards(data)
        }
      })
    }
  }, [contract])

  const initialValues = {
    signature: '',
    termsAndConditions: 0 as any,
  }

  const AccessCardRequestSchema = Yup.object().shape({
    termsAndConditions: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
    signature: Yup.string().required(t("Please add your signature")),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AccessCardRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        const data = new FormData()
        data.append('category_id', '7')
        data.append('property_id', contract?.propertyID)
        data.append('signature', values.signature, 'signature.png')
        data.append('termsAndConditions', values.termsAndConditions)

        createMoveInAccessCardsRequest(data)
          .then((response: any) => {
            setMessage(t("Your Access Card Request has been Submitted!"))
            getStepStatus()
            ref.current.click()
            setLoading(false)
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)

    }

  })

  const handleTermsAndConditions = (e: any) => {
    if (e.target.checked) {
      return formik.setFieldValue('termsAndConditions', 1)
    }
    return formik.setFieldValue('termsAndConditions', 0)
  }

  const submitAccessCards = () => {
    setLoading(true)
    const data = new FormData()
    data.append('category_id', '7')
    data.append('property_id', contract?.propertyID)

    createMoveInAccessCardsRequest(data).then(() => {
      setMessage(t("Your Access Card Request has been Submitted!"))
      getStepStatus()
      ref.current.click()
      setLoading(false)
    })
  }

  const cardsData = accessCards?.map((card: any, index: any) => (
    <div key={card.id} className='row'>
      <div className='col-sm-2 col-12'>
        <span className='text-black font-12 d-block mb-5 fw-bolder'>{index + 1}</span>
      </div>
      <div className='col-sm-5 col-12'>
        <span className='text-black font-12 d-block mb-5 fw-bolder'>{card?.cardType}</span>
      </div>
      <div className='col-sm-5 col-12 text-end text-sm-center'>
        <span className='text-black font-12 d-block mb-5 fw-bolder'>{card?.total}</span>
      </div>
    </div>
  ))

  let total: number = 0
  accessCards?.forEach((card: any) => {
    total += card.total
  })

  // const ul = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut viverra tellus, sit amet sagittis libero.
  // Integer nibh tortor, facilisis vel mollis dapibus, mattis ut nisl. Praesent convallis consequat eros, at interdum lorem
  // lacinia eget. Praesent posuere leo nec tempor pretium. Quisque imperdiet semper ex, in maximus urna porttitor laoreet.
  // Curabitur hendrerit est eget ante pulvinar tristique. Nullam vulputate, nulla vel posuere ullamcorper, mauris leo molestie tellus,
  // a volutpat orci velit eu justo. Curabitur erat lectus, luctus non mauris ut, ultricies ornare diam. Praesent iaculis sapien nec
  // blandit tempus. Praesent vitae gravida nisi. Donec consequat interdum elementum. Donec nec lacus mi. Fusce posuere cursus augue,
  // sit amet vulputate eros dapibus ac. Nulla consequat massa massa, vel hendrerit nunc mattis ut. Morbi lobortis tristique t
  // incidunt. Nulla facilisi`;

  const ul = [
    <li key={0}>
      {t("To Regulate and maintain secure access to the building, Access cards will be issued to all tenants.")}
    </li>,
    <li key={1}>{t("Access cards will only be issued to registered tenants.")}</li>,
    <li key={2}>
      {t("Access to the building will be via intercom or access card only therefore residents must ensure to obtain access cards from RealCube Real estate prior to activation of the access control system")}
    </li>,
    <li key={3}>{t("All-access cards are non-transferable.")}</li>,
    <li key={4}>
      {t("On completion of the tenancy, the access card should be handed back to the RealCube RealEstate, or a replacement card fee will be applied at AED 150 Per card.")}
    </li>,
    <li key={5}>
      {t("Loss of access card must be reported to the management immediately. The lost cards will be voided from the system and replacements.")}
    </li>,
    <li key={6}>{t("will be charged at AED 150 Per card.")}</li>,
    <li key={7}>
      {t("This issued card can not be used as a formal form of identification with any external body.")}
    </li>,
    <li key={8}>
      {t("In the case of an emergency/evacuation, all access doors will open automatically therefore access cards will not be required.")}
    </li>,
  ]


  const clear = () => {
    sigCanvas.current.clear()
    formik.setFieldValue('signature', '')
  }
  const handleSignature = () => {
    const canvas = sigCanvas.current.getCanvas()
    canvas.toBlob((blob: string) => {
      formik.setFieldValue('signature', blob)
    })
  }

  return (
    <>
      <div className='row mb-7'>
        <div className='col-md-12 col-lg-12 col-12'>
          <div className='card h-100'>
            <div className='card-body'>
              <div className='row mb-10'>
                <div className='col-12'>
                  <div className='bg-white rounded-5 px-1 py-2'>
                    <div className='row'>
                      <div className='col-2'>
                        <h4 className='step3-card-title fw-boldest text-black mb-6'>{t("S.No")}</h4>
                      </div>
                      <div className='col-5'>
                        <h4 className='step3-card-title fw-boldest text-black mb-6'>
                          {t("Access Card Types")}
                        </h4>
                      </div>
                      <div className='col-5 text-center'>
                        <h4 className='step3-card-title fw-boldest text-black mb-6'>
                          {t("No of Access Cards")}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='bg-white rounded-5 px-1 py-2'>
                    <div className='row'>{accessCards ? cardsData : ''}</div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='bg-white rounded-5 px-1 py-2'>
                    <div className='row'>
                      <div className='col-2'>&nbsp;</div>
                      <div className='col-5 bg-light-gray-solid p-4 rounded-left'>
                        <span className='text-black font-12 d-block fw-boldest'>{t("Total")}</span>
                      </div>
                      <div className='col-5 text-center bg-light-gray-solid p-4 rounded-right'>
                        <span className='text-black font-12 d-block fw-boldest me-10'>{total}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <form
                  className='form w-100 form-add-design fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='inspectionForm'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='col-12'>
                    <h4 className='card-title font-14 text-black mb-6'>
                      {' '}
                      {t("Building Access Requlations")}
                    </h4>
                    <ul>{isShowMore ? ul.slice(0, 300) : ul}</ul>
                  </div>
                  <div className='col-12'>
                    {ul && ul.length > 300 && (
                      <h4
                        className='ShowMoreText mb-0 text-theme font-12 fw-bold'
                        onClick={toggleReadMore}
                      >
                        {isShowMore ? (
                          <span>
                            {t("Read More")}{' '}
                            <i className='bi bi-arrow-right-circle-fill text-theme fonts-17 ms-2'></i>
                          </span>
                        ) : (
                          <span>
                            {t("Read Less")}{' '}
                            <i className='bi bi-arrow-left-circle-fill text-theme fonts-17 ms-2'></i>
                          </span>
                        )}
                      </h4>
                    )}
                  </div>
                  {stepStatus ? (
                    ''
                  ) : (
                    <>
                      <div className='col-12'>
                        <div className='row mt-7'>
                          <div className='col-md-8 col-12'>
                            <div className='mt-5'>
                              <label className='form-label fw-bolder'>{t("Signature")}<span className='text-danger'>*</span></label>
                              <div className='border border-2 c-pointer'>
                                <ReactSignatureCanvas
                                  ref={sigCanvas}
                                  penColor='black'
                                  canvasProps={{
                                    height: 130,
                                    width: 500,
                                    className: 'sigCanvas',
                                  }}
                                  onEnd={handleSignature}
                                />
                                <button
                                  type='button'
                                  className='btn btn-default bg-transparent text-primary fw-bold px-10 py-4 w-100 d-block border-0'
                                  onClick={clear}
                                >
                                  {t("Reset")}
                                </button>
                              </div>
                              {formik.touched.signature && formik.errors.signature && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.signature}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-12'>
                        {/* <button
                      type='submit'
                      onClick={() => submitAccessCards()}
                      className='btn btn-primary fw-bold mt-10 px-10 py-3'
                    >
                      Submit
                      </button> */}
                        <div className='row align-items-center mt-10'>
                          <div className='col'>
                            <div className=''>
                              {/* <label className='form-label fw-bolder'>&nbsp;</label> */}
                              <div className='form-check custom text-start'>
                                <input
                                  className='form-check-input'
                                  checked={formik.values.termsAndConditions ? true : false}
                                  {...formik.getFieldProps('termsAndConditions')}
                                  onChange={(e) => handleTermsAndConditions(e)}
                                  type='checkbox'
                                  id='flexCheckDefault'
                                  onBlur={formik.handleBlur}
                                />
                                <label
                                  className='form-check-label font-12 fw-normal'
                                  htmlFor='flexCheckDefault'
                                >
                                   {t("Accept")}	&nbsp;
                                  {/* <Link to={{ pathname: `${process.env.REACT_APP_WEB_URL}terms-and-conditions` }} target="_blank" className="text-primary fw-bolder">
                                    {t("Terms & Conditions")}
                                  </Link> */}
                                  <button
                                      data-bs-target="#terms-conditions-popup"
                                      data-bs-toggle='modal'
                                      className="text-primary p-0 border-0 bg-transparent shadow-none fw-bolder">
                                      {t("Terms & Conditions")}
                                  </button>
                                  <span className='text-danger'>*</span>
                                </label>
                              </div>
                              {formik.touched.termsAndConditions && formik.errors.termsAndConditions && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                      {formik.errors.termsAndConditions}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-auto text-end'>
                            <button
                              type='submit'
                              className='btn btn-primary fw-bold px-10 py-3'
                              // onClick={() => submitAccessCards()}
                              disabled={loading}
                            >
                              {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                              {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  {t("Please wait...")}{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        ref={ref}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <TermsAndConditionsPopup />
    </>
  )
}

export { Step3Add }
