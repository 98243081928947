import React, {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import CheckGreen from '../../assets/images/Check_green_lg.png'


export function RenewalPopup() {
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }

  const intl = useIntl()
  const history = useHistory()

  return (
    <div className='modal fade custom-modal' id='renew-popup'>
      <div className='modal-dialog modal-sm modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header bg-white border-0'>
            <button
              type='button'
              className='btn-close shadow'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              &times;
            </button>
          </div>
          <div className='modal-body py-10'>
            <div className='row g-5'>
              <div className='col-md-12'>
                <div className='text-center'>
                  <div className='redeem-offer-QR'>
                    <img src={CheckGreen} alt='tick-img' className='img-fluid mb-5' />
                    <h1 className='text-theme font-16 mb-10 fw-boldest'>Thank You!</h1>
                    <p className='mb-3 font-14 fw-bold'>
                      Intention to Renew or Vacate your unit must be recieved with a minimum of 2
                      Months notice
                    </p>
                    <p className='mb-8 font-14 fw-bold'>
                      Failure to do so will result in your lease being automatically renewed for
                      another 12 months duration.
                    </p>
                    {userEmail === 'commercialtenant@exalogic.co' ? (
                      <button
                        data-bs-dismiss='modal'
                        className='btn btn-primary btn-lg fw-bolder font-12 w-100'
                        onClick={(e) => history.push('/thankyou-service')}
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                        data-bs-dismiss='modal'
                        className='btn btn-primary btn-lg fw-bolder font-12 w-100'
                      >
                        Continue
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
