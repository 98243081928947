import React, { useEffect, useState } from 'react'
import pdfImage from '../../../assets/images/documentImage.png'
import { ViewPopup } from '../../maintenance/ViewPopup'
import { useTranslation } from 'react-i18next'
import { CustomLoader1 } from '../../../constants/generics/customActios'

export function GeenralService({ selectedIssue, contract }: any) {
    const [issue, setIssue] = useState<any>()
    const [loading, setLoading] = useState(true)
    const [viewImage, setViewImage] = useState<any>()

    useEffect(() => {
        if (selectedIssue) {
            setIssue(selectedIssue)
            setLoading(false)
        }
    }, [selectedIssue])

    const handleView = (data: any) => {
        setViewImage(data)
    }
    const { t } = useTranslation();
    if (issue === undefined) { return <CustomLoader1 height={50} /> }
    return (
        <div className='com-card-section'>
            {!loading && issue && issue?.code == 'GS' ? (
                <div className='row mta-app-setting-campaign'>
                    <div className='col-12'>
                        <div className='accordion accordion-flush' id='accordionFlushExampleAccesscard'>
                            <div className='accordion-item offers_details_accordian_div1'>
                                <h2 className='accordion-header position-relative' id='flush-headingAccesscard'>
                                    <button
                                        className='accordion-button collapsed accordian_bt'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#flush-collapseAccesscard'
                                        aria-expanded='false'
                                        aria-controls='flush-collapseAccesscard'
                                    >
                                        {t("General Service")}
                                    </button>
                                    {/* <span className='services-badge'>
                      {issue?.status == 0 ? 'In Progress' : 'Closed'}
                    </span> */}
                                </h2>
                                <div
                                    id='flush-collapseAccesscard'
                                    className='accordion-collapse collapse show'
                                    aria-labelledby='flush-headingAccesscard'
                                    data-bs-parent='#accordionFlushExampleAccesscard'
                                >
                                    <div className='accordion-body py-5'>
                                        <div className='row form-add-design'>
                                            <div className='col-md-6 col-lg-6 mb-md-0 mb-6'>
                                                <label className='form-label fw-bolder d-block mb-4'>{t("Service Category")}</label>
                                                <div className='d-flex service-req-img align-items-center mb-8'>
                                                    <div className='col-md-4 col-6 mb-5'>
                                                        <span className='text-black fw-bold font-14'>
                                                            {issue?.details?.category_type_name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-lg-6 mb-md-0 mb-6'>
                                                <label className='form-label fw-bolder d-block mb-4'>{t("Comments")}</label>
                                                <div className='d-flex service-req-img align-items-center mb-8'>
                                                    <div className='col-md-4 col-6 mb-5'>
                                                        <span className='text-black fw-bold font-14'>
                                                            {issue?.details?.description}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {issue?.images[0]?.length > 2 &&
                                        <div className='accordion-body py-5'>
                                            <div className='row form-add-design'>
                                                <div className='col-md-4 col-lg-3 mb-md-0 mb-4'>
                                                    <label className='form-label fw-bolder d-block mb-4'>{t("Service Category")} Image</label>
                                                    <div className='d-flex service-req-img align-items-center mb-8'>
                                                        <button className='border-0 text-primary font-14 btn p-0' data-bs-target='#view-popUpreschedule'
                                                            data-bs-toggle='modal'
                                                            data-bs-dismiss='modal'
                                                            type='button'
                                                            onClick={() => {
                                                                handleView(issue?.images[0])
                                                            }}>
                                                            <img
                                                                src={
                                                                    issue?.images[0]
                                                                        .substr(
                                                                            issue?.images[0].lastIndexOf('.')
                                                                        ).includes('.pdf')
                                                                        ? pdfImage
                                                                        : issue?.images[0]
                                                                }
                                                                alt="document"
                                                                className='img-fluid me-5'
                                                                style={{ height: 'auto' }}
                                                            />
                                                            <span className='text-md'>{t("View")}</span>
                                                        </button><ViewPopup viewImage={viewImage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}