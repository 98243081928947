import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function StatementTabs({ searchItem, setSearchItem }: any) {
  const location = useLocation()
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className='com-card-section'>
      <div className='row px-3'>
        <div className='col-12'>
          <div className='row mb-4 align-items-center'>
            <div className='col-md-6 col'>
              <h4 className='card-title font-18 text-black mb-4'>{t('Statements')}</h4>
            </div>
            <div className='col-md-6 col-auto text-end'>
              <Link to={'/statements/add-commission'} className='btn btn-primary btn-sm font-16'>
                <i className='bi bi-plus-circle-fill align-middle'></i>
                {t('Add')}
              </Link>

            </div>
          </div>
        </div>
        <div className='card mb-3'>
          <div className='card-body px-5 px-md-8 py-0'>
            <div className='row access-cd-tabs'>
              {/* <div className='row'> */}
                <div className='col-md-6 col-12'>
                  <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/statements/inprogress-invoices' && 'active')
                          }
                          to='/statements/inprogress-invoices'
                        >
                          {t('In progress')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/statements/approved-invoices' && 'active')
                          }
                          to='/statements/approved-invoices'
                        >
                          {t('Approved')}
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link
                          className={
                            `nav-link pb-1 position-relative text-active-primary me-14 ` +
                            (location.pathname === '/statements/rejected-invoices' && 'active')
                          }
                          to='/statements/rejected-invoices'
                        >
                          {t('Rejected')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-6 col-12 text-end my-md-auto my-4'>
                  <div className='d-flex pm-topbar-buttons float-end align-items-center'>
                    <div className='form-group has-search'>
                      <span className='fa fa-search pt-2 form-control-feedback'></span>
                      <input
                        type='text'
                        className='form-control ps-10'
                        onChange={(e) => setSearchItem(e.target.value)}
                        placeholder={t('Search')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </div>
  )
}