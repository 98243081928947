import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { accessCardRequest, getConfig } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { useDispatch } from 'react-redux'

const addVisitorSchema = Yup.object().shape({
  category_type_id: Yup.string().required('Please select a card type'),
  no_of_cards: Yup.number()
    .min(1, 'Minimum number of cards per request is 1')
    .required('Please specify the number of cards required'),
})

export function AccessCard({ accessCards, contract }: any) {
  const history = useHistory()
  const dispatch = useDispatch()
  const ref = useRef<any>(0)
  const [loading, setLoading] = useState(false)
  const [selectedCard, setSelectedCard] = useState<any>()
  const [message, setMessage] = useState<any>()
  const [amount, setAmount] = useState<number>(0)
  const [vat, setVat] = useState<number>(0)
  const [accessCardsCount, setAccessCardsCount] = useState<number>()
  const [total, setTotal] = useState<number>(0)
  const [vatPercent, setVatPercent] = useState<any>(0)
  // const [messages, setMessages] = useState<any>()
  // const [serviceStatus, setServiceStatus] = useState<any>(false)
  const { t } = useTranslation();

  const initialValues = {
    category_id: 1,
    category_type_id: selectedCard?.id,
    no_of_cards: accessCardsCount ? accessCardsCount : '',
    property_id: contract?.propertyID,
  }

  useEffect(() => {
    getConfig('').then(({data}: any) => {
      if (data && Array.isArray(data)) {
        const vat_per = data?.find((item: any) => item.key == 'vat_percentage').value
        setVatPercent(vat_per ? parseFloat(vat_per) : 0)
      }
    })
  },[])

  useEffect(() => {
    if (accessCardsCount) {
      setAmount(
        accessCardsCount && accessCardsCount !== 0
          ? accessCardsCount * (selectedCard?.price ? selectedCard?.price : 0)
          : selectedCard?.price
            ? selectedCard?.price
            : 0
      )
    } else {
      setAmount(0)
    }
    setVat(amount * (vatPercent / 100))
    setTotal(amount + vat)
  }, [selectedCard, accessCardsCount, amount, vat])

  // useEffect(() => {
  //   getServiceRequestByData(1, '', contract?.propertyID).then((response) => {
  //     if (response.data) {
  //       let data = response.data.sort((a: any, b: any) => {
  //         return b.id - a.id
  //       })
  //       if (data[0]?.status === 0) {
  //         setServiceStatus(true)
  //         formik.resetForm()
  //         setAccessCardsCount(0)
  //       } else {
  //         setServiceStatus(false)
  //       }
  //     } else {
  //       setServiceStatus(false)
  //     }
  //   })
  // }, [contract])

  const handleCardChanged = (e: any) => {
    setSelectedCard(accessCards?.find((card: any) => card.id == e.target.value))
    formik.handleChange(e)
  }

  const updateAmount = (e: any) => {
    if (e.target.value < 0 || e.target.value > 4) {
      return
    }
    setAccessCardsCount(e.target.value)
    formik.handleChange(e)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addVisitorSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        accessCardRequest(
          values.category_id,
          values.category_type_id,
          values.no_of_cards,
          values.property_id
        )
          .then((response: any) => {
            setLoading(false)
            // if (response != 500) {
            if (response?.code === 200 && response?.errorCode === 0) {
              setMessage({
                error: 0,
                message: response?.description,
              })
              ref.current.click()
              resetForm()

              setTimeout(() => {
                ref.current.click()
                history.push('/my-services/openissues', {
                  offer_id: response.data.id,
                  // object_id: response.data.id,
                  amount: amount,
                  extra_charges_title: 'VAT',
                  extra_charges_amount: vat,
                  payment_category: 2,
                  payment_for: 3,
                  property_id: contract?.propertyID,
                  for: 'accesscard'
                })
              }, 2000);
            } 
            else if(response?.errorCode === 1) {
              setMessage({
                error: 1,
                message: response?.errorDescription,
              })
              ref.current.click()
            }
            else {
              setMessage({
                error: 1,
                message: t('Something went wrong!'),
              })
              ref.current.click()
            }
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus('Invalid Data Provided')
            setMessage({
              error: 1,
              message: t('Something went wrong!'),
            })
            ref.current.click()
          })
      }, 1000)
    },
  })
  useEffect(() => {
    if (message?.message?.length > 0) {
      dispatch(PTOffer.actions.modalmessage(message))
    }
  }, [message])
  const accessCardsList = accessCards?.filter((card: any) => {
    return card.type == 'card'
  })?.map((card: any) => (
    <option key={card.id} value={card.id}>
      {card.name}
    </option>
  ))

  return (
    <><div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/my-services/openissues'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            {t("Access Card Request")}
          </h4>
          {/* {serviceStatus ? (
      <p className='text-success'>An Access Card Request is already in progress.</p>
    ) : (
      ''
    )} */}
        </div>
        <div className='col-md-6 col-lg-6 col-xl-8 col-12 mb-md-0 mb-5'>
          <div className='card h-100'>
            <div className='card-body px-8 py-6'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='accessCardRequestForm'
                onSubmit={formik.handleSubmit}
              >
                <div className='row form-add-design'>
                  <div className='col-md-12 col-sm-6 col-12 '>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Access Card")}<span className='text-danger'>*</span></label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid'
                        name='category_type_id'
                        onChange={(e) => handleCardChanged(e)}
                        value={formik.values.category_type_id}
                        onBlur={formik.handleBlur}
                      >
                        <option selected disabled>
                          {t("Select Type")}
                        </option>
                        {accessCards ? accessCardsList : ''}
                      </select>
                      {formik.touched.category_type_id && formik.errors.category_type_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.category_type_id}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 col-sm-6 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("No.of Access Card")}<span className='text-danger'>*</span></label>
                      <input
                        type='number'
                        name='no_of_cards'
                        min={1}
                        className='form-control fw-bolder form-control-solid'
                        onChange={(e) => updateAmount(e)}
                        value={formik.values.no_of_cards}
                        onBlur={formik.handleBlur} />
                      {formik.touched.no_of_cards && formik.errors.no_of_cards && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.no_of_cards}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-lg-6 col-xl-4 col-12'>
          <div className='card h-100'>
            <div className='card-body px-8 py-6'>
              <div className='row'>
                <div className='col-12'>
                  <h4 className='card-title'>{t("Access Card Charges")}</h4>
                </div>
                <div className='col-12'>
                  <div className='bg-light-gray rounded-5 p-4 my-4'>
                    <div className='row'>
                      <div className='col-md-8 col-8'>
                        <span className='text-black font-12 d-block mb-5 fw-bolder'>
                          {t("Access Card Amount")} X {accessCardsCount}
                        </span>
                      </div>
                      <div className='col-md-4 col-4 text-end'>
                        <span className='text-black font-12 d-block mb-5 fw-bolder'>
                          {t("AED")} {amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </span>
                      </div>
                      <div className='col-md-8 col-8'>
                        <span className='text-black font-12 d-block mb-5 fw-bolder'>{t("VAT")}</span>
                      </div>
                      <div className='col-md-4 col-4 text-end'>
                        <span className='text-black font-12 d-block mb-5 fw-bolder'>{t("AED")} {vat?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
                      </div>
                    </div>
                    <div className='bg-white rounded-5 p-2'>
                      <div className='row'>
                        <div className='col-md-8 col-8'>
                          <span className='text-black font-12 d-block fw-bolder'>{t("Total")}</span>
                        </div>
                        <div className='col-md-4 col-4 text-end'>
                          <span className='text-black font-12 d-block fw-bolder'>{t("AED")} {total?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <button
                    type='submit'
                    form='accessCardRequestForm'
                    className='btn btn-primary w-100 fw-bold px-10 py-3'
                    // disabled={loading || serviceStatus}
                    disabled={loading || total === 0}
                  >
                    {!loading && (
                      <span className='indicator-label'>{t("Submit")}</span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {t("Please wait...")}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
        {/*  */}
      </p></>
  )
}
