import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { currencyFormater } from '../../../../constants/generics/customActios';
import { DisputePopup } from './DisputePopup/DisputePopup';
import { useTranslation } from 'react-i18next'

type Props = {
    contract?: any
    clearanceCharges?: any
    otherCharges?: any
    stepStatus?: any
    totalClearanceCharges?: any
    totalOtherCharges?: any
    setMessage?: any
    vatPercent?: any
}

const Step3Add: FC<Props> = ({ contract, clearanceCharges, otherCharges, stepStatus, totalClearanceCharges, totalOtherCharges, vatPercent, setMessage }) => {

    const { t } = useTranslation();

    let ccBase = parseFloat(totalClearanceCharges)
    let ccVat = (vatPercent * ccBase) / 100
    let ccTotal = ccBase + ccVat
    let ocBase = parseFloat(totalOtherCharges)
    let ocVat = (vatPercent * ocBase) / 100
    let ocTotal = ocBase + ocVat

    return (
        <>
            <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-4 mb-5 col-12">
                    <div className="card clear-card rounded-5 h-100">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4 className="card-title">
                                                {t("Clearance Charges")}
                                            </h4>
                                        </div>
                                        {!stepStatus && clearanceCharges?.length > 0 &&
                                            <div className="col-3 text-end">
                                                <DisputePopup contract={contract} workOrderId={clearanceCharges[0]?.work_order_id} setMessage={setMessage} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 my-4">
                                    <div className="">
                                        <div className="bg-light-gray rounded-5 p-4">
                                            <div className="row">
                                                {clearanceCharges?.length ? clearanceCharges?.map((charge: any) => (
                                                    <>
                                                        <div className="col-md-8 col-8">
                                                            <span className="text-black font-12 d-block mb-5 fw-bold">
                                                                {charge?.details?.category_type_name} * <small>{charge?.details?.quantity}</small>
                                                                <div className='text-theme-gray font-10'>({charge?.details?.precise_location_name})</div>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4 text-end col-4">
                                                            <span className="text-black font-12 d-block mb-5 fw-bold">
                                                                {/* {t("AED")} {charge?.details?.amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })} */}
                                                                {t("AED")} {currencyFormater(parseInt(charge?.details?.quantity) * parseFloat(charge?.details?.amount))}
                                                            </span>
                                                        </div>
                                                    </>
                                                )) :
                                                    <>
                                                        <div className="col-md-8 col-8">
                                                            <span className="text-black font-12 d-block mb-5 fw-bold">
                                                                {t("Charges")}
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4 text-end col-4">
                                                            <span className="text-black font-12 d-block mb-5 fw-bold">
                                                                {t("AED")} 0
                                                            </span>
                                                        </div>
                                                    </>
                                                }
                                                {/* <div className="col-md-8 col-8">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("VAT")} ({vatPercent}%)
                                                    </span>
                                                </div>
                                                <div className="col-md-4 text-end col-4">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("AED")} {currencyFormater(ccVat)}
                                                    </span>
                                                </div> */}
                                            </div>
                                            <div className="bg-white rounded-5 p-2">
                                                <div className="row">
                                                    <div className="col-md-8 col-8">
                                                        <span className="text-black font-12 d-block fw-bold">
                                                            {t("Total")}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-4 text-end col-4">
                                                        <span className="text-black font-12 d-block fw-bold">
                                                            {t("AED")} {currencyFormater(ccBase)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6 col-xl-4 mb-5 col-12">
                    <div className="card clear-card rounded-5 h-100">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-9">
                                            <h4 className="card-title">
                                                {t("Other Charges")}
                                            </h4>
                                        </div>
                                        {!stepStatus && otherCharges.length ?
                                            <div className="col-3 text-end">
                                                <DisputePopup contract={contract} workOrderId={otherCharges[0]?.work_order_id} setMessage={setMessage} />
                                            </div>
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 my-4">
                                    <div className="">
                                        <div className="bg-light-gray rounded-5 p-4">
                                            <div className="row">
                                                {otherCharges?.length ? otherCharges?.map((charge: any) => (
                                                    <>
                                                        <div className="col-md-8 col-8">
                                                            <span className="text-black font-12 d-block mb-5 fw-bold">
                                                                {charge?.details?.description} * <small>{charge?.details?.quantity}</small>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4 text-end col-4">
                                                            <span className="text-black font-12 d-block mb-5 fw-bold">
                                                                {t("AED")} {charge?.details?.amount?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                                            </span>
                                                        </div>
                                                    </>
                                                )) :
                                                    <>
                                                        <div className="col-md-8 col-8">
                                                            <span className="text-black font-12 d-block mb-5 fw-bold">
                                                                {t("Charges")}
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4 text-end col-4">
                                                            <span className="text-black font-12 d-block mb-5 fw-bold">
                                                                {t("AED")} 0
                                                            </span>
                                                        </div>
                                                    </>
                                                }
                                                {/* <div className="col-md-8 col-8">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("VAT")} ({vatPercent}%)
                                                    </span>
                                                </div>
                                                <div className="col-md-4 text-end col-4">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("AED")} {currencyFormater(ocVat)}
                                                    </span>
                                                </div> */}
                                            </div>
                                            <div className="bg-white rounded-5 p-2">
                                                <div className="row">
                                                    <div className="col-md-8 col-8">
                                                        <span className="text-black font-12 d-block fw-bold">
                                                            {t("Total")}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-4 text-end col-4">
                                                        <span className="text-black font-12 d-block fw-bold">
                                                            {t("AED")} {currencyFormater(ocBase)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 mb-5 col-12">
                    <div className="card clear-card rounded-5 h-100">
                        <div className="card-body p-7">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="card-title">
                                        {t("Total")}
                                    </h4>
                                </div>
                                <div className="col-12 my-4">
                                    <div className="">
                                        <div className="bg-light-gray rounded-5 p-4">
                                            <div className="row">
                                                <div className="col-md-8 col-8">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("Clearance Charges")}
                                                    </span>
                                                </div>
                                                <div className="col-md-4 text-end col-4">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("AED")} {currencyFormater(ccBase)}
                                                    </span>
                                                </div>
                                                <div className="col-md-8 col-8">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("Other Charges")}
                                                    </span>
                                                </div>
                                                <div className="col-md-4 text-end col-4">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("AED")} {currencyFormater(ocBase)}
                                                    </span>
                                                </div>
                                                <div className="col-md-8 col-8">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("VAT")} ({vatPercent}%)
                                                    </span>
                                                </div>
                                                <div className="col-md-4 text-end col-4">
                                                    <span className="text-black font-12 d-block mb-5 fw-bold">
                                                        {t("AED")} {currencyFormater(ccVat + ocVat)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="bg-white rounded-5 p-2">
                                                <div className="row">
                                                    <div className="col-md-8 col-8">
                                                        <span className="text-black font-12 d-block fw-bold">
                                                            {t("Total")}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-4 text-end col-4">
                                                        <span className="text-black font-12 d-block fw-bold">
                                                            {t("AED")} {currencyFormater(ccTotal + ocTotal)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!stepStatus && clearanceCharges?.length > 0 &&
                                    <div className="col-12">
                                        <Link to={{
                                            pathname: '/payments/card-payment',
                                            state: {
                                                offer_id: contract?.id,
                                                work_order_id: clearanceCharges[0]?.work_order_id,
                                                amount: totalClearanceCharges + totalOtherCharges,
                                                // extra_charges_title: 'VAT',
                                                // extra_charges_amount: vat,
                                                payment_category: 10,
                                                payment_for: 7,
                                                property_id: contract?.propertyID
                                            }
                                        }} className='btn btn-primary w-100 fw-bold px-10 py-3'>
                                            {t("Make Payment")}
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Step3Add }
