import React from 'react';

export function PhotoViewPopup({ images = [] }: any) {
    return (
        <div className="">
            <button type="button" className="photo-view" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <i className="bi bi-eye font-18"></i>
            </button>
            <div className="modal fade custom-modal" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-10">
                        <div className="modal-header bg-transparent border-0 p-0">
                            <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                        </div>
                        <div className="modal-body">
                            {/* <div className='m-5'> */}
                            <div className='row'>
                                {images?.map((image: any, index: any) => image.includes('pdf') ?
                                    <iframe src={image} style={{ width: '600px', height: '500px' }} ></iframe> :
                                    <img key={index} src={image}></img>)}
                                {/* {props?.viewImage?.includes('pdf') ? <iframe src={props?.viewImage} style={{ width: '600px', height: '500px' }} ></iframe> :
                                <img src={props?.viewImage} style={{ width: '100%', height: '20%' }} />} */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}