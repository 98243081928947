import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {getActiveBroker} from '../../../../../utils/makeRequest'
import {IState} from '../../../../../utils/interface'
import {useTranslation} from 'react-i18next'

export const TeamSearch: React.FC<any> = ({setSearchBroker, setSearchProperty}) => {
  const intl = useIntl()
  const state: any = useSelector((state) => state)
  const userToken = state?.auth?.accessToken
  const userDetails = state?.auth?.user?.data || {}
  const {t} = useTranslation()
  const [brokersList, setBrokersList] = useState<IState['APIState']>({
    loading: false,
    response: {},
    error: '',
  })
  const {loading, response: brokers, error} = brokersList
  const [finalList, setFinalList] = useState<any>([])

  useEffect(() => {
    getActiveBroker(setBrokersList, userToken)
  }, [])

  useEffect(()=>{
    const adminData = [{
      id: userDetails?.id,
      name: userDetails?.first_name + ' ' + userDetails?.last_name,
    }]
    const brokerListData = brokers?.teamList && Array.isArray(brokers?.teamList) ? brokers?.teamList?.map((item:any)=>({id: item?.id, name: item?.name})) : []
    setFinalList(adminData?.concat(brokerListData))
  }, [brokers])

  return (
    <>
      <div className='row justify-content-end'>
        <div className='col-lg-6 col-md-6 col-12 my-md-auto'>
          <div className='input-group custom-search-form'>
            <input
              type='text'
              className='form-control search-input'
              placeholder={t('Search Property Name')}
              onChange={(e: any) => {
                setSearchProperty(e.target.value)
              }}
            />
            <span className='input-group-btn'>
              <button className='search_icon' type='button'>
                <span className=''> </span>
              </button>
            </span>
          </div>
        </div>
        <div className='col-lg-6 col-md-6 col-12 my-md-auto'>
          <div className='input-group custom-search-form border-0'>
            <select
              className='form-select-lg form-select-solid filter-select c-pointer'
              data-placeholder={t('Filter')}
              onChange={(e: any) => {
                setSearchBroker(e.target.value)
              }}
            >
              <option value=''>{t('All Brokers')}</option>
              {finalList?.length !== 0 &&
                finalList?.map((broker: any, index: number) => {
                  return <option value={broker?.id} key={index}>{broker?.name ? broker?.name : '-'}</option>
                })}
            </select>
          </div>
        </div>
      </div>
    </>
  )
}
