import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IState as Props } from '../../../../utils/interface'
import { useTranslation } from 'react-i18next'
import { CompactPagination } from '../../../../components/partials/content/CompactPagination/CompactPagination';
import { CustomLoader2, NoDataFound1 } from '../../../../constants/generics/customActios';
import { getBrokerMyTeam } from '../../../../constants/axios/apis';

export function MyteamBrokersList() {
  const { t } = useTranslation();
  const pageSize = 10
  const [myTeamBrokerList, setmyTeamBrokerList] = useState<any>()
  const [loading, setLoading] = useState<any>(true)
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [totalmyTeamBrokerList, setTotalmyTeamBrokerList] = useState<any>(0)
  const [page, setPage] = React.useState(1)
  setTimeout(() => {
    setLoading(false)
  }, 1500)

  const handleClick = (newCurrentPage: any) => {
    setCurrentPage(newCurrentPage)
  }

  useEffect(() => {
    let page = currentPage
    const newPayload =
      '?currentPage=' +
      page +
      '&pageSize=' +
      pageSize +
      '&id_sort=1'
    getBrokerMyTeam(newPayload).then((response: any) => {
      if (Array.isArray(response?.data?.teamList) && response?.data?.teamList?.length > 0) {
        setmyTeamBrokerList(response?.data?.teamList)
        setTotalmyTeamBrokerList(response?.dataCount)
      } else {
        setmyTeamBrokerList([])
        setTotalmyTeamBrokerList(0)
      }
    })
  }, [currentPage])


  if (!loading && (myTeamBrokerList === undefined || myTeamBrokerList?.length === 0)) {
    return <NoDataFound1 />
  }

  const brokerData = myTeamBrokerList?.map((broker: any, index: any) => (
    <tr key={broker?.id}>
      <td>
        <Link
          to={{
            pathname: broker?.status === 1 && broker?.is_approved === 1 ? '/SingleBroker/in-progress' : '/SingleBroker/data/details',
            state: { broker: broker },
          }}
          className='text-decoration-none text-dark'
        >
          <span className={broker?.status === 1 && broker?.is_approved === 1 ? 'member-status active' : 'member-status inactive'}></span>{broker?.name ? broker?.name : '-'}
        </Link>
      </td>
      <td>{broker?.paidInvoices !== undefined && broker?.paidInvoices !== "" ? broker?.paidInvoices : '-'}</td>
      <td>{broker?.nonPaidInvoices !== undefined && broker?.nonPaidInvoices !== "" ? broker?.nonPaidInvoices : '-'}</td>
      <td>{broker?.recentInvoices !== undefined && broker?.recentInvoices !== ""  ? broker?.recentInvoices : '-'}</td>
    </tr>
  ))

  return (
    <div className=''>
      <div className='row'>
        <div className='col-12'>
          <div className='rec-payment-act-sec'>
            <div className='row'>
              <div className='col-12'>
                <div className='table-responsive'>
                  <table className='table align-middle table-striped gy-4 gx-4 mb-0'>
                    <thead>
                      <tr>
                        {/* <th className=''> {t("Id")} </th> */}
                        <th className=''> {t("Broker Name")} </th>
                        <th className=''> {t("Paid Invoices")} </th>
                        <th className=''> {t("Un-paid Invoices")} </th>
                        <th className=''> {t("Recent Invoices")}</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <tbody>
                        <tr>
                          <td colSpan={4}>
                            <CustomLoader2 />
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>{myTeamBrokerList ? brokerData : ''}</tbody>
                    )}
                  </table>
                  <CompactPagination
                    pageSize={pageSize}
                    totalCount={parseInt(totalmyTeamBrokerList)}
                    defaultPage={currentPage}
                    onPageChange={setCurrentPage}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

