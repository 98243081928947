import { useState, useRef, useEffect } from 'react'
import Propertie from '../../../../assets/images/latest-one.png'
import Place from '../../../../assets/images/place.svg'
import Sqft from '../../../../assets/images/icon-sqft.svg'
import Bed from '../../../../assets/images/icon-bed.svg'
import Bath from '../../../../assets/images/icon-bath.svg'
import YesIcon from '../../../../assets/images/icon-parking.svg'
import SignatureCanvas from 'react-signature-canvas'
import { addSignature } from '../../../../constants/axios/apis'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { Spinner } from 'react-bootstrap-v5'
import { useDispatch } from 'react-redux'
import { currencyFormater } from '../../../../constants/generics/customActios'
import * as General from '../../../../constants/redux/Sagas/GeneralSaga'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl'
    },
];

export function Card(props: any) {
    const dispatch = useDispatch()
    const { contracts } = props
    const [fieldValue, setFieldValue] = useState<any>()
    const ref = useRef<any>(0)
    const ref2 = useRef<any>(0)
    const [message, setMessage] = useState<any>()
    const [errormessage, seterrorMessage] = useState<any>()
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation();

    const sigCanvas = useRef<any>()

    function clearSignature() {
        sigCanvas.current.clear()
        setFieldValue('')
    }

    const handleSignature = () => {
        const canvas = sigCanvas.current.getCanvas()
        canvas.toBlob((blob: string) => {
            setFieldValue(blob)
        }, 'image/png')
    }
    const addSign = () => {
        if (fieldValue !== undefined && fieldValue !== '') {
            setLoading(true)
            var data = new FormData();
            data.append('contract_id', contracts?.id);
            data.append('signature', fieldValue, 'signature.png');
            addSignature(data).then((response) => {

                setFieldValue('')
                ref2.current.click();
                setLoading(false)
                if (response?.code === 200 && response?.errorCode != 1) {

                    dispatch(General.actions.contractDetails(contracts))
                    clearSignature()
                    setMessage({
                        error: 0,
                        message: response?.data?.msg,
                    })
                    ref.current.click();
                } else {
                    setMessage({
                        error: 1,
                        message: response?.errorDescription,
                    })
                    ref.current.click();
                }
            })
        } else {
            seterrorMessage('Signature is required');
            setTimeout(() => {
                seterrorMessage('')
            }, 2000)
        }
    }

    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
        }
    }, [message])

    return (
        <>
            <div className='card'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='offer_main_img h-100'>
                            <div className='img-inner'>
                            <img
                                src={contracts?.propertyCoverImg?.length > 0
                                    ? contracts?.propertyCoverImg
                                    : Propertie}
                                alt='Work Orders'
                                className='img-fluid' />
                            </div>
                            <div className='property-overlay'>
                                <div className="row align-items-center g-0">
                                    <div className="col-6">
                                        <h6>{contracts?.propertySearchType === 1 ? t("Residential") : contracts?.propertySearchType === 2 ? t("Commercial") : contracts?.propertySearchType}</h6>
                                    </div>
                                    <div className="col-6 text-end">
                                        <span className='price-amount d-block font-12'>
                                            {t("AED")} <strong className="font-16">{currencyFormater(contracts?.price)}</strong>  / {t("Year")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <span className="badge badge-pill badge-dark prop-type-badge">{contracts?.propertySearchType === 1 ? t("Residential") : contracts?.propertySearchType === 2 ? t("Commercial") : contracts?.propertySearchType}</span> */}
                        </div>
                    </div>
                    <div className='col-12 align-self-center'>
                        <div className='offer_main_details p-3'>
                            <div className='col-12 pt-3'>
                                <p className='text-black fw-bolder font-17 mb-3 px-3'>{contracts?.propertyName}</p>
                                {/* <h1 className='text-black fw-bolder mb-3'>
                                                <small>{t("AED")}</small> {currencyFormater(contracts?.price)}
                                                <span className='fw-boldest'></span>
                                                <small> / {t("Year")} </small>
                                            </h1> */}
                            </div>
                            <div className='col-12 offer_main_details_div_3 mb-3'>
                                <p className='mb-3 px-3'>
                                    <img src={Place} className='img-fluid pe-2' />
                                    {/* {contracts?.location} */}
                                    {contracts?.propertyCode?.substring(contracts?.propertyCode?.lastIndexOf("-") + 1, contracts?.propertyCode?.length) + ' | '} {contracts?.buildingName && contracts?.buildingName + ' | '}  {contracts?.address && contracts?.address + ' | '}  {contracts?.location && contracts?.location}
                                </p>
                            </div>
                            <div className='col-12'>
                                <div className='mainCard_d_etails_div_2 d-flex'>
                                    {contracts?.property_search_type !== 2 &&
                                        <>
                                            {contracts?.bedRooms?.length > 0 && contracts?.bedRooms != 0 ? (
                                                <p className='p-3 m-0'>
                                                    <img src={Bed} className='img-fluid me-3' alt="bed" /> {contracts?.bedRooms} Bedrooms
                                                </p>
                                            ) : (
                                                <></>
                                            )
                                            }
                                        </>
                                    }
                                    {contracts?.bathRooms?.length > 0 && contracts?.bathRooms != 0 ? (
                                        <p className='p-3 m-0'>
                                            <img src={Bath} className='img-fluid me-3' alt="bath" /> {contracts?.bathRooms} Bathrooms
                                        </p>
                                    ) : (
                                        <></>
                                    )
                                    }
                                    {contracts?.builtInArea > 0 && contracts?.builtInArea != 0 ? (
                                        <p className='p-3 m-0 sqft-my-offer'>
                                            <img src={Sqft} className='img-fluid me-3' />
                                            {contracts?.builtInArea} {t("Sqmt")}
                                        </p>
                                    ) : (
                                        <></>
                                    )
                                    }
                                    <p className='p-3 m-0'>
                                        <img src={YesIcon} className='img-fluid me-3' />
                                        {contracts?.parking === 'Y' ? t("Yes") : t("No")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 align-self-center'>
                        <div className='p-5 pt-0'>
                            {contracts?.contractFile != null ? (<div className='text-end'>
                                <div className='text-end'>
                                    <a href={contracts?.contractFile} target='_blank'>
                                        <button className='btn btn-primary rounded py-2 w-100 mb-0'> {t("Download")} </button>
                                    </a>
                                </div>
                            </div>) : null}
                            {contracts?.isSigned != 1 ? (<div className='text-end'>
                                <button
                                    data-bs-target='#sign-submitted-popup'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                    type='submit'
                                    className='btn btn-outline-primary rounded w-100 mt-5 py-2 mb-0'
                                >
                                    {t("Contract Sign")}
                                </button>
                            </div>) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade add-broker-modal" id="sign-submitted-popup">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-8">
                        <div className="modal-header p-0">
                            <h5 className="modal-title">{t("Tenant Signature")}</h5>{loading ? <Spinner animation='border' variant='primary' /> : null}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-0 pb-0 pt-5">
                            <div className="mb-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-5 position-relative">
                                            <SignatureCanvas
                                                ref={sigCanvas}
                                                penColor='black'
                                                canvasProps={{ height: 80, width: 320, className: 'sigCanvas' }}
                                                onEnd={handleSignature} />
                                        </div>
                                        <p style={{ color: 'red' }}>{errormessage}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer px-0 py-0">
                            <div className="d-flex flex-wrap justify-content-end">
                                <button
                                    className='btn btn-primary fw-bold px-10 py-3 me-4'
                                    type='button'
                                    data-bs-toggle='modal'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                >
                                    {t("Cancel")}
                                </button>{/*  */}
                                <button className='btn btn-primary fw-bold px-10 py-3 me-4 mb-3' onClick={clearSignature}>
                                    {t("Clear")}
                                </button>{/*  */}
                                <button className='btn btn-primary fw-bold px-10 py-3'
                                    onClick={addSign}>
                                    {t("Submit")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                {/*  */}
            </p>
            <p ref={ref2} data-bs-target="#sign-submitted-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                {/*  */}
            </p>
            </>
    )
}
