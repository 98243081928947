import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import StarActive from '../../../assets/images/Star_Active.png'
import StarInactive from '../../../assets/images/Star_Inactive.png'
import ViewRightArrow from '../../../assets/images/Right_arrow_white.png'
import { getUserNotifications, postReadNotifications } from '../../../constants/axios/apis'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as General from '../../../constants/redux/Sagas/GeneralSaga'
import { RootState } from '../../../constants'
import { getNotificationListTypes } from '../../../constants/generics/notificationListTypes'
import { getUserRole } from '../../../constants/generics/userCheck'
import { NoDataFound } from '../../../constants/generics/customActios'


const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl'
  },
];

export function NotificationsList() {
  const history = useHistory();

  const contractList: any = useSelector<RootState>(({ Tdashboard }) => Tdashboard.allContract)
  const dispatch = useDispatch()
  const [notifications, setNotifications] = useState<any>([])
  const [readNotifs, setReadNotifs] = useState<any>([])
  const state: any = useSelector((state) => state)
  const user_data = state?.auth?.user?.data
  const user_role = getUserRole(user_data)
  // console.log(user_role,"user_roleuser_role");


  let notifCount: any
  notifCount = useSelector<RootState>(({ General }) => General.notif)

  // const handleRead = (notification: any) => {
  //   if (notification?.is_read === 0) {
  //     const formData = new FormData()
  //     formData.append('notification_id', notification?.id)
  //     postReadNotifications(formData).then((response: any) => {
  //       //no action required
  //       // console.log(response, 'response');
  //       const notifCountTemp = isNaN(notifCount) ? '' : Number(notifCount) - 1
  //       dispatch(General.actions.notif(notifCountTemp))
  //     })
  //   }
  // }

  const handleRead = (notification: any) => {
    if (notification?.is_read === 0 && !(readNotifs.includes(notification.id))) {
      const formData = new FormData()
      formData.append('notification_id', notification?.id)
      postReadNotifications(formData).then((response: any) => {
        //no action required
        // console.log(response, 'response');
        const notifCountTemp = isNaN(notifCount) ? '' : Number(notifCount) - 1
        dispatch(General.actions.notif(notifCountTemp))
      })
      setReadNotifs((oState: any) => {
        let tempArr = [...oState]
        tempArr.push(notification?.id)
        return tempArr
      })
    }
  }

  useEffect(() => {
    getUserNotifications().then((response: any) => {
      if (response.data && Array.isArray(response.data)) {
        setNotifications(response.data)
        dispatch(General.actions.notif(response.unreadCount))
      }
      else{
        setNotifications([])
        dispatch(General.actions.notif(0))
      }
    })
  }, [])
  const { t } = useTranslation();

  const notificationType = getNotificationListTypes()
  // const notificationType2 = [
  //   { type: 'user_registration', button1: '', button2: '', link: 'userRegistration', notificationName: 'userRegistration' },
  //   { type: 'create_announcements', button1: 'View', button2: '', link: 'announcements', notificationName: 'announcements' },
  //   { type: 'accept_offer', button1: 'View Offer', button2: '', link: 'pt-activity/my-offer', notificationName: 'offer' },
  //   { type: 'approve_contract', button1: 'View Contract', button2: '', link: 'pt-activity/my-contracts-details', notificationName: 'contract' },
  //   { type: 'contract_sign', button1: 'Sign Contract', button2: '', link: 'pt-activity/my-contracts-details', notificationName: 'contract' },
  //   { type: 'submit_contract_sign', button1: '', button2: 'Download', link: 'pt-activity/my-contracts-details', notificationName: 'contract' },
  //   { type: 'contract_generated', button1: 'View Contract', button2: 'Download', link: 'pt-activity/my-contracts-details', notificationName: 'contract' },
  //   { type: 'visit_scheduled', button1: 'View', button2: 'Reschedule', link: 'pt-activity/my-visit/upcoming', notificationName: 'visit_scheduled' },
  //   { type: 'visit_schedule', button1: 'View', button2: '', link: 'pt-activity/my-visit/upcoming', notificationName: 'visit_scheduled' },
  //   { type: 'cancel_visit', button1: 'View', button2: '', link: 'pt-activity/my-visit/visited', notificationName: 'cancel_visit' },
  // ]
  const visitLink = (link: any, buttonCase: any) => {
    if (link?.notification_type === 'contract_generated' && buttonCase === 'download') {

      window.open(link?.notification_url, '_blank')
    }
    else if (link?.notification_type === 'submit_contract_sign' && buttonCase === 'download') {

      window.open(link?.notification_url, '_blank')
    }
    else {
      const result: any = notificationType.find((element: any) => element.type === link?.notification_type && (element.permission === undefined || element.permission.includes(user_role)));
      // history.push('usernotifications/' + result?.notificationName + '/' + link?.reference_id + '/view' + result?.link)
      history.push({
        pathname: 'usernotifications/' + result?.notificationName + '/' + link?.reference_id + '/view' + result?.link,
        state: { otherData: link }
      })
    }
    handleRead(link)
  }
  const escapeRegExpMatch = function (s: any) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };
  const isExactMatch = (str: any, match: any) => {
    return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str)
  }
  const notificationList1 = notifications?.map((notification: any) => {
    let pageLink = ''
    let pageButton1 = ''
    let pageButton2 = ''
    let pageType = ''
    let stateVal = notification.reference_id


    notificationType?.every((element: any) => {
      // if (element.type.includes(notification.notification_type)) {
      if (isExactMatch(element.type, notification.notification_type) && (element.permission === undefined || element.permission.includes(user_role))) {
        pageLink = element.link;
        pageButton1 = element.button1
        pageButton2 = element.button2
        pageType = element.type
        return false
      }
      return true
    });
    const isAlreadyRead = notification.is_read === 1 || (readNotifs.includes(notification.id))
    return (<div key={notification.id} className='col-12'>
      <div className='card notification-my-profile mb-3'>
      <div className={isAlreadyRead ? 'card-body p-5' : 'card-body p-5 c-pointer'} onClick={() => handleRead(notification)}>
          <div className='row'>
            <div className='notification_inner_div col-md-12 align-items-center'>
              <div className='notification_Star'>
                <img src={StarInactive} className='img-fluid' />
              </div>
              <div className='notification_details'>
                <div className='row gx-10'>
                  <div className='col-md-7'>
                    <div className="d-flex align-items-center mb-3">
                      <h4 className='notif-title font-17 fw-bold mb-0 me-5'>
                        {notification.title}
                        {!isAlreadyRead &&
                          <span className='ms-2 bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-5 start-70 animation-blink'></span>
                        }
                        {/* {notification.is_read === 0 && 
                          <span className='ms-2 bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-5 start-70 animation-blink'></span>
                        } */}
                      </h4>
                      <span className='notif-time font-10 fw-bold'>
                        {moment(notification.created_at).fromNow()}
                      </span>
                    </div>
                    <h6 className='notif-desc fw-normal font-14 mb-0'>{notification.description}  </h6>
                    {notification?.notification_type === 'reschedule_maintenance_request_tenant' &&
                      <h6 className='notif-desc fw-normal font-14 mb-0'>to <b>{moment(notification?.other_data?.date).format('DD-MM-YYYY')} {moment(notification?.other_data?.time, "hh").format('LT')}</b></h6>}
                  </div>
                  <>
                    <div className='col-md-5 text-end'>
                      {pageButton1 != '' && <button
                        onClick={() => visitLink(notification, '')}
                        className='btn btn-primary fw-bold px-10 py-3 me-5 mb-3 mb-md-0'
                      >
                        {pageButton1}
                      </button>}
                      {pageButton2 != '' && <button
                        onClick={() => visitLink(notification, 'download')}
                        className='btn btn-primary fw-bold px-10 py-3 me-5 mb-3 mb-md-0'
                      >
                        {pageButton2}
                      </button>}
                    </div>
                  </>
                  <div className='col-md-3 d-block d-md-none text-end'>
                    <span className='notif-time font-14 fw-bold'>
                      {moment(notification.created_at).fromNow()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  )

  return (
    <>
      <div className='com-card-section'>
        <div className='row'>
          <div className='col-md-12'>
            <h4 className='card-title font-18 text-black mb-6'> {t("Notifications")}</h4>
          </div>
        </div>
        <div className='row'>
          <div className='notification_main_card'>
            {notifications && notifications?.length ? notificationList1 : <NoDataFound />}
          </div>
        </div>
      </div>
    </>
  )
}
