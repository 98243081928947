import React, { useState, useEffect, useRef } from 'react'
import PlusIcon from '../../../../assets/images/plus-circle.svg'
import { Alert, Spinner } from 'react-bootstrap-v5'
import { ViewPopup } from '../ViewPopup/ViewPopup'
import { getMyDocs, uploadMyDocs, deleteMyDocs } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

export function DrivingLicense() {

	const [myDocs, setMyDocs] = useState<any>()
	const [uploadedFileFrnt, setUploadedFileFrnt] = useState<any>()
	const [uploadedFileBack, setUploadedFileBack] = useState<any>()
	const [viewImage, setViewImage] = useState<any>()
	const [show, setShow] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const inputRefFrnt = useRef<any>(0)
	const inputRefBack = useRef<any>(0)
	var dlFrontImage = ''
	var dlBackImage = ''
	const [dlFrontVisible, setEIdFrontVisible] = useState(false)
	const [dlBackVisible, setEIdBackVisible] = useState(false)
	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')
	const { t } = useTranslation();

	// ** Get uploaded docs - getMyDocs()
	useEffect(() => {
		setLoading(true)
		getMyDocs().then((data) => {
			if (data.code === 200 && data.errorCode != 1) {
				setLoading(false)
				setMyDocs(data)
			} else {
				setLoading(false)
			}
		})
	}, [])
	if (myDocs?.data?.length > 0) {
		dlFrontImage = myDocs.data.find((obj: any) => obj.document_type === 'dl_front')
		dlBackImage = myDocs.data.find((obj: any) => obj.document_type === 'dl_back')
	}

	const handleUploadFrnt = () => {
		inputRefFrnt.current?.click()
	}
	const handleUploadBack = () => {
		inputRefBack.current?.click()
	}
	// ** Upload new doc(s) - uploadMyDocs() 
	const handleSubmit = () => {
		setLoading(true)
		var FormData = require('form-data')
		var data = new FormData()
		if (uploadedFileFrnt?.name != '' && uploadedFileFrnt != null) {
			data.append('dl_front', uploadedFileFrnt)
		}
		if (uploadedFileBack?.name != '' && uploadedFileBack != null) {
			data.append('dl_back', uploadedFileBack)
		}
		uploadMyDocs(data).then((data) => {
			if (data.code === 200 && data.errorCode != 1) {
				setSuccess(data?.description)
				setShowSuccess(true)
				setUploadedFileFrnt('')
				setUploadedFileBack('')
				setLoading(false)
				setTimeout(() => {
					setShowSuccess(false)
				}, 2000)
				getMyDocs().then((data) => {
					if (data.code === 200 && data.errorCode != 1) {
						setMyDocs(data)
					} else {
						setMyDocs(null)
					}
				})
			} else {
				setError(data?.errorDescription)
				setShow(true)
				setLoading(false)
				setTimeout(() => {
					setShow(false)
				}, 2000)
			}
		})
	}

	// ** Verify the file size and extension/type
	const handleDisplayFileDetailsFrnt = () => {
		let fileSize: any = inputRefFrnt?.current?.files[0]?.size <= 2101546
		let isAllowedType: any =
			inputRefFrnt?.current?.files[0]?.type === 'image/png' ||
			inputRefFrnt?.current?.files[0]?.type === 'image/jpeg' ||
			inputRefFrnt?.current?.files[0]?.type === 'image/jpg' ||
			inputRefFrnt?.current?.files[0]?.type === 'application/pdf'
		if (!isAllowedType) {
			setShow(true)
			setError('File is not a png/jpeg/jpg/PDF  file')
			setTimeout(() => {
				setShow(false)
			}, 2000)
		}
		if (!fileSize) {
			setShow(true)
			setError('File size should be less than 2MB')
			setTimeout(() => {
				setShow(false)
			}, 2000)
		}
		if (isAllowedType && fileSize) {
			inputRefFrnt.current?.files && setUploadedFileFrnt(inputRefFrnt.current.files[0])
		}
	}
	const handleDisplayFileDetailsBack = () => {
		let fileSize: any = inputRefBack?.current?.files[0]?.size <= 2101546
		let isAllowedType: any =
			inputRefBack?.current?.files[0]?.type === 'image/png' ||
			inputRefBack?.current?.files[0]?.type === 'image/jpeg' ||
			inputRefBack?.current?.files[0]?.type === 'image/jpg' ||
			inputRefBack?.current?.files[0]?.type === 'application/pdf'
		if (!isAllowedType) {
			setShow(true)
			setError('File is not a png/jpeg/jpg/PDF  file')
			setTimeout(() => {
				setShow(false)
			}, 2000)
		}
		if (!fileSize) {
			setShow(true)
			setError('File size should be less than 2MB')
			setTimeout(() => {
				setShow(false)
			}, 2000)
		}
		if (isAllowedType && fileSize) {
			inputRefBack.current?.files && setUploadedFileBack(inputRefBack.current.files[0])
		}
	}
	// ** Delete uploaded docs by id - deleteMyDocs(#id)
	const handleDelete = (id: any) => {
		setLoading(true)
		deleteMyDocs(id?.id).then((data) => {
			if (data.code === 200 && data.errorCode != 1) {
				setLoading(false)
				setSuccess(data?.description)
				setShowSuccess(true)
				setLoading(false)
				setTimeout(() => {
					setShowSuccess(false)
				}, 2000)
				getMyDocs().then((data) => {
					if (data.code === 200 && data.errorCode != 1) {
						setMyDocs(data)
					} else {
						setMyDocs(null)
					}
				})
			} else {
				setError(data?.errorDescription)
				setShow(true)
				setLoading(false)
				setTimeout(() => {
					setShow(false)
				}, 2000)
			}
		})
	}
	const handleView = (data: any) => {
		setViewImage(data)
	}
	return (
		<div className='com-card-section'>
			<div className='row form-add-design'>
				<div className='col-12'>
					<div className='card documents-card mb-3 pb-7'>
						<div className='card-body px-10'>
							<div className='row'>
								<div className='col-12'>
									<p className='font-14 fw-bold mb-8'>
										{t("Include the front and back of your Driving License")}
										{loading ? <Spinner animation='border' variant='primary' /> : null}
									</p>
								</div>
								{show ? (
									<Alert className='font-14 fw-bold mb-8' variant='danger'>
										{error}!
									</Alert>
								) : null}
								{showSuccess ? (
									<Alert className='font-14 fw-bold mb-8' variant='success'>
										{success}
									</Alert>
								) : null}
							</div>
							<div className='row'>
								<div className='col-md-12'>
									<div className='row'>
										<div className='col-lg-4 col-12'>
											<div className='mb-3 upload-input'>
												<label className='form-label text-black font-14 fw-bold d-block mb-5'>
													{t("Front")}
												</label>
												<input
													ref={inputRefFrnt}
													onChange={handleDisplayFileDetailsFrnt}
													className='d-none'
													type='file'
													accept='.png,.jpeg,.jpg,application/pdf'
												/>
												<button
													onClick={handleUploadFrnt}
													className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileFrnt?.name || dlFrontImage ? 'primary active' : 'primary'
														}`}
												>
													<span>
														{uploadedFileFrnt?.name || dlFrontImage ? (
															uploadedFileFrnt?.name || ''
														) : (
															<img src={PlusIcon} className='img-fluid va-baseline' />
														)}
													</span>
												</button>
												<div className='col-12 position-relative'>
													<div className='doc-view-bt'>
														<div className='row'>
															<div className='col-6 text-center'>
																{dlFrontImage ? (
																	<button
																		className='border-0 bg-transparent'
																		data-bs-target='#view-popUp'
																		data-bs-toggle='modal'
																		data-bs-dismiss='modal'
																		type='submit'
																		onClick={() => {
																			handleView(dlFrontImage)
																		}}
																	>
																		<i className='bi bi-eye text-black font-17'></i>
																	</button>
																) : null}
															</div>
															<div className='col-6 text-center'>
																{dlFrontImage ? (
																	<button
																		className='border-0 bg-transparent '
																		onClick={() => {
																			handleDelete(dlFrontImage)
																		}}
																	>
																		<i className='bi bi-trash text-black font-17'></i>
																	</button>
																) : null}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-lg-4 col-12'>
											<div className='mb-3 upload-input'>
												<label className='form-label text-black font-14 fw-bold d-block mb-5'>
													{t("Back")}
												</label>
												<input
													ref={inputRefBack}
													onChange={handleDisplayFileDetailsBack}
													className='d-none'
													type='file'
													accept='.png,.jpeg,.jpg,application/pdf'
												/>
												<button
													onClick={handleUploadBack}
													className={`w-100 py-3 ellipsis mydoc_bt_file btn-outline-${uploadedFileBack?.name || dlBackImage ? 'primary active' : 'primary'
														}`}
												>
													<span>
														{uploadedFileBack?.name || dlBackImage ? (
															uploadedFileBack?.name || ''
														) : (
															<img src={PlusIcon} className='img-fluid va-baseline' />
														)}
													</span>
												</button>
												<div className='col-12 position-relative'>
													<div className='doc-view-bt'>
														<div className='row'>
															<div className='col-6 text-center'>
																{dlBackImage ? (
																	<button
																		className='border-0 bg-transparent'
																		data-bs-target='#view-popUp'
																		data-bs-toggle='modal'
																		data-bs-dismiss='modal'
																		type='submit'
																		onClick={() => {
																			handleView(dlBackImage)
																		}}
																	>
																		<i className='bi bi-eye text-black font-17'></i>
																	</button>
																) : null}
															</div>
															<ViewPopup viewImage={viewImage} />
															<div className='col-6 text-center'>
																{dlBackImage ? (
																	<button
																		className='border-0 bg-transparent '
																		onClick={() => {
																			handleDelete(dlBackImage)
																		}}
																	>
																		<i className='bi bi-trash text-black font-17'></i>
																	</button>
																) : null}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-12 text-end'>
						<button
							type='submit'
							className='btn btn-primary fw-bold px-10 py-2'
							onClick={handleSubmit}
							disabled={loading}
						>
							{t("Save")}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
