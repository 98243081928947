import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { getMaintenanceIssueDetails } from '../../../../constants/axios/apis'
import { ViewPopup } from '../../ViewPopup'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../../constants/generics/dateTimeFormater'

export function IssuesView() {
  const [issue, setIssue] = useState<any>()
  const [date, setDate] = useState<any>('')
  const [viewImage, setViewImage] = useState<any>()
  const { id } = useParams<any>()
  const { t } = useTranslation();

  useEffect(() => {
    getMaintenanceIssueDetails(id).then((response) => {
      setIssue(response.data)
    })
  }, [id])

  useEffect(() => {
    if (issue) {
      setDate(new Date(issue?.appointmentDate))
    }
  }, [issue])

  const handleView = (data: any) => {
    setViewImage(data)
  }
  return (
    <div className='com-card-section'>
      <div className='row align-items-center mb-6'>
        <div className='col-sm-8 col-12'>
          <h4 className='card-title font-18 text-black mb-0'>
            <Link to={'/maintenance/openissues'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            {t("Ticket No")} - {issue?.ticket}
          </h4>
        </div>
        <div className='col-sm-4 col-12 text-end'>
          <Link
            to={{ pathname: '/maintenance/reschedule', state: { issue: issue } }}
            className='btn btn-primary btn-sm font-16'
          >
            {t("Reschedule")}
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <div className='row form-add-design'>
                <div className='col-12 col-sm-4'>
                  <div className='mb-6'>
                    <label className='form-label fw-bolder text-black mb-3'> {t("Issue Category")} </label>
                    <input
                      value={issue?.issueCategory}
                      type='text'
                      className='form-control fw-bolder form-control-solid'
                      disabled
                    />
                  </div>
                </div>
                <div className='col-12 col-sm-4'>
                  <div className='mb-6'>
                    <label className='form-label fw-bolder text-black mb-3'>{t("Issue Type")}</label>
                    <input
                      value={issue?.issueType}
                      type='text'
                      className='form-control fw-bolder form-control-solid'
                      disabled
                    />
                  </div>
                </div>
                <div className='col-12 col-sm-4'>
                  <div className='mb-6 date-picker'>
                    <label className='form-label fw-bolder text-black mb-3'> {t("Date")} </label>
                    <input
                      type='text'
                      className='form-control fw-bolder form-control-solid'
                      value={formatDate(date)}
                      // value={moment(date).format('DD-MM-YYYY')}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-12 col-sm-4'>
                  <div className='mb-6 date-picker'>
                    <label className='form-label fw-bolder text-black mb-3'> {t("Time")} </label>
                    <input
                      type='time'
                      value={issue?.time}
                      className='form-control fw-bolder form-control-solid'
                      disabled
                    />
                  </div>
                </div>
                <div className='col-12 col-sm-4'>
                  <div className='mb-6'>
                    <label className='form-label fw-bolder text-black mb-3'>{t("Precise Location")}</label>
                    <input
                      value={issue?.location}
                      type='text'
                      className='form-control fw-bolder form-control-solid'
                      disabled
                    />
                  </div>
                </div>
                <div className='col-12 col-sm-4'>
                  <div className='mb-6'>
                    <label className='form-label fw-bolder text-black mb-3'>{t("Image")}</label>
                    <div className='d-flex service-req-img align-items-center'>
                      {issue?.photo ? (
                        <>
                          <img src={issue?.photo[0]} className='img-fluid me-5' />
                          {/* <span className='text-black fw-bold me-8'> IMG012101.JPG </span> */}
                          <button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
                            data-bs-toggle='modal'
                            data-bs-dismiss='modal'
                            type='button'
                            onClick={() => {
                              handleView(issue?.photo[0])
                            }}>
                            {t("View")}
                          </button>
                          <ViewPopup viewImage={viewImage} />
                        </>
                      ) : (
                        <span className='text-muted'>{t("No image")}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-12'>
                  <div className='mb-6'>
                    <label className='form-label fw-bolder text-black mb-3'>{t("Description")}</label>
                    <textarea
                      value={issue?.description}
                      className='form-control form-control-lg form-control-solid'
                      rows={1}
                      disabled
                    />
                  </div>
                </div>
                {issue?.is_recurring && parseInt(issue?.is_recurring) === 1 &&
                  <div className='col-12 col-sm-12'>
                    <div className='mb-6'>
                      <div className='overflow-scroll pb-3 form-check custom'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='flexCheckDefault'
                          checked={true}
                          style={{ cursor: 'none' }}
                        />
                        <label
                          className='text-black'
                          htmlFor='flexCheckDefault'
                        >
                          {t("Recurring issue")}
                        </label>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
