import React from 'react'
import LeftArrow from '../../../assets/images/Left_Arrow.png'
import { HelpPopup } from '../components/ServiceRequests/HelpPopup'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export function UnitCard({ contract, selectedIssue }: any) {
  const { t } = useTranslation();

  return (
    <>
      <div className='com-card-section'>
        <div className='row align-items-center'>
          <div className='col-sm-6 col'>
            <h4 className='card-title font-18 text-black mb-6'>
              <Link to={'/my-services/openissues'} className=''>
                <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                <span className='text-black'>{t("Request Details")}</span>
              </Link>
            </h4>
          </div>
          <div className='col-sm-6 col-auto text-end'>
            <HelpPopup />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card mb-7'>
              <div className='card-body p-7'>
                <div className='row'>
                  <div className='col-md-12 col-12'>
                    <div className='row align-items-baseline mb-4'>
                      <div className='col-md-6'>
                        <h4>
                          {' '}
                          {t("Request Number")} :{' '}
                          <span className='text-normal fw-normal'>
                            {' '}
                            {selectedIssue?.SRNumber}
                          </span>{' '}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-3'>
                    <div className='approval-move'>
                      <img src={contract?.communityLogo} alt='Home' className='img-fluid' />
                    </div>
                  </div>
                  <div className='col-md-8 col-lg-9'>
                    <div className='card lease-card bg-white rounded'>
                      <div className='card-body px-0 pt-4 pb-2'>
                        <div className='row'>
                          <div className='col-md-4 col-6 mb-5'>
                            <div className=''>
                              <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                                {' '}
                                {t("Lease Number")}{' '}
                              </h6>
                              <span className='text-black fw-bold font-14'>
                                {contract?.leaseNumber}
                              </span>
                            </div>
                          </div>
                          <div className='col-md-4 col-6 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Lease Start Date")}{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {moment(contract?.contractStartDate).format('DD-MM-YYYY')}
                            </span>
                          </div>
                          <div className='col-md-4 col-6 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {t("Lease End Date")}
                            </h6>
                            <span className='text-black fw-bold font-14'>
                              {moment(contract?.contractEndDate).format('DD-MM-YYYY')}
                            </span>
                          </div>
                          {/* <div className='col-md-4 col-6 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'> Status </h6>
                            <span className='text-black fw-bold font-14'>{contract?.status}</span>
                          </div> */}
                          <div className='col-md-4 col-6 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Annual Rent")}{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'>{t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
                          </div>
                          <div className='col-md-4 col-6 mb-5'>
                            <h6 className='text-theme-gray mb-3 fw-normal font-13'>
                              {' '}
                              {t("Security Deposit")}{' '}
                            </h6>
                            <span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(!contract || !selectedIssue) &&
            <div className="col-12 text-center">
              {t("Loading...")}
            </div>
          }
          {/* {!selectedIssue && 
            <div className="col-12 text-center">
                <div className="alert alert-danger w-100 mt-2" role="alert">
                    {'No service data found'}
                </div>
            </div>
          } */}
        </div>
      </div>

    </>
  )
}
