import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { useLocation, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { TotalInvoices } from './TotalInvoices/TotalInvoices'
import { SingleBrokersActivity } from './SingleBrokersActivity/SingleBrokersActivity'
import { RecentInvoices } from '../broker-admin-dashboard/components/RecentInvoices/RecentInvoices'
import RightArrow from '../../assets/images/Right_Arrow.png'
import LeftArrow from '../../assets/images/Left_Arrow.png'
import ProfileAvatar from '../../assets/images/DefaultUser.png'
import { useTranslation } from 'react-i18next'

const BrokersPage: FC = () => {
  let params: any = {}
  params = useLocation()
  const history = useHistory()

  if (!params?.state?.broker) {
    window.location.replace('/broker-admin-dashboard/available-properties')
  }

  const { broker } = params.state
  const { t } = useTranslation()

  return (
    <>
      <div className='row gy-5 g-xl-8 broker-dash-page dashboard-page'>
        <div className='col-xxl-12 mt-0'>
          <div className='com-card-section'>
            <div className='row'>
              <div className='col-md-12'>
                <h4 className='card-title font-18 text-black mb-6'>
                  <Link to={'/broker-admin-MyTeam'} >
                    <img src={LeftArrow} className='img-fluid me-5' />
                  </Link>
                  {t('My Team')}
                </h4>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='card mb-6'>
                  <div className='card-body px-8 pb-10'>
                    <div className='row'>
                      <div className='col-lg-8 col-md-7 col-7'>
                        <Link
                          to={{ pathname: '/SingleBroker/data/details', state: { broker: broker } }}
                        >
                          <h4 className='card-title'>
                            <div className='d-flex broker-det'>
                              <span>
                                <img
                                  src={ broker?.profileImage ? broker?.profileImage : ProfileAvatar}
                                  alt='profile-img' className='rounded-circle'
                                />
                              </span>
                              <span className='ps-3'>{broker?.name ? broker?.name : '-'}</span>
                            </div>
                          </h4>
                        </Link>
                      </div>
                    </div>
                    <div className='row grid-match'>
                      <div className='col-lg-5 col-12'>
                        <TotalInvoices broker={broker} />
                      </div>
                      <div className='col-lg-7 col-12'>
                        <SingleBrokersActivity broker={broker} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card mb-3'>
                  <div className='card-body px-8'>
                    <div className='row'>
                      <div className='col-md-6 col-7'>
                        <h4 className='card-title'> {t('Recent Invoices')} </h4>
                      </div>
                      <div className='col-md-6 col-5 text-end'>
                        <Link
                          to={'/statements/inprogress-invoices'}
                          className='text-decoration-none view-all d-block mt-2 text-end'
                        >
                          <span> {t('View All')} </span>
                          <img src={RightArrow} className='img-fluid' />
                        </Link>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <RecentInvoices broker={broker} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const SingleBrokersWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <BrokersPage />
    </>
  )
}

export { SingleBrokersWrapper }
