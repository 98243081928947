import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { ContractDetails } from './Details'
import { Card } from './Card'
import { getMyContract } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../constants'

const languages = [
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl'
    },
];

export function MyContractsDetails(props: any) {
    let contractDetails: any
    contractDetails = useSelector<RootState>(({ General }) => General.contractDetails)
    const location = useLocation()
    const id = location.pathname.split("/").pop()
    // const [openDetails, setOpenDetails] = useState(false)
    const [contracts, setContracts] = useState<any>([])
    const { t } = useTranslation();

    useEffect(() => {
        // getMyContract(id).then((response) => {
        // setContracts(response.data)
        // })
        setContracts(contractDetails)
    }, [contractDetails])
    return (
        <div className='com-card-section'>
            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-body px-8 py-6'>
                            <div className='row align-items-center mb-10'>
                                <div className='col-6'>
                                    <h4 className='card-title font-18 text-black mb-0'>
                                        <Link to={'/pt-activity/my-contracts'} className=''>
                                            <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                                        </Link>
                                        {t("Contract Id")} : {contracts?.contractNo}
                                    </h4>
                                </div>
                                <div className='col-6'>
                                </div>
                            </div>
                            <div className="row mb-10">
                                <div className='col-12'>
                                    <div className='pm-offers-and-renewals mb-6'>
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <Card contracts={contracts} />
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className='row form-add-design mb-10'>
                                                    <div className='col-md-12'>
                                                        <div className='mb-3'>
                                                            <label className='form-label fw-bolder'>{t("Tenant")}</label>
                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                                    {contracts?.occupantFirstName}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='mb-3'>
                                                            <label className='form-label fw-bolder'>{t("Email")}</label>
                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                                    {contracts?.email}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='mb-3'>
                                                            <label className='form-label fw-bolder'>{t("Mobile")}</label>
                                                            <div className='data-contain px-5 py-4 rounded-10'>
                                                                <h6 className='font-14 fw-bold d-block mb-0 border rounded p-3'>
                                                                    {contracts?.phone}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className='accordion check-date-details pros-offer' id='accordion-step-five'>
                                        <ContractDetails contracts={contracts} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
