import React from 'react';
import { useTranslation } from 'react-i18next'

export function TermsAndConditionsPopup() {
	const { t } = useTranslation();

    return (
        <div className='modal fade custom-modal terms-con-popup' id='terms-conditions-popup'>
            <div className='modal-dialog modal-dialog-scrollable modal-xl'>
                <div className='modal-content rounded-10'>
                    <div className='modal-header border-0'>
                        <h5 className="modal-title">{t("Terms & Conditions")}</h5>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'>
                            &times;
                        </button>
                    </div>
                    <div className='modal-body px-5 py-8'>
                        <div className="row cond-overview">
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('Terms & Conditions')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details1')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('terms_head1')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details2')}
                                </p>
                                <p>
                                    {t('terms_details3')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head2')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details4')}
                                </p>
                                <p>
                                    {t('terms_details5')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head3')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details6')}
                                </p>
                                <p>
                                    {t('terms_details7')}
                                </p>
                                <p className="padding_left">
                                    <ul className='p-0'>
                                        <li className='mb-25'>
                                            {t('terms_details8')}
                                        </li>
                                        <li>
                                            {t('terms_details9')}
                                        </li>
                                    </ul>
                                </p>
                                <p>
                                    {t('terms_details10')}
                                </p>
                                <p>
                                    {t('terms_details11')}
                                </p>
                                <p>
                                    {t('terms_details12')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head4')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details13')}
                                </p>
                                <p>
                                    {t('terms_details14')}
                                </p>
                                <p className="padding_left">
                                    <ul className='p-0'>
                                        <li className='mb-25'>
                                            {t('terms_details15')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details16')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details17')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details18')}
                                        </li>
                                        <li>
                                            {t('terms_details19')}
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head5')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details20')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head6')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details21')}
                                </p>
                                <p className="padding_left">
                                    <ul className='p-0'>
                                        <li className='mb-25'>
                                            {t('terms_details22')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details_new1')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details23')}
                                        </li>
                                        <li className=''>
                                            {t('terms_details74')}
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head7')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p className="padding_left">
                                    <ul className='p-0'>
                                        <li className='mb-25'>
                                            {t('terms_details24')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details25')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details26')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details27')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details28')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details29')}
                                        </li>
                                        <li className=''>
                                            {t('terms_details30')}
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head8')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details31')}
                                </p>
                                <p>
                                    {t('terms_details32')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head9')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details33')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head10')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details34')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                    {t('term_head11')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                    {t('terms_details35')}
                                </p>
                                <p>
                                    {t('terms_details36')}
                                </p>
                                <p>
                                    {t('terms_details37')}
                                </p>
                                <p>
                                    {t('terms_details38')}
                                </p>
                                <p>
                                    {t('terms_details39')}
                                </p>
                                <p>
                                    {t('terms_details_new2')}
                                </p>
                                <p>
                                    {t('terms_details40')}
                                </p>
                                <p>
                                    {t('terms_details41')}
                                </p>
                                <p>
                                    {t('terms_details42')}
                                </p>
                                <p>
                                    {t('terms_details43')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head12')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details44')}
                                </p>
                                <p>
                                {t('terms_details45')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head13')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details46')}
                                </p>
                                <p>
                                {t('terms_details47')}
                                </p>
                                <p className="padding_left">
                                    <ul className='p-0'>
                                        <li className='mb-25'>
                                            {t('terms_details48')}
                                        </li>
                                        <li className='mb-25'>
                                            {t('terms_details49')}
                                        </li>
                                        <li className=''>
                                            {t('terms_details50')}
                                        </li>
                                    </ul>
                                </p>
                                <p>
                                {t('terms_details51')}
                                </p>
                                <p className="padding_left">
                                    <ul className='p-0'>
                                        <li className='mb-25'>
                                            {t('terms_details52')}
                                        </li>
                                        <li className=''>
                                            {t('terms_details53')}
                                        </li>
                                    </ul>
                                </p>
                                <p>
                                {t('terms_details54')}
                                </p>
                                <p>
                                {t('terms_details55')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head14')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details56')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head15')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details57')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head16')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details58')}
                                </p>
                                <p>
                                {t('terms_details59')}
                                </p>
                                <p>
                                    {t('terms_details_new3')}
                                </p>
                                <p>
                                {t('terms_details60')}
                                </p>
                                <p>
                                {t('terms_details61')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head17')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details62')}
                                </p>
                                <p>
                                {t('terms_details63')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head18')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details64')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head19')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details65')}
                                </p>
                                <p>
                                {t('terms_details66')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head20')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details67')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head21')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details68')}
                                </p>
                                <p>
                                {t('terms_details69')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head22')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details70')}
                                </p>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                                <h2 className="mb-0">
                                {t('term_head23')}
                                </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 mt-5">
                                <p>
                                {t('terms_details71')}
                                </p>
                                <p>
                                {t('terms_details72')}
                                </p>
                                <p>
                                    {t('terms_details_new4')}
                                </p>
                                <p>
                                    {t('terms_details73')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}