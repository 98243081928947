import axios from 'axios'
import { APIs } from './endpoints'

export const getAllContract = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getAllContract.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getUserNotifications = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getUserNotifications.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const postReadNotifications = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.postReadNotifications.endPoint}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// * Same API is also used for PM Module
export const getApprovals = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getApprovals.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getIncidentList = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getIncidentList.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

//* Create Incident Request
export const createIncident = async (
  area,
  level,
  building_location,
  comments,
  image,
  category_id
) => {
  const data = new FormData()
  data.append('category_id', category_id)
  data.append('area', area)
  data.append('level', level)
  data.append('building_location', building_location)
  data.append('comments', comments)
  data.append('image[]', image)

  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createIncident.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// export const createIncident = async (
//   area,
//   level,
//   building_location,
//   comments,
//   image,
//   category_id
// ) => {
//   try {
//     const response = await axios.post(`${APIs.URL + '/' + APIs.createIncident.endPoint}`, {
//       data.append('area', area),
//       area,
//       level,
//       building_location,
//       comments,
//       data.append('image[]', image)
//       category_id
//     })
//     return response.data
//   } catch (err) {
//     // console.log('err -->', err)
//     return err.response.status
//   }
// }

// Broker Admin Recent Invoices 
export const getInvoiceList = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getInvoiceList.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// Broker Admin Add Invoice
export const addBrokerInvoice = async (
  property_id,
  contract_id,
  broker_id,
  invoice_file,
  supporting_document,
  comment
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('contract_id', contract_id)
  data.append('broker_id', broker_id)
  data.append('invoice_file', invoice_file)
  data.append('supporting_document', supporting_document)
  data.append('comment', comment)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.addBrokerInvoice.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getPaymentHistory = async (property_id, start_date = '', end_date = '', pageSize = 10) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getPaymentHistory.endPoint
      }?start_date=${start_date}&end_date=${end_date}&pageSize=${pageSize}`,
      {
        params: {
          property_id,
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getPaymentHistoryByParams = async (
  property_id,
  payment_category = '',
  payment_for = '',
  is_paid = '',
  start_date = '',
  end_date = '',
  pageSize = 10
) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getPaymentHistory.endPoint
      }?start_date=${start_date}&end_date=${end_date}&pageSize=${pageSize}&payment_category=${payment_category}
      &payment_for=${payment_for}&is_paid=${is_paid}`,
      {
        params: {
          property_id,
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const generateStatementRequest = async (
  property_id,
  from_date = '',
  to_date = '',
  category,
  send_email
) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.generateStatementRequest.endPoint}`,
      {
        property_id,
        from_date,
        to_date,
        category,
        send_email,
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getMaintenanceRequestsCategories = async () => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMaintenanceRequestsCategories.endPoint}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getMyUnitDocuments = async () => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMyUnitDocuments.endPoint}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getAvailableTimings = async (property_id, date, timing_for) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getAvailableTimings.endPoint
      }?property_id=${property_id}&date=${date}&timing_for=${timing_for}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getServiceRequestsCategories = async (tenant_type, currentContract, userDetail) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getServiceRequestsCategories.endPoint + '?tenant_type=' + tenant_type + '&user_id=' + userDetail?.id + '&property_id=' + currentContract?.property_id + '&contract_id=' + currentContract?.id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getMarketPlaceList = async () => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.getAllMarketPlace.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getMarketPlaceFilteredList = async (data) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.getAllMarketPlace.endPoint}`, data)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getMarketPlace = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMarketPlace.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getMarketPlaceFiltered = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMarketPlace.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getMarketPlaceDetails = async (id) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMarketPlaceDetails.endPoint + '/' + id}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const redeemMarketPlaceOffer = async (id, data) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.redeemMarketPlaceOffer.endPoint + '/' + id}`, data)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getPreciseLocations = async (property_id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getPreciseLocations.endPoint}?property_id=${property_id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getPreciseLocationsCommon = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getPreciseLocationsCommon.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getServiceRequestByCategoryId = async (category_id, status = '') => {
  try {
    let response = ''
    if (status) {
      response = await axios.get(
        `${APIs.URL + '/' + APIs.getServiceRequestByCategoryId.endPoint
        }?category_id=${category_id}&status=${status}`
      )
    } else {
      response = await axios.get(
        `${APIs.URL + '/' + APIs.getServiceRequestByCategoryId.endPoint}?category_id=${category_id}`
      )
    }
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getServiceRequestByData = async (category_id, category_type_id, property_id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getServiceRequestByCategoryId.endPoint
      }?category_id=${category_id}&category_type_id=${category_type_id}&property_id=${property_id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getStepsStatus = async (property_id, contract_id, type = 0) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getStepsStatus.endPoint
      }?property_id=${property_id}&contract_id=${contract_id}&type=${type}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getInspectionIssues = async () => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getInspectionIssues.endPoint}?category_id=5`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getServiceRequestDetails = async (request_id, category_id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getServiceRequestDetails.endPoint
      }?id=${request_id}&category_id=${category_id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const deleteInspectionIssue = async (issue_id) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.deleteInspectionIssue.endPoint}`, { sr_id: issue_id }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getNationalities = async () => {
  try {
    const response = await axios.get(APIs.URL + '/' + APIs.getNationalities.endPoint)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getMarketPlaceTopOffers = async () => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.getAllMarketPlace.endPoint}`,
      {},
      {
        body: {
          top_offer: 1,
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getAnnouncements = async (payload = '') => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getAnnouncements.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getFCSAnnouncements = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getAnnouncements.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getMaintenanceIssues = async (status, property_id = '') => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMaintenanceIssues.endPoint}`, {
      params: {
        status,
        property_id,
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getMaintenanceIssueDetails = async (id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMaintenanceIssueDetails.endPoint + '/' + id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getServicesIssues = async (status) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getServicesIssues.endPoint}`, {
      params: {
        status,
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const facilityIncidentReslovedStatusUpdate = async (id, payload) => {
  try {
    const response = await axios.put(`${APIs.URL + '/' + APIs.facilityIncidentReslovedStatusUpdate.endPoint + id}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getRenewalNotice = async () => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getRenewalNotice.endPoint}?category_id=18`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const redeemOfferrequest = async (offer_id) => {
  // console.log(offer_id)
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.redeemOfferrequest.endPoint}`, {
      id: offer_id,
      is_redeemed: 1,
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const renewContractRequest = async (
  contract_id,
  mode_payment,
  customer_name,
  no_payments
) => {
  const data = new FormData()
  data.append('contract_id', contract_id)
  data.append('mode_payment', mode_payment)
  data.append('customer_name', customer_name)
  data.append('installment', no_payments)
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.renewContract.endPoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getContractActivity = async (contract_id, activity_type) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.contractActivity.endPoint
      }/${contract_id}?activity_type=${activity_type}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const updateContractActivity = async (data) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.updateContractActivity.endPoint}`, data)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getMasterData = async (status) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMasterData.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getConfig = async (status) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getConfig.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getAllotedParkingSlots = async (property_id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getAllotedParkingSlots.endPoint}?property_id=${property_id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getAllotedAccessCards = async (property_id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getAllotedAccessCards.endPoint}?property_id=${property_id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityParkingSlots = async (building_id = 1) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getFacilityParkingSlots.endPoint}?building_id=${building_id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityUserList = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityUserList.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getFacilityUserData = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityUserList.endPoint + payload}`)
    return response
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// export const getFacilityUserList = async (fetchType = 'FC') => {
//   try {
//     const response = await axios.get(
//       // `${APIs.URL + '/' + APIs.getFacilityUserList.endPoint}?user_role=${fetchType}`
//       `${APIs.URL + '/' + APIs.getFacilityUserList.endPoint}`
//     )
//     return response.data
//   } catch (err) {
//     // console.log('err -->', err)
//     return err.response.status
//   }
// }

export const getDirectoryUserList = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityUserList.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityBuildingList = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityBuildingList.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityBuildingContractList = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityBuildingContractList.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityDocumentList = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityDocumentList.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityVisitorsTypeList = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityVisitorsTypeList.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityUserType = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityUserType.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getIncidentLists = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getIncidentLists.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getCommonArea = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getCommonArea.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityDocuments = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityDocuments.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// export const getFacilityDocuments = async () => {
//   try {
//     const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityDocuments.endPoint}`)
//     return response.data
//   } catch (err) {
//     // console.log('err -->', err)
//     return err.response.status
//   }
// }

export const getFacilityAccessCards = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityAccessCards.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getAllotedAccessId = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getAllotedAccessId.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}


export const getFacilityVisitors = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityVisitors.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}



// export const getFacilityVisitors = async () => {
//   try {
//     const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityVisitors.endPoint}`)
//     return response.data
//   } catch (err) {
//     // console.log('err -->', err)
//     return err.response.status
//   }
// }

// export const getFacilityScheduledVisits = async (status = '') => {
//   try {
//     const response = await axios.get(
//       `${APIs.URL + '/' + APIs.getFacilityScheduledVisits.endPoint}?status=${status}`
//     )
//     return response.data
//   } catch (err) {
//     // console.log('err -->', err)
//     return err.response.status
//   }
// }


export const getFacilityScheduledVisits = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityScheduledVisits.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// export const getFacilityMaintenanceRequests = async (status) => {
//   try {
//     const response = await axios.get(
//       `${APIs.URL + '/' + APIs.getFacilityMaintenanceRequests.endPoint}?status=${status}`
//     )
//     return response.data
//   } catch (err) {
//     // console.log('err -->', err)
//     return err.response.status
//   }
// }

export const getFacilityMaintenanceRequests = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityMaintenanceRequests.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityWorkOrders = async () => {
  try {
    const response = await axios.get(APIs.URL + '/' + APIs.getFacilityWorkOrders.endPoint)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getWorkOrdersList = async (payload) => {
  try {
    const response = await axios.get(APIs.URL + '/' + APIs.getFacilityWorkOrders.endPoint + payload)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
export const getFacilityWorkOrderDetails = async (id) => {
  try {
    const response = await axios.get(APIs.URL + '/' + APIs.getFacilityWorkOrders.endPoint + '?id=' + id)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getFacilityCompanyData = async (id) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFacilityCompanyData.endPoint + '?user_id=' + id}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getAccessCardsView = async (id) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getAccessCardsView.endPoint + '?approval_id=' + id}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const saveAccessCard = async (
  property_id,
  user_id,
  allotted_access_id,
  card_number,
  signature,
  flag,
  approval_id
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('user_id', user_id)
  data.append('allotted_access_id', allotted_access_id)
  data.append('flag', flag)
  data.append('approval_id', approval_id)
  card_number.forEach((element) => {
    data.append('card_number[]', element)
  })
  data.append('signature', signature)
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.saveAccessCard.endPoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const saveApprovalsData = async (
  property_id,
  user_id,
  allotted_access_id,
  signature,
  flag,
  approval_id
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('user_id', user_id)
  data.append('allotted_access_id', allotted_access_id)
  data.append('flag', flag)
  data.append('approval_id', approval_id)
  data.append('signature', signature, 'signature.png')
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.saveApprovalsData.endPoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const saveApprovalsDataFMSM = async (
  property_id,
  user_id,
  allotted_access_id,
  signature,
  flag,
  approval_id,
  card_data,
  card_type
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('user_id', user_id)
  data.append('allotted_access_id', allotted_access_id)
  data.append('flag', flag)
  data.append('approval_id', approval_id)
  if (signature && signature != '')
    data.append('staff_signature', signature, 'signature.png')
  card_data.forEach((el) => {
    data.append(`card_number[${card_type}][]`, el)
  })
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.saveApprovalsData.endPoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const saveAccessData = async (
  property_id,
  user_id,
  allotted_access_id,
  signature,
  flag,
  access_card_id
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('user_id', user_id)
  data.append('allotted_access_id', allotted_access_id,)
  data.append('flag', flag)
  data.append('access_card_id', access_card_id)
  data.append('signature', signature, 'signature.png')
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.saveAccessData.endPoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    console.log('err -->', err)
    return err.response.status
  }
}

export const saveAccessDataFMSM = async (
  property_id,
  user_id,
  allotted_access_id,
  signature,
  flag,
  access_card_id,
  is_card_data_added,
  card_data,
  card_type
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('user_id', user_id)
  data.append('allotted_access_id', allotted_access_id,)
  data.append('flag', flag)
  data.append('access_card_id', access_card_id)
  data.append('is_card_data_added', is_card_data_added)
  if (signature && signature != '')
    data.append('staff_signature', signature, 'signature.png')
  card_data.forEach((el) => {
    data.append(`card_number[${card_type}][]`, el)
  })
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.saveAccessData.endPoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    console.log('err -->', err)
    return err.response.status
  }
}

export const submitMoveInApproval = async (approval_id, alloted_card_id) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.submitMoveInApproval.endPoint}`, {
      approval_id,
      alloted_card_id
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const submitFmsmHandoverToFcss = async (access_card_id, property_id, user_id) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.submitFmsmHandoverToFcss.endPoint}`, {
      access_card_id,
      property_id,
      user_id
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const submitAccessCardHandle = async (access_card_id, alloted_card_id) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.submitAccessCardHandle.endPoint}`, {
      access_card_id,
      alloted_card_id
    })
    return response.data
  } catch (err) {
    console.log('err -->', err)
    return err.response.status
  }
}

export const submitDocumentStatus = async (document_id) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.submitDocumentStatus.endPoint}`, {
      document_id,
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const submitMoveOutApproval = async (approval_id, alloted_card_id) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.submitMoveOutApproval.endPoint}`, {
      approval_id,
      alloted_card_id
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const moveInInspectionRequest = async (data) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.moveInInspectionRequest.endPoint}`,
      data
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const scheduleMeetingRequest = async (
  property_id,
  user_unique_id,
  start_time,
  end_time,
  visit_date,
  title,
  customer_type,
  customer_name,
  email,
  mobile,
  organization_name,
  schedule_type,
  comments,
  category_id
) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.scheduleMeetingRequest.endPoint}`, {
      property_id,
      user_unique_id,
      start_time,
      end_time,
      visit_date,
      title,
      customer_type,
      customer_name,
      email,
      mobile,
      organization_name,
      schedule_type,
      comments,
      category_id
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const getOfferDetails = async (offer_id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getOfferDetails.endPoint + '/' + offer_id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const sendCardPaymentRequest = async (
  offer_id,
  object_id = '',
  property_id,
  amount,
  payment_category,
  payment_for,
  payment_type = '',
  upcoming_payment_id = '',
  upcoming_payment_plan_id = '',
  work_order_id = '',
) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.sendPaymentRequest.endPoint}`, {
      offer_id,
      object_id,
      property_id,
      amount,
      payment_category,
      payment_for,
      payment_type,
      upcoming_payment_id,
      upcoming_payment_plan_id,
      work_order_id
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const sendBankTransferRequest = async (
  offer_id,
  payment_for,
  property_id,
  beneficiary_name,
  beneficiary_account,
  iban_no,
  beneficiary_bank,
  swift_code,
  branch_address,
  payment_type = '',
  upcoming_payment_id = ''
) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.sendBankTransferRequest.endPoint}`, {
      offer_id,
      payment_for,
      property_id,
      beneficiary_name,
      beneficiary_account,
      iban_no,
      beneficiary_bank,
      swift_code,
      branch_address,
      payment_type,
      upcoming_payment_id
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const sendChequePaymentRequest = async (
  offer_id,
  payment_for,
  payment_category = '',
  property_id,
  cheque_number,
  payment_date,
  acc_holder_name,
  acc_number,
  amount,
  bank,
  cheque_img,
  payment_type,
  upcoming_payment_id,
  upcoming_payment_plan_id
) => {
  const data = new FormData()
  data.append('offer_id', offer_id)
  data.append('payment_for', payment_for)
  data.append('payment_category', payment_category)
  data.append('property_id', property_id);
  data.append('cheque_number', cheque_number)
  data.append('payment_date', payment_date)
  data.append('acc_holder_name', acc_holder_name)
  data.append('acc_number', acc_number)
  data.append('amount', amount)
  data.append('bank', bank)
  data.append('cheque_img', cheque_img)
  data.append('payment_type', payment_type);
  data.append('upcoming_payment_id', upcoming_payment_id);
  data.append('upcoming_payment_plan_id', upcoming_payment_plan_id);
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.sendChequePaymentRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// get amenities by property_id
export const getPropertyAmenities = async (property_id) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getPropertyAmenities.endPoint}?property_id=${property_id}`)
    return response.data
  } catch (err) {
    return err.response.status
  }
}

export const createAmenityBooking = async (
  amenity_type_id,
  property_id,
  date,
  time,
  category_id = 20
) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.createAmenityBooking.endPoint}`, {
      amenity_type_id,
      property_id,
      date,
      time,
      category_id,
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const createChequeDelayRequest = async (
  offer_cheque_id,
  desc,
  reason,
  type,
  property_id
) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createChequeDelayRequest.endPoint}`,
      {
        offer_cheque_id,
        desc,
        reason,
        type,
        property_id,
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const createChequeReplacementRequest = async (
  offer_cheque_id,
  date,
  cheque_number,
  acc_holder_name,
  acc_number,
  amount,
  bank,
  desc,
  reason,
  type,
  cheque_img,
  property_id
) => {
  const data = new FormData()
  data.append('offer_cheque_id', offer_cheque_id)
  data.append('date', date)
  data.append('cheque_number', cheque_number)
  data.append('acc_holder_name', acc_holder_name)
  data.append('acc_number', acc_number)
  data.append('amount', amount)
  data.append('bank', bank)
  data.append('desc', desc)
  data.append('reason', reason)
  data.append('type', type)
  data.append('property_id', property_id)
  // cheque_img.forEach((element) => {
  data.append('cheque_img', cheque_img)
  // })
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createChequeReplacementRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const createFacilityDocument = async (
  request_type,
  building_id,
  property_id,
  comments,
  photo
) => {
  const data = new FormData()
  data.append('request_type', request_type)
  data.append('building_id', building_id)
  data.append('property_id', property_id)
  data.append('comments', comments)
  data.append('photo', photo)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createFacilityDocument.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const addFacilityVisitor = async (
  building_id,
  property_id,
  first_name,
  last_name,
  visit_purpose,
  company,
  emirates_id,
  mobile_no,
  email,
  visitor_type,
  check_in
) => {
  const data = new FormData()
  data.append('building_id', building_id)
  data.append('property_id', property_id)
  data.append('first_name', first_name)
  data.append('last_name', last_name)
  data.append('purpose_of_visit', visit_purpose)
  data.append('company', company)
  data.append('emirates_id', emirates_id)
  data.append('mobile_no', mobile_no)
  data.append('email', email)
  data.append('visitor_type', visitor_type)
  data.append('check_in', check_in)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.addFacilityVisitor.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const createVisitorInOutTime = async (
  status,
  visitor_id,
  action_date_time
) => {
  const data = new FormData()
  data.append('status', status)
  data.append('visitor_id', visitor_id)
  data.append('action_date_time', action_date_time)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createVisitorInOutTime.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const createFacilityAnnouncement = async (
  title,
  category_id,
  date,
  start_time,
  end_time,
  description,
  media,
  type
) => {
  const data = new FormData()
  data.append('title', title)
  data.append('category_id', category_id)
  data.append('date', date)
  data.append('start_time', start_time)
  data.append('end_time', end_time)
  data.append('announcement', description)
  console.log('media', media)
  media.forEach((element) => {
    data.append('media', element)
  })
  data.append('type', type)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createFacilityAnnouncement.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const createReportRequest = async (
  property_id,
  slot_id,
  issue_type,
  outcome,
  description,
  image
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('slot_id', slot_id)
  data.append('issue_type', issue_type)
  data.append('outcome', outcome)
  data.append('description', description)
  data.append('image[]', image)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createReportRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const createMaintenanceRequest = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createMaintenanceRequest.endPoint}`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// export const rescheduleMaintenanceRequest = async (date, time, id) => {
//   try {
//     const response = await axios.post(
//       `${APIs.URL + '/' + APIs.rescheduleMaintenanceRequest.endPoint}`,
//       {
//         id,
//         date,
//         time,
//       }
//     )
//     return response.data
//   } catch (err) {
//     // console.log('err -->', err)
//     return err.response.status
//   }
// }
export const rescheduleMaintenanceRequest = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.rescheduleMaintenanceRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const createMoveInAccessCardsRequest = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createMoveInAccessCardsRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const createMoveInInspectionIssue = async (data) => {

  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createMoveInInspectionIssue.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const updateMoveInInspectionIssue = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.updateMoveInInspectionIssue.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const moveOutInspectionRequest = async (
  category_id,
  category_type_id,
  property_id,
  date,
  time_slot,
  comments
) => {
  const data = new FormData()
  data.append('category_id', category_id)
  data.append('category_type_id', category_type_id)
  data.append('property_id', property_id)
  data.append('date', date)
  data.append('time_slot', time_slot)
  data.append('comments', comments)

  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.moveOutInspectionRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const disputeChargesRequest = async (data) => {

  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.disputeChargesRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const moveOutApprovalRequest = async (
  category_id,
  property_id,
  addc_clearance_date,
  comments,
  photo
) => {
  const data = new FormData()
  data.append('category_id', category_id)
  data.append('property_id', property_id)
  data.append('addc_clearance_date', addc_clearance_date)
  data.append('comments', comments)
  data.append('photo', photo)

  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.moveOutApprovalRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const refundRequest = async (
  category_id,
  property_id,
  refund_to,
  name,
  bank,
  account_number,
  iban_number,
  confirm_iban_number,
  swift_code
) => {
  const data = new FormData()
  data.append('category_id', category_id)
  data.append('property_id', property_id)
  data.append('refund_to', refund_to)
  data.append('name', name)
  data.append('bank', bank)
  data.append('account_number', account_number)
  data.append('iban_number', iban_number)
  data.append('confirm_iban_number', confirm_iban_number)
  data.append('swift_code', swift_code)

  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.refundRequest.endPoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const uploadADDCLetterRequest = async (category_id, property_id, photo) => {
  const data = new FormData()
  data.append('category_id', category_id)
  data.append('property_id', property_id)
  data.append('photo[]', photo)

  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.uploadADDCLetterRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const accessCardRequest = async (
  category_id,
  category_type_id,
  no_of_cards,
  property_id
) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.accessCardRequest.endPoint}`, {
      category_id,
      category_type_id,
      no_of_cards,
      property_id,
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const parkingIssueRequest = async (
  property_id,
  category_id,
  category_type_id,
  desc,
  photo
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('desc', desc)
  data.append('category_id', category_id)
  data.append('category_type_id', category_type_id)
  data.append('photo[]', photo)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.parkingIssueRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const earlyTerminationRequest = async (
  contract_id,
  property_id,
  category_id,
  contract_start_date,
  contract_end_date,
  annual_rent,
  early_termination_date,
  early_termination_reason,
  early_termination_fees,
  terms,
  photo
) => {
  const data = new FormData()
  data.append('contract_id', contract_id)
  data.append('property_id', property_id)
  data.append('category_id', category_id)
  data.append('contract_start_date', contract_start_date)
  data.append('contract_end_date', contract_end_date)
  data.append('annual_rent', annual_rent)
  data.append('early_termination_date', early_termination_date)
  data.append('early_termination_reason', early_termination_reason)
  data.append('early_termination_fees', early_termination_fees)
  data.append('terms', terms)
  data.append('photo[]', photo)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.earlyTerminationRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const undertakingLetterRequest = async (
  property_id,
  category_id,
  category_type_id,
  desc,
  signature,
  photo
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('category_id', category_id)
  data.append('category_type_id', category_type_id)
  data.append('desc', desc)
  data.append('signature', signature, 'signature.png')
  data.append('photo', photo)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.undertakingLetterRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const renewalNoteRequest = async (
  property_id,
  category_id,
  category_type_id,
  signature,
  photo
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('category_id', category_id)
  data.append('category_type_id', category_type_id)
  data.append('signature', signature, 'signature.png')
  data.append('photo', photo)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.renewalNoteRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const extendContractRequest = async (
  contract_id,
  mode_payment,
  installment,
  extend_period,
  rent_amount
) => {
  const data = new FormData()
  data.append('contract_id', contract_id)
  data.append('mode_payment', mode_payment)
  data.append('installment', installment)
  data.append('extend_period', extend_period)
  data.append('rent_amount', rent_amount)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.extendContractRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const vacateRequest = async (data) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.vacateRequest.endPoint}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const rentReductionRequest = async (
  contract_id,
  property_id,
  customer_name,
  mode_payment,
  installment,
  offer_request_price,
  start_date,
  end_date
) => {
  const data = new FormData()
  data.append('contract_id', contract_id)
  data.append('property_id', property_id)
  data.append('customer_name', customer_name)
  data.append('mode_payment', mode_payment)
  data.append('installment', installment)
  data.append('offer_request_price', offer_request_price)
  data.append('start_date', start_date)
  data.append('end_date', end_date)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.rentReductionRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const generalPTWRequest = async (data) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.generalPTWRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const generalPTWSafetyPrecautions = async (service_req_id, safety_prec) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.generalPTWSafetyPrecautionsRequest.endPoint}`,
      {
        service_req_id,
        safety_prec,
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const healthClubRegistrationRequest = async (
  category_id,
  property_id,
  fullName,
  dob,
  nationality,
  spouseName,
  childName,
  po_box,
  telNumber,
  mobile,
  email,
  companyName,
  emirates_id_front,
  emirates_id_back,
  passport_front,
  passport_back,
  photo,
  spousePhoto,
  childPhoto
) => {
  const data = new FormData()
  data.append('category_id', category_id)
  data.append('property_id', property_id)
  data.append('fullName', fullName)
  data.append('dob', dob)
  data.append('nationality', nationality)
  data.append('spouseName', spouseName)
  data.append('childName', childName)
  data.append('po_box', po_box)
  data.append('telNumber', telNumber)
  data.append('mobile', mobile)
  data.append('email', email)
  data.append('companyName', companyName)
  data.append('emiratesId_front', emirates_id_front)
  data.append('emiratesId_back', emirates_id_back)
  data.append('passport_front', passport_front)
  data.append('passport_back', passport_back)
  data.append('photo', photo)
  data.append('SpousePhoto', spousePhoto)
  data.append('childPhoto[]', childPhoto)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.healthClubRegistrationRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

export const vehicleRegistrationRequest = async (
  property_id,
  category_id,
  parking_slot,
  brand,
  model,
  color,
  regno,
  signature,
  user_id
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('category_id', category_id)
  data.append('parking_slot', parking_slot)
  data.append('brand', brand)
  data.append('model', model)
  data.append('color', color)
  data.append('regno', regno)
  data.append('signature', signature, 'signature.png')
  data.append('photo', '')
  data.append('user_id', user_id)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.vehicleRegistrationRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** FC - Submit Clearance Charges
export const createClearanceCharge = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createClearanceCharge.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** FC - Update Clearance Charges
export const updateClearanceCharge = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.updateClearanceCharge.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** Finance - Service Request
export const getFinanceServiceRequestList = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFinanceServiceRequestList.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** FCS - My Team list
export const getActivitiesList = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getActivitiesList.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** FCS - My Team list
export const getMyTeamUserList = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMyTeamUserList.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** FCS - My Team Chart
export const getMyTeamChartData = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMyTeamChartData.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** FCS - WorkOrder Checklist View
export const getWorkOrderChecklist = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getWorkOrderChecklist.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** FCS - WorkOrder Comments
export const getWorkOrderComments = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getWorkOrderComments.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** FCS - WorkOrder Comments add
export const postWorkOrderComments = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.postWorkOrderComments.endPoint}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json'
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** FC - WorkOrder Re-Schedule 
export const postWorkOrderReschedule = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.postWorkOrderReschedule.endPoint}`,
      payload,
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** FC - WorkOrder Complete Corrective/Submit Checklist
export const postWorkOrderChecklist = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.postWorkOrderChecklist.endPoint}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json'
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// FM Team - Get Company List
export const getCompanyList = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getCompanyList.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// Get community list
export const getCommunities = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getCommunities.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// FM Team - Get User Company & Building List
export const getFmCommunityUser = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getFmCommunityUser.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* FM module - create team member
export const createContractorUser = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.createTeamMember.endPoint}`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** MTA - Get Team Member Details **
export const getTeamMemberDetails = async (user_id) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getTeamMemberDetails.endPoint + '/' + user_id}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** MTA - Get All Properties **
export const getMarketingTeamProperties = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMarketingTeamProperties.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** MTA - Get Promotions **
export const getMarketingTeamPromotions = async (status = '') => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMarketingTeamPromotions.endPoint + '?status=' + status}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Submit Key Type Details **
export const keyTypeDetails = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.keyTypeDetails.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Submit Fit-out NOC **
export const fitOutDesignRequest = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.fitOutDesignRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Submit Fit-out Commencement Approval **
export const fitOutCommencementApprovalRequest = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.fitOutCommencementApprovalRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Submit Move-in Authority Approval **
export const moveInAuthorityApproval = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.moveInAuthorityApproval.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Permit To Work - General PTW **
export const generalPermitToWork = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.generalPermitToWork.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Permit To Work - Helipad & Rooftop Access **
export const helipadRooftopAccessPermit = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.helipadRooftopAccessPermit.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Permit To Work - Fire Protection Impairment **
export const fireProtectionImpairmentPermit = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.fireProtectionImpairmentPermit.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Get Final Completion Certificate Questions **
export const finalCompletionCertificateQuestions = async (data) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.finalCompletionCertificateQuestions.endPoint}`
    )
    return response.data
  } catch (err) {
    console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Final Completion Certificate **
export const finalCompletionCertificate = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.finalCompletionCertificate.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    console.log('err -->', err)
    return err.response.status
  }
}

// ** Commercial Tenant - Final Completion Certificate **
export const getMyTeam = async (data) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMyTeam.endPoint}`)
    return response.data
  } catch (err) {
    console.log('err -->', err)
    return err.response.status
  }
}

// ** Extend Offer Request **

export const extendOfferRequest = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.extendOfferRequest.endPoint}`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** Bank Transfer Request **

export const bankTransfer = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.bankTransfer.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  } catch (err) {
    // console.log('err -->', err)
    return err.response
  }
}
// ** Pay By Cheque Request **

export const payByCheque = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.payByCheque.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** Create Contract Request **

export const createContract = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.createContract.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  } catch (err) {
    // console.log('err -->', err)
    return err.response
  }
}

// ** Create Contract Broker Request **

export const createBrokerContract = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.createBrokerContract.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  } catch (err) {
    // console.log('err -->', err)
    return err.response
  }
}
// ** Profile Update Request **

export const profileUpdate = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.profileUpdate.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** To get create Alert data **

export const getPropertyFilterData = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getPropertyFilterData.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** Add Alert data **

export const addAlerts = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.addAlerts.endPoint}`, payload)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** Get Alert data **

export const getAlerts = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getAlerts.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** Remove Alert data **

export const removeAlerts = async (alert_id) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.removeAlerts.endPoint}`, alert_id)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** Unsubscribe Alert data **

export const unSubscribeAlerts = async (alert_id) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.unSubscribeAlerts.endPoint}`,
      alert_id
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** Subscribe Alert data **

export const subscribeAlerts = async (alert_id) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.subscribeAlerts.endPoint}`, alert_id)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// **  Get all uploaded documents **

export const getMyDocs = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMyDocs.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// **  Get all uploaded documents **

export const uploadMyDocs = async (docs) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.uploadMyDocs.endPoint}`, docs)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// **  Delete all uploaded documents **

export const deleteMyDocs = async (docId) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.deleteMyDocs.endPoint}`, { doc_id: docId }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// **  Like and unlike property **

export const myLikes = async (likeData) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.myLikes.endPoint}`, likeData)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//*  property visit review

export const rateUs = async (Reviewdata) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.rateUs.endPoint}`, Reviewdata)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

//* Upload Post data cheques
export const uploadPDCs = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.uploadPDCs.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    return err.response.status
  }
}
//* Upload Renewal Post data cheques
export const uploadRenewalPDCs = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.uploadRenewalPDCs.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    return err.response.status
  }
}
//* Check emirates already exists
export const checkEmirates = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.checkEmirates.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    return err.response.status
  }
}
// ** Finance APIs **
// ** Reports **
export const geFinanceReports = async (payload) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.geFinanceReports.endPoint}` + '?' + payload
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
// ** Receipts **
export const geFinanceReceipts = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.geFinanceReceipts.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Get All Schedule List for selected date and property id
export const getVisitScheduleList = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.getVisitScheduleList.endPoint}`,
      payload.data
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Post Reschedule List for selected date and property id
export const postRescheduleVisit = async (payload) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.postRescheduleVisit.endPoint}`,
      payload.data
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Cancel schedule by id
export const cancelScheduleVisit = async (visitId) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.cancelScheduleVisit.endPoint}`, { visit_id: visitId }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get lease list
export const getLeaseList = async (filter) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getLeaseList.endPoint + filter}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get receipt list
export const getReceiptList = async (filter) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getReceiptList.endPoint + filter}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get lease details
export const getLeaseDetail = async (id) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getLeaseDetail.endPoint + '/' + id}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get receipt details
export const getReceiptDetail = async (id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getReceiptDetail.endPoint + '/' + id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get refunds list
export const getRefundList = async (filter) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getRefundData.endPoint + '?status=' + filter}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get refunds details
export const getRefundDetail = async (id) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getRefundData.endPoint + '/' + id}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get moveout list
export const getMoveoutList = async () => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getMoveoutList.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get moveout detail (main & lease)
export const getMoveoutDetail = async (id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMoveoutDetail.endPoint + '/' + id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get moveout detail (payments)
export const getMoveoutPayments = async (id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMoveoutPayments.endPoint + '/' + id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

//* Finance module - get moveout detail (documents)
export const getMoveoutDocuments = async (id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMoveoutDocuments.endPoint + '/' + id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - get payment detail
export const getPaymentDetails = async (id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getPaymentDetails.endPoint + '/' + id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

//* Finance module - get activity detail
export const getActivityDetails = async (id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getActivityDetails.endPoint + '/' + id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* Finance module - Update/CLose activity
export const closeActivity = async (data) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.closeActivity.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** Property Managers(PM) APIs **
//* PM module - get approvals list
export const getPMapprovalList = async (filters) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getPMapprovalList.endPoint + '?' + filters}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* PM module - get myTeam and chart data
export const getPMmyTeam = async (roleType, currentPage, pageSize, searchItem) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getPMmyTeam.endPoint}?user_role=${roleType}&currentPage=${currentPage}&pageSize=${pageSize}&search_key=${searchItem}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* PM module - create team member
export const createTeamMember = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.createTeamMember.endPoint}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

//*Get One Contract
export const getMyContract = async (id) => {
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.getMyContract.endPoint + '/' + id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//*signature
export const addSignature = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.addSignature.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//*Approve Contract, Service Request, Maintenance Request etc
export const approveTheRequests = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.approveTheRequests.endPoint}`, payload)
    return response.data
  } catch (err) {
    return handleErrors(err)//err.response.status
  }
}
//*Offers and Renewals
export const getOffersAndRenewals = async (type, currentPage, pageSize, currentBuilding) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getOffersAndRenewals.endPoint}/${type}/lists?currentPage=${currentPage}&pageSize=${pageSize}&building_id=${currentBuilding}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//*Offers and Renewals Details
export const getOffersAndRenewalsDetails = async (id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getOffersAndRenewalsDetails.endPoint}/${id}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//*Get Rtefunds List
export const getRefundsList = async (type, currentPage, pageSize) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getRefundsList.endPoint}?status=${type}&currentPage=${currentPage}&pageSize=${pageSize}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//*Tenant Search
export const tenantSearch = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.tenantSearch.endPoint + '/' + payload}`)
    return response.data
  } catch (err) {
    return handleErrors(err)//err.response.status
  }
}
//*Create new user(PT)
export const createTenant = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.createTenant.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//*Get AddOns List
export const getAddOns = async () => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getAddOns.endPoint}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//*Make an offer
export const makeAnoffer = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.makeAnoffer.endPoint}`, payload)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//*Get Dynamic card for dashboard
export const getActions = async (id) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getActions.endPoint + '?contract_id=' + id}`
    )
    return response
  } catch (err) {
    // console.log('err -->', err)
    return err.response
  }
}
//* PM - get my team details
export const getMyTeamDeatils = async (qryParams) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMyTeamDeatils.endPoint + '?' + qryParams}`
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

//* PM module - Assign Property to team member
export const assignMember = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.assignMember.endPoint}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* PM module - All Schedule List 
export const allScheduleList = async (currentBuilding) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.allScheduleList.endPoint + `?building_id=${currentBuilding}`}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* PM module - Dashboard Chart Data
export const myDashboard = async (currentBuilding) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.myDashboard.endPoint}`)
    return response
  } catch (err) {
    // console.log('err -->', err)
    return err.response
  }
}
//*  Chat: Get all chats based on the current user
export const getMessageForUser = async () => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMessageForUser.endPoint}`
    )
    return response
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

// ** Bank Transfer Request **

export const sendMessages = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.sendMessages.endPoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  } catch (err) {
    // console.log('err -->', err)
    return err.response
  }
}

// ** general Service Request **

export const generalServiceRequest = async (
  property_id,
  category_id,
  category_type_id,
  desc,
  photo
) => {
  const data = new FormData()
  data.append('property_id', property_id)
  data.append('desc', desc)
  data.append('category_id', category_id)
  data.append('category_type_id', category_type_id)
  data.append('photo[]', photo)
  try {
    const response = await axios.post(
      `${APIs.URL + '/' + APIs.generalServiceRequest.endPoint}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',

        },
      }
    )
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status

  }

}


//* Broker My Team
export const getBrokerMyTeam = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getBrokerMyTeam.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}

/**
 * Market Place APIs
 */
//* Market Place - MarketPlaceList
export const marketPlaceList = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.marketPlaceList.endPoint + payload}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* MT module - create market place voucher
export const createMarketingVoucher = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.createMarketingVoucher.endPoint}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* MT module - create market place voucher
export const createMarketingOffer = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.createMarketingOffer.endPoint}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* MT module - create market place voucher
export const createMarketingEvent = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.createMarketingEvent.endPoint}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* MT module - send market place offer
export const postSendOffer = async (payload) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.postSendOffer.endPoint}`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}
//* MT module - Get merchants
export const getMerchantList = async (payload) => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMerchantList.endPoint + payload}`)
    return response.data
  } catch (err) {
    return err.response.status
  }
}
//Approve/Reject/RMI (Can be used anywhere for approvals)
export const postApprovalSubmit = async (payload, approvalType) => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.postApprovalSubmit.endPoint + '/' + approvalType}`, payload)
    return response.data
  } catch (err) {
    return err.response.status
  }
}
//* MTA module - approval comment list
export const getApprovalCommentList = async (payload) => {
  try {
    const response = await axios.get(`${APIs.URL + '/' + APIs.getApprovalCommentList.endPoint + payload}`)
    return response.data
  } catch (err) {
    return err.response.status
  }
}


//* Get user menu/permissions API
export const getMenu = async () => {
  try {
    const response = await axios.get(
      `${APIs.URL + '/' + APIs.getMenu.endPoint}`)
    return response
  } catch (err) {
    return err.response
  }
}

//* Logout
export const logOut = async () => {
  try {
    const response = await axios.post(`${APIs.URL + '/' + APIs.logOut.endPoint}`)
    return response.data
  } catch (err) {
    // console.log('err -->', err)
    return err.response.status
  }
}




function handleErrors(err) {
  if (err.response) {
    return ("Problem with response", err.response.status);
  } else if (err.request) {
    return ("problem with request");
  } else {
    return ("error", err.message);
  }
}