import React, { useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import DatePicker from 'react-date-picker'
import { Time } from './Time'
import { useFormik } from 'formik'
import {
  createAmenityBooking,
  getAvailableTimings,
  getPropertyAmenities,
} from '../../../../constants/axios/apis'
import clsx from 'clsx'
import moment from 'moment'
import { NoDataFoundWithMsg } from '../../../../constants/generics/customActios'
import { useDispatch } from 'react-redux'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'

const today = new Date()
today.setHours(0, 0, 0, 0)

const amenityBookingSchema = Yup.object().shape({
  amenity_type_id: Yup.number().required('Please select an amenity'),
  date: Yup.date()
    .min(today, 'Selected date cannot be in the past')
    .required('Please select a date'),
  time: Yup.string().required('Please select a time slot'),
})

export function AmenityBooking({ contract }: any) {
  const [loading, setLoading] = useState(false)
  const [amenities, setAmenities] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const closeRef = useRef<any>()
  const ref = useRef<any>(0)
  const [timeSlots, setTimeSlots] = useState<any>()
  const [messages, setMessages] = useState<any>()

  const initialValues = {
    amenity_type_id: '',
    date: today,
    time: '',
    property_id: contract?.propertyID,
  }

  useEffect(() => {
    getPropertyAmenities(contract?.propertyID).then(response => {
      setAmenities(response.data)
    })
  }, [contract])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: amenityBookingSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        createAmenityBooking(
          values.amenity_type_id,
          values.property_id,
          moment(values.date).format('YYYY-MM-DD'),
          values.time
        )
          .then((response: any) => {
            setLoading(false)
            if (response != 500) {
              resetForm()
              closeRef.current.click()
              history.push('/my-services/openissues')
            } else {
              setMessages({
                error: 1,
                message: 'Something went wrong!',
              })
              ref.current.click()
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Invalid Data Provided')
          })
      }, 1000)
    },
  })

  const handleDate = (e: any) => {
    formik.setFieldValue('date', new Date(e))
    formik.setFieldValue('time', '')
    formik.setFieldTouched('time', false)
  }

  useEffect(() => {
    if (contract) {
      getAvailableTimings(
        contract.propertyID,
        moment(formik.values.date).format('YYYY-MM-DD'),
        1
      ).then(({ data }) => {
        setTimeSlots(data)
      })
    }
  }, [contract, formik.values.date])
  useEffect(() => {
    if (messages?.message?.length > 0) {
      dispatch(PTOffer.actions.modalmessage(messages))
    }
  }, [messages])
  if (amenities?.length <= 0) { return (<div><NoDataFoundWithMsg msg={'No Amenities'} /></div>) }
  const amenitiesList = amenities?.length > 0 && amenities?.map((amenity: any) => (
    <option key={amenity.id} value={amenity.id}>
      {amenity.name}
    </option>
  ))
  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <h4 className='card-title font-18 text-black mb-7'>
            <Link to={'/my-services/openissues'} className=''>
              <img src={LeftArrow} alt="Back" className='img-fluid me-5' />
            </Link>
            Amenity Booking
          </h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='card'>
              <div className='card-body px-8 py-6'>
                <div className='row form-add-design'>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>Category<span className='text-danger'>*</span></label>
                      <select
                        className='form-control form-select fw-bolder form-control-solid'
                        name='amenity_type_id'
                        onChange={formik.handleChange}
                        value={formik.values.amenity_type_id}
                        onBlur={formik.handleBlur}
                      >
                        <option value='' disabled>
                          Select Amenity
                        </option>
                        {amenities ? amenitiesList : ''}
                      </select>
                      {formik.touched.amenity_type_id && formik.errors.amenity_type_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.amenity_type_id}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4 col-12'>
                    <div className='mb-10 date-picker'>
                      <label className='form-label fw-bolder'>Date<span className='text-danger'>*</span></label>
                      <DatePicker
                        onChange={(e: any) => handleDate(e)}
                        value={formik.values.date}
                        minDate={new Date(new Date().toLocaleDateString())}
                        className={clsx(
                          'form-control fw-bolder form-control-solid',
                          {
                            'is-invalid': formik.touched.date && formik.errors.date,
                          },
                          {
                            'is-valid': formik.touched.date && !formik.errors.date,
                          }
                        )}
                        format='dd-MM-y'
                        clearIcon={null}
                      />
                      {formik.touched.date && formik.errors.date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.date}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 col-12'>
                    <Time timeSlots={timeSlots} formik={formik} />
                  </div>
                  <div className='col-md-12 col-12 text-end'>
                    <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading}>
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#amenity-booked-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p><p ref={ref} data-bs-target='#dynamic-popup' data-bs-toggle='modal' data-bs-dismiss='modal'>
        {/*  */}
      </p>
    </div>
  )
}
