import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ViewPopup } from '../../../pt-documents/components/ViewPopup/ViewPopup'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { uploadMyDocs } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'
import NoDataFoundImage from '../../../../assets/images/no-data-found.svg'
import PhDoc from '../../../../assets/images/Property-Handover-in-DOC.webp'


export function NeedAction({ needActionDocuments, getDocs }: any) {
  const [viewImage, setViewImage] = useState<any>()
  const [loading, setLoading] = useState<any>(false)
  const [uploadedEmiratesIdFrontName, setUploadedEmiratesIdFrontName] = useState<any>(null)
  const emiratesIdFrontInputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation();

  var emirates_front: any

  const initialValues = {
    emirates_front: '',
  }

  const documentVerficationSchema = Yup.object().shape({
      emirates_front: Yup.mixed()
      .test('type', t("Only the following formats are acceptable: .jpeg, .jpg .png .pdf"), (value) => {
        if (value === 0) return true
        return (
          value &&
          (value.type === 'image/jpeg' ||
            value.type === 'image/jpg' ||
            value.type === 'application/pdf')
        )
      })
      .test('fileSize', t("The file is too large! Max allowed size is 5MB."), (value) => {
        if (!value) return true
        return value?.size <= 5242880
      }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: documentVerficationSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)

      var FormData = require('form-data')
      var data = new FormData()
      if (formik.values.emirates_front != '0') {
        data.append('emirates_front', values.emirates_front)
      }
      setTimeout(() => {
        uploadMyDocs(data)
          .then((response: any) => {
            setLoading(false)
            //   closeRef.current.click()
            formik.setTouched({})
            getDocs()
            console.log(response)
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const handleView = (data: any) => {
    setViewImage(data)
  }

  const formatTitle = (string: string) => {
    return string.replaceAll('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase())
  }

  useEffect(() => {
    formik.setFieldValue('emirates_front', 0)
    formik.setFieldValue('emirates_back', 0)
    formik.setFieldValue('passport', 0)
    formik.setFieldValue('passport_back', 0)
    formik.setFieldValue('visa', 0)
    formik.setFieldValue('letter_of_intent', 0)
    formik.setFieldValue('addc_bill', 0)
  }, [emirates_front])

  // Emirates Front
  const [uploading, setUploading] = useState(false)
  const handleEmiratesIdFrontUpload = () => {
    emiratesIdFrontInputRef.current?.click()
  }

  const handleDisplayEmiratesIdFrontDetails = (e: any) => {
    if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
      emiratesIdFrontInputRef.current?.files && setUploadedEmiratesIdFrontName(emiratesIdFrontInputRef.current.files[0].name)
      formik.setFieldTouched('emirates_front')
      formik.setFieldValue('emirates_front', e.currentTarget.files[0])
    } else {
      setUploading(true)
      formik.setFieldError('emirates_front', undefined)
      try {
        validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
          if (compressResponse?.success) {
            emiratesIdFrontInputRef.current?.files && setUploadedEmiratesIdFrontName(compressResponse.file.name);
            formik.setFieldTouched('emirates_front', true, false)
            formik.setFieldValue('emirates_front', compressResponse.file, false)
          } else {
            setUploadedEmiratesIdFrontName(null)
            formik.setFieldTouched('emirates_front', true, false)
            formik.setFieldValue('emirates_front', '', false)
            formik.setFieldError('emirates_front', t(compressResponse?.message || 'File compression error!'))
          }
          setUploading(false)
        })
      } catch (error) {
        setUploadedEmiratesIdFrontName(null)
        formik.setFieldTouched('emirates_front')
        formik.setFieldValue('emirates_front', '', false)
        formik.setFieldError('emirates_front', t('File upload error!'))
        setUploading(false)
      }
    }
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <div className='card mb-3'>
            <div className='card-body px-8 py-6'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='row'>
    
                  <div className='col-xl-6 col-md-6 col-12 mb-6'>
                    <div className='gray-card'>
                      <div className='row'>
                        <div className='col-sm-8 col-12'>
                          <h4 className='fw-bold font-16 text-black'>
                            Document
                          </h4>
                          <p className='text-normal font-12'>
                            Upload your Handover document here
                          </p>
                        </div>
                        <div className='col-sm-4 col-12 text-end'>
                          <div>
                            {/* <button
                              className='border-0 bg-transparent'
                              data-bs-target='#view-popUp'
                              data-bs-toggle='modal'
                              data-bs-dismiss='modal'
                              type='button'
                              onClick={() => {
                                handleView(document)
                              }}
                            >
                              <i className='text-black bi bi-eye font-13 me-3'></i>
                            </button> */}
                            {/* <ViewPopup viewImage={PhDoc} /> */}

                            <Link to={PhDoc} target='_blank'>
                              <i className='text-black bi bi-download font-13'></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className='row align-items-center'>
                        <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                          <p className='text-normal font-12 fw-bold'>
                           
                          </p>
                        </div>
                        <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                          <p className='text-normal font-12 fw-bold'>
                            
                          </p>
                        </div>
                        <div className='col-xl-6 col-md-12 col-sm-6 col-12'>
                          <div className='d-flex'>
                            {/* <Link to={'/'} className='btn btn-outline-primary bg-white border-0 font-15 px-3 py-2 me-3 w-100'>Cancel</Link> */}
                            {/* <Link to={'/'} className='btn btn-primary font-15 px-3 py-2 w-100'> */}
                            <input
                              ref={emiratesIdFrontInputRef}
                              onChange={(e) => handleDisplayEmiratesIdFrontDetails(e)}
                              className='d-none'
                              type='file'
                            // accept='.jpeg,.jpg,.png,application/pdf'
                            />
                            <button
                              type='button'
                              onClick={handleEmiratesIdFrontUpload}
                              // className={`w-100 py-3 ellipsis btn btn-outline-${uploadedEmiratesIdFrontName ? 'primary' : 'primary'
                              //   }`}
                              disabled={uploading}
                              className={`w-100 py-3 ellipsis btn btn-outline-${uploadedEmiratesIdFrontName ? 'primary' : 'primary'
                                }`}
                            >
                              {/* <span>
                                {uploadedEmiratesIdFrontName ? (
                                  uploadedEmiratesIdFrontName
                                ) : (
                                  <span>
                                    {t("Upload")}{' '}
                                    <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                  </span>
                                )}{' '}
                              </span> */}
                              {uploading ?
                                <span className='indicator-progress d-block'>
                                  {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                : uploadedEmiratesIdFrontName ? (
                                  uploadedEmiratesIdFrontName
                                ) : (
                                  <span>
                                    {t("Upload")}{' '}
                                    <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>
                                  </span>
                                )}{' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {formik.touched.emirates_front && formik.errors.emirates_front && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert' className='text-danger'>
                            {formik.errors.emirates_front}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                      
                  {needActionDocuments?.length ? (
                   <div className='col-xl-12 col-md-12 col-12 text-end'> 
                    <button
                      type='submit'
                      className='btn btn-primary px-10 py-3'
                    >
                      {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {t("Please wait...")}{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
