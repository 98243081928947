import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../constants';
import { Spinner } from 'react-bootstrap-v5'
import { getMasterData, extendOfferRequest } from '../../../../../constants/axios/apis'
import { DynamicPopup } from '../../../../SuccessfulPopup/DynamicPopup';
import * as PTOffer from '../../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

export function ExtendOfferPopup() {

    const ref = useRef<any>(0)
    const dispatch = useDispatch()
    const [extendOfferReasons, setExtendOfferReasons] = useState<any>()
    const [selectReason, setSelectReason] = useState<any>()
    const [message, setMessage] = useState<any>()
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation();

    let offerDeatilsForExtend: any
    offerDeatilsForExtend = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)
    useEffect(() => {

        getMasterData().then(({ data: masterData }) => {
            let reasons = masterData
                .filter((item: any) => item.id === 12)
                .flatMap((data: any) => data.master_data)
            setExtendOfferReasons(reasons)
        })
    }, [offerDeatilsForExtend])
    const handleSubmit = () => {
        setLoading(true)
        var FormData = require('form-data')
        var extendDetails = new FormData()
        extendDetails.append('offer_id', offerDeatilsForExtend?.offerId);
        extendDetails.append('reason', selectReason);
        extendOfferRequest(extendDetails).then((data) => {
            setLoading(false)
            if (data.code === 200 && data.errorCode != 1) {
                setMessage({
                    error: 0,
                    message: data?.data?.msg,
                })
                ref.current.click();
            } else {
                setMessage({
                    error: 1,
                    message: data.errorDescription,
                })
                ref.current.click();
            }
        })
    }
    
    useEffect(() => {
        if (message?.message?.length > 0) {
            dispatch(PTOffer.actions.modalmessage(message))
        }
    }, [message])
    return (
        <><div className="modal fade custom-modal" aria-hidden='true' id="extend-offer-popUp">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-10">
                    <div className="modal-header py-4">
                        <h5 className="modal-title">{t("Extend Offer")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                    </div>
                    <div className="modal-body p-md-7">
                        <div className="mb-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mb-5 position-relative">
                                        <label className="form-label font-12">{t("Customer Name")}</label>
                                        <input type="text" className="form-control font-12" value={offerDeatilsForExtend?.customerName} disabled={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-4 col-lg-4 col-12">
                                            <div className="mb-5 position-relative makeon-offer-div">
                                                <label className="form-label font-12">{t("Lease Start Date")}</label>
                                                <p className="form-label text-black font-17 mb-0">{moment(offerDeatilsForExtend?.leaseDate).format('DD-MM-YYYY')}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-12">
                                            <div className="mb-5 position-relative makeon-offer-div">
                                                <label className="form-label font-12">{t("No. of Installments")}</label>
                                                <p className="form-label text-black font-17 mb-0">{offerDeatilsForExtend?.noOfPayments} {t("Installments")}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-12">
                                            <div className="mb-5 position-relative makeon-offer-div">
                                                <label className="form-label font-12">{t("My Offer")}</label>
                                                <p className="form-label text-black font-17 mb-0">{t("AED")} {offerDeatilsForExtend?.offeredPrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-12 text-center">{loading ? <Spinner animation='border' variant='primary' /> : null}</div>
                                <div className="col-12">
                                    <div className="mb-0">
                                        <label className="form-label fw-bolder">{t("Extension Reason")} </label>
                                        <select className="form-control form-select fw-bolder" onChange={(e) => setSelectReason(e?.target?.value)}>
                                            <option selected disabled>{t("Select")}</option>
                                            {extendOfferReasons && extendOfferReasons?.map((item: any, index: number) => (
                                                <option value={item.value}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pt-0 border-0">
                        {/* <button data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal"
                            type='submit' className='btn btn-primary fw-bold px-10 py-3 w-50'>
                            Extend offer
                        </button> */}
                        <button onClick={handleSubmit}
                            type='submit' className='btn btn-primary fw-bold px-10 py-3 w-50 m-0' disabled={!selectReason ? true : false}>
                            {t("Extend offer")}
                        </button>
                        <p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                            {/*  */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
            <DynamicPopup message={message} />
        </>
    );
}