import {t} from 'i18next'
import {FC, useEffect, useState} from 'react'
import {Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../components/layout/core'
import {UnitsList} from './UnitsList/UnitsList'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {RootState} from '../../constants'
import {
  getMyUnitDocuments,
  getPaymentHistory,
  getServiceRequestsCategories,
} from '../../constants/axios/apis'
import {CustomLoader} from '../../constants/generics/customActios'
import {MyUnitCard} from './UnitsDetails/MyUnitCard/MyUnitCard'
import {MyUnitlist} from './UnitsDetails/MyUnitList/MyUnitList'
import {PaymentsNew} from './UnitsDetails/Payments/PaymentsNew'
import {NeedAction} from './UnitsDetails/NeedAction/NeedAction'
import { MyProperty} from './UnitsDetails/MyProperty'
import {OwnerDetails} from './UnitsDetails/OwnerDetails/OwnerDetails'
import { PaymentSuccessfulPopup } from '../SuccessfulPopup/PaymentSuccessfulPopup'
import { CardPayment } from './UnitsDetails/Payments/CardPayment/CardPayment'
import { PaymentMethodTabs } from './UnitsDetails/Payments/PaymentMethodTabs/PaymentMethodTabs'
import { BankTransfer } from './UnitsDetails/Payments/BankTransfer/BankTransfer'
import { PayByCheque } from './UnitsDetails/Payments/PayByCheque/PayByCheque'
import {CreateUnit} from './create_unit/CreateUnit'
import {EditUnit} from './edit_unit/EditUnit'
import { getUserRole } from '../../constants/generics/userCheck'

let userEmail = ''
let userDetail = localStorage.getItem('userDetail')
if (userDetail) {
  userEmail = JSON.parse(userDetail).email
}

const UnitPortfolio: FC = () => {
  const currentContract: any = useSelector<RootState>(({Tdashboard}) => Tdashboard.selectedContract)

  const [services, setServices] = useState<any>()
  const [payments, setPayments] = useState<any>(undefined)
  const [allDocuments, setAllDocuments] = useState<any>([])
  const [needActionDocuments, setNeedActionDocuments] = useState<any>([])

  let userDetail = localStorage.getItem('userDetail')
    const state: any = useSelector(state => state);
  const user_role = getUserRole(state?.auth?.user?.data);
  useEffect(() => {
    if (currentContract) {
      getPaymentHistory(currentContract.propertyID, '', '', 100).then(({data: paymentsData}) => {
        setPayments(paymentsData)
      })
    }
  }, [currentContract])

  useEffect(() => {
    getServiceRequestsCategories(
      currentContract?.property_search_type,
      currentContract,
      userDetail && JSON.parse(userDetail)
    ).then((response) => {
      let categories = response?.data && Array.isArray(response?.data) ?response?.data?.filter((category: any) => category?.is_visible === 1) : undefined;
      setServices(categories)
    })
  }, [])

  const getDocs = () => {
    getMyUnitDocuments().then((response) => {
      // console.log(moment("2023-10-31 15:18:28").format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm'), moment("2023-10-31 15:18:28").format('YYYY-MM-DD HH:mm'), ' -', moment().format('YYYY-MM-DD HH:mm'))
      if (response.data) {
        // setAllDocuments(response.data?.all_documents?.filter((document: any) => document.contract_id == currentContract.id))
        setAllDocuments(
          response?.data?.all_documents?.filter(
            (item: any) =>
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >=
                moment().format('YYYY-MM-DD HH:mm') &&
                item.document_type == 'emirates_front') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >=
                moment().format('YYYY-MM-DD HH:mm') &&
                item.document_type == 'emirates_back') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >=
                moment().format('YYYY-MM-DD HH:mm') &&
                item.document_type == 'passport') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >=
                moment().format('YYYY-MM-DD HH:mm') &&
                item.document_type == 'passport_back') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >=
                moment().format('YYYY-MM-DD HH:mm') &&
                item?.filename?.length > 0 &&
                item.document_type == 'visa') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') >=
                moment().format('YYYY-MM-DD HH:mm') &&
                item?.filename?.length > 0 &&
                item.document_type == 'letter_of_intent')
          )
        )
        // setNeedActionDocuments(response.data?.need_action?.filter((document: any) => document.contract_id == currentContract.id))
        setNeedActionDocuments(
          response?.data?.all_documents?.filter(
            (item: any) =>
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <=
                moment().format('YYYY-MM-DD HH:mm') &&
                item.document_type == 'emirates_front') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <=
                moment().format('YYYY-MM-DD HH:mm') &&
                item.document_type == 'emirates_back') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <=
                moment().format('YYYY-MM-DD HH:mm') &&
                item.document_type == 'passport') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <=
                moment().format('YYYY-MM-DD HH:mm') &&
                item.document_type == 'passport_back') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <=
                moment().format('YYYY-MM-DD HH:mm') &&
                item?.filename?.length > 0 &&
                item.document_type == 'visa') ||
              (moment(item?.expiry_date).format('YYYY-MM-DD HH:mm') <=
                moment().format('YYYY-MM-DD HH:mm') &&
                item?.filename?.length > 0 &&
                item.document_type == 'letter_of_intent')
          )
        )
      }
    })
  }

  useEffect(() => {
    if (currentContract) {
      getDocs()
    }
  }, [currentContract])
  if (user_role != 'PT' && currentContract === undefined) {
    return <CustomLoader />
  }
  return (
    <>
      <div className='row gy-5 g-xl-8 dashboard-page'>
        <div className='col-12 mt-0'>
          <Switch>
            <Route path='/unit-portfolio/list'>
              <UnitsList />
            </Route>
            <Route path='/unit-portfolio/create-unit'>
              <CreateUnit />
            </Route>
            <Route path='/unit-portfolio/edit-unit'>
              <EditUnit />  
            </Route>
            <Route path='/unit-portfolio/payments'>
              <MyUnitCard contract={currentContract} />
              <MyUnitlist
                allDocumentCount={allDocuments?.length}
                needActionDocumentCount={needActionDocuments?.length}
              />
              <PaymentsNew />
            </Route>
            <Route path='/unit-portfolio/documents'>
              <MyUnitCard contract={currentContract} />
              <MyUnitlist
                allDocumentCount={allDocuments?.length}
                needActionDocumentCount={needActionDocuments.length}
              />
              {/* <InnerDocxList allDocuments={allDocuments} /> */}
              <NeedAction needActionDocuments={needActionDocuments} getDocs={getDocs} />
            </Route>
            <Route path='/unit-portfolio/need-action'>
              <MyUnitCard contract={currentContract} />
              <MyUnitlist
                allDocumentCount={allDocuments?.length}
                needActionDocumentCount={needActionDocuments?.length}
              />
              <NeedAction needActionDocuments={needActionDocuments} getDocs={getDocs} />
            </Route>
            <Route path='/unit-portfolio/details'>
              <MyUnitCard contract={currentContract} />
              <MyUnitlist
                allDocumentCount={allDocuments?.length}
                needActionDocumentCount={needActionDocuments?.length}
              />
              <MyProperty contract={currentContract} />
            </Route>
            <Route path='/unit-portfolio/owner-details'>
              <MyUnitCard contract={currentContract} />
              <MyUnitlist
                allDocumentCount={allDocuments?.length}
                needActionDocumentCount={needActionDocuments?.length}
              />
              <OwnerDetails />
            </Route>
            <Route path='/unit-portfolio/card-payment'>
              <PaymentMethodTabs />
              <CardPayment />
              <PaymentSuccessfulPopup />
            </Route>
            <Route path='/unit-portfolio/bank-transfer'>
              <PaymentMethodTabs />
              <BankTransfer />
              <PaymentSuccessfulPopup />
            </Route>
            <Route path='/unit-portfolio/pay-by-cheque'>
              <PaymentMethodTabs />
              <PayByCheque />
              <PaymentSuccessfulPopup />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}
const UnitPortfolioWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Unit Portfolio</PageTitle>
      <UnitPortfolio />
    </>
  )
}

export {UnitPortfolioWrapper}
