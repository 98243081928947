import React, {useState, useEffect, FC} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useHistory} from 'react-router-dom'
import {PageTitle} from '../../components/layout/core'
import {DashboardFilter} from './components/DashboardFilter/DashboardFilter'
import {PropertiesList} from './components/PropertiesList/PropertiesList'
import {AvailableProperties} from './components/PropertiesList/AvailableProperties/AvailableProperties'
import {UpcomingProperties} from './components/PropertiesList/UpcomingProperties/UpcomingProperties'
import {LatestProperties} from './components/PropertiesList/LatestProperties/LatestProperties'
import {TrendingProperties} from './components/PropertiesList/TrendingProperties/TrendingProperties'
import {RecentInvoices} from './components/RecentInvoices/RecentInvoices'
import {Redirect, Route, Switch} from 'react-router-dom'
import {BrokersList} from './components/BrokersList/BrokersList'
import RightArrow from '../../assets/images/Right_Arrow.png'
import {Link} from 'react-router-dom'
import {SortBy} from './components/SortBy/SortBy'
import LeftArrow from '../../assets/images/Left_Arrow.png'
import {Search} from '../pt-dashboard/components/Search/Search'
import {useTranslation} from 'react-i18next'
import SearchForm from '../pt-dashboard/components/Search/Searchs'

const BrokerAdminDashboardPage: FC = (props: any) => {
  const location = useLocation()
  let history = useHistory()
  const {data, offerId} = props
  let userEmail = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userEmail = JSON.parse(userDetail).email
  }
  const {t} = useTranslation()

  const [propertyFilter, setPropertyFIlter] = useState('Filter')

  const handlePropertyFilters = (e: any) => {
    setPropertyFIlter(e.target.value)

    if (e.target.value === 'Filter') {
      history.push(`/broker-admin-dashboard/available-properties`)
    } else {
      history.push(`/broker-admin-dashboard/${e.target.value.toLowerCase()}-properties`)
    }
  }
  /* useEffect(()=>{
        if(propertyFilter === "Filter" ){
            history.push(`/broker-admin-dashboard/available-properties`);
        }
    },[]); */
  return (
    <>
      <div className='row gy-5 g-xl-8 broker-dash-page dashboard-page'>
        <div className='col-xxl-12 mt-0'>
          <div className='com-card-section'>
            {/* <SearchForm /> */}
            <div className='row'>
              {location.pathname === '/broker-admin-dashboard/available-properties' ||
              location.pathname === '/broker-admin-dashboard/upcoming-properties' ||
              location.pathname === '/broker-admin-dashboard/latest-properties' ||
              location.pathname === '/broker-admin-dashboard/trending-properties' ? (
                <div className='col-12'>
                  <div className='card mb-6 broker-dash-discover'>
                    <div className='card-body px-8 pb-1'>
                      <div className='row'>
                        <div className='col-md-9'>
                          <h4 className='card-title'> {t('Discover')} </h4>
                        </div>
                        <div className='col-md-3 text-end'>
                          <DashboardFilter handlePropertyFilters={handlePropertyFilters} />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-12 col-xl-8 col-12 mb-lg-0 mb-5'>
                          <Switch>
                            <Route path='/broker-admin-dashboard/available-properties'>
                              <PropertiesList propertyFilter={propertyFilter} />
                              <AvailableProperties />
                            </Route>
                            <Route path='/broker-admin-dashboard/upcoming-properties'>
                              <PropertiesList propertyFilter={propertyFilter} />
                              <UpcomingProperties />
                            </Route>
                            <Route path='/broker-admin-dashboard/latest-properties'>
                              <PropertiesList propertyFilter={propertyFilter} />
                              <LatestProperties />
                            </Route>
                            <Route path='/broker-admin-dashboard/trending-properties'>
                              <PropertiesList propertyFilter={propertyFilter} />
                              <TrendingProperties />
                            </Route>
                          </Switch>
                        </div>
                        <div className='col-lg-12 col-xl-4 col-12'>
                          <BrokersList />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-3'>
                    <div className='card-body p-7'>
                      <div className='row align-items-center'>
                        <div className='col-md-6 col-sm-7 col-12'>
                          <h4 className='card-title mb-0'> {t('Recent Invoices')} </h4>
                        </div>
                        <div className='col-md-6 col-sm-5 col-12 text-end'>
                          <Link
                            to={'/statements/inprogress-invoices'}
                            className='text-decoration-none view-all d-block mt-2 text-end'
                          >
                            <span> {t('View All')} </span>
                            <img src={RightArrow} className='img-fluid' />
                          </Link>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <RecentInvoices />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : location.pathname === '/broker-admin-dashboard/viewall-available-properties' ||
                location.pathname === '/broker-admin-dashboard/viewall-upcoming-properties' ||
                location.pathname === '/broker-admin-dashboard/viewall-latest-properties' ||
                location.pathname === '/broker-admin-dashboard/viewall-trending-properties' ? (
                <div className='col-12'>
                  <div className='card mb-6'>
                    <div className='card-body px-8 pb-10'>
                      <div className='row'>
                        <div className='col-md-9'>
                          <h4 className='card-title font-18 text-black mb-0'>
                            <Link to={'/broker-admin-dashboard/available-properties'} className=''>
                              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
                            </Link>
                            {t('Properties')}
                          </h4>
                        </div>
                        {/* <div className="col-md-3 text-end">
                                                <SortBy />
                                            </div> */}
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <Switch>
                            <Route path='/broker-admin-dashboard/viewall-available-properties'>
                              <PropertiesList />
                              <AvailableProperties />
                            </Route>
                            <Route path='/broker-admin-dashboard/viewall-upcoming-properties'>
                              <PropertiesList />
                              <UpcomingProperties />
                            </Route>
                            <Route path='/broker-admin-dashboard/viewall-latest-properties'>
                              <PropertiesList />
                              <LatestProperties />
                            </Route>
                            <Route path='/broker-admin-dashboard/viewall-trending-properties'>
                              <PropertiesList />
                              <TrendingProperties />
                            </Route>
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const BrokerAdminDashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <BrokerAdminDashboardPage />
    </>
  )
}

export {BrokerAdminDashboardWrapper}
