import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { getPropertyFilterData } from '../../../constants/axios/apis'
import { ServiceDataInfo } from './ServiceDataInfo'
import { useTranslation } from 'react-i18next'

export function AmenityBookingInner({ selectedIssue }: any) {
  const [amenity, setAmenity] = useState<any>()
  const [issue, setIssue] = useState<any>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (selectedIssue) {
      setIssue(selectedIssue)
      setLoading(false)
    }
  }, [selectedIssue])

  useEffect(() => {
    if (issue && issue?.code == 'AM') {
      getPropertyFilterData().then(({ data }) => {
        setAmenity(data.amenity.find((item: any) => item.value == issue?.details?.amenity_type_id))
      })
    }
  }, [issue])
  const { t } = useTranslation();

  return (
    <div className='com-card-section'>
      {!loading && issue && issue?.code == 'AM' ? (
        <div className='row mta-app-setting-campaign'>
          <div className='col-12'>
            <div className='accordion accordion-flush' id='accordionFlushExampleAmenityBooking'>
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header position-relative' id='flush-headingAmenityBooking'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapseAmenityBooking'
                    aria-expanded='false'
                    aria-controls='flush-collapseAmenityBooking'
                  >
                    {t("Amenity booking Request Detail")}
                  </button>
                  <span className='services-badge'>
                    {issue?.status == 0 ? t("Open") : t("Closed")}
                  </span>
                </h2>

                <div
                  id='flush-collapseAmenityBooking'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingAmenityBooking'
                  data-bs-parent='#accordionFlushExampleAmenityBooking'
                >
                  <div className='accordion-body py-5'>
                    <div className='row form-add-design'>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Category")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={amenity?.name}
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>{t("Date")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.details?.date).format('D-MM-YYYY')}
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>{t("Time")}</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                            value={moment(issue?.details?.time, 'HH:mm').format('h:mm A')}
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <ServiceDataInfo issue={issue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
