import {Action} from '@reduxjs/toolkit'
import {put, takeLatest} from 'redux-saga/effects'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
// ** Define API endPoints **/
export const GET_MYCONTRACT = `${API_URL}/getAllContract`
// export const GET_PAYMENTS = `${API_URL}/payment/list`

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

// ** Define Actions "ALL ACTIONS NAMES SHOULD BE UNIQUE!!!!" **
export const actionTypes = {
  AllContract: '[AllContract] Action',
  AllContractLoaded: '[Load AllContract] Load data',
  SelectedContract: '[SelectedContract] Action',
  SelectedContractId: '[SelectedContractId] Action',
  ContractLoading: '[ContractLoading] Action',
}

// ** Define initialDashboardState **
const initialDashboardState: IDashboardState = {
  allContract: undefined,
  selectedContract: undefined,
  selectedContractId: undefined,
  accessToken: undefined,
  contractLoading: true,
}
export interface IDashboardState {
  allContract?: ''
  selectedContract?: ''
  selectedContractId?: ''
  accessToken?: string
  contractLoading?: Boolean
}

// ** Add Reducers **
// ** Once done make sure to import and add to rootReducer in RootReducer.ts file
export const reducer = persistReducer(
  {
    storage,
    key: 'Tdashboard',
    // blacklist: ['allContract'],
    whitelist: ['selectedContractId', 'allContract'],
  },
  (state: IDashboardState = initialDashboardState, action: ActionWithPayload<IDashboardState>) => {
    switch (action.type) {
      case actionTypes.AllContract: {
        return {...state, allContract: undefined}
      }
      // ** Reducer for AllContracts data load **
      case actionTypes.AllContractLoaded: {
        const allContract = action.payload?.allContract
        return {...state, allContract}
      }

      case actionTypes.SelectedContract: {
        const contract = action.payload?.selectedContract
        return {...state, selectedContract: contract}
      }
      case actionTypes.SelectedContractId: {
        const contract = action.payload?.selectedContractId
        return {...state, selectedContractId: contract}
      }
      case actionTypes.ContractLoading: {
        const contractLoading = action.payload?.contractLoading
        return {...state, contractLoading: contractLoading}
      }
      default:
        return state
    }
  }
)

export function myAllContracts() {
  return axios.get(GET_MYCONTRACT)
}

export const actions = {
  allContract: () => ({type: actionTypes.AllContract}),
  fulfillMyAllContracts: (allContract: '') => ({
    type: actionTypes.AllContractLoaded,
    payload: {allContract},
  }),

  selectedContract: (selectedContract: any) => ({
    type: actionTypes.SelectedContract,
    payload: {selectedContract},
  }),

  selectedContractId: (selectedContractId: any) => ({
    type: actionTypes.SelectedContractId,
    payload: {selectedContractId},
  }),

  contractLoading: (contractLoading: any) => ({
    type: actionTypes.ContractLoading,
    payload: {contractLoading},
  }),
}
// ** Define Saga [Make sure to maintain unique names]
// ** Once done make sure to import and add to rootSaga in RootReducer.ts file
export function* TenantDashboardsaga() {
  // ** Get My AllContracts Dispatch**
  yield takeLatest(actionTypes.AllContract, function* userRequested() {
    const {data: allContract} = yield myAllContracts()
    yield put(actions.fulfillMyAllContracts(allContract))
  })
}
