import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/core'
import { VisitsPage } from './components/VisitsPage/Visits/VisitsPage'
import { UpcomingList } from './components/VisitsPage/UpcomingList/UpcomingList'
import { ClosedList } from './components/VisitsPage/ClosedList/ClosedList'
import { Route, Switch } from 'react-router-dom'
import { ReschedulePopup } from './components/VisitsPage/ReschedulePopup/ReschedulePopup'

const TeamsActivityPage: FC = () => {
    // const [visitDetails, setVisitDetails] = useState({});
    const [searchProperty, setSearchProperty] = useState("");
    const [searchBroker, setSearchBroker] = useState("");
    return (
        <>
            <div className='row gy-5 g-xl-8 dashboard-page'>
                <div className='col-xxl-12 mt-0'>
                    <div className="com-card-section">
                        <div className="row">
                            <div className="col-12">
                                <div className="card mb-3">
                                    <div className="card-body px-8 pb-10">
                                        <div className="row">
                                            <div className="col-12">
                                                <Switch>
                                                    <Route path='/visits/upcoming'>
                                                        <VisitsPage setSearchBroker={setSearchBroker} setSearchProperty={setSearchProperty} />
                                                        <UpcomingList searchBroker={searchBroker} searchProperty={searchProperty} />
                                                        
                                                    </Route>
                                                    <Route path='/visits/closed'>
                                                        <VisitsPage setSearchBroker={setSearchBroker} setSearchProperty={setSearchProperty} />
                                                        <ClosedList searchBroker={searchBroker} searchProperty={searchProperty} />
                                                    </Route>
                                                    {/* <Route path='/visits/visitconfirmed'>
                                                        <VisitConfirmed setVisitDetails={setVisitDetails} />
                                                        <ReschedulePopup visitDetails={visitDetails} />
                                                        <RequestSuccessfulPopup />
                                                    </Route> */}
                                                </Switch>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const TeamsActivityWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <TeamsActivityPage />
        </>
    )
}

export { TeamsActivityWrapper }
