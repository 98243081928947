import React, { useState } from 'react';
import PrevIcon from '../../../../../assets/images/back_icon.png';
import { ExtendOfferOverview } from './ExtendOfferOverview/ExtendOfferOverview';
import { ExtendOfferStepper } from './ExtendOfferStepper/ExtendOfferStepper';
import { ExtendOfferStepperTwo } from './ExtendOfferStepperTwo/ExtendOfferStepperTwo';
import { useTranslation } from 'react-i18next'

export function ExtendOffer() {

    const [isExtendOfferOverview, setExtendOfferOverview] = useState(false);
    const [isExtendOfferStepper, setExtendOfferStepper] = useState(false);
    const [isExtendOfferStepperTwo, setExtendOfferStepperTwo] = useState(false);
    const { t } = useTranslation();

    const onExtendOfferStepper = () => {
        setExtendOfferStepper(true);
        setExtendOfferStepperTwo(false);
        setExtendOfferOverview(false);
    };

    const onExtendOfferStepperTwo = () => {
        setExtendOfferStepperTwo(true);
        setExtendOfferStepper(false);
        setExtendOfferOverview(false);
    };

    const onExtendOfferOverview = () => {
        setExtendOfferStepper(false);
        setExtendOfferStepperTwo(false);
        setExtendOfferOverview(true);
    };

    return (
        <div className="modal fade custom-modal create-campaign-popup tenant-info-popup" id="extend-offer-popup">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content rounded pt-5">
                    <div className="modal-header p-0 justify-content-start rounded-0 mb-2">
                        <h5 className="modal-title fw-bold font-18">
                            {isExtendOfferStepper ?
                                <span
                                    onClick={() => setExtendOfferStepper(false)}
                                    className="c-pointer me-3"
                                >
                                    {/* <img src={PrevIcon} alt="Prev" className="img-fluid" /> */}
                                </span>
                                : isExtendOfferStepperTwo ?
                                    <span
                                        onClick={() => setExtendOfferStepperTwo(false)}
                                        className="c-pointer me-3"
                                    >
                                        {/* <img src={PrevIcon} alt="Prev" className="img-fluid" /> */}
                                    </span>
                                    :
                                    null
                            }
                            {t("Enter Tenant's Details")}
                        </h5>
                        <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                    </div>
                    <div className="modal-body">
                        {isExtendOfferStepper ?
                            <ExtendOfferStepper
                                isExtendOfferStepperTwo={isExtendOfferStepperTwo}
                                onExtendOfferStepperTwo={onExtendOfferStepperTwo}
                            />
                            : isExtendOfferStepperTwo ?
                                <ExtendOfferStepperTwo />
                                :
                                <ExtendOfferOverview
                                    isExtendOfferStepper={isExtendOfferStepper}
                                    onExtendOfferStepper={onExtendOfferStepper}
                                />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}