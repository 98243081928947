export function Price({chooseMessage}:any) {
    return (
        <>
        <h3>Sale Details</h3>
        <div className="card">
            <div className="card-body">
                <div className="row mb-4">
                    <div className="col-md-12">
                        <form >
                            <div className="form-add-design">
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">Price (AED)</label>
                                                <input type="text" className="form-control fw-bolder "  value="1200" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">Price/SQFT</label>
                                                <input type="text" className="form-control fw-bolder "  value="0" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="form-group">
                                                <label className="form-label fw-bolder"> Deposit </label>
                                                <div className="form-control input-group p-0">
                                                    <input type="text" className="form-control border-0 form-height convertThis font-13" placeholder="Area" name="built_up_area" value="Deposit"/>
                                                    <div className="input-group-append">
                                                        <span className="font-12 input-group-text border-0"> % </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="form-group">
                                                <label className="form-label fw-bolder"> Commission </label>
                                                <div className="form-control input-group p-0">
                                                    <input type="text" className="form-control border-0 form-height convertThis font-13" placeholder="Area" name="built_up_area" value="Commision"/>
                                                    <div className="input-group-append">
                                                        <span className="font-12 input-group-text border-0"> % </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">Installments</label>
                                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_community" data-live-search="true" title="Select Community" required>
                                                    <option> Please Select </option>
                                                    <option selected> 1 payment</option>    
                                                    <option> 2 payment</option>    
                                                    <option> 3 payment </option>    
                                                    <option> 4 payment</option>           
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">Recurring Period</label>
                                                <select className="form-control form-select fw-bolder form-control-solid" id="" name="id_building" data-live-search="true" title="Select Building" required>
                                                    <option selected> Please Select </option>
                                                    <option > 0</option>    
                                                    <option> 1</option>    
                                                    <option> 2 </option>    
                                                    <option> 3</option>           
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-3 col-sm-6">
                                            <div className="mb-7">
                                                <label className="form-label fw-bolder">Base Price (AED)</label>
                                                <input type="text" className="form-control font-13 form-height" name="name" placeholder="Base Price" value="" required/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="text-end my-6">
            <button className="btn btn-outline-primary fw-bold px-10 py-3 me-3" onClick={() => chooseMessage('2')}>Previous</button>
            <button type="submit" className="btn btn-primary fw-bold px-10 py-3" onClick={() => chooseMessage('4')}>Next</button>
        </div>
        </>
    )
}