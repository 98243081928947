import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../components/layout/core'
import {ReservedPropertiesList} from './ReservedPropertiesComponents'
import {Route, Switch} from 'react-router-dom'

const ReservedPropertiesPage: FC = () => (
  <>
    <div className='row gy-5 g-xl-8 dashboard-page'>
      <div className='col-xxl-12 mt-0'>
        <div className='com-card-section'>
          <div className='row'>
            <div className='col-12'>
              <div className='card mb-3'>
                <div className='card-body p-7'>
                  <div className='row'>
                    <div className='col-12'>
                      <Switch>
                        <Route path=''>
                          <ReservedPropertiesList />
                        </Route>
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

const ReservedPropertiesWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <ReservedPropertiesPage />
    </>
  )
}

export {ReservedPropertiesWrapper}
