import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { sendCardPaymentRequest } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'

type Props = {
  props?: any
}

export const CardPayment: React.FC<Props> = ({ props }: any) => {
  const [loading, setLoading] = useState(false)
  const [paymentType, setPaymentType] = useState(0)
  const { t } = useTranslation();

  const initialValues = {
    terms: false,
    // payment_type: paymentType,
  }

  // //   setTimeout(() => {
  //   history.push('/payments/card-payment', {
  //     offer_id: 0,
  //     amount: props?.amount,
  //     payment_category: props?.payment_category,
  //     payment_for: props.payment_for,
  //     property_id: props?.property_id,
  //   })
  //   //   }, 1000)

  const validationSchema = Yup.object().shape({
    terms: Yup.bool().oneOf([true], t("Terms & Conditions must be accepted")),
  })

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      sendCardPaymentRequest(
        props?.contract?.offer_id,
        '',
        props?.property_id,
        props?.amount,
        props?.payment_category,
        props.payment_for,
      ).then((response) => {
        setLoading(false)
        window.location.replace(response.data.paymentUrl)
      })
    },
  })

  const handlePaymentTypeChange = (e: any) => {
    setPaymentType(e.target.value)
    formik.handleChange(e)
  }

  const handleTermsClick = () => {
    window.open("/");
  };

  return (
    <div className=' '>
      <div className='row'>
        <div className='col-12'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='accessCardRequestForm'
            onSubmit={formik.handleSubmit}
          >
            <div className='row form-add-design'>
              <div className='col-12'>
                <div className='mb-5 position-relative p-5 bg-white rounded'>
                  <input
                    className='form-check-input me-5'
                    // {...formik.getFieldProps('payment_type')}
                    type='radio'
                    name='payment_type'
                    id='inlineRadio1'
                    value='0'
                    checked={paymentType == 0}
                    onChange={(e) => handlePaymentTypeChange(e)}
                    onBlur={formik.handleBlur}
                    autoComplete='off'
                  />
                  <label
                    className='form-check-label text-black fw-bolder font-14'
                    htmlFor='inlineRadio1'
                  >
                    {t("Credit Card")}
                  </label>
                </div>
              </div>
              <div className='col-12'>
                <div className='mb-5 position-relative p-5 bg-white rounded'>
                  <input
                    className='form-check-input me-5'
                    // {...formik.getFieldProps('payment_type')}
                    type='radio'
                    name='payment_type'
                    id='inlineRadio2'
                    value='1'
                    checked={paymentType == 1}
                    onChange={(e) => handlePaymentTypeChange(e)}
                    onBlur={formik.handleBlur}
                    autoComplete='off'
                  />
                  <label
                    className='form-check-label text-black fw-bolder font-14'
                    htmlFor='inlineRadio2'
                  >
                    {t("Debit Card")}
                  </label>
                </div>
              </div>
              {/*<div className="col-12">*/}
              {/*<div className="mb-5 position-relative p-5 bg-white rounded">*/}
              {/*<div className="row">*/}
              {/*<div className="col-12 mb-5">*/}
              {/*<label className="font-14 fw-bold mb-3 text-black d-block">Enter Credit Card Number</label>*/}
              {/*<input type="text" className="form-control fw-bolder bg-secon" placeholder="" />*/}
              {/*</div>*/}
              {/*<div className="col-6 mb-5">*/}
              {/*<label className="font-14 fw-bold mb-3 text-black d-block">Expiry Date</label>*/}
              {/*<input type="text" className="form-control fw-bolder bg-secon" placeholder="" />*/}
              {/*</div>*/}
              {/*<div className="col-6 mb-5">*/}
              {/*<label className="font-14 fw-bold mb-3 text-black d-block">CVV  <span className="float-end text-secondary">Help?</span></label>*/}
              {/*<input type="text" className="form-control fw-bolder bg-secon" placeholder="" />*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div> */}

              <div className='col-12'>
                <div className='mb-5 form-check'>
                  <input
                    className='form-check-input fc-wo-checkbox'
                    {...formik.getFieldProps('terms')}
                    type='checkbox'
                    onBlur={formik.handleBlur}
                    id='flexCheckDefault'
                  />
                  <label className='form-check-label' htmlFor='flexCheckDefault'>
                    {t("Accept")}
                  </label>
                  <span onClick={handleTermsClick} className="ps-1 c-pointer text-theme fw-bold font-14">
                    {t("Terms and Conditions")}
                  </span>
                  {formik.touched.terms && formik.errors.terms && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.terms}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12'>
                {/* <button
                  className='btn btn-primary fw-bold px-10 py-3 w-100'
                  data-bs-dismiss={!formik.isValid ? '' : 'modal'}
                >
                  Pay Now
                </button> */}
                <button
                  type='submit'
                  className='btn btn-primary fw-bold px-10 py-3 w-100'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>{t("Pay Now")}</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {t("Please wait...")}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
