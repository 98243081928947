import React, { FC, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { SecurityDeposit } from './SecurityDeposit'
import { useDispatch, useSelector } from 'react-redux'
import * as PTOffer from '../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { RootState } from '../../../../constants'
import { useTranslation } from 'react-i18next'

interface Props {
    offerId: any
    amount: any
}

export const MakeanOfferNotification: React.FC = () => {

    const dispatch = useDispatch()
    let tabVisible: any
    tabVisible = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)
    const history = useHistory()
    const [tabClicked, setTabClicked] = useState(tabVisible?.tabMode)
    const { t } = useTranslation();

    const tabChange = (e: any) => {
        setTabClicked(e)
    }

    return (
        <div className='modal fade notif-popup' id='make-an-offer-notification' data-bs-backdrop="static" data-bs-keyboard="false">
            <div className='modal-dialog modal-dialog-scrollable'>
                <div className='modal-content rounded-10'>
                    <div className='modal-body'>
                        <div className='row'>
                            <div className='col-12 '>
                                <button type='button' className='btn-close float-end' data-bs-dismiss='modal'>
                                    <span className='icon-close' aria-hidden='true'></span>
                                </button>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <ul
                                    className='nav nav-pills mb-3 make-offer-notification'
                                    id='pills-tab'
                                    role='tablist'
                                >
                                    <li className='nav-item tab_nav_li' role='presentation'>
                                        <button
                                            className={tabVisible?.tabMode === 2 ? 'nav-link active px-10 py-5 w-100 fw-bolder' : 'nav-link  px-10 py-5 w-100 fw-bolder'}
                                            id='pills-home-tab'
                                            data-bs-toggle='pill'
                                            data-bs-target='#pills-home'
                                            type='button'
                                            role='tab'
                                            aria-controls='pills-home'
                                            aria-selected='false'
                                            onClick={() => tabChange('2')}
                                            disabled={tabVisible?.tabMode == 1 ? true : false}
                                        >
                                            {t("Security Deposit")}
                                        </button>
                                    </li>
                                    <li className='nav-item tab_nav_li' role='presentation'>
                                        <button
                                            className={tabVisible?.tabMode === 1 ? 'nav-link active px-10 py-5 w-100 fw-bolder' : 'nav-link  px-10 py-5 w-100 fw-bolder'}
                                            id='pills-profile-tab'
                                            data-bs-toggle='pill'
                                            data-bs-target='#pills-home'
                                            type='button'
                                            role='tab'
                                            aria-controls='pills-profile'
                                            aria-selected='true'
                                            onClick={() => tabChange('1')}
                                            disabled={tabVisible?.tabMode == 2 ? true : false}
                                        >
                                            {t("Rent")}
                                        </button>
                                    </li>
                                </ul>
                                <div className='tab-content' id='pills-tabContent'>
                                    <div
                                        className='tab-pane fade show active'
                                        id='pills-home'
                                        role='tabpanel'
                                        aria-labelledby='pills-home-tab'
                                    >
                                        <SecurityDeposit />
                                    </div>
                                    {/*<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">*/}
                                    {/*<SecurityDeposit />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
