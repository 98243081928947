import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

export function Time({ timeSlots, formik }: any) {
    const { t } = useTranslation();
    const timings = timeSlots?.map((timeSlot: any, index: any) => (
        <>
            <input
                key={index}
                type='radio'
                className='btn-check'
                name='time_slot'
                value={timeSlot}
                onChange={formik.handleChange}
                id={'btnradio' + index}
                autoComplete='off'
                onBlur={formik.handleBlur}
                checked={formik.values.time_slot == timeSlot}
            />
            <label className='btn btn-outline-primary mx-2 ' htmlFor={'btnradio' + index}>
                {' '}
                {moment(timeSlot, 'HH:mma').format('LT')}{' '}
            </label>
        </>
    ))

    return (
        <div className='mb-10 w-100 overflow-hidden'>
            <label className='form-label fw-bolder d-block mb-3'>{t("Time")}<span className='text-danger'>*</span></label>
            <div className='btn-group w-100' id='maintanance_time'>
                {timeSlots ? timings : ''}
            </div>

            {formik.touched.time && formik.errors.time && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                            {formik.errors.time}
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}
