import React, {FC, useEffect, useRef, useState, Fragment} from 'react'
import DatePicker from 'react-date-picker';

export function TDetails() {
  const [file, setFile] = useState([] as any);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    function uploadSingleFile(e:any) {
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
        console.log("file", file);
    }

    function upload(e:any) {
        e.preventDefault();
        console.log(file);
    }

    function deleteFile(e:any) {
        const s = file.filter((item:any, index:any) => index !== e);
        setFile(s);
        console.log(s);
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const [dateValue, setDateValue] = useState(new Date());

    return (
        <div className="row">
            <div className="col-12">
                <div className='row form-add-design'>
                    <div className='col-12'>
                        <div className='mb-5'>
                          <label className="font-14 fw-bold mb-3 text-black d-block">Emirates ID Number</label>
                          <input type="text" 
                            className="form-control fw-bolder bg-secon" 
                            placeholder="" 
                          />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mb-5'>
                            <label className="font-14 fw-bold mb-3 text-black d-block">Name</label>
                            <input type="text"
                               className="form-control fw-bolder bg-secon"
                               placeholder=""
                            />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mb-5'>
                            <label className="font-14 fw-bold mb-3 text-black d-block">Date of Birth</label>
                            <input type="date"
                               className="form-control fw-bolder bg-secon"
                               placeholder=""
                            />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mb-5'>
                            <label className="font-14 fw-bold mb-3 text-black d-block">Emirates ID Expiry Date </label>
                            <input type="text"
                                   className="form-control fw-bolder bg-secon"
                                   placeholder=""
                            />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mb-5'>
                            <label className="font-14 fw-bold mb-3 text-black d-block">Passport Number</label>
                            <input type="text"
                                   className="form-control fw-bolder bg-secon"
                                   placeholder=""
                            />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mb-5'>
                            <label className="font-14 fw-bold mb-3 text-black d-block">Passport Expiry Date</label>
                            <input type="date"
                                   className="form-control fw-bolder bg-secon"
                                   placeholder=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}