import moment from 'moment'
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../constants'
import { uploadPDCs } from '../../../../../constants/axios/apis'
import { Spinner } from 'react-bootstrap-v5'
import { currencyFormater, CustomViewPopup, fileUploader, validateRequireChars, validateRequired, validateRequiredNumbers } from '../../../../../constants/generics/customActios'
import * as dashboard from '../../../../../constants/redux/Sagas/ProspectiveTenant/PTDashboardSaga'
import * as PTOffer from '../../../../../constants/redux/Sagas/ProspectiveTenant/PTOffer'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../../constants/generics/fileUploadHandler';

const PaymentPlanDetails = (props: any) => {
	const dispatch = useDispatch()
	const ref = useRef<any>(0)
	const ref2 = useRef<any>(0)
	const ref3 = useRef<any>(0)
	let offerList: any = []
	offerList = useSelector<RootState>(({ PTdashboard }) => PTdashboard.offerDetails)
	let offerData: any
	offerData = useSelector<RootState>(({ PTOffer }) => PTOffer.offerdetailsdata)
	const [uploadedFileNameCheque, setUploadedFileNameCheque] = useState<any>(null);
	const inputRefCheque = useRef<HTMLInputElement>(null);
	const [chequeFieldError, setChequeFieldError] = useState<string[]>(['']);
	const [chequeNumberFieldError, setchequeNumberFieldError] = useState<string[]>(['']);
	const [chequeDetailsCheck, setChequeDetailsCheck] = useState<any>();
	const [chequeNumb, setChequeNumb] = useState<any>('');
	const [accountNo, setAccountNo] = useState<any>('');
	const [accountNumberFieldError, setAccountNumberFieldError] = useState<string[]>(['']);
	const [accountHolderName, setAccountHolderName] = useState<any>('');
	const [accountHolderFieldError, setAccountHolderFieldError] = useState<string[]>(['']);
	const [bankName, setBankName] = useState<any>('');
	const [accountHolderBankFieldError, setAccountHolderBankFieldError] = useState<string[]>(['']);
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState<any>()
	const [viewImage, setViewImage] = useState<any>()
	const [otherFilesData, setOtherFilesData] = useState<any>([])
	const { t } = useTranslation();

	const [uploading, setUploading] = useState(false)
	const handleUploadCheque = () => {
		inputRefCheque.current?.click();
	};
	const handleDisplayFileDetailsCheque = () => {
		if(inputRefCheque.current?.files){
			if (inputRefCheque.current?.files[0] && inputRefCheque.current.files[0].type === 'application/pdf') {
				inputRefCheque.current?.files && setUploadedFileNameCheque(inputRefCheque.current.files[0].name)
				
				setChequeFieldError([])
				fileUploader(inputRefCheque.current?.files[0], setChequeFieldError, "Cheque");
				validateRequired(inputRefCheque.current?.files[0], setChequeFieldError, "Cheque");
			} else {
				setUploading(true)
				setChequeFieldError([])
				try {
					validateAndCompressImage(inputRefCheque.current?.files[0]).then((compressResponse) => {
						if (compressResponse?.success) {
							inputRefCheque.current?.files && setUploadedFileNameCheque(compressResponse.file.name)
							// fileUploader(compressResponse.file, setChequeFieldError, "Cheque");
						} else {
							setUploadedFileNameCheque(null)
							setChequeFieldError([t(compressResponse?.message || 'File compression error!')])
						}
						setUploading(false)
					})
				} catch (error) {
					setUploadedFileNameCheque(null)
					setChequeFieldError([t('File upload error!')])
					setUploading(false)
				}
			}
		}

		// inputRefCheque.current?.files &&
		// 	setUploadedFileNameCheque(inputRefCheque.current.files[0].name);


		// if (inputRefCheque.current?.files) {
		// 	setChequeFieldError([]);
		// 	fileUploader(inputRefCheque.current?.files[0], setChequeFieldError, "Cheque");
		// 	validateRequired(inputRefCheque.current?.files[0], setChequeFieldError, "Cheque");
		// }
	};
	const chequeNumber = (e: any) => {
		setChequeNumb(e.target.value);

		setchequeNumberFieldError([]);
		validateRequiredNumbers(e.target.value, setchequeNumberFieldError, 'Cheque Number');
	}
	const accountNumber = (e: any) => {
		setAccountNo(e.target.value);

		setAccountNumberFieldError([]);
		validateRequiredNumbers(e.target.value, setAccountNumberFieldError, 'Account Number');
	}
	const accountHName = (e: any) => {
		setAccountHolderName(e.target.value);

		setAccountHolderFieldError([]);
		validateRequireChars(e.target.value, setAccountHolderFieldError, 'Account Holder Name');
	}
	const bankHName = (e: any) => {
		setBankName(e.target.value);

		setAccountHolderBankFieldError([]);
		validateRequireChars(e.target.value, setAccountHolderBankFieldError, 'Bank Name');
	}
	const chequeUpload = (uploadDetails: any) => {
		// console.log("🚀 ~ file: PaymentPlanDetails.tsx ~ line 55 ~ chequeUpload ~ uploadDetails", uploadDetails)
		setChequeDetailsCheck(uploadDetails)
		var filesUpdated: any
		uploadDetails?.otherFiles?.map((file: any, index: any) => {
			if (uploadDetails.currentIndex !== index) {
				// console.log("UploadDocuments ", file.chequeNumber)
				filesUpdated = (
					[...otherFilesData,
					file.chequeNumber]
				)
				setOtherFilesData(filesUpdated)
			}
		})
		// console.log("🚀 ~ file: PaymentPlanDetails.tsx ~ line 55 ~ chequeUpload ~ uploadDetails", otherFilesData)
	}
	const submitCheque = () => {
		if (uploadedFileNameCheque === null || uploadedFileNameCheque?.length <= 0) { setChequeFieldError([t("Cheque is required")]) }
		if (chequeNumb === null || chequeNumb === undefined || chequeNumb?.length <= 0) { setchequeNumberFieldError([t("Cheque Number is required")]) }
		if (accountNo === null || accountNo === undefined || accountNo?.length <= 0) { setAccountNumberFieldError([t("Account Number is required")]) }
		if (accountHolderName === null || accountHolderName === undefined || accountHolderName?.length <= 0) { setAccountHolderFieldError([t("Account Holder Name is required")]) }
		if (bankName === null || bankName === undefined || bankName?.length <= 0) { setAccountHolderBankFieldError([t("Bank Name is required")]) }
		if (uploadedFileNameCheque?.length > 0 && chequeNumb?.length > 0 && accountNo?.length > 0 && accountHolderName?.length > 0 && bankName?.length > 0 && chequeFieldError?.length == 0 && chequeNumberFieldError?.length == 0) {
			setLoading(true)
			var FormData = require('form-data')
			var pdcDetails = new FormData()
			pdcDetails.append('offer_id', offerData?.offerId);
			pdcDetails.append('payment_plan[0][id]', chequeDetailsCheck?.id);
			inputRefCheque.current?.files && pdcDetails.append('payment_plan[0][cheque_file]', inputRefCheque.current?.files[0]);
			pdcDetails.append('payment_plan[0][cheque_number]', chequeNumb);
			pdcDetails.append('account_no', accountNo);
			pdcDetails.append('account_holder', accountHolderName);
			pdcDetails.append('bank_name', bankName);
			uploadPDCs(pdcDetails).then((data) => {
				setLoading(false)
				ref2.current.click();
				if (data.code === 200 && data.errorCode != 1) {
					setChequeNumb('')
					setAccountNo('')
					setBankName('')
					setAccountHolderName('')
					setUploadedFileNameCheque(null)
					dispatch(dashboard.actions.offerDetails(offerData?.offerId))
					setMessage({
						error: 0,
						message: data.description,
					})
					ref.current.click();
				} else {
					setMessage({
						error: 1,
						message: t("Couldn't submit the data. Try again."),
					})
					ref.current.click();
				}
			})
		} else {
			setLoading(false);
		}
	}
	useLayoutEffect(() => {
		if (loading) {
			document.body.style.overflow = "hidden";
			document.body.style.height = "100%";
			document.body.style.pointerEvents = "none";
		}
		if (!loading) {
			document.body.style.overflow = "auto";
			document.body.style.height = "auto";
			document.body.style.pointerEvents = "all";
		}
	}, [loading]);

	useEffect(() => {
		if (message?.message?.length > 0) {
			dispatch(PTOffer.actions.modalmessage(message))
		}
	}, [message])

	const handlePreviewViewDoc = (data: any) => {
		setViewImage(data)
		setTimeout(() => {
			ref3.current.click();
		}, 1000)
	}
	return (
		<><div className='row'>
			<div className='col-12'>
				<div className=''>
					<h3 className='mb-7' id='flush-headingSeven'>
						{t("Payment Plan Details")}
					</h3>
					<div
						id='payment-plan-details'
						className=''
					>
						<div className='pb-5 pt-0'>
							<div className='row form-add-design'>
								<div className='col-12'>
									<div className='mb-7'>
										<h4 className='fw-normal'>{t("Security Deposit")}</h4>
									</div>
								</div>
								{offerData?.sdModeOfPayment != null ? (
								<div className='col-md-4 col-12'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Mode Of Payment")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>
												{offerData?.sdModeOfPayment === "BANK_TRANSFER" ? t("Bank Transfer") : offerData?.sdModeOfPayment === "CREDIT_CARD" ? t("Credit Card") : t("Cheque")}
											</h6>
										</div>
									</div>
								</div>)
									: null}
								<div className='col-md-4 col-12'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{offerData?.sdModeOfPayment != null ? t("Paid Date") : t("Due Date")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>
												{' '}
												{/* {moment(offerList?.data?.payment_plan_details?.sd?.checkDate).format('DD-MM-YYYY')} */}
												{moment(offerList?.data?.offer_details?.validity).format('DD-MM-YYYY')}
											</h6>
										</div>
									</div>
								</div>
								<div className='col-md-4 col-12'>
									<div className='mb-10 input-field-new'>
										<label className='form-label fw-bolder'>{t("Total Amount")}</label>
										<div className='data-contain px-5 py-4 rounded-10'>
											<h6 className='font-14 fw-bold d-block mb-0'>
												{t("AED")}	{parseInt(offerList?.data?.payment_plan_details?.sd?.totalAmount)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
											</h6>
										</div>
									</div>
								</div>
								{offerList?.data?.payment_plan_details?.rent?.length > 0 && offerList?.data?.payment_plan_details?.rent?.map((rent: any, index: any) => (
								<>
								<div className='col-12'>
									<div className='mb-7'>
										<h4 className='fw-bold'>{t("Rent")}</h4>
									</div>
								</div>
									<>
										<div className='col-12'>
											<h4 className='mb-7 fw-normal' id='flush-headingSeven1'>
												{offerList?.data?.payment_plan_details?.rent?.length > 1 ? `Rent Installment ${index + 1}` : t("Rent Installment")}
											</h4>
											<div
												id={`payment-plan-rent-${index + 1}`}
												className='row'
											>
												<div className='col-12'>
													<div className='row'>
														{index === 0 && offerData?.rentModeOfPayment != null ? <div className='col-md-4 col-12'>
															<div className='mb-10'>
																<label className='form-label fw-bolder'>{t("Mode Of Payment")}</label>
																<div className='data-contain px-5 py-4 rounded-10'>
																	<h6 className='font-14 fw-bold d-block mb-0'>
																		{offerData?.rentModeOfPayment === "BANK_TRANSFER" ? t("Bank Transfer") : offerData?.rentModeOfPayment === "CREDIT_CARD" ? t("Credit Card") : t("Cheque")}
																	</h6>
																</div>
															</div>
														</div> : null}
														<div className='col-md-4 col-12'>
															<div className='mb-10'>
																<label className='form-label fw-bolder'>{index === 0 ? t("Payment Date") : t("Cheque Due Date")}</label>
																<div className='data-contain px-5 py-4 rounded-10'>
																	<h6 className='font-14 fw-bold d-block mb-0'>
																		{moment(rent?.checkDate).format('DD-MM-YYYY')}
																	</h6>
																</div>
															</div>
														</div>
														<div className='col-md-4 col-12'>
															<div className='mb-10'>
																<label className='form-label fw-bolder'>{t("Total Amount")}</label>
																<div className='data-contain px-5 py-4 rounded-10'>
																	<h6 className='font-14 fw-bold d-block mb-0'>
																		{t("AED")}	{parseInt(rent?.totalAmount)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
																	</h6>
																</div>
															</div>
														</div>
														{index != 0 && offerList?.data?.offer_details?.offerPaymentStatus != 0 && <div className='col-md-4 col-12'>
															<div className=''>
																<label className='form-label fw-bolder'>{t("Post-Dated Cheque")} <span className="text-danger"> * </span> </label>
																<div className=''>
																	<button
																		data-bs-target='#adding-pdccheque-popup'
																		data-bs-toggle='modal'
																		type='button'
																		onClick={(e) => chequeUpload({
																			id: rent?.id,
																			installment: index + 1,
																			chequeDate: moment(rent?.checkDate).format('DD-MM-YYYY'),
																			amount: rent?.totalAmount,
																			chequeFile: rent?.chequeFile,
																			chequeNumber: rent?.chequeNumber,
																			accountNo: rent?.accountNo,
																			accountHolder: rent?.accountHolder,
																			bankName: rent?.bankName,
																			currentIndex: index,
																			otherFiles: offerList?.data?.payment_plan_details?.rent
																		})}
																		className='btn btn-primary px-3 font-14 py-2 cpt-btn'
																	>
																		{rent?.chequeFile?.length > 0 ? t("Replace Cheque") : t("Upload Cheque")}
																	</button>{' '}
																	{rent?.chequeFile?.length > 0 && <button
																		className='border-0 font-14 bg-transparent'
																		type='submit'
																		onClick={() => {
																			handlePreviewViewDoc(rent?.chequeFile)
																		}}
																	>
																		<i className='bi bi-eye text-black font-26'></i>
																	</button>}
																</div>
															</div>
														</div>}
													</div>
												</div>
											</div>
										</div>
										{offerList?.data?.payment_plan_details?.rent?.length > 1 ? (<></>) : ''}
									</>
								</>))}
								<CustomViewPopup viewImage={viewImage} />
							</div>
							<div className="">
								<hr className="my-0" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
			<div className="modal fade custom-modal" id="adding-pdccheque-popup">
				<div className="modal-dialog modal-lg modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header bg-white border-0">
							<h5 className="modal-title fw-bold font-18 text-black">
								{t("Adding Post-Dated Cheque For Installment")} {chequeDetailsCheck?.installment}
							</h5>
							{/* <button type="button" className="btn-close shadow" data-bs-dismiss="modal" aria-label="Close">&times;</button> */}
						</div>
						<div className="modal-body pt-5">
							<div className="row g-5">
								<div className="col-md-12">
									<div className="form-add-design">
										<div className="redeem-offer-QR">
											<div className='row'>
												{/* <div className='col-12 col-md-3'>
													<div className="mb-5 upload-input">
														<label className="form-label text-black font-13 fw-bold d-block mb-5"> Installment : {chequeDetailsCheck?.installment}  </label>
													</div>
												</div> */}
												<div className='col-12 col-md-6'>
													<div className="mb-3">
														<label className="form-label fw-bolder">{t("Cheque Date")}</label>
														<div className="data-contain px-5 py-4 rounded-10">
															<h6 className="font-14 fw-bold d-block mb-0">{chequeDetailsCheck?.chequeDate}</h6>
														</div>
													</div>
												</div>
												<div className='col-12 col-md-6'>
													<div className="mb-3">
														<label className="form-label fw-bolder">{t("Amount")}</label>
														<div className="data-contain px-5 py-4 rounded-10">
															<h6 className="font-14 fw-bold d-block mb-0">{t("AED")} {currencyFormater(chequeDetailsCheck?.amount)}</h6>
														</div>
													</div>
												</div>
											</div>
											<hr />
											<div className='row'>
												<div className='col-12 col-md-6'>
													<div className="mb-5 upload-input">
														<label className="form-label fw-bolder"> {t("Upload Cheque")} <span className="text-danger"> * </span> </label>{chequeDetailsCheck?.chequeFile != null && <Tooltip title={<img src={chequeDetailsCheck?.chequeFile} width={200} height={200} />}  ><i className='bi bi-eye text-black font-18'></i></Tooltip >}
														<input ref={inputRefCheque} onChange={handleDisplayFileDetailsCheque} className="d-none" type="file" disabled={loading} accept='.png,.jpeg,.jpg,application/pdf' />
														<button onClick={handleUploadCheque} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileNameCheque ? "primary active" : "primary"}`} disabled={uploading}>
															<span> {uploading ?
																<span className='indicator-progress d-block'>
																	{t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
																</span>
																: uploadedFileNameCheque ? uploadedFileNameCheque : <i className='bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt'></i>}
															</span>
														</button>
														<div className='d-flex flex-column text-danger'>
															{chequeFieldError.map((error, index) => {
																if (index === 0)
																	return (
																		<small className="text-danger">{error}</small>
																	)
															})}
														</div>
													</div>
												</div>
												<div className='col-12 col-md-6'>
													<div className="mb-5 upload-input">
														<label className="form-label fw-bolder"> {t("Cheque No")} <span className="text-danger"> * </span> </label> {chequeDetailsCheck?.chequeNumber != null && <Tooltip title={`Pervious cheque no: ${chequeDetailsCheck?.chequeNumber}`}  ><i className='bi bi-eye text-black font-18'></i></Tooltip >}
														<input className='form-control form-control-solid' type="text" onChange={chequeNumber} value={chequeNumb} placeholder="Cheque No" minLength={6} maxLength={10}
															onKeyPress={(event) => {
																if (!/[a-zA-Z0-9 ]/.test(event.key)) {
																	event.preventDefault();
																}
															}} />
														<div className='d-flex flex-column text-danger'>
															{chequeNumberFieldError.map((error, index) => {
																if (index === 0)
																	return (
																		<small className="text-danger">{error}</small>
																	)
															})}
														</div>
													</div>
												</div>
												<div className='col-12 col-md-6'>
													<div className="mb-5 upload-input">
														<label className="form-label fw-bolder"> {t("Account Number")}  <span className="text-danger"> * </span> </label>
														<input className='form-control form-control-solid' type="text" onChange={accountNumber} value={accountNo} placeholder="Account No" minLength={6} maxLength={20}
															onKeyPress={(event) => {
																if (!/[0-9]/.test(event.key)) {
																	event.preventDefault();
																}
															}} />
														<div className='d-flex flex-column text-danger'>
															{accountNumberFieldError.map((error, index) => {
																if (index === 0)
																	return (
																		<small className="text-danger">{error}</small>
																	)
															})}
														</div>
													</div>
												</div>
												<div className='col-12 col-md-6'>
													<div className="mb-5 upload-input">
														<label className="form-label fw-bolder"> {t("Account Holder Name")}  <span className="text-danger"> * </span> </label>
														<input className='form-control form-control-solid' type="text" onChange={accountHName} value={accountHolderName} placeholder="Account Holder Name" minLength={4} maxLength={20}
															onKeyPress={(event) => {
																if (!/[a-zA-Z ]/.test(event.key)) {
																	event.preventDefault();
																}
															}} />
														<div className='d-flex flex-column text-danger'>
															{accountHolderFieldError.map((error, index) => {
																if (index === 0)
																	return (
																		<small className="text-danger">{error}</small>
																	)
															})}
														</div>
													</div>
												</div>
												<div className='col-12 col-md-6'>
													<div className="mb-5 upload-input">
														<label className="form-label fw-bolder"> {t("Bank Name")}  <span className="text-danger"> * </span> </label>
														<input className='form-control form-control-solid' type="text" onChange={bankHName} value={bankName} placeholder="Bank Name" minLength={4} maxLength={20}
															onKeyPress={(event) => {
																if (!/[a-zA-Z ]/.test(event.key)) {
																	event.preventDefault();
																}
															}} />
														<div className='d-flex flex-column text-danger'>
															{accountHolderBankFieldError.map((error, index) => {
																if (index === 0)
																	return (
																		<small className="text-danger">{error}</small>
																	)
															})}
														</div>
													</div>
												</div>
											</div>
											<button className="btn btn-primary btn-lg fw-bolder font-12 mt-5 w-100"
												onClick={submitCheque} disabled={uploading}>
												{/* data-bs-dismiss="modal" */}
												{loading && <Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true" />}
												{loading ? t("Loading...") : t("Submit")}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p ref={ref} data-bs-target="#dynamic-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
				{/*  */}
			</p>
			<p ref={ref2} data-bs-target="#adding-pdccheque-popup" data-bs-toggle="modal" data-bs-dismiss="modal">
				{/*  */}
			</p>
			<p ref={ref3} data-bs-target="#view-customPopUp" data-bs-toggle="modal" data-bs-dismiss="modal">
				{/*  */}
			</p></>
	)
}

export { PaymentPlanDetails }
