import React, { useState, useEffect, useRef } from 'react'
import WorksIcon from '../../../../assets/images/que-icon.png'
import cheque from '../../../../assets/images/bank-check-inactive.svg'
import cash from '../../../../assets/images/cash-active.svg'
import emp from '../../../../assets/images/payment-inactive.svg'
import { useHistory } from 'react-router-dom'
import Nouislider from 'nouislider-react'
import 'nouislider/dist/nouislider.css'
import { getConfig, rentReductionRequest } from '../../../../constants/axios/apis'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'

const rentReductionRequestSchema = Yup.object().shape({
  installment: Yup.number().required('Please choose a renewal period'),
  mode_payment: Yup.string().required('Please choose a payment method'),
})

export function RentDeductionForm({ contract, setMessage }: any) {
  const [loading, setLoading] = useState(false)
  const [renewalAmount, setRenewalAmount] = useState<any>()
  const [maxRentValue, setMaxRentValue] = useState<any>()
  const [renewalPercentage, setRenewalPercentage] = useState<any>()
  const [offerAmount, setOfferAmount] = useState<any>()
  const [installments, setInstallments] = useState<any>(1)
  const history = useHistory()
  const closeRef = useRef<any>()

  const start_date: any = moment(contract?.contractStartDate).add(1, 'y').format('DD-MM-YYYY')
  const end_date: any = moment(contract?.contractEndDate).add(1, 'y').format('DD-MM-YYYY')

  const initialValues = {
    contract_id: contract?.id,
    property_id: contract?.propertyID,
    customer_name: contract?.customerName,
    mode_payment: '',
    installment: '',
    offer_request_price: '',
    start_date: start_date,
    end_date: end_date,
  }

  useEffect(() => {
    getConfig().then(({ data }: any) => {
      setRenewalPercentage(
        data?.find((item: any) => item.key == 'renewal_percentage_increase').value
      )
    })
  }, [])

  useEffect(() => {
    setOfferAmount(Math.round(renewalAmount / installments))
  }, [renewalAmount, installments])

  const formik = useFormik({
    initialValues,
    validationSchema: rentReductionRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        rentReductionRequest(
          values.contract_id,
          values.property_id,
          values.customer_name,
          values.mode_payment,
          values.installment,
          values.offer_request_price,
          values.start_date,
          values.end_date
        )
          .then((response: any) => {
            setLoading(false)
            setMessage('Your Rent Reduction Request has been Submitted!')
            resetForm()
            closeRef.current.click()
            history.push('/my-services/openissues')
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Invalid Data Provided')
          })
      }, 1000)
    },
  })

  useEffect(() => {
    if (renewalPercentage > 0) {
      let amount = (renewalPercentage / 100) * contract?.rent + contract?.rent
      setRenewalAmount(amount)
      setMaxRentValue(amount)
      //   formik.values.offer_request_price = amount
    }
  }, [renewalPercentage, contract])

  useEffect(() => {
    formik.setFieldValue('offer_request_price', offerAmount)
  }, [offerAmount])

  const handleInstallmentChange = (e: any) => {
    setInstallments(e.target.value)
    formik.handleChange(e)
  }

  const handleSlide = (data: any) => {
    // setInstallments(1)
    // formik.setFieldValue('installment', 1)
    setRenewalAmount(data)
  }

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-12'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='accordion accordion-flush' id='accordionFlushExample'>
              <div className='accordion-item offers_details_accordian_div1'>
                <h2 className='accordion-header' id='flush-headingOne'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapseOne'
                    aria-expanded='false'
                    aria-controls='flush-collapseOne'
                  >
                    Contract Details
                  </button>
                </h2>
                <div
                  id='flush-collapseOne'
                  className='accordion-collapse collapse show'
                  aria-labelledby='flush-headingOne'
                  data-bs-parent='#accordionFlushExample'
                >
                  <div className='accordion-body py-5'>
                    <div className='row form-add-design'>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>Customer Name</label>
                          <input
                            value={contract?.customerName}
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10'>
                          <label className='form-label fw-bolder'>Renewal Start Date</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={start_date}
                            disabled
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>Renewal End Date</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={end_date}
                            disabled
                          />
                          {/*<img src={Dateicon} className="img-fluid cal-ioc" />*/}
                        </div>
                      </div>
                      <div className='col-md-4 col-12'>
                        <div className='mb-10 position-relative'>
                          <label className='form-label fw-bolder'>Renewal Annual Amount</label>
                          <input
                            type='text'
                            className='form-control fw-bolder form-control-solid'
                            value={renewalAmount?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' AED'}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='accordion-item offers_details_accordian_div1 payment-plan-details'>
                <h2 className='accordion-header' id='flush-headingTwo'>
                  <button
                    className='accordion-button collapsed accordian_bt'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#flush-collapseTwo'
                    aria-expanded='false'
                    aria-controls='flush-collapseTwo'
                  >
                    Method of Payments
                  </button>
                </h2>
                <div
                  id='flush-collapseTwo'
                  className='accordion-collapse collapse'
                  aria-labelledby='flush-headingTwo'
                  data-bs-parent='#accordionFlushExample'
                >
                  <div className='accordion-body py-5'>
                    <div className='row justify-content-center'>
                      <div className='col-md-12 col-12 col-lg-8'>
                        <div className='make-an-offer-wdw'>
                          <div className='row'>
                            <div className='col-md-4 col-sm-6 col-12 mb-12'>
                              <div className='btn payment-group w-100 form-check form-check-inline col-auto mb-4 me-10'>
                                <input
                                        className='form-check-input'
                                        type='radio'
                                        name='mode_payment'
                                        id='pay-one'
                                        value='CREDIT_CARD'
                                        autoComplete='off'
                                        onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                />
                                <label className='form-check-label text-black font-12 d-inline-flex' htmlFor='pay-one'>
                                  Credit Card
                                  <span className='cash-ioc'><img src={cash} alt="cheqe"/></span>
                                </label>
                              </div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-12 mb-12'>
                              <div className='btn payment-group w-100 form-check form-check-inline col-auto mb-4 me-10'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='mode_payment'
                                  id='pay-two'
                                  value='CHEQUE'
                                  autoComplete='off'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                <label className='form-check-label text-black font-12 ms-3 d-inline-flex' htmlFor='pay-two'>
                                  Cheque<span className='cheque-ioc'><img src={cheque} alt="cheqe"/></span>

                                </label>
                              </div>
                            </div>
                            <div className='col-md-4 col-sm-6 col-12 mb-12'>
                              <div className='btn payment-group w-100 form-check form-check-inline col-auto mb-4 me-10'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='mode_payment'
                                  id='pay-three'
                                  value='PAYMENT_BY_EMP'
                                  autoComplete='off'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                <label className='form-check-label employe  text-black font-12 ms-3 d-inline-flex' htmlFor='pay-three'>
                                  Payment By Employer<span className='emp-pay-ioc'><img src={emp} alt="cheqe"/></span>

                                </label>
                              </div>
                            </div>
                            <div className='col-12 mb-7 rent-reduction'>
                              <label className='form-label font-14 fw-bolder text-black d-block  mb-5'>
                                Choose the number of installments
                              </label>

                              <div className="form-check form-check-inline col-auto mb-4 me-10">
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='installment'
                                  id='no-pay-one'
                                  value='1'
                                  autoComplete='off'
                                  checked={formik.values.installment == '1'}
                                  onChange={(e) => handleInstallmentChange(e)}
                                  onBlur={formik.handleBlur}
                                />
                                <label className='form-check-label text-black font-12' htmlFor='no-pay-one'>
                                  1 Installment
                                </label>
                            </div>
                            <div className="form-check form-check-inline col-auto mb-4 me-10">
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='installment'
                                  id='no-pay-two'
                                  value='2'
                                  autoComplete='off'
                                  checked={formik.values.installment == '2'}
                                  onChange={(e) => handleInstallmentChange(e)}
                                  onBlur={formik.handleBlur}
                                />
                                <label className='form-check-label text-black font-12' htmlFor='no-pay-two'>
                                  2 Installments
                                </label>
                            </div>
                            <div className="form-check form-check-inline col-auto mb-4 me-10">
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='installment'
                                  id='no-pay-three'
                                  value='3'
                                  autoComplete='off'
                                  checked={formik.values.installment == '3'}
                                  onChange={(e) => handleInstallmentChange(e)}
                                  onBlur={formik.handleBlur}
                                />
                                <label className='form-check-label text-black font-12' htmlFor='no-pay-three'>
                                  3 Installments
                                </label>
                            </div>
                            <div className="form-check form-check-inline col-auto mb-4 me-10">
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='installment'
                                  id='no-pay-four'
                                  value='4'
                                  autoComplete='off'
                                  checked={formik.values.installment == '4'}
                                  onChange={(e) => handleInstallmentChange(e)}
                                  onBlur={formik.handleBlur}
                                />
                                <label className='form-check-label text-black font-12' htmlFor='no-pay-four'>
                                  4 Installments
                                </label>
                              </div>
                            </div>
                            <div className='col-12 text-center mb-5'>
                              <h2 className='text-black fw-boldest font-34 mb-5'>
                                {renewalAmount?.toLocaleString(undefined, { maximumFractionDigits: 2 })} AED
                                <span className='fw-bold font-20'>/ year</span>
                              </h2>
                              <h6 className='font-13 fw-normal'>
                                You pay{' '}
                                <span className='text-black font-14 fw-boldest'>
                                  {' '}
                                  {offerAmount?.toLocaleString(undefined, { maximumFractionDigits: 2 })} AED{' '}
                                </span>{' '}
                                per installment
                              </h6>
                            </div>

                            {/* <div className='col-12 text-center mb-5'>
                              <div className='bg-white shadow-sm py-2 px-5 rounded-5 d-inline'>
                                <span
                                  className='text-black fw-boldest font-12 c-pointer'
                                  data-bs-container='body'
                                  data-bs-toggle='popover'
                                  data-bs-placement='right'
                                  data-bs-content='Right popover'
                                >
                                  How renting works
                                  <img src={WorksIcon} className='img-fluid ms-2' />
                                </span>
                              </div>
                            </div> */}
                            <div className='col-12 mb-1 text-center'>
                              <p className='text-black font-13 fw-bold'>
                                If you don't think a price is fair, make your own offer for the
                                landlord to consider.
                              </p>
                            </div>
                            <div className='col-12 mb-5'>
                              <div className='bg-white pt-5 pb-8 px-10 shadow-sm rounded-5'>
                                <div className=''>
                                  <label
                                    htmlFor='customRange1'
                                    className='form-label mb-5 text-center text-black fw-bolder font-18 d-block'
                                  >
                                    Make an Offer
                                  </label>
                                  {offerAmount && (
                                  <Nouislider
                                          start={renewalAmount}
                                  step={10000}
                                  onChange={(data) => handleSlide(data[0])}
                                  range={{
                                  min: 0,
                                  // max: parseFloat(propertyDetails?.price)
                                  max: maxRentValue
                                  }}
                                  />

                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='col-12 text-end'>
                              {/* <button
                                data-bs-target='#req-submitted-popup'
                                data-bs-toggle='modal'
                                type='submit'
                                className='btn btn-primary w-100 fw-bold px-10 py-3'
                              >
                                Make On Offer
                              </button> */}
                              <button type='submit' className='btn btn-primary fw-bold px-10 py-3 w-100' disabled={loading}>
                                {!loading && <span className='indicator-label'>Make On Offer</span>}
                                {loading && (
                                  <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}
