import React from 'react'
import { Link } from 'react-router-dom'
import Home from '../../../../assets/images/my-unit.png'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

export function GeneralPTW({ contract }: any) {
  const { t } = useTranslation();

  return (
    <div className='com-card-section myunit-page'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/my-services/openissues'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            {t("General PTW")}
          </h4>
        </div>
        <div className='col-12'>
          <div className='card mb-7'>
            <div className='card-body p-7'>
              <div className='row'>
                <div className='col-md-4 col-lg-3 mb-md-0 mb-4'>
                  <img src={Home} className='img-fluid' />
                </div>
                <div className='col-md-8 col-lg-9'>
                  <div className='card lease-card text-theme-gray rounded'>
                    <div className='card-body p-10'>
                      <div className='row'>
                        <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5 mb-md-0'>
                          <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Tenant")} </h6>
                          <span className='text-black fw-bold font-15'>
                            {' '}
                            {contract?.customerName}{' '}
                          </span>
                        </div>
                        <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5 mb-md-0'>
                          <div className=''>
                            <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Building Name")} </h6>
                            <span className='text-black fw-bold font-15'>
                              {' '}
                              {contract?.property_name}{' '}
                            </span>
                          </div>
                        </div>
                        <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-5 mb-md-0'>
                          <div className=''>
                            <h6 className='text-theme-gray mb-3 fw-normal font-14'> {t("Unit Number")} </h6>
                            <span className='text-black fw-bold font-15'>
                              {' '}
                              {contract?.communityName + ' - ' + contract?.UnitNumber}{' '}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
