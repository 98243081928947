/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../components/layout/MasterLayout'
import { PrivateRoutes } from './private/PrivateRoutes'
import { Logout, AuthPage } from '../components/auth'
import { ErrorsPage } from '../components/errors/ErrorsPage'
import { RootState } from '../constants'
import { PrivateRoutesNew } from './private/PrivateRoutesNew'

const Routes: FC = () => {
    const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from='/auth' to='/' />
            )}

            <Route path='/error' component={ErrorsPage} />
            <Route path='/logout' component={Logout} />

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to='/auth/login' />
            ) : (
                <MasterLayout>
                    {/* <PrivateRoutes /> */}
                    {/* TODO-Dynamic menu */}
                    <PrivateRoutesNew />
                </MasterLayout>
            )}
        </Switch>
    )
}

export { Routes }
