import React, { useEffect, useState } from 'react'
import ban01 from '../../../../assets/images/lease-banner.png'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getActions, getPaymentHistory, getStepsStatus } from '../../../../constants/axios/apis'
import { useTranslation } from 'react-i18next'
import SampleBanner from "../../../../assets/images/lease-banner.png";
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import NoImage from "../../../../assets/images/House.png";
import { formatDate, formatDateTimeMeridiem, formatTimeMeridiem } from '../../../../constants/generics/dateTimeFormater'
import { NoDataFound1, currencyFormater } from '../../../../constants/generics/customActios'
import profile from '../../../../assets/images/profile-tenant.png'
import avatarImg from '../../../../assets/images/avatar.jpg'


export function WelcomeBanner({ contract }: any) {
  const [step1Status, setStep1Status] = useState<Boolean>(false)
  const [step2Status, setStep2Status] = useState<Boolean>(false)
  const [step3Status, setStep3Status] = useState<Boolean>(false)
  const [moveInStatus, setMoveInStatus] = useState<Boolean>(false)
  const [actions, setActions] = useState<any>()
  const [paymentList, setPaymentList] = useState<any>()
  const [loading, setLoading] = useState<any>(true)

  let userName = ''
  let userImage = ''
  let userDetail = localStorage.getItem('userDetail')
  if (userDetail) {
    userName = JSON.parse(userDetail).first_name + ' ' + JSON.parse(userDetail).last_name
    userImage = JSON.parse(userDetail).profile_img
  }
  const { t } = useTranslation();

  const getMoveInStepsStatus = () => {
    getStepsStatus(contract?.propertyID, contract?.id, 0).then((response) => {
      response.data?.map((value: any) => {
        if (value.id == 5) {
          setStep1Status(value.status)
        }

        if (value.id == 6) {
          setStep2Status(value.status)
        }

        if (value.id == 7) {
          setStep3Status(value.status)
        }
      })
    })
  }

  useEffect(() => {
    if (contract) {
      getMoveInStepsStatus()
    }
    getActions(contract?.id).then((response: any) => {
      if (response?.status === 200 && response?.data?.errorCode === 0) {
        setActions(response)
      } else {
        setActions(response)
      }
    })
      .catch((e) => {
      })
  }, [contract])

  useEffect(() => {
    if (step1Status && step2Status && step3Status) {
      setMoveInStatus(true)
    } else {
      setMoveInStatus(false)
    }
  }, [step1Status, step2Status, step3Status])

  useEffect(() => {
    setLoading(true)
    getPaymentHistory().then((response: any) => {
    if (Array.isArray(response.data) && response.data.length > 0) {
        setPaymentList(response.data)
      } else {
        setPaymentList([])
      }
     setLoading(false)
    }).catch(() => { setLoading(false); setPaymentList([]) })
  }, [])

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  }

  const paymentData = paymentList?.slice(0,3)?.map((data: any, index: any) => (
    <tr key={data.id}>
     <td>{data.title ? data.title : '--'}</td>
      <td>{t('AED')} {currencyFormater(data?.amount)}</td>
      <td>{formatDate(data.date ? data.date : '--')}</td>
    </tr>
  ))

  // if (!loading && (paymentList === undefined || paymentList?.length === 0)) {
  //   return <NoDataFound1 />
  // }

  const actionCards = actions?.status == 200 && actions?.data?.data?.length > 0 && actions?.data?.data?.find((action: any) => action?.contract_id == contract?.id)
  return (
    <div className='row mb-md-7 mb-7'>
      {/* {contract?.contractStatus != 'Terminated' && */}
      <div className='col-12 col-md-12'>
        <div className='card mb-7 welcome-banner-sec banner-sec'>
          <div className='card-body p-7'>
            {/* <Slider {...settings}>
                <div>
                  {!moveInStatus ? (
                    <div className='row p-md-4 p-xl-0'>
                      <div className='col-md-auto col-12'>
                        <div className='my-6 move-in-main-div'>
                          <h6 className='fs-6 text-capitalize text-white fw-bold mb-xl-10 mb-6'>
                            {t("Welcome")}, <span className='fw-700'> {userName} </span>
                          </h6>
                          <h6 className='fs-5 text-capitalize text-gray-400 fw-bolder'>
                            {' '}
                            {t("Action Required")}{' '}
                          </h6>
                          <h2 className='text-white mb-0 fw-bolder font-17'>{t("Request For Move-in")}</h2>
                        </div>
                      </div>
                      <div className='col-md col-12 text-right mt-auto'>
                        <Link to={'/move-in'} className='btn btn-lg btn-light rounded move-in-link px-6 w-100 mb-7'>
                          {t("Move in")}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className='row p-md-4 p-xl-0'>
                      <div className='col-xl-7 col-12'>
                        <div className='my-6 move-in-main-div'>
                          <h6 className='fs-6 text-capitalize text-white fw-bold mb-xl-17 mb-6'>
                            {t("Welcome")}, <span className='fw-700'> {userName} </span>
                          </h6>
                          <h2 className='text-white mb-0 fw-bolder font-17'>{t("Check Move-in Process")}</h2>
                        </div>
                      </div>
                      <div className='col-xl-5 col-12 text-right mt-auto'>
                        <Link to={'/move-in'} className='btn btn-lg btn-light rounded move-in-link w-100 mb-7'>
                          {t("View Move-in Details")}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </Slider> */}
            {/* <Slider {...settings}>
                <div>
                  {actions?.status === 200 && actions?.data?.errorCode === 0 ?
                    actions?.data?.data?.map((action: any, index: any) => {
                      if (action?.contract_id == contract?.id) {
                        return (
                          <div className='row p-md-4 p-xl-0'>
                            <div className='col-xl-8 col-12'>
                              <div className='my-6 move-in-main-div'>
                                <h6 className='fs-6 text-capitalize text-white fw-bold mb-xl-10 mb-6'>
                                  {t("Welcome")}, <span className='fw-700'> {userName} </span>
                                </h6>
                                <h6 className='fs-5 text-capitalize text-gray-400 fw-bolder'>
                                  {' '}
                                  {t("Action Required")}{' '}
                                </h6>
                                <h2 className='text-white mb-0 fw-bolder font-17'>{t("Request For Move-in")}</h2>
                              </div>
                            </div><div className='col-xl-4 col-12 text-right mt-auto'>
                              <Link to={action?.action_code == 1 ? '/move-in' : action?.action_code == 2 ? '/my-services/18' : action?.action_code == 3 ? '/payments/all' : '/my-services/15'} className='btn btn-lg btn-light rounded move-in-link w-100 mb-7'>

                                {action?.action_code == 1 ? t("Move in") : action?.action_code == 2 ? 'Renewal Notice' : action?.action_code == 3 ? 'Upcoming Payment' : 'Move Out'}
                              </Link>
                            </div>
                          </div>)
                      } else {
                        return (<div className='col-xl-8 col-12'>
                          <div className='my-6 move-in-main-div'>
                            <h4 className='fs-6 text-capitalize text-white fw-bold mb-xl-10 mb-6'>
                              {t("Welcome")}, <span className='fw-700'>{userName}</span>
                            </h4>
                            <h6 className='fs-5 text-capitalize text-gray-400 fw-bolder'>
                              {' '}No Action Required
                              {' '}
                            </h6>
                            <h2 className='text-white mb-0 fw-bolder font-17'>All Good!</h2>
                          </div>
                        </div>)
                      }
                    }) :
                    ''
                  }
                </div>
              </Slider> */}
            <Slider {...settings}>
              <div>
                {actionCards != undefined && actionCards != false ?
                  (
                    <div className='row align-items-center'>
                      <div className='col-sm-auto mb-5 mb-sm-0'>
                        <div className='move-in-main-div d-flex align-items-center'>
                          <img src={userImage ? userImage : avatarImg} alt="images" />
                          <div className="ms-5">
                            <h6 className='text-gray-02 font-16 text-capitalize fw-700 mb-xl-2 mb-3'>
                              {t("Welcome")}, <span className='fw-700'> {userName} </span>
                            </h6>
                            <h6 className='fs-5 text-capitalize text-gray-01 fw-bolder'>
                              {' '}
                              {t("Action Required")}{' '}
                            </h6>
                            <h2 className='mb-0 fw-bolder font-14 text-gray-02'>{t("Request For")}</h2>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm text-end'>
                        <Link to={actionCards?.action_code == 1 ? '/move-in' : actionCards?.action_code == 2 ? '/my-services/18' : actionCards?.action_code == 3 ? '/payments/all' : actionCards?.action_code == 4 ? '/my-services/15' : ''} className='btn btn-lg btn-primary move-in-link px-10 px-md-15'>

                          {actionCards?.action_code == 1 ? t("Complete Your Move In") : actionCards?.action_code == 2 ? 'Renewal Notice' : actionCards?.action_code == 3 ? 'Upcoming Payment' : actionCards?.action_code == 4 ? 'Move Out' : ''}
                        </Link>
                      </div>
                    </div>)
                  :
                  <div className='col-xl-8 col-12'>
                    <div className='move-in-main-div d-flex align-items-center'>
                      <img src={userImage ? userImage : avatarImg} alt="images" />
                      <div className="ms-5">
                      <h4 className='text-gray-02 font-18 text-capitalize fw-700 mb-xl-2 mb-5'>
                        {t("Welcome")}, <span className='fw-700'>{userName}</span>
                      </h4>
                      <h6 className='fs-5 text-capitalize text-gray-01 fw-bolder'>
                        {' '}No Action Required
                        {' '}
                      </h6>
                      <h2 className='mb-0 fw-bolder font-17 text-gray-02'>All Good!</h2>
                    </div>
                    </div>
                  </div>
                }
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {/* } */}
      <div className='col-12 col-md-12 col-xl-6 mb-xl-0 mb-7'>
        <div className="card h-100">
          <div className="card-body p-7">
            <div className='row'>
              <div className='col-lg-4 col-xl-4 col-md-6 col-12'>
                <div className='lease-banner text-center'>
                  <img
                    src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage ? contract?.propertyImage : NoImage}
                    alt='Banner'
                    className='img-fluid rounded'
                  />
                </div>
              </div>
              <div className='col-xl-8 col-lg-8 col-md-6 col-12'>
                <div className='card lease-card h-100 justify-content-between'>
                  <div className='row'>
                      <div className='col-xl-6 col-6 mb-5'>
                        <h6 className='text-theme-gray mb-2 fw-bold fs-7'> {t("Lease Number")} </h6>
                        <span className='text-black fw-bolder fs-7'> {contract?.contractNo ? contract?.contractNo : '-'} </span>
                      </div>
                      <div className='col-xl-6 col-6 mb-5'>
                        <h6 className='text-theme-gray mb-2 fw-bold fs-7'> {t("Lease Start Date")} </h6>
                        <span className='text-black fw-bolder fs-7'>
                          {contract?.contractStartDate ? formatDate(contract?.contractStartDate) : '-'}
                        </span>
                      </div>
                      <div className='col-xl-6 col-6 mb-5'>
                        <h6 className='text-theme-gray mb-2 fw-bold fs-7'> {t("Lease End Date")} </h6>
                        <span className='text-black fw-bolder fs-7'>
                          {contract?.contractEndDate ? formatDate(contract?.contractEndDate) : '-'}
                        </span>
                      </div>
                      <div className='col-xl-6 col-6'>
                        <h6 className='text-theme-gray mb-2 fw-bold fs-7'> {t("Status")} </h6>
                        <span className='text-black fw-bolder fs-7'> {contract?.contractStatus ? contract?.contractStatus : '-'} </span>
                      </div>
                      <div className='col-xl-6 col-6'>
                        <h6 className='text-theme-gray mb-2 fw-bold fs-7'> {t("Annual Rent")} </h6>
                        <span className='text-black fw-bolder fs-7'>
                          {contract?.rent ? t("AED") + " " + currencyFormater(contract?.rent) : '-'}
                        </span>
                      </div>
                      <div className='col-xl-6 col-6'>
                        <h6 className='text-theme-gray mb-2 fw-bold fs-7'> {t("Security Deposit")} </h6>
                        <span className='text-black fw-bolder fs-7'>
                          {contract?.SD ? t("AED") + " " + currencyFormater(contract?.SD) : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-md-12 col-xl-6'>
        <div className="card h-100">
          <div className="card-body p-7">
            <div className="row mb-3">
              <div className="col-12 col-md-8">
                <h4>Upcoming Payments</h4>
              </div>
              <div className="col-12 col-md-4 text-end">
                <Link to={'/payments/all'} className="text-decoration-underline view-all text-black font-12 ">View All</Link>
              </div>
            </div>
            <div className='row'>
              <div className="col-12">
                <table className="table dash-table">
                <tbody>{paymentList ? paymentData : ''}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
