import MyUnit from "../../../assets/images/myunit-banner.png";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

export function MyUnitCard({ contract }: any) {
    const { t } = useTranslation();
    return (
        <>
            <div className="row">
                <div className='col-md-12 col-12'>
                    <h4 className='card-title font-18 text-black mb-6'> {t("My Unit")} </h4>
                </div>
            </div>
            <div className="card mb-7">
                <div className="card-body p-7">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <img src={contract?.propertyImage ? contract?.propertyImage : MyUnit} alt="my-unit-image" className="img-fluid w-100 rounded my-unit-image" />
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="d-flex flex-column justify-content-between h-100">
                                <div className="my-unit-address">
                                    <h6>{contract?.occupantFirstName + " " + contract?.occupantLastName} </h6>
                                    <p> Address :- {contract?.buildingName && contract?.buildingName + ' | '}{' '}
                                        {contract?.address && contract?.address + ' | '}{' '}
                                        {contract?.location && contract?.location} </p>

                                    <p>Unit :- {contract?.UnitNumber}</p>
                                </div>
                                <div>
                                    <a
                                        href={contract?.contractFile}
                                        className='text-decoration-underline text-primary fw-bolder view-all font-14'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <button className="btn btn-primary w-100">View Contract</button>

                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
