import React, { useState, useEffect, useRef } from 'react'
import Propertie from '../../../../assets/images/latest-one.png'

export function ViewPopup(props: any) {
    // console.log("🚀 ~ file: ViewPopup.tsx ~ line 5 ~ ViewPopup ~ props", props)
    const [previewDoc, setPreviewDoc] = useState<any>('')
    useEffect(() => {
        setPreviewDoc('')
        setTimeout(() => {
            setPreviewDoc(props?.viewImage?.filename)
        }, (1000))
    }, [props])
    return (
        <div className='modal fade custom-modal' aria-hidden='true' id='view-popUp-contract'>
            <div className='modal-dialog modal-lg modal-dialog-centered'>
                <div className='modal-content rounded-10'>
                    <div className='modal-header border-0 p-0'>
                        {/* <button
                            type='button'
                            className='btn-close shadow'
                            data-bs-dismiss='modal1'
                            aria-label='Close'
                        >&times;</button> */}
                    </div>
                    <div className='modal-body p-8'>
                        <div className=''>
                            <div className='row'>
                                <div className="col-12 text-center">
                                    {previewDoc?.includes('pdf') ? <iframe src={previewDoc} style={{ width: '600px', height: '500px' }} ></iframe> :
                                        <img src={previewDoc} className='img-fluid va-baseline' />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
