import HomeImg from '../../../../assets/images/my_visit.png'
import LikesImg from '../../../../assets/images/my_likes.png'
import OfferImg from '../../../../assets/images/my_documents.png'
import ContractImg from '../../../../assets/images/my_contracts.png'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { initReactI18next } from "react-i18next";

const languages = [
	{
		code: 'en',
		name: 'English'
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl'
	},
];

export function MyActivity(props: any) {
	const { visits, likes, offer, contract } = props
	var count = 0;

	for (var i = 0; i < visits?.data?.length; i++) {
		// if (visits?.data[i]?.visit_date >= moment().format('YYYY-MM-DD')) {
		// 	count++;
		// }
		if ([1, 3, 4].includes(visits?.data[i]?.event_status) && visits?.data[i]?.visit_date >= moment().format('YYYY-MM-DD')) {
			count++;
		}
	}
	const settings = {
		margin: 10,
		dots: false,
		arrows: true,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1920,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					arrows: true,
				},
			},
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					arrows: true,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					dots: false,
					arrows: true,
				},
			},
			{
				breakpoint: 740,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					dots: false,
					arrows: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false,
					arrows: true,
				},
			},
		],
	}
	// console.log(offer, 'offer');

	const { t } = useTranslation();

	return (
		<div className='com-card-section mb-7'>
			<div className='row'>
				<div className='col-12'>
					<div className='quick-link-sec work-orders-sec my-activity-sec new-sec'>
						<div>
							<div className='row'>
								<div className='col-md-6 col-6'>
									<Link to={'/pt-activity/my-visit/upcoming'}>
										<h4 className='card-title'> {t("My Activity")} </h4>
									</Link>
								</div>
							</div>
							<div className='row gx-5'>
								<div className='col-12'>
									<Slider {...settings}>
										<div>
											<Link
												to={'/pt-activity/my-visit/upcoming'}
												className='text-decoration-none'
											>
												<div className='card mt-4'>
													<div className='card-body py-4 px-4'>
														<div className='row g-0 align-items-center'>
															<div className='col-8'>
																<h6 className='card-title fw-normal mb-0 ms-0 text-black'>
																	<span className='fw-bolder d-block'> {count} </span>
																	{t("Upcoming Visits")}
																</h6>
															</div>
															<div className='col-4 text-end'>
																<div className='ps-0'>
																	{/* <div className='text-center'> */}
																	<img src={HomeImg} alt="my-activity-icon" className="mb-0" width="40" height="40" />
																	{/* </div> */}
																</div>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>
										<div>
											<Link to={'/pt-activity/my-shortlist'} className='text-decoration-none'>
												<div className='card mt-4'>
													<div className='card-body py-4 px-4'>
														<div className='row g-0 align-items-center'>
															<div className='col-8'>
																	<span className='fw-bolder d-block'> {likes?.dataCount ? likes?.dataCount : 0} </span>
																<h6 className='card-title fw-normal mb-0 ms-0 text-black'> {t("My Likes")}</h6>
															</div>
															<div className='col-4 text-end'>
																<div className='ps-0'>
																	<img src={LikesImg} alt="my-activity-icon" className="mb-0" width="40" height="40" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>
										<div>
											<Link to={'/pt-activity/my-offer'} className='text-decoration-none'>
												<div className='card mt-4'>
													<div className='card-body py-4 px-4'>
														<div className='row g-0 align-items-center'>
															<div className='col-8'>
																	<span className='fw-bolder d-block'> {offer?.dataCount ? offer?.dataCount : 0} </span>
																<h6 className='card-title fw-normal mb-0 ms-0 text-black'> {t("My Offers")} </h6>
															</div>
															<div className='col-4 text-end'>
																<div className='ps-0'>
																	<img src={OfferImg} alt="my-activity-icon" className="mb-0" width="40" height="40" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>
										<div>
											<Link to={'/pt-activity/my-contracts'} className='text-decoration-none'>
												<div className='card mt-4'>
													<div className='card-body py-4 px-4'>
														<div className='row g-0 align-items-center'>
															<div className='col-8'>
																	<span className='fw-bolder d-block'> {contract?.data ? contract?.data?.length : 0} </span>
																<h6 className='card-title fw-normal mb-0 ms-0 text-black'> {t("My Contracts")} </h6>
															</div>
															<div className='col-4 text-end'>
																<div className='ps-0'>
																	<img src={ContractImg} alt="my-activity-icon" className="mb-0" width="40" height="40" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>
										{/* <div>
                          <Link to={'/'} className='text-decoration-none'>
                            <div className='card mt-4'>
                              <div className='card-body py-4 px-4'>
                                <div className='row g-0 align-items-center'>
                                  <div className='col-4 text-end'>
                                    <img src={HomeImg} className='img-fluid' />
                                  </div>
                                  <div className='col-8'>
                                    <div className='ps-3'>
                                      <span className='fw-bolder'> 06 </span>
                                      <h6 className='card-title fw-normal mb-0 ms-0 text-black'>
                                        
                                        Properties Visited
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div> */}
									</Slider>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
