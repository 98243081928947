import React, { FC, useEffect, useState } from 'react'
import { LeaseDetails } from '../LeaseDetails/LeaseDetails'
import { AccessCardDetails } from './AccessCardDetails/AccessCardDetails'
import { KeyTypeDetails } from './KeyTypeDetails/KeyTypeDetails'
import { VehicleRegistrationDetails } from './VehicleRegistrationDetails/VehicleRegistrationDetails'
import { getAllotedParkingSlots } from '../../../../../constants/axios/apis'

type Props = {
    contract?: any
    getStepStatus?: any
    stepStatus?: any
}

const StepTwo: FC<Props> = ({ contract, getStepStatus, stepStatus }) => {
    const [parkingSlots, setParkingSlots] = useState<any>([])

    useEffect(() => {
        if (contract) {
            getAllotedParkingSlots(contract?.propertyID).then(({ data: slots }: any) => {
                if (slots) {
                    setParkingSlots(slots)
                }
                // else {
                //     setParkingSlots(0)
                // }
            })
        }
    }, [contract])

    return (
        <div className="com-card-section w-100 p-7">
            <div className="row">
                <div className="col-md-12 mb-10">
                    <LeaseDetails contract={contract} />
                </div>
                <div className="col-md-12">
                    <div className="accordion move-out-accordion" id="accordion-step-one">
                        <AccessCardDetails contract={contract} getStepStatus={getStepStatus} stepStatus={stepStatus} />
                        <KeyTypeDetails contract={contract} getStepStatus={getStepStatus} stepStatus={stepStatus} />
                        <VehicleRegistrationDetails contract={contract} parkingSlots={parkingSlots} getStepStatus={getStepStatus} stepStatus={stepStatus} />
                        {/* <SignatureModal /> */}
                    </div>
                </div>
            </div>
        </div>
    )
};

export { StepTwo }
