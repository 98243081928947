import React, {FC, useEffect, useRef, useState, Fragment} from 'react'
import DatePicker from 'react-date-picker';

export function TDetails() {
  const [file, setFile] = useState([] as any);
    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    function uploadSingleFile(e:any) {
        setFile([...file, URL.createObjectURL(e.target.files[0])]);
        console.log("file", file);
    }

    function upload(e:any) {
        e.preventDefault();
        console.log(file);
    }

    function deleteFile(e:any) {
        const s = file.filter((item:any, index:any) => index !== e);
        setFile(s);
        console.log(s);
    }
    const inputRef = useRef<HTMLInputElement>(null);
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const [dateValue, setDateValue] = useState(new Date());

    return (
        <div className="row">
            <div className="col-12">
                <div className='row form-add-design'>
                    <div className='col-12'>
                        <div className='mb-5'>
                          <label className="font-14 fw-bold mb-3 text-black d-block">Tenant Name</label>
                          <input type="text" 
                            className="form-control fw-bolder bg-secon" 
                            placeholder="" 
                          />
                        </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-5 upload-input">
                          <label className="form-label fw-bolder d-block">Upload Image</label>
                          <input ref={inputRef}  onChange={uploadSingleFile} type="file" className="d-none" multiple/>
                          <button onClick={handleUpload} className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? "primary" : "primary"}`}>
                              <span>Upload  <i className="bi bi-upload text-muted ms-3 fw-bold font-17 uploadbt"></i></span>
                          </button>
                      </div>
                    </div>
                    
                    <div className='col-12'>
                        <div className='mb-5'>
                          <label className="font-14 fw-bold mb-3 text-black d-block">Trade License Number</label>
                          <input type="text" 
                            className="form-control fw-bolder bg-secon" 
                            placeholder="" 
                          />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='mb-5 date-picker'>
                          <label className="font-14 fw-bold mb-3 text-black d-block">Trade License Expiry Date</label>
                          <DatePicker
                            onChange={setDateValue}
                            value={dateValue}
                            className='form-control fw-bolder form-control-solid'
                            format="y-MM-dd"
                          />
                        </div>
                    </div>
                    
                    {/* <div className="col-12 text-end">
                        <button className='btn btn-outline-primary fw-bold px-10 py-3 me-5'>
                            Cancel Offer
                        </button>
                        <button className='btn btn-primary fw-bold px-10 py-3'>
                            Next
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
}