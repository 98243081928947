import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ReportsList: React.FC = () => {
    const location = useLocation()
    const { t } = useTranslation();

    return (
        <div className="com-card-section">
            <div className="row mb-6 flex-stack">
                <div className="col-7">
                    <h4 className="card-title font-18 text-black">
                        {t("My Team")}
                    </h4>
                </div>
                <div className="col-5 text-end">
                    <Link to={'/ct-my-team/add'} className="btn btn-primary btn-sm font-16">
                        <i className="bi bi-plus-circle-fill align-middle"></i> {t("Add")}</Link>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-12">
                    <div className="card rounded-bottom-0">
                        <div className="card-body px-8 py-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="text-black font-18 fw-bolder mt-4 mb-5">
                                        {t("Contractors")} - {t("Attendance")}
                                    </h4>
                                </div>
                                <div className="col-md-6 text-end">
                                    <div className="row access-cd-tabs">
                                        <div className="col-md-12 col-12">
                                            <div className='d-flex overflow-auto h-55px justify-content-end'>
                                                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mp-list'>
                                                    <li className='nav-item'>
                                                        <Link
                                                            className={`nav-link pb-1 pt-0 position-relative text-active-primary me-6 ps-0 ` +
                                                            (location.pathname === '/ct-my-team/day' && 'active')}
                                                            to='/ct-my-team/day'>
                                                            {t("Day")}
                                                        </Link>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <Link
                                                            className={`nav-link pb-1 pt-0 position-relative text-active-primary me-6 ps-0 ` +
                                                            (location.pathname === '/ct-my-team/month' && 'active')}
                                                            to='/ct-my-team/month'>
                                                            {t("Month")}
                                                        </Link>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <Link
                                                            className={`nav-link pb-1 pt-0 position-relative text-active-primary me-6 ` +
                                                            (location.pathname === '/ct-my-team/year' && 'active')}
                                                            to='/ct-my-team/year'>
                                                            {t("Year")}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>

    );
}

export {ReportsList}
