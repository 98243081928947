import React, { useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import LeftArrow from '../../../../assets/images/Left_Arrow.png'
import uploadImg from '../../../../assets/images/file-upload.svg'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { parkingIssueRequest } from '../../../../constants/axios/apis'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

export function ParkingIssue({ contract, setMessage }: any) {
  const [loading, setLoading] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const closeRef = useRef<any>()
  const failRef = useRef<any>()
  const { t } = useTranslation();

  // const handleUpload = () => {
  //   inputRef.current?.click()
  // }
  // const handleDisplayFileDetails = (e: any) => {
  //   inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
  //   // formik.setFieldTouched('photo')
  //   formik.setFieldValue('photo', e.currentTarget.files[0])
  // }

  const initialValues = {
    category_id: 2,
    category_type_id: 4,
    desc: '',
    photo: '',
    property_id: contract?.propertyID,
  }

  const parkingIssueSchema = Yup.object().shape({
    desc: Yup.string().required(t("Please add a comment")).max(250, t("Maximum 250 characters")),
    // photo: Yup.mixed()
    //   .test('type', t("Only the following formats are acceptable: .jpeg, .jpg, .png"), (value) => {
    //     if (value) {
    //       return (
    //         value &&
    //         (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png')
    //       )
    //     } else { return true }
    //   })
    //   .test('fileSize', t("The file is bigger than 2MB"), (value) => {
    //     if (value) {
    //       return value?.size <= 2000000
    //     } else { return true }
    //   }),
  })

  const formik = useFormik({
    initialValues,
    isInitialValid: false,
    validationSchema: parkingIssueSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      setTimeout(() => {
        parkingIssueRequest(
          values.property_id,
          values.category_id,
          values.category_type_id,
          values.desc,
          values.photo
        )
          .then((response: any) => {
            setLoading(false)
            resetForm()
            setUploadedFileName(null)
            if (response.errorCode === 1) {
              setMessage(response.errorDescription)
              failRef.current.click()
            } else {
              setMessage(t("Your Parking Issue Request has been Submitted!"))
              closeRef.current.click()
              history.push('/my-services/openissues')
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(t("Invalid Data Provided"))
          })
      }, 1000)
    },
  })

  const [uploading, setUploading] = useState(false)
  const handleUpload = () => {
    inputRef.current?.click()
  }

  const handleDisplayFileDetails = (e: any) => {
    setUploading(true)
    formik.setFieldError('photo', undefined)
    try {
      validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
        if (compressResponse?.success) {
          inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
          formik.setFieldTouched('photo')
          formik.setFieldValue('photo', compressResponse.file)
        } else {
          setUploadedFileName(null)
          formik.setFieldTouched('photo', true, false)
          formik.setFieldValue('photo', '', false)
          formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
        }
        setUploading(false)
      })
    } catch (error) {
      setUploadedFileName(null)
      formik.setFieldTouched('photo')
      formik.setFieldValue('photo', '', false)
      formik.setFieldError('photo', t('File upload error!'))
      setUploading(false)
    } }
  

  return (
    <div className='com-card-section'>
      <div className='row'>
        <div className='col-md-12 col-12'>
          <h4 className='card-title font-18 text-black mb-6'>
            <Link to={'/my-services/openissues'} className=''>
              <img src={LeftArrow} className='img-fluid me-5 left-arrow-back' />
            </Link>
            {t("Parking Issue")}
          </h4>
        </div>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='col-md-12 col-12'>
            <div className='card'>
              <div className='card-body px-8 py-6'>
                <div className='row form-add-design'>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>{t("Parking Issue")}</label>
                      <select className='form-control form-select fw-bolder form-control-solid'>
                        <option selected disabled>
                          {t("Select")}
                        </option>
                        <option>{t("Parking Issue")}</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-4 col-sm-6 col-xl-4 col-12'>
                    <div className='mb-10 upload-input'>
                      <label className='form-label fw-bolder d-block'>
                        {t("Photo")}
                        {/* <span className='text-danger'>*</span> */}
                      </label>
                      <input
                        ref={inputRef}
                        onChange={(e) => handleDisplayFileDetails(e)}
                        className='d-none'
                        type='file'
                        accept=".png,.jpg,.jpeg"
                        // onBlur={formik.handleBlur}
                      />
                      <button
                        type='button'
                        onClick={handleUpload}
                        disabled={uploading}
                        className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
                        }`}
                      >
                         <span>
                            {uploading ?
                              <span className='indicator-progress d-block'>
                                {t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                              : uploadedFileName ? (
                                uploadedFileName
                              ) : (
                                <span className="txt">
                                  <img
                                          alt="File"
                                          src={uploadImg}
                                  className="position-relative"
                                  loading="lazy"/>
                                  {t('Upload')}{' '}
                                </span>
                              )}{' '}
                          </span>
                        {/* <span>
                          {uploadedFileName ? (
                            uploadedFileName
                          ) : (
                            <span className="txt">
                              <img
                                      alt="File"
                                      src={uploadImg}
                              className="position-relative"
                              loading="lazy"/>
                              {t("Upload")}{' '}
                            </span> */}
                          {/* )}{' '}
                        </span> */}
                      </button>
                      {formik.touched.photo && formik.errors.photo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.photo}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 col-12'>
                    <div className='mb-10'>
                      <label className='form-label fw-bolder'>
                        {t("Comments")}<span className='text-danger'>*</span>
                      </label>
                      <textarea
                        // className='form-control fw-bolder form-control-solid'
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.desc && formik.errors.desc,
                          },
                          {
                            'is-valid': formik.touched.desc && !formik.errors.desc,
                          }
                        )}
                        data-kt-autosize='true'
                        rows={3}
                        placeholder={t("Type here...")}
                        {...formik.getFieldProps('desc')}
                      />
                      {formik.touched.desc && formik.errors.desc && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.desc}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-12 text-end'>
                    <div className='row justify-content-end'>
                      <div className='col-md-12'>
                        <button type='submit' className='btn btn-primary fw-bold px-10 py-3' disabled={loading || uploading}>
                          {!loading && <span className='indicator-label'>{t("Submit")}</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              {t("Please wait...")}{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <p
        ref={closeRef}
        data-bs-target='#req-submitted-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
      <p
        ref={failRef}
        data-bs-target='#req-failed-popup'
        data-bs-toggle='modal'
        data-bs-dismiss='modal'
      >
        {/*  */}
      </p>
    </div>
  )
}
