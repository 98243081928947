import React from 'react';
import { MoveInMoveOut } from './MoveInMoveOut';
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import SampleBanner from "../../../../assets/images/lease-banner.png";
import NoImage from "../../../../assets/images/House.png";
import { formatDate } from '../../../../constants/generics/dateTimeFormater';
import { currencyFormater } from '../../../../constants/generics/customActios';

export function WelcomeBanner({ contract }: any) {
    const { t } = useTranslation();

    let userName = "Mohammad";
    let userDetail = localStorage.getItem("userDetail");
    if (userDetail) {
        userName = JSON.parse(userDetail).userName;
    };

    return (
        <div className="row mb-7">
            <div className="col-12 col-md-6 col-lg-6">
                <MoveInMoveOut />
            </div>
            <div className="col-12 col-md-6 col-lg-6">
                <div className="card p-7">
                    <div className="row g-0 bg-white rounded-10">
                        <div className="col-lg-4 col-xl-4 col-md-6 col-12">
                            <div className="lease-banner">
                                <img
                                        src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage ? contract?.propertyImage :  NoImage}
                                alt='Banner'
                                className='img-fluid rounded'
                                />
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-6 col-12 my-auto">
                            <div className="card lease-card h-100 shadow-none border-top-left-rounded-0 border-bottom-left-rounded-0 border-0">
                                <div className="card-body px-5 py-0">
                                    <div className="row">
                                        <div className="col-md-6 col-6 mb-5">
                                            <h6 className="text-theme-gray mb-2 fw-bold fs-7"> {t("Lease Number")} </h6>
                                            <span className="text-black fw-bolder fs-7"> {contract?.contractNo ? contract?.contractNo : '-'} </span>
                                        </div>
                                        <div className="col-md-6 col-6 mb-5">
                                            <h6 className="text-theme-gray mb-2 fw-bold fs-7"> {t("Lease Start Date")} </h6>
                                            <span className='text-black fw-bolder fs-7'>
                                                {contract?.contractStartDate ? formatDate(contract?.contractStartDate) : '-'}
                                            </span>
                                        </div>
                                        <div className="col-md-6 col-6 mb-5">
                                            <h6 className="text-theme-gray mb-2 fw-bold fs-7"> {t("Lease End Date")} </h6>
                                            <span className='text-black fw-bolder fs-7'>
                                                {contract?.contractEndDate ? formatDate(contract?.contractEndDate) : '-'}
                                            </span>
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <h6 className="text-theme-gray mb-2 fw-bold fs-7"> {t("Status")} </h6>
                                            <span className="text-black fw-bolder fs-7"> {contract?.contractStatus ? contract?.contractStatus : '-'} </span>
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <h6 className="text-theme-gray mb-2 fw-bold fs-7"> {t("Annual Rent")} </h6>
                                            <span className="text-black fw-bolder fs-7"> {contract?.rent ? t("AED") + " " + currencyFormater(contract?.rent) : '-'} </span>
                                        </div>
                                        <div className="col-md-6 col-6">
                                            <h6 className="text-theme-gray mb-2 fw-bold fs-7"> {t("Security Deposit")} </h6>
                                            <span className="text-black fw-bolder fs-7"> {contract?.SD ? t("AED") + " " + currencyFormater(contract?.SD) : '-'} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}