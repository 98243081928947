import React, { FC, useRef, useState, useEffect } from 'react'
import Home from '../../../../assets/images/my-unit.png'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getServiceRequestByData, uploadADDCLetterRequest } from '../../../../constants/axios/apis'
import moment from 'moment'
import { ViewPopup } from '../../../maintenance/ViewPopup'
import pdfImage from '../../../../assets/images/documentImage.png'
import { useTranslation } from 'react-i18next'
import uploadImg from '../../../../assets/images/file-upload.svg'
import { validateAndCompressImage } from '../../../../constants/generics/fileUploadHandler'

type Props = {
	contract?: any
	stepStatus?: any
	getStepStatus?: any
	setMessage?: any
}

const Step2: FC<Props> = ({ contract, stepStatus, getStepStatus, setMessage }: any) => {
	const [loading, setLoading] = useState(false)
	const [uploadedFileName, setUploadedFileName] = useState<string | null>(null)
	const [step2Data, setStep2Data] = useState<any>([])
	const inputRef = useRef<HTMLInputElement>(null)
	const [viewImage, setViewImage] = useState<any>()
	const successRef = useRef<any>()
	const failRef = useRef<any>()
	const { t } = useTranslation();

	const initialValues = {
		category_id: 6,
		property_id: contract?.propertyID,
		photo: '',
	}

	// const handleUpload = () => {
	// 	inputRef.current?.click()
	// }
	// const handleDisplayFileDetails = (e: any) => {
	// 	inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
	// 	formik.setFieldValue('photo', e.currentTarget.files[0])
	// }

	const uploadADDCLetterRequestSchema = Yup.object().shape({
		photo: Yup.mixed()
			.required(t("Please add ADDC Welcome Letter"))
			.test('type', t("Only the following formats are accepted: .jpeg, .jpg, .png, .pdf"), (value) => {
				return (
					value &&
					(value.type === 'image/jpeg' ||
						value.type === 'image/jpg' ||
						value.type === 'image/png' ||
						value.type === 'application/pdf')
				)
			})
			.test('fileSize', t('The file is too large! Max allowed size is 5MB.'), (value) => {
				return value?.size <= 5242880
			}),
	})

	const formik = useFormik({
		initialValues,
		validationSchema: uploadADDCLetterRequestSchema,
		onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
			setLoading(true)
			setTimeout(() => {
				uploadADDCLetterRequest(values.category_id, values.property_id, values.photo)
					.then((response: any) => {
						setLoading(false)
						if (response.errorCode === 1) {
							setMessage(response.errorDescription)
							failRef.current.click()
							resetForm()
							setUploadedFileName(null)
							formik.setFieldValue('photo', '')
						} else {
							setMessage(t("Your ADDC Letter has been Submitted!"))
							resetForm()
							setUploadedFileName(null)
							formik.setFieldValue('photo', '')
							successRef.current.click()
							getStepStatus()
						}
					})
					.catch((e) => {
						setSubmitting(false)
						setStatus(t("Invalid Data Provided"))
					})
			}, 1000)
		},
	})

	formik.values.property_id = initialValues.property_id

	const handleView = (data: any) => {
		setViewImage(data)
	}
	useEffect(() => {
		getServiceRequestByData(6, '', contract?.propertyID).then((response) => {
			if (response.data) {
				setStep2Data(response.data[0].images)
			}
		})
	}, [contract, stepStatus])

	const [uploading, setUploading] = useState(false)
	const handleUpload = () => {
		inputRef.current?.click()
	}

	const handleDisplayFileDetails = (e: any) => {
		if (e?.currentTarget?.files[0] && e.currentTarget.files[0].type === 'application/pdf') {
			inputRef.current?.files && setUploadedFileName(inputRef.current.files[0].name)
			formik.setFieldTouched('photo')
			formik.setFieldValue('photo', e.currentTarget.files[0])
		} else {
			setUploading(true)
			formik.setFieldError('photo', undefined)
			try {
				validateAndCompressImage(e.target.files[0]).then((compressResponse) => {
					if (compressResponse?.success) {
						inputRef.current?.files && setUploadedFileName(compressResponse.file.name);
						formik.setFieldTouched('photo', true, false)
						formik.setFieldValue('photo', compressResponse.file, false)
					} else {
						setUploadedFileName(null)
						formik.setFieldTouched('photo', true, false)
						formik.setFieldValue('photo', '', false)
						formik.setFieldError('photo', t(compressResponse?.message || 'File compression error!'))
					}
					setUploading(false)
				})
			} catch (error) {
				setUploadedFileName(null)
				formik.setFieldTouched('photo')
				formik.setFieldValue('photo', '', false)
				formik.setFieldError('photo', t('File upload error!'))
				setUploading(false)
			}
		}
	}

	return (
		<>
			<form
				className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
				noValidate
				id='kt_login_signup_form'
				onSubmit={formik.handleSubmit}
			>
				<div className='com-card-section w-100 mb-7 step3-page'>
					<div className='row form-add-design'>
						<div className='col-12'>
							<div className='card shadow-none'>
								<div className='card-body p-0'>
									<div className='row'>
										<div className='col-md-4 col-lg-3'>
											<div className='d-flex justify-content-center align-content-center step-3'>
												<div className='approval-move'>
													<img src={contract?.communityLogo != null ? contract?.communityLogo : contract?.propertyImage} alt='Home' className='img-fluid' />
												</div>
											</div>
										</div>
										<div className='col-md-8 col-lg-9'>
											<div className='row'>
												<div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mt-3'>
													<h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Lease Number")} </h6>
													<span className='text-black fw-bold font-14'>
														{' '}
														{contract?.contractNo}{' '}
													</span>
												</div>
												<div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mt-3'>
													<div className=''>
														<h6 className='text-theme-gray mb-3 fw-normal font-13'>
															{t("Lease Start Date")}
														</h6>
														<span className='text-black fw-bold font-14'>
															{' '}
															{moment(contract?.contractStartDate).format('DD-MM-YYYY')}{' '}
														</span>
													</div>
												</div>
												<div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mt-3'>
													<h6 className='text-theme-gray mb-3 fw-normal font-13'>
														{t("Lease End Date")}
													</h6>
													<span className='text-black fw-bold font-14'>
														{' '}
														{moment(contract?.contractEndDate).format('DD-MM-YYYY')}{' '}
													</span>
												</div>
												{/* <div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mt-3'>
                        <h6 className='text-theme-gray mb-3 fw-normal font-13'> No of Payments </h6>
                        <span className='text-black fw-bold font-14'> {contract?.noOfPayments} </span>
                      </div> */}
												<div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mt-3'>
													<h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Status")} </h6>
													<span className='text-black fw-bold font-14'>
														{' '}
														{contract?.contractStatus}{' '}
													</span>
												</div>
												<div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mt-3'>
													<h6 className='text-theme-gray mb-3 fw-normal font-13'> {t("Annual Rent")} </h6>
													<span className='text-black fw-bold font-14'> {t("AED")} {contract?.rent?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
												</div>
												<div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mt-3'>
													<h6 className='text-theme-gray mb-3 fw-normal font-13'>
														{t("Security Deposit")}
													</h6>
													<span className='text-black fw-bold font-14'> {t("AED")} {contract?.SD?.toLocaleString(undefined, { maximumFractionDigits: 2 })} </span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='com-card-section w-100 rounded'>
					<div className='row form-add-design'>
						<div className='card shadow-none'>
							<div className='card-body p-0'>
								<div className='col-12'>
									{stepStatus ? (
										<div
											className='col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mt-3 upload-input'
											style={{ cursor: 'not-allowed' }}
										>
											<h6 className='text-theme-gray mb-3 fw-normal font-13'>
												{t("ADDC Welcome Letter")}
												<span className='text-danger'>*</span>{' '}
											</h6>
											<button
												type='button'
												className={`w-100 py-3 ellipsis btn btn-outline-primary`}
												disabled
											>
												<span>
													<span>{t("Uploaded!")} </span>{' '}
												</span>
											</button>
										</div>
									) : (
										<div className='col-md-4 col-sm-6 col-xl-4 col-12 mb-7 mt-3 upload-input'>
											<h6 className='text-theme-gray mb-3 fw-normal font-13'>
												{t("ADDC Welcome Letter")}
											</h6>
											<input
												ref={inputRef}
												onChange={(e) => handleDisplayFileDetails(e)}
												className='d-none'
												type='file'
											/>
											<button
												onClick={handleUpload}
												type='button'
												disabled={uploading}
												className={`w-100 py-3 ellipsis btn btn-outline-${uploadedFileName ? 'primary' : 'primary'
													}`}
											>
												{/* <span>
													{uploadedFileName ? (
														uploadedFileName
													) : (
														<span className="txt">
															<img
																	alt="File"
																	src={uploadImg}
															className="position-relative"
															loading="lazy"/>
															{t("Upload")}{' '}
														</span>
													)}{' '}
												</span> */}
												 {uploading ?
														<span className='indicator-progress d-block'>
															{t("Please wait...")} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
														</span>
														: uploadedFileName ? (
															uploadedFileName
														) : (
															<span className="txt">
																<img
																		alt="File"
																		src={uploadImg}
																className="position-relative"
																loading="lazy"/>
																{t("Upload")}{' '}
															</span>
														)}{' '}
											</button>
											{formik.touched.photo && formik.errors.photo && (
												<div className='fv-plugins-message-container'>
													<div className='fv-help-block'>
														<span role='alert' className='text-danger'>
															{formik.errors.photo}
														</span>
													</div>
												</div>
											)}
										</div>
									)}
								</div>
								{!stepStatus ? (
									<div className='col-md-12 text-end'>
										<button
											type='submit'
											className='btn btn-primary fw-bold px-10 py-3'
											disabled={loading || uploading}
										>
											{!loading && <span className='indicator-label'>{t("Submit")}</span>}
											{loading && (
												<span className='indicator-progress' style={{ display: 'block' }}>
													{t("Please wait...")}{' '}
													<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
												</span>
											)}
										</button>
									</div>
								) : (
									''
								)}
							</div>
							<div className='d-flex service-req-img align-items-center'>
								{/* {stepStatus && (<><img src={step2Data} className='img-fluid me-5' /><button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
									data-bs-toggle='modal'
									data-bs-dismiss='modal'
									type='button'
									onClick={() => {
										handleView(step2Data[0])
									}}>
									View
								</button><ViewPopup viewImage={viewImage} /></>
								)} */}

								{stepStatus && step2Data.length && (
									<>
										<button className='border-0 text-primary font-12 btn' data-bs-target='#view-popUpreschedule'
											data-bs-toggle='modal'
											data-bs-dismiss='modal'
											type='button'
											onClick={() => {
												handleView(step2Data[0])
											}}>
											<img
												src={
													step2Data[0]
														.substr(
															step2Data[0].lastIndexOf('.')
														).includes('.pdf')
														? pdfImage
														: step2Data[0]
												}
												alt="document"
												className='img-fluid me-5'
												style={{ height: 'auto' }}
											/>
											<span className='text-md'>{t("View")}</span>
										</button><ViewPopup viewImage={viewImage} /></>
								)}
							</div>
						</div>
					</div>
				</div>
				<p
					ref={successRef}
					data-bs-target='#req-submitted-popup'
					data-bs-toggle='modal'
					data-bs-dismiss='modal'
				>
					{/*  */}
				</p>
				<p
					ref={failRef}
					data-bs-target='#req-failed-popup'
					data-bs-toggle='modal'
					data-bs-dismiss='modal'
				>
					{/*  */}
				</p>
			</form>
		</>
	)
}

export { Step2 }
