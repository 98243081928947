/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import {
	defaultMessages,
	defaultUserInfos,
	MessageModel,
	UserInfoModel,
	messageFromClient,
} from '../../../components/helpers'
import { getMessageForUser, sendMessages } from '../../../constants/axios/apis'
import { CustomViewPopup, DefaultAvatar, fileUploader } from './../../../constants/generics/customActios';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { getMyTeamDeatils } from './../../../constants/axios/apis';
import { MdPictureAsPdf } from 'react-icons/md';
import { useTranslation } from 'react-i18next'

type Props = {
	isDrawer?: boolean
}

const bufferMessages = defaultMessages

const ChatInner: FC<Props> = ({ isDrawer = false }) => {
	const ref = useRef<any>(0)
	const location = useLocation()
	const ref3 = useRef<any>(0)
	let userEmail = ''
	let userRole: number = 0
	let id: number = 0
	let userName = ''
	let userImg = ''
	let accountManager: number = 0
	let userDetail = localStorage.getItem('userDetail')
	const [viewImage, setViewImage] = useState<any>()
	if (userDetail) {
		userEmail = JSON.parse(userDetail).email
		userRole = JSON.parse(userDetail).id_role
		id = JSON.parse(userDetail).id
		userName = JSON.parse(userDetail).first_name + ' ' + JSON.parse(userDetail).last_name
		userImg = JSON.parse(userDetail).profile_img
		accountManager = JSON.parse(userDetail).account_manager
	}
	const [allMessages, setAllMessages] = useState<any>([])
	const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
	const [success, setSuccess] = useState<boolean>(false)
	const [message, setMessage] = useState<string>('')
	const [messages, setMessages] = useState<MessageModel[]>(bufferMessages)
	const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)

	const inputRefImgUpload = useRef<any>(null);
	const [uploadedFileNameImgUpload, setUploadedFileNameImgUpload] = useState<any>(null);
	const [imgUploadFieldError, setImgUploadFieldError] = useState<string[]>(['']);
	const [imgUploadFieldError1, setImgUploadFieldError1] = useState<string[]>(['']);
	const handleUploadImgUpload = () => {
		inputRefImgUpload.current?.click();
	};
	const [teamDetailData, setTeamDetailData] = useState<any>([])
	const { t } = useTranslation();

	useEffect(() => {
		if (accountManager > 0) {
			const qryParams = "user_role=PE&user_id=" + accountManager
			getMyTeamDeatils(qryParams)
				.then((response: any) => {
					setTeamDetailData(response);
				})
				.catch((e) => {
				})
		} else {
			console.log("no id found");
		}
	}, [accountManager])
	const handleDisplayFileDetailsImgUpload = () => {
		inputRefImgUpload.current?.files &&
			setUploadedFileNameImgUpload(inputRefImgUpload.current.files[0].name);


		if (inputRefImgUpload.current?.files) {
			setImgUploadFieldError([]);
			fileUploader(inputRefImgUpload.current?.files[0], setImgUploadFieldError, "Image");
			fileUploader(inputRefImgUpload.current?.files[0], setImgUploadFieldError1, "Image");
			// setTimeout(() => (
			// 	setImgUploadFieldError([])
			// ), 3000);

		}
	};
	const sendMessage = () => {
		const newMessage: MessageModel = {
			user: 2,
			type: 'out',
			text: message,
			time: 'Just now',
		}
		var FormData = require('form-data')
		var msgPayload = new FormData()
		msgPayload.append('chat_screen', id);
		msgPayload.append('to_user_id', accountManager);
		msgPayload.append('message', message);
		inputRefImgUpload.current?.files?.length > 0 && msgPayload.append('img[]', inputRefImgUpload.current?.files[0])
		if (message.length > 0 || inputRefImgUpload.current?.files?.length > 0 && imgUploadFieldError?.length === 0) {
			sendMessages(msgPayload)
				.then((response: any) => {
					setSuccess(true)
					setUploadedFileNameImgUpload(null)
				})
		} else {
			setSuccess(true)
		}
		bufferMessages.push(newMessage)
		setMessages(bufferMessages)
		toggleChatUpdateFlat(!chatUpdateFlag)
		setMessage('')
		setTimeout(() => {
			bufferMessages.push(messageFromClient)
			setMessages(() => bufferMessages)
			toggleChatUpdateFlat((flag) => !flag)
		}, 1000)
	}

	const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault()
			sendMessage()
		}
	}
	useEffect(() => {
		if (location.pathname === '/chat') {
			// const interval = setInterval(async () => {
			getMessageForUser()
				.then((response: any) => {
					setSuccess(false)
					setAllMessages(response?.data);
					// window.scrollTo({ left: 0, top: 900, behavior: "smooth" }) 
					ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
				})
				.catch((e) => {
				})
			// }, 5000);
		}
	}, [success])

	const handlePreviewViewDoc = (data: any) => {
		setViewImage(data)
		ref3.current.click();
		// setTimeout(() => {

		// }, 1000)
	}
	return (
		<><div
			className='card-body'
			id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
		>
			<div
				className={clsx('scroll-y me-n5 pe-5', { 'h-410px': !isDrawer })}
				data-kt-element='messages'
				data-kt-scroll='true'
				data-kt-scroll-activate='{default: false, lg: true}'
				data-kt-scroll-max-height='auto'
				data-kt-scroll-dependencies={isDrawer
					? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
					: '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'}
				data-kt-scroll-wrappers={isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'}
				data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
			>
				{allMessages?.data?.length > 0 ? allMessages?.data?.map((message: any, index: any) => {
					const userInfo = userInfos[message.user];
					const state = message.type === 'In' ? 'info' : 'primary';
					const templateAttr = {};
					if (message.template) {
						Object.defineProperty(templateAttr, 'data-kt-element', {
							value: `template-${message.type}`,
						});
					}
					const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.type === 'In' ? 'start' : 'end'} mb-10 ${message.type === 'In' ? 'chat-reply' : 'chat-in'}`;

					return (
						<div
							key={`message${index}`}
							className={clsx('d-flex', contentClass, 'mb-6', { 'd-none': message.template })}
							{...templateAttr}
						>
							<div
								className={clsx(
									'd-flex flex-column align-items',
									`align-items-${message.type === 'In' ? 'start' : 'end'}`
								)}
							>
								{message?.message && <div className='d-flex align-items-center mb-2'>
									{message.type === 'In' ? (
										<>
											<div className='symbol  symbol-35px symbol-circle chat-inner-symbol'>
												{teamDetailData?.data?.profile_img?.length > 0 ? <img alt='Pic' src={teamDetailData?.data?.profile_img} /> :
													<DefaultAvatar />}
											</div>

										</>
									) : (
										<>
											<div className='symbol  symbol-35px symbol-circle chat-inner-symbol'>
												{userImg ? <img alt='Pic' src={userImg} /> :
													<DefaultAvatar />}
											</div>
										</>
									)}

									<div
										className={clsx(
											'p-5 rounded',
											`bg-white`,
											'text-dark fw-bold mw-lg-400px mx-5 chat-text',
											`text-${message.type === 'In' ? 'start' : 'end'}`
										)}
										data-kt-element='message-text'
										dangerouslySetInnerHTML={{ __html: message.message }}
									></div>
								</div>}
								<div className='d-flex align-items-center mb-2'>
									<div className='symbol  symbol-85px'>&nbsp;</div>
									{message.type === 'In' ? (
										<>
											<div className='symbol  symbol-100px ' onClick={() => {
												handlePreviewViewDoc(message?.img[0]);
											}}>
												{message?.img[0]?.includes('pdf') ? <MdPictureAsPdf height="5em" width="5em" size={'5em'} />
													:
													message?.img[0] && <img alt='Pic' src={message?.img[0]} />
												}
											</div>

										</>
									) : (
										<>
											<div className='symbol  symbol-100px ' onClick={() => {
												handlePreviewViewDoc(message?.img[0]);
											}}>
												{message?.img[0]?.includes('pdf') ? <MdPictureAsPdf height="5em" width="5em" size={'5em'} />
													:
													message?.img[0] && <img alt='Pic' src={message?.img[0]} />
												}
											</div>
										</>
									)}
								</div>
								<div className='d-flex align-items-center mb-2'>
									<div className='symbol  symbol-35px symbol-circle chat-inner-symbol'>&nbsp;</div>
									<div ref={ref}
										className={clsx(
											`text-${message.type === 'In' ? 'chat-reply-time mx-5' : 'chat-in-time mx-5'}`
										)}
										data-kt-element='message-text'
									>
										{/* 10:04 AM, Jan 23, 2020 */}
										{moment(message.created_at).format("MMM Do YYYY, h:mm:ss a")}</div>
								</div>
							</div>
						</div>
					);
				}) : 'Hi User'}
			</div>

			<div
				className='card-footer pt-4 px-0 pb-0'
				id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
			>


				<div className='d-flex flex-stack'>
					<div className='d-flex align-items-center me-2'>
						<button
							className='btn btn-sm btn-icon btn-active-light-primary me-1 btn-attachment'
							type='button'
							data-bs-toggle='tooltip'
							onClick={handleUploadImgUpload}
						>
							<i className='bi bi-paperclip fs-3'></i>
						</button>
						<input ref={inputRefImgUpload} onChange={handleDisplayFileDetailsImgUpload} className="d-none" type="file" accept='.png,.jpeg,.jpg,application/pdf' />

						{/* <button
    className='btn btn-sm btn-icon btn-active-light-primary me-1'
    type='button'
    data-bs-toggle='tooltip'
    title='Coming soon'
    >
    <i className='bi bi-upload fs-3'></i>
    </button> */}

					</div> <span className="slash">|</span>
					<textarea
						className='form-control form-control-flush mb-0 typing-area me-5 px-2'
						data-kt-element='input'
						rows={1}
						placeholder={t("Type Something")}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						onKeyDown={onEnterPress}
					></textarea>
					<button
						className='btn btn-primary send-msg d-flex align-items-center'
						type='button'
						data-kt-element='send'
						onClick={sendMessage}
					>
						<i className="fas fa-paper-plane me-1"></i> {t("Send")}
					</button>
				</div>
				{uploadedFileNameImgUpload ? uploadedFileNameImgUpload : ''}
				<div className='d-flex flex-column text-danger'>
					{imgUploadFieldError.map((error, index) => {
						if (index === 0)
							return (
								<small className="text-danger" key={index}>{error}</small>
							);
					})}
				</div>
			</div>
		</div><CustomViewPopup viewImage={viewImage} />
			<p ref={ref3} data-bs-target="#view-customPopUp" data-bs-toggle="modal" data-bs-dismiss="modal">
				{/*  */}
			</p></>
	)
}

export { ChatInner }
